import { action } from 'mobx';
import DomainStore from './DomainStore/DomainStore';
import EditorStore from './EditorStore/EditorStore';
import type { GuardStore } from './UiStore/GuardStore/GuardStore';
import type { EnvironmentalPropsStore } from './UiStore/EnvironmentalProps/EnvironmentalPropsStore';
import type MapStore from './UiStore/MapStore/MapStore';
import type { ModalStore } from './UiStore/Modal/Modal';
import type { NotificationStore } from './UiStore/Notifications/Notifications';
import type { PanelsStore } from './UiStore/Panels/Panels';
import type { BuildingPropertiesStore } from './UiStore/Properties/BuildingProperties/BuildingPropertiesStore';
import type { PropertiesStore } from './UiStore/Properties/Properties';
import type { RoofFacePropertiesStore } from './UiStore/Properties/RoofProperties/RoofFacePropertiesStore';
import type { RoofProtrusionStore } from './UiStore/Properties/RoofProtrusion/RoofProtrusionStore';
import { ServiceBus } from './ServiceBus/ServiceBus';
import type SmartGuidesStore from './UiStore/SmartGuidesStore/SmartGuidesStore';
import type { ToolbarStore } from './UiStore/ToolbarStore/Toolbar';
import type { DesignCreationWizardStore } from './UiStore/Wizard/DesignCreationWizardStore';
import type { WizardStore } from './UiStore/Wizard/Wizard';
import type { WorkspaceStore } from './UiStore/WorkspaceStore';
import { UiStore } from './UiStore/UiStore';
import { setRootStore } from './RootStoreInversion';
import type { NotificationContext } from './UiStore/Notifications/AuroraNotificationTypes';

// This is RootStore class, it takes all other existing stores in the app
// and combines them here to make on central place of truth.
//
// When you create new store or change existing one, please follow next rules:
// - store class should be as minimal as possible
// - number of @observable properties should be as minimal as possible
// - try to calculate as much as possible from the existing state using @computed
// - try to reduce inter stores communication, all stores should be independent, some exceptions allowed

class Store {
  serviceBus!: ServiceBus;
  domain!: DomainStore;
  uiStore!: UiStore;
  editor!: EditorStore;
  auroraNotificationManager!: NotificationContext;
  awaitReinitializationPromise: Promise<void> = Promise.resolve();
  storeVersion: number = 0;

  constructor() {
    this.init();
  }

  /**
   * @deprecated use {@link UiStore#guardStore} directly instead
   */
  get guardStore(): GuardStore {
    return this.uiStore.guardStore;
  }
  /**
   * @deprecated use {@link UiStore#map} directly instead
   */
  get map(): MapStore {
    return this.uiStore.map;
  }
  /**
   * @deprecated use {@link UiStore#notification} directly instead
   */
  get notification(): NotificationStore {
    return this.uiStore.notification;
  }
  /**
   * @deprecated use {@link UiStore#wizard} directly instead
   */
  get wizard(): WizardStore {
    return this.uiStore.wizard;
  }
  /**
   * @deprecated use {@link UiStore#roofProtrusion} directly instead
   */
  get roofProtrusion(): RoofProtrusionStore {
    return this.uiStore.roofProtrusion;
  }
  /**
   * @deprecated use {@link UiStore#environmentalProps} directly instead
   */
  get environmentalProps(): EnvironmentalPropsStore {
    return this.uiStore.environmentalProps;
  }
  /**
   * @deprecated use {@link UiStore#toolbar} directly instead
   */
  get toolbar(): ToolbarStore {
    return this.uiStore.toolbar;
  }
  /**
   * @deprecated use {@link UiStore#roofFaceProps} directly instead
   */
  get roofFaceProps(): RoofFacePropertiesStore {
    return this.uiStore.roofFaceProps;
  }
  /**
   * @deprecated use {@link UiStore#smartGuides} directly instead
   */
  get smartGuides(): SmartGuidesStore {
    return this.uiStore.smartGuides;
  }
  /**
   * @deprecated use {@link UiStore#workspace} directly instead
   */
  get workspace(): WorkspaceStore {
    return this.uiStore.workspace;
  }
  /**
   * @deprecated use {@link UiStore#buildingProperties} directly instead
   */
  get buildingProperties(): BuildingPropertiesStore {
    return this.uiStore.buildingProperties;
  }
  /**
   * @deprecated use {@link UiStore#designCreationWizardStore} directly instead
   */
  get designCreationWizardStore(): DesignCreationWizardStore {
    return this.uiStore.designCreationWizardStore;
  }
  /**
   * @deprecated use {@link UiStore#modal} directly instead
   */
  get modal(): ModalStore {
    return this.uiStore.modal;
  }
  /**
   * @deprecated use {@link UiStore#panels} directly instead
   */
  get panels(): PanelsStore {
    return this.uiStore.panels;
  }
  /**
   * @deprecated use {@link UiStore#properties} directly instead
   */
  get properties(): PropertiesStore {
    return this.uiStore.properties;
  }

  get auroraToastManager(): NotificationContext {
    return this.auroraNotificationManager;
  }

  set auroraToastManager(manager: NotificationContext) {
    this.auroraNotificationManager = manager;
  }

  @action.bound
  reset = async (): Promise<void> => {
    if (this.domain.designLoadingPromise) {
      await this.domain.designLoadingPromise;
    }

    await this.uiStore.reset();
    this.domain.reset();

    // We don't have to deactivate base/selection/drag control, as it's done by workspace store
    this.editor.beforeRemount();

    this.storeVersion++;
    this.awaitReinitializationPromise = new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
  };

  private init = (): void => {
    this.serviceBus = new ServiceBus();
    this.domain = new DomainStore();
    this.editor = new EditorStore();
    this.uiStore = new UiStore(this);
    this.editor.init(this);
  };
}

const rootStore = new Store();
setRootStore(rootStore);
export { rootStore };
export default Store;
