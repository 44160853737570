import { useObserver } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import useStore from '../../../../stores/useStore';
import { isProjectWorkspace } from '../../../../stores/UiStore/WorkspaceStore/utils';
import EnvInfoCard from './EnvInfoCard/EnvInfoCard';
import Info from './EnvInfoCard/Info';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  &.disabled {
    opacity: 0.7;
  }
`;

const EnvProps = (): ReactElement => {
  const {
    environmentalProps, workspace
  } = useStore();

  const editingEnabled = isProjectWorkspace(workspace.currentWorkspace);

  return useObserver(
    (): ReactElement => (
      <Container className={!editingEnabled ? 'disabled mh-xxs' : 'mh-xxs'}>
        <EnvInfoCard
          editButtonComplete={isProjectWorkspace(workspace.currentWorkspace)}
          envProps={[environmentalProps.lowTemperatureEnvProp, environmentalProps.highTemperatureEnvProp]}
          onClick={(): void => environmentalProps.showSiteTemperatureModal()}
        />
        <EnvInfoCard
          editButtonComplete={isProjectWorkspace(workspace.currentWorkspace)}
          envProps={[environmentalProps.groundSnowLoadEnvProp]}
          onClick={(): void => environmentalProps.showSiteGroundSnowModal()}
        />
        <EnvInfoCard
          editButtonComplete={isProjectWorkspace(workspace.currentWorkspace)}
          envProps={[environmentalProps.windEnvProp]}
          onClick={(): void => environmentalProps.showWindSpeedModal()}
        />
        <EnvInfoCard
          editButtonComplete={isProjectWorkspace(workspace.currentWorkspace)}
          envProps={[environmentalProps.windExposureEnvProp]}
          onClick={(): void => environmentalProps.showWindExposureModal()}
        />
        <EnvInfoCard
          editButtonComplete={isProjectWorkspace(workspace.currentWorkspace)}
          info={<Info icon="other" name="Other" orientation="column" />}
          envProps={[
            environmentalProps.topographicalEnvProp,
            environmentalProps.averageGradeEnvProp,
            environmentalProps.seismicEnvProp,
            environmentalProps.elevationEnvProp
          ]}
          onClick={(): void => environmentalProps.showSiteOtherPropertiesModal()}
        />
      </Container>
    )
  );
};

export default EnvProps;
