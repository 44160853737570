import * as FileSaver from 'file-saver';
import {
  action, computed, observable
} from 'mobx';
import config from '../../../../../config/config';
import {
  calculateEnergyProductionEstimate, handleApiError, notify
} from '../../../../../utils/helpers';
import { DesignStep } from '../../../../../domain/models/Design/DesignState';
import type {
  IPreliminaryDocumentGenerationOptionsData,
  IDocumentResponse,
  IDocumentsGenerationOptionsData
} from '../../../../../domain/entities/Documents/DocumentsResponse';
import type { Project } from '../../../../../domain/models/SiteDesign/Project';

import type DomainStore from '../../../../DomainStore/DomainStore';
import type EditorStore from '../../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ModalStore } from '../../Modal';
import type { DesignWorkspace } from '../../../WorkspaceStore/workspaces/DesignWorkspace';
import { applyBlobMimeHackForFirefox } from '../../../../../utils/request';
import { ConceptDesignGeneratedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';

interface IConceptDesignOptionViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly workspace: DesignWorkspace;
  readonly project: Project;
  readonly editor: EditorStore;
}

type PreliminaryData = { externalInstallerId?: string };
type GetRequestResponse = { url: string; data: PreliminaryData };
type PostRequestResponse = {
  url: string;
  data: IPreliminaryDocumentGenerationOptionsData | IDocumentsGenerationOptionsData;
};

export class ConceptDesignOptionViewModel extends BaseViewModel {
  @observable
  formContent: IDocumentsGenerationOptionsData;

  @observable
  generatingDocument = false;

  @observable
  optionsLoaded = false;

  readonly propCodeUI = 'concept_design_option_modal';
  override readonly editor: EditorStore;
  private readonly workspace: DesignWorkspace;
  private readonly project: Project;
  defaultValuesOptionsPromise: Promise<void>;
  private resolveDefaultValuesOptionsPromise!: () => void;

  constructor(dependencies: IConceptDesignOptionViewModelDependencies) {
    super(dependencies);
    this.workspace = dependencies.workspace;
    this.defaultValuesOptionsPromise = new Promise((resolve: () => void): void => {
      this.resolveDefaultValuesOptionsPromise = resolve;
    });
    this.project = dependencies.project;
    this.domain = dependencies.domain;
    this.formContent = {
      includes: {
        billOfMaterials: false,
        customerApprovalBox: false,
        emptyFields: false,
        logo: false
      },
      system: {
        annualProductionEstimate: 0
      },
      options: {
        projectReferenceId: dependencies.domain.internalReferenceId,
        creatorFirstName: config.user.firstName,
        creatorLastName: config.user.lastName
      }
    };

    this.getDefaultValues();
    this.editor = dependencies.editor;
  }

  @action
  downloadConceptDesign = (): void => {
    if (!this.documentGenerationOptionsLoaded) {
      return;
    }
    this.generatingDocument = true;
    const request: PostRequestResponse = this.buildRequestParams(this.formContent);
    this.documentsService
      .getConceptDesignDocument(request.url, request.data)
      .then((response: IDocumentResponse): void => {
        FileSaver.saveAs(applyBlobMimeHackForFirefox(response.file), response.fileName);
        this.closeModal();
        config.analytics?.trackEvent(new ConceptDesignGeneratedEvent(this.domain));
      })
      .catch(handleApiError('Could not generate concept design'))
      .finally((): void => {
        this.generatingDocument = false;
        notify('The Concept Design is downloaded', 'confirmation');
      });
  };

  @action
  setFieldValue = (field: string, value: boolean | number | string): void => {
    if (field.includes('includes.logo')) {
      this.formContent.includes.logo = !value;
    }
    if (field.includes('options.projectReferenceId')) {
      this.formContent.options.projectReferenceId = value.toString();
    }
    if (field.includes('includes.emptyFields')) {
      this.formContent.includes.emptyFields = !value;
    }
    if (field.includes('includes.billOfMaterials')) {
      this.formContent.includes.billOfMaterials = !value;
    }
    if (field.includes('includes.customerApprovalBox')) {
      this.formContent.includes.customerApprovalBox = !value;
    }
    if (field.includes('system.annualProductionEstimate')) {
      const annualProductionEstimate = parseInt(value as string, 10);
      if (!annualProductionEstimate) {
        // If the user deletes the value in the UI, we restore the original estimate
        this.formContent.system.annualProductionEstimate = this.estimateAnnualEnergyProduction();
      } else {
        this.formContent.system.annualProductionEstimate = annualProductionEstimate;
      }
    }
    if (field.includes('includes.debugFiles')) {
      this.formContent.includes.debugFiles = !value;
    }
  };

  @action
  private getDefaultValues = (): void => {
    const response: GetRequestResponse = this.getRequest();

    this.documentsService
      .getConceptDesignOptions(response.url, response.data)
      .then((conceptDesignOptions: IDocumentsGenerationOptionsData): void => {
        this.formContent = {
          ...this.formContent,
          ...conceptDesignOptions
        };
        this.formContent.system.annualProductionEstimate = this.estimateAnnualEnergyProduction();
        this.formContent.options.creatorFirstName = config.user.firstName;
        this.formContent.options.creatorLastName = config.user.lastName;
        this.optionsLoaded = true;
        this.resolveDefaultValuesOptionsPromise();
      })
      .catch(handleApiError('Failed to load document generation options'));
  };

  @computed
  get isPreliminaryDocumentVersion(): boolean {
    const designState = this.domain.design.state;
    return (
      designState.isDataIn(DesignStep.ARRAY_PLACEMENT)
      || designState.isDataIn(DesignStep.LAYOUT_DESIGN)
      || designState.isDataIn(DesignStep.ELECTRICAL_DESIGN)
    );
  }

  @computed
  get designId(): string {
    return this.domain.design.id;
  }

  @computed
  get documentGenerationOptionsLoaded(): boolean {
    return this.formContent && this.optionsLoaded;
  }

  private getRequest(): GetRequestResponse {
    if (!this.isPreliminaryDocumentVersion) {
      return {
        url: `/designs/${this.designId}/documents/concept-design-options`,
        data: {}
      };
    }
    // Preliminary document
    return {
      url: `/designs/${this.designId}/documents/preliminary-concept-design-options`,
      data: {
        externalInstallerId: this.domain.project.participants.installerId
      }
    };
  }

  private buildRequestParams(data: IDocumentsGenerationOptionsData): PostRequestResponse {
    if (!this.isPreliminaryDocumentVersion) {
      return {
        url: `/designs/${this.designId}/documents/concept-design`,
        data
      };
    }
    // Preliminary document
    const currentDesign = this.domain.design;
    const design = currentDesign.toData();
    const project = this.project.toData();
    const preliminaryData: IPreliminaryDocumentGenerationOptionsData = {
      project,
      design,
      options: {
        ...data
      }
    };
    return {
      url: `/designs/${this.designId}/documents/preliminary-concept-design`,
      data: preliminaryData
    };
  }

  private estimateAnnualEnergyProduction(): number {
    const {
      roofTopArrayAreas, system
    } = this.domain.design;
    if (!roofTopArrayAreas) {
      return 0;
    }
    const { energyProductionEstimate } = calculateEnergyProductionEstimate(roofTopArrayAreas, system);

    return energyProductionEstimate;
  }

  override dispose(): void {
    // do nothing
  }
}
