import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React, { BaseProps, ReactElement } from 'react';
import { Container, ContainerProps } from './styles';

const DraggableContainer = (props: BaseProps & ContainerProps): ReactElement => {
  const { children, width } = props;

  return (
    <DndProvider backend={HTML5Backend}>
      <Container width={width}>
        {children}
      </Container>
    </DndProvider>
  );
};

export { DraggableContainer };
