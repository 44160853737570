import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  Fragment, useState
} from 'react';
import sumBy from 'lodash/sumBy';
import type {
  IStringingOptionData,
  IStringingOptionsResponseData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import type {
  StringConnected,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';
import { precisionNumber } from '../../../../utils/helpers';
import {
  ContentItem,
  ItemText,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperValueText
} from './styles';

type Props = BaseProps & {
  index: number;
};

const MicroinverterOrACModuleSystemPanel = observer((props: Props): ReactElement => {
  const { index = 0 } = props;
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, stringingOptions
  } = systemSummaryViewModel;

  const stringsConnected = systemSummaryList[index].strings;

  const totalMaxOutputPower = sumBy(stringsConnected, (item: StringConnected): number => {
    return (item?.stringingOption as IStringingOptionData)?.maxOutputPower ?? 0;
  });

  const totalMaxContinuousOutputCurrent = sumBy(stringsConnected, (item: StringConnected): number => {
    return (item?.stringingOption as IStringingOptionData)?.maxContinuousOutputCurrent ?? 0;
  });

  const essRatedOutputCurrent = sumBy(stringingOptions, (item: IStringingOptionsResponseData): number => {
    return item?.essRatedOutputCurrent ?? 0;
  });

  return (
    <Fragment>
      <ContentItem>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>PV System AC Power Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{totalMaxOutputPower}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>PV System AC Current Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{precisionNumber(totalMaxContinuousOutputCurrent, 2)}</ValueText>
            <UnitText>A</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        {essRatedOutputCurrent > 0 && (
          <>
            <ValueItemWrapper bordered>
              <WrapperItemText>
                <ItemText>ESS AC Current Output</ItemText>
              </WrapperItemText>
              <WrapperValueText>
                <ValueText>{precisionNumber(essRatedOutputCurrent, 2)}</ValueText>
                <UnitText>A</UnitText>
              </WrapperValueText>
            </ValueItemWrapper>
            <ValueItemWrapper bordered>
              <WrapperItemText>
                <ItemText>Total AC Current Output</ItemText>
              </WrapperItemText>
              <WrapperValueText>
                <ValueText>{precisionNumber(totalMaxContinuousOutputCurrent + essRatedOutputCurrent, 2)}</ValueText>
                <UnitText>A</UnitText>
              </WrapperValueText>
            </ValueItemWrapper>
          </>
        )}
      </ContentItem>
    </Fragment>
  );
});

export default MicroinverterOrACModuleSystemPanel;
