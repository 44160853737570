enum EGuideIdentifier {
  PARALLEL,
  MID_POINTS,
  EXTENSION_LINES,
  PERPENDICULAR,
  SQUARE,
  LIVE_ANGLES,
  SNAP_LINES
}

export default EGuideIdentifier;
