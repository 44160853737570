import {
  LyraFormElementsDesignTool, LyraLayout
} from '@aurorasolar/lyra-ui-kit';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import useStore from '../../../../../stores/useStore';
import type { SolarAccessRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/SolarAccessRoofFaceViewModel';
import { LABEL_SOLAR_ACCESS } from '../../constants';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';

const SolarAccess = observer((): ReactElement => {
  const {
    roofFaceProps, properties
  } = useStore();
  const [viewModel] = useState<SolarAccessRoofFaceViewModel>(
    roofFaceProps.currentRoofPropertyEditor as SolarAccessRoofFaceViewModel
  );

  useEffect((): void => {
    properties.setTitle(LABEL_SOLAR_ACCESS);
  });

  const setSolarAccessPercentage = useCallback(
    (value: number): void => viewModel.setSolarPercentage(value),
    [viewModel]
  );

  return useObserver(
    (): ReactElement => (
      <>
        <RoofPropertiesParent viewModel={viewModel}>
          <LyraLayout.Container>
            <LyraLayout.Row>
              <LyraLayout.Col xs={6} className="mt-xs">
                <LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField
                  label="PERCENTAGE"
                  measure="%"
                  fractionDigits={1}
                  value={viewModel.solarPercentage}
                  onChange={setSolarAccessPercentage}
                  limits={viewModel.PERCENTAGE_LIMIT}
                />
              </LyraLayout.Col>
            </LyraLayout.Row>
          </LyraLayout.Container>
        </RoofPropertiesParent>
      </>
    )
  );
});

export default SolarAccess;
