import type EditorStore from '../../stores/EditorStore/EditorStore';
import { BaseControl } from '../../stores/EditorStore/Controls/BaseControl';
import type {
  IHandleClicksTool, IHandleMoveTool
} from '../../stores/UiStore/ToolbarStore/Tool';

export class MouseBehaviour {
  protected baseControl: BaseControl;

  constructor(editor: EditorStore) {
    this.baseControl = BaseControl.getInstance(editor, editor.viewport, editor.activeCamera);
  }

  addMouseClickEvents(instance: IHandleClicksTool): void {
    this.baseControl.addEventListener('PointerDown', instance.onMouseDown);
    this.baseControl.addEventListener('PointerUp', instance.onMouseUp);
    this.baseControl.addEventListener('PointerDblClick', instance.onMouseDblClick);
  }

  removeMouseClickEvents(instance: IHandleClicksTool): void {
    this.baseControl.removeEventListener('PointerDown', instance.onMouseDown);
    this.baseControl.removeEventListener('PointerUp', instance.onMouseUp);
    this.baseControl.removeEventListener('PointerDblClick', instance.onMouseDblClick);
  }

  addMouseMoveEvents(instance: IHandleMoveTool): void {
    this.baseControl.addEventListener('PointerMove', instance.onMouseMove);
    this.baseControl.addEventListener('PointerLeft', instance.onMouseLeave);
  }

  removeMouseMoveEvents(instance: IHandleMoveTool): void {
    this.baseControl.removeEventListener('PointerMove', instance.onMouseMove);
    this.baseControl.removeEventListener('PointerLeft', instance.onMouseLeave);
  }
}
