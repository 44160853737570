import { ReactText } from 'react';
import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { H2, H4, Notes } from '../FontFamily/Fonts';

type Props = PropsTheme & {
  progressBar?: number;
  color?: string;
  active?: boolean;
  offset?: number;
  color1?: string;
  color2?: string;
  barHeight?: string;
  fontSize?: string;
  isCompleted?: boolean;
  size?: string;
  arrowSize?: ArrowSizeProps;
  minValue?: number;
  width?: number;
  total?: number;
};

type ArrowSizeProps = {
  offset?: number;
  arrowSize?: {
    left: string;
    right: string;
    top: string;
    positionTop: string;
    positionRight: string;
  };
};

type DotProps = {
  color: string;
  key: number;
  position: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
};

type CustomDotProps = {
  dotKey: number;
  current: number;
};

export const Wrapper = styled.section`
  width: 250px;
`;

export const ProgressBarContainer = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.lighter};
  border-radius: 4px;
  height: 30px;
  position: relative;
  width: 100%;
`;

export const CircularProgressBarWrapper = styled.div`
  width: 80px;
  position: relative;
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-top: -10px;
`;

export const MinText = styled.div`
  width: 50%;
  font-size: 10px;
  font-weight: normal;
  color: #4a4a4a;
  text-align: left;
`;

export const MaxText = styled(MinText)`
  text-align: right;
`;

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const GaugeDot = styled.div<DotProps>`
  height: 4px;
  width: 4px;
  background-color: ${({ color }: DotProps): string => color};
  border-radius: 50%;
  position: absolute;
  top: ${({ position: { top } }: DotProps): string | undefined => top};
  bottom: ${({ position: { bottom } }: DotProps): string | undefined => bottom};
  left: ${({ position: { left } }: DotProps): string | undefined => left};
  right: ${({ position: { right } }: DotProps): string | undefined => right};
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const LabelAndIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  p {
    font-size: 11px;
    margin-right: 6px;
    text-align: left;
  }
  & > div {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-block: 8px 10px;
    span {
      margin-inline-end: 8px;
    }
  }
`;

export const RangeContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.2s ease-in;
  width: ${({ progressBar = 0 }: Props): number => progressBar}%;
`;

export const RangeBar = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.str03};
  border-radius: 4px;
  height: 30px;
  width: 100%;
`;

export const Percent = styled(H4)<Props>`
  color: ${({ theme, isCompleted }: Props): Color =>
    isCompleted ? theme.colors.whiteBg : theme.colors.grey1};
  font-size: ${({ isCompleted }: Props): string => (isCompleted ? '1.2rem' : '.8rem')};
  font-weight: ${({ isCompleted }: Props): number => (isCompleted ? 800 : 400)};
  margin-right: -16px;
  padding-bottom: ${({ isCompleted }: Props): string => (isCompleted ? '0' : '.5rem')};
  position: absolute;
  top: ${({ isCompleted }: Props): string => (isCompleted ? '50%' : '-1.3rem')};
  left: ${({ isCompleted }: Props): string => (isCompleted ? '50%' : 'inherit')};
  transform: translate(
    ${({ isCompleted }: Props): string => (isCompleted ? '-50%, -50%' : '0, 0')}
  );
  transition: all 0.5s ease-in-out;
`;

export const Target = styled(H4)<Props>`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  font-size: 13px;
  margin-top: 8px;
  position: absolute;
  bottom: -18px;
  left: ${({ isCompleted }: Props): string => (isCompleted ? '50%' : '0')};
  transform: translateX(
    ${({ isCompleted }: Props): string => (isCompleted ? '-50%' : '0')}
  );
  transition: all 0.5s ease-in-out;
  width: max-content;
`;

export const DottedProgressBarContainer = styled.div<Props>`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.lighter};
  border-radius: 5px;
  height: ${({ barHeight }: Props): string => (barHeight ? barHeight : '30px')};
  position: relative;
  width: 150px;
`;

export const LinearProgressBarContainer = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.lighter};
  border-radius: 2px;
  height: 4px;
  position: relative;
`;

export const LinearRangeContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.2s ease-in;
  width: ${({ progressBar = 0 }: Props): number => progressBar}%;
  height: 4px;
  background-color: ${({ theme, color }: Props): Color =>
    color ? color : theme.colors.str03};
  border-radius: 2px;
`;

export const DottedRangeBar = styled.div<Props>`
  background-color: ${({ theme, color }: Props): Color =>
    color ? color : theme.colors.str03};
  border-radius: 5px;
  height: 30px;
`;

export const LinearRangeBar = styled.div<Props>`
  background-color: ${({ theme, color }: Props): Color =>
    color ? color : theme.colors.str03};
  border-radius: 2px;
  height: 4px;
  width: 100%;
`;

export const LinearProgressWrapper = styled.div``;

export const DottedProgressWrapper = styled.div`
  display: flex;
`;

export const RangeTextWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

export const RangeText = styled.div<Props>`
  width: 50%;
  font-size: ${({ fontSize }: Props): string => (fontSize ? fontSize : '20px')};
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
`;

export const RangeEndText = styled(RangeText) <Props>`
  text-align: right;
  font-size: ${({ fontSize }: Props): string => (fontSize ? fontSize : '20px')};
`;

export const LinearPercent = styled(H2) <Props>`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  font-size: ${({ fontSize }: Props): string => (fontSize ? fontSize : '23px')};
  font-weight: bold;
  padding-bottom: 8px;
`;

export const DottedPercent = styled(H2) <Props>`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  font-size: ${({ fontSize }: Props): string => (fontSize ? fontSize : '23px')};
  font-weight: bold;
  position: absolute;
  top: ${({ size }: Props): string => (size ? size : '-2rem')};
  right: ${({ offset }: Props): string => (offset ? `${offset}px` : '-1.0rem')};
`;

export const DownArrow = styled.div<ArrowSizeProps>`
  position: absolute;
  width: 0;
  height: 0;
  border-left: ${({ arrowSize }: ArrowSizeProps): string =>
    `${arrowSize ? arrowSize.left : '4px'} solid transparent`};
  border-right: ${({ arrowSize }: ArrowSizeProps): string =>
    `${arrowSize ? arrowSize.right : '4px'} solid transparent`};
  border-top: ${({ arrowSize }: ArrowSizeProps): string =>
    `${arrowSize ? arrowSize.top : '12px'} solid #4a4a4a`};
  top: ${({ arrowSize }: ArrowSizeProps): string =>
    `${arrowSize ? arrowSize.positionTop : '-1.0rem'}`};
  right: ${({ offset }: ArrowSizeProps): string =>
    offset ? `${offset - 2}px` : '-0.1rem'};
`;

export const OverrideDottedView = styled.div`
  display: flex;
  width: 100px;
`;

export const LinearDotView = styled.div<Props>`
  width: 6px;
  height: ${({ barHeight }: Props): string => (barHeight ? barHeight : '30px')};
  border-radius: 3px;
  background: ${({ color1, color2, active, theme }: Props): ReactText =>
    active ? `linear-gradient(to right, ${color1}, ${color2})` : theme.colors.lighter};
  margin-left: 2px;
`;

export const MaxValueText = styled.div<Props>`
  font-size: ${({ fontSize }: Props): string => (fontSize ? fontSize : '20px')};
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
  position: absolute;
  top: ${({ barHeight }: Props): string => (barHeight ? '25px' : '40px')};
  right: -2px;
`;

export const BackgroundCircle = styled.circle`
  fill: none;
  stroke: #ddd;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const ProgressCircle = styled.circle`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const ValueText = styled.text`
  font-size: 18px;
  font-weight: bold;
`;

export const UnitText = styled.text`
  font-size: 10px;
  font-weight: normal;
`;

export const StepDottedProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StepDottedProgressWrapper = styled.div`
  display: flex;
`;

export const LowerValueContainer = styled.div`
  display: flex;
`;

export const MediumValueContainer = styled.div<Props>`
  display: flex;
  border-left: ${({ minValue }: Props): string => `${minValue ? '1px solid' : '0px'}`} ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  border-right: 1px solid ${
  ({ theme }: PropsTheme): Color =>
    theme.colors.grey4
  };
  margin-left: 5px;
  padding-left: 5px;
  margin-right: 5px;
  padding-right: 5px;
  position: relative;
`;

export const UpperValueContainer = styled.div`
  display: flex;
`;

export const DotItem = styled.div<Props>`
  width: ${({ total = 10 }: Props): string => {
    let width: number = 3.2;
    let value : string = '5px';
    width = ((width * 15) / total);
    if (width > 4) {
      value = '6px';
    } else {
      value = width.toString() + 'px';
    }
    return value;
  }
  }; 
  height: 16px;
  border-radius: 2px;
  border: solid 1px ${({ theme }: Props): Color => theme.colors.grey3};
  background-color: ${({ theme, color }: Props): Color =>
    color ? color : theme.colors.secondary};
  margin: 0 0.9px;
`;

export const MinLimitText = styled(Notes)`
  position: absolute;
  left: -10px;
  top: 25px;
`;

export const MaxLimitText = styled(Notes)`
  position: absolute;
  right: -10px;
  top: 25px;
`;

export const StepPercent = styled(LinearPercent) <Props>`
  font-size: ${({ size }: Props): string => (size === 'small' ? '14px' : '20px')};
`;

export const StepDownArrow = styled.div<Props>`
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const StepDotRangeContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border: 1px solid black;
  width: 100%; 
  height: 10px;
  margin: 12px 0 8px 0;
  border-radius: 5px;
`;

export const DottedRangeContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.2s ease-in;
  width: ${({ progressBar = 0 }: Props): number => progressBar}%;
  height: ${({ barHeight }: Props): string => (barHeight ? barHeight : '30px')};
  background-color: ${({ theme, color }: Props): Color =>
    color ? color : theme.colors.str03};
  border-radius: 5px;
`;

export const StepDotWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 98px;
`;


export const DotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const DotItemsContainer = styled.div<CustomDotProps>`
  position: absolute;
  bottom: 24px;
  display: ${({ dotKey, current }: CustomDotProps): string =>
    dotKey === current ? 'flex' : 'none'
  };
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 187.34px;
`;
