import { observable } from 'mobx';
import { handleApiError } from '../../../../../utils/helpers';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';

export class RoofSurfaceDataFetcher {
  @observable
  surfaceTypeOptions: IOption<BaseAttributes>[] = [];

  @observable
  roofMaterialOptions: IOption<BaseAttributes>[] = [];

  private readonly designService = new DesignService();

  async loadOptions(): Promise<void> {
    this.loadSurfaceTypeOptions();
  }

  async loadSurfaceTypeOptions(): Promise<void> {
    this.designService.getRoofSurfaceTypeOptions().then((response: IOption<BaseAttributes>[]): void => {
      this.surfaceTypeOptions = response;
    });
  }

  async loadRoofMaterial(surface: string): Promise<void> {
    this.designService
      .getRoofSurfaceSubTypeOptions(surface)
      .then((response: IOption<BaseAttributes>[]): void => {
        this.roofMaterialOptions = response;
      })
      .catch(handleApiError('Failed to fetch roof material data'));
  }
}
