import { toJS } from 'mobx';
import { Vector3 } from 'three';
import { SiteStructureFactory } from '../../domain/models/StructureFactory';
import type { SiteEquipment as SiteEquipmentDTO } from '../../domain/models/SiteDesign/SiteEquipment';
import { StreetLocation as StreetLocationDTO } from '../../domain/models/SiteDesign/StreetLocation';
import { getRootStore } from '../../stores/RootStoreInversion';
import { SceneObjectType } from '../../domain/models/Constants';
import type { IRoadwaysData } from '../../domain/entities/SiteDesign/Roadways';
import type { IVertexData } from '../../domain/entities/SiteDesign/Vertex';
import { canvasConfig } from '../../config/canvasConfig';
import type { IMapping } from './IMapping';

export class RoadwaysMapping implements IMapping<IRoadwaysData, SiteEquipmentDTO> {
  private drawableObjectsFactory: SiteStructureFactory;

  constructor() {
    this.drawableObjectsFactory = new SiteStructureFactory();
  }

  convertEntityToDomainModel(entity: IRoadwaysData): SiteEquipmentDTO {
    const dto: SiteEquipmentDTO = {} as SiteEquipmentDTO;
    const { street } = entity;
    if (street) {
      dto.streetLocation = this.createEquipment(street);
    }
    return dto;
  }

  convertDomainModelToEntity(domainModel: SiteEquipmentDTO): IRoadwaysData {
    const equipments = toJS(domainModel);
    const { streetLocation } = equipments;

    if (!streetLocation) {
      return {};
    }

    return {
      street: {
        ...streetLocation.mesh.position,
        z: StreetLocationDTO.defaultEquipmentZValueInWorldUnits()
      }
    };
  }

  private createEquipment(location: IVertexData): StreetLocationDTO {
    const marker = this.drawableObjectsFactory.create<StreetLocationDTO>(StreetLocationDTO, {
      color: canvasConfig.streetLocationIconColor
    });
    const position = new Vector3(
      location.x,
      location.y,
      getRootStore().editor.getObjectRenderHeight(SceneObjectType.Street)
    );
    marker.draw(position);

    return marker;
  }
}
