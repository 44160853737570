import type Store from '../../../../../stores/Store';
import { StringingTool } from '../../../../../stores/UiStore/ToolbarStore/Design/StringingTool/StringingTool';
import type { IBaseStringingDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/StringingTool/StringingTool';
import withTool from '../../Shared/withTool';

type StringingModuleToolDependencies = Pick<IBaseStringingDependencies, 'workspace'>;

const Stringing = withTool<StringingTool, IBaseStringingDependencies, StringingModuleToolDependencies>(
  StringingTool,
  ({ workspace }: Store): StringingModuleToolDependencies => ({
    workspace
  })
);

export default Stringing;
