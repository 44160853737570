import React, { BaseProps, ReactElement } from 'react';
import {
  BackgroundCircle,
  CircularProgressBarWrapper,
  DotsWrapper,
  GaugeDot,
  MaxText,
  MinText,
  ProgressCircle,
  TextWrapper,
  UnitText,
  ValueText
} from './styles';

export type MarkerProps = {
  name: string;
  color: string;
  value: number;
};

type CircularProgressProps = BaseProps & {
  sqSize: number;
  strokeWidth: number;
  currentValue: number;
  markers: MarkerProps[];
  minValue: number;
  maxValue: number;
  unitName: string;
  unitAbbreviation: string;
  allowOutOfRange?: boolean;
  outOfRangeLabelColor?: string;
};

type PositionProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

const CircularProgressBar = ({
  sqSize,
  strokeWidth,
  currentValue,
  markers,
  minValue,
  maxValue,
  unitName,
  unitAbbreviation,
  allowOutOfRange,
  outOfRangeLabelColor
}: CircularProgressProps): ReactElement => {
  const radius = (sqSize - strokeWidth) / 2;
  let possibleValue = currentValue;
  if (currentValue > maxValue) {
    possibleValue = maxValue;
  }
  if (currentValue < minValue) {
    possibleValue = minValue;
  }
  const valueToParse = (allowOutOfRange ? currentValue : possibleValue) ?? 0;
  const textValue = `${parseFloat(valueToParse.toString()).toFixed(1)}`;
  const isValueOutOfRange = !(currentValue >= minValue && currentValue <= maxValue);
  const labelColor = allowOutOfRange && isValueOutOfRange && outOfRangeLabelColor ? outOfRangeLabelColor : '#4a4a4a';
  const percentage = Math.floor((100 * possibleValue) / (maxValue - minValue));
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - ((dashArray / 360) * 260 * percentage) / 100;
  const getColors = (): string => {
    markers.sort((a: MarkerProps, b: MarkerProps): number =>
      a.value < b.value ? 1 : -1
    );
    for (let i = 0; i < markers.length; i++) {
      if (possibleValue > markers[i].value) {
        return markers[i].color;
      }
    }
    return markers[markers.length - 1].color;
  };

  const getDotPosition = (dot: MarkerProps): PositionProps => {
    let positionValue = {};
    const radiusS = 3;
    const radiusL = 40;
    const lowerValue = dot.value - minValue;
    const rangeValue = maxValue - minValue;
    const angle = -(((lowerValue / rangeValue) * 260 + 140) / 180) * Math.PI;
    const x = radiusL + (radiusL + radiusS * 2) * Math.cos(angle);
    const y = 40 + (radiusL + radiusS * 2) * Math.sin(angle);
    positionValue = {
      left: `${Math.round(x - radiusS)}px`,
      bottom: `${Math.round(y - radiusS)}px`
    };
    return positionValue;
  };

  return (
    <CircularProgressBarWrapper>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <BackgroundCircle
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(140 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: (dashArray / 360) * 260,
            strokeDashoffset: 0
          }}
        />
        <ProgressCircle
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth - 5}px`}
          transform={`rotate(140 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          stroke={getColors()}
        />
        <ValueText x="50%" y="40%" dy=".3em" textAnchor="middle" fill={labelColor}>
          {textValue}
        </ValueText>
        <UnitText x="50%" y="60%" dy=".3em" textAnchor="middle" fill={labelColor}>
          {unitName}
        </UnitText>
      </svg>
      <TextWrapper>
        <MinText>
          {minValue}
          {unitAbbreviation}
        </MinText>
        <MaxText>
          {maxValue}
          {unitAbbreviation}
        </MaxText>
      </TextWrapper>
      <DotsWrapper>
        {markers.map(
          (marker: MarkerProps, index: number): ReactElement => {
            return (
              <GaugeDot
                color={marker.color}
                key={index}
                position={getDotPosition(marker)}
              />
            );
          }
        )}
      </DotsWrapper>
    </CircularProgressBarWrapper>
  );
};

export { CircularProgressBar };
