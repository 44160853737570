export const canvasConfig = {
  units: 'meters',
  autosave: true,
  autosaveDebounce: 10000,
  projectInVR: false,
  fontAsset: 'assets/fonts/helvetiker_regular.typeface.json',

  wipRoofFaceColor: 0x00f100,
  wipOutlineColor: 0x28a2f6,
  parcelBoundaryColor: 0xff8c00,
  previewObjectColor: 0xfdec00,
  previewObjectInvalidColor: 0xff0000,
  parallelLinesColor: 0xffee00,
  extensionLinesColor: 0x14ff36,
  snapPointColor: 0x0000ff,
  midPointGuideColor: 0xffffff,
  mainServicePanelIconColor: '#09468d',
  gasMeterIconColor: '#F0BA44',
  utilityMeterIconColor: '#09468d',
  subpanelIconColor: '#3a88e1',
  streetLocationIconColor: '#4a4a4a',
  rectangleProtrusionColor: '#9d0007',
  pvModuleDefaultColor: '#000000',
  pvModuleHoveredColor: '#5dc1b9',
  pvModulePositionHoveredColor: '#ffffff',
  pvModulePositionDefaultColor: '#000000',

  snapThreshold: 4,
  smartSnapNearThreshold: 10,
  smartSnapFarThreshold: 30,
  parallelLineThreshold: 10,
  midPointsThreshold: 0.07,

  snapPerpendicularGuide: 0.07,
  perpendicularGuideColor: 0x41c5ca,

  liveAngleThreshold: 0.05,
  liveAngleNormalColor: 0xffffff,
  liveAngleSnapColor: 0x11f1eb,

  objectHoverScale: 2,
  zoomHoverScale: 0.6,

  shortcutTranslate: 't',
  shortcutScale: 's',
  shortcutRotate: 'r',
  shortcutUndo: 'u|Escape',
  shortcutDelete: 'd|Delete|Backspace',
  shortcutRedo: 'y',
  shortcutFocus: 'f',

  shortcutSGParallel: 'p',
  shortcutSGSmartAngles: 'a',
  shortcutSGSnap: 'n'
};
