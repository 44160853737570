import { Vector3 } from 'three';
import type PvModulePosition from '../../../domain/models/SiteDesign/PvModulePosition';
import type { IVertexData } from '../../../domain/entities/SiteDesign/Vertex';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { getParentLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import type { Selectable } from '../../../domain/mixins/Selectable';
import { BaseCommand } from './Command';

export interface IUpdatePvModulePositions extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly pvModulePositions: PvModulePosition[];
}

export class UpdatePvModulePositionsCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly pvModulePositions: PvModulePosition[];

  constructor(dependencies: IUpdatePvModulePositions) {
    super();
    const {
      domain, pvModulePositions
    } = dependencies;
    this.domain = domain;
    this.pvModulePositions = pvModulePositions;
  }

  async execute(): Promise<void> {
    const currentDesign = this.domain.design;
    for (let pvModulePosition of this.pvModulePositions) {
      const roofFaceId = getParentLyraModelByMesh<Selectable>(pvModulePosition.mesh)?.serverId;
      if (!roofFaceId) {
        throw new Error(`Cannot update position ${pvModulePosition.mesh.uuid} as it has no parent`);
      }
      const layout = currentDesign.roofTopArrayAreas.layoutOn(roofFaceId);
      if (!layout) {
        throw new Error(
          `Cannot update position ${pvModulePosition.mesh.uuid} as there is no layout on roof face ${roofFaceId}`
        );
      }
      const updatedVertices = pvModulePosition.boundary.vertices
        .map((vertex: IVertexData): Vector3 => new Vector3(vertex.x, vertex.y, vertex.z))
        .slice(0, 4);
      layout.updatePvModulePosition(pvModulePosition.serverId, pvModulePosition.orientation, updatedVertices);
    }
    currentDesign.state = currentDesign.state.withDesignSyncRequired().withDataNoFurtherThan(DesignStep.ELECTRICAL_BOS);
  }
}
