import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import {
  HightLighView, InputLeftView, InputRightView, InputTextArea, MainText
} from '../styles';

interface ITextAreaRowProps {
  title: string;
  name: string;
  value: string;
  onChange?: (value: string) => void;
}

const TextareaRow: React.FC<ITextAreaRowProps> = (props: ITextAreaRowProps): ReactElement => {
  const {
    title, value: propValue, onChange
  } = props;

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>): void => {
      const value: string = event.currentTarget.value;
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <HightLighView height="129px">
      <InputLeftView>
        <MainText className="mt-xs">{title}</MainText>
      </InputLeftView>

      <InputRightView>
        <InputTextArea value={propValue} onChange={handleChange} />
      </InputRightView>
    </HightLighView>
  );
};

export default TextareaRow;
