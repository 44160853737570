import type {
  CustomValidator, FormValidation, RJSFSchema
} from '@rjsf/utils';
import { encodeFieldName } from '../fieldNameUtils';
import type {
  FormData, FormValidationEffect
} from '../FormOptionsRulesAndState';
import { getValidationRuleByName } from '../validation';

export default (
  apiValidation: FormValidationEffect[] | undefined, schema: RJSFSchema
): CustomValidator => {
  if (!apiValidation) {
    return (
      _: FormData, errors: FormValidation
    ): FormValidation => errors;
  }
  const validationRules = apiValidation.map((apiValidationRule: FormValidationEffect) => {
    const validationRule = getValidationRuleByName(apiValidationRule.predicate);
    const encodedFieldName = encodeFieldName(apiValidationRule.field);
    return (
      formData: FormData,
      errors: FormValidation
    ) => validationRule(
      encodedFieldName, apiValidationRule, schema, formData, errors
    );
  });
  return (
    formData: FormData, errors: FormValidation
  ): FormValidation => {
    validationRules.forEach((rule): void => rule?.(
      formData, errors
    ));
    return errors;
  };
};
