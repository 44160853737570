import type { FC } from 'react';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { rootStore } from '../../../stores/Store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuroraNotificationHandler: FC<{ DS: any }> = (props) => {
  rootStore.auroraToastManager = props.DS?.useNotifications();

  return <>{props.children}</>;
};

export const AuroraNotificationHandlerWrapper: FC = ({ children }) => {
  const DS = useContext(ThemeContext)!.DS;
  if (!DS) {
    return <>{children}</>;
  }
  return (
    <DS.NotificationManager mountTarget={document.querySelector('#design-tool-ds-mount-target')}>
      <AuroraNotificationHandler DS={DS}>{children}</AuroraNotificationHandler>
    </DS.NotificationManager>
  );
};
