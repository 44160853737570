import type { ReactElement } from 'react';
import * as React from 'react';
import { MathUtils as ThreeMath } from 'three';
import { RoofTracingTools } from '../../../../../../stores/UiStore/ToolbarStore/Project/RoofTracingTools';
import withTool from '../../../Shared/withTool';
import { ImportModel } from './ImportModel';
import { Outline } from './Outline';
import { TraceIndividualRoofFace } from './TraceIndividualRoofFace';

const SubTools: ReactElement[] = [
  <TraceIndividualRoofFace key={ThreeMath.generateUUID()} />,
  <Outline key={ThreeMath.generateUUID()} />,
  <ImportModel key={ThreeMath.generateUUID()} />
];

export const TraceRoof = withTool(RoofTracingTools, undefined, SubTools);
