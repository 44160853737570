import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import type { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { MathUtils as ThreeMath } from 'three';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import type { Color } from '../../../domain/typings';
import LineVertical from '../LineVertical';
import {
  Button, ButtonGroupStyle, ButtonWrapper
} from './styles';

export type ButtonOption = {
  name: string | ReactElement;
  value: string | number;
  caption?: string;
};

type Props = BaseProps & {
  selected?: string | number;
  selectedOptionColor?: EThemeComponentColor | undefined;
  onSelect?: (value: string | number) => void;
  options: ButtonOption[];
  height?: string;
  width?: string;
  withCaption?: boolean;
  withBorder?: boolean;
  buttonBackground?: Color;
  divider?: boolean;
  hideElement?: boolean;
};

function ButtonGroup({
  buttonBackground,
  className,
  divider = false,
  height,
  onSelect,
  options,
  selected,
  width,
  withBorder = false,
  withCaption = false,
  hideElement = false,
  selectedOptionColor
}: Props): ReactElement {
  const [selVal, setSelVal] = useState(selected);

  useEffect((): void => {
    setSelVal(selected);
  }, [selected]);

  const handleSelect = useCallback(
    (value: string | number): void => {
      onSelect?.(value);
      setSelVal(value);
    },
    [onSelect]
  );

  const optionsLength = options.length;

  const optionsElements = options.map((option: ButtonOption, index: number): ReactElement[] => {
    const result: ReactElement[] = [];
    const btnElement = (
      <ButtonWrapper key={index}>
        <Button
          height={height}
          width={width}
          selected={option.value === selVal}
          onClick={(): void => handleSelect(option.value)}
          data-testid={`button-group-${option.caption}`}
          buttonBackground={buttonBackground}
          withBorder={withBorder}
          hideElement={hideElement}
          selectedOptionColor={selectedOptionColor}
          className={className}
        >
          {option.name}
        </Button>
        {withCaption && (
          <LyraTypography.Heading margin="5px 0" fontSize="10px">
            {option.caption}
          </LyraTypography.Heading>
        )}
      </ButtonWrapper>
    );
    result.push(btnElement);
    const dividerElement = divider && index !== optionsLength - 1 && (
      <LineVertical key={ThreeMath.generateUUID()} height={height} />
    );
    if (dividerElement) {
      result.push(dividerElement);
    }
    return result;
  });

  return <ButtonGroupStyle className={className}>{...optionsElements}</ButtonGroupStyle>;
}

export default ButtonGroup;
