import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import { useUiStore } from '../../../../../stores/useStore';
import type { SlopeRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/SlopeRoofFaceViewModel';
import CustomHouse from '../../../../components/House';
import SlopeButton from '../../../../components/SlopeButton/SlopeButton';
import { precisionNumber } from '../../../../../utils/helpers';
import {
  LABEL_SLOPE, DENOMINATOR_PITCH
} from '../../constants';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';
import CustomSlope from '../CustomSlope/CustomSlope';
import { pitchToDegrees } from '../../../../../utils/slope';
import {
  CustomSlopeFieldSet, SlopeButtonsList
} from './styles';

const SlopeRoofFace = (): ReactElement => {
  const {
    roofFaceProps, properties
  } = useUiStore();
  const viewModel = roofFaceProps.currentRoofPropertyEditor as SlopeRoofFaceViewModel;

  useEffect((): void => {
    properties.setTitle(LABEL_SLOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listSlopeButtons = viewModel.slopeOptions.map((roofRise: number, index: number): ReactElement => {
    const angle = pitchToDegrees(roofRise);

    // Highlight specific predefined slope button
    // if slope value is withing +-0.01 range difference.
    const diff = precisionNumber(angle - viewModel.slopeValueInDegrees, 2);
    const isSelected = Math.abs(diff) <= 0.01;

    return (
      <SlopeButton
        key={index}
        selected={isSelected}
        className="slope-btn"
        denominator={DENOMINATOR_PITCH}
        numerator={roofRise}
        angle={angle}
        onClick={viewModel.selectPredefinedSlope}
      />
    );
  });

  return (
    <RoofPropertiesParent viewModel={viewModel} multiEdit={false}>
      <CustomHouse slope={Number(viewModel.slopeValueInDegrees)} />

      <SlopeButtonsList>{listSlopeButtons}</SlopeButtonsList>

      <CustomSlopeFieldSet label="CUSTOM SLOPE">
        <CustomSlope viewModel={viewModel} />
      </CustomSlopeFieldSet>
    </RoofPropertiesParent>
  );
};

export default observer(SlopeRoofFace);
