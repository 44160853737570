import type {
  BaseAttributes, IOption
} from '../../../domain/models/SiteDesign/IOption';
import type {
  Coords, Dictionary
} from '../../../domain/typings';
import Http from '../Http';
import config from '../../../config/config';

export class AuthoritiesService {
  get baseURL(): string {
    return config.api.authorities;
  }

  async getAuthorityHavingJurisdictionOptions(
    state: string,
    coordinates: Coords,
    requiredAhjId?: string | number
  ): Promise<IOption<BaseAttributes>[]> {
    const queryParams: Dictionary<string | number | boolean> = {
      state,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude
    };
    if (requiredAhjId) {
      queryParams.requiredAhjId = requiredAhjId;
    }
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/authorities-having-jurisdiction'),
      queryParams
    );
    return response.data;
  }
}
