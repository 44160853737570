import type { IObservableArray } from 'mobx';
import { computed } from 'mobx';
import type {
  Intersection, Vector3
} from 'three';
import type { IBaseProtrusionToolDependencies } from '../ProtrusionTools/ProtrusionTool';
import type { IDrawableStructureFactory } from '../../../../../domain/models/StructureFactory';
import type { RoofProtrusion } from '../../../../../domain/models/SiteDesign/RoofProtrusion';
import type DomainStore from '../../../../DomainStore/DomainStore';
import { BaseCastObjectControl } from '../../../../EditorStore/Controls/BaseCastObjectControl';
import type {
  IPointerDownControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../../../EditorStore/Controls/ControlEvents';
import type { PropertiesStore } from '../../../Properties/Properties';
import type { RoofProtrusionStore } from '../../../Properties/RoofProtrusion/RoofProtrusionStore';
import type { IAddProtrusionDependencies } from '../../../../ServiceBus/Commands/AddProtrusionCommand';
import { RECTANGULAR_PROTRUSION_TOOL_ID } from '../constants';
import { canvasConfig } from '../../../../../config/canvasConfig';
import { SelectionControl } from '../../../../EditorStore/Controls/SelectionControl';
import type SmartGuidesStore from '../../../SmartGuidesStore/SmartGuidesStore';
import type {
  IHandleClicksTool, IHandleMoveTool
} from '../../Tool';
import { BaseTool } from '../../Tool';
import { PropsPanelUICodes } from '../../../Properties/propertiesStoreConstants';
import { SceneObjectType } from '../../../../../domain/models/Constants';
import type { Selectable } from '../../../../../domain/mixins/Selectable';
import { RectangleProtrusion } from '../../../../../domain/models/SiteDesign/RectangleProtrusion';
import { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';

export class RectangularProtrusionTool extends BaseTool implements IHandleClicksTool, IHandleMoveTool {
  id: string = RECTANGULAR_PROTRUSION_TOOL_ID;
  icon: string = 'protrusions';
  title: string = 'Protrusion';
  description: string = this.title;
  override testId: string = 'TraceRectangularProtrusion';

  private inProgress: boolean = false;
  private castObjectControl?: BaseCastObjectControl;
  private intersectedRoofFace?: RoofFace;
  private wipProtrusion?: RectangleProtrusion;
  private properties: PropertiesStore;
  private domain: DomainStore;
  private drawableObjectsFactory: IDrawableStructureFactory;
  private roofProtrusion: RoofProtrusionStore;
  private selectionControl?: SelectionControl;
  private smartGuides: SmartGuidesStore;

  constructor(dependencies: IBaseProtrusionToolDependencies) {
    super(dependencies);
    this.properties = dependencies.properties;
    this.domain = dependencies.domain;
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
    this.roofProtrusion = dependencies.roofProtrusion;
    this.smartGuides = dependencies.smartGuides;
  }

  whenSelected(): void {
    this.init();

    this.properties.setPropertyPanel(PropsPanelUICodes.SiteStructure);

    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.addEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);

    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
  }

  whenDeselected(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
    // Unselect all protrusions
    this.roofProtrusion.unSelectAll();
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    const {
      target, pointerStart
    } = event;
    // Validate if there is any roofFace intersected
    const intersections: Intersection[] = this.castObjectControl!.getCastedObjects();
    const firstSelected: Selectable | null = this.selectionControl!.getFirstSelectableFromIntersections(intersections);
    if (target === undefined || pointerStart === undefined || !(firstSelected instanceof RoofFace)) {
      return;
    }

    const pointerWorldCoords = target.unprojectMouseToFrustum(pointerStart);

    // Reconfigure castedObjectcontrol in order to only allow drawing
    // through the current roof face
    this.intersectedRoofFace = firstSelected;
    this.configureBaseCastObjectControl([this.intersectedRoofFace]);
    // Add protrusion to array, add protrusion to scene
    this.createProtrusion(pointerWorldCoords);
  };

  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    const {
      target, pointerPosition
    } = event;
    const protrusion = this.wipProtrusion;

    if (target === undefined || pointerPosition === undefined) {
      return;
    }

    const pointerWorldCoords = target.unprojectMouseToFrustum(pointerPosition);

    if (this.inProgress && protrusion) {
      protrusion.calculateProtrusion(pointerWorldCoords, this.intersectedRoofFace);
    }
  };

  override onMouseLeave = (event: IPointerMoveControlEvent): void => {
    // const { target, pointerPosition } = event;
    //
  };

  override onMouseUp = (event: IPointerUpControlEvent): void => {
    const protrusion = this.wipProtrusion;

    if (this.inProgress && protrusion) {
      const isLargeEnough = protrusion.hasRightDimensions();
      if (!isLargeEnough) {
        protrusion.notifyTooSmall();
      }
      const isValid =
        isLargeEnough
        && protrusion.isValidAgainstRoofFace(this.intersectedRoofFace!)
        && protrusion.isValidAgainstProtrusions(this.intersectedRoofFace!);

      if (!isValid) {
        // Remove current
        this.editor.removeObject(protrusion.mesh);
        this.init();
        return;
      }

      const commandDependencies: IAddProtrusionDependencies = {
        domain: this.domain,
        protrusion,
        roofFace: this.intersectedRoofFace!
      };
      this.serviceBus.send('add_protrusion', commandDependencies);

      // Show roof protrusions container in right panel
      this.roofProtrusion.createProtrusionViewModel('rectangular', protrusion, this.intersectedRoofFace!);
      // Show property panel of protrusions
      this.properties.setPropertyPanel(PropsPanelUICodes.RoofProtrusions);
    }

    this.init();
  };

  dispose(): void {
    /** */
  }

  private init(): void {
    this.inProgress = false;
    // Set up BaseCastObjectControl
    this.configureBaseCastObjectControl(this.editor.getObjectsByType(SceneObjectType.RoofFace));
  }

  private configureBaseCastObjectControl(objects: Selectable[]): void {
    this.castObjectControl = new BaseCastObjectControl(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera!,
      objects
    );
  }

  private getLastValue(protrusions: IObservableArray<RoofProtrusion>): number {
    let higherValue: number = 0;
    protrusions.forEach((protrusion: RoofProtrusion): void => {
      const val: number = Number(protrusion.name.split('#')[1]);
      higherValue = val > higherValue ? val : higherValue;
    });
    return higherValue + 1;
  }

  private createProtrusion(position: Vector3): void {
    // Use drawable objects factory
    const protrusion = this.drawableObjectsFactory.create<RectangleProtrusion>(RectangleProtrusion, {
      color: canvasConfig.rectangleProtrusionColor
    });

    protrusion.name = `Protrusion #${this.getLastValue(this.intersectedRoofFace!.protrusions)}`;
    protrusion.vertexColor = canvasConfig.rectangleProtrusionColor;
    protrusion.setInitialCorner(position);
    this.editor.addOrUpdateObject(protrusion.mesh);

    this.wipProtrusion = protrusion;
    this.inProgress = true;
  }

  // Overriding isDisabled,
  // If there is not roof faces, tool is disabled
  @computed
  override get isDisabled(): boolean {
    return !this.domain.hasRoofFaces;
  }
}
