import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import { EquipmentExposureTypes } from '../../../../domain/entities/SiteDesign/EquipmentExposure';
import type { IElectricalEquipmentData } from '../../../../domain/entities/SitePlan/SitePlan';
import config, { UI_MODE } from '../../../../config/config';
import {
  AuroraInverterItemWrapper,
  ButtonView,
  IconView,
  InverterDescription,
  InverterItem,
  InverterItemWrapper,
  InverterTitle,
  MarkerIcon,
  StatusButton,
  StatusToggleContainer,
  TextView
} from './styles';

const { Toggle } = LyraFormElementsDesignTool.FormElementsDesignTool.Toggle;

type Props = {
  equipment: IElectricalEquipmentData[];
  placed: boolean;

  onIconClick: (equipment: IElectricalEquipmentData) => void;
  onExposureChange: (id: string, exposure: EquipmentExposureTypes, placed: boolean) => void;
};

const EquipmentItem = observer((props: Props): ReactElement => {
  const {
    equipment, placed, onIconClick, onExposureChange
  } = props;

  const Wrapper = config.featureFlag.uiMode === UI_MODE.AURORA ? AuroraInverterItemWrapper : InverterItemWrapper;

  return (
    <Wrapper>
      {equipment.map(
        (item: IElectricalEquipmentData, index: number): ReactElement => (
          <InverterItem key={index}>
            <IconView onClick={(): boolean | void => !placed && onIconClick(item)}>
              <MarkerIcon $active={placed} name="site-equipment" />
              <InverterTitle>{item.label}</InverterTitle>
            </IconView>
            <TextView>
              <InverterDescription>{item.description}</InverterDescription>
              {item.exposure && !item.exposure.constrained && (
                <ButtonView>
                  {config.featureFlag.uiMode === UI_MODE.AURORA ? (
                    <StatusToggleContainer>
                      <Toggle
                        id={`isEquipmentIndoors-${index}`}
                        checked={item.exposure.type === EquipmentExposureTypes.INDOOR}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onExposureChange(
                            item.id,
                            event.target.checked ? EquipmentExposureTypes.INDOOR : EquipmentExposureTypes.OUTDOOR,
                            placed
                          )
                        }
                        label="Indoor"
                      />
                    </StatusToggleContainer>
                  ) : (
                    <>
                      <StatusButton
                        active={item.exposure.type === EquipmentExposureTypes.INDOOR}
                        onClick={(): void => onExposureChange(item.id, EquipmentExposureTypes.INDOOR, placed)}
                      >
                        Indoor
                      </StatusButton>
                      <StatusButton
                        active={item.exposure.type === EquipmentExposureTypes.OUTDOOR}
                        onClick={(): void => onExposureChange(item.id, EquipmentExposureTypes.OUTDOOR, placed)}
                      >
                        Outdoor
                      </StatusButton>
                    </>
                  )}
                </ButtonView>
              )}
            </TextView>
          </InverterItem>
        )
      )}
    </Wrapper>
  );
});

export default EquipmentItem;
