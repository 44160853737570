import React, { MouseEvent, ReactElement } from 'react';
import styled, { css, PropsTheme, SimpleInterpolation, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { BaseReactProps } from '../../typings/BaseReactProps';

type LinkProps = BaseReactProps & {
  color?: string;
  bold?: boolean;
  href: string;
  onClick?: (event: MouseEvent) => void;
};

const StyledLink = styled.a<PropsTheme<LinkProps>>`
  color: ${({ theme, color }: PropsTheme<LinkProps>): Color =>
    color || theme.colors.primary};
  font-family: ${({ theme }: PropsTheme<LinkProps>): string =>
    theme.fonts.fontFamily};
  text-decoration: none;
  padding: 0 1px 2px;
  margin: 0 -1px 2px;
  position: relative;

  ${({ bold }: PropsTheme<LinkProps>): SimpleInterpolation =>
    bold ? css`font-weight: bold;` : css``}

  &::after, &::before {
    content: '';
    width: 100%;
    position: absolute;
    margin: auto 0 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::after {
    border-bottom: 1px dotted ${
      ({ theme, color }: PropsTheme<LinkProps>): Color =>
      color || theme.colors.primary
  };
  }

  &::before {
      transform-origin: left;
      border-bottom: 1px solid ${
        ({ theme, color }: PropsTheme<LinkProps>): Color =>
        color || theme.colors.primary
      };
      transition: transform 250ms ease-in-out;
      transform: translate3d(0,0,0) scaleX(0);
  }

  &:hover::before {
      transform: translate3d(0,0,0) scaleX(1);
  }

  &:active::before {
      transform: translate3d(0,2px,0) scaleX(1);
  }
`;

export const Link = (props: LinkProps): ReactElement => <StyledLink {...props} />;
