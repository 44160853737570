import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  Fragment, useState
} from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import type {
  IDcOptimizerStringOptionsData,
  IStringingOptionData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import useStore from '../../../../stores/useStore';
import type {
  StringConnected,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import {
  ContentItem,
  ContentText,
  ISCWrapper,
  ItemText,
  DcOptimizerIcon,
  DcOptimizerItem,
  DcOptimizerText,
  DcOptimizerWrapper,
  PanelHeader,
  PanelText,
  ProgressItemContainer,
  DeleteButtonWrapper,
  DeleteIcon,
  ProgressWrapper,
  TextWrapper,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperValueText
} from './styles';

type Props = BaseProps & {
  index: number;
  onDeleteActiveString: () => void;
};

const {
  CircularProgressBar, StepDottedProgressBar, LinearProgressBar
} = LyraProgressBar;

const VOLTS_MAX_VALUE = 60;

const ModulesInStringComponent = observer((props: Props): ReactElement => {
  let numberOfModules = 0;
  let maxContinuousOutputCurrent = 0;
  const {
    index = 0, onDeleteActiveString
  } = props;
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;
  const stringsConnected = systemSummaryList[index].strings;
  if (stringsConnected.length) {
    const existingString =
      stringsConnected.find((item: StringConnected): boolean => item.getId() === selectedString) || stringsConnected[0];
    const stringingOption: IStringingOptionData | undefined =
      (existingString?.stringingOption as IStringingOptionData) ?? undefined;
    numberOfModules = stringingOption?.numberOfModules ?? 0;
    maxContinuousOutputCurrent = stringingOption?.maxContinuousOutputCurrent ?? 0;
  }
  const {
    optimizerDefinition, pvInput
  } = systemSummaryList[index].definition as IDcOptimizerStringOptionsData;
  const minInputVoltage = optimizerDefinition?.minInputVoltage ?? VOLTS_MAX_VALUE / 3;
  const maxInputVoltage = optimizerDefinition?.maxInputVoltage ?? (VOLTS_MAX_VALUE / 3) * 2;

  const vmpAtDesignHighTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Not Optimal Voltage, But Still Functional',
      color: '#90C725',
      value: maxInputVoltage - 1
    }
  ];

  const vocAtDesignLowTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Upper Voltage',
      color: '#D34A4A',
      value: maxInputVoltage - 1
    }
  ];

  return (
    <Fragment>
      <ContentItem>
        <Flexbox justify="space-between" align="center" className="pt-xs">
          <ContentText className="mt-xxs">Modules in string</ContentText>
          {numberOfModules > 0 && (
            <DeleteButtonWrapper onClick={onDeleteActiveString}>
              <DeleteIcon name="icon-delete" />
            </DeleteButtonWrapper>
          )}
        </Flexbox>
        <ProgressItemContainer>
          <StepDottedProgressBar
            currentValue={numberOfModules}
            minValue={optimizerDefinition.minNumberOfOptimizersPerString || 0}
            maxValue={optimizerDefinition.maxNumberOfOptimizersPerString || 0}
            possibleMinValue={0}
            possibleMaxValue={optimizerDefinition.maxNumberOfOptimizersPerString + 1}
            size="small"
          />
        </ProgressItemContainer>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>DC Power @ STC</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{pvInput.pStc * numberOfModules}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>DC output current</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{maxContinuousOutputCurrent}</ValueText>
            <UnitText>A</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        <PanelHeader className="mt-s" black>
          <PanelText>DC Optimizer</PanelText>
        </PanelHeader>
        <DcOptimizerWrapper>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={pvInput.vmpAtDesignHighTemperature}
              markers={vmpAtDesignHighTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
            />
            <TextWrapper>
              <DcOptimizerIcon name="highTemp" />
              <DcOptimizerText>Vmp at high temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={pvInput.vocAtDesignLowTemperature}
              markers={vocAtDesignLowTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
              outOfRangeLabelColor="#D34A4A"
            />
            <TextWrapper>
              <DcOptimizerIcon name="lowTemp" />
              <DcOptimizerText>Voc at low temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
        </DcOptimizerWrapper>
        <ISCWrapper>
          <DcOptimizerItem>
            <TextWrapper>
              <DcOptimizerIcon name="highTemp" />
              <DcOptimizerText>Isc at high temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
          <DcOptimizerItem>
            <ProgressWrapper>
              <LinearProgressBar
                units={'A'}
                minValue={0}
                maxValue={optimizerDefinition?.maxInputIsc || 100}
                currentValue={pvInput.iscAtDesignHighTemperature}
                strokeColor={'#36A247'}
                size="min"
              />
            </ProgressWrapper>
          </DcOptimizerItem>
        </ISCWrapper>
      </ContentItem>
    </Fragment>
  );
});

export default ModulesInStringComponent;
