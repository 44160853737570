import {
  LyraLayout,
  LyraTheme
} from '@aurorasolar/lyra-ui-kit';
import { configure } from 'mobx';
import { useJsApiLoader } from '@react-google-maps/api';
import type {
  EffectCallback, ReactElement
} from 'react';
import React, {
  memo, useEffect, useRef, useState, Fragment, useCallback
} from 'react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import Modal from './ui/containers/Modals/Modal';
import config, {
  addConfigFromDesignToolComponentProps, UI_MODE
} from './config/config';
import { BaseImageryProvider } from './domain/typings';
import type {
  IHostAppConfig, IUserConfig
} from './domain/typings/Config';
import Http from './infrastructure/services/Http';
import {
  SentryException, SentrySetup
} from './utils/sentryLog';
import { rootStore } from './stores/Store';
import context from './stores/context';
import { UpsertInstallerAndCreateProjectViewModel } from './stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import TitleUpdater from './ui/containers/TitleUpdater';
import './styles/global.css';
import { BaseImageryOptions } from './ui/containers/MapOptions/BaseImageryOptions';
import type {
  ExternalProposalData, IAdditionalProjectData
} from './domain/models/SiteDesign/Project';
import { QuickStartGuideViewModel } from './stores/UiStore/Modal/ViewModels/QuickStartGuideViewModel/QuickStartGuideViewModel';
import {
  handleApiError, throwWrapper
} from './utils/helpers';
import * as Storage from './infrastructure/services/WebStorage';
import { MouseClicksIgnoringTransparentLayer } from './ui/containers/MouseClicksIgnoringTransparentLayer';
import {
  get, set
} from './infrastructure/services/WebStorage';
import BottomPanel from './ui/containers/BottomPanel/BottomPanel';
import { ConfirmCommandModal } from './ui/containers/ConfirmCommandModal/ConfirmCommandModal';
import FloatingElements from './ui/containers/FloatingElements/FloatingElements';
import ModalStreetView from './ui/containers/FloatingElements/modal';
import ProgressStepper from './ui/containers/ProgressStepper/ProgressStepper';
import PropertyPanel from './ui/containers/PropertyPanel';
import Toolbar from './ui/containers/Toolbar/Toolbar';
import Topbar from './ui/containers/Topbar/Topbar';
import Panel from './ui/containers/Panels/Panel';
import DrawViewport from './ui/containers/DrawViewport';
import { ProjectOpenedInDesignToolEvent } from './services/analytics/DesignToolAnalyticsEvents';
import WizardModal from './ui/containers/Wizard/WizardModals';
import WizardPersistant from './ui/containers/Wizard/WizardPersistant';
import type { IAnalytics } from './services/analytics/IAnalytics';
// import * as DS from '@aurorasolar/ds';

configure({ isolateGlobalState: true });

declare type Library =
  | 'core'
  | 'maps'
  | 'places'
  | 'geocoding'
  | 'routes'
  | 'marker'
  | 'geometry'
  | 'elevation'
  | 'streetView'
  | 'journeySharing'
  | 'drawing'
  | 'visualization';
const GOOGLE_MAP_LIBRARIES: Library[] = ['places'];

// Failsafe to avoid hypothetical situation of infinite page reloads:
const pageReloadCooldownInMs = 3 * 1000;
function reloadPage(): void {
  const nowInMs = new Date().getTime();
  const lastPageReloadTimeInMs = get<number>('lyra_lastPageReloadTimeInMs', false) ?? 0;
  if (nowInMs - lastPageReloadTimeInMs > pageReloadCooldownInMs) {
    set<number>('lyra_lastPageReloadTimeInMs', nowInMs);
    setTimeout((): void => window.location.reload(), 400);
  }
}

export interface IAppProps {
  /**
   * Information about an existing project
   */
  readonly project?: {
    /**
     * ID of an already existing project that should be reopened in the design tool.
     * If this is blank, a project creation dialog will be displayed.
     */
    readonly id?: string;
    /**
     * Externally-managed project data
     */
    readonly externalData?: IAdditionalProjectData;
  };
  /**
   * External integration project data, may be incomplete.
   * It's not processed by frontend, so we're just passing it to the backend.
   */
  readonly externalProposalDesign?: ExternalProposalData;
  readonly jwtToken: string;
  readonly generateNewTokenCallback: () => Promise<string>;
  readonly user: IUserConfig;
  readonly host: IHostAppConfig;
  readonly analytics?: IAnalytics;
  readonly sentryEnabledInHost?: boolean;
  readonly forwardToPermitPackageDownload?: boolean;
  readonly showEditInstaller?: boolean;
  readonly uiMode?: UI_MODE;
}

const loadProjectById = async (
  projectId: string,
  externallyManagedAdditionalProjectData?: IAdditionalProjectData
): Promise<void> => {
  await rootStore.editor.editorSetupPromise;
  const project = await rootStore.domain.loadProjectById(projectId, {
    editor: rootStore.editor,
    roofProtrusion: rootStore.uiStore.roofProtrusion,
    workspace: rootStore.uiStore.workspace,
    externallyManagedAdditionalProjectData
  });
  const siteEquipmentInstancesCount = Object.keys(project?.site.equipment?.instances ?? {}).length;
  if (project?.site.buildings?.length === 0 && siteEquipmentInstancesCount === 0) {
    openQuickStartGuideModal();
  }
};

const openCreateProjectModal = async (): Promise<void> => {
  await rootStore.editor.editorSetupPromise;
  const createProjectViewModel = new UpsertInstallerAndCreateProjectViewModel({
    modal: rootStore.uiStore.modal,
    domain: rootStore.domain,
    editor: rootStore.editor,
    startupMode: true
  });
  rootStore.uiStore.modal.createModal('upsert_installer_and_create_project_modal', createProjectViewModel);
  await createProjectViewModel.projectCreationCompletionPromise;

  openQuickStartGuideModal();
};

const openQuickStartGuideModal = (): void => {
  const quickStartGuideViewModel = new QuickStartGuideViewModel({
    modal: rootStore.uiStore.modal,
    domain: rootStore.domain,
    editor: rootStore.editor,
    wizard: rootStore.uiStore.wizard,
    roofProtrusion: rootStore.uiStore.roofProtrusion,
    serviceBus: rootStore.serviceBus,
    toolbar: rootStore.uiStore.toolbar,
    workspace: rootStore.uiStore.workspace
  });
  rootStore.uiStore.modal.createModal('quick_start_guide_modal', quickStartGuideViewModel);
};

/**
 * To avoid unnecessary re-rendering the memoization solution is used.
 * MemoizedAppWrapper converts params to JSON string so that React.memo will work
 * by comparing old params as a string against new params as a string. This way even if
 * object references changed - component will not re-render unless the actual values in
 * that object change. So the only duty of MemoizedAppWrapper is to convert params to
 * JSON string, MemoizedApp is where the actual memoization is applied, and params
 * are converted back to objects and passed to App. App separation is not necessary,
 * but may be easier to comprehend as it's separated from the memoized component.
 */
function MemoizedAppWrapper(props: IAppProps): ReactElement {
  // Do not use error boundary if we have it on a host app level (with sentry)
  const WrapperComponent = props.sentryEnabledInHost ? Fragment : Sentry.ErrorBoundary;
  // Override feature flags inside config.
  if (props.showEditInstaller !== undefined) {
    config.featureFlag.installer.showEdit = props.showEditInstaller;
  }

  // static configuration:
  // local storage configuration:
  config.featureFlag.uiMode = (localStorage.forceAuroraMode === 'true')
    ? UI_MODE.AURORA
    : (props.uiMode ?? UI_MODE.LYRA);

  // Separate non-serializable properties from serializable ones
  const {
    analytics, generateNewTokenCallback, ...simpleProps
  } = props;
  const generateNewTokenCallbackStaticRef = useCallback(() => {
    return generateNewTokenCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const serializedSimpleProps: string = JSON.stringify(simpleProps);
  return (
    <WrapperComponent>
      <MemoizedApp
        propsJson={serializedSimpleProps}
        generateNewTokenCallback={generateNewTokenCallbackStaticRef}
        analytics={analytics}
      />
    </WrapperComponent>
  );
}

type NonserializableMembersOfIAppProps = Pick<IAppProps, 'generateNewTokenCallback' | 'analytics'>;
type MemoizedAppProps = { propsJson: string } & NonserializableMembersOfIAppProps;

const MemoizedApp = memo(({
  propsJson, generateNewTokenCallback, analytics
}: MemoizedAppProps): ReactElement => {
  const props: IAppProps = {
    ...JSON.parse(propsJson),
    generateNewTokenCallback,
    analytics
  };
  return <App {...props} />;
});

const extraThemeProps: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DS?: any;
} = {};

function App(props: IAppProps): ReactElement {
  const [loaded, setLoaded] = useState(false);
  const {
    jwtToken, generateNewTokenCallback, user, host, analytics: externalAnalytics
  } = props;

  // Add config from externally provided config
  addConfigFromDesignToolComponentProps(host, user, externalAnalytics, props.forwardToPermitPackageDownload);

  // Clear session storage on page refresh; it would clear cached API responses
  useEffect(() => {
    const clearSessionStorageOnRefresh = (): void => {
      const performanceNavigationTimingEntries = window.performance.getEntriesByType('navigation');
      const doesPerformanceNavigtionTimingIncludeReload = performanceNavigationTimingEntries
        .map((nav) => (nav as PerformanceNavigationTiming).type)
        .includes('reload');
      // Note: this checks the same in two ways - one that is current, and one that is deprecated
      const didUserRefreshPage =
        (window.performance.navigation
          && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD)
        || doesPerformanceNavigtionTimingIncludeReload;

      if (didUserRefreshPage) {
        Storage.clearSessionStorage();
      }
    };

    window.addEventListener('beforeunload', clearSessionStorageOnRefresh);

    return () => {
      window.removeEventListener('beforeunload', clearSessionStorageOnRefresh);
    };
  }, []);

  // Initialize Sentry
  useEffect((): void => {
    if (!props.sentryEnabledInHost) {
      SentrySetup();
    } else {
      // eslint-disable-next-line no-console
      console.log('Sentry is enabled in host app. Design tool sentry is disabled.');
    }
  }, [props.sentryEnabledInHost]);

  // If existing project ID was not passed, then we need to create it within the design tool
  const existingProjectId = props.project?.id;
  const externalProposalData = props.externalProposalDesign;

  // Set JWT auth token from the outside
  Http.updateMapStoreOnTokenUpdate = useCallback((token) => {
    rootStore.uiStore.map.setAuthToken(token);
  }, []);
  Http.setToken(jwtToken);
  Http.setCallbackRenewToken(generateNewTokenCallback);

  let isGoogleMapsLoaded = true;
  const useJsApiLoaderResult = useJsApiLoader({
    id: 'lyra-google-map',
    nonce: 'lyra-google-map',
    language: 'en',
    libraries: GOOGLE_MAP_LIBRARIES,
    googleMapsApiKey: config.baseImageryConfig(BaseImageryProvider.GOOGLE_MAPS).options.key
  });
  isGoogleMapsLoaded = useJsApiLoaderResult.isLoaded;

  const externallyManagedAdditionalProjectData = props.project?.externalData;

  // Handling analytics in a separate hook so that other hook would not react
  // to changes in data required only for analytics.
  useEffect((): ReturnType<EffectCallback> => {
    if (existingProjectId) {
      externalAnalytics?.trackEvent(new ProjectOpenedInDesignToolEvent(existingProjectId));
    }
  }, [existingProjectId, externalAnalytics]);

  useEffect((): ReturnType<EffectCallback> => {
    const storeVersion = rootStore.storeVersion;
    if (loaded) {
      // eslint-disable-next-line no-console
      console.error(
        'DesignTool props have changed (existingProjectId or externallyManagedAdditionalProjectData). '
          + 'Currently we don\'t support changing this props after their initial values have been handled. '
          + 'Ignoring this change. '
          + `existingProjectId=${existingProjectId}, `
          + `externallyManagedAdditionalProjectData=${externallyManagedAdditionalProjectData}`
      );
      return;
    }

    if (isGoogleMapsLoaded) {
      rootStore.uiStore.map.setGoogleMapsFromGlobal();
      setLoaded(true);

      rootStore.awaitReinitializationPromise.finally((): void => {
        (async (): Promise<void> => {
          if (storeVersion !== rootStore.storeVersion) {
            return;
          }
          rootStore.domain.resetDesign();

          if (existingProjectId) {
            // Here we load project by id passed from host app as prop.
            loadProjectById(existingProjectId, externallyManagedAdditionalProjectData);
          } else if (externalProposalData) {
            const projectCreatedSuccessfully = await rootStore.domain.createProjectUsingExternalProposalData(
              externalProposalData
            );
            if (projectCreatedSuccessfully) {
              openQuickStartGuideModal();
            } else {
              openCreateProjectModal();
            }
          } else {
            openCreateProjectModal();
          }
        })();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Don't depend on *loaded* to avoid printing an irrelevant error message (because *loaded* is changed inside)
    existingProjectId,
    externallyManagedAdditionalProjectData,
    isGoogleMapsLoaded,
    externalProposalData
  ]);

  useEffect(() => {
    return (): void => {
      // Reloading page when we're unmounting the app, usually it happens when user goes back to the dashboard.
      // Reloading is needed to reset all state properly and avoid bugs when users are switching between projects.
      reloadPage();
    };
  }, []);

  useEffect(() => {
    return () => {
      if (loaded) {
        rootStore.reset();
      }
    };
  }, [loaded]);

  const storeNodeRef = useRef(null);
  useEffect(() => {
    if (storeNodeRef.current) {
      // @ts-ignore
      storeNodeRef.current.getStore = (): Store => rootStore;
    }
  }, [storeNodeRef]);

  let CrushButton = <></>;
  if (sessionStorage.getItem('sentryTestDrive') === 'on') {
    CrushButton = (
      <>
        <button
          onClick={(): void => {
            throwWrapper();
          }}
        >
          |Break design tool|
        </button>
        <button
          onClick={(): void => {
            SentryException(
              'test message',
              {},
              {
                message: 'kdhfygvnaiuydvgmadisuyvhmcdsuyivhmfduyvhbmdsuyfbvmffdiujbvm'
              }
            );
          }}
        >
          |sentry exception|
        </button>
        <button
          onClick={(): void => {
            const error = new AxiosError('fake error', '500');
            // @ts-ignore
            error.response = new Error('true error');
            handleApiError('test api error', {
              domainStoreJson: rootStore.domain.toJson()
            })(error);
          }}
        >
          |api error exception|
        </button>
      </>
    );
  }

  useEffect(() => {
    if (sessionStorage.getItem('sentryTestDrive.instant') === 'on') {
      throwWrapper();
    }
  }, []);

  // The actual lib is taken and passed from extraThemeProps, but we still use
  // React state to trigger re-rendering.
  const [DsLib, setDsLib] = useState(null);
  useEffect(() => {
    if (
      // true
      config.featureFlag.uiMode === UI_MODE.AURORA
    ) {
      import('@aurorasolar/ds').then((DS) => {
        // eslint-disable-next-line no-console
        // console.info('Loaded DS from Design Tool for UI kit:', DS);
        extraThemeProps.DS = DS;
        // @ts-ignore
        setDsLib(DS);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    config.featureFlag.uiMode
  ]);

  const ThemeComponent = config.featureFlag.uiMode === UI_MODE.AURORA
    ? LyraTheme.LyraBorealisTheme
    : LyraTheme.Theme;

  // Waiting for DS lib to load
  if (
    config.featureFlag.uiMode === UI_MODE.AURORA
    // true &&
    && !extraThemeProps.DS
  ) {
    return <></>;
  }

  return (
    <context.Provider value={rootStore}>
      {/* @ts-ignore */}
      <ThemeComponent
        isNested={config.styling.isWrappedWithExternalLyraTheme}
        {...extraThemeProps}
      >
        {CrushButton}
        <div ref={storeNodeRef} id="store-handle"></div>
        {loaded && (
          <>
            <TitleUpdater />
            <WizardModal />
            <WizardPersistant />
            <Modal />
            <LyraLayout.SolarLayout auroraMode={config.featureFlag.uiMode === UI_MODE.AURORA}>
              {config.featureFlag.uiMode !== UI_MODE.AURORA && (
                <>
                  <LyraLayout.LayoutHeader>
                    <Topbar />
                  </LyraLayout.LayoutHeader>
                  <LyraLayout.Tool>
                    <Toolbar />
                  </LyraLayout.Tool>
                </>
              )}
              {config.featureFlag.uiMode === UI_MODE.AURORA && (
                <LyraLayout.Tool>
                  <Toolbar />
                </LyraLayout.Tool>
              )}

              <LyraLayout.Content>
                <ProgressStepper showSalesMenuSection={!user.isHomeowner} />
                <Panel />
                <MouseClicksIgnoringTransparentLayer />
                <DrawViewport />
                <FloatingElements />
                <BaseImageryOptions />
              </LyraLayout.Content>
              <LyraLayout.Aside>
                <PropertyPanel />
              </LyraLayout.Aside>
              <LyraLayout.Footer>
                <BottomPanel />
              </LyraLayout.Footer>
              <ModalStreetView />
              <ConfirmCommandModal />
            </LyraLayout.SolarLayout>
          </>
        )}
        <div id="lyra-dropdown-portal" />
      </ThemeComponent>
    </context.Provider>
  );
}

export default MemoizedAppWrapper;
