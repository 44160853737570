import type { Vector3 } from 'three';
import type { Parcel } from '../../../domain/models/SiteDesign/Parcel';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type EditorStore from '../../EditorStore/EditorStore';
import { BaseCommand } from './Command';

export interface IAddOrUpdateParcelCommandDependencies extends IBaseCommandDependencies {
  readonly editor: EditorStore;
  readonly parcel: Parcel;
  readonly newVertex?: Vector3;
}

export class AddOrUpdateParcelCommand extends BaseCommand {
  private readonly commandDependencies: IAddOrUpdateParcelCommandDependencies;

  constructor(dependencies: IAddOrUpdateParcelCommandDependencies) {
    super();
    this.commandDependencies = dependencies;
  }

  async execute(): Promise<void> {
    const {
      parcel, editor, newVertex
    } = this.commandDependencies;
    if (newVertex) {
      parcel.addVertex({
        vertex: newVertex,
        removeIfCollinear: true,
        originatingFromTracing: true
      });
    }
    editor.addOrUpdateObject(parcel.mesh);
  }
}
