import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, observable
} from 'mobx';
import type { IBaseSiteEquipmentViewModelDependencies } from '../BaseSiteEquipmentViewModel';
import { BaseSiteEquipmentViewModel } from '../BaseSiteEquipmentViewModel';
import { PropsPanelUICodes } from '../../propertiesStoreConstants';
import type { GasMeter } from '../../../../../domain/models/SiteDesign/GasMeter';
import { recursiveObjectAssign } from './siteEquipmentViewModelHelpers';

type TProperties = {
  exposure?: { type: string; constrained: boolean };
};
export class GasMeterViewModel extends BaseSiteEquipmentViewModel {
  readonly propUICode = PropsPanelUICodes.GasMeter;
  readonly title = 'Gas Meter';

  readonly exposureOptions = [
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  /** Gas meter props */
  @observable
  exposure?: Option;

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    super(dependencies);
    this.getDomainProps();
  }

  setDomainProps(prop: TProperties): void {
    const gasMeter = this.domain.siteEquipment.gasMeter;
    this.updateEquipment(recursiveObjectAssign<GasMeter>(gasMeter ?? {}, prop));
  }

  @action.bound
  getDomainProps(): void {
    const gasMeter = this.domain.siteEquipment.gasMeter;
    this.setExposure(gasMeter?.exposure?.type, false);
  }

  /** Setter methods */
  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find<Option>(this.exposureOptions, this.findOptionByValue(value));
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        exposure: {
          type: `${value}`,
          constrained: true
        }
      });
    }
  }
}
