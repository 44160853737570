/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  AxiosError, AxiosRequestConfig, AxiosResponse
} from 'axios';
import axios from 'axios';
import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import type { Dictionary } from '../../domain/typings';
import { isEmpty } from '../../utils/helpers';
import { SentryException } from '../../utils/sentryLog';
import * as Storage from './WebStorage';

const FIFTEEN_MINS_IN_MILISECONDS = 15 * 60 * 1000;

export function isTokenInNeedOfRefresh(jwtToken: string, nowInMs = Date.now()): boolean {
  if (localStorage.developmentFlagToForceRefreshAccessToken) {
    delete localStorage.developmentFlagToForceRefreshAccessToken;
    return true;
  }
  const decodedJwtToken = jwtDecode<JwtPayload>(jwtToken);
  const creationTimeInSeconds = decodedJwtToken.iat!;
  const expirationTimeInSeconds = decodedJwtToken.exp!;
  const currentTimeInSeconds = Math.floor(nowInMs / 1000);
  const secondsUntilTokenExpires = expirationTimeInSeconds - currentTimeInSeconds;
  const halfSessionTimeInSeconds = (expirationTimeInSeconds - creationTimeInSeconds) / 2;
  return secondsUntilTokenExpires < halfSessionTimeInSeconds;
}

export function isTokenExpired(jwtToken: string, nowInMs = Date.now()): boolean {
  const expirationTimeInSeconds = jwtDecode<JwtPayload>(jwtToken).exp!;
  const currentTimeInSeconds = Math.floor(nowInMs / 1000);
  return currentTimeInSeconds >= expirationTimeInSeconds;
}

interface ICachedResponse<T> {
  readonly data: T;
  readonly expires: number;
}

class Http {
  private static instance: Http;

  static getInstance(): Http {
    if (!this.instance) {
      this.instance = new Http();
    }
    return this.instance;
  }

  defaultHeader = {
    'Content-Type': 'application/json'
  };

  private jwtToken: string = '';
  updateMapStoreOnTokenUpdate?: (token: string) => void;

  private renewToken: () => Promise<string> = () => {
    console.error('Renew token callback was not set');
    return Promise.resolve('');
  };

  setToken(jwtToken: string): void {
    if (typeof this.updateMapStoreOnTokenUpdate !== 'function') {
      console.error('Http: updateMapStoreOnTokenUpdate is not set.');
    } else {
      this.updateMapStoreOnTokenUpdate(jwtToken);
    }
    this.jwtToken = jwtToken;
  }

  setCallbackRenewToken(callback: () => Promise<string>): void {
    this.renewToken = callback;
  }

  async get<T>(
    url: string,
    params: Dictionary<string | number | boolean>,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T>> {
    if (!isEmpty(params)) {
      const queryParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        queryParams.append(key, value.toString());
      }
      url = url.concat('?', queryParams.toString());
    }
    return this.http<T>(
      {
        method: 'GET',
        url,
        withCredentials: false,
        ...args
      },
      withToken
    );
  }

  async getWithCache<T>(
    url: string,
    params: Dictionary<string | number | boolean>,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T> | ICachedResponse<T>> {
    const options = {
      params,
      args,
      withToken
    };
    const cacheKey = url.concat(JSON.stringify(options));
    let data = Storage.getSessionStorage<ICachedResponse<T>>(cacheKey);
    if (data && data.expires > new Date().getTime()) {
      return data;
    }

    const response = await this.get<T>(url, params, args, withToken);
    if (response.status === 200) {
      const expires = new Date().getTime() + FIFTEEN_MINS_IN_MILISECONDS;
      const data = {
        data: response.data,
        expires
      };
      Storage.setSessionStorage<ICachedResponse<T>>(cacheKey, data);
    }
    return response;
  }

  async post<K, T>(
    url: string,
    data: BodyInit | K,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T>> {
    return this.http<T>(
      {
        method: 'POST',
        url,
        withCredentials: false,
        data,
        validateStatus: (status: number): boolean => status >= 200 && status < 400,
        ...args
      },
      withToken
    );
  }

  async put<K, T>(
    url: string,
    data: BodyInit | K,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T>> {
    return this.http<T>(
      {
        method: 'PUT',
        url,
        withCredentials: false,
        data,
        ...args
      },
      withToken
    );
  }

  async delete<K, T>(
    url: string,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T>> {
    return this.http<T>(
      {
        method: 'DELETE',
        url,
        withCredentials: false,
        ...args
      },
      withToken
    );
  }

  async patch<K, T>(
    url: string,
    data: BodyInit | K,
    args: AxiosRequestConfig = {
      headers: this.defaultHeader
    },
    withToken?: boolean
  ): Promise<AxiosResponse<T>> {
    return this.http<T>(
      {
        method: 'PATCH',
        url,
        withCredentials: false,
        data,
        ...args
      },
      withToken
    );
  }

  private http = async <T>(request: AxiosRequestConfig, withToken: boolean = true): Promise<any> => {
    if (withToken && isTokenExpired(this.jwtToken)) {
      // Reload to navigate to login page by the host app.
      window.location.reload();
      // Awaiting a while so that an unneeded request is not sent until the page is reloaded.
      await new Promise((resolve): void => {
        setTimeout(resolve, 5000);
      });
    }
    if (withToken && isTokenInNeedOfRefresh(this.jwtToken)) {
      this.setToken(await this.renewToken());
    }

    // By default, this param is true, we put it as false only for special cases
    // where we have strict limitations for additional headers in the request.
    // e.g. to download file from AWS file storage.
    if (withToken) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${this.jwtToken}`
      };
    }

    // throw ETIMEDOUT error instead of generic ECONNABORTED on request timeouts
    request.transitional = {
      clarifyTimeoutError: true
    };

    return axios.request<T>(request).catch((error: AxiosError): void => {
      if (error.response || error.request) {
        // It is important to throw original error object. It gets caught on the level above.
        //
        // If `error.response` is defined, the request was made and the server responded with a status code
        // that falls out of the range of 2xx;
        //
        // If `error.request` is defined, the request was made but no response received
        // `error.request` is an instance of XMLHttpRequest in the browser;
        throw error;
      } else {
        // If no `error.response` or `error.request` then something happened in setting up
        // the request that triggered an Error;
        SentryException('Axios HTTP AxiosRequestConfig error', error);
      }
    });
  };
}

export default Http.getInstance();
