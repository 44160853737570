import type { IMeterMainData } from '../../../entities/SiteDesign/MeterMainInstance';
import {
  getServiceEntranceAndSiteEquipmentProperties,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../SiteEquipmentTypesAndHelpers';
import type {
  ISiteEquipmentData, SiteEquipmentDataKeys
} from '../../../entities/SiteDesign/SiteEquipment';
import {
  type GenericMarkerDependencies, GenericSiteEquipment
} from './GenericSiteEquipment';

export class MeterBase extends GenericSiteEquipment {
  serialNumber?: string;
  electricServiceId?: string;
  override readonly isServiceEntranceEquipment = true;

  constructor(dependencies: GenericMarkerDependencies) {
    super(dependencies);

    const {
      label, name
    } = getServiceEntranceAndSiteEquipmentProperties(
      ServiceEntranceEquipmentDevice.UtilityMeter,
      dependencies.serviceEntranceEquipmentType ?? ServiceEntranceEquipmentType.MeterMainLoadCenter
    );
    this.label = label;
    this.name = name;
  }

  enrichWithMeterBaseData(data: IMeterMainData): void {
    this.realWorldZValue = data.location.z;
    this.serverId = data.id;
    this.exposure = data.exposure;
    this.electricServiceId = data.electricServiceId;
    this.serialNumber = data.serialNumber;
  }
  toData(): ISiteEquipmentData[SiteEquipmentDataKeys.utilityMeter] {
    return {
      id: this.serverId,
      serialNumber: this.serialNumber || undefined,
      electricServiceId: this.electricServiceId || undefined,
      location: {
        x: this.mesh.position.x,
        y: this.mesh.position.y,
        z: this.realWorldZValue
      },
      exposure: this.exposure
    };
  }
}
