import React, { BaseProps, ReactElement } from 'react';
import { Step, StepData } from './Step';
import {
  Divider,
  Layout,
  LeftContainer,
  RightContainer,
  Subtitle,
  Title
} from './styles';

export type Props = BaseProps & {
  title?: string;
  subtitle?: string;
  currentStep?: number;
  data?: StepData[];
};

function getStepState(
  index: number,
  currentStep: number
): 'ready' | 'active' | 'pending' {
  if (index < currentStep) return 'ready';
  if (index > currentStep) return 'pending';
  return 'active';
}

const Pipeline = (props: Props): ReactElement => {
  const {
    className = '',
    children,
    title = '',
    subtitle = '',
    currentStep = 0,
    data = []
  } = props;

  return (
    <Layout className={className}>
      <LeftContainer>
        <Title>{title}</Title>

        <Subtitle>{subtitle}</Subtitle>

        {data.map(
          (step: StepData, index: number): ReactElement => (
            <Step key={index} state={getStepState(index, currentStep)} {...step} />
          )
        )}
      </LeftContainer>

      {!!children && (
        <>
          <Divider />
          <RightContainer>{children}</RightContainer>
        </>
      )}
    </Layout>
  );
};

export { Pipeline };
