export interface ISignal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: Function, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: Function, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addAndCall(listener: Function, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: Function, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: Function, listenerContext?: any): boolean;
  removeAll(): void;

  dispatch(...args: any[]): void;
  bindings: IBinding[];
}

export interface ISignalP0 extends ISignal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: () => void, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: () => void, listenerContext?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: () => void, listenerContext?: any): boolean;
  dispatch(): void;
}

/**
 * Signal with 1 parameter
 */
export interface ISignalP1<T1> extends ISignal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: (p1: T1) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: (p1: T1) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: (p1: T1) => void, listenerContext?: any): boolean;
  dispatch(p1: T1): void;
}

/**
 * Signal with 2 parameters
 */
export interface ISignalP2<T1, T2> extends ISignal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: (p1: T1, p2: T2) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: (p1: T1, p2: T2) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: (p1: T1, p2: T2) => void, listenerContext?: any): boolean;
  dispatch(p1: T1, p2: T2): void;
}

/**
 * Signal with 3 parameters
 */
export interface ISignalP3<T1, T2, T3> extends ISignal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: (p1: T1, p2: T2, p3: T3) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: (p1: T1, p2: T2, p3: T3) => void, listenerContext?: any, priority?: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: (p1: T1, p2: T2, p3: T3) => void, listenerContext?: any): boolean;
  dispatch(p1: T1, p2: T2, p3: T3): void;
}

interface IBinding {
  listener: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any;
  isOnce: boolean;
  priority: number;
}

export class Signal implements ISignal {
  // --------------------------------------------------------------------------------------------------
  // static create method

  static create(): ISignalP0;
  static create<T>(): ISignalP1<T>;
  static create<T0, T1>(): ISignalP2<T0, T1>;
  static create<T0, T1, T2>(): ISignalP3<T0, T1, T2>;

  static create(): Signal {
    return new Signal();
  }

  // --------------------------------------------------------------------------------------------------
  // private members, constructor

  // protected _listeners: Function[];
  // protected _options: ISignalOption[];
  protected _bindings: IBinding[];

  protected _shouldPropagate = true;

  constructor() {
    this._bindings = [];
  }

  // --------------------------------------------------------------------------------------------------
  // add methods

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(listener: Function, context?: any, priority: number = 0): void {
    this.registerListener(listener, false, context, priority);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addAndCall(listener: Function, context?: any, priority: number = 0): void {
    this.registerListener(listener, false, context, priority);

    context = context || this;
    listener.call(context);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOnce(listener: Function, context?: any, priority: number = 0): void {
    this.registerListener(listener, true, context, priority);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected registerListener(listener: Function, isOnce: boolean, context: any, priority: number = 0): void {
    const prevIndex = this.indexOfListener(listener, context);
    let binding: IBinding | null = null;

    if (prevIndex !== -1) {
      binding = this._bindings[prevIndex];
      if (binding.isOnce !== isOnce) {
        throw new Error(
          `You cannot add${isOnce ? '' : 'Once'}() then add${
            !isOnce ? '' : 'Once'
          }() the same listener without removing the relationship first.`
        );
      }
    } else {
      binding = {
        listener,
        context,
        isOnce,
        priority
      };

      this.addBinding(binding);
    }
  }

  protected addBinding(binding: IBinding): void {
    let n = this._bindings.length;

    do {
      --n;
    } while (this._bindings[n] && binding.priority <= this._bindings[n].priority);

    this._bindings.splice(n + 1, 0, binding);

    // if (this._highestPriority < binding.pr)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected indexOfListener(listener: Function, context: any): number {
    for (let i = this._bindings.length - 1; i >= 0; --i) {
      const binding = this._bindings[i];
      if (binding.listener === listener && binding.context === context) {
        return i;
      }
    }

    return -1;
  }

  // --------------------------------------------------------------------------------------------------
  // remove Methods

  /**
   * If context is given -> remove the matching listener with that context.
   * If no context given -> remove all matching listeners (regardless of context).
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(listener: Function, context?: any): boolean {
    const i = this.indexOfListener(listener, context);

    if (i !== -1) {
      this._bindings.splice(i, 1);
      return true;
    }

    return false;
  }

  removeAll(): void {
    this._bindings.length = 0;
  }

  // --------------------------------------------------------------------------------------------------
  // dispatch

  dispatch(...args: any[]): void {
    const paramsArr = [...args];
    this._shouldPropagate = true; // in case `halt` was called before dispatch or during the previous dispatch.

    // Clone array in case add/remove items during dispatch, or if the order of the elements changes
    // Eg.: add a key listener in a key listener, in this case you
    // only want the listener to be triggered for the next key event, not the current one.
    // (although that isn't a problem because the for loop is decremental)
    // Another potential bug: when you remove the event listener with index 2 and 3 in the event listener
    // with index 3 -> in that case the next step in the for loop will try to access index 2 which
    // doesn't exist anymore.

    const bindings = [...this._bindings];

    for (let i = bindings.length - 1; i >= 0; --i) {
      const result = bindings[i].listener.apply(bindings[i].context, paramsArr);

      if (result === false || !this._shouldPropagate) {
        break;
      }
    }
  }

  dispose(): void {
    this.removeAll();
  }

  get bindings(): IBinding[] {
    return this._bindings;
  }
}
