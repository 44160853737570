import type {
  OrthographicCamera, PerspectiveCamera, Scene
} from 'three';
import {
  Vector2, WebGLRenderer
} from 'three';
import { Convergence } from '../../utils/Convergence';
import type { Vector2D } from '../../domain/typings';
import type { IControls } from './Controls/Controls';
import { TimeStamp } from './TimeStamp';
import type EditorStore from './EditorStore';

/**
 * Viewport is going to contain all the logic that manages three.js tools
 *
 * @export
 */
export class ViewPort {
  static mapZoomFactor: number = 0;

  static readonly resolution: Vector2 = new Vector2();

  static _editor: EditorStore;

  static setMapZoomFactor(mapZoomFactor: number): void {
    this.mapZoomFactor = Math.pow(2, mapZoomFactor);
  }

  private controls: IControls[];

  renderer!: WebGLRenderer;

  /**
   * Creates a viewport controller that can be binded
   * to any action or observable
   * @param  editor used to notify about modifications on the viewport.
   */
  constructor(editor: EditorStore) {
    ViewPort._editor = editor;
    this.controls = [];
  }

  static get scaleFactor(): number {
    return ViewPort._editor?.scaleFactor || 1;
  }

  get resolution(): Vector2D {
    return {
      x: ViewPort.resolution.x,
      y: ViewPort.resolution.y
    };
  }

  private get scene(): Scene {
    return ViewPort._editor.scene!;
  }

  private get backgroundScene(): Scene {
    return ViewPort._editor.backgroundScene!;
  }

  private get activeCamera(): OrthographicCamera | PerspectiveCamera | undefined {
    return ViewPort._editor.activeCamera;
  }

  setup(viewportSize: Vector2D, testEnv: boolean = false): void {
    let startAnimation = true;
    if (testEnv) {
      const domElement = document.createElement('canvas');
      this.renderer = {
        autoClear: false,
        clear: (): void => {
          // do nothing
        },
        setClearColor: (): void => {
          // do nothing
        },
        setPixelRatio: (): void => {
          // do nothing
        },
        setSize: (): void => {
          // do nothing
        },
        domElement: domElement,
        render(): void {
          // do nothing
        }
      } as unknown as WebGLRenderer;
    } else {
      if (!this.renderer) {
        this.renderer = new WebGLRenderer({
          antialias: true
        });
      } else {
        startAnimation = false;
      }
    }
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0xf0f0f0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.setRendererSize(viewportSize);

    if (startAnimation) {
      this.animate();
    }
  }

  setRendererSize(viewportSize: Vector2D): void {
    this.renderer.setSize(viewportSize.x, viewportSize.y);
    ViewPort.resolution.set(viewportSize.x, viewportSize.y);
  }

  render(): void {
    TimeStamp.value = window.performance.now();
    this.backgroundScene!.updateMatrixWorld();
    this.scene!.updateMatrixWorld();
    Convergence.updateActiveOnes(TimeStamp.value);
    this.updateControls();
    if (this.activeCamera) {
      this.renderer.clear();
      this.renderer.render(this.backgroundScene!, this.activeCamera);
      this.renderer.render(this.scene!, this.activeCamera);
    }
  }

  addControls(control: IControls): void {
    this.controls.push(control);
  }

  resetControls(): void {
    this.controls.forEach((control: IControls): void => control.deactivate?.());
    this.controls = [];
  }

  private animate = (time?: number): void => {
    requestAnimationFrame(this.animate);
    this.render();
  };

  private updateControls(): void {
    this.controls.forEach((control: IControls): void => control.update());
    ViewPort._editor.throttledUpdateMarkerHeights();
  }
}
