import debounce from 'lodash/debounce';
import {
  LyraFormElementsDesignTool, LyraLayout, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import type { LayoutDesignStage } from '../../../../domain/stages/DesignStages/LayoutDesignStage';
import { ArrayPlacementCard } from '../styles';
import {
  precisionNumber, formatNumberToShow
} from '../../../../utils/helpers';
import type DomainStore from './../../../../stores/DomainStore/DomainStore';

type DCPowerRatingProps = {
  layoutDesignStage: LayoutDesignStage;
  domain: DomainStore;
};

export const DCPowerRatingCard = observer((props: DCPowerRatingProps): ReactElement => {
  const {
    layoutDesignStage, domain
  } = props;
  const design = domain.design;
  const totalModules = design.system.equipment.pvModules.count;
  const dcPowerRating = design.supplementalData.pvModuleInfo?.powerRating || 0;
  const energyProductionLossesValue = design.designSpecification.energyProductionLossesDisplayValue;
  const { energyProductionEstimate } = layoutDesignStage;

  const delayedHandleChange = debounce((value: number): void => {
    if (value < 99) {
      const roundedValue = precisionNumber(value / 100, 4);
      layoutDesignStage.updatePvSystemEnergyProductionLosses(roundedValue);
    }
  }, 500);

  return (
    <ArrayPlacementCard editable={false}>
      <LyraLayout.Row>
        <LyraLayout.Col xs={7} className="col">
          <LyraTypography.TextLayout type="h6" margin={'0px 0px 15px'} lineHeight={1.5}>
            TOTAL ARRAY DC POWER RATING
          </LyraTypography.TextLayout>
        </LyraLayout.Col>
        <LyraLayout.Col xs={5} className="col">
          <LyraTypography.TextLayout align="right" margin={'0px 0px 15px'} type="h3" fontWeight="bold">
            {formatNumberToShow(totalModules * dcPowerRating)} W
          </LyraTypography.TextLayout>
        </LyraLayout.Col>
      </LyraLayout.Row>
      <LyraLayout.Row>
        <LyraLayout.Col xs={5} className="col">
          <LyraTypography.TextLayout type="h6" margin={'0px'} lineHeight={1.5}>
            ENERGY PRODUCTION ESTIMATE
          </LyraTypography.TextLayout>
        </LyraLayout.Col>
        <LyraLayout.Col xs={7} className="col">
          <LyraTypography.TextLayout type="h3" fontWeight="bold" lineHeight={1} align="right" margin={'15px 0px 0px'}>
            {formatNumberToShow(energyProductionEstimate)} kWh
          </LyraTypography.TextLayout>
        </LyraLayout.Col>
        <LyraLayout.Col xs={12} className="col">
          <LyraTypography.TextLayout type="h6" lineHeight={1} margin={'0px 0px 15px'} align="right">
            Source: PVWatts
          </LyraTypography.TextLayout>
        </LyraLayout.Col>
      </LyraLayout.Row>
      <LyraLayout.Row>
        <LyraLayout.Col xs={12} className="col">
          <LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField
            label="PV SYSTEM ENERGY PRODUCTION LOSSES"
            measure="%"
            step={0.5}
            fractionDigits={1}
            value={energyProductionLossesValue}
            onChange={delayedHandleChange}
            limits={{
              lower: 0,
              upper: 98
            }}
            allowOutOfRange={false}
          />
        </LyraLayout.Col>
      </LyraLayout.Row>
    </ArrayPlacementCard>
  );
});
