import {
  observable, toJS
} from 'mobx';
import type { IAddressData } from '../../SiteDesign/Address';

export interface IEngineerOfRecordData {
  readonly name: string;
  readonly licenseNumber: string;
  readonly address?: IAddressData;
  readonly phoneNumber: string;
}

type AddressAlt = {
  placeId: string;
  address: string;
  type: string;
};

export default class EngineerOfRecord {
  @observable
  name: string;

  @observable
  licenseNumber: string;

  @observable
  address: IAddressData;

  @observable
  addressAlt?: AddressAlt;

  @observable
  phoneNumber: string;

  constructor(
    data?: IEngineerOfRecordData & {
      addressAlt?: AddressAlt;
    }
  ) {
    this.name = data?.name ?? '';
    this.licenseNumber = data?.licenseNumber ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.address = {
      addressOne: data?.address?.addressOne ?? '',
      addressTwo: data?.address?.addressTwo ?? '',
      city: data?.address?.city ?? '',
      province: data?.address?.province ?? '',
      postalCode: data?.address?.postalCode ?? ''
    };
    this.addressAlt = data?.addressAlt;
  }

  async geocodeAddress(): Promise<void> {
    const geocoder = new google.maps.Geocoder();
    const addressString = `${this.address.addressOne}, ${this.address.province}, ${this.address.city}, ${this.address.postalCode}`;

    if (addressString.replace(/[\s,]/g, '').length > 0) {
      try {
        const { results } = await geocoder.geocode({
          address: `${this.address.addressOne}, ${this.address.province}, ${this.address.city}, ${this.address.postalCode}`
        });

        this.addressAlt = results[0]
          ? {
            address: results[0].formatted_address,
            type: results[0].types[0],
            placeId: results[0].place_id
          }
          : undefined;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('could not geocode address ', addressString);
      }
    }
  }

  toData(showEngineerAddress: boolean = true): IEngineerOfRecordData & {
    addressAlt?: AddressAlt;
  } {
    const finalShowEngineerAddress = showEngineerAddress
      && this.address.province.trim().length > 0
      && this.address.addressOne.trim().length > 0
      && this.address.postalCode.trim().length > 0;
    return {
      name: this.name,
      licenseNumber: this.licenseNumber,
      phoneNumber: this.phoneNumber,
      ...(finalShowEngineerAddress && {
        address: toJS(this.address),
        addressAlt: toJS(this.addressAlt)
      })
    };
  }
}
