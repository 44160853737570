import { observer } from 'mobx-react-lite';
import type {
  ReactElement, FC
} from 'react';
import React from 'react';
import { showToast } from '@aurorasolar/lyra-ui-kit/lib/components/Toast';
import styled from 'styled-components';
import useStore from '../../../stores/useStore';
import ToastRender from '../../components/ToastRender/ToastRender';
import { WARNING } from '../../../domain/models/Constants';

const TransparentLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
`;

export const MouseClicksIgnoringTransparentLayer: FC = observer((): ReactElement => {
  const { editor } = useStore();

  const shownMouseClicksIgnoringTransparentLayer =
    editor.mouseClicksIgnoringTransparentLayerTriggeredWarningMessage.visibility;
  const toastMessage = editor.mouseClicksIgnoringTransparentLayerTriggeredWarningMessage.message;

  const onClickOnCanvas = (): void => {
    showToast((): ReactElement => ToastRender(toastMessage, WARNING));
  };

  return <>{shownMouseClicksIgnoringTransparentLayer ? <TransparentLayer onClick={onClickOnCanvas} /> : <></>}</>;
});
