import type { DesignWorkspace } from '../workspaces/DesignWorkspace';
import type { IWorkspace } from '../types/IWorkspace';
import type { ProjectWorkspace } from '../workspaces/ProjectWorkspace';
import { getUiStore } from '../../../RootStoreInversion';

export function isDesignWorkspace(target: IWorkspace): target is DesignWorkspace {
  return target.id === 'design';
}

export function isProjectWorkspace(target: IWorkspace): target is ProjectWorkspace {
  return target.id === 'project';
}

export function isCurrentWorkspace(id: string): boolean {
  return getUiStore().workspace.currentWorkspace.id === id;
}
