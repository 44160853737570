import { WARNING } from '../../../../../domain/models/Constants';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { RoofProtrusionStore } from '../../../Properties/RoofProtrusion/RoofProtrusionStore';
import { Import3DModelViewModel } from '../../../Wizard/ViewModels/Import3DModel/Import3DModelViewModel';
import type { WizardStore } from '../../../Wizard/Wizard';
import { notify } from '../../../../../utils/helpers';
import type { IBaseToolDependencies } from '../../Tool';
import { BaseTool } from '../../Tool';
import { IMPORT_FILE_TOOL_ID } from '../constants';

export interface IImportModelToolDependencies extends IBaseToolDependencies {
  readonly wizard: WizardStore;
  readonly domain: DomainStore;
  readonly roofProtrusion: RoofProtrusionStore;
}

export class ImportModelTool extends BaseTool {
  readonly id: string = IMPORT_FILE_TOOL_ID;
  readonly icon: string = 'tool-import-3d';
  readonly title: string = 'Import 3D model';
  description: string = this.title;

  private readonly wizard: WizardStore;
  private readonly domain: DomainStore;
  private readonly roofProtrusion: RoofProtrusionStore;

  constructor(dependencies: IImportModelToolDependencies) {
    super(dependencies);
    const {
      domain, wizard, roofProtrusion
    } = dependencies;
    this.wizard = wizard;
    this.domain = domain;
    this.roofProtrusion = roofProtrusion;
  }

  whenSelected(): void {
    if (this.domain.buildings.length > 0) {
      notify('Please delete existing buildings before importing a 3D model', WARNING);
      return;
    }

    this.wizard.createOneStepWizard(Import3DModelViewModel, {
      wizard: this.wizard,
      domain: this.domain,
      editor: this.editor,
      serviceBus: this.serviceBus,
      toolbar: this.toolbar,
      roofProtrusion: this.roofProtrusion
    });
  }

  whenDeselected(): void {
    this.wizard.clear();
  }

  dispose(): void {
    throw new Error('Method not implemented.');
  }

  onClose(): void {
    // Not implemented
  }
}
