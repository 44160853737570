import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';
import { LyraButtons } from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import {
  PropertyContentWrapper, PropertyPanelWrapper
} from '../PropertyPanel/styles';
import IconMapper from '../../components/Icons';
import { IconKeys } from '../../components/Icons/registry';
import useStore from '../../../stores/useStore';
import type { TraceToDefineScalingTool } from '../../../stores/UiStore/ToolbarStore/Project/TraceToDefineScalingTool';
import {
  ButtonWrapper,
  Header,
  IconContainer,
  ItemContainer,
  OuterContainer,
  RightSide,
  PropertyPanelFooter
} from './CustomBaseImageryTransformationPanel';

const IconWrapper = styled.div`
  margin-top: 5px;

  & svg {
    height: 32px;
  }
`;

export const ScaleToDefineScalingPanel = (): ReactElement => {
  const { toolbar } = useStore();
  const tool = toolbar.selectedTool as TraceToDefineScalingTool;
  return (
    <PropertyPanelWrapper>
      <PropertyContentWrapper>
        <OuterContainer>
          <ItemContainer>
            <IconContainer>
              <IconWrapper>
                <IconMapper name={IconKeys.TraceToScale} />
              </IconWrapper>
            </IconContainer>
            <RightSide>
              <Header>SET SCALE</Header>
              Draw a line over your custom image to define the length of a specific wall/roof face. The system will
              automatically resize the image for you to exactly fit basemap dimensions.
            </RightSide>
          </ItemContainer>
        </OuterContainer>
      </PropertyContentWrapper>

      <PropertyPanelFooter>
        <ButtonWrapper noSvg>
          <LyraButtons.Buttons
            styleType={ButtonStyleType.FLAT}
            colorTheme={EThemeComponentColor.TRANSPARENT}
            fontColorTheme={EThemeComponentColor.AQUAMARINE}
            disabled={false}
            onClick={(): void => {
              tool.cancel();
            }}
          >
            <b>Cancel</b>
          </LyraButtons.Buttons>
        </ButtonWrapper>
      </PropertyPanelFooter>
    </PropertyPanelWrapper>
  );
};
