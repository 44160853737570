import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { ArrayPlacementStage } from '../../../../domain/stages/DesignStages/ArrayPlacementStage';
import { sectionTitle } from '../SectionTitle';
import { ArrayPlacementCard } from '../styles';

type LayoutStrategyProps = {
  arrayPlacementStage: ArrayPlacementStage;
  editable?: boolean;
};

export const LayoutStrategyCard = observer((props: LayoutStrategyProps): ReactElement => {
  const {
    arrayPlacementStage, editable
  } = props;
  const definitions = arrayPlacementStage.domainModel.system.equipment.mountingSystems.definitions;
  const {
    lowSlope, steepSlope
  } = definitions;
  const defaultLayoutStrategySteepSlope = steepSlope?.defaultLayoutStrategy;
  const defaultLayoutStrategyLowSlope = lowSlope?.defaultLayoutStrategy;
  const defaultConfigurationLowSlope = lowSlope?.defaultConfiguration;

  const handleEditClick = useCallback((): void => {
    arrayPlacementStage?.editDefaultLayoutStrategy();
  }, [arrayPlacementStage]);

  if (definitions.isEmpty) {
    return <></>;
  }
  return (
    <ArrayPlacementCard title={''} editable={editable} onEditClick={handleEditClick}>
      {defaultLayoutStrategySteepSlope && (
        <>
          {sectionTitle('steep-slope layout strategy')}
          <LyraTypography.Heading type="h5" fontWeight="bold" margin={'5px 0px 0px'}>
            {defaultLayoutStrategySteepSlope.dominantOrientationAsText}
          </LyraTypography.Heading>
          <LyraTypography.TextLayout type="h6" lineHeight={1} margin={'1px 0px'}>
            {defaultLayoutStrategySteepSlope.rowAlignmentStrategyAsTextForSteepSlope}
          </LyraTypography.TextLayout>
          <LyraTypography.TextLayout type="h6" lineHeight={1} margin={'1px 0px'}>
            {defaultLayoutStrategySteepSlope.mixedOrientationsAllowed && 'Allow mixed landscape orientation'}
          </LyraTypography.TextLayout>
        </>
      )}
      {defaultLayoutStrategySteepSlope && defaultLayoutStrategyLowSlope && <br />}
      {defaultLayoutStrategyLowSlope && (
        <>
          {sectionTitle('low-slope layout strategy')}
          <LyraTypography.Heading type="h5" fontWeight="bold" margin={'5px 0px 0px'}>
            {defaultLayoutStrategyLowSlope.dominantOrientationAsText}
          </LyraTypography.Heading>
          <LyraTypography.TextLayout type="h6" lineHeight={1} margin={'1px 0px'}>
            {defaultConfigurationLowSlope?.rackSpacing.asText}
          </LyraTypography.TextLayout>
        </>
      )}
    </ArrayPlacementCard>
  );
});
