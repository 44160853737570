import type { Option } from '@aurorasolar/lyra-ui-kit/lib/components/OptionsBar/Option';
import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import {
  action, computed, observable
} from 'mobx';
import defer from 'lodash/defer';
import {
  convertFromCentigradesToFahrenheit, convertFromFahrenheitToCentigrades
} from '../../../../utils/helpers';
import { TemperatureDesignParameters } from '../../../../domain/models/SiteDesign/TemperatureDesignParameters';
import type {
  IDataSourceData,
  ITemperatureDesignParameterData,
  TSourceType,
  TTemperatureDataSource,
  ITemperatureOptionsResponse,
  ITemperatureOption,
  TemperatureDesignParameter
} from '../../../../domain/models/SiteDesign/TemperatureDesignParameter';
import {
  ETemperatureType,
  TEMPERATURE_TYPE_LABELS
} from '../../../../domain/models/SiteDesign/TemperatureDesignParameter';
import type { OptionWithAttribute } from '../../../../domain/typings/OptionWithAttribute';
import type DomainStore from '../../../DomainStore/DomainStore';
import type { ModalStore } from '../../Modal/Modal';
import type EditorStore from '../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../Modal/BaseViewModel';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateTemperatureValues } from '../../../ServiceBus/Commands/TemperatureCommand';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import { getUiStore } from '../../../RootStoreInversion';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../domain/behaviour/KeyboardBehaviour';

export type TDropDownOption = IDropDownOption & { type: TSourceType };

export class SiteTemperatureSelectionPanelViewModel {
  static readonly CUSTOM_LOW_TEMPERATURE_OPTIONS: OptionWithAttribute[] = [
    {
      value: ETemperatureType.ASHRAE_EXTREME_LOW,
      label: TEMPERATURE_TYPE_LABELS.ASHRAE_EXTREME_LOW,
      defaultValue: ''
    }
  ];
  static readonly CUSTOM_HIGH_TEMPERATURE_OPTIONS: OptionWithAttribute[] = [
    {
      value: ETemperatureType.ASHRAE_2_PERCENT_HIGH_DRY_BULB,
      label: TEMPERATURE_TYPE_LABELS.ASHRAE_2_PERCENT_HIGH_DRY_BULB,
      defaultValue: ''
    },
    {
      value: ETemperatureType.ASHRAE_4_PERCENT_HIGH_DRY_BULB,
      label: TEMPERATURE_TYPE_LABELS.ASHRAE_4_PERCENT_HIGH_DRY_BULB,
      defaultValue: ''
    },
    {
      value: ETemperatureType.ASHRAE_EXTREME_HIGH,
      label: TEMPERATURE_TYPE_LABELS.ASHRAE_EXTREME_HIGH,
      defaultValue: ''
    }
  ];

  @observable
  selectedDataSource: TDropDownOption | undefined;

  @observable
  selectedTemperatureOption: OptionWithAttribute | undefined;

  @observable
  temperatureValueInFahrenheit: string = '';

  private readonly weatherStationDataSource: ITemperatureOption[];
  private userEntryTemperatureOptions: OptionWithAttribute[] = [];

  constructor(
    temperature: TemperatureDesignParameter | undefined,
    weatherStationDataSource: ITemperatureOption[],
    userEntryTemperatureOptions: OptionWithAttribute[]
  ) {
    this.weatherStationDataSource = weatherStationDataSource;

    const noWeatherStationOption = weatherStationDataSource.find(
      (option: ITemperatureOption): boolean => !option.weatherStation
    );
    const noWeatherStationTempType = noWeatherStationOption
      ? (Object.keys(noWeatherStationOption.temperatureData)[0] as ETemperatureType)
      : undefined;
    this.userEntryTemperatureOptions = noWeatherStationOption
      ? [
        {
          value: ETemperatureType[noWeatherStationTempType!],
          label: TEMPERATURE_TYPE_LABELS[noWeatherStationTempType!],
          defaultValue: ''
        }
      ]
      : userEntryTemperatureOptions;

    // It is important to assign this.selectedDataSource first, because
    // this.selectedDataSourceTemperatureOptions has computed values that
    // depending on this.selectedDataSource.
    const selectedSource = this.weatherStationsDropDownOptions.find((option: TDropDownOption): boolean => {
      if (temperature?.dataSource.type === 'USER') {
        return option.type === 'USER';
      }
      return Number(option.value) === temperature?.dataSource?.id;
    });
    this.selectedDataSource = selectedSource ?? this.weatherStationsDropDownOptions[0];

    // Order of this code is important, because there are some computed values
    // depending on calculations above. Read comment above.
    const selectedOption = this.selectedDataSourceTemperatureOptions.find(
      (option: Option): boolean => (option.value as ETemperatureType) === temperature?.description
    );
    this.selectedTemperatureOption = selectedOption ?? this.selectedDataSourceTemperatureOptions[0];

    const valueInFahrenheit = temperature?.valueInFahrenheit.toFixed(1);
    const defaultValue = this.selectedTemperatureOption?.defaultValue?.toString();

    this.temperatureValueInFahrenheit = valueInFahrenheit ?? defaultValue ?? '';
  }

  isSelectedTemperatureOption = (option: OptionWithAttribute): boolean => {
    return this.selectedTemperatureOption?.value === option.value;
  };

  @action
  onDataSourceChange = (item: TDropDownOption): void => {
    this.selectedDataSource = item;
    this.selectedTemperatureOption =
      this.selectedDataSourceTemperatureOptions.find(this.isSelectedTemperatureOption)
      ?? this.selectedDataSourceTemperatureOptions[0];

    if (item.type !== 'USER') {
      const defaultValue = Number(this.selectedTemperatureOption?.defaultValue);
      const valueInFahrenheit = convertFromCentigradesToFahrenheit(defaultValue).toFixed(1);
      this.temperatureValueInFahrenheit = valueInFahrenheit ?? '';
    } else {
      this.temperatureValueInFahrenheit = '';
    }
  };

  @action
  onTemperatureOptionChange = (value: string): void => {
    this.selectedTemperatureOption = this.selectedDataSourceTemperatureOptions.find(
      (current: Option): boolean => current.value.toString() === value
    );

    const defaultValue = Number(this.selectedTemperatureOption?.defaultValue);
    const valueInFahrenheit = convertFromCentigradesToFahrenheit(defaultValue).toFixed(1);

    this.temperatureValueInFahrenheit = valueInFahrenheit ?? this.temperatureValueInFahrenheit;
  };

  @action
  onTemperatureValueChange = (value: string): void => {
    const compareValue = value === '-' ? 1 : 0;
    if (value.length > compareValue && !this.validateInput(value)) {
      return;
    }

    if (this.selectedDataSource?.type && this.weatherTemperaturesValues[this.selectedDataSource!.value]) {
      this.userEntryTemperatureOptions = this.weatherTemperaturesValues[this.selectedDataSource!.value];
    }

    this.temperatureValueInFahrenheit = value;
    this.selectedDataSource = this.weatherStationsDropDownOptions.find(
      (option: TDropDownOption): boolean => option.type === 'USER'
    );
  };

  private mapTemperatureToOptions(temperature: TTemperatureDataSource): OptionWithAttribute[] {
    return Object.keys(temperature).map((key: string): OptionWithAttribute => {
      const type = key as ETemperatureType;

      return {
        value: type,
        label: TEMPERATURE_TYPE_LABELS[type],
        defaultValue: temperature[type]
      };
    });
  }

  @computed
  get weatherStationsDropDownOptions() {
    const stations = this.weatherStationDataSource
      ?.filter(({ weatherStation }: ITemperatureOption): boolean => !!weatherStation)
      .map(
        ({ weatherStation }: ITemperatureOption): TDropDownOption => ({
          label: weatherStation!.name,
          value: weatherStation!.id.toString(),
          type: 'WEATHER_STATION'
        })
      );

    const user = {
      label: 'Custom Weather Data',
      type: 'USER' as TSourceType,
      value: 'custom'
    };

    return [...stations, user];
  }

  @computed
  get weatherTemperaturesValues() {
    const temperatures: { [key: string]: OptionWithAttribute[] } = {};

    this.weatherStationDataSource.forEach((item: ITemperatureOption) => {
      const {
        temperatureData, weatherStation
      } = item;

      if (temperatureData) {
        temperatures[weatherStation?.id ?? 'custom'] = this.mapTemperatureToOptions(temperatureData);
      }
    });

    return temperatures;
  }

  @computed
  get selectedDataSourceTemperatureOptions(): OptionWithAttribute[] {
    return this.selectedDataSource?.type === 'WEATHER_STATION'
      ? this.weatherTemperaturesValues[this.selectedDataSource.value]
      : this.userEntryTemperatureOptions;
  }

  private validateInput(value: string): boolean {
    /*
      RegExp to numbers with format 12.2 or -12.2.
      Values from -40 to 150 with one decimal
    */
    return /^(150|1[01234]\d|[1-9]?\d|-(40|[0-3]?\d))(\.|(\.\d)?)$/.test(value);
  }

  @computed
  get canSave(): boolean {
    return Boolean(this.selectedDataSource && this.selectedTemperatureOption && this.temperatureValueInFahrenheit);
  }

  @computed
  get temperatureDataToSave(): ITemperatureDesignParameterData {
    const isWeatherStation = this.selectedDataSource!.type === 'WEATHER_STATION';

    // For the user custom data we need to convert value into celsius first
    let dataSource: IDataSourceData = { type: 'USER' };
    let tempValue = convertFromFahrenheitToCentigrades(Number(this.temperatureValueInFahrenheit));

    if (isWeatherStation) {
      dataSource = {
        type: 'WEATHER_STATION',
        id: Number(this.selectedDataSource?.value),
        name: this.selectedDataSource?.label
      };

      // For the weather station we take original value in celsius without conversion
      tempValue = Number(this.selectedTemperatureOption?.defaultValue);
    }

    return {
      value: tempValue,
      description: this.selectedTemperatureOption!.value as ETemperatureType,
      dataSource: dataSource
    };
  }
}

interface ISiteTemperatureViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly serviceBus: ServiceBus;
  readonly editor: EditorStore;
}

export class SiteTemperatureViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI: string = 'site_temperature_modal';
  override readonly editor: EditorStore;

  @observable
  lowTemperatureSelectionViewModel!: SiteTemperatureSelectionPanelViewModel;

  @observable
  highTemperatureSelectionViewModel!: SiteTemperatureSelectionPanelViewModel;

  @observable
  temperaturesOptionsSourceData!: ITemperatureOptionsResponse;

  private readonly serviceBus: ServiceBus;

  constructor(dependencies: ISiteTemperatureViewModelDependencies) {
    super(dependencies);
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;
    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @action
  saveSiteTemperatureData = (): void => {
    const commandDependencies: IUpdateTemperatureValues = {
      domain: this.domain,
      temperatures: new TemperatureDesignParameters({
        lowTemperature: this.lowTemperatureSelectionViewModel.temperatureDataToSave,
        highTemperature: this.highTemperatureSelectionViewModel.temperatureDataToSave
      })
    };
    this.serviceBus.send('update_temperature_values', commandDependencies);
    defer(() => getUiStore().workspace.projectWorkspace?.saveManually());
    this.closeModal();
  };

  @action
  loadProjectTemperatureOptions = (): void => {
    this.designService
      .getProjectTemperatureOptions(this.domain.project.id)
      .then((response: ITemperatureOptionsResponse): void => {
        this.temperaturesOptionsSourceData = response;

        this.lowTemperatureSelectionViewModel = new SiteTemperatureSelectionPanelViewModel(
          this.temperatures.lowTemperature,
          this.lowTemperaturesWeatherStations,
          SiteTemperatureSelectionPanelViewModel.CUSTOM_LOW_TEMPERATURE_OPTIONS
        );
        this.highTemperatureSelectionViewModel = new SiteTemperatureSelectionPanelViewModel(
          this.temperatures.highTemperature,
          this.highTemperaturesWeatherStations,
          SiteTemperatureSelectionPanelViewModel.CUSTOM_HIGH_TEMPERATURE_OPTIONS
        );
      });
  };

  onKeyDown = (): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_ENTER && this.canSave) {
      this.saveSiteTemperatureData();
      this.dispose();
    }
  };

  @computed
  get temperatures(): TemperatureDesignParameters {
    return this.domain.project.designParameters.temperature;
  }

  @computed
  get lowTemperaturesWeatherStations(): ITemperatureOption[] {
    return this.temperaturesOptionsSourceData?.lowTemperatures;
  }

  @computed
  get highTemperaturesWeatherStations(): ITemperatureOption[] {
    return this.temperaturesOptionsSourceData?.highTemperatures;
  }

  @computed
  get canSave(): boolean {
    const canSave = this.lowTemperatureSelectionViewModel?.canSave && this.highTemperatureSelectionViewModel?.canSave;
    if (!canSave) {
      return false;
    }
    const lowTemp = Number(this.lowTemperatureSelectionViewModel.temperatureValueInFahrenheit);
    const highTemp = Number(this.highTemperatureSelectionViewModel.temperatureValueInFahrenheit);
    return lowTemp < highTemp;
  }

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }
}
