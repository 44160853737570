import {
  BufferAttribute, BufferGeometry, MeshBasicMaterial, Vector2, Vector3
} from 'three';
import { canvasConfig } from '../../../../config/canvasConfig';
import { extendLine } from '../../../../utils/math';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import { Line } from '../../../../domain/graphics/Line';
import type { Boundary } from '../../../../domain/graphics/Boundary';
import { LayerCanvas } from '../../../../domain/graphics/LayerCanvas';
import { BaseGuide } from './BaseGuide';
import type { ISmartGuideDependencies } from './BaseGuide';

const KEYCODE_M = 77;

export class MidPointsGuide extends BaseGuide {
  icon = 'smartguide-midpoints-lines';
  name = 'Mid Points lines';
  commandKeyCode = KEYCODE_M;
  identifier = EGuideIdentifier.MID_POINTS;
  kindSupport = [KindGuides.TRACE_TOOL];

  lineGuide?: Line;
  private lineGeometry?: BufferGeometry;

  constructor(deps: ISmartGuideDependencies) {
    super(deps);
  }

  setup(): void {
    this.enableKeyListener();
    const { midPointGuideColor } = canvasConfig;

    this.lineGeometry = new BufferGeometry();
    this.setPositionAttribute(new Float32Array([]));
    const lineMaterial = new MeshBasicMaterial({
      transparent: true,
      depthTest: true,
      depthWrite: false,
      ...LayerCanvas.CLOSEST,
      color: midPointGuideColor
    });
    this.lineGuide = new Line(this.lineGeometry, lineMaterial);
  }

  apply({
    mousePos, wipBoundary, kind
  }: IApplyParams): IGuideResult {
    this.editor.addOrUpdateObject(this.lineGuide!);

    if (kind === KindGuides.TRACE_TOOL && wipBoundary && mousePos) {
      return this.applyWithTraceTool(mousePos, wipBoundary);
    } else {
      return {
        color: '',
        position: mousePos
      };
    }
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    this.lineGuide!.visible = false;
    this.editor.removeObject(this.lineGuide!);
  }

  override hideUiElements(): void {
    this.lineGuide!.visible = false;
  }

  private setPositionAttribute(float32Array: Float32Array): void {
    this.lineGeometry!.setAttribute('position', new BufferAttribute(float32Array, 3));
  }

  private applyWithTraceTool(mousePos: Vector3, wipBoundary: Boundary): IGuideResult {
    const {
      editor, lineGuide
    } = this;
    const { midPointsThreshold } = canvasConfig;
    const allboundaries: Boundary[] = editor.getObjectsByType('boundary', true);

    let newPos = new Vector3().copy(mousePos);
    let showLine = false;

    if (wipBoundary.vertices.length) {
      allboundaries.forEach((boundary: Boundary): void => {
        const { segments } = boundary;
        const segmentsLength = segments.length;

        if (segmentsLength >= 2) {
          for (let itr = 0; itr < segmentsLength; itr++) {
            const segment = segments[itr];
            const [point1, point2] = segment.points;

            // Getting the middle point of the segment(centroid)
            // via average ecuation
            const midpoint = new Vector2((point1.x + point2.x) / 2, (point1.y + point2.y) / 2);

            // imaginary line between between preview line vertex
            // and the midPoint
            const prevVector = new Vector2(mousePos.x - midpoint.x, mousePos.y - midpoint.y);

            const normalizedPrevVector = new Vector2().copy(prevVector)
              .normalize();

            // Segment vector
            const nsegment = new Vector2(point2.x - point1.x, point2.y - point1.y).normalize();

            // dot product from segment vector and
            // the vector of the preview line in order to get
            // the direction between those
            const dot = Math.abs(nsegment.dot(normalizedPrevVector));

            if (dot <= midPointsThreshold) {
              // Calculating the snap point an the extended line guide position
              const posLength = prevVector.length();
              const lineGuideAngle = segment.angle;
              const xcos = Math.cos(lineGuideAngle);
              const ysin = Math.sin(lineGuideAngle);
              const lineGuidePosX1 = midpoint.x + xcos;
              const lineGuidePosY1 = midpoint.y + ysin;
              const lineGuidePosX2 = midpoint.x + Math.cos(lineGuideAngle) * 2;
              const lineGuidePosY2 = midpoint.y + Math.sin(lineGuideAngle) * 2;
              const [posBegin, posEnd] = extendLine(
                new Vector3(lineGuidePosX1, lineGuidePosY1),
                new Vector3(lineGuidePosX2, lineGuidePosY2)
              );
              const orientation = Math.round(normalizedPrevVector.dot(new Vector2(xcos, ysin)));
              const snapPos = new Vector3(
                midpoint.x + Math.cos(lineGuideAngle) * posLength * orientation,
                midpoint.y + Math.sin(lineGuideAngle) * posLength * orientation
              );

              this.setPositionAttribute(new Float32Array([posBegin.x, posBegin.y, 0, posEnd.x, posEnd.y, 0]));
              showLine = true;
              newPos = snapPos;
            }
          }
        }
      });
    }

    lineGuide!.visible = showLine;
    mousePos.x = newPos.x;
    mousePos.y = newPos.y;
    return {
      color: '',
      position: newPos
    };
  }
}
