import { LyraGrid } from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { canvasConfig } from '../../../config/canvasConfig';
import useStore from '../../../stores/useStore';
import { StreetLocationViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/StreetLocationViewModel';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import { Container } from './styles';

const StreetLocation = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<StreetLocationViewModel>();

  useEffect((): void => {
    setViewModel(
      new StreetLocationViewModel({
        editor,
        domain,
        serviceBus
      })
    );
  }, [editor, domain, serviceBus]);

  if (!viewModel) {
    return <></>;
  }
  return (
    <>
      <PropertyPanelHeader
        icon="tool-properties"
        iconBg={canvasConfig.streetLocationIconColor}
        name={viewModel.title}
      />

      <Container>
        <LyraGrid.Grid
          options={viewModel.exposureOptions}
          columns={1}
          selected={viewModel.exposure}
          onSelect={(option: Option): void => viewModel.setExposure(option.value)}
          selectedOptionColor={EThemeComponentColor.PURPLE}
        />
      </Container>
    </>
  );
};
export default observer(StreetLocation);
