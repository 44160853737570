import { LyraWarningModal } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import type { MissingPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/MissingProperties/MissingPropertiesViewModel';
import useStore from '../../../../stores/useStore';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore';
import MissingProperties from './MissingProperties';
import DefinedProperties from './DefinedPropertiesMo';

const MissingPropertiesModal = observer((): ReactElement => {
  const {
    modal, workspace
  } = useStore();
  const [tabIndex, setTabIndex] = useState(0);
  const changeTab = (index: number): void => {
    setTabIndex(index);
  };
  const [missingPropertiesViewModel] = useState(modal.viewModel as MissingPropertiesViewModel);
  const { projectReadiness } = missingPropertiesViewModel;

  useEffect((): void => {
    if (projectReadiness.getTotalMandatoryMissingProperties() === 0) {
      setTabIndex(1);
    }
  }, [projectReadiness]);

  const {
    mandatoryMissingProperties, optionalMissingProperties
  } = projectReadiness;

  const hasMandatoryProperties: boolean = projectReadiness.getTotalMandatoryMissingProperties() === 0;

  const customTabItems = [
    {
      label: 'Mandatory',
      count: projectReadiness.getTotalMandatoryMissingProperties(),
      order: 'first'
    },
    {
      label: 'For Permit Package',
      count: projectReadiness.getTotalOptionalMissingProperties(),
      order: 'last',
      active: hasMandatoryProperties
    }
  ];
  const showFooter = (): boolean => {
    return tabIndex !== 0;
  };

  const isDefinedMandatoryProperties: boolean = projectReadiness.getTotalMandatoryMissingProperties() === 0;
  const isDefinedProjectProperties: boolean = projectReadiness.getTotalOptionalMissingProperties() === 0;

  return (
    <LyraWarningModal.WarningModal
      show={projectReadiness.hasMissingProperties()}
      tabItems={customTabItems}
      trigger={changeTab}
      onClose={(): void => missingPropertiesViewModel.closeModal()}
      customFooter={
        showFooter() ? (
          <LyraWarningModal.Footer
            onClickButton={(): void => {
              missingPropertiesViewModel.closeModal();
              workspace.changeWorkspace(Workspace.Design);
            }}
            buttonDisabled={!!projectReadiness.getTotalMandatoryMissingProperties()}
          />
        ) : (
          <></>
        )
      }
    >
      <>
        {tabIndex === 0 ? (
          <>
            {!isDefinedMandatoryProperties ? (
              <MissingProperties missingProperties={mandatoryMissingProperties} />
            ) : (
              <DefinedProperties headerText="All mandatory site parameters defined!" />
            )}
          </>
        ) : (
          <>
            {!isDefinedProjectProperties ? (
              <MissingProperties missingProperties={optionalMissingProperties} />
            ) : (
              <DefinedProperties headerText="All parameters for permit package defined!" />
            )}
          </>
        )}
      </>
    </LyraWarningModal.WarningModal>
  );
});

export default MissingPropertiesModal;
