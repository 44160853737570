import _isFinite from 'lodash/isFinite';
import _toNumber from 'lodash/toNumber';
import _isNil from 'lodash/isNil';
import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import {
  Arrow,
  ArrowsContainer,
  ArrowWrapper,
  Container,
  Input,
  InputWrapper,
  Label,
  MeasureText
} from './styles';

type Limit = {
  lower: number;
  upper: number;
};

export type Props = {
  height?: string;
  width?: string;
  className?: string;
  label?: string;
  measure?: string;
  value?: number;
  step?: number;
  decimals?: number;
  limits?: Limit;
  withQuantityArrows?: boolean;
  allowEmptyField?: boolean;
  onChange?: (value: number) => void;
  onLower?: () => void;
  onUpper?: () => void;
  onInput?: (value: string) => void;
  onEnterKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  positiveValues?: boolean;
  fractionDigits?: number;

  // Flag that allows user to enter out of range values;
  // Out of range means out of lower/upper limits;
  allowOutOfRange?: boolean;
};
const isBlank = (value: string): boolean => {
  return _isNil(value) || /^\s*$/.test(value);
};
const isEmptyOrPositiveNumber = (value: string): boolean => {
  return value.trim().length === 0 || Number(value) >= 0;
};
const isValidIntegerNumber = (value: string): boolean => /^-?\d*$/.test(value);
const isValidDecimalNumber = (value: string, decimals: number): boolean => {
  return new RegExp('^-?\\d*(\\.)?(\\d{0,' + decimals + '})?$').test(value);
};

const NumberField: React.FC<Props> = (props: Props): ReactElement => {
  const {
    height,
    width,
    className = '',
    label = '',
    measure = '',
    value,
    step = 1,
    limits,
    withQuantityArrows = true,
    allowEmptyField= false,
    onChange,
    onLower,
    onUpper,
    onInput,
    onEnterKeyDown,
    autoFocus,
    positiveValues = true,
    allowOutOfRange = true,
    fractionDigits = 0
  } = props;

  const [input, setInput] = useState(value !== undefined ? `${value}` : '');
  const [hasErrorState, setHasErrorState] = useState(false);

  useEffect((): void => {
    if (value !== undefined && `${value}` !== input) {
      setInput(`${value}`);
      validateErrorState(`${value}`);
    }
  }, [value]);

  const isInRange = (numberValue: number): boolean => {
    return !!limits && numberValue >= limits.lower && numberValue <= limits.upper;
  };

  const validateLimits = (textValue: string): void => {
    const newValue: number = Number(textValue);
    const inRange: boolean = !isBlank(textValue) && isInRange(newValue);
    setHasErrorState(!inRange);
  };

  const validateMinimumValue = (textValue: string): void => {
    const newValue: number = Number(textValue);
    setHasErrorState(newValue < 0);
  };

  const validateErrorState = (inputValue: string): void => {
   if (inputValue.length === 0 || inputValue === '0' && allowEmptyField) {
     setHasErrorState(false);
     return;
   }
   if (limits) {
      validateLimits(inputValue);
    } else {
      validateMinimumValue(inputValue);
    }
  };

  const incrementValue = (textValue: string): void => {
    changeInputValue(textValue,
        (_value: number): number => _value + (_isNil(limits?.upper) || (_value + step <= limits!.upper) ? step : 0),
        onUpper);
  };

  const decrementValue = (textValue: string): void => {
    changeInputValue(textValue,
        (_value: number): number => _value - (_isNil(limits?.lower) || (_value - step >= limits!.lower) ? step : 0),
        onLower);
  };

  const changeInputValue = (textValue: string,
                            stepValue:  (value: number) => number,
                            postChange?:  () => void): void => {
    let numberValue = _toNumber(textValue);
    if (_isFinite(numberValue)) {
      numberValue = stepValue(numberValue);
      numberValue = Number(numberValue.toFixed(fractionDigits));
      validateErrorState(`${numberValue}`);
      setInput(`${numberValue}`);
      if (onChange) onChange(numberValue);
      if (postChange) postChange();
    }
  };

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>): void => {
    const text = event.currentTarget.value.replace(/[^0-9.]/gi, '');
    const num = _toNumber(text);
    const isFiniteValue = _isFinite(num);
    const isValidValue = (fractionDigits ? isValidDecimalNumber(text, fractionDigits) : isValidIntegerNumber(text))
        && (!positiveValues || isEmptyOrPositiveNumber(text));

    onInput?.(text);

    if (!allowOutOfRange && !isInRange(num) && !allowEmptyField) {
      return;
    }

    validateErrorState(text);

    if (isValidValue) {
      setInput(text);

      if (onChange && isFiniteValue) {
        onChange(num);
      }
    }
  }, [limits, allowOutOfRange, onChange]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowUp') {
      incrementValue(e.currentTarget.value);
    }

    if (e.key === 'ArrowDown') {
      decrementValue(e.currentTarget.value);
    }

    if (e.key === 'Enter') {
      onEnterKeyDown?.(e);
    }
  }, [onChange, incrementValue, decrementValue]);

  const inputProps = {
    height,
    width,
    autoFocus,
    extra: hasErrorState,
    value: input,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
  };

  return (
    <Label>
      {label && label}

      <Container className={className} extra={hasErrorState}>
        <InputWrapper>
          <Input {...inputProps} />
        </InputWrapper>

        {measure ? <MeasureText>{measure}</MeasureText> : null}

        {withQuantityArrows ? (
          <ArrowsContainer>
            <ArrowWrapper>
              <Arrow
                data-testid="number-field-increment"
                direction="up"
                onClick={(): void => incrementValue(input)}
              />
            </ArrowWrapper>

            <ArrowWrapper>
              <Arrow
                data-testid="number-field-decrement"
                direction="down"
                onClick={(): void => decrementValue(input)}
              />
            </ArrowWrapper>
          </ArrowsContainer>
        ) : null}
      </Container>
    </Label>
  );
};

export { NumberField };
