import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type Color from '../../../../../domain/typings/Color';
interface IGoogleMapsWrapperProps {
  visible: boolean;
}
export const EmptyMapView = styled.div`
  display: flex;
  width: 100%;
  height: 348px;
  z-index: 10;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor11};
  box-sizing: border-box;
`;

export const Container = styled.div`
  position: relative;
  height: 285px;
  border-radius: 6px;
  overflow: hidden;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.neutral};
`;

export const GoogleMapsWrapper = styled.div<IGoogleMapsWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  visibility: ${(props: IGoogleMapsWrapperProps): string => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props: IGoogleMapsWrapperProps): number => (props.visible ? 1 : 0)};
`;
