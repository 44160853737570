import {
  action, computed
} from 'mobx';
import { generateUUID } from 'three/src/math/MathUtils';
import { Configuration } from '../MountingSystemDefinition/IConfiguration';
import type { IMountingSystemConfigurationData } from '../MountingSystemDefinition/IConfiguration';
import {
  type ILayoutStrategyData, LayoutStrategy
} from '../RoofTopArray/LayoutStrategy';

export interface IMountingSystemData {
  readonly id?: string;
  readonly arrayAreaId: string;
  readonly definitionId: 'steepSlope' | 'lowSlope';
  readonly layoutStrategy: ILayoutStrategyData;
  readonly configuration?: IMountingSystemConfigurationData;
}

export class MountingSystem {
  id?: string;
  arrayAreaId: string;
  definitionId: 'steepSlope' | 'lowSlope';
  layoutStrategy: LayoutStrategy;
  configuration?: Configuration;

  constructor(data: IMountingSystemData) {
    this.id = data.id;
    this.arrayAreaId = data.arrayAreaId;
    this.definitionId = data.definitionId;
    this.layoutStrategy = new LayoutStrategy(data.layoutStrategy);
    this.configuration = data.configuration ? new Configuration(data.configuration) : undefined;
  }

  toData(): IMountingSystemData {
    return {
      id: this.id,
      arrayAreaId: this.arrayAreaId,
      definitionId: this.definitionId,
      layoutStrategy: this.layoutStrategy.toData(),
      configuration: this.configuration?.toData()
    };
  }

  @computed
  get isTemplate(): boolean {
    return !this.id;
  }

  @computed
  get isInstance(): boolean {
    return !this.isTemplate;
  }

  isOn = (arrayAreaId: string): boolean => {
    return this.arrayAreaId === arrayAreaId;
  };

  @action
  toTemplate = (): MountingSystem => {
    if (this.isTemplate) {
      throw new Error('Only mounting system instances can be converted to templates');
    }
    delete this.id;
    return this;
  };

  @action
  toInstance = (): this => {
    if (this.isInstance) {
      throw new Error('Only mounting system template can be turned into an instance');
    }
    this.id = generateUUID();
    return this;
  };
}
