import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { EApplicationContext } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import config, { UI_MODE } from '../../../config/config';
import { panelRegistry } from './registry';

const PropertyPanel = (): ReactElement => {
  const {
    properties, editor
  } = useStore();

  const auroraModeExclusivePanels: string[] = [PropsPanelUICodes.ElectricalBos, PropsPanelUICodes.MountingBos];

  let PropertyPanelComponent = null;

  if (config.featureFlag.uiMode === UI_MODE.AURORA) {
    PropertyPanelComponent =
      auroraModeExclusivePanels.indexOf(properties.showingPanelOf) !== -1
        ? panelRegistry.get(properties.showingPanelOf)
        : null;
  } else {
    PropertyPanelComponent = panelRegistry.get(properties.showingPanelOf);
  }

  const handleOnPropertiesEdition = (): void => {
    const { target } = window.event!;
    const isTyping = target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement;

    editor.updateApplicationContext(
      isTyping ? EApplicationContext.PROPERTIES_CONTEXT : EApplicationContext.DRAW_CONTEXT
    );
  };

  return (
    <LyraLayout.Flexbox dir="column" align="center" onClick={handleOnPropertiesEdition} fullHeight>
      {PropertyPanelComponent ? <PropertyPanelComponent /> : <FallbackComponent uiMode={config.featureFlag.uiMode} />}
    </LyraLayout.Flexbox>
  );
};

type FallbackComponentProps = {
  uiMode: UI_MODE;
};

const FallbackComponent = ({ uiMode }: FallbackComponentProps): ReactElement => {
  const DS = React.useContext(ThemeContext)!.DS;
  return uiMode === UI_MODE.AURORA ? <DS.Loader /> : <></>;
};

export default observer(PropertyPanel);
