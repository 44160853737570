import type {
  BaseProps, ReactElement, ReactText
} from 'react';
import React, { useCallback } from 'react';
import IconMapper from '../Icons';
import type EButtonType from './EButtonType';
import { ButtonStyle } from './styles';

type Props = BaseProps & {
  onClick?: (event: MouseEvent) => void;
  label?: string | ReactElement | ReactText;
  type?: string | EButtonType;
  icon?: string;
  selected?: boolean;
  disabled?: boolean;
};

function Button(props: Props): ReactElement {
  const { onClick } = props;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      onClick?.(event.nativeEvent);
    },
    [onClick]
  );

  return (
    <ButtonStyle
      className={props.className}
      onClick={handleClick}
      themeType={props.type}
      disabled={props.disabled}
      selected={props.selected}
    >
      {props.icon && <IconMapper name={props.icon} />}
      {props.label || props.children}
    </ButtonStyle>
  );
}

export default Button;
