/**
 * Type for layers of threejs
 */
type LayerCanvasType = {
  polygonOffset: boolean;
  polygonOffsetUnits: number;
  polygonOffsetFactor: number;
};

export class LayerCanvas {
  static readonly CLOSEST: LayerCanvasType = {
    polygonOffset: true,
    polygonOffsetUnits: 1,
    polygonOffsetFactor: -2
  };
  static readonly FOREGROUND: LayerCanvasType = {
    polygonOffset: true,
    polygonOffsetUnits: 1,
    polygonOffsetFactor: -1
  };
  static readonly MIDDLEGROUND: LayerCanvasType = {
    polygonOffset: true,
    polygonOffsetUnits: 1,
    polygonOffsetFactor: 0
  };
  static readonly BACKGROUND: LayerCanvasType = {
    polygonOffset: true,
    polygonOffsetUnits: 1,
    polygonOffsetFactor: 1
  };
  static readonly UPPERGROUND: LayerCanvasType = {
    polygonOffset: true,
    polygonOffsetUnits: -1,
    polygonOffsetFactor: -1
  };
}
