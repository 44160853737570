import { Vector3 } from 'three';
import type { Segment } from '../domain/graphics/Segment';
import { DEFAULT_Z } from '../domain/models/Constants';
import EOrientation from '../domain/models/EOrientation';
import type { Vector2D } from '../domain/typings';
import { isPointOnLineSegmentViaCrossProduct } from './math';

/**
 * Author: David Obregón
 * Utility that returns a boolean if two lines intersect or false if it's not.
 * The approach is based in the notion of orientation of an ordered triplet of
 * points in the plane.
 * Source:
 *    https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
 * @export
 * @param pointA Initial point of the first line
 * @param pointB Final point of the first line
 * @param pointC Initial point of the second line
 * @param pointD Final point of the second line
 * @returns True if intersects, False if it's not
 */
const doIntersect = (pointA: Vector2D, pointB: Vector2D, pointC: Vector2D, pointD: Vector2D): boolean => {
  // Get orientations of the triplet of points in the plane
  const ori1: number = orientation(pointA, pointB, pointC);
  const ori2: number = orientation(pointA, pointB, pointD);
  const ori3: number = orientation(pointC, pointD, pointA);
  const ori4: number = orientation(pointC, pointD, pointB);

  // General case
  if (ori1 !== ori2 && ori3 !== ori4) {
    return true;
  }

  // Special Cases
  /*
    pointA, pointB and pointC are colinear and
    pointC lies on segment pointApointB
  */
  if (ori1 === EOrientation.COLLINEAR && onSegment(pointA, pointC, pointB)) {
    return true;
  }

  /*
    pointA, pointB and pointD are colinear and
    pointD lies on segment pointApointB
  */
  if (ori2 === EOrientation.COLLINEAR && onSegment(pointA, pointD, pointB)) {
    return true;
  }

  /*
    pointC, pointD and pointA are colinear and
    pointA lies on segment pointCpointD
  */
  if (ori3 === EOrientation.COLLINEAR && onSegment(pointC, pointA, pointD)) {
    return true;
  }

  /*
    pointC, pointD and pointB are colinear and
    pointB lies on segment pointCpointD
  */
  if (ori4 === EOrientation.COLLINEAR && onSegment(pointC, pointB, pointD)) {
    return true;
  }

  return false;
};

/*
  Get the orientation, only three possible results:
  Collinear, Clockwise or Counter_Clockwise
*/
const orientation = (p: Vector2D, q: Vector2D, r: Vector2D, tolerance?: number): EOrientation => {
  const val: number = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
  if (!tolerance) {
    const nonZeroValue = val > 0 ? EOrientation.CLOCKWISE : EOrientation.COUNTER_CLOCKWISE;
    return val === 0 ? EOrientation.COLLINEAR : nonZeroValue;
  } else {
    const nonTolerantValue = val > Math.abs(tolerance) ? EOrientation.CLOCKWISE : EOrientation.COUNTER_CLOCKWISE;
    return val >= -Math.abs(tolerance) && val <= Math.abs(tolerance) ? EOrientation.COLLINEAR : nonTolerantValue;
  }
};

// Check if is on the segment
const onSegment = (p: Vector2D, q: Vector2D, r: Vector2D): boolean => {
  if (
    q.x <= Math.max(p.x, r.x)
    && q.x >= Math.min(p.x, r.x)
    && q.y <= Math.max(p.y, r.y)
    && q.y >= Math.min(p.y, r.y)
  ) {
    return true;
  }

  return false;
};

const doIntersectWithoutOrientation = (pointA: Vector3, pointB: Vector3, pointC: Vector3, pointD: Vector3): boolean => {
  const slopeOneLine: number = (pointB.y - pointA.y) / (pointB.x - pointA.x);
  const slopeTwoLine: number = (pointD.y - pointC.y) / (pointD.x - pointC.x);

  const interceptOneLineCero: number = slopeOneLine * pointA.x - pointA.y;
  const interceptTwoLineCero: number = slopeTwoLine * pointC.x - pointC.y;

  const maxtrixA = [
    [slopeOneLine, 1],
    [slopeTwoLine, 1]
  ];
  const maxtrixB = [[interceptOneLineCero], [interceptTwoLineCero]];

  const determinant = 1 / (maxtrixA[0][0] * maxtrixA[1][1] - maxtrixA[0][1] * maxtrixA[1][0]);

  const invMatrixA = [
    [determinant * maxtrixA[1][1], -1 * (determinant * maxtrixA[0][1])],
    [-1 * (determinant * maxtrixA[1][0]), determinant * maxtrixA[0][0]]
  ];

  const pointX = invMatrixA[0][0] * maxtrixB[0][0] + invMatrixA[0][1] * maxtrixB[1][0];
  const pointY = invMatrixA[1][0] * maxtrixB[0][0] + invMatrixA[1][1] * maxtrixB[1][0];

  const intersectPoint: Vector3 = new Vector3(pointX, -1 * pointY, DEFAULT_Z);

  const isInLineOne: boolean = isPointOnLineSegmentViaCrossProduct(pointA, pointB, intersectPoint, 0.03);
  const isInLineTwo: boolean = isPointOnLineSegmentViaCrossProduct(pointC, pointD, intersectPoint, 0.03);

  if (isInLineOne && isInLineTwo) {
    return true;
  }
  return false;
};

const touches = (segment: Segment, point: Vector3, offset: number = 0.03): boolean => {
  return isPointOnLineSegmentViaCrossProduct(
    segment.points[0].getVector3().setZ(0),
    segment.points[1].getVector3().setZ(0),
    point,
    offset
  );
};

export {
  doIntersect, orientation
};
