import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';

import styled from 'styled-components';
import PanningButton from '../ProjectToolbar/Tools/Panning';
import ZoomControls from '../../Topbar/ZoomControls';
import { useUiStore } from '../../../../stores/useStore';

type WrapperProps = {
  hiddenControls: boolean;
};

const MapControlWrapper = styled.div<WrapperProps>`
  position: absolute;
  bottom: 24px;
  right: 32px;
  display: flex;
  padding: 10px;
  z-index: 0;
  scrollbar-width: none;
  ${({ hiddenControls }: WrapperProps): string => (hiddenControls ? 'display: none;' : '')}
`;

/** Replacement component for zooming and panning in Aurora mode */
const MapControlTools = observer((): ReactElement => {
  const { workspace } = useUiStore();

  const stageManager = workspace.currentWorkspace.stageManager;
  const currentStageNumber = stageManager?.stages.indexOf(stageManager?.currentStage) ?? -1;
  const isBeforeElectricalStage = currentStageNumber < 4;

  return (
    <MapControlWrapper data-testid="mainToolbar" hiddenControls={isBeforeElectricalStage}>
      <PanningButton />
      <ZoomControls />
    </MapControlWrapper>
  );
});

export default MapControlTools;
