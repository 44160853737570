import React, { BaseProps, ReactElement, useCallback } from 'react';
import { BigButtonWithIcon, BigButtonIconContainer } from './styles';

export type ButtonOption = {
  name: string | ReactElement;
  caption?: string;
};

export type Props = BaseProps & {
  selected?: string | number;
  onClick: (event: MouseEvent) => void;
  options: ButtonOption;
  height?: string;
  withCaption?: boolean;
  fullWidth: boolean;
};

const BigButtonIcon = ({
  onClick,
  options,
  withCaption = false
}: Props): ReactElement => {

  const onClickEvent = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      onClick(event.nativeEvent);
    },
    []
  );

  return (
      <BigButtonIconContainer onClick={onClickEvent}>
      <BigButtonWithIcon>
        {options.name}
        {withCaption &&
          <p>{options.caption}</p>
        }
      </BigButtonWithIcon>
      </BigButtonIconContainer>
  );
};

export { BigButtonIcon };
