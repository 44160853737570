import React, { BaseProps, ReactElement } from 'react';
import {
  DescriptionView,
  DescriptionBoldText,
  DescriptionLightText
} from './styles';

export type ElectricalBosFormGroupDescriptionProps = BaseProps & {
  line1: string;
  line2: string;
};

const ElectricalBosFormGroupDescription = ({
  line1, line2, className
}: ElectricalBosFormGroupDescriptionProps): ReactElement => {
  return (
    <DescriptionView
      className={className}
    >
      <DescriptionBoldText>
        {line1}
      </DescriptionBoldText>
      <DescriptionLightText>
        {line2}
      </DescriptionLightText>
    </DescriptionView>
  );
};

export { ElectricalBosFormGroupDescription };
