import {
  action, computed, observable
} from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import type Limit from '../../../../domain/models/Limit';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type { RectangleProtrusion } from '../../../../domain/models/SiteDesign/RectangleProtrusion';
import {
  EProtrusionProfile, Units
} from '../../../../domain/typings';
import ProjectionUtil from '../../../../utils/projectionUtil';
import type DomainStore from '../../../DomainStore/DomainStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { ISaveRoofProtrusionPropertyCommand } from '../../../ServiceBus/Commands/SaveRoofProtrusionPropertyCommand';

type RoofProtrusionProfileUI = {
  id: number;
  icon: string;
  label: string;
};

export type ProtrusionType = 'rectangular' | 'circular' | 'free-form';

export interface IRoofProtrusionViewModel {
  PROTRUSION_UNIT: string;
  MEASURE_LIMIT: Limit;
  PROTRUSION_PROFILE_OPTIONS: RoofProtrusionProfileUI[];
  id: string;
  roofProtrusion: RectangleProtrusion;
  roofFaceSelected: RoofFace;
  type: ProtrusionType;
  currentProtrusionProfile?: RoofProtrusionProfileUI;
  length: number;
  width: number;
  changeProfile(value: number): void;
  changeWidth(width: number): void;
  changeLength(length: number): void;
  changeName(name: string): void;
  changeFirefighter(value: boolean): void;
}

export class RoofProtrusionViewModel implements IRoofProtrusionViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly PROTRUSION_UNIT: string = 'in';
  readonly MEASURE_LIMIT: Limit = {
    lower: 1,
    upper: 120
  };
  readonly PROTRUSION_PROFILE_OPTIONS: RoofProtrusionProfileUI[] = [
    {
      id: EProtrusionProfile.HORIZONTAL,
      icon: 'horizontal-protrusion',
      label: 'Horizontal'
    },
    {
      id: EProtrusionProfile.PARALLEL,
      icon: 'parallel-protrusion',
      label: 'Parallel to the Roof'
    }
  ];

  @observable
  roofProtrusion: RectangleProtrusion;
  type: ProtrusionType;
  currentProtrusionProfile?: RoofProtrusionProfileUI;
  roofFaceSelected: RoofFace;

  private serviceBus: ServiceBus;
  private domain: DomainStore;

  @computed
  get length(): number {
    return RoofProtrusionViewModel.convertProtrusionDistanceFromWorldUnits(this.roofProtrusion.getLength());
  }

  @computed
  get width(): number {
    return RoofProtrusionViewModel.convertProtrusionDistanceFromWorldUnits(this.roofProtrusion.getWidth());
  }

  static convertProtrusionDistanceFromWorldUnits(worldUnits: number) {
    const value = ProjectionUtil.convertFromWorldUnits(worldUnits, Units.Inches);
    return Number(value.toFixed(2));
  }

  constructor(
    domain: DomainStore,
    serviceBus: ServiceBus,
    type: ProtrusionType,
    roofProtrusion: RectangleProtrusion,
    roofFace: RoofFace
  ) {
    this.domain = domain;
    this.serviceBus = serviceBus;
    this.type = type;
    this.roofFaceSelected = roofFace;
    this.roofProtrusion = roofProtrusion;
    if (roofProtrusion.profile) {
      this.changeProfile(roofProtrusion.profile);
    }
  }

  @action.bound
  changeProfile(value: number): void {
    const profileSelected = value as EProtrusionProfile;
    this.currentProtrusionProfile = this.PROTRUSION_PROFILE_OPTIONS.filter(
      (roofProfileUI: RoofProtrusionProfileUI): boolean => roofProfileUI.id === profileSelected
    )[0];
  }

  @action.bound
  changeWidth(width: number): void {
    if (width >= this.roofProtrusion.MIN_WIDTH) {
      const widthWorld = ProjectionUtil.convertToWorldUnits(width, Units.Inches);
      const saveAttributes = {
        domain: this.domain,
        roofFace: this.roofFaceSelected,
        protrusion: this.roofProtrusion,
        key: 'width',
        value: widthWorld
      } as ISaveRoofProtrusionPropertyCommand;

      this.roofProtrusion.setWidth(widthWorld);

      this.serviceBus.send('save_roof_protrusion_property_command', saveAttributes);
    } else {
      this.roofProtrusion.notifyTooSmall();
    }
  }

  @action.bound
  changeLength(length: number): void {
    if (length >= this.roofProtrusion.MIN_LENGTH) {
      const lengthWorld = ProjectionUtil.convertToWorldUnits(length, Units.Inches);
      const saveAttributes = {
        domain: this.domain,
        roofFace: this.roofFaceSelected,
        protrusion: this.roofProtrusion,
        key: 'length',
        value: lengthWorld
      } as ISaveRoofProtrusionPropertyCommand;

      this.roofProtrusion.setLength(lengthWorld);

      this.serviceBus.send('save_roof_protrusion_property_command', saveAttributes);
    } else {
      this.roofProtrusion.notifyTooSmall();
    }
  }

  @action.bound
  changeName(name: string): void {
    const saveAttributes = {
      domain: this.domain,
      roofFace: this.roofFaceSelected,
      protrusion: this.roofProtrusion,
      key: 'name',
      value: name
    } as ISaveRoofProtrusionPropertyCommand;

    this.serviceBus.send('save_roof_protrusion_property_command', saveAttributes);
  }

  @action.bound
  changeFirefighter(firefighter: boolean): void {
    const saveAttributes = {
      domain: this.domain,
      roofFace: this.roofFaceSelected,
      protrusion: this.roofProtrusion,
      key: 'firefighter',
      value: firefighter
    } as ISaveRoofProtrusionPropertyCommand;

    this.serviceBus.send('save_roof_protrusion_property_command', saveAttributes);
  }
}
