import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import type { BaseGuide } from '../../../../stores/UiStore/SmartGuidesStore/SmartGuides/BaseGuide';
import {
  TooltipContent, TooltipGuideKey, TooltipGuideName
} from './styles';

type ItemProps = {
  guide: BaseGuide;
};

const SmartGuideTooltip = ({ guide }: ItemProps): ReactElement => {
  const keyCode = String.fromCharCode(guide.commandKeyCode);
  const withCmd = guide.commandKeyCtrl ?? '⌘';

  return (
    <TooltipContent>
      <TooltipGuideName>{guide.name}</TooltipGuideName>
      <TooltipGuideKey>
        {withCmd}
        {keyCode}
      </TooltipGuideKey>
    </TooltipContent>
  );
};

export default observer(SmartGuideTooltip);
