import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';

export const LayoutWrapper = styled.div.attrs({
  className: 'pv-xxs ph-xs mv-xs'
})<PropsTheme>`
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grayColor4};
  border-radius: 5px;
`;
