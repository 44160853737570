import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, computed
} from 'mobx';
import type { Building } from '../../../../../domain/models/SiteDesign/Building';
import type { TraceToolBase } from '../../../ToolbarStore/Project/TraceToolBase';
import type { IBaseStepViewModelDependencies } from '../BaseStepViewModel';
import { BaseStepViewModel } from '../BaseStepViewModel';

interface ISelectBuildingStepViewModel extends IBaseStepViewModelDependencies {
  readonly traceTool: TraceToolBase;
}

export class SelectBuildingViewModel extends BaseStepViewModel {
  readonly propCodeUI = 'select_building_modal';

  private readonly traceTool: TraceToolBase;

  constructor(dependencies: ISelectBuildingStepViewModel) {
    super(dependencies);
    this.traceTool = dependencies.traceTool;
  }

  @computed
  get buildings(): Option[] {
    return this.traceTool.availableBuildings.map(
      (building: Building): Option => ({
        name: building.name,
        value: building.id
      })
    );
  }

  override closeSteps(): void {
    this.traceTool.removeWipPolygon();
    this.wizard.clear();
  }

  @action.bound
  selectBuildings(buildingId: string): void {
    const nextStep = this.wizard.currentStep + 2;
    const prevStep = 1;

    this.domain.setCurrentBuilding(buildingId);
    this.wizard.changeStep(nextStep, prevStep);
  }
}
