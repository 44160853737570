import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import HelpToolbar from '../Shared/HelpToolbar';
import MainToolbar from './MainToolbar';

const DesignToolbar: React.FC = (): ReactElement => (
  <>
    <MainToolbar />
    <HelpToolbar />
  </>
);

export default observer(DesignToolbar);
