import {
  BufferAttribute, BufferGeometry, MeshBasicMaterial, Vector3
} from 'three';
import type { Color } from '../../typings/index';
import { LayerCanvas } from '../LayerCanvas';
import { Line } from '../Line';
import { Unzoomable } from '../../mixins/Unzoomable';
import { Drawable } from '../../mixins/Drawable';

const MixedClass = Unzoomable(Drawable(class SimpleClass {}));

export class StringingLine extends MixedClass {
  start: Vector3 = new Vector3();
  end: Vector3 = new Vector3();

  override color: Color = '#FFFFFF';

  unzoom(factor: number): void {
    this.redraw();
  }

  set(start: Vector3, end: Vector3): void {
    this.start.copy(start);
    this.end.copy(end);
    this.redraw();
  }

  redraw(): void {
    const geometry = this.calculateLineSegmentGometry();
    const material = new MeshBasicMaterial({
      transparent: true,
      depthTest: true,
      depthWrite: false,
      ...LayerCanvas.UPPERGROUND,
      color: this.color
    });
    this.clearChildren(this.mesh);
    const line = new Line(geometry, material);
    line.computeLineDistances();
    this.mesh.add(line);
    geometry.dispose();
    material.dispose();
  }

  private calculateLineSegmentGometry(): BufferGeometry {
    const geometry = new BufferGeometry();
    const vertices = new Float32Array([this.start.x, this.start.y, this.start.z, this.end.x, this.end.y, this.end.z]);
    geometry.setAttribute('position', new BufferAttribute(vertices, 3));
    return geometry;
  }
}
