import {
  action, computed, observable
} from 'mobx';
import type {
  IAcMicroinverterBranchOptionsData,
  IAcModuleBranchOptionsData,
  IDcOptimizerStringOptionsData,
  IMpptStringingOptionData,
  ISeriesStringConfigurationData,
  IStringingOptionData,
  ISeriesStringConfigurationOptionsData,
  IStringingOptionsResponseData
} from '../../../../../domain/entities/StringingOption/IStringingOptionData';
import type { Stringing } from '../../../../../domain/graphics/stringing/Stringing';
import {
  BRANCH_AC_MODULE,
  BRANCH_MICROINVERTER,
  CENTRAL_MPPT_STRING,
  STRING_WITH_DC_OPTIMIZERS
} from '../../../../../domain/models/Constants';
import type { IBasePanelViewModelDependencies } from '../BasePanelViewModel';
import { BasePanelViewModel } from '../BasePanelViewModel';
import type { EventDispatcherType } from '../../../../../services/eventSystem/eventSystemHook';
import {
  EventType, useEventSystemDispatch
} from '../../../../../services/eventSystem/eventSystemHook';
import { getRootStore } from '../../../../RootStoreInversion';
import type { DeleteObjectDependencies } from '../../../../ServiceBus/Commands/DeleteObjectCommand';
import StringingService from '../../../../../services/stringing/stringingService';

type DefinitionType =
  | IAcModuleBranchOptionsData
  | IAcMicroinverterBranchOptionsData
  | IDcOptimizerStringOptionsData
  | ISeriesStringConfigurationOptionsData;

export class StringConnected {
  @observable
  stringingOption?: IStringingOptionData | IMpptStringingOptionData;

  inverterId: string;
  mpptId: string;
  serverId: string;

  constructor(stringing: Stringing) {
    this.serverId = stringing.serverId;
    this.inverterId = stringing.getInverterId();
    this.mpptId = stringing.getMpptId();
  }

  getId(): string {
    return this.serverId;
  }

  getInverterId(): string {
    return this.inverterId;
  }

  getMpptId(): string {
    return this.mpptId;
  }

  @computed
  get totalModules(): number {
    if (!this.stringingOption) {
      return 0;
    }
    if (this.mpptId) {
      const mpptStringConfiguration = this.stringingOption as IMpptStringingOptionData;
      return mpptStringConfiguration ? mpptStringConfiguration.seriesStringDefinition.numberOfModules : 0;
    }
    const stringingOption = this.stringingOption as IStringingOptionData;
    const { numberOfModules = 0 } = stringingOption ?? {};
    return numberOfModules;
  }
}

export class SystemSummary {
  definition: DefinitionType;

  @observable
  strings: StringConnected[];

  constructor(definition: DefinitionType) {
    this.definition = definition;
    this.strings = [];
  }

  getInverterId(): string {
    return this.definition.inverterId;
  }

  getType(): string {
    return this.definition.type;
  }

  selectStringingOption(numModules: number, selected: Stringing): IStringingOptionData | IMpptStringingOptionData {
    const {
      mppts = [], stringingOptions
    } = this.definition;
    const numberOfStrings = this.strings.filter(
      (value: StringConnected): boolean => value.getMpptId() === selected.getMpptId()
    ).length;

    const mppt = mppts.find((value: ISeriesStringConfigurationData): boolean => value.mppt === selected.getMpptId());
    if (mppt) {
      const option = mppt.stringingOptions.find(
        (value: IMpptStringingOptionData): boolean =>
          value.numberOfStrings === numberOfStrings && value.seriesStringDefinition.numberOfModules === numModules
      );
      if (option) {
        return option;
      }
      return mppt.stringingOptions[mppt.stringingOptions.length - 1];
    }
    return stringingOptions[numModules - 1];
  }

  @action.bound
  clearStrings(): void {
    this.strings = [];
  }

  @action.bound
  addOrUpdateString(selected: Stringing): void {
    const totalModules = selected.getModules().length;
    const idx = this.strings.findIndex((value: StringConnected): boolean => value.getId() === selected.serverId);
    if (idx >= 0) {
      if (totalModules > 0) {
        this.strings[idx].stringingOption = this.selectStringingOption(totalModules, selected);
      }
    } else {
      const stringConnected = new StringConnected(selected);

      // Clear possible old instances of the same string:
      this.strings = this.strings.filter(
        ({ serverId }: { serverId: string }): boolean => serverId !== stringConnected.serverId
      );

      this.strings.push(stringConnected);
      if (totalModules > 0) {
        this.addOrUpdateString(selected);
      }
    }
  }

  @action.bound
  deleteString(selected: Stringing): void {
    const idx = this.strings.findIndex((value: StringConnected): boolean => value.getId() === selected.serverId);
    if (idx >= 0) {
      this.strings.splice(idx, 1);
    }
  }
}

export interface ISystemSummaryPanelViewModelDependencies extends IBasePanelViewModelDependencies {
  stringingOptions: IStringingOptionsResponseData[];
  stringingList: Stringing[];
  minDcInputVoltage?: number;
  maxDcInputVoltage?: number;
}

export class SystemSummaryPanelViewModel extends BasePanelViewModel {
  propCodeUI = 'system_summary_panel';
  type?: string;

  eventDispatcher: EventDispatcherType;

  @observable
  systemSummaryList: SystemSummary[] = [];

  @observable
  stringingOptions: IStringingOptionsResponseData[] = [];

  @observable
  stringingList: Stringing[] = [];

  @observable
  selectedString?: string;

  readonly minDcInputVoltage?: number;
  readonly maxDcInputVoltage?: number;

  constructor(dependencies: ISystemSummaryPanelViewModelDependencies) {
    super(dependencies);
    this.minDcInputVoltage = dependencies.minDcInputVoltage;
    this.maxDcInputVoltage = dependencies.maxDcInputVoltage;
    this.eventDispatcher = useEventSystemDispatch(); // eslint-disable-line react-hooks/rules-of-hooks
  }

  @computed
  get hasStringing(): boolean {
    return this.systemSummaryList.some((systemSummary: SystemSummary): boolean => systemSummary.strings.length > 0);
  }

  @computed
  get stringingOptionsLoading(): boolean {
    return !this.stringingOptions.length;
  }

  @action
  callDeleteStringCommand = (stringName: string, stringToDelete: Stringing): void => {
    const {
      editor, domain, serviceBus
    } = getRootStore();
    const dependencies: DeleteObjectDependencies = {
      editor: editor,
      domain: domain,
      object: {
        ...stringToDelete,
        name: stringName
      } as Stringing
    };

    StringingService.selectStringById(stringToDelete.serverId).then((): unknown =>
      serviceBus.send('delete_string_command', dependencies)
    );
  };

  @action
  setStringingOption = (selected: Stringing): void => {
    const idx = this.systemSummaryList.findIndex(
      (value: SystemSummary): boolean => value.getInverterId() === selected.getInverterId()
    );
    if (idx >= 0) {
      this.systemSummaryList[idx].addOrUpdateString(selected);
      this.setSelectedString(selected.serverId);
    }
  };

  @action
  setSelectedString = (selectedString: string = '', propagateSelectionEvent: boolean = true): void => {
    this.selectedString = selectedString;
    if (propagateSelectionEvent) {
      this.eventDispatcher({
        type: EventType.SelectStringingInInverterSummaryModal,
        payload: {
          stringingServerId: selectedString
        }
      });
    }
  };

  @action
  deleteString = (selected: Stringing): void => {
    const idx = this.systemSummaryList.findIndex(
      (value: SystemSummary): boolean => value.getInverterId() === selected.getInverterId()
    );
    if (idx >= 0) {
      this.systemSummaryList[idx].deleteString(selected);

      if (this.selectedString === selected.serverId) {
        this.setSelectedString(this.systemSummaryList[idx].strings[0]?.getId() ?? '');
      }
    }
  };

  @action
  setStringingOptions = (stringingOptions: IStringingOptionsResponseData[], stringingList: Stringing[]): void => {
    this.stringingOptions = stringingOptions;
    this.stringingList = stringingList;

    stringingOptions.forEach((info: IStringingOptionsResponseData): void => {
      switch (info.type) {
      case BRANCH_AC_MODULE:
        this.systemSummaryList.push(new SystemSummary(info as IAcModuleBranchOptionsData));
        this.type = BRANCH_AC_MODULE;
        break;

      case BRANCH_MICROINVERTER:
        this.systemSummaryList.push(new SystemSummary(info as IAcMicroinverterBranchOptionsData));
        this.type = BRANCH_MICROINVERTER;
        break;

      case STRING_WITH_DC_OPTIMIZERS:
        this.systemSummaryList.push(new SystemSummary(info as IDcOptimizerStringOptionsData));
        this.type = STRING_WITH_DC_OPTIMIZERS;
        break;

      default:
        this.systemSummaryList.push(new SystemSummary(info as ISeriesStringConfigurationOptionsData));
        this.type = CENTRAL_MPPT_STRING;
      }
    });

    stringingList.forEach((value: Stringing): void => {
      this.setStringingOption(value);
    });
  };

  @action.bound
  clearStrings(): void {
    this.systemSummaryList.forEach((systemSummary: SystemSummary): void => {
      systemSummary.clearStrings();
    });
  }
}
