import React, { BaseProps, ReactElement } from 'react';
import { PipelineProps, StepData } from '../../typings';
import { Step } from './Step';
import {
  AuroraStepDivider,
  PipelineContainer,
  ProgressBar
} from './styles';

type Props = BaseProps & PipelineProps;

const getStep = (
  index: number,
  currentStep: number
): 'ready' | 'active' | 'pending' => {
  if (index < currentStep) return 'ready';
  if (index > currentStep) return 'pending';
  return 'active';
};

const HorizontalPipeline = (props: Props): ReactElement => {
  const {
    currentStep = 0,
    data,
    auroraMode
  } = props;

  return (
    <PipelineContainer>
      <ProgressBar>
        {data.map(
          (step: StepData, index: number): ReactElement => (
            <React.Fragment key={index}>
              {(auroraMode && index > 0) && (<AuroraStepDivider />)}
              <Step state={getStep(index, currentStep)} auroraMode={auroraMode} {...step} />
            </React.Fragment>
          )
        )}
      </ProgressBar>
    </PipelineContainer>
  );
};

export { HorizontalPipeline };
