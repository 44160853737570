import camelCase from 'lodash/camelCase';
import defer from 'lodash/defer';
import extend from 'lodash/extend';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import set from 'lodash/set';
import type { IObservableArray } from 'mobx';
import {
  action, autorun, computed, decorate, observable
} from 'mobx';
import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import type { Vertex } from '../../domain/graphics/Vertex';
import { ERROR } from '../../domain/models/Constants';
import { RectangleProtrusion } from '../../domain/models/SiteDesign/RectangleProtrusion';
import type { Coords } from '../../domain/typings';
import { ERoofType } from '../../domain/typings';
import { DesignService } from '../../infrastructure/services/api/DesignService';
import {
  handleApiError, mergeByProperty, notify, parseErrorDetailsMessage
} from '../../utils/helpers';
import ProjectionUtil from '../../utils/projectionUtil';
import type EditorStore from '../EditorStore/EditorStore';
import { MAXIMUM_ZOOM_LEVEL } from '../EditorStore/constants';
import { ViewPort } from '../EditorStore/ViewportController';
import type { RoofProtrusionStore } from '../UiStore/Properties/RoofProtrusion/RoofProtrusionStore';
import type { WorkspaceStore } from '../UiStore/WorkspaceStore';
import { Workspace } from '../UiStore/WorkspaceStore';
import type Limit from '../../domain/models/Limit';
import type { IDesignCreationRequest } from '../../domain/request/DesignCreationRequest/IDesignCreationRequest';
import { isProjectWorkspace } from '../UiStore/WorkspaceStore/utils';
import type {
  ExternalProposalData,
  IAdditionalProjectData,
  IProjectData
} from '../../domain/models/SiteDesign/Project';
import { Project } from '../../domain/models/SiteDesign/Project';
import { SentryException } from '../../utils/sentryLog';
import { updateRoofFace3dValues } from '../../utils/UpdateRoofface3DValuesHandler';
import {
  EventType, getEventSystemDispatch
} from '../../services/eventSystem/eventSystemHook';
import type { Marker } from '../../domain/models/SiteDesign/Marker';
import type { PolygonDrawable } from '../../domain/mixins/PolygonDrawable';
import type { IDesignData } from '../../domain/models/Design/Design';
import { Design } from '../../domain/models/Design/Design';
import { getRootStore } from '../RootStoreInversion';
import type { DesignDelta } from '../../domain/entities/Design/DesignDelta';
import {
  DesignCreatedEvent,
  ParcelBoundaryDeletedEvent,
  ProjectCreatedEvent,
  RoofFaceDeletedEvent,
  RoofOutlineAddedEvent,
  RoofOutlineDeletedEvent
} from '../../services/analytics/DesignToolAnalyticsEvents';
import type { Address } from '../../domain/models/SiteDesign/Address';
import type { Building } from '../../domain/models/SiteDesign/Building';
import type {
  BaseAttributes, IOption
} from '../../domain/models/SiteDesign/IOption';
import { Outline } from '../../domain/models/SiteDesign/Outline';
import type { Site } from '../../domain/models/SiteDesign/Site';
import type {
  SiteEquipment, SiteEquipmentUnionType
} from '../../domain/models/SiteDesign/SiteEquipment';
import { RoofStory } from '../../domain/models/SiteDesign/RoofStory';
import { RoofFace } from '../../domain/models/SiteDesign/RoofFace';
import type { Parcel } from '../../domain/models/SiteDesign/Parcel';
import type { RoofProtrusion } from '../../domain/models/SiteDesign/RoofProtrusion';
import config from '../../config/config';
import { PromisableCommands } from '../ServiceBus/Commands/registry';
import type { SiteEquipmentItemKeyName } from '../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import { DocumentsService } from '../../infrastructure/services/api/DocumentsService';
import { getNonCoplanarRoofIds } from './InvalidDataFallbackFunctions';

decorate(Outline, {
  visible: observable
});

decorate(RoofFace, {
  color: observable,
  material: observable,
  visible: observable
});

decorate(RectangleProtrusion, {
  visible: observable
});

class DomainStore {
  readonly MIN_STEEP_SLOPED_ROOF_SLOPE_IN_DEGREES = 9.45; // 2/12 pitch

  @observable
  project: Project = new Project();

  readonly documentsService = new DocumentsService();

  private resolveDesignLoadingPromise!: () => void;
  /**
   * @description designLoadingPromise - this promise is used in the `store.reset`
   * method for awaiting design loading completion before switching back to project
   * workspace to avoid race conditions that leads to bug occurring when switching
   * between projects in a fast way.
   */
  designLoadingPromise?: Promise<void> = new Promise((resolve) => {
    this.resolveDesignLoadingPromise = resolve;
  });

  /**
   * Project's Internal Reference ID that is managed outside of the design tool
   */
  @observable
  private externallyManagedInternalReferenceId?: string;

  @observable
  dimensionCrossSectionOptions: IObservableArray<IOption<BaseAttributes>> = observable([]);

  @observable
  roofConstructionTypesOptions: IObservableArray<IOption<BaseAttributes>> = observable([]);

  @observable
  currentBuilding: Building | undefined = undefined;

  @observable
  guards: Record<string, boolean> = {
    pathwaysLoading: false
  };

  @observable
  private designId?: string;
  @observable
  optionalDesign?: Design;

  private readonly designService = new DesignService();

  constructor() {
    autorun(() => {
      if (this.currentBuilding && !this.project.site.buildings.includes(this.currentBuilding)) {
        this.currentBuilding = undefined;
      }
    });

    autorun(() => {
      Sentry.setTag('designId', this.designId ?? undefined);
    });
  }

  @computed
  get design(): Design {
    if (!this.optionalDesign) {
      throw new Error('Use "design" only when the design definitely exists (e.g. anywhere under design workspace)');
    }
    return this.optionalDesign;
  }

  @computed
  get internalReferenceId(): string {
    return this.project.internalReferenceId ?? this.externallyManagedInternalReferenceId ?? '';
  }

  @computed
  get buildings(): IObservableArray<Building> {
    return this.project.site.buildings;
  }

  @computed
  get parcel(): Parcel {
    return this.project.site.parcel;
  }

  buildingWithName = (buildingName?: string): Building | undefined => {
    return this.buildings.find(
      (building: Building): boolean => building.name.toLowerCase() === buildingName?.toLowerCase().trim()
    );
  };

  @computed
  get siteEquipment(): SiteEquipment {
    return this.project.site.equipment;
  }

  @computed
  get siteEquipmentModels(): SiteEquipmentUnionType[] {
    return this.project.site.equipment.getEquipmentMarkerObjects() as SiteEquipmentUnionType[];
  }

  @computed
  get allRoofStories(): RoofStory[] {
    return this.buildings.reduce((prevValue: RoofStory[], building: Building): RoofStory[] => {
      return [...prevValue, ...building.stories];
    }, []);
  }

  @computed
  get allRoofFaces(): RoofFace[] {
    return this.allRoofStories.reduce((prevValue: RoofFace[], roofStory: RoofStory): RoofFace[] => {
      return [...prevValue, ...roofStory.roofFaces];
    }, []);
  }

  @computed
  get hasRoofFaces(): boolean {
    return this.allRoofFaces.length > 0;
  }

  @computed
  get slopes(): number[] {
    return this.allRoofFaces
      .filter(
        (roofFace: RoofFace): boolean =>
          (roofFace.slope === 0 && roofFace.roofType === ERoofType.FLAT)
          || (roofFace.slope !== 0 && roofFace.roofType === ERoofType.SLOPED)
      )
      .filter((roofFace: RoofFace): boolean => !isNil(roofFace.slope))
      .map((roofFace: RoofFace): number => roofFace.slope!);
  }

  @computed
  get steepSlopes(): Limit | undefined {
    return this.slopesAsLimit(
      this.slopes.filter((slope: number): boolean => slope >= this.MIN_STEEP_SLOPED_ROOF_SLOPE_IN_DEGREES)
    );
  }

  @computed
  get lowSlopes(): Limit | undefined {
    return this.slopesAsLimit(
      this.slopes.filter((slope: number): boolean => slope < this.MIN_STEEP_SLOPED_ROOF_SLOPE_IN_DEGREES)
    );
  }

  private slopesAsLimit = (numbers: number[]): Limit | undefined => {
    if (!numbers.length) {
      return undefined;
    }
    return {
      upper: Math.max(...numbers),
      lower: Math.min(...numbers)
    };
  };

  @computed
  get calculatedRoofFaceName(): string {
    let counter = 1;
    while (this.allRoofFaces.find((roof: RoofFace): boolean => roof.name === `Roof face #${counter}`)) {
      counter++;
    }
    return `Roof face #${counter}`;
  }

  @computed
  get suggestedBuildingName(): string {
    let counter = 1;
    while (this.buildings.find((target: Building): boolean => target.name === `Building #${counter}`)) {
      counter++;
    }
    return `Building #${counter}`;
  }

  /** PARCEL */
  @action.bound
  setParcel(newParcel: Parcel): void {
    this.project.site.setParcel(newParcel);
  }

  @action.bound
  deleteParcelBoundary(): void {
    this.project.site.deleteParcelBoundary();
    config.analytics?.trackEvent(new ParcelBoundaryDeletedEvent(this));
  }

  /** BUILDING */
  @action.bound
  setCurrentBuilding(buildingID: string): void {
    const existBuilding: Building | undefined = this.buildings.find(
      (target: Building): boolean => target.id === buildingID
    );
    if (existBuilding) {
      this.currentBuilding = existBuilding;
    }
  }

  @action.bound
  addOrUpdateBuilding(building: Building): void {
    mergeByProperty<Building>(this.buildings, [building], 'id');
    this.currentBuilding = building;
  }

  getRoofFaceById(id: string): RoofFace | undefined {
    return this.allRoofFaces.find((roofFace: RoofFace): boolean => roofFace.serverId === id);
  }

  findBuildingByChild(polygon: PolygonDrawable): Building | undefined {
    return this.buildings.find((building: Building): boolean | undefined => {
      if (polygon instanceof Outline) {
        let isValid: boolean = !!building.roofOutline?.boundary.vertices;
        building.roofOutline?.boundary.vertices.forEach((element: Vertex, index: number): void => {
          const distance: number = polygon.boundary.vertices[index]?.getVector3().distanceTo(element.getVector3());
          if (distance > 0) {
            isValid = false;
          }
        });
        return isValid;
      }
      if (polygon instanceof RoofFace) {
        return !!building.roofFaceWithId(polygon.serverId);
      }
    });
  }

  /** OUTLINE */
  @action.bound
  addOutline(outline: Outline, currentBuilding: Building): void {
    if (!currentBuilding) {
      return;
    }
    if (!currentBuilding.roofOutline) {
      currentBuilding.roofOutline = outline;
    } else {
      extend(currentBuilding.roofOutline, outline);
    }
    config.analytics?.trackEvent(new RoofOutlineAddedEvent(this));
  }

  @action.bound
  deleteOutline(outline: Outline): void {
    const building = this.findBuildingByChild(outline);
    if (building) {
      building.roofOutline = undefined;
    }
    this.project.site.deleteEmptyBuildings();
    config.analytics?.trackEvent(new RoofOutlineDeletedEvent(this));
  }

  /** ROOF_STORY */
  @action.bound
  deleteEmptyRoofStories(): void {
    const buildings = this.buildings;
    buildings.forEach((building: Building): unknown =>
      remove(building.stories, (target: RoofStory): boolean => !target.roofFaces.length)
    );
  }

  /** ROOF_FACE */
  @action.bound
  addOrUpdateRoofFace(roofToUpdate: RoofFace, level?: number, building?: Building): void {
    const currentBuilding = building ?? this.findBuildingByChild(roofToUpdate);
    if (!currentBuilding) {
      // If the building was not found, we can't add or update anything
      return;
    }

    // This is the Roof Story that contains the Roof Face
    const roofFaceStory = currentBuilding.stories.find((story: RoofStory): boolean =>
      story.roofFaces.some((roofFace: RoofFace): boolean => roofFace.serverId === roofToUpdate.serverId)
    );

    if (isNil(level)) {
      // If the level parameter was not sent, the Roof Face should only update
      if (roofFaceStory) {
        mergeByProperty<RoofFace>(roofFaceStory.roofFaces, [roofToUpdate], 'serverId');
      }
    } else {
      // If the level parameter was sent, we should update the Roof Face level
      // This is the Roof Story where we are gonna set the Roof Face
      let levelStory = currentBuilding.stories.find((story: RoofStory): boolean => story.level === level);
      if (!levelStory) {
        levelStory = new RoofStory();
        levelStory.level = level;
      }
      // If the Roof Face is into a RoofStory, removes the Roof Face
      if (roofFaceStory) {
        remove(roofFaceStory.roofFaces, (target: RoofFace): boolean => target.serverId === roofToUpdate.serverId);
      }
      // Set the Roof Face into the levelStory and updates the building stories
      mergeByProperty<RoofFace>(levelStory.roofFaces, [roofToUpdate], 'id');
      mergeByProperty<RoofStory>(currentBuilding.stories, [levelStory], 'id');
      mergeByProperty<Building>(this.project.site.buildings, [currentBuilding], 'id');
      // Cleaning all the Roof Stories that don't have Roof Faces
      this.deleteEmptyRoofStories();
      // Cleaning all the Buildings that don't have Roof Faces or an Outline
      this.project.site.deleteEmptyBuildings();
    }
  }

  @action.bound
  deleteRoofFace(roofFace: RoofFace): void {
    const building = this.findBuildingByChild(roofFace);
    if (building) {
      building.stories.forEach((roofStory: RoofStory): unknown =>
        remove(roofStory.roofFaces, (target: RoofFace): boolean => target.serverId === roofFace.serverId)
      );
    }
    this.deleteEmptyRoofStories();
    this.project.site.deleteEmptyBuildings();
    config.analytics?.trackEvent(new RoofFaceDeletedEvent(this));
  }

  getLevelOfRoofFace(roofFace: RoofFace): number {
    let level = -1;
    this.buildings.forEach((building: Building): void => {
      const storyLevel = building.storyLevelOfRoofFace(roofFace.serverId);
      if (storyLevel) {
        level = storyLevel;
      }
    });
    return level;
  }

  /** PROTRUSION */
  @action.bound
  addOrUpdateRoofProtrusion(roofFace: RoofFace, protrusion: RoofProtrusion): void {
    const existProtrusion = roofFace.protrusions.findIndex((currentProtrusion: RoofProtrusion): boolean => {
      let allVertexEqual = true;
      currentProtrusion.boundary.vertices.forEach((vertex: Vertex, indexVertex: number): void => {
        if (vertex.getVector3().distanceTo(protrusion.boundary.vertices[indexVertex].getVector3()) > 0) {
          allVertexEqual = false;
        }
      });
      return allVertexEqual;
    });

    if (existProtrusion >= 0) {
      roofFace.protrusions[existProtrusion] = protrusion;
    } else {
      roofFace.protrusions.push(protrusion);
    }
    this.addOrUpdateRoofFace(roofFace);
  }

  @action.bound
  deleteProtrusion(protrusion: RoofProtrusion): void {
    this.allRoofFaces.forEach((roofFace: RoofFace): void => {
      remove(roofFace.protrusions, (target: RoofProtrusion): boolean => target.mesh.id === protrusion.mesh.id);
    });
  }

  /** SITE_EQUIPMENT */
  @action.bound
  addOrUpdateSiteEquipment(equipment: Marker): boolean {
    const site = this.siteEquipment;
    const key = camelCase(equipment.type) as keyof typeof SiteEquipmentItemKeyName;

    if (!site[key]) {
      set(site, [key], equipment);
      return true;
    } else {
      extend(site[key], equipment);
      return false;
    }
  }

  @action.bound
  deleteGenericSiteEquipment(equipment: Marker): void {
    const site = this.siteEquipment;
    const key = camelCase(equipment.type) as keyof typeof SiteEquipmentItemKeyName;
    set(site, [key], undefined);
  }

  /** PROJECT */
  @action.bound
  /**
   * @returns {Promise<boolean>} - true if project was created successfully, false otherwise
   */
  async createProjectUsingExternalProposalData(data: ExternalProposalData): Promise<boolean> {
    try {
      const installers = await this.designService.loadInstallers();
      const projectData = await this.designService.createProjectWithExternalProposalData(data, installers[0].id);

      await this.loadProject(projectData, getRootStore());
      config.analytics?.trackEvent(new ProjectCreatedEvent(projectData));
      window.history.pushState({}, '', `?project=${projectData.id}`);
    } catch (error) {
      parseErrorDetailsMessage(error as AxiosError).then((message: string): void => {
        notify(`External proposal project creation failed: ${message}`, ERROR);
      });
      // eslint-disable-next-line no-console
      console.warn('createProjectWithExternalProposalData failed', error);
      return false;
    }
    return true;
  }

  @action.bound
  async createProject(
    address: Address,
    coordinates: Coords,
    imagery: Site['imagery'],
    installer: string,
    internallyManagedAdditionalProjectData: IAdditionalProjectData,
    dependencies: {
      editor: EditorStore;
      roofProtrusion: RoofProtrusionStore;
      workspace: WorkspaceStore;
    }
  ): Promise<void> {
    try {
      const projectData = await this.designService.createProject(
        address,
        coordinates,
        imagery,
        installer,
        internallyManagedAdditionalProjectData
      );
      this.loadProject(projectData, dependencies);
      config.analytics?.trackEvent(new ProjectCreatedEvent(projectData));
      window.history.pushState({}, '', `?project=${projectData.id}`);
    } catch (error) {
      notify('Project creation failed', ERROR);
      // eslint-disable-next-line no-console
      console.error('Project creation failed', error);
    }
  }

  @action.bound
  async loadProjectById(
    projectId: string,
    dependencies: {
      editor: EditorStore;
      roofProtrusion: RoofProtrusionStore;
      workspace: WorkspaceStore;
      externallyManagedAdditionalProjectData?: IAdditionalProjectData;
    }
  ): Promise<IProjectData | undefined> {
    try {
      const project: IProjectData = await this.designService.getProject(projectId);

      this.loadProject(project, dependencies);
      return project;
    } catch (error) {
      handleApiError('Failed to fetch project data')(error);
    }
  }

  @action.bound
  async loadProject(
    projectData: IProjectData,
    dependencies: {
      editor: EditorStore;
      roofProtrusion: RoofProtrusionStore;
      workspace: WorkspaceStore;
      externallyManagedAdditionalProjectData?: IAdditionalProjectData;
    }
  ): Promise<void> {
    Sentry.setTag('projectId', projectData.id);
    if (!this.designLoadingPromise) {
      this.designLoadingPromise = new Promise((resolve) => {
        this.resolveDesignLoadingPromise = resolve;
      });
    }
    const zoomLevel = projectData.site.imagery.zoomLevel;
    const mapZoomFactor = zoomLevel > MAXIMUM_ZOOM_LEVEL ? -1 : 20 - zoomLevel;

    ViewPort.setMapZoomFactor(mapZoomFactor);

    // Set projection coordinates
    ProjectionUtil.setCoordinates(projectData.site.coordinateSystemOrigin);
    this.project = Project.fromData(projectData);

    const externallyManagedAdditionalProjectData = dependencies.externallyManagedAdditionalProjectData;
    if (externallyManagedAdditionalProjectData) {
      // Alternative 1 - Additional project data is managed externally in the Host App
      this.externallyManagedInternalReferenceId = externallyManagedAdditionalProjectData.internalReferenceId;
      if (externallyManagedAdditionalProjectData.customer) {
        this.project.participants.updateCustomer(externallyManagedAdditionalProjectData.customer);
      }
    } else {
      // Alternative 2 - Additional project data is managed within the Design Tool
      const additionalProjectData = projectData.supplementalData?.additionalProjectData;
      if (additionalProjectData) {
        if (additionalProjectData.internalReferenceId) {
          // In case of legacy location for storing internal reference ID - we move it to the new location
          this.project.internalReferenceId = additionalProjectData.internalReferenceId;
        }
        if (additionalProjectData.customer) {
          // Note: a better flow would be to pass the customer into project creation endpoint.
          // Currently we temporarily set it under supplemental data, and then move it to the proper location here
          this.project.participants.updateCustomer(additionalProjectData.customer);
        }
        delete this.project.supplementalData.additionalProjectData;
      }
    }

    if (dependencies) {
      const { editor } = dependencies;
      await editor.editorSetupPromise;
      await editor.setBaseImagery(projectData.id, projectData.site.imagery);
    }

    // Draw the site equipments
    if (dependencies && this.project.site.equipment) {
      const {
        editor, workspace
      } = dependencies;

      defer(async (): Promise<void> => {
        await editor.editorSetupPromise;
        editor.renderSiteMarkers(workspace.currentWorkspace);
      });
    }

    if (dependencies && projectData.site.buildings) {
      defer(async (): Promise<void> => {
        await dependencies.editor.editorSetupPromise;
        if (this.project.site.parcel.hasBoundary) {
          dependencies.editor.addOrUpdateObject(this.project.site.parcel.mesh);
        }
      });
    }

    if (dependencies && projectData.site.buildings) {
      defer(async (): Promise<void> => {
        // Draw buildings on canvas
        const {
          editor, roofProtrusion
        } = dependencies;

        await editor.editorSetupPromise;

        this.project.site.buildings.forEach(({ roofOutline }: Building): void => {
          if (roofOutline) {
            editor.addOrUpdateObject(roofOutline.mesh);
          }
        });

        this.allRoofFaces.forEach((roofFace: RoofFace): void => {
          const { protrusions } = roofFace;
          editor.addOrUpdateObject(roofFace.mesh);
          if (protrusions) {
            protrusions.forEach((protrusion: RoofProtrusion): void => {
              roofProtrusion.createProtrusionViewModel('rectangular', protrusion as RectangleProtrusion, roofFace);
            });
          }
        });

        // Set `hasEverBeenValidAfterUserInteraction` field to each vertex so that it'd be possible to
        // disable building movement validation when the project is in an invalid state from the start.
        this.project.site.buildings.forEach((building: Building): void => {
          const allEdgeVertices: Vertex[] = [
            ...(building?.roofFaces ?? []).flatMap((roofFace: RoofFace): Vertex[] => roofFace.boundary.vertices),
            ...(building?.roofOutline?.boundary.vertices ?? [])
          ];

          for (const vertex of allEdgeVertices) {
            vertex.hasEverBeenValidAfterUserInteraction = vertex.isValidCurrentPosition(editor);
          }
        });
      });
    }

    await this.getDesign(projectData.id, dependencies);
  }

  @action.bound
  async updateProject(): Promise<void> {
    let projectData = this.project.toData();

    const nonCoplanarRoofIds = getNonCoplanarRoofIds(projectData);

    if (nonCoplanarRoofIds.length) {
      for (const roofFaceId of nonCoplanarRoofIds) {
        const roofFace = this.getRoofFaceById(roofFaceId);
        if (!roofFace) {
          // eslint-disable-next-line no-console
          console.error(`Couldn't find roof face using id: ${roofFaceId}`);
          continue;
        }
        await updateRoofFace3dValues(roofFace, this);
        this.addOrUpdateRoofFace(roofFace);
      }
      // Recreate project data with recent changes
      projectData = this.project.toData();

      getEventSystemDispatch()({
        type: EventType.NonCoplanarPolygonDetected
      });
    }

    await this.designService.updateProject(projectData).catch(handleApiError('Project could not be updated'));
  }

  /** DESIGN */
  @action.bound
  async loadDesign(projectId?: string): Promise<void> {
    if (projectId) {
      if (!this.designId) {
        const designIds = await this.designService
          .getDesignsByProject(projectId)
          .catch(handleApiError(`Failed to retrieve design IDs for project ${projectId}`));
        this.designId = designIds?.length ? designIds[designIds.length - 1] : undefined;
      }
    }
    if (this.designId) {
      const designData: IDesignData = await this.designService
        .getDesign(this.designId)
        .catch(handleApiError(`Failed to retrieve design ${this.designId}`));
      this.setDesignFromData(designData);

      if (designData.state.requiresExternalProposalDesignImport) {
        try {
          const designDelta: DesignDelta = await this.designService.completeExternalProposalDesignImport(
            this.design.toData()
          );

          await getRootStore().serviceBus.promisedSend(
            PromisableCommands.update_design_delta,
            designDelta.toApplyDesignDeltaCommand(this)
          );
        } catch (e) {
          await handleApiError(
            `Failed to update design after completing import of external proposal design ${this.designId}`
          )(e);
        }
      }

      await this.design.updateSupplementalDataIfNeeded();
    }
  }

  @action.bound
  createDesign = async (designCreationRequest: IDesignCreationRequest): Promise<void> => {
    return this.designService
      .createDesign(designCreationRequest, this.project.id)
      .then((designData: IDesignData): void => {
        this.setDesignFromData(designData);
        config.analytics?.trackEvent(new DesignCreatedEvent(this.project.id, designData.id));
      })
      .catch(handleApiError(`Failed to create design for project ${this.project.id}`));
  };

  @action.bound
  async updateDesign(): Promise<void> {
    if (!this.optionalDesign) {
      return;
    }
    const designData = this.optionalDesign.toData();
    await this.designService.updateDesign(designData).catch(handleApiError('Design could not be updated'));
  }

  @action.bound
  setDesignFromData = (designData: IDesignData): void => {
    this.optionalDesign = new Design(designData);
    this.designId = this.optionalDesign.id;
  };

  @action.bound
  forceSetDesignFromData = (capturedDesignData: IDesignData): void => {
    this.setDesignFromData = (): void => {
      this.optionalDesign = new Design(capturedDesignData);
      this.designId = this.optionalDesign.id;
    };
    (this.setDesignFromData as () => void)();
  };

  @action.bound
  resetDesign(): void {
    this.optionalDesign = undefined;
    this.designId = undefined;
  }

  toJson(): { designJson: string; projectJson: string } {
    const result: { designJson: string; projectJson: string } = {
      designJson: '',
      projectJson: ''
    };
    try {
      if (this.optionalDesign) {
        result.designJson = JSON.stringify(this.optionalDesign.toData());
      }
      result.projectJson = JSON.stringify(this.project.toData());
    } catch (e) {
      SentryException('Domain store serialization failed', e);
    }
    return result;
  }

  private async getDesign(
    projectId: string,
    dependencies: {
      workspace: WorkspaceStore;
    }
  ): Promise<void> {
    const { workspace } = dependencies;
    if (
      isProjectWorkspace(workspace.currentWorkspace)
      && (await workspace.currentWorkspace.canSwitchToDesignWorkspaceAfterLoadingDesign())
    ) {
      await this.loadDesign(projectId);
      if (this.optionalDesign) {
        await workspace.asyncChangeWorkspace(Workspace.Design);
      }
    }
    this.resolveDesignLoadingPromise();
    this.designLoadingPromise = undefined;
  }

  /** @TODO get this data in the view model */
  @action.bound
  getDimensionCrossSectionOptions(yearOfConstruction: number): void {
    this.designService
      .getLumberCrossSections({
        yearOfConstruction
      })
      .then((response: IOption<BaseAttributes>[]): void => {
        this.dimensionCrossSectionOptions.replace(response);
      });
  }

  /** @TODO get this data in the view model */
  @action.bound
  async getRoofConstructionTypes(): Promise<void> {
    return this.designService.getRoofConstructionTypes().then((response: IOption<BaseAttributes>[]): void => {
      this.roofConstructionTypesOptions.replace(response);
    });
  }

  @computed
  get getProjectDescription(): string {
    return this.project.description ?? '';
  }

  @action.bound
  setProjectDescription(description: string): void {
    this.project.description = description;
  }

  /**
   * The intention of this method is to clear domain's state as much as possible as
   * it's used when switching between projects in the same page (the same browser
   * tab without page reloading).
   */
  @action.bound
  reset(): void {
    this.externallyManagedInternalReferenceId = undefined;
    this.dimensionCrossSectionOptions.clear();
    this.roofConstructionTypesOptions.clear();
    this.project = new Project();
    this.resetDesign();
    this.currentBuilding = undefined;
    this.designLoadingPromise = new Promise((resolve) => {
      this.resolveDesignLoadingPromise = resolve;
    });
  }
}

export default DomainStore;
