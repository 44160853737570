import type {
  BaseProps, ReactElement, ReactNode
} from 'react';
import * as React from 'react';
import type { BaseRoofFaceViewModel } from '../../../../stores/UiStore/Properties/RoofProperties/BaseRoofFaceViewModel';
import LineHr from '../../../components/LineHr';
import Field from '../../../components/Field';
import Switch from '../../../components/Switch/Switch';
import SubmitSection from '../../../components/SubmitSection/SubmitSection';
import {
  MultiEditWrapper, SubmitSectionWrapper, Stretcher, Scrollable
} from './styles';

type Props = BaseProps & {
  viewModel: BaseRoofFaceViewModel;
  multiEdit?: boolean;
  visibility?: boolean;
};

const showMultiEditOption = (multiEdit: boolean = false): ReactNode => {
  if (multiEdit) {
    return (
      <>
        <LineHr />
        <Field inline={true} label="MULTI-EDIT">
          <Switch />
        </Field>
        <LineHr />
      </>
    );
  } else {
    return <></>;
  }
};

function RoofPropertiesParent(props: Props): ReactElement {
  const {
    children, viewModel, multiEdit, visibility = true
  } = props;
  return (
    <Stretcher>
      {multiEdit && <MultiEditWrapper hideLabel={visibility}>{showMultiEditOption(multiEdit)}</MultiEditWrapper>}
      <Scrollable>{children}</Scrollable>
      <SubmitSectionWrapper>
        <SubmitSection viewModel={viewModel} />
      </SubmitSectionWrapper>
    </Stretcher>
  );
}

export default RoofPropertiesParent;
