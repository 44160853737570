export interface IColorsFactory { [key: number]: string; }

export const ColorsFactory: IColorsFactory = {
  1:   '#3eacf7',
  1.5: '#10acb8',
  2:   '#cb5013',
  2.5: '#ae6600',
  3:   '#c955ff',
  3.5: '#ca00b0',
  4:   '#babe0b'
};
