import isNil from 'lodash/isNil';
import {
  computed, observable
} from 'mobx';

export interface ITerrainDesignParametersData {
  propertyGrade?: number;
  windExposureCategory?: string;
  topographicalCondition?: string;
}

export class TerrainDesignParameters {
  /**
   * Average grade of property in range [0, 1], where 0.4 means "Up to 40%"
   */
  @observable
  propertyGrade?: number;
  /**
   * ASCE categories for describing wind exposure categories.
   * These enums could change with future versions of ASCE
   * ENUM B, C, D
   */
  @observable
  windExposureCategory?: string;
  /**
   * ENUM: STANDARD, TOP_OF_HILL, COASTAL, MESA
   */
  @observable
  topographicalCondition?: string;

  constructor(data?: ITerrainDesignParametersData) {
    this.propertyGrade = data?.propertyGrade;
    this.windExposureCategory = data?.windExposureCategory;
    this.topographicalCondition = data?.topographicalCondition;
  }

  toData = (): ITerrainDesignParametersData | undefined => {
    if (this.isEmpty) {
      return undefined;
    }
    return {
      propertyGrade: this.propertyGrade,
      windExposureCategory: this.windExposureCategory,
      topographicalCondition: this.topographicalCondition
    };
  };

  @computed
  get isEmpty(): boolean {
    return isNil(this.propertyGrade) && isNil(this.windExposureCategory) && isNil(this.topographicalCondition);
  }

  @computed
  get averagePropertyGradeAsText(): string {
    return !isNil(this.propertyGrade) ? `Up to ${this.propertyGrade * 100}%` : 'Not Known';
  }

  @computed
  get averagePropertyGradeAsOptionValue(): string {
    return !isNil(this.propertyGrade) ? this.propertyGrade.toString() : 'Not Known';
  }
}
