import debounce from 'lodash/debounce';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type {
  Design, IDesignData
} from '../../models/Design/Design';
import type { IModulesOption } from '../../models/SiteDesign/IModulesOption';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../models/SiteDesign/RoofFace';
import type IPvModuleChangeRequest from '../../request/PvModuleChangeRequest/PvModuleChangeRequest';
import type { IStage } from '../IStage';
import { handleApiError } from '../../../utils/helpers';
import type { DesignCreationWizardStore } from '../../../stores/UiStore/Wizard/DesignCreationWizardStore';
import { DesignStep } from '../../models/Design/DesignState';
import type { IUpdateSupplementalPvModuleDefinitionDataDependencies } from '../../../stores/ServiceBus/Commands/UpdateSupplementalPvModuleDefinitionDataCommand';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { EquipmentService } from '../../../infrastructure/services/api/EquipmentService';
import { PvModuleDefinitionSpecifiedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../config/config';

export interface INewSystemDesignStageDeps {
  readonly isDesignCreation: boolean;
  readonly designCreationWizardStore?: DesignCreationWizardStore;
  readonly domain: DomainStore;
  readonly serviceBus?: ServiceBus;
  readonly editor?: EditorStore;
  readonly onContinue?: () => void;
}

export class NewSystemDesignStage implements IStage {
  readonly id: string = 'new_system_design_stage';
  readonly propCodeUI = 'new_system_design_modal';

  /**
   * This holds either the search query (as typed by user) or the make and model of the selected PV module definition
   */
  @observable
  pvModuleDefinitionSearchQuery: string = '';
  @observable
  pvModuleDefinitionSearchResults: IModulesOption[] = observable([]);
  @observable
  selectedPvModuleDefinitionId: string = '';
  @observable
  loading: boolean = false;

  private readonly loadPvModuleDefinitionOptionsWithDelay = debounce((): void => {
    this.loadPvModuleDefinitionOptions(this.pvModuleDefinitionSearchQuery).then((): void => {
      this.loading = false;
    });
  }, 1000);

  readonly isDesignCreation: boolean;
  private readonly designService = new DesignService();
  private readonly equipmentService = new EquipmentService();
  private readonly designCreationWizardStore?: DesignCreationWizardStore;
  private readonly editor?: EditorStore;
  private readonly domain: DomainStore;
  private readonly currentDesign?: Design;
  private readonly serviceBus?: ServiceBus;
  private readonly onContinue?: () => void;

  constructor(dependencies: INewSystemDesignStageDeps) {
    const {
      isDesignCreation, designCreationWizardStore, domain, serviceBus, editor, onContinue
    } = dependencies;
    this.isDesignCreation = isDesignCreation;
    this.domain = domain;
    if (this.isDesignCreation) {
      this.designCreationWizardStore = designCreationWizardStore!;
      this.setInitialValuesFromIncompleteWizard();
    } else {
      this.currentDesign = this.domain.design;
      this.serviceBus = serviceBus;
      this.editor = editor;
      this.onContinue = onContinue;
      this.setInitialValuesFromDomainModel();
    }
  }

  @action.bound
  private setInitialValuesFromIncompleteWizard(): void {
    this.selectedPvModuleDefinitionId = this.designCreationWizardStore!.pvModuleDefinitionId;
    this.pvModuleDefinitionSearchQuery = this.designCreationWizardStore!.pvModuleDefinitionTitle;
  }

  @action.bound
  private setInitialValuesFromDomainModel(): void {
    this.selectedPvModuleDefinitionId = this.currentDesign!.system.equipment.pvModules.definition;
    const supplementalPvModuleInfo = this.currentDesign!.supplementalData?.pvModuleInfo;
    this.pvModuleDefinitionSearchQuery = supplementalPvModuleInfo
      ? `${supplementalPvModuleInfo?.make} ${supplementalPvModuleInfo?.model}`
      : '';
  }

  @action.bound
  onPvModuleDefinitionSearchQueryChange(searchQuery: string): void {
    this.selectedPvModuleDefinitionId = '';
    this.pvModuleDefinitionSearchQuery = searchQuery;
    this.pvModuleDefinitionSearchResults = [];
    if (!searchQuery) {
      this.loading = false;
      return;
    }
    if (!this.loading) {
      this.loading = true;
      this.loadPvModuleDefinitionOptionsWithDelay();
    }
  }

  @action.bound
  onPvModuleDefinitionSearchResultSelected(pvModuleDefinitionId: string, pvModuleDefinitionTitle: string): void {
    this.selectedPvModuleDefinitionId = pvModuleDefinitionId;
    this.pvModuleDefinitionSearchQuery = pvModuleDefinitionTitle;
    this.pvModuleDefinitionSearchResults = [];
  }

  @computed
  get canContinue(): boolean {
    return Boolean(this.selectedPvModuleDefinitionId) && Boolean(this.pvModuleDefinitionSearchQuery);
  }

  @action.bound
  continue(): void {
    if (this.isDesignCreation) {
      this.designCreationWizardStore!.setPvModuleDefinition(
        this.selectedPvModuleDefinitionId,
        this.pvModuleDefinitionSearchQuery
      );
    } else {
      if (this.currentDesign!.state.isUserIn(DesignStep.ARRAY_PLACEMENT)) {
        this.updatePvModuleDefinition();
      }
    }
    config.analytics?.trackEvent(
      new PvModuleDefinitionSpecifiedEvent(
        this.domain,
        this.selectedPvModuleDefinitionId,
        this.pvModuleDefinitionSearchQuery
      )
    );
  }

  private updatePvModuleDefinition(): void {
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor!);

    const design: IDesignData = this.currentDesign!.toData();
    const request: IPvModuleChangeRequest = {
      newPvModuleDefinitionId: this.selectedPvModuleDefinitionId,
      design
    };
    this.designService
      .updatePvModuleDefinition(request)
      .then((response: DesignDelta): void => {
        runInAction((): void => {
          this.serviceBus!.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));

          const dependencies: IUpdateSupplementalPvModuleDefinitionDataDependencies = {
            domain: this.domain
          };
          this.serviceBus!.send('update_supplemental_pv_module_definition_data', dependencies);
        });
        this.onContinue?.();
      })
      .catch(handleApiError('Failed to update PV module definition'))
      .finally((): void => hideLoaders());
  }

  cancel(): void {
    /** */
  }

  dispose(): void {
    // Deallocate stuff here
  }

  resume(lastValidStage: string): void {
    // Not needed
  }

  private async loadPvModuleDefinitionOptions(moduleValue: string): Promise<void> {
    const data = await this.equipmentService
      .getPvModuleDefinitionOptions(moduleValue)
      .catch(handleApiError('Failed to retrieve PV module options'));
    runInAction((): void => {
      this.pvModuleDefinitionSearchResults = data;
    });
  }
}
