import React, { BaseProps, ReactElement, useCallback } from 'react';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { CustomTabLayout, CustomTabsWrapper, Content, Wrapper } from './styles';
import { TabLabel } from './TabLabel';
import { Icon } from '../Icon/Icon';
import { H2 } from '../FontFamily/Fonts';

export type CustomTabsProps = BaseProps & {
  labels: TabLabel[];
  contents: ReactElement[] | string[];
  layoutClassName?: string;
  value: number;
  onClick?: (event: number) => void;
  activeThemeColor?: EThemeComponentColor;
  inactiveThemeColor?: EThemeComponentColor;
};

const CustomTabs = ({
  labels,
  contents,
  className,
  value,
  onClick,
  activeThemeColor = EThemeComponentColor.PURPLE,
  inactiveThemeColor = EThemeComponentColor.WHITE,
}: CustomTabsProps): ReactElement => {
  const handleClick = useCallback(
    (event: number): void => onClick && onClick(event),
    []
  );

  return (
    <Wrapper>
      <CustomTabsWrapper inactiveThemeColor={inactiveThemeColor} className={className}>
        {labels.map(
          ({ name, image, ...props }: TabLabel, index: number): ReactElement => (
            <CustomTabLayout
              key={index}
              className={`${props.index === value ? 'active-tab' : ''}`}
              active={props.index === value}
              inactiveThemeColor={inactiveThemeColor}
              inactiveBorderThemeColor={EThemeComponentColor.GRAY_200}
              activeThemeColor={activeThemeColor}
              data-testid={`custom-tab-${index}`}
              onClick={(): void => {
                handleClick(props.index);
              }}
            >
              <Icon name={image} className="icon" />
              <H2 className={`${props.index === value ? 'name' : 'hidden-xxl name'}`}>{name}</H2>
            </CustomTabLayout>
          )
        )}
      </CustomTabsWrapper>
      <Content>
        {contents[value]
          ||
        'The number of tab images must be equal to the number of tabs'
        }
      </Content>
    </Wrapper>
  );
};

export { CustomTabs };
