// IDs
export const CIRCULAR_PROTRUSION_TOOL_ID = 'circular-protrusion';
export const FREE_FORM_PROTRUSION_TOOL_ID = 'free-protrusion';
export const GM_TOOL_ID = 'GM';
export const HELP_DOCS_TOOL = 'help-docs';
export const MAP_PROVIDER_TOOL = 'map-source';
export const MSP_TOOL_ID = 'MSP';
export const PANELS_TOOL_ID = 'panels';
export const PANNING_TOOL_ID = 'pan';
export const PROTRUSION_TOOL_ID = 'protrusions';
export const RECTANGULAR_PROTRUSION_TOOL_ID = 'rectangular-protrusion';
export const ROOF_TRACING_TOOL_ID = 'tracing-tool';
export const SELECT_TOOL_ID = 'selection';
export const SITE_EQUIPMENT_TOOL_ID = 'site-equipment';
export const SITE_FEATURE_TOOL_ID = 'site-features';
export const SITE_INFO_TOOL_ID = 'site-info';
export const SUBPANEL_TOOL_ID = 'SP';
export const TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID = 'trace-roof';
export const OUTLINE_TOOL_ID = 'outline';
export const IMPORT_FILE_TOOL_ID = 'import-file';
export const UTILITY_METER_TOOL_ID = 'UM';
export const STREET_LOCATION_ID = 'STREET';
export const CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID = 'custom-base-imagery-transformation';
export const TRACE_TO_DEFINE_SCALING_TOOL_ID = 'trace-to-define-scaling';
export const PARCEL_BOUNDARY_TOOL_ID = 'parcel-boundary-tool';
