import {
  LyraFormElementsDesignTool, LyraLayout, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactText
} from 'react';
import React, {
  useEffect, useState
} from 'react';
import useStore from '../../../../stores/useStore';
import type { ProjectPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/ProjectPropertiesModal/ProjectPropertiesViewModel';
import {
  AdditionalCodes,
  APIAttribution,
  APIAttributionText,
  DefaultCodes,
  LocallyAdoptedCodes,
  PropertiesTitle,
  TagsContainer
} from './styles';

const { DropDownNew } = LyraFormElementsDesignTool.FormElementsDesignTool;
const { DropDownNew: DropDown } = DropDownNew;

const SitePropertyForm: React.FC = (): ReactElement => {
  const { modal } = useStore();
  const [projectPropertiesViewModel] = useState(modal.viewModel as ProjectPropertiesViewModel);

  useEffect((): void => {
    projectPropertiesViewModel.loadSelectionOptions();
  }, [projectPropertiesViewModel]);

  const {
    lseOptions,
    ahjOptions,
    electricalCodeOptions,
    fireCodeOptions,
    changeApn,
    changeLoadServingEntity,
    selectedLseOption,
    changeSelectedAuthorityHavingJurisdiction,
    selectedAhjOption,
    changeZoning,
    siteGeneralProperties,
    additionalCodes,
    changeInputTag,
    selectedElectricalCodeOption,
    selectedFireCodeOption,
    changeElectricalCode,
    changeFireCode
  } = projectPropertiesViewModel;

  return (
    <>
      <PropertiesTitle className="pv-xxxs ph-xs mt-xs mb-xs">
        <LyraTypography.Heading margin="0">GENERAL</LyraTypography.Heading>
      </PropertiesTitle>

      <LyraLayout.Container>
        <LyraLayout.Row>
          <LyraLayout.Col xs={6} className="mb-m">
            <DropDown
              name="Authority Having Jurisdiction (AHJ)"
              options={ahjOptions}
              value={selectedAhjOption as IDropDownOption}
              menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
              onChange={(option: unknown): void => changeSelectedAuthorityHavingJurisdiction(option as IDropDownOption)}
            />
          </LyraLayout.Col>
          <LyraLayout.Col xs={6} className="mb-m">
            <DropDown
              name="Utility Company"
              options={lseOptions}
              value={selectedLseOption as IDropDownOption}
              menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
              onChange={(option: unknown): void => changeLoadServingEntity(option as IDropDownOption)}
            />
          </LyraLayout.Col>
        </LyraLayout.Row>
        <LyraLayout.Row>
          <LyraLayout.Col xs={6} className="mb-m mt-xxxs">
            <LyraFormElementsDesignTool.FormElementsDesignTool.Input.Input
              name="Assessor's Parcel Number (APN)"
              value={siteGeneralProperties.assessorsParcelNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => changeApn(event.target.value as string)}
            />
          </LyraLayout.Col>
          <LyraLayout.Col xs={6} className="mb-m mt-xxxs">
            <LyraFormElementsDesignTool.FormElementsDesignTool.Input.Input
              name="Zoning"
              value={siteGeneralProperties.zoning}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                changeZoning(event.target.value as string)
              }
            />
          </LyraLayout.Col>
        </LyraLayout.Row>
      </LyraLayout.Container>

      <PropertiesTitle className="pv-xxxs ph-xs mt-xxs mb-xs">
        <LyraTypography.Heading margin="0">LOCALLY ADOPTED CODES</LyraTypography.Heading>
      </PropertiesTitle>

      <LocallyAdoptedCodes>
        <DefaultCodes>
          <LyraLayout.Row>
            <LyraLayout.Col xs={6} className="mt-xxxs">
              <DropDown
                name="Electrical Code"
                value={selectedElectricalCodeOption}
                options={electricalCodeOptions}
                menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
                onChange={(option: unknown): void => {
                  changeElectricalCode(option as IDropDownOption);
                }}
              />
            </LyraLayout.Col>
            <LyraLayout.Col xs={6} className="mt-xxxs">
              <DropDown
                name="Fire Code"
                value={selectedFireCodeOption}
                options={fireCodeOptions}
                menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
                onChange={(option: unknown): void => {
                  changeFireCode(option as IDropDownOption);
                }}
              />
            </LyraLayout.Col>
          </LyraLayout.Row>
        </DefaultCodes>
        <AdditionalCodes>
          <TagsContainer>
            <LyraFormElementsDesignTool.FormElementsDesignTool.TagInput.TagInput
              name="Additional Codes"
              propTags={additionalCodes}
              colorTheme={EThemeComponentColor.GRAY_400}
              onChange={(value: ReactText[]): void => {
                changeInputTag(value as string[]);
              }}
            />
          </TagsContainer>
        </AdditionalCodes>
        <APIAttribution>
          <APIAttributionText>
            This product uses the Census Bureau Data API but is not endorsed or certified by the Census Bureau.
          </APIAttributionText>
        </APIAttribution>
      </LocallyAdoptedCodes>
    </>
  );
};

export default observer(SitePropertyForm);
