import {
  action, computed, observable
} from 'mobx';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import { canvasConfig } from '../../../../../config/canvasConfig';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import {
  CEILING_HEIGHT_LIMIT,
  FOUNDATION_HEIGHT_LIMIT,
  SceneObjectType,
  WARNING
} from '../../../../../domain/models/Constants';
import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../../domain/typings';
import { Building } from '../../../../../domain/models/SiteDesign/Building';
import { notify } from '../../../../../utils/helpers';
import {
  convertFeetToMeters, convertMeterToFeet
} from '../../../../../utils/Convertions';
import type { IAddOrUpdateBuildingDependencies } from '../../../../ServiceBus/Commands/AddOrUpdateBuildingCommand';
import type { TraceToolBase } from '../../../ToolbarStore/Project/TraceToolBase';
import type { IBaseStepViewModelDependencies } from '../BaseStepViewModel';
import { BaseStepViewModel } from '../BaseStepViewModel';
import { isWithin } from '../../../../../domain/models/Limit';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../../domain/behaviour/KeyboardBehaviour';

interface INewOrEditBuildingStepViewModel extends IBaseStepViewModelDependencies {
  readonly traceTool?: TraceToolBase;
  readonly editBuilding?: Building;
}

export class NewOrEditBuildingViewModel extends BaseStepViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI = 'new_or_edit_building_modal';

  readonly defaultNameBuilding: string = 'Example HOUSE';

  private editBuilding?: Building;

  @observable
  loading: boolean = false;

  @observable
  name: string = this.domain.suggestedBuildingName;

  @observable
  ceilingHeight: number = 8;

  @observable
  foundationHeight: number = 2;

  @observable
  useAndOccupancyClassification: string = 'R3';

  @observable
  automaticSprinklerSystem: boolean = false;

  @observable
  validName: boolean = true;

  @observable
  useAndOccupancyClassificationOptions: Option[] = [];

  private readonly traceTool?: TraceToolBase;
  readonly hasBuildingHeightsData: boolean = true;

  constructor(dependencies: INewOrEditBuildingStepViewModel) {
    super(dependencies);
    this.traceTool = dependencies.traceTool;
    if (dependencies.editBuilding) {
      this.editBuilding = dependencies.editBuilding;
      this.name = this.editBuilding.name;
      this.automaticSprinklerSystem = this.editBuilding.automaticSprinklerSystem ?? false;
      this.useAndOccupancyClassification = this.editBuilding.useAndOccupancyClassification;
      const buildingHeightsData =
        dependencies.domain.project.supplementalData.buildingsHeightsData?.[this.editBuilding.id];
      this.hasBuildingHeightsData = typeof buildingHeightsData !== 'undefined';
      if (buildingHeightsData) {
        this.ceilingHeight = convertMeterToFeet(buildingHeightsData.ceilingHeight);
        this.foundationHeight = convertMeterToFeet(buildingHeightsData.foundationHeight);
      }
    }
    this.loadOptions();
  }

  @action.bound
  setName(newName: string): void {
    const existingBuilding = this.domain.buildingWithName(newName);
    if (existingBuilding && existingBuilding.id !== this.getCurrentBuilding().id) {
      this.validName = false;
      notify('A building with that name already exists. Choose a different name', WARNING);
    } else {
      this.validName = true;
      this.name = newName;
    }
  }

  @action.bound
  setCeilingHeight(value: number): void {
    this.ceilingHeight = value;
  }

  @action.bound
  setFoundationHeight(value: number): void {
    this.foundationHeight = value;
  }

  @action.bound
  setUseAndOccupancyClassification(value: string | number): void {
    this.useAndOccupancyClassification = `${value}`;
  }

  @action.bound
  setAutomaticSprinklerSystem(value: boolean): void {
    this.automaticSprinklerSystem = value;
  }

  @computed
  get isEditMode(): boolean {
    return !!this.editBuilding;
  }

  @computed
  get disabledSave(): boolean {
    const invalidName = this.name === '' || !this.validName;
    if (!this.hasBuildingHeightsData) {
      return invalidName;
    }
    const invalidCeilingHeight = !this.validateCeilingHeight();
    const invalidFoundationHeight = !this.validateFoundationHeight();
    return invalidName || invalidCeilingHeight || invalidFoundationHeight;
  }

  override nextStep(): void {
    this.setName(this.name);
    if (!this.hasBuildingHeightsData && !this.validName) {
      return;
    }
    if (!this.validName || !this.validateCeilingHeight() || !this.validateFoundationHeight()) {
      return;
    }
    const building = this.getCurrentBuilding();
    building.name = this.name;
    building.useAndOccupancyClassification = this.useAndOccupancyClassification;
    building.automaticSprinklerSystem = this.automaticSprinklerSystem;

    this.saveBuilding(building);

    // Change step
    if (this.wizard.numberOfSteps > 1) {
      this.wizard.changeStep(this.wizard.currentStep + 1);
    } else {
      this.wizard.clear();
      this.traceTool?.finishWipPolygon({
        name: SceneObjectType.Outline,
        color: canvasConfig.wipOutlineColor,
        building
      });
    }
    this.dispose();
  }

  override closeSteps(): void {
    this.traceTool?.removeWipPolygon();
    this.wizard.clear();
    this.dispose();
  }

  dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (_event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (!this.disabledSave && this.wizard.currentViewModel.propCodeUI === this.propCodeUI) {
      if (event.key === KeyboardListener.KEY_ENTER) {
        this.nextStep();
      }
    }
  };

  enableBehaviors = (): void => {
    KeyboardBehaviour.addKeyboardEvents(this);
  };

  loadOptions = async (): Promise<void> => {
    this.loading = true;
    await this.loadUseAndOccupancyClassificationOptions();
    this.loading = false;
  };

  private async loadUseAndOccupancyClassificationOptions(): Promise<void> {
    const state = this.domain.project.site.address.province;
    await this.designService
      .getUseAndOccupancyClassificationOptions(state)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.useAndOccupancyClassificationOptions = this.mapToItemOption(response);
      });
  }

  private getCurrentBuilding(): Building {
    return this.editBuilding ?? new Building();
  }

  private validateCeilingHeight(): boolean {
    return isWithin(CEILING_HEIGHT_LIMIT, this.ceilingHeight);
  }

  private validateFoundationHeight(): boolean {
    return isWithin(FOUNDATION_HEIGHT_LIMIT, this.foundationHeight);
  }

  private saveBuilding(building: Building): void {
    const commandDependencies: IAddOrUpdateBuildingDependencies = {
      domain: this.domain,
      building: building
    };
    if (this.hasBuildingHeightsData) {
      this.domain.project.supplementalData.addHeightDataForBuilding(
        building.id,
        convertFeetToMeters(this.ceilingHeight),
        convertFeetToMeters(this.foundationHeight)
      );
    }
    this.serviceBus.send('add_or_update_building', commandDependencies);
  }
}
