import React, { useContext, ReactElement } from 'react';
import { ThemeContext } from 'styled-components';
import type { Color } from '../../typings';
import { LoaderContainer, LoaderSpinner } from './Loader.styles';

export interface ILoaderProps {
  fontSize?: string;
  height?: string;
  bgColor?: Color | string;
  color?: string;
  size?: number;
  padding?: string;
  text?: string;
  visible?: boolean;
  width?: string;
}

export const Loader = (props: ILoaderProps): ReactElement => {
  const {
    fontSize = '16px',
    height = '100%',
    text,
    padding = '5px 0',
    bgColor,
    color = '#427ecd',
    size = 125,
    visible = true,
    width = '100%',
  } = props;
  const theme = useContext(ThemeContext);

  if (!visible) {
    return <></>;
  }

  return (
    <LoaderContainer
      padding={padding}
      fontSize={fontSize}
      width={width}
      height={height}
    >
      <LoaderSpinner
        color={color}
        bgColor={bgColor ?? theme?.colors.secondary}
        size={size}
      />
      {text && <span>{text}</span>}
    </LoaderContainer>
  );
};

export default Loader;
