import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { LyraDivider } from '@aurorasolar/lyra-ui-kit';
import Flexbox, { type FlexboxDirection } from '../../../../../ui/components/Layout/Flexbox';
import {
  Icon, InfoDescription, InfoTextHeader
} from './styles';

type Props = BaseProps & {
  name: string;
  icon?: string;
  value?: string;
  divider?: boolean;
  orientation?: string;
};

const Info = observer(({
  icon = '', value = '', name = '', divider, orientation
}: Props): ReactElement => {
  return (
    <>
      {divider && (
        <LyraDivider.Divider
          direction="vertical"
          color={EThemeComponentColor.GRAY_200}
          className="ml-xs mr-xxs"
          thickness="1px"
          length="34px"
        />
      )}
      <Flexbox dir={(orientation ?? 'row') as FlexboxDirection} align="center" justify="center" fullHeight={true}>
        <Icon name={icon} className={!value ? '' : 'mr-xxs'} />
        <Flexbox dir="column">
          {value && <InfoTextHeader>{value}</InfoTextHeader>}
          <InfoDescription className={!value ? 'pl-xxs' : ''}>{name}</InfoDescription>
        </Flexbox>
      </Flexbox>
    </>
  );
});

export default Info;
