import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import useStore from '../../../../../stores/useStore';
import type { RoofDeckingViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/RoofDeckingViewModel';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';
import { DeckingWrapper } from './styles';

const RoofDecking = (): ReactElement => {
  const { roofFaceProps } = useStore();
  const [viewModel] = useState<RoofDeckingViewModel>(roofFaceProps.currentRoofPropertyEditor as RoofDeckingViewModel);

  useEffect((): void => {
    viewModel.loadDeckingTypes();
  }, [viewModel]);

  return (
    <>
      <RoofPropertiesParent viewModel={viewModel} multiEdit={true} visibility={false}>
        <DeckingWrapper>
          <LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select
            label={'DECK TYPE'}
            listOptions={viewModel.listDeckingTypes}
            onSelect={viewModel.setCurrentType}
            selected={viewModel.selectedType}
            isCollapsedByDefault={false}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </DeckingWrapper>
      </RoofPropertiesParent>
    </>
  );
};

export default observer(RoofDecking);
