import React, { BaseProps, ReactElement } from 'react';
import { CTAButtonContainer, IconView, TextView, ButtonText, FullTextView } from './styles';
import { Icon } from '../Icon';

export type CTAButtonProps = BaseProps & {
  iconName?: string;
  label: string;
  trigger?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fullWidth?: boolean;
};

const CTAButton = ({
  iconName,
  label,
  trigger,
  fullWidth = false
}: CTAButtonProps): ReactElement => {
  return (
    <CTAButtonContainer
      onClick={trigger}
      fullWidth={fullWidth}
    >
      {iconName ?
        <>
          <IconView>
            <Icon name={iconName} />
          </IconView>
          <TextView>
            <ButtonText>
              {label}
            </ButtonText>
          </TextView>
        </>
        :
        <FullTextView>
          <ButtonText>
            {label}
          </ButtonText>
        </FullTextView>
      }
    </CTAButtonContainer>
  );
};

export { CTAButton };
