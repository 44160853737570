import type { DesignState } from '../../../domain/models/Design/DesignState';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateDesignState extends IBaseCommandDependencies {
  domain: DomainStore;
  state: DesignState;
}

export class UpdateDesignState extends BaseCommand {
  domain: DomainStore;
  state: DesignState;

  constructor(dependencies: IUpdateDesignState) {
    super();
    this.domain = dependencies.domain;
    this.state = dependencies.state;
  }

  async execute(): Promise<void> {
    this.domain.design.state = this.state;
  }
}
