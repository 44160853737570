import React, { BaseProps, ReactElement } from 'react';
import {
  DownArrow,
  LinearPercent,
  LinearProgressBarContainer,
  LinearProgressWrapper,
  LinearRangeBar,
  LinearRangeContainer,
  RangeEndText,
  RangeText,
  RangeTextWrapper
} from './styles';

type SizeProps = { min: string; med: string; max: string };

type LinearProgressProps = BaseProps & {
  units: string;
  minValue: number;
  maxValue: number;
  currentValue: number;
  strokeColor?: string;
  size: string;
};

type ArrowSizeType = {
  min: ArrowSizeProps;
  med: ArrowSizeProps;
  max: ArrowSizeProps;
};

type ArrowSizeProps = {
  left: string;
  right: string;
  top: string;
  positionTop: string;
  positionRight: string;
};

const arrowSize: ArrowSizeType = {
  min: {
    left: '1px',
    right: '1px',
    top: '3px',
    positionTop: '-.25rem',
    positionRight: '0'
  },
  med: {
    left: '2px',
    right: '2px',
    top: '6px',
    positionTop: '-.5rem',
    positionRight: '0'
  },
  max: {
    left: '4px',
    right: '4px',
    top: '12px',
    positionTop: '-1.0rem',
    positionRight: '0'
  }
};

const fontSize: SizeProps = {
  min: '8px',
  med: '10px',
  max: '12px'
};

const positionLinearTitle: SizeProps = {
  min: '-1.0rem',
  med: '-1.3rem',
  max: '-2.0rem'
};

const Range = ({
  units,
  className,
  minValue,
  maxValue,
  currentValue,
  strokeColor,
  size
}: LinearProgressProps): ReactElement => {
  const range = maxValue - minValue;
  let possibleValue = currentValue;
  if (currentValue > maxValue) {
    possibleValue = maxValue;
  }
  if (currentValue < minValue) {
    possibleValue = minValue;
  }
  const currentPercentage = (possibleValue / range) * 100;
  return (
    <LinearRangeContainer progressBar={currentPercentage} className={className}>
      <LinearPercent
        fontSize={`${fontSize[size as keyof typeof fontSize]}`}
        size={`${positionLinearTitle[size as keyof typeof positionLinearTitle]}`}
      >
        {possibleValue ? parseFloat(possibleValue.toFixed(1)) : 0}{units}
      </LinearPercent>
      <DownArrow arrowSize={arrowSize[size as keyof typeof arrowSize]}/>
      <LinearRangeBar color={strokeColor} />
    </LinearRangeContainer>
  );
};

const LinearProgressBar =
  ({
    className,
    units,
    minValue,
    maxValue,
    currentValue,
    strokeColor,
    size
  }: LinearProgressProps)
: ReactElement => {

  return (
    <section className={className}>
      <LinearProgressWrapper>
        <LinearProgressBarContainer>
          <Range
            units={units}
            minValue={minValue}
            maxValue={maxValue}
            currentValue={currentValue}
            strokeColor={strokeColor}
            size={size}
          />
        </LinearProgressBarContainer>
        <RangeTextWrapper>
          <RangeText fontSize={`${fontSize[size as keyof typeof fontSize]}`}>
            {minValue}{units}
          </RangeText>
          <RangeEndText fontSize={`${fontSize[size as keyof typeof fontSize]}`}>
            {maxValue}{units}
          </RangeEndText>
        </RangeTextWrapper>
      </LinearProgressWrapper>
    </section>
  );
};

export { LinearProgressBar };
