import {
  type BufferAttribute, type BufferGeometry, type MeshBasicMaterial, type Object3D, Vector2
} from 'three';
import {
  Line, Mesh, OrthographicCamera, PerspectiveCamera, Raycaster, Vector3
} from 'three';

export class SimpleVector2 {
  x: number;
  y: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export function subSimpleVector2s(A: SimpleVector2, B: SimpleVector2): SimpleVector2 {
  return new SimpleVector2(A.x - B.x, A.y - B.y);
}

export interface ISimpleVector3 {
  x: number;
  y: number;
  z: number;
}

interface IElement {
  offsetWidth: number;
  offsetHeight: number;
}

export class ThreeUtils {
  private static readonly _raycaster = new Raycaster();
  private static readonly _plane = {
    q: new Vector3(0, 0, 0), // a point on the surface of the plane
    n: new Vector3(0, 0, 1) // the normal vector of the plane
  };

  public static destroyObject(container: Object3D): void {
    ThreeUtils.disposeAndClearObject(container);
    container.parent?.remove(container);
  }

  public static applyOffsetToBufferGeometry(bufferGeometry: BufferGeometry, offset: ISimpleVector3): void {
    const positionAttribute = bufferGeometry.attributes.position as BufferAttribute;

    for (let i = 0; i < positionAttribute.count; ++i) {
      const newX = positionAttribute.getX(i) + offset.x;
      const newY = positionAttribute.getY(i) + offset.y;
      const newZ = positionAttribute.getZ(i) + offset.z;

      positionAttribute.setXYZ(i, newX, newY, newZ);
    }

    positionAttribute.needsUpdate = true;
  }

  public static getLength(vec: SimpleVector2) {
    return Math.sqrt(vec.x * vec.x + vec.y * vec.y);
  }

  public static updateMatrices(element: Object3D) {
    element.updateMatrix();
    element.updateMatrixWorld();
  }

  /**
   * return values are between -1 and 1
   */
  private static domCoordinatesToNDC(x: number, y: number, domElement: HTMLElement) {
    return {
      x: (x / domElement.offsetWidth - 0.5) * 2,
      y:
        -(y / domElement.offsetHeight - 0.5)
        * 2 /** y values are flipped when we compare html coordinates with 3d coordinates */
    };
  }

  public static domCoordinatesToWorldCoordinates(
    x: number,
    y: number,
    domElement: HTMLElement,
    camera: OrthographicCamera | PerspectiveCamera
  ) {
    const NDC = ThreeUtils.domCoordinatesToNDC(x, y, domElement);

    return ThreeUtils.NDCtoWorldCoordinates(NDC.x, NDC.y, camera);
  }

  private static NDCtoWorldCoordinates(x: number, y: number, spaceSize: SimpleVector2): SimpleVector2;
  private static NDCtoWorldCoordinates(x: number, y: number, camera: OrthographicCamera | PerspectiveCamera): SimpleVector2;
  private static NDCtoWorldCoordinates(
    x: number,
    y: number,
    cameraOrSpaceSize: (OrthographicCamera | PerspectiveCamera) | SimpleVector2
  ) {
    if (cameraOrSpaceSize instanceof OrthographicCamera || cameraOrSpaceSize instanceof PerspectiveCamera) {
      const camera = cameraOrSpaceSize;
      /**
       * See the intersectPlane function here: http://vargapeter.info/thesis.pdf
       * */
      ThreeUtils._raycaster.setFromCamera(
        new Vector2(x, y),
        camera
      );
      const ray = ThreeUtils._raycaster.ray;

      const plane = ThreeUtils._plane;
      const t = plane.n.dot(plane.q.clone().sub(ray.origin)) / plane.n.dot(ray.direction);

      if (t < 0) {
        return null;
      }

      const hitPoint = ray.origin.add(ray.direction.multiplyScalar(t));

      return {
        x: hitPoint.x,
        y: hitPoint.y
      };
    } else {
      const spaceSize = cameraOrSpaceSize;

      return {
        x: x * spaceSize.x,
        y: y * spaceSize.y
      };
    }
  }

  public static worldCoordinatesToDomCoordinates(
    worldX: number,
    worldY: number,
    domElement: IElement,
    camera: OrthographicCamera | PerspectiveCamera
  ): SimpleVector2;
  public static worldCoordinatesToDomCoordinates(
    worldX: number,
    worldY: number,
    domElement: IElement,
    spaceSize: SimpleVector2
  ): SimpleVector2;
  public static worldCoordinatesToDomCoordinates(
    worldX: number,
    worldY: number,
    domElement: IElement,
    cameraOrSpaceSize: OrthographicCamera | PerspectiveCamera | SimpleVector2
  ) {
    const ndc = ThreeUtils.worldCoordinatesToNDC(
      worldX,
      worldY,
      cameraOrSpaceSize as OrthographicCamera | PerspectiveCamera
    );

    return {
      x: ndc.x * domElement.offsetWidth,
      y: domElement.offsetHeight - ndc.y * domElement.offsetHeight
    };
  }

  private static worldCoordinatesToNDC(
    worldX: number,
    worldY: number,
    camera: OrthographicCamera | PerspectiveCamera
  ): SimpleVector2;
  private static worldCoordinatesToNDC(worldX: number, worldY: number, spaceSize: SimpleVector2): SimpleVector2;
  private static worldCoordinatesToNDC(
    worldX: number,
    worldY: number,
    cameraOrSpaceSize: OrthographicCamera | PerspectiveCamera | SimpleVector2
  ) {
    if (cameraOrSpaceSize instanceof OrthographicCamera || cameraOrSpaceSize instanceof PerspectiveCamera) {
      const camera = cameraOrSpaceSize;
      camera.updateMatrixWorld();

      const position = new Vector3(worldX, worldY, 0);
      position.project(camera);

      return {
        x: (position.x + 1) / 2,
        y: (position.y + 1) / 2
      };
    } else {
      // we simply assume a top-down view and we interpolate the coords between space
      const spaceSize = cameraOrSpaceSize;

      return {
        x: worldX / spaceSize.x,
        y: worldY / spaceSize.y
      };
    }
  }

  private static clearObject(container: Object3D): void {
    for (let i = container.children.length - 1; i >= 0; --i) {
      if (container.children[i].children.length > 0) {
        ThreeUtils.clearObject(container.children[i]);
      }
      container.remove(container.children[i]);
    }
  }

  private static disposeObject(container: Object3D): void {
    container.traverse((node: Object3D) => {
      if (node instanceof Mesh || node instanceof Line) {
        const material = node.material as MeshBasicMaterial;
        material.map?.dispose();
        material.dispose();
        node.geometry?.dispose();
      }
    });
  }

  private static disposeAndClearObject(container: Object3D): void {
    ThreeUtils.disposeObject(container);
    ThreeUtils.clearObject(container);
  }
}
