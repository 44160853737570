import { BaseSuperTool } from '../../SuperTool';
import { SITE_EQUIPMENT_TOOL_ID } from '../constants';

export class SiteEquipmentTool extends BaseSuperTool {
  override readonly id: string = SITE_EQUIPMENT_TOOL_ID;
  override readonly icon: string = 'site-equipment';
  override readonly title: string = 'Site Equipment';
  override readonly description: string = this.title;
  override testId: string = 'SiteEquipmentToolContainer';

  whenSelected(): void {
    /** */
  }

  whenDeselected(): void {
    /** */
  }

  dispose(): void {
    /** */
  }
}
