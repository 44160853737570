import {
  LyraButtonGroup, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { Segment } from '../../../domain/graphics/Segment';
import type {
  ArrayPlacementStage,
  RestrictedAreaCategory,
  SegmentOrSetbackOrPathway
} from '../../../domain/stages/DesignStages/ArrayPlacementStage';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { ERoofFaceEdgeType } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import { typesToSupportPathway } from '../../../stores/UiStore/ToolbarStore/Design/SetBackTool';
import { getEdgeTypeByRestrictedArea } from '../../../utils/spatial';
import { StringUtils } from '../../../utils/StringUtils';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyContentWrapper, PropertyPanelContainer, PropertyPanelWrapper
} from '../PropertyPanel/styles';
import { RestrictedAreaType } from '../../../domain/models/RoofTopArray/FireVentilation';
import { SceneObjectType } from '../../../domain/models/Constants';
import { getParentLyraModelByMeshOrLyraModel } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import Pathway from '../../../domain/models/SiteDesign/Pathway';
import VentilationSetback from '../../../domain/models/SiteDesign/VentilationSetback';

const setbackAndPathway: RestrictedAreaCategory[] = [SceneObjectType.Setback, SceneObjectType.Pathway];

const isPathway = (selectedItem?: SegmentOrSetbackOrPathway): boolean => {
  return selectedItem?.type === SceneObjectType.Pathway;
};

const isSetback = (selectedItem?: SegmentOrSetbackOrPathway): boolean => {
  return selectedItem?.type === SceneObjectType.Setback;
};

const isRidge = (selectedItem?: SegmentOrSetbackOrPathway): boolean => {
  return selectedItem?.type === 'RIDGE';
};

const getRestrictedAreaTypeOptions = (
  selectedItem?: SegmentOrSetbackOrPathway,
  fireCode?: string
): LyraButtonGroup.ButtonOption[] => {
  const defaultValues = [
    {
      value: RestrictedAreaType.STANDARD_18_INCHES,
      name: '1.5 ft'
    },
    {
      value: RestrictedAreaType.STANDARD_36_INCHES,
      name: '3 ft'
    }
  ];

  if (fireCode === 'OFC_2019' && (isSetback(selectedItem) || isRidge(selectedItem))) {
    defaultValues[0] = {
      value: RestrictedAreaType.STANDARD_12_INCHES,
      name: '1 ft'
    };
  } else if (fireCode === 'COBFC_2018') {
    defaultValues[0] = {
      value: RestrictedAreaType.STANDARD_12_INCHES,
      name: '1 ft'
    };
    defaultValues[1] = {
      value: RestrictedAreaType.STANDARD_30_INCHES,
      name: '2.5 ft'
    };
  }

  return defaultValues;
};

const SetbackPathwayProps = observer((): ReactElement => {
  const {
    domain, guardStore, workspace
  } = useStore();
  const stage = workspace.currentWorkspace.stageManager?.currentStage as ArrayPlacementStage;
  const [selectedRestrictedAreaType, setSelectedRestrictedAreaType] = useState<RestrictedAreaType>();
  const currentFireCode = domain.project.designParameters.codesAndStandards.fireCode;
  const disabled = guardStore.workspace.frozen;

  useEffect((): void => {
    if (!stage || !stage.selectedItem) {
      return;
    }
    const roofFace = getParentLyraModelByMeshOrLyraModel<RoofFace>(stage.selectedItem);
    if (roofFace) {
      const roofFaceRestrictedAreas = stage.domainModel.roofTopArrayAreas.restrictedAreasOn(roofFace.serverId);
      const selectedRestrictedAreaType = roofFaceRestrictedAreas?.findRestrictedArea(stage.selectedItem.serverId)?.type;
      setSelectedRestrictedAreaType(selectedRestrictedAreaType);
    }
  }, [stage, stage?.selectedItem, stage?.domainModel.roofTopArrayAreas]);

  const handleRemoveItem = useCallback((): void => {
    if (disabled) {
      return;
    }
    stage?.removeSelectedItem();
  }, [stage, disabled]);

  const changeRestrictedAreaType = useCallback(
    (newRestrictedAreaType: string | number): void => {
      stage?.changeRestrictedAreaWidthForSelectedEdge(newRestrictedAreaType as RestrictedAreaType);
    },
    [stage]
  );

  if (!stage) {
    return <></>;
  }

  const getParagraph = (): string => {
    const selectedItem = stage.selectedItem;
    if (!selectedItem) {
      return '';
    }
    return selectedItem instanceof Segment
      ? `Create new ${getRestrictedAreaLabelBySelectedItem(selectedItem)}`
      : `${StringUtils.capitalize(selectedItem.type)} width`;
  };

  const getRestrictedAreaLabelBySelectedItem = (selectedItem: SegmentOrSetbackOrPathway): RestrictedAreaCategory => {
    const selectedItemType: ERoofFaceEdgeType | string = selectedItem?.type || '';

    if (setbackAndPathway.includes(selectedItemType as RestrictedAreaCategory)) {
      return selectedItemType as RestrictedAreaCategory;
    } else {
      return typesToSupportPathway.includes(selectedItemType as ERoofFaceEdgeType)
        ? SceneObjectType.Pathway
        : SceneObjectType.Setback;
    }
  };

  const getEdgeTypeBySelectedItem = (selectedItem: SegmentOrSetbackOrPathway): ERoofFaceEdgeType | '' => {
    if (!selectedItem) {
      return '';
    }
    return isSetback(selectedItem) || isPathway(selectedItem)
      ? getEdgeTypeByRestrictedArea(selectedItem as Pathway | VentilationSetback)
      : (selectedItem.type as ERoofFaceEdgeType);
  };

  const propertyHeaderLabel = `Edit ${StringUtils.capitalize(
    getRestrictedAreaLabelBySelectedItem(stage.selectedItem!)
  )}`;

  const propertyHeaderDescription = `${stage.selectedItem?.parentRoofFace?.name || ''} / ${getEdgeTypeBySelectedItem(
    stage.selectedItem!
  )}`;

  const showRemoveLink = stage.selectedItem instanceof Pathway || stage.selectedItem instanceof VentilationSetback;

  return (
    <PropertyPanelWrapper disabled={disabled}>
      <PropertyPanelContainer>
        <PropertyPanelHeader
          icon="tool-properties"
          name={propertyHeaderLabel}
          additionalData={propertyHeaderDescription}
          iconBg={EThemeComponentColor.BLUE}
        />
      </PropertyPanelContainer>
      <PropertyContentWrapper>
        <LyraTypography.Paragraph>{getParagraph()}</LyraTypography.Paragraph>
        <LyraButtonGroup.ButtonGroup
          options={getRestrictedAreaTypeOptions(stage.selectedItem, currentFireCode)}
          height="35px"
          selected={selectedRestrictedAreaType}
          onSelect={changeRestrictedAreaType}
          disabled={disabled}
          selectedOptionColor={EThemeComponentColor.BLUE}
        />
        {showRemoveLink && (
          <LyraTypography.TextLayout
            color={EThemeComponentColor.BLUE}
            onClick={handleRemoveItem}
            textDecoration="underline"
          >
            <span className="clickable-text">{`Remove ${stage.selectedItem.type}`}</span>
          </LyraTypography.TextLayout>
        )}
      </PropertyContentWrapper>
    </PropertyPanelWrapper>
  );
});

export default SetbackPathwayProps;
