import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateOtherSitePropertiesDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
  updatedTopographicalCondition?: string;
  updatedPropertyGrade?: number;
  updatedSds?: number;
  updatedElevationInFeet?: number;
}

export class UpdateOtherSitePropertiesCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly updatedTopographicalCondition?: string;
  private readonly updatedPropertyGrade?: number;
  private readonly updatedSds?: number;
  private readonly updatedElevationInFeet?: number;

  constructor(dependencies: IUpdateOtherSitePropertiesDependencies) {
    super();
    this.domain = dependencies.domain;
    this.updatedTopographicalCondition = dependencies.updatedTopographicalCondition;
    this.updatedPropertyGrade = dependencies.updatedPropertyGrade;
    this.updatedSds = dependencies.updatedSds;
    this.updatedElevationInFeet = dependencies.updatedElevationInFeet;
  }

  async execute(): Promise<void> {
    this.domain.project.designParameters.terrain.topographicalCondition = this.updatedTopographicalCondition;
    this.domain.project.designParameters.terrain.propertyGrade = this.updatedPropertyGrade;
    this.domain.project.designParameters.seismic.sds = this.updatedSds;
    this.domain.project.site.setElevationInFeet(this.updatedElevationInFeet);
  }
}
