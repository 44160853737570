import {
  LyraFormElementsDesignTool, LyraLayout, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { OptionWithAttribute } from '../../../../domain/typings/OptionWithAttribute';
import type {
  TDropDownOption,
  SiteTemperatureSelectionPanelViewModel
} from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/SiteTemperatureViewModel';
import { LayoutWrapper } from './styles';

const DropDown = LyraFormElementsDesignTool.FormElementsDesignTool.DropDownNew.DropDownNew;
const Radio = LyraFormElementsDesignTool.FormElementsDesignTool.Radio.Radio;
const Input = LyraFormElementsDesignTool.FormElementsDesignTool.Input.Input;
const {
  Paragraph, Heading
} = LyraTypography;
const {
  Row, Col
} = LyraLayout;

type SiteTemperatureSelectionPanelProps = {
  title: string;
  model: SiteTemperatureSelectionPanelViewModel;
};

const SiteTemperatureSelectionPanel = (props: SiteTemperatureSelectionPanelProps): ReactElement => {
  const {
    selectedDataSource,
    selectedDataSourceTemperatureOptions,
    weatherStationsDropDownOptions,
    isSelectedTemperatureOption,
    temperatureValueInFahrenheit,
    onDataSourceChange,
    onTemperatureOptionChange,
    onTemperatureValueChange
  } = props.model;

  const portalElement = document.getElementById('lyra-dropdown-portal');

  const handleDataSourceChange = useCallback(
    (newValue: unknown): void => {
      onDataSourceChange(newValue as TDropDownOption);
    },
    [onDataSourceChange]
  );

  const handleTemperatureOptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onTemperatureOptionChange(event.target.value);
    },
    [onTemperatureOptionChange]
  );

  const handleTemperatureValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onTemperatureValueChange(event.target.value);
    },
    [onTemperatureValueChange]
  );

  return (
    <Flexbox dir="column" align="stretch">
      <Heading type="h4">
        <p>{props.title}</p>
      </Heading>

      <Heading type="h5">
        <b>Weather Station</b>
      </Heading>

      <DropDown
        options={weatherStationsDropDownOptions}
        value={selectedDataSource}
        menuPortalTarget={portalElement}
        onChange={handleDataSourceChange}
      />

      <LayoutWrapper>
        {selectedDataSourceTemperatureOptions.map(
          (option: OptionWithAttribute, index: number): ReactElement => (
            <Row key={index}>
              <Col xs={9}>
                <Paragraph type="h5">
                  <Radio
                    key={index}
                    value={option.value}
                    text={option.label.toString()}
                    name={`${props.title}-radio`}
                    colorTheme={EThemeComponentColor.PURPLE}
                    checked={isSelectedTemperatureOption(option)}
                    onChange={handleTemperatureOptionChange}
                  />
                </Paragraph>
              </Col>

              {isSelectedTemperatureOption(option) && (
                <Col xs={2}>
                  <Input
                    className="pt-xxxs"
                    name=""
                    rightText="°F"
                    value={temperatureValueInFahrenheit}
                    onChange={handleTemperatureValueChange}
                  />
                </Col>
              )}
            </Row>
          )
        )}
      </LayoutWrapper>
    </Flexbox>
  );
};

export default observer(SiteTemperatureSelectionPanel);
