import {
  type Intersection, type Object3D, type OrthographicCamera, type PerspectiveCamera, Vector2
} from 'three';
import type EditorStore from '../EditorStore';
import type { Drawable } from '../../../domain/mixins/Drawable';
import type { Selectable } from '../../../domain/mixins/Selectable';
import type { ViewPort } from '../ViewportController';
import { getLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import { BaseControl } from './BaseControl';

export class BaseCastObjectControl<T = {}> extends BaseControl<T> {
  recursiveRaycast: boolean = true;
  protected objects?: Selectable[];
  constructor(
    editor: EditorStore,
    viewport: ViewPort,
    camera: OrthographicCamera | PerspectiveCamera,
    objects?: Selectable[]
  ) {
    super(editor, viewport, camera);

    this.objects = objects;
  }

  setTargetObjects(objects: Selectable[] | undefined, recursiveRaycast: boolean = true): void {
    this.objects = objects;
    this.recursiveRaycast = recursiveRaycast;
  }

  getCastedObjects(): Intersection[] {
    this.raycaster.setFromCamera(new Vector2(this.mouse.x, this.mouse.y), this.camera);
    return this.raycaster.intersectObjects(
      this.getTargetObjects().map((obj: Selectable): Object3D => obj.mesh ?? obj),
      this.recursiveRaycast
    );
  }

  getTargetObjects(): Selectable[] {
    return this.objects || this.getSelectableFromScene();
  }

  filterAndUpdateTargetObjects(filterFn: (object: Selectable) => boolean): void {
    const targetObjects = this.getTargetObjects().filter(filterFn);
    this.setTargetObjects(targetObjects);
  }

  /**
   * [Important]: Old and New selectable
   * @param target
   * @protected
   */
  protected isSelectable(target: unknown): boolean {
    return getLyraModelByMesh<Selectable>(target as Object3D).isSelectable;
  }

  private getSelectableFromScene(): Selectable[] {
    return [
      ...this.editor
        .getObjectsByCondition(
          (obj: Object3D): boolean => this.isSelectable(obj) && !(obj.userData?.lyraModel as Drawable).isNewDrawable
        )
        .map((obj: Object3D): Selectable => obj.userData.lyraModel),
      ...this.editor
        .getObjectsByCondition((obj: Object3D): boolean => (obj.userData?.lyraModel as Drawable)?.isNewDrawable)
        .map((obj: Object3D): Selectable => obj.userData.lyraModel)
    ];
  }
}
