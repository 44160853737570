import type { IDesignParametersData } from '../../../domain/models/SiteDesign/DesignParameters';
import type { ITemperatureDesignParametersData } from '../../../domain/models/SiteDesign/TemperatureDesignParameters';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateTemperatureValues extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly temperatures: ITemperatureDesignParametersData;
}

export class UpdateTemperatureValuesCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly temperatures: ITemperatureDesignParametersData;

  constructor(dependencies: IUpdateTemperatureValues) {
    super();
    this.domain = dependencies.domain;
    this.temperatures = dependencies.temperatures;
  }

  async execute(): Promise<void> {
    const designParametersData: IDesignParametersData = {
      ...this.domain.project.designParameters.toData(),
      temperature: this.temperatures
    };
    this.domain.project.updateDesignParameters(designParametersData);
  }
}
