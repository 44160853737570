import React, { BaseProps, ReactElement, useState } from 'react';
import {
  TextView,
  VerticalButtonContainer,
  IconWrapper,
  VerticalButtonText,
  PanelView,
  DownIconView,
  ChildrenView
} from './styles';
import { Icon } from '../Icon';

export type CTAButtonProps = BaseProps & {
  iconName: string;
  label: string;
  trigger?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  enabled?: boolean;
};

const VerticalButton = ({
  iconName,
  label,
  trigger,
  enabled = false,
  children
}: CTAButtonProps): ReactElement => {
  const [showChild, setShowChild] = useState(false);

  return (
    <VerticalButtonContainer
      data-testid="vertical-button-toggle"
      onClick={trigger}
    >
      <IconWrapper enabled={!!enabled}>
        <PanelView>
          <Icon name={enabled ? `${iconName}-enabled` : `${iconName}-disabled`} />
        </PanelView>
        <DownIconView
          enabled={enabled}
          data-testid="vertical-button-down-button"
          onClick={(): void => {
            setShowChild(!showChild);
          }}
        >
          <Icon name={enabled ? 'dropdown-arrow-enabled' : 'dropdown-arrow-disabled'} />
        </DownIconView>
      </IconWrapper>
      <TextView>
        <VerticalButtonText>
          {label}
        </VerticalButtonText>
      </TextView>
      {showChild &&
        <ChildrenView>
          {children}
        </ChildrenView>
      }
    </VerticalButtonContainer>
  );
};

export { VerticalButton };
