import {
  action, computed, observable
} from 'mobx';
import type { MountingSystemAttributes } from '../../typings';
import { milimetersToImperialInches } from '../../../utils/math';
import type Limit from '../Limit';
import {
  type ILayoutStrategyData, LayoutStrategy
} from '../RoofTopArray/LayoutStrategy';

export interface IMountingBosPreferencesData {
  readonly railDefinitionId: string;
  readonly anchorDefinitionId?: string;
  readonly clampDefinitionIds?: string[];
}

export interface ISteepSlopeMountingSystemDefinitionData {
  externalDefinitionId: string;
  defaultLayoutStrategy: ILayoutStrategyData;
  readonly defaultMountingBosPreferences?: IMountingBosPreferencesData;
}

/**
 * Mounting system definition common to all mounting systems.
 * Used as-is for steep-slope mounting systems, and extended with additional configuration for low-slope systems.
 */
export class MountingSystemDefinition {
  static readonly columnSpacingToLabel = (columnSpacing?: number): string => {
    if (!columnSpacing) {
      return '0" Spacing between modules';
    }
    const imperialInches = milimetersToImperialInches(columnSpacing * 1000);
    return imperialInches ? `${imperialInches[0]}/${imperialInches[1]}" Spacing between modules` : '';
  };

  @observable
  externalDefinitionId: string;
  @observable
  defaultLayoutStrategy: LayoutStrategy = LayoutStrategy.initialForSteepSlope();
  defaultMountingBosPreferences?: IMountingBosPreferencesData;

  /**
   * The attributes are persisted under supplemental data
   */
  @observable
  protected attributes?: MountingSystemAttributes;

  @computed
  get maximumSlope(): number {
    return Number(this.attributes?.maximumSlope) ?? 0;
  }

  @computed
  get minimumSlope(): number {
    return Number(this.attributes?.minimumSlope) ?? 0;
  }

  @computed
  get acceptableSlopeLimits(): Limit {
    return {
      lower: this.minimumSlope,
      upper: this.maximumSlope
    };
  }

  constructor(data: ISteepSlopeMountingSystemDefinitionData) {
    this.externalDefinitionId = data.externalDefinitionId;
    this.defaultLayoutStrategy = new LayoutStrategy(data.defaultLayoutStrategy);
    this.defaultMountingBosPreferences = data.defaultMountingBosPreferences;
  }

  toData(): ISteepSlopeMountingSystemDefinitionData {
    return {
      externalDefinitionId: this.externalDefinitionId,
      defaultLayoutStrategy: this.defaultLayoutStrategy.toData(),
      defaultMountingBosPreferences: this.defaultMountingBosPreferences
    };
  }

  copy = (): MountingSystemDefinition => {
    const copy = new MountingSystemDefinition(this.toData());
    copy.attributes = this.attributes;
    return copy;
  };

  @action
  setAttributes = (attributes: MountingSystemAttributes | undefined): void => {
    this.attributes = attributes;
  };

  @computed
  get arrayAzimuth(): number | undefined {
    return undefined;
  }

  @computed
  get name(): string {
    return this.attributes ? `${this.attributes.manufacturer} ${this.attributes.name}` : '';
  }

  @computed
  get rowSpacing(): number {
    return Number(this.attributes?.rowSpacing ?? 0);
  }

  @computed
  get columnSpacing(): number {
    return Number(this.attributes?.columnSpacing ?? 0);
  }

  @computed
  get columnSpacingLabel(): string {
    return MountingSystemDefinition.columnSpacingToLabel(this.columnSpacing);
  }
}
