import type { Color } from '@aurorasolar/lyra-ui-kit';
import { observable } from 'mobx';
import { Vector3 } from 'three';
import type { IElectricalEquipmentData } from '../../../../../domain/entities/SitePlan/SitePlan';
import { SiteStructureFactory } from '../../../../../domain/models/StructureFactory';
import { ElectricalEquipmentMarker } from '../../../../../domain/models/Design/ElectricalEquipmentMarker';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { ModalStore } from '../../../Modal/Modal';
import type { PropertiesStore } from '../../../Properties/Properties';
import type SmartGuidesStore from '../../../SmartGuidesStore/SmartGuidesStore';
import type { ToolbarStore } from '../../../ToolbarStore/Toolbar';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import type { IBasePanelViewModelDependencies } from '../BasePanelViewModel';
import { BasePanelViewModel } from '../BasePanelViewModel';
import type { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import { SceneObjectType } from '../../../../../domain/models/Constants';

export interface IEquipmentPanelViewModelDependencies extends IBasePanelViewModelDependencies {
  editor: EditorStore;
  equipment: IElectricalEquipmentData[];
  toolbar: ToolbarStore;
  modal: ModalStore;
}

export class EquipmentPanelViewModel extends BasePanelViewModel {
  propCodeUI = 'equipment_panel';

  @observable
  equipmentPlaced: IElectricalEquipmentData[] = [];

  @observable
  equipmentNotPlaced: IElectricalEquipmentData[] = [];

  @observable
  selectedEquipment: IElectricalEquipmentData | undefined;

  workspace?: WorkspaceStore;
  smartGuides?: SmartGuidesStore;
  properties?: PropertiesStore;

  private drawableObjectsFactory: SiteStructureFactory;
  private color: Color = '#52b8b8';
  private editor: EditorStore;

  constructor(dependencies: IEquipmentPanelViewModelDependencies) {
    super(dependencies);
    const {
      editor, equipment
    } = dependencies;
    this.editor = editor;
    this.drawableObjectsFactory = new SiteStructureFactory();

    equipment.forEach((item: IElectricalEquipmentData, index: number): void => {
      if (item.location) {
        this.drawEquipmentMarker(item, index);
        this.equipmentPlaced = [...this.equipmentPlaced, item];
      } else {
        this.equipmentNotPlaced = [...this.equipmentNotPlaced, item];
      }
    });
  }

  createEquipmentMarker(equipment: IElectricalEquipmentData): Marker {
    const marker = this.drawableObjectsFactory.create<ElectricalEquipmentMarker>(ElectricalEquipmentMarker, {
      color: this.color
    });
    marker.electricalEquipment = equipment;
    equipment.marker = marker;
    marker.label = equipment.label;
    marker.exposure = equipment.exposure;
    // If we have location object from API, we set vertical value as
    // z value from the location object, otherwise we set default z value
    // which is 5 feet converted to world units.
    marker.realWorldZValue = equipment.location
      ? equipment.location.z
      : ElectricalEquipmentMarker.defaultEquipmentZValueInWorldUnits();
    return marker;
  }

  drawEquipmentMarker(electricalEquipment: IElectricalEquipmentData, index: number): void {
    const equipmentMarker = this.createEquipmentMarker(electricalEquipment);
    const { location } = electricalEquipment;

    if (!location || !equipmentMarker || equipmentMarker.hasChildren) {
      return;
    }

    // If equipmentMarker has locations we draw marker immediately.
    // Otherwise user should select position of the equipmentMarker manually.
    const coords = new Vector3(
      location.x,
      location.y,
      this.editor.getObjectRenderHeight(SceneObjectType.ElectricalEquipmentMarker, index)
    );
    equipmentMarker.draw(coords);
    this.editor.addOrUpdateObject(equipmentMarker.mesh);
  }

  placeEquipment(): void {
    if (this.selectedEquipment) {
      this.equipmentPlaced.push(this.selectedEquipment);
      this.equipmentNotPlaced = this.equipmentNotPlaced.filter(
        (value: IElectricalEquipmentData): boolean => value.id !== this.selectedEquipment?.id
      );
    }
  }

  setSelectedMarker = (electricalEquipment: IElectricalEquipmentData): void => {
    this.selectedEquipment = electricalEquipment;
  };

  setStoreDependencies = (
    workspace: WorkspaceStore,
    smartGuides: SmartGuidesStore,
    properties: PropertiesStore
  ): void => {
    this.workspace = workspace;
    this.smartGuides = smartGuides;
    this.properties = properties;
  };

  setEquipmentExposure = (id: string, exposure: string, placed: boolean): void => {
    const equipmentList: IElectricalEquipmentData[] = placed ? this.equipmentPlaced : this.equipmentNotPlaced;
    const idx = equipmentList.findIndex((equipment: IElectricalEquipmentData): boolean => equipment.id === id);
    if (idx !== -1) {
      equipmentList[idx].exposure.type = exposure;
    }
  };
}
