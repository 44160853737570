import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import config, { UI_MODE } from '../../../../config/config';

export const DCACRatioContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: solid 1px #c9c9c9;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 0 5%;
  margin-top: 5px;
`;

export const DCACRatioTitleContainer = styled.div`
  width: 100%;
  padding: 0.8px 0 17.5px 0;
`;
export const DCACRatioProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 54px;
  margin-bottom: 14px;
  & > section {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;

export const DCACRatioTitle = styled.span`
  ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #4a4a4a;
`;
