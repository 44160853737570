import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { isDesignWorkspace } from '../../../../stores/UiStore/WorkspaceStore/utils';
import type { InverterSelectionViewModel } from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import useStore from '../../../../stores/useStore';
import { getTitle } from '../../../../utils/parsing';
import type Limit from '../../../../domain/models/Limit';
import { isWithin } from '../../../../domain/models/Limit';
import { isInverterOutsideInputVoltageRange } from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import { ElectricalDesignStage } from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import {
  AlertIcon,
  AlertText,
  AlertView,
  IconView,
  OverdriveWrapper,
  progressBarStyle,
  StatusBar,
  StatusText,
  StatusView,
  AlertViewContainer,
  AlertViewDivider
} from './styles';

const { DottedProgressBar } = LyraProgressBar;

function InverterStatusAndErrorText(): ReactElement {
  const { modal } = useStore();
  const [inverterSelectionViewModel] = useState(modal.viewModel as InverterSelectionViewModel);
  const [overdrive, setOverdrive] = useState(false);

  const {
    maxRatio,
    currentRatio,
    secondInverterValue,
    compatibility,
    pvModuleInfo,
    inputVoltageLowerBound,
    inputVoltageUpperBound,
    hoveredOrSelectedFirstInverter,
    currentlySelectedEssCompatibilityErrorMessage
  } = inverterSelectionViewModel;

  const { workspace } = useStore();
  const currentStage = workspace?.currentWorkspace.stageManager?.currentStage;
  const showEssCompatibilityError = !!currentlySelectedEssCompatibilityErrorMessage;

  const [secondInverterVisible, setSecondInverterVisible] = useState(!!secondInverterValue);

  useEffect((): void => {
    const isOverdrive = currentRatio > maxRatio;
    setOverdrive(isOverdrive);
  }, [maxRatio, currentRatio]);

  const outsideInputVoltageRangeErrorMessage = (): ReactElement => {
    const inverterDcInputVoltageRange: Limit = {
      lower: hoveredOrSelectedFirstInverter?.attributes.minDcInputVoltage,
      upper: hoveredOrSelectedFirstInverter?.attributes.maxDcInputVoltage
    };

    const isInputVoltageLowerBoundWithinInverterRange = isWithin(inverterDcInputVoltageRange, inputVoltageLowerBound);
    const isInputVoltageUpperBoundWithinInverterRange = isWithin(inverterDcInputVoltageRange, inputVoltageUpperBound);
    const noErrorMsg =
      (isInputVoltageLowerBoundWithinInverterRange && isInputVoltageUpperBoundWithinInverterRange)
      || !isDesignWorkspace(workspace.currentWorkspace)
      || !(currentStage instanceof ElectricalDesignStage);

    if (noErrorMsg) {
      return <></>;
    }
    if (!isInputVoltageLowerBoundWithinInverterRange && !isInputVoltageUpperBoundWithinInverterRange) {
      return (
        <span>
          temperature-adjusted Vmp of <b>{inputVoltageLowerBound}V</b> and Voc of <b>{inputVoltageUpperBound}V</b> are
        </span>
      );
    }
    return !isInputVoltageLowerBoundWithinInverterRange ? (
      <span>
        &nbsp; temperature-adjusted Vmp of <b>{inputVoltageLowerBound}V</b> is
      </span>
    ) : (
      <span>
        &nbsp; temperature-adjusted Voc of <b>{inputVoltageUpperBound}V</b> is
      </span>
    );
  };

  const showOutsideInputVoltageRangeError = isInverterOutsideInputVoltageRange(hoveredOrSelectedFirstInverter);
  const showOverdriveAlert = overdrive && !secondInverterVisible && !showOutsideInputVoltageRangeError;
  const showOptimizerIncompatibilityError = !compatibility && !showOutsideInputVoltageRangeError;

  return (
    <>
      <StatusView>
        <StatusText>DC-AC Ratio Status</StatusText>
        <StatusBar>
          <DottedProgressBar
            currentValue={currentRatio}
            units={'%'}
            minValue={0}
            maxValue={100}
            possibleMaxValue={maxRatio}
            style={progressBarStyle}
          />
        </StatusBar>
      </StatusView>

      <OverdriveWrapper>
        {showOverdriveAlert && (
          <AlertView>
            <IconView>
              <AlertIcon name="icon-warning" />
              <AlertText className="ml-xxxs">OVERDRIVE AC CAPACITY</AlertText>
            </IconView>
            <AlertText className="mt-xxxs">
              We suggest that you select a larger inverter or add a second inverter.
            </AlertText>
          </AlertView>
        )}

        {showOptimizerIncompatibilityError && (
          <AlertView>
            <IconView>
              <AlertIcon name="overdrive-alert-icon" />
              <AlertText className="ml-xxxs">MODULE/OPTIMIZER INCOMPATIBILITY</AlertText>
            </IconView>
            <AlertText className="mt-xxxs">
              No optimizers will work with the module you've selected
              <b>
                ({pvModuleInfo?.make} {pvModuleInfo?.model})
              </b>
              .
            </AlertText>
          </AlertView>
        )}

        {(showOutsideInputVoltageRangeError || showEssCompatibilityError) && (
          <AlertViewContainer>
            {showOutsideInputVoltageRangeError && (
              <AlertView>
                <IconView>
                  <AlertIcon name="overdrive-alert-icon" />
                  <AlertText className="ml-xxxs">NOT COMPATIBLE WITH SELECTED MODULE</AlertText>
                </IconView>
                <AlertText className="mt-xxxs">
                  <b>
                    {pvModuleInfo?.make} {pvModuleInfo?.model}
                  </b>
                  &nbsp;is not compatible with the <b>{getTitle(hoveredOrSelectedFirstInverter.name)}</b> because the
                  module's {outsideInputVoltageRangeErrorMessage()} outside the inverter's operating range of &nbsp;
                  <b>
                    {hoveredOrSelectedFirstInverter.attributes.minDcInputVoltage}-
                    {hoveredOrSelectedFirstInverter.attributes.maxDcInputVoltage}V
                  </b>
                  .
                </AlertText>
              </AlertView>
            )}

            {showOutsideInputVoltageRangeError && showEssCompatibilityError && <AlertViewDivider />}

            {showEssCompatibilityError && (
              <AlertView>
                <IconView>
                  <AlertIcon name="overdrive-alert-icon" />
                  <AlertText className="ml-xxxs">ESS COMPATIBILITY ERROR</AlertText>
                </IconView>
                <AlertText className="mt-xxxs">{currentlySelectedEssCompatibilityErrorMessage}</AlertText>
              </AlertView>
            )}
          </AlertViewContainer>
        )}
      </OverdriveWrapper>
    </>
  );
}

export default observer(InverterStatusAndErrorText);
