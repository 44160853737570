import {
  LyraDivider, LyraModal, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import type { SiteTemperatureViewModel } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/SiteTemperatureViewModel';
import { useUiStore } from '../../../../stores/useStore';
import { LicenseText } from '../../Panels/SystemSummaryPanel/styles';
import type { FlexboxDirection } from '../../../components/Layout/Flexbox';
import SiteTemperatureSelectionPanel from './SiteTemperatureSelectionPanel';

const SiteTemperatureModal = (): ReactElement => {
  const { modal } = useUiStore();
  const viewModel = modal.viewModel as SiteTemperatureViewModel;
  const {
    lowTemperatureSelectionViewModel, highTemperatureSelectionViewModel
  } = viewModel;

  const isLoading = !lowTemperatureSelectionViewModel || !highTemperatureSelectionViewModel;

  useEffect((): void => {
    viewModel.loadProjectTemperatureOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flexProps = {
    dir: 'row' as FlexboxDirection,
    style: {
      width: 900
    },
    align: 'flex-start'
  };

  return (
    <LyraModal.Modal
      $show={true}
      title="Edit Site Temperature Data"
      color={EThemeComponentColor.PURPLE}
      rightButtonDisabled={!viewModel.canSave}
      onClickLeftButton={viewModel.closeModal}
      onClickCloseButton={viewModel.closeModal}
      onClickRightButton={viewModel.saveSiteTemperatureData}
    >
      <Flexbox {...flexProps} className="mb-xs">
        {isLoading ? (
          <LyraLoader.Loader text="Loading site temperature..." />
        ) : (
          <Flexbox dir="column">
            <Flexbox align="unset">
              <SiteTemperatureSelectionPanel title="LOW TEMPERATURES" model={lowTemperatureSelectionViewModel} />
              <LyraDivider.Divider
                direction="vertical"
                className="mh-s"
                length={'320px'}
                color={EThemeComponentColor.GRAY_200}
              />
              <SiteTemperatureSelectionPanel title="HIGH TEMPERATURES" model={highTemperatureSelectionViewModel} />
            </Flexbox>
            <LicenseText>© 2021 ASHRAE, www.ashrae.org. Used with permission.</LicenseText>
          </Flexbox>
        )}
      </Flexbox>
    </LyraModal.Modal>
  );
};

export default observer(SiteTemperatureModal);
