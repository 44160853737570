import { MathUtils } from 'three';
import { isWithin } from '../domain/models/Limit';
import type Limit from '../domain/models/Limit';
import {
  Convergence, DURATIONS, Easing
} from './Convergence';

export class BoundedConvergence extends Convergence {
  private _originalMax: number;
  private _originalMin: number;

  constructor(
    start: number,
    end: number,
    min: number,
    max: number,
    easing: Easing = Easing.EASE_OUT,
    animationTime: number = DURATIONS.DEFAULT_ANIMATION,
    triggerRender: boolean = true
  ) {
    super(start, end, easing, animationTime, triggerRender);
    this._originalMin = this._min = min;
    this._originalMax = this._max = max;
  }

  get min(): number {
    return this._min;
  }

  get max(): number {
    return this._max;
  }

  get originalMin(): number {
    return this._originalMin;
  }

  get originalMax(): number {
    return this._originalMax;
  }

  setMin(min: number, animation: boolean = true): void {
    this._min = min;

    this.resetIfNecessary(animation);
  }

  setMax(max: number, animation: boolean = true): void {
    this._max = max;

    this.resetIfNecessary(animation);
  }

  private resetIfNecessary(animation: boolean) {
    const limit: Limit = {
      lower: this._min,
      upper: this._max,
    };
    const isOutsideOfNewInterval = (
      !isWithin(limit, this._start)
      || !isWithin(limit, this._end)
    );

    if (animation || isOutsideOfNewInterval) {
      const newStart = MathUtils.clamp(this._start, this._min, this._max);
      const newEnd = MathUtils.clamp(this._end, this._min, this._max);
      super.reset(newStart, newEnd);
    }
  }

  override reset(
    start?: number,
    end?: number,
    min?: number,
    max?: number,
    clampBetweenMinAndMax: boolean = false,
    animationDuration: number = this.originalAnimationDuration
  ): void {
    this._min = min != null ? min : this._min;
    this._max = max != null ? max : this._max;
    const newStartCandidate = start != null ? start : this._originalStart;
    const newStart = clampBetweenMinAndMax
      ? MathUtils.clamp(newStartCandidate, this._min, this._max)
      : newStartCandidate;
    const newEndCandidate = end != null ? end : this._originalEnd;
    const newEnd = clampBetweenMinAndMax ? MathUtils.clamp(newEndCandidate, this._min, this._max) : newEndCandidate;
    super.reset(newStart, newEnd, animationDuration);
  }

  isPlaying(): boolean {
    return this.value !== this.end;
  }
}
