import type { ReactElement } from 'react';
import * as React from 'react';
import { HELP_DOCS_TOOL } from '../../../../../stores/UiStore/ToolbarStore/Project/constants';
import ToolbarButton from '../../Shared/ToolbarButton';

interface IHelpDocsButtonProps {
  title: string;
  url: string;
}

function HelpDocsButton(props: IHelpDocsButtonProps): ReactElement {
  if (!props.url) {
    return <></>;
  }
  return (
    <a target="_blank" href={props.url}>
      <ToolbarButton key={HELP_DOCS_TOOL} icon="help-docs" title={props.title} submenu={false} selected={false} />
    </a>
  );
}

export default HelpDocsButton;
