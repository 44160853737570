import type {
  CSSRule, PropsTheme
} from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

type HouseShapeProps = PropsTheme<{
  selected?: boolean;
}>;

function putStylesOnSelect({
  theme, selected
}: HouseShapeProps): string {
  return selected ? `border: 2px solid ${theme.colors.mainColor2}; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);` : '';
}

export const HouseShapeWrapper = styled.div<HouseShapeProps>`
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.wrapperFill}
  box-sizing: content-box;
  display: flex;
  height: 60px;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid transparent;
  ${({
    theme, selected
  }: HouseShapeProps): string =>
    putStylesOnSelect({
      theme,
      selected
    })}
  &:hover {
    border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.mainColor2};
  }

  @media (min-width: 2560px) {
    padding: 10px;
  }
`;

export const RoofButtonStyle = styled.div`
  cursor: pointer;
  width: 55px;
  line-height: 1.7;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  & > p {
    font-size: 10px;
    text-align: center;
  }

  @media (min-width: 2560px) {
    width: 76px;
  }
`;
