import styled, {
  css,
  PropsTheme,
  SimpleInterpolation,
  withTheme
} from 'styled-components';
import { Color } from '../../../typings/index';

type ContainerProps = {
  width?: string;
};

const InputStyles = css`
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grayColor4};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation =>
    css`width: ${width};`}
  box-sizing: border-box;
`;

const InputField = styled.input`
  ${InputStyles}
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  height: 10px;
  padding: 6px 10px 6px 10px;
  font-size: 10px;

  :before {
    top: 0;
    position: absolute;
    content: "cool";
  }
`;

export {
  InputField
};
