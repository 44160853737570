import {
  action, computed, observable
} from 'mobx';
import type { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type { Design } from '../../models/Design/Design';
import { LayoutStrategy } from '../../models/RoofTopArray/LayoutStrategy';
import type IChangeMountingSystemDefinition from '../../request/ChangeMountingSystemDefinitionRequest/ChangeMountingSystemDefinition';
import type { IStage } from '../IStage';
import { DesignStep } from '../../models/Design/DesignState';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../models/SiteDesign/RoofFace';
import { handleApiError } from '../../../utils/helpers';
import type { DesignCreationWizardStore } from '../../../stores/UiStore/Wizard/DesignCreationWizardStore';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { DefaultLayoutStrategySpecifiedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import { RackSpacing } from '../../models/MountingSystemDefinition/IConfiguration';
import type { IMountingSystemDefinitionsData } from '../../models/MountingSystemDefinition/MountingSystemDefinitions';
import config from '../../../config/config';

export interface INewLayoutStrategyStageDeps {
  readonly isDesignCreation: boolean;
  readonly designCreationWizardStore?: DesignCreationWizardStore;
  readonly domain: DomainStore;
  readonly editor?: EditorStore;
  readonly serviceBus?: ServiceBus;
  readonly onContinue: () => void;
  readonly onDispose?: () => void;
}

export class NewLayoutStrategyStage implements IStage {
  readonly id: string = 'NewLayoutStrategyStage';
  readonly propCodeUI = 'new_layout_strategy_modal';

  @observable
  steepSlopeLayoutStrategy: LayoutStrategy = LayoutStrategy.initialForSteepSlope();
  @observable
  lowSlopeLayoutStrategy: LayoutStrategy = LayoutStrategy.initialForLowSlope();
  @observable
  private lowSlopeRackSpacing: RackSpacing = RackSpacing.initial();
  @observable
  isRackSpacingAuto: boolean = true;

  readonly isDesignCreation: boolean;
  private readonly designCreationWizardStore?: DesignCreationWizardStore;
  private readonly domain: DomainStore;
  private readonly currentDesign?: Design;
  private readonly editor?: EditorStore;
  private readonly serviceBus?: ServiceBus;
  private readonly onContinue: () => void;
  private readonly onDispose?: () => void;
  private readonly designService = new DesignService();

  constructor(dependencies: INewLayoutStrategyStageDeps) {
    const {
      isDesignCreation, designCreationWizardStore, domain, editor, serviceBus, onContinue
    } = dependencies;
    this.isDesignCreation = isDesignCreation;
    this.domain = domain;
    this.onContinue = onContinue;
    if (isDesignCreation) {
      this.designCreationWizardStore = designCreationWizardStore;
      // Note: this is the last step, so we do not restore the initial values from the designCreationWizardStore
    } else {
      this.currentDesign = this.domain.design;
      this.serviceBus = serviceBus;
      this.editor = editor;
      this.setInitialValuesFromDomainModel();
    }
  }

  @action.bound
  private setInitialValuesFromDomainModel(): void {
    const definitions = this.currentDesign!.system.equipment.mountingSystems.definitions;
    if (this.requiresSteepSlopeLayoutStrategy) {
      this.steepSlopeLayoutStrategy =
        definitions?.steepSlope?.defaultLayoutStrategy.copy() ?? LayoutStrategy.initialForSteepSlope();
    }
    if (this.requiresLowSlopeLayoutStrategy) {
      this.lowSlopeLayoutStrategy =
        definitions?.lowSlope?.defaultLayoutStrategy.copy() ?? LayoutStrategy.initialForLowSlope();
      this.lowSlopeRackSpacing =
        definitions?.lowSlope?.defaultConfiguration.rackSpacing?.copy() ?? RackSpacing.initial();
      this.isRackSpacingAuto = definitions?.lowSlope?.defaultConfiguration.rackSpacing?.isAutomatic ?? true;
    }
  }

  @computed
  get rackSpacingExactValueInInches(): number | undefined {
    return this.lowSlopeRackSpacing.exactValueInInchesRoundedToAWholeNumber;
  }

  @computed
  get rackSpacingDesignMonthForAutomaticCalculation(): string | undefined {
    return this.lowSlopeRackSpacing.autoForDesignMonth;
  }

  @computed
  get requiresSteepSlopeLayoutStrategy(): boolean {
    return this.isDesignCreation
      ? this.designCreationWizardStore!.hasSteepSlopeMountingSystemDefinition
      : this.currentDesign!.system.equipment.mountingSystems.definitions.hasSteepSlopeDefinition;
  }

  @computed
  get requiresLowSlopeLayoutStrategy(): boolean {
    return this.isDesignCreation
      ? this.designCreationWizardStore!.hasLowSlopeMountingSystemDefinition
      : this.currentDesign!.system.equipment.mountingSystems.definitions.hasLowSlopeDefinition;
  }

  @action.bound
  changeAutoRackSpacingDesignMonth(value: number | string): void {
    this.lowSlopeRackSpacing.setDesignMonthForAutomaticSpacing(value.toString());
  }

  @action.bound
  changeAutomaticRackSpacing(value: boolean): void {
    this.isRackSpacingAuto = value;
  }

  @action.bound
  changeRackSpacingExactValueInInches(inputValue: number): void {
    this.lowSlopeRackSpacing.setExactValueInInches(inputValue);
  }

  @computed
  get canContinue(): boolean {
    const steepSlopeValid = Boolean(this.steepSlopeLayoutStrategy?.hasAllRequiredProperties);
    const lowSlopeValid =
      Boolean(this.lowSlopeLayoutStrategy?.hasAllRequiredProperties)
      && this.lowSlopeRackSpacing.isValid(this.isRackSpacingAuto);
    return (
      (!this.requiresSteepSlopeLayoutStrategy || steepSlopeValid)
      && (!this.requiresLowSlopeLayoutStrategy || lowSlopeValid)
    );
  }

  async continue(): Promise<void> {
    if (this.isDesignCreation) {
      this.updateDesignCreationWizardStore();
      this.onContinue();
    } else {
      if (this.currentDesign!.state.isUserIn(DesignStep.ARRAY_PLACEMENT)) {
        await this.updateMountingSystemDefinitions();
        this.onContinue();
      }
    }
    config.analytics?.trackEvent(
      new DefaultLayoutStrategySpecifiedEvent(
        this.domain,
        this.requiresSteepSlopeLayoutStrategy ? this.steepSlopeLayoutStrategy : undefined,
        this.requiresLowSlopeLayoutStrategy ? this.lowSlopeLayoutStrategy : undefined,
        this.requiresLowSlopeLayoutStrategy ? this.lowSlopeRackSpacing : undefined
      )
    );
  }

  @action
  private updateDesignCreationWizardStore = (): void => {
    const store = this.designCreationWizardStore!;
    if (this.requiresSteepSlopeLayoutStrategy) {
      store.setSteepSlopeLayoutStrategy(this.steepSlopeLayoutStrategy);
    }
    if (this.requiresLowSlopeLayoutStrategy) {
      store.setLowSlopeLayoutStrategy(this.lowSlopeLayoutStrategy, this.lowSlopeRackSpacing);
    }
  };

  private updateMountingSystemDefinitions = async (): Promise<void> => {
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor!);
    const request: IChangeMountingSystemDefinition = {
      mountingSystemDefinitions: this.updatedMountingSystemDefinitionsData(),
      design: this.currentDesign!.toData()
    };
    return this.designService
      .updateMountingSystemDefinitions(request)
      .then((response: DesignDelta): void => {
        this.serviceBus!.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
      })
      .catch(handleApiError('Failed to update default layout strategy'))
      .finally((): void => hideLoaders());
  };

  private updatedMountingSystemDefinitionsData(): IMountingSystemDefinitionsData {
    const copy = this.currentDesign!.system.equipment.mountingSystems.definitions.copy();
    if (this.requiresSteepSlopeLayoutStrategy) {
      copy.steepSlope!.defaultLayoutStrategy = this.steepSlopeLayoutStrategy;
    }
    if (this.requiresLowSlopeLayoutStrategy) {
      copy.lowSlope!.defaultLayoutStrategy = this.lowSlopeLayoutStrategy;
      copy.lowSlope!.defaultConfiguration.rackSpacing = this.lowSlopeRackSpacing;
    }
    return copy.toData();
  }

  cancel(): void {
    /** */
  }

  resume(lastValidStage: string): void {
    /** Not implemented yet */
  }

  dispose(): void {
    this.onDispose?.();
  }
}
