import React, { BaseProps, ReactElement } from 'react';
import { Grid } from '../Grid';
import { Aside } from './Aside';
import * as names from './constants';
import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';
import { Tool } from './Tool';
import { SubTool } from './SubTool';
import { ProgressStepperContainer } from './ProgressStepperContainer';

type LayoutProps = BaseProps & {
  auroraMode?: boolean;
  pagesMode?: boolean;
};

//  Base:
//  +-------------------------------+
//  |             Header            |
//  +-------------------------------+
//  |  Tool |               | Aside |
//  |       |               |       |
//  +       +    Content    +       +
//  |       |               |       |
//  |       |               |       |
//  +       +---------------+       +
//  |       |    Footer     |       |
//  +-------+---------------+-------+
//
//  AuroraMode:
//  _________________________________
//  |        ProgressStepper        |
//  Г‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾T‾‾‾‾‾‾‾‾‾ꓶ
//  |                     |         |
//  |                     |         |
//  +       Content       +  Aside  +
//  |                     |         |
//  |                     |         |
//  +                     +         +
//  |                     |         |
//  +-------+-------------+---------+
//
//  AuroraMode & PagesMode:
//  +-------------------------------+
//  |                               |
//  |                               |
//  |                               |
//  |            Content            |
//  |                               |
//  |                               |
//  |                               |
//  +-------------------------------+
//
function SolarLayout({ children, auroraMode, pagesMode }: LayoutProps): ReactElement {
  let COLUMNS: string = '';
  let ROWS: string = '';
  let AREA: string = '';

  switch (`${auroraMode ? 'auroraMode' : ''}, ${pagesMode ? 'pagesMode' : ''}`) {
    case 'auroraMode, pagesMode': {
      COLUMNS = `1fr`;
      ROWS = `${names.HEIGHT_PROGRESS_STEPPER} 1fr`;
      AREA = `
        "${names.PROGRESS_STEPPER}"
        "${names.CONTENT}"
      `;
      break;
    }
    case 'auroraMode, ': {
      COLUMNS = `1fr ${names.WIDTH_ASIDE * 2}px`;
      ROWS = `${names.HEIGHT_PROGRESS_STEPPER} minmax(0, 1fr)`;
      AREA = `
        "${names.PROGRESS_STEPPER} ${names.PROGRESS_STEPPER}"
        "${names.CONTENT} ${names.ASIDE}"
      `;
      break;
    }
    default: {
      COLUMNS = `${names.WIDTH_TOOL} 1fr ${names.WIDTH_ASIDE}px`;
      ROWS = `${names.HEIGHT_TOP} 1fr ${names.HEIGHT_BOTTOM}`;
      AREA = `
        "${names.HEADER} ${names.HEADER} ${names.HEADER}"
        "${names.TOOL} ${names.CONTENT} ${names.ASIDE}"
        "${names.TOOL} ${names.FOOTER} ${names.ASIDE}"
      `;
    }
  }

  return (
    <Grid areas={AREA} rows={ROWS} cols={COLUMNS}>
      {children}
    </Grid>
  );
}

export {
  Header,
  Tool,
  SubTool,
  Content,
  Aside,
  Footer,
  SolarLayout,
  ProgressStepperContainer
};
