import { isFirefox } from './browserDetector';

const fireFoxMimesMap: { [key: string]: string } = {
  'application/pdf': 'text/pdf'
};

export const applyBlobMimeHackForFirefox = (blob: Blob): Blob => {
  if (fireFoxMimesMap[blob.type]) {
    return new Blob([blob], {
      type: fireFoxMimesMap[blob.type]
    });
  }
  return blob;
};

type BlobifyApiResponseTypeClosureType = (response: string) => Blob;

export const blobifyApiResponse =
  (mime: string): BlobifyApiResponseTypeClosureType =>
    (response: string): Blob => {
    // Setting text/pdf mime type so that firefox won't open the file in the current tab:
      return new Blob([response], {
        type: isFirefox() && fireFoxMimesMap[mime] ? fireFoxMimesMap[mime] : mime
      });
    };

// Parses content-disposition response header;
// Taken from this answer https://stackoverflow.com/a/67994693
export const parseContentDisposition = (disposition: string): string => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  if (utf8FilenameRegex.test(disposition)) {
    return decodeURIComponent(utf8FilenameRegex.exec(disposition)?.[1] ?? '');
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');

    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (!!matches && matches[2]) {
        return matches[2];
      }
    }
  }

  return '';
};
