import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import type {
  IDesignFileData,
  EDesignFileType
} from '../../../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import DocumentTitle from '../DocumentTitle/DocumentTitle';
import {
  DocumentsListContainer, DocumentItem, SelectWrapper, DocumentIcon, DeleteIcon
} from './DocumentsList.styles';

const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;

interface IDocumentsListProps {
  filesTypes: {
    name: string;
    value: string;
  }[];
  filesList: IDesignFileData[];
  onFileTypeChange: (id: string, value: EDesignFileType) => void;
  onFileTitleChange: (id: string, value: string) => void;
  onFileDelete: (id: string) => void;
}

function DocumentsList(props: IDocumentsListProps): ReactElement {
  const {
    filesTypes, filesList, onFileTypeChange, onFileDelete, onFileTitleChange
  } = props;

  const getFileTypeOptionByValue = useCallback(
    (selectedDesignFileType: string): string => {
      return filesTypes.find((type: OptionProps): boolean => type.value === selectedDesignFileType)?.value as string;
    },
    [filesTypes]
  );

  return (
    <DocumentsListContainer>
      {filesList.map(
        (designFile: IDesignFileData): ReactElement => (
          <DocumentItem key={designFile.id}>
            <DocumentIcon />

            <DocumentTitle
              title={designFile.title}
              onTitleChange={(value: string): void => onFileTitleChange(designFile.id, value)}
            />

            <SelectWrapper>
              <Select
                textAlign="left"
                listOptions={filesTypes}
                onSelect={(option: string | number): void => onFileTypeChange(designFile.id, option as EDesignFileType)}
                selected={getFileTypeOptionByValue(designFile.type!)}
              />
            </SelectWrapper>

            <DeleteIcon onClick={(): void => onFileDelete(designFile.id)} />
          </DocumentItem>
        )
      )}
    </DocumentsListContainer>
  );
}

export default DocumentsList;
