interface IDcOptimizerData {
  readonly id: string;
}

export interface IDcOptimizersData {
  readonly definition: string;
  readonly instances?: readonly IDcOptimizerData[];
}

export class DcOptimizers {
  definition: string;
  instances: readonly IDcOptimizerData[];

  constructor(data?: IDcOptimizersData) {
    this.definition = data?.definition ?? '';
    this.instances = [...(data?.instances ?? [])];
  }

  toData(): IDcOptimizersData {
    return {
      definition: this.definition,
      instances: this.instances?.length ? this.instances : undefined
    };
  }

  clearInstancesIfPresent(): void {
    this.instances = [];
  }
}
