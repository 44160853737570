export interface IPvSystemCircuitData {
  readonly id: string;
  readonly type: string;
  readonly sources?: IPvSystemCircuitData[];
}

export class PvSystemCircuit {
  id: string;
  type: string;
  sources?: PvSystemCircuit[];

  constructor(data: IPvSystemCircuitData) {
    this.id = data.id;
    this.type = data.type;
    this.sources = data.sources?.map((source: IPvSystemCircuitData): PvSystemCircuit => new PvSystemCircuit(source));
  }

  toData(): IPvSystemCircuitData {
    return {
      id: this.id,
      type: this.type,
      sources: this.sources?.map((source: PvSystemCircuit): IPvSystemCircuitData => source.toData())
    };
  }
}
