import { Vector3 } from 'three';
import { PreviewLine } from '../../../../domain/graphics/PreviewLine';
import {
  SceneObjectType, WARNING
} from '../../../../domain/models/Constants';
import type { Building } from '../../../../domain/models/SiteDesign/Building';
import type { Color } from '../../../../domain/typings';
import { canvasConfig } from '../../../../config/canvasConfig';
import type DomainStore from '../../../DomainStore/DomainStore';
import type {
  IPointerDownControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../../EditorStore/Controls/ControlEvents';
import type { PropertiesStore } from '../../Properties/Properties';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import { NewOrEditBuildingViewModel } from '../../Wizard/ViewModels/NewRoofFace/NewOrEditBuildingViewModel';
import { NewRoofFaceViewModel } from '../../Wizard/ViewModels/NewRoofFace/NewRoofFaceViewModel';
import { SelectBuildingViewModel } from '../../Wizard/ViewModels/NewRoofFace/SelectBuildingViewModel';
import type { WizardStore } from '../../Wizard/Wizard';
import type { IWorkspace } from '../../WorkspaceStore/types';
import { BaseSuperTool } from '../SuperTool';
import type { IDrawableStructureFactory } from '../../../../domain/models/StructureFactory';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import { PropsPanelUICodes } from '../../Properties/propertiesStoreConstants';
import { KindGuides } from '../../SmartGuidesStore/IApplyParams';
import EGuideIdentifier from '../../SmartGuidesStore/EGuideIdentifier';
import type { PolygonDrawable } from '../../../../domain/mixins/PolygonDrawable';
import { notify } from '../../../../utils/helpers';
import {
  objectInsideOther,
  isVertexLayingOnOneOfTheEdges,
  isPolygonSelfIntersecting,
  divideSegment,
  doesLineSegmentIntersectPolygon,
  pointOnPolygonEdge,
  pointInsidePolygon,
  isPolygonEnclosingOtherPolygon,
  getPolygonAreaDifference,
  calculateVector3to2PolygonArea,
  isPolygonInsideAnother
} from '../../../../utils/spatial';
import { Outline } from '../../../../domain/models/SiteDesign/Outline';
import { Parcel } from '../../../../domain/models/SiteDesign/Parcel';
import { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type { IAddOrUpdateParcelCommandDependencies } from '../../../ServiceBus/Commands/AddOrUpdateParcelCommand';
import type { IAddVertexToRoofFaceDependencies } from '../../../ServiceBus/Commands/AddVertexToRoofFaceCommand';
import type { IRemoveVertexFromPolygonDependencies } from '../../../ServiceBus/Commands/RemoveVertexFromPolygon';
interface ITraceToolBase {
  wipObject?: PolygonDrawable;
  smartGuides: SmartGuidesStore;
  currentWorkspace: IWorkspace;
}

export abstract class TraceToolBase extends BaseSuperTool implements ITraceToolBase {
  private debugMouseDownCoords: Vector3[] = [];
  private debugMouseDownTime: number[] = [];
  debugMouseUpCoords: Vector3[] = [];
  debugMouseUpTime: number[] = [];
  wipObject?: Parcel | Outline | RoofFace;
  smartGuides!: SmartGuidesStore;
  currentWorkspace!: IWorkspace;
  wizard!: WizardStore;
  domain!: DomainStore;
  properties!: PropertiesStore;
  roofGroupBuildingName: string = '';
  availableBuildings: Building[] = [];
  allSameRoofsBuildingName: string = '';
  protected drawableObjectsFactory!: IDrawableStructureFactory;
  private previewLine?: PreviewLine;

  private invalidRoofClosing: boolean = false;
  private enclosedAllSameRoofs: boolean = true;
  private outlineContainsAnother: boolean = false;
  private parcelEnclosesNotAllBuildings: boolean = false;

  private guidesAlreadyReset: boolean = false;

  abstract finishWipPolygon(finishData: { name: string; color: Color; building?: Building; level?: number }): void;

  override onMouseUp = (event: IPointerUpControlEvent): void => {
    const {
      target, pointerEnd
    } = event;
    if (!this.isValidUpEvent(event) || !target || !pointerEnd) {
      return;
    }
    const vertexWorldCoords = target.unprojectMouseToFrustum(pointerEnd);

    this.debugMouseUpCoords.push(vertexWorldCoords.clone());
    this.debugMouseUpTime.push(new Date().getTime());
    if (this.debugMouseUpCoords.length > 100) {
      // Remove first element
      this.debugMouseUpCoords.splice(0, 1);
      this.debugMouseUpTime.splice(0, 1);
    }

    this.applyGuidesWithValidationForEachGuideAndPersistIfValid({
      mousePos: vertexWorldCoords
    });
  };

  override onKeyUp = ({ key }: KeyboardEvent): void => {
    const { shortcutUndo } = canvasConfig;
    const undo = shortcutUndo.split('|').find((command: string): boolean => command === key);

    if (undo) {
      this.smartGuides.resetGuides();
      this.removeLastVertex();
    }
  };

  removeWipPolygon(): void {
    this.editor.removeObject(this.wipObject!.mesh);
    this.wipObject!.delete();
    this.enableTools();
  }

  removeLastVertex(): void {
    const dependencies: IRemoveVertexFromPolygonDependencies = {
      wipPolygon: this.wipObject!
    };
    this.serviceBus.send('remove_vertex_from_polygon', dependencies);

    if (this.wipObject!.polygon.length === 0) {
      this.previewLine!.visible = false;
      this.enableTools();

      if (this.wipObject instanceof RoofFace) {
        this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelTraceIndividualRoofFace);
      }

      if (this.wipObject instanceof Outline) {
        this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelOutline);
      }
    } else {
      const prevLine = this.previewLine!;
      const startIndex = this.wipObject!.polygon.length - 1;
      const prevLineColor = !this.isInvalidSegment()
        ? canvasConfig.previewObjectColor
        : canvasConfig.previewObjectInvalidColor;
      prevLine.color = prevLineColor;
      prevLine.setStart(this.wipObject!.polygon[startIndex].mesh.position);
    }
  }

  enableTools(): void {
    this.toolbar.enableTools();
    const selectedTool = this.toolbar.selectedTool;
    this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
    if (selectedTool) {
      this.toolbar.selectTool(selectedTool);
    }
  }

  override onMouseDown(event: IPointerDownControlEvent): void {
    const {
      target, pointerStart
    } = event;

    if (pointerStart && target) {
      const vertexWorldCoords = target.unprojectMouseToFrustum(pointerStart);
      this.debugMouseDownCoords.push(vertexWorldCoords.clone());
      this.debugMouseDownTime.push(new Date().getTime());
      if (this.debugMouseDownCoords.length > 100) {
        // Remove first element
        this.debugMouseDownCoords.splice(0, 1);
        this.debugMouseDownTime.splice(0, 1);
      }

      if (this.wipObject!.boundary.vertices.length === 0) {
        this.previewLine!.set({
          start: vertexWorldCoords,
          end: vertexWorldCoords
        });
        this.previewLine!.visible = false;
      }
    }

    if (this.isInvalidSegment()) {
      if (this.invalidRoofClosing) {
        notify('Roof faces within outline belong to different buildings', WARNING);
        this.invalidRoofClosing = false;
      }
      if (!this.enclosedAllSameRoofs) {
        notify(`Roof outline must enclose all roof faces belonging to ${this.allSameRoofsBuildingName}`, WARNING);
        this.enclosedAllSameRoofs = true;
      }
      if (this.outlineContainsAnother) {
        notify('Roof outlines can\'t contain other roof outlines', WARNING);
        this.outlineContainsAnother = false;
      }
      if (this.parcelEnclosesNotAllBuildings) {
        notify('Parcel boundary does not enclose all buildings', WARNING);
        this.parcelEnclosesNotAllBuildings = false;
      }
    }
  }

  private shouldClosePolygon(pointerEnd: Vector3): boolean {
    if (this.wipObject!.polygon.length === 0) {
      return false;
    }

    const delta = new Vector3().subVectors(pointerEnd, this.wipObject!.polygon[0].mesh.position);
    return Math.abs(delta.x) <= canvasConfig.snapThreshold && Math.abs(delta.y) <= canvasConfig.snapThreshold;
  }

  protected calculateNewVertex(pointerEnd: Vector3): Vector3 {
    if (this.wipObject!.polygon.length === 0) {
      return pointerEnd;
    }

    const delta = new Vector3().subVectors(pointerEnd, this.wipObject!.polygon[0].mesh.position);
    // The condition is identical to shouldClosePolygon
    return Math.abs(delta.x) <= canvasConfig.snapThreshold && Math.abs(delta.y) <= canvasConfig.snapThreshold
      ? this.wipObject!.polygon[0].mesh.position
      : pointerEnd;
  }

  protected applyGuidesWithValidationForEachGuide({ mousePos }: { mousePos: Vector3 }): void {
    const prevLine = this.previewLine!;
    const prevLinePosition: Vector3 = new Vector3().copy(mousePos);
    const previouslyCheckedPosition: Vector3 = new Vector3();
    let isPreviouslyCheckedPositionValid: boolean = false;

    this.previewLine!.visible = true;

    const vectorStart: Vector3 = prevLinePosition.clone();
    if (this.wipObject!.polygon.length > 0) {
      vectorStart.copy(this.wipObject!.polygon[this.wipObject!.polygon.length - 1].mesh.position);
    }
    let validSnapHappenedAtLeastOnce = false;

    // Apply multiple if possible, ignore invalid
    const iterator = this.smartGuides.applyGuidesViaIterator({
      mousePos,
      wipBoundary: this.wipObject!.boundary,
      kind: KindGuides.TRACE_TOOL
    });

    // Initialize iterator. It'll return snap result and take validation result for each guide.
    let iterationResult = iterator.next();
    do {
      if (iterationResult.done) {
        continue;
      }

      const {
        position, lastAppliedGuideId
      } = iterationResult.value;

      let isValid: boolean;
      if (position && previouslyCheckedPosition.equals(position)) {
        isValid = isPreviouslyCheckedPositionValid;
      } else {
        isValid = !!(position && !this.isInvalidPossibleSegment(position));
        isPreviouslyCheckedPositionValid = isValid;
      }

      if (position) {
        previouslyCheckedPosition.copy(position);
      }

      if (position && isValid) {
        prevLine.color = canvasConfig.previewObjectColor;
        prevLine.set({
          start: vectorStart,
          end: position
        });
        validSnapHappenedAtLeastOnce = true;
        this.guidesAlreadyReset = false;
      } else if (
        lastAppliedGuideId !== EGuideIdentifier.SNAP_LINES
        && this.smartGuides.active
        && !this.guidesAlreadyReset
      ) {
        this.smartGuides.resetGuides();
        this.guidesAlreadyReset = true;
      }

      // Send validation result to iterator and take next snapped position.
      iterationResult = iterator.next(isValid);
    } while (!iterationResult.done);

    if (!validSnapHappenedAtLeastOnce) {
      // Check if current location is invalid, highlight it if needed.
      prevLine.color = this.isInvalidPossibleSegment(prevLinePosition)
        ? canvasConfig.previewObjectInvalidColor
        : (prevLine.color = canvasConfig.previewObjectColor);
      prevLine.set({
        start: vectorStart,
        end: prevLinePosition
      });
    }
  }

  protected applyGuidesWithValidationForEachGuideAndPersistIfValid({ mousePos }: { mousePos: Vector3 }): void {
    const previouslyCheckedPosition: Vector3 = new Vector3();
    let isPreviouslyCheckedPositionValid: boolean = false;

    // Apply multiple if possible, ignore invalid
    const iterator = this.smartGuides.applyGuidesViaIterator({
      mousePos,
      wipBoundary: this.wipObject!.boundary,
      kind: KindGuides.TRACE_TOOL
    });

    // Initialize iterator. It'll return snap result and take validation result for each guide.
    let iterationResult = iterator.next();
    const finalPosition = mousePos.clone();
    do {
      if (iterationResult.done) {
        continue;
      }

      const { position } = iterationResult.value;

      let isValid: boolean;
      if (position && previouslyCheckedPosition.equals(position)) {
        isValid = isPreviouslyCheckedPositionValid;
      } else {
        isValid = !!(position && !this.isInvalidPossibleSegment(position));
        isPreviouslyCheckedPositionValid = isValid;
      }

      if (position) {
        previouslyCheckedPosition.copy(position);
      }

      if (position && isValid) {
        finalPosition.copy(position);
      }

      // Send validation result to iterator and take next snapped position.
      iterationResult = iterator.next(isValid);
    } while (!iterationResult.done);

    if (!this.isInvalidPossibleSegment(finalPosition)) {
      this.addVertexToWipPolygon(finalPosition);

      if (this.wipObject!.boundary.closed) {
        // Roof face is closed, call wizard (popup) to finish roof face
        this.showWizard();
        this.updateCursor();
      }
      this.hidePreviewLine();
    }
  }

  private addVertexToWipPolygon(finalPosition: Vector3): void {
    const targetCoordinates = this.calculateNewVertex(finalPosition);
    if (this.wipObject instanceof Parcel) {
      const dependencies: IAddOrUpdateParcelCommandDependencies = {
        editor: this.editor,
        parcel: this.wipObject,
        newVertex: targetCoordinates
      };
      this.serviceBus.send('add_or_update_parcel', dependencies);
    } else {
      // This would work for outlines also:
      const dependencies: IAddVertexToRoofFaceDependencies = {
        editor: this.editor,
        targetCoordinates,
        wipRoofface: this.wipObject as RoofFace
      };
      this.serviceBus.send('add_vertex_to_roof_face', dependencies);
    }
  }

  /*
  // To measure performance of snapping put this setInterval code to constructor, uncomment private members beneath
  // and measureStart/measureStop calls in the code.
  // setInterval(() => {
  //   console.log('slowness: ', this.getMeasure());
  // }, 3000);
  private stamp = 0;
  private measureSum = 0;
  private measureCalls = 0;
  private measureStart(): void {
    this.stamp = performance.now();
  }
  private measureStop(): void {
    this.measureSum += performance.now() - this.stamp;
    this.measureCalls++;
  }
  private getMeasure(): string {
    return this.measureCalls && this.measureSum && (this.measureSum/this.measureCalls).toFixed(5) || '0';
  }
  */
  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    const {
      target, pointerPosition
    } = event;

    if (target === undefined || pointerPosition === undefined) {
      return;
    }

    const mousePos = target.unprojectMouseToFrustum(pointerPosition);

    //this.measureStart();
    this.applyGuidesWithValidationForEachGuide({ mousePos });
  };

  showWizard(): void {
    this.disableShortcuts();
    const dependencies = {
      wizard: this.wizard,
      domain: this.domain,
      traceTool: this,
      serviceBus: this.serviceBus
    };

    if (this.wipObject instanceof Parcel) {
      this.finishWipPolygon({
        name: 'parcel',
        color: canvasConfig.parcelBoundaryColor
      });
    }

    if (this.wipObject instanceof Outline) {
      if (this.roofGroupBuildingName !== '') {
        const roofGroupBuilding = this.domain.buildings.find(
          (target: Building): boolean => target.name === this.roofGroupBuildingName
        );
        this.finishWipPolygon({
          name: 'outline',
          color: canvasConfig.wipOutlineColor,
          building: roofGroupBuilding
        });
      } else {
        this.wizard.createOneStepWizard(NewOrEditBuildingViewModel, dependencies);
      }
    }

    if (this.wipObject instanceof RoofFace) {
      this.setAvailableBuildings();
      this.wizard.createWizard({
        1: this.wizard.createStepViewModel(SelectBuildingViewModel, dependencies),
        2: this.wizard.createStepViewModel(NewOrEditBuildingViewModel, dependencies),
        3: this.wizard.createStepViewModel(NewRoofFaceViewModel, dependencies)
      });
      const existIntoOutline: PolygonDrawable | undefined = this.getOutline();

      if (existIntoOutline) {
        this.domain.currentBuilding = this.domain.findBuildingByChild(existIntoOutline);
        this.wizard.changeStep(3);
        (this.wizard.currentViewModel as NewRoofFaceViewModel).disableBack = true;
      } else {
        if (this.domain.buildings.length === 0 || this.availableBuildings.length === 0) {
          this.wizard.changeStep(2);
        } else {
          this.wizard.changeStep(1);
        }
      }
    }
  }

  private disableShortcuts(): void {
    KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
  }

  getOutline(): PolygonDrawable | undefined {
    return objectInsideOther(this.editor, this.wipObject!, SceneObjectType.Outline);
  }

  protected initPreviewLine(): void {
    this.previewLine = this.previewLine || this.getPreviewObject();
    this.editor.addOrUpdateObject(this.previewLine.mesh);
  }

  protected getPreviewObject(): PreviewLine {
    return this.drawableObjectsFactory.create<PreviewLine>(PreviewLine, {
      color: canvasConfig.previewObjectColor,
      getWipObject: (): PolygonDrawable | undefined => this.wipObject
    });
  }

  protected hidePreviewLine(): void {
    this.previewLine!.visible = false;
    this.previewLine!.segmentLengthElement.style.display = 'none';
  }

  protected removePreviewLine(): void {
    this.editor.removeObject(this.previewLine!.mesh);
    this.previewLine!.segmentLengthElement.remove();
  }

  protected isInvalidSegment(): boolean {
    return this.isInvalidPossibleSegment(this.previewLine!.end);
  }

  protected isInvalidPossibleSegment(end: Vector3): boolean {
    // static configuration:
    const DEBUG = false;
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log(`isInvalidPossibleSegment (${end.x}, ${end.y})`);
    }
    this.invalidRoofClosing = false;
    this.enclosedAllSameRoofs = true;
    this.outlineContainsAnother = false;
    this.parcelEnclosesNotAllBuildings = false;

    const start = this.previewLine!.start;
    const prvLineSegment = {
      A: start,
      B: end
    };

    const parcel: Parcel = this.editor.getObjectsByType(SceneObjectType.ParcelBoundary)[0] as Parcel;
    const outlines: Outline[] = this.editor.getObjectsByType(SceneObjectType.Outline);
    const roofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace);

    const wipVertices = this.wipObject!.getVector3s();

    // Do not allow a vertex touch any of its edges, back-end will treat such roof/outline as invalid
    if (isVertexLayingOnOneOfTheEdges(wipVertices.slice(1), end)) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log(
          'isInvalidPossibleSegment: invalid polygon because last vertex touches one of its '
            + 'edges (not start or ending)'
        );
      }
      return true;
    } else if (wipVertices.length > 1 && isPolygonSelfIntersecting([...wipVertices, end])) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log(
          'isInvalidPossibleSegment: invalid polygon because one of the vertices touches one of its '
            + 'edges (not part of that vertex)'
        );
      }
      return true;
    }

    const wipVerticesWithNewest: Vector3[] = [...wipVertices];
    if (end.x !== 0 || end.y !== 0) {
      // FIXME: comment why add middle point?
      if (wipVertices.length > 0) {
        wipVerticesWithNewest.length = 0;
        for (let i = 0; i < wipVertices.length - 1; ++i) {
          const lineSegment = {
            A: wipVertices[i],
            B: wipVertices[i + 1]
          };
          const dividedLineSegment = divideSegment({
            A: wipVertices[i],
            B: wipVertices[i + 1]
          });
          const middlePoint = new Vector3(
            dividedLineSegment[0].B.x,
            dividedLineSegment[0].B.y,
            (lineSegment.A.z + lineSegment.B.z) / 2
          );

          wipVerticesWithNewest.push(lineSegment.A, middlePoint);
        }
      }

      const lastDividedLineSegment = divideSegment(prvLineSegment);
      const lastMiddlePoint = new Vector3(
        lastDividedLineSegment[0].B.x,
        lastDividedLineSegment[0].B.y,
        (start.z + end.z) / 2
      );
      wipVerticesWithNewest.push(start, lastMiddlePoint, end);
    }

    const isOutline = this.wipObject instanceof Outline;
    const isRoofFace = this.wipObject instanceof RoofFace;
    const isParcelBoundary = this.wipObject instanceof Parcel;

    if (isOutline || isRoofFace || isParcelBoundary) {
      // Check PrevLine passing through RoofFaces
      if (parcel && doesLineSegmentIntersectPolygon(prvLineSegment, parcel.getVector3s())) {
        if (DEBUG) {
          // eslint-disable-next-line no-console
          console.log(
            'isInvalidPossibleSegment: invalid because polygon intersects a parcel',
            prvLineSegment,
            parcel
          );
        }
        return true;
      }

      for (const roof of roofFaces) {
        if (doesLineSegmentIntersectPolygon(prvLineSegment, roof.getVector3s())) {
          if (DEBUG) {
            // eslint-disable-next-line no-console
            console.log('isInvalidPossibleSegment: invalid because polygon intersects a roof', prvLineSegment, roof);
          }
          return true;
        }
      }

      for (const outline of outlines) {
        if (doesLineSegmentIntersectPolygon(prvLineSegment, outline.getVector3s())) {
          if (DEBUG) {
            // eslint-disable-next-line no-console
            console.log('isInvalidPossibleSegment: invalid because polygon intersects an outline');
          }
          return true;
        }
      }

      if (
        (this.wipObject?.boundary.segments.length ?? 0) > 1
        && this.isWipObjectEnclosingDifferentBuildings(end, roofFaces, outlines)
      ) {
        if (DEBUG) {
          // eslint-disable-next-line no-console
          console.log(
            'isInvalidPossibleSegment: invalid because polygon (outline) encloses roofs from different buildings'
          );
        }
        return true;
      }

      if (
        this.wipObject
        && this.isWipObjectNotEnclosedByParcel(end, parcel)
      ) {
        if (DEBUG) {
          // eslint-disable-next-line no-console
          console.log('isInvalidPossibleSegment: invalid because polygon is not enclosed by parcel boundary');
        }
        return true;
      }
    }

    if (isOutline) {
      for (const outline of outlines) {
        if (outline.boundary.closed) {
          const outlineVertices = outline.getVector3s();
          const areSomeVerticesCompletelyInsideAnOutline = wipVerticesWithNewest.some(
            (v: Vector3): boolean =>
              !pointOnPolygonEdge(outlineVertices, v)
              && pointInsidePolygon(outlineVertices, v, {
                onEdgeConsideredInside: false,
                noErrorMargin: true
              })
          );
          if (areSomeVerticesCompletelyInsideAnOutline) {
            this.outlineContainsAnother = true;
            if (DEBUG) {
              // eslint-disable-next-line no-console
              console.log(
                'isInvalidPossibleSegment: invalid because some of this outline\'s vertices are'
                  + ' inside an another outline'
              );
            }
            return true;
          }
          if (wipVertices.length > 0) {
            // outlines shouldn't contain other outlines
            if (
              isPolygonEnclosingOtherPolygon(wipVerticesWithNewest, outlineVertices, true)
              || isPolygonEnclosingOtherPolygon(outlineVertices, wipVerticesWithNewest, true)
            ) {
              this.outlineContainsAnother = true;
              if (DEBUG) {
                // eslint-disable-next-line no-console
                console.log('isInvalidPossibleSegment: invalid because this outline encloses another or vice versa');
              }
              return true;
            }
          }
        }
      }

      for (const roof of roofFaces) {
        const roofVertices = roof.getVector3s();
        const areSomeVerticesCompletelyInsideARoofFace = wipVerticesWithNewest.some(
          (v: Vector3): boolean =>
            !pointOnPolygonEdge(roofVertices, v)
            && pointInsidePolygon(roofVertices, v, {
              onEdgeConsideredInside: false,
              noErrorMargin: true
            })
        );
        if (areSomeVerticesCompletelyInsideARoofFace) {
          if (DEBUG) {
            // eslint-disable-next-line no-console
            console.log(
              'isInvalidPossibleSegment: invalid because some vertices of this outline are inside another '
                + 'building\'s roof'
            );
          }
          return true;
        }
      }

      if (this.wipObject!.boundary.segments.length > 1) {
        if (!this.isOutlineEnclosingAllSameRoofs(end, roofFaces)) {
          if (DEBUG) {
            // eslint-disable-next-line no-console
            console.log(
              'isInvalidPossibleSegment: invalid because this outline enclosed roofs from different buildings'
            );
          }
          return true;
        }
      }
    } else if (isRoofFace) {
      for (const roof of roofFaces) {
        if (wipVertices.length > 0) {
          const roofVertices = roof.getVector3s();

          if (roof.boundary.closed) {
            const areSomeVerticesCompletelyInside = wipVerticesWithNewest.some(
              (v: Vector3): boolean =>
                !pointOnPolygonEdge(roofVertices, v)
                && pointInsidePolygon(roofVertices, v, {
                  onEdgeConsideredInside: false,
                  noErrorMargin: true
                })
            );
            if (areSomeVerticesCompletelyInside) {
              if (DEBUG) {
                // eslint-disable-next-line no-console
                console.log(
                  'isInvalidPossibleSegment: invalid because some of this roof\'s vertices are inside another roof'
                );
              }
              return true;
            }
            // RoofFaces shouldn't contain other rooffaces at all
            if (
              isPolygonEnclosingOtherPolygon(wipVerticesWithNewest, roofVertices, true)
              || isPolygonEnclosingOtherPolygon(roofVertices, wipVerticesWithNewest, true)
            ) {
              if (DEBUG) {
                // eslint-disable-next-line no-console
                console.log(
                  'isInvalidPossibleSegment: invalid because this roof encloses another roof or vice versa'
                );
              }
              return true;
            }
          }
        }
      }

      for (const outline of outlines) {
        // Check PrevLine passing through Other Outlines
        // Checking if RoofFace is enclosing other closed Outline
        if (wipVertices.length > 0) {
          const outlineVertices = outline.getVector3s();

          // RoofFaces shouldn't contain outlines at all
          if (
            // The case where outline is "deep" inside a roof, not on an edge:
            isPolygonEnclosingOtherPolygon(wipVerticesWithNewest, outlineVertices, false)
            // The case where a part of outline is inside a roof, while the rest may lie on an edge:
            || !this.isRoofClosingValidToOutline(end, [...wipVertices, end], outlineVertices)
          ) {
            if (DEBUG) {
              // eslint-disable-next-line no-console
              console.log('isInvalidPossibleSegment: invalid because this roof encloses an outline');
            }
            return true;
          }

          const areSomeVerticesCompletelyInside = wipVerticesWithNewest.some(
            (v: Vector3): boolean =>
              !pointOnPolygonEdge(outlineVertices, v)
              && pointInsidePolygon(outlineVertices, v, {
                onEdgeConsideredInside: false,
                noErrorMargin: true
              })
          );

          if (areSomeVerticesCompletelyInside) {
            const areAllVerticesInside = wipVerticesWithNewest.every((v: Vector3): boolean =>
              pointInsidePolygon(outlineVertices, v)
            );

            if (!areAllVerticesInside) {
              if (DEBUG) {
                // eslint-disable-next-line no-console
                console.log('isInvalidPossibleSegment: invalid because this roof is enclosed by multiple outlines');
              }
              return true;
            }
          }
        }
      }
      const areSomeWipVerticesCompletelyInsideAnExistingRoofFace = roofFaces
        .filter((roofFace: RoofFace): boolean => roofFace.boundary.closed)
        .map((roofFace: RoofFace): Vector3[] => roofFace.getVector3s())
        .some((existingRoofFaceVertices: Vector3[]) =>
          wipVerticesWithNewest.some(
            (wipVertex: Vector3): boolean =>
              !pointOnPolygonEdge(existingRoofFaceVertices, wipVertex)
              && pointInsidePolygon(existingRoofFaceVertices, wipVertex, {
                onEdgeConsideredInside: false,
                noErrorMargin: true
              })
          )
        );
      if (areSomeWipVerticesCompletelyInsideAnExistingRoofFace) {
        if (DEBUG) {
          // eslint-disable-next-line no-console
          console.log(
            'isInvalidPossibleSegment: invalid because some of this roof\'s vertices are inside another roof'
          );
        }
        return true;
      }
    }

    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log('isInvalidPossibleSegment: valid');
    }

    return false;
  }

  private isRoofClosingValidToOutline(
    lastRoofVertex: Vector3,
    roofVertices: Vector3[],
    outlineVertices: Vector3[]
  ): boolean {
    if (!this.shouldClosePolygon(lastRoofVertex)) {
      return true;
    }

    if (roofVertices.length < 3) {
      return true;
    }

    const areaDiff = getPolygonAreaDifference(roofVertices, outlineVertices);
    // overlap is present and outline - roof is > 0
    return areaDiff <= 0.01 || areaDiff >= calculateVector3to2PolygonArea(roofVertices) - 0.01;
  }

  private isWipObjectEnclosingDifferentBuildings(
    prevLineEnd: Vector3,
    roofFaces: RoofFace[],
    outlines: Outline[]
  ): boolean {
    const objectToCheck = this.wipObject!.boundary.segments.length
      ? this.wipObject!.boundary.segments[0].points[0].getVector3()
      : prevLineEnd;
    const distance = prevLineEnd.distanceTo(objectToCheck);
    if (distance > canvasConfig.smartSnapNearThreshold) {
      return false;
    }

    const wipVertices = this.wipObject!.getVector3s();

    const insideRoofs: RoofFace[] = [];
    const insideOutlines: Outline[] = [];
    const closedRoofFaces = roofFaces.filter((r: RoofFace): boolean => r.boundary.closed);
    const closedOutlines = outlines.filter((outline: Outline): boolean => outline.boundary.closed);
    for (const roofOrOutline of [...closedRoofFaces, ...closedOutlines]) {
      const inside: boolean =
        this.wipObject!.boundary.segments.length > 1
          ? isPolygonInsideAnother(roofOrOutline.boundary.vector3s, wipVertices, this.wipObject instanceof Parcel)
          : pointInsidePolygon(roofOrOutline.boundary.vector3s, prevLineEnd);
      if (inside) {
        if (this.wipObject instanceof RoofFace && roofOrOutline instanceof RoofFace) {
          // RoofFace shouldn't be able to enclose other rooffaces at all
          return true;
        }
        if (roofOrOutline instanceof Outline) {
          insideOutlines.push(roofOrOutline);
        } else {
          insideRoofs.push(roofOrOutline);
        }
      }
    }

    return (
      this.checkIfOutlineEnclosesDifferentBuildings(insideRoofs)
      || this.checkIfParcelEnclosesNotAllBuildings(insideRoofs, roofFaces, insideOutlines, outlines)
    );
  }

  private checkIfParcelEnclosesNotAllBuildings(
    insideRoofs: RoofFace[],
    roofFaces: RoofFace[],
    insideOutlines: Outline[],
    outlines: Outline[]
  ): boolean {
    if (this.wipObject instanceof Parcel) {
      if (this.wipObject.boundary.segments.length > 1) {
        this.parcelEnclosesNotAllBuildings =
          insideRoofs.length !== roofFaces.length || insideOutlines.length !== outlines.length;
      } else {
        this.parcelEnclosesNotAllBuildings = !!insideRoofs.length || !!insideOutlines.length;
      }
    }
    return this.parcelEnclosesNotAllBuildings;
  }

  private checkIfOutlineEnclosesDifferentBuildings(insideRoofs: RoofFace[]): boolean {
    if (this.wipObject instanceof Outline) {
      let firstRoofsBuildingName: string = '';

      for (const roof of insideRoofs) {
        const building: Building | undefined = this.domain.findBuildingByChild(roof);
        if (building?.name) {
          if (!firstRoofsBuildingName) {
            firstRoofsBuildingName = building.name;
          } else if (firstRoofsBuildingName !== building.name) {
            this.invalidRoofClosing = true;
            return true;
          }
        }
      }
    }
    return false;
  }

  private isWipObjectNotEnclosedByParcel(cursorPosition: Vector3, parcel?: Parcel): boolean {
    const parcelVertices = parcel?.boundary.vector3s ?? [];
    if (!parcelVertices.length || this.wipObject instanceof Parcel) {
      return false;
    }

    const isInvalidWipObject =
      this.wipObject!.boundary.vector3s.length > 2
        ? !isPolygonInsideAnother(
            this.wipObject!.boundary.vector3s,
            parcelVertices,
            !(this.wipObject instanceof Parcel)
        )
        : !pointInsidePolygon(parcelVertices, cursorPosition);

    if (isInvalidWipObject) {
      this.parcelEnclosesNotAllBuildings = true;
      return true;
    }

    return false;
  }

  private isOutlineEnclosingAllSameRoofs(prevLineEnd: Vector3, roofFaces: RoofFace[]): boolean {
    if (
      prevLineEnd.distanceTo(this.wipObject!.boundary.segments[0].points[0].getVector3()) > 5
      && prevLineEnd.distanceTo(this.wipObject!.boundary.segments[0].points[1].getVector3()) > 5
    ) {
      return true;
    }

    const wipVertices = this.wipObject!.getVector3s();

    const insideRoofs: RoofFace[] = [];
    for (const roof of roofFaces) {
      let inside: boolean = true;
      for (const segment of roof.boundary.segments) {
        const firstVertexStatus = pointInsidePolygon(wipVertices, segment.points[0].getVector3());
        const secondVertexStatus = pointInsidePolygon(wipVertices, segment.points[1].getVector3());

        if (!firstVertexStatus || !secondVertexStatus) {
          inside = false;
        }
      }
      if (inside) {
        insideRoofs.push(roof);
      }
    }

    const insideRoofNames: string[] = [];
    for (const roof of insideRoofs) {
      const building: Building | undefined = this.domain.findBuildingByChild(roof);
      if (building) {
        insideRoofNames.push(building.name);
      }
    }

    const insideBuildingName = insideRoofNames[0];
    let buildingNameGlobalCount = 0;
    for (const roof of roofFaces) {
      const building: Building | undefined = this.domain.findBuildingByChild(roof);
      if (insideBuildingName === building?.name) {
        buildingNameGlobalCount++;
      }
    }

    let enclosed = true;
    if (insideRoofNames.length < buildingNameGlobalCount) {
      enclosed = false;
    }

    this.enclosedAllSameRoofs = enclosed;
    this.allSameRoofsBuildingName = insideBuildingName;
    this.roofGroupBuildingName = '';
    if (insideRoofNames.length > 0) {
      this.roofGroupBuildingName = String(insideRoofNames[0]);
    }
    return enclosed;
  }

  private getRoofsWithoutOutlines(): RoofFace[] {
    const lonelyRoofs: RoofFace[] = [];
    const roofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace);
    const outlines: Outline[] = this.editor.getObjectsByType(SceneObjectType.Outline);

    for (const roof of roofFaces) {
      let outside: boolean = true;
      for (const outline of outlines) {
        if (pointInsidePolygon(outline.getVector3s(), roof.boundary.segments[0].points[0].getVector3())) {
          outside = false;
        }
      }
      if (outside) {
        lonelyRoofs.push(roof);
      }
    }

    return lonelyRoofs;
  }

  private setAvailableBuildings(): void {
    this.availableBuildings = [];
    const lonelyRoofs = this.getRoofsWithoutOutlines();
    for (const roof of lonelyRoofs) {
      const building = this.domain.findBuildingByChild(roof);
      if (building && !this.availableBuildings.includes(building)) {
        this.availableBuildings.push(building);
      }
    }
  }
}
