import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { RoofStory } from '../../../domain/models/SiteDesign/RoofStory';
import type { Building } from '../../../domain/models/SiteDesign/Building';
import { mergeByProperty } from '../../../utils/helpers';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateBuildingVisibilityDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly building: Building;
  readonly visible: boolean;
}

export class UpdateBuildingVisibilityCommand extends BaseCommand {
  private readonly building: Building;
  private readonly domain: DomainStore;
  private readonly visible: boolean;

  constructor(dependencies: IUpdateBuildingVisibilityDependencies) {
    super();
    const {
      building, domain, visible
    } = dependencies;
    this.building = building;
    this.domain = domain;
    this.visible = visible;
  }

  async execute(): Promise<void> {
    const building = this.building;
    building.visible = this.visible;
    if (building.roofOutline) {
      building.roofOutline.mesh.visible = this.visible;
    }

    building.stories.forEach((roofStory: RoofStory): void => {
      roofStory.roofFaces.forEach((roofFace: RoofFace): void => {
        roofFace.mesh.visible = this.visible;
        mergeByProperty<RoofFace>(roofStory.roofFaces, [roofFace], 'id');
      });
    });
    this.domain.addOrUpdateBuilding(building);
  }
}
