import styled from 'styled-components';
import type { PropsTheme } from 'styled-components';
import type { Color } from '../../../../../domain/typings';
import Field from '../../../../../ui/components/Field';
import Flexbox from '../../../../../ui/components/Layout/Flexbox';

export const SlopeButtonsList = styled(Flexbox).attrs({
  dir: 'row',
  align: 'center',
  justify: 'space-between',
  wrap: 'wrap'
})`
  margin-top: 15px;
  row-gap: 10px;

  @media (min-width: 2560px) {
    justify-content: unset;
    column-gap: 13px;
  }

  fieldset:first-of-type {
    text-align: end;
  }
`;

export const CustomSlopeFieldSet = styled(Field)<PropsTheme>`
  margin-top: 15px;
  padding-top: 15px;
  border-top: ${({ theme }: PropsTheme): Color => `1px solid ${theme.colors.lineHr}`};
  div {
    font-size: 10px;
    display: flex;
    margin-bottom: 0;
  }
`;
