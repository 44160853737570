import Registry from '../../../utils/Registry';
import ArrayPlacement from '../ArrayPlacement/ArrayPlacement';
import SiteStructurePanel from '../SiteStructurePanel/SiteStructurePanel';
import { ElectricalBos } from '../ElectricalBos/ElectricalBos';
import { ElectricalDesign } from '../ElectricalDesign/ElectricalDesign';
import HintPanel from '../HintPanel';
import { LayoutDesign } from '../LayoutDesign/LayoutDesign';
import { MountingBos } from '../MountingBos/MountingBos';
import RoofProperties from '../RoofFaceProperties/RoofProperties';
import RoofProtrusions from '../RoofProtrusions/RoofProtrusions';
import SetbackPathwayProps from '../SetbackPathwayProps/SetbackPathwayProps';
import GasMeter from '../SiteEquipmentPanel/GasMeter';
import ServiceEntranceEquipmentLoadCenterPanel from '../SiteEquipmentPanel/ServiceEntranceEquipmentLoadCenterPanel';
import StreetLocation from '../SiteEquipmentPanel/StreetLocation';
import Subpanel from '../SiteEquipmentPanel/Subpanel';
import UtilityMeter from '../SiteEquipmentPanel/UtilityMeter';
import ServiceEntranceEquipmentOptions from '../SiteEquipmentPanel/ServiceEntranceEquipmentOptions';
import { CustomBaseImageryTransformationPanel } from '../CustomBaseImageryTransformation/CustomBaseImageryTransformationPanel';
import { ScaleToDefineScalingPanel } from '../CustomBaseImageryTransformation/ScaleToDefineScalingPanel';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import {
  SceneObjectType, PropsPanelHintsForTools, HintObjectType
} from '../../../domain/models/Constants';
import ServiceEntranceEquipmentFormsContainer from '../SiteEquipmentPanel/ServiceEntranceEquipmentFormsContainer';

const panelRegistry = new Registry();

panelRegistry.add(PropsPanelUICodes.RoofProperties, RoofProperties);
panelRegistry.add(PropsPanelUICodes.HintPanelTraceIndividualRoofFace, HintPanel(SceneObjectType.RoofFace));
panelRegistry.add(PropsPanelUICodes.HintPanelOutline, HintPanel(SceneObjectType.Outline));
panelRegistry.add(PropsPanelUICodes.HintPanelParcelBoundary, HintPanel(SceneObjectType.ParcelBoundary));
panelRegistry.add(PropsPanelUICodes.HintPanelSiteMarkerSubpanel, HintPanel(HintObjectType.Subpanel));
panelRegistry.add(PropsPanelUICodes.HintPanelSiteMarkerGasMeter, HintPanel(HintObjectType.GasMeter));
panelRegistry.add(
  PropsPanelUICodes.HintPanelServiceEntranceEquipment,
  HintPanel(HintObjectType.ServiceEntranceEquipment)
);
panelRegistry.add(PropsPanelUICodes.HintPanelSiteMarkerStreetLocation, HintPanel(HintObjectType.Street));

panelRegistry.add(PropsPanelUICodes.HintPanelOutlineTool, HintPanel(PropsPanelHintsForTools.OutlineTool));
panelRegistry.add(PropsPanelUICodes.HintPanelParcelBoundaryTool, HintPanel(PropsPanelHintsForTools.ParcelBoundaryTool));

panelRegistry.add(PropsPanelUICodes.ServiceEntranceEquipmentOptions, ServiceEntranceEquipmentOptions);
panelRegistry.add(PropsPanelUICodes.ServiceEntranceEquipmentForm, ServiceEntranceEquipmentFormsContainer);

panelRegistry.add(PropsPanelUICodes.SiteStructure, SiteStructurePanel);
panelRegistry.add(PropsPanelUICodes.MainServicePanel, ServiceEntranceEquipmentLoadCenterPanel);
panelRegistry.add(PropsPanelUICodes.Subpanel, Subpanel);
panelRegistry.add(PropsPanelUICodes.UtilityMeter, UtilityMeter);
panelRegistry.add(PropsPanelUICodes.GasMeter, GasMeter);
panelRegistry.add(PropsPanelUICodes.StreetLocation, StreetLocation);
panelRegistry.add(PropsPanelUICodes.RoofProtrusions, RoofProtrusions);
panelRegistry.add(PropsPanelUICodes.ArrayPlacement, ArrayPlacement);
panelRegistry.add(PropsPanelUICodes.LayoutDesign, LayoutDesign);
panelRegistry.add(PropsPanelUICodes.ElectricalDesign, ElectricalDesign);
panelRegistry.add(PropsPanelUICodes.ElectricalBos, ElectricalBos);
panelRegistry.add(PropsPanelUICodes.MountingBos, MountingBos);
panelRegistry.add(PropsPanelUICodes.SetbackPathwayProps, SetbackPathwayProps);
panelRegistry.add(PropsPanelUICodes.CustomBaseImageryTransformation, CustomBaseImageryTransformationPanel);
panelRegistry.add(PropsPanelUICodes.ScaleToDefineScaling, ScaleToDefineScalingPanel);

export { panelRegistry };
