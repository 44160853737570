import {
  observer, useObserver
} from 'mobx-react-lite';
import type {
  EffectCallback, ReactElement
} from 'react';
import React, {
  useCallback, useEffect
} from 'react';
import type { Vector3 } from 'three';
import type { Vertex } from '../../../../../domain/graphics/Vertex';
import { useUiStore } from '../../../../../stores/useStore';
import type { AzimuthRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/AzimuthRoofFaceViewModel';
import Compass from '../../../../../ui/components/Compass/Compass';
import { getVisualCenter } from '../../../../../utils/spatial';
import { LABEL_AZIMUTH } from '../../constants';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';

const AzimuthCompass = (): ReactElement => {
  const {
    roofFaceProps, properties
  } = useUiStore();
  const viewModel = roofFaceProps.currentRoofPropertyEditor as AzimuthRoofFaceViewModel;

  useEffect((): ReturnType<EffectCallback> => {
    properties.setTitle(LABEL_AZIMUTH);
    const roofFace = viewModel.roofFace;
    const location = getVisualCenter(roofFace.polygon.map((vertex: Vertex): Vector3 => vertex.getVector3()));
    const size = roofFace.boundary.getLargestSegment();
    viewModel.setCompassInScene(viewModel.currentValue as number, location.x, location.y, size);

    return (): void => {
      viewModel.removeCompassInScene();
    };
  }, [properties, viewModel]);

  const onChange = useCallback(
    (degree: number): void => {
      viewModel.changeValue(degree);
    },
    [viewModel]
  );

  return useObserver(
    (): ReactElement => (
      <RoofPropertiesParent viewModel={viewModel}>
        <Compass angle={viewModel.currentValue as number} onChange={onChange} snaps={viewModel.polygonAzimuth()} />
      </RoofPropertiesParent>
    )
  );
};

export default observer(AzimuthCompass);
