import type { Vector3 } from 'three';
import type { Design } from '../../../domain/models/Design/Design';
import type { IStage } from '../../../domain/stages/IStage';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Vertex } from '../../../domain/graphics/Vertex';
import { getParentLyraModelByMeshOrLyraModel } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import type PvModulePosition from '../../../domain/models/SiteDesign/PvModulePosition';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import { PvModuleCommand } from './PvModuleCommand';

export interface IAddPvModulesInstancesDependencies extends IBaseCommandDependencies {
  readonly currentStage: IStage;
  readonly PvModulePositions: PvModulePosition[];
  readonly domain: DomainStore;
}

export class AddPvModulePositions extends PvModuleCommand {
  private readonly pvModulePositions: PvModulePosition[];
  private readonly design: Design;

  constructor(dependencies: IAddPvModulesInstancesDependencies) {
    super();
    this.pvModulePositions = dependencies.PvModulePositions;
    this.design = dependencies.domain.design;
  }

  async execute(): Promise<void> {
    this.pvModulePositions.forEach((pvModulePosition: PvModulePosition): void => {
      // Cloning because objects passed by reference may be changed before data is submitted to the back-end
      const polygon = pvModulePosition.polygon.slice(0, -1).map((item: Vertex): Vector3 => item.mesh.position.clone());
      this.design.roofTopArrayAreas.addPvModulePosition(
        getParentLyraModelByMeshOrLyraModel<RoofFace>(pvModulePosition)!.serverId,
        pvModulePosition.serverId,
        pvModulePosition.orientation,
        polygon
      );
    });
  }
}
