import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useUiStore } from '../../../stores/useStore';
import { pagesRegistry } from './PagesRegistry';

const LoaderCenterer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 300px);
`;
const PagesWrapper = styled.div`
  min-height: calc(100% - 64px);
  align-self: flex-start;
`;

const Pages: React.FC = (): ReactElement => {
  const { pages } = useUiStore();
  const DS = React.useContext(ThemeContext)!.DS;
  // Page to be rendered
  const Page = pages.pageId && pagesRegistry.get(pages.pageId);
  return (
    <PagesWrapper>
      {pages.isLoading && Page && (
        <LoaderCenterer>
          <DS.Loader size={60} />
        </LoaderCenterer>
      )}
      {!pages.isLoading && Page && <Page />}
    </PagesWrapper>
  );
};

const ObservedPages = observer(Pages);

export { ObservedPages as Pages };
