import type {
  Object3D, OrthographicCamera, PerspectiveCamera, Vector3
} from 'three';
import { Vector2 } from 'three';
import { ViewPort } from '../../../stores/EditorStore/ViewportController';
import { ThreeUtils } from '../../../utils/ThreeUtils';
import { distanceBetween2Points } from '../../../utils/spatial';
import type { StringingLine } from './StringingLine';

type SlopedLine = { start: Vector3; end: Vector3; slope: number };
type LineWithLength = { start: Vector3; end: Vector3; length: number };

export class StringLabelController {
  private stringLabelElement!: HTMLElement;
  private stringLabelPosition!: Vector2;
  private stringLabel!: string;
  stringLabelExists: boolean = false;

  constructor() {
    Object.assign(this, {
      stringLabelElement: document.createElement('div'),
      stringLabelPosition: new Vector2(0, 0),
      stringLabel: '',
      stringLabelExists: false
    });

    this.setHtmlElementsStyle();
  }

  private get canvas(): HTMLElement {
    // Note: _editor is not available in unit tests
    return ViewPort._editor?.rendererDom;
  }

  private get camera(): OrthographicCamera | PerspectiveCamera {
    return ViewPort._editor.activeCamera!;
  }

  private setHtmlElementsStyle(): void {
    const { style: stringLabelStyle } = this.stringLabelElement;
    stringLabelStyle.position = 'absolute';
    stringLabelStyle.top = '0';
    stringLabelStyle.left = '0';
    stringLabelStyle.color = '#e7ab39';
    stringLabelStyle.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    stringLabelStyle.borderRadius = '4px';
    stringLabelStyle.padding = '8px';
    stringLabelStyle.pointerEvents = 'none';
    stringLabelStyle.display = 'flex';
    stringLabelStyle.justifyContent = 'center';
    stringLabelStyle.alignItems = 'center';
  }

  private getMidpointOfLongestStringSegment(slopedLines: SlopedLine[]): Vector2 {
    const groupedLines: SlopedLine[] = [];

    groupedLines.push(slopedLines[0]);

    slopedLines.forEach((slopedLine: SlopedLine): void => {
      if (groupedLines[groupedLines.length - 1].slope !== slopedLine.slope) {
        groupedLines.push(slopedLine);
      } else {
        groupedLines[groupedLines.length - 1].end = slopedLine.end;
      }
    });

    const groupedDistancedLines: LineWithLength[] = [];
    groupedLines.forEach((line: SlopedLine): void => {
      groupedDistancedLines.push({
        start: line.start,
        end: line.end,
        length: distanceBetween2Points(line.start, line.end)
      });
    });

    const biggestLine = groupedDistancedLines.reduce((first: LineWithLength, next: LineWithLength): LineWithLength => {
      return first.length > next.length ? first : next;
    });
    return new Vector2((biggestLine.start.x + biggestLine.end.x) / 2, (biggestLine.start.y + biggestLine.end.y) / 2);
  }

  private setStringingLabelPosition(startStringing: Object3D, stringingLazzo: StringingLine[]): void {
    const slopedLines: SlopedLine[] = [];
    stringingLazzo.forEach((lazzo: StringingLine): void => {
      slopedLines.push({
        start: lazzo.start,
        end: lazzo.end,
        slope: parseInt((((lazzo.end.y - lazzo.start.y) * 10) / (lazzo.end.x - lazzo.start.x)).toString(), 10) / 10
      });
    });

    this.stringLabelPosition = !slopedLines[0]
      ? new Vector2(startStringing.position.x, startStringing.position.y)
      : this.getMidpointOfLongestStringSegment(slopedLines);
  }

  draw(startStringing: Object3D, stringingLazzo: StringingLine[]): void {
    this.setStringingLabelPosition(startStringing, stringingLazzo);

    if (!this.canvas || !this.stringLabel) {
      // Canvas is not available in unit tests
      return;
    }

    const domCoords = ThreeUtils.worldCoordinatesToDomCoordinates(
      this.stringLabelPosition.x,
      this.stringLabelPosition.y,
      this.canvas,
      this.camera
    );

    this.stringLabelElement.style.transform = `translateX(${domCoords.x}px) translateX(-50%) translateY(${domCoords.y}px) translateY(-50%)`;
    this.stringLabelElement.style.display = 'flex';
    this.stringLabelElement.textContent = this.stringLabel;
    if (!this.stringLabelExists) {
      this.canvas.parentElement?.appendChild(this.stringLabelElement);
      this.stringLabelExists = true;
    }
  }

  setStringLabel(stringLabel: string): void {
    this.stringLabel = stringLabel;
  }

  remove(): void {
    if (this.stringLabelExists) {
      if (this.canvas?.parentElement?.contains(this.stringLabelElement)) {
        this.canvas?.parentElement?.removeChild(this.stringLabelElement);
      }
      this.stringLabelExists = false;
    }
  }
}
