import type {
  BaseProps, ReactElement
} from 'react';
import React, { useCallback } from 'react';
import type { InverterType } from '../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import { isInverterOutsideInputVoltageRange } from '../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import {
  Description, InverterCardStyle, ItemStatus, PillItem, PillsWrapper, Title, TitleWrapper
} from './styles';

type Props = BaseProps & {
  inverterId: string;
  value: string;
  title: string | ReactElement[];
  pills: string[] | ReactElement[];
  item: InverterType;
  description?: string;
  onSelected?: (value: string, id: string) => void;
  onMouseOver?: (item: InverterType) => void;
};

const InverterCard = (props: Props): ReactElement => {
  const {
    description, inverterId, onSelected, onMouseOver, pills, title, value, item
  } = props;
  const handleClick = useCallback((): void => {
    onSelected?.(value, inverterId);
  }, [value, inverterId, onSelected]);

  const handleMouseOver = useCallback((): void => {
    onMouseOver?.(item);
  }, [item, onMouseOver]);

  const card = (pillArr: string[] | ReactElement[]): ReactElement[] => {
    const result: ReactElement[] = [];
    pillArr.forEach((label: string | ReactElement, index: number): void => {
      if (label) {
        result.push(<PillItem key={index}>{label}</PillItem>);
      }
    });
    return result;
  };

  return (
    <InverterCardStyle onClick={handleClick} onMouseOver={handleMouseOver}>
      <TitleWrapper>
        <Title>{title}</Title>
        {isInverterOutsideInputVoltageRange(item) && <ItemStatus className="not-compatible">Not compatible</ItemStatus>}
      </TitleWrapper>
      <PillsWrapper>{card(pills)}</PillsWrapper>
      {description && <Description>{description}</Description>}
    </InverterCardStyle>
  );
};

export default InverterCard;
