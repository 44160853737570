import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import {
  LeftView, LogoView, MainText, RadioWrapper, RightView, StatusText
} from '../styles';

const { Toggle } = LyraFormElementsDesignTool.FormElementsDesignTool;

interface IToggleRowProps {
  id: string;
  name: string;

  title: string;
  leftValue: string;
  rightValue: string;
  checked: boolean;

  onChange?: (value: boolean) => void;
}

const ToggleRow: React.FC<IToggleRowProps> = (props: IToggleRowProps): ReactElement => {
  const {
    title, leftValue, rightValue, checked, id, name, onChange
  } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.currentTarget.checked;
      onChange?.(value);
    },
    [onChange]
  );

  const toggleProps = {
    checked,
    id,
    name
  };

  return (
    <LogoView height="46px">
      <LeftView>
        <MainText>{title}</MainText>
      </LeftView>

      <RightView>
        <RadioWrapper>
          <StatusText status={!checked}>{leftValue}</StatusText>

          <Toggle.Toggle className="ml-xxs mr-xxs" {...toggleProps} onChange={handleChange} />

          <StatusText status={checked}>{rightValue}</StatusText>
        </RadioWrapper>
      </RightView>
    </LogoView>
  );
};

export default ToggleRow;
