import styled, { PropsTheme } from 'styled-components';
import { Flexbox } from '../Layout/Flexbox';
import { Color } from '../../typings';
import { Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon/Icon';

export const AccordionWrapper = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;

export const AccordionHeader = styled(Flexbox).attrs({
  align: 'center'
})`
  width: 100%;
  height: 30px;
  position: relative;
  cursor: pointer;
`;

export const AccordionName = styled(Paragraph)`
  padding-left: 11px;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

export const ArrowIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`;

export const AccordionContent = styled.div`
  min-height: 50px;
`;

export const DescriptionView = styled.div`
  padding: 11px;
`;

export const DescriptionTitle = styled(Paragraph)`
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const ValueView = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const ValueLabel = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  font-size: 10px;
`;

export const ValueText = styled(ValueLabel)`
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
`;

export const DescriptionBoldText = styled(Paragraph)`
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const DescriptionLightText = styled(Paragraph)`
  font-size: 11px;
  font-weight: 300;
  line-height: 1.27;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;
