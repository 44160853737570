import type Store from '../../../../../stores/Store';
import { SelectionTool } from '../../../../../stores/UiStore/ToolbarStore/Design/SelectionTool';
import type { ISelectionToolDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/SelectionTool';
import withTool from '../../Shared/withTool';

type SelectionToolDependencies = Pick<ISelectionToolDependencies, 'properties' | 'domain' | 'serviceBus' | 'workspace'>;
const Selection = withTool<SelectionTool, ISelectionToolDependencies, SelectionToolDependencies>(
  SelectionTool,
  ({
    properties, domain, serviceBus, workspace
  }: Store): SelectionToolDependencies => ({
    properties,
    domain,
    serviceBus,
    workspace
  })
);

export default Selection;
