import { LyraButtons } from '@aurorasolar/lyra-ui-kit';
import type { FieldTemplateProps } from '@rjsf/utils';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

/**
 * This is a field template that customizes the layout of regular form fields.
 *
 * Reference: https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#fieldtemplate
 *
 * Modifications:
 * - Do not render '*' next to required fields
 * - Render tooltip button if `rawHelp` is provided
 * - Title gets duplicated when dealing with nested fields -- don't render title if field type is object
 * - Do not render custom field if there's no UI schema to render
 * - Do not render description for every field -- only group title (if group attributes exist)
 */
function CustomFieldTemplate(props: FieldTemplateProps): JSX.Element | null {
  const {
    id,
    classNames,
    label,
    rawHelp,
    errors,
    children,
    uiSchema
  } = props;

  const isNotObjectField = !classNames?.includes('field-object');

  return !isEmpty(uiSchema) ? (
    <div className={classNames}>
      <div className="input-top">
        {isNotObjectField && <label htmlFor={id}>{label}</label>}
      </div>
      {rawHelp && (
        <LyraButtons.ButtonIcon
          icon="tooltip"
          tooltip={rawHelp}
          tooltipContentAlign="left"
          showTooltipOnClick
          className="help-button"
          stickToTopRight
        />
      )}
      {children}
      {errors}
    </div>
  ) : null;
}

export default CustomFieldTemplate;
