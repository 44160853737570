import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type { ReactText } from 'react';
import type React from 'react';
import { Parcel } from '../../../../../domain/models/SiteDesign/Parcel';
import { EProjectPropertyOption } from '../../../../../domain/typings';
import type DomainStore from '../../../../DomainStore/DomainStore';
import {
  handleApiError,
  searchByNumericValueFromDropdown,
  searchValueFromDropdown
} from '../../../../../utils/helpers';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { IBaseViewModelDependencies } from '../../BaseViewModel';
import { BaseViewModel } from '../../BaseViewModel';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type { IDesignParametersData } from '../../../../../domain/models/SiteDesign/DesignParameters';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../../domain/behaviour/KeyboardBehaviour';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import config, { UI_MODE } from '../../../../../config/config';
import { importProjectAndDesignIntoDocumentService } from '../../../../../domain/stages/DesignStages/interMicroServiceHelpers';

const defaultSelect: string = '-- Select --';
const emptySelectionOption: IDropDownOption = {
  value: '',
  label: ''
};

export class ProjectPropertiesViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI: string = 'project_properties_modal';
  override readonly domain!: DomainStore;
  override readonly editor: EditorStore;
  readonly siteParameters = this.domain.project.site;
  readonly designParameters = this.domain.project.designParameters;

  @observable
  projectPropertyTabSelected: EProjectPropertyOption = EProjectPropertyOption.SITE;

  @observable
  projectDescription: string = '';

  @observable
  siteGeneralProperties = {
    assessorsParcelNumber: '',
    lseSelectedOptionValue: '',
    ahjSelectedOptionValue: '',
    zoning: ''
  };

  @observable
  lseOptions: IDropDownOption[] = [];
  @observable
  ahjOptions: IDropDownOption[] = [];

  @observable
  private locallyAdoptedCodes = {
    electricalCode: this.designParameters.codesAndStandards.electricalCode || defaultSelect,
    fireCode: this.designParameters.codesAndStandards.fireCode || defaultSelect
  };

  @observable
  electricalCodeOptions = observable<IDropDownOption>([]);
  @observable
  fireCodeOptions = observable<IDropDownOption>([]);
  @observable
  additionalCodes: string[] = [];

  constructor(dependencies: IBaseViewModelDependencies) {
    super(dependencies);
    this.projectDescription = this.domain.getProjectDescription;
    this.editor = dependencies.editor;

    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @computed
  get selectedAhjOption(): IDropDownOption {
    return (
      this.ahjOptions.find(
        (ahjOption: IDropDownOption): boolean => ahjOption.value === this.siteGeneralProperties.ahjSelectedOptionValue
      ) ?? emptySelectionOption
    );
  }

  @computed
  get selectedLseOption(): IDropDownOption {
    return (
      this.lseOptions.find(
        (lseOption: IDropDownOption): boolean => lseOption.value === this.siteGeneralProperties.lseSelectedOptionValue
      ) ?? emptySelectionOption
    );
  }

  @computed
  get selectedElectricalCodeOption(): IDropDownOption {
    return (
      this.electricalCodeOptions.find(
        (electricalCode: IDropDownOption): boolean => electricalCode.value === this.locallyAdoptedCodes.electricalCode
      ) ?? emptySelectionOption
    );
  }

  @computed
  get selectedFireCodeOption(): IDropDownOption {
    return (
      this.fireCodeOptions.find(
        (fireCode: IDropDownOption): boolean => fireCode.value === this.locallyAdoptedCodes.fireCode
      ) ?? emptySelectionOption
    );
  }

  @action.bound
  handleChangeInputProjectDescription(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    this.projectDescription = event.target.value;
  }

  @action.bound
  changeInputTag(event: string[]): void {
    this.additionalCodes = event;
  }

  @action.bound
  changeApn(option: string): void {
    this.siteGeneralProperties.assessorsParcelNumber = option;
  }

  @action.bound
  changeLoadServingEntity(option: IDropDownOption): void {
    this.siteGeneralProperties.lseSelectedOptionValue = option.value;
  }

  @action.bound
  changeSelectedAuthorityHavingJurisdiction(option: IDropDownOption): void {
    this.siteGeneralProperties.ahjSelectedOptionValue = option.value;
  }

  @action.bound
  changeZoning(option: string): void {
    this.siteGeneralProperties.zoning = option;
  }

  @action.bound
  changeElectricalCode(option: IDropDownOption): void {
    this.locallyAdoptedCodes.electricalCode = option.value;
  }

  @action.bound
  changeFireCode(option: IDropDownOption): void {
    this.locallyAdoptedCodes.fireCode = option.value;
  }

  @action.bound
  handleClickTab(value: ReactText): void {
    this.projectPropertyTabSelected = value as EProjectPropertyOption;
  }

  loadSelectionOptions = (): void => {
    this.loadLoadServingEntityOptions();
    this.loadAuthorityHavingJurisdictionOptions();
    this.loadFireCodeOptions();
    this.loadElectricalCodeOptions();
  };

  private async loadFireCodeOptions(): Promise<void> {
    const state = this.domain.project.site.address.province;
    const ahjId = this.designParameters.authorityHavingJurisdiction;

    await this.designService
      .getFireCodeOptions(state, ahjId)
      .then((response: IOption<BaseAttributes>[]): void => {
        runInAction((): void => {
          const options = this.mapItemOptions(response);
          this.fireCodeOptions.replace(options);
          this.setInitialValues();
        });
      })
      .catch(handleApiError('Failed to fetch fire code options'));
  }

  private async loadElectricalCodeOptions(): Promise<void> {
    const state = this.domain.project.site.address.province;
    await this.designService
      .getElectricalCodeOptions(state)
      .then((response: IOption<BaseAttributes>[]): void => {
        runInAction((): void => {
          const options = this.mapItemOptions(response);
          this.electricalCodeOptions.replace(options);
          this.setInitialValues();
        });
      })
      .catch(handleApiError('Failed to fetch electrical code options'));
  }

  private async loadLoadServingEntityOptions(): Promise<void> {
    const province = this.domain.project.site.address.province;
    if (province) {
      this.loadServingEntitiesService
        .getLoadServingEntityOptions(province)
        .then((response: IOption<BaseAttributes>[]): void => {
          runInAction((): void => {
            this.lseOptions = this.mapItemOptions(response);
            this.setInitialValues();
          });
        })
        .catch(handleApiError('Failed to fetch utility company options'));
    }
  }

  private async loadAuthorityHavingJurisdictionOptions(): Promise<void> {
    const site = this.domain.project.site;
    const province: string = site.address.province;
    if (province) {
      this.authoritiesService
        .getAuthorityHavingJurisdictionOptions(
          province,
          site.coordinateSystemOrigin,
          this.domain.project.designParameters.authorityHavingJurisdiction
        )
        .then((response: IOption<BaseAttributes>[]): void => {
          runInAction((): void => {
            this.ahjOptions = this.mapItemOptions(response);
            this.setInitialValues();
          });
        })
        .catch(handleApiError('Failed to fetch authority having jurisdiction options data'));
    }
  }

  private setInitialValues(): void {
    // General properties
    this.siteGeneralProperties.assessorsParcelNumber = this.siteParameters.parcel.assessorsParcelNumber ?? '';
    this.siteGeneralProperties.zoning = this.siteParameters.parcel.zoning ?? '';

    if (this.designParameters.authorityHavingJurisdiction) {
      const optionToSelect = searchValueFromDropdown(
        this.ahjOptions,
        this.designParameters.authorityHavingJurisdiction
      );
      this.siteGeneralProperties.ahjSelectedOptionValue = (optionToSelect?.value as string) ?? '';
    }
    if (this.designParameters.loadServingEntity) {
      const optionToSelect = searchByNumericValueFromDropdown(this.lseOptions, this.designParameters.loadServingEntity);
      this.siteGeneralProperties.lseSelectedOptionValue = (optionToSelect?.value as string) ?? '';
    }

    // Locally adopted codes properties
    const {
      electricalCode, fireCode
    } = this.designParameters.codesAndStandards;
    if (electricalCode) {
      const optionToSelect = searchValueFromDropdown(this.electricalCodeOptions, electricalCode);
      this.locallyAdoptedCodes.electricalCode = optionToSelect?.value as string;
    }
    if (fireCode) {
      const optionToSelect = searchValueFromDropdown(this.fireCodeOptions, fireCode);
      this.locallyAdoptedCodes.fireCode = optionToSelect?.value as string;
    }
    this.additionalCodes = [...this.designParameters.codesAndStandards.other];
  }

  private mapItemOptions(response: IOption<BaseAttributes>[]): IDropDownOption[] {
    return response.map(
      (option: IOption<BaseAttributes>): IDropDownOption => ({
        label: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  @computed
  get isValid(): boolean {
    return (
      this.locallyAdoptedCodes.electricalCode !== defaultSelect && this.locallyAdoptedCodes.fireCode !== defaultSelect
    );
  }

  @action
  saveProjectProperties = async (): Promise<void> => {
    const lseSelectedOptionValue = Number(this.siteGeneralProperties.lseSelectedOptionValue);
    const authorityHavingJurisdiction = this.siteGeneralProperties.ahjSelectedOptionValue;

    if (lseSelectedOptionValue) {
      this.domain.project.designParameters.setLoadServingEntity(lseSelectedOptionValue);
    }

    if (authorityHavingJurisdiction) {
      this.domain.project.designParameters.setAuthorityHavingJurisdiction(authorityHavingJurisdiction);
    }

    const designParameters: IDesignParametersData = {
      ...this.domain.project.designParameters.toData(),
      codesAndStandards: this.domain.project.designParameters.codesAndStandards.withCodes(
        this.locallyAdoptedCodes.fireCode,
        this.locallyAdoptedCodes.electricalCode,
        this.additionalCodes
      )
    };
    this.domain.project.updateDesignParameters(designParameters);

    if (this.domain.project.site.parcel) {
      this.domain.project.site.parcel.assessorsParcelNumber = this.siteGeneralProperties.assessorsParcelNumber;
      this.domain.project.site.parcel.zoning = this.siteGeneralProperties.zoning;
    } else {
      const updatedParcelInfo = new Parcel({
        assessorsParcelNumber: this.siteGeneralProperties.assessorsParcelNumber,
        zoning: this.siteGeneralProperties.zoning
      });
      this.domain.setParcel(updatedParcelInfo);
    }
    this.domain.setProjectDescription(this.projectDescription);
    const updateProjectPromise = this.domain.updateProject();

    if (config.featureFlag.uiMode !== UI_MODE.AURORA) {
      this.closeModal();
    } else {
      // Update project, get design, check flags, reimport what's needed.
      await updateProjectPromise;
      await this.domain.loadDesign(this.domain.project.id);
      await importProjectAndDesignIntoDocumentService();
    }

    return updateProjectPromise;
  };

  fromData = (values: IDesignParametersData): void => {
    this.locallyAdoptedCodes.electricalCode =
      values.codesAndStandards?.electricalCode ?? this.locallyAdoptedCodes.electricalCode;
    this.locallyAdoptedCodes.fireCode = values.codesAndStandards?.fireCode ?? this.locallyAdoptedCodes.fireCode;
    this.additionalCodes = values.codesAndStandards?.other?.length
      ? [...values.codesAndStandards.other]
      : this.additionalCodes;
    this.domain.project.designParameters.fromData(values);
  };

  toData = (): IDesignParametersData => {
    const lseSelectedOptionValue = Number(this.siteGeneralProperties.lseSelectedOptionValue);
    const authorityHavingJurisdiction = this.siteGeneralProperties.ahjSelectedOptionValue;

    if (lseSelectedOptionValue) {
      this.domain.project.designParameters.setLoadServingEntity(lseSelectedOptionValue);
    }

    if (authorityHavingJurisdiction) {
      this.domain.project.designParameters.setAuthorityHavingJurisdiction(authorityHavingJurisdiction);
    }

    return {
      ...this.domain.project.designParameters.toData(),
      codesAndStandards: this.domain.project.designParameters.codesAndStandards.withCodes(
        this.locallyAdoptedCodes.fireCode,
        this.locallyAdoptedCodes.electricalCode,
        this.additionalCodes
      )
    };
  };

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (this.isValid && !this.isTagInputActive()) {
      if (event.key === KeyboardListener.KEY_ENTER) {
        this.saveProjectProperties();
        this.dispose();
      }
    }
  };

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  // Prevents submit when tag input is active
  private isTagInputActive(): boolean {
    return document.activeElement instanceof HTMLInputElement && document.activeElement.name === 'tag';
  }
}
