import isNil from 'lodash/isNil';
import {
  computed, observable
} from 'mobx';
import type Limit from '../Limit';

export interface ISeismicDesignParametersData {
  sds?: number;
}

export class SeismicDesignParameters {
  static readonly SDS_LIMITS: Limit = {
    lower: 0,
    upper: 4
  };

  /**
   * Spectral acceleration (SDS), spectral response acceleration parameter at short periods.
   */
  @observable
  sds?: number;

  constructor(data?: ISeismicDesignParametersData) {
    this.sds = data?.sds;
  }

  toData = (): ISeismicDesignParametersData | undefined => {
    if (this.isEmpty) {
      return undefined;
    }
    return {
      sds: this.sds
    };
  };

  @computed
  get isEmpty(): boolean {
    return isNil(this.sds);
  }

  @computed
  get sdsAsText(): string {
    return !this.isEmpty ? `${this.sds?.toFixed(3)} g` : '--';
  }
}
