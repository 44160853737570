import type { IHandlerBus } from '../IServiceBus';

export interface ICommand {
  execute(): Promise<void>;
  reExecute(): Promise<void>;
  postExecute(context: IHandlerBus): Promise<void>;
}

export interface IConfirmableCommand extends ICommand {
  confirmationModalText?: string;
}

export abstract class BaseCommand implements ICommand {
  abstract execute(): Promise<void>;

  async reExecute(): Promise<void> {
    return Promise.resolve();
  }

  async postExecute(context: IHandlerBus): Promise<void> {
    return Promise.resolve();
  }
}
