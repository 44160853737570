import type { Vector3 } from 'three';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IAddVertexToRoofFaceDependencies extends IBaseCommandDependencies {
  editor: EditorStore;
  targetCoordinates: Vector3;
  wipRoofface: RoofFace;
}

export class AddVertexToRoofFaceCommand extends BaseCommand {
  private editor: EditorStore;
  private targetCoordinates: Vector3;
  private wipRoofface: RoofFace;

  constructor(dependencies: IAddVertexToRoofFaceDependencies) {
    super();
    this.editor = dependencies.editor;
    const {
      targetCoordinates, wipRoofface
    } = dependencies;
    this.targetCoordinates = targetCoordinates;
    this.wipRoofface = wipRoofface;
  }

  async execute(): Promise<void> {
    this.wipRoofface.addVertex({
      vertex: this.targetCoordinates,
      removeIfCollinear: true,
      originatingFromTracing: true
    });
    this.editor.addOrUpdateObject(this.wipRoofface.mesh);
  }
}
