import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useRef, useState
} from 'react';
import {
  getServiceEntranceAndSiteEquipmentProperties,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import { MeterMainPropertiesViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/MeterMainPropertiesViewModel';
import useStore from '../../../stores/useStore';
import { ServiceEntranceEquipmentFormContainer } from './ServiceEntranceEquipmentFormContainer';
import {
  MainBreakerProperties, MeterMainInputContainer
} from './styles';

const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;

const Label = LyraTypography.Paragraph;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

const MeterMainProperties = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<MeterMainPropertiesViewModel>();
  const cleanupFunction = useRef<() => void>();

  useEffect(() => {
    setViewModel(new MeterMainPropertiesViewModel(editor, domain, serviceBus));
    cleanupFunction.current = (): void => {
      viewModel?.clearMainBreakerRatingOptionsAutoUpdate();
      viewModel?.clearMainBreakerAICOptionsAutoUpdate();
    };
    return () => {
      cleanupFunction.current?.();
    };
    // Adding viewModel to a list of dependencies will send this hook into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, editor, serviceBus]);

  if (!viewModel) {
    return <></>;
  }

  const {
    label, name
  } = getServiceEntranceAndSiteEquipmentProperties(
    ServiceEntranceEquipmentDevice.UtilityMeter,
    ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter
  );

  return (
    <ServiceEntranceEquipmentFormContainer label={label} title={name}>
      <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
        SERIAL NUMBER
      </Label>
      <TextField
        height="20px"
        value={viewModel.serialNumber}
        onChange={(value): void => viewModel.assignSerialNumber(value, true)}
      />

      {viewModel.isElectricServiceIdFieldVisible && (
        <>
          <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
            ELECTRIC SERVICE IDENTIFIER (ESID)
          </Label>
          <TextField
            height="20px"
            value={viewModel.electricServiceId}
            maxLength={22}
            onChange={(value): void => viewModel.assignElectricServiceId(value, true)}
            hasError={!viewModel.isValidElectricServiceIdField}
          />
          {!viewModel.isValidElectricServiceIdField && (
            <Label lineHeight={1} margin="4px 4px" type="h6" color={EThemeComponentColor.RED}>
              It must be a 17 or 22-digit number.
            </Label>
          )}
        </>
      )}
      <MainBreakerProperties>
        <MeterMainInputContainer>
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MAIN BREAKER RATING
          </Label>
          <Select
            name="breakerOptions"
            listOptions={viewModel.mainBreakerRatingOptions}
            selected={viewModel.mainBreakerRating?.value}
            onSelect={viewModel.setMainBreakerRating}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MeterMainInputContainer>
        <MeterMainInputContainer>
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MAIN BREAKER INTERRUPTING CAPACITY (AIC)
          </Label>
          <Select
            name="mainBreakerAmpereInterruptingCapacityOptions"
            listOptions={viewModel.mainBreakerAmpereInterruptingCapacityOptions}
            selected={viewModel.mainBreakerAmpereInterruptingCapacity?.value}
            onSelect={viewModel.setMainBreakerAmpereInterruptingCapacity}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MeterMainInputContainer>
      </MainBreakerProperties>
    </ServiceEntranceEquipmentFormContainer>
  );
};

export default observer(MeterMainProperties);
