import isNil from 'lodash/isNil';
import { observer } from 'mobx-react-lite';
import { type PropsTheme, ThemeContext } from 'styled-components';
import { withTheme } from 'styled-components';
import { type ReactElement, useContext } from 'react';
import React, {
  useCallback, useMemo
} from 'react';
import { LyraDivider } from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { SiteImage } from '../../../domain/models/SiteDesign/SiteImage';
import useStore, { useUiStore } from '../../../stores/useStore';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyPanelWrapper,
  ScrollablePanelContent,
  ScrollablePanelContainer,
  RoofFaceWrapper
} from '../PropertyPanel/styles';
import Collapse from '../../../ui/components/Collapse';
import LineHr from '../../components/LineHr';
import SiteImagesContainer from '../SiteImagesContainer/SiteImagesContainer';
import type { IViewUploadImageDependencies } from '../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import ViewUploadImage from '../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import type {
  IDeleteSiteImageCommandDependencies,
  TObjectWithDeletableImage
} from '../../../stores/ServiceBus/Commands/DeleteSiteImageCommand';
import { propsRegistry } from './registry';
import SlopeRoofProperties from './SlopeRoofProperties';
import AzimuthRoofProperties from './AzimuthRoofProperties';
import { RoofFaceLevel } from './containers/RoofLevel/RoofFaceLevel';
import { RoofFaceType } from './containers/RoofType/RoofFaceType';
import { BasicPropsContainer } from './styles';
import FramingRoofProperties from './FramingRoofProperties';
import RoofDeckingProperties from './RoofDeckingProperties';
import RoofConditionProperties from './RoofConditionProperties';
import SurfaceRoofProperties from './SurfaceRoofProperties';

const RoofProperties: React.FC = (): ReactElement => {
  const {
    domain, serviceBus, editor
  } = useStore();
  const {
    roofFaceProps, properties, modal, workspace
  } = useUiStore();
  const theme = useContext(ThemeContext);
  const {
    firstRoofFaceSelected, levelRoofFaceViewModel
  } = roofFaceProps;
  const selectedRoofFace: RoofFace = roofFaceProps.currentSelectedRoofFaces?.[0];
  const currentRoofFace = useMemo((): RoofFace => {
    return roofFaceProps.currentSelectedRoofFaces.length ? selectedRoofFace : ({} as RoofFace);
  }, [selectedRoofFace, roofFaceProps.currentSelectedRoofFaces]);

  const showSinglePropertyEditor = !isNil(roofFaceProps.currentRoofPropertyEditor);

  const roofName = firstRoofFaceSelected ? firstRoofFaceSelected.name : '';
  const roofData = `${levelRoofFaceViewModel!.description} / ${roofName}`;

  const getSinglePropertyEditorComponent = (): JSX.Element | null => {
    const PropertyEditor = propsRegistry.get(roofFaceProps.currentRoofPropertyEditor!.propUICode);
    return PropertyEditor && <PropertyEditor />;
  };

  const onAddImageClick = useCallback((): void => {
    const dependencies: IViewUploadImageDependencies = {
      modal,
      domain,
      editor,
      serviceBus,
      currentRoofFace,
      workspace
    };
    modal.createModal('upload_image_modal', new ViewUploadImage({
      dependencies: dependencies,
      type: 'roof',
      label: 'Roof Face'
    }));
  }, [modal, domain, editor, serviceBus, workspace, currentRoofFace]);

  const onDeleteImageClick = useCallback(
    (image: SiteImage): void => {
      const dependencies: IDeleteSiteImageCommandDependencies<RoofFace> = {
        editor,
        domain,
        image,
        imageOwnerObject: currentRoofFace as TObjectWithDeletableImage<RoofFace>
      };
      serviceBus.send('delete_site_image', dependencies);
    },
    [domain, editor, serviceBus, currentRoofFace]
  );

  // Only validate if there is no roof selected in this point.
  // When changes first selected, re-render component and subcomponents.
  if (!firstRoofFaceSelected) {
    return <></>;
  }
  return (
    <PropertyPanelWrapper>
      <PropertyPanelHeader icon="tool-properties" name={properties.currentTitle} additionalData={roofData} />
      <ScrollablePanelContainer className="element_with_scroll">
        <ScrollablePanelContent>
          {showSinglePropertyEditor && getSinglePropertyEditorComponent()}
          {!showSinglePropertyEditor && (
            <>
              <BasicPropsContainer>
                <Collapse title="Basic Properties" open={true} color={theme!.colors.secondaryFont}>
                  <RoofFaceWrapper>
                    <RoofFaceType />
                    <RoofFaceLevel />
                  </RoofFaceWrapper>
                  <SlopeRoofProperties />
                  <AzimuthRoofProperties />
                  <LineHr />
                  <FramingRoofProperties />
                  <SurfaceRoofProperties />
                  <RoofDeckingProperties />
                  <LineHr />
                  <RoofConditionProperties />
                  {/*
                  <LineHr/>
                  <SolarAccessProperties />
                  */}
                </Collapse>
              </BasicPropsContainer>
              <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
              <SiteImagesContainer
                theme={theme!}
                images={currentRoofFace.images}
                onAddImage={onAddImageClick}
                onDeleteImage={onDeleteImageClick}
              />
            </>
          )}
        </ScrollablePanelContent>
      </ScrollablePanelContainer>
    </PropertyPanelWrapper>
  );
};

export default observer(RoofProperties);
