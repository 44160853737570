import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type { Building } from '../../../../../domain/models/SiteDesign/Building';
import { Outline } from '../../../../../domain/models/SiteDesign/Outline';
import type { IDrawableStructureFactory } from '../../../../../domain/models/StructureFactory';
import type { Color } from '../../../../../domain/typings';
import { ECursor } from '../../../../../domain/typings';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type {
  IPointerDownControlEvent,
  IPointerHoveringControlEvent,
  IPointerLeftControlEvent,
  IPointerUpControlEvent
} from '../../../../EditorStore/Controls/ControlEvents';
import { HoverControl } from '../../../../EditorStore/Controls/HoverControl';
import type { ModalStore } from '../../../Modal/Modal';
import type { PropertiesStore } from '../../../Properties/Properties';
import type { IAddRoofOutlineCommandDependencies } from '../../../../ServiceBus/Commands/AddRoofOutlineCommand';
import type SmartGuideStore from '../../../SmartGuidesStore/SmartGuidesStore';
import type { WizardStore } from '../../../Wizard/Wizard';
import { canvasConfig } from '../../../../../config/canvasConfig';

import type { IWorkspace } from '../../../WorkspaceStore/types';
import type {
  IBaseToolDependencies, IHandleClicksTool, IHandleHoverTool, IHandleMoveTool
} from '../../Tool';
import {
  OUTLINE_TOOL_ID,
  PANNING_TOOL_ID,
  PARCEL_BOUNDARY_TOOL_ID,
  ROOF_TRACING_TOOL_ID,
  SELECT_TOOL_ID
} from '../constants';
import { TraceToolBase } from '../TraceToolBase';
import { PropsPanelUICodes } from '../../../Properties/propertiesStoreConstants';
import type { Unzoomable } from '../../../../../domain/mixins/Unzoomable';

export interface IOutlineToolDependencies extends IBaseToolDependencies {
  properties: PropertiesStore;
  smartGuides: SmartGuideStore;
  domain: DomainStore;
  drawableObjectsFactory: IDrawableStructureFactory;
  modal: ModalStore;
  wizard: WizardStore;
  currentWorkspace: IWorkspace;
}

export class OutlineTool extends TraceToolBase implements IHandleClicksTool, IHandleHoverTool, IHandleMoveTool {
  static readonly toolWhitelist: string[] = [
    PANNING_TOOL_ID,
    PARCEL_BOUNDARY_TOOL_ID,
    SELECT_TOOL_ID,
    OUTLINE_TOOL_ID,
    ROOF_TRACING_TOOL_ID
  ];

  override readonly id: string = OUTLINE_TOOL_ID;
  override readonly icon: string = 'trace-outlines';
  override readonly title: string = 'Outline';
  override readonly description: string = this.title;
  override testId: string = 'TraceRoofOutline';

  override showSubmenu: boolean = false;
  private hoveringControl?: HoverControl;

  constructor(dependencies: IOutlineToolDependencies) {
    super(dependencies);
    this.cursor = ECursor.FIRST_TRACE;
    this.properties = dependencies.properties;
    this.wizard = dependencies.wizard;
    this.domain = dependencies.domain;
    this.smartGuides = dependencies.smartGuides;
    this.currentWorkspace = dependencies.currentWorkspace;
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
    this.wipObject = this.drawableObjectsFactory.create<Outline>(Outline, {
      color: canvasConfig.wipOutlineColor
    });
  }

  whenSelected(): void {
    this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelOutlineTool);
    this.setupHovering();
    this.initPreviewLine();
    this.hidePreviewLine();
    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.addEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);
    KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
  }

  whenDeselected(): void {
    this.editor.removeObject(this.wipObject!.mesh);
    this.hidePreviewLine();
    this.wipObject = this.drawableObjectsFactory.create<Outline>(Outline, {
      color: canvasConfig.wipOutlineColor
    });
    this.removeEventListeners();
    this.properties.setPropertyPanel();
    this.hoveringControl!.dispose();
  }

  dispose(): void {
    this.removeEventListeners();
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    super.onMouseDown(event);
    this.disableTools();
  };

  override onMouseUp = (event: IPointerUpControlEvent): void => {
    const {
      target, pointerEnd
    } = event;
    if (!this.isValidUpEvent(event) || !target || !pointerEnd) {
      return;
    }

    const vertexWorldCoords = target.unprojectMouseToFrustum(pointerEnd);
    this.applyGuidesWithValidationForEachGuideAndPersistIfValid({
      mousePos: vertexWorldCoords
    });
  };

  override onMouseLeave = (event: IPointerLeftControlEvent): void => {
    this.hidePreviewLine();
    this.smartGuides.resetGuides();
  };

  onObjectHoverIn = ({ hoverObject: hoverEvent }: IPointerHoveringControlEvent): void => {
    if (hoverEvent === undefined) {
      return;
    }

    if (hoverEvent.type === 'Vertex' && hoverEvent.mesh.userData.index === 0) {
      this.updateCursor(ECursor.TRACE);
      const { scaleFactor } = this.editor;
      (hoverEvent as Unzoomable).unzoom(scaleFactor * 2);
    }
  };

  onObjectHoverOut = ({ hoverObject: hoverEvent }: IPointerHoveringControlEvent): void => {
    if (hoverEvent === undefined) {
      return;
    }

    if (hoverEvent.type === 'Vertex' && hoverEvent.mesh.userData.index === 0) {
      this.updateCursor();
      const { scaleFactor } = this.editor;
      (hoverEvent as Unzoomable).unzoom(scaleFactor);
    }
  };

  finishWipPolygon(finishData: { name: string; color: Color; building: Building }): void {
    const {
      domain, wipObject
    } = this;
    const dependencies: IAddRoofOutlineCommandDependencies = {
      domain,
      newOutline: wipObject as Outline,
      color: finishData.color,
      name: finishData.name,
      building: finishData.building
    };
    this.serviceBus.send('add_roof_outline', dependencies);

    this.wipObject = this.drawableObjectsFactory.create<Outline>(Outline, {
      color: canvasConfig.wipOutlineColor
    });
    this.hoveringControl!.setTargetObjects([this.wipObject]);
    // hide smart guides displayed
    this.smartGuides.resetGuides();
    this.enableTools();
    this.toolbar.activateSelectionToolInProjectWorkspace();

    this.removePreviewLine();
  }

  private setupHovering(): void {
    this.hoveringControl = new HoverControl(this.editor, this.editor.viewport, this.editor.activeCamera!, [
      this.wipObject!
    ]);

    this.hoveringControl.addEventListener('HoverInObject', this.onObjectHoverIn);
    this.hoveringControl.addEventListener('HoverOutObject', this.onObjectHoverOut);
  }

  private removeEventListeners(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
    this.editor.baseMouseControl.removeEventListener('PointerDown', this.onMouseDown);

    this.hoveringControl!.removeEventListener('HoverInObject', this.onObjectHoverIn);
    this.hoveringControl!.removeEventListener('HoverOutObject', this.onObjectHoverOut);
    KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
  }

  private disableTools(): void {
    this.toolbar.whitelistTools(OutlineTool.toolWhitelist);
  }
}
