import { MathUtils as ThreeMath } from 'three';
import isNil from 'lodash/isNil';
import { computed } from 'mobx';
import { Compass } from '../../../../../domain/graphics/Compass';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import { AZIMUTH_CODE } from '../../../../../ui/containers/RoofFaceProperties/constants';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import { UpdateRoofFacePropertyKey } from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';

export class AzimuthRoofFaceViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = AZIMUTH_CODE;
  private compass?: Compass;

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.Azimuth;
  }

  @computed
  override get currentValue(): RoofFace[keyof RoofFace] {
    if (this.value) {
      let ret = Number(this.value).toFixed(2);
      if (ret === '360.00') {
        ret = '0.00';
      }

      return ret;
    }
    return this.value;
  }

  setCompassInScene(angle: number = 0, x: number, y: number, radius: number): void {
    const compass = new Compass(this.editor.font, radius);
    compass.unzoom(this.editor.scaleFactor);

    if (angle !== 0) {
      // Inverting radians in order to flip rotation values
      // threejs is right handed system
      // but azimuth works with left handed coord values
      const radians = ThreeMath.degToRad(angle) * -1;
      compass.rotateRing(radians);
    }
    compass.mesh.position.x = x;
    compass.mesh.position.y = y;
    this.compass = compass;

    this.editor.addOrUpdateObject(compass.mesh);
    this.editor.setMouseClicksIgnoringTransparentLayerTriggeredWarningMessage(
      true,
      'Modify azimuth from the right panel'
    );
  }

  removeCompassInScene(): void {
    this.editor.removeObject(this.compass!.mesh);
    this.editor.setMouseClicksIgnoringTransparentLayerTriggeredWarningMessage(false);
  }

  override saveProperty(): void {
    super.saveProperty();
  }

  polygonAzimuth(): number[] {
    // Getting vertex array
    return this.roofFace.azimuthAngles ?? [];
  }

  override changeValue(value: RoofFace[keyof RoofFace]): void {
    super.changeValue(value);
    if (!isNil(value)) {
      const angle = value as number;
      // Inverting radians in order to flip rotation values
      // threejs is right handed system
      // but azimuth works with left handed coord values
      const radians = ThreeMath.degToRad(angle) * -1;
      this.compass!.rotateRing(radians);
    }
  }
}
