import type {
  Intersection, Object3D, OrthographicCamera, PerspectiveCamera
} from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { Segment } from '../../../domain/graphics/Segment';
import type EditorStore from '../EditorStore';
import type { Drawable } from '../../../domain/mixins/Drawable';
import type { Selectable } from '../../../domain/mixins/Selectable';
import type { ViewPort } from '../ViewportController';
import {
  deleteItem, pushUniqueItem
} from '../../../utils/helpers';
import { getLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import { BaseCastObjectControl } from './BaseCastObjectControl';
import type { IControlSelectionChange } from './ControlEvents';

type SelectionEvents = {
  selection_change: IControlSelectionChange;
};

type AutoSelectByFunction = (selectable: Selectable) => Selectable[];

export class SelectionControl extends BaseCastObjectControl<SelectionEvents> {
  protected static sInstance: SelectionControl;
  static override getInstance(
    editor?: EditorStore,
    viewport?: ViewPort,
    camera?: OrthographicCamera | PerspectiveCamera
  ): SelectionControl {
    if (editor !== undefined && viewport !== undefined && camera !== undefined) {
      if (!SelectionControl.sInstance) {
        SelectionControl.sInstance = new SelectionControl(editor, viewport, camera);
      } else if (!SelectionControl.getInstance()) {
        throw new Error('Singleton instance cannot be created without parameters');
      }
    }
    return SelectionControl.sInstance;
  }

  private allowMultiSelect: boolean = true;
  private isMouseDown: boolean = false;
  private didDragHappened: boolean = false;
  private ignoreMouseUpForTarget: Object3D | null = null;
  private autoselectBy?: AutoSelectByFunction;

  // Static configuration:
  private readonly singleItemUnSelectionPossible: boolean = true;

  selectedItems: Selectable[] = [];
  /**
   * @description this list contains Selectable items that shouldn't be included in
   * un-selection list after mouse up event. Useful for keeping selection after dragging.
   */
  ignoreMouseUpForItems: Selectable[] = [];
  private constructor(editor: EditorStore, viewport: ViewPort, camera: OrthographicCamera | PerspectiveCamera) {
    super(editor, viewport, camera);
  }

  override activate(
    {
      allowMultiSelect,
      autoselectBy
    }: {
      allowMultiSelect: boolean;
      autoselectBy?: AutoSelectByFunction;
    } = { allowMultiSelect: true }
  ): void {
    this.allowMultiSelect = allowMultiSelect;
    this.editor.canvasParent.addEventListener('mousedown', this.onMouseEvent);
    this.editor.canvasParent.addEventListener('mouseup', this.onMouseEvent);
    this.editor.canvasParent.addEventListener('mousemove', this.onMouseMoveNew);
    this.autoselectBy = autoselectBy;
  }

  override deactivate(): void {
    this.editor.canvasParent.removeEventListener('mousedown', this.onMouseEvent);
    this.editor.canvasParent.removeEventListener('mouseup', this.onMouseEvent);
    this.editor.canvasParent.removeEventListener('mousemove', this.onMouseMoveNew);
  }

  clearSelection(): void {
    this.selectedItems = [];
  }

  onMouseMoveNew = (event: MouseEvent): void => {
    this.onMouseMove(event);
    if (this.isMouseDown) {
      this.didDragHappened = true;
    }
  };

  override onMouseDown = (event: MouseEvent): void => {
    /* dummy (rely on onMouseEvent instead) */
  };

  onMouseEvent = (event: MouseEvent): void => {
    // Selection should happen on mouse down
    // Un-selection should happen on mouse up
    this.setMouse(event.clientX, event.clientY);
    const isMouseDown = event.type === 'mousedown';
    const isMouseUp = event.type === 'mouseup';

    let intersections = this.getCastedObjects();

    if (intersections.length === 0) {
      if (!event.shiftKey) {
        this.unselectAll();
      }
      return;
    }

    // Failsafe in case the event handler will be added on another mouse event type.
    if (!(isMouseDown || isMouseUp)) {
      return;
    }

    if (this.editor.overrideShiftIsPressed || (this.allowMultiSelect && event.shiftKey)) {
      this.multiSelect(intersections, isMouseDown);
    } else {
      this.singleSelect(intersections, isMouseDown);
    }

    if (!!this.autoselectBy) {
      const firstSelectable = this.getFirstSelectableFromIntersections(intersections);
      if (firstSelectable) {
        const overrideSelection = this.autoselectBy(firstSelectable);
        if (overrideSelection.length > 0) {
          this.unselectAll();
          this.changeSelection(overrideSelection);
        }
      }
    }

    if (isMouseDown) {
      this.isMouseDown = true;
    }
    if (isMouseUp) {
      this.isMouseDown = false;
      this.didDragHappened = false;
    }
  };

  unselectAll(): void {
    if (this.selectedItems === undefined) {
      return;
    }
    const unselected = this.selectedItems;
    this.selectedItems = [];
    this.changeSelection(this.selectedItems, unselected);
  }

  setSelectedObjects(selection: Selectable[]): void {
    const unselected: Selectable[] = [];
    selection.forEach((object: Selectable): void => {
      if (!this.isSelectable(object)) {
        return;
      }
      const indexOfObject = this.selectedItems.indexOf(object);
      const selectables: Selectable[] = this.getAllSelectableParents(object.mesh);

      if (selectables) {
        if (indexOfObject === -1) {
          this.selectedItems.push(...selectables);
        } else {
          unselected.push(...selectables);
          this.selectedItems.splice(indexOfObject, 1);
        }
      }
    });

    this.changeSelection(this.selectedItems, unselected);
  }

  /**
   * Recursive function for get the first selectable through the parents
   *
   */
  getAllSelectableParents(target: Object3D): Selectable[] {
    const multiple: Selectable[] = [];
    const parent = target.parent;
    if (this.isSelectable(target)) {
      const model = getLyraModelByMesh<Selectable>(target);
      multiple.push(model);
      if (model.selectWithParent && parent) {
        const selectableParent: Selectable[] | null = this.getAllSelectableParents(parent);
        if (selectableParent) {
          multiple.push(...selectableParent);
        }
      }
      return multiple;
    }
    if (parent) {
      const selectableParent: Selectable[] | null = this.getAllSelectableParents(parent);
      if (selectableParent) {
        multiple.push(...selectableParent);
      }
      return multiple;
    }
    return multiple;
  }

  private singleSelect(intersections: Intersection[], isMouseDown: boolean): void {
    /* If clicked-on item is already selected - unselect on mouse up, and
     * only if no dragging have happened.
     * If clicked-on item is not already selected - select it on mouse down. */

    // Don't unselect after drag:
    if (!isMouseDown && this.didDragHappened) {
      return;
    }

    const intersectedModel = this.getFirstSelectableFromIntersections(intersections);
    const intersectedObject = (intersectedModel as Drawable)?.mesh;
    if (intersectedObject) {
      const intersectedSelectables: Selectable[] = this.getAllSelectableParents(intersectedObject);
      const selectedItemsContainsIntersectedElement = this.selectedItems.includes(intersectedSelectables[0]);

      if (this.ignoreSingleSelectIfNecessary(intersectedObject, selectedItemsContainsIntersectedElement, isMouseDown)) {
        return;
      }

      const unselected = this.selectedItems;
      this.selectedItems = [];

      if (this.singleItemUnSelectionPossible && selectedItemsContainsIntersectedElement && !isMouseDown) {
        // Unselect everything, so doing nothing here
      } else if (intersectedSelectables.length > 0) {
        // Select all selectable intersected objects.
        this.selectedItems.push(...intersectedSelectables);

        intersectedSelectables.forEach((item: Selectable): void => {
          const objIndex = unselected.indexOf(item);
          if (objIndex >= 0) {
            unselected.splice(objIndex, 1);
          }
        });
      }

      this.changeSelection(this.selectedItems, unselected);

      if (isMouseDown) {
        this.ignoreMouseUpForTarget = intersectedObject;
      }
    }
  }

  private ignoreSingleSelectIfNecessary(
    intersectedObject: Object3D,
    selectedItemsContainsIntersectedElement: boolean,
    isMouseDown: boolean
  ): boolean {
    if (!this.singleItemUnSelectionPossible) {
      return false;
    }

    // Don't unselect on mouse down:
    if (isMouseDown && selectedItemsContainsIntersectedElement) {
      // Mouse down happened on previously selected element, so we're clearing
      // ignoreMouseUpForTarget so that unselect would happen on mouse up.
      // Otherwise, the next check, designed to avoid double selection, will exit execution.
      this.ignoreMouseUpForTarget = null;
      return true;
    }

    // This object was just selected, so doing nothing on mouse up to avoid double selection.
    return !isMouseDown && this.ignoreMouseUpForTarget === intersectedObject;
  }

  private multiSelect(intersections: Intersection[], isMouseDown: boolean): void {
    const object = this.getFirstSelectableFromIntersections(intersections);
    if (object) {
      const indexOfObject = this.selectedItems.indexOf(object);
      const unselected: Selectable[] = [];
      const firstSelectable: Selectable[] = this.getAllSelectableParents(object.mesh);
      let shouldPerformAction: boolean = false;

      if (firstSelectable) {
        if (indexOfObject === -1) {
          if (isMouseDown) {
            shouldPerformAction = true;
            this.selectedItems.push(...firstSelectable);
            for (let firstSelectableItem of firstSelectable) {
              pushUniqueItem<Selectable>(this.ignoreMouseUpForItems, firstSelectableItem);
            }
          }
        } else {
          if (!isMouseDown) {
            for (let firstSelectableItem of firstSelectable) {
              if (!deleteItem<Selectable>(this.ignoreMouseUpForItems, firstSelectableItem)) {
                shouldPerformAction = true;
                unselected.push(firstSelectableItem);
              }
            }
            if (shouldPerformAction) {
              this.selectedItems.splice(indexOfObject, 1);
            }
          }
        }
      }

      if (shouldPerformAction) {
        this.changeSelection(this.selectedItems, unselected);
      }
    }
  }

  private changeSelection(selectedItems: Selectable[] = [], unselectedItems: Selectable[] = []) {
    this.selectedItems = selectedItems.filter(
      (selectedItem: Selectable): boolean => !unselectedItems.includes(selectedItem)
    );

    this.dispatchEvent({
      type: 'selection_change',
      selection: this.selectedItems,
      unselected: unselectedItems
    });
  }

  private getFirstSelectableAscendant(obj: Object3D): Selectable | null {
    let parent: Object3D | null = obj;

    if (parent instanceof Line2) {
      return null;
    }

    while (parent) {
      if (this.isSelectable(parent)) {
        if (!(getLyraModelByMesh(parent) instanceof Segment)) {
          return getLyraModelByMesh(parent);
        }
        return parent.userData.lyraModel as Selectable;
      }
      parent = parent.parent;
    }

    return null;
  }

  getFirstSelectableFromIntersections(intersections: Intersection[]): Selectable | null {
    const foundIntersection = intersections
      .map((intersection: Intersection): Object3D => intersection.object)
      .find((object: Intersection['object']): boolean => !!this.getFirstSelectableAscendant(object));
    return (foundIntersection && this.getFirstSelectableAscendant(foundIntersection)) ?? null;
  }

  override filterAndUpdateTargetObjects(filterFn: (object: Selectable) => boolean) {
    super.filterAndUpdateTargetObjects(filterFn);
    this.selectedItems = this.selectedItems.filter(filterFn);
  }
}
