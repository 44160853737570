import type { Vector3 } from 'three';
import Vector2D from '../domain/models/Vector2D';
import EOrientation from '../domain/models/EOrientation';
import { orientation } from '../utils/segmentIntersection';
import type { Boundary } from '../domain/graphics/Boundary';
import type { Vertex } from '../domain/graphics/Vertex';
import type Vector3D from '../domain/typings/Vector3D';

/**
 * Use case for calculate if the click mouse position is collinear
 * with the last line of the wipPolygon
 *
 * @export
 * @param wipBoundary Current boundary
 * @param mousePos Mouse Position
 * @returns true whether collinear, otherwise false
 */
export default function isPositionCollinearToLines(wipBoundary: Boundary, mousePos: Vector2D): boolean {
  return isPositionCollinearToVector3Lines(
    wipBoundary.vertices.slice(-2).map((vertex: Vertex): Vector3 => vertex.getVector3()),
    mousePos
  );
}

export function isPositionCollinearToVector3Lines(vertices: Vector3[], mousePos: Vector2D | Vector3D): boolean {
  const length = vertices.length;
  if (length < 2) {
    return false;
  }
  const lastPoint = vertices[length - 1];
  const nextToLast = vertices[length - 2];
  return (
    orientation(
      new Vector2D(lastPoint.x, lastPoint.y),
      new Vector2D(mousePos.x, mousePos.y),
      new Vector2D(nextToLast.x, nextToLast.y),
      0.005
    ) === EOrientation.COLLINEAR
  );
}
