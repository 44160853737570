import { INVERTER_TYPE_MICROINVERTER_SHORT } from '../../../../../domain/models/Constants';
import type { InverterType } from './InverterSelectionViewModel';

export const calcCurrentRatio = (
  totalAcPowerOutput: number,
  moduleDcPowerRating: number,
  hoveredInverter: InverterType,
  totalModules: number
): number => {
  if (totalAcPowerOutput <= 0) {
    return 0;
  }

  const hoveredInverterAttributes = hoveredInverter.attributes;
  const inverterType = hoveredInverterAttributes['Inverter Type'];

  const totalDcPowerRating =
    inverterType === INVERTER_TYPE_MICROINVERTER_SHORT
      ? hoveredInverterAttributes.dcInputQuantity * moduleDcPowerRating
      : totalModules * moduleDcPowerRating;

  return Math.round((totalDcPowerRating / totalAcPowerOutput) * 100);
};

export const calcTotalMaxDcInput = (inverters: (InverterType | undefined)[]): number => {
  return inverters.reduce((sum: number, currentInverter: InverterType | undefined): number => {
    const inverterPowerOutput = currentInverter?.attributes['AC Power Output'] ?? 0;
    const inverterRatio = currentInverter?.attributes['Max DC/AC Ratio'] ?? 1.4;
    const inverterMaxDcInput = inverterPowerOutput * inverterRatio;

    return inverterMaxDcInput + sum;
  }, 0);
};

export const calcAcPowerOutput = (inverters: (InverterType | undefined)[]): number => {
  return inverters.reduce((sum: number, currentInverter: InverterType | undefined): number => {
    const inverterPowerOutput = currentInverter ? currentInverter?.attributes['AC Power Output'] : 0;
    return inverterPowerOutput + sum;
  }, 0);
};
