import styled from 'styled-components';
import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import Flexbox from '../../../ui/components/Layout/Flexbox';

type ScrollablePanelContainerProps = {
  width?: string;
  padding?: string;
  disabled?: boolean;
};

export const PropertyPanelContainer = styled(Flexbox).attrs({
  dir: 'column',
  align: 'center'
})`
  width: 100%;
  background-color: #f2f2f2;
`;

export const SubpanelRowWrapper = styled.div`
  margin-bottom: 15px;
`;

export const ScrollablePanelContainer = styled.div`
  overflow: hidden auto;
  width: 100%;
  height: 100%;
`;

export const ScrollablePanelContent = styled.div<ScrollablePanelContainerProps>`
  padding: ${({ padding = '15px 15px 0' }: ScrollablePanelContainerProps): string => padding};
  box-sizing: border-box;
  height: inherit;
`;

export const RoofFaceWrapper = styled.div`
  padding-bottom: 15px;
`;

export const PropertyPanelWrapper = styled.div<ScrollablePanelContainerProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  align-items: center;

  .clickable-text {
    cursor: ${({ disabled }: ScrollablePanelContainerProps): string => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const PropertyContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden auto;
  box-sizing: border-box;
  padding: 0 15px;
`;

export const Note = styled.div`
  font-size: 13px;
  margin-bottom: 15px;
  background-color: rgb(221, 232, 232);
  padding: 7px;
  line-height: 1.5;
  border-radius: 6px;
`;

export const NextStepWrapper = styled(LyraLayout.Col)<PropsTheme>`
  padding-top: 0 !important;
  background: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  width: 100%;
  button {
    width: 100%;
  }
  .back-button {
    button {
      box-sizing: border-box;
      margin: 8% 0;
      justify-content: flex-start;
    }
  }
`;

export const SubmitSectionWrapper = styled(LyraLayout.Flexbox).attrs({
  dir: 'column',
  justify: 'center'
})``;
