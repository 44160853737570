import Http from '../Http';
import config from '../../../config/config';
import type {
  IOption, BaseAttributes
} from '../../../domain/models/SiteDesign/IOption';

export class LoadServingEntitiesService {
  get baseURL(): string {
    return config.api.utilities;
  }

  async getLoadServingEntityOptions(state: string): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/load-serving-entities'),
      {
        state
      }
    );
    return response.data;
  }
}
