import type { SimpleVector2 } from './ThreeUtils';

export class HTMLUtils {
  /**
   * offsetXY is not widely supported by the browsers yet
   */
  static clientXYToOffsetXY(element: HTMLElement, x: number, y: number): SimpleVector2 {
    const boundingClientRect = element.getBoundingClientRect();
    return {
      x: x - boundingClientRect.left,
      y: y - boundingClientRect.top
    };
  }

  static getSize(element: Element): DOMRect {
    return element.getBoundingClientRect();
  }

  /**
   * Removes every child of the element
   * @param element HTMLElement
   */
  static clearElement(element: Element, alsoRemoveFromDom: boolean = false): void {
    while (element.lastChild) {
      element.removeChild(element.lastChild);
    }

    if (alsoRemoveFromDom) {
      HTMLUtils.removeElementFromDOM(element);
    }
  }

  /**
   * Removes element from DOM
   * @param element HTMLelement
   */
  static removeElementFromDOM(element: Element): void {
    if (element.parentElement) {
      element.parentElement.removeChild(element);
    }
  }

  /**
   * Returns true if parent contains child
   * @param parent
   * @param child
   * @param acceptIfSame
   */
  static isDescendant(parent: Element, child: Element, acceptIfSame: boolean = true): boolean {
    let node = acceptIfSame ? child : child.parentElement;

    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentElement;
    }

    return false;
  }
}
