import React, { BaseProps, ReactElement } from 'react';
import {
  ButtonWrapper,
  PropertyGroupContainer,
  UpperView,
  TitleText,
  CenterView,
  GroupWrapper,
  GroupTitle,
  PropertyWrapper,
  ListWrapper
} from './styles';
import { CTAButton } from '../Buttons/CTAButton';
import { PropertyListProps, PropertyList } from './PropertyList';
import { Icon } from '../Icon';

type GroupProps = {
  name: string;
  iconName: string;
  label: string;
  trigger: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  properties: PropertyListProps[];
};

export type PropertyGroupProps = BaseProps & {
  buildingName: string;
  iconName: string;
  groups: GroupProps[];
};

const PropertyGroup = ({
  buildingName, iconName, groups
}: PropertyGroupProps): ReactElement => {
  return (
    <PropertyGroupContainer>
      <UpperView>
        <Icon name={iconName} />
        <TitleText>{buildingName}</TitleText>
      </UpperView>
      <CenterView>
        {groups.map((group: GroupProps, index: number): ReactElement => {
          return (
            <GroupWrapper key={index}>
              <GroupTitle>
                {group.name}
              </GroupTitle>
              { group.label ?
                <ButtonWrapper>
                  <CTAButton
                    iconName={group.iconName}
                    label={group.label}
                    trigger={group.trigger}
                    fullWidth={false}
                  />
                </ButtonWrapper>
              :
                <></>
              }
              <PropertyWrapper>
                {group.properties.map(
                  (property: PropertyListProps, propertyIndex: number): ReactElement => {
                  return (
                    <ListWrapper key={propertyIndex}>
                      <PropertyList
                        name={property.name}
                        level={property.level}
                        levelColor={property.levelColor}
                        buttons={property.buttons}
                      />
                    </ListWrapper>
                  );
                })}
              </PropertyWrapper>
            </GroupWrapper>
          );
        })}
      </CenterView>
    </PropertyGroupContainer>
  );
};

export { PropertyGroup };
