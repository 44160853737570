import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, computed, observable
} from 'mobx';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  MEASURE_LIMIT, YES_OR_NO_OPTIONS
} from '../../../../../domain/models/Constants';
import type { IBuildingData } from '../../../../../domain/entities/SiteDesign/Building';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import type { IRoofFaceStructureData } from '../../../../../domain/entities/SiteDesign/RoofFaceStructure';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { RoofProtrusionStore } from '../../../Properties/RoofProtrusion/RoofProtrusionStore';
import type { IImport3DModelCommandDependencies } from '../../../../ServiceBus/Commands/Import3DModelFromWizard';
import type { ToolbarStore } from '../../../ToolbarStore/Toolbar';
import { handleApiError } from '../../../../../utils/helpers';
import type { IBaseStepViewModelDependencies } from '../BaseStepViewModel';
import { BaseStepViewModel } from '../BaseStepViewModel';
import {
  convertFeetToMeters, convertInchesToMeters
} from '../../../../../utils/Convertions';
import { ThreeDimensionalModelImportedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../../config/config';
import { RoofDeckingDataFetcher } from './RoofDeckingDataFetcher';
import { RoofFramingDataFetcher } from './RoofFramingDataFetcher';
import { RoofSurfaceDataFetcher } from './RoofSurfaceDataFetcher';

export type IImport3DModelFileProps = {
  modelProvider: string; // Step 1
  buildingHeight?: {
    foundationHeight: number; // Step 1
    ceilingHeight: number; // Step 1
    numberOfStories: number; // Step 1
  };
};

export type IImport3DModelProps = {
  structure: IRoofFaceStructureData;
};

type FilterFunction = (option: IOption<BaseAttributes>) => boolean;

const EAGLEVIEW_PROVIDER = 'EAGLEVIEW';
const ROOFORDERS_PROVIDER = 'ROOFORDERS';

interface IImportFileWizardDependencies extends IBaseStepViewModelDependencies {
  editor: EditorStore;
  toolbar: ToolbarStore;
  roofProtrusion: RoofProtrusionStore;
  onModalClose?: () => void;
}

const findOptionByValue =
  (value: string | number): FilterFunction =>
    (option: IOption<BaseAttributes>): boolean =>
      option.attributes.value === `${value}`;

export class Import3DModelViewModel extends BaseStepViewModel {
  private defaultsWereSetOnChangeStepMap: {
    [key: number]: boolean;
  } = {};

  readonly propCodeUI = 'import_file_modal';
  readonly yesOrNoOptions = YES_OR_NO_OPTIONS;
  readonly measureLimit = MEASURE_LIMIT;

  @observable
  override step: number = 1;

  // Step 1
  @observable
  private file?: File = undefined;
  @observable
  modelProvider: string = ROOFORDERS_PROVIDER;
  @observable
  foundationHeight: number = 2;
  @observable
  ceilingHeight: number = 8;
  @observable
  numberOfStories: number = 1;

  // Step 2
  @observable
  constructionStyle?: IOption<BaseAttributes>;
  @observable
  yearOfConstruction: OptionProps = {
    name: 'Post-1960',
    value: '1970'
  };
  @observable
  crossSection?: IOption<BaseAttributes>;
  @observable
  spacing?: IOption<BaseAttributes>;

  // Step 3
  @observable
  surfaceType?: IOption<BaseAttributes>;
  @observable
  roofMaterial?: IOption<BaseAttributes>;

  // Step 4
  @observable
  deckSheathingType?: IOption<BaseAttributes>;

  // Step 5
  @observable
  maxHorizontalSpan?: number;
  @observable
  sag?: number;
  @observable
  ridgeBeamSag?: number;
  @observable
  disallowedHolesOrNotchesInStructuralMembers?: string;
  @observable
  structuralDecayOrFireDamage?: string;
  @observable
  evidenceOfLeaksDamageOrDeterioration?: string;

  @observable
  importInProgress: boolean = false;

  readonly VENDORS_LIST: Option[] = [
    {
      name: 'Eagleview',
      value: EAGLEVIEW_PROVIDER
    },
    {
      name: 'RoofOrders.com',
      value: ROOFORDERS_PROVIDER
    }
  ];

  private readonly editor: EditorStore;
  private readonly toolbar: ToolbarStore;
  private readonly roofProtrusion: RoofProtrusionStore;
  private readonly roofFramingDataFetcher = new RoofFramingDataFetcher();
  private readonly roofSurfaceDataFetcher = new RoofSurfaceDataFetcher();
  private readonly roofDeckingDataFetcher = new RoofDeckingDataFetcher();
  private readonly onModalClose?: () => void;

  constructor(dependencies: IImportFileWizardDependencies) {
    super(dependencies);
    this.editor = dependencies.editor;
    this.toolbar = dependencies.toolbar;
    this.roofProtrusion = dependencies.roofProtrusion;
    this.onModalClose = dependencies.onModalClose;
  }

  @computed
  get getRoofFramingData(): RoofFramingDataFetcher {
    return this.roofFramingDataFetcher;
  }

  @computed
  get getRoofSurfaceData(): RoofSurfaceDataFetcher {
    return this.roofSurfaceDataFetcher;
  }

  @computed
  get getRoofDeckingData(): RoofDeckingDataFetcher {
    return this.roofDeckingDataFetcher;
  }

  @computed
  get getFileName(): string {
    return !!this.file ? this.file?.name : 'Drag & Drop file';
  }

  @computed
  get showExtraFields(): boolean {
    return this.modelProvider === EAGLEVIEW_PROVIDER;
  }

  @computed
  get modalTitle(): 'Import 3D Model' | 'Roof face properties' {
    return this.step > 1 ? 'Roof face properties' : 'Import 3D Model';
  }

  @computed
  get leftButtonLabel(): 'CANCEL' | 'BACK' {
    return this.step > 1 ? 'BACK' : 'CANCEL';
  }

  @computed
  get rightButtonLabel(): 'NEXT' | 'SAVE' {
    return this.step > 4 ? 'SAVE' : 'NEXT';
  }

  @computed
  get canProceed(): boolean {
    const stepsValidation = [
      // Step 1
      this.file && this.ceilingHeight && this.foundationHeight && this.numberOfStories > 0,
      // Step 2
      this.constructionStyle && this.crossSection && Number(this.spacing?.attributes.value) > 0,
      // Step 3
      this.surfaceType && (!!this.getRoofSurfaceData.roofMaterialOptions.length ? this.roofMaterial : true),
      // Step 4
      this.deckSheathingType,
      // Step 5
      this.file && !this.importInProgress
    ];
    return !!stepsValidation[this.step - 1];
  }

  async loadOptions(): Promise<void> {
    this.roofFramingDataFetcher.loadOptions();
    this.roofSurfaceDataFetcher.loadOptions();
    this.roofDeckingDataFetcher.loadOptions();
  }

  // Step 1
  @action.bound
  setFile(tempFile?: File): void {
    this.file = !!tempFile ? tempFile : undefined;
  }

  @action.bound
  setModelProvider(value: string): void {
    this.modelProvider = value;
  }

  @action.bound
  setFoundationHeight(value: number): void {
    this.foundationHeight = value;
  }

  @action.bound
  setCeilingHeight(value: number): void {
    this.ceilingHeight = value;
  }

  @action.bound
  setNumberOfStories(value: number): void {
    this.numberOfStories = value;
  }

  // Step 2
  @action.bound
  setConstructionStyle(value: string | number): void {
    const constructionStyle = find(this.getRoofFramingData.constructionStyleOptions, findOptionByValue(value));
    if (constructionStyle) {
      this.constructionStyle = constructionStyle;
    }
  }

  @action.bound
  setYearOfConstruction(option: OptionProps): void {
    this.yearOfConstruction = option;
    this.roofFramingDataFetcher.loadCrossSectionOptions(Number(option.value));
  }

  @action.bound
  setCrossSection(option: Option): void {
    const crossSection = find(this.getRoofFramingData.crossSectionOptions, findOptionByValue(option.value));
    if (crossSection) {
      this.crossSection = crossSection;
    }
  }

  @action.bound
  setSpacing(value: string | number): void {
    const spacing = find(this.getRoofFramingData.spacingOptions, findOptionByValue(value));
    if (spacing) {
      this.spacing = spacing;
    }
  }

  @action.bound
  setOtherSpacing(value: string | number): void {
    const lastOption = this.getRoofFramingData.spacingOptions.length - 1;
    this.spacing = this.getRoofFramingData.spacingOptions[lastOption];
    this.spacing.attributes.value = `${value}`;
  }

  // Step 3
  @action.bound
  setSurfaceType(value: string | number): void {
    const surfaceType = find(this.getRoofSurfaceData.surfaceTypeOptions, findOptionByValue(value));
    if (surfaceType) {
      this.surfaceType = surfaceType;
      this.roofMaterial = undefined;
      this.roofSurfaceDataFetcher.loadRoofMaterial(surfaceType.attributes.name);
    }
  }

  @action.bound
  setRoofMaterial(value: string | number): void {
    const roofMaterial = find(this.getRoofSurfaceData.roofMaterialOptions, findOptionByValue(value));
    if (roofMaterial) {
      this.roofMaterial = roofMaterial;
    }
  }

  // Step 4
  @action.bound
  setDeckSheathingType(value: string | number): void {
    const deckSheathingType = find(this.getRoofDeckingData.deckSheathingTypeOptions, findOptionByValue(value));
    if (deckSheathingType) {
      this.deckSheathingType = deckSheathingType;
    }
  }

  // Step 5
  @action.bound
  setMaxHorizontalSpan(value: number): void {
    this.maxHorizontalSpan = value;
  }

  @action.bound
  setSag(value: number): void {
    this.sag = value;
  }

  @action.bound
  setRidgeBeamSag(value: number): void {
    this.ridgeBeamSag = value;
  }

  @action.bound
  setDisallowedHolesOrNotchesInStructuralMembers(option: Option): void {
    this.disallowedHolesOrNotchesInStructuralMembers = `${option.value}`;
  }

  @action.bound
  setStructuralDecayOrFireDamage(option: Option): void {
    this.structuralDecayOrFireDamage = `${option.value}`;
  }

  @action.bound
  setEvidenceOfLeaksDamageOrDeterioration(option: Option): void {
    this.evidenceOfLeaksDamageOrDeterioration = `${option.value}`;
  }

  @action.bound
  closeModal(): void {
    this.onModalClose?.();
    this.toolbar.deselectTool();
  }

  @action.bound
  override changeStep(value: number): void {
    this.step = this.step + value;

    // FIXME: this is bad design, it needs refactoring:

    // Setting defaults:

    if (this.defaultsWereSetOnChangeStepMap[this.step]) {
      return;
    } else {
      this.defaultsWereSetOnChangeStepMap[this.step] = true;
    }

    if (this.step === 2) {
      this.setConstructionStyle('MANUFACTURED_TRUSS');
      this.setYearOfConstruction({
        name: 'Post-1960',
        value: '1970'
      });
      this.setCrossSection({
        name: '2x4',
        value: 'L2X4_POST1960'
      });
      this.setSpacing(24);
    } else if (this.step === 4) {
      this.setDeckSheathingType('ORIENTED_STRAND_BOARD_15_32_INCH_THICK');
    } else if (this.step === 5) {
      this.setSag(0);
      this.setRidgeBeamSag(0);
      this.setDisallowedHolesOrNotchesInStructuralMembers({
        name: 'NO',
        value: 'NO'
      });
      this.setStructuralDecayOrFireDamage({
        name: 'NO',
        value: 'NO'
      });
      this.setEvidenceOfLeaksDamageOrDeterioration({
        name: 'NO',
        value: 'NO'
      });
    }
  }

  getSurfaceType(): string {
    return this.roofMaterial?.attributes.value
      ? this.roofMaterial?.attributes.value
      : this.surfaceType?.attributes.value ?? '';
  }

  @action.bound
  async import3dModel(): Promise<void> {
    this.importInProgress = true;
    try {
      const buildingsData: IBuildingData[] = await this.designService.import3dModel(
        this.file!,
        this.domain.project.site.coordinateSystemOrigin,
        this.domain.project.site.address.province,
        this.buildImportParameters()
      );
      config.analytics?.trackEvent(new ThreeDimensionalModelImportedEvent(this.domain.project.id, this.modelProvider));
      const commandDependencies: IImport3DModelCommandDependencies = {
        domain: this.domain,
        editor: this.editor,
        roofProtrusion: this.roofProtrusion,
        buildingsData
      };
      this.serviceBus.send('import_3d_model', commandDependencies);
      this.closeModal();
      this.toolbar.activateSelectionToolInProjectWorkspace();
    } catch (e) {
      handleApiError('An error has occurred while importing the 3D model.')(e);
    } finally {
      this.importInProgress = false;
    }
  }

  private buildImportParameters = (): IImport3DModelFileProps & IImport3DModelProps => {
    const buildingHeight = {
      foundationHeight: convertFeetToMeters(this.foundationHeight),
      ceilingHeight: convertFeetToMeters(this.ceilingHeight),
      numberOfStories: this.numberOfStories
    };
    return {
      modelProvider: this.modelProvider,
      ...(this.modelProvider === EAGLEVIEW_PROVIDER && {
        buildingHeight
      }),
      structure: {
        constructionStyle: this.constructionStyle?.attributes.value ?? '',
        slopingStructuralMembers: {
          crossSection: this.crossSection?.attributes.value ?? '',
          spacing: convertInchesToMeters(parseInt(this.spacing?.attributes.value ?? '', 10)),
          sag: convertInchesToMeters(this.sag ?? 0),
          ...(!!this.maxHorizontalSpan && {
            maxHorizontalSpan: convertInchesToMeters(this.maxHorizontalSpan)
          })
        },
        deckSheathingType: this.deckSheathingType?.attributes.value ?? '',
        surfaceType: this.getSurfaceType(),
        condition: {
          ridgeBeamSag: convertInchesToMeters(this.ridgeBeamSag ?? 0),
          disallowedHolesOrNotchesInStructuralMembers: this.disallowedHolesOrNotchesInStructuralMembers === 'YES',
          structuralDecayOrFireDamage: this.structuralDecayOrFireDamage === 'YES',
          evidenceOfLeaksDamageOrDeterioration: this.evidenceOfLeaksDamageOrDeterioration === 'YES'
        }
      }
    };
  };
}
