import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Gallery from '../../../../../components/Gallery/Gallery';
import type { ISectionProps } from '../ImagesAndDocuments';
import { Message } from '../../styles';
import {
  ImagesContainer, GalleryHeader, MainHeader, SubHeader
} from './SiteImagesSection.styles';

function SiteImagesSection({ viewModel }: ISectionProps) {
  const toggleImageSelection = useCallback(
    (imageId: string) => {
      viewModel.toggleImageSelected(imageId);
    },
    [viewModel]
  );

  return (
    <ImagesContainer>
      <GalleryHeader>
        <MainHeader>Site Images</MainHeader>
        <SubHeader>(check/uncheck images to include them to Permit Package)</SubHeader>
      </GalleryHeader>

      <Message>
        Upload site images in <strong>Site Definition</strong> under roof face and/or MSP properties if this project
        requires additional site imagery.
      </Message>

      <Gallery items={viewModel.siteImagesAsGalleryItems} onItemCheck={toggleImageSelection} />
    </ImagesContainer>
  );
}

export default observer(SiteImagesSection);
