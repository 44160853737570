import Registry from '../../../utils/Registry';
import Import3DModelModal from '../Import3DModelModal/Import3DModelModal';
import NewLayoutStrategyModal from './LayoutStrategyModal/NewLayoutStrategyModal';
import MountingSystemDefinitionsModal from './MountingSystemDefinitionsModal/MountingSystemDefinitionsModal';
import NewRoofFaceModal from './NewRoofFaceModal/NewRoofFaceModal';
import SelectBuildingModal from './SelectBuildingModal/SelectBuildingModal';
import NewSystemDesignModal from './SystemDesignModal/NewSystemDesignModal';
import NewSystemSizeModal from './SystemSizeModal/NewSystemSizeModal';
import NewBuildingModal from './NewBuildingModal/NewOrEditBuildingModal';

const wizardRegistry = new Registry();

wizardRegistry.add('select_building_modal', SelectBuildingModal);
wizardRegistry.add('new_or_edit_building_modal', NewBuildingModal);
wizardRegistry.add('new_roof_face_modal', NewRoofFaceModal);
wizardRegistry.add('new_system_size_modal', NewSystemSizeModal);
wizardRegistry.add('new_system_design_modal', NewSystemDesignModal);
wizardRegistry.add('new_layout_strategy_modal', NewLayoutStrategyModal);
wizardRegistry.add('mounting_system_definitions_modal', MountingSystemDefinitionsModal);
wizardRegistry.add('import_file_modal', Import3DModelModal);

export { wizardRegistry };
