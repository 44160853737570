enum ECursor {
  DEFAULT = 'DEFAULT_CURSOR',
  SELECTION = 'SELECTION_CURSOR',
  PANNING = 'PANNING_CURSOR',
  FIRST_TRACE = 'FIRST_TRACE_CURSOR',
  TRACE = 'TRACE_CURSOR',
  FORBIDDEN = 'FORBIDDEN_CURSOR'
}

export default ECursor;
