import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { useUiStore } from '../../../stores/useStore';
import config, { UI_MODE } from '../../../config/config';
import { panelRegistry } from './registry';

const Panel = observer((): ReactElement => {
  const { panels } = useUiStore();
  // Panel to be rendered
  const PanelUI = panels.viewModel && panelRegistry.get(panels.viewModel.propCodeUI);
  // Equipment panel in Aurora mode will be shown inside Electrical BOS form, it's not needed on canvas
  const isEquipmentPanelInAuroraMode =
    panels.viewModel?.propCodeUI === 'equipment_panel' && config.featureFlag.uiMode === UI_MODE.AURORA;

  return <>{PanelUI && !isEquipmentPanelInAuroraMode && <PanelUI />}</>;
});

export default Panel;
