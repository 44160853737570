// @ts-ignore
import predicateObject from 'predicate';
import type {
  JSONSchema7, JSONSchema7Type, JSONSchema7Definition
} from 'json-schema';

import type { Enclosure } from '../effects/RecentValuesRegistry';
import type {
  FormData, FormFieldValue
} from '../FormOptionsRulesAndState';
import type { EffectParamKey } from '../interpreters/rules';
import { findFormSchemaField } from '../effects/util';
import {
  isObject, toError, selectRef, toArray
} from './util';

export type EngineRule = {
  readonly conditions: EngineCondition;
  readonly event: EngineEvent | EngineEvent[];
}

export type EngineEvent = {
  readonly type: string;
  readonly params: EventParams;
}

export type EngineCondition = EngineConditionEquals
                | EngineConditionNotEquals
                | EngineConditionEqualsOneOf
                | EngineConditionIncludesAllTags
                | EngineCombinedCondition
                | {alwaysField: 'always'}
                | {neverField: 'never'}
                | {[key: string]: EngineCondition};

export type EngineConditionEquals = {
  readonly is: FormFieldValue
}
export type EngineConditionNotEquals = {
  readonly not: {
    readonly equal: FormFieldValue
  }
}
export type EngineConditionEqualsOneOf = {
  readonly or: readonly EngineConditionEquals[]
}
export type EngineConditionIncludesAllTags = {
  readonly includesAllTags: readonly FormFieldValue[]
}
export type EngineCombinedCondition = {
  [key: string]: EngineCondition
}

type EventParams = {
  [key in EffectParamKey]?: FormFieldValue | FormFieldValue[];
} & {
  enclosure: Enclosure;
  conditions: EngineCondition;
};

class Engine {
  private rules: EngineRule[];
  private schema: JSONSchema7;
  private enclosure: Enclosure;

  // Leave in constructor (for param order) but do not pass in rules.
  // The calling code actually does this in every case.
  constructor(
    rules: EngineRule[] | null,
    schema: JSONSchema7,
    enclosure: Enclosure
  ) {
    this.rules = [];
    this.schema = schema;
    this.enclosure = enclosure;

    if (rules) {
      toArray(rules).forEach((rule): void => this.addRule(rule));
    }
  }

  addRule = (rule: EngineRule): void => {
    this.rules.push(rule);
  };

  run = (formData: FormData): Promise<EngineEvent[]> => {
    return Promise.resolve(this.applicableActions(formData));
  };

  applicableActions = (formData: FormData): EngineEvent[] => {
    return this.rules.flatMap(({
      conditions, event
    }) => {
      if (this.conditionMet(
        conditions, formData
      )) {
        return toArray(event);
      } else {
        return [];
      }
    });
  };

  conditionMet = (
    condition: EngineCondition, formData: FormData
  ): boolean => {
    if (!isObject(condition) || !isObject(formData)) {
      toError(`Condition ${JSON.stringify(condition)} with form data ${formData} can't be processed!`);
      return false;
    }
    // assume every condition must be met
    return Object.keys(condition).every((ref): boolean => {
      const refCondition = condition[ref as keyof EngineCondition];
      if (ref === 'or') {
        return (refCondition as EngineCondition[]).some((rule: EngineCondition) => this.conditionMet(
          rule, formData
        ));
      } else if (ref === 'and') {
        return (refCondition as EngineCondition[]).every((rule: EngineCondition) => this.conditionMet(
          rule, formData
        ));
      } else if (ref === 'not') {
        return !this.conditionMet(
          refCondition, formData
        );
      } else if (ref === 'alwaysField') {
        return true;
      } else if (ref === 'neverField') {
        return false;
      } else {
        const refVal: FormData = selectRef(
          ref, formData
        );
        // This seems to be just their arbitrary way of checking array values (assuming they are an OR)
        if (Array.isArray(refVal)) {
          const condMetOnce = refVal.some((val): boolean => (isObject(val) ? this.conditionMet(
            refCondition, val
          ) : false));
          // It's either true for an element in an array or for the whole array
          return (
            condMetOnce
            || this.checkField(
              refVal,
              toRelCondition(
                refCondition, formData
              ),
              findFormSchemaField(ref.split('.')[1], this.schema.properties)
            )
          );
        } else {
          return this.checkField(
            refVal,
            toRelCondition(
              refCondition, formData
            ),
            findFormSchemaField(ref.split('.')[1], this.schema.properties)
          );
        }
      }
    });
  };

  checkField = (
    fieldVal: JSONSchema7Type,
    rule: EngineCondition,
    fieldSchema?: JSONSchema7Definition
  ): boolean => {
    if (!fieldSchema) {
      return false;
    }
    if (isObject(rule)) {
      return Object.keys(rule).every((predicate): boolean => {
        const subRule = rule[predicate as keyof EngineCondition];
        if (predicate === 'or' || predicate === 'and') {
          if (Array.isArray(subRule)) {
            if (predicate === 'or') {
              return (subRule as EngineCondition[]).some((rule): boolean => this.checkField(
                fieldVal, rule, fieldSchema
              ));
            } else {
              return (subRule as EngineCondition[]).every((rule): boolean => this.checkField(
                fieldVal, rule, fieldSchema
              ));
            }
          } else {
            return false;
          }
        } else if (predicate === 'not') {
          return !this.checkField(
            fieldVal, subRule, fieldSchema
          );
        } else if (predicateObject[predicate]) {
          return predicateObject[predicate](
            fieldVal, subRule, fieldSchema, this.enclosure
          );
        } else {
          return false;
        }
      });
    } else {
      // Unary operators only
      return predicateObject[rule](
        fieldVal, fieldSchema, this.enclosure
      );
    }
  };
}

const toRelCondition = (
  refCondition: EngineCondition | EngineCondition[] | FormFieldValue,
  formData: FormData
) => {
  if (Array.isArray(refCondition)) {
    return refCondition.map((cond: EngineCondition): EngineCondition | EngineCondition[] => toRelCondition(
      cond, formData
    ));
  } else if (isObject(refCondition)) {
    const res = Object.keys(refCondition).reduce(
      (
        agg: EngineCondition, field: string
      ): EngineCondition => {
        (
          agg[field as keyof EngineCondition] as EngineCondition | EngineCondition[] | FormFieldValue
        ) = toRelCondition(
              refCondition[field as keyof EngineCondition], formData
            );
        return agg;
      }, {}
    );
    return res;
  } else if (typeof refCondition === 'string' && refCondition.startsWith('$')) {
    return selectRef(
      refCondition.substring(1), formData
    );
  }
  return refCondition;
};

export default Engine;
