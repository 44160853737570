import {
  action, computed, observable
} from 'mobx';

export interface IParticipantData {
  roles: string[];
  entity: IExternallyDefinedParticipant | IPersonParticipant;
}

/**
 * Project participant that is defined by an ID which references some external entity.
 */
interface IExternallyDefinedParticipant {
  externalId: string;
}

/**
 * Project participant that is a person.
 */
export interface IPersonParticipant {
  firstName?: string;
  lastName: string;
}

class Participant {
  private static readonly PROPERTY_OWNER_ROLE = 'PROPERTY_OWNER';
  private static readonly SYSTEM_OWNER_ROLE = 'SYSTEM_OWNER';
  private static readonly INSTALLER_ROLE = 'INSTALLER';

  @observable
  private roles: string[];
  @observable
  private entity: IExternallyDefinedParticipant | IPersonParticipant;

  constructor(data: IParticipantData) {
    this.roles = data.roles;
    this.entity = data.entity;
  }

  toData(): IParticipantData {
    return {
      roles: this.roles,
      entity: this.entity
    };
  }

  static customer = (customer: IPersonParticipant): Participant =>
    new Participant({
      roles: [Participant.PROPERTY_OWNER_ROLE, Participant.SYSTEM_OWNER_ROLE],
      entity: customer
    });

  @computed
  get isInstaller(): boolean {
    return this.roles.includes(Participant.INSTALLER_ROLE);
  }

  @computed
  get installerId(): string {
    return (this.entity as IExternallyDefinedParticipant).externalId;
  }

  @computed
  get isCustomer(): boolean {
    return (
      this.roles.length === 2
      && this.roles.includes(Participant.PROPERTY_OWNER_ROLE)
      && this.roles.includes(Participant.SYSTEM_OWNER_ROLE)
    );
  }

  @action
  update = (customer: IPersonParticipant): void => {
    this.entity = customer;
  };
}

export class Participants {
  @observable
  private participants: Participant[];

  constructor(data: IParticipantData[]) {
    this.participants = data.map((value: IParticipantData): Participant => new Participant(value));
  }

  toData = (): IParticipantData[] => this.participants.map((value: Participant): IParticipantData => value.toData());

  @action
  updateCustomer = (customer: IPersonParticipant): void => {
    const existingParticipant = this.participants.find((participant: Participant): boolean => participant.isCustomer);
    if (existingParticipant) {
      existingParticipant.update(customer);
    } else {
      this.participants.push(Participant.customer(customer));
    }
  };

  @computed
  get installerId(): string {
    return this.participants.find((participant: Participant): boolean => participant.isInstaller)!.installerId;
  }
}
