import {
  LyraButtonGroup, LyraDivider, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { BillOfMaterialsViewModel } from '../../../../stores/UiStore/Modal/ViewModels/BillOfMaterials/BillOfMaterialsViewModel';
import { useUiStore } from '../../../../stores/useStore';

const BillOfMaterialsModal = (): ReactElement => {
  const { modal } = useUiStore();
  const viewModel = modal.viewModel as BillOfMaterialsViewModel;

  const modalProps = {
    title: 'Options',
    leftButtonLabel: 'Cancel',
    rightButtonLabel: 'Download',
    $show: true,
    width: '470px',
    onClickCloseButton: (): void => viewModel.closeModal(),
    onClickLeftButton: (): void => viewModel.closeModal(),
    onClickRightButton: (): void => viewModel.downloadBillOfMaterialsDocument()
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <LyraTypography.TextLayout type="h2" margin={'10px 0 0'} lineHeight={1.5} align="center">
        Bill of Materials
      </LyraTypography.TextLayout>
      <LyraDivider.Divider className="mt-xs mb-xs" color={EThemeComponentColor.GRAY_200} direction="horizontal" />
      <Flexbox justify="space-between">
        <Flexbox>
          <LyraTypography.TextLayout fontSize="14px" className="col-sm-6">
            Document Format
          </LyraTypography.TextLayout>
        </Flexbox>
        <LyraButtonGroup.ButtonGroup
          height="30px"
          options={viewModel.documentFormatOptions}
          selected={viewModel.documentFormat.value}
          onSelect={(value: string | number): void => viewModel.setDocumentFormat(value as string)}
        />
      </Flexbox>
    </LyraModal.Modal>
  );
};

export default observer(BillOfMaterialsModal);
