import type {
  CSSRule, PropsTheme
} from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

type OptionProps = {
  selected?: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export const OptionStyle = styled.li`
  ${({ theme }: PropsTheme<OptionProps>): CSSRule => theme.mixins.wrapperFill}
  ${({ selected }: PropsTheme<OptionProps>): string =>
    selected
      ? `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
       transform: scale(1.02);`
      : ''}
  border: 1px solid ${({
    theme, selected
  }: PropsTheme<OptionProps>): Color =>
    selected ? theme.colors.primary : theme.colors.tertiary};
  box-sizing: border-box;
  color: ${({ theme }: PropsTheme<OptionProps>): Color => theme.colors.secondaryFont};
  cursor: pointer;
  list-style: none;
  margin: 4px 0 6px;
  padding: 10px 6px;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 14px;
  line-height: 1.14;

  :hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }
`;
