import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import Field from '../../../ui/components/Field';

type FieldsetProp = {
  width: string;
  hideLabel?: boolean;
};

export const FieldsetInlineStyle = styled(Field)<FieldsetProp>`
  width: ${({ width }: FieldsetProp): string => width};
  display: ${({ hideLabel }: PropsTheme<FieldsetProp>): string => (!hideLabel ? 'inline' : 'none')};
`;

export const FieldsetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;
