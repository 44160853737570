import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Drawable } from './Drawable';

export const Unzoomable = <T extends AnyConstructor<Drawable>>(UnzoomableBase: T) => {
  abstract class MixinClass extends UnzoomableBase {
    isUnzoomable = true;
    abstract unzoom(factor: number): void;
  }

  return MixinClass;
};

export type Unzoomable = Mixin<typeof Unzoomable>;
