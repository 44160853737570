import styled, { withTheme } from 'styled-components';
import { Tool } from './Tool';
import { WIDTH_TOOL } from './constants';

type SubToolProps = {
  visible?: boolean;
};

const SubTool = styled(Tool)<SubToolProps>`
  ${({ visible = false }: SubToolProps): string => {
    if (visible) {
      return `left: ${WIDTH_TOOL};
      opacity: 1;
      transition: opacity .5s linear;`;
    } else {
      return `left: -${WIDTH_TOOL};
      opacity: 0;
      transition: left .3s ease-in, opacity .3s linear;`;
    }
  }}
  border-radius: 2px;
  display: flex;
  position: absolute;
  padding-left: 5px;
  top: -8px;
  z-index: 1;
  margin-left: -3px;
`;

export { SubTool };
