import {
  BufferAttribute, BufferGeometry
} from 'three';

// Library to calculate the expansion of the line
const getNormalsEdges = require('polyline-normals');
const VERTS_PER_POINT = 2;

interface ILine3DGeometryOption {
  closed?: boolean;
  distances?: boolean;
}

class Line3DGeometry extends BufferGeometry {
  constructor(vertices: number[][], options: ILine3DGeometryOption = {}) {
    super();

    const emptyArray = new Float32Array([]);
    this.setAttribute('position', new BufferAttribute(emptyArray, 3));
    this.setAttribute('lineNormal', new BufferAttribute(emptyArray, 2));
    this.setAttribute('lineMiter', new BufferAttribute(emptyArray, 1));

    if (options.distances) {
      this.setAttribute('lineDistance', new BufferAttribute(emptyArray, 1));
    }

    if (this.isSetIndexIsFunction()) {
      this.setIndex(new BufferAttribute(emptyArray, 1));
    } else {
      this.setAttribute('index', new BufferAttribute(emptyArray, 1));
    }

    this.update(vertices, options.closed);
  }

  update(vertices: number[][], closed: boolean = false): void {
    const normals = getNormalsEdges(vertices, closed);
    let path = vertices;

    if (closed) {
      path = path.slice();
      path.push(path[0]);
      normals.push(normals[0]);
    }

    const attrPosition = this.getAttribute('position') as BufferAttribute;
    const attrNormal = this.getAttribute('lineNormal') as BufferAttribute;
    const attrMiter = this.getAttribute('lineMiter') as BufferAttribute;
    const attrDistance = this.getAttribute('lineDistance') as BufferAttribute;
    const attrIndex: BufferAttribute = (
      this.isSetIndexIsFunction() ? this.getIndex() : this.getAttribute('index')
    ) as BufferAttribute;

    const indexCount = Math.max(0, (path.length - 1) * 6);
    const attrpositionArray = attrPosition.array as unknown as number[];
    let count: number = 0;
    if (!attrpositionArray || path.length !== attrpositionArray.length / 3 / VERTS_PER_POINT) {
      count = path.length * VERTS_PER_POINT;
      attrPosition.array = new Float32Array(count * 3);
      attrNormal.array = new Float32Array(count * 2);
      attrMiter.array = new Float32Array(count);
      attrIndex.array = new Uint16Array(indexCount);

      if (attrDistance) {
        attrDistance.array = new Float32Array(count);
      }
    }

    if (undefined !== attrPosition.count) {
      // @ts-ignore
      attrPosition.count = count;
    }
    attrPosition.needsUpdate = true;

    if (undefined !== attrNormal.count) {
      // @ts-ignore
      attrNormal.count = count;
    }
    attrNormal.needsUpdate = true;

    if (undefined !== attrMiter.count) {
      // @ts-ignore
      attrMiter.count = count;
    }
    attrMiter.needsUpdate = true;

    if (undefined !== attrIndex.count) {
      // @ts-ignore
      attrIndex.count = indexCount;
    }
    attrIndex.needsUpdate = true;

    if (attrDistance) {
      if (undefined !== attrDistance.count) {
        // @ts-ignore
        attrDistance.count = count;
      }
      attrDistance.needsUpdate = true;
    }

    let index = 0;
    let c = 0;
    let dIndex = 0;
    const indexArray = attrIndex.array as unknown as number[];

    path.forEach((point: number[], pointIndex: number, list: number[][]): void => {
      const i = index;
      indexArray[c++] = i + 0;
      indexArray[c++] = i + 1;
      indexArray[c++] = i + 2;
      indexArray[c++] = i + 2;
      indexArray[c++] = i + 1;
      indexArray[c++] = i + 3;

      attrPosition.setXYZ(index++, point[0], point[1], point[2]);
      attrPosition.setXYZ(index++, point[0], point[1], point[2]);

      if (attrDistance) {
        const d = pointIndex / (list.length - 1);
        attrDistance.setX(dIndex++, d);
        attrDistance.setX(dIndex++, d);
      }
    });

    let nIndex = 0;
    let mIndex = 0;
    normals.forEach((n: number[][] | number[]): void => {
      const norm: number[] = n[0] as number[];
      const miter: number = n[1] as number;
      attrNormal.setXY(nIndex++, norm[0], norm[1]);
      attrNormal.setXY(nIndex++, norm[0], norm[1]);

      attrMiter.setX(mIndex++, -miter);
      attrMiter.setX(mIndex++, miter);
    });
  }

  private isSetIndexIsFunction(): boolean {
    return typeof this.setIndex === 'function';
  }
}

export default Line3DGeometry;
