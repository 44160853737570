import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type { ReactText } from 'react';
import { MathUtils as ThreeMath } from 'three';
import { handleApiError } from '../../../../../utils/helpers';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import { ROOF_DECKING_CODE } from '../../../../../ui/containers/RoofFaceProperties/constants';
import {
  UpdateRoofFacePropertyKey,
  type IUpdateRoofFacePropertyDependencies
} from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';
import type {
  IOption, BaseAttributes
} from '../../../../../domain/models/SiteDesign/IOption';
import { deckingKeyToOption } from './constants';

export class RoofDeckingViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = ROOF_DECKING_CODE;

  @observable
  listDeckingTypes: Option[] = observable([]);

  private readonly designService = new DesignService();

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.DeckSheathingType;
  }

  @action.bound
  setCurrentType(value: ReactText): void {
    const optionSelected: Option | undefined = this.listDeckingTypes.find(
      (option: Option): boolean => option.value === value
    );
    this.changeValue(optionSelected as RoofFace[keyof RoofFace]);
  }

  @computed
  get selectName(): string {
    const current: Option = this.currentValue as Option;
    return current.name.toString();
  }

  @computed
  get selectedType(): string {
    const current: Option = this.currentValue as Option;
    return current.value.toString();
  }

  override saveProperty(): void {
    const {
      domain, roofFaceProps, property
    } = this;
    const dependencies: IUpdateRoofFacePropertyDependencies = {
      domain,
      roofFace: roofFaceProps.currentSelectedRoofFaces[0],
      key: UpdateRoofFacePropertyKey.DeckSheathingType,
      value: (this.currentValue as Option)!.value
    };
    this.serviceBus.send('update_roof_face_property', dependencies);
    roofFaceProps.reset();
    this.roofFaceProps.lastInteractedRoofFace = this.roofFace;
  }

  async loadDeckingTypes(): Promise<void> {
    const data: IOption<BaseAttributes>[] = await this.designService
      .getDeckSheatingTypeOptions()
      .catch(handleApiError('Failed to fetch decking types data'));
    runInAction((): void => {
      this.listDeckingTypes = this.mapToItemOption(data);
    });
  }

  protected mapToItemOption(options: IOption<BaseAttributes>[]): Option[] {
    return options.map(
      (option: IOption<BaseAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  protected override calculateCurrentValue(): RoofFace[keyof RoofFace] {
    const { deckSheathingType } = this.roofFace;
    return deckingKeyToOption[deckSheathingType].attributes as RoofFace[keyof RoofFace];
  }
}
