import {
  action, observable
} from 'mobx';
import { saveAs } from 'file-saver';
import type { IBaseViewModelDependencies } from '../../../../../stores/UiStore/Modal/BaseViewModel';
import { BaseViewModel } from '../../../../../stores/UiStore/Modal/BaseViewModel';
import {
  handleApiError, notify
} from '../../../../../utils/helpers';
import { CONFIRMATION } from '../../../../../domain/models/Constants';
import { getRootStore } from '../../../../../stores/RootStoreInversion';

type PreviewPlanSetViewModelDependencies = IBaseViewModelDependencies & {
  fileURL: string;
  downloadURL: string;
};

export class PlanSetPreviewPageViewModel extends BaseViewModel {
  readonly propCodeUI = 'plan_set_preview';

  readonly fileURL: string;
  readonly downloadURL: string;
  planSetFileDirectDownloadFallbackURL = '';
  @observable
  embedDimensions = {
    width: 0,
    height: 0
  };
  @observable
  isDownloading: boolean = false;

  constructor({
    fileURL, downloadURL, ...baseDeps
  }: PreviewPlanSetViewModelDependencies) {
    super(baseDeps);
    this.fileURL = fileURL;
    this.downloadURL = downloadURL;
  }

  @action.bound
  resizeEmbed = (newWidth: number, newHeight: number): void => {
    this.embedDimensions = {
      width: newWidth,
      height: newHeight
    };
  };

  downloadPlanSet = async (): Promise<void> => {
    this.isDownloading = true;
    const loadingBannerId = 'Generating your instant plan set';
    notify(loadingBannerId, 'loading');
    try {
      const permitPackageDownloadUrl = this.downloadURL;
      const url = await this.documentsService
        .getPermitPackageLink(permitPackageDownloadUrl)
        .catch(handleApiError('Failed to generate plan set preview'));
      this.planSetFileDirectDownloadFallbackURL = url;
    } catch (e) {
      getRootStore().auroraNotificationManager.remove(loadingBannerId);
      this.isDownloading = false;
      return;
    }
    try {
      const {
        file, fileName
      } = await this.documentsService
        .getPermitPackageDoc(this.planSetFileDirectDownloadFallbackURL)
        .catch(handleApiError('Failed to download plan set'));
      saveAs(file, fileName);
    } catch (e) {
      getRootStore().auroraNotificationManager.remove(loadingBannerId);
      this.isDownloading = false;
      return;
    }
    getRootStore().auroraNotificationManager.remove(loadingBannerId);
    notify('Plan set downloaded successfully', CONFIRMATION);
    this.isDownloading = false;
  };

  override dispose(): void {
    // not implemented
  }
}
