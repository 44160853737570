import React, {
  BaseProps,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Option } from '../../typings/Option';
import { Flexbox } from '../Layout/Flexbox';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import {
  OptionStyle
} from './styles';

export type Props = BaseProps & {
  options: Option[];
  selected?: string | number;
  selectedOptionColor?: EThemeComponentColor | undefined;
  onSelect?: (value: string | number) => void;
  dir?: 'row' | 'column';
};

const List = ({
  dir= 'column',
  options,
  selected,
  onSelect,
  selectedOptionColor,
  className = ''
}: Props): ReactElement => {
  const [selVal, setSelVal] = useState(selected);
  const handleSelect = useCallback((option: Option): void => {
    if (!option.disabled) {
      if (typeof onSelect === 'function') {
        onSelect(option.value);
      }
      setSelVal(option.value);
    }
  }, [options]);
  useEffect((): void => {
    setSelVal(selected);
  }, [selected]);

  const optionsElements = options.map((
      option: Option,
      index: number
    ): ReactElement =>
    <OptionStyle
      key={index}
      selected={option.value === selVal}
      disabled={option.disabled}
      onClick={handleSelect.bind(null, option)}
      selectedOptionColor={selectedOptionColor}
    >
      {option.name}
    </OptionStyle>
  );

  return (
    <Flexbox
      className={className}
      dir={dir}
    >
      {optionsElements}
    </Flexbox>
  );
};

export { List };
