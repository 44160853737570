import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  Fragment, useState
} from 'react';
import sumBy from 'lodash/sumBy';
import type {
  ISeriesStringConfigurationOptionsData,
  IStringingOptionsResponseData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import type { SystemSummaryPanelViewModel } from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import { useUiStore } from '../../../../stores/useStore';
import { precisionNumber } from '../../../../utils/helpers';
import {
  ContentItem,
  DownArrow,
  ItemText,
  PanelHeader,
  PanelText,
  RightArrow,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperValueText
} from './styles';

const SystemPanel = observer((): ReactElement => {
  const { panels } = useUiStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const { stringingOptions } = systemSummaryViewModel;

  const totalAcPowerOutput = sumBy(stringingOptions, (item: IStringingOptionsResponseData): number => {
    return (item as ISeriesStringConfigurationOptionsData)?.inverterDefinition?.ratedPowerOutput ?? 0;
  });

  const totalAcOutputCurrent = sumBy(stringingOptions, (item: IStringingOptionsResponseData): number => {
    return (item as ISeriesStringConfigurationOptionsData)?.inverterDefinition?.maxContinuousOutputCurrent ?? 0;
  });

  const essRatedOutputCurrent = sumBy(stringingOptions, (item: IStringingOptionsResponseData): number => {
    return item?.essRatedOutputCurrent ?? 0;
  });

  const [systemExpanded, setSystemExpanded] = useState(true);

  return (
    <Fragment>
      <PanelHeader onClick={(): void => setSystemExpanded(!systemExpanded)}>
        <PanelText>System</PanelText>
        {systemExpanded ? <DownArrow name="arrow-collapse" /> : <RightArrow name="arrow-collapse" />}
      </PanelHeader>
      {systemExpanded && (
        <ContentItem>
          <ValueItemWrapper bordered>
            <WrapperItemText>
              <ItemText>PV System AC Power Output</ItemText>
            </WrapperItemText>
            <WrapperValueText>
              <ValueText>{totalAcPowerOutput}</ValueText>
              <UnitText>W</UnitText>
            </WrapperValueText>
          </ValueItemWrapper>
          <ValueItemWrapper bordered>
            <WrapperItemText>
              <ItemText>PV System AC Current Output</ItemText>
            </WrapperItemText>
            <WrapperValueText>
              <ValueText>{totalAcOutputCurrent}</ValueText>
              <UnitText>A</UnitText>
            </WrapperValueText>
          </ValueItemWrapper>
          {essRatedOutputCurrent > 0 && (
            <>
              <ValueItemWrapper bordered>
                <WrapperItemText>
                  <ItemText>ESS AC Current Output</ItemText>
                </WrapperItemText>
                <WrapperValueText>
                  <ValueText>{precisionNumber(essRatedOutputCurrent, 2)}</ValueText>
                  <UnitText>A</UnitText>
                </WrapperValueText>
              </ValueItemWrapper>
              <ValueItemWrapper bordered>
                <WrapperItemText>
                  <ItemText>Total AC Current Output</ItemText>
                </WrapperItemText>
                <WrapperValueText>
                  <ValueText>{precisionNumber(totalAcOutputCurrent + essRatedOutputCurrent, 2)}</ValueText>
                  <UnitText>A</UnitText>
                </WrapperValueText>
              </ValueItemWrapper>
            </>
          )}
        </ContentItem>
      )}
    </Fragment>
  );
});

export default SystemPanel;
