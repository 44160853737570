import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import type { PropsTheme } from 'styled-components';
import Collapse from '../../components/Collapse';
import useStore from '../../../stores/useStore';
import ImagesPreview from '../../components/ImagesPreview/ImagesPreview';
import type { SiteImage } from '../../../domain/models/SiteDesign/SiteImage';
import {
  BasicPropsContainer, AddImageButton
} from './styles';

type SiteImageContainerProps = PropsTheme & {
  images: SiteImage[];
  onAddImage: () => void;
  onDeleteImage?: (image: SiteImage) => void;
};

const SiteImagesContainer: React.FC<SiteImageContainerProps> = (props: SiteImageContainerProps): ReactElement => {
  const {
    images, onAddImage, onDeleteImage
  } = props;
  const { domain } = useStore();

  return (
    <BasicPropsContainer>
      <Collapse title="Images" open={images.length > 0} color={props.theme.colors.secondaryFont}>
        <ImagesPreview
          images={images ?? []}
          projectId={domain.project.id}
          accountId={domain.project.account}
          onDeleteClick={onDeleteImage}
        />

        <AddImageButton onClick={onAddImage}>Add Image(s)</AddImageButton>
      </Collapse>
    </BasicPropsContainer>
  );
};

export default observer(SiteImagesContainer);
