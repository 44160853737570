import type Store from '../../../../../stores/Store';
import { AddModuleTool } from '../../../../../stores/UiStore/ToolbarStore/Design/AddModuleTool';
import type { IAddModuleToolDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/AddModuleTool';
import withTool from '../../Shared/withTool';

type AddModuleToolDependencies = Pick<
  IAddModuleToolDependencies,
  'properties' | 'domain' | 'serviceBus' | 'workspace' | 'smartGuides'
>;

const AddModule = withTool<AddModuleTool, IAddModuleToolDependencies, AddModuleToolDependencies>(
  AddModuleTool,
  ({
    properties, domain, serviceBus, workspace, smartGuides
  }: Store): AddModuleToolDependencies => ({
    properties,
    domain,
    serviceBus,
    workspace,
    smartGuides
  })
);

export default AddModule;
