import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useUiStore } from '../../../../../stores/useStore';
import type {
  OptionType,
  RowProps,
  TableHeaderType,
  VoltageDropSummary,
  CircuitTableViewModel
} from '../../viewModels/CircuitTable/CircuitTableViewModel';
import './custom.css';

import {
  DataInput,
  DataSelect,
  PlainText,
  RowItem,
  TableHeaderWrapper,
  TableRowWrapper,
  TableWrapper,
  Table
} from './styles';

const getTableItemClass = (columnIndex: number, columns: number): string => {
  if (columnIndex === 0) {
    return 'firstItem';
  } else if (columnIndex === columns) {
    return 'lastItem';
  } else {
    return '';
  }
};

const CircuitTable = observer(
  ({ circuitTableViewModel }: { circuitTableViewModel: CircuitTableViewModel }): ReactElement => {
    const {
      circuitData, tableData, headerItems, optionChange, inputChange, errors, disableAllFields
    } =
      circuitTableViewModel;
    const DS = useContext(ThemeContext)!.DS;

    return (
      <>
        <DS.Text text="h2" px={44}>
          Circuit table
        </DS.Text>
        <DS.Grid flex="1" px={44} gap={0}>
          {circuitData.circuitVoltageDrops.map(
            (circuitVoltageDrop: VoltageDropSummary): ReactElement => (
              <DS.GridItem display="flex" gap={3} key={circuitVoltageDrop.id}>
                <DS.Flex flexDirection="column" flexWrap="wrap" justifyContent="start" gap="0">
                  <DS.Text text="body12" mt={4}>
                    {circuitVoltageDrop.title}
                  </DS.Text>
                  <DS.Text text="body20" mb={6}>
                    {circuitVoltageDrop.value}
                  </DS.Text>
                </DS.Flex>
              </DS.GridItem>
            )
          )}
        </DS.Grid>
        <TableWrapper>
          <Table>
            <TableHeaderWrapper>
              {headerItems?.map(
                (item: TableHeaderType, index: number, tableHeaderTypes: TableHeaderType[]): ReactElement => (
                  <DS.Text
                    text="body12"
                    key={index}
                    color={DS.BorealisTheme.colors['gray-600']}
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle'
                    }}
                    textAlign={item.align ?? 'center'}
                    pl={index ? 0 : 40}
                    pr={index !== tableHeaderTypes.length - 1 ? 0 : 40}
                  >
                    {item.id === 'number' ? 'No.' : item.title}
                  </DS.Text>
                )
              )}
            </TableHeaderWrapper>
            {tableData.map(
              (data: RowProps, index: number): ReactElement => (
                <TableRowWrapper key={index}>
                  {headerItems.map(
                    (
                      header: TableHeaderType,
                      columnIndex: number,
                      tableHeaderTypes: TableHeaderType[]
                    ): ReactElement => {
                      const id: keyof typeof data = header.id;
                      const propertyKeys = data[id]?.id.split('.') ?? [];
                      const rowHasFieldInColumn = !!propertyKeys[1];
                      const values: OptionType[] = data[id]?.options ?? [];
                      const hasOptionsProperty: boolean = !!data[id]?.options;
                      const hasOneOrNoOptions: boolean = hasOptionsProperty && data[id]!.options!.length <= 1;
                      const isReadOnlyField: boolean =
                        (!hasOptionsProperty && data[id]?.readOnly) || !rowHasFieldInColumn || hasOneOrNoOptions;
                      const isInputField = !hasOptionsProperty;
                      return (
                        <React.Fragment key={columnIndex}>
                          <RowItem className={getTableItemClass(columnIndex, tableHeaderTypes.length - 1)}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isReadOnlyField ? (
                              <PlainText
                                data-testid={`${index}.${data[id]?.id}`}
                                className={rowHasFieldInColumn && data[id]?.class ? data[id]?.class : 'default'}
                                align={header.align}
                              >
                                {rowHasFieldInColumn ? values[0]?.name || data[id]?.value : 'N/A'}
                              </PlainText>
                            ) : isInputField ? (
                              <DataInput
                                data-testid={`${index}.${data[id]?.id}`}
                                type="number"
                                defaultValue={data[id]?.value}
                                value={data[id]?.value}
                                className={data[id]?.class || 'default'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                  inputChange(event.target.value, index, columnIndex, propertyKeys);
                                }}
                                error={!!errors[index]?.[columnIndex]}
                                disabled={disableAllFields}
                              />
                            ) : (
                              <DataSelect
                                data-testid={`${index}.${data[id]?.id}`}
                                value={data[id]?.value}
                                className={data[id]?.class || 'default'}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
                                  optionChange(event, propertyKeys);
                                }}
                                disabled={disableAllFields}
                              >
                                {values?.map(
                                  (dropdown: OptionType, optionIndex: number): ReactElement => (
                                    <option value={dropdown.value} key={optionIndex}>
                                      {dropdown.name || dropdown.value}
                                    </option>
                                  )
                                )}
                              </DataSelect>
                            )}
                          </RowItem>
                        </React.Fragment>
                      );
                    }
                  )}
                </TableRowWrapper>
              )
            )}
          </Table>
        </TableWrapper>
      </>
    );
  }
);

const PageWrapper = styled.div`
  width: 100%;
  padding-block: 24px;
`;

const CircuitTableAuroraDesignPage = (): ReactElement => {
  const { pages } = useUiStore();

  if (!pages.pageViewModel) {
    // eslint-disable-next-line no-console
    console.error('Circuit connections data/viewModel is missing');
    return <></>;
  }

  return (
    <PageWrapper>
      <CircuitTable circuitTableViewModel={pages.pageViewModel as CircuitTableViewModel} />
    </PageWrapper>
  );
};

// eslint-disable-next-line import/no-unused-modules
export {
  CircuitTableAuroraDesignPage, CircuitTable
};
