import isNil from 'lodash/isNil';
import { observer } from 'mobx-react-lite';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import ERoofType from '../../../domain/typings/ERoofType';
import type { AzimuthRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/AzimuthRoofFaceViewModel';
import { getCardinalAbbrev } from '../../../ui/components/Compass/helpers';
import LineHr from '../../components/LineHr';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';
import IconMapper from '../../components/Icons';
import { useUiStore } from '../../../stores/useStore';
import {
  RoofPropertyButton, RoofPropertyButtonWrapper, RoofPropertyHeaderRow
} from './styles';
import {
  DEGREES_SYMBOL, LABEL_AZIMUTH
} from './constants';

const AzimuthRoofProperties = (): ReactElement => {
  const { roofFaceProps } = useUiStore();
  const {
    firstRoofFaceSelected, azimuthRoofFaceModel
  } = roofFaceProps;
  const [viewModel, setViewModel] = useState<AzimuthRoofFaceViewModel>(azimuthRoofFaceModel!);

  useEffect((): void => {
    setViewModel(azimuthRoofFaceModel!);
  }, [azimuthRoofFaceModel]);

  return (
    <>
      {roofFaceProps.typeRoofFaceViewModel!.currentRoofTypeUI.id === ERoofType.SLOPED && viewModel && (
        <>
          <LineHr />
          {!isNil(viewModel.value) ? (
            <RoofPropertyEdition
              title="Azimuth"
              onEditClick={(): void => {
                roofFaceProps.changeShowPropertiesOf(viewModel);
                firstRoofFaceSelected!.setAzimuthArrowVisibility(false);
              }}
            >
              <RoofPropertyHeaderRow>
                <LyraTypography.TextLayout margin={'0px'} lineHeight={1} fontWeight={'bold'} fontSize={'20px'}>
                  {viewModel.currentValue}
                  {DEGREES_SYMBOL}
                </LyraTypography.TextLayout>
                <LyraTypography.TextLayout margin={'0px'} lineHeight={1} fontSize={'11px'}>
                  {getCardinalAbbrev(Number(viewModel.currentValue))}
                </LyraTypography.TextLayout>
              </RoofPropertyHeaderRow>
            </RoofPropertyEdition>
          ) : (
            <RoofPropertyButtonWrapper>
              <RoofPropertyButton
                onClick={(): void => {
                  roofFaceProps.changeShowPropertiesOf(viewModel);
                }}
              >
                <IconMapper name="define-azimuth" />
                {LABEL_AZIMUTH}
              </RoofPropertyButton>
            </RoofPropertyButtonWrapper>
          )}
        </>
      )}
    </>
  );
};

export default observer(AzimuthRoofProperties);
