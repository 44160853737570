/* tslint:disable:no-any */
import React, { BaseProps, ReactChild, ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Row, Cell, CellLabel, CellProps,
  Container, HeaderLabel, Label,
  TableBody, TableContainer, TableHeader,
  TableHeaderRow
} from './styles';
import { DraggableRow, DraggableRowProps } from './DraggableRow';
import { DraggableContainer } from './DragableContainer';

export type HeaderCellProps = {
  content: ReactChild;
  headerCell?: CellProps;
  contentCell?: CellProps;
};

export type TableRowDataProps = {
  content: ReactChild[];
  [key: string]: any;
};

export type TableProps = BaseProps & {
  width?: string;
  label?: string;
  draggable?: boolean;
  header?: HeaderCellProps[];
  unit?: string;
  rows?: TableRowDataProps[];

  /**
   * @description Name of the field inside data set that represents unique row id.
   *              By default, it takes 'id' as a field name. But if 'id' is missing,
   *              other custom name need to be specified.
   *              Otherwise, drag&drop sorting may not work properly.
   */
  indexFieldName?: string;

  /**
   * @description Callback that runs every time you grab and move
   *              table row over another row.
   * @param dragIndex - index of dragging element
   * @param hoverIndex - index of current hovered element
   */
  onItemDrag?: (dragIndex: number, hoverIndex: number) => void;

  /**
   * @description Callback that runs when you click on single row.
   * @param row - table row data
   */
  onItemClick?: (row: TableRowDataProps, event: MouseEvent) => void;

  /**
   * @description Callback that runs when user drops grabbed item.
   * @param orderedRows original data set, but ordered according to user's input.
   */
  onItemDragEnd?: (orderedRows: TableRowDataProps[]) => void;
};

const Table = (props: TableProps): ReactElement => {
  const {
    width, label, draggable = false, header = [], rows = [],
    indexFieldName = 'id', onItemDrag, onItemClick, onItemDragEnd
  } = props;

  const [rowsState, setRowsState] = useState(rows);

  useEffect((): void => {
    setRowsState(rows);
  }, [rows]);

  const changeRowsOrder = useCallback((dragIndex: number, hoverIndex: number): void => {
    const orderedRows = [...rowsState];
    orderedRows.splice(dragIndex, 1);
    orderedRows.splice(hoverIndex, 0, rowsState[dragIndex]);
    setRowsState(orderedRows);
    onItemDrag?.(dragIndex, hoverIndex);
  }, [rowsState]);

  const onDragEnd = useCallback((): void => {
    onItemDragEnd?.(rowsState);
  }, [rowsState]);


  if (!header.length) return <></>;

  const TableRoot = draggable ? DraggableContainer : Container;
  const TableRow = draggable ? DraggableRow : Row;

  const rowProps: Partial<DraggableRowProps> = {
    width,
  };

  if (draggable) {
    rowProps.onDrag = changeRowsOrder;
    rowProps.onDragEnd = onDragEnd;
  }

  return (
      <TableRoot width={width}>
        {label && <Label>{label.toUpperCase()}</Label>}

        <TableContainer>
          <TableHeader>
            <TableHeaderRow width={width}>
              {header.map(({ content, headerCell }: HeaderCellProps, colIdx: number): ReactElement => (
                  <Cell key={`header-cell-${colIdx}`} {...headerCell}>
                    {typeof content === 'string' ? (
                        <HeaderLabel>{content}</HeaderLabel>
                    ) : (
                        content
                    )}
                  </Cell>
              ))}
            </TableHeaderRow>
          </TableHeader>

          <TableBody>
            {rowsState.map((row: TableRowDataProps, rowIdx: number): ReactElement => (
              <TableRow
                id={row?.data?.[indexFieldName] ?? row[indexFieldName]}
                index={rowIdx}
                key={`row-${row?.data?.[indexFieldName] ?? row[indexFieldName]}`}
                data-testid={`table-row-${rowIdx}`}
                onClick={(event: MouseEvent): void => onItemClick?.(row, event)}
                content={row.content}
                {...rowProps}
              >
                {row.content.map((cell: ReactChild, colIdx: number): ReactElement => {
                  const { headerCell, contentCell } = header[colIdx];
                  const cellProps = {
                    ...(contentCell ?? headerCell),
                    size: headerCell?.size ?? 1
                  };
                  return (
                    <Cell key={`cell-${rowIdx}${colIdx}`} {...cellProps}>
                      {typeof cell === 'string' ? <CellLabel>{cell}</CellLabel> : cell}
                    </Cell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </TableRoot>
  );
};

export { Table };
