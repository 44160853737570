import React, { BaseProps, ReactElement } from 'react';
import { PanelWrapper, PanelText } from './styles';

export type PanelProps = BaseProps & {
  label: string;
};

const Panel = ({
  className,
  label,
}: PanelProps): ReactElement => {

  return (
    <PanelWrapper className={className}>
      <PanelText>
        {label}
      </PanelText>
    </PanelWrapper>
  );
};

export { Panel };
