import React, { ReactElement } from 'react';
import { SummaryContainer } from './styles';
import { Order } from './Order';
import { Progress } from './Progress';
import { Info } from './Info';

export interface IPriceCorrections {
  description: string;
  amount: number;
}

export interface ISummaryDataProps {
  /**
   * Properties for 'progress' mode
   */
  progress?: {
    message: string | ReactElement[];
    estimatedWaitTimeInSeconds: number;
  };
  /**
   * Properties for 'order' mode
   */
  order?: {
    type: string;
    amount: number;
    description?: string;
    corrections?: IPriceCorrections[];
    subtotal?: number;
    credit?: number;
    previousPayments?: number;
    total: number;
  };
  /**
   * Properties for 'externalPayment' mode
   */
  externalPayment? : {
    message: string | ReactElement[];
    explanation: string;
  };
  /**
   * Properties for 'success' mode
   */
  success? : {
    message: string | ReactElement[];
  };
  /**
   * Properties for 'failure' mode
   */
  failure? : {
    message: string | ReactElement[];
    explanation: string | ReactElement[];
  };
}

export type SummaryMode = 'preview' | 'progress' | 'order' | 'externalPayment' | 'success' | 'failure';

export interface ISummaryProps {
  /**
   * The mode in which the component should render
   */
  mode: SummaryMode;
  data: ISummaryDataProps;
}

const Summary = ({
  data,
  mode
}: ISummaryProps): ReactElement => {
  return (
    <SummaryContainer>
      {mode === 'preview' &&
        <Info
          icon="doc-preview"
          heading="Download a Free Preview"
          message={
            <>
              Generate a free watermarked preview of the permit package to check that the information is correct.
              <br/>
              You can also make revisions for free after purchasing.
            </>
          }
        />
      }
      {mode === 'progress' &&
        <Progress data={data.progress} />
      }
      {mode === 'order' &&
        <Order data={data.order} />
      }
      {mode === 'externalPayment' &&
        <Info
          icon="card-external"
          heading={data.externalPayment!.message}
          message={data.externalPayment!.explanation}
        />
      }
      {mode === 'success' &&
        <Info icon="doc-check" heading={data.success!.message} />
      }
      {mode === 'failure' &&
        <Info icon="doc-x" heading={data.failure!.message} message={data.failure!.explanation} />
      }
    </SummaryContainer>
  );
};

export { Summary };
