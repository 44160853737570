import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import LineVertical from '../../../ui/components/LineVertical';
import { useUiStore } from '../../../stores/useStore';
import config, { UI_MODE } from '../../../config/config';
import EnvProps from './EnvProps//EnvProps';
import { SiteDesignButton } from './SiteDesign/SiteDesign';
import { SystemDesignButton } from './SystemDesign/SystemDesignButton';

const WorkspaceButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 10px;
`;

const BottomPanel = observer((): ReactElement => {
  const { pages } = useUiStore();
  const pagesMode = !!pages.pageId;

  return (
    <>
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <WorkspaceButton>
          <SiteDesignButton />
          <LineVertical />
          <SystemDesignButton />
          <LineVertical />
        </WorkspaceButton>
      )}
      {!pagesMode && (
        <EnvProps />
      )}
    </>
  );
});

export default BottomPanel;
