import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type DimensionHouse from '../../../domain/models/DimensionsHouse';
import type { Color } from '../../../domain/typings';

type HouseRectProps = Pick<DimensionHouse, 'baseRectangle' | 'heightRectangle'>;

type HouseRoofProps = Pick<DimensionHouse, 'baseRoof' | 'heightRoof'>;

const HouseWrapper = styled.div`
  height: 122px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > span {
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
    font-size: 14px;
  }
`;

const HouseRoof = styled.div<PropsTheme<HouseRoofProps>>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0
    ${({
    baseRoof, heightRoof
  }: PropsTheme<HouseRoofProps>): string => `${baseRoof}px ${heightRoof}px ${baseRoof}px`};
  border-color: transparent transparent ${({ theme }: PropsTheme<HouseRoofProps>): Color => theme.colors.mainColor2}
    transparent;
  transform: rotate(360deg);
`;

const HouseRectangle = styled.div<PropsTheme<HouseRectProps>>`
  width: ${({ baseRectangle }: PropsTheme<HouseRectProps>): Color => baseRectangle}px;
  height: ${({ heightRectangle }: PropsTheme<HouseRectProps>): Color => heightRectangle}px;
  border: solid 1.6px ${({ theme }: PropsTheme<HouseRectProps>): Color => theme.colors.mainColor2};
  border-top-width: 0;
  background-color: rgba(104, 95, 203, 0.15);
`;

export {
  HouseRectangle, HouseRoof, HouseWrapper
};
