import type {
  ErrorTransformer, RJSFValidationError
} from '@rjsf/utils';

const transformErrors: ErrorTransformer = (errors: RJSFValidationError[]): RJSFValidationError[] =>
  errors
    .map((error: RJSFValidationError) => {
      if (error.name === 'required') {
        error.message = 'Required property';
      }
      return error;
    })
    .filter((
      error: RJSFValidationError, _, array: RJSFValidationError[]
    ): boolean => (
      error.name !== 'type' || !array.some((otherError: RJSFValidationError): boolean => (
        otherError.property === error.property && otherError.name === 'enum'
      ))
    ));

export { transformErrors };
