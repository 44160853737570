import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import type SwitchProps from './SwitchProps';

const HEIGHT = 13;
const WIDTH = 29;
const CIRCLE_HEIGHT = 11;
const CIRCLE_WIDTH = 11;
const CIRCLE_OFFSET = 1;

export const SwitchCircle = styled.div<PropsTheme<SwitchProps>>`
  ${({ actived }: PropsTheme<SwitchProps>): string =>
    actived
      ? `transform : translate(
      ${WIDTH - (CIRCLE_WIDTH + CIRCLE_OFFSET)}px, ${CIRCLE_OFFSET}px
      );
      `
      : `transform : translate(${CIRCLE_OFFSET}px, ${CIRCLE_OFFSET}px);`};
  background-color: #ffffff;
  border-radius: 100%;
  height: ${CIRCLE_HEIGHT}px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.4s ease;
  width: ${CIRCLE_WIDTH}px;
`;

export const SwitchBar = styled.div<PropsTheme<SwitchProps>>`
  border-radius: 16px;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props: PropsTheme<SwitchProps>): Color =>
    props.actived ? props.theme.colors.primary : props.theme.colors.quaternary};
  height: 100%;
  position: relative;
  width: 100%;
`;

export const SwitchStyle = styled.div`
  cursor: pointer;
  height: ${HEIGHT}px;
  position: relative;
  width: ${WIDTH}px;
`;
