import { observable } from 'mobx';
import { handleApiError } from '../../../../../utils/helpers';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';

export class RoofDeckingDataFetcher {
  @observable
  deckSheathingTypeOptions: IOption<BaseAttributes>[] = [];

  private designService = new DesignService();

  async loadOptions(): Promise<void> {
    this.loadDeckSheathingType();
  }

  async loadDeckSheathingType(): Promise<void> {
    this.designService
      .getDeckSheatingTypeOptions()
      .then((response: IOption<BaseAttributes>[]): void => {
        this.deckSheathingTypeOptions = response;
      })
      .catch(handleApiError('Failed to fetch deck sheathing type data'));
  }
}
