import type {
  BufferGeometry, Material
} from 'three';
import { Vector3 } from 'three';
import type EditorStore from '../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type { SVGObject } from '../../infrastructure/services/drawingArea/SVGLoader';
import { Draggable } from '../mixins/Draggable';
import { Selectable } from '../mixins/Selectable';
import { Drawable } from '../mixins/Drawable';
import { SceneObjectType } from './Constants';

const MixedClass = Draggable(Selectable(Drawable(class SimpleClass {})));

// static configuration
const performanceLogging = false;
const performanceLog = (...strs: string[]): void => {
  if (performanceLogging) {
    // eslint-disable-next-line no-console
    console.log(...strs);
  }
};

export class CustomBaseImageryMesh extends MixedClass {
  isMultipleVertices: boolean = false;
  override isDrawable: boolean = true;
  override isDraggable: boolean = true;
  override renderOrder: number = -1;
  override defaultRenderOrder: number = -1;

  handle?: SVGObject;
  selectWithParent: boolean = false;
  propertyId: string = SceneObjectType.CustomBaseImagery;

  constructor(geometry?: BufferGeometry, material?: Material) {
    super();
    if (geometry) {
      this.mesh.geometry = geometry;
    }
    if (material) {
      this.mesh.material = material;
    }

    performanceLog('custom imagery mesh constructor');
  }

  resetVertex(vector: Vector3, index: number, editor: EditorStore): void {
    //
  }

  override select(): void {
    //
  }

  override unselect(): void {
    //
  }

  redraw(): void {
    //
  }

  getVector3s(): Vector3[] {
    return [new Vector3(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z)];
  }

  override move(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (!this.mesh.userData.groupReference) {
      return;
    }
    performanceLog('custom imagery mesh: move');
    this.mesh.userData.groupReference.position.add(this.moveDeltaVector);
    this.mesh.userData.groupReference.userData.positionHandles();
  }

  override afterMove(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }
}
