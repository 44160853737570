import type {
  IWorkspace, TWorkspaceConstructor
} from '../types';
import type { Dictionary } from '../../../../domain/typings';
import { DesignWorkspace } from '../workspaces/DesignWorkspace';
import { ProjectWorkspace } from '../workspaces/ProjectWorkspace';

const registryWorkspaces: Dictionary<TWorkspaceConstructor<IWorkspace>> = {
  design: DesignWorkspace,
  project: ProjectWorkspace
};

export default registryWorkspaces;
