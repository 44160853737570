import React, { BaseProps, ReactElement } from 'react';
import { DataTableCell } from './DataTableCell';
import { DataTableHeader } from './DataTableHeader';
import { DataTableWrapper, TableContent } from './styles';

export type DataProps = BaseProps & {
  id: string | number;
  name?: string;
  billingAddress?: string;
  date?: string;
  price?: string;
};

export type DataTableProps = BaseProps & {
  lists?: DataProps[];
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  noDownloadColumn?: boolean;
  fillContent?: boolean | string;
};

const DataTable = (props: DataTableProps): ReactElement => {
  const { lists, onClick, noDownloadColumn, fillContent } = props;
  return (
    <DataTableWrapper fillContent={fillContent}>
      <DataTableHeader noDownloadColumn={noDownloadColumn} />
      <TableContent>
        {lists &&
          lists.map(
            (item: DataProps, index: number): ReactElement => {
              return <DataTableCell
                key={index}
                data={item}
                onClick={onClick}
                data-testid={`data-table-row-${index}`}
                noDownloadColumn={noDownloadColumn}
              />;
            }
          )}
      </TableContent>
    </DataTableWrapper>
  );
};

export { DataTable };
