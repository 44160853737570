import React, { BaseProps, ReactElement, useCallback, useState } from 'react';
import { TextField } from '../../FormElementsDesignTool/TextField';
import { Icon } from '../../Icon';
import { RightIcons } from '../RightButtons';
import { BuildingContainer, Separator, TextRow } from '../styles';

export type BuildingProps = BaseProps & {
  id?: string;
  value?: string;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onNameChange?: (value: string) => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

const Building = (props: BuildingProps): ReactElement => {
  const {
    id,
    className = '',
    styles = {},
    value = '',
    children,
    onDeleteClick,
    onEditClick,
    onNameChange,
    onVisibilityToggle
  } = props;
  const [collapse, setCollapse] = useState(true);
  const [hover, setHover] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    (event: React.KeyboardEvent, text: string): void => {
      if (event.key === 'Enter') {
        setEditing(false);
        if (!!text) {
          onNameChange?.(text);
        }
      }
    },
    [setEditing, onNameChange]
  );

  return (
    <>
      <BuildingContainer
        key={id}
        className={className}
        styles={styles}
        collapse={collapse}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
      >
        <Icon
          name="arrow-collapse"
          data-testid="collapse-building"
          onClick={(): void => setCollapse(!collapse)}
        />
        {editing ? (
          <TextField
            width="130px"
            name="building-name"
            value={value}
            onKeyUp={handleChange}
          />
        ) : (
          <TextRow
            onClick={(): void => setEditing(true)}
            data-testid="edit-building"
          >
            {value}
          </TextRow>
        )}
        <Separator />
        {hover && !editing
          && (
            <RightIcons
              theme="light"
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              onVisibilityToggle={onVisibilityToggle}
            />
          )
        }
      </BuildingContainer>
      {collapse && children}
    </>
  );
};

export { Building };
