import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import { getRootStore } from '../../RootStoreInversion';
import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteRoofFaceCommand extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    const roofFace = this.object as RoofFace;
    this.editor.removeObject(roofFace.mesh);
    this.editor.viewport.render();
  }

  protected deleteFromDomain(): void {
    const roofFace = this.object as RoofFace;
    this.domain.deleteRoofFace(roofFace);
    const roofFaceProps = getRootStore().roofFaceProps;
    if (roofFaceProps.lastInteractedRoofFace?.serverId === roofFace.serverId) {
      roofFaceProps.lastInteractedRoofFace = undefined;
    }
  }
}
