import * as HACheckBox from './HACheckbox/HACheckbox';
import * as HADropdown from './HADropdown';
import * as HAInput from './HAInput/HAInput';
import * as HALabel from './HALabel/HALabel';
import * as HANumberField from './HANumberField';
import * as HARadio from './HARadio/HARadio';
import * as HASelect from './HASelect';
import * as HATagInput from './HATags/HATags';
import * as HATextArea from './HATextArea';
import * as HATextField from './HATextField';
import * as HAToggle from './HAToggle/HAToggle';

const FormElementsHostApp = {
  HACheckBox,
  HADropdown,
  HAInput,
  HAToggle,
  HARadio,
  HANumberField,
  HATextField,
  HATextArea,
  HATagInput,
  HASelect,
  HALabel
};

export {
  FormElementsHostApp
};
