import flatMap from 'lodash/flatMap';
import type { IObservableArray } from 'mobx';
import {
  computed, observable
} from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import type { INameable } from '../../../domain/typings/INameable';
import type { Outline } from './Outline';
import type { RoofStory } from './RoofStory';
import type { RoofFace } from './RoofFace';

export class Building implements INameable {
  id: string;
  @observable
  name: string = '';
  @observable
  visible: boolean = true;
  automaticSprinklerSystem?: boolean;
  source: string = 'TRACE';
  useAndOccupancyClassification: string = 'R3';
  @observable
  stories: IObservableArray<RoofStory> = observable([]);
  @observable
  roofOutline?: Outline;

  constructor() {
    this.id = ThreeMath.generateUUID();
  }

  @computed
  get roofFaces(): RoofFace[] {
    return flatMap(this.stories, (story: RoofStory): RoofFace[] => story.roofFaces);
  }

  roofFaceWithId = (roofFaceId: string): RoofFace | undefined => {
    return this.roofFaces.find((roofFace: RoofFace): boolean => roofFace.serverId === roofFaceId);
  };

  roofFaceWithName = (roofFaceName: string): RoofFace | undefined => {
    return this.roofFaces.find(
      (roofFace: RoofFace): boolean => roofFace.name.toLowerCase() === roofFaceName.toLowerCase()
    );
  };

  storyLevelOfRoofFace = (roofFaceId: string): number | undefined => {
    const storyWithRoofFace = this.stories.find((roofStory: RoofStory): boolean =>
      roofStory.roofFaces.some((roofFace: RoofFace): boolean => roofFace.serverId === roofFaceId)
    );
    return storyWithRoofFace?.level;
  };

  @computed
  get isEmpty(): boolean {
    return !this.roofFaces.length && !this.roofOutline;
  }

  @computed
  get isImportedFrom3dModel(): boolean {
    return this.source === 'MODEL_3D';
  }
}
