import type DomainStore from '../../DomainStore/DomainStore';
import type EditorStore from '../../EditorStore/EditorStore';
import type { ServiceBus } from '../../ServiceBus/ServiceBus';
import { DocumentsService } from '../../../infrastructure/services/api/DocumentsService';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { EquipmentService } from '../../../infrastructure/services/api/EquipmentService';
import { AuthoritiesService } from '../../../infrastructure/services/api/AuthoritiesService';
import { LoadServingEntitiesService } from '../../../infrastructure/services/api/LoadServingEntitiesService';
import type { ModalStore } from './Modal';

export interface IBaseViewModelDependencies {
  modal: ModalStore;
  domain: DomainStore;
  editor: EditorStore;
  serviceBus?: ServiceBus;
}

export abstract class BaseViewModel {
  editor: EditorStore;
  abstract propCodeUI: string;
  protected modal: ModalStore;
  protected domain: DomainStore;
  protected documentsService = new DocumentsService();
  protected designService = new DesignService();
  protected equipmentService = new EquipmentService();
  protected loadServingEntitiesService = new LoadServingEntitiesService();
  protected authoritiesService = new AuthoritiesService();

  constructor(dependencies: IBaseViewModelDependencies) {
    this.modal = dependencies.modal;
    this.domain = dependencies.domain;
    this.editor = dependencies.editor;
  }

  closeModal = (): void => {
    this.dispose();
    this.modal.closeModal();
  };

  /**
   * @description Mandatory method for all modals that should clear
   * any event listeners and other handlers when modal is closed.
   */
  abstract dispose(): void;
}
