import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type {
  CommandExecutedEvent, ServiceBus
} from '../../../stores/ServiceBus/ServiceBus';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { KeyboardBehaviour } from '../../behaviour/KeyboardBehaviour';
import { NewSystemSizeStage } from '../CreationDesignStages/NewSystemSizeStage';
import type { INewLayoutStrategyStageDeps } from '../CreationDesignStages/NewLayoutStrategyStage';
import { NewLayoutStrategyStage } from '../CreationDesignStages/NewLayoutStrategyStage';
import type { INewSystemDesignStageDeps } from '../CreationDesignStages/NewSystemDesignStage';
import { NewSystemDesignStage } from '../CreationDesignStages/NewSystemDesignStage';
import type { INewSystemSizeStageDeps } from '../CreationDesignStages/NewSystemSizeStage';
import type { IMountingSystemDefinitionsStageDeps } from '../CreationDesignStages/MountingSystemDefinitionsStage';
import { MountingSystemDefinitionsStage } from '../CreationDesignStages/MountingSystemDefinitionsStage';
import type { StageFactoryParameters } from '../StageFactory';
import { WizardStager } from '../WizardStager';
import type { DesignCreationWizardStore } from '../../../stores/UiStore/Wizard/DesignCreationWizardStore';
import { KeyboardListener } from '../../../utils/KeyboardListener';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import type { IWizardStage } from '../IWizardStage';
import type { ICreateDesignDependencies } from '../../../stores/ServiceBus/Commands/CreateDesignCommand';
import type { IUpdateSupplementalMountingSystemDataDependencies } from '../../../stores/ServiceBus/Commands/UpdateSupplementalMountingSystemDataCommand';
import type { IUpdateSupplementalPvModuleDefinitionDataDependencies } from '../../../stores/ServiceBus/Commands/UpdateSupplementalPvModuleDefinitionDataCommand';

export interface ICreationDesignDependencies {
  readonly designCreationWizardStore: DesignCreationWizardStore;
  readonly domain: DomainStore;
  readonly designWorkspace: DesignWorkspace;
  readonly serviceBus: ServiceBus;
  readonly guidelines: SmartGuidesStore;
}

export class CreationDesignStage implements IProgressStepperStage {
  readonly propCodeUI = '';
  readonly title = '';
  readonly id: string = 'creation_design_stage';
  readonly canContinue: boolean = true;
  disposeEvents?: () => void;

  private readonly designCreationWizardStore: DesignCreationWizardStore;
  private readonly domain: DomainStore;
  private readonly designWorkspace: DesignWorkspace;
  private readonly serviceBus: ServiceBus;
  private wizardCreateDesign?: WizardStager;
  private readonly guidelines: SmartGuidesStore;

  constructor(dependencies: ICreationDesignDependencies) {
    const {
      designCreationWizardStore, domain, designWorkspace, serviceBus, guidelines
    } = dependencies;
    this.designCreationWizardStore = designCreationWizardStore;
    this.domain = domain;
    this.designWorkspace = designWorkspace;
    this.serviceBus = serviceBus;
    this.guidelines = guidelines;

    KeyboardBehaviour.addKeyboardEvents(this);

    if (!this.domain.optionalDesign) {
      this.createWizardStager();
      this.serviceBus.addEventListener('commandExecuted', this.onCommandExecuted);
    }
  }

  continue(): void {
    /* Not implemented */
  }

  cancel(): void {
    /**  */
  }

  createWizardStager(): void {
    const newSystemSizeStage: StageFactoryParameters<INewSystemSizeStageDeps, IWizardStage> = {
      c: NewSystemSizeStage,
      dependencies: {
        isDesignCreation: true,
        designCreationWizardStore: this.designCreationWizardStore,
        domain: this.domain
      }
    };
    const newSystemDesignStage: StageFactoryParameters<INewSystemDesignStageDeps, IWizardStage> = {
      c: NewSystemDesignStage,
      dependencies: {
        isDesignCreation: true,
        designCreationWizardStore: this.designCreationWizardStore,
        domain: this.domain
      }
    };
    const mountingSystemDefinitionsStage: StageFactoryParameters<IMountingSystemDefinitionsStageDeps, IWizardStage> = {
      c: MountingSystemDefinitionsStage,
      dependencies: {
        isDesignCreation: true,
        designCreationWizardStore: this.designCreationWizardStore,
        domain: this.domain
      }
    };
    const newLayoutStrategyStage: StageFactoryParameters<INewLayoutStrategyStageDeps, IWizardStage> = {
      c: NewLayoutStrategyStage,
      dependencies: {
        isDesignCreation: true,
        designCreationWizardStore: this.designCreationWizardStore,
        domain: this.domain,
        onContinue: this.createDesignAndProceed,
        onDispose: this.dispose.bind(this)
      }
    };
    this.wizardCreateDesign = new WizardStager({
      stageFactoryParameters: [
        newSystemSizeStage,
        newSystemDesignStage,
        mountingSystemDefinitionsStage,
        newLayoutStrategyStage
      ],
      designWorkspace: this.designWorkspace,
      stageManagerName: 'creationDesign'
    });

    this.designWorkspace.setWizardStagerToWorkspace(this.wizardCreateDesign);
    this.disableGuidelines();
  }

  private createDesignAndProceed = (): void => {
    const dependencies: ICreateDesignDependencies = {
      domain: this.domain,
      designCreationRequest: this.designCreationWizardStore.createDesignRequest()
    };
    this.serviceBus.send('create_design', dependencies);
  };

  private onCommandExecuted = async (event: CommandExecutedEvent): Promise<void> => {
    if (event.commandName === 'create_design') {
      const supplementalPvModuleData: IUpdateSupplementalPvModuleDefinitionDataDependencies = {
        domain: this.domain
      };
      this.serviceBus.send('update_supplemental_pv_module_definition_data', supplementalPvModuleData);
      const supplementalMountingSystemData: IUpdateSupplementalMountingSystemDataDependencies = {
        domain: this.domain,
        steepSlopeAttributes: this.designCreationWizardStore.steepSlopeMountingSystemAttributes,
        lowSlopeAttributes: this.designCreationWizardStore.lowSlopeMountingSystemAttributes
      };
      this.serviceBus.send('update_supplemental_mounting_system_definition_data', supplementalMountingSystemData);
      this.dispose();
      this.designWorkspace.stageManager!.next();
    }
  };

  dispose(): void {
    this.serviceBus.removeEventListener('commandExecuted', this.onCommandExecuted);
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  resume(lastValidStage: string): void {
    this.wizardCreateDesign?.recoverLastState();
  }

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_ENTER && this.wizardCreateDesign?.currentStage.canContinue) {
      this.wizardCreateDesign.next();
    }
  };

  onKeyDown = (event: KeyboardEvent): void => {
    // Not implemented
  };

  private disableGuidelines(): void {
    this.guidelines.enable = false;
  }
}
