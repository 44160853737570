import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { EButtonType } from './EButtonType';

export type ButtonStyleProps = {
  themeType?: string | EButtonType;
  selected?: boolean;
};

export type PropsWithTheme = PropsTheme & ButtonStyleProps;

/**
 * Select a prefab button style
 *
 * @param theme - to take the theme colors
 * @param themeType - style of the button
 * @return - css rule
 */
function getButtonStyle({ theme, themeType }: PropsWithTheme): string {
  let bgColor: Color;
  let fontColor: Color;
  let onHover: Color = '';

  switch (themeType) {
    case EButtonType.MAIN:
      const btnColor = theme.colors.main1;
      bgColor = btnColor;
      fontColor = theme.colors.highlight;
      onHover = btnColor;
      break;
    case EButtonType.PRIMARY:
      bgColor = theme.colors.primary;
      fontColor = theme.colors.highlight;
      onHover = theme.colors.primaryHover;
      break;
    case EButtonType.SECONDARY:
      bgColor = theme.colors.mainColor2;
      fontColor = theme.colors.highlight;
      onHover = theme.colors.mainColor2;
      break;
    default:
      bgColor = theme.colors.tertiary;
      fontColor = theme.colors.secondaryFont;
      onHover = theme.colors.tertiary;
      break;
  }

  return `
    background-color: ${bgColor};
    color: ${fontColor};
    &:hover{
      background-color: ${onHover};
    }
  `;
}

function square({ themeType }: PropsWithTheme): string {
  if (themeType === EButtonType.SQUARE) {
    return `padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    margin: 10px 0;`;
  } else {
    return `padding: 6px 20px;
    font-size: 12px;
    border-radius: 25px;`;
  }
}

function isSelected({ selected }: PropsWithTheme): string {
  return selected ? `border: 2px solid blue;` : `border: none;`;
}

export const ButtonStyle = styled.button<ButtonStyleProps>`
  ${getButtonStyle};
  ${square};
  ${isSelected};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin: 0 10px;
  & > :first-child {
    margin: 0 5px;
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.35;
  }
  &:active {
    opacity: 0.8;
  }
`;
