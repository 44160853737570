function validateEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function getPriceText(amount: number): string {
  let priceText = '';
  if (amount >= 0) {
    priceText = `$${amount.toFixed(2)}`;
  } else {
    priceText = `-$${Math.abs(amount).toFixed(2)}`;
  }
  return priceText;
}

export { validateEmail, getPriceText };
