import {
  LyraModal, LyraTypography, LyraLoader, LyraDropzone
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { FileError } from 'react-dropzone';
import { useUiStore } from '../../../../stores/useStore';
import type { UploadCustomBaseImageryModalViewModel } from '../../../../stores/UiStore/Modal/ViewModels/UploadCustomBaseImageryModalViewModel/UploadCustomBaseImageryModalViewModel';
import { notify } from '../../../../utils/helpers';
import { ERROR } from '../../../../domain/models/Constants';

const acceptedFormats = { 'image/*': ['.png', '.jpeg', '.jpg', '.gif'] };

const ModalBodyContainer = styled.div`
  min-width: 440px;
  min-height: 250px;
`;

const UploadCustomBaseImageryModal: React.FC = (): ReactElement => {
  const { modal } = useUiStore();
  const uploadImageryViewModel = modal.viewModel as UploadCustomBaseImageryModalViewModel;

  const fileName = uploadImageryViewModel.fileName;

  const modalProps = {
    $show: true,
    title: 'Add Custom Base Imagery',
    color: EThemeComponentColor.PURPLE,
    leftButtonLabel: 'CANCEL',
    rightButtonLabel: 'ADD',
    rightButtonDisabled: !uploadImageryViewModel.hasFile || uploadImageryViewModel.isSaving,
    onClickLeftButton: (): void => uploadImageryViewModel.closeModal(),
    onClickRightButton: (): void => uploadImageryViewModel.save(),
    onClickCloseButton: (): void => uploadImageryViewModel.closeModal()
  };

  const tenMegabytesAsBytes = 10 * Math.pow(1024, 2);
  return (
    <LyraModal.Modal {...modalProps}>
      <ModalBodyContainer>
        {uploadImageryViewModel.isSaving ? (
          <LyraLoader.Loader padding="50px 0 0 0" text="Uploading custom base imagery..." />
        ) : (
          <>
            <LyraTypography.TextLayout type="h2" margin={'20px 0 0'} lineHeight={1.5} align="center">
              Upload your custom imagery here!
            </LyraTypography.TextLayout>
            <LyraDropzone.DropzoneContainer
              accept={acceptedFormats}
              maxSize={tenMegabytesAsBytes}
              canSave={uploadImageryViewModel.hasFile}
              fileName={fileName}
              supportedFormat="JPG / PNG"
              onError={(rejectedFiles: FileError): void => {
                notify(rejectedFiles.message, ERROR);
              }}
              saveFiles={(files: File[]): void => {
                uploadImageryViewModel.setFile(files[0]);
              }}
            />
          </>
        )}
      </ModalBodyContainer>
    </LyraModal.Modal>
  );
};

export default observer(UploadCustomBaseImageryModal);
