import {
  LyraDivider, LyraFormElementsDesignTool, LyraGrid, LyraModal
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { LayoutStrategy } from '../../../../domain/models/RoofTopArray/LayoutStrategy';
import { ERowAlignmentStrategy } from '../../../../domain/typings';
import useStore from '../../../../stores/useStore';
import CustomLayout from '../../../containers/Panels/CustomLayout/CustomLayout';
import {
  rowAlignmentStrategyOption,
  ORIENTATION_OPTIONS
} from '../../../containers/Modals/LowSlopeModal/LowSlopeModal';
import type { SteepSlopeViewModel } from '../../../../stores/UiStore/Modal/ViewModels/SteepSlopeViewModel/SteepSlopeViewModel';

const { CheckBox } = LyraFormElementsDesignTool.FormElementsDesignTool;

const SteepSlopeModal = (): ReactElement => {
  const { modal } = useStore();
  const steepSlopeViewModel = modal.viewModel as SteepSlopeViewModel;

  const STEEP_SLOPE_ROW_ALIGNMENT_STRATEGY_OPTIONS = [
    rowAlignmentStrategyOption(
      LayoutStrategy.ROW_ALIGNMENT_STEEP_SLOPE_LABELS,
      ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY,
      'maximized-modules'
    ),
    rowAlignmentStrategyOption(
      LayoutStrategy.ROW_ALIGNMENT_STEEP_SLOPE_LABELS,
      ERowAlignmentStrategy.ALIGNED,
      'aligned-rows'
    ),
    rowAlignmentStrategyOption(
      LayoutStrategy.ROW_ALIGNMENT_STEEP_SLOPE_LABELS,
      ERowAlignmentStrategy.STAGGERED,
      'staggered-rows'
    )
  ];

  return (
    steepSlopeViewModel.layoutStrategy && (
      <LyraModal.Modal
        $show={true}
        width="600px"
        title={steepSlopeViewModel.roofFaceName}
        leftButtonLabel="BACK"
        rightButtonLabel="APPLY"
        onClickCloseButton={steepSlopeViewModel.closeModal}
        onClickLeftButton={steepSlopeViewModel.closeModal}
        onClickRightButton={steepSlopeViewModel.applyChanges}
      >
        <CustomLayout
          fontSize="12px"
          text="ORIENTATION"
          component={
            <LyraGrid.Grid
              options={ORIENTATION_OPTIONS}
              columns={3}
              selected={steepSlopeViewModel.layoutStrategy.dominantOrientationAsOption}
              onSelect={steepSlopeViewModel.layoutStrategy.setDominantOrientationAsOption}
            />
          }
        />

        <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />

        <CustomLayout
          fontSize="12px"
          text="ROW ALIGNMENT"
          component={
            <LyraGrid.Grid
              options={STEEP_SLOPE_ROW_ALIGNMENT_STRATEGY_OPTIONS}
              columns={3}
              selected={steepSlopeViewModel.layoutStrategy.rowAlignmentStrategyAsOptionForSteepSlope}
              onSelect={steepSlopeViewModel.layoutStrategy.setRowAlignmentStrategyAsOption}
            />
          }
        />

        {steepSlopeViewModel.layoutStrategy.areMixedOrientationsPossible && (
          <>
            <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
            <CustomLayout
              padding="15px 0 0"
              component={
                <CheckBox.Checkbox
                  text="Allow mixed landscape orientation if possible"
                  indeterminate={true}
                  checked={steepSlopeViewModel.layoutStrategy.mixedOrientationsAllowed}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                    steepSlopeViewModel.layoutStrategy.setMixedOrientationAllowed(event.target.checked)
                  }
                />
              }
            />
          </>
        )}
      </LyraModal.Modal>
    )
  );
};

export default observer(SteepSlopeModal);
