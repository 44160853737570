import { observable } from 'mobx';
import type Store from '../../../Store';
import type { UiStore } from '../../UiStore';
import { BuildingPropertiesViewModel } from './BuildingPropertiesViewModel';

export class BuildingPropertiesStore {
  @observable
  buildingPropertiesViewModel: BuildingPropertiesViewModel;

  constructor(root: Store, uiStore: UiStore) {
    this.buildingPropertiesViewModel = new BuildingPropertiesViewModel({
      domain: root.domain,
      editor: root.editor,
      serviceBus: root.serviceBus,
      propertyStore: uiStore.roofFaceProps,
      modal: uiStore.modal,
      wizard: uiStore.wizard
    });
  }
}
