import {
  action, computed, observable
} from 'mobx';
import debounce from 'lodash/debounce';
import type { BaseUtilityMeterProps } from 'domain/models/SiteDesign/BaseUtilityMeterProps';

export class MeterBasePropertiesViewModel {
  private readonly province: string;
  protected readonly setUtilityMeterPropsToDomainObject: (utilityMeterProps: BaseUtilityMeterProps) => void;

  @observable
  serialNumber?: string;
  @observable
  electricServiceId?: string;

  @observable
  loading = false;

  readonly assignSerialNumber: (value: string, shouldUpdate: boolean) => void;
  readonly assignElectricServiceId: (value: string, shouldUpdate: boolean) => void;

  constructor(
    province: string,
    setUtilityMeterPropsToDomainObject: (utilityMeterProps: BaseUtilityMeterProps) => void
  ) {
    this.province = province;
    this.setUtilityMeterPropsToDomainObject = setUtilityMeterPropsToDomainObject;

    this.assignSerialNumber = debounce((serialNumber: string, shouldUpdate: boolean): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          serialNumber
        },
        shouldUpdate
      );
    }, 300);

    this.assignElectricServiceId = debounce((electricServiceId: string, shouldUpdate: boolean = true): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          electricServiceId
        },
        this.validateElectricServiceId(electricServiceId) && shouldUpdate
      );
    }, 300);
  }

  @action.bound
  setProperties(utilityMeterProps?: BaseUtilityMeterProps, shouldUpdate: boolean = true): void {
    this.serialNumber = utilityMeterProps?.serialNumber || undefined;
    this.electricServiceId = utilityMeterProps?.electricServiceId || undefined;
    if (shouldUpdate) {
      this.setUtilityMeterPropsToDomainObject(this.getPropertiesForDomainObject());
    }
  }

  @action.bound
  clearProperties(): void {
    this.serialNumber = undefined;
    this.electricServiceId = undefined;
  }

  @computed
  get isElectricServiceIdFieldVisible(): boolean {
    return this.province === 'TX';
  }

  @computed
  get isValidElectricServiceIdField(): boolean {
    return this.validateElectricServiceId(this.electricServiceId);
  }

  protected getPropertiesForDomainObject(): BaseUtilityMeterProps {
    return {
      serialNumber: this.serialNumber || undefined,
      electricServiceId: this.electricServiceId || undefined
    };
  }

  validateElectricServiceId(electricServiceId?: string): boolean {
    if (!electricServiceId) {
      return true;
    }
    const hasCorrectLength = electricServiceId.length === 17 || electricServiceId.length === 22;
    const hasNumbersOnly = /^\d+$/.test(electricServiceId);
    return hasCorrectLength && hasNumbersOnly;
  }
}
