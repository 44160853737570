import {
  action, observable
} from 'mobx';
import { Vector3 } from 'three';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { SiteEquipmentDataKeys } from '../../entities/SiteDesign/SiteEquipment';
import type { ISiteEquipmentData } from '../../entities/SiteDesign/SiteEquipment';
import { SceneObjectType } from '../Constants';
import type { Drawable } from '../../mixins/Drawable';
import { canvasConfig } from '../../../config/canvasConfig';
import type { IVertexData } from '../../entities/SiteDesign/Vertex';
import { GasMeter } from './GasMeter';
import type { StreetLocation } from './StreetLocation';
import { Subpanel } from './Subpanel';
import { LoadCenter } from './SiteEquipment/LoadCenter';
import { MeterBase } from './SiteEquipment/MeterBase';
import type { Marker } from './Marker';
import type { GenericSiteEquipment } from './SiteEquipment/GenericSiteEquipment';
import {
  ServiceEntranceEquipmentType, SiteEquipmentItemKeyName
} from './SiteEquipmentTypesAndHelpers';
import { MeterMain } from './SiteEquipment/MeterMain';
import { MainBreakerInEnclosure } from './SiteEquipment/MainBreakerInEnclosure';

export enum ServiceEntranceEquipmentItemKeyName {
  mainServicePanel = 'mainServicePanel',
  utilityMeter = 'utilityMeter',
  meterMain = 'meterMain'
}

function inferServiceEntranceEquipmentTypeFromData(
  siteEquipmentData: ISiteEquipmentData
): ServiceEntranceEquipmentType {
  if (siteEquipmentData.meterMain) {
    return siteEquipmentData.mainServicePanel!.hasOwnProperty('mainBreakerRating')
      ? ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter
      : ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter;
  }

  if (siteEquipmentData.mainServicePanel && siteEquipmentData.utilityMeter) {
    if (siteEquipmentData.mainBreakerInEnclosure) {
      return siteEquipmentData.mainServicePanel.hasOwnProperty('mainBreakerRating')
        ? ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter
        : ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter;
    }
    return ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter;
  }

  if (siteEquipmentData.mainServicePanel) {
    return ServiceEntranceEquipmentType.MeterMainLoadCenter;
  }

  throw new Error(`Current equipment configuration not supported: ${JSON.stringify(siteEquipmentData)}`);
}

export type ServiceEntranceEquipment = LoadCenter | MeterMain | MeterBase | MainBreakerInEnclosure;
export type SiteEquipmentUnionType = ServiceEntranceEquipment | GasMeter | Subpanel;

export class SiteEquipment {
  @observable
  [SiteEquipmentItemKeyName.mainServicePanel]?: LoadCenter;
  [SiteEquipmentItemKeyName.subpanel]?: Subpanel;
  [SiteEquipmentItemKeyName.utilityMeter]?: MeterBase;
  [SiteEquipmentItemKeyName.meterMain]?: MeterMain;
  [SiteEquipmentItemKeyName.gasMeter]?: GasMeter;
  [SiteEquipmentItemKeyName.mainBreakerInEnclosure]?: MainBreakerInEnclosure;
  /**
   * Street location is mapped from `site.roadways` in project data structure
   * to `siteEquipment.streetLocation` class in the domain store.
   */
  [SiteEquipmentItemKeyName.streetLocation]?: StreetLocation;

  constructor(data?: ISiteEquipmentData) {
    if (!data) {
      return;
    }
    const {
      mainServicePanel: mainServicePanelData,
      utilityMeter: utilityMeterData,
      meterMain: meterMainData,
      subpanel: subpanelData,
      gasMeter: gasMeterData,
      mainBreakerInEnclosure: mainBreakerInEnclosureData
    } = data;

    if (mainServicePanelData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(data);
      const mainServicePanel = new LoadCenter({
        color: canvasConfig.mainServicePanelIconColor,
        sceneObjectType: SceneObjectType.MainServicePanel,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      mainServicePanel.enrichWithData(mainServicePanelData);
      this.drawEquipmentItem(mainServicePanel, mainServicePanelData.location);

      this[SiteEquipmentItemKeyName.mainServicePanel] = mainServicePanel;
    }

    if (utilityMeterData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(data);
      const utilityMeter = new MeterBase({
        color: canvasConfig.utilityMeterIconColor,
        sceneObjectType: SceneObjectType.UtilityMeter,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      utilityMeter.enrichWithMeterBaseData(utilityMeterData);
      this.drawEquipmentItem(utilityMeter, utilityMeterData.location);

      this[SiteEquipmentItemKeyName.utilityMeter] = utilityMeter;
    }

    if (meterMainData) {
      const targetServiceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(data);
      const meterMain = new MeterMain({
        color: canvasConfig.utilityMeterIconColor,
        sceneObjectType: SceneObjectType.MeterMain,
        serviceEntranceEquipmentType: targetServiceEntranceEquipmentType
      });
      meterMain.enrichWithMeterMainData(meterMainData);
      this.drawEquipmentItem(meterMain, meterMainData.location);

      this[SiteEquipmentItemKeyName.meterMain] = meterMain;
    }

    if (subpanelData) {
      const subpanel = new Subpanel();
      subpanel.fromData(subpanelData);
      this.drawEquipmentItem(subpanel, subpanelData.location);

      this[SiteEquipmentItemKeyName.subpanel] = subpanel;
    }

    if (gasMeterData) {
      const gasMeter = new GasMeter();
      gasMeter.fromData(gasMeterData);
      this.drawEquipmentItem(gasMeter, gasMeterData.location);

      this[SiteEquipmentItemKeyName.gasMeter] = gasMeter;
    }

    if (mainBreakerInEnclosureData) {
      const serviceEntranceEquipmentType = inferServiceEntranceEquipmentTypeFromData(data);
      const mainBreakerInEnclosure = new MainBreakerInEnclosure({
        color: canvasConfig.mainServicePanelIconColor,
        sceneObjectType: SceneObjectType.MainBreakerInEnclosure,
        serviceEntranceEquipmentType: serviceEntranceEquipmentType
      });
      mainBreakerInEnclosure.enrichWithData(mainBreakerInEnclosureData);
      this.drawEquipmentItem(mainBreakerInEnclosure, mainBreakerInEnclosureData.location);

      this[SiteEquipmentItemKeyName.mainBreakerInEnclosure] = mainBreakerInEnclosure;
    }
  }

  @action
  deleteEquipment({
    siteEquipmentToDelete,
    deleteSubpanelWithServiceEntranceEquipment = true
  }: {
    siteEquipmentToDelete: SiteEquipmentUnionType;
    deleteSubpanelWithServiceEntranceEquipment?: boolean;
  }): void {
    const {
      editor, domain
    } = getRootStore();
    // If one piece of service entrance equipment is deleted, the other ones should be deleted as well
    if ((siteEquipmentToDelete as GenericSiteEquipment).isServiceEntranceEquipment) {
      const subpanelDrawable = editor.getObjectsByType(SceneObjectType.Subpanel)[0];
      // We don't want to remove subpanel when we switch service entrance equipment type,
      // so instead of adding service-entrance-equipment-specific field, we can rely on
      // skipConfirmation that's used only for this action.
      if (subpanelDrawable && deleteSubpanelWithServiceEntranceEquipment) {
        editor.removeObject(subpanelDrawable.mesh);
      }
      const subpanel = this[SiteEquipmentItemKeyName.subpanel];
      if (subpanel && deleteSubpanelWithServiceEntranceEquipment) {
        this[SiteEquipmentItemKeyName.subpanel] = undefined;
      }

      // Remove objects from canvas first
      editor
        .getObjectsByTypes([
          SceneObjectType.MainServicePanel,
          SceneObjectType.UtilityMeter,
          SceneObjectType.MeterMain,
          SceneObjectType.MainBreakerInEnclosure
        ])
        .forEach((drawable: Drawable) => {
          editor.removeObject(drawable.mesh);
        });

      // Then remove them from domain
      const equipmentToDeleteByKeys = [
        SiteEquipmentItemKeyName.mainServicePanel,
        SiteEquipmentItemKeyName.utilityMeter,
        SiteEquipmentItemKeyName.meterMain,
        SiteEquipmentItemKeyName.mainBreakerInEnclosure
      ];
      equipmentToDeleteByKeys.forEach((equipmentItemKey: SiteEquipmentItemKeyName) => {
        this[equipmentItemKey] = undefined;
      });
    } else {
      editor.removeObject(siteEquipmentToDelete.mesh);
      domain.deleteGenericSiteEquipment(siteEquipmentToDelete);
    }
  }

  getRenderableKeyNames(): string[] {
    return [
      SiteEquipmentItemKeyName.mainServicePanel,
      SiteEquipmentItemKeyName.utilityMeter,
      SiteEquipmentItemKeyName.meterMain,
      SiteEquipmentItemKeyName.subpanel,
      SiteEquipmentItemKeyName.gasMeter,
      SiteEquipmentItemKeyName.mainBreakerInEnclosure,
      SiteEquipmentItemKeyName.streetLocation
    ];
  }

  getServiceEntranceEquipment = (): Marker[] => {
    const equipment: (Marker | undefined)[] = [
      this.mainServicePanel,
      this.utilityMeter,
      this.meterMain,
      this.mainBreakerInEnclosure
    ];

    return equipment.filter((marker: Marker | undefined): boolean => !!marker) as Marker[];
  };

  inferServiceEntranceEquipmentType = (): ServiceEntranceEquipmentType | undefined => {
    if (this[SiteEquipmentItemKeyName.mainServicePanel]) {
      if (this[SiteEquipmentItemKeyName.utilityMeter]) {
        if (this[SiteEquipmentItemKeyName.mainBreakerInEnclosure]) {
          // There can be an equipment item with two main breakers, this isn't a typo
          return this[SiteEquipmentItemKeyName.mainServicePanel].hasMainBreaker
            ? ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter
            : ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter;
        }
        return ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter;
      }
      if (this[SiteEquipmentItemKeyName.meterMain]) {
        return this[SiteEquipmentItemKeyName.mainServicePanel].hasMainBreaker
          ? ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter
          : ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter;
      }
      return ServiceEntranceEquipmentType.MeterMainLoadCenter;
    }
  };

  getEquipmentMarkerObjects = (): (SiteEquipmentUnionType | StreetLocation)[] =>
    [
      this[SiteEquipmentItemKeyName.mainServicePanel],
      this[SiteEquipmentItemKeyName.subpanel],
      this[SiteEquipmentItemKeyName.utilityMeter],
      this[SiteEquipmentItemKeyName.meterMain],
      this[SiteEquipmentItemKeyName.gasMeter],
      this[SiteEquipmentItemKeyName.streetLocation]
    ].filter((equipment) => equipment !== undefined) as (SiteEquipmentUnionType | StreetLocation)[];

  toData(): Partial<ISiteEquipmentData> {
    const result: Partial<ISiteEquipmentData> = {};

    if (this[SiteEquipmentItemKeyName.mainServicePanel]) {
      result.mainServicePanel = this[SiteEquipmentItemKeyName.mainServicePanel].toData();
    }
    if (this[SiteEquipmentItemKeyName.utilityMeter]) {
      result[SiteEquipmentDataKeys.utilityMeter] = this[SiteEquipmentItemKeyName.utilityMeter].toData();
    }
    if (this[SiteEquipmentItemKeyName.meterMain]) {
      result[SiteEquipmentDataKeys.meterMain] = this[SiteEquipmentItemKeyName.meterMain].toData();
    }
    if (this[SiteEquipmentItemKeyName.subpanel]) {
      result[SiteEquipmentDataKeys.subpanel] = this[SiteEquipmentItemKeyName.subpanel].toData();
    }
    if (this[SiteEquipmentItemKeyName.gasMeter]) {
      result[SiteEquipmentDataKeys.gasMeter] = this[SiteEquipmentItemKeyName.gasMeter].toData();
    }
    if (this[SiteEquipmentItemKeyName.mainBreakerInEnclosure]) {
      result[SiteEquipmentDataKeys.mainBreakerInEnclosure] =
        this[SiteEquipmentItemKeyName.mainBreakerInEnclosure].toData();
    }

    return result;
  }

  private readonly drawEquipmentItem = (marker: SiteEquipmentUnionType, location: IVertexData): void => {
    const renderHeight = getRootStore().editor.getObjectRenderHeight(marker.type as SceneObjectType);
    const renderPosition = new Vector3(location.x, location.y, renderHeight);
    marker.draw(renderPosition);
  };
}
