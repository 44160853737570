import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';

export const HeaderText = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const ErrorText = styled.span`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor4};
  display: block;
  margin-top: 5px;
`;
