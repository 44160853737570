import styled from 'styled-components';

export const MainToolbarWrapper = styled.div`
  flex: 3 0 0;
  margin-top: 10px;
`;

export const SubMenuWrapper = styled.div`
  margin: 8px 0 0px;
`;
