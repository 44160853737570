import React from 'react';

export const ServiceEntranceEquipmentFormHeaderToggleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 10 6">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFFFFF" className="fill-arrow" d="M9.115.165L10 1.05 5.05 6 .1 1.05.985.165 5.05 4.23z"></path>
      <path d="M11 9V-3H-1V9z"></path>
    </g>
  </svg>
);

export const ServiceEntranceEquipmentFormHeaderMarkerIcon = ({ label }: { label: string }) => {
  return (
    <svg width="36px" viewBox="0 0 60 82" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(1.000000, 1.000000)">
          <g textAnchor="middle">
            <path
              /* eslint-disable-next-line max-len */
              d="M17.1284881,57.3479457 C6.81874621,53.7201883 0,45.6558712 0,37.6666667 C0,26.9015226 12.3804704,16 29,16 C45.6195296,16 58,26.9015226 58,37.6666667 C58,45.6558712 51.1812538,53.7201883 40.8715119,57.3479457 L29,81 L17.1284881,57.3479457 Z"
              id="Combined-Shape"
              stroke="#FFFFFF"
              fill="#09468D"
              fillRule="nonzero"
            ></path>
            <text
              fontFamily="SFProText-Bold, SF Pro Text"
              fontSize="18"
              fontWeight="bold"
              letterSpacing="-0.7600001"
              fill="#FFFFFF"
            >
              <tspan x="50%" y="45">
                {label}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};
