import React, { BaseProps, ReactElement, useEffect, useState } from 'react';
import {
  DotItem,
  LowerValueContainer,
  MaxLimitText,
  MediumValueContainer,
  MinLimitText,
  StepDottedProgressContainer,
  StepDottedProgressWrapper,
  StepDotWrapper,
  StepDownArrow,
  StepPercent,
  UpperValueContainer,
  DotContainer,
  DotItemsContainer
} from './styles';

type StepDottedProgressProps = BaseProps & {
  minValue: number;
  maxValue: number;
  currentValue: number;
  possibleMinValue?: number;
  possibleMaxValue: number;
  size?: 'large' | 'medium' | 'small';
};

const StepDottedProgressBar =
  ({
    className,
    minValue,
    maxValue,
    currentValue,
    possibleMinValue,
    possibleMaxValue,
    size
  }: StepDottedProgressProps)
    : ReactElement => {
    const lowStepCount = minValue - (possibleMinValue ?? 0);
    const mediumStepCount = maxValue;
    const upperStepCount = possibleMaxValue;

    let possibleValue = currentValue;
    if (currentValue > possibleMaxValue) {
      possibleValue = possibleMaxValue;
    }
    if (currentValue < (possibleMinValue ?? 0)) {
      possibleValue = (possibleMinValue ?? 0);
    }

    const [lowerDotGroup, setLowerDotGroup] = useState<ReactElement[]>([]);
    const [mediumDotGroup, setMediumDotGroup] = useState<ReactElement[]>([]);
    const [upperDotGroup, setUpperDotGroup] = useState<ReactElement[]>([]);

    useEffect((): void => {
      const lowerGroup = [];
      const mediumGroup = [];
      const upperGroup = [];

      for (let i = 1; i < lowStepCount; i++) {
        lowerGroup.push(drawDot(i));
      }

      for (let i = lowStepCount || 1; i <= mediumStepCount; i++) {
        mediumGroup.push(drawDot(i));
      }

      for (let i = mediumStepCount + 1; i <= upperStepCount; i++) {
        upperGroup.push(drawDot(i));
      }

      setLowerDotGroup(lowerGroup);
      setMediumDotGroup(mediumGroup);
      setUpperDotGroup(upperGroup);
    }, [minValue,
      maxValue,
      currentValue,
      possibleMinValue,
      possibleMaxValue]);

    const getColor = (zeroFirstIndex: number): string => {
      if (zeroFirstIndex > currentValue) {
        return '#f2f2f2'; // gray
      } else if (currentValue > (possibleMinValue || 0) && currentValue < minValue) {
        return '#c3bc6a'; // light green
      } else if (currentValue >= minValue && currentValue <= maxValue) {
        return '#36A247'; // dark green
      } else {
        return '#C20000'; // red
      }
    };

    const drawDot = (i: number): ReactElement => {
      return (<DotContainer key={i}>
        <DotItemsContainer dotKey={i} current={possibleValue}>
          <StepPercent size={size}>
            {possibleValue}
          </StepPercent>
          <StepDownArrow size={size ?? ''} />
        </DotItemsContainer>
        <DotItem
          total={possibleMaxValue}
          color={getColor(i)}
        />
      </DotContainer>);
    };

    return (
      <StepDotWrapper className={className}>
        <StepDottedProgressContainer>
          <StepDottedProgressWrapper>
            <LowerValueContainer>
              {lowerDotGroup}
            </LowerValueContainer>
            <MediumValueContainer
              minValue={minValue}
            >
              {mediumDotGroup}
              {minValue > 0 && (
                <MinLimitText>
                    min
                </MinLimitText>
              )}
              <MaxLimitText>
                max
            </MaxLimitText>
            </MediumValueContainer>
            <UpperValueContainer>
              {upperDotGroup}
            </UpperValueContainer>
          </StepDottedProgressWrapper>
        </StepDottedProgressContainer>
      </StepDotWrapper>
    );
  };

export { StepDottedProgressBar };
