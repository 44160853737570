import type { IBaseCommandDependencies } from '../IServiceBus';
import type { PolygonDrawable } from '../../../domain/mixins/PolygonDrawable';
import { BaseCommand } from './Command';

export interface IRemoveVertexFromPolygonDependencies extends IBaseCommandDependencies {
  wipPolygon: PolygonDrawable;
}

export class RemoveVertexFromPolygonCommand extends BaseCommand {
  private wipPolygon: PolygonDrawable;

  constructor(dependencies: IRemoveVertexFromPolygonDependencies) {
    super();
    this.wipPolygon = dependencies.wipPolygon;
  }

  async execute(): Promise<void> {
    this.wipPolygon.removeLast();
  }
}
