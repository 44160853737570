import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import {
  DataInput, InputLeftView, InputRightView, LogoView, MainText
} from '../styles';

interface ITextFieldRowProps {
  name: string;

  title: string;
  value: string;
  error?: boolean;

  onChange?: (value: string) => void;
}

const TextfieldRow: React.FC<ITextFieldRowProps> = (props: ITextFieldRowProps): ReactElement => {
  const {
    title, value: propValue, error, onChange
  } = props;

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>): void => {
      const value: string = event.currentTarget.value;
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <LogoView height="52px">
      <InputLeftView>
        <MainText>{title}</MainText>
      </InputLeftView>

      <InputRightView error={error}>
        <DataInput value={propValue} onChange={handleChange} />
      </InputRightView>
    </LogoView>
  );
};

export default TextfieldRow;
