import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import ERoofType from '../../../domain/typings/ERoofType';
import { useUiStore } from '../../../stores/useStore';
import type { SlopeRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/SlopeRoofFaceViewModel';
import { degreesToPitch } from '../../../utils/slope';
import { ROOF_FACE_SLOPE_FRACTION_DIGITS } from '../../../domain/models/Constants';
import LineHr from '../../components/LineHr';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';
import IconMapper from '../../components/Icons';
import {
  RoofPropertyButton, RoofPropertyButtonWrapper, RoofPropertyHeaderRow
} from './styles';
import {
  DENOMINATOR_PITCH, LABEL_SLOPE
} from './constants';

const SlopeRoofProperties = observer((): ReactElement => {
  const { roofFaceProps } = useUiStore();
  const { slopeRoofFaceViewModel } = roofFaceProps;
  const [slopeViewModel, setVM] = useState<SlopeRoofFaceViewModel | undefined>(slopeRoofFaceViewModel);

  useEffect((): void => {
    setVM(slopeRoofFaceViewModel);
  }, [slopeRoofFaceViewModel]);

  const slopeValueWithDecimals = Number(
    degreesToPitch(slopeViewModel?.slopeValueInDegrees ?? 0).toFixed(ROOF_FACE_SLOPE_FRACTION_DIGITS)
  );
  const integerSlopeValue = Math.round(slopeValueWithDecimals);
  const slopeValueInDegrees = slopeValueWithDecimals % 1 === 0 ? integerSlopeValue : slopeValueWithDecimals;

  return (
    <>
      {roofFaceProps.typeRoofFaceViewModel!.currentRoofTypeUI.id === ERoofType.SLOPED && slopeViewModel && (
        <>
          <LineHr />
          {slopeViewModel.slopeValueInDegrees ? (
            <RoofPropertyEdition
              title="Slope"
              onEditClick={(): void => roofFaceProps.changeShowPropertiesOf(slopeViewModel)}
            >
              <RoofPropertyHeaderRow>
                <LyraTypography.TextLayout margin={'0px'} lineHeight={1} fontWeight={'bold'} fontSize={'20px'}>
                  {slopeValueInDegrees}/{DENOMINATOR_PITCH}
                </LyraTypography.TextLayout>
                <LyraTypography.TextLayout margin={'0px'} lineHeight={1} fontSize={'11px'}>
                  ({slopeViewModel.slopeValueInDegrees.toFixed(ROOF_FACE_SLOPE_FRACTION_DIGITS)}°)
                </LyraTypography.TextLayout>
              </RoofPropertyHeaderRow>
            </RoofPropertyEdition>
          ) : (
            <RoofPropertyButtonWrapper>
              <RoofPropertyButton onClick={(): void => roofFaceProps.changeShowPropertiesOf(slopeViewModel)}>
                <IconMapper name="define-slope" />
                {LABEL_SLOPE}
              </RoofPropertyButton>
            </RoofPropertyButtonWrapper>
          )}
        </>
      )}
    </>
  );
});

export default SlopeRoofProperties;
