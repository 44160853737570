import { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import type { IDesignDeltaData } from '../../../domain/entities/Design/DesignDelta';
import { DesignState } from '../../../domain/models/Design/DesignState';
import { RoofTopArrayAreas } from '../../../domain/models/RoofTopArray/RoofTopArrayAreas';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Design } from '../../../domain/models/Design/Design';
import { DesignSpecification } from '../../../domain/models/Design/DesignSpecification';
import { PvSystem } from '../../../domain/models/PvSystem/PvSystem';
import { BaseCommand } from './Command';

export interface IUpdateDesignDeltaDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly designDelta: IDesignDeltaData;
}

export class UpdateDesignDeltaCommand extends BaseCommand {
  private readonly design: Design;
  private readonly delta: DesignDelta;

  constructor(dependencies: IUpdateDesignDeltaDependencies) {
    super();
    this.design = dependencies.domain.design;
    this.delta = new DesignDelta(dependencies.designDelta);
  }

  async execute(): Promise<void> {
    const currentDesign = this.design;

    if (this.delta.roofTopArrayAreas) {
      currentDesign.roofTopArrayAreas = new RoofTopArrayAreas(this.delta.roofTopArrayAreas);
    }
    if (this.delta.system) {
      currentDesign.system = new PvSystem(this.delta.system);
      currentDesign.system.equipment.mountingSystems.definitions.enrichWith(currentDesign.supplementalData);
      await currentDesign.updateSupplementalDataIfNeeded();
    }
    if (this.delta.designSpecification) {
      currentDesign.designSpecification = new DesignSpecification(this.delta.designSpecification);
    }
    if (this.delta.state) {
      currentDesign.state = new DesignState(this.delta.state);
    }
  }

  override async reExecute(): Promise<void> {
    return this.execute();
  }
}
