import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import config, { UI_MODE } from '../../../config/config';

export const CounterStyle = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & > div:first-child {
    min-width: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 100 : 0)}px;
    background: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 'white' : 'none')};
    border: ${(): string =>
    config.featureFlag.uiMode !== UI_MODE.AURORA
      ? `1px solid ${({ theme }: PropsTheme): Color => theme.colors.tertiary}`
      : 'none'};
    border-radius: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 3 : 0)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 2 : 0)}px;
    height: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 20 : 32)}px;
    & > div {
      margin: 0 7px;
    }
  }

  button {
    background: ${({ theme }: PropsTheme): Color =>
    config.featureFlag.uiMode !== UI_MODE.AURORA ? theme.colors.secondary : theme.colors.whiteBg};
    cursor: pointer;
    height: 100%;
    width: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? '20%' : '32px')};
    line-height: inherit;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 0 : 1)}px;

    &:disabled {
      cursor: not-allowed;
      span,
      & > div {
        opacity: 0.25;
      }
    }
  }
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 0.2;
`;
