import type {
  ISiteEquipmentData, SiteEquipmentDataKeys
} from '../../entities/SiteDesign/SiteEquipment';
import { SceneObjectType } from '../Constants';
import { canvasConfig } from '../../../config/canvasConfig';
import type { EquipmentExposure } from './EquipmentExposure';
import { Marker } from './Marker';

export class GasMeter extends Marker {
  override readonly label: string = 'GM';
  override readonly type: string = SceneObjectType.GasMeter;
  override readonly propertyId: string = SceneObjectType.GasMeter;
  override readonly name: string = 'Gas Meter';
  override readonly color: string = canvasConfig.gasMeterIconColor;

  serialNumber?: string;
  description?: string;
  exposure: EquipmentExposure;

  constructor() {
    super();
    this.exposure = {
      type: 'OUTDOOR',
      constrained: true
    };
  }

  fromData(data: ISiteEquipmentData[SiteEquipmentDataKeys.gasMeter]): void {
    if (data) {
      this.serverId = data.id;
      this.serialNumber = data.serialNumber;
      this.exposure = data.exposure;
      this.description = data.description;
    }
  }

  toData(): ISiteEquipmentData[SiteEquipmentDataKeys.gasMeter] {
    return {
      id: this.serverId,
      serialNumber: this.serialNumber || undefined,
      exposure: this.exposure,
      description: this.description || undefined,
      location: {
        x: this.mesh.position.x,
        y: this.mesh.position.y,
        z: this.realWorldZValue
      }
    };
  }
}
