import { Vector3 } from 'three';
import { EPSILON } from '../../utils/math';
import {
  calculateDistanceFromPlaneFrom3PointsToAPoint,
  getLongestEdgePointsAndFurthestPointFromPointsArray
} from '../../utils/spatial';
import type { IBuildingData } from '../../domain/entities/SiteDesign/Building';
import type { IRoofFaceData } from '../../domain/entities/SiteDesign/RoofFace';
import type { IRoofSectionData } from '../../domain/entities/SiteDesign/RoofSection';
import type { IRoofStoryData } from '../../domain/entities/SiteDesign/RoofStory';
import type { IProjectData } from 'domain/models/SiteDesign/Project';

/**
 * getNonCoplanarRoofIds
 * @returns true list of non-coplanar roof face serverIds
 */
export function getNonCoplanarRoofIds(project: IProjectData): string[] {
  const foundIds: string[] = [];
  try {
    project.site.buildings?.forEach((building: IBuildingData) => {
      building.stories.forEach((story: IRoofStoryData) => {
        story.roofSections.forEach((roofSection: IRoofSectionData) => {
          roofSection.faces.forEach((roofFace: IRoofFaceData) => {
            let isCoplanar = true;
            const polygon = roofFace.geometry.polygon;
            // Take the longest edge and furthest point as the plane to check other points against
            const planePointsToCheckAgainst = getLongestEdgePointsAndFurthestPointFromPointsArray(polygon);
            for (let index = 0; index < polygon.length - 1; index++) {
              const distance = calculateDistanceFromPlaneFrom3PointsToAPoint(
                planePointsToCheckAgainst[0],
                planePointsToCheckAgainst[1],
                planePointsToCheckAgainst[2],
                new Vector3(polygon[index].x, polygon[index].y, polygon[index].z)
              );
              if (distance >= EPSILON) {
                // Polygon is not a plane
                foundIds.push(roofFace.id);
                isCoplanar = false;
              }
            }
            if (!isCoplanar) {
              // eslint-disable-next-line no-console
              console.error(
                `Polygon is not a plane, roofFaceId:${roofFace.id}, vertices:[${roofFace.geometry.polygon.reduce(
                  (acc, vertex) => `${acc}, {x:${vertex.x}, y:${vertex.y}, z:${vertex.z}}`,
                  ''
                )}]`
              );
            }
          });
        });
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Non-coplanar polygon check failed.', error);
  }
  return foundIds;
}
