import type { IAddressData } from './SiteDesign/Address';

export type ICompanyInstallerCreationRequest = Omit<ICompanyInstallerData, 'id' | 'account'>;

export interface ICompanyInstallerData {
  id: string;
  account: string;
  type: 'LICENSED_CONTRACTOR';
  name: string;
  address?: IAddressData;
  phoneNumber?: string;
  licenses?: ILicenseData[];
  preferences?: unknown;
}

export interface ILicenseData {
  /**
   * Two-letter abbreviation of the issuing authority (state)
   */
  issuingAuthority: string;
  /**
   * Official, authority-assigned code or id of credential
   */
  classification: string;
  /**
   * Official, authority-assigned alphanumeric number that is unique to the assignee
   */
  number: string;
}

export function emptyLicense(): ILicenseData {
  return {
    issuingAuthority: '',
    classification: '',
    number: ''
  };
}

export function isLicenseValid(license: ILicenseData): boolean {
  return Boolean(license.issuingAuthority.trim() && license.classification.trim() && license.number.trim());
}
