import isNil from 'lodash/isNil';
import React, { BaseProps, Fragment, ReactElement, useState } from 'react';
import { Option } from '../../OptionsBar/Option';
import { Icon } from '../../Icon/Icon';
import {
  ClearButton,
  DropdownBtn,
  DropdownContent,
  DropdownContentLink,
  DropdownContentPanel,
  DropdownStyled,
  DropdownWrapper,
  HeaderText,
  LeftIcon,
  LinkText,
  StyledIcon
} from './styles';

type DropdownButtonProps = BaseProps & {
  name?: string;
  iconName?: string;
  className?: string;
  dropdownContentStyle?: React.CSSProperties;
  label?: string;
  items?: Option[];
  value?: string;
  showClearButton?: boolean;
  renderItem?: (item: Option, index: number) => ReactElement;
  onClear?: () => void;
  onSelect?: (item: Option) => void;
  containerStyle?: React.CSSProperties;
  fixHeight?: boolean;
};

const DropdownButton = ({
  name,
  iconName,
  className,
  containerStyle,
  dropdownContentStyle,
  label,
  items,
  value,
  showClearButton,
  renderItem,
  onClear,
  onSelect,
  fixHeight
}: DropdownButtonProps): ReactElement => {
  const [openState, clickButton] = useState(false);

  const selected = !isNil(value) && value !== '';
  return (
    <Fragment>
      {name && <HeaderText>{name}</HeaderText>}
      <DropdownWrapper
        // opened={openState}
        style={containerStyle}
        className={`${className || ''} ${selected ? 'selected' : ''}`}
      >
        <DropdownStyled
        // opened={openState}
        // hasClearButton={showClearButton && selected}
        // hasClearButton={false}
        >
          <DropdownBtn
            onClick={(): void => {
              clickButton(!openState);
            }}
          >
            <StyledIcon>
              {iconName && (
                <LeftIcon>
                  <Icon name={iconName} />
                </LeftIcon>
              )}
              <LinkText>{label}</LinkText>
            </StyledIcon>

            <Icon name="arrow-down" />
          </DropdownBtn>

          {openState && (
            <DropdownContent
              style={dropdownContentStyle}
              onClick={(): void => {
                clickButton(!openState);
              }}
              fixHeight={fixHeight}
            >
              {items &&
                items.map(
                  (item: Option, index: number): ReactElement => {
                    return renderItem ? (
                      renderItem(item, index)
                    ) : (
                      <DropdownContentLink
                        key={index}
                        onClick={(): void => onSelect && onSelect(item)}
                      >
                        <LinkText>{item.label}</LinkText>
                      </DropdownContentLink>
                    );
                  }
                )}
              <DropdownContentPanel />
            </DropdownContent>
          )}
        </DropdownStyled>
        {showClearButton && selected && (
          <ClearButton
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              if (typeof onClear === 'function') {
                onClear();
              }
            }}
          >
            <Icon name="close" />
          </ClearButton>
        )}
      </DropdownWrapper>
    </Fragment>
  );
};

export { DropdownButton, DropdownButtonProps };
