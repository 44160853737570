import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, observable
} from 'mobx';
import type { IBaseSiteEquipmentViewModelDependencies } from '../BaseSiteEquipmentViewModel';
import { BaseSiteEquipmentViewModel } from '../BaseSiteEquipmentViewModel';
import { PropsPanelUICodes } from '../../propertiesStoreConstants';

import type { MeterBase } from '../../../../../domain/models/SiteDesign/SiteEquipment/MeterBase';
import { MeterBasePropertiesViewModel } from './MeterBasePropertiesViewModel';
import { recursiveObjectAssign } from './siteEquipmentViewModelHelpers';
import type { BaseUtilityMeterProps } from 'domain/models/SiteDesign/BaseUtilityMeterProps';

type TProperties = BaseUtilityMeterProps & {
  exposure?: { type: string; constrained: boolean };
};
export class UtilityMeterViewModel extends BaseSiteEquipmentViewModel {
  readonly propUICode = PropsPanelUICodes.UtilityMeter;
  readonly title = 'Utility Meter';

  readonly exposureOptions = [
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  /** Utility Meter props */
  @observable
  exposure?: Option;
  @observable
  utilityMeterProps: MeterBasePropertiesViewModel;

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    super(dependencies);
    const setUtilityMeterPropsToDomainObject = (utilityMeterProps: BaseUtilityMeterProps): void => {
      this.setDomainProps({
        ...utilityMeterProps
      });
    };
    this.utilityMeterProps = new MeterBasePropertiesViewModel(
      dependencies.domain.project.site.address.province,
      setUtilityMeterPropsToDomainObject
    );
    this.getDomainProps();
  }

  setDomainProps(prop: TProperties): void {
    const utilityMeter = this.domain.siteEquipment.utilityMeter;
    this.updateEquipment(recursiveObjectAssign<MeterBase>(utilityMeter ?? {}, prop));
  }

  @action.bound
  getDomainProps(): void {
    const utilityMeter = this.domain.siteEquipment.utilityMeter;

    this.setExposure(utilityMeter?.exposure?.type, false);
    this.utilityMeterProps.setProperties(utilityMeter as BaseUtilityMeterProps, false);
  }

  /** Setter methods */
  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find(this.exposureOptions, this.findOptionByValue(value)) as Option;
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        exposure: {
          type: `${value}`,
          constrained: true
        }
      } as TProperties);
    }
  }
}
