import styled, {
  SimpleInterpolation,
  withTheme
} from 'styled-components';
import { Color } from '../../typings';
import { Notes } from '../FontFamily/Fonts';
import { ColorThemeProps, getColorTheme } from '../Theme/ColorThemeProps';

export type DirectionProps = {
  direction: Direction;
};

export type Direction = 'horizontal' | 'vertical';

export type Dimension = {
  [DirectionKey in Direction]: SimpleInterpolation;
};

export type WrapperProps = DirectionProps & {
  thickness: string;
  length: string;
  title?: string;
};

export type LineProps = WrapperProps & ColorThemeProps;

function setDimensionDependingOnDirection({
  direction,
  length,
  thickness,
}: WrapperProps): SimpleInterpolation {
  const dimension: Dimension  = {
    vertical : {
      height: length,
      width: thickness
    },
    horizontal : {
      height: thickness,
      width: length
    }
  };
  return dimension[direction];
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${setDimensionDependingOnDirection};
`;

export const Line = styled.div<LineProps>`
  ${setDimensionDependingOnDirection};
  background: ${
    (props: LineProps): Color =>
    getColorTheme({...props})
  };
`;

export const Title = styled(Notes)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 5px;
`;
