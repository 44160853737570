import merge from 'lodash/merge';
import { Vector3 } from 'three';
import type { ISiteData as SiteEntity } from '../../domain/entities/SiteDesign/Site';
import { SiteEquipment as SiteEquipmentDTO } from '../../domain/models/SiteDesign/SiteEquipment';
import { Site as SiteDTO } from '../../domain/models/SiteDesign/Site';
import { Parcel } from '../../domain/models/SiteDesign/Parcel';
import { SiteStructureFactory } from '../../domain/models/StructureFactory';
import { canvasConfig } from '../../config/canvasConfig';
import type { IParcelData } from '../../domain/models/SiteDesign/Parcel';
import type { Vector2D } from '../../domain/typings';
import { Address } from '../../domain/models/SiteDesign/Address';
import type { Building as BuildingDTO } from '../../domain/models/SiteDesign/Building';
import type { IBuildingData } from '../../domain/entities/SiteDesign/Building';
import type { IRoadwaysData } from '../../domain/entities/SiteDesign/Roadways';
import type { ISiteEquipmentData } from '../../domain/entities/SiteDesign/SiteEquipment';
import { BuildingsMapping } from './BuildingsMapping';
import type { IMapping } from './IMapping';
import { RoadwaysMapping } from './RoadwaysMapping';
import { VerticesMapping } from './VerticesMapping';

export class SiteMapping implements IMapping<SiteEntity, SiteDTO> {
  private readonly roadwaysMapping = new RoadwaysMapping();
  private readonly buildingMapping = new BuildingsMapping();
  private readonly verticesMapper = new VerticesMapping();

  convertEntityToDomainModel(entity: SiteEntity): SiteDTO {
    const dto: SiteDTO = new SiteDTO();

    dto.address = new Address(entity.address);
    dto.coordinateSystemOrigin = entity.coordinateSystemOrigin;
    if (entity.parcel) {
      dto.parcel = this.parcelMapper(entity.parcel);
    }
    dto.imagery = entity.imagery;
    dto.elevation = entity.elevation;
    if (entity.buildings) {
      dto.buildings.push(
        ...entity.buildings.map(
          (building: IBuildingData): BuildingDTO => this.buildingMapping.convertEntityToDomainModel(building)
        )
      );
    }
    if (entity.equipment) {
      // When site equipment mapping migration (LYRA-8480) will be done, we'll use the commented code below
      // dto.equipment = new SiteEquipmentDTO();
      // dto.equipment.fromData(entity.equipment);

      dto.equipment = new SiteEquipmentDTO(entity.equipment);
    }
    if (entity.roadways) {
      dto.equipment = merge(dto.equipment, this.getRoadwaysDTO(entity.roadways));
    }
    return dto;
  }

  convertDomainModelToEntity(dto: SiteDTO): SiteEntity {
    const entity: SiteEntity = {} as SiteEntity;
    entity.address = dto.address.toData();
    entity.coordinateSystemOrigin = dto.coordinateSystemOrigin;
    entity.parcel = dto.parcel.toData();

    entity.elevation = dto.elevation;

    if (dto.buildings) {
      entity.buildings = dto.buildings.map(
        (building: BuildingDTO): IBuildingData => this.buildingMapping.convertDomainModelToEntity(building)
      );
    }

    entity.equipment = this.getEquipmentsEntity(dto.equipment);
    entity.roadways = this.getRoadwaysEntity(dto.equipment);
    entity.imagery = dto.imagery;
    return entity;
  }

  private getRoadwaysDTO(equipment: IRoadwaysData): SiteEquipmentDTO {
    return this.roadwaysMapping.convertEntityToDomainModel(equipment);
  }

  private getEquipmentsEntity(equipment?: SiteEquipmentDTO): ISiteEquipmentData | undefined {
    if (!equipment) {
      return undefined;
    }
    const equipments = equipment.toData();
    return Object.keys(equipments).length > 0 ? equipments : undefined;
  }

  private getRoadwaysEntity(equipment?: SiteEquipmentDTO): IRoadwaysData | undefined {
    if (!equipment) {
      return undefined;
    }
    const roadways = this.roadwaysMapping.convertDomainModelToEntity(equipment);
    return Object.keys(roadways).length > 0 ? roadways : undefined;
  }

  private parcelMapper(parcelEntity: IParcelData): Parcel {
    const drawableObjectsFactory = new SiteStructureFactory();
    const parcelDTO = drawableObjectsFactory.create<Parcel>(Parcel, {
      color: canvasConfig.parcelBoundaryColor
    });
    parcelDTO.assessorsParcelNumber = parcelEntity.assessorsParcelNumber;
    parcelDTO.zoning = parcelEntity.zoning;

    if (parcelEntity.boundary?.length) {
      parcelEntity.boundary.forEach(({
        x, y
      }: Vector2D): void => {
        const coords = new Vector3(x, y, 0);
        parcelDTO.addVertex({
          vertex: coords,
          removeIfCollinear: false,
          originatingFromTracing: false
        });
        parcelDTO.redraw();
      });
      const firstVertex = parcelEntity.boundary[0];
      parcelDTO.addVertex({
        vertex: new Vector3(firstVertex.x, firstVertex.y, 0),
        removeIfCollinear: false,
        originatingFromTracing: false
      });
      parcelDTO.redraw();
    }

    return parcelDTO;
  }
}
