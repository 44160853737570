import { computed } from 'mobx';
import type { IWizardStage } from './IWizardStage';
import type { IWizardStager } from './IWizardStager';
import { StageManager } from './StageManager';

export class WizardStager extends StageManager<IWizardStage> implements IWizardStager<IWizardStage> {
  @computed
  get propCodeUI(): string {
    return this.currentStage?.propCodeUI ?? '';
  }
}
