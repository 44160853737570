import type { RoofProtrusion } from '../../../domain/models/SiteDesign/RoofProtrusion';
import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteProtrusionCommand extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    const object = this.object.mesh;
    const father = object.parent;
    father?.remove(object);
  }

  protected deleteFromDomain(): void {
    this.domain.deleteProtrusion(this.object as RoofProtrusion);
  }
}
