import type { BaseProps } from 'react';
import type React from 'react';
import type { Dictionary } from '../domain/typings';

type Component<T = BaseProps> = React.NamedExoticComponent<T> | React.FC<T>;

class Registry {
  private pool: Dictionary<Component>;

  constructor(initPool: Dictionary<Component> = {}) {
    this.pool = initPool;
  }

  get<PropType = {}>(key: string): Component<PropType> | null {
    const value = this.pool[key];

    return (value as React.FC<PropType>) || null;
  }

  add(key: string, value: Component): void {
    this.pool[key] = value;
  }
}

export default Registry;
