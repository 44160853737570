import React, { type ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import type { FileError } from 'react-dropzone';
import { LyraDropzone } from '@aurorasolar/lyra-ui-kit';
import { ERROR } from '../../../../../../domain/models/Constants';
import { notify } from '../../../../../../utils/helpers';
import type { ISectionProps } from '../ImagesAndDocuments';
import {
  Header, Message
} from '../../styles';
import PermitPackageFiles from '../../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import DocumentsList from './DocumentsList/DocumentsList';
import {
  DocumentsContainer, Documents
} from './DocumentsSection.styles';

const acceptedDocumentFormats = { 'application/pdf': ['.pdf'] };
const maxFileSize = 10 * Math.pow(1024, 2);

function DocumentsSection({ viewModel }: ISectionProps): ReactElement {
  const documentsList = viewModel.documentGenerationOptions?.includedFiles.designFiles ?? [];

  return (
    <DocumentsContainer>
      <Header>Design Files</Header>

      <Message>
        If this Project requires <strong>drawings</strong> or <strong>notes</strong> that are not already included in
        our Permit Package you can upload them from here.
      </Message>
      <Documents>
        <LyraDropzone.DropzoneContainer
          noClick={true}
          noKeyboard={true}
          accept={acceptedDocumentFormats}
          maxSize={maxFileSize}
          withFileNamePreview={false}
          onError={(rejectedFiles: FileError): void => {
            notify(rejectedFiles.message, ERROR);
          }}
          saveFiles={(files: File[]): void => {
            viewModel.saveDesignFile(files[0]);
          }}
          showLoader={viewModel.isSaving}
        />
        <DocumentsList
          filesList={documentsList}
          filesTypes={PermitPackageFiles.fileTypeOptions}
          onFileDelete={viewModel.deleteFile}
          onFileTypeChange={viewModel.updateFileType}
          onFileTitleChange={viewModel.updateFileTitle}
        />
      </Documents>
    </DocumentsContainer>
  );
}

export default observer(DocumentsSection);
