import type Store from '../../../../../stores/Store';
import {
  SelectionTool,
  type ISelectionToolDependencies
} from '../../../../../stores/UiStore/ToolbarStore/Project/SelectionTool';
import withTool from '../../Shared/withTool';

type SelectionToolDependencies = Pick<
  ISelectionToolDependencies,
  'properties' | 'domain' | 'serviceBus' | 'smartGuides'
>;
const Selection = withTool<SelectionTool, ISelectionToolDependencies, SelectionToolDependencies>(
  SelectionTool,
  ({
    properties, domain, serviceBus, smartGuides
  }: Store): SelectionToolDependencies => ({
    properties,
    domain,
    serviceBus,
    smartGuides
  })
);

export default Selection;
