import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, observable
} from 'mobx';
import type { MarkerAttributes } from '../../../../../domain/typings';
import type { IBaseSiteEquipmentViewModelDependencies } from '../BaseSiteEquipmentViewModel';
import { BaseSiteEquipmentViewModel } from '../BaseSiteEquipmentViewModel';
import { PropsPanelUICodes } from '../../propertiesStoreConstants';
import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { LoadCenter } from '../../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';
import type { Subpanel } from '../../../../../domain/models/SiteDesign/Subpanel';
import type { MeterMain } from '../../../../../domain/models/SiteDesign/SiteEquipment/MeterMain';
import { recursiveObjectAssign } from './siteEquipmentViewModelHelpers';

type TProperties = {
  exposure?: { type: string; constrained: boolean };
  manufacturer?: string;
  model?: string;
  busbarRating?: number;
  busbarPointOfConnection?: string;
  mainBreakerRating?: number;
  feederOcpdRating?: number;
};
export class SubpanelViewModel extends BaseSiteEquipmentViewModel {
  readonly propUICode = PropsPanelUICodes.Subpanel;
  readonly title = 'Subpanel';

  readonly typeOptions = [
    {
      name: 'Regular',
      value: 'REGULAR'
    },
    {
      name: 'Main Lug Only',
      value: 'NONE'
    }
  ];

  readonly exposureOptions = [
    {
      name: 'INDOOR',
      value: 'INDOOR'
    },
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  /** Sub Panel props */
  @observable
  exposure?: Option;
  @observable
  manufacturer?: Option;
  @observable
  model?: string;
  @observable
  busbarRating?: Option;
  @observable
  busbarPointOfConnection?: Option;
  @observable
  type: Option = this.typeOptions[0];
  @observable
  mainBreakerRating?: Option;
  @observable
  feederOcpdRating?: Option;

  /** Options */
  @observable
  manufacturerOptions: Option[] = [];
  @observable
  busbarRatingOptions: Option[] = [];
  @observable
  busbarPointOfConnectionOptions: Option[] = [];
  @observable
  mainBreakerRatingOptions: Option[] = [];
  @observable
  feederOcpdRatingOptions: Option[] = [];

  protected mainServicePanel: LoadCenter;
  private meterMain?: MeterMain;

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    super(dependencies);
    this.mainServicePanel = this.domain.siteEquipment.mainServicePanel!;
    this.meterMain = this.domain.siteEquipment.meterMain;
    this.loadOptions().then(this.getDomainProps);
  }

  setDomainProps(prop: TProperties): void {
    const subpanel = this.domain.siteEquipment.subpanel;
    const updatedSubpanel = recursiveObjectAssign<Subpanel>(subpanel ?? {}, prop);
    if (prop.hasOwnProperty('mainBreakerRating') && !prop.mainBreakerRating) {
      updatedSubpanel.mainBreakerRating = undefined;
    }
    if (prop.hasOwnProperty('feederOcpdRating') && !prop.feederOcpdRating) {
      updatedSubpanel.feederOcpdRating = undefined;
    }
    this.updateEquipment(updatedSubpanel);
  }

  @action.bound
  async getDomainProps(): Promise<void> {
    const subpanel = this.domain.siteEquipment.subpanel;

    this.setExposure(subpanel?.exposure?.type, false);
    this.setManufacturer(subpanel?.manufacturer, false);
    this.setModel(subpanel?.model, false);
    this.setBusbarRating(subpanel?.busbarRating, false);
    this.setBusbarPointOfConnection(subpanel?.busbarPointOfConnection, false);
    this.setType(subpanel?.feederOcpdRating ? 'NONE' : 'REGULAR', false);

    if (this.mainServicePanel && this.busbarRating) {
      await this.loadSubpanelMainBreakerRatingOptions(
        Number(this.busbarRating.value),
        Number(this.mainServicePanel.busbarRating)
      );
      if (this.mainServicePanel.mainBreakerRating || this.meterMain?.mainBreakerRating) {
        await this.loadFeederOcpdRatingOptions(
          Number(this.busbarRating.value),
          Number(this.meterMain ? this.meterMain.mainBreakerRating : this.mainServicePanel.mainBreakerRating)
        );
        this.setFeederOcpdRating(subpanel?.feederOcpdRating, false);
      }
      this.setMainBreakerRating(subpanel?.mainBreakerRating, false);
    }
  }

  /** Service request */
  async loadOptions(): Promise<void> {
    // For a better UX, all the requests should execute at the same time
    await Promise.all([this.loadManufacturer(), this.loadBusbarRating(), this.loadBusbarPointOfConnection()]);
  }
  async loadManufacturer(): Promise<void> {
    await this.equipmentService.getPanelManufacturerOptions().then((response: IOption<MarkerAttributes>[]): void => {
      this.manufacturerOptions = this.mapToItemOption(response);
    });
  }
  async loadBusbarRating(): Promise<void> {
    const mspBusbarRating = this.domain.project?.site?.equipment?.mainServicePanel?.busbarRating;
    await this.designService
      .getSubpanelBusbarRatingOptions(mspBusbarRating ?? 0)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.busbarRatingOptions = this.mapToItemOption(response);
      });
  }
  async loadBusbarPointOfConnection(): Promise<void> {
    await this.designService.getBusbarPointOfConnectionOptions().then((response: IOption<MarkerAttributes>[]): void => {
      this.busbarPointOfConnectionOptions = this.mapToItemOption(response);
    });
  }
  async loadSubpanelMainBreakerRatingOptions(subpanelBusbarRating: number, mspBusbarRating: number): Promise<void> {
    await this.designService
      .getSubpanelMainBreakerRatingOptions(subpanelBusbarRating, mspBusbarRating)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerRatingOptions = this.mapToItemOption(response);
      });
  }
  async loadFeederOcpdRatingOptions(subpanelBusbarRating: number, mspMainBreakerRating: number): Promise<void> {
    await this.designService
      .getSubpanelFeederOcpdRatingOptions(subpanelBusbarRating, mspMainBreakerRating)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.feederOcpdRatingOptions = this.mapToItemOption(response);
      });
  }

  /** Setter methods */
  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find<Option>(this.exposureOptions, this.findOptionByValue(value));
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        exposure: {
          type: `${value}`,
          constrained: false
        }
      });
    }
  }
  @action.bound
  setManufacturer(value?: string | number, shouldUpdate: boolean = true): void {
    const manufacturer = find(this.manufacturerOptions, this.findOptionByValue(value));
    if (manufacturer) {
      this.manufacturer = manufacturer;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        manufacturer: `${value}`
      });
    }
  }
  @action.bound
  setModel(value?: string, shouldUpdate: boolean = true): void {
    this.model = value;
    if (shouldUpdate) {
      this.setDomainProps({
        model: `${value}`
      } as Subpanel);
    }
  }
  @action.bound
  setBusbarRating(value?: string | number, shouldUpdate: boolean = true): void {
    const busbarRating = find(this.busbarRatingOptions, this.findOptionByValue(value));
    if (busbarRating) {
      this.busbarRating = busbarRating;
    }
    if (shouldUpdate) {
      this.mainBreakerRating = undefined;
      this.feederOcpdRating = undefined;
      if (busbarRating) {
        if (this.mainServicePanel.mainBreakerRating) {
          this.loadFeederOcpdRatingOptions(Number(busbarRating.value), Number(this.mainServicePanel.mainBreakerRating));
        }
        this.loadSubpanelMainBreakerRatingOptions(
          Number(busbarRating.value),
          Number(this.mainServicePanel.busbarRating)
        );
      }
      this.setDomainProps({
        busbarRating: Number(value),
        feederOcpdRating: undefined,
        mainBreakerRating: undefined
      });
    }
  }
  @action.bound
  setBusbarPointOfConnection(value?: string | number, shouldUpdate: boolean = true): void {
    const busbarPointOfConnection = find(this.busbarPointOfConnectionOptions, this.findOptionByValue(value));
    if (busbarPointOfConnection) {
      this.busbarPointOfConnection = busbarPointOfConnection;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        busbarPointOfConnection: `${value}`
      });
    }
  }
  @action.bound
  setType(value?: string | number, shouldUpdate: boolean = true): void {
    const type = find<Option>(this.typeOptions, this.findOptionByValue(value));
    if (type) {
      this.type = type;
    }
    if (shouldUpdate) {
      const updateData: TProperties = {};
      if (value === 'REGULAR') {
        this.feederOcpdRating = undefined;
        updateData.feederOcpdRating = undefined;
      } else {
        this.mainBreakerRating = undefined;
        updateData.mainBreakerRating = undefined;
      }
      this.setDomainProps(updateData);
    }
  }
  @action.bound
  setMainBreakerRating(value?: string | number, shouldUpdate: boolean = true): void {
    const mainBreakerRating = find(this.mainBreakerRatingOptions, this.findOptionByValue(value));
    if (mainBreakerRating) {
      this.mainBreakerRating = mainBreakerRating;
    }
    if (shouldUpdate) {
      this.setDomainProps({ mainBreakerRating: Number(value) });
    }
  }
  @action.bound
  setFeederOcpdRating(value?: string | number, shouldUpdate: boolean = true): void {
    const feederOcpdRating = find(this.feederOcpdRatingOptions, this.findOptionByValue(value));
    if (feederOcpdRating) {
      this.feederOcpdRating = feederOcpdRating;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        feederOcpdRating: Number(value)
      });
    }
  }
}
