import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { ORDER_LAYER_TOP, TOOL } from './constants';
import { FlexBG } from './FlexBG';

const Tool = ({ children, className }: BaseProps): ReactElement => {
  return (
    <Area
      name={TOOL}
      layer={ORDER_LAYER_TOP}
      className={className}
    >
      <FlexBG
        dir="column"
        align="center"
        justify="flex-start"
        fullHeight
      >
        {children}
      </FlexBG>
    </Area>
  );
};

export { Tool };
