import styled, { css, SimpleInterpolation } from 'styled-components';
import { Flexbox } from '../../Layout/Flexbox/index';

type ContainerProps = {
  width?: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation =>
    css`
      width: ${width};
    `}
  box-sizing: border-box;
`;

export const ListFlexWraper = styled(Flexbox).attrs({
  dir: 'column',
  justify: 'center',
  align: 'flex-start'
})`
  width: 100%;
`;

const hiddenLabel = css`
  opacity: 0;
  visibility: hidden;
  height: 0;
`;

const visibleLabel = css`
  opacity: 1;
  visibility: visible;
  height: 100%;
`;

type InputProps = {
  visible: boolean;
  disabled: boolean;
};

export const InputStyle = styled.div<InputProps>`
  ${({ visible }: InputProps): SimpleInterpolation =>
    visible ? visibleLabel : hiddenLabel}
  ${({ disabled = false }: InputProps): string =>
    disabled ? 'cursor: not-allowed;' : 'cursor: pointer;'}
  background-color: white;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 10px;
  padding: 6px 10px;

  &:active,
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: 10px;
`;
