import React, {
  BaseProps,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Option } from '../../../typings/Option';
import { Icon } from '../../Icon';
import { List } from '../../List';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { Label, LabelProps } from '../HALabel/index';
import { Container, IconContainer, SelectList, SelectStyle } from './styles';

type Props = BaseProps & LabelProps & {
  label?: string | ReactNode;
  placeholder?: string | ReactNode;
  listOptions?: Option[];
  onSelect?: (value: string | number) => void;
  selected?: string | number;
  disabled?: boolean;
  width?: string;
  height?: string;
  selectedOptionListColor?: EThemeComponentColor;
  /**
   * Leave the placeholder in the select field
   * and the selected option will be below the select field
   */
  displayOptionBelow?: boolean;
  showList?: boolean;
};

const DEFAULT_PLACEHOLDER = '- HASelect an option -';

function HASelect({
  className,
  disabled,
  label,
  listOptions = [],
  onSelect,
  selected,
  width,
  height,
  color,
  selectedOptionListColor = EThemeComponentColor.BLUE,
  showList = true
}: Props): ReactElement {
  const [currentSelected, setCurrentSelected] = useState(
    selected
      ? listOptions.find((option: Option): boolean => option.value === selected)
      : null
  );
  const [currentDescription, setCurrentDescription] = useState<string | ReactNode>(
    DEFAULT_PLACEHOLDER
  );
  const [collapsed, setCollapsed] = useState(showList);
  const [disable, setDisable] = useState(disabled);

  const onInputClick = useCallback((): void => {
    if (!disabled) setCollapsed(false);
  }, [disabled]);

  useEffect((): void => {
    const selectedOption: Option | undefined = listOptions.find(
      (option: Option): boolean => option.value === selected
    );

    if (selectedOption !== undefined) {
      setCurrentSelected(selectedOption);
    }
  }, [selected, listOptions]);

  useEffect((): void => {
    if (listOptions.length === 0 || listOptions === undefined) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [listOptions, disabled]);

  useEffect((): void => {
    if (currentSelected) {
      setCurrentDescription(currentSelected.name);
    }
  }, [currentSelected]);

  const onSelectList = (value: string | number): void => {
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
    const selectedData = listOptions.find(
      (option: Option): boolean => option.value === value
    );

    setCollapsed(true);
    setCurrentSelected(selectedData);
  };

  return (
    <Container width={width ? width : '100%'} className={className}>
      <Label color={color} name={''} label={label} />
      <SelectStyle
        collapsed={collapsed}
        onClick={onInputClick}
        disabled={disable}
        height={height}
        data-testid="haselect-open"
      >
        {currentDescription}
        <IconContainer>
          <Icon name="arrow-down" />
        </IconContainer>
      </SelectStyle>
      {!collapsed ? (
        <SelectList>
          <List
            options={listOptions}
            onSelect={onSelectList}
            selected={currentSelected ? currentSelected.value : undefined}
            selectedOptionColor={selectedOptionListColor}
          />
        </SelectList>
      ) : (
        <></>
      )}
    </Container>
  );
}

export { HASelect };
