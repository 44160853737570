import React, { BaseProps, ReactElement } from 'react';
import {
  ButtonWrapper,
  UpperView,
  TitleText,
  ButtonListContainer,
  ButtonGroupWrapper
} from './styles';
import { CTAButtonProps, CTAButton } from '../Buttons/CTAButton';
import { Icon } from '../Icon';

export type ButtonListProps = BaseProps & {
  label: string;
  iconName: string;
  buttons: CTAButtonProps[];
};

const ButtonList = ({
  label, iconName, buttons
}: ButtonListProps): ReactElement => {
  return (
    <ButtonListContainer>
      <UpperView>
        <Icon name={iconName} />
        <TitleText>{label}</TitleText>
      </UpperView>
      <ButtonGroupWrapper>
        {buttons.map((button: CTAButtonProps, index: number): ReactElement => {
          return (
            <ButtonWrapper key={index}>
              <CTAButton
                iconName={button.iconName}
                label={button.label}
                trigger={button.trigger}
                fullWidth={button.fullWidth}
              />
            </ButtonWrapper>
          );
        })}
      </ButtonGroupWrapper>
    </ButtonListContainer>
  );
};

export { ButtonList };
