import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';
import EButtonType from './EButtonType';

type ButtonStyleProps = {
  themeType?: string | EButtonType;
  selected?: boolean;
  error?: boolean;
};

export type PropsWithTheme = PropsTheme & ButtonStyleProps;

/**
 * Select a prefab button style
 *
 * @param theme - to take the theme colors
 * @param themType - style of the button
 * @return - css rule
 */
function getButtonStyle({
  theme, themeType
}: PropsWithTheme): string {
  let bgColor: Color;
  let fontColor: Color;
  let onHover: Color = '';

  switch (themeType) {
  case EButtonType.MAIN:
    const btnColor = theme.colors.main1;
    bgColor = btnColor;
    onHover = btnColor;
    fontColor = theme.colors.highlight;
    break;
  case EButtonType.PRIMARY:
    bgColor = theme.colors.primary;
    fontColor = theme.colors.highlight;
    onHover = theme.colors.primaryHover;
    break;
  case EButtonType.SECONDARY:
  default:
    bgColor = theme.colors.tertiary;
    fontColor = theme.colors.secondaryFont;
    onHover = theme.colors.tertiary;
    break;
  }

  return `
    background-color: ${bgColor};
    color: ${fontColor};
    &:hover{
      background-color: ${onHover};
    }
  `;
}

function square({
  themeType, theme
}: PropsWithTheme): string {
  if (themeType === EButtonType.SQUARE) {
    return `
    padding: 10px 14px 10px 0px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;
    background-color: ${theme.colors.whiteBg};

    & > :first-child {
      margin: 0 15px;
    }

    :hover {
      border: 2px solid ${theme.colors.primary};
    }
    `;
  } else {
    return `
    padding: 10px 30px;
    font-size: 16px;
    border-radius: 20px;
    & > :first-child {
      margin: 0 5px;
    }
    `;
  }
}

function isSelected({
  selected, theme
}: PropsWithTheme): string {
  return selected ? `border: 2px solid ${theme.colors.primary};` : 'border: none;';
}

export const ButtonStyle = styled.button<PropsWithTheme>`
  ${getButtonStyle};
  ${square};
  ${isSelected}
  ${({
    theme, themeType
  }: PropsWithTheme): string =>
    themeType === EButtonType.SQUARE ? `border: 1px solid ${theme.colors.tertiary};` : ''}
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.35;
  }
  &:active {
    opacity: 0.8;
  }
`;
