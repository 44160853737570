import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import {
  SwitchBar, SwitchCircle, SwitchStyle
} from './styles';
import type SwitchProps from './SwitchProps';

function Switch({
  actived, onChange, className
}: SwitchProps): ReactElement {
  const [checked, setChecked] = useState(actived);
  const handleClick = useCallback((): void => {
    const newVal = !checked;
    onChange?.(newVal);
    setChecked(newVal);
  }, [checked, onChange]);

  useEffect((): void => {
    setChecked(actived);
  }, [actived]);

  return (
    <SwitchStyle className={className} onClick={handleClick}>
      <SwitchBar actived={checked} />
      <SwitchCircle actived={checked} />
    </SwitchStyle>
  );
}

export default Switch;
