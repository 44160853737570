import React, { BaseProps, ReactElement } from 'react';
import {
  HintWrapper,
  TipText,
  DirectionArrowView,
  ArrowView,
  ArrowIcon,
  HintContentWrapper,
  InstructionView,
  ShowHintWrapper,
  BoldTitleText,
  LightDescriptionText,
  SeparatorView,
  ColorPanelView,
  ColorView,
  ColoredText,
  GreenColorView,
  BlueColorView,
  YellowColorView,
  RedColorView
} from './styles';

export type HintProps = BaseProps & {
  isShow: boolean;
  trigger: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showHintForMPPT?: boolean;
};

const Hint = ({
  className,
  isShow,
  trigger,
  showHintForMPPT
}: HintProps): ReactElement => {

  return (
    <>
    {isShow ?
      <ShowHintWrapper className={className} onClick={trigger}>
        <HintContentWrapper>
          <DirectionArrowView>
            <ArrowView>
              <ArrowIcon name="accordion-arrow-down" />
            </ArrowView>
          </DirectionArrowView>
          <TipText>
            HIDE TIPS & REFERENCES
          </TipText>
        </HintContentWrapper>
        <InstructionView>
          <BoldTitleText>
            HOW TO START?
          </BoldTitleText>
          {showHintForMPPT ? (
              <LightDescriptionText>
                Hover over a module and click to pick an Inverter and MPPT channel.
              </LightDescriptionText>
          ) : (
              <LightDescriptionText>
                Hover over a module and click to start stringing.
              </LightDescriptionText>
          )}
          <SeparatorView />
          <BoldTitleText>
            TO BEGIN STRINGING
          </BoldTitleText>
          <LightDescriptionText>
            Click over modules to add them to the string.
          </LightDescriptionText>
          <LightDescriptionText>
            Click & drag for faster configuration.
          </LightDescriptionText>
          <SeparatorView />
          <BoldTitleText>
            TO CLOSE STRING
          </BoldTitleText>
          <LightDescriptionText>
            Double click on the circle in the last module of the string.
          </LightDescriptionText>
          <SeparatorView />
          <BoldTitleText>
            STRING STATES REFERENCES
          </BoldTitleText>
          <ColorPanelView>
            <ColorView />
            <ColoredText>
              Under voltage (invalid configuration)
            </ColoredText>
          </ColorPanelView>
          <ColorPanelView>
            <GreenColorView />
            <ColoredText>
              Under optimal voltage
            </ColoredText>
          </ColorPanelView>
          <ColorPanelView>
            <BlueColorView />
            <ColoredText>
              Optimal voltage
            </ColoredText>
          </ColorPanelView>
          <ColorPanelView>
            <YellowColorView />
            <ColoredText>
              Over MPPT voltage
            </ColoredText>
          </ColorPanelView>
          <ColorPanelView>
            <RedColorView />
            <ColoredText>
              Over inverter voltage  (invalid conf.)
            </ColoredText>
          </ColorPanelView>
        </InstructionView>
      </ShowHintWrapper>
    :
      <HintWrapper onClick={trigger}>
        <HintContentWrapper>
          <DirectionArrowView>
            <ArrowView>
              <ArrowIcon name="accordion-arrow-up" />
            </ArrowView>
          </DirectionArrowView>
          <TipText>
            SHOW TIPS & REFERENCES
          </TipText>
        </HintContentWrapper>
      </HintWrapper>
    }
    </>
  );
};

export { Hint };
