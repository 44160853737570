/**
 * Error logging
 *
 * Can be used to log errors to console or and external
 * error logging system, like Sentry.
 *
 */
import * as Sentry from '@sentry/react';

import {
  reportingObserverIntegration,
  extraErrorDataIntegration,
  captureConsoleIntegration
} from '@sentry/integrations';
import config from '../config/config';

const arr = new Array<string>();
interface ApiError {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
  code: string;
  length: number;
  meta: typeof arr;
  key: string;
  data: {
    errors: typeof arr;
  };
}
interface IBackendErrorResponse {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
}

/**
 * return apiErrors from error response
 */
/* FIXME - (e) returning as string for some reason form error: ApiError.
// Removing until more API error testing is done

type ApiError = [
  error: string,
  exception: string,
  message: string,
  path: string,
  status: number,
  timestamp: number,
  code: string,
  length: number,
  meta: typeof arr,
  key: string,
  code: string,
  data: typeof arr,
];

const responseAPIErrors = (error: ApiError) => {
  return error && error.data && error.data.errors ? error.data.errors : [];
};

const responseApiErrorInfo = (error: ApiError) =>
  responseAPIErrors(error).map(e => ({
    status: e.status,
    code: e.code,
    meta: e.meta,
    key: e.key,
  }));
*/

const showErrorDialog = (code: ApiError['code']) =>
  config.sentry.showDialog && !config.sentry.shouldIgnoreErrorCode(code);

/**
 * Set up error handling. If a Sentry DSN is
 * provided a Sentry client will be installed.
 */
export const SentrySetup = () => {
  const sentry = config.sentry;

  if (sentry.enabled && sentry.dsn) {
    Sentry.init({
      dsn: sentry.dsn,
      environment: config.host.environment,
      release: `${config.designTool.version}`,
      autoSessionTracking: sentry.autoSessionTracking,
      maxBreadcrumbs: sentry.maxBreadcrumbs,
      attachStacktrace: sentry.attachStacktrace,
      tracesSampleRate: sentry.tracesSampleRate,
      integrations: [
        new Sentry.BrowserTracing(),
        reportingObserverIntegration(),
        extraErrorDataIntegration({
          // limit of how deep the object serializer should go. Anything deeper than limit will
          // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
          // a primitive value. Defaults to 3.
          depth: sentry.dataIntegrationDepth
        }),
        captureConsoleIntegration({
          levels: sentry.captureConsole
        })
      ],
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      tracePropagationTargets: ['localhost', config.site.canonicalRootUrl, /^\//]
      // This causes a bug right now, but according to documentation it is correct:
      // https://docs.sentry.io/platforms/javascript/configuration/transports/
      // Let's uncomment when a future version will fix it.
      /*
      transportOptions: {
        maxQueueSize: sentry.maxStoredEvents
      }
      */
    });
    // eslint-disable-next-line no-console
    console.log(`Sentry has been loaded for Design Tool version ${config.designTool.version}`);
  }
};

/**
 * Set user ID for the logger so that it
 * can be attached to Sentry issues.
 *
 * @param {String} userId ID of current user
 */
const SentrySetUserId = (userId: string) => {
  Sentry.getCurrentScope().setUser({
    id: userId
  });
};

/**
 * Clears the user ID.
 */
const SentryClearUserId = () => {
  Sentry.getCurrentScope().setUser(null);
};

/**
 * Logs a front end exception. If Sentry is configured sends the error information there.
 * Otherwise prints the error to the console.
 */
export const SentryException = (
  message: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exception: Error | IBackendErrorResponse | any,
  attachment?: Object
) => {
  const sentry = config.sentry;
  const scope = new Sentry.Scope();
  scope.setTag('message', message);
  // Creating an error in case we have no exception so that we'll see a stack trace in sentry.
  const exceptionToUse = exception || new Error(message);
  if (sentry.dsn) {
    if (attachment) {
      const data = JSON.stringify(attachment, null, '\t');
      scope.addAttachment({
        filename: 'data.json',
        data
      });
    }
    Sentry.captureException(exceptionToUse, () => scope);
    if (exceptionToUse && sentry.showDialog) {
      Sentry.showReportDialog({
        eventId: exceptionToUse.message
      });
    }
  }
  // Use .log so that it's not reported to Sentry for the second time via console capture.
  // eslint-disable-next-line no-console
  console.log(message, JSON.stringify(exception), JSON.stringify(attachment));
};

/**
 * Logs a full API exception. If Sentry is configured
 * sends the error information there. Otherwise
 * prints the error to the console.
 *
 * @param {Error} e Error that occurred
 * @param {String} code Error code
 * @param {Object} data Additional data to be sent to Sentry
 */
/*
/* FIXME - Removing until more API error testing is done
export const apiError = (e: ApiError, code: ApiError['code'], data: ApiError['data'], key: ApiError['key']) => {
  // const apiErrors = responseApiErrorInfo(e);
  if (sentry.dsn) {
    const extra = { ...data, apiErrorData: e, key: key };
    Sentry.withScope(scope => {
      scope.setTag('code', code);
      Object.keys(extra).forEach(key => {
        scope.setExtra(key, extra[key]);
      });
      Sentry.captureException(e);
      if (e && showErrorDialog(code)) {
        Sentry.showReportDialog({ eventId: e.code });
      }
    // });

    // printAPIErrorsAsConsoleTable(e);
  } else {
    console.error(e);
    console.error('Error code:', code, 'data:', data);
    // printAPIErrorsAsConsoleTable(e);
  }
};
*/
