import styled, { withTheme } from 'styled-components';

type OptionProps = {
  padding?: string;
  fontSize?: string;
};

export const Layout = styled.div<OptionProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }: OptionProps): string => padding ?? '15px 0'};
  & > span {
    margin-bottom: 4px;
    font-size: ${({ padding }: OptionProps): string => padding ?? '25px 0'};
    font-size: ${({ fontSize }: OptionProps): string => fontSize ?? '14px'};
  }
`;
