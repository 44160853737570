import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import config, { UI_MODE } from '../../../config/config';

export const CustomButton = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  text-indent: 13px;
`;
