import { KeyboardListener } from '../../utils/KeyboardListener';

export interface IKeyboardBehaviourHandler {
  onKeyDown(event: KeyboardEvent): void;
  onKeyUp(event: KeyboardEvent): void;
}

export const KeyboardBehaviour = {
  addKeyboardEvents: (instance: IKeyboardBehaviourHandler): void => {
    KeyboardListener.getInstance().signals.down.add(instance.onKeyDown);
    KeyboardListener.getInstance().signals.up.add(instance.onKeyUp);
  },

  removeKeyboardEvents: (instance: IKeyboardBehaviourHandler): void => {
    KeyboardListener.getInstance().signals.down.remove(instance.onKeyDown);
    KeyboardListener.getInstance().signals.up.remove(instance.onKeyUp);
  }
};
