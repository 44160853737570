import {
  LyraLoader, LyraModal, LyraTabs
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactText
} from 'react';
import React, { useState } from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import useStore from '../../../../stores/useStore';
import type { UpsertInstallerAndCreateProjectViewModel } from '../../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import { CreateProjectStep } from './Steps/CreateProjectStep';
import { NameAndLicensesStep } from './Steps/NameAndLicensesStep';
import { AddressAndPhoneNumberStep } from './Steps/AddressAndPhoneNumberStep';
import { UploadCompanyLogoStep } from './Steps/UploadCompanyLogoStep';
import {
  CompanySettingsTabsWrapper, ModalWrapper, Container
} from './styles';

const UpsertInstallerAndCreateProjectModal = (): ReactElement => {
  const {
    modal, workspace: workspace, roofProtrusion, editor
  } = useStore();

  // Setting view model used by the component
  const [upsertInstallerAndCreateProjectViewModel] = useState<UpsertInstallerAndCreateProjectViewModel>(
    modal.viewModel as UpsertInstallerAndCreateProjectViewModel
  );

  const startupMode = upsertInstallerAndCreateProjectViewModel.startupMode;

  const tabsProps = {
    options: [
      {
        label: (
          <span>
            Name &<br />
            Licenses
          </span>
        ),
        value: 1
      },
      {
        label: (
          <span>
            Address &<br />
            Phone Number
          </span>
        ),
        value: 2
      },
      {
        label: (
          <span>
            Logo
            <br />
            &nbsp;
          </span>
        ),
        value: 3
      }
    ],
    value: upsertInstallerAndCreateProjectViewModel.step,
    onClick: (newStep: ReactText): void => {
      upsertInstallerAndCreateProjectViewModel.step = newStep as number;
    }
  };

  const showOnlyCreateProject: boolean =
    startupMode && upsertInstallerAndCreateProjectViewModel.onlyProjectCreationOnStartupMode;

  upsertInstallerAndCreateProjectViewModel.setLoadProjectDependencies({
    editor,
    roofProtrusion,
    workspace
  });

  const rightButtonLabel = showOnlyCreateProject ? 'Create' : 'Next';
  const leftButtonLabel = showOnlyCreateProject ? undefined : 'Previous';

  const rightButtonDisabled =
    !upsertInstallerAndCreateProjectViewModel.isValid
    || upsertInstallerAndCreateProjectViewModel.installerSavingInProgress
    || (!startupMode && upsertInstallerAndCreateProjectViewModel.step === 3);

  const leftButtonDisabled =
    (startupMode && upsertInstallerAndCreateProjectViewModel.step === 1) || showOnlyCreateProject;

  const modalProps = {
    id: 'create-project',
    className: 'create-project',
    title: 'CREATE PROJECT',
    color: EThemeComponentColor.PURPLE,
    showLeftButton: !startupMode,
    leftButtonLabel: startupMode ? leftButtonLabel : 'Close',
    rightButtonLabel: startupMode ? rightButtonLabel : 'Save',
    rightButtonDisabled,
    leftButtonDisabled,
    $show: true,
    showHeaderDivider: false,
    showCloseButton: false,
    onClickLeftButton: (): void => {
      if (!leftButtonDisabled) {
        if (startupMode) {
          upsertInstallerAndCreateProjectViewModel.handlePreviousStep();
        } else {
          upsertInstallerAndCreateProjectViewModel.closeModal();
        }
      }
    },
    onClickRightButton: (): void => {
      if (startupMode) {
        upsertInstallerAndCreateProjectViewModel.handleNextStepInCreateProjectWizard({
          editor,
          roofProtrusion,
          workspace
        });
      } else {
        upsertInstallerAndCreateProjectViewModel.saveInstallerOutsideOfCreateProjectWizard();
      }
    }
  };

  const currentStep: number = upsertInstallerAndCreateProjectViewModel.step;

  modalProps.title = startupMode ? 'Start Your Permit-Ready Project' : 'Edit Contractor Information';

  const withSteps = startupMode && !showOnlyCreateProject;

  const modalSteps = withSteps ? 4 : undefined;
  const modalCurrentStep = withSteps ? currentStep : undefined;

  return (
    <>
      {upsertInstallerAndCreateProjectViewModel.isLoading ? (
        <Container>
          <LyraLoader.Loader />
        </Container>
      ) : (
        <LyraModal.Modal {...modalProps} steps={modalSteps} currentStep={modalCurrentStep}>
          <ModalWrapper step={currentStep}>
            {!startupMode && (
              <CompanySettingsTabsWrapper>
                <LyraTabs.Tabs
                  {...tabsProps}
                  layoutClassName="ph-xs pv-xxs"
                  activeThemeColor={EThemeComponentColor.PURPLE}
                  inactiveThemeColor={EThemeComponentColor.TRANSPARENT}
                />
              </CompanySettingsTabsWrapper>
            )}
            {currentStep === 1 && <NameAndLicensesStep />}
            {currentStep === 2 && <AddressAndPhoneNumberStep />}
            {currentStep === 3 && <UploadCompanyLogoStep />}
            {currentStep === 4 && <CreateProjectStep />}
          </ModalWrapper>
        </LyraModal.Modal>
      )}
    </>
  );
};

export default observer(UpsertInstallerAndCreateProjectModal);
