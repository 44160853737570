import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { Icon } from '../Icon';
import { Button } from '../Button';

export const DropzoneContainerWithOutPreview = styled.div`
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: grabbing;
  color: ${({ theme }: PropsTheme): Color => theme.colors.neutral};
  
  .divider-title {
    color: ${({ theme }: PropsTheme): Color => theme.colors.neutral};
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.gray3};
  }
`;

export const DropzoneWrapperWithOutPreview = styled.div`
  flex-shrink: 0;
  width: 170px;
  height: 130px;
  overflow: hidden;
  border-radius: 10px;
  border-style: dashed;
  border-width: 1px;
  border-color: ${({ theme }: PropsTheme): Color => theme.colors.gray5};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.gray3};

  &:hover {
    border-color: ${({ theme }: PropsTheme): Color => theme.colors.gray2};
  }

  @media (max-width: 1180px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const DropzoneWrapper = styled.div`
  font-weight: normal !important;
  width: 75%;
  height: 125px;
  margin: 20px;
  background: white;
  border-radius: 15px;
  border: 1px dashed black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  &:hover {
    border-color: ${({ theme }: PropsTheme): Color => theme.colors.gray2};
    cursor: grabbing;
  }
  b {
    word-break: break-all;
  }
`;

export const BrowseButton = styled(Button)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.neutral};
  box-shadow: rgb(203 203 203) 1px 1px 3px 2px;
`;

export const DropzoneOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SupportedFormatString = styled.span`
  color: ${({ theme }: PropsTheme): Color => theme.colors.gray2 || ''};
  margin-top: 7px;
  font-size: 14px;
`;

export const CheckIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
`;

export const ButtonFont = styled.span`
  font-weight: normal !important;
`;


export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${({ theme }: PropsTheme): Color => theme.colors.neutral};
`;
