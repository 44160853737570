import type { Dictionary } from '../../../domain/typings';
import { AzimuthChangedHandler } from './AzimuthChangedHandler';
import type { IHandler } from './IHandler';
import { RoofTypeChangedHandler } from './RoofTypeChangedHandler';
import { SlopeChangedHandler } from './SlopeChangedHandler';

export type HandlerConstructor<A = IHandler> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (deps: any) => A;

export const registryHandlers: Dictionary<HandlerConstructor[]> = {
  azimuth_changed: [AzimuthChangedHandler],
  slope_changed: [SlopeChangedHandler],
  roofType_changed: [RoofTypeChangedHandler]
};
