import { BaseProps } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';

type Props = BaseProps & {
  isError?: boolean;
  min?: number;
};

export const HeaderText = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
  height: 23px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${
    ({ theme }: PropsTheme): Color => theme.colors.grayColor3
  };
  padding: 0;
`;

export const InputField = styled.input<Props>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${(props: Props & PropsTheme): Color => (props.isError
    ? props.theme.colors.mainColor4
    : props.theme.colors.grayColor1
  )};
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  background: inherit;
`;

export const ErrorText = styled.span`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor4};
  display: block;
  margin-top: 5px;
`;
