import type { Dictionary } from '../domain/typings';

/**
 * Takes an object literal(i.e. config file) and creates a string
 * of URL queries
 *
 * i.e. {a: "1", b: "2", c: "3"} => a=1&b=2&c=3
 *
 * @param  obj - an iterable object, works as a dictionary
 * @param blackList - exception of keys which won't be
 *                    added in the queries result
 * @return URL queries in a string row
 */
export function keyToQuery<T = Dictionary<string>>(obj: T, blackList: string[] = []): string {
  // @ts-ignore
  return Object.entries(obj).reduce((acc: string, [key, value]: [string, T], index: number): string => {
    const inBlackList = blackList.find((bl: string): boolean => key === bl);
    const query = !inBlackList ? `${key}=${value}` : '';
    const newAcc = index === 0 ? query : `${acc}&${query}`;

    // if it's on the black list array, just send the old accomulator
    return inBlackList ? acc : newAcc;
  }, '');
}

/**
 * Utility for make strings safe for adding as query in the url
 * helpful for api keys signatures
 *
 */
export function applyWebSafe(str: string = ''): string {
  return str.replace(/\+/g, '-').replace(/\//g, '_');
}

/**
 * Decodes and removes characters applied for make the string web safe
 */
export function removeWebSafe(str: string = ''): string {
  return str.replace(/-/g, '+').replace(/_/g, '/');
}

/**
 * Get a title from backend response
 */
export function getTitle(title: string): string {
  return title.replace(/<(.*?)em>/g, '');
}

/**
 * Get a formatted text from backend response
 */
export function getFormattedText(text: string): string {
  return text.replace(/<em>/g, '<em style="font-weight: bold; font-style: normal; background: #ffe656">');
}
