import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import config, { UI_MODE } from '../../../../config/config';
import LineHr from '../../../components/LineHr';
import {
  HR_MARGIN, HR_TOOLBAR_WIDTH
} from '../../../components/Layout/SolarLayout/constants';
import HelpDocsButton from '../ProjectToolbar/Buttons/HelpDocsButton';

const HelpToolbarWrapper = styled.div`
  flex: 1 0 0;
`;

const HelpToolbarVersion = styled.div`
  font-size: 0.5em;
  text-align: center;
  color: #999;
`;

// This part of toolbar contains simple buttons only. They should not act like tools,
// that's why we don't have appropriate tools mapped to these buttons in the toolbar store.
const HelpToolbar = (): ReactElement =>
  config.featureFlag.uiMode !== UI_MODE.AURORA ? (
    <HelpToolbarWrapper>
      <HelpDocsButton title="Knowledge base" url={config.site.knowledgeBaseUrl} />
      <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
      <HelpToolbarVersion>v{config.designTool.version}</HelpToolbarVersion>
    </HelpToolbarWrapper>
  ) : (
    <></>
  );

export default observer(HelpToolbar);
