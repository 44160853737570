import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Design } from '../../../domain/models/Design/Design';
import type { MountingSystemAttributes } from '../../../domain/typings';
import { BaseCommand } from './Command';

export interface IUpdateSupplementalMountingSystemDataDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly steepSlopeAttributes?: MountingSystemAttributes;
  readonly lowSlopeAttributes?: MountingSystemAttributes;
}

export class UpdateSupplementalMountingSystemDataCommand extends BaseCommand {
  private readonly currentDesign: Design;
  private readonly steepSlopeAttributes?: MountingSystemAttributes;
  private readonly lowSlopeAttributes?: MountingSystemAttributes;

  constructor(dependencies: IUpdateSupplementalMountingSystemDataDependencies) {
    super();
    this.currentDesign = dependencies.domain.design;
    this.steepSlopeAttributes = dependencies.steepSlopeAttributes;
    this.lowSlopeAttributes = dependencies.lowSlopeAttributes;
  }

  async execute(): Promise<void> {
    this.currentDesign.supplementalData.setMountingSystemInfo(this.steepSlopeAttributes, this.lowSlopeAttributes);
    this.currentDesign.system.equipment.mountingSystems.definitions.enrichWith(this.currentDesign.supplementalData);
  }
}
