import type {
  ISiteEquipmentData, SiteEquipmentDataKeys
} from '../../../entities/SiteDesign/SiteEquipment';
import type { IMainBreakerInEnclosureData } from '../../../entities/SiteDesign/MainBreakerInEnclosureInstance';
import {
  DEFAULT_MAIN_BREAKER_RATING,
  getServiceEntranceAndSiteEquipmentProperties,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../SiteEquipmentTypesAndHelpers';
import {
  GenericSiteEquipment, type GenericMarkerDependencies
} from './GenericSiteEquipment';

export class MainBreakerInEnclosure extends GenericSiteEquipment {
  mainBreakerRating: number = DEFAULT_MAIN_BREAKER_RATING;
  mainBreakerAmpereInterruptingCapacity?: number;

  override isServiceEntranceEquipment = true;

  constructor(deps: GenericMarkerDependencies) {
    super(deps);

    const {
      name, label
    } = getServiceEntranceAndSiteEquipmentProperties(
      ServiceEntranceEquipmentDevice.MainBreakerInEnclosure,
      deps.serviceEntranceEquipmentType ?? ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter
    );
    this.name = name;
    this.label = label;
  }

  enrichWithData = (data: IMainBreakerInEnclosureData): void => {
    this.realWorldZValue = data.location.z;
    this.serverId = data.id;
    this.exposure = data.exposure;
    this.mainBreakerRating = data.breakerRating;
    this.mainBreakerAmpereInterruptingCapacity = data.breakerAmpereInterruptingCapacity;
  };

  toData = (): ISiteEquipmentData[SiteEquipmentDataKeys.mainBreakerInEnclosure] => ({
    id: this.serverId,
    location: {
      x: this.mesh.position.x,
      y: this.mesh.position.y,
      z: this.realWorldZValue
    },
    exposure: this.exposure,
    breakerRating: this.mainBreakerRating,
    breakerAmpereInterruptingCapacity: this.mainBreakerAmpereInterruptingCapacity
  });
}
