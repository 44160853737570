import { BaseProps } from 'react';
import styled, { PropsTheme, withTheme } from 'styled-components';
import { getColorTheme } from '../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

type IconLayoutProps = BaseProps & PropsTheme & {
  selected: boolean;
  colorTheme?: EThemeComponentColor;
};

function setIconColor({ colorTheme, theme }: IconLayoutProps): string {
  if (colorTheme) {
    return `
    fill: ${getColorTheme({ colorTheme, theme })}
   `;
  }
  return ``;

}

export const IconLayout = styled.div<IconLayoutProps>`
  opacity: ${({ selected }: IconLayoutProps): number => selected ? 1 : 0.2};
  line-height: 0;
  path {
    opacity: 1;
    ${(props: IconLayoutProps): string => setIconColor({ ...props })};
  }
`;
