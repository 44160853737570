import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { H4 } from '@aurorasolar/lyra-ui-kit/lib/components/FontFamily/Fonts';
import {
  LeftView, RowContainer, RadioWrapper, RightView, StatusText
} from '../../styles';

const LyraToggle = LyraFormElementsDesignTool.FormElementsDesignTool.Toggle.Toggle;

type Props = {
  id: string;
  shortDesc: string;
  viewState: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const OptionView = (props: Props): ReactElement => {
  const {
    id, shortDesc, viewState, onChange
  } = props;

  return (
    <RowContainer>
      <LeftView>
        <H4>{shortDesc}</H4>
      </LeftView>
      <RightView>
        <RadioWrapper>
          <StatusText status={!viewState}>No</StatusText>
          <LyraToggle className="toggleBig" id={id} checked={viewState} onChange={onChange} />
          <StatusText status={viewState}>Yes</StatusText>
        </RadioWrapper>
      </RightView>
    </RowContainer>
  );
};

export default observer(OptionView);
