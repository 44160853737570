import type IBaseImageryProviderConfig from '../domain/models/IBaseImageryProviderConfig';
import type {
  Coords, Dictionary
} from '../domain/typings';
import { BaseImageryProvider } from '../domain/typings';
import { encryptHMAC } from '../utils/helpers';
import {
  applyWebSafe, removeWebSafe, keyToQuery
} from '../utils/parsing';

// For Google maps, we need to sign the URL path in order to get the image
// we need to encrypt it using HMAC and SHA1 with the private key
// provided.
// the result signature will be added as a new query.
// i.e. '/maps/api/staticmap?center=560x560&zoom=20&client=[CLIENT]'
//                          ||
//                          \/
//       encrypt with HMAC and the provided secret
//                          ||
//                          \/
//           get the signature from encryption
//                          ||
//                          \/
//            add the signature as a query
//        '/maps/api/staticmap?center=560x560&zoom=20
//         &client=[CLIENT]&signature=[SIGN]'
function signGoogleMapsUrl(url: string, secret: string): string {
  const {
    host, pathname, protocol, search
  } = new window.URL(url);
  const hash = applyWebSafe(encryptHMAC(pathname + search, removeWebSafe(secret)));

  return `${protocol}//${host}${pathname}${search}&signature=${hash}`;
}

export default function getGoogleMapStaticImageUrl(
  {
    latitude, longitude
  }: Coords,
  {
    options, url, zoomLimit, name
  }: IBaseImageryProviderConfig
): string {
  const coordQuery = `center=${latitude},${longitude}`;
  const zoomQuery = `zoom=${zoomLimit}`;
  const styleQuery = 'style=feature:all|visibility:off';
  let queries = `${coordQuery}&${zoomQuery}&${styleQuery}`;
  let urlResult: string = '';

  /** Apply the needed logic for each provider */
  if (BaseImageryProvider.GOOGLE_MAPS === name) {
    //  builds url queries based in the options,
    //  but ignores the private key and the client
    // https://hi.service-now.com/kb_view.do?sysparm_article=KB0723599
    const optionQueries = keyToQuery<Dictionary<string>>(options, ['privateKey', 'client']);

    queries += `&${optionQueries}`;
    urlResult = signGoogleMapsUrl(`${url}?${queries}`, options.privateKey);
  } else {
    const optionQueries = keyToQuery<Dictionary<string>>(options);

    queries += `&${optionQueries}`;
    urlResult = `${url}?${queries}`;
  }

  return urlResult;
}
