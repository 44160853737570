import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Segment } from '../graphics/Segment';
import type { PolygonDrawable } from './PolygonDrawable';

export const LengthLabelable = <T extends AnyConstructor<PolygonDrawable>>(LengthLabelableBase: T) => {
  abstract class MixinClass extends LengthLabelableBase {
    override select(): void {
      if (!this.selected) {
        this.selected = true;
        this.setShowSegmentLengths(true);
        this.redraw();
        this.renderOrder = -1;
      }
    }

    override unselect(): void {
      if (this.selected) {
        this.selected = false;
        this.setShowSegmentLengths(false);
        this.redraw();
        this.renderOrder = 0;
      }
    }

    setShowSegmentLengths(show: boolean): void {
      this.boundary.segments.forEach((segment: Segment): void => {
        segment.setShowSegmentLength(show);
      });
    }

    dispose(): void {
      this.boundary.segments.forEach((segment: Segment): void => {
        segment.dispose();
      });
    }
  }

  return MixinClass;
};

export type LengthLabelable = Mixin<typeof LengthLabelable>;
