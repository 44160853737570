import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  InfoContainer,
  InfoHeading,
  InfoText,
  BlueIcon,
  CenterView
} from './styles';
import { ISummaryDataProps } from './Summary';
import Loader from '../Loader/Loader';

const Progress = ({ data }: { data: ISummaryDataProps['progress'] }): ReactElement => {
  const { message, estimatedWaitTimeInSeconds } = data!;
  const theme = useContext(ThemeContext);

  return (
    <InfoContainer dir="row">
      <CenterView>
        <Loader bgColor={theme!.colors.grayColor11} />
        <BlueIcon name="doc-blue" />
      </CenterView>
      <InfoHeading>
        {message}
      </InfoHeading>
      <InfoText>
        This usually takes about {estimatedWaitTimeInSeconds} seconds.
      </InfoText>
    </InfoContainer>
  );
};

export { Progress };
