import type {
  BaseProps, ReactElement, ReactNode
} from 'react';
import * as React from 'react';
import IconMapper from '../../../ui/components/Icons';
import {
  EditButtonWrapper,
  ServiceEntranceEquipmentHeading,
  ServiceEntranceEquipmentHeaderContainer,
  ServiceEntranceEquipmentHeader,
  ServiceEntranceEquipmentDescription,
  ServiceEntranceEquipmentHeaderWrapper
} from './styles';

type RoofEdition = BaseProps & {
  children?: ReactNode;
  title: string;
  onEditClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
};

export const ServiceEntranceEquipmentPropertyPanelHeader = (props: RoofEdition): ReactElement => {
  const {
    children, onEditClick, title, disabled
  } = props;
  return (
    <ServiceEntranceEquipmentHeaderWrapper>
      <ServiceEntranceEquipmentHeaderContainer>
        <ServiceEntranceEquipmentHeading dir="row" justify="space-between">
          <ServiceEntranceEquipmentHeader>{title}</ServiceEntranceEquipmentHeader>
          <EditButtonWrapper onClick={onEditClick} disabled={disabled}>
            <IconMapper name="pencil" />
          </EditButtonWrapper>
        </ServiceEntranceEquipmentHeading>
        <ServiceEntranceEquipmentDescription>{children}</ServiceEntranceEquipmentDescription>
      </ServiceEntranceEquipmentHeaderContainer>
    </ServiceEntranceEquipmentHeaderWrapper>
  );
};
