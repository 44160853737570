import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { IPvModuleInfo } from '../../../../domain/models/SupplementalData/SupplementalData';
import { sectionTitle } from '../SectionTitle';
import { ArrayPlacementCard } from '../styles';
import {
  LeftContainer, ModelContainer, ModuleContainer, ModuleNumbers, RightContainer
} from './ModuleCardStyles';

type ModuleCardProps = {
  pvModuleInfo?: IPvModuleInfo;
  totalModules?: number;
  editable?: boolean;
  onclick?: () => void;
};

export const ModuleCard = observer((props: ModuleCardProps): ReactElement => {
  const {
    pvModuleInfo, totalModules, editable = false, onclick
  } = props;

  const handleEditClick = useCallback((): void => onclick && onclick(), [onclick]);

  const showModules = totalModules && totalModules > 0;

  return (
    <ArrayPlacementCard title={sectionTitle('module')} editable={editable} onEditClick={handleEditClick}>
      <ModuleContainer>
        {!!showModules && (
          <LeftContainer>
            <ModuleNumbers data-testid="module-number">{totalModules}</ModuleNumbers>
          </LeftContainer>
        )}
        <RightContainer>
          <ModelContainer>
            <p className="title">Make:</p>
            <p className="content">{pvModuleInfo?.make}</p>
          </ModelContainer>
          <ModelContainer>
            <p className="title">Model:</p>
            <p className="content">
              {pvModuleInfo?.model} {pvModuleInfo?.powerRating}W
            </p>
          </ModelContainer>
        </RightContainer>
      </ModuleContainer>
    </ArrayPlacementCard>
  );
});
