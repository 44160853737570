import { LyraCard } from '@aurorasolar/lyra-ui-kit';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { PADDING_CARD } from '../../Layout/SolarLayout/constants';
import config, { UI_MODE } from '../../../../config/config';

export const ElectricalDesignCard = styled(LyraCard.Card)`
  padding: ${PADDING_CARD};
  width: auto;
`;

export const DcOptimizerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DcOptimizerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const DcOptimizerDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 38.3%;
`;

export const DcOptimizerDescriptionName = styled.span`
  ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
  font-size: 13px;
  font-weight: bold;
  line-height: normal;
  color: #4a4a4a;
`;
