import { observer } from 'mobx-react-lite';
import React, {
  type ReactElement, useMemo
} from 'react';
import { canvasConfig } from '../../../config/canvasConfig';
import useStore from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import { getUiStore } from '../../../stores/RootStoreInversion';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import {
  getServiceEntranceAndSiteEquipmentProperties,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import { LoadCenterForm } from './ServiceEntranceEquipmentForms/LoadCenterForm';
import MeterBaseForm from './ServiceEntranceEquipmentForms/MeterBaseForm';
import { ServiceEntranceEquipmentPropertyPanelHeader } from './ServiceEntranceEquipmentPropertyPanelHeader';
import { ServiceEntranceEquipmentConfigurationContainer } from './styles';
import MeterMainProperties from './MeterMainProperties';
import { SEOptionTitlesAndDescriptions } from './ServiceEntranceEquipmentOptions';
import { MainBreakerForm } from './ServiceEntranceEquipmentForms/MainBreakerForm';

const ServiceEntranceEquipmentFormsContainer = observer((): ReactElement => {
  const { domain } = useStore();

  // Infer service entrance equipment type
  // Stitch forms
  const [serviceEntranceEquipmentTitle, serviceEntranceEquipmentDescription, Forms] = useMemo((): [
    string,
    string,
    ReactElement
  ] => {
    const serviceEntranceEquipmentType = domain.siteEquipment.inferServiceEntranceEquipmentType();
    if (!serviceEntranceEquipmentType) {
      return ['', '', <></>];
    }
    const {
      title, description
    } = SEOptionTitlesAndDescriptions[serviceEntranceEquipmentType];
    let element = <></>;
    switch (serviceEntranceEquipmentType) {
      case ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter
        );
        element = (
          <>
            <MeterBaseForm />
            <LoadCenterForm title={name} label={label} />
          </>
        );
        break;
      }
      case ServiceEntranceEquipmentType.MeterMainLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterMainLoadCenter
        );
        element = <LoadCenterForm title={name} label={label} />;
        break;
      }
      case ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter
        );
        element = (
          <>
            <MeterMainProperties />
            <LoadCenterForm label={label} title={name} />
          </>
        );
        break;
      }
      case ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter
        );
        element = (
          <>
            <MeterBaseForm />
            <MainBreakerForm />
            <LoadCenterForm label={label} title={name} />
          </>
        );
        break;
      }
      case ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter
        );
        element = (
          <>
            <MeterBaseForm />
            <MainBreakerForm />
            <LoadCenterForm label={label} title={name} />
          </>
        );
        break;
      }
      case ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter: {
        const {
          label, name
        } = getServiceEntranceAndSiteEquipmentProperties(
          ServiceEntranceEquipmentDevice.LoadCenter,
          ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter
        );
        element = (
          <>
            <MeterMainProperties />
            <LoadCenterForm label={label} title={name} />
          </>
        );
        break;
      }
    }
    return [title, description, element];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    domain.siteEquipment,
    domain.project.site.equipment.mainServicePanel,
    domain.project.site.equipment.utilityMeter
  ]);

  const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    getUiStore().properties.setPropertyPanel(PropsPanelUICodes.ServiceEntranceEquipmentOptions);
  };

  return (
    <>
      <PropertyPanelHeader
        icon="tool-properties"
        iconBg={canvasConfig.gasMeterIconColor}
        name="Service Entrance Equipment"
      />
      <ServiceEntranceEquipmentPropertyPanelHeader title={serviceEntranceEquipmentTitle} onEditClick={onEditClick}>
        {serviceEntranceEquipmentDescription}
      </ServiceEntranceEquipmentPropertyPanelHeader>
      <ServiceEntranceEquipmentConfigurationContainer overflow="scroll">
        {Forms}
      </ServiceEntranceEquipmentConfigurationContainer>
    </>
  );
});
export default ServiceEntranceEquipmentFormsContainer;
