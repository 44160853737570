import {
  Mesh, MeshBasicMaterial, Object3D
} from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import EWorkspace from '../typings/EWorkspace';
import type { Vector2D } from '../typings';
import { TEXT_HEIGHT } from '../../stores/EditorStore/constants';
import { getRootStore } from '../../stores/RootStoreInversion';
import { SceneObjectType } from '../models/Constants';
import { WorkspaceTagged } from '../mixins/WorkspaceTagged';
import { Unzoomable } from '../mixins/Unzoomable';
import { Selectable } from '../mixins/Selectable';
import { Drawable } from '../mixins/Drawable';
import Loader from './Loader';
import WrapperMesh from './WrapperMesh';

export enum EDisplayPotential {
  NONE = 'none',
  LOADER = 'loader',
  USE_FOR_SOLAR = 'use-for-solar',
  DONT_USE_FOR_SOLAR = 'dont-use-for-solar',
  POTENTIAL = 'potential'
}

const MixedClass = WorkspaceTagged(Unzoomable(Selectable(Drawable(class SimpleClass {}))));

export class PotentialInfo extends MixedClass {
  override readonly tag: EWorkspace = EWorkspace.DESIGN;
  override readonly type: string = SceneObjectType.PotentialInfo;
  private readonly font: Font;
  override readonly propertyId: string = SceneObjectType.PotentialInfo;
  override readonly selectWithParent: boolean = true;

  private forSolarButton!: Object3D;
  private loader!: Loader;
  private infoCapsule!: Object3D;

  maxPotential: number = 0;
  private textMaxPotential!: Mesh;
  parent?: Object3D;
  state = EDisplayPotential.NONE;

  private readonly scaleValue: number = 0.7;
  private scaleFactor: number = 1;

  constructor(font: Font, location: Vector2D) {
    super();
    this.font = font;

    this.setUseForSolarMesh();
    this.setDontUseForSolarMesh();
    this.setMaxPotentialMesh();
    this.setLoaderMesh();
    this.displayNone();
    this.mesh.position.setX(location.x);
    this.mesh.position.setY(location.y);
    this.mesh.position.z = getRootStore().editor.getObjectRenderHeight(SceneObjectType.PotentialInfo);
    // Reducing size for small roof faces
    this.mesh.scale.set(this.scaleFinalValue, this.scaleFinalValue, 1);
  }

  get scaleFinalValue(): number {
    return this.scaleValue * this.scaleFactor * this.mapZoomFactor;
  }

  displayMaxPotential(): void {
    this.display(EDisplayPotential.POTENTIAL);
  }

  displayLoader(): void {
    this.display(EDisplayPotential.LOADER);
  }

  displayUseForSolar(): void {
    this.display(EDisplayPotential.USE_FOR_SOLAR);
  }

  displayDontUseForSolar(): void {
    this.display(EDisplayPotential.DONT_USE_FOR_SOLAR);
  }

  displayNone(): void {
    this.display(EDisplayPotential.NONE);
  }

  changeMaxPotential(valueInKw: number): void {
    this.maxPotential = valueInKw;
    this.textMaxPotential.geometry = this.createTextGeometry(`${this.maxPotential.toFixed(2) || '0'} kW`, 12);
  }

  unzoom(factor: number): void {
    this.scaleFactor = factor;
    this.mesh.scale.set(this.scaleFinalValue, this.scaleFinalValue, 1);
  }

  private createTextGeometry(text: string, fontSize: number): TextGeometry {
    const textGeometry = new TextGeometry(text, {
      font: this.font,
      size: fontSize,
      height: TEXT_HEIGHT
    });

    textGeometry.center();

    return textGeometry;
  }

  private setUseForSolarMesh(): void {
    const forSolarText = new Mesh(
      this.createTextGeometry('USE FOR SOLAR', 12),
      new MeshBasicMaterial({
        color: 0x000000
      })
    );

    const forSolarWrapper = WrapperMesh(
      16,
      130,
      new MeshBasicMaterial({
        transparent: true,
        opacity: 0.7,
        color: 0xffffff
      })
    );

    this.forSolarButton = new Object3D();
    this.forSolarButton.add(forSolarText);
    this.forSolarButton.add(forSolarWrapper);
    this.forSolarButton.name = EDisplayPotential.USE_FOR_SOLAR;

    this.mesh.add(this.forSolarButton);
  }

  private setDontUseForSolarMesh(): void {
    const forSolarText = new Mesh(
      this.createTextGeometry('DO NOT USE FOR SOLAR', 12),
      new MeshBasicMaterial({
        color: 0x000000
      })
    );

    const forSolarWrapper = WrapperMesh(
      16,
      220,
      new MeshBasicMaterial({
        transparent: true,
        opacity: 0.7,
        color: 0xffffff
      })
    );

    this.forSolarButton = new Object3D();
    this.forSolarButton.add(forSolarText);
    this.forSolarButton.add(forSolarWrapper);
    this.forSolarButton.name = EDisplayPotential.DONT_USE_FOR_SOLAR;

    this.mesh.add(this.forSolarButton);
  }

  private setMaxPotentialMesh(): void {
    const label = new Mesh(
      this.createTextGeometry('Max. Potential', 8),
      new MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.9
      })
    );
    label.position.y = 10;

    const value = new Mesh(
      this.createTextGeometry(`${String(this.maxPotential || 0)} kW`, 12),
      new MeshBasicMaterial({
        color: 0xffffff
      })
    );
    value.position.y = -8;

    const infoWrapper = WrapperMesh(
      20,
      70,
      new MeshBasicMaterial({
        transparent: true,
        opacity: 0.85,
        color: 0x547cb8
      })
    );

    this.textMaxPotential = value;
    this.infoCapsule = new Object3D();
    this.infoCapsule.add(infoWrapper);
    this.infoCapsule.add(label);
    this.infoCapsule.add(value);
    this.infoCapsule.name = EDisplayPotential.POTENTIAL;

    this.mesh.add(this.infoCapsule);
  }

  private setLoaderMesh(): void {
    this.loader = new Loader();
    this.loader.name = EDisplayPotential.LOADER;

    this.mesh.add(this.loader);
  }

  display(shouldDisplay: EDisplayPotential): void {
    this.mesh.children.forEach((obj: Object3D): void => {
      if (obj.name === shouldDisplay) {
        this.state = shouldDisplay;
        obj.visible = true;
      } else {
        obj.visible = false;
      }
    });
  }
}
