import isNil from 'lodash/isNil';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type { DesignDelta } from '../../entities/Design/DesignDelta';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { IUpdateSupplementalMountingSystemDataDependencies } from '../../../stores/ServiceBus/Commands/UpdateSupplementalMountingSystemDataCommand';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type { Design } from '../../models/Design/Design';
import type Limit from '../../models/Limit';
import {
  fit, isWithin
} from '../../models/Limit';
import type { IOption } from '../../models/SiteDesign/IOption';
import type IChangeMountingSystemDefinition from '../../request/ChangeMountingSystemDefinitionRequest/ChangeMountingSystemDefinition';
import type { MountingSystemAttributes } from '../../typings';
import type { IStage } from '../IStage';
import { DesignStep } from '../../models/Design/DesignState';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../models/SiteDesign/RoofFace';
import { handleApiError } from '../../../utils/helpers';
import type { DesignCreationWizardStore } from '../../../stores/UiStore/Wizard/DesignCreationWizardStore';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { EquipmentService } from '../../../infrastructure/services/api/EquipmentService';
import { MountingSystemDefinitionSpecifiedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import type { IMountingSystemDefinitionsData } from '../../models/MountingSystemDefinition/MountingSystemDefinitions';
import config from '../../../config/config';

export interface IMountingSystemDefinitionsStageDeps {
  readonly isDesignCreation: boolean;
  readonly designCreationWizardStore?: DesignCreationWizardStore;
  readonly domain: DomainStore;
  readonly serviceBus?: ServiceBus;
  readonly editor?: EditorStore;
  readonly onContinue?: () => void;
}

const DEFAULT_NUMBER_OF_ROWS = 1;
const DEFAULT_MIN_TILT_ANGLE = 10;

export class MountingSystemDefinitionsStage implements IStage {
  readonly id: string = 'mounting_system_definitions_stage';
  readonly propCodeUI = 'mounting_system_definitions_modal';

  @observable
  systemWillBeMountedOnSteepSlope: boolean = true;
  @observable
  systemWillBeMountedOnLowSlope: boolean = true;
  @observable
  steepSlopeMountingSystemOptions: IOption<MountingSystemAttributes>[] = observable([]);
  @observable
  lowSlopeMountingSystemOptions: IOption<MountingSystemAttributes>[] = observable([]);

  @observable
  selectedSteepSlopeMountingSystemId: string = '';
  @observable
  selectedSteepSlopeMountingSystemAttributes?: MountingSystemAttributes = undefined;
  @observable
  selectedLowSlopeMountingSystemId: string = '';
  @observable
  selectedLowSlopeMountingSystemAttributes?: MountingSystemAttributes = undefined;
  @observable
  lowSlopeMountingSystemDefaultTiltAngle: number = DEFAULT_MIN_TILT_ANGLE;
  @observable
  lowSlopeMountingSystemDefaultNumberOfTiers: number = DEFAULT_NUMBER_OF_ROWS;

  readonly isDesignCreation: boolean;
  private readonly domain: DomainStore;
  private readonly currentDesign?: Design;
  private readonly designCreationWizardStore?: DesignCreationWizardStore;
  private readonly editor?: EditorStore;
  private readonly serviceBus?: ServiceBus;
  private readonly onContinue?: () => void;
  private readonly designService = new DesignService();
  private readonly equipmentService = new EquipmentService();

  constructor(dependencies: IMountingSystemDefinitionsStageDeps) {
    const {
      domain, isDesignCreation, designCreationWizardStore, serviceBus, editor, onContinue
    } = dependencies;
    this.domain = domain;
    this.isDesignCreation = isDesignCreation;
    if (isDesignCreation) {
      this.designCreationWizardStore = designCreationWizardStore;
      this.setInitialValuesFromIncompleteWizard();
    } else {
      this.currentDesign = this.domain.design;
      this.serviceBus = serviceBus;
      this.editor = editor;
      this.onContinue = onContinue;
      this.setInitialValuesFromDomainModel();
    }
  }

  @action.bound
  private setInitialValuesFromIncompleteWizard(): void {
    const store = this.designCreationWizardStore!;
    if (store.hasMountingSystemDefinitions) {
      this.systemWillBeMountedOnSteepSlope = this.hasSteepSlopeRoofFaces;
      this.systemWillBeMountedOnLowSlope = this.hasLowSlopeRoofFaces;
    }
    if (this.hasSteepSlopeRoofFaces && store.hasSteepSlopeMountingSystemDefinition) {
      this.selectedSteepSlopeMountingSystemId = store.steepSlopeMountingSystemDefinitionId;
      this.selectedSteepSlopeMountingSystemAttributes = store.steepSlopeMountingSystemAttributes;
    }
    if (this.hasLowSlopeRoofFaces && store.hasLowSlopeMountingSystemDefinition) {
      this.selectedLowSlopeMountingSystemId = store.lowSlopeMountingSystemDefinitionId;
      this.selectedLowSlopeMountingSystemAttributes = store.lowSlopeMountingSystemAttributes;
      this.lowSlopeMountingSystemDefaultTiltAngle = store.lowSlopeMountingSystemDefaultTiltAngle!;
      this.lowSlopeMountingSystemDefaultNumberOfTiers = store.lowSlopeMountingSystemDefaultNumberOfTiers!;
    }
  }

  @action.bound
  private setInitialValuesFromDomainModel(): void {
    const mountingSystemDefinitions = this.currentDesign!.system.equipment.mountingSystems.definitions;

    if (mountingSystemDefinitions.hasSteepSlopeDefinition) {
      this.systemWillBeMountedOnSteepSlope = this.hasSteepSlopeRoofFaces;
      this.selectedSteepSlopeMountingSystemId = mountingSystemDefinitions.steepSlope!.externalDefinitionId;
    }
    if (mountingSystemDefinitions.hasLowSlopeDefinition) {
      this.systemWillBeMountedOnLowSlope = this.hasLowSlopeRoofFaces;
      const lowSlopeDefinition = mountingSystemDefinitions.lowSlope!;
      this.selectedLowSlopeMountingSystemId = lowSlopeDefinition.externalDefinitionId;
      this.lowSlopeMountingSystemDefaultTiltAngle = lowSlopeDefinition.defaultConfiguration.tiltAngle;
      this.lowSlopeMountingSystemDefaultNumberOfTiers = lowSlopeDefinition.defaultConfiguration.numberOfRowsInRack;
    }
    if (mountingSystemDefinitions.isEmpty) {
      this.systemWillBeMountedOnSteepSlope = this.hasSteepSlopeRoofFaces;
      this.systemWillBeMountedOnLowSlope = this.hasLowSlopeRoofFaces;
    }
  }

  @computed
  get designHasSteepSlopeArrayAreas(): boolean {
    return this.currentDesign?.hasSteepSlopeArrayAreas ?? false;
  }

  @computed
  get designHasLowSlopeArrayAreas(): boolean {
    return this.currentDesign?.hasLowSlopeArrayAreas ?? false;
  }

  @computed
  get hasSteepSlopeRoofFaces(): boolean {
    return !isNil(this.domain.steepSlopes);
  }

  @computed
  get hasLowSlopeRoofFaces(): boolean {
    return !isNil(this.domain.lowSlopes);
  }

  loadMountingSystemOptions = async (): Promise<void> => {
    await this.loadSteepSlopeOptions();
    await this.loadLowSlopeOptions();
  };

  private async loadSteepSlopeOptions(): Promise<void> {
    if (!this.hasSteepSlopeRoofFaces) {
      return;
    }
    const data: IOption<MountingSystemAttributes>[] = await this.equipmentService
      .getMountingSystemDefinitionOptions({
        application: 'STEEP_SLOPE_ROOF_ARRAY',
        moduleId: this.pvModuleDefinitionId,
        maxApplicationSlope: this.domain.steepSlopes!.upper,
        minApplicationSlope: this.domain.steepSlopes!.lower
      })
      .catch(handleApiError('Failed to retrieve steep-slope mounting system options'));

    runInAction((): void => {
      this.steepSlopeMountingSystemOptions = data;
      if (this.selectedSteepSlopeMountingSystemId) {
        this.selectedSteepSlopeMountingSystemAttributes = this.findAttributes(
          this.steepSlopeMountingSystemOptions,
          this.selectedSteepSlopeMountingSystemId
        );
        if (!this.selectedSteepSlopeMountingSystemAttributes) {
          // Option with selected ID is not available in selection options
          this.setSteepSlopeMountingSystem('');
        }
      }
    });
  }

  private async loadLowSlopeOptions(): Promise<void> {
    if (!this.hasLowSlopeRoofFaces) {
      return;
    }
    const data = await this.equipmentService
      .getMountingSystemDefinitionOptions({
        application: 'LOW_SLOPE_ROOF_ARRAY',
        moduleId: this.pvModuleDefinitionId,
        maxApplicationSlope: this.domain.lowSlopes!.upper,
        minApplicationSlope: this.domain.lowSlopes!.lower
      })
      .catch(handleApiError('Failed to retrieve low-slope mounting system options'));

    runInAction((): void => {
      this.lowSlopeMountingSystemOptions = data;
      if (this.selectedLowSlopeMountingSystemId) {
        this.selectedLowSlopeMountingSystemAttributes = this.findAttributes(
          this.lowSlopeMountingSystemOptions,
          this.selectedLowSlopeMountingSystemId
        );
        if (!this.selectedLowSlopeMountingSystemAttributes) {
          // Option with selected ID is not available in selection options
          this.setLowSlopeMountingSystem('');
        }
      }
    });
  }

  @computed
  private get pvModuleDefinitionId(): string {
    return this.isDesignCreation
      ? this.designCreationWizardStore!.pvModuleDefinitionId
      : this.currentDesign!.system.equipment.pvModules.definition;
  }

  @action.bound
  setSystemWillBeMountedOnSteepSlope(value: boolean): void {
    this.systemWillBeMountedOnSteepSlope = value;
  }

  @action.bound
  setSystemWillBeMountedOnLowSlope(value: boolean): void {
    this.systemWillBeMountedOnLowSlope = value;
  }

  @action.bound
  setSteepSlopeMountingSystem(mountingSystemDefinitionId: string): void {
    this.selectedSteepSlopeMountingSystemId = mountingSystemDefinitionId;
    this.selectedSteepSlopeMountingSystemAttributes = this.findAttributes(
      this.steepSlopeMountingSystemOptions,
      mountingSystemDefinitionId
    );
  }

  private findAttributes = (
    options: IOption<MountingSystemAttributes>[],
    mountingSystemDefinitionId: string
  ): MountingSystemAttributes | undefined => {
    const matchingOption = options.find(
      (option: IOption<MountingSystemAttributes>): boolean => option.attributes.value === mountingSystemDefinitionId
    );
    return matchingOption?.attributes;
  };

  @action.bound
  setLowSlopeMountingSystem(mountingSystemDefinitionId: string): void {
    this.selectedLowSlopeMountingSystemId = mountingSystemDefinitionId;
    this.selectedLowSlopeMountingSystemAttributes = this.findAttributes(
      this.lowSlopeMountingSystemOptions,
      mountingSystemDefinitionId
    );
    this.lowSlopeMountingSystemDefaultTiltAngle = this.selectedLowSlopeMountingSystemTiltAngleLimits
      ? fit(this.selectedLowSlopeMountingSystemTiltAngleLimits, DEFAULT_MIN_TILT_ANGLE)
      : DEFAULT_MIN_TILT_ANGLE;
    this.lowSlopeMountingSystemDefaultNumberOfTiers = this.selectedLowSlopeMountingSystemTierLimits
      ? fit(this.selectedLowSlopeMountingSystemTierLimits, DEFAULT_NUMBER_OF_ROWS)
      : DEFAULT_NUMBER_OF_ROWS;
  }

  @computed
  get selectedLowSlopeMountingSystemTiltAngleLimits(): Limit | undefined {
    if (!this.selectedLowSlopeMountingSystemAttributes) {
      return undefined;
    }
    return {
      lower: Number(this.selectedLowSlopeMountingSystemAttributes?.minimumTiltAngle),
      upper: Number(this.selectedLowSlopeMountingSystemAttributes?.maximumTiltAngle)
    };
  }

  @computed
  get selectedLowSlopeMountingSystemTierLimits(): Limit | undefined {
    if (!this.selectedLowSlopeMountingSystemAttributes) {
      return undefined;
    }
    return {
      lower: Number(this.selectedLowSlopeMountingSystemAttributes?.minimumNumberOfTiersPerRack),
      upper: Number(this.selectedLowSlopeMountingSystemAttributes?.maximumNumberOfTiersPerRack)
    };
  }

  @action.bound
  changeTiltAngle(value: number): void {
    this.lowSlopeMountingSystemDefaultTiltAngle = value;
  }

  @action.bound
  changeNumberOfRowsPerRack(value: number): void {
    this.lowSlopeMountingSystemDefaultNumberOfTiers = value;
  }

  getMountingSystemLabel(appendLabel: string): string {
    const BASE_LABEL = 'MOUNTING SYSTEM';
    return this.hasSteepSlopeRoofFaces && this.hasLowSlopeRoofFaces ? `${BASE_LABEL} ${appendLabel}` : BASE_LABEL;
  }

  @computed
  get canContinue(): boolean {
    const steepSlopeAllowsContinuing =
      !this.hasSteepSlopeRoofFaces
      || !this.systemWillBeMountedOnSteepSlope
      || Boolean(this.selectedSteepSlopeMountingSystemId);
    const lowSlopeAllowsContinuing =
      !this.hasLowSlopeRoofFaces
      || !this.systemWillBeMountedOnLowSlope
      || (Boolean(this.selectedLowSlopeMountingSystemId)
      && Boolean(this.lowSlopeMountingSystemDefaultNumberOfTiers)
      && Boolean(this.lowSlopeMountingSystemDefaultTiltAngle)
      && this.selectedLowSlopeMountingSystemTiltAngleLimits
        ? isWithin(this.selectedLowSlopeMountingSystemTiltAngleLimits, this.lowSlopeMountingSystemDefaultTiltAngle)
        : Boolean(this.lowSlopeMountingSystemDefaultTiltAngle));
    return (
      (this.systemWillBeMountedOnSteepSlope || this.systemWillBeMountedOnLowSlope)
      && steepSlopeAllowsContinuing
      && lowSlopeAllowsContinuing
    );
  }

  continue(): void {
    if (this.isDesignCreation) {
      this.updateDesignCreationWizardStore();
    } else {
      if (this.currentDesign!.state.isUserIn(DesignStep.ARRAY_PLACEMENT)) {
        this.updateMountingSystemDefinitions();
      }
    }
    config.analytics?.trackEvent(
      new MountingSystemDefinitionSpecifiedEvent(
        this.domain,
        this.hasSteepSlopeRoofFaces && this.systemWillBeMountedOnSteepSlope
          ? this.selectedSteepSlopeMountingSystemId
          : undefined,
        this.hasSteepSlopeRoofFaces && this.systemWillBeMountedOnSteepSlope
          ? this.selectedSteepSlopeMountingSystemAttributes!.manufacturer
          : undefined,
        this.hasLowSlopeRoofFaces && this.systemWillBeMountedOnLowSlope
          ? this.selectedLowSlopeMountingSystemId
          : undefined,
        this.hasLowSlopeRoofFaces && this.systemWillBeMountedOnLowSlope
          ? this.selectedLowSlopeMountingSystemAttributes!.manufacturer
          : undefined
      )
    );
  }

  @action
  private updateDesignCreationWizardStore = (): void => {
    const store = this.designCreationWizardStore!;
    if (this.systemWillBeMountedOnSteepSlope) {
      store.setSteepSlopeMountingSystemDefinition(
        this.selectedSteepSlopeMountingSystemId,
        this.selectedSteepSlopeMountingSystemAttributes!
      );
    } else {
      store.clearSteepSlopeMountingSystemDefinition();
    }
    if (this.systemWillBeMountedOnLowSlope) {
      store.setLowSlopeMountingSystemDefinition(
        this.selectedLowSlopeMountingSystemId,
        this.selectedLowSlopeMountingSystemAttributes!,
        this.lowSlopeMountingSystemDefaultTiltAngle,
        this.lowSlopeMountingSystemDefaultNumberOfTiers
      );
    } else {
      store.clearLowSlopeMountingSystemDefinition();
    }
  };

  private updateMountingSystemDefinitions = async (): Promise<void> => {
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor!);
    const request: IChangeMountingSystemDefinition = {
      mountingSystemDefinitions: this.updatedMountingSystemDefinitionsData(),
      design: this.currentDesign!.toData()
    };

    return this.designService
      .updateMountingSystemDefinitions(request)
      .then((response: DesignDelta): void => {
        this.serviceBus!.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));

        const dependencies: IUpdateSupplementalMountingSystemDataDependencies = {
          domain: this.domain,
          steepSlopeAttributes: this.selectedSteepSlopeMountingSystemAttributes,
          lowSlopeAttributes: this.selectedLowSlopeMountingSystemAttributes
        };
        this.serviceBus!.send('update_supplemental_mounting_system_definition_data', dependencies);
        this.onContinue?.();
      })
      .catch(handleApiError('Failed to update mounting system definitions'))
      .finally((): void => {
        hideLoaders();
      });
  };

  private updatedMountingSystemDefinitionsData(): IMountingSystemDefinitionsData {
    const copy = this.currentDesign!.system.equipment.mountingSystems.definitions.copy();
    if (this.hasSteepSlopeRoofFaces && this.systemWillBeMountedOnSteepSlope) {
      copy.setSteepSlopeDefinition(this.selectedSteepSlopeMountingSystemId);
    } else {
      copy.steepSlope = undefined;
    }
    if (this.hasLowSlopeRoofFaces && this.systemWillBeMountedOnLowSlope) {
      copy.setLowSlopeDefinition(
        this.selectedLowSlopeMountingSystemId,
        this.lowSlopeMountingSystemDefaultTiltAngle,
        this.lowSlopeMountingSystemDefaultNumberOfTiers
      );
    } else {
      copy.lowSlope = undefined;
    }
    return copy.toData();
  }

  cancel(): void {
    /** */
  }

  resume(lastValidStage: string): void {
    // Not implemented
  }

  dispose(): void {
    // Deallocate stuff here
  }
}
