import {
  LyraDivider, LyraFormElementsDesignTool, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useEffect, useRef, useState
} from 'react';
import { LayoutStrategy } from '../../../../domain/models/RoofTopArray/LayoutStrategy';
import type { NewLayoutStrategyStage } from '../../../../domain/stages/CreationDesignStages/NewLayoutStrategyStage';
import { ERowAlignmentStrategy } from '../../../../domain/typings';
import { EOrientation } from '../../../../domain/typings/EOrientation';
import type { ButtonOption } from '../../../../ui/components/ButtonGroup/ButtonGroup';
import ContentTooltip from '../../../../ui/components/ContentTooltip/ContentTooltip';
import CustomLayout from '../../Panels/CustomLayout/CustomLayout';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Flexbox from '../../../components/Layout/Flexbox';
import IconMapper from '../../../components/Icons';
import { useUiStore } from '../../../../stores/useStore';
import { RackSpacing } from '../../../../domain/models/MountingSystemDefinition/IConfiguration';
import {
  ContentLayout,
  ContentStyle,
  HoverAlignmentContainer,
  HoverAlignmentFlexbox,
  LayoutStrategySection,
  OrientationContentIcon,
  OrientationContentWrapper,
  RowAlignmentBtnGroup,
  SquareButton,
  Row
} from './styles';

type TooltipContentProps = {
  iconName: string;
  description: string;
};

type OrientationButtonProps = {
  iconName: string;
  label: string;
  selected: boolean;
};

const WIDTH_ROW_SPACING = '100px';

const NewLayoutStrategyModal = (props: BaseProps): ReactElement => {
  const {
    NumberField, CheckBox, Label
  } = LyraFormElementsDesignTool.FormElementsDesignTool;
  const { workspace } = useUiStore();
  const { wizard } = workspace.currentWorkspace;

  const parentBtn = useRef({} as HTMLDivElement);
  const [parentWidth, setParentWidth] = useState<number>();

  useEffect((): void => {
    setParentWidth(parentBtn.current.offsetWidth);
  }, [parentBtn.current.offsetWidth]);

  const viewModel = wizard?.currentStage as NewLayoutStrategyStage;

  const cancelModal = (): void => {
    wizard?.clear();
    if (viewModel.isDesignCreation) {
      workspace.changeWorkspace(Workspace.Project);
    }
  };

  const {
    requiresSteepSlopeLayoutStrategy,
    steepSlopeLayoutStrategy,
    requiresLowSlopeLayoutStrategy,
    lowSlopeLayoutStrategy
  } = viewModel;

  const OrientationButton = ({
    iconName, label, selected
  }: OrientationButtonProps): ReactElement => (
    <OrientationContentWrapper selected={selected}>
      <OrientationContentIcon
        styles={{
          width: '58px',
          height: '58px'
        }}
        name={iconName}
        selected={selected}
      />
      {label}
    </OrientationContentWrapper>
  );

  const createOrientationOptions = (orientation: EOrientation): ButtonOption[] => [
    {
      name: (
        <OrientationButton
          label={LayoutStrategy.ORIENTATION_LABELS.PORTRAIT}
          iconName="strategy-portrait"
          selected={orientation === EOrientation.PORTRAIT}
        />
      ),
      value: EOrientation.PORTRAIT
    },
    {
      name: (
        <OrientationButton
          label={LayoutStrategy.ORIENTATION_LABELS.LANDSCAPE}
          iconName="strategy-landscape"
          selected={orientation === EOrientation.LANDSCAPE}
        />
      ),
      value: EOrientation.LANDSCAPE
    }
  ];

  function createTooltipContent({
    iconName, description
  }: TooltipContentProps): ReactElement {
    return (
      <HoverAlignmentFlexbox>
        <HoverAlignmentContainer>
          <IconMapper name={iconName} />
        </HoverAlignmentContainer>
        <HoverAlignmentContainer>
          <p>{description}</p>
        </HoverAlignmentContainer>
      </HoverAlignmentFlexbox>
    );
  }

  const createRowAlignmentOptions = (rowAlignmentLabels: {
    [alignment in ERowAlignmentStrategy]: string;
  }): ButtonOption[] => {
    const maximizedModule = {
      name: (
        <ContentTooltip
          parentWidth={parentWidth}
          step={1}
          totalSteps={3}
          contentComponent={
            <SquareButton label={rowAlignmentLabels.MAXIMIZE_MODULE_QUANTITY} icon="maximized-modules" />
          }
          tooltipContent={createTooltipContent({
            iconName: 'graph-maximized-modules',
            description: `Use this configuration to
              maximize the number of modules`
          })}
        />
      ),
      value: ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY
    };
    const alignedRows = {
      name: (
        <ContentTooltip
          parentWidth={parentWidth}
          step={2}
          totalSteps={3}
          contentComponent={<SquareButton className="mh-xxxs" label={rowAlignmentLabels.ALIGNED} icon="aligned-rows" />}
          tooltipContent={createTooltipContent({
            iconName: 'graph-aligned-rows',
            description: `Use this configuration to
            perfectly align all of your modules`
          })}
        />
      ),
      value: ERowAlignmentStrategy.ALIGNED
    };
    const staggeredRows = {
      name: (
        <ContentTooltip
          parentWidth={parentWidth}
          step={3}
          totalSteps={3}
          contentComponent={
            <SquareButton className="mh-xxxs" label={rowAlignmentLabels.STAGGERED} icon="staggered-rows" />
          }
          tooltipContent={createTooltipContent({
            iconName: 'graph-staggered-rows',
            description: 'Use this configuration to offset rows by 50%'
          })}
        />
      ),
      value: ERowAlignmentStrategy.STAGGERED
    };
    return [maximizedModule, alignedRows, staggeredRows];
  };

  return useObserver(
    (): ReactElement => (
      <LyraModal.Modal
        $show={true}
        title={viewModel.isDesignCreation ? 'Roof Top System' : ''}
        currentStep={wizard?.current}
        steps={viewModel.isDesignCreation ? wizard?.steps : undefined}
        rightButtonDisabled={!wizard?.currentStage?.canContinue}
        leftButtonLabel={viewModel.isDesignCreation ? 'Back' : 'Cancel'}
        rightButtonLabel="Save"
        onClickLeftButton={(): void => {
          if (viewModel.isDesignCreation) {
            wizard?.previous();
          } else {
            wizard?.clear();
          }
        }}
        onClickRightButton={(): void => {
          wizard?.next();
        }}
        onClickCloseButton={cancelModal}
      >
        <ContentStyle>
          {requiresSteepSlopeLayoutStrategy && (
            <ContentLayout>
              <LyraTypography.TextLayout
                type="h2"
                margin={viewModel.isDesignCreation ? '20px 0 20px' : '10px 0 10px'}
                lineHeight={1.5}
                align="center"
              >
                {viewModel.isDesignCreation ? 'Layout Generation Parameters' : 'Update Layout Parameters'}
              </LyraTypography.TextLayout>
              {requiresLowSlopeLayoutStrategy && requiresSteepSlopeLayoutStrategy && (
                <LyraTypography.TextLayout type="h4" margin={'0 0 15px'} lineHeight={1.5} align="center">
                  Sloped Roof
                </LyraTypography.TextLayout>
              )}
              <LayoutStrategySection>
                <ButtonGroup
                  width={'124px'}
                  height={'114px'}
                  withBorder={true}
                  options={createOrientationOptions(steepSlopeLayoutStrategy.dominantOrientation)}
                  buttonBackground={'white'}
                  divider={true}
                  onSelect={(value: string | number): void =>
                    steepSlopeLayoutStrategy.setDominantOrientation(EOrientation[value as keyof typeof EOrientation])
                  }
                  selected={steepSlopeLayoutStrategy.dominantOrientation}
                />
              </LayoutStrategySection>

              <LyraDivider.Divider className="mt-s" direction="horizontal" color={EThemeComponentColor.GRAY_200} />

              <Flexbox ref={parentBtn} justify="center">
                <CustomLayout
                  fontSize="12px"
                  text="ROW ALIGNMENT"
                  component={
                    <RowAlignmentBtnGroup
                      withBorder={true}
                      buttonBackground={'white'}
                      options={createRowAlignmentOptions(LayoutStrategy.ROW_ALIGNMENT_STEEP_SLOPE_LABELS)}
                      onSelect={(value: string | number): void =>
                        steepSlopeLayoutStrategy.setRowAlignmentStrategy(
                          ERowAlignmentStrategy[value as keyof typeof ERowAlignmentStrategy]
                        )
                      }
                      selected={steepSlopeLayoutStrategy.rowAlignmentStrategy}
                      selectedOptionColor={EThemeComponentColor.BLUE}
                    />
                  }
                />
              </Flexbox>

              {steepSlopeLayoutStrategy.areMixedOrientationsPossible && (
                <>
                  <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
                  <CustomLayout
                    padding="15px 0 0"
                    component={
                      <CheckBox.Checkbox
                        text="Allow mixed landscape orientation if possible"
                        indeterminate={true}
                        checked={steepSlopeLayoutStrategy.mixedOrientationsAllowed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          steepSlopeLayoutStrategy.setMixedOrientationAllowed(event.target.checked)
                        }
                      />
                    }
                  />
                </>
              )}
            </ContentLayout>
          )}
          {requiresLowSlopeLayoutStrategy && requiresSteepSlopeLayoutStrategy && (
            <LyraDivider.Divider
              direction="vertical"
              className="mh-s"
              length={'430px'}
              color={EThemeComponentColor.GRAY_200}
            />
          )}
          {requiresLowSlopeLayoutStrategy && (
            <ContentLayout dir="column">
              <LyraTypography.TextLayout
                type="h2"
                margin={viewModel.isDesignCreation ? '20px 0 20px' : '10px 0 10px'}
                lineHeight={1.5}
                align="center"
              >
                {viewModel.isDesignCreation ? 'Layout Generation Parameters' : 'Update Layout Parameters'}
              </LyraTypography.TextLayout>
              {requiresLowSlopeLayoutStrategy && requiresSteepSlopeLayoutStrategy && (
                <LyraTypography.TextLayout type="h4" margin={'0 0 15px'} lineHeight={1.5} align="center">
                  Flat Roof
                </LyraTypography.TextLayout>
              )}

              <LayoutStrategySection>
                <ButtonGroup
                  width={'124px'}
                  height={'114px'}
                  options={createOrientationOptions(lowSlopeLayoutStrategy.dominantOrientation)}
                  divider={true}
                  onSelect={(value: string | number): void =>
                    lowSlopeLayoutStrategy.setDominantOrientation(EOrientation[value as keyof typeof EOrientation])
                  }
                  selected={lowSlopeLayoutStrategy.dominantOrientation}
                />
              </LayoutStrategySection>
              <LyraDivider.Divider className="mt-s" direction="horizontal" color={EThemeComponentColor.GRAY_200} />
              <Flexbox ref={parentBtn} justify="center">
                <CustomLayout
                  fontSize="12px"
                  text="ROW ALIGNMENT"
                  component={
                    <RowAlignmentBtnGroup
                      withBorder={true}
                      buttonBackground={'white'}
                      options={createRowAlignmentOptions(LayoutStrategy.ROW_ALIGNMENT_LOW_SLOPE_LABELS)}
                      selected={lowSlopeLayoutStrategy.rowAlignmentStrategy}
                      onSelect={(value: string | number): void =>
                        lowSlopeLayoutStrategy.setRowAlignmentStrategy(
                          ERowAlignmentStrategy[value as keyof typeof ERowAlignmentStrategy]
                        )
                      }
                      selectedOptionColor={EThemeComponentColor.BLUE}
                    />
                  }
                />
              </Flexbox>
              <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
              <Row>
                <CustomLayout
                  text="AUTO ROW SPACING"
                  fontSize="12px"
                  padding="15px 25px 15px 0"
                  component={
                    <ButtonGroup
                      width={'55px'}
                      height={'24px'}
                      withBorder={true}
                      buttonBackground={'white'}
                      options={RackSpacing.AUTOMATIC_SPACING_BUTTON_OPTIONS}
                      onSelect={(value: string | number): void =>
                        viewModel.changeAutomaticRackSpacing(value === 'true')
                      }
                      selected={String(viewModel.isRackSpacingAuto)}
                    />
                  }
                />
                {!viewModel.isRackSpacingAuto && (
                  <NumberField.NumberField
                    label="ROW SPACING"
                    onChange={viewModel.changeRackSpacingExactValueInInches}
                    limits={RackSpacing.EXACT_VALUE_LIMIT_IN_INCHES}
                    value={viewModel.rackSpacingExactValueInInches}
                    measure={'in'}
                    width={WIDTH_ROW_SPACING}
                    fractionDigits={2}
                  />
                )}
              </Row>
              {viewModel.isRackSpacingAuto && (
                <>
                  <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
                  <CustomLayout
                    text="SHADE MONTH"
                    fontSize="12px"
                    component={
                      <ButtonGroup
                        width="55px"
                        height="24px"
                        withBorder={true}
                        buttonBackground={'white'}
                        options={RackSpacing.DESIGN_MONTH_OPTIONS}
                        onSelect={viewModel.changeAutoRackSpacingDesignMonth}
                        selected={viewModel.rackSpacingDesignMonthForAutomaticCalculation}
                      />
                    }
                  />
                </>
              )}
            </ContentLayout>
          )}
        </ContentStyle>
      </LyraModal.Modal>
    )
  );
};

export default observer(NewLayoutStrategyModal);
