import styled, { PropsTheme } from 'styled-components';
import { Flexbox } from '../Layout/Flexbox';
import { Color } from '../../typings';

type Props = PropsTheme & {
  status?: string;
};

export const StatusIconWrapper = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})<Props & { status: string }>`
  width: 60px;
  max-height: 54px;
  height: 100%;
  background-color: ${({ theme, status }: Props): Color => status !== 'fail'
    ? theme.colors.main1
    : theme.colors.error
  };
`;
