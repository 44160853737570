import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { FOOTER } from './constants';
import { FlexBG } from './FlexBG';

const Footer = ({ children, className }: BaseProps): ReactElement => {
  return (
    <Area name={FOOTER} className={className}>
      <FlexBG
        className="pv-xxs"
        dir="row"
        align="center"
        justify="center"
        withGradient
        fullHeight
      >
        {children}
      </FlexBG>
    </Area>
  );
};

export { Footer };
