import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';

const {
  H1, H4
} = LyraFontFamily.FontFamily;

type Props = PropsWithTheme & {
  active?: boolean;
  status?: boolean;
  height?: string;
  color?: string;
};

export const ModalContainer = styled.div`
  width: 469px;
`;

export const GroupWrapper = styled.div`
  margin-top: 12px;
  border-top: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const LogoView = styled.div<Props & PropsWithTheme>`
  height: ${({ height }: Props): string => (height ? height : '73px')};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const LeftView = styled.div`
  width: 50%;
`;

export const RightView = styled(LeftView)`
  display: flex;
  justify-content: flex-end;
`;

export const MainText = styled(H4)`
  font-size: 14px;
`;

export const StatusText = styled(H4)<Props>`
  font-size: 14px;
  color: ${({
    theme, status
  }: Props): Color => (status ? theme.colors.gray1 : theme.colors.grayOpacity1)};
`;

export const RadioWrapper = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 83px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 45px;
  height: 18px;
  font-size: 10px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
  border: none;
  padding-left: 5px;
  outline: none;
  margin-right: 8px;
`;

export const InputLabel = styled(H4)`
  font-size: 10px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
  opacity: 0.35;
`;
