import type { JSONSchema7 } from 'json-schema';
import type { UiSchema } from '@rjsf/utils/lib/types';
import { encodeFieldName } from '../fieldNameUtils';
import type { FormData } from '../FormOptionsRulesAndState';
import {
  findFormDataGroupContainingField, findFormSchemaField, findUiSchemaField, getFieldByGroupName
} from '../effects/util';

const doEnableOptions = (
  field: string,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  constrainedByField: string,
  formData: FormData
): void => {
  const fieldInSchema = findFormSchemaField(field, schema.properties);
  if (!fieldInSchema) {
    // This could happen if field is hidden (removed) by some rule
    return;
  }

  const [groupName, constrainingValue] = findFormDataGroupContainingField(encodeFieldName(constrainedByField), formData);
  if (constrainingValue == undefined) {
    return;
  }

  const uiSchemaField = findUiSchemaField(field, uiSchema);
  if (!uiSchemaField) {
    return;
  }

  const fieldEnum = fieldInSchema.enum as number[] | undefined;

  const disabledEnumValues = fieldEnum?.filter(
    (numericEnumValue: number): boolean => numericEnumValue > constrainingValue
  );
  if (disabledEnumValues?.length) {
    uiSchemaField['ui:enumDisabled'] = disabledEnumValues;
  }
  const enabledEnumValues = fieldEnum?.filter(
    (numericEnumValue): boolean => !disabledEnumValues?.includes(numericEnumValue)
  );
  const valueLocation = getFieldByGroupName(formData, groupName);
  if (enabledEnumValues?.length) {
    // there are enabled values - update value if the value in formData in case it is no longer compatible with options available
    const maxEnabledEnumValue = Math.max(...enabledEnumValues);
    fieldInSchema.default = maxEnabledEnumValue;
    const currentValueInFormData = valueLocation[field] as number;
    if (currentValueInFormData !== undefined && !enabledEnumValues.includes(currentValueInFormData)) {
      // Update the value in formData in case it is no longer compatible with options available
      valueLocation[field] = maxEnabledEnumValue;
    }
  } else {
    // all values are disabled - clear previous selection
    delete fieldInSchema.default;
    // storing null makes the field render as "- Select an option -" instead of "First option (disabled)"
    valueLocation[field] = null;
  }
};

type Constraint = {
  field: string,
  constrainedBy: string
}

const enableOptionsLessThanOrEqual = (
  { field, constrainedBy }: Constraint,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
): void => {
  doEnableOptions(
    field.split('.')[1],
    schema,
    uiSchema,
    constrainedBy,
    formData
  );
};

export default enableOptionsLessThanOrEqual;
