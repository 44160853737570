import {
  useCallback, useEffect
} from 'react';
import { EventDispatcher } from 'three';

export enum EventType {
  EditProtrusion = 'EditProtrusion',
  SelectStringingInInverterSummaryModal = 'SelectStringingInInverterSummaryModal',
  NonCoplanarPolygonDetected = 'NonCoplanarPolygonDetected'
}
export type EditProtrusionEventPayload = {
  width: number;
  length: number;
};
export type SelectStringingInInverterSummaryModalPayload = {
  stringingServerId: string;
};
type EventPayload = EditProtrusionEventPayload | SelectStringingInInverterSummaryModalPayload;
export type Event<T = EventPayload> = {
  type: EventType;
  payload?: T;
};
class EventSystem {
  dispatcher: EventDispatcher;
  constructor() {
    this.dispatcher = new EventDispatcher();
  }
}

const eventSystemInstance = new EventSystem();

export type EventDispatcherType = (event: Event) => void;
/**
 * @description use this function to dispatch a custom event
 * @see Event
 */
export function useEventSystemDispatch(): EventDispatcherType {
  // @ts-ignore
  return eventSystemInstance.dispatcher.dispatchEvent.bind(eventSystemInstance);
}
export function getEventSystemDispatch(): EventDispatcherType {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useEventSystemDispatch(); //NOSONAR
}

/**
 * @description use this hook to add an event handler to en eventType. Event listener
 * will be automatically removed when a component it was created from is removed.
 * @see Event
 * @returns unsubscribe callback
 */
export function useEventSystemSubscribe<PayloadType = EventPayload>(
  eventType: EventType,
  handler: (event: Event<PayloadType>) => void
): () => void {
  const unSubscribe = useCallback(
    () => eventSystemInstance.dispatcher.removeEventListener.bind(eventSystemInstance)(eventType, handler),
    [eventType, handler]
  );
  useEffect(() => {
    eventSystemInstance.dispatcher.addEventListener.bind(eventSystemInstance)(eventType, handler);
    return unSubscribe;
  }, [eventType, handler, unSubscribe]);

  return unSubscribe;
}

/**
 * @description use this hook to add an event handler to en eventType. Event listener
 * will be automatically removed when a component it was created from is removed.
 * @see Event
 * @returns unsubscribe callback
 */
export function getEventSystemSubscribe<PayloadType = EventPayload>(
  eventType: EventType,
  handler: (event: Event<PayloadType>) => void
): () => void {
  eventSystemInstance.dispatcher.addEventListener.bind(eventSystemInstance)(eventType, handler);
  return () => eventSystemInstance.dispatcher.removeEventListener.bind(eventSystemInstance)(eventType, handler);
}
