import type { Material } from 'three';
import {
  CircleGeometry,
  BufferGeometry,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  BufferAttribute,
  LineDashedMaterial
} from 'three';
import memoize from 'lodash/memoize';
import { distanceBetween2Points } from '../../utils/spatial';
import { DEFAULT_Z } from '../models/Constants';
import type Color from '../typings/Color';
import type { PolygonDrawable } from '../mixins/PolygonDrawable';
import { WithLengthLabels } from '../mixins/WithLengthLabels';
import { Unzoomable } from '../mixins/Unzoomable';
import { Drawable } from '../mixins/Drawable';
import { Line } from './Line';
import { LayerCanvas } from './LayerCanvas';

const MixedClass = WithLengthLabels(Unzoomable(Drawable(class SimpleClass {})));

export class PreviewLine extends MixedClass {
  isPreviewLine: boolean = true;
  start: Vector3 = new Vector3();
  end: Vector3 = new Vector3();
  upPosition: number = 10;
  circleSize: number = 2;
  getWipObject?: () => PolygonDrawable | undefined;
  showSegmentLength: boolean = true;

  private vertex: Mesh = new Mesh();
  private getPreviewMaterialMemo = memoize(
    (color: Color): Material =>
      new MeshBasicMaterial({
        transparent: true,
        depthTest: true,
        depthWrite: false,
        ...LayerCanvas.UPPERGROUND,
        color
      })
  );

  constructor() {
    super();
    this.mesh.position.setZ(DEFAULT_Z);
    this.initSegmentLengthStyle();
  }

  /**
   * Set the initial and ending coordinates of the line and changes the mesh
   *
   * @param  start in world coordinates
   * @param  end in world coordinates
   */
  set({
    start, end
  }: { start: Vector3; end: Vector3 }): void {
    this.start.copy(start);
    this.end.copy(end);
    this.redraw();
  }

  setStart(start: Vector3): void {
    this.start.copy(start);
    this.redraw();
  }

  unzoom(factor: number): void {
    this.circleSize = factor * 4;
    this.redraw();
  }

  redraw(): void {
    const geometry = this.calculateLineSegmentGeometry();
    const circle = this.createPreviewVertex();
    const material = this.getPreviewMaterialMemo(this.color!);
    this.clearChildren(this.mesh);
    const line = new Line(geometry, material);
    line.computeLineDistances();
    this.mesh.add(line);
    this.mesh.add(this.vertex);
    circle.dispose();
    geometry.dispose();
    material.dispose();

    const wipObjectExists = (this.getWipObject?.()?.boundary?.vertices?.length ?? 0) > 0;

    if (!wipObjectExists) {
      return;
    }

    const labelX = (this.start.x + this.end.x) / 2;
    const labelY = (this.start.y + this.end.y) / 2;
    const length = distanceBetween2Points(this.start, this.end);
    const showSegmentLength =
      this.showSegmentLength
      && this.start.x !== 0
      && this.start.y !== 0
      && this.end.x !== 0
      && this.end.y !== 0
      && (this.start.x !== this.end.x || this.start.y !== this.end.y)
      && wipObjectExists;

    this.renderSegmentLengthLabel({
      showSegmentLength,
      labelX,
      labelY,
      length
    });
  }

  createPreviewVertex(): BufferGeometry {
    this.vertex.geometry.dispose();
    (this.vertex.material as Material).dispose();

    const geometry = new CircleGeometry(this.circleSize, 16);
    const material = new LineDashedMaterial({
      color: this.color,
      ...LayerCanvas.UPPERGROUND
    });
    this.vertex.position.copy(this.end);
    this.vertex.geometry = geometry;
    this.vertex.material = material;

    return geometry;
  }

  private calculateLineSegmentGeometry(): BufferGeometry {
    const geometry = new BufferGeometry();
    const vertices = new Float32Array([this.start.x, this.start.y, this.start.z, this.end.x, this.end.y, this.end.z]);
    geometry.setAttribute('position', new BufferAttribute(vertices, 3));
    return geometry;
  }
}
