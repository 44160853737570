const conversionConstantInches: number = 39.3700787;
const conversionConstantFoot: number = 3.2808399;

export function convertInchesToMeters(valueInInches: number): number {
  return Number((valueInInches / conversionConstantInches).toFixed(4));
}

export function convertMeterToInches(valueInMeters: number): number {
  return Number((valueInMeters * conversionConstantInches).toFixed(4));
}

export function convertFeetToMeters(valueInFeet: number): number {
  return Number((valueInFeet / conversionConstantFoot).toFixed(4));
}

export function convertMeterToFeet(valueInMeters: number): number {
  return Number((valueInMeters * conversionConstantFoot).toFixed(4));
}

export function convertInchesToFeet(valueInInches: number): number {
  return convertMeterToFeet(convertInchesToMeters(valueInInches));
}
