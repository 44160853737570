import type { Intersection } from 'three';
import { MouseBehaviour } from '../../../../domain/behaviour/MouseBehaviour';
import type { IStage } from '../../../../domain/stages/IStage';
import { canvasConfig } from '../../../../config/canvasConfig';
import type DomainStore from '../../../DomainStore/DomainStore';
import { BaseCastObjectControl } from '../../../EditorStore/Controls/BaseCastObjectControl';
import type {
  IPointerDownControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../../EditorStore/Controls/ControlEvents';
import type { PropertiesStore } from '../../Properties/Properties';
import type { WorkspaceStore } from '../../WorkspaceStore';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import type { IDeletePvModulesAndPositionsCommandDependencies } from '../../../ServiceBus/Commands/DeletePvModulesPositionsCommand';
import type { LayoutDesignStage } from '../../../../domain/stages/DesignStages/LayoutDesignStage';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import { getLyraModelByMesh } from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { PvModule } from '../../../../domain/models/PvSystem/PvModule';
import PvModulePosition from '../../../../domain/models/SiteDesign/PvModulePosition';
import { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import { REMOVE_MODULE_ID } from './constants';

export interface IDeleteModuleToolDependencies extends IBaseToolDependencies {
  readonly properties: PropertiesStore;
  readonly domain: DomainStore;
  readonly workspace: WorkspaceStore;
}

/**
 * @description RemovePVModuleTool will remove pv modules from PV module
 * positions as well as positions themselves.
 */
export class RemovePVModuleTool extends BaseTool {
  readonly id: string = REMOVE_MODULE_ID;
  readonly icon: string = 'new-remove-module';
  readonly title: string = 'Remove module';
  readonly description: string = this.title;
  readonly showSubmenu: boolean = false;
  private cooldownInProgress: boolean = false;
  override testId: string = 'RemoveModuleTool';

  private currentStage?: IStage;
  private castPvModulePositionControl?: BaseCastObjectControl;
  /**
   * PV module positions in which PV modules will be deleted
   */
  private positionsToDeletePvModulesInSet: Set<PvModulePosition> = new Set<PvModulePosition>();
  /**
   * PV module positions which will be deleted
   */
  private positionsToDeleteSet: Set<PvModulePosition> = new Set<PvModulePosition>();
  private readonly domain: DomainStore;
  private readonly workspace: WorkspaceStore;
  private inProgress: boolean = false;

  private readonly mouseBehaviour: MouseBehaviour;
  // If multiSelectOnlyPvModulePosition = false - select only PV modules
  private multiSelectOnlyPvModulePosition: boolean = false;

  constructor(dependencies: IDeleteModuleToolDependencies) {
    super(dependencies);
    const {
      domain, workspace
    } = dependencies;
    this.domain = domain;
    this.workspace = workspace;
    this.mouseBehaviour = new MouseBehaviour(this.editor);
  }

  whenSelected(): void {
    this.init();
    this.initListeners();
  }

  private init(): void {
    this.configureBaseCastObjectControl();
    const currentStage = this.workspace.currentWorkspace.stageManager?.currentStage;
    if (currentStage) {
      this.currentStage = currentStage;
    }
    this.currentStage?.setUpTool?.(REMOVE_MODULE_ID);
  }

  private configureBaseCastObjectControl(): void {
    const roofFacesAndAllChildren: Selectable[] = this.editor.getObjectsByType(SceneObjectType.RoofFace, true);
    this.castPvModulePositionControl = new BaseCastObjectControl(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera!,
      roofFacesAndAllChildren
    );
  }

  whenDeselected(): void {
    this.removeListeners();
  }

  private initListeners(): void {
    this.mouseBehaviour.addMouseClickEvents(this);
    this.mouseBehaviour.addMouseMoveEvents(this);
  }

  private removeListeners(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
    this.mouseBehaviour.removeMouseMoveEvents(this);
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    if (this.cooldownInProgress) {
      return;
    }
    this.inProgress = true;
    const intersected: Intersection[] = this.castPvModulePositionControl!.getCastedObjects();
    this.multiSelectOnlyPvModulePosition =
      !!this.getPvModulePositionToDelete(intersected) && !this.getPositionToDeletePvModuleIn(intersected);
    this.reactionToSelection(intersected);
  };

  startCooldown() {
    this.cooldownInProgress = true;
    setTimeout(() => {
      this.cooldownInProgress = false;
    }, 100);
  }

  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    if (this.inProgress) {
      const intersected: Intersection[] = this.castPvModulePositionControl!.getCastedObjects();
      const isPvModule = !!this.getPositionToDeletePvModuleIn(intersected);
      if (
        (this.multiSelectOnlyPvModulePosition && !isPvModule)
        || (!this.multiSelectOnlyPvModulePosition && isPvModule)
      ) {
        this.reactionToSelection(intersected);
      }
    }
  };

  override onMouseUp = (event: IPointerUpControlEvent): void => {
    this.commitPvModulesAndPositionsRemoval();
    if (!this.cooldownInProgress) {
      this.startCooldown();
    }
  };

  override onMouseLeave = (event: IPointerMoveControlEvent): void => {
    this.cleanSelection();
  };

  dispose(): void {
    /** */
  }

  private getPositionToDeletePvModuleIn(intersection: Intersection[]): PvModulePosition | null {
    let position: PvModulePosition | null = null;
    let pvModule: PvModule | null = null;

    intersection.forEach((element: Intersection): void => {
      const model = getLyraModelByMesh(element.object);
      if (model instanceof PvModulePosition) {
        position = model;
      }
      if (model instanceof PvModule) {
        pvModule = model;
      }
    });

    return position && pvModule ? position : null;
  }

  private getPvModulePositionToDelete(intersection: Intersection[]): PvModulePosition | null {
    let position: PvModulePosition | null = null;
    let roofFace: RoofFace | null = null;

    intersection.forEach((element: Intersection): void => {
      const model = getLyraModelByMesh(element.object);
      if (model instanceof PvModulePosition) {
        position = model;
      }
      if (model instanceof RoofFace) {
        roofFace = model;
      }
    });

    return roofFace && position ? position : null;
  }

  private reactionToSelection(intersected: Intersection[]): void {
    if (intersected.length > 0) {
      const positionWithModule: PvModulePosition | null = this.getPositionToDeletePvModuleIn(intersected);
      if (positionWithModule !== null) {
        this.selectPvModulePositionForPvModuleDeletion(positionWithModule);
      } else {
        const positionWithoutPvModule = this.getPvModulePositionToDelete(intersected);
        if (positionWithoutPvModule !== null) {
          this.selectPvModulePositionForDeletion(positionWithoutPvModule);
        }
      }
    }
  }

  private selectPvModulePositionForDeletion(position: PvModulePosition): void {
    const { pvModuleHoveredColor } = canvasConfig;
    position.changeMeshMaterial(pvModuleHoveredColor);
    position.setSelectedMode(true);

    this.positionsToDeleteSet.add(position);
  }

  private selectPvModulePositionForPvModuleDeletion(position: PvModulePosition): void {
    const { pvModuleHoveredColor } = canvasConfig;
    if (position.pvModule) {
      position.pvModule.changeMeshMaterial(pvModuleHoveredColor);
      position.pvModule.setSelectedMode(true);
    }
    this.positionsToDeletePvModulesInSet.add(position);
  }

  private cleanSelection(): void {
    const { pvModuleDefaultColor } = canvasConfig;
    this.positionsToDeletePvModulesInSet.forEach((position: PvModulePosition): void => {
      if (position.pvModule) {
        position.pvModule.changeMeshMaterial(pvModuleDefaultColor);
        position.pvModule.setSelectedMode(false);
      }
    });
    this.positionsToDeletePvModulesInSet.clear();

    this.positionsToDeleteSet.forEach((position: PvModulePosition): void => {
      if (position.pvModule) {
        position.pvModule.changeMeshMaterial(pvModuleDefaultColor);
        position.pvModule.setSelectedMode(false);
      }
    });
    this.positionsToDeleteSet.clear();

    this.inProgress = false;
  }

  private commitPvModulesAndPositionsRemoval(): void {
    if (this.positionsToDeletePvModulesInSet.size > 0 || this.positionsToDeleteSet.size > 0) {
      const commandDependencies: IDeletePvModulesAndPositionsCommandDependencies = {
        currentStage: this.currentStage!,
        positionsToDelete: Array.from(this.positionsToDeleteSet.values()),
        positionsToDeletePvModulesIn: Array.from(this.positionsToDeletePvModulesInSet.values()),
        domain: this.domain
      };
      this.serviceBus.send('delete_pv_modules_and_module_positions', commandDependencies);
      this.positionsToDeleteSet.clear();

      (this.currentStage as LayoutDesignStage).updateAllPositionsCollision(this.editor);
    }
    this.inProgress = false;
  }
}
