import {
  action, computed, observable
} from 'mobx';
import { handleApiError } from '../../../utils/helpers';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { ModalStore } from '../../../stores/UiStore/Modal/Modal';
import { PermitPackageViewModel } from '../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import {
  ADD_MODULE_ID,
  CHANGE_ORIENTATION_ID,
  PANNING_TOOL_ID,
  REMOVE_MODULE_ID,
  REVIEW_CIRCUITS_ID,
  SELECT_TOOL_ID,
  STRINGING_ID
} from '../../../stores/UiStore/ToolbarStore/Design/constants';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { IFormOptionsRulesAndStateData } from '../../entities/Form/FormOptionsRulesAndState';
import type { Design } from '../../models/Design/Design';
import { DesignReadiness } from '../../models/SiteDesign/DesignReadiness';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import type { DesignState } from '../../models/Design/DesignState';
import { DesignStep } from '../../models/Design/DesignState';
import config, { UI_MODE } from '../../../config/config';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import { SentryException } from '../../../utils/sentryLog';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { DocumentsService } from '../../../infrastructure/services/api/DocumentsService';
import type { IFormData } from '../../entities/Form/FormData';
import { MountingBosFormSubmittedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import { type PagesStore } from '../../../stores/UiStore/Pages/PagesStore';

export interface IMountingBosDependencies {
  readonly editor: EditorStore;
  readonly domain: DomainStore;
  readonly designWorkspace: DesignWorkspace;
  readonly serviceBus: ServiceBus;
  readonly toolbar: ToolbarStore;
  readonly modal: ModalStore;
  readonly pages: PagesStore;
}

export class MountingBosStage implements IProgressStepperStage {
  static readonly toolBlacklist: string[] = [
    SELECT_TOOL_ID,
    ADD_MODULE_ID,
    CHANGE_ORIENTATION_ID,
    REMOVE_MODULE_ID,
    STRINGING_ID
  ];

  static readonly toolWhitelist: string[] = [PANNING_TOOL_ID, REVIEW_CIRCUITS_ID];

  readonly propCodeUI: string = PropsPanelUICodes.MountingBos;
  readonly title: string = `Mounting${config.featureFlag.uiMode !== UI_MODE.AURORA ? ' BOS' : ''}`;
  readonly id: DesignStep = DesignStep.MOUNTING_BOS;

  @observable
  formSpecification: IFormOptionsRulesAndStateData | undefined;

  private hasMissingProperties: boolean = false;
  @observable
  formSubmitInProgress: boolean = false;

  private readonly editor: EditorStore;
  private readonly domain: DomainStore;
  private readonly designWorkspace: DesignWorkspace;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly modal: ModalStore;
  private readonly designService = new DesignService();
  private readonly documentsService = new DocumentsService();
  private readonly pages: PagesStore;

  constructor(dependencies: IMountingBosDependencies) {
    const {
      designWorkspace, domain, editor, serviceBus, toolbar, modal, pages
    } = dependencies;

    this.designWorkspace = designWorkspace;
    this.domain = domain;
    this.editor = editor;
    this.serviceBus = serviceBus;
    this.toolbar = toolbar;
    this.modal = modal;
    this.pages = pages;

    if (config.forwardToPermitPackageDownload) {
      config.forwardToPermitPackageDownload = false;
      if (this.domainModel.state.isDataIn(DesignStep.COMPLETED)) {
        this.openPermitPackageModalOrPage(true);
      }
    }
  }

  @computed
  get domainModel(): Design {
    return this.domain.design;
  }

  setUp = (): void => {
    this.updateUserState();
    if (this.domainModel.state.isDataIn(DesignStep.ELECTRICAL_BOS)) {
      this.updateDataState(DesignStep.MOUNTING_BOS);
    }
    this.retrieveFormSpecification();
    this.enableTools();
    this.editor.renderSiteMarkers(this.designWorkspace);
  };

  @computed
  get hasFormFields(): boolean {
    return this.formSpecification?.form.fields.length > 0;
  }

  @computed
  get formSpecificationLoaded(): boolean {
    return !!this.formSpecification;
  }

  @action.bound
  async retrieveFormSpecification(): Promise<void> {
    this.formSpecification = undefined;
    try {
      this.documentsService
        .getFormMountingBOS(this.domainModel.id)
        .then((response: IFormOptionsRulesAndStateData): void => {
          this.formSpecification = response;
          if (!this.formSpecificationLoaded) {
            this.updateDataState(DesignStep.COMPLETED);
          }
        })
        .catch(handleApiError('Failed to get mounting BOS options'));
    } catch (error) {
      SentryException('Error getting mounting BOS options', error);
    }
  }

  get canContinue(): boolean {
    return !this.hasMissingProperties;
  }

  @action.bound
  async onSubmitForm(formData: IFormData): Promise<void> {
    if (
      this.formSubmitInProgress
      // FormGenerator captures global enter press. Don't restart the modal if it's already opened.
      || (this.modal.modalName === 'permit_package_modal' && this.modal.viewModel)
    ) {
      return;
    }
    this.formSubmitInProgress = true;
    this.documentsService
      .saveFormMountingBOS(this.domainModel.id, formData)
      .then((): void => {
        this.updateDataState(DesignStep.COMPLETED);
        this.openPermitPackageModalOrPage();
        config.analytics?.trackEvent(new MountingBosFormSubmittedEvent(this.domain));
      })
      .catch(handleApiError('Failed to save mounting BOS options'))
      .finally((): void => {
        this.formSubmitInProgress = false;
      });
  }

  @action
  openPermitPackageModalOrPage = (forwardToPermitPackageDownload: boolean = false): void => {
    // FormGenerator captures Enter keypress and restarts the permit package modal.
    if (this.modal.modalName === 'permit_package_modal' && this.modal.viewModel) {
      return;
    }

    if (config.featureFlag.uiMode === UI_MODE.AURORA) {
      // Instead of directly opening the page, we can ask stage manager to do it for us,
      // which will also take care of managing the current stage index (and the stage status)
      this.designWorkspace.stageManager?.next();
    } else {
      this.modal.createModal(
        'permit_package_modal',
        new PermitPackageViewModel({
          modal: this.modal,
          domain: this.domain,
          editor: this.editor,
          forwardToPermitPackageDownload
        })
      );
    }
  };

  disposeEvents(): void {
    // Not implemented yet
  }

  continue(): void {
    // Not implemented yet
  }

  cancel(): void {
    this.toolbar.deselectTool();
    this.formSpecification = undefined;
  }

  dispose(): void {
    this.formSpecification = undefined;
  }

  resume(lastValidStage: string): void {
    this.retrieveFormSpecification();
    this.enableTools();
  }

  async beforeContinue(continueBackwards: boolean = false): Promise<void> {
    if (!continueBackwards) {
      await this.validateMissingProperties();
    }
  }
  async validateMissingProperties(): Promise<void> {
    // BE doesn't have 'CUSTOMIZATION' and 'PREVIEW' stages,
    // so we just skip two stages straight to 'COMPLETED' to avoid API error
    const moveForward = config.featureFlag.uiMode === UI_MODE.AURORA ? 3 : 1;
    const nextStep = this.designWorkspace.stageManager!.currentIndex + moveForward;
    const nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);
    if (nextStage) {
      const missingPropertiesResponse = await this.designService
        .getDesignMissingProperties(this.domainModel.id, nextStage.id)
        .catch(handleApiError('Failed to get missing design properties'));
      const designReadiness = new DesignReadiness({
        missingPropertiesResponse
      });
      this.hasMissingProperties = designReadiness.hasMissingProperties();
      designReadiness.createNotifications();
    }
  }

  private enableTools(): void {
    this.toolbar.blacklistTools(MountingBosStage.toolBlacklist);
    this.toolbar.whitelistTools(MountingBosStage.toolWhitelist);
  }

  private updateUserState(): void {
    this.updateDesignState(this.domainModel.state.withUserState(this.id));
  }

  /**
   * Should be called every time when data-modifying permit-ready endpoint is called
   */
  private updateDataState(newDataState: DesignStep): void {
    this.updateDesignState(this.domainModel.state.withDataState(newDataState));
  }

  private updateDesignState(state: DesignState): void {
    this.serviceBus.send('update_design_state', state.toUpdateStateCommand(this.domain));
  }
}
