import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type { DesignParameters } from '../../../../domain/models/SiteDesign/DesignParameters';
import type { IOption } from '../../../../domain/models/SiteDesign/IOption';
import type {
  RiskCategoryOptionAttributes, WindSpeedOptionAttributes
} from '../../../../domain/typings/Attributes';
import type DomainStore from '../../../DomainStore/DomainStore';
import { handleApiError } from '../../../../utils/helpers';
import { BaseViewModel } from '../../Modal/BaseViewModel';
import type { ModalStore } from '../../Modal/Modal';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateWindDesignParametersCommandDependencies } from '../../../ServiceBus/Commands/UpdateWindDesignParametersCommand';
import type { StructuralStandard } from '../../../../domain/models/SiteDesign/CodesAndStandards';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../domain/behaviour/KeyboardBehaviour';

interface IWindSpeedViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly serviceBus: ServiceBus;
  readonly editor: EditorStore;
}

type WindSpeedOptionProps = OptionProps & {
  riskCategories: string;
};

export class WindSpeedViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  static readonly CUSTOM_WIND_SPEED_OPTION = 'Other';

  @observable
  isLoading!: boolean;

  readonly propCodeUI = 'wind_speed_modal';
  override readonly editor: EditorStore;

  @observable
  selectedStructuralStandardOption: OptionProps;

  @observable
  private windSpeed?: OptionProps;

  @observable
  private riskCategory?: OptionProps;

  @observable
  private riskCategoryOptions: OptionProps[] = [];

  @observable
  private windSpeedOptions: WindSpeedOptionProps[] = [];

  private readonly serviceBus: ServiceBus;

  constructor(dependencies: IWindSpeedViewModelDependencies) {
    super(dependencies);
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;

    this.selectedStructuralStandardOption =
      this.domain.project.designParameters.codesAndStandards.structuralStandardAsOption;
    this.loadOptions();

    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @computed
  get structuralStandardOptions(): OptionProps[] {
    return this.domain.project.designParameters.codesAndStandards.structuralStandardOptions;
  }

  @computed
  get currentWindSpeed(): OptionProps | undefined {
    return this.windSpeed;
  }

  @computed
  get currentRiskCategory(): OptionProps | undefined {
    return this.riskCategory;
  }

  @computed
  get optionsSpeed(): OptionProps[] {
    const riskCategory = this.riskCategory;
    if (!riskCategory) {
      return [];
    }
    const key = `${this.selectedStructuralStandardOption.value}#${riskCategory.value}`;
    return this.windSpeedOptions.filter((currentWindSpeed: WindSpeedOptionProps): boolean =>
      currentWindSpeed.riskCategories.includes(key)
    );
  }

  @computed
  get optionsRisk(): OptionProps[] {
    return this.riskCategoryOptions;
  }

  @computed
  get saveButtonEnabled(): boolean {
    const isCustomWindSpeedValid =
      this.currentWindSpeed?.name === WindSpeedViewModel.CUSTOM_WIND_SPEED_OPTION
      && Number(this.currentWindSpeed?.value) > 0;
    const isStandardWindSpeedValid = this.optionsSpeed.some(
      (option: OptionProps): boolean => option.value === this.currentWindSpeed?.value
    );
    return isCustomWindSpeedValid || isStandardWindSpeedValid;
  }

  @action.bound
  setSelectedStructuralStandardOption(selected: OptionProps): void {
    this.selectedStructuralStandardOption = selected;
  }

  @action.bound
  setSpeed(selected: OptionProps): void {
    this.windSpeed = {
      ...selected,
      value: Math.round(Number(selected.value)).toString()
    };
  }

  @action.bound
  setCategory(selected: OptionProps): void {
    this.riskCategory = selected;
  }

  @action.bound
  save(): void {
    const windDesignParameters = this.domain.project.designParameters.wind;
    if (windDesignParameters) {
      const commandDependencies: IUpdateWindDesignParametersCommandDependencies = {
        domain: this.domain,
        windSpeed: Number(this.currentWindSpeed!.value),
        structuralStandardSelected: this.selectedStructuralStandardOption.value as StructuralStandard,
        riskCategorySelected: this.riskCategory?.value ?? ''
      };
      this.serviceBus.send('update_windSpeed_values', commandDependencies);
      this.closeModal();
    }
  }

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_ENTER) {
      this.save();
      this.dispose();
    }
  };

  private async loadOptions(): Promise<void> {
    this.isLoading = true;

    const riskCategoryOptions = await this.designService
      .getRiskCategories()
      .catch(handleApiError('Failed to fetch risk categories data'));
    const windSpeedOptions = await this.designService
      .getWindSpeeds()
      .catch(handleApiError('Failed to fetch wind speed options data'));

    runInAction((): void => {
      this.riskCategoryOptions = this.mapRiskCategoryToOptions(riskCategoryOptions);
      this.windSpeedOptions = this.mapWindSpeedToOptions(windSpeedOptions);
      this.loadWindByDefault(this.domain.project.designParameters);

      this.isLoading = false;
    });
  }

  private mapRiskCategoryToOptions(options: IOption<RiskCategoryOptionAttributes>[]): OptionProps[] {
    return options
      .filter((option: IOption<RiskCategoryOptionAttributes>): boolean =>
        option.attributes.structuralStandards.includes(this.selectedStructuralStandardOption.value)
      )
      .map(({ attributes }: IOption<RiskCategoryOptionAttributes>): OptionProps => {
        return {
          ...attributes,
          description: attributes.definition,
          component: `Risk Category ${attributes.name}`
        };
      });
  }

  private mapWindSpeedToOptions(options: IOption<WindSpeedOptionAttributes>[]): WindSpeedOptionProps[] {
    return options.map(
      ({ attributes }: IOption<WindSpeedOptionAttributes>): WindSpeedOptionProps => ({
        ...attributes,
        component: `${attributes.value} MPH`,
        riskCategories: attributes['risk-categories']
      })
    );
  }

  @action.bound
  private loadWindByDefault(designParameters: DesignParameters): void {
    const {
      buildingRiskCategory, wind
    } = designParameters;
    const windSpeed = wind?.windSpeed;

    if (buildingRiskCategory) {
      const buildingRiskLoaded = this.findOptionWithValue(this.riskCategoryOptions, buildingRiskCategory);
      if (buildingRiskLoaded) {
        this.riskCategory = buildingRiskLoaded;
      }
    }

    if (windSpeed) {
      const windSpeedLoaded = this.findOptionWithValue(this.optionsSpeed, `${windSpeed}`);
      this.windSpeed = windSpeedLoaded ?? {
        name: WindSpeedViewModel.CUSTOM_WIND_SPEED_OPTION,
        value: `${windSpeed}`
      };
    }
  }

  private findOptionWithValue(options: OptionProps[], searchedValue: string): OptionProps | undefined {
    return options.find((currentOption: OptionProps): boolean => currentOption.value === searchedValue);
  }
}
