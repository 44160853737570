import { notify } from '../../../utils/helpers';
import type {
  IProjectMissingPropertiesResponse,
  IProjectMissingProperty,
  IProjectMissingPropertyObject,
  ISiteEquipmentMissingProperties,
  IBuildingMissingProperties,
  IBuildingObjectList,
  IRoofFaceMissingProperties
} from '../../entities/MissingProperties/IMissingProperties';
import {
  MissingPropertyLabel as EnumLabel,
  MissingPropertyType as EnumType
} from '../../entities/MissingProperties/IMissingProperties';
import { ERROR } from '../Constants';

interface IDesignReadinessDependency {
  missingPropertiesResponse: IProjectMissingPropertiesResponse;
}

type MissingProperty = {
  type: string;
  label: string;
};

export class DesignReadiness {
  private missingProperties: MissingProperty[];

  constructor(dependencies: IDesignReadinessDependency) {
    this.missingProperties = [];
    const { missingPropertiesResponse } = dependencies;
    if (missingPropertiesResponse.designParameters) {
      this.mapDesign(missingPropertiesResponse.designParameters);
    }
    if (missingPropertiesResponse.site) {
      this.mapDesign(missingPropertiesResponse.site);
    }
    if (missingPropertiesResponse.siteEquipment) {
      this.mapSiteEquipment(missingPropertiesResponse.siteEquipment);
    }
    if (missingPropertiesResponse.buildings) {
      this.mapBuildings(missingPropertiesResponse.buildings);
    }
    if (missingPropertiesResponse.design) {
      this.mapDesign(missingPropertiesResponse.design);
    }
  }

  hasMissingProperties(): boolean {
    return !!this.missingProperties.length;
  }

  createNotifications(): void {
    if (this.missingProperties instanceof Array) {
      this.missingProperties.forEach((missingProperty: MissingProperty): void => notify(missingProperty.label, ERROR));
    }
  }

  private mapDesign(designParameters: IProjectMissingPropertyObject): void {
    const { missingProperties } = designParameters;
    this.addMandatoryMissingProperties(missingProperties);
  }

  private mapSiteEquipment(siteEquipment: ISiteEquipmentMissingProperties): void {
    const {
      missingProperties = [],
      mainServicePanel = {
        missingProperties: []
      },
      subpanel = {
        missingProperties: []
      }
    } = siteEquipment;
    this.addMandatoryMissingProperties(missingProperties);
    this.addMandatoryMissingProperties(mainServicePanel.missingProperties);
    this.addMandatoryMissingProperties(subpanel.missingProperties);
  }

  private mapBuildings(buildings: IBuildingObjectList): void {
    Object.entries(buildings).forEach(([, buildingMissingProperties]: [string, IBuildingMissingProperties]): void => {
      const {
        missingProperties = [], roofFaces = {}
      } = buildingMissingProperties;
      this.addMandatoryMissingProperties(missingProperties);
      Object.entries(roofFaces).forEach(([, roofFaceMissingProperties]: [string, IRoofFaceMissingProperties]): void => {
        this.addMandatoryMissingProperties(roofFaceMissingProperties.missingProperties);
      });
    });
  }

  private addMandatoryMissingProperties(missingProperties: IProjectMissingProperty[]): void {
    missingProperties.forEach((missingProperty: IProjectMissingProperty): void => {
      const { type } = missingProperty;
      this.missingProperties.push({
        type: EnumType[type],
        label: missingProperty.explanation ? missingProperty.explanation : EnumLabel[type]
      });
    });
  }
}
