import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactNode
} from 'react';
import React, {
  useState, useEffect, useMemo, useContext
} from 'react';
import { ThemeContext } from 'styled-components';
import type { BaseReactProps } from '@aurorasolar/lyra-ui-kit/lib/typings/BaseReactProps';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import { ProjectPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/ProjectPropertiesModal/ProjectPropertiesViewModel';
import { getRootStore } from '../../../../stores/RootStoreInversion';
import {
  handleApiError, notify
} from '../../../../utils/helpers';
import {
  CONFIRMATION, ERROR
} from '../../../../domain/models/Constants';
import type { IDesignParametersData } from '../../../../domain/models/SiteDesign/DesignParameters';
import { CodesAndStandards } from '../../../../domain/models/SiteDesign/CodesAndStandards';
import { DesignService } from '../../../../infrastructure/services/api/DesignService';
import type { IOption } from '../../../../domain/models/SiteDesign/IOption';
import type {
  RiskCategoryOptionAttributes, WindSpeedOptionAttributes
} from '../../../../domain/typings/Attributes';
import type { WindExposureCategoryProp } from '../WindExposureModal/WindExposureModal';
import { windExposureCategories } from '../WindExposureModal/WindExposureModal';
import { SnowDesignParameters } from '../../../../domain/models/SiteDesign/SnowDesignParameters';
import { topographicalConditionList } from '../../../../stores/UiStore/EnvironmentalProps/EnvironmentalPropsStore';
import { averagePropertyGradeOptions } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/SiteTopographicalViewModel';
import { convertFeetToMeters } from '../../../../utils/Convertions';

type ProjectProperties = IDesignParametersData & {
  elevationInFeet?: number;
};
type ProjectPropertiesModalAuroraUIProps = {
  isOpened: boolean;
  onClose: () => void;
} & BaseReactProps;

type Option = {
  name: string;
  label: string;
  value: string | number;
  tooltip?: string | ReactNode;
};

export const ProjectPropertiesModalAuroraUI = observer(
  ({
    isOpened, onClose
  }: ProjectPropertiesModalAuroraUIProps): ReactElement => {
    const theme = useContext(ThemeContext);
    const DS = theme!.DS!;

    const designService = useMemo((): DesignService => new DesignService(), []);
    const { domain } = getRootStore();

    const viewModel = useMemo((): ProjectPropertiesViewModel => {
      const {
        serviceBus, editor
      } = getRootStore();
      const { modal } = getRootStore().uiStore;
      return new ProjectPropertiesViewModel({
        modal,
        domain,
        editor,
        serviceBus
      });
    }, [domain]);

    const [windSpeedOptions, setWindSpeedOptions] = useState<Option[]>([]);
    const [riskCategoryOptions, setRiskCategoryOptions] = useState<Option[]>([]);
    useEffect((): void => {
      viewModel.loadSelectionOptions();
      designService
        .getRiskCategories()
        .then((data: IOption<RiskCategoryOptionAttributes>[]): void => {
          setRiskCategoryOptions(
            data.map(
              (option: IOption<RiskCategoryOptionAttributes>): Option => ({
                name: option.attributes.name,
                value: option.attributes.value,
                label: option.attributes.name
              })
            )
          );
        })
        .catch(handleApiError('Failed to fetch risk categories data'));
      designService
        .getWindSpeeds()
        .then((data: IOption<WindSpeedOptionAttributes>[]): void => {
          setWindSpeedOptions(
            data.map(
              (option: IOption<WindSpeedOptionAttributes>): Option => ({
                name: option.attributes.name,
                value: Number(option.attributes.value),
                label: option.attributes.name
              })
            )
          );
        })
        .catch(handleApiError('Failed to fetch wind speed options data'));
    }, [designService, viewModel]);

    /**
     * @description for additional codes we need an input with chips inside of it.
     * Users are able to enter custom values, and add it to the list of chips, remove chips.
     * DS TokenizedAutocompleteInput is almost a perfect fit. Except the dropdowns, we need to hide it.
     * There's no way to do that without changing DS, so a following hack is used:
     * When the input with chips is focused, we hide the dropdowns by adding a style tag,
     * and when it's blured - remove the style tag. The dropdown is rendered using a portal,
     * so we're unable to use CSS specificity to hide only the dropdowns inside the
     * TokenizedAutocompleteInput.
     */
    const [additionalCodesFocused, setAdditionalCodesFocused] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    return (
      <DS.Modal
        id="project-properties-modal"
        handleClose={onClose}
        open={isOpened}
        size="md"
        mountTarget={document.querySelector('#design-tool-ds-mount-target')}
      >
        <Formik<ProjectProperties>
          enableReinitialize={false}
          initialValues={{
            ...viewModel.toData(),
            elevationInFeet: domain.project.site.elevationInFeet
          }}
          onSubmit={async (values: ProjectProperties): Promise<void> => {
            setIsSaving(true);
            try {
              viewModel.fromData(values);
              if (values.elevationInFeet) {
                domain.project.site.elevation = convertFeetToMeters(values.elevationInFeet);
              }
              await viewModel.saveProjectProperties();
              setIsSaving(false);
              onClose();
              notify('Project properties saved', CONFIRMATION);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Failed to generate or download plan set', e);
              notify(`Failed to save project properties: ${e}`, ERROR);
            }
          }}
        >
          {(formikbag: FormikProps<ProjectProperties>) => (
            <>
              <DS.ModalHeader>
                <DS.Text ml={2} as="h2" text="h2">
                  Project properties
                </DS.Text>
              </DS.ModalHeader>

              <DS.ModalBody text="span">
                <DS.Grid>
                  <DS.GridItem p={2} size="third">
                    <DS.Text mb={4} text="body20">
                      Adopted codes
                    </DS.Text>
                    <DS.Grid>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="codesAndStandards.electricalCode"
                          label="Electrical Code"
                          placeholder="Select electrical code"
                          options={viewModel.electricalCodeOptions.map(
                            (item: IDropDownOption): Option => ({
                              name: item.label,
                              label: item.label,
                              value: item.value
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('codesAndStandards.electricalCode', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="codesAndStandards.fireCode"
                          label="Fire Code"
                          placeholder="Select electrical code"
                          options={viewModel.fireCodeOptions.map(
                            (item: IDropDownOption): Option => ({
                              name: item.label,
                              label: item.label,
                              value: item.value
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('codesAndStandards.fireCode', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      {additionalCodesFocused && (
                        /* See the explanation near additionalCodesFocused initialization. */
                        <style>
                          {`
                          div[data-subcomponent="dropdown-menu"] {
                            display: none !important;
                          }
                        `}
                        </style>
                      )}

                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.TokenizedAutocompleteInput}
                          onFocus={() => {
                            setAdditionalCodesFocused(true);
                          }}
                          onBlur={() => {
                            setAdditionalCodesFocused(false);
                          }}
                          name="codesAndStandards.other"
                          label="Additional codes"
                          placeholder="Add an additional code"
                          multiple={true}
                          options={[]}
                          size="sm"
                          autogenerateValues
                        />
                      </DS.GridItem>
                    </DS.Grid>
                  </DS.GridItem>
                  <DS.GridItem p={2} size="third">
                    <DS.Text mb={4} text="body20">
                      Wind
                    </DS.Text>
                    <DS.Grid>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="codesAndStandards.structuralStandard"
                          label="ASCE"
                          placeholder="Select structural standard"
                          options={CodesAndStandards.STRUCTURAL_STANDARD_OPTIONS.map(
                            (value: {
                              name: string;
                              value: string;
                            }): {
                              name: string;
                              label: string;
                              value: string;
                            } => ({
                              name: value.name,
                              label: value.name,
                              value: value.value
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('codesAndStandards.structuralStandard', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.NumericInput}
                          name="wind.windSpeed"
                          label="Wind speed"
                          placeholder="Select wind speed"
                          size="sm"
                          formatOptions={{
                            style: 'unit',
                            unit: 'mile-per-hour'
                          }}
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="buildingRiskCategory"
                          label="Risk category"
                          placeholder="Select risk category"
                          options={riskCategoryOptions}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('buildingRiskCategory', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="terrain.windExposureCategory"
                          label="Wind exposure category"
                          placeholder="Select wind exposure category"
                          renderOptionLabel={(option: Option): ReactElement => (
                            <DS.Tooltip
                              anchor={
                                <DS.Text text="body12" color="uiHelperCopy" fontVariant="normal">
                                  {option.label}
                                </DS.Text>
                              }
                              tooltip={option.tooltip}
                            />
                          )}
                          options={windExposureCategories.map(
                            (value: WindExposureCategoryProp): Option => ({
                              name: value.key,
                              label: value.key,
                              value: value.key,
                              tooltip: value.text
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('terrain.windExposureCategory', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                    </DS.Grid>
                  </DS.GridItem>
                  <DS.GridItem p={2} size="third">
                    <DS.Text mb={4} text="body20">
                      Other
                    </DS.Text>
                    <DS.Grid>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="snow.groundSnowLoad"
                          label="Ground snow load"
                          placeholder="Select ground snow load"
                          options={SnowDesignParameters.GROUND_SNOW_LOAD_OPTIONS.map(
                            (value: { name: string; value: string }): Option => ({
                              name: value.name,
                              label: value.name,
                              value: Number(value.value)
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('snow.groundSnowLoad', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="terrain.topographicalCondition"
                          label="Topographical condition"
                          placeholder="Select topographical condition"
                          options={Object.entries(topographicalConditionList).map(
                            ([value, label]: string[]): Option => ({
                              name: label,
                              label: label,
                              value: value
                            })
                          )}
                          handleSelectionChange={(values: string[]) => {
                            formikbag.setFieldValue('terrain.topographicalCondition', values[0]);
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.DropdownInput}
                          name="terrain.propertyGrade"
                          label="Average grade of property"
                          placeholder="Select average grade of property"
                          renderOptionLabel={(option: Option): ReactElement => (
                            <DS.Tooltip
                              anchor={
                                <DS.Text text="body12" color="uiHelperCopy" fontVariant="normal">
                                  {option.label}
                                </DS.Text>
                              }
                              tooltip={option.tooltip}
                            />
                          )}
                          options={averagePropertyGradeOptions.map(
                            ({
                              name: label,
                              value,
                              component
                            }: {
                              name: string;
                              value: string;
                              component?: string | ReactNode;
                            }): Option => ({
                              name: label,
                              label: label,
                              value: Number(value),
                              tooltip: <div style={{ minWidth: '200px' }}>{String(component)}</div>
                            })
                          )}
                          handleSelectionChange={(values: (string | number)[]) => {
                            formikbag.setFieldValue(
                              'terrain.propertyGrade',
                              isNaN(Number(values[0])) ? undefined : values[0]
                            );
                          }}
                          size="sm"
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.NumericInput}
                          name="seismic.sds"
                          label="Spectral Acceleration (SDS)"
                          placeholder="Enter spectral acceleration"
                          size="sm"
                          formatOptions={{
                            style: 'unit',
                            unit: 'gram'
                          }}
                        />
                      </DS.GridItem>
                      <DS.GridItem size="full">
                        <DS.Field
                          component={DS.NumericInput}
                          name="elevationInFeet"
                          label="Elevation"
                          placeholder="Enter elevation"
                          size="sm"
                          formatOptions={{
                            style: 'unit',
                            unit: 'foot'
                          }}
                        />
                      </DS.GridItem>
                    </DS.Grid>
                  </DS.GridItem>
                </DS.Grid>
              </DS.ModalBody>

              <DS.ModalFooter>
                <DS.Button
                  loading={isSaving}
                  action={(): void => {
                    formikbag.submitForm();
                  }}
                >
                  Save
                </DS.Button>
                <DS.Button action={onClose} variant="tertiary">
                  Cancel
                </DS.Button>
              </DS.ModalFooter>
            </>
          )}
        </Formik>
      </DS.Modal>
    );
  }
);
