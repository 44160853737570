import { RoofLevel } from '@aurorasolar/lyra-ui-kit/lib/components/RoofLevel';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useState
} from 'react';
import type {
  Color, ERoofType
} from '../../../domain/typings';
import { DEFAULT_ICON_COLOR } from '../../../domain/models/RoofColorLevelRange';
import {
  HouseShapeWrapper, RoofButtonStyle
} from './styles';

type Props = BaseProps & {
  onClick?: (level: number, event: MouseEvent) => void;
  level: number;
  selected?: boolean;
  withCaption?: boolean;
  selectedRoofFaceColor?: Color;
  groupRoofType?: ERoofType;
  currentRoofFaceColor?: Color;
  icon?: string;
};

function RoofButton({
  onClick,
  level,
  selected,
  withCaption = true,
  className,
  icon,
  selectedRoofFaceColor,
  currentRoofFaceColor
}: Props): ReactElement {
  const decimal = level % 1;
  const levelValue = decimal === 0 ? level : Math.floor(level) + 0.5;
  const initialIconColor = currentRoofFaceColor === selectedRoofFaceColor ? selectedRoofFaceColor : DEFAULT_ICON_COLOR;

  const [iconColor, setIconColorOnHover] = useState(initialIconColor);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      onClick?.(levelValue, event.nativeEvent);
    },
    [onClick, levelValue]
  );

  const onHover = useCallback((): void => {
    const colorOnHover = currentRoofFaceColor !== selectedRoofFaceColor ? currentRoofFaceColor : iconColor;
    setIconColorOnHover(colorOnHover);
  }, [currentRoofFaceColor, selectedRoofFaceColor, iconColor, setIconColorOnHover]);
  const onHoverOut = useCallback((): void => {
    const colorOnHoverOut = currentRoofFaceColor === selectedRoofFaceColor ? selectedRoofFaceColor : DEFAULT_ICON_COLOR;
    setIconColorOnHover(colorOnHoverOut);
  }, [currentRoofFaceColor, selectedRoofFaceColor, setIconColorOnHover]);

  return (
    <RoofButtonStyle className={className}>
      <HouseShapeWrapper onClick={handleClick} selected={selected} onMouseOut={onHoverOut} onMouseOver={onHover}>
        {icon && <RoofLevel level={level} fill={iconColor} />}
      </HouseShapeWrapper>
      {withCaption && <p>Level {levelValue}</p>}
    </RoofButtonStyle>
  );
}

export default RoofButton;
