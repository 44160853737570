// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import createProps from '../createProps.js';
import { ViewportSizeType } from '../types.js';

const rowKeys = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'around', 'between'];

const propTypes = {
  reverse: PropTypes.bool,
  start: ViewportSizeType,
  center: ViewportSizeType,
  end: ViewportSizeType,
  top: ViewportSizeType,
  middle: ViewportSizeType,
  bottom: ViewportSizeType,
  around: ViewportSizeType,
  between: ViewportSizeType,
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};

function getRowClassNames(props: Record<string, unknown>): string[] {
  const modificators = [props.className, 'row'];

  for (let i = 0; i < rowKeys.length; ++i) {
    const key = rowKeys[i];
    const value = props[key];
    if (value) {
      modificators.push(`${key}-${value}`);
    }
  }

  if (props.reverse) {
    modificators.push('reverse');
  }

  return modificators;
}

export function getRowProps(props: Record<string, unknown>): Record<string, unknown> {
  return createProps(propTypes, props, getRowClassNames(props));
}

export default function Row(props: Record<string, unknown>): React.ReactElement {
  return React.createElement(props.tagName || 'div', getRowProps(props));
}

Row.propTypes = propTypes;
