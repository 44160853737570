enum EThemeComponentColor {
  BLUE = 'blue',
  PURPLE = 'purple',
  GRAY = 'gray',
  RED = 'red',
  GRAY_200 = 'gray_200',
  GRAY_300 = 'gray_300',
  GRAY_400 = 'gray_400',
  GRAY_500 = 'gray_500',
  GRAY_600 = 'gray_600',
  GRAY_700 = 'gray_700',
  GRAY_800 = 'gray_800',
  GRAY_900 = 'gray_900',
  AQUAMARINE = 'aquamarine',
  WHITE = 'white',
  BLACK = 'black',
  TRANSPARENT = 'transparent'
}

export { EThemeComponentColor };
