import { EventDispatcher } from 'three';
import { handleApiError } from '../../utils/helpers';
import { CommandQueue } from './CommandQueue';
import type {
  ICommand, IConfirmableCommand
} from './Commands/Command';
import type { PromisableCommands } from './Commands/registry';
import { registryCommands } from './Commands/registry';
import type { HandlerConstructor } from './Handlers/registry';
import { registryHandlers } from './Handlers/registry';
import type {
  IBaseCommandDependencies, IBaseHandlerDependencies, ICommandBus, IHandlerBus
} from './IServiceBus';

export type CommandExecutedEvent = {
  commandName: string;
};
export type CommandNeedsConfirmationEvent = {
  command: IConfirmableCommand;
  commandName: string;
};

export class ServiceBus
  extends EventDispatcher<{
    commandNeedsConfirmation: CommandNeedsConfirmationEvent;
    commandExecuted: CommandExecutedEvent;
  }>
  implements ICommandBus, IHandlerBus {
  protected counter: number = 0;
  private iterator: Generator<string, string, boolean> | undefined;
  private queue: CommandQueue | undefined;

  publish<T extends IBaseHandlerDependencies>(name: string, dependencies: T): void {
    if (!registryHandlers[name]) {
      return;
    }

    registryHandlers[name].forEach((c: HandlerConstructor): void => {
      const result = new c(dependencies);
      result.execute().catch(handleApiError(`Servicebus handler ${result.name} error`));
    });
  }

  send<T extends IBaseCommandDependencies>(
    name: string,
    dependencies: T,
    confirmationDeclinedCallback?: () => void
  ): Generator<string, string, boolean> | void {
    const isRegisteredCommand = !!registryCommands[name];

    if (isRegisteredCommand && !this.isConfirmableCommand(new registryCommands[name](dependencies))) {
      this.execute(name, dependencies);

      return;
    } else if (this.iterator === undefined) {
      this.iterator = this.saga(name, dependencies, confirmationDeclinedCallback);
      this.iterator.next();
    }

    return this.iterator;
  }

  async promisedSend<T extends IBaseCommandDependencies>(name: PromisableCommands, dependencies: T): Promise<void> {
    const isRegisteredCommand = !!registryCommands[name];

    if (isRegisteredCommand) {
      await this.execute(name, dependencies);
    }
  }

  startQueue(): void {
    if (!this.queue) {
      this.queue = new CommandQueue();
    }
  }

  stopQueue(): void {
    this.queue = undefined;
  }

  processQueue(): void {
    if (this.queue) {
      while (!this.queue.isEmpty()) {
        const queueElement = this.queue.dequeue();
        if (queueElement) {
          const { commandInstance } = queueElement;
          commandInstance.reExecute().catch(handleApiError('Servicebus command executution error'));
        }
      }
      this.stopQueue();
    }
  }

  private *saga<T extends IBaseCommandDependencies>(
    name: string,
    dependencies: T,
    confirmationDeclinedCallback?: () => void
  ): Generator<string, string, boolean> {
    let continueExecution = false;

    const command = new registryCommands[name](dependencies);

    if (command === undefined) {
      return 'No command to execute';
    }

    //  Verify whether command is IConfirmableCommand or not
    if (!this.isConfirmableCommand(command)) {
      this.execute(name, dependencies);
    } else {
      this.dispatchEvent({
        type: 'commandNeedsConfirmation',
        command,
        commandName: name
      });

      const yieldInjectedValue = yield 'waiting for command confirmation';
      continueExecution = yieldInjectedValue ? yieldInjectedValue : false;

      if (continueExecution) {
        this.execute(name, dependencies);
      } else {
        confirmationDeclinedCallback?.();
      }
    }

    this.iterator = undefined;

    return 'Command was processed';
  }

  private isConfirmableCommand(target: ICommand): target is IConfirmableCommand {
    return (target as IConfirmableCommand).confirmationModalText !== undefined;
  }

  private execute<T extends IBaseCommandDependencies>(name: string, dependencies: T): Promise<void> {
    const CommandConstructor = registryCommands[name];
    if (!CommandConstructor) {
      return Promise.resolve();
    }

    const command = new CommandConstructor(dependencies);

    let resolveNestedPromise: (() => void) | undefined;
    const nestedPromise = new Promise<void>((resolve, reject) => {
      resolveNestedPromise = resolve;
    });

    command
      .execute()
      .then(
        (): Promise<void> =>
          command
            .postExecute(this)
            .then((): void => {
              this.dispatchApplied(name);
              if (this.queue) {
                this.queue.enqueue({
                  name,
                  commandInstance: command
                });
              }
              resolveNestedPromise?.();
            })
            .catch(handleApiError('Servicebus command post executution error'))
      )
      .catch(handleApiError('Servicebus command executution error'));

    return nestedPromise;
  }

  private dispatchApplied(command: string): void {
    this.dispatchEvent({
      type: 'commandExecuted',
      commandName: command
    });
  }
}
