import { observable } from 'mobx';
import EngineerOfRecord, { type IEngineerOfRecordData } from './EngineerOfRecord';
import PermitPackageAdvancedSettings, { type IPermitPackageAdvancedSettingsData } from './PermitPackageAdvancedSettings';
import PermitPackageFiles from './PermitPackageFiles';
import type { IPermitPackageFilesData } from './PermitPackageFiles';
import PermitPackageOptions, { type IPermitPackageOptionsData } from './PermitPackageOptions';

export interface IPermitPackageDocumentGenerationOptionsData {
  readonly options: IPermitPackageOptionsData;
  readonly includedFiles: IPermitPackageFilesData;
  readonly advancedSettings: IPermitPackageAdvancedSettingsData;
  readonly engineerOfRecord?: IEngineerOfRecordData;
}

export default class PermitPackageDocumentGenerationOptions {
  @observable
  options!: PermitPackageOptions;

  @observable
  includedFiles!: PermitPackageFiles;

  @observable
  advancedSettings!: PermitPackageAdvancedSettings;

  @observable
  engineerOfRecord!: EngineerOfRecord;

  constructor(data: IPermitPackageDocumentGenerationOptionsData, internalReferenceId: string) {
    this.fromData(data, internalReferenceId);
  }

  toData({
    forServer,
  }: {
    forServer: boolean;
  }): IPermitPackageDocumentGenerationOptionsData {
    let engineerOfRecord = this.engineerOfRecord.toData(
      this.advancedSettings.titleBlock.showEngineerOfRecordInfo
    );
    if (forServer) {
      engineerOfRecord = {
        ...engineerOfRecord,
        addressAlt: undefined
      };
    }
    return {
      options: this.options.toData(),
      includedFiles: this.includedFiles.toData(),
      advancedSettings: this.advancedSettings.toData(),
      engineerOfRecord
    };
  }
  fromData(values: IPermitPackageDocumentGenerationOptionsData, internalReferenceId: string): void {
    this.options = new PermitPackageOptions(values.options, internalReferenceId);
    this.includedFiles = new PermitPackageFiles(values.includedFiles);
    this.advancedSettings = new PermitPackageAdvancedSettings(values.advancedSettings);
    this.engineerOfRecord = new EngineerOfRecord(values.engineerOfRecord);
  }
}
