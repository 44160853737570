import type { BaseProps } from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

type Props = BaseProps & {
  width?: string;
  height?: string;
  margin?: string;
};

// Create an HR styled component
const LineHr = styled.hr<Props & PropsTheme<BaseProps>>`
  background-color: ${({ theme }: PropsTheme<BaseProps>): Color => theme.colors.lineHr || ''};
  border: none;
  height: ${({ height = '1px' }: Props): string => height};
  width: ${({ width = '100%' }: Props): string => width};
  margin: ${({ margin = '0px' }: Props): string => margin};
`;
export default LineHr;
