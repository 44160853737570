import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateWindExposureValues extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly windExposureCategory: string;
}

export class UpdateWindExposureValuesCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly windExposureCategory: string;

  constructor(dependencies: IUpdateWindExposureValues) {
    super();
    this.domain = dependencies.domain;
    this.windExposureCategory = dependencies.windExposureCategory;
  }

  async execute(): Promise<void> {
    this.domain.project.designParameters.terrain.windExposureCategory = this.windExposureCategory;
  }
}
