import React, { ReactElement } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';
import { BaseReactProps } from '../../../typings/BaseReactProps';

type LabelColorProps = {
  color?: Color;
};

type LabelProps = BaseReactProps & LabelColorProps & {
  label?: string;
  /**
   * ID of the input with which this label is associated
   */
  name?: string;
};

const LabelBox = styled.label<LabelProps>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme, color }: PropsTheme<LabelProps>): Color => color || theme.colors.grey1};
  margin-bottom: 4px;
`;

const Label = ({
  color,
  label,
  name
}: LabelProps): ReactElement => {
  return (
    <>
    {label &&
      <LabelBox
        color={color}
        {...(name ? {htmlFor: name} : {})}
      >
        {label}
      </LabelBox>
    }
    </>
  );
};

export { Label, LabelProps };
