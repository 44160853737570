import type {
  FormValidation, RJSFSchema
} from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';
import {
  findFormDataGroupContainingField, findFormSchemaField
} from '../effects/util';
import { encodeFieldName } from '../fieldNameUtils';
import type {
  FormData, FormValidationEffect
} from '../FormOptionsRulesAndState';

const lessEqualToField = (
  encodedFieldName: string,
  paramsSpecificToValidationRule: FormValidationEffect,
  globalSchema: RJSFSchema,
  formData: FormData,
  errors: FormValidation
): void => {
  const constraintFieldName = encodeFieldName(paramsSpecificToValidationRule.constraintField);
  const result = findFormSchemaField(
    encodedFieldName, globalSchema
  );
  const [, fieldValue] = findFormDataGroupContainingField(
    encodedFieldName, formData
  );
  const [, constraintValue] = findFormDataGroupContainingField(
    constraintFieldName, formData
  );

  if (fieldValue && constraintValue && result && Number(fieldValue) > Number(constraintValue)) {
    const field = result.properties! as {[key: string]: JSONSchema7};
    const fieldTitle = field[encodedFieldName].title;
    const constraintFieldTitle = field[constraintFieldName].title;
    errors[encodedFieldName]?.addError(`${fieldTitle} must be less than or equal to ${constraintFieldTitle}!`);
  }
};

export default lessEqualToField;
