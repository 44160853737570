import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

type FielStyleProps = {
  inline?: boolean;
  hideLabel?: boolean;
};

export const FieldStyle = styled.fieldset<PropsTheme<FielStyleProps>>`
  & > div {
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
    font-size: 10px;
    font-weight: normal;
    line-height: normal;
    ${({ inline }: PropsTheme<FielStyleProps>): string =>
    inline
      ? `
        display: inline-block;
        &:nth-child(2){
          margin-left: 9px;
          top: 2px;
        }
      `
      : 'margin-bottom: 4px;'}
    text-transform: uppercase;
    display: ${({ hideLabel }: PropsTheme<FielStyleProps>): string => (!hideLabel ? 'none' : '')};
    visibility: ${({ hideLabel }: PropsTheme<FielStyleProps>): string => (!hideLabel ? 'hidden' : 'visible')};
  }
`;
