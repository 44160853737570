import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { ERoofType } from '../../../../../domain/typings';
import { ERoofSlopeType } from '../../../../../domain/typings';
import { useUiStore } from '../../../../../stores/useStore';
import type { RoofTypeUI } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/TypeRoofFaceViewModel';
import type { ButtonOption } from '../../../../../ui/components/ButtonGroup/ButtonGroup';
import {
  BasicPropsBox, RoofIconWrapper, RoofTypeContainer
} from '../../styles';
import Flexbox from '../../../../components/Layout/Flexbox';
import IconMapper from '../../../../components/Icons';
import ButtonGroup from '../../../../../ui/components/ButtonGroup/ButtonGroup';
import { RoofTypeSelectionWrapper } from './styles';

function getRoofTypeOptions(options: RoofTypeUI[]): Option[] {
  return options.map(
    (roofType: RoofTypeUI): ButtonOption => ({
      name: <IconMapper name={roofType.icon} />,
      value: roofType.id,
      caption: roofType.label
    })
  );
}

export const RoofFaceType = observer((): ReactElement => {
  const { roofFaceProps } = useUiStore();
  const typeRoofFaceViewModel = roofFaceProps.typeRoofFaceViewModel!;
  const [editing, setEditing] = useState(false);
  // Effect to handle when change the current roof Selected
  useEffect((): void => {
    setEditing(false);
  }, [roofFaceProps.firstRoofFaceSelected]);

  const editRoofType = useCallback((): void => {
    setEditing(!editing);
  }, [editing]);

  return useObserver((): ReactElement => {
    return (
      <RoofTypeContainer>
        <BasicPropsBox>
          <Flexbox className="box" align="center" justify="center" onClick={editRoofType}>
            <Flexbox dir="column" className="text-box">
              <span className="title-box">Roof Type</span>
              <span className="name-box">{typeRoofFaceViewModel.currentRoofTypeUI.label}</span>
            </Flexbox>
            <RoofIconWrapper>
              <IconMapper name={typeRoofFaceViewModel.currentRoofTypeUI.icon} />
            </RoofIconWrapper>
          </Flexbox>
        </BasicPropsBox>

        {editing && (
          <RoofTypeSelectionWrapper>
            <ButtonGroup
              hideElement={true}
              withBorder={true}
              width="70px"
              height="45px"
              options={getRoofTypeOptions(typeRoofFaceViewModel.ROOF_TYPE_OPTIONS)}
              selected={typeRoofFaceViewModel.currentRoofTypeUI.id}
              onSelect={(value: string | number): void => {
                typeRoofFaceViewModel.changeRoofType(value as ERoofType);
                setEditing(!editing);
                if (roofFaceProps.firstRoofFaceSelected!.slopeType === ERoofSlopeType.LowSlope) {
                  roofFaceProps.firstRoofFaceSelected!.setAzimuthArrowVisibility(false);
                }
              }}
              withCaption={true}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </RoofTypeSelectionWrapper>
        )}
      </RoofTypeContainer>
    );
  });
});
