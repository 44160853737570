import React, { BaseProps, CSSProperties, ReactElement, ReactNode } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from 'typings';
import { getColorTheme } from '../../../components/Theme/ColorThemeProps';
import { EThemeComponentColor } from '../../../components/Theme/EThemeComponentColor';
import { Paragraph } from '../../FontFamily/Fonts';
import { Icon } from '../../Icon/Icon';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

type CheckboxContainerProps = {
  size: number;
  customizableSize: boolean;
};

function setContainerSize(
  {size, customizableSize
  }: CheckboxContainerProps
): string {
  return customizableSize ? `height: ${size}px` : ``;
}

interface ICheckboxProps {
  size: number;
  checked: boolean;
  indeterminate?: boolean;
  colorTheme: EThemeComponentColor;
}

const StyledCheckbox = styled.span<ICheckboxProps>`
  position: absolute;
  width: ${(props: PropsTheme<ICheckboxProps>): number => props.size}px;
  height: ${(props: PropsTheme<ICheckboxProps>): number => props.size}px;
  background: ${
    ({checked, colorTheme, theme}: PropsTheme<ICheckboxProps>): Color =>
    (checked ? getColorTheme({colorTheme, theme}) : theme.colors.whiteBg)};
  border-radius: 3px;
  transition: all 150ms;
  border: solid 1px ${
    ({checked, colorTheme, theme}: PropsTheme<ICheckboxProps>): Color =>
      checked ? getColorTheme({colorTheme, theme}) : theme.colors.grayColor1};
  svg {
    position: absolute;
    top: 3px;
    left: 2px;
    visibility: ${(props: PropsTheme<ICheckboxProps>): string => props.checked ? 'visible' : 'hidden'};
  }
`;

type ArrowButtonType = {
  checked: boolean;
  theme: PropsTheme & { activeColour: Color; grayColor6: Color};
};

const ArrowButton = styled.span<ArrowButtonType & { checked: boolean }>`
  position: absolute;
  left: 36px;
  path {
    fill: ${(props: ArrowButtonType): Color => props.checked ? props.theme.activeColour : props.theme.grayColor6};
  }
`;

const CheckboxContainer = styled.label<CheckboxContainerProps>`
  ${setContainerSize};
  display: inline-flex;
  position: relative;
  align-items: center;
  &::before {
    content: '';
    vertical-align: middle;
    height: 100%;
  }
`;

const CheckboxText = styled(Paragraph)`
  margin-left: 25px;
  line-height: 1.5;
`;

export interface IProps extends BaseProps {
  size?: number;
  checked: boolean;
  value?: string | number;
  text?: ReactNode;
  containerStyle?: CSSProperties;
  disabled?: boolean;
  inputProps?: object;
  indeterminate?: boolean;
  showArrow?: boolean;
  customizableSize?: boolean;
  colorTheme?: EThemeComponentColor;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onArrowClick?: (event: React.MouseEvent) => void;
  className?: string;
}

const Checkbox = ({
  customizableSize = true,
  size = 16,
  colorTheme = EThemeComponentColor.BLUE,
  checked,
  value,
  text,
  className,
  containerStyle,
  disabled,
  indeterminate,
  inputProps,
  showArrow,
  onChange,
  onArrowClick
}: IProps): ReactElement => (
  <CheckboxContainer
    size={size}
    customizableSize={customizableSize}
    style={containerStyle}
    className={className}
  >
    <HiddenCheckbox
      checked={checked}
      value={value}
      disabled={disabled}
      onChange={onChange}
      {...inputProps}
    />
    <StyledCheckbox
      size={size}
      indeterminate={indeterminate}
      checked={checked}
      colorTheme={colorTheme}
    >
      {indeterminate && checked && <Icon name="check-white" />}
    </StyledCheckbox>

    {showArrow && (
      <ArrowButton
        checked={checked}
        data-testid="checkbox-arrow-button"
        onClick={(event: React.MouseEvent): void => {
          event.preventDefault();
          if (onArrowClick) {
            onArrowClick(event);
          }
        }}
      >
        <Icon name="downVector" />
      </ArrowButton>
    )}
    <CheckboxText>{text}</CheckboxText>
  </CheckboxContainer>
);

export { Checkbox };
