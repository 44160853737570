import { BaseProps } from 'react';
import styled from 'styled-components';

type SnappingContainerProps = BaseProps & {
  auroraMode?: boolean;
};

export const SnappingContainer = styled.div<SnappingContainerProps>`
  display: flex;
  justify-content: ${({auroraMode}: SnappingContainerProps): string => auroraMode ? 'space-between' : 'center'};
  align-items: center;
`;

export const StepperButtons = styled.div`
  display: flex;
`;

export const SnappingWidth = styled.div`
  margin: 0 auto;
  width: 800px;
`;
