import type Limit from '../../domain/models/Limit';

// FIXME: update if higher zoom levels will be supported
export const MAXIMUM_ZOOM_LEVEL = 21;

// Cursors
export const DEFAULT_CURSOR = 'default';
export const SELECTION_CURSOR = 'default';
export const PANNING_CURSOR = 'grab';
export const FIRST_TRACE_CURSOR = 'first-trace.svg';
export const TRACE_CURSOR = 'trace.svg';
export const FORBIDDEN_CURSOR = 'forbidden.svg';

export const ZOOM_LIMIT: Limit = {
  lower: 1,
  upper: 15
};

export const MOUSE_BUTTON = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2
};

export const TEXT_HEIGHT = 0.1;
