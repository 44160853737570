interface IPriceCorrection {
  readonly description: string;
  readonly amount: number;
}

interface IOrderProduct {
  readonly title: string;
  readonly price: {
    readonly amount: number;
    readonly description: string;
    readonly corrections?: IPriceCorrection[];
  };
}

export enum EPaymentType {
  PAID = 'PAID',
  CREDIT = 'CREDIT',
  CHARGE = 'CHARGE'
}

export interface IPaymentStructure {
  readonly type: EPaymentType;
  /**
   * In USD
   */
  readonly amount: number;
}

export interface ILyraOrderPreview {
  readonly product: IOrderProduct;
  readonly order: {
    readonly total: number;
  };
  readonly paymentStructure: IPaymentStructure[];
}

interface IExternalOrderPreview {
  readonly requiresPayment: boolean;
  readonly paymentUrl?: string;
}

type IOrderPreview = ILyraOrderPreview | IExternalOrderPreview;

export default IOrderPreview;

export function isLyraOrder(order: IOrderPreview): order is ILyraOrderPreview {
  return (order as ILyraOrderPreview).paymentStructure !== undefined;
}

export function isExternalOrder(order: IOrderPreview): order is IExternalOrderPreview {
  return (order as IExternalOrderPreview).requiresPayment !== undefined;
}
