import { observable } from 'mobx';
import type Store from '../../Store';
import type {
  BaseViewModel, IBaseViewModelDependencies
} from './BaseViewModel';
import { ProjectPropertiesViewModel } from './ViewModels/ProjectPropertiesModal/ProjectPropertiesViewModel';

export class ModalStore {
  @observable
  modalName: string = '';

  @observable
  viewModel: BaseViewModel | undefined;

  private dependencies: IBaseViewModelDependencies;

  constructor(root: Store) {
    this.dependencies = {
      modal: this,
      domain: root.domain,
      editor: root.editor
    };
  }

  createModal(modalName: string, modal: BaseViewModel): void {
    this.modalName = modalName;
    this.viewModel = modal;
  }

  createModalBasedOnViewModalCode(modalCode: string): void {
    this.modalName = modalCode;
    this.viewModel = this.createViewModelBasedOnModalCode(modalCode);
  }

  closeModal(): void {
    this.viewModel = undefined;
  }

  private createViewModelBasedOnModalCode(modalCode: string): BaseViewModel | undefined {
    switch (modalCode) {
    case 'project_properties_modal': {
      return new ProjectPropertiesViewModel(this.dependencies);
    }
    default: {
      throw new Error(`Unsupported modal code: ${modalCode}`);
    }
    }
  }
}
