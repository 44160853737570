import { observer } from 'mobx-react-lite';
import {
  useContext, type ReactElement
} from 'react';
import React from 'react';
import type { PropsTheme } from 'styled-components';
import styled, { ThemeContext } from 'styled-components';
import type { Color } from '@aurorasolar/lyra-ui-kit';
import type { FormikProps } from 'formik';
import type { IAddressData } from '../../../../../domain/models/SiteDesign/Address';
import type { AutoCompleteValue } from '../../../../components/DSAddressAutocomplete/DSAddressAutocomplete';
import { DSAddressAutoComplete } from '../../../../components/DSAddressAutocomplete/DSAddressAutocomplete';
import type { IPermitPackageDocumentGenerationOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import type { IDesignFileData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import type { PlanSetCustomizationPageViewModel } from './PlanSetCustomizationPageViewModel';

const watermarkOptions = [
  {
    label: 'None',
    value: 'NONE'
  },
  {
    label: 'NFC',
    value: 'NOT_FOR_CONSTRUCTION'
  },
  {
    label: 'FQO',
    value: 'FOR_QUOTING_ONLY'
  },
  {
    label: 'Draft',
    value: 'DRAFT_PRINT'
  },
  {
    label: 'Prel.',
    value: 'PRELIMINARY'
  }
];

type GeneralTabProperties = {
  formikbag: FormikProps<IPermitPackageDocumentGenerationOptionsData>;
  viewModel: PlanSetCustomizationPageViewModel;
};

const ErrorWrapper = styled.div<PropsTheme>`
  margin: 8px 0;
  color: ${({ theme }: PropsTheme): Color => theme.colors.red};
  font-size: 13px;
`;

export const PlanSetCustomizationGeneralTab = observer((properties: GeneralTabProperties): ReactElement => {
  const {
    formikbag, viewModel
  } = properties;
  const DS = useContext(ThemeContext)!.DS;

  return (
    <>
      <DS.Grid>
        <DS.GridItem size="third">
          <DS.Grid rowGap={4}>
            <DS.Flex flexDirection="row" alignItems="center" gap={2} mb={2}>
              <DS.IconGear size={4} />
              <DS.Text text="h3">Options</DS.Text>
            </DS.Flex>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.TextInput}
                name="options.projectReferenceId"
                label="Project reference ID"
                placeholder="Enter project reference ID"
                size="sm"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.TextInput}
                name="options.reviewerFullName"
                label="Reviewer full name"
                placeholder="Enter reviewer full name"
                size="sm"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.DropdownInput}
                name="options.watermarks"
                label="Watermark"
                options={watermarkOptions}
                size="sm"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.ToggleInput}
                name="options.includeSourceCadFiles"
                description="DWG only includes electrical diagram"
                label="Include DWG"
                size="sm"
              />
            </DS.GridItem>
          </DS.Grid>
        </DS.GridItem>
        <DS.GridItem size="third">
          <DS.Grid rowGap={4}>
            <DS.GridItem size="full">
              <DS.Flex flexDirection="row" alignItems="center" gap={2} mb={2}>
                <DS.IconUser size={4} />
                <DS.Text text="h3">Engineer of record</DS.Text>
              </DS.Flex>
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.TextInput}
                name="engineerOfRecord.name"
                label="Engineer name"
                placeholder="Enter engineer full name"
                size="sm"
              />
            </DS.GridItem>
            {(formikbag.errors as { 'engineerOfRecord.name': string })['engineerOfRecord.name'] && (
              <DS.GridItem size="full">
                <ErrorWrapper>
                  {(formikbag.errors as { 'engineerOfRecord.name': string })['engineerOfRecord.name']}
                </ErrorWrapper>
              </DS.GridItem>
            )}
            <DS.GridItem size="full">
              <DS.Field
                component={DS.TextInput}
                name="engineerOfRecord.licenseNumber"
                label="Engineer license number"
                placeholder="Enter license number"
                size="sm"
              />{' '}
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                /* Using addressAlt for formik to track values returned from autocomplete component */
                name="engineerOfRecord.addressAlt"
                component={DSAddressAutoComplete}
                label="Engineer address"
                size="sm"
                onSelect={(addressData: IAddressData, autoCompleteValue: AutoCompleteValue) => {
                  formikbag.setFieldValue('engineerOfRecord.address', addressData);
                  formikbag.setFieldValue('engineerOfRecord.addressAlt', autoCompleteValue);
                }}
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.PhoneInput}
                name="engineerOfRecord.phoneNumber"
                label="Engineer phone number"
                size="sm"
              />
            </DS.GridItem>
          </DS.Grid>
        </DS.GridItem>

        <DS.GridItem size="third">
          <DS.Flex flexDirection="row" alignItems="center" gap={2} mb={6}>
            <DS.IconPaperLines size={4} />
            <DS.Text text="h3">Design files</DS.Text>
          </DS.Flex>
          <DS.Field
            component={DS.FilePickerInput}
            name="includedFiles.designFilesAlt"
            label={
              <DS.Text text="h6" color="gray-700">
                If this project requires drawings or notes that are not already in our plan set, upload them here.
              </DS.Text>
            }
            size="sm"
            inputProps={{
              handleChange: (files: (typeof DS.IFilePickerEntity)[]): unknown => {
                (async (): Promise<void> => {
                  for (const file of files) {
                    await viewModel.saveDesignFile(file.file!);
                  }
                })();
                return files;
              },
              multiple: true,
              accept: 'application/pdf,text/pdf',
              entityList: false,
              renderCta: (_: never) => 'Upload'
            }}
          />
          <DS.Flex gap={0}>
            {(viewModel.documentGenerationOptions?.includedFiles.designFiles ?? []).map(
              (entity: IDesignFileData, index) => (
                <DS.Flex key={index} lgap={2} alignItems="center" flexDirection="row" text="body14">
                  <DS.FileEntityIcon mime={entity.type} size={5} />
                  <DS.Text ellipse flexGrow={0} flexBasis="auto" text="inherit" color="uiHeadingCopy" fontWeight={500}>
                    {entity.title}
                  </DS.Text>
                  <DS.ButtonGroup ml="auto" variant="tertiary">
                    <DS.Button
                      data-subcomponent="file-picker-entity-remove-button"
                      action={() => viewModel.deleteFile(entity.id)}
                      key="remove"
                      icon={DS.IconClose}
                      title={`Remove ${entity.title}`}
                    />
                  </DS.ButtonGroup>
                </DS.Flex>
              )
            )}
          </DS.Flex>
        </DS.GridItem>
      </DS.Grid>
      {(formikbag.errors as { general: string }).general && (
        <ErrorWrapper>{(formikbag.errors as { general: string }).general}</ErrorWrapper>
      )}
    </>
  );
});
