import React, { BaseProps, ReactElement } from 'react';
import { Icon } from '../Icon';
import { EModalType } from './EModalType';
import { Divider, HeaderStyle as Container, Separator } from './styles';

export type HeaderProps = BaseProps & {
  color?: string | EModalType;
  showHeader?: boolean;
  title?: string;
  steps?: number;
  currentStep?: number;
  showHeaderDivider?: boolean;
  showCloseButton?: boolean;
  onClickCloseButton?: () => void;
};

const Header = (props: HeaderProps): ReactElement => {
  const {
    color,
    showHeader = true,
    title = '',
    steps,
    currentStep,
    showHeaderDivider = true,
    showCloseButton = true,
    onClickCloseButton
  } = props;

  return showHeader ? (
    <Container color={color}>
      <h4>{title}</h4>
      <Separator />
      {steps && currentStep && (
        <span>
          Step <strong>{currentStep}</strong> of {steps}
        </span>
      )}
      {showHeaderDivider && (
        <Divider className="ml-xs mr-xs" direction="vertical" length="20px" />
      )}
      {showCloseButton && (
        <span
          data-testid="modal-close-button"
          onClick={onClickCloseButton}
        >
          <Icon name="close-icon" />
        </span>
      )}
    </Container>
  ) : (
    <></>
  );
};

export { Header };
