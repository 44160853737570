import type DomainStore from '../../DomainStore/DomainStore';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Marker } from '../../../domain/models/SiteDesign/Marker';
import { SceneObjectType } from '../../../domain/models/Constants';
import type { IConfirmableCommand } from './Command';
import { BaseCommand } from './Command';

export interface IAddSiteEquipmentDependencies extends IBaseCommandDependencies {
  readonly editor: EditorStore;
  readonly domain: DomainStore;
  readonly equipment: Marker;
  readonly confirmationModalText?: string;
}

export class AddSiteEquipmentCommand extends BaseCommand implements IConfirmableCommand {
  readonly confirmationModalText?: string;
  private readonly editor: EditorStore;
  private readonly domain: DomainStore;
  private readonly equipment: Marker;

  constructor(dependencies: IAddSiteEquipmentDependencies) {
    super();
    this.editor = dependencies.editor;
    this.domain = dependencies.domain;
    this.equipment = dependencies.equipment;
    this.confirmationModalText = dependencies.confirmationModalText;
  }

  async execute(): Promise<void> {
    const mainServicePanel = this.domain.project?.site?.equipment?.mainServicePanel;

    if (this.equipment.type === SceneObjectType.UtilityMeter && mainServicePanel?.meterSocket) {
      mainServicePanel.meterSocket = false;
      mainServicePanel.utilityMeter = undefined;
      this.domain.addOrUpdateSiteEquipment(mainServicePanel);
    }

    this.domain.addOrUpdateSiteEquipment(this.equipment);
    this.editor.addOrUpdateObject(this.equipment.mesh);
  }
}
