import type { Color } from '@aurorasolar/lyra-ui-kit';
import type { BufferAttribute } from 'three';
import {
  CircleGeometry, DoubleSide, EdgesGeometry, Mesh, MeshBasicMaterial, Vector3
} from 'three';
import { Line2 as Line } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import type { IDrawable } from '../../mixins/Drawable';
import { LayerCanvas } from '../LayerCanvas';
import { PolygonBufferGeometry } from '../PolygonBufferGeometry';
import { Unzoomable } from '../../mixins/Unzoomable';
import { Drawable } from '../../mixins/Drawable';

const MixedClass = Unzoomable(Drawable(class SimpleClass {}));

export enum StringDotKind {
  BEGIN = 'BEGIN',
  END = 'END'
}

export class StringingDot extends MixedClass implements IDrawable {
  override color: Color = '#FFFFFF';

  private zoomFactor: number = 1;
  private defaultThickness: number = 3;

  private kind: StringDotKind;
  private background?: Mesh;
  private lineBorder?: Line;
  private objectMaterial: MeshBasicMaterial;

  constructor(kind: StringDotKind) {
    super();

    this.objectMaterial = new MeshBasicMaterial({
      color: this.color,
      side: DoubleSide,
      ...LayerCanvas.FOREGROUND
    });

    this.kind = kind;
  }

  unzoom(factor: number): void {
    this.zoomFactor = factor;
  }

  changePosition(position: Vector3): void {
    this.mesh.position.set(position.x, position.y, position.z);
  }

  changePositionAndSelect(position: Vector3): void {
    this.changePosition(position);
    this.select();
  }

  select(): void {
    this.mesh.remove(this.background!);
    this.mesh.remove(this.lineBorder!);

    this.addDot();
  }

  unselect(): void {
    this.mesh.remove(this.background!);
    this.mesh.remove(this.lineBorder!);

    this.addBox();
  }

  private addDot(): void {
    let radioDot: number = 3;

    const sphereGeometry = new CircleGeometry(radioDot, 60);
    this.background = new Mesh(sphereGeometry, this.objectMaterial);
    this.mesh.add(this.background);

    const edges = new EdgesGeometry(this.background.geometry);
    const vertices: Float32Array = (edges.getAttribute('position') as BufferAttribute).array as Float32Array;
    this.addBorder(vertices);
  }

  private addBox(): void {
    const boxGeometry = new PolygonBufferGeometry();
    const vertexList: Vector3[] = [
      new Vector3(2, 1, 0),
      new Vector3(2, -1, 0),
      new Vector3(-2, -1, 0),
      new Vector3(-2, 1, 0),
      new Vector3(2, 1, 0)
    ];
    boxGeometry.update(vertexList);
    this.background = new Mesh(boxGeometry, this.objectMaterial);
    this.mesh.add(this.background);
  }

  private addBorder(vertices: Float32Array): void {
    const geometry = new LineGeometry();
    geometry.setPositions(vertices);

    const matLine = new LineMaterial({
      color: 0x000000,
      linewidth: 0.001,
      vertexColors: false,
      dashed: false
    });

    this.lineBorder = new Line(geometry, matLine);
    this.lineBorder.computeLineDistances();
    this.mesh.add(this.lineBorder);
  }
}
