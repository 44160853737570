import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { ArrayPlacementStage } from '../../../domain/stages/DesignStages/ArrayPlacementStage';
import { sectionTitle } from './SectionTitle';
import { ArrayPlacementCard } from './styles';

type MountingSystemDefinitionsCardProps = {
  arrayPlacementStage: ArrayPlacementStage;
  editable?: boolean;
};

export const MountingSystemDefinitionsCard = observer((props: MountingSystemDefinitionsCardProps): ReactElement => {
  const {
    arrayPlacementStage, editable
  } = props;
  const definitions = arrayPlacementStage.domainModel.system.equipment.mountingSystems.definitions;

  const handleEditClick = useCallback((): void => {
    arrayPlacementStage?.editMountingSystemDefinitions();
  }, [arrayPlacementStage]);

  return (
    <ArrayPlacementCard title={''} onEditClick={handleEditClick} editable={editable}>
      {definitions.isEmpty && sectionTitle('Choose a mounting system')}
      {definitions.hasSteepSlopeDefinition && (
        <>
          {sectionTitle('steep-slope mounting system')}
          <LyraTypography.Heading type="h5" fontWeight="bold" margin={'5px 0px 0px'}>
            {definitions.steepSlope!.name}
          </LyraTypography.Heading>
          <LyraTypography.TextLayout margin={'1px 0px'} lineHeight={1} type="h6">
            {definitions.steepSlope!.columnSpacingLabel}
          </LyraTypography.TextLayout>
        </>
      )}
      {definitions.hasSteepSlopeDefinition && definitions.hasLowSlopeDefinition && <br />}
      {definitions.hasLowSlopeDefinition && (
        <>
          {sectionTitle('low-slope mounting system')}
          <LyraTypography.Heading type="h5" fontWeight="bold" margin={'5px 0px 0px'}>
            {definitions.lowSlope!.name}
          </LyraTypography.Heading>
          <LyraTypography.TextLayout margin={'1px 0px'} lineHeight={1} type="h6">
            {definitions.lowSlope!.defaultConfiguration!.tiltAngle}° Tilt Angle
          </LyraTypography.TextLayout>
          <LyraTypography.TextLayout margin={'1px 0px'} lineHeight={1} type="h6">
            {`${definitions.lowSlope!.defaultConfiguration!.numberOfRowsInRack} `} Rows
          </LyraTypography.TextLayout>
          <LyraTypography.TextLayout margin={'1px 0px'} lineHeight={1} type="h6">
            {definitions.lowSlope!.columnSpacingLabel}
          </LyraTypography.TextLayout>
        </>
      )}
    </ArrayPlacementCard>
  );
});
