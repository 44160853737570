import {
  action, computed, observable, runInAction
} from 'mobx';
import type {
  Object3D, Vector3
} from 'three';
import {
  handleApiError, notify
} from '../../../utils/helpers';
import { SentryException } from '../../../utils/sentryLog';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type {
  IControlDragging,
  IControlSelectionChange,
  IPointerDblClickControlEvent,
  IPointerDownControlEvent,
  IPointerHoveringControlEvent,
  IPointerUpControlEvent
} from '../../../stores/EditorStore/Controls/ControlEvents';
import type { ModalStore } from '../../../stores/UiStore/Modal/Modal';
import type { PanelsStore } from '../../../stores/UiStore/Panels/Panels';
import type {
  EquipmentPanelViewModel,
  IEquipmentPanelViewModelDependencies
} from '../../../stores/UiStore/Panels/ViewModels/EquipmentPanel/EquipmentPanelViewModel';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type {
  IHandleHoverTool,
  IHandleDragTool,
  IHandleClicksTool,
  IHandleSelectionTool
} from '../../../stores/UiStore/ToolbarStore/Tool';
import {
  ADD_MODULE_ID,
  CHANGE_ORIENTATION_ID,
  PANNING_TOOL_ID,
  REMOVE_MODULE_ID,
  REVIEW_CIRCUITS_ID,
  SELECT_TOOL_ID,
  STRINGING_ID
} from '../../../stores/UiStore/ToolbarStore/Design/constants';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { Design } from '../../models/Design/Design';
import { DesignStep } from '../../models/Design/DesignState';
import type { IFormData } from '../../entities/Form/FormData';
import { ElectricalEquipmentMarker } from '../../models/Design/ElectricalEquipmentMarker';
import { DesignReadiness } from '../../models/SiteDesign/DesignReadiness';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import { DocumentsService } from '../../../infrastructure/services/api/DocumentsService';
import type { Selectable } from '../../mixins/Selectable';
import {
  CONFIRMATION, SceneObjectType
} from '../../models/Constants';
import type { IUpdatedSiteFeatureLocationData } from '../../entities/SitePlan/UpdatedSitePlan';
import { UpdatedSiteFeatureLocation } from '../../entities/SitePlan/UpdatedSitePlan';
import { isCurrentWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import type { ICircuitConnectionsData } from '../../entities/CircuitConnection/CircuitConnectionsOptions';
import { DragBehaviour } from '../../behaviour/DragBehaviour';
import { HoverBehaviour } from '../../behaviour/HoverBehaviour';
import { MouseBehaviour } from '../../behaviour/MouseBehaviour';
import { SelectionBehaviour } from '../../behaviour/SelectionBehaviour';
import {
  ElectricalBosFormSubmittedEvent,
  ElectricalBosSitePlanSubmittedEvent
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import type { IFormOptionsRulesAndStateData } from '../../entities/Form/FormOptionsRulesAndState';
import type { IElectricalEquipmentData } from '../../entities/SitePlan/SitePlan';
import config, { UI_MODE } from '../../../config/config';
import type { CircuitDataType } from '../../../ui/containers/Pages/viewModels/CircuitTable/CircuitTableViewModel';
import { CircuitTableViewModel } from '../../../ui/containers/Pages/viewModels/CircuitTable/CircuitTableViewModel';
import {
  importProjectAndDesignIntoDocumentService,
  updateDesignState,
  updateEquipmentPlacedFlagInDesignStateTo
} from './interMicroServiceHelpers';

export interface IElectricalBosDependencies {
  editor: EditorStore;
  domain: DomainStore;
  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;
  toolbar: ToolbarStore;
  modal: ModalStore;
  panels: PanelsStore;
  guidelines: SmartGuidesStore;
}

export class ElectricalBosStage
implements IProgressStepperStage, IHandleSelectionTool, IHandleClicksTool, IHandleHoverTool, IHandleDragTool {
  static readonly toolBlacklist: string[] = [ADD_MODULE_ID, CHANGE_ORIENTATION_ID, REMOVE_MODULE_ID, STRINGING_ID];
  static readonly toolWhitelist: string[] = [SELECT_TOOL_ID, PANNING_TOOL_ID, REVIEW_CIRCUITS_ID];

  @observable
  showCircuitTableOrGenerateLocationsInProgress: boolean = false;

  @observable
  circuitConnectionsData?: ICircuitConnectionsData;

  @computed
  get isReviewCircuitsButtonDisabled(): boolean {
    return !this.isEquipmentPlaced || this.showCircuitTableOrGenerateLocationsInProgress || !this.formSpecification;
  }

  @computed
  get isEquipmentPlaced(): boolean {
    return (this.panels.viewModel as EquipmentPanelViewModel)?.equipmentNotPlaced?.length === 0;
  }

  @computed
  get isEquipmentPanelDisplayed(): boolean {
    return !!this.panels.viewModel;
  }

  private get movableMarkers(): Selectable[] {
    return [
      ...this.editor
        .getObjectsByCondition((obj: Object3D): boolean => this.movableMarkerTypes.includes(obj.type))
        .map((obj: Object3D): Selectable => obj.userData?.lyraModel),
      ...this.editor
        .getObjectsByCondition(
          (obj: Object3D): boolean =>
            obj.userData?.lyraModel?.type && this.movableMarkerTypes.includes(obj.userData.lyraModel.type)
        )
        .map((obj: Object3D): Selectable => obj.userData?.lyraModel)
    ];
  }

  readonly propCodeUI: string = PropsPanelUICodes.ElectricalBos;
  readonly title: string = `Electrical${config.featureFlag.uiMode !== UI_MODE.AURORA ? ' BOS' : ''}`;
  readonly id: DesignStep = DesignStep.ELECTRICAL_BOS;

  @observable
  formSpecification: IFormOptionsRulesAndStateData | undefined;

  private hasMissingProperties: boolean = false;
  private electricalEquipment: IElectricalEquipmentData[];

  private readonly domain: DomainStore;
  private readonly editor: EditorStore;
  private readonly designWorkspace: DesignWorkspace;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly modal: ModalStore;
  private readonly panels: PanelsStore;
  private readonly designService = new DesignService();
  private readonly documentsService = new DocumentsService();
  private readonly selectionBehaviour: SelectionBehaviour;
  private readonly mouseBehaviour: MouseBehaviour;
  private readonly hoverBehaviour: HoverBehaviour;
  private readonly dragBehaviour: DragBehaviour;
  private readonly movableMarkerTypes: string[] = [SceneObjectType.ElectricalEquipmentMarker];
  @observable
  canShowEquipmentPanel: boolean = false;

  constructor(dependencies: IElectricalBosDependencies) {
    const {
      designWorkspace, domain, editor, serviceBus, toolbar, modal, panels, guidelines
    } = dependencies;

    this.designWorkspace = designWorkspace;
    this.domain = domain;
    this.editor = editor;
    this.serviceBus = serviceBus;
    this.toolbar = toolbar;
    this.modal = modal;
    this.panels = panels;
    this.electricalEquipment = [];
    this.dragBehaviour = new DragBehaviour(this.editor, guidelines);
    this.selectionBehaviour = new SelectionBehaviour(this.editor);
    this.mouseBehaviour = new MouseBehaviour(this.editor);
    this.hoverBehaviour = new HoverBehaviour(this.editor);
  }

  @computed
  get domainModel(): Design {
    return this.domain.design;
  }

  setUp = async (): Promise<void> => {
    this.updateUserState();
    await this.reimportDesignIfNeededAndResumeLocations(true);

    if (isCurrentWorkspace('design')) {
      this.enableTools();
      this.enableBehaviors();
      this.toolbar.activateToolInDesignWorkspaceWithoutClick(SELECT_TOOL_ID, this);
      this.editor.renderSiteMarkers(this.designWorkspace);
    }
  };

  setUpTool = (toolId?: string): void => {
    this.enableBehaviors(toolId);
  };

  disposeEvents(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
    this.hoverBehaviour.removeHoverEvents(this);
    this.selectionBehaviour.removeSelectionChangeEvent(this);
  }

  get canContinue(): boolean {
    return !this.hasMissingProperties;
  }

  async persistEquipmentLocations(): Promise<void> {
    try {
      this.showCircuitTableOrGenerateLocationsInProgress = true;
      const equipmentPanelViewModel = this.panels.viewModel as EquipmentPanelViewModel;
      const designId = this.domain.design.id;
      const data: IUpdatedSiteFeatureLocationData[] = equipmentPanelViewModel.equipmentPlaced.map(
        (item: IElectricalEquipmentData): IUpdatedSiteFeatureLocationData => new UpdatedSiteFeatureLocation(item)
      );
      await this.documentsService
        .updateSitePlan(designId, {
          electricalEquipment: data
        })
        .catch(handleApiError());
      config.analytics?.trackEvent(new ElectricalBosSitePlanSubmittedEvent(this.domain));
    } finally {
      this.showCircuitTableOrGenerateLocationsInProgress = false;
    }
  }

  async continue(): Promise<void> {
    this.resetStageUI();
  }

  cancel(): void {
    this.dispose();
  }

  dispose(): void {
    this.clearElectricalEquipmentMarkers();
    this.resetStageUI();
  }

  /**
   * @returns `false` if:
   * - It wasn't possible to synchronize design and/or project with backend
   * - Something went wrong when fetching BOS options
   *
   * Otherwise, @returns `true`
   */
  @action.bound
  async updateProjectAndDesignInDocumentsService(): Promise<boolean> {
    this.formSpecification = undefined;

    try {
      await importProjectAndDesignIntoDocumentService();
      await this.documentsService
        .getElectricalBosOptions(this.domain.design.id)
        .then((formResponse: IFormOptionsRulesAndStateData): void => {
          runInAction((): void => {
            this.formSpecification = formResponse;
          });
        })
        .catch(handleApiError('Failed to get electrical BOS'));
      return true;
    } catch (error) {
      SentryException('Error importing project or design', error);
      return false;
    }
  }

  @action.bound
  async generateLocations(formData: IFormData): Promise<void> {
    this.showCircuitTableOrGenerateLocationsInProgress = true;
    if (this.formSpecification) {
      this.formSpecification.data = formData;
    }
    try {
      const designId = this.domain.design.id;
      await this.documentsService
        .saveElectricalBos(designId, formData)
        .catch(handleApiError('Failed to save electrical BOS'));
      this.updateDataState();
      const { electricalEquipment } = await this.documentsService
        .getSitePlan(designId)
        .catch(handleApiError('Failed to generate equipment locations'));
      if (isCurrentWorkspace('design')) {
        this.updateElectricalEquipment(electricalEquipment);
        updateEquipmentPlacedFlagInDesignStateTo(true);
        notify('Equipment successfully generated', CONFIRMATION);
      }
      config.analytics?.trackEvent(new ElectricalBosFormSubmittedEvent(this.domain));
    } catch (error) {
      SentryException('Error generating locations', error);
    } finally {
      this.showCircuitTableOrGenerateLocationsInProgress = false;
    }
  }

  onSelectionChange = (event: IControlSelectionChange): void => {
    const { selection } = event;
    if (selection === undefined || event.unselected === undefined) {
      return;
    }
    const equipmentPanel = this.panels.viewModel as EquipmentPanelViewModel;
    selection.forEach((selectedObject: Selectable): void => {
      if (selectedObject instanceof ElectricalEquipmentMarker) {
        selectedObject.setSelectedMode(true);
        equipmentPanel.setSelectedMarker(selectedObject.electricalEquipment);
      }
    });
    event.unselected.forEach((unselectedObject: Selectable): void => {
      unselectedObject.setSelectedMode(false);
    });
  };

  onMouseDown = (event: IPointerDownControlEvent): void => {
    // Not implemented yet
  };

  onMouseUp = (event: IPointerUpControlEvent): void => {
    const {
      target, pointerEnd
    } = event;
    // We don't want to execute any code if event properties missing;
    if (!target || !pointerEnd) {
      return;
    }
    const equipmentPanel = this.panels.viewModel as EquipmentPanelViewModel;
    if (!equipmentPanel?.selectedEquipment) {
      return;
    }

    if (!equipmentPanel.selectedEquipment.marker) {
      const equipment = equipmentPanel.createEquipmentMarker(equipmentPanel.selectedEquipment);
      // We don't want to execute any code if we failed to create equipment instance;
      if (!equipment || equipment.hasChildren) {
        return;
      }

      // We can reach this part of code only in case if we are missing location for certain electrical equipment.
      // So we ask user to place marker manually.
      const coordinates: Vector3 = target.unprojectMouseToFrustum(pointerEnd);
      equipment.draw(coordinates);
      this.editor.addOrUpdateObject(equipment.mesh);
      equipmentPanel.placeEquipment();
      equipmentPanel.selectedEquipment = undefined;
      this.updateTargetObjects();
    }
  };

  onMouseDblClick = (event: IPointerDblClickControlEvent): void => {
    // Not implemented yet
  };

  showCircuitTableModal(populateForm: CircuitDataType, designId: string): void {
    this.modal.createModal(
      'circuit_table_modal',
      new CircuitTableViewModel({
        domain: this.domain,
        designWorkspace: this.designWorkspace,
        modal: this.modal,
        designId,
        data: populateForm,
        editor: this.editor
      })
    );
  }

  // This method should be used only in Lyra design
  async openReviewCircuitsAndProceedToMountingModal(): Promise<void> {
    await this.persistEquipmentLocations();

    this.showCircuitTableOrGenerateLocationsInProgress = true;
    const designId = this.domain.design.id;
    try {
      this.updateDataState();
      this.circuitConnectionsData = await this.documentsService
        .getCircuitConnections(designId)
        .catch(handleApiError('Failed to get circuit connections'));

      this.showCircuitTableModal(
        {
          circuitConnections: this.circuitConnectionsData.circuitConnections,
          circuitVoltageDrops: [...this.circuitConnectionsData.circuitVoltageDrops]
        },
        designId
      );
    } finally {
      this.showCircuitTableOrGenerateLocationsInProgress = false;
    }
  }

  async resume(lastValidStage: string): Promise<void> {
    const isLastValidStage: boolean = lastValidStage === this.id;
    await this.reimportDesignIfNeededAndResumeLocations(isLastValidStage);

    if (isLastValidStage && isCurrentWorkspace('design')) {
      this.enableTools();
      this.enableBehaviors();
      this.toolbar.activateToolInDesignWorkspaceWithoutClick(SELECT_TOOL_ID, this);
    }
  }

  onChangeBosFormValue = (formData: IFormData): void => {
    if (!this.formSpecification) {
      return;
    }
    this.canShowEquipmentPanel = false;
    this.panels.destroyPanel();
    this.clearElectricalEquipmentMarkers();
  };

  async beforeContinue(continueBackwards: boolean = false): Promise<void> {
    if (config.featureFlag.uiMode === UI_MODE.AURORA) {
      await this.persistEquipmentLocations();
    }
    if (!continueBackwards) {
      await this.validateMissingProperties();
    }
  }
  async validateMissingProperties(): Promise<void> {
    let nextStep = this.designWorkspace.stageManager!.currentIndex + 1;
    let nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);

    if (nextStage?.id === DesignStep.CIRCUIT_TABLE) {
      // Circuit Table is FE-only stage, so we use the next stage for validation
      nextStep++;
      nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);
    }

    if (nextStage) {
      const missingPropertiesResponse = await this.designService
        .getDesignMissingProperties(this.domainModel.id, nextStage.id)
        .catch(handleApiError('Failed to get missing design properties'));
      const designReadiness = new DesignReadiness({
        missingPropertiesResponse
      });
      this.hasMissingProperties = designReadiness.hasMissingProperties();
      designReadiness.createNotifications();
    }
  }

  onObjectHoverIn(event: IPointerHoveringControlEvent): void {
    // Added because the class needs to implement the IHandleHoverTool interface
  }

  onObjectHoverOut(event: IPointerHoveringControlEvent): void {
    // Added because the class needs to implement the IHandleHoverTool interface
  }

  onDrag(event: IControlDragging): void {
    // Added because the class needs to implement the IHandleDragTool interface
  }

  onDragStart(event: IControlDragging): void {
    // Added because the class needs to implement the IHandleDragTool interface
  }

  onDragEnd(event: IControlDragging): void {
    // Added because the class needs to implement the IHandleDragTool interface
  }

  /**
   * Should be called every time when data-modifying permit-ready endpoint is called
   */
  updateDataState(): void {
    updateDesignState(this.domainModel.state.withDataState(this.id));
  }

  private updateElectricalEquipment(electricalEquipmentLocations: IElectricalEquipmentData[]): void {
    this.clearElectricalEquipmentMarkers();
    this.electricalEquipment = electricalEquipmentLocations;
    this.showEquipmentPanel(this.electricalEquipment);
    this.updateTargetObjects();
  }

  private async reimportDesignIfNeededAndResumeLocations(isLastValidStage: boolean): Promise<void> {
    const projectAndDesignUpdateSuccessful = await this.updateProjectAndDesignInDocumentsService();

    if (
      !isLastValidStage
      || !this.domainModel.state?.isElectricalBosEquipmentPlaced
      || !projectAndDesignUpdateSuccessful
      || !isCurrentWorkspace('design')
    ) {
      return;
    }

    try {
      const designId = this.domain.design.id;
      const sitePlanResponse = await this.documentsService
        .getSitePlan(designId)
        .catch(handleApiError('Failed to get site plan'));
      this.updateElectricalEquipment(sitePlanResponse.electricalEquipment);
    } catch (error) {
      SentryException('Error resuming locations', error);
    }
  }

  private enableBehaviors(toolId?: string): void {
    switch (toolId) {
      case SELECT_TOOL_ID: {
        this.dragBehaviour.addDragEvents(this);
        this.hoverBehaviour.addHoverEvents(this);
        this.selectionBehaviour.addSelectionChangeEvent(this);
        this.updateTargetObjects();
        break;
      }

      default: {
        this.mouseBehaviour.addMouseClickEvents(this);
        break;
      }
    }
  }

  private updateTargetObjects(): void {
    const movableMarkers = this.movableMarkers;
    const recursive = false;
    this.selectionBehaviour.setTargetObjects(movableMarkers, recursive);
    this.hoverBehaviour.setTargetObjects(movableMarkers, recursive);
    this.dragBehaviour.setTargetObjects(movableMarkers, recursive);
  }

  private showEquipmentPanel(electricalEquipment: IElectricalEquipmentData[] = []): void {
    const dependencies: IEquipmentPanelViewModelDependencies = {
      domain: this.domain,
      designWorkspace: this.designWorkspace,
      editor: this.editor,
      panel: this.panels,
      serviceBus: this.serviceBus,
      modal: this.modal,
      toolbar: this.toolbar,
      equipment: electricalEquipment
    };
    if (isCurrentWorkspace('design')) {
      this.panels.createPanel('equipment_panel', dependencies);
      this.canShowEquipmentPanel = true;
    }
  }

  private enableTools(): void {
    this.toolbar.blacklistTools(ElectricalBosStage.toolBlacklist);
    this.toolbar.whitelistTools(ElectricalBosStage.toolWhitelist);
  }

  private clearElectricalEquipmentMarkers(): void {
    this.editor.removeObjectsByType(SceneObjectType.ElectricalEquipmentMarker);
  }

  private resetStageUI(): void {
    this.canShowEquipmentPanel = false;
    this.panels.destroyPanel();
    this.disposeEvents();
    this.toolbar.deselectTool();
  }

  private updateUserState(): void {
    updateDesignState(this.domainModel.state.withUserState(this.id));
  }
}
