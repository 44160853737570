import isEqual from 'lodash/isEqual';
import { BackupStrategy } from '../../models/PvSystem/PvSystem';

export enum EssBackupConfiguration {
  NO_BACKUP = 'NO_BACKUP',
  SUNLIGHT_BACKUP = 'SUNLIGHT_BACKUP',
  PARTIAL_BACKUP = 'PARTIAL_BACKUP',
  SELF_CONSUMPTION = 'SELF_CONSUMPTION',
  FULL_BACKUP = 'FULL_BACKUP'
}

/**
 * A selection option for a specific storage solution (i.e., how many devices of what kind there are)
 */
export interface IEssStorageOptionData {
  readonly name: string;
  readonly usableEnergyCapacityInKwh: number;
  /**
   * A list of UUIDs, where each UUID is a definition of a standalone ESS instance (i.e., it may have duplicates).
   * Units may be undefined when an inverter-integrated storage solution is used (e.g., Tesla Powerwall+).
   */
  readonly units?: readonly string[];
  /**
   * A message that informs why this option would not be compatible with the current system
   */
  readonly compatibilityError?: string;
}

/**
 * ESS selection options API returns an array of such objects.
 * Each object represents a group of AC-coupled storage options sharing the same backup configuration.
 */
export interface IEssOptionsGroupData {
  readonly configuration: EssBackupConfiguration;
  readonly acCoupledStorageOptions?: IEssStorageOptionData[];
}

/**
 * A convenience tuple type holding a pair of options that define the energy storage solution
 */
export type EssOptionsPair = {
  readonly backup: EssBackupConfiguration;
  readonly storage?: IEssStorageOptionData;
};

/**
 * A utility class that wraps the ESS selection options API response, and allows convenient querying of that data.
 */
export class EnergyStorageAndBackupOptions {
  private readonly options: readonly IEssOptionsGroupData[];

  constructor(options: readonly IEssOptionsGroupData[]) {
    this.options = options;
  }

  get availableBackupConfigurations(): readonly EssBackupConfiguration[] {
    return this.options.map(({ configuration }: IEssOptionsGroupData): EssBackupConfiguration => configuration);
  }

  /**
   * Are there any backup configurations available other than "NO_BACKUP"?
   */
  get isAnyBackupConfigurationAvailable(): boolean {
    return this.availableBackupConfigurations.some(
      (configuration: EssBackupConfiguration): boolean => configuration !== EssBackupConfiguration.NO_BACKUP
    );
  }

  /**
   * Is no-backup configuration available? (It may not be available when inverter-integrated energy storage is present)
   */
  private get isNoBackupConfigurationAvailable(): boolean {
    return this.availableBackupConfigurations.includes(EssBackupConfiguration.NO_BACKUP);
  }

  /**
   * Returns AC-coupled storage solution selection options for a given configuration
   */
  availableAcCoupledStorageOptions(backupConfiguration: EssBackupConfiguration): readonly IEssStorageOptionData[] {
    const optionsGroupForRequestedConfiguration = this.options.find(
      (option: IEssOptionsGroupData): boolean => option.configuration === backupConfiguration
    );
    return optionsGroupForRequestedConfiguration?.acCoupledStorageOptions ?? [];
  }

  /**
   * Returns the storage solution selection option that should be selected initially for a given configuration.
   * Returns undefined in case given configuration does not have selection options.
   */
  defaultAcCoupledStorageOption(configuration: EssBackupConfiguration): IEssStorageOptionData | undefined {
    // We simply pick the first available option
    return this.availableAcCoupledStorageOptions(configuration)?.[0];
  }

  /**
   * Finds an AC-coupled storage solution option by configuration and name of the option
   */
  findAcCoupledStorageSolutionOption(
    backupConfiguration: EssBackupConfiguration,
    optionName: string
  ): IEssStorageOptionData {
    return this.availableAcCoupledStorageOptions(backupConfiguration).find(
      (option: IEssStorageOptionData): boolean => option.name === optionName
    )!;
  }

  /**
   * Given a backup strategy and a list of standalone ESS units, finds a closest match among the available options.
   * Note that the match may not be exact in cases when exact match cannot be found.
   */
  findMatchingAvailableOptionsPair(
    backupStrategy: BackupStrategy,
    standaloneAcCoupledEssUnits: readonly string[]
  ): EssOptionsPair {
    // Note: if no backup configuration is available, it means inverter-integrated ESS is used (e.g., Tesla Powerwall+)
    const energyStorageIsPresent = standaloneAcCoupledEssUnits.length > 0 || !this.isNoBackupConfigurationAvailable;
    const requestedBackupConfig = this.findMatchingEssBackupConfiguration(backupStrategy, energyStorageIsPresent);
    if (!this.availableBackupConfigurations.includes(requestedBackupConfig)) {
      const fallbackBackupConfig = this.availableBackupConfigurations[0];
      return {
        backup: fallbackBackupConfig,
        storage: this.defaultAcCoupledStorageOption(fallbackBackupConfig)
      };
    }
    const storageOptions = this.availableAcCoupledStorageOptions(requestedBackupConfig);
    if (storageOptions.length === 0) {
      return {
        backup: requestedBackupConfig,
        storage: undefined
      };
    }
    // Note: For an option to match, the order of the units has to be identical
    let selectedStorageOption = storageOptions.find((option: IEssStorageOptionData): boolean =>
      isEqual(option.units ?? [], standaloneAcCoupledEssUnits)
    );
    if (!selectedStorageOption) {
      // Only possible if BE stopped returning a storage solution option after a system using it was already designed
      console.error(
        'Could not find a matching storage solution option. Falling back to the default one.',
        backupStrategy,
        standaloneAcCoupledEssUnits
      );
      selectedStorageOption = this.defaultAcCoupledStorageOption(requestedBackupConfig);
    }
    return {
      backup: requestedBackupConfig,
      storage: selectedStorageOption
    };
  }

  private findMatchingEssBackupConfiguration(
    backupStrategy: BackupStrategy,
    hasStorage: boolean
  ): EssBackupConfiguration {
    switch (backupStrategy) {
    case BackupStrategy.FULL:
      return EssBackupConfiguration.FULL_BACKUP;
    case BackupStrategy.PARTIAL:
      return EssBackupConfiguration.PARTIAL_BACKUP;
    case BackupStrategy.NONE:
      return hasStorage ? EssBackupConfiguration.SELF_CONSUMPTION : EssBackupConfiguration.NO_BACKUP;
    default:
      throw new Error(`Unsupported backup strategy: ${backupStrategy}`);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static backupStrategyFromEssBackupConfiguration(backupConfiguration: EssBackupConfiguration): BackupStrategy {
    switch (backupConfiguration) {
    case EssBackupConfiguration.FULL_BACKUP:
      return BackupStrategy.FULL;
    case EssBackupConfiguration.PARTIAL_BACKUP:
      return BackupStrategy.PARTIAL;
    case EssBackupConfiguration.SELF_CONSUMPTION:
    case EssBackupConfiguration.NO_BACKUP:
      return BackupStrategy.NONE;
    default:
      throw new Error(`Unsupported ESS backup configuration: ${backupConfiguration}`);
    }
  }
}
