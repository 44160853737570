import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import HelpToolbar from '../Shared/HelpToolbar';
import MainToolbar from './MainToolbar';

/* We may need it in future. DO NOT DELETE. */
// import SecondaryToolbar from './SecondaryToolbar';

const ProjectToolbar = (): ReactElement => (
  <>
    <MainToolbar />
    {/*<SecondaryToolbar />*/}
    <HelpToolbar />
  </>
);

export default observer(ProjectToolbar);
