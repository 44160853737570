import {
  LyraLayout, LyraStatusIcon, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import type { Color } from '../../../../domain/typings';
import useStore from '../../../../stores/useStore';
import type { DesignWorkspace } from '../../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { ProjectWorkspace } from '../../../../stores/UiStore/WorkspaceStore/workspaces/ProjectWorkspace';
import config, { UI_MODE } from '../../../../config/config';
import {
  HeaderContainer,
  HeaderIconContainer,
  HeaderTitle,
  HeaderTitleContainer,
  SubHeaderTitle,
  StatusWrapper,
  StatusText
} from './styles';

type PropertyPanelHeaderProps = {
  icon?: string;
  iconBg?: Color;
  name: string;
  additionalData?: string;
};

const { StatusIcon } = LyraStatusIcon;

const PropertyPanelHeader = observer(
  ({
    icon = 'tool-properties', name, additionalData = ''
  }: PropertyPanelHeaderProps): ReactElement => {
    const { workspace } = useStore();
    const {
      saving, errors
    } = workspace.currentWorkspace;
    const theme = useContext(ThemeContext);
    const isUnsavedState = (workspace.currentWorkspace as ProjectWorkspace | DesignWorkspace).isUnsavedState;
    const isSavedState = (workspace.currentWorkspace as ProjectWorkspace | DesignWorkspace).isSavedState;

    const getStatusAutoSave = (): ReactElement => {
      let component: ReactElement = <></>;
      if (isUnsavedState && !saving) {
        component = (
          <StatusWrapper>
            <StatusIcon status="pending" />
            <StatusText>Unsaved changes</StatusText>
          </StatusWrapper>
        );
      }
      if (saving && !errors.length) {
        component = (
          <StatusWrapper>
            <LyraLoader.Loader size={25} color={theme!.colors.whiteBg as string} bgColor={theme!.colors.main1} />
            <StatusText>Saving...</StatusText>
          </StatusWrapper>
        );
      }
      if (isSavedState && !errors.length) {
        component = (
          <StatusWrapper>
            <StatusIcon status="success" />
            <StatusText>Changes saved</StatusText>
          </StatusWrapper>
        );
      }
      if (!saving && errors.length > 0) {
        component = (
          <StatusWrapper>
            <StatusIcon status="fail" />
            <StatusText>Save failed</StatusText>
          </StatusWrapper>
        );
      }
      return component;
    };

    return (
      <HeaderContainer>
        <LyraLayout.Flexbox align="center">
          {config.featureFlag.uiMode !== UI_MODE.AURORA && (
            <HeaderIconContainer isFailedStatus={!saving && errors.length > 0}>
              {getStatusAutoSave()}
            </HeaderIconContainer>
          )}
          <HeaderTitleContainer>
            <HeaderTitle>{name}</HeaderTitle>
            <SubHeaderTitle>{additionalData}</SubHeaderTitle>
          </HeaderTitleContainer>
        </LyraLayout.Flexbox>
      </HeaderContainer>
    );
  }
);

export default PropertyPanelHeader;
