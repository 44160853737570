import styled from 'styled-components';

const CustomSlopeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .container-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .container-value {
    width: 100%;
    min-width: 55px;
    font-size: 16px;
    display: inline-block;
    text-align: right;
  }

  .switch-icon {
    cursor: pointer;
    margin-right: 15px;
  }
`;

export { CustomSlopeWrapper };
