import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import {
  action, computed, observable
} from 'mobx';
import type { EOrientation } from '../../typings/EOrientation';
import type { IPolygon } from '../Polygon/IPolygon';

interface IEnergyProductionData {
  /**
   * In kWh
   */
  readonly firstYearProductionForOneModulePositionInFullSun: number;
  readonly source: 'PV Watts' | 'Lyra';
}

export interface IPvModulePositionData {
  readonly id: string;
  readonly solarAccessPercentage?: number;
  readonly orientation: EOrientation;
  readonly polygon: IPolygon;
}

export interface ILayoutData {
  readonly arrayAreaId: string;
  readonly energyProduction: IEnergyProductionData;
  readonly positions: IPvModulePositionData[];
}

export class Layout {
  readonly arrayAreaId: string;
  private readonly energyProduction: IEnergyProductionData;
  @observable
  private positions: IPvModulePositionData[];

  constructor(data: ILayoutData) {
    this.arrayAreaId = data.arrayAreaId;
    this.energyProduction = data.energyProduction;
    this.positions = data.positions;
  }

  toData(): ILayoutData {
    return {
      arrayAreaId: this.arrayAreaId,
      energyProduction: this.energyProduction,
      positions: [...this.positions]
    };
  }

  @computed
  get numberOfPositions(): number {
    return this.positions.length;
  }

  sizeInWatts = (pvModulePowerRating: number): number => {
    return this.numberOfPositions * pvModulePowerRating;
  };

  energyProductionEstimateInKwh = (positionIds: readonly string[]): number => {
    return sumBy(
      this.positions.filter((position: IPvModulePositionData): boolean => positionIds.includes(position.id)),
      (position: IPvModulePositionData) =>
        this.energyProduction.firstYearProductionForOneModulePositionInFullSun * (position.solarAccessPercentage ?? 1)
    );
  };

  forEachPosition = (callback: (position: IPvModulePositionData) => void): void => {
    return this.positions
      .map((position: IPvModulePositionData): IPvModulePositionData => cloneDeep(position))
      .forEach(callback);
  };

  @action
  addPvModulePosition = (positionId: string, orientation: EOrientation, polygon: IPolygon): void => {
    const newPosition: IPvModulePositionData = {
      id: positionId,
      orientation: orientation,
      solarAccessPercentage: 1,
      polygon
    };
    this.positions.push(newPosition);
  };

  @action
  updatePvModulePosition = (positionId: string, orientation: EOrientation, polygon: IPolygon): void => {
    const existingPosition = this.positions.find(
      (position: IPvModulePositionData): boolean => position.id === positionId
    );
    if (!existingPosition) {
      throw new Error(`Position with ID ${positionId} not found on layout ${this.arrayAreaId}`);
    }
    const index = this.positions.indexOf(existingPosition);
    const updatedPosition: IPvModulePositionData = {
      id: existingPosition.id,
      orientation: orientation,
      solarAccessPercentage: existingPosition.solarAccessPercentage,
      polygon: polygon
    };
    this.positions.splice(index, 1, updatedPosition);
  };

  @action
  deletePvModulePositions = (positionIds: readonly string[]): void => {
    this.positions = this.positions.filter(
      (position: IPvModulePositionData): boolean => !positionIds.includes(position.id)
    );
  };
}
