import isEqual from 'lodash/isEqual';
import { computed } from 'mobx';

export interface IAcCoupledEnergyStorageSystemDefinitionData {
  readonly id: string;
  readonly name: string;
  readonly usableEnergyCapacityInKwh: number;
}

interface IAcCoupledEnergyStorageSystemData {
  readonly id: string;
  readonly definitionId: string;
}

export interface IAcCoupledEnergyStorageSystemsData {
  readonly definitions?: readonly IAcCoupledEnergyStorageSystemDefinitionData[];
  readonly instances?: readonly IAcCoupledEnergyStorageSystemData[];
}

export class AcCoupledEnergyStorageSystems {
  definitions: readonly IAcCoupledEnergyStorageSystemDefinitionData[];
  instances: readonly IAcCoupledEnergyStorageSystemData[];

  constructor(data: IAcCoupledEnergyStorageSystemsData) {
    this.definitions = [...(data.definitions ?? [])];
    this.instances = [...(data.instances ?? [])];
  }

  toData(): IAcCoupledEnergyStorageSystemsData {
    return {
      definitions: this.definitions.length ? this.definitions : undefined,
      instances: this.instances ?? undefined
    };
  }

  instanceCountByDefinition(definitionId: string): number {
    return this.instances.filter(
      (instance: IAcCoupledEnergyStorageSystemData) => instance.definitionId === definitionId
    ).length;
  }

  /**
   * Returns an array of definition IDs, where each definition ID represents an instance.
   * For example, if there are 3 instances that have the same definition ID of X, this would return `[X, X ,X]`
   */
  @computed
  get instanceDefinitionIds(): readonly string[] {
    return this.instances.map((instance: IAcCoupledEnergyStorageSystemData): string => instance.definitionId);
  }

  /**
   * Checks whether this object matches the specification, in which each ESS is represented as its definition ID.
   */
  match = (instancesSpecification: readonly string[]): boolean => {
    return isEqual(this.instanceDefinitionIds, instancesSpecification);
  };
}
