import minBy from 'lodash/minBy';
import type { IUpdateDesignState } from '../../../stores/ServiceBus/Commands/UpdateDesignState';
import type DomainStore from '../../../stores/DomainStore/DomainStore';

export enum DesignStep {
  ARRAY_PLACEMENT = 'ARRAY_PLACEMENT',
  LAYOUT_DESIGN = 'LAYOUT_DESIGN',
  ELECTRICAL_DESIGN = 'ELECTRICAL_DESIGN',
  ELECTRICAL_BOS = 'ELECTRICAL_BOS',
  CIRCUIT_TABLE = 'CIRCUIT_TABLE',
  MOUNTING_BOS = 'MOUNTING_BOS',
  CUSTOMIZATION = 'CUSTOMIZATION',
  PLAN_SET_PREVIEW = 'PLAN_SET_PREVIEW',
  COMPLETED = 'COMPLETED'
}

/**
 * An interface describing the related data structure in the API spec.
 * Note: DesignState does not implement this interface itself in order to not expose these properties.
 */
export interface IDesignStateData {
  user: DesignStep;
  data: DesignStep;
  synchronizationRequired: string[];
  electricalBosEquipmentPlaced?: boolean;
  requiresExternalProposalDesignImport?: boolean;
}

export class DesignState {
  /**
   * Design step in which the user currently is
   */
  private user: DesignStep;
  /**
   * Design step up to which there is data in the Design.
   * An example of this is e.g. when a user goes to ELECTRICAL_DESIGN step and then goes back to ARRAY_PLACEMENT
   * in that case 'user' state will be ARRAY_PLACEMENT, while 'data' state will be ELECTRICAL_DESIGN.
   */
  private data: DesignStep;
  private synchronizationRequired: string[];
  private requiresExternalProposalDesignImport: boolean;
  /**
   * A flag indicating whether electrical equipment locations have been generated.
   * This is needed to determine if GET site-plan endpoint should be called when restoring Electrical BOS step stage.
   */
  private electricalBosEquipmentPlaced: boolean;

  /**
   * Constructor for creating a class instance from data of the same structure
   * @see IDesignStateData
   */
  constructor(data: IDesignStateData) {
    this.user = data.user;
    this.data = data.data;
    this.synchronizationRequired = data.synchronizationRequired.slice();
    this.requiresExternalProposalDesignImport = data.requiresExternalProposalDesignImport ?? false;
    this.electricalBosEquipmentPlaced = data.electricalBosEquipmentPlaced ?? false;
  }

  toData(): IDesignStateData {
    return {
      user: this.user,
      data: this.data,
      synchronizationRequired: [...this.synchronizationRequired],
      electricalBosEquipmentPlaced: this.electricalBosEquipmentPlaced,
      requiresExternalProposalDesignImport: this.requiresExternalProposalDesignImport
    };
  }

  currentUserStep(): DesignStep {
    return this.user;
  }

  get isElectricalBosEquipmentPlaced(): boolean {
    return this.electricalBosEquipmentPlaced;
  }

  isUserIn(step: DesignStep): boolean {
    return this.user === step;
  }

  isUserInOrAfter(step: DesignStep): boolean {
    const keys: string[] = Object.keys(DesignStep);
    return keys.indexOf(this.user) >= keys.indexOf(step);
  }

  isDataIn(step: DesignStep): boolean {
    return this.data === step;
  }

  withUserState(newUserState: DesignStep): DesignState {
    if (this.user === newUserState) {
      return this;
    }
    const copy = this.copy();
    copy.user = newUserState;
    return copy;
  }

  /**
   * Normally pv-system-design service updates the data state based on operations performed on Design.
   * However, once the user is in ELECTRICAL_BOS or MOUNTING_BOS steps, permit-ready-design service takes over
   * and then frontend has to update data state every time data changes on the backend.
   */
  withDataState(newDataState: DesignStep): DesignState {
    if (this.user !== newDataState && newDataState !== DesignStep.COMPLETED) {
      throw new Error(
        `Cannot set Data state to ${DesignStep[newDataState]} as User state is different: ${DesignStep[this.user]}`
      );
    }
    if (this.data === newDataState) {
      return this;
    }
    const copy = this.copy();
    copy.data = newDataState;
    return copy;
  }

  withDataNoFurtherThan(limit: DesignStep): DesignState {
    const copy = this.copy();
    copy.data = minBy([copy.data, limit], (step: DesignStep): number => Object.keys(DesignStep).indexOf(step))!;
    return copy;
  }

  /**
   * Is both Project and Design synchronized with permit-ready (documents) service?
   */
  isSynchronized(): boolean {
    return !this.isProjectSyncRequired() && !this.isDesignSyncRequired();
  }

  isProjectSyncRequired(): boolean {
    return this.synchronizationRequired.includes('PROJECT');
  }

  isDesignSyncRequired(): boolean {
    return this.synchronizationRequired.includes('DESIGN');
  }

  withProjectSynchronized(): DesignState {
    return this.withSynchronizationFlagRemoved('PROJECT');
  }

  withDesignSynchronized(): DesignState {
    return this.withSynchronizationFlagRemoved('DESIGN');
  }

  withDesignSyncRequired(): DesignState {
    const copy = this.copy();
    if (!copy.synchronizationRequired.includes('DESIGN')) {
      copy.synchronizationRequired.push('DESIGN');
    }
    return copy;
  }

  withElectricalBosEquipmentPlaced(newFlag: boolean): DesignState {
    const copy = this.copy();
    copy.electricalBosEquipmentPlaced = newFlag;
    return copy;
  }

  /**
   * Produces a dependency object for 'update_design_state' command
   * @see UpdateDesignState
   */
  toUpdateStateCommand(domainStore: DomainStore): IUpdateDesignState {
    return {
      domain: domainStore,
      state: this
    };
  }

  private withSynchronizationFlagRemoved(synchronizationFlagToRemove: string): DesignState {
    const copy = this.copy();
    copy.synchronizationRequired = this.synchronizationRequired.filter(
      (synchronizationFlag: string): boolean => synchronizationFlag !== synchronizationFlagToRemove
    );
    return copy;
  }

  private copy(): DesignState {
    return new DesignState(this.toData());
  }
}
