import type {
  CSSProperties, PropsWithChildren, ReactElement
} from 'react';
import * as React from 'react';
import {
  TooltipStyle, TooltipWrapper
} from './styles';

interface ITooltipProps {
  position?: 'top' | 'bottom';
  tooltipTheme?: 'dark' | 'white';
  arrowPosition?: 'top' | 'bottom';
}

function ToolbarTooltip(props: PropsWithChildren<ITooltipProps>): ReactElement {
  const {
    children, position = 'bottom', tooltipTheme = 'white', arrowPosition = 'top'
  } = props;

  function calculateSpanPosition(): CSSProperties {
    return {
      left: 'calc(90% - 6px)',
      [arrowPosition]: 'calc(0% - 6px)'
    };
  }

  const tooltipStyleProps = {
    tooltipTheme,
    tooltipPosition: position
  };

  return (
    <TooltipWrapper tooltipPosition={position}>
      <TooltipStyle {...tooltipStyleProps}>
        <div className="p-xxs">{children}</div>
        <span style={calculateSpanPosition()} />
      </TooltipStyle>
    </TooltipWrapper>
  );
}

export default ToolbarTooltip;
