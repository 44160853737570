import styled, {
  css,
  SimpleInterpolation,
  PropsTheme
} from 'styled-components';
import { Color } from '../../../typings';

type CardStyleProp = {
  collapsed?: boolean;
  disabled?: boolean;
  selectIsClickable?: boolean;
  selected?: string | number;
  height?: string;
  textAlign?: string;
};

type ContainerProps = {
  width?: string;
};

function hideLabel({ collapsed }: CardStyleProp): SimpleInterpolation {
  return !collapsed
    ? css`
      opacity: 0; 
      visibility: hidden; 
      height: 0;
      padding: 0;
    `
    : css`
      opacity: 1;
      visibility: visible;
      padding: 6px 10px;
    `;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation => css`width: ${width};`}
  box-sizing: border-box;
  
  &.is-opened {
    z-index: 9;
  }
`;

export const SelectStyle = styled.div<CardStyleProp>`
  ${hideLabel};
  ${({ disabled = false, selectIsClickable = false }: CardStyleProp): string =>
    `cursor: ${disabled ? 'not-allowed' : selectIsClickable ? 'pointer' : 'default'};`
  };
  background-color: white;
  border-radius: 4px;
  border: ${({ theme }: PropsTheme): Color => `solid 2px ${theme.colors.gray4}`};
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:active, &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: 10px;
`;

export const SelectList = styled.div<CardStyleProp>`
  opacity: 1;
  text-align: ${({ textAlign }: CardStyleProp): string => textAlign ?? 'center'};
  width: 100%;
`;
