import React, { BaseProps, ReactElement, useState } from 'react';
import { LeftIcon } from '../LeftIcon';
import { RightIcons } from '../RightButtons';
import { PaddedSiteStructureItemContainer, Separator } from '../styles';

export type RoofOutlineProps = BaseProps & {
  onDeleteClick?: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

const RoofOutline = (props: RoofOutlineProps): ReactElement => {
  const { className = '', styles = {}, onDeleteClick, onVisibilityToggle } = props;
  const [hover, setHover] = useState(false);

  return (
    <PaddedSiteStructureItemContainer
      className={className}
      styles={styles}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      <LeftIcon icon="trace-outlines" bgColor="#4a4a4a" />
      Roof Outline
      <Separator />
      {hover && (
        <RightIcons
          theme="dark"
          onDeleteClick={onDeleteClick}
          onVisibilityToggle={onVisibilityToggle}
        />
      )}
    </PaddedSiteStructureItemContainer>
  );
};

export { RoofOutline };
