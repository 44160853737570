import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { HEADER, ORDER_LAYER_TOP } from './constants';
import { FlexBG } from './FlexBG';

const Header = ({ children, className }: BaseProps): ReactElement => {
  return (
    <Area
      name={HEADER}
      className={className}
      layer={ORDER_LAYER_TOP}
    >
      <FlexBG
        dir="row"
        align="center"
        justify="center"
        withGradient
        fullHeight
      >
        {children}
      </FlexBG>
    </Area>
  );
};

export { Header };
