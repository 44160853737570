import {
  computed, observable
} from 'mobx';
import type { IRoofFaceSupplementalData } from '../../../../domain/entities/SiteDesign/RoofFaceSupplementalData';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type {
  IUpdateRoofFacePropertyDependencies,
  UpdateRoofFacePropertyKey
} from '../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { RoofFacePropertiesStore } from './RoofFacePropertiesStore';

export interface IBaseRoofFaceViewModelDependencies {
  editor: EditorStore;
  roofFaceProps: RoofFacePropertiesStore;
  domain: DomainStore;
  roofFace: RoofFace;
  serviceBus: ServiceBus;
  roofFacePropertyViewModelsRefs?: {
    [key: string]: BaseRoofFaceViewModel;
  };
}

export abstract class BaseRoofFaceViewModel {
  readonly roofFace: RoofFace;

  abstract id: string;
  abstract propUICode: string;

  /**
   * RoofFace Property value
   */
  @observable
  value: RoofFace[keyof RoofFace];

  protected editor: EditorStore;
  protected roofFaceProps: RoofFacePropertiesStore;
  protected domain: DomainStore;
  protected serviceBus: ServiceBus;
  protected roofFacePropertyViewModelsRefs?: {
    [key: string]: BaseRoofFaceViewModel;
  };

  constructor(dependencies: IBaseRoofFaceViewModelDependencies) {
    this.editor = dependencies.editor;
    this.roofFaceProps = dependencies.roofFaceProps;
    this.domain = dependencies.domain;
    this.roofFace = dependencies.roofFace;
    this.value = this.calculateCurrentValue();
    this.serviceBus = dependencies.serviceBus;
    this.roofFacePropertyViewModelsRefs = dependencies.roofFacePropertyViewModelsRefs;
  }

  @computed
  get currentValue(): RoofFace[keyof RoofFace] {
    return this.value;
  }

  @computed
  get isAlreadyEdited(): RoofFace[keyof RoofFace] {
    const roofFacesSelected = this.roofFaceProps.currentSelectedRoofFaces;
    return roofFacesSelected.length > 0;
  }

  @computed
  get currentLabel(): string {
    const currentRoofFaceData: IRoofFaceSupplementalData | undefined = this.getCurrentRoofFaceSupplementalData();
    if (currentRoofFaceData) {
      const currentSurface = currentRoofFaceData.surfaceType?.attributes.abbreviatedName ?? '';
      const currentMaterial = currentRoofFaceData.surfaceSubType?.attributes.abbreviatedName ?? '';
      return `${currentSurface} ${currentMaterial ? `/${currentMaterial}` : ''}`;
    }
    return '';
  }

  /**
   * RoofFace Property to be edited
   */
  abstract get property(): UpdateRoofFacePropertyKey;

  @computed
  get savePropertyButtonDisabled(): boolean {
    return false;
  }

  getCurrentValue<T extends RoofFace[keyof RoofFace]>(): RoofFace[keyof RoofFace] {
    return this.roofFace[this.property] as T;
  }

  /**
   * Save the value temporary to be used later
   *
   * @param  value the value (must be a valid value)
   */
  changeValue(value: RoofFace[keyof RoofFace]): void {
    this.value = value;
    // dev helper: Save property immediately if localStorage.devInstantSelection is present
    if (localStorage.devInstantSelection) {
      this.saveProperty();
    }
  }

  saveProperty(): void {
    const currentValue = this.getCurrentValue();
    if (this.value !== currentValue) {
      const {
        domain, roofFaceProps, property, value, roofFacePropertyViewModelsRefs
      } = this;
      const dependencies: IUpdateRoofFacePropertyDependencies = {
        domain,
        roofFace: roofFaceProps.currentSelectedRoofFaces[0],
        roofFacePropertyViewModelsRefs,
        key: property,
        value
      };
      this.serviceBus.send('update_roof_face_property', dependencies);
    }
    this.roofFaceProps.reset();
    this.roofFaceProps.lastInteractedRoofFace = this.roofFace;
  }

  getDomain(): DomainStore {
    return this.domain;
  }

  /**
   * Cancel action of roof face properties
   * Should reset the roof face properties store
   */
  cancel(): void {
    const { property } = this;
    if (property === 'slope' || property === 'azimuth') {
      this.changeValue(this.calculateCurrentValue());
    }
    this.roofFaceProps.reset();
  }

  protected calculateCurrentValue(): RoofFace[keyof RoofFace] {
    const target = this.roofFace;

    if (this.selectedIsCollection(target) && target.length > 0) {
      return target[0][this.property];
    }
    if (this.selectedIsSingle(target)) {
      return target[this.property];
    }
  }

  /**
   * Guard that checks if selected is a collection of roof faces
   */
  protected selectedIsCollection(target: RoofFace | RoofFace[]): target is RoofFace[] {
    return Array.isArray(target);
  }

  /**
   * Guard that checks if selected is a single roof face
   */
  protected selectedIsSingle(target: RoofFace | RoofFace[]): target is RoofFace {
    return !Array.isArray(target);
  }

  protected getCurrentRoofFaceSupplementalData(): IRoofFaceSupplementalData | undefined {
    return this.getDomain().project.supplementalData?.roofFacesSupplementalData?.find(
      (roofFaceSupplementalData: IRoofFaceSupplementalData): boolean =>
        this.roofFaceProps?.currentSelectedRoofFaces[0]?.serverId === roofFaceSupplementalData.serverId
    );
  }
}
