import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

export const ModuleCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px;
  margin-top: 5px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  border-radius: 4px;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  outline: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.primary};
    outline: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.primary};
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px 0;
  }
  box-sizing: border-box;
`;

export const PillsWrapper = styled.div`
  display: flex;
  padding-top: 6px;
  flex-wrap: wrap;
`;

export const Description = styled.div`
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  padding-top: 6px;
  font-size: 11px;
`;
