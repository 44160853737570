import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import { LyraCard } from '@aurorasolar/lyra-ui-kit';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { PADDING_CARD } from '../../components/Layout/SolarLayout/constants';
import config, { UI_MODE } from '../../../config/config';

export const ArrayPlacementCard = styled(LyraCard.Card)`
  padding: ${PADDING_CARD};
  box-sizing: border-box;
  .row {
    margin: 0;
  }
  .col {
    padding: 0;
  }
`;

export const SectionTitle = styled.span`
  ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
  font-size: 11px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  text-transform: uppercase;
  display: block;
  width: 70%;
  line-height: 1.4;
`;
