import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useContext
} from 'react';
import type { FormikProps } from 'formik';
import styled, { ThemeContext } from 'styled-components';
import type { IPermitPackageDocumentGenerationOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import {
  electricalDiagramOptions,
  mapStyleOptions,
  propertyDescriptionKeys,
  titleBlockOptions
} from '../../../Modals/PermitPackageModal/Settings/Settings';

const DividerWrapper = styled.div`
  margin: 18px 0;
`;
const SubHeaderWrapper = styled.div`
  margin: 8px 0 24px 0;
`;
const SubHeaderInTheMiddleWrapper = styled.div`
  margin: 24px 0;
`;

let penultimatePropertyDescriptionSelection: propertyDescriptionKeys;

export const PlanSetCustomizationAdvancedTab = observer(
  ({
    formikbag,
    showRotateSiteMapPlacardOption,
    hasBoundary
  }: {
    formikbag: FormikProps<IPermitPackageDocumentGenerationOptionsData>;
    showRotateSiteMapPlacardOption: boolean;
    hasBoundary: boolean;
  }): ReactElement => {
    // Aurora Design System
    const DS = useContext(ThemeContext)!.DS;

    const handleTitleBlockSelect = useCallback((values: string[]): string[] => {
      let result = values;
      const lastChoice = values[values.length - 1];
      if (
        result.includes(propertyDescriptionKeys.CUSTOMER_OF_RECORD)
        && result.includes(propertyDescriptionKeys.NAME_OF_RESIDENCE)
      ) {
        result = [];
        if (values.includes(propertyDescriptionKeys.APN)) {
          result.push(propertyDescriptionKeys.APN);
        }
        result.push(lastChoice);
      }
      if (result.length > 2) {
        result = [penultimatePropertyDescriptionSelection, lastChoice];
      }
      penultimatePropertyDescriptionSelection = lastChoice as propertyDescriptionKeys;
      return result;
    }, []);

    return (
      <DS.Grid>
        <DS.GridItem size="third">
          <DS.Grid rowGap={2}>
            <SubHeaderWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">General</DS.Text>
              </DS.Flex>
            </SubHeaderWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.DropdownInput}
                multiple
                handleSelectionChange={(values: string[]) => {
                  formikbag.setFieldValue(
                    'advancedSettings.titleBlock.propertyDescriptions',
                    handleTitleBlockSelect(values)
                  );
                }}
                label="Customer identifier in the title block"
                options={titleBlockOptions}
                name="advancedSettings.titleBlock.propertyDescriptions"
              />
            </DS.GridItem>

            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show installer logo in title block"
                name="advancedSettings.titleBlock.showContractorLogo"
              />
            </DS.GridItem>

            <>
              <DS.GridItem size="full">
                <DS.Field
                  layout="adjacent"
                  component={DS.ToggleInput}
                  label="Show installer info in title block"
                  name="advancedSettings.titleBlock.showContractorInfo"
                />
              </DS.GridItem>
              <DS.GridItem size="full">
                <DS.Field
                  layout="adjacent"
                  component={DS.ToggleInput}
                  label="Show engineer of record in title block"
                  name="advancedSettings.titleBlock.showEngineerOfRecordInfo"
                />
              </DS.GridItem>
            </>

            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Include electrical calculations worksheet"
                name="advancedSettings.includeElectricalCalculations"
              />
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DividerWrapper>
                <DS.Divider />
              </DividerWrapper>
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <SubHeaderInTheMiddleWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">Site placards</DS.Text>
              </DS.Flex>
            </SubHeaderInTheMiddleWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show all non-ANSI safety labels as red"
                name="advancedSettings.sitePlacards.showNonAnsiLabelsAsRed"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show site map placard, even if optional"
                name="advancedSettings.sitePlacards.forceInclusion"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show site map placard as red"
                name="advancedSettings.sitePlacards.showAsRed"
              />
            </DS.GridItem>
            {showRotateSiteMapPlacardOption && (
              <DS.GridItem size="full">
                <DS.Field
                  layout="adjacent"
                  component={DS.ToggleInput}
                  label="Rotate site map placard to orientation of main service panel location"
                  name="advancedSettings.sitePlacards.rotateToOrientationOfPocLocation"
                />
              </DS.GridItem>
            )}
          </DS.Grid>
        </DS.GridItem>
        {/* Second column */}
        <DS.GridItem size="third">
          <DS.Grid rowGap={2}>
            <SubHeaderWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">Site plan</DS.Text>
              </DS.Flex>
            </SubHeaderWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.DropdownInput}
                label="Base map style"
                options={mapStyleOptions}
                name="advancedSettings.sitePlan.sitePlanBackgroundImage"
                handleSelectionChange={(values: string[]) => {
                  formikbag.setFieldValue('advancedSettings.sitePlan.sitePlanBackgroundImage', values[0]);
                  formikbag.setFieldValue('advancedSettings.sitePlan.includeParcelBoundaries', false);
                }}
              />
            </DS.GridItem>

            {!hasBoundary && formikbag.values.advancedSettings.sitePlan.sitePlanBackgroundImage === 'ROAD_MAP' && (
              <DS.GridItem size="full">
                <DS.Field
                  layout="adjacent"
                  component={DS.ToggleInput}
                  label="Show parcel boundaries"
                  name="advancedSettings.sitePlan.includeParcelBoundaries"
                />
              </DS.GridItem>
            )}

            {hasBoundary && (
              <DS.GridItem size="full">
                <DS.Field
                  layout="adjacent"
                  component={DS.ToggleInput}
                  label="Show parcel boundary distance lines"
                  name="advancedSettings.sitePlan.includeParcelBoundaryDistanceLines"
                />
              </DS.GridItem>
            )}

            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show gas meter clearance requirements"
                name="advancedSettings.fireSafetyPlan.showGasMeterClearanceRequirements"
              />
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DividerWrapper>
                <DS.Divider />
              </DividerWrapper>
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <SubHeaderInTheMiddleWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">Attachment plan</DS.Text>
              </DS.Flex>
            </SubHeaderInTheMiddleWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show attachment points"
                name="advancedSettings.attachmentPlan.showAttachmentPoints"
              />
            </DS.GridItem>

            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show dead load calculations"
                name="advancedSettings.attachmentPlan.showDeadLoadCalculations"
              />
            </DS.GridItem>

            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Show roof face edge length"
                name="advancedSettings.attachmentPlan.showRoofFaceEdgeLength"
              />
            </DS.GridItem>
          </DS.Grid>
        </DS.GridItem>
        {/* Third column */}
        <DS.GridItem size="third">
          <DS.Grid rowGap={2}>
            <SubHeaderWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">Electrical diagram</DS.Text>
              </DS.Flex>
            </SubHeaderWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                component={DS.DropdownInput}
                multiple
                label="Electrical diagram style"
                options={electricalDiagramOptions.slice(0, 2)}
                name="advancedSettings.electricalPlan.schematicTypes"
              />
            </DS.GridItem>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Include voltage drop in conductor table"
                name="advancedSettings.electricalPlan.includeVoltageDropInConductorTable"
              />
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DividerWrapper>
                <DS.Divider />
              </DividerWrapper>
            </DS.GridItem>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <SubHeaderInTheMiddleWrapper>
              <DS.Flex flexDirection="row" alignItems="center">
                <DS.Text text="h3">Fire safety plan</DS.Text>
              </DS.Flex>
            </SubHeaderInTheMiddleWrapper>
          </DS.Grid>
          <DS.Grid rowGap={2}>
            <DS.GridItem size="full">
              <DS.Field
                layout="adjacent"
                component={DS.ToggleInput}
                label="Include fire safety plan"
                name="advancedSettings.fireSafetyPlan.include"
              />
            </DS.GridItem>
          </DS.Grid>
        </DS.GridItem>
      </DS.Grid>
    );
  }
);
