import * as CheckBox from './Checkbox';
import * as Dropdown from './Dropdown';
import * as Input from './Input';
import * as Label from './Label';
import * as NumberField from './NumberField';
import * as Radio from './Radio';
import * as Select from './Select';
import * as SelectToggle from './SelectToggle';
import * as TagInput from './Tags';
import * as TextArea from './TextArea';
import * as TextField from './TextField';
import * as Toggle from './Toggle/Toggle';
import * as DropDownNew from './../DropDownNew/DropDownNew';

const FormElementsDesignTool = {
  CheckBox,
  Dropdown,
  DropDownNew,
  Input,
  Toggle,
  Radio,
  NumberField,
  TextField,
  TextArea,
  TagInput,
  Select,
  Label,
  SelectToggle
};

export {
  FormElementsDesignTool
};
