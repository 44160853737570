import React from 'react';
import { LyraIcon } from '@aurorasolar/lyra-ui-kit';

type Props = {
  readonly onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  readonly visible: boolean;
}

function ShowMoreBottomButton({
  onClick, visible
}: Props) {
  const visibleClassName = visible ? 'visible' : '';
  const className = `show-more-bottom-button ${visibleClassName}`;

  return (
    <div className={className} onClick={onClick}>
      <LyraIcon.Icon name="arrow-down" style={{
        width: 6,
        height: 10
      }} />
      <span>more info</span>
    </div>
  );
}

export default ShowMoreBottomButton;
