import type { CSSProperties } from 'react';

const selectContentWrapper: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const selectDiscontinued: CSSProperties = {
  opacity: 0.5
};

const selectContentTitle: CSSProperties = {
  marginBottom: '7px'
};

export {
  selectContentWrapper, selectContentTitle, selectDiscontinued
};
