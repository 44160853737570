import { action } from 'mobx';
import type {
  Intersection, Object3D
} from 'three';
import { MouseBehaviour } from '../../../../domain/behaviour/MouseBehaviour';
import { Segment } from '../../../../domain/graphics/Segment';
import type { ArrayPlacementStage } from '../../../../domain/stages/DesignStages/ArrayPlacementStage';
import { ERoofFaceEdgeType } from '../../../../domain/typings';
import { BaseCastObjectControl } from '../../../EditorStore/Controls/BaseCastObjectControl';
import { getEdgeIndex } from '../../../../utils/spatial';
import type { WorkspaceStore } from '../../WorkspaceStore';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import {
  getLyraModelByMesh,
  getLyraModelByOptionalMesh,
  getParentLyraModelByMesh,
  getParentLyraModelBySceneObjectType
} from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import type Pathway from '../../../../domain/models/SiteDesign/Pathway';
import type VentilationSetback from '../../../../domain/models/SiteDesign/VentilationSetback';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import { SET_BACK_ID } from './constants';

export interface IBaseSetBackToolDependencies extends IBaseToolDependencies {
  readonly workspace?: WorkspaceStore;
}

export const typesToSupportPathway = [
  ERoofFaceEdgeType.Hip,
  ERoofFaceEdgeType.Valley,
  ERoofFaceEdgeType.Rake,
  ERoofFaceEdgeType.Eave,
  ERoofFaceEdgeType.StepFlashing
];
export const typesToSupportSetBack = [ERoofFaceEdgeType.Ridge];

export class SetBackTool extends BaseTool {
  override readonly id: string = SET_BACK_ID;
  override readonly icon: string = 'set-back';
  override readonly title: string = 'Setback';
  override readonly description: string = this.title;
  private readonly mouseBehaviour: MouseBehaviour;
  private castPvModulePositionControl?: BaseCastObjectControl;
  private readonly setbackAndPathway = [SceneObjectType.Setback, SceneObjectType.Pathway];
  private readonly workspace: WorkspaceStore | undefined;
  override testId: string = 'SetBackTool';

  constructor(dependencies: IBaseSetBackToolDependencies) {
    super(dependencies);

    this.workspace = dependencies.workspace;
    this.mouseBehaviour = new MouseBehaviour(this.editor);
  }

  whenSelected(): void {
    this.init();
    this.initListeners();
  }

  whenDeselected(): void {
    this.removeListeners();
    this.unselect();
  }

  private get arrayPlacementStage(): ArrayPlacementStage {
    return this.workspace!.currentWorkspace.stageManager?.currentStage as ArrayPlacementStage;
  }

  private get pathways(): Pathway[] {
    return this.editor.getObjectsByType<Pathway>(SceneObjectType.Pathway, true);
  }

  private get setbacks(): VentilationSetback[] {
    return this.editor.getObjectsByType<VentilationSetback>(SceneObjectType.Setback, true);
  }

  @action unselect(): void {
    this.arrayPlacementStage.setSelectedItem(null);
  }

  @action
  override onMouseDown = (): void => {
    this.unselect();
    this.castPvModulePositionControl!.setTargetObjects(this.getRaycasterTargetObjects());
    const intersected: Intersection[] = this.castPvModulePositionControl!.getCastedObjects();

    if (intersected.length > 0) {
      const firstSetBackOrPathway: VentilationSetback | Pathway | undefined = getLyraModelByOptionalMesh(
        intersected.find((intersection: Intersection): boolean =>
          this.setbackAndPathway.includes(getLyraModelByMesh(intersection.object).type as SceneObjectType)
        )?.object
      );

      let firstIntersected: Segment | VentilationSetback | Pathway | null = null;

      if (firstSetBackOrPathway) {
        firstIntersected = firstSetBackOrPathway;
      } else {
        let firstSegment: Object3D | undefined | null = intersected.find(
          (intersection: Intersection): boolean =>
            getLyraModelByMesh(intersection.object) instanceof Segment
            || getParentLyraModelByMesh(intersection.object) instanceof Segment
        )?.object;

        if (firstSegment) {
          if (
            !(getLyraModelByOptionalMesh(firstSegment) instanceof Segment)
            && getParentLyraModelByMesh(firstSegment) instanceof Segment
          ) {
            firstSegment = firstSegment.parent;
          }

          const segmentModel = getLyraModelByMesh<Segment>(firstSegment!);

          // pathway (green)
          // setback (red)

          if ([...typesToSupportPathway, ...typesToSupportSetBack].includes(segmentModel.type!)) {
            if (this.isSegmentEmpty(segmentModel)) {
              firstIntersected = segmentModel;
            }
          }
        }
      }

      const selectedItem = firstIntersected;
      if (selectedItem) {
        this.arrayPlacementStage.setSelectedItem(selectedItem);
      }
    }
  };

  dispose(): void {
    this.removeListeners();
  }

  private isSegmentEmpty(segment: Segment): boolean {
    const roofFace = getParentLyraModelBySceneObjectType<RoofFace>(segment, SceneObjectType.RoofFace);
    const roofFaceGeometry = roofFace?.getVector3s();
    if (!roofFaceGeometry) {
      return false;
    }
    const edgeIndexOfSegment = getEdgeIndex(segment.points, roofFaceGeometry);

    for (const setbackOrPathway of [...this.pathways, ...this.setbacks]) {
      const edgeIndex = getEdgeIndex(setbackOrPathway.getVector3s(), roofFaceGeometry);
      if (edgeIndex === edgeIndexOfSegment) {
        return false;
      }
    }
    return true;
  }

  private init(): void {
    this.castPvModulePositionControl = new BaseCastObjectControl(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera!,
      this.getRaycasterTargetObjects()
    );
  }

  private getRaycasterTargetObjects(): Selectable[] {
    const typesToIntersect = [...typesToSupportSetBack, ...typesToSupportPathway];
    const selectable: Selectable[] = [];
    for (const typeToIntersect of typesToIntersect) {
      selectable.push(...this.editor.getObjectsByType<Selectable>(typeToIntersect, true));
    }
    selectable.push(...this.pathways);
    selectable.push(...this.setbacks);
    return selectable.filter(
      (selectable: Selectable): boolean => !!(selectable.mesh.parent && selectable.mesh.visible)
    );
  }

  private initListeners(): void {
    this.mouseBehaviour.addMouseClickEvents(this);
  }

  private removeListeners(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
  }
}
