import chunk from 'lodash/chunk';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import type BaseEnvProp from '../../../../../stores/UiStore/EnvironmentalProps/BaseEnvProps';
import Flexbox from '../../../../components/Layout/Flexbox';
import EnvironmentalInfoCard from '../../../../components/EnviromentalInfoPanel/EnvironmentalInfoCard';
import Details from './Details';
import Info from './Info';

type Props = BaseProps & {
  envProps: BaseEnvProp[];
  info?: ReactElement;
  editButtonComplete?: boolean;
  onClick?: () => void;
};

function renderCardContent(values: BaseEnvProp[]): ReactElement {
  const cardContent = values.map(
    (env: BaseEnvProp, index: number): ReactElement => (
      <Info key={index} divider={index > 0} name={env.name} icon={env.icon} value={env.value} />
    )
  );

  return (
    <Flexbox align="center" fullHeight={true}>
      {cardContent}
    </Flexbox>
  );
}

function renderTooltipContent(values: BaseEnvProp[]): ReactElement {
  const rowsOfPairs = chunk(values, 2).map(
    (pair: BaseEnvProp[], pairIndex: number): ReactElement => (
      <Flexbox dir="row" key={pairIndex}>
        {pair.map(
          (env: BaseEnvProp, indexWithinPair: number): ReactElement => (
            <Details key={indexWithinPair} env={env} divider={indexWithinPair > 0} themeBg={'main3'} />
          )
        )}
      </Flexbox>
    )
  );
  return (
    <Flexbox dir="column" align="center" wrap="wrap">
      {rowsOfPairs}
    </Flexbox>
  );
}

const EnvInfoCard = observer(({
  envProps, info, onClick, editButtonComplete
}: Props): ReactElement => {
  return (
    <EnvironmentalInfoCard
      cardContent={info || renderCardContent(envProps)}
      tooltipContent={renderTooltipContent(envProps)}
      onClick={onClick}
      editButtonComplete={editButtonComplete}
    />
  );
});

export default EnvInfoCard;
