import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import { updateRoofFace3dValues } from '../../../utils/UpdateRoofface3DValuesHandler';
import type { IBaseHandlerDependencies } from '../IServiceBus';
import type { IHandler } from './IHandler';

export interface IAzimuthChangedDependencies extends IBaseHandlerDependencies {
  readonly roofFace: RoofFace;
  readonly domainStore: DomainStore;
}

export class AzimuthChangedHandler implements IHandler {
  readonly name: string = 'azimuth_changed';
  private readonly roofFace: RoofFace;
  private readonly domainStore: DomainStore;

  constructor(dependencies: IAzimuthChangedDependencies) {
    this.roofFace = dependencies.roofFace;
    this.domainStore = dependencies.domainStore;
  }

  async execute(): Promise<void> {
    await updateRoofFace3dValues(this.roofFace, this.domainStore);
    this.domainStore.addOrUpdateRoofFace(this.roofFace);
    this.roofFace.redraw();
  }
}
