import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { Framing } from '../../../domain/models/SiteDesign/Framing';
import {
  ERoofSlopeType, ERoofType
} from '../../../domain/typings';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IRoofTypeChangedDependencies } from '../Handlers/RoofTypeChangedHandler';
import type {
  IBaseCommandDependencies, IHandlerBus
} from '../IServiceBus';
import type { BaseRoofFaceViewModel } from '../../UiStore/Properties/RoofProperties/BaseRoofFaceViewModel';
import { updateRoofFace3dValues } from '../../../utils/UpdateRoofface3DValuesHandler';
import type EditorStore from '../../EditorStore/EditorStore';
import type { RoofSlopingStructuralMembers } from '../../../domain/models/SiteDesign/RoofSlopingStructuralMembers';
import type { RoofCondition } from '../../../domain/models/SiteDesign/RoofCondition';
import { convertInchesToMeters } from '../../../utils/Convertions';
import type { IAzimuthChangedDependencies } from '../Handlers/AzimuthChangedHandler';
import type { ISlopeChangedDependencies } from '../Handlers/SlopeChangedHandler';
import { BaseCommand } from './Command';

export interface IUpdateRoofFacePropertyDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
  editor?: EditorStore;
  roofFacePropertyViewModelsRefs?: {
    [key: string]: BaseRoofFaceViewModel;
  };
  roofFace: RoofFace;
  key: UpdateRoofFacePropertyKey;
  value: RoofFace[keyof RoofFace];
}

export enum UpdateRoofFacePropertyKey {
  Polygon = 'polygon',
  RoofType = 'roofType',
  Edge = 'edge',
  Framing = 'framing',
  DeckSheathingType = 'deckSheathingType',
  Structure = 'structure',
  Condition = 'condition',
  SlopingStructuralMembers = 'slopingStructuralMembers',
  Surface = 'surface',
  Slope = 'slope',
  Azimuth = 'azimuth',
  SolarAccess = 'solarAccess',
  Name = 'name',
  Visible = 'visible',
  Images = 'images'
}

export class UpdateRoofFacePropertyCommand extends BaseCommand {
  private readonly value: RoofFace[keyof RoofFace];
  private readonly domain: DomainStore;
  private readonly editor?: EditorStore;
  private readonly roofFacePropertyViewModelsRefs:
    | {
        [key: string]: BaseRoofFaceViewModel;
      }
    | undefined;
  private readonly roofFace: RoofFace;
  private readonly key: UpdateRoofFacePropertyKey;

  constructor(dependencies: IUpdateRoofFacePropertyDependencies) {
    super();
    const {
      domain, editor, roofFace, key, value, roofFacePropertyViewModelsRefs
    } = dependencies;
    this.domain = domain;
    this.editor = editor;
    this.roofFace = roofFace;
    this.key = key;
    this.value = value;
    this.roofFacePropertyViewModelsRefs = roofFacePropertyViewModelsRefs;
  }

  async execute(): Promise<void> {
    if (this.key === UpdateRoofFacePropertyKey.Polygon) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.roofFace[this.key] as any) = this.value;
  }

  override async postExecute(context: IHandlerBus): Promise<void> {
    if (this.key === UpdateRoofFacePropertyKey.Edge) {
      if (!this.roofFace.verticesUpdateIsInProgress) {
        console.error('Updating an edge with this.roofFace.verticesUpdateIsInProgress off');
      }
      try {
        await updateRoofFace3dValues(this.roofFace, this.domain);
      } catch (error) {
        this.editor?.toggleCanvasLoaderCursorState(false);
        this.roofFace.verticesUpdateIsInProgress = false;
        throw error;
      }
    }

    if (this.key === UpdateRoofFacePropertyKey.RoofType) {
      if (this.value === ERoofType.FLAT) {
        this.roofFace.slope = 0;
        this.roofFace.azimuth = undefined;
        this.roofFace.roofType = ERoofType.FLAT;
        this.roofFace.slopeType = ERoofSlopeType.LowSlope;
      } else {
        this.roofFace.azimuth = undefined;
        this.roofFace.slope = undefined;
        this.roofFace.setEdgeTypes([]);
        this.roofFace.slopeType = undefined;
      }

      const deps: IRoofTypeChangedDependencies = {
        roofFace: this.roofFace,
        domainStore: this.domain,
        roofFacePropertyViewModelsRefs: this.roofFacePropertyViewModelsRefs
      };
      context.publish<IRoofTypeChangedDependencies>('roofType_changed', deps);

      this.roofFace.roofType = this.value as ERoofType;
    }

    if (this.key === UpdateRoofFacePropertyKey.Azimuth) {
      const deps: IAzimuthChangedDependencies = {
        roofFace: this.roofFace,
        domainStore: this.domain
      };
      context.publish<IAzimuthChangedDependencies>('azimuth_changed', deps);
    }

    if (this.key === UpdateRoofFacePropertyKey.Slope) {
      this.roofFace.slopeType =
        (this.roofFace.slope ?? 0) >= this.domain.MIN_STEEP_SLOPED_ROOF_SLOPE_IN_DEGREES
          ? ERoofSlopeType.SteepSlope
          : ERoofSlopeType.LowSlope;
      const deps: ISlopeChangedDependencies = {
        roofFace: this.roofFace,
        domainStore: this.domain,
        roofFacePropertyViewModelsRefs: this.roofFacePropertyViewModelsRefs
      };
      context.publish<ISlopeChangedDependencies>('slope_changed', deps);
    }

    if (this.key === UpdateRoofFacePropertyKey.Surface && this.roofFace.structure) {
      this.roofFace.structure.surfaceType = this.value as string;
    }

    if (this.key === UpdateRoofFacePropertyKey.DeckSheathingType && this.roofFace.structure) {
      this.roofFace.structure.deckSheathingType = this.value as string;
    }

    if (this.key === UpdateRoofFacePropertyKey.Condition) {
      if (!this.roofFace.structure) {
        this.roofFace.structure = {};
      }
      this.roofFace.structure.condition = this.value as RoofCondition;
    }

    if (this.key === UpdateRoofFacePropertyKey.SlopingStructuralMembers) {
      if (!this.roofFace.structure) {
        this.roofFace.structure = {};
      }
      this.roofFace.structure.slopingStructuralMembers = {
        ...this.roofFace.structure.slopingStructuralMembers,
        ...(this.value as RoofSlopingStructuralMembers)
      };
    }

    if (this.key === UpdateRoofFacePropertyKey.Framing) {
      if (!this.roofFace.structure) {
        this.roofFace.structure = {};
      }

      this.roofFace.structure.constructionStyle = (this.value as Framing).constructionType.attributes.value;

      if (!this.roofFace.structure.slopingStructuralMembers) {
        this.roofFace.structure.slopingStructuralMembers = {};
      }

      this.roofFace.structure.slopingStructuralMembers.crossSection = (
        this.value as Framing
      ).crossSectionDimension.attributes.value;
      this.roofFace.structure.slopingStructuralMembers.spacing = convertInchesToMeters(
        Number((this.value as Framing).trussSpacing.attributes.value)
      );
    }

    this.domain.addOrUpdateRoofFace(this.roofFace);
    if (this.key !== UpdateRoofFacePropertyKey.Visible) {
      this.roofFace.setShowSegmentLengths(true);
    }
    this.roofFace.redraw();
    this.roofFace.verticesUpdateIsInProgress = false;
    this.editor?.toggleCanvasLoaderCursorState(false);
  }
}
