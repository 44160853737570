import { observable } from 'mobx';
import type EditorStore from '../../../EditorStore/EditorStore';
import type EGuideIdentifier from '../EGuideIdentifier';
import type {
  IApplyParams, KindGuides
} from '../IApplyParams';
import type IGuideResult from '../IGuideResult';

export interface ISmartGuideDependencies {
  editor: EditorStore;
}

export abstract class BaseGuide {
  abstract icon: string;
  abstract name: string;
  abstract commandKeyCode: number;
  abstract identifier: EGuideIdentifier;
  abstract kindSupport: KindGuides[];

  offsetPoint = 10;
  newCategory: boolean = false;
  commandKeyCtrl: boolean = false;

  @observable
  isEnabled: boolean = false;

  @observable
  isActive: boolean = true;

  editor: EditorStore;

  constructor(deps: ISmartGuideDependencies) {
    this.editor = deps.editor;
    this.editor.addEventListener('canvasReady', this.setup.bind(this));

    this.onKeyUp = this.onKeyUp.bind(this);
  }

  enableKeyListener(): void {
    window.addEventListener('keyup', this.onKeyUp);
  }

  disableKeyListener(): void {
    window.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyUp(event: KeyboardEvent): void {
    const {
      keyCode, ctrlKey, target
    } = event;
    const isKeyCodeCorrect = keyCode === this.commandKeyCode;
    const isCtrlKeyCorrect = ctrlKey === this.commandKeyCtrl;

    const isKeyPressed = isKeyCodeCorrect && isCtrlKeyCorrect;
    const isNotTyping = !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement);

    if (isKeyPressed && isNotTyping) {
      this.isEnabled = !this.isEnabled;

      if (!this.isEnabled) {
        this.reset(false);
      } else {
        this.setup();
      }
    }
  }

  abstract setup(): void;

  abstract apply(params: IApplyParams): IGuideResult;
  abstract reset(disableKeyListener: boolean): void;

  hideUiElements(): void {
    // do nothing
  }
}
