import React, { ReactElement } from 'react';
import { toast, ToastContainer as ToastifyToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastifyToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress'
})`
  position: fixed;
  z-index: 1000;

  .toast {
    width: 307px;
    max-height: 500px;
    border-radius: 4px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #f4f4f4;
  }

  button[aria-label='close'] {
    width: 15px;
    height: 15px;
    font-weight: 100;
    font-size: 15px;
  }
`;

const TIME: number = 7000;

const ToastContainer = (): ReactElement => {
  return (
    <StyledToastContainer
      position={toast.POSITION.TOP_RIGHT}
      autoClose={TIME}
      closeOnClick={true}
      hideProgressBar={true}
    />
  );
};

export { ToastContainer };
