import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { clamp } from '../../../utils/index';
import { Icon } from '../../Icon/index';
import { Label, LabelProps } from '../HALabel/index';
import {
  Container,
  InputElement,
  InputNumberWrapper,
  Messure,
  Quantity,
  QuantityWrapper
} from './styles';

type EmptyFunc = () => void;
type Limit = {
  lower: number;
  upper: number;
};

type Props = LabelProps & {
  limits?: Limit;
  measure?: string;
  onChange?: (value: number) => void;
  onLower?: EmptyFunc;
  onUpper?: EmptyFunc;
  step?: number;
  value?: number;
  height?: string;
  width?: string;
  className?: string;
  withQuantityArrows?: boolean;
  label?: string;
};

const HANumberField = (props: Props): ReactElement => {
  const {
    className,
    onChange,
    onUpper,
    onLower,
    color,
    limits,
    measure,
    step = 1,
    value,
    height,
    width,
    withQuantityArrows = true,
    label
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [limit, setLimit] = useState<Limit>({} as Limit);

  // Effect in order to handle the changes of the value
  // from out of the component
  useEffect((): void => {
    setInputValue(value !== undefined ? value.toString() : '');
  }, [value]);

  // // Effect in order to handle the changes of the limits
  // // from out of the component
  useEffect((): void => {
    setLimit(limits as Limit);
  }, [limits]);

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>): void => {
      const newValue = Number(event.currentTarget.value);
      // // Control limits on change value
      if (
        (limit && clamp(newValue, limit.lower, limit.upper) === newValue) ||
        limits === undefined
      ) {
        if (typeof onChange === 'function') {
          onChange(newValue);
        }
      }
    },
    [inputValue, limits]
  );

  // Increment input value
  const handleUpper = useCallback((): void => {
    const newValue = Number(inputValue) + step;
    if ((limits && newValue <= limits.upper) || limits === undefined) {
      setInputValue(newValue.toString());
      if (typeof onUpper === 'function') {
        onUpper();
      }
      if (typeof onChange === 'function') {
        onChange(newValue);
      }
    }
  }, [inputValue]);

  // Decrease input value
  const handleLower = useCallback((): void => {
    const newValue = Number(inputValue) - step;
    if ((limits && newValue >= limits.lower) || limits === undefined) {
      if (typeof onLower === 'function') {
        onLower();
      }
      if (typeof onChange === 'function') {
        onChange(newValue);
      }
      setInputValue(newValue.toString());
    }
  }, [inputValue]);

  return (
    <Container width={width ? width : '100%'} className={className}>
      <Label
        className={className}
        color={color}
        name={''}
        label={label}
      />
      <InputNumberWrapper
        className={className}
        content={measure}
        width={width}
        height={height}
        withQuantityArrows={withQuantityArrows}
      >
        <InputElement
          onChange={handleChange}
          type="number"
          value={inputValue}
        />
        {measure && (
          <Messure>
            {measure}
          </Messure>
        )}
        {withQuantityArrows && (
          <QuantityWrapper>
            <Quantity onClick={handleUpper}>
              <Icon name="increase-decrease-arrow" />
            </Quantity>
            <Quantity decrease={true} onClick={handleLower}>
              <Icon name="increase-decrease-arrow" />
            </Quantity>
          </QuantityWrapper>
        )}
      </InputNumberWrapper>
    </Container>
  );
};

export { HANumberField };
