import isNil from 'lodash/isNil';
import {
  computed, observable
} from 'mobx';

export interface IWindDesignParametersData {
  readonly windSpeed?: number;
  readonly specialWindRegion?: string;
}

export class WindDesignParameters {
  /**
   * In miles per hour
   */
  @observable
  readonly windSpeed?: number;
  /**
   * Is the property in a special wind zone?
   * When a property is in a special wind zone, it means that local site conditions create higher wind situations
   * that are site-specific. Wind speed maps are inadequate in that case.
   */
  @observable
  private specialWindRegion?: string;

  constructor(data?: IWindDesignParametersData) {
    this.windSpeed = data?.windSpeed;
    this.specialWindRegion = data?.specialWindRegion;
  }

  toData = (): IWindDesignParametersData | undefined => {
    if (this.isEmpty) {
      return undefined;
    }
    return {
      windSpeed: this.windSpeed,
      specialWindRegion: this.specialWindRegion
    };
  };

  @computed
  get isEmpty(): boolean {
    return isNil(this.windSpeed) && isNil(this.specialWindRegion);
  }

  @computed
  get windSpeedAsText(): string {
    return `${this.windSpeed?.toString() ?? '--'} MPH`;
  }

  withWindSpeed = (windSpeed: number): WindDesignParameters =>
    new WindDesignParameters({
      ...(this.toData() ?? {}),
      windSpeed
    });
}
