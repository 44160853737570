import {
  action, computed, observable
} from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import type Limit from '../../../../../domain/models/Limit';
import {
  DEGREES_SYMBOL,
  DENOMINATOR_PITCH,
  SLOPE_CODE
} from '../../../../../ui/containers/RoofFaceProperties/constants';
import {
  degreesToPitch, pitchToDegrees
} from '../../../../../utils/slope';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import { isWithin } from '../../../../../domain/models/Limit';
import {
  ROOF_FACE_SLOPE_DEGREES_LIMIT,
  ROOF_FACE_SLOPE_FRACTION_DIGITS,
  ROOF_FACE_SLOPE_PITCH_LIMIT
} from '../../../../../domain/models/Constants';
import { UpdateRoofFacePropertyKey } from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';

interface ICustomSlopeInputProps {
  // Additional label inside input field
  insideInputRightLabel: string;

  // Label next to the input field on the right side
  outsideInputRightLabel: string;

  // Upper and lower limits for input field
  limits: Limit;
  precision: number;
  value: number;
}

export class SlopeRoofFaceViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = SLOPE_CODE;
  readonly slopeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

  // Defines which value we let user enter in custom slope textfield
  // true - value in degrees
  // false - integer pitch value x/12
  @observable
  isDegreesMode: boolean = false;

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.Slope;
  }

  @computed
  override get savePropertyButtonDisabled(): boolean {
    // Depends on this.isDegreesMode it may be either degrees or pitch value;
    // Limits also different for each case.
    return !isWithin(this.customSlopeInputProps.limits, this.customSlopeInputProps.value);
  }

  @computed
  get slopeValueInDegrees(): number {
    // In our database we save slope in degrees.
    // Fractional value can be always calculated later.
    // So this.value field should always be single source of truth.
    return this.value ? Number(this.value) : 0;
  }

  @computed
  get slopeValueInPitch(): number {
    // Integer numbers that would fit in x/12 format.
    // Our degrees value should always be a single source of truth,
    // so here we just convert degrees into desired format.
    return degreesToPitch(this.slopeValueInDegrees);
  }

  @computed
  get customSlopeInputProps(): ICustomSlopeInputProps {
    if (this.isDegreesMode) {
      return {
        insideInputRightLabel: DEGREES_SYMBOL,
        outsideInputRightLabel: `${this.slopeValueInPitch.toFixed(
          ROOF_FACE_SLOPE_FRACTION_DIGITS
        )}/${DENOMINATOR_PITCH}`,
        limits: ROOF_FACE_SLOPE_DEGREES_LIMIT,
        precision: ROOF_FACE_SLOPE_FRACTION_DIGITS,
        value: this.slopeValueInDegrees
      };
    } else {
      return {
        insideInputRightLabel: `/${DENOMINATOR_PITCH}`,
        outsideInputRightLabel: `${this.slopeValueInDegrees.toFixed(ROOF_FACE_SLOPE_FRACTION_DIGITS)}${DEGREES_SYMBOL}`,
        limits: ROOF_FACE_SLOPE_PITCH_LIMIT,
        precision: ROOF_FACE_SLOPE_FRACTION_DIGITS,
        value: this.slopeValueInPitch
      };
    }
  }

  /**
   * Runs when user selects predefined slope value using UI buttons
   * @param value - float value in degrees
   */
  @action
  selectPredefinedSlope = (value: number): void => {
    this.isDegreesMode = false;
    this.changeValue(value);
  };

  /**
   * Runs when user enters custom slope value in textfield
   * @param value can be both degrees or pitch depends on this.isDegreesMode value.
   */
  @action
  selectCustomSlope = (value: number): void => {
    const customSlopeValue = !this.isDegreesMode ? pitchToDegrees(value) : value;

    this.changeValue(customSlopeValue);
  };

  @action
  toggleCustomSlopeInputMode = (): void => {
    this.isDegreesMode = !this.isDegreesMode;
  };
}
