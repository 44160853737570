import {
  action, computed, observable
} from 'mobx';
import type { Dictionary } from '../../../domain/typings';

import type {
  BaseStepViewModel, IBaseStepViewModelDependencies
} from './ViewModels/BaseStepViewModel';

export class WizardStore {
  @computed
  get hasSteps(): boolean {
    return Object.keys(this.steps).length !== 0;
  }

  @computed
  get numberOfSteps(): number {
    return Object.keys(this.steps).length;
  }

  @computed
  get currentViewModel(): BaseStepViewModel {
    return this.steps[this.currentStep];
  }

  @computed
  get previousStepNumber(): number {
    const previousStep = this.currentStep - 1;
    return previousStep < 0 ? 0 : previousStep;
  }

  @computed
  get nextStepNumber(): number {
    const nextStep = this.currentStep + 1;
    const stepLimit = this.numberOfSteps - 1;
    return nextStep > stepLimit ? stepLimit : nextStep;
  }

  @observable
  currentStep: number = 1;

  /**
   * Sometimes we have a case where steps do not go in a linear sequence.
   * So `prevStep` field holds which step was before the current one.
   */
  @observable
  prevStep: number = 0;

  @observable
  private steps: Dictionary<BaseStepViewModel> = {};

  createWizard(initSteps: Dictionary<BaseStepViewModel> = {}): void {
    this.steps = initSteps;
  }

  /**
   * Factory used to create the desired
   * ViewModel with its dependencies
   *
   */
  @action.bound
  createStepViewModel<T extends BaseStepViewModel, K extends IBaseStepViewModelDependencies>(
    c: new (dep: K) => T,
    dependencies: K
  ): T {
    return new c(dependencies);
  }

  @action.bound
  createOneStepWizard<T extends BaseStepViewModel, K extends IBaseStepViewModelDependencies>(
    c: new (dep: K) => T,
    dependencies: K
  ): void {
    this.createWizard({
      1: this.createStepViewModel(c, dependencies)
    });
  }

  @action.bound
  changeStep(step: number, prevStep?: number): void {
    if (!this.hasSteps && !this.isValidStep) {
      return;
    }

    if (prevStep) {
      this.prevStep = prevStep;
    }

    this.currentStep = step;
  }

  @action.bound clear(): void {
    this.currentStep = 1;
    this.steps = {};
  }

  private isValidStep(step: number): boolean {
    return step in this.steps;
  }
}
