import {
  MeshBasicMaterial, SphereGeometry, Vector2, Vector3
} from 'three';
import type { SimpleVector2 } from '../../../../utils/ThreeUtils';
import { Vertex } from '../../../../domain/graphics/Vertex';
import ProjectionUtil from '../../../../utils/projectionUtil';
import type { ILineSegment } from '../../../../utils/spatial';
import {
  getCenterOfBoundingBoxAroundVertices,
  getClosestSegment,
  isILineSegment,
  projectPointOnEdge
} from '../../../../utils/spatial';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import { canvasConfig } from '../../../../config/canvasConfig';
import Units from '../../../../domain/typings/Units';
import type { Drawable } from '../../../../domain/mixins/Drawable';
import { Marker } from '../../../../domain/models/SiteDesign/Marker';
import { SceneObjectType } from '../../../../domain/models/Constants';
import {
  deleteItem, pushUniqueItem
} from '../../../../utils/helpers';
import {
  EPSILON, distanceBetweenPointAndLineSegment
} from '../../../../utils/math';
import type { Outline } from '../../../../domain/models/SiteDesign/Outline';
import PvModulePosition from '../../../../domain/models/SiteDesign/PvModulePosition';
import { RectangleProtrusion } from '../../../../domain/models/SiteDesign/RectangleProtrusion';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import { getParentLyraModelByMeshOrLyraModel } from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import type { ISmartGuideDependencies } from './BaseGuide';
import { BaseGuide } from './BaseGuide';

const KEYCODE_L = 76;

export class SnapGuide extends BaseGuide {
  icon = 'smartguide-snap';
  name = 'Line Snap';
  hotkey = 'S';
  override newCategory = true;
  commandKeyCode = KEYCODE_L;
  identifier = EGuideIdentifier.SNAP_LINES;
  kindSupport = [KindGuides.TRACE_TOOL, KindGuides.MOVE_OBJECT, KindGuides.MOVE_VERTEX];
  previewVertex?: Vertex;

  constructor(deps: ISmartGuideDependencies) {
    super(deps);
  }

  setup(): void {
    this.enableKeyListener();
    if (!this.previewVertex) {
      this.previewVertex = new Vertex();
      this.previewVertex.mesh.geometry = new SphereGeometry(2, 32, 32);
      this.previewVertex.mesh.material = new MeshBasicMaterial({
        color: canvasConfig.snapPointColor
      });
    }
    this.previewVertex.mesh.visible = false;
    this.previewVertex.unzoom(this.editor.scaleFactor * 2);
  }

  override hideUiElements(): void {
    this.previewVertex!.mesh.visible = false;
  }

  apply(applyParams: IApplyParams): IGuideResult {
    const {
      mousePos, kind, wipBoundary, wipObject, vertexObject, ignorePvModulePositionServerIds
    } = applyParams;

    this.editor.addOrUpdateObject(this.previewVertex!.mesh);
    if (kind === KindGuides.TRACE_TOOL && wipBoundary && mousePos) {
      const wipBoundaryType: SceneObjectType | undefined = getParentLyraModelByMeshOrLyraModel(wipBoundary)
        ?.type as SceneObjectType;
      return this.applyWithTraceTool(mousePos, wipBoundaryType);
    } else if (kind === KindGuides.MOVE_OBJECT && wipObject && vertexObject && mousePos) {
      return this.applyWithObject(mousePos, vertexObject, wipObject);
    } else if (kind === KindGuides.MOVE_OBJECT && wipObject && vertexObject) {
      return this.applyForPvModulePosition(vertexObject, wipObject, ignorePvModulePositionServerIds);
    } else if (kind === KindGuides.MOVE_VERTEX && wipBoundary && mousePos) {
      return this.applyWithVertex(mousePos, applyParams.vertexId!);
    } else {
      return {
        color: '',
        position: mousePos
      };
    }
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    this.previewVertex!.mesh.visible = false;
    this.editor.removeObject(this.previewVertex!.mesh);
  }

  private applyWithTraceTool(mousePos: Vector3, wipBoundaryType?: SceneObjectType): IGuideResult {
    this.previewVertex!.mesh.position.copy(mousePos);
    this.previewVertex!.unzoom(this.editor.scaleFactor * 2);

    const result = this.calculateResult(mousePos, undefined, wipBoundaryType);
    this.previewVertex!.mesh.visible = !!result.color;

    return result;
  }

  private applyWithVertex(mousePos: Vector3, currentVertexUuid: string): IGuideResult {
    this.previewVertex!.mesh.visible = true;

    this.previewVertex!.mesh.position.copy(mousePos);
    this.previewVertex!.unzoom(this.editor.scaleFactor * 2);

    return this.calculateResult(mousePos, currentVertexUuid);
  }

  private applyWithObject(mousePos: Vector3, vertexObject: Vector3[], wipPanel: Drawable): IGuideResult {
    if (wipPanel instanceof RectangleProtrusion) {
      // Only for testing if rework work fine
      const result: IGuideResult = {
        color: '',
        position: mousePos,
        objectVertex: getCenterOfBoundingBoxAroundVertices(vertexObject),
        lastAppliedGuideId: this.identifier
      };
      return result;
      // End for testing
    } else if (wipPanel instanceof Marker) {
      const result: IGuideResult = {
        color: '',
        position: mousePos,
        objectVertex: vertexObject[0],
        lastAppliedGuideId: this.identifier
      };

      const newPosition: Vector3 = this.applySnapBoundary(vertexObject[0], [SceneObjectType.Outline]);

      if (!vertexObject[0].equals(newPosition)) {
        result.snapOccurred = true;
      }

      if (result.objectVertex) {
        result.objectVertex = newPosition.clone();
      }

      return result;
    } else {
      throw new Error('Object not supported.');
    }
  }

  /**
   * Returns the distance between position1 and position2
   * The distance between 2 squares is the sum of the distances between their 2 closest vertices
   */
  private getDistanceBetweenPositions(position1: PvModulePosition, position2: PvModulePosition): number {
    const vertices1 = position1.boundary.vertices;
    const vertices2 = position2.boundary.vertices;

    const v1 = new Vector2(vertices1[0].x, vertices1[0].y);
    const v2 = new Vector2(vertices2[0].x, vertices2[0].y);

    const distances: number[] = [];

    // PvModulePositions have 5 vertices: the first and the last have the same values
    for (let i = 0; i < vertices1.length - 1; ++i) {
      v1.set(vertices1[i].x, vertices1[i].y);

      for (let j = 0; j < vertices2.length - 1; ++j) {
        v2.set(vertices2[j].x, vertices2[j].y);
        distances.push(v1.distanceTo(v2));
      }
    }

    const sortedDistances = distances.sort((a: number, b: number): number => a - b);
    return (sortedDistances[0] ?? 0) + (sortedDistances[1] ?? 0);
  }

  /**
   * @description `pvModulePositions` must NOT contain `wipPosition`, otherwise that will be returned obviously
   * @returns the closest PV module position to a given WIP position and close PV module position centers.
   */
  private getClosestAndClosePositions(
    wipPosition: PvModulePosition,
    pvModulePositions: PvModulePosition[]
  ): {
    closestPosition: PvModulePosition | null;
    closePositionsCenters: Vector2[];
  } {
    if (pvModulePositions.length > 0) {
      let closestPosition = null;
      const closePositionsCenters: Vector2[] = [];

      const { longestSide: closeDistance } = wipPosition.getPositionSizeWithSpacingAndLongestAndShortestSides();

      let minimumDistance = Infinity;
      for (const pvModulePosition of pvModulePositions) {
        const distance = this.getDistanceBetweenPositions(wipPosition, pvModulePosition);
        if (distance < minimumDistance) {
          minimumDistance = distance;
          closestPosition = pvModulePosition;
        }
        if (distance < closeDistance) {
          closePositionsCenters.push(
            this.convertIVecToVector(getCenterOfBoundingBoxAroundVertices(pvModulePosition.boundary.vertices))
          );
        }
      }

      return {
        closestPosition: closestPosition,
        closePositionsCenters
      };
    } else {
      return {
        closestPosition: null,
        closePositionsCenters: []
      };
    }
  }

  private getSnapLimitBetweenPositions(position1: PvModulePosition, position2: PvModulePosition): number {
    const sqPanel1Size = position1.getPositionSizeWithSpacing();
    const sqPanel2Size = position2.getPositionSizeWithSpacing();

    return Math.min((sqPanel1Size.x + sqPanel2Size.x) / 2, (sqPanel1Size.y + sqPanel2Size.y) / 2) / 3;
  }

  private convertIVecToVector(vec: SimpleVector2): Vector2 {
    return new Vector2(vec.x, vec.y);
  }

  /**
   * Returns the snapped position of a polygon next to the closest PV module position
   * @param points Vertices of the polygon
   * @param wipPvModulePosition The polygon object
   * @param ignorePvModulePositionServerIds selected PV module positions that should not snap to each other
   */
  private applyForPvModulePosition(
    points: Vector3[],
    wipPvModulePosition: Drawable,
    ignorePvModulePositionServerIds: string[] = []
  ): IGuideResult {
    const snapPoints: Vector3[] = [];
    const result: IGuideResult = {
      color: '',
      snapOccurred: false
    };

    if (wipPvModulePosition instanceof PvModulePosition && points.length > 0) {
      const wipPositionCenterPoint = this.convertIVecToVector(getCenterOfBoundingBoxAroundVertices(points));

      const pvModulePositions: PvModulePosition[] = this.editor
        .getObjectsByType<Drawable>(SceneObjectType.PvModulePosition, true)
        .filter(
          (item: Drawable): boolean => !ignorePvModulePositionServerIds.includes((item as PvModulePosition).serverId)
        ) as PvModulePosition[];

      const otherPvModulePositions: PvModulePosition[] = pvModulePositions.filter(
        (position: PvModulePosition): boolean => position !== wipPvModulePosition
      );

      const {
        closestPosition, closePositionsCenters
      } = this.getClosestAndClosePositions(
        wipPvModulePosition,
        otherPvModulePositions
      );

      if (closestPosition) {
        // Use PV module position centers as snapping targets
        const pvModulePositionPosToSnapTo = this.convertIVecToVector(
          getCenterOfBoundingBoxAroundVertices(closestPosition.boundary.vertices)
        );

        const vertices = wipPvModulePosition.boundary.vertices;

        if (vertices?.length > 3) {
          const {
            size: wipPositionSize,
            longestSide: largerSideOfWipPosition,
            shortestSide: smallerSideOfWipPosition
          } = wipPvModulePosition.getPositionSizeWithSpacingAndLongestAndShortestSides();
          const {
            size: closestPositionSize,
            longestSide: largerSideOfClosestPosition,
            shortestSide: smallerSideOfClosestPosition
          } = closestPosition.getPositionSizeWithSpacingAndLongestAndShortestSides();

          const nullVector = new Vector2(0, 0);

          // A third of a short side of a PV module
          const snapDistLimit = this.getSnapLimitBetweenPositions(wipPvModulePosition, closestPosition);

          const azimuth = wipPvModulePosition.azimuth;

          /*
           * Take the closest PV module position center and check four angles (sides) from it to compare
           * distances to WIP PV module. Initial angle is set from PV module azimuth and for each
           * direction as PV module width or length is added. Take the shortest direction that's shorter
           * than third of a PV module's short side.
           */
          const directionToCheck = new Vector2(0, 1);
          directionToCheck.rotateAround(nullVector, azimuth);

          let minimumDistance = Infinity;
          let snapPoint: SimpleVector2 = {
            x: -Infinity,
            y: -Infinity
          };

          const overrideMinDistanceAndSnapPointIfNecessary = (potentialSnapPoint: Vector2, distance: number): void => {
            if (distance >= minimumDistance) {
              return;
            }
            minimumDistance = distance;

            if (distance >= snapDistLimit) {
              return;
            }

            // Do not snap if snap point is center of another PV module position
            const snapOverExistingModule = !closePositionsCenters.every(
              (center: Vector2): boolean => center.distanceTo(potentialSnapPoint) > EPSILON
            );

            if (!snapOverExistingModule) {
              snapPoint = potentialSnapPoint;
            }
          };

          // Checking four sides:
          for (let i = 0; i < 4; ++i) {
            /*
             * Snap point is the center of the PV module position.
             * Here we're creating a vector that'd go from WIP module center
             * to the closest panel center. Summed with the closest PV module position's location
             * we get a distance between current WIP module location and possible snap location.
             * The shortest distance will signify the side of the closest panel we should
             * snap to (if the distance is less than snapDistLimit).
             */
            const possibleSnapDeltaVector = new Vector2()
              .copy(directionToCheck)
              .multiplyScalar(
                i % 2 === 0
                  ? (wipPositionSize.y + closestPositionSize.y) / 2
                  : (wipPositionSize.x + closestPositionSize.x) / 2
              );

            // Add distance checking vector to WIP panel center.
            const potentialSnapPoint = new Vector2().copy(pvModulePositionPosToSnapTo)
              .add(possibleSnapDeltaVector);
            let distance = wipPositionCenterPoint.distanceTo(potentialSnapPoint);

            overrideMinDistanceAndSnapPointIfNecessary(potentialSnapPoint, distance);

            // If we have rack spacing we should give an option to snap to rack spacing as well.
            if (wipPvModulePosition.rackSpacing > 0) {
              const rackSpacing = ProjectionUtil.convertToWorldUnits(wipPvModulePosition.rackSpacing, Units.Meters);
              const possibleSnapToRackSpacingDeltaVector = new Vector2()
                .copy(directionToCheck)
                .multiplyScalar(
                  (i % 2 === 0
                    ? (wipPositionSize.y + closestPositionSize.y) / 2
                    : (wipPositionSize.x + closestPositionSize.x) / 2) + rackSpacing
                );

              // We produced a possible snap point for snap to rack spacing. If it'll produce the shortest
              // distance from current location - we'll snap to it.
              const potentialSnapToRackSpacingPoint = new Vector2()
                .copy(pvModulePositionPosToSnapTo)
                .add(possibleSnapToRackSpacingDeltaVector);
              let distance = wipPositionCenterPoint.distanceTo(potentialSnapToRackSpacingPoint);

              overrideMinDistanceAndSnapPointIfNecessary(potentialSnapToRackSpacingPoint, distance);
            }

            /**
             * For mismatched orientations we're rotating snap points around WIP PV module
             * position center on 90% and adjust delta vector to account for short and
             * long sides for WIP PV module position and the closest PV module position.
             */
            if (wipPvModulePosition.orientation !== closestPosition.orientation) {
              let scalar = (largerSideOfWipPosition - smallerSideOfClosestPosition) / 2;

              if (
                (wipPvModulePosition.orientation === 'PORTRAIT' && i % 2 === 0)
                || (wipPvModulePosition.orientation === 'LANDSCAPE' && i % 2 === 1)
              ) {
                scalar = (largerSideOfClosestPosition - smallerSideOfWipPosition) / 2;
              }

              const normalV = new Vector2()
                .copy(possibleSnapDeltaVector)
                .rotateAround(nullVector, Math.PI / 2)
                .normalize()
                .multiplyScalar(scalar);

              const additionalPositionsToCheck: Vector2[] = [];

              // Same thing as we've seen previously, but here we'll get rotated and adjusted
              // for mismatched side sizes snap points.
              additionalPositionsToCheck.push(
                new Vector2().copy(pvModulePositionPosToSnapTo)
                  .add(normalV)
                  .add(possibleSnapDeltaVector)
              );
              additionalPositionsToCheck.push(
                new Vector2().copy(pvModulePositionPosToSnapTo)
                  .sub(normalV)
                  .add(possibleSnapDeltaVector)
              );

              // Find the closest snap point
              for (const additionalPosition of additionalPositionsToCheck) {
                distance = wipPositionCenterPoint.distanceTo(additionalPosition);
                overrideMinDistanceAndSnapPointIfNecessary(additionalPosition, distance);
              }

              if (wipPvModulePosition.rackSpacing > 0) {
                const rackSpacing = ProjectionUtil.convertToWorldUnits(
                  wipPvModulePosition.projectedRackSpacing,
                  Units.Meters
                );
                const possibleSnapToRackSpacingDeltaVector = new Vector2()
                  .copy(directionToCheck)
                  .multiplyScalar(
                    (i % 2 === 0
                      ? (wipPositionSize.y + closestPositionSize.y) / 2
                      : (wipPositionSize.x + closestPositionSize.x) / 2) + rackSpacing
                  );

                const normalVRackSpacing = new Vector2()
                  .copy(possibleSnapToRackSpacingDeltaVector)
                  .rotateAround(nullVector, Math.PI / 2)
                  .normalize()
                  .multiplyScalar(scalar);

                const additionalPositionsToCheck2: Vector2[] = [];

                additionalPositionsToCheck2.push(
                  new Vector2()
                    .copy(pvModulePositionPosToSnapTo)
                    .add(normalVRackSpacing)
                    .add(possibleSnapToRackSpacingDeltaVector)
                );
                additionalPositionsToCheck2.push(
                  new Vector2()
                    .copy(pvModulePositionPosToSnapTo)
                    .sub(normalVRackSpacing)
                    .add(possibleSnapToRackSpacingDeltaVector)
                );

                for (const additionalPosition of additionalPositionsToCheck2) {
                  distance = wipPositionCenterPoint.distanceTo(additionalPosition);
                  overrideMinDistanceAndSnapPointIfNecessary(additionalPosition, distance);
                }
              }
            }

            directionToCheck.rotateAround(nullVector, Math.PI / 2);
          }

          if (snapPoint.x > -Infinity && snapPoint.y > -Infinity) {
            // The PV module position snapped, so we can remove it from the ignore list so
            // that other selected PV module positions may snap to it.
            deleteItem<string>(ignorePvModulePositionServerIds, wipPvModulePosition.serverId);
            const currentPointToSnapPoint = {
              x: snapPoint.x - wipPositionCenterPoint.x,
              y: snapPoint.y - wipPositionCenterPoint.y
            };

            for (const point of points) {
              snapPoints.push(
                new Vector3(point.x + currentPointToSnapPoint.x, point.y + currentPointToSnapPoint.y, point.z)
              );
            }
            result.snapOccurred = true;
          } else {
            // The PV module position could be un-snapped, so we add it back to the ignore list so that other
            // selected PV module positions would not snap to it while moving the selection to another place.
            pushUniqueItem<string>(ignorePvModulePositionServerIds, wipPvModulePosition.serverId);
          }
        }
      }
    }

    result.objectVertex = getCenterOfBoundingBoxAroundVertices(snapPoints.length > 0 ? snapPoints : points);

    return result;
  }

  private calculateResult(
    mousePos: Vector3,
    currentVertexUuid?: string,
    wipBoundaryType?: SceneObjectType
  ): IGuideResult {
    const result: IGuideResult = {
      color: '',
      position: mousePos,
      lastAppliedGuideId: this.identifier
    };
    const {
      snapPointColor, smartSnapNearThreshold
    } = canvasConfig;
    const { scaleFactor } = this.editor;
    const boundariesToSnapTo = [SceneObjectType.Outline, SceneObjectType.RoofFace];

    if (wipBoundaryType === SceneObjectType.ParcelBoundary) {
      boundariesToSnapTo.push(SceneObjectType.ParcelBoundary);
    }

    result.position = this.applySnapBoundary(mousePos, boundariesToSnapTo, currentVertexUuid);
    const distance = result.position?.distanceTo(mousePos);
    if (0 < distance && distance <= smartSnapNearThreshold) {
      result.color = snapPointColor;
      result.snapOccurred = true;
    }

    if (result.color !== '') {
      this.previewVertex!.mesh.position.copy(result.position);
      this.previewVertex!.unzoom(scaleFactor);
      this.previewVertex!.mesh.position.setZ(smartSnapNearThreshold);
    }

    return result;
  }

  private applySnapBoundary(pointPosition: Vector3, boundaryTypes: string[], currentVertexUuid?: string): Vector3 {
    const closestResult = this.getClosestEdgeOrVertex(pointPosition, boundaryTypes, currentVertexUuid);
    if (closestResult instanceof Vector3) {
      return closestResult;
    } else if (isILineSegment(closestResult)) {
      const projectedVertex = projectPointOnEdge(pointPosition, closestResult.A, closestResult.B);

      if (projectedVertex) {
        return new Vector3(projectedVertex.x, projectedVertex.y, pointPosition.z);
      }
    }

    return pointPosition;
  }

  getClosestEdgeOrVertex(
    pointPosition: Vector3,
    boundaryTypes: string[],
    currentVertexId?: string
  ): ILineSegment | Vector3 | undefined {
    const outlinesAndRoofs: (RoofFace | Outline)[] = [];
    for (const boundaryType of boundaryTypes) {
      const boundaries = this.editor.getObjectsByType(boundaryType) as (Outline | RoofFace)[];
      const otherOutlines = currentVertexId
        ? boundaries.filter(
          (item: Outline | RoofFace): boolean =>
            !item.boundary.vertices.some(
              ({ serverId: vertexId }: { serverId: string }): boolean => vertexId === currentVertexId
            )
        )
        : boundaries;
      outlinesAndRoofs.push(...otherOutlines);
    }

    if (outlinesAndRoofs.length === 0) {
      return pointPosition;
    }

    const outlineWithMinSegDist: {
      object: Outline | RoofFace;
      segment: ILineSegment;
      distance: number;
    } = {
      object: outlinesAndRoofs[0],
      segment: {
        A: {
          x: 0,
          y: 0
        },
        B: {
          x: 0,
          y: 0
        }
      },
      distance: Infinity
    };

    const pointPositionV2 = new Vector2(pointPosition.x, pointPosition.y);
    const snapThreshold = canvasConfig.smartSnapNearThreshold;

    for (const outline of outlinesAndRoofs) {
      if (outline.boundary.vertices.length > 2) {
        const outlineVertices: Vertex[] = outline.getUniqueVertices();

        for (const vertex of outlineVertices) {
          // If close enough to the edge vertex, snap to the edge vertex.
          if (
            // Ignore currently dragged vertex.
            !(currentVertexId && vertex.serverId === currentVertexId)
            && pointPositionV2.distanceTo(new Vector2(vertex.x, vertex.y)) <= snapThreshold
          ) {
            return new Vector3(vertex.x, vertex.y, pointPosition.z);
          }
        }
        if (outline.boundary.closed) {
          const closestSegmentFromThisOutline = getClosestSegment(outlineVertices, pointPosition, currentVertexId);
          const distance = distanceBetweenPointAndLineSegment(
            pointPosition,
            new Vector2(closestSegmentFromThisOutline.A.x, closestSegmentFromThisOutline.A.y),
            new Vector2(closestSegmentFromThisOutline.B.x, closestSegmentFromThisOutline.B.y)
          );

          if (distance < outlineWithMinSegDist.distance) {
            outlineWithMinSegDist.distance = distance;
            outlineWithMinSegDist.object = outline;
            outlineWithMinSegDist.segment = closestSegmentFromThisOutline;
          }
        }
      }
    }

    if (outlineWithMinSegDist.distance <= snapThreshold) {
      return outlineWithMinSegDist.segment;
    }
  }
}
