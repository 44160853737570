export interface IEquipmentExposureData {
  type: string;

  readonly constrained: boolean;
}

export enum EquipmentExposureTypes {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR'
}
