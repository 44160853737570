import {
  action, computed, observable, reaction
} from 'mobx';
import debounce from 'lodash/debounce';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { DEFAULT_MAIN_BREAKER_RATING } from '../../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import { removePropertiesIfNullOrUndefined } from '../../../../../utils/helpers';
import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../../domain/typings';
import { getRootStore } from '../../../../RootStoreInversion';
import type { TProperties } from './MainBreakerViewModel';
import { MainBreakerViewModel } from './MainBreakerViewModel';
import type { MeterMainProps } from 'domain/models/SiteDesign/MeterMainProps';

export class MeterMainPropertiesViewModel extends MainBreakerViewModel {
  @observable
  serialNumber?: string;
  @observable
  electricServiceId?: string;

  readonly assignSerialNumber: (value: string, shouldUpdate: boolean) => void;
  readonly assignElectricServiceId: (value: string, shouldUpdate: boolean) => void;

  clearMainBreakerRatingOptionsAutoUpdate = reaction(
    (): [number?, boolean?] => [
      getRootStore().domain.siteEquipment.mainServicePanel?.busbarRating,
      getRootStore().domain.siteEquipment.mainServicePanel?.hasMainBreaker
    ],
    ([busbarRating, loadCenterHasMainBreaker]) => {
      const mainBreakerRating = this.mainBreakerRating?.value ?? 0;
      if (busbarRating && busbarRating !== mainBreakerRating) {
        this.loadMainBreakerRatingOptions(busbarRating).then(() => {
          if (busbarRating < +mainBreakerRating && !loadCenterHasMainBreaker) {
            this.setMainBreakerRating(this.mainBreakerRatingOptions[this.mainBreakerRatingOptions.length - 1].value);
          }
        });
      }
    }
  );

  constructor(editor: EditorStore, domain: DomainStore, serviceBus: ServiceBus) {
    super(editor, domain, serviceBus);

    this.assignSerialNumber = debounce((serialNumber: string, shouldUpdate: boolean): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          serialNumber
        },
        shouldUpdate
      );
    }, 300);

    this.assignElectricServiceId = debounce((electricServiceId: string, shouldUpdate: boolean = true): void => {
      this.setProperties(
        {
          ...this.getPropertiesForDomainObject(),
          electricServiceId
        },
        this.validateElectricServiceId(electricServiceId) && shouldUpdate
      );
    }, 300);
  }

  @computed
  get isElectricServiceIdFieldVisible(): boolean {
    return this.domain.project.site.address.province === 'TX';
  }

  @computed
  get isValidElectricServiceIdField(): boolean {
    return this.validateElectricServiceId(this.electricServiceId);
  }

  validateElectricServiceId(electricServiceId?: string): boolean {
    if (!electricServiceId) {
      return true;
    }
    const hasCorrectLength = electricServiceId.length === 17 || electricServiceId.length === 22;
    const hasNumbersOnly = /^\d+$/.test(electricServiceId);
    return hasCorrectLength && hasNumbersOnly;
  }

  @action.bound
  setProperties(utilityMeterProps?: MeterMainProps, shouldUpdate: boolean = true): void {
    this.serialNumber = utilityMeterProps?.serialNumber || undefined;
    this.electricServiceId = utilityMeterProps?.electricServiceId || undefined;
    this.setMainBreakerRating(utilityMeterProps?.mainBreakerRating, shouldUpdate);
    this.setMainBreakerAmpereInterruptingCapacity(
      utilityMeterProps?.mainBreakerAmpereInterruptingCapacity,
      shouldUpdate
    );
    if (shouldUpdate) {
      this.setPropertiesToDomain(this.getPropertiesForDomainObject());
    }
  }

  getPropertiesForDomainObject(): MeterMainProps {
    return {
      serialNumber: this.serialNumber || undefined,
      electricServiceId: this.electricServiceId || undefined,
      mainBreakerRating: Number(this.mainBreakerRating?.value || DEFAULT_MAIN_BREAKER_RATING),
      mainBreakerAmpereInterruptingCapacity: this.mainBreakerAmpereInterruptingCapacity
        ? Number(this.mainBreakerAmpereInterruptingCapacity.value)
        : undefined
    };
  }

  override async loadMainBreakerRatingOptions(busbarRating: number): Promise<void> {
    await this.designService
      // For standalone main breaker we don't care about keeping its current rating below busbar rating,
      // but it is important for meter-main
      .getMspMainBreakerRatingOptions(
        this.domain.siteEquipment.mainServicePanel?.hasMainBreaker ? undefined : busbarRating
      )
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerRatingOptions = this.mapToItemOption(response);
      });
  }

  @action.bound
  override setPropertiesFromDomainObject(): void {
    super.setPropertiesFromDomainObject();
    const meterMain = this.domain.siteEquipment.meterMain;
    this.assignElectricServiceId(meterMain?.electricServiceId ?? '', false);
    this.assignSerialNumber(meterMain?.serialNumber ?? '', false);
  }

  @action.bound
  override setPropertiesToDomain(properties: TProperties): void {
    const meterMain = this.domain.siteEquipment.meterMain!;
    const mergedMeterMain = Object.assign(meterMain, properties);
    removePropertiesIfNullOrUndefined(mergedMeterMain);
    this.serviceBus.send('update_equipment', {
      editor: this.editor,
      domain: this.domain,
      equipment: mergedMeterMain
    });
  }
}
