import React, { BaseProps, ReactElement } from 'react';
import styled from 'styled-components';

type Props = BaseProps & {
  name?: string;
  row?: string;
  col?: string;
  layer?: string;
};

const Area = styled.div<Props>`
  ${({ row }: Props): string => row ? `grid-row: ${row};` : ''}
  ${({ col }: Props): string => col ? `grid-column: ${col};` : ''}
  ${({ name }: Props): string => name ? `grid-area: ${name};` : ''}
  ${({ layer }: Props): string => layer ? `z-index: ${layer};` : 'z-index: 1;'}
`;

export { Area };
