export class BrowserWindow {
  // --------------------------------------------------------------------------------------------------
  // Scroll functions

  static getScrollX(): number {
    return window.scrollX || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
  }

  static getScrollY(): number {
    return window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }
}
