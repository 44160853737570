import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  Fragment, useState
} from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import type {
  IAcMicroinverterBranchOptionsData,
  IStringingOptionData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import useStore from '../../../../stores/useStore';
import type {
  StringConnected,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import { precisionNumber } from '../../../../utils/helpers';
import {
  DCACRatioContainer,
  DCACRatioProgressBarContainer,
  DCACRatioTitle,
  DCACRatioTitleContainer
} from './MicroinverterStyle';
import {
  ContentItem,
  ContentText,
  DeleteButtonWrapper,
  DeleteIcon,
  ItemText,
  DcOptimizerIcon,
  DcOptimizerItem,
  DcOptimizerText,
  DcOptimizerWrapper,
  PanelHeader,
  PanelText,
  ProgressItemContainer,
  TextWrapper,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperValueText
} from './styles';

const {
  CircularProgressBar, StepDottedProgressBar, DottedProgressBar
} = LyraProgressBar;

type Props = BaseProps & {
  index: number;
  onDeleteActiveBranch: () => void;
};

const DEFAULT_MAX_VALUE = 10;
const VOLTS_MAX_VALUE = 60;

const MicroInvertersInBranch = observer((props: Props): ReactElement => {
  let numberOfMicroinvertersInBranch = 0;
  let maxContinuousOutputCurrent = 0;
  let maxOutputPower: number | undefined;
  const {
    index = 0, onDeleteActiveBranch
  } = props;
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;
  const definition = systemSummaryList[index].definition as IAcMicroinverterBranchOptionsData;
  const {
    microinverterDefinition, pvInput
  } = definition;

  const stringsConnected = systemSummaryList[index].strings;

  if (stringsConnected.length) {
    const existingString = stringsConnected.find((item: StringConnected): boolean => item.getId() === selectedString);
    const stringingOption: IStringingOptionData | undefined =
      (existingString?.stringingOption as IStringingOptionData) ?? undefined;

    numberOfMicroinvertersInBranch = stringingOption?.numberOfMicroinverters ?? 0;
    maxContinuousOutputCurrent = stringingOption?.maxContinuousOutputCurrent ?? 0;
    maxOutputPower = stringingOption?.maxOutputPower ?? 0;
  }
  const minInputVoltage = microinverterDefinition.minInputVoltage ?? VOLTS_MAX_VALUE / 3;
  const maxInputVoltage = microinverterDefinition.maxInputVoltage ?? (VOLTS_MAX_VALUE / 3) * 2;

  const vmpAtDesignHighTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Not Optimal Voltage, But Still Functional',
      color: '#90C725',
      value: maxInputVoltage - 1
    }
  ];

  const vocAtDesignLowTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Upper Voltage',
      color: '#D34A4A',
      value: maxInputVoltage - 1
    }
  ];
  return (
    <Fragment>
      <ContentItem>
        <Flexbox justify="space-between" align="center" className="pt-xs">
          <ContentText className="mt-xxs">Microinverters in branch</ContentText>
          {numberOfMicroinvertersInBranch > 0 && (
            <DeleteButtonWrapper onClick={onDeleteActiveBranch}>
              <DeleteIcon name="icon-delete" />
            </DeleteButtonWrapper>
          )}
        </Flexbox>
        <ProgressItemContainer>
          <StepDottedProgressBar
            currentValue={numberOfMicroinvertersInBranch}
            minValue={0}
            maxValue={microinverterDefinition?.maxNumberOfMicroinvertersPerBranch ?? DEFAULT_MAX_VALUE}
            possibleMinValue={0}
            possibleMaxValue={1 + (microinverterDefinition?.maxNumberOfMicroinvertersPerBranch ?? DEFAULT_MAX_VALUE)}
            size="small"
          />
        </ProgressItemContainer>

        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>AC Current Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{maxContinuousOutputCurrent}</ValueText>
            <UnitText>A</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>

        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>AC Power Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{maxOutputPower ?? 0}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        <PanelHeader className="mt-s" black>
          <PanelText>Microinverter</PanelText>
        </PanelHeader>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>Modules in parallel</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{microinverterDefinition?.numberOfPvInputs ?? 0}</ValueText>
          </WrapperValueText>
        </ValueItemWrapper>

        <DCACRatioContainer>
          <DCACRatioTitleContainer>
            <DCACRatioTitle>DC-AC Ratio</DCACRatioTitle>
          </DCACRatioTitleContainer>
          <DCACRatioProgressBarContainer>
            <DottedProgressBar
              currentValue={getDcAcRatio(definition)}
              units={'%'}
              minValue={0}
              maxValue={100}
              possibleMaxValue={getMaxDcAcRatioAsPercentage(definition)}
              strokeColor={'#36A247'}
              style={{
                primaryFontSize: '8px',
                secondaryFontSize: '10px',
                barHeight: '14.6px'
              }}
            />
          </DCACRatioProgressBarContainer>
        </DCACRatioContainer>

        <DcOptimizerWrapper>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={pvInput.vmpAtDesignHighTemperature}
              markers={vmpAtDesignHighTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
            />
            <TextWrapper>
              <DcOptimizerIcon name="highTemp" />
              <DcOptimizerText>Vmp at high temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={pvInput.vocAtDesignLowTemperature}
              markers={vocAtDesignLowTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
              outOfRangeLabelColor="#D34A4A"
            />
            <TextWrapper>
              <DcOptimizerIcon name="lowTemp" />
              <DcOptimizerText>Voc at low temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
        </DcOptimizerWrapper>
      </ContentItem>
    </Fragment>
  );
});

const getDcAcRatio = (definition: IAcMicroinverterBranchOptionsData): number => {
  const ratedPowerOutput = definition.microinverterDefinition.ratedPowerOutput ?? 1;
  const pStc = definition.pvInput.pStc ?? 0;
  const numberOfPvInputs = definition.microinverterDefinition.numberOfPvInputs ?? 1;
  return precisionNumber((pStc * numberOfPvInputs * 100) / ratedPowerOutput, 2);
};

const getMaxDcAcRatioAsPercentage = (definition: IAcMicroinverterBranchOptionsData): number => {
  const maxDcAcRoundedValue = Math.round(definition.microinverterDefinition?.maxDcAcRatio * 100);
  return maxDcAcRoundedValue || 100;
};
export default MicroInvertersInBranch;
