import React, { BaseProps, ReactElement } from 'react';
import { EditButton } from '../EditButton';
import { Icon } from '../Icon';
import {
  LabelAndIcon,
  LabelContainer,
  Percent,
  ProgressBarContainer,
  RangeBar,
  RangeContainer,
  Target,
  Wrapper
} from './styles';

type BasicProps = BaseProps & {
  label?: string;
  units?: string;
  minProgressBar?: number;
  fractionDigits?: number;
  onEditClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

type RangeProps = BasicProps & {
  range: number;
  progressBar: number;
};

type ProgressProps = BasicProps & {
  value: number;
  percentRange: number;
};

const FULL_PERCENTAGE: number = 100;

const Range = ({
  className,
  minProgressBar,
  fractionDigits = 1,
  range,
  progressBar,
  units}: RangeProps
): ReactElement => {
  const currentPercentage = range * progressBar / FULL_PERCENTAGE;
  const isCompleted = progressBar >= FULL_PERCENTAGE;
  const progressBarWidth = progressBar > 100 ? 100 : progressBar;

  return (
    <RangeContainer progressBar={progressBarWidth} className={className}>
      <Percent isCompleted={isCompleted}>
        {`${progressBar ? progressBar.toFixed(0) : 0}%`}
      </Percent>
      <RangeBar />
      <Target isCompleted={isCompleted}>
        {
          minProgressBar && (progressBar <= minProgressBar)
            ? `0 ${units}`
            : `${currentPercentage.toFixed(fractionDigits)} ${units}`
        }
      </Target>
    </RangeContainer>
  );
};

const ProgressBar =
  ({
    className,
    value,
    percentRange,
    label,
    units,
    minProgressBar,
    fractionDigits,
    onEditClick
  }: ProgressProps)
: ReactElement => {
  const isCompleted = percentRange >= FULL_PERCENTAGE;

  return (
    <Wrapper className={className}>
      {label &&
        <LabelContainer>
          <LabelAndIcon>
            <p>{label.toUpperCase()}</p>
            <div>
              <span>{`${value} ${units}`}</span>
              {isCompleted && <Icon name="icon-tick" />}
            </div>
          </LabelAndIcon>
          <EditButton onEditClick={onEditClick} />
        </LabelContainer>
      }
      <ProgressBarContainer>
        <Range
          range={value}
          progressBar={percentRange}
          units={units}
          minProgressBar={minProgressBar}
          fractionDigits={fractionDigits}
        />
      </ProgressBarContainer>
    </Wrapper>
  );
};

export { ProgressBar };
