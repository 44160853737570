import React, { BaseProps, ReactElement } from 'react';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { Direction, Line, Title, Wrapper } from './styles';

export type DividerProps = BaseProps & {
  direction?: Direction;
  thickness?: string;
  length?: string;
  color?: EThemeComponentColor;
  title?: string;
};

const Divider = (props: DividerProps): ReactElement => {
  const {
    className = '',
    direction = 'horizontal',
    thickness = '1px',
    length = '100%',
    color = EThemeComponentColor.GRAY_700,
    title = '',
    styles
  } = props;

  return (
    <Wrapper
      className={className}
      direction={direction}
      thickness={thickness}
      length={length}
      style={styles}
    >
      <Line
        direction={direction}
        thickness={thickness}
        length={length}
        colorTheme={color}
      />
      {!!title.length && <Title className="divider-title">{title}</Title>}
    </Wrapper>
  );
};

export { Divider };
