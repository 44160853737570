import type {
  BaseProps, ReactElement
} from 'react';
import React, { useCallback } from 'react';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit/';
import Pill from '../Pill';
import {
  Description, ModuleCardStyle, PillsWrapper
} from './styles';

type Props = BaseProps & {
  pvModuleDefinitionId: string;
  titleAsString: string;
  titleAsHtml: string | ReactElement[];
  pills: ReactElement[][];
  description: string;
  onSelected?: (titleAsString: string, pvModuleDefinitionId: string) => void;
};

const ModuleCard = (props: Props): ReactElement => {
  const {
    description, pvModuleDefinitionId, onSelected, pills, titleAsHtml, titleAsString
  } = props;

  const handleClick = useCallback((): void => {
    onSelected?.(titleAsString, pvModuleDefinitionId);
  }, [onSelected, titleAsString, pvModuleDefinitionId]);

  const card = (pillArr: ReactElement[][]): ReactElement[] =>
    pillArr.map((label: ReactElement[], index: number): ReactElement => <Pill key={index} label={label} />);

  return (
    <ModuleCardStyle onClick={handleClick}>
      <LyraTypography.TextLayout type="h6" margin="0">
        {titleAsHtml}
      </LyraTypography.TextLayout>
      <PillsWrapper>{card(pills)}</PillsWrapper>
      <Description>{description}</Description>
    </ModuleCardStyle>
  );
};

export default ModuleCard;
