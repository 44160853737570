import {
  action, computed, observable
} from 'mobx';

import uniqBy from 'lodash/uniqBy';
import type { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import { DesignStep } from '../../models/Design/DesignState';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import { canvasConfig } from '../../../config/canvasConfig';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type {
  IControlDragging,
  IControlSelectionChange,
  IPointerHoveringControlEvent
} from '../../../stores/EditorStore/Controls/ControlEvents';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import EGuideIdentifier from '../../../stores/UiStore/SmartGuidesStore/EGuideIdentifier';
import {
  ADD_MODULE_ID,
  CHANGE_ORIENTATION_ID,
  PANNING_TOOL_ID,
  REMOVE_MODULE_ID,
  REVIEW_CIRCUITS_ID,
  SELECT_TOOL_ID,
  STRINGING_ID
} from '../../../stores/UiStore/ToolbarStore/Design/constants';
import type {
  IHandleDragTool,
  IHandleHoverTool,
  IHandleSelectionTool
} from '../../../stores/UiStore/ToolbarStore/Tool';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import {
  calculateEnergyProductionEstimate,
  deleteItem,
  handleApiError,
  notify,
  pushUniqueItem
} from '../../../utils/helpers';
import {
  ERROR, SceneObjectType
} from '../../models/Constants';
import type { Design } from '../../models/Design/Design';
import type { PvModuleData } from '../../models/PvSystem/PvModule';
import type { RoofFace } from '../../models/SiteDesign/RoofFace';
import PvModulePosition from '../../models/SiteDesign/PvModulePosition';
import { DesignReadiness } from '../../models/SiteDesign/DesignReadiness';
import PvModule from '../../models/SiteDesign/PvModule';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import { KeyboardListener } from '../../../utils/KeyboardListener';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import type { Draggable } from '../../mixins/Draggable';
import type { Drawable } from '../../mixins/Drawable';
import type { Selectable } from '../../mixins/Selectable';
import type { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { getParentLyraModelByMesh } from '../../sceneObjectsWithLyraModelsHelpers';
import { DragBehaviour } from '../../behaviour/DragBehaviour';
import { HoverBehaviour } from '../../behaviour/HoverBehaviour';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../behaviour/KeyboardBehaviour';
import { SelectionBehaviour } from '../../behaviour/SelectionBehaviour';
import {
  EnergyProductionLossesUpdatedEvent,
  LayoutDesignCompleted
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import type { IUpdatePvModulePositions } from '../../../stores/ServiceBus/Commands/UpdatePvModulePositionsCommand';
import type { IDeletePvModulesAndPositionsCommandDependencies } from '../../../stores/ServiceBus/Commands/DeletePvModulesPositionsCommand';
import config from '../../../config/config';

export interface ILayoutDesignDependencies {
  editor: EditorStore;
  domain: DomainStore;
  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;
  toolbar: ToolbarStore;
  guidelines: SmartGuidesStore;
}

export class LayoutDesignStage
implements IProgressStepperStage, IHandleHoverTool, IHandleSelectionTool, IKeyboardBehaviourHandler, IHandleDragTool {
  static readonly toolWhitelist: string[] = [
    ADD_MODULE_ID,
    CHANGE_ORIENTATION_ID,
    PANNING_TOOL_ID,
    REMOVE_MODULE_ID,
    SELECT_TOOL_ID
  ];

  static readonly toolBlacklist: string[] = [PANNING_TOOL_ID, REVIEW_CIRCUITS_ID, SELECT_TOOL_ID, STRINGING_ID];

  readonly propCodeUI = PropsPanelUICodes.LayoutDesign;
  readonly title = 'Layout Design';
  readonly id: DesignStep = DesignStep.LAYOUT_DESIGN;
  readonly editor: EditorStore;

  @observable
  energyProductionEstimate: number = 0;
  @observable
  totalModules?: number;
  @observable
  private areas!: RoofFace[];
  private isDragActive: boolean = false;
  private readonly designWorkspace: DesignWorkspace;
  private readonly domain: DomainStore;
  private readonly serviceBus: ServiceBus;
  private selectionList: Selectable[];
  private readonly toolbar: ToolbarStore;
  private readonly guidelines: SmartGuidesStore;
  private readonly designService = new DesignService();
  private hasMissingProperties: boolean = false;
  private readonly dragBehaviour: DragBehaviour;
  private readonly selectionBehaviour: SelectionBehaviour;
  private readonly hoverBehaviour: HoverBehaviour;
  /**
   * @name selectionChangeLock
   * @description selectionChangeLock differs from isDragActive in that it's
   * enabled after the fact of a drag event, as opposed to isDragActive,
   * that's enabled after click event.
   */
  private selectionChangeLock: boolean = false;
  /**
   * @description this list contains selected PV module positions' serverIds. It's used for
   * snap guides to avoid snapping between selected PV module positions.
   */
  private selectedPvModulePositionServerIds: string[] = [];

  constructor(dependencies: ILayoutDesignDependencies) {
    const {
      designWorkspace, domain, editor, serviceBus, toolbar, guidelines
    } = dependencies;

    this.designWorkspace = designWorkspace;
    this.domain = domain;
    this.editor = editor;
    this.serviceBus = serviceBus;
    this.guidelines = guidelines;
    this.toolbar = toolbar;
    this.selectionList = [];
    this.dragBehaviour = new DragBehaviour(this.editor, guidelines);
    this.selectionBehaviour = new SelectionBehaviour(this.editor);
    this.hoverBehaviour = new HoverBehaviour(this.editor);
  }

  @computed
  get domainModel(): Design {
    return this.domain.design;
  }

  @action.bound
  updatePvSystemEnergyProductionLosses(value: number): void {
    const IDesignData = this.domainModel.toData();
    this.designService
      .updatePvSystemEnergyProductionLosses(value, IDesignData)
      .then((response: DesignDelta): void => {
        this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        this.getEnergyProductionEstimate();
        config.analytics?.trackEvent(new EnergyProductionLossesUpdatedEvent(this.domain));
      })
      .catch(handleApiError('Failed to update PV system energy production losses'));
  }

  getEnergyProductionEstimate(): void {
    const roofTopArrayAreas = this.domainModel.roofTopArrayAreas;
    const system = this.domainModel.system;

    if (roofTopArrayAreas) {
      const {
        energyProductionEstimate, totalModules
      } = calculateEnergyProductionEstimate(roofTopArrayAreas, system);

      this.energyProductionEstimate = energyProductionEstimate;
      this.totalModules = totalModules;
    }
  }

  get canContinue(): boolean {
    return !this.hasMissingProperties;
  }

  continue(): void {
    const {
      pvModuleDefaultColor, pvModulePositionDefaultColor
    } = canvasConfig;

    this.selectionList.forEach((selected: Selectable): void => {
      selected.setSelectedMode(false);
      if (selected instanceof PvModule) {
        selected.changeMeshMaterial(pvModuleDefaultColor);
      }
      if (selected instanceof PvModulePosition) {
        selected.changeMeshMaterial(pvModulePositionDefaultColor);
      }
    });
    this.toolbar.deselectTool();
    this.disposeEvents();
    this.selectionBehaviour.unselectAllObjects();
    this.selectionList = [];
    config.analytics?.trackEvent(new LayoutDesignCompleted(this.domain));
  }

  cancel(): void {
    const system = this.domainModel.system;

    this.toolbar.deselectTool();
    system.equipment.pvModules?.instances?.forEach((pvModule: PvModuleData): void => {
      this.undrawPvModule(pvModule);
    });
    this.disposeEvents();

    this.guidelines.disableLineSnapGuide();
  }

  setUp = (): void => {
    this.setDesignState();
    this.enableTools();
    this.enableGuidelines();

    this.setupArrayAreas();
    this.setupModules();
    this.showPvModulePositions();
    this.updateAllPositionsCollision(this.editor);
    this.toolbar.activateToolInDesignWorkspaceWithoutClick(SELECT_TOOL_ID, this);
    this.editor.renderSiteMarkers(this.designWorkspace);
  };

  setUpTool = (toolId: string): void => {
    switch (toolId) {
    case SELECT_TOOL_ID: {
      const objectsToInteractInThisStage = [
        ...this.editor.getObjectsByType<PvModulePosition>(SceneObjectType.PvModulePosition, true),
        ...this.editor.getObjectsByType<PvModule>(SceneObjectType.PvModule, true)
      ];

      KeyboardBehaviour.addKeyboardEvents(this);
      this.dragBehaviour.addDragEvents(this);
      this.hoverBehaviour.addHoverEvents(this);
      this.selectionBehaviour.addSelectionChangeEvent(this);

      const recursive = false;
      this.dragBehaviour.setTargetObjects(objectsToInteractInThisStage, recursive);
      this.hoverBehaviour.setTargetObjects(objectsToInteractInThisStage, recursive);
      this.selectionBehaviour.setTargetObjects(objectsToInteractInThisStage, recursive);
      this.guidelines.enableLineSnapGuide();
      break;
    }

    case ADD_MODULE_ID:
    case CHANGE_ORIENTATION_ID:
    case REMOVE_MODULE_ID: {
      this.disposeEvents();
      break;
    }

    default:
      break;
    }
  };

  setupModules = (): void => {
    const system = this.domainModel.system;
    system.equipment.pvModules?.instances?.forEach((pvModule: PvModuleData): void => {
      this.drawPvModule(pvModule);
    });
    this.getEnergyProductionEstimate();
  };

  disposeEvents = (): void => {
    this.selectionBehaviour.removeSelectionChangeEvent(this);
    this.dragBehaviour.removeDragEvents(this);
    this.hoverBehaviour.removeHoverEvents(this);
    KeyboardBehaviour.removeKeyboardEvents(this);
  };

  dispose = (): void => {
    this.cancel();
  };

  resume(lastValidStage: string): void {
    if (lastValidStage === this.id) {
      this.setUp();
    } else {
      this.setupArrayAreas();
      this.setupModules();
    }
  }

  async beforeContinue(continueBackwards: boolean = false): Promise<void> {
    if (!continueBackwards) {
      await this.validateMissingProperties();
    }
  }
  async validateMissingProperties(): Promise<void> {
    const nextStep = this.designWorkspace.stageManager!.currentIndex + 1;
    const nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);
    if (nextStage) {
      const missingPropertiesResponse = await this.designService
        .getDesignMissingProperties(this.domainModel.id, nextStage.id)
        .catch(handleApiError('Failed to get missing design properties'));
      const designReadiness = new DesignReadiness({
        missingPropertiesResponse
      });
      this.hasMissingProperties = designReadiness.hasMissingProperties();
      designReadiness.createNotifications();

      if (!this.arePositionsCollisionFree()) {
        notify('Create a valid array layout before moving to the next step!', ERROR);
        this.hasMissingProperties = true;
      }
    }
  }

  onSelectionChange = (event: IControlSelectionChange): void => {
    const selected = event.selection;
    const unselected = event.unselected;

    this.handleSelected(selected ?? []);

    /**
     * We can add elements to selected when dragging: let's say you started dragging by
     * grabbing an unselected element, so it'd be fine if we'll visually select it on drag start.
     * But it'd look bad if we'll unselect element on drag start. That's why we run un-selection on
     * mouse up event. Also, during drag a currently dragged element is added to the ignore list
     * in selection control, so that un-selection will not be run with it. As a fail-safe mechanism we
     * also have {@see selectionChangeLock} to ensure that un-selection won't be run during drag.
     */
    if (this.selectionChangeLock && unselected?.length) {
      // Drag is active, set unselected back to selected
      this.selectionBehaviour.addToSelectedItems(unselected ?? []);
      this.dragBehaviour.setMultiSelectedObject(
        this.selectionList.filter(
          (item: Selectable): boolean => (item as Draggable).isDraggable === true
        ) as Draggable[]
      );
      return;
    }

    this.handleUnselected(unselected ?? []);
  };

  private handleSelected(selectedList: Selectable[]): void {
    selectedList.forEach((selected: Selectable): void => {
      if (selected instanceof PvModule) {
        selected.updateEdgeFactor(this.editor.scaleFactor);
        pushUniqueItem<string>(
          this.selectedPvModulePositionServerIds,
          getParentLyraModelByMesh<PvModulePosition>(selected.mesh).serverId
        );
        selected.setSelectedMode(true);
        selected.updateColor();
      }

      if (selected instanceof PvModulePosition) {
        pushUniqueItem<string>(this.selectedPvModulePositionServerIds, selected.serverId);
        selected.setSelectedMode(true);
        selected.updateColor();
      }

      this.selectionList = uniqBy([...this.selectionList, selected], 'serverId');
    });
    this.dragBehaviour.setSnapIgnoreServerIds(this.selectedPvModulePositionServerIds);
  }

  private handleUnselected(unselectedList: Selectable[]): void {
    const collidableObjects: PolygonDrawable[] = this.editor.getCollidableObjects();

    unselectedList.forEach((unselected: Selectable): void => {
      if (unselected instanceof PvModule || unselected instanceof PvModulePosition) {
        unselected.updateCanMove(this.editor, undefined, collidableObjects);
        unselected.isDragging = false;
        unselected.updateColor();
        unselected.setSelectedMode(false);
        unselected.updateColor();

        if (unselected instanceof PvModule) {
          unselected.updateEdgeFactor(this.editor.scaleFactor);
          deleteItem<string>(
            this.selectedPvModulePositionServerIds,
            getParentLyraModelByMesh<PvModulePosition>(unselected.mesh).serverId
          );
        }
      }
      if (unselected instanceof PvModulePosition) {
        deleteItem<string>(this.selectedPvModulePositionServerIds, unselected.serverId);
      }

      this.selectionList = this.selectionList.filter(
        (drawable: Selectable): boolean => drawable.serverId !== unselected.serverId
      );
    });

    this.dragBehaviour.setMultiSelectedObject(
      this.selectionList.filter((item: Selectable): boolean => (item as Draggable).isDraggable === true) as Draggable[]
    );

    this.dragBehaviour.setSnapIgnoreServerIds(this.selectedPvModulePositionServerIds);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    const { pvModuleDefaultColor } = canvasConfig;

    const key = event.key;
    if (key === 'Escape') {
      this.selectionList.forEach((objectSelected: Selectable): void => {
        objectSelected.setSelectedMode(false);
        if (objectSelected instanceof PvModule) {
          objectSelected.changeMeshMaterial(pvModuleDefaultColor);
        }
      });
      this.selectionBehaviour.unselectAllObjects();
    }
  };

  onKeyUp = (event: KeyboardEvent): void => {
    const key = event.key;
    if (key === KeyboardListener.KEY_BACKSPACE || key === KeyboardListener.KEY_DELETE) {
      const pvModulesToDelete: PvModule[] = this.selectionList.filter(
        (element: Selectable): boolean => element instanceof PvModule
      ) as unknown as PvModule[];
      const pvModulePositionsToDelete: PvModulePosition[] = this.selectionList.filter(
        (element: Selectable): boolean => element instanceof PvModulePosition
      ) as unknown as PvModulePosition[];

      const serverIdsOfRemovedSelectables: string[] = [
        ...pvModulesToDelete.map((module: PvModule): string => module.serverId),
        ...pvModulePositionsToDelete.map((position: PvModulePosition): string => position.serverId)
      ];

      if (serverIdsOfRemovedSelectables.length > 0) {
        const commandDependencies: IDeletePvModulesAndPositionsCommandDependencies = {
          currentStage: this,
          positionsToDelete: pvModulePositionsToDelete,
          positionsToDeletePvModulesIn: pvModulesToDelete
            .filter((pvModule: PvModule): boolean => !pvModule.isDetached)
            .map((pvModule: PvModule): PvModulePosition => pvModule.pvModulePosition),
          domain: this.domain
        };
        this.serviceBus.send('delete_pv_modules_and_module_positions', commandDependencies);
      }
      this.selectionList = [];

      const filterFn: (object: Selectable) => boolean = (object: Selectable): boolean =>
        !serverIdsOfRemovedSelectables.includes(object.serverId);
      this.selectionBehaviour.filterAndUpdateTargetObjects(filterFn);
      this.dragBehaviour.filterAndUpdateTargetObjects(filterFn);
      this.hoverBehaviour.filterAndUpdateTargetObjects(filterFn);

      this.selectionBehaviour.unselectAllObjects();
    }
  };

  onObjectHoverIn = (event: IPointerHoveringControlEvent): void => {
    if (!this.isDragActive) {
      const hoveredObject = event.hoverObject;

      if (hoveredObject instanceof PvModule || hoveredObject instanceof PvModulePosition) {
        hoveredObject.setHoverableMode(true);
        hoveredObject.updateColor();
      }
    }
  };

  onObjectHoverOut = (event: IPointerHoveringControlEvent): void => {
    const hoveredObject = event.hoverObject;

    if (hoveredObject instanceof PvModule || hoveredObject instanceof PvModulePosition) {
      hoveredObject.setHoverableMode(false);
      hoveredObject.updateColor();
    }
  };

  onDragStart = (event: IControlDragging): void => {
    this.isDragActive = true;
  };

  onDrag = (event: IControlDragging): void => {
    this.selectionChangeLock = true;
    // Add to ignore list so that after drag a dragged item stays selected
    this.selectionBehaviour.ignoreMouseUp(event.object as unknown as Selectable);
  };

  onDragEnd = (event: IControlDragging): void => {
    this.selectionChangeLock = false;
    this.isDragActive = false;
    if (!(event.object instanceof PvModule) && !(event.object instanceof PvModulePosition)) {
      return;
    }

    const pvModulePositions: PvModulePosition[] = [];
    for (let draggedObject of event.selectedObjects ?? []) {
      if (draggedObject instanceof PvModule && getParentLyraModelByMesh(draggedObject.mesh)) {
        // Changing draggedObject to its parent - PvModulePosition, so that we can update its position
        draggedObject = getParentLyraModelByMesh(draggedObject.mesh);
      }

      if (draggedObject instanceof PvModulePosition) {
        pvModulePositions.push(draggedObject);
        draggedObject.updateZValuesAfterMove();
      }
    }

    if (pvModulePositions.length > 0) {
      const commandDependencies: IUpdatePvModulePositions = {
        domain: this.domain,
        pvModulePositions
      };
      this.serviceBus.send('update_pv_module_position', commandDependencies);
    }

    this.updateAllPositionsCollision(this.editor);
  };

  /**
   * Update all positions collision
   */
  updateAllPositionsCollision(editor: EditorStore): void {
    const pvModulePositions: PvModulePosition[] = editor
      .getObjectsByType(SceneObjectType.PvModulePosition, true)
      .filter(
        (pvModulePosition: Drawable): boolean => !(pvModulePosition as PvModulePosition).isPvModulePositionPreview
      ) as PvModulePosition[];

    const collidableObjects: PolygonDrawable[] = [
      ...pvModulePositions,
      ...editor.getObjectsByTypes([SceneObjectType.Setback, SceneObjectType.Pathway, SceneObjectType.Protrusion], true)
    ];

    for (const pvModulePosition of pvModulePositions) {
      if (pvModulePosition.pvModule) {
        pvModulePosition.pvModule.updateCanMove(this.editor, undefined, collidableObjects);
        pvModulePosition.pvModule.updateColor();
      } else {
        pvModulePosition.updateCanMove(this.editor, undefined, collidableObjects);
        pvModulePosition.updateColor();
      }
    }
  }

  arePositionsCollisionFree(): boolean {
    this.updateAllPositionsCollision(this.editor);
    const pvModulePositions: PvModulePosition[] = this.editor.getObjectsByType(SceneObjectType.PvModulePosition, true);
    let areCollisionFree: boolean = true;
    for (const position of pvModulePositions) {
      if (position.isColliding && !position.isPvModulePositionPreview) {
        areCollisionFree = false;
        break;
      }
    }
    return areCollisionFree;
  }

  private showPvModulePositions(): void {
    const pvModulePositions: PvModulePosition[] = this.editor.getObjectsByType(SceneObjectType.PvModulePosition, true);

    for (const pvModulePosition of pvModulePositions) {
      pvModulePosition.show();
    }
  }

  private setupArrayAreas(): void {
    const allRoofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace, true);
    this.areas = allRoofFaces.filter((roofFace: RoofFace): boolean =>
      this.domainModel.roofTopArrayAreas.hasLayoutOn(roofFace.serverId)
    );
  }

  private drawPvModule(pvModule: PvModuleData): void {
    this.areas.forEach((roofFace: RoofFace): void => {
      const existingPvModule = roofFace.pvModulePositions.find(
        (pvModulePosition: PvModulePosition): boolean => pvModulePosition.serverId === pvModule.positionId
      );
      if (existingPvModule) {
        existingPvModule.show();
        existingPvModule.addPvModule(
          new PvModule({
            id: pvModule.id,
            positionVertices: existingPvModule.polygon
          })
        );
      }
    });
  }

  private undrawPvModule(pvModule: PvModuleData): void {
    this.areas.forEach((item: RoofFace): void => {
      const existingPvModule: PvModulePosition | undefined = item.pvModulePositions.find(
        (pvModulePosition: PvModulePosition): boolean => pvModulePosition.serverId === pvModule.positionId
      );
      existingPvModule?.deletePvModule();
    });
  }

  private enableTools(): void {
    this.toolbar.whitelistTools(LayoutDesignStage.toolWhitelist);
    this.toolbar.blacklistTools(LayoutDesignStage.toolBlacklist);
  }

  private enableGuidelines(): void {
    this.guidelines.enable = true;
    this.guidelines.initGuides([EGuideIdentifier.SNAP_LINES, EGuideIdentifier.EXTENSION_LINES]);
  }

  private setDesignState(): void {
    const dependencies = this.domainModel.state.withUserState(this.id).toUpdateStateCommand(this.domain);
    this.serviceBus.send('update_design_state', dependencies);
  }
}
