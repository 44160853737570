import {
  action, computed
} from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import {
  ERoofSlopeType, ERoofType
} from '../../../../../domain/typings';
import type DomainStore from '../../../../DomainStore/DomainStore';
import {
  UpdateRoofFacePropertyKey,
  type IUpdateRoofFacePropertyDependencies
} from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type { RoofFacePropertiesStore } from '../RoofFacePropertiesStore';
import type { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';

export type RoofTypeUI = { id: string; icon: string; label: string };

export class TypeRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();

  readonly ROOF_TYPE_OPTIONS: RoofTypeUI[] = [
    {
      id: ERoofType.FLAT,
      icon: 'roof-type-flat',
      label: 'Flat'
    },
    {
      id: ERoofType.SLOPED,
      icon: 'roof-type-sloped',
      label: 'Sloped'
    }
  ];

  private readonly domain: DomainStore;
  private readonly roofFaceProps: RoofFacePropertiesStore;
  private readonly serviceBus: ServiceBus;
  private readonly roofFacePropertyViewModelsRefs?: {
    [key: string]: BaseRoofFaceViewModel;
  };

  constructor(
    domain: DomainStore,
    roofFaceProps: RoofFacePropertiesStore,
    serviceBus: ServiceBus,
    roofFacePropertyViewModelsRefs:
      | {
          [key: string]: BaseRoofFaceViewModel;
        }
      | undefined
  ) {
    this.domain = domain;
    this.roofFaceProps = roofFaceProps;
    this.serviceBus = serviceBus;
    this.roofFacePropertyViewModelsRefs = roofFacePropertyViewModelsRefs;
  }

  @computed
  get currentRoofTypeUI(): RoofTypeUI {
    return this.ROOF_TYPE_OPTIONS.find(
      (roof: RoofTypeUI): boolean => roof.id === this.roofFaceProps.firstRoofFaceSelected!.roofType
    )!;
  }

  @action.bound
  changeRoofType(roofTypeSelected: ERoofType): void {
    if (this.roofFace.roofType === roofTypeSelected) {
      // Current roof face type is already selected - no need to change anything
      return;
    }

    this.roofFaceProps.slopeRoofFaceViewModel!.changeValue(undefined);
    this.roofFaceProps.azimuthRoofFaceModel!.changeValue(undefined);
    if (roofTypeSelected === ERoofType.FLAT) {
      this.roofFaceProps.firstRoofFaceSelected!.roofType = ERoofType.FLAT;
      this.roofFaceProps.firstRoofFaceSelected!.slopeType = ERoofSlopeType.LowSlope;
    } else {
      this.roofFaceProps.firstRoofFaceSelected!.roofType = ERoofType.SLOPED;
    }
    const dependencies: IUpdateRoofFacePropertyDependencies = {
      domain: this.domain,
      roofFace: this.roofFace,
      key: UpdateRoofFacePropertyKey.RoofType,
      value: roofTypeSelected,
      roofFacePropertyViewModelsRefs: this.roofFacePropertyViewModelsRefs
    };
    this.serviceBus.send('update_roof_face_property', dependencies);
    this.roofFaceProps.lastInteractedRoofFace = this.roofFace;
  }

  private get roofFace(): RoofFace {
    return this.roofFaceProps.currentSelectedRoofFaces[0];
  }
}
