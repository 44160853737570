import {
  action, computed, observable
} from 'mobx';
import type { IPvModulesData } from './PvModules';
import { PvModules } from './PvModules';
import { Inverters } from './Inverters';
import type { IInvertersData } from './Inverters';
import type { IMountingSystemsData } from './MountingSystems';
import { MountingSystems } from './MountingSystems';
import type { BackupStrategy } from './PvSystem';
import type { IDcOptimizersData } from './DcOptimizers';
import { DcOptimizers } from './DcOptimizers';
import type { IAcCoupledEnergyStorageSystemsData } from './AcCoupledEnergyStorageSystems';
import { AcCoupledEnergyStorageSystems } from './AcCoupledEnergyStorageSystems';

export interface PowerConversionAndStorageEquipmentDefinition {
  readonly inverterDefinitionIds: readonly string[];
  readonly optimizerDefinitionId?: string;
  readonly energyStorageAndBackup?: {
    readonly backupStrategy: BackupStrategy;
    readonly acCoupledEnergyStorageSystems?: readonly string[];
  };
}

export interface IEquipmentData {
  readonly mountingSystems: IMountingSystemsData;
  readonly pvModules: IPvModulesData;
  readonly inverters?: IInvertersData;
  readonly optimizers?: IDcOptimizersData;
  readonly acCoupledEnergyStorageSystems?: IAcCoupledEnergyStorageSystemsData;
}

export class Equipment {
  @observable
  mountingSystems: MountingSystems;
  @observable
  pvModules: PvModules;
  @observable
  inverters?: Inverters;
  optimizers?: DcOptimizers;
  acCoupledEnergyStorageSystems?: AcCoupledEnergyStorageSystems;

  constructor(data: IEquipmentData) {
    this.mountingSystems = new MountingSystems(data.mountingSystems);
    this.pvModules = new PvModules(data.pvModules);
    this.optimizers = data.optimizers ? new DcOptimizers(data.optimizers) : undefined;
    this.inverters = data.inverters ? new Inverters(data.inverters) : undefined;
    this.acCoupledEnergyStorageSystems = data.acCoupledEnergyStorageSystems
      ? new AcCoupledEnergyStorageSystems(data.acCoupledEnergyStorageSystems)
      : undefined;
  }

  toData(): IEquipmentData {
    return {
      mountingSystems: this.mountingSystems.toData(),
      pvModules: this.pvModules.toData(),
      inverters: this.inverters?.toData(),
      optimizers: this.optimizers?.toData(),
      acCoupledEnergyStorageSystems: this.acCoupledEnergyStorageSystems?.toData()
    };
  }

  doesPowerConversionEquipmentMatch = (specification: PowerConversionAndStorageEquipmentDefinition): boolean => {
    const invertersMatch = this.inverters?.match(specification.inverterDefinitionIds) ?? false;
    const dcPowerOptimizersMatch = this.optimizers?.definition === specification.optimizerDefinitionId;
    const specifiedEnergyStorageSystems = specification.energyStorageAndBackup?.acCoupledEnergyStorageSystems ?? [];
    const energyStorageSystemsMatch =
      this.acCoupledEnergyStorageSystems?.match(specifiedEnergyStorageSystems)
      ?? specifiedEnergyStorageSystems.length === 0;
    return invertersMatch && dcPowerOptimizersMatch && energyStorageSystemsMatch;
  };

  @action
  deletePvModulesInPositions = (positionIds: string[]): void => {
    this.pvModules.deletePvModulesInPositions(positionIds);
    this.mountingSystems.convertInstancesToTemplatesIfNeeded(this.pvModules.idsOfMountingSystemsInUse);
  };

  @computed
  get hasDcOptimizerDefinition(): boolean {
    return !!this.optimizers?.definition;
  }
}
