import config, { UI_MODE } from '../../../config/config';
import Registry from '../../../utils/Registry';
import EquipmentPanel from './EquipmentPanel/EquipmentPanel';
import MpptAndMultiInverterSelectionPanel from './MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanel';
import SystemSummaryPanel from './SystemSummaryPanel';

const panelRegistry = new Registry();

if (config.featureFlag.uiMode !== UI_MODE.AURORA) {
  panelRegistry.add('equipment_panel', EquipmentPanel);
  panelRegistry.add('system_summary_panel', SystemSummaryPanel);
  panelRegistry.add('MPPT_and_multi_inverter_selection_panel', MpptAndMultiInverterSelectionPanel);
}

export { panelRegistry };
