// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import createProps from '../createProps.js';
import {
  ColumnSizeType, ViewportSizeType
} from '../types.js';

const propTypes = {
  xs: ColumnSizeType,
  sm: ColumnSizeType,
  md: ColumnSizeType,
  lg: ColumnSizeType,
  xl: ColumnSizeType,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  first: ViewportSizeType,
  last: ViewportSizeType,
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};

const classMap = {
  xs: 'col-xs',
  sm: 'col-sm',
  md: 'col-md',
  lg: 'col-lg',
  xl: 'col-xl',
  xsOffset: 'col-xs-offset',
  smOffset: 'col-sm-offset',
  mdOffset: 'col-md-offset',
  lgOffset: 'col-lg-offset',
  xlOffset: 'col-xl-offset'
};

function isInteger(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

function getColClassNames(props: Record<string, unknown>): string[] {
  const extraClasses = [];

  if (props.className) {
    extraClasses.push(props.className);
  }

  if (props.first) {
    extraClasses.push(`first-${ props.first}`);
  }

  if (props.last) {
    extraClasses.push(`last-${ props.last}`);
  }

  return Object.keys(props)
    .filter((key: string): string => classMap[key])
    .map((key: string): string => isInteger(props[key]) ? (`${classMap[key] }-${ props[key]}`) : classMap[key])
    .concat(extraClasses);
}

export function getColumnProps(props: Record<string, unknown>): Record<string, unknown> {
  return createProps(propTypes, props, getColClassNames(props));
}

export default function Col(props: Record<string, unknown>): React.ReactElement {
  const {
    tagName, ...columnProps
  } = props;
  return React.createElement(tagName || 'div', getColumnProps(columnProps));
}

Col.propTypes = propTypes;
