import styled, { PropsTheme } from 'styled-components';
import { Flexbox, Row, Col } from '../Layout';
import { Color } from '../../index';
import { H2, H4, Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon';

type Props = PropsTheme & {
  selected: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
`;

export const GalleryWrapper = styled.div`

`;

export const GalleryHeader = styled(Flexbox).attrs({
  align: 'center'
})`
  position: relative;
  border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  padding-bottom: 10px;
`;

export const GalleryHeaderTile = styled(H2)`
  font-size: 26px;
`;

export const GalleryHeaderDescription = styled(H4)`
  position: absolute;
  right: 0;
`;

export const GalleryContent = styled(Row)`
`;

export const GalleryItem = styled(Col)`
  margin-top: 15px;
  cursor: pointer;
  position: relative;
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  left: 17px;
`;

export const GalleryItemImage = styled.img<Props>`
  width: 100%;
  height: 165px;
  object-fit: cover;
  box-sizing: border-box;
  border: ${({ selected, theme }: Props): Color => selected ? `3px solid ${theme.colors.main1}` : 'none'};
`;

export const GalleryItemTitle = styled(Paragraph)`
  margin-top: 10px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const GalleryItemDescription = styled(Paragraph)`
  margin-top: 5px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const CommentItem = styled(Col)`
  margin-top: 15px;
`;

export const ContentView = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor8};
  height: 171px;
`;

export const ContentText = styled(Paragraph)`
  margin-left: 30px;
  margin-right: 30px;
`;
