import type { Vector3 } from 'three';
import type EditorStore from '../../../../stores/EditorStore/EditorStore';
import type { Building } from '../Building';
import type { Vertex } from '../../../graphics/Vertex';
import type { RoofFace } from '../RoofFace';
import type { SiteEquipmentUnionType } from '../SiteEquipment';
import { pointInsidePolygon } from '../../../../utils/spatial';
import type { RoofProtrusion } from '../RoofProtrusion';

export function moveBuildingToANewPosition(deltaVector: Vector3, building: Building, editor: EditorStore): SiteEquipmentUnionType[] {
  const equipmentPlural = editor.domain.siteEquipmentModels;
  const capturedEquipment: SiteEquipmentUnionType[] = [];

  // Move outline
  if (building?.roofOutline) {
    for (const equipment of equipmentPlural) {
      if (pointInsidePolygon(
        building.roofOutline.boundary.vertices.map((vertex: Vertex): Vector3 => vertex.getVector3()),
        equipment.mesh.position,
        {
          onEdgeConsideredInside: true,
          noErrorMargin: false
        }
      )
      ) {
        capturedEquipment.push(equipment);
      }
    }

    const lastOneIsRefToFirstOne =
      building.roofOutline.boundary.vertices[0]
      === building.roofOutline.boundary.vertices[building.roofOutline.boundary.vertices.length - 1];
    building.roofOutline.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
      if (index === 0 && lastOneIsRefToFirstOne) {
        return;
      }
      vertex.mesh.position.sub(deltaVector);
    });
    building.roofOutline.updateMesh();
  }

  // move all building.roofFaces
  building?.roofFaces.forEach((roofFace: RoofFace): void => {
    // First collect equipment located over a roof (before it's location is updated)
    for (const equipment of equipmentPlural) {
      const position = equipment.mesh?.position;
      if (position
        && pointInsidePolygon(roofFace.boundary.vector3s, position, {
          onEdgeConsideredInside: true,
          noErrorMargin: false
        })
        && capturedEquipment.findIndex(
          (equipmentToFind: SiteEquipmentUnionType) => equipmentToFind.serverId === equipment.serverId
        ) === -1
      ) {
        capturedEquipment.push(equipment);
      }
    }

    // Move roof faces
    const firstVertex = roofFace.boundary.vertices[0];
    const lastVertex = roofFace.boundary.vertices[roofFace.boundary.vertices.length - 1];
    const lastOneIsRefToFirstOne = firstVertex === lastVertex;
    roofFace.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
      if (index === 0 && lastOneIsRefToFirstOne) {
        return;
      }
      vertex.mesh.position.sub(deltaVector);
    });
    roofFace.updateMesh();
    roofFace.updateArrowLocation();

    // Move protrusions
    roofFace.protrusions.forEach((protrusion: RoofProtrusion): void => {
      const lastOneIsRefToFirstOne =
        protrusion.boundary.vertices[0] === protrusion.boundary.vertices[protrusion.boundary.vertices.length - 1];
      protrusion.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
        if (index === 0 && lastOneIsRefToFirstOne) {
          return;
        }
        vertex.mesh.position.sub(deltaVector);
      });
      protrusion.updateMesh();
    });
  });

  return capturedEquipment;
}

export function isBuildingPositionValid(editor: EditorStore, building: Building): boolean {
  const allEdgeVertices: Vertex[] = [
    ...(building?.roofFaces ?? []).flatMap((roofFace: RoofFace): Vertex[] => roofFace.boundary.vertices),
    ...(building?.roofOutline?.boundary.vertices ?? [])
  ];

  for (const vertex of allEdgeVertices) {
    const hasEverBeenValidAfterUserInteraction = vertex.hasEverBeenValidAfterUserInteraction;
    const isValidPosition = vertex.isValidCurrentPosition(editor);
    if (hasEverBeenValidAfterUserInteraction && !isValidPosition) {
      // // Uncomment to see why validation fails:
      // vertex.debugValidation = true;
      // vertex.isValidCurrentPosition(editor);

      return false;
    }
  }

  return true;
}

export function moveEquipmentWithBuilding(
  deltaVector: Vector3,
  capturedEquipment: SiteEquipmentUnionType[]
): void {
  capturedEquipment.forEach((equipment: SiteEquipmentUnionType): void => {
    equipment.mesh.position.sub(deltaVector);
  });
}

export function revertMoveBuildingToANewPosition(deltaVector: Vector3, building: Building): void {
  // Move outline
  if (building?.roofOutline) {
    const lastOneIsRefToFirstOne =
      building.roofOutline.boundary.vertices[0]
      === building.roofOutline.boundary.vertices[building.roofOutline.boundary.vertices.length - 1];
    building.roofOutline.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
      if (index === 0 && lastOneIsRefToFirstOne) {
        return;
      }
      vertex.mesh.position.add(deltaVector);
    });
    building.roofOutline.updateMesh();
  }

  // Move roof faces
  building?.roofFaces.forEach((roofFace: RoofFace): void => {
    const lastOneIsRefToFirstOne =
      roofFace.boundary.vertices[0] === roofFace.boundary.vertices[roofFace.boundary.vertices.length - 1];
    roofFace.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
      if (index === 0 && lastOneIsRefToFirstOne) {
        return;
      }
      vertex.mesh.position.add(deltaVector);
    });
    roofFace.updateMesh();
    roofFace.updateArrowLocation();

    // Move protrusions
    roofFace.protrusions.forEach((protrusion: RoofProtrusion): void => {
      const lastOneIsRefToFirstOne =
        protrusion.boundary.vertices[0] === protrusion.boundary.vertices[protrusion.boundary.vertices.length - 1];
      protrusion.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
        if (index === 0 && lastOneIsRefToFirstOne) {
          return;
        }
        vertex.mesh.position.add(deltaVector);
      });
      protrusion.updateMesh();
    });
  });
}
