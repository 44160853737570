import {
  LyraFormElementsDesignTool, LyraLayout, LyraModal, LyraLoader, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import type {
  IDocumentsIncludes,
  IDocumentsOptions,
  IDocumentsSystem
} from '../../../../domain/entities/Documents/DocumentsResponse';
import type { ConceptDesignOptionViewModel } from '../../../../stores/UiStore/Modal/ViewModels/ConceptDesignOption/ConceptDesignOptionViewModel';
import useStore from '../../../../stores/useStore';
import {
  GroupWrapper,
  Input,
  InputLabel,
  InputWrapper,
  LeftView,
  LogoView,
  MainText,
  ModalContainer,
  RadioWrapper,
  RightView,
  StatusText
} from './styles';

const { Toggle } = LyraFormElementsDesignTool.FormElementsDesignTool;

const ConceptDesignOptionModal = (): ReactElement => {
  const { modal } = useStore();
  const conceptDesignOptionViewModel = modal.viewModel as ConceptDesignOptionViewModel;

  const {
    formContent, setFieldValue
  } = conceptDesignOptionViewModel;
  const {
    includes = {} as IDocumentsIncludes,
    options = {} as IDocumentsOptions,
    system = {} as IDocumentsSystem
  } = formContent;

  const getToggleComponent = (title: string, field: string, value: boolean): ReactElement => (
    <LogoView height="46px">
      <LeftView>
        <MainText>{title}</MainText>
      </LeftView>
      <RightView>
        <RadioWrapper>
          <StatusText status={!value}>No</StatusText>

          <Toggle.Toggle
            className="ml-xxs mr-xxs"
            id={field + Math.random()}
            name={field + Math.random()}
            checked={value}
            onChange={(): void => setFieldValue(field, value)}
          />
          <StatusText status={value}>Yes</StatusText>
        </RadioWrapper>
      </RightView>
    </LogoView>
  );

  const getInputComponent = (title: string, field: string, value: number | string, label?: string): ReactElement => (
    <LogoView height="46px">
      <LeftView>
        <MainText>{title}</MainText>
      </LeftView>

      <RightView>
        <InputWrapper>
          <Input
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setFieldValue(field, e.target.value);
            }}
          />
          <InputLabel>{label}</InputLabel>
        </InputWrapper>
      </RightView>
    </LogoView>
  );

  const modalProps = {
    title: 'Options',
    leftButtonLabel: 'Cancel',
    rightButtonLabel: conceptDesignOptionViewModel.generatingDocument ? 'Downloading...' : 'Download',
    rightButtonDisabled:
      !conceptDesignOptionViewModel.documentGenerationOptionsLoaded || conceptDesignOptionViewModel.generatingDocument,
    $show: true,
    height: '100%',
    onClickCloseButton: (): void => conceptDesignOptionViewModel.closeModal(),
    onClickLeftButton: (): void => conceptDesignOptionViewModel.closeModal(),
    onClickRightButton: (): void => conceptDesignOptionViewModel.downloadConceptDesign()
  };

  return (
    <LyraModal.Modal {...modalProps}>
      {conceptDesignOptionViewModel.documentGenerationOptionsLoaded ? (
        <LyraLayout.ContainerWithTheme>
          <ModalContainer>
            <LyraTypography.TextLayout type="h2" margin={'10px 0 0'} lineHeight={1.5} align="center">
              Concept Design
            </LyraTypography.TextLayout>
            <GroupWrapper>
              {includes.hasOwnProperty('logo') && getToggleComponent('Logo:', 'includes.logo', includes.logo)}
              {getInputComponent('Project Reference ID:', 'options.projectReferenceId', options.projectReferenceId)}

              {includes.hasOwnProperty('emptyFields')
                && getToggleComponent('Include Empty Fields:', 'includes.emptyFields', includes.emptyFields)}

              {includes.hasOwnProperty('billOfMaterials')
                && getToggleComponent('Include Bill of Materials:', 'includes.billOfMaterials', includes.billOfMaterials)}

              {includes.hasOwnProperty('customerApprovalBox')
                && getToggleComponent(
                  'Include Customer Approval Box:',
                  'includes.customerApprovalBox',
                  includes.customerApprovalBox!
                )}

              {system.hasOwnProperty('annualProductionEstimate')
                && getInputComponent(
                  'Annual Production (kwh):',
                  'system.annualProductionEstimate',
                  system.annualProductionEstimate,
                  'kwh'
                )}

              {includes.hasOwnProperty('debugFiles')
                && getToggleComponent('Include Debug Files:', 'includes.debugFiles', includes.debugFiles!)}
            </GroupWrapper>
          </ModalContainer>
        </LyraLayout.ContainerWithTheme>
      ) : (
        <LyraLoader.Loader width="470px" text="Loading..." />
      )}
    </LyraModal.Modal>
  );
};

export default observer(ConceptDesignOptionModal);
