import type { BaseProps } from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

type Props = BaseProps & {
  height?: string;
  margin?: string;
  visible?: boolean;
};

// Create an HR styled component
const LineVertical = styled.div<Props & PropsTheme<BaseProps>>`
  background-color: ${({ theme }: PropsTheme<BaseProps>): Color => theme.colors.lineHr || ''};
  border: none;
  width: 1px;
  visibility: ${({ visible = true }: { visible?: boolean }): string => (visible ? 'visible' : 'hidden')};
  height: ${({ height = '80%' }: Props): string => height};
  margin: ${({ margin = '0 10px' }: Props): string => margin};
`;
export default LineVertical;
