import Registry from '../../../utils/Registry';
import DesignToolbar from './DesignToolbar/DesignToolbar';
import ProjectToolbar from './ProjectToolbar/ProjectToolbar';
import MapControl from './DesignToolbar/MapControl';

const toolbarRegistry = new Registry();

toolbarRegistry.add('toolbar_design', DesignToolbar);
toolbarRegistry.add('toolbar_project', ProjectToolbar);
toolbarRegistry.add('map_control', MapControl);

export { toolbarRegistry };
