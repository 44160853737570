import type { JSONSchema7Type } from 'json-schema';
import { arrayIncludesAll } from '../engine/util';
import type { FormFieldProp } from '../FormOptionsRulesAndState';

export default {
  always: (_: unknown) => true, // extend predicates library to include 'always' unary.
  never: (_: unknown) => false, // useful for debugging
  true: (entity: unknown) => !!entity,
  false: (entity: unknown) => !entity,
  includesAllTags: (
    fieldVal: JSONSchema7Type, tags: string[], fieldSchema: FormFieldProp
  ) => {
    const index = fieldSchema.enum!.indexOf(fieldVal);
    const valueTags = fieldSchema.enumTags![index];
    if (Array.isArray(valueTags)) {
      return arrayIncludesAll(
        valueTags, tags
      );
    }
    return false;
  }
};
