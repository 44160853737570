import React, { BaseProps, ReactElement } from 'react';
import { ImageContainer, LogoIcon, NavContainer, SideBarContainer } from './styles';

const SideBar = ({children, className}: BaseProps): ReactElement => {
  return (
    <SideBarContainer className={className}>
      <ImageContainer dir="row">
        <LogoIcon name="logo-white" />
      </ImageContainer>
      <NavContainer>
        {children}
      </NavContainer>
    </SideBarContainer>
  );
};

export { SideBar };
