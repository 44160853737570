import {
  LyraDivider, LyraLoader, LyraFormElementsDesignTool, LyraModal, LyraDropzone
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { FileError } from 'react-dropzone';
import { useUiStore } from '../../../../stores/useStore';
import type ViewUploadImage from '../../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import { notify } from '../../../../utils/helpers';
import { ERROR } from '../../../../domain/models/Constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
`;

const Input = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

const acceptedFormats = { 'image/*': ['.png', '.jpeg', '.jpg', '.gif'] };

const UploadImageModal: React.FC = (): ReactElement => {
  const { modal } = useUiStore();
  const viewModel = modal.viewModel as ViewUploadImage;

  const modalProps = {
    $show: true,
    title: 'Add Site Images',
    color: EThemeComponentColor.PURPLE,
    leftButtonLabel: 'CANCEL',
    rightButtonLabel: 'ADD',
    rightButtonDisabled: !viewModel.canSave || viewModel.isSaving,
    onClickLeftButton: (): void => viewModel.closeModal(),
    onClickRightButton: (): void => viewModel.save(),
    onClickCloseButton: (): void => viewModel.closeModal()
  };
  const tenMegabytesAsBytes = 10 * Math.pow(1024, 2);
  return (
    <LyraModal.Modal {...modalProps}>
      <Container>
        {viewModel.isSaving ? (
          <LyraLoader.Loader text="Uploading site images... " />
        ) : (
          <>
            <LyraDropzone.DropzoneContainer
              accept={acceptedFormats}
              maxSize={tenMegabytesAsBytes}
              canSave={viewModel.canSave}
              fileName={viewModel.getFileName}
              supportedFormat="JPG / PNG"
              onError={(rejectedFiles: FileError): void => {
                notify(rejectedFiles.message, ERROR);
              }}
              saveFiles={(files: File[]): void => {
                viewModel.setFile(files[0]);
              }}
            />
            <LyraDivider.Divider className="mt-s mb-xs" color={EThemeComponentColor.GRAY_200} direction="horizontal" />
            <Input
              label="NAME"
              name="nameImage"
              value={viewModel.imageTitle}
              disabled={viewModel.isSaving}
              onChange={viewModel.setTitle}
            />
            <div className="mt-xs">
              <Input
                label="DESCRIPTION"
                name="descriptionImage"
                value={viewModel.imageDescription}
                disabled={viewModel.isSaving}
                onChange={viewModel.setDescription}
              />
            </div>
          </>
        )}
      </Container>
    </LyraModal.Modal>
  );
};

export default observer(UploadImageModal);
