import flatMap from 'lodash/flatMap';
import isNil from 'lodash/isNil';
import type { IObservableArray } from 'mobx';
import {
  action, computed, observable
} from 'mobx';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import type {
  Coords, BaseImageryProvider
} from '../../../domain/typings';
import type { Vertex } from '../../graphics/Vertex';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { SceneObjectType } from '../Constants';
import {
  convertMeterToFeet, convertFeetToMeters
} from '../../../utils/Convertions';
import type { Address } from './Address';
import type { Building } from './Building';
import { Parcel } from './Parcel';
import { SiteEquipment } from './SiteEquipment';
import type { SiteImage } from './SiteImage';
import type { RoofFace } from './RoofFace';

export class Site {
  /**
   * Site address
   */
  address!: Address;

  /**
   * Site lat lon coordinates
   */
  coordinateSystemOrigin: Coords = {} as Coords;

  /**
   * Elevation in meters
   */
  @observable
  elevation?: number;

  /**
   * Site buildings
   */
  @observable
  buildings: IObservableArray<Building> = observable([]);

  @observable
  parcel: Parcel = new Parcel();

  @observable
  equipment: SiteEquipment = new SiteEquipment();

  /**
   * Site boundaries
   */
  boundary?: Vertex[];

  imagery!: {
    provider: BaseImageryProvider;
    zoomLevel: number;
    CUSTOM?: {
      scaleFactor: number;
      rotation: number;
      translationVector: {
        x: number;
        y: number;
      };
    };
  };

  @computed
  get elevationInFeet(): number | undefined {
    return !isNil(this.elevation) ? Math.round(convertMeterToFeet(this.elevation)) : undefined;
  }

  @computed
  get elevationInFeetAsString(): string {
    return this.elevationInFeet?.toString().concat(' ft') ?? '--';
  }

  @computed
  get roofFaces(): RoofFace[] {
    return flatMap(this.buildings, (building: Building): RoofFace[] => building.roofFaces);
  }

  isBuildingEmpty(buildingId: string): boolean {
    const building = this.buildings.find((buildingToFind: Building): boolean => buildingToFind.id === buildingId);
    return !!building && !building.roofFaces.length && !building.roofOutline;
  }

  @action
  setBuildings = (buildings: Building[]): void => {
    this.buildings.replace(buildings);
  };

  @action.bound
  deleteBuildings(buildingIds: string[]): void {
    remove(this.buildings, (target: Building): boolean => buildingIds.includes(target.id));
  }

  @action.bound
  deleteEmptyBuildings(): void {
    this.deleteBuildings(
      this.buildings
        .filter((building: Building): boolean => building.isEmpty)
        .map((building: Building): string => building.id)
    );
  }

  @action
  setElevationInFeet = (valueInFeet: number | undefined): void => {
    this.elevation = !isNil(valueInFeet) ? convertFeetToMeters(valueInFeet) : undefined;
  };

  @action
  setParcel = (parcel: Parcel): void => {
    this.parcel = parcel;
  };

  @action
  deleteParcelBoundary = (): void => {
    const existingParcelData = this.parcel.toData();
    const newParcel = new Parcel(existingParcelData && pick(existingParcelData, ['assessorsParcelNumber', 'zoning']));
    this.setParcel(newParcel);

    const { editor } = getRootStore();
    editor.removeObjectsByType(SceneObjectType.ParcelBoundary);
    editor.addOrUpdateObject(newParcel.mesh);
  };

  imageWithId = (imageId: string): SiteImage | undefined => {
    const mspImage = this.equipment?.mainServicePanel?.imageWithId(imageId);
    if (mspImage) {
      return mspImage;
    }
    return this.roofFaces.find((roofFace: RoofFace): boolean => !!roofFace.imageWithId(imageId))?.imageWithId(imageId);
  };
}
