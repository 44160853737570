import isNil from 'lodash/isNil';
import maxBy from 'lodash/maxBy';
import type { IObservableArray } from 'mobx';
import {
  action, computed, observable
} from 'mobx';
import type {
  Object3D, Vector3
} from 'three';
import { MathUtils as ThreeMath } from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import cloneDeep from 'lodash/cloneDeep';
import minBy from 'lodash/minBy';
import Loader from '../../../domain/graphics/Loader';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import {
  SceneObjectType, TWO_PI
} from '../../models/Constants';
import type {
  ERoofSlopeType, ERoofFaceEdgeType, PolarCoord, Vector2D
} from '../../typings';
import { ERoofType } from '../../typings';
import {
  ROOF_AREA, ROOF_AREA_SELECTED
} from '../RoofColorLevelRange';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { deckingKeyToOption } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/constants';
import { LengthLabelable } from '../../mixins/LengthLabelable';
import { Deletable } from '../../mixins/Deletable';
import { Draggable } from '../../mixins/Draggable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { Drawable } from '../../mixins/Drawable';
import { Hoverable } from '../../mixins/Hoverable';
import { deleteItem } from '../../../utils/helpers';
import {
  getAreaByVertices, getVectorAngle
} from '../../../utils/math';
import {
  getVisualCenter,
  calculateZFromInfiniteMesh,
  getCenterOfBoundingBoxAroundVertices
} from '../../../utils/spatial';
import { Arrow } from '../../graphics/Arrow';
import {
  PotentialInfo, EDisplayPotential
} from '../../graphics/PotentialInfo';
import { Segment } from '../../graphics/Segment';
import { Vertex } from '../../graphics/Vertex';
import type { Framing } from './Framing';
import type Pathway from './Pathway';
import type { RoofFaceStructure } from './RoofFaceStructure';
import type { RoofProtrusion } from './RoofProtrusion';
import type { RoofCondition } from './RoofCondition';
import type { RoofSlopingStructuralMembers } from './RoofSlopingStructuralMembers';
import type { SiteImage } from './SiteImage';
import type { SolarAccess } from './SolarAccess';
import type PvModulePosition from './PvModulePosition';
import type VentilationSetback from './VentilationSetback';
import type { Building } from './Building';
import {
  isBuildingPositionValid,
  moveBuildingToANewPosition,
  moveEquipmentWithBuilding,
  revertMoveBuildingToANewPosition
} from './functions/DraggableBuildingHelpers';

type PolarAzimuth = PolarCoord & { original: boolean };

const AZIMUTH_REMOVE_TOLERANCE = 0.367; // In radians, around 21 degrees

let roofFaceNumberId = 0;

const MixedClass = LengthLabelable(
  Deletable(Hoverable(Draggable(PolygonDrawable(Selectable(Drawable(class SimpleClass {}))))))
);

export class RoofFace extends MixedClass {
  private movementInProgress: boolean = false;
  /**
   * Array with edge types
   */
  private _edgeTypes: ERoofFaceEdgeType[] = [];

  isMultipleVertices: boolean = true;

  verticesUpdateIsInProgress: boolean = false;

  azimuthAngles?: number[];
  /**
   * @deprecated (ThreeJs) ID field used for compatibility with code using the old-style models
   */
  id: number;
  @observable
  override name!: string;
  /**
   * Slope angle in degrees
   */
  slope?: number;
  slopeType?: ERoofSlopeType;
  /**
   * Azimuth in degrees
   */
  azimuth?: number;
  /**
   * Surface complex type
   */
  framing?: Framing;
  /**
   * Roof Type: Sloped or Flat
   * By default, it is Sloped.
   */
  @observable
  roofType: ERoofType = ERoofType.SLOPED;
  /**
   * Roof protrusions.
   */
  @observable
  protrusions: IObservableArray<RoofProtrusion> = observable([]);
  /**
   * Property id used to select right panel component
   */
  readonly propertyId: string = SceneObjectType.RoofFace;
  /**
   *
   */
  selectWithParent: boolean = false;
  /**
   * Surface property
   */
  surface!: string;
  /**
   * Decking key property
   */
  deckSheathingType: string = deckingKeyToOption.ORIENTED_STRAND_BOARD_15_32_INCH_THICK.attributes.value;
  potentialInfo?: PotentialInfo;
  azimuthArrow: Arrow;
  /**
   * Solar Access
   */
  solarAccess?: SolarAccess;
  pvModulePositions: PvModulePosition[] = [];
  pathways: Pathway[] = [];
  ventilationSetbacks: VentilationSetback[] = [];
  images: IObservableArray<SiteImage> = observable([]);
  /**
   * Layer Number
   */
  layerNumber: number = 2;
  /**
   *
   */
  levelColor: string = '';
  structure?: RoofFaceStructure;
  condition?: RoofCondition;
  slopingStructuralMembers?: RoofSlopingStructuralMembers;
  hasLayout: boolean = false;

  constructor() {
    super();
    this.type = SceneObjectType.RoofFace;
    this.id = ++roofFaceNumberId;
    this.serverId = ThreeMath.generateUUID();
    this.azimuthArrow = new Arrow();
    this.setAzimuthArrowVisibility(false);
    this.mesh.add(this.azimuthArrow.mesh);
  }

  private vertexWithZeroZ = (vertex: Vertex): Vertex => {
    const newVertex = Vertex.fromXyzValues(vertex.x, vertex.y, 0);
    newVertex.hasEverBeenValidAfterUserInteraction = vertex.hasEverBeenValidAfterUserInteraction;
    newVertex.mesh.parent = vertex.mesh.parent;
    return newVertex;
  };

  imageWithId = (imageId: string): SiteImage | undefined => {
    return this.images.find((image: SiteImage): boolean => image.id === imageId);
  };

  override move(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (this.movementInProgress || this.verticesUpdateIsInProgress) {
      return;
    }

    this.movementInProgress = true;
    try {
      const building = editor.domain.buildings.find(
        (building: Building): boolean => !!building.roofFaceWithId(this.serverId)
      );
      if (!building) {
        // Failsafe. Not an expected behaviour.
        return;
      }

      const deltaVector = this.boundary.vertices[0].getVector3().clone()
        .sub(newPosition[0]);
      const capturedEquipment = moveBuildingToANewPosition(deltaVector, building, editor);

      if (!isBuildingPositionValid(editor, building)) {
        revertMoveBuildingToANewPosition(deltaVector, building);
      } else {
        moveEquipmentWithBuilding(deltaVector, capturedEquipment);
      }
    } finally {
      this.movementInProgress = false;
    }
  }

  /**
   * @returns is valid
   */
  override afterMove(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    // do nothing
  }

  override resetVertex(vector: Vector3, index: number): void {
    // do nothing
  }

  get edgeTypes(): ERoofFaceEdgeType[] {
    return this._edgeTypes;
  }

  @computed
  get heightOfLowestPointInPrimaryCoordinateSystem(): number {
    const lowestPoint = minBy(this.boundary.vector3s, (point: Vector3): number => point.z) as Vector3;
    return lowestPoint.z;
  }

  @action.bound
  addImage(image: SiteImage): void {
    this.images.push(image);
  }

  @action.bound
  deleteImage(imageId: string): void {
    const images = this.images.filter((image: SiteImage): boolean => image.id !== imageId);
    this.images.replace(images);
  }

  setColor(levelColor: string): void {
    this.color = levelColor;
    this.redraw();
  }

  setAzimuth(azimuth: number): void {
    this.azimuth = azimuth;
    this.setAngleArrowPosition();
    this.setAzimuthArrowVisibility(true);
  }

  calculateAzimuth(): void {
    // original vectors converted to polar coordinates from the roof face edges
    const polarVectors: PolarAzimuth[] = [];
    const verticesLength = this.polygon.length;
    const area = getAreaByVertices(this.polygon);
    // what fashion was traced the polygon?
    // if it was counter clockwise is positive
    // if it was clockwise is negative
    const clockwise = area < 0;
    // Getting the angle of each segment of the polygon
    for (let i = 0; i < verticesLength - 1; i++) {
      // Current point
      const cpoint = this.polygon[i].mesh.position;
      // Next point, first one when the current is the last
      const npoint = this.polygon[(i + 1) % verticesLength].mesh.position;
      let xdiff: number;
      let ydiff: number;

      // depending on the tracing of the polygon
      // invert calculation
      // in order to make accurate the angle with one orientation
      if (clockwise) {
        xdiff = npoint.x - cpoint.x;
        ydiff = npoint.y - cpoint.y;
      } else {
        xdiff = cpoint.x - npoint.x;
        ydiff = cpoint.y - npoint.y;
      }
      // taking the magnitude, the length of the edge
      const magnitude = Math.sqrt(xdiff * xdiff + ydiff * ydiff);
      // extrating the angle of the edge with regards to global coordinates
      // in radians
      const angle = getVectorAngle({
        x: xdiff,
        y: ydiff
      });
      // save them
      polarVectors.push({
        angle,
        magnitude,
        original: true
      });

      // And save a vector with the opposite angle in order to compare later
      // with the original ones.
      // in degrees will be like:
      // 0 => 180
      // 225 => 45
      // 45 => 225
      // 270 => 90
      polarVectors.push({
        magnitude,
        angle: (angle + Math.PI) % TWO_PI,
        original: false
      });
    }

    const angles: number[] = [];
    // Decompose polar vector array, remove the near angles
    // and subscribe the remain angles
    // the priority are the polygon edges with greater length(magnitude)
    // and the inner angles(originals)
    while (polarVectors.length) {
      const vec = polarVectors.shift() ?? ({} as PolarAzimuth);

      // looking for near angles
      for (let itr = 0; itr < polarVectors.length; itr++) {
        const { angle } = vec;
        const foundVec = polarVectors[itr] || ({} as PolarAzimuth);
        const otherAngle = foundVec.angle;
        const diff = Math.abs(angle - otherAngle);
        const greatAngle = Math.max(angle, otherAngle);
        const lowAngle = Math.min(angle, otherAngle);

        if (
          // testing if the angles are between threshold range
          diff <= AZIMUTH_REMOVE_TOLERANCE
          // for those edge cases when we values return to zero
          // we need to know the angle steps between those
          // from hightest angle values to lowest angle values
          // i.e. 355 degrees and 3 degrees
          //      they have 8 degree of difference
          //      notice after 360 degrees return to 0
          //      we're working in radians by the way
          || TWO_PI - greatAngle + lowAngle <= AZIMUTH_REMOVE_TOLERANCE
        ) {
          // remove the found vector with near angle
          polarVectors.splice(itr, 1);
          // make a step back in the iteration
          // no one is going to escape!!
          itr--;
        }
      }

      // subscribe the result angle for each iteration
      //
      // NOTE: Inverting radians in order to flip rotation values
      // threejs is right handed system
      // but azimuth works with left handed coord values
      // @TODO find a best way to flip the angles
      //      try using matrices, it's just a suggestion
      // @TODO for this flip, snapping azimuth values don't work
      //      gotta fix it
      angles.push(vec.angle * -1);
    }

    this.azimuthAngles = angles.map(ThreeMath.radToDeg);
  }

  setAngleArrowPosition(): void {
    if (!isNil(this.azimuth)) {
      const radAng = ThreeMath.degToRad(-1 * this.azimuth);
      this.azimuthArrow.mesh.rotation.z = radAng;
    }
  }

  deletePvModulePosition(positionToDelete: PvModulePosition): void {
    const positionForDeletion = this.pvModulePositions.find(
      (position: PvModulePosition): boolean => position.serverId === positionToDelete.serverId
    );
    if (positionForDeletion) {
      const index: number = this.pvModulePositions.indexOf(positionForDeletion);
      this.pvModulePositions.splice(index, 1);
      this.mesh.remove(positionForDeletion.mesh);
      this.redraw();
    }
  }

  /**
   * Draw an arrow helper inside the Roof face located in its center
   */
  private drawArrowHelper(): void {
    const location: Vector2D = getVisualCenter(this.polygon.map((vertex: Vertex): Vector3 => vertex.getVector3()));
    this.azimuthArrow.setColor('#FFFFFF');
    if (location) {
      this.azimuthArrow.mesh.position.setX(location.x);
      this.azimuthArrow.mesh.position.setY(location.y);
    }
  }

  setAzimuthArrowVisibility(visible: boolean): void {
    this.azimuthArrow.setVisible(visible);
  }

  showVertices(): boolean {
    return !this.boundary.closed || this.selected;
  }

  showLines(): boolean {
    return true;
  }

  hasFill(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return true;
  }

  showFill(): boolean {
    return this.boundary.closed;
  }

  onClose(): void {
    this.drawArrowHelper();
    this.calculateAzimuth();
  }

  updateArrowLocation(): void {
    this.drawArrowHelper();
  }

  getAzimuthOrSegmentAngle(): number {
    if (!isNil(this.azimuth)) {
      return ThreeMath.degToRad(this.azimuth);
    }
    const vectorZero: Vertex = Vertex.fromXyzValues(0, 0, 0);
    const longestSegment =
      maxBy(this.boundary.segments, (segment: Segment): number => segment.length)
      ?? new Segment({
        v1: vectorZero,
        v2: vectorZero
      });
    return longestSegment.angle * -1;
  }

  /**
   * This function calculates a point''z intersecting z by drawing
   * a normal from point to the plane using Plane Formula z = -ax-by
   * @param point Point in z axis to be intersected to Polygon plane
   */
  calculateZ(point: Vector3): number {
    const roofFaceVertices = this.boundary.vertices.map((vertex: Vertex): Vector3 => vertex.getVector3());
    return calculateZFromInfiniteMesh(roofFaceVertices, point);
  }

  /**
   * Resets Z values to 0 and clears edge types.
   */
  clearZValues(): void {
    this.setEdgeTypes([]);
    this.boundary.setVertices(this.boundary.vertices.map(this.vertexWithZeroZ));
    this.protrusions?.forEach((protrusion: RoofProtrusion): void => {
      protrusion.boundary.setVertices(protrusion.boundary.vertices.map(this.vertexWithZeroZ));
    });
  }

  setRoofFaceIsPolygon(): void {
    if (this.roofType === ERoofType.SLOPED) {
      this.azimuthArrow.setVisible(true);
    }
    if (this.levelColor !== '') {
      this.color = this.levelColor;
    }
    this.hidePotentialInfo();
    this.redraw();
  }

  createPotentialInfoIfAbsent(font: Font): void {
    if (!this.potentialInfo) {
      this.potentialInfo = new PotentialInfo(
        font,
        getVisualCenter(this.polygon.map((vertex: Vertex): Vector3 => vertex.getVector3()))
      );
      this.mesh.add(this.potentialInfo.mesh);
    }
  }

  setRoofFaceIsUsedForSolar(font: Font, isUsedForSolar: boolean, maxPotentialValue?: number): void {
    this.azimuthArrow.setVisible(false);
    this.levelColor = this.color.toString();
    this.color = isUsedForSolar ? ROOF_AREA_SELECTED : ROOF_AREA;

    this.createPotentialInfoIfAbsent(font);
    if (maxPotentialValue) {
      this.potentialInfo?.changeMaxPotential(maxPotentialValue);
    }

    const isPotentialInfoFlat = this.roofType === ERoofType.FLAT;
    const isPotentialInfoSloped = this.roofType === ERoofType.SLOPED && !isNil(this.azimuth) && !isNil(this.slope);
    const potentialInfoShouldBeVisible =
      (this.potentialInfo?.maxPotential ?? 0) > 0 && (isPotentialInfoFlat || isPotentialInfoSloped);

    if (!isUsedForSolar) {
      this.potentialInfo?.changeMaxPotential(0);
      this.potentialInfo?.displayNone();
    } else if (potentialInfoShouldBeVisible) {
      this.potentialInfo?.displayMaxPotential();
    } else {
      this.potentialInfo?.displayNone();
    }
    this.hasLayout = isUsedForSolar;
    this.redraw();
  }

  @action.bound
  copySlopeAndStructuralPropertiesFrom = (templateRoofFace: RoofFace): void => {
    const {
      slope, slopeType, surface, deckSheathingType, framing, condition, structure, slopingStructuralMembers
    } =
      templateRoofFace;
    this.slope = slope;
    this.slopeType = slopeType;
    this.surface = surface;
    this.deckSheathingType = deckSheathingType;
    this.framing = cloneDeep(framing);
    this.condition = cloneDeep(condition);
    this.structure = cloneDeep(structure);
    this.slopingStructuralMembers = cloneDeep(slopingStructuralMembers);
  };

  showLoader(): () => void {
    if (this.potentialInfo) {
      this.potentialInfo.displayLoader();
      const prevState: EDisplayPotential = this.potentialInfo.state!;

      // This is a handler turn off the loading state.
      return (): void => {
        if (!this.potentialInfo || this.potentialInfo?.state !== EDisplayPotential.LOADER) {
          // If we moved on to other state - do nothing.
          return;
        }
        // If previous state is not loading - just moving to it.
        if (prevState !== EDisplayPotential.LOADER) {
          this.potentialInfo.display(prevState);
        } else if (this.potentialInfo.maxPotential) {
          // Previous state was loading, so trying to guess what's the best state to show now -
          this.potentialInfo.displayMaxPotential();
        } else {
          this.potentialInfo.displayNone();
        }
      };
    } else {
      const loader = new Loader();
      const roofFaceCenter = getCenterOfBoundingBoxAroundVertices(this.getVector3s());
      loader.position.x = roofFaceCenter.x;
      loader.position.y = roofFaceCenter.y;
      loader.position.z = roofFaceCenter.z * 2;
      const scaleValue = 0.5;
      loader.scale.set(scaleValue, scaleValue, scaleValue);
      this.mesh.add(loader);

      return (): void => {
        loader.destroy();
      };
    }
  }

  hoverInPotentialInfo(): void {
    if (this.potentialInfo) {
      if (this.isMarkedAsUseForSolar) {
        this.potentialInfo.displayDontUseForSolar();
      } else {
        this.potentialInfo.displayUseForSolar();
      }
    }
  }

  hoverOutPotentialInfo(): void {
    if (this.potentialInfo) {
      if (this.isMarkedAsUseForSolar && this.potentialInfo.maxPotential > 0) {
        this.potentialInfo.displayMaxPotential();
      } else {
        this.potentialInfo.displayNone();
      }
    }
  }

  get isMarkedAsUseForSolar(): boolean {
    return this.potentialInfo ? this.potentialInfo.maxPotential > 0 || this.hasLayout : false;
  }

  hidePotentialInfo(): void {
    if (this.potentialInfo) {
      this.mesh.remove(this.potentialInfo.mesh);
      this.potentialInfo = undefined;
    }
  }

  clearPotencialInfo(): void {
    if (this.potentialInfo) {
      this.potentialInfo.displayLoader();
      this.potentialInfo.maxPotential = 0;
    }
  }

  addPvModulePosition(child: PvModulePosition): void {
    this.pvModulePositions.push(child);
    this.mesh.add(child.mesh);
  }

  removePvModulePosition(pvModulePosition: PvModulePosition): void {
    if (deleteItem<PvModulePosition>(this.pvModulePositions, pvModulePosition)) {
      this.mesh.remove(pvModulePosition.mesh);
    }
  }

  removePvModulePositions(): void {
    this.pvModulePositions.forEach((child: PvModulePosition): void => {
      this.mesh.remove(child.mesh);
    });
    this.pvModulePositions = [];
  }

  addPathway(child: Pathway): void {
    this.pathways.push(child);
    this.mesh.add(child.mesh);
  }

  addVentilationSetback(child: VentilationSetback): void {
    this.ventilationSetbacks.push(child);
    this.mesh.add(child.mesh);
  }

  removeFireVentilationSetbacksAndPathways(): void {
    this.ventilationSetbacks.forEach((child: VentilationSetback): void => {
      this.mesh.remove(child.mesh);
    });
    this.pathways.forEach((child: Pathway): void => {
      child.removeFromScene();
      this.mesh.remove(child.mesh);
    });
    this.ventilationSetbacks = [];
    this.pathways = [];
  }

  removeChildFromModel(object3D: Object3D): void {
    this.pvModulePositions = this.pvModulePositions.filter(
      (child: PvModulePosition): boolean => child.mesh.uuid !== object3D.uuid
    );
  }

  setEdgeTypes(edgeTypes: ERoofFaceEdgeType[]): void {
    this._edgeTypes = edgeTypes;
    if (this.edgeTypes.length && this.edgeTypes.length !== this.boundary.segments.length) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot set ${this.edgeTypes.length} edge types on ${this.boundary.segments.length} edges`);
      return;
    }
    for (let i = 0; i < this._edgeTypes.length; ++i) {
      const segment = this.boundary.segments[i];
      segment.type = this._edgeTypes[i];
    }
  }
}

/**
 * Displays loaders on all roof faces that are used for solar
 *
 * @return A callback function that will hide the loaders once called
 */
export function showLoadersOnAllRoofFacesUsedForSolar(editorStore: EditorStore): () => void {
  const loaderHiders: (() => void)[] = editorStore
    .getObjectsByType<RoofFace>(SceneObjectType.RoofFace)
    .filter((roofFace: RoofFace): boolean => roofFace.isMarkedAsUseForSolar)
    .map((roofFace: RoofFace): (() => void) => roofFace.showLoader());
  return (): void => loaderHiders.forEach((loaderHider: () => void): void => loaderHider());
}
