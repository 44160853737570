import {
  LyraTypography, LyraDivider
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import type BaseEnvProp from '../../../../../stores/UiStore/EnvironmentalProps/BaseEnvProps';
import TitleBar from '../../../../components/TitleBar';
import Flexbox from '../../../../components/Layout/Flexbox';
import IconMapper from '../../../../components/Icons';
import {
  DetailsIcon, DetailsStyle, DetailsContent
} from './styles';

type Props = BaseProps & {
  env: BaseEnvProp;
  divider?: boolean;
  themeBg?: string;
};

function Details({
  env, divider, themeBg
}: Props): ReactElement {
  return (
    <>
      {divider && (
        <LyraDivider.Divider direction="vertical" length="100%" thickness="2px" color={EThemeComponentColor.GRAY_200} />
      )}
      <DetailsStyle>
        <TitleBar title={env.name} themeBg={themeBg} />
        <Flexbox dir="row" justify="center" align="center" className="pt-xs pb-xxs">
          {env.icon && (
            <DetailsIcon className="mr-xxs">
              <IconMapper name={env.icon} />
            </DetailsIcon>
          )}
          <DetailsContent color={env.color as string}>{env.value}</DetailsContent>
        </Flexbox>
        <LyraTypography.Paragraph
          color={EThemeComponentColor.GRAY_800}
          fontSize="12px"
          fontWeight="bold"
          margin="0 0 5px"
          lineHeight={1}
        >
          {env.metaDetail}
        </LyraTypography.Paragraph>
      </DetailsStyle>
    </>
  );
}

export default Details;
