import type Store from './Store';
import type { UiStore } from './UiStore/UiStore';
/**
 * This lite hack allows to get the store anywhere without props drilling and circular dependencies creation.
 * */
let rootStore: Store;
export function setRootStore(root: Store): void {
  rootStore = root;
}

export function getRootStore(): Store {
  if (!rootStore) {
    console.error('The rootStore is not set (RootStoreInversion)');
  }
  return rootStore;
}

export function getUiStore(): UiStore {
  if (!rootStore) {
    console.error('The rootStore is not set (RootStoreInversion)');
  }
  return rootStore.uiStore;
}
