import type { ReactElement } from 'react';
import * as React from 'react';
import Icon from '../../../../ui/components/Icons';
import {
  Button, ButtonLabel, IconContainer, ShowHideStyle
} from './styles';

type Props = {
  visibility?: boolean;
};

function ShowHide(props: Props): ReactElement {
  const { visibility = true } = props;
  return (
    <ShowHideStyle hideLabel={visibility}>
      <Button>
        <IconContainer>
          <Icon name="showHideElements" />
        </IconContainer>
      </Button>
      <ButtonLabel>Show/Hide Elements</ButtonLabel>
    </ShowHideStyle>
  );
}

export default ShowHide;
