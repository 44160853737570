import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../index';
import { Notes } from '../FontFamily/Fonts';

export const Wrapper = styled.div`
  width: 100%;
`;

export const PanelWrapper = styled.div`
  border-radius: 7px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.opacityColor3};
  margin: 12px 10px 10px 9px;
  padding: 3px 7px 4px 6px;
`;

export const PanelText = styled(Notes)`
  line-height: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;
