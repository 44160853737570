import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState, useCallback, useContext
} from 'react';
import { ThemeContext } from 'styled-components';
import { LyraLoader } from '@aurorasolar/lyra-ui-kit';
import {
  BRANCH_AC_MODULE,
  BRANCH_MICROINVERTER,
  CENTRAL_MPPT_STRING,
  STRING_WITH_DC_OPTIMIZERS
} from '../../../../domain/models/Constants';
import type { SystemSummaryPanelViewModel } from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';
import AcModule from './AcModule';
import MicroInverterComponent from './Microinverter';
import StringInverterWithInternalMPPTS from './StringInverterWithInternalMPPTS';
import StringInverterWithMLPEMPPTS from './StringInverterWithMLPMPPTs';
import {
  ContainerWrapperInitial, SummaryPanelContainer
} from './styles';
import SystemPanel from './SystemPanel';
import TipSection from './TipSection';

const UiToDisplay = ({ option }: { option: string }): ReactElement | null => {
  if (option === BRANCH_MICROINVERTER) {
    return <MicroInverterComponent />;
  }
  if (option === BRANCH_AC_MODULE) {
    return <AcModule />;
  }
  if (option === STRING_WITH_DC_OPTIMIZERS) {
    return <StringInverterWithMLPEMPPTS />;
  }
  if (option === CENTRAL_MPPT_STRING) {
    return <StringInverterWithInternalMPPTS />;
  }
  return null;
};

const SystemSummaryPanel = (): ReactElement => {
  const theme = useContext(ThemeContext);
  const { panels } = useStore();
  const { viewModel } = panels;
  const [tipContainer, setTipContainer] = useState(false);
  const {
    type, hasStringing, stringingOptionsLoading
  } = viewModel as SystemSummaryPanelViewModel;

  const handleClick = useCallback((showTip: boolean): void => {
    setTipContainer(showTip);
  }, []);

  useEffect((): void => {
    setTipContainer(hasStringing);
  }, [hasStringing]);

  const showSystemSummary = type === STRING_WITH_DC_OPTIMIZERS || type === CENTRAL_MPPT_STRING;
  const containerClassName = tipContainer ? 'hidetip' : 'showtip';

  return (
    <SummaryPanelContainer>
      {!stringingOptionsLoading ? (
        <>
          <ContainerWrapperInitial className={containerClassName}>
            {showSystemSummary && <SystemPanel />}
            <UiToDisplay option={type!} />
          </ContainerWrapperInitial>

          <TipSection
            displayTip={!hasStringing}
            onShowTip={handleClick}
            showHintForMPPT={type === CENTRAL_MPPT_STRING}
          />
        </>
      ) : (
        <LyraLoader.Loader bgColor={theme!.colors.newGray2} text="Loading stringing options..." />
      )}
    </SummaryPanelContainer>
  );
};

export default observer(SystemSummaryPanel);
