import React, { BaseProps, Fragment, ReactElement } from 'react';
import {
  DottedProgressBarContainer,
  DottedProgressWrapper,
  DottedRangeBar,
  DottedRangeContainer,
  DownArrow,
  LinearDotView,
  LinearPercent,
  LinearProgressWrapper,
  MaxValueText,
  OverrideDottedView,
  RangeEndText,
  RangeText,
  RangeTextWrapper,
  DottedPercent
} from './styles';

type DottedProgressProps = BaseProps & {
  units: string;
  minValue: number;
  maxValue: number;
  currentValue: number;
  strokeColor?: string;
  possibleMaxValue: number;
  style?: {
    primaryFontSize?: string;
    secondaryFontSize?: string;
    barHeight?: string;
  };
};

const Range = ({
  units,
  className,
  minValue,
  maxValue,
  currentValue,
  strokeColor,
  possibleMaxValue,
  style
}: DottedProgressProps): ReactElement => {
  const range = maxValue - minValue;
  let possibleValue = currentValue;
  if (currentValue > maxValue) {
    possibleValue = maxValue;
  }
  if (currentValue < minValue) {
    possibleValue = minValue;
  }
  const currentPercentage = (possibleValue / range) * 100;
  if (currentValue >= possibleMaxValue) {
    currentValue = possibleMaxValue;
  }
  const fullOffset = possibleMaxValue - maxValue;
  const currentOffset = currentValue - maxValue;
  const offset = (-currentOffset / fullOffset) * 100;
  return (
    <DottedRangeContainer
      progressBar={currentPercentage}
      className={className}
      barHeight={style && style.barHeight}
    >
      {currentValue < maxValue ? (
        <Fragment>
          {currentValue !== 0 && (
            <Fragment>
              <DottedPercent fontSize={style && style.secondaryFontSize}>
                {possibleValue ? parseFloat(possibleValue.toFixed(1)) : 0}
                {units}
              </DottedPercent>
              <DownArrow />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {currentValue !== 0 && (
            <Fragment>
              <DottedPercent offset={offset} fontSize={style && style.secondaryFontSize}>
                {possibleValue ? parseFloat(currentValue.toFixed(1)) : 0}
                {units}
              </DottedPercent>
              <DownArrow offset={offset} />
            </Fragment>
          )}
        </Fragment>
      )}
      <DottedRangeBar color={strokeColor} />
    </DottedRangeContainer>
  );
};

const DottedProgressBar = ({
  className,
  units,
  minValue,
  maxValue,
  currentValue,
  strokeColor,
  possibleMaxValue,
  style
}: DottedProgressProps): ReactElement => {
  const dotCount = 20;
  const dotGroup = [];
  const getActiveStatus = (index: number): boolean => {
    const isActive = false;
    if (currentValue > maxValue && currentValue <= possibleMaxValue) {
      const dotRange =
        ((currentValue - maxValue) / (possibleMaxValue - maxValue)) * dotCount;
      if (dotRange > index) {
        return true;
      }
    }
    if (currentValue > possibleMaxValue) {
      return true;
    }
    return isActive;
  };

  const getStrokeColor = (percent: number): string => {
    const gradient = [
      [0, 58, 161, 74],
      [50, 249, 255, 24],
      [100, 194, 0, 0]
    ];
    if (percent < 0) {
      percent = 0;
    }
    if (percent > 100) {
      percent = 100;
    }
    let start;
    let end;
    for (let i = 0; i < gradient.length - 1; i += 1) {
      if (gradient[i][0] <= percent && percent <= gradient[i + 1][0]) {
        start = gradient[i];
        end = gradient[i + 1];
        break;
      }
    }
    let color: string = '#';
    for (let i = 1; i <= 3; i += 1) {
      // tslint:disable-next-line: max-line-length
      const value =
        (start &&
          end &&
          start[i] +
            ((end[i] - start[i]) / (end[0] - start[0])) * (percent - start[0])) ||
        0;
      // tslint:disable-next-line
      let c = `00${Math.floor(value).toString(16)}`.substr(-2);
      color = `${color}${c}`;
    }
    return color;
  };

  for (let i = 0; i < dotCount; i++) {
    const percent1 = Math.floor((i * 100) / dotCount);
    const percent2 = Math.floor(((i + 1) * 100) / dotCount);
    const color1 = getStrokeColor(percent1);
    const color2 = getStrokeColor(percent2);
    dotGroup.push(
      <LinearDotView
        key={i}
        active={getActiveStatus(i)}
        color1={color1}
        color2={color2}
        barHeight={style && style.barHeight}
      />
    );
  }
  return (
    <section className={className}>
      <LinearProgressWrapper>
        <DottedProgressWrapper>
          <DottedProgressBarContainer barHeight={style && style.barHeight}>
            <Range
              units={units}
              minValue={minValue}
              maxValue={maxValue}
              currentValue={currentValue}
              strokeColor={strokeColor}
              possibleMaxValue={possibleMaxValue}
              style={style}
            />
            <MaxValueText
              barHeight={style && style.barHeight}
              fontSize={style && style.primaryFontSize}
            >
              {maxValue}
              {units}
            </MaxValueText>
          </DottedProgressBarContainer>
          <OverrideDottedView>{dotGroup}</OverrideDottedView>
        </DottedProgressWrapper>
        <RangeTextWrapper>
          <RangeText fontSize={style && style.primaryFontSize}>
            {minValue}
            {units}
          </RangeText>
          <RangeEndText fontSize={style && style.primaryFontSize}>
            {possibleMaxValue}
            {units}
          </RangeEndText>
        </RangeTextWrapper>
      </LinearProgressWrapper>
    </section>
  );
};

export { DottedProgressBar };
