import type { ReactElement } from 'react';
import * as React from 'react';
import {
  RIGHT_ANGLE, STRAIGHT_ANGLE
} from '../../../domain/models/Constants';
import type DimensionHouse from '../../../domain/models/DimensionsHouse';
import { degreesToRadians } from '../../../utils/math';
import {
  BORDER_BASE, DIFF_BASE, HEIGHT_HOUSE, SIDE_HOUSE
} from './constants';
import {
  HouseRectangle, HouseRoof, HouseWrapper
} from './styles';

type Props = {
  slope?: number;
};

const dimensionsHouse = (slope: number): DimensionHouse => {
  //        .
  //       /|
  // side / |
  //     /  |  height
  //    /  _|
  //   /__|_|
  //   base
  //
  // theta = angle between side and height
  // slope = angle between side and base

  const theta = STRAIGHT_ANGLE - RIGHT_ANGLE - slope;
  // Law of sin to calculate the base of the roof
  const baseRoof = SIDE_HOUSE * Math.sin(degreesToRadians(theta));
  // Pythagorean theorem to calculate the height
  const heightRoof = Math.sqrt(Math.pow(SIDE_HOUSE, 2) - Math.pow(baseRoof, 2));
  const heightRectangle = HEIGHT_HOUSE;
  const baseRectangle = baseRoof * 2 - BORDER_BASE * 2 - DIFF_BASE * 2;

  return {
    baseRectangle,
    baseRoof,
    heightRectangle,
    heightRoof,
    sideRoof: SIDE_HOUSE
  };
};

function CustomHouse(props: Props): ReactElement {
  // Component props
  const { slope = 0 } = props;

  // Dimensions of the house
  const {
    baseRectangle, baseRoof, heightRectangle, heightRoof
  } = dimensionsHouse(slope);

  return (
    <HouseWrapper className="pt-xxxs">
      {slope !== 0 ? (
        <>
          <HouseRoof baseRoof={baseRoof} heightRoof={heightRoof} />
          <HouseRectangle baseRectangle={baseRectangle} heightRectangle={heightRectangle} />
        </>
      ) : (
        <span>Select Slope</span>
      )}
    </HouseWrapper>
  );
}

export default CustomHouse;
