import type { Intersection } from 'three';
import { Vector3 } from 'three';
import { MouseBehaviour } from '../../../../../domain/behaviour/MouseBehaviour';
import type { Stringing } from '../../../../../domain/graphics/stringing/Stringing';
import {
  SceneObjectType, WARNING
} from '../../../../../domain/models/Constants';
import PvModule from '../../../../../domain/models/SiteDesign/PvModule';
import type { IStage } from '../../../../../domain/stages/IStage';
import { ElectricalDesignStage } from '../../../../../domain/stages/DesignStages/ElectricalDesignStage';
import { BaseCastObjectControl } from '../../../../EditorStore/Controls/BaseCastObjectControl';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import type { IBaseToolDependencies } from '../../Tool';
import { BaseTool } from '../../Tool';
import StringingService, { ProcessStringingUserInputResult } from '../../../../../services/stringing/stringingService';
import { getLyraModelByMesh } from '../../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { STRINGING_ID } from '../constants';
import { notify } from '../../../../../utils/helpers';
import { doesLineSegmentIntersectPolygon } from '../../../../../utils/spatial';
import {
  MouseActions,
  MouseActionsInteractionsState,
  ProcessMouseActionResult
} from './StringingMouseActionsInteractionsState';

export interface IBaseStringingDependencies extends IBaseToolDependencies {
  readonly workspace?: WorkspaceStore;
  readonly currentStage?: IStage;
}

export class StringingTool extends BaseTool {
  readonly id: string = STRINGING_ID;
  readonly icon: string = 'stringing';
  readonly title: string = 'Stringing';
  readonly description: string = this.title;
  readonly showSubmenu: boolean = false;

  private currentStage: ElectricalDesignStage | undefined;
  private castPvModulePositionControl?: BaseCastObjectControl;
  private readonly workspace: WorkspaceStore | undefined;
  private readonly mouseBehaviour: MouseBehaviour;
  private pvModules: PvModule[] = [];

  private stringing: Stringing | undefined;
  private dragModuleCounter?: number;
  private isDragging: boolean = false;
  private isGoingToUseDragging: boolean = false;

  private mouseActionsState = new MouseActionsInteractionsState();

  constructor(dependencies: IBaseStringingDependencies) {
    super(dependencies);
    this.workspace = dependencies.workspace;
    this.currentStage = dependencies.currentStage as ElectricalDesignStage;
    this.mouseBehaviour = new MouseBehaviour(this.editor);
  }

  whenSelected(): void {
    this.init();
    this.initListeners();
  }

  whenDeselected(): void {
    this.removeListeners();
    this.stringing?.finishStringing();
    this.stringing = undefined;
  }

  dispose(): void {
    this.removeListeners();
  }

  override onMouseMove = async (): Promise<void> => {
    if (!this.isDragging || (await this.mouseActionsState.move.processAction()) === ProcessMouseActionResult.Cancel) {
      return;
    }

    const finish = this.mouseActionsState.start(MouseActions.move);
    try {
      const pvModule: PvModule | undefined = this.getIntersectedPvModule(
        this.castPvModulePositionControl!.getCastedObjects()
      );

      if (!pvModule) {
        finish();
        return;
      }

      const modulesInBetween = this.getModulesBetweenFirstOrLastAndCurrent(
        pvModule,
        StringingService.selectedStringsModules,
        StringingService.addModulesToStringBeginning
      );

      const stringingModificationResult = await StringingService.processPvModules([pvModule, ...modulesInBetween]);

      switch (stringingModificationResult) {
      case ProcessStringingUserInputResult.FailedValidation:
        return;
      case ProcessStringingUserInputResult.Success:
          this.dragModuleCounter! += modulesInBetween.length;
        return;
      default:
      }
    } finally {
      finish();
    }
  };

  override onMouseDown = async (): Promise<void> => {
    const shouldCancel = (await this.mouseActionsState.down.processAction()) === ProcessMouseActionResult.Cancel;
    if (shouldCancel) {
      return;
    }

    const finish = this.mouseActionsState.start(MouseActions.down);

    try {
      const pvModule = this.getIntersectedPvModule(this.castPvModulePositionControl!.getCastedObjects());

      if (!pvModule) {
        finish();
        return;
      }

      this.isGoingToUseDragging = true;
      switch (
        await StringingService.processPvModule({
          pvModule: pvModule,
          calledFromBatchedProcessing: false
        })
      ) {
      case ProcessStringingUserInputResult.NoMPPTAvailable:
        notify('There is no MPPT available', WARNING);
        break;
      case ProcessStringingUserInputResult.NoInverterDataAvailable:
        notify('There is no Inverter Data available in Supplemental Data', WARNING);
        break;
      case ProcessStringingUserInputResult.FailedValidation:
        break;
      case ProcessStringingUserInputResult.NoInverterAvailable:
        break;
      case ProcessStringingUserInputResult.NeedToSelectMPPTOrInverter:
        break;
      case ProcessStringingUserInputResult.Success:
        if (this.isGoingToUseDragging) {
          this.dragModuleCounter = 0;
          this.isDragging = true;
        }
        break;
      default:
      }
    } finally {
      finish();
    }
  };

  // Drag-only handler
  override onMouseUp = async (): Promise<void> => {
    // Set this flag for async mouse down handler to know that it should cancel dragging
    this.isGoingToUseDragging = false;

    const shouldCancel = (await this.mouseActionsState.up.processAction()) === ProcessMouseActionResult.Cancel;
    if (!this.isDragging || shouldCancel) {
      return;
    }

    const finish = this.mouseActionsState.start(MouseActions.up);
    this.isDragging = false;

    try {
      if (this.dragModuleCounter) {
        await StringingService.finishEditingString();
      }
    } finally {
      finish();
    }
  };

  override onMouseLeave = (): void => {
    // do nothing
  };

  override onMouseDblClick = async (): Promise<void> => {
    const shouldCancel = (await this.mouseActionsState.dblClick.processAction()) === ProcessMouseActionResult.Cancel;
    if (shouldCancel) {
      return;
    }

    const finish = this.mouseActionsState.start(MouseActions.dblClick);

    try {
      await StringingService.finishEditingString();
    } finally {
      finish();
    }
  };

  /**
   * Returns the pv modules from the last added one to the newest one (the one we're hovering with the mouse),
   * including the newest one (but not the last added one)
   * @param pvModuleSelected the newest pvmodule
   * @param addedModules
   * @param startFromStringBeginning
   */
  private getModulesBetweenFirstOrLastAndCurrent(
    pvModuleSelected: PvModule,
    addedModules: PvModule[],
    startFromStringBeginning: boolean = false
  ): PvModule[] {
    const modules: PvModule[] = [];

    if (addedModules.length > 0) {
      const lastAddedModule = addedModules[startFromStringBeginning ? 0 : addedModules.length - 1];
      const lastAddedCenter = lastAddedModule.getCenter();
      const newestCenter = pvModuleSelected.getCenter();

      const lineSegment = {
        A: lastAddedCenter,
        B: newestCenter
      };
      const aToB = new Vector3().subVectors(newestCenter, lastAddedCenter);

      for (const pvModule of this.pvModules) {
        if (doesLineSegmentIntersectPolygon(lineSegment, pvModule.getVector3s())) {
          if (!modules.includes(pvModule)) {
            modules.push(pvModule);
          }
        }
      }

      const signX = Math.sign(aToB.x);
      const signY = Math.sign(aToB.y);

      modules.sort((a: PvModule, b: PvModule): number => {
        let refSign = signX;
        let sign = Math.sign(a.getCenter().x - b.getCenter().x);
        if (sign === 0) {
          sign = Math.sign(a.getCenter().y - b.getCenter().y);
          refSign = signY;
        }
        return refSign > 0 ? sign : -sign;
      });
    } else {
      modules.push(pvModuleSelected);
    }

    return modules;
  }

  private initListeners(): void {
    this.mouseBehaviour.addMouseClickEvents(this);
    this.mouseBehaviour.addMouseMoveEvents(this);
  }

  private removeListeners(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
    this.mouseBehaviour.removeMouseMoveEvents(this);
  }

  private init(): void {
    this.configureBaseCastObjectControl();
    if (!this.currentStage) {
      this.setCurrentStage();
    }
    this.currentStage?.setUpTool?.(STRINGING_ID);
  }

  private setCurrentStage(): void {
    const currentStage = this.workspace?.currentWorkspace.stageManager?.currentStage;
    if (currentStage && currentStage instanceof ElectricalDesignStage) {
      this.currentStage = currentStage;
    }
  }

  private configureBaseCastObjectControl(): void {
    this.pvModules = this.editor.getObjectsByType(SceneObjectType.PvModule, true);
    this.castPvModulePositionControl = new BaseCastObjectControl(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera!,
      this.pvModules
    );
  }

  private getIntersectedPvModule(intersected: Intersection[]): PvModule | undefined {
    let pvModuleSelected: PvModule | undefined;
    for (const obj of intersected) {
      if (getLyraModelByMesh(obj.object) instanceof PvModule) {
        pvModuleSelected = getLyraModelByMesh(obj.object);
        break;
      }
    }
    return pvModuleSelected;
  }
}
