import {
  LyraDivider, LyraFormElementsDesignTool, LyraGrid, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { observer } from 'mobx-react-lite';
import type { PropsTheme } from 'styled-components';
import type { ReactElement } from 'react';
import React from 'react';
import type { ServiceEntranceEquipmentLoadCenterViewModel } from '../../../../stores/UiStore/Properties/SiteEquipment/ViewModels/ServiceEntranceEquipmentLoadCenterViewModel';
import Collapse from '../../../components/Collapse';
import UtilityMeterProperties from '../UtilityMeterProperties';
import {
  BasicPropsContainer,
  IndoorOutdoorWrapper,
  MSPRowWrapper
} from './styles';

const Label = LyraTypography.Paragraph;
const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

type MspFeaturesProps = PropsTheme & {
  viewModel: ServiceEntranceEquipmentLoadCenterViewModel;
  mainBreakerPresent: boolean;
};

const MSPFeatures: React.FC<MspFeaturesProps> = (props: MspFeaturesProps): ReactElement => {
  const {
    viewModel, mainBreakerPresent
  } = props;

  const collapseProps = {
    title: 'Basic Properties',
    open: true,
    color: props.theme.colors.secondaryFont
  };

  return (
    <BasicPropsContainer>
      <Collapse {...collapseProps}>
        <IndoorOutdoorWrapper>
          <LyraGrid.Grid
            options={viewModel.exposureOptions}
            columns={2}
            selected={viewModel.exposure as LyraGrid.OptionProps}
            onSelect={(option: Option): void => viewModel.setExposure(option.value)}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </IndoorOutdoorWrapper>

        <LyraDivider.Divider direction="horizontal" thickness="1px" color={EThemeComponentColor.GRAY_200} />

        {viewModel.isCombinationPanel && <UtilityMeterProperties viewModel={viewModel.utilityMeterProps} />}

        <MSPRowWrapper>
          <Label lineHeight={1} margin="15px 0 15px 0" type="h6">
            ELECTRICAL SERVICE VOLTAGE
          </Label>
          <Label lineHeight={1} margin="0 0 0 0" type="h5" fontWeight="bold">
            240V
          </Label>
        </MSPRowWrapper>
        <MSPRowWrapper data-testid="manufacturerOptionsContainer">
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MANUFACTURER
          </Label>
          <Select
            name="manufacturerOptions"
            listOptions={viewModel.manufacturerOptions}
            selected={viewModel.manufacturer?.value}
            onSelect={viewModel.setManufacturer}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MSPRowWrapper>
        <MSPRowWrapper>
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MODEL NUMBER
          </Label>
          <TextField name="viewModel" fontSize="10px" value={viewModel.model} onChange={viewModel.assignModelNumber} />
        </MSPRowWrapper>
        <MSPRowWrapper data-testid="busBarRatingOptionsContainer">
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            BUSBAR RATING
          </Label>
          <Select
            name="busBarRatingOptions"
            listOptions={viewModel.busbarRatingOptions}
            selected={viewModel.busbarRating?.value}
            onSelect={viewModel.setBusbarRating}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MSPRowWrapper>
        <MSPRowWrapper data-testid="shortCircuitCurrentRatingContainer">
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            SHORT CIRCUIT CURRENT RATING
          </Label>
          <Select
            name="shortCircuitCurrentRatingOptions"
            listOptions={viewModel.shortCircuitCurrentRatingOptions}
            selected={viewModel.shortCircuitCurrentRating?.value}
            onSelect={viewModel.setShortCircuitCurrentRating}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MSPRowWrapper>
        <MSPRowWrapper data-testid="busBarConnectionOptionsContainer">
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            BUSBAR POINT OF CONNECTION
          </Label>
          <Select
            name="connectionOptions"
            listOptions={viewModel.busbarPointOfConnectionOptions}
            selected={viewModel.busbarPointOfConnection?.value}
            onSelect={viewModel.setBusbarPointOfConnection}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MSPRowWrapper>

        {viewModel.busbarRating && mainBreakerPresent && (
          <>
            <MSPRowWrapper data-testid="breakerOptionsContainer">
              <Label lineHeight={1} margin="0 0 4px 0" type="h6">
                MAIN BREAKER RATING
              </Label>
              <Select
                name="breakerOptions"
                listOptions={viewModel.mainBreakerRatingOptions}
                selected={viewModel.mainBreakerRating?.value}
                onSelect={viewModel.setMainBreakerRating}
                selectedOptionColor={EThemeComponentColor.PURPLE}
              />
            </MSPRowWrapper>
            <MSPRowWrapper data-testid="mainBreakerAmpereInterruptingCapacityOptionsContainer">
              <Label lineHeight={1} margin="0 0 4px 0" type="h6">
                MAIN BREAKER INTERRUPTING CAPACITY (AIC)
              </Label>
              <Select
                name="mainBreakerAmpereInterruptingCapacityOptions"
                listOptions={viewModel.mainBreakerAmpereInterruptingCapacityOptions}
                selected={viewModel.mainBreakerAmpereInterruptingCapacity?.value}
                onSelect={viewModel.setMainBreakerAmpereInterruptingCapacity}
                selectedOptionColor={EThemeComponentColor.PURPLE}
              />
            </MSPRowWrapper>
          </>
        )}

        <MSPRowWrapper data-testid="energySourceOptionsContainer">
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            PARALLEL ENERGY SOURCE INPUT
          </Label>
          <Select
            name="energySourceRatingOptions"
            listOptions={viewModel.parallelEnergySourceInputRatingOptions}
            selected={viewModel.parallelEnergySourceInputRating?.value}
            onSelect={viewModel.setParallelEnergySourceInputRating}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MSPRowWrapper>
      </Collapse>
    </BasicPropsContainer>
  );
};

export default observer(MSPFeatures);
