import type DomainStore from '../../../DomainStore/DomainStore';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { PropertiesStore } from '../../Properties/Properties';
import type { WorkspaceStore } from '../../WorkspaceStore';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import { SELECT_TOOL_ID } from './constants';

export interface ISelectionToolDependencies extends IBaseToolDependencies {
  readonly properties: PropertiesStore;
  readonly domain: DomainStore;
  readonly workspace: WorkspaceStore;
}

export class SelectionTool extends BaseTool {
  readonly id: string = SELECT_TOOL_ID;
  readonly icon: string = 'selection';
  readonly title: string = 'Select';
  readonly description: string = this.title;
  private selectionControl?: SelectionControl;
  private readonly workspace: WorkspaceStore;
  override testId: string = 'DesignSelectionTool';

  constructor(dependencies: ISelectionToolDependencies) {
    super(dependencies);
    this.workspace = dependencies.workspace;
  }

  whenSelected = (): void => {
    this.initEditor();
  };

  whenDeselected = (): void => {
    this.selectionControl!.unselectAll();
    this.selectionControl!.deactivate();
    this.workspace.currentWorkspace.stageManager?.currentStage?.disposeEvents?.();
  };

  dispose(): void {
    this.workspace.currentWorkspace.stageManager?.currentStage?.dispose?.();
  }

  private initEditor = (): void => {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    this.workspace.currentWorkspace.stageManager?.currentStage?.setUpTool?.(SELECT_TOOL_ID);
  };
}
