import type { ReactElement } from 'react';
import * as React from 'react';
import { Layout } from './CustomLayout.styles';

interface ICustomLayoutProps {
  text?: string;
  component: ReactElement;
  padding?: string;
  fontSize?: string;
}

const CustomLayout = (props: ICustomLayoutProps): ReactElement => {
  const {
    padding, fontSize, text, component
  } = props;

  return (
    <Layout padding={padding} fontSize={fontSize}>
      {text ? <span>{text}</span> : null}
      {component}
    </Layout>
  );
};

export default CustomLayout;
