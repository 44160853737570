import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {
  MathUtils, Matrix4, Vector2, Vector3
} from 'three';
import type {
  Feature, Point, Polygon, Position
} from '@turf/helpers';
import {
  polygon, polygon as turfPolygon, point as turfPoint
} from '@turf/helpers';
import intersect from '@turf/intersect';
import difference from '@turf/difference';
import { default as turfPointInPolygon } from '@turf/boolean-point-in-polygon';
import centroid from '@turf/centroid';
import poleOfInaccessibility from 'polylabel';
import truncate from '@turf/truncate';
import type {
  ERoofFaceEdgeType, Vector2D
} from '../domain/typings';
import type EditorStore from '../stores/EditorStore/EditorStore';
import { canvasConfig } from '../config/canvasConfig';
import {
  EOrientation, ERoofSlopeType, Units
} from '../domain/typings';
import type { MountingSystemDefinition } from '../domain/models/MountingSystemDefinition/MountingSystemDefinition';
import type { MountingSystem } from '../domain/models/PvSystem/MountingSystem';
import { precisionNumber } from '../utils/helpers';
import type { PolygonDrawable } from '../domain/mixins/PolygonDrawable';
import { getParentLyraModelByMeshOrLyraModel } from '../domain/sceneObjectsWithLyraModelsHelpers';
import type { Segment } from '../domain/graphics/Segment';
import type { Vertex } from '../domain/graphics/Vertex';
import type Pathway from '../domain/models/SiteDesign/Pathway';
import type { RoofFace } from '../domain/models/SiteDesign/RoofFace';
import type VentilationSetback from '../domain/models/SiteDesign/VentilationSetback';
import {
  degreesToRadians, EPSILON, isPointOnLine, isPointOnLineSegmentViaCrossProduct
} from './math';
import {
  subSimpleVector2s, SimpleVector2
} from './ThreeUtils';
import type { ISimpleVector3 } from './ThreeUtils';
import ProjectionUtil from './projectionUtil';

interface IBox3 {
  min: ISimpleVector3;
  max: ISimpleVector3;
}

interface IPlane {
  p: Vector3; // a point on the plane's surface
  normal: Vector3; // normal vector of the plane
}

export interface ILineSegment {
  A: SimpleVector2;
  B: SimpleVector2;
}

/**
 *  The precision used in the calculation to find the pole of inaccessibility.
 */
const POLE_OF_INACCESSIBILITY_ALGORITHM_PRECISION = 1.0;

export function isILineSegment(segment: ILineSegment | Vector3 | undefined): segment is ILineSegment {
  return (
    !!segment
    && Object.keys(segment as ILineSegment).join(',') === 'A,B'
    && Object.keys((segment as ILineSegment).A).join(',') === 'x,y'
    && Object.keys((segment as ILineSegment).B).join(',') === 'x,y'
  );
}

type PointInsidePolygonOptions = {
  onEdgeConsideredInside?: boolean;
  noErrorMargin?: boolean;
};
/**
 * Calculate if a point is within a polygon
 * Return true if the point is INSIDE & ON the polygon Edge
 * In our application a point touching the edge
 * is also considered INSIDE that's why we include Edge as well here.
 *
 * Accounting for error margin is an importance concept that should be used in some cases:
 * For example, when roof face must lie exactly on an outline's edge, sometimes, we have to
 * check that roof face's vertices lie inside an outline or exactly on it's edge, not outside.
 * And since we use the usual float for calculations - sometimes the float representation fails and
 * point is considered outside, when in fact it's on the edge. That's why we need error margins. We
 * use constant EPSILON as our error margin, and when {@see noErrorMargin} is false - we don't check
 * {@see point}, instead we check 4 other points, +- EPSILON in every direction, if any is "inside" -
 * we consider the result as the point being inside.
 *
 * @param rawPolygon Array of vertices of the polygon (at least 3)
 * @param point Point to calculate if is inside
 * @param {PointInsidePolygonOptions} options {@see PointInsidePolygonOptions}
 *
 * @type PointInsidePolygonOptions
 * @property {boolean} onEdgeConsideredInside otherwise given point exactly on
 * edge the function will return false
 * @property {boolean} noErrorMargin pass true if you want to check the exact
 * location of the point, even if float precision may break.
 */
export function pointInsidePolygon(
  rawPolygon: Vector3[],
  point: Vector3,
  options: PointInsidePolygonOptions = {
    onEdgeConsideredInside: true,
    noErrorMargin: false
  }
): boolean {
  const onEdgeConsideredInside = options.onEdgeConsideredInside ?? true;
  // Force noErrorMargin to true if onEdgeConsideredInside is false. Using error
  // margin with and ignoring polygon edges makes no sense because error margin may overlap edges.
  const noErrorMargin = options.noErrorMargin || !onEdgeConsideredInside;
  const polygon: Vector3[] = [...rawPolygon];
  const polygonLength: number = polygon.length;
  if (polygon[0].x !== polygon[polygonLength - 1].x || polygon[0].y !== polygon[polygonLength - 1].y) {
    /*
    // You can uncomment this error logging if you want to debug when a rogue polygon is passed.
    console.error('First and last vertices of the passed polygon does not match');
    console.log('Pushing cloned polygon\'s first vertex into itself so that first and last vertices match');
     */
    polygon.push(polygon[0].clone());
  }
  // Last point is copy of first, so triangle must have at least 4 points.
  if (polygon.length < 4) {
    return false;
  }
  const pointsToCheck = [turfPoint([precisionNumber(point.x), precisionNumber(point.y)])];
  if (!noErrorMargin) {
    /*
      Checking additional `+` positions around `@` center.
      + + +
      + @ +
      + + +
      */
    for (let xFactor = -1; xFactor <= 1; xFactor++) {
      for (let yFactor = -1; yFactor <= 1; yFactor++) {
        pointsToCheck.push(
          turfPoint([precisionNumber(point.x) + xFactor * EPSILON, precisionNumber(point.y) + yFactor * EPSILON])
        );
      }
    }
  }
  const polygonToCheck = turfPolygon([
    polygon.map((vector: Vector3): number[] => [precisionNumber(vector.x), precisionNumber(vector.y)])
  ]);

  return pointsToCheck.some((point: Feature<Point>): boolean =>
    turfPointInPolygon(point, polygonToCheck, {
      ignoreBoundary: !onEdgeConsideredInside
    })
  );
}

export function isPolygonIntersection(polygonA: Vector3[], polygonB: Vector3[]): boolean {
  /*
    Turf by default truncates coordinates to six decimal positions, which should be precise enough.
    10 digits was an overkill and caused an issue: https://aurorasolar.atlassian.net/browse/LYRA-7750
   */
  const turfPolygonA: Feature<Polygon> = truncate(
    turfPolygon([polygonA.map((polygonPoint: Vector3): Position => polygonPoint.toArray().slice(0, 2))])
  );
  const turfPolygonB: Feature<Polygon> = truncate(
    turfPolygon([polygonB.map((polygonPoint: Vector3): Position => polygonPoint.toArray().slice(0, 2))])
  );

  const intersectionResult = intersect(turfPolygonA, turfPolygonB);
  if (!!intersectionResult) {
    for (const intersectionGroups of intersectionResult.geometry.coordinates) {
      if (
        typeof intersectionGroups[0][0] === 'number'
        && isTurfIntersectionBiggerThanThreshold(intersectionGroups as Position[])
      ) {
        return true;
      } else {
        for (const rawIntersection of intersectionGroups) {
          const intersection = rawIntersection as Position[];
          if (typeof intersection[0][0] === 'number' && isTurfIntersectionBiggerThanThreshold(intersection)) {
            return true;
          }
        }
      }
    }
  }

  return false;
}

function isTurfIntersectionBiggerThanThreshold(intersection: Position[]): boolean {
  const mapped = intersection.map((item: Position): Vector2 => new Vector2(item[0], item[1]));
  const intersectionArea = calculatePolygonArea(mapped);
  return intersectionArea > EPSILON / 10;
}

// Simple polygon area calculation algorithm from https://stackoverflow.com/a/33670691/2229104
// @turf/area for some reason gives a vastly invalid results.
function calculatePolygonArea(vertices: Vector2[]) {
  let total = 0;

  for (let i = 0, l = vertices.length; i < l; i++) {
    total += vertices[i].x * vertices[i == vertices.length - 1 ? 0 : i + 1].y * 0.5;
    total -= vertices[i == vertices.length - 1 ? 0 : i + 1].x * vertices[i].y * 0.5;
  }

  return Math.abs(total);
}

export function calculatePolygonCentroid(vertices: Vector2[]): number[] {
  const polygonObject = polygon([vertices.map((vertex: Vector2): number[] => Object.values(vertex))]);
  return centroid(polygonObject).geometry.coordinates;
}

/**
 * Computes the most distant internal point from the polygon outline. Similar to a centroid.
 * Useful for optimal placement of a text label on a polygon.
 */
function getPoleOfInaccessibility(polygon: Vector3[]): Vector2D {
  const polygonCordinates: number[][] = polygon.map((vertex: Vector3): number[] => [vertex.x, vertex.y]);
  const poleOfInaccessibilityPoint = poleOfInaccessibility(
    [polygonCordinates],
    POLE_OF_INACCESSIBILITY_ALGORITHM_PRECISION
  );
  return {
    x: poleOfInaccessibilityPoint[0],
    y: poleOfInaccessibilityPoint[1]
  };
}

export function getVisualCenter(polygon: Vector3[]): Vector2D {
  const [centroidX, centroidY] = calculatePolygonCentroid(
    polygon.map((vertex: Vector3): Vector2 => new Vector2(vertex.x, vertex.y))
  );
  return pointInsidePolygon(polygon, new Vector3(centroidX, centroidY, 0))
    ? {
      x: centroidX,
      y: centroidY
    }
    : getPoleOfInaccessibility(polygon);
}

export function divideSegment(segment: ILineSegment): ILineSegment[] {
  const middlePoint = {
    x: (segment.A.x + segment.B.x) / 2,
    y: (segment.A.y + segment.B.y) / 2
  };

  const segment0: ILineSegment = {
    A: segment.A,
    B: middlePoint
  };

  const segment1: ILineSegment = {
    A: middlePoint,
    B: segment.B
  };

  return [segment0, segment1];
}

function distanceToSegment(p: SimpleVector2, a: SimpleVector2, b: SimpleVector2): number {
  const pa = new Vector2().subVectors(new Vector2(p.x, p.y), new Vector2(a.x, a.y));
  const ba = new Vector2().subVectors(new Vector2(b.x, b.y), new Vector2(a.x, a.y));
  const h = MathUtils.clamp(pa.dot(ba) / ba.dot(ba), 0, 1);

  return new Vector2().subVectors(pa, ba.multiplyScalar(h))
    .length();
}

export function getClosestSegment(polygon: Vertex[], point: SimpleVector2, currentVertexId?: string): ILineSegment {
  const closestSegment = {
    a: {
      x: polygon[0].x,
      y: polygon[0].y
    },
    b: {
      x: polygon[1].x,
      y: polygon[1].y
    },
    // Setting infinite distance so that a segment without currentVertex will be selected
    distance: Infinity
  };

  for (let i = 0; i < polygon.length; ++i) {
    const nextIndex = (i + 1) % polygon.length;
    const distance = distanceToSegment(point, polygon[i], polygon[nextIndex]);
    if (
      (!currentVertexId
        || (polygon[i].serverId !== currentVertexId && polygon[nextIndex].serverId !== currentVertexId))
      && distance < closestSegment.distance
    ) {
      closestSegment.a = {
        x: polygon[i].x,
        y: polygon[i].y
      };
      closestSegment.b = {
        x: polygon[nextIndex].x,
        y: polygon[nextIndex].y
      };
      closestSegment.distance = distance;
    }
  }

  return {
    A: closestSegment.a,
    B: closestSegment.b
  };
}

// Modified version of Justin C. Round's algorithm found here:
// https://stackoverflow.com/questions/13937782/calculating-the-point-of-intersection-of-two-lines
function checkLineIntersection(
  line1Start: SimpleVector2,
  line1End: SimpleVector2,
  line2Start: SimpleVector2,
  line2End: SimpleVector2
): Vector2 | null {
  // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite)
  // and booleans for whether line segment 1 or line segment 2 contain the point
  const denominator =
    (line2End.y - line2Start.y) * (line1End.x - line1Start.x)
    - (line2End.x - line2Start.x) * (line1End.y - line1Start.y);
  if (denominator === 0) {
    return null;
  }
  let a = line1Start.y - line2Start.y;
  let b = line1Start.x - line2Start.x;
  const numerator1 = (line2End.x - line2Start.x) * a - (line2End.y - line2Start.y) * b;
  const numerator2 = (line1End.x - line1Start.x) * a - (line1End.y - line1Start.y) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;

  // if we cast these lines infinitely in both directions, they intersect here:
  const result: Vector2 = new Vector2(
    line1Start.x + a * (line1End.x - line1Start.x),
    line1Start.y + a * (line1End.y - line1Start.y)
  );

  // // if line1 is a segment and line2 is infinite, they intersect if:
  // if (a > 0 && a < 1)
  // {
  // 	result.onLine1 = true;
  // }
  // // if line2 is a segment and line1 is infinite, they intersect if:
  // if (b > 0 && b < 1)
  // {
  // 	result.onLine2 = true;
  // }
  // if line1 and line2 are segments, they intersect if both of the above are true
  return result;
}

/**
 * Draws a perpendicular line from point to the edge, and gives back the coordinate of the intersection point
 */
export function projectPointOnEdge(point: SimpleVector2, edgeA: SimpleVector2, edgeB: SimpleVector2): Vector2 | null {
  const ab = new Vector2().subVectors(new Vector2(edgeB.x, edgeB.y), new Vector2(edgeA.x, edgeA.y));
  const ap = new Vector2().subVectors(new Vector2(point.x, point.y), new Vector2(edgeA.x, edgeA.y));

  const normalOfAB = {
    a: new Vector2(point.x + ab.y, point.y - ab.x),
    b: new Vector2(point.x - ab.y, point.y + ab.x)
  };

  const intersectionPoint = checkLineIntersection(edgeA, edgeB, normalOfAB.a, normalOfAB.b);

  if (!intersectionPoint) {
    return null;
  }

  // if it's not between A and B, but over A on the same line
  if (ab.dot(ap) < 0) {
    return new Vector2(edgeA.x, edgeA.y);
  } else if (new Vector2(edgeA.x, edgeA.y).distanceTo(intersectionPoint) > ab.length()) {
    return new Vector2(edgeB.x, edgeB.y);
  } else {
    return intersectionPoint;
  }
}

/**
 * Calculate if a point is on a polygon Edge
 * ONLY return true if the point is ON the edge of a polygon
 * polygon: Array of vertices of the polygon
 * point: Point that want to detect if is inside or ouside
 * @param rawPolygon Polygon vertices (at least 3)
 * @param point Point to be checked
 */
export function pointOnPolygonEdge(rawPolygon: Vector3[], point: Vector3): boolean {
  let onEdge = false;
  const polygon: Vector3[] = rawPolygon.map(
    (vertex: Vector3): Vector3 =>
      new Vector3(precisionNumber(vertex.x), precisionNumber(vertex.y), precisionNumber(vertex.z))
  );
  for (const polygonPoint of polygon) {
    if (point.equals(polygonPoint)) {
      onEdge = true;
      break;
    }
  }

  if (onEdge) {
    return onEdge;
  }

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x;
    const yi = polygon[i].y;
    const xj = polygon[j].x;
    const yj = polygon[j].y;

    onEdge = isPointOnLineSegmentViaCrossProduct(new Vector3(xi, yi, 0), new Vector3(xj, yj, 0), point, 0.001);

    if (onEdge === true) {
      break;
    }
  }
  return onEdge;
}

const addCopyFirstVertexToLastIfNeeded = (rawPolygon: Vector3[]): Vector3[] => {
  return rawPolygon[0].x !== rawPolygon[rawPolygon.length - 1].x
    || rawPolygon[0].y !== rawPolygon[rawPolygon.length - 1].y
    ? [...rawPolygon, rawPolygon[0].clone()]
    : [...rawPolygon];
};

/**
 * @description Finds the difference between two polygons by clipping the second
 * {@see: anotherPolygon} polygon from the first.
 */
export function getPolygonAreaDifference(rawPolygon: Vector3[], rawAnotherPolygon: Vector3[]): number {
  const polygon = addCopyFirstVertexToLastIfNeeded(rawPolygon);
  const anotherPolygon = addCopyFirstVertexToLastIfNeeded(rawAnotherPolygon);
  const [turfInsidePolygon, turfOuterPolygon] = [
    turfPolygon([polygon.map((vector: Vector3): number[] => [precisionNumber(vector.x), precisionNumber(vector.y)])]),
    turfPolygon([
      anotherPolygon.map((vector: Vector3): number[] => [precisionNumber(vector.x), precisionNumber(vector.y)])
    ])
  ];
  // It'd return null if turfInsidePolygon is completely inside turfOuterPolygon,
  // but to account for imperfections (like precision error), a very small piece out of bounds is acceptable.
  const differencePolygon = difference(turfInsidePolygon, turfOuterPolygon);
  if (differencePolygon) {
    const diffPolygonsCoordinates =
      differencePolygon.geometry.type === 'MultiPolygon'
        ? differencePolygon.geometry.coordinates
        : [differencePolygon.geometry.coordinates];
    // Sum all "overflows", a small enough value might be acceptable
    const overflowAreasSum = diffPolygonsCoordinates.reduce(
      (sum: number, polygon: Position[][]): number =>
        sum
        + calculateVector2PolygonArea(
          polygon[0].map((positions: Position): Vector2 => {
            return new Vector2(positions[0], positions[1]);
          })
        ),
      0
    );
    return precisionNumber(overflowAreasSum);
  }

  return 0;
}

/**
 * Check if polygonA is inside polygonB
 * @param polygon Array of vector
 * @param anotherPolygon Array of vector
 * @param allowEdge set to false to consider polygon vertices lying on anotherPolygon's edges NOT inside
 */
export function isPolygonInsideAnother(
  polygon: Vector3[],
  anotherPolygon: Vector3[],
  allowEdge: boolean = true
): boolean {
  const differenceArea = getPolygonAreaDifference(polygon, anotherPolygon);
  if (differenceArea > 0) {
    // to account for imperfections (like precision error), a very small piece out of bounds is acceptable.
    return differenceArea < 0.01;
  }

  if (!allowEdge) {
    return areVerticesInsidePolygon(polygon, anotherPolygon, allowEdge);
  }

  return true;
}

// from here: https://www.wikihow.com/Calculate-the-Area-of-a-Polygon
export function calculateVector2PolygonArea(vertices: Vector2[]): number {
  let s1 = 0,
    s2 = 0;
  const verticesLength = vertices.length;
  vertices.forEach((vertex: Vector2, index): void => {
    if (index < verticesLength - 1) {
      s1 += vertex.x * vertices[index + 1].y;
      s2 += vertex.y * vertices[index + 1].x;
    }
  });
  return precisionNumber(Math.abs((s1 - s2) / 2));
}
export function calculateVector3to2PolygonArea(vertices: Vector3[]): number {
  return calculateVector2PolygonArea(vertices.map((vertex: Vector3): Vector2 => new Vector2(vertex.x, vertex.y)));
}

/**
 * Returns true if all the vertex of `vertices` are inside `polygon`
 * Returns false otherwise
 */
export function areVerticesInsidePolygon(vertices: Vector3[], polygon: Vector3[], allowEdge: boolean = false): boolean {
  for (const vertex of vertices) {
    if (
      !pointInsidePolygon(polygon, vertex, {
        onEdgeConsideredInside: allowEdge
      })
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Returns true if some of the vertex of `vertices` are inside `polygon`
 * Returns false otherwise
 */
function areSomeVerticesInsidePolygon(vertices: Vector3[], polygon: Vector3[], allowEdge: boolean = false): boolean {
  for (const vertex of vertices) {
    if (
      pointInsidePolygon(polygon, vertex, {
        onEdgeConsideredInside: allowEdge
      })
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Returns true if all the vertex of `vertices` are outside `polygon`
 * Returns false otherwise
 */
function areVerticesOutsidePolygon(vertices: Vector3[], polygon: Vector3[], allowEdge: boolean = false): boolean {
  for (const vertex of vertices) {
    if (
      pointInsidePolygon(polygon, vertex, {
        onEdgeConsideredInside: !allowEdge
      })
    ) {
      return false;
    }
  }

  return true;
}

function calculateNormalFromPlaneVertices(planeVertices: Vector3[]): Vector3 | undefined {
  // Check if the mesh has at least 3 vertices
  if (planeVertices.length < 3) {
    return undefined;
  }

  for (let i = 0; i < planeVertices.length; ++i) {
    for (let j = i + 1; j < planeVertices.length; ++j) {
      for (let k = j + 1; k < planeVertices.length; ++k) {
        const P: Vector3 = planeVertices[i];
        const Q: Vector3 = planeVertices[j];
        const R: Vector3 = planeVertices[k];

        const QR = new Vector3().subVectors(R, Q);
        const QP = new Vector3().subVectors(P, Q);

        const normal = new Vector3().crossVectors(QR, QP)
          .normalize();

        if (normal.length() > 0) {
          if (normal.z < 0) {
            normal.multiplyScalar(-1);
          }
          return normal;
        }
      }
    }
  }
}

/**
 * Returns a point on the plane and a normal vector to the plane, pointing in positive z direction
 * @param planeVertices vertices of the plane. Needs to contain at least 3 vertices, and they must NOT be collinear
 */
export function createPlaneFromVertices(planeVertices: Vector3[]): IPlane | undefined {
  const normal = calculateNormalFromPlaneVertices(planeVertices);

  if (normal) {
    const P: Vector3 = planeVertices[0];

    return {
      p: P,
      normal
    };
  }
}

/**
 * Calculate the Z value of a point that is inside a mesh
 * Based on this: https://www.cl.cam.ac.uk/teaching/1999/AGraphHCI/SMAG/node2.html
 * @param meshVertices Mesh vertices (at least 3)
 * @param point Point to calculate Z
 */
export function calculateZFromInfiniteMesh(
  meshVertices: Vector3[],
  point: Vector3 // z value is ignored, but we need vec3 instead of vec2, because of three.js types in functions
): number {
  const plane = createPlaneFromVertices(meshVertices);
  if (!plane) {
    return meshVertices[0].z || 0;
  }
  const rayOrigin = new Vector3(point.x, point.y, 0);
  const rayDirection = new Vector3(0, 0, 1);
  return (
    new Vector3().copy(plane.normal)
      .dot(new Vector3().subVectors(plane.p, rayOrigin))
    / new Vector3().copy(plane.normal)
      .dot(rayDirection)
  );
}

// Copy-pasted from https://stackoverflow.com/a/6853926/2229104
function distanceFromPointToSegment(
  pointX: number,
  pointY: number,
  segmentAx: number,
  segmentAy: number,
  segmentBx: number,
  segmentBy: number
): number {
  const A = pointX - segmentAx;
  const B = pointY - segmentAy;
  const C = segmentBx - segmentAx;
  const D = segmentBy - segmentAy;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq != 0) {
    param = dot / len_sq;
  }

  let xx, yy;

  if (param < 0) {
    xx = segmentAx;
    yy = segmentAy;
  } else if (param > 1) {
    xx = segmentBx;
    yy = segmentBy;
  } else {
    xx = segmentAx + param * C;
    yy = segmentAy + param * D;
  }

  const dx = pointX - xx;
  const dy = pointY - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

export function isPolygonSelfIntersecting(polygon: Vector3[]): boolean {
  // For each point measure distance to each other edge this point is not a part of.
  // If distance is less than threshold, then polygon is considered to be self-intersecting.
  // Don't consider the last points and special cases for the first points - against
  // the first and the last segments.
  for (let pointIndex = 0; pointIndex < polygon.length - 1; pointIndex++) {
    for (let segmentIndex = 0; segmentIndex < polygon.length - 1; segmentIndex++) {
      if (
        polygon[segmentIndex] === polygon[pointIndex]
        || polygon[segmentIndex + 1] === polygon[pointIndex]
        || (pointIndex === 0 && segmentIndex === 0)
        || (pointIndex === 0 && segmentIndex === polygon.length - 2)
      ) {
        continue;
      }
      if (
        distanceFromPointToSegment(
          polygon[pointIndex].x,
          polygon[pointIndex].y,
          polygon[segmentIndex].x,
          polygon[segmentIndex].y,
          polygon[segmentIndex + 1].x,
          polygon[segmentIndex + 1].y
        ) <= EPSILON
      ) {
        return true;
      }
    }
  }

  // The previous part checked if any vertex is touching any other edge.
  // And here we're checking the actual intersections.
  // Only consider closed polygons for self-intersection using
  // isPolygonIntersectingAnother. Because wip polygon has no last point, and
  // when we consider a line from last point of wip polygon to it's first point,
  // it will often self-intersect.
  if (polygon[0].equals(polygon[polygon.length - 1])) {
    return isPolygonIntersectingAnother(polygon, polygon, true);
  }
  return false;
}

/**
 * Calculate if a polygon A intersects polygon B
 * @param polygonA polygon
 * @param polygonB polygon
 * @param allowEdge if the user allows common points from polygonA and polygonB
 */
export function isPolygonIntersectingAnother(
  polygonA: Vector3[],
  polygonB: Vector3[],
  allowEdge: boolean = true
): boolean {
  if (!allowEdge) {
    for (const vertexOfPolygonA of polygonA) {
      for (let j = 0; j < polygonB.length - 1; ++j) {
        const isPointOnLineSegment = isPointOnLine(polygonB[j], polygonB[j + 1], vertexOfPolygonA, EPSILON);
        if (isPointOnLineSegment) {
          return true;
        }
      }
    }
  }

  const areCompletePolygons: boolean =
    polygonA[0].x === polygonA[polygonA.length - 1].x
    && polygonA[0].y === polygonA[polygonA.length - 1].y
    && polygonB[0].x === polygonB[polygonB.length - 1].x
    && polygonB[0].y === polygonB[polygonB.length - 1].y;

  if (
    polygonA !== polygonB
    // Check that the polygon is not WIP
    && areCompletePolygons
    && isPolygonIntersection(polygonA, polygonB)
  ) {
    return true;
  }

  return arePolygonEdgesIntersecting(polygonA, polygonB);
}

export function isPolygonInsideOrIntersectingAnother(polygonA: Vector3[], polygonB: Vector3[]): boolean {
  return areVerticesInsidePolygon(polygonA, polygonB) || isPolygonIntersectingAnother(polygonA, polygonB);
}

export function arePolygonEdgesIntersecting(polygonA: Vector3[], polygonB: Vector3[]): boolean {
  // Let's check if any polygonA segment intersects any polygonB segment
  let lineA: ILineSegment;

  for (let i = 0; i < polygonA.length - 1; ++i) {
    lineA = {
      A: {
        x: polygonA[i].x,
        y: polygonA[i].y
      },
      B: {
        x: polygonA[i + 1].x, // we include the last with the first point like this
        y: polygonA[i + 1].y
      }
    };

    if (doesLineSegmentIntersectPolygon(lineA, polygonB, EPSILON)) {
      return true;
    }
  }

  return false;
}

export function distanceBetween2Points(a: SimpleVector2, b: SimpleVector2): number {
  return Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
}

/**
 * Returns the index of the roofFaceGeometry's edge which is associated with the selectedGeometry,
 * which is typically the geometry of a Segment, a Pathway, or a Setback
 * TODO: This is not very nice, we should have a reference from the setback/pathway to the edge, or at least the index
 */
export function getEdgeIndex(
  selectedGeometry: ISimpleVector3[],
  roofFaceGeometry: ISimpleVector3[]
): { minDistance: number; index: number } {
  const minimum = {
    index: -1,
    value: Infinity
  };

  for (let i = 0; i < roofFaceGeometry.length - 1; ++i) {
    const roofFaceVertex1 = new Vector2(roofFaceGeometry[i].x, roofFaceGeometry[i].y);
    const roofFaceVertex2 = new Vector2(roofFaceGeometry[i + 1].x, roofFaceGeometry[i + 1].y);

    let restrictedAreaToEdge: number = -Infinity;

    for (const selectedGeometryVertex of selectedGeometry) {
      const fireVentilationVertex = new Vector2(selectedGeometryVertex.x, selectedGeometryVertex.y);

      restrictedAreaToEdge = Math.max(
        restrictedAreaToEdge,
        sdSegment(fireVentilationVertex, roofFaceVertex1, roofFaceVertex2)
      );
    }

    if (restrictedAreaToEdge < minimum.value) {
      minimum.value = restrictedAreaToEdge;
      minimum.index = i;
    }
  }

  return {
    index: minimum.index,
    minDistance: minimum.value
  };
}

/**
 * Direction doesn't matter here
 */
export function segmentEqualsOtherSegment(a: ILineSegment, b: ILineSegment, threshold: number = EPSILON): boolean {
  const aA = new Vector2(a.A.x, a.A.y);
  const aB = new Vector2(a.B.x, a.B.y);
  const bA = new Vector2(b.A.x, b.A.y);
  const bB = new Vector2(b.B.x, b.B.y);

  return (
    (pointEqualsOtherPoint(aA, bA, threshold) && pointEqualsOtherPoint(aB, bB, threshold))
    || (pointEqualsOtherPoint(aA, bB, threshold) && pointEqualsOtherPoint(aB, bA, threshold))
  );
}

function pointEqualsOtherPoint(a: Vector2, b: Vector2, threshold: number = EPSILON): boolean {
  return a.distanceTo(b) <= threshold;
}

// Returns the edge associated with the restricted area
export function getEdgeTypeByRestrictedArea(restrictedArea: VentilationSetback | Pathway): ERoofFaceEdgeType {
  if (!getParentLyraModelByMeshOrLyraModel(restrictedArea)) {
    console.error('Restricted area has no parent');
    return '' as ERoofFaceEdgeType;
  }
  const roofFace = getParentLyraModelByMeshOrLyraModel<RoofFace>(restrictedArea)!;
  const roofFaceGeometry = roofFace.getVector3s();
  const restrictedAreaGeometry = restrictedArea.getVector3s();

  const indexObject = getEdgeIndex(restrictedAreaGeometry, roofFaceGeometry);

  return roofFace.edgeTypes[indexObject.index] || '';
}

// https://www.iquilezles.org/www/articles/distfunctions2d/distfunctions2d.htm
function sdSegment(p: Vector2, a: Vector2, b: Vector2): number {
  const pa = new Vector2().subVectors(p, a);
  const ba = new Vector2().subVectors(b, a);
  const h = MathUtils.clamp(pa.dot(ba) / ba.dot(ba), 0, 1);

  return new Vector2().subVectors(pa, ba.multiplyScalar(h))
    .length();
}

function isLineSegmentInsidePolygon(lineSegment: ILineSegment, polygon: Vector3[]): boolean {
  for (let j = 0; j < polygon.length - 1; ++j) {
    const lineB = {
      A: {
        x: polygon[j].x,
        y: polygon[j].y
      },
      B: {
        x: polygon[j + 1].x,
        y: polygon[j + 1].y
      }
    };

    if (
      pointInsidePolygon(polygon, new Vector3(lineB.A.x, lineB.A.y, 0))
      || pointInsidePolygon(polygon, new Vector3(lineB.B.x, lineB.B.y, 0))
      || doesLineSegmentIntersectLineSegment(lineSegment, lineB)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Checks if lineSegment intersects any of sides of the polygon
 */
export function doesLineSegmentIntersectPolygon(
  lineSegment: ILineSegment,
  polygon: Vector3[],
  threshold: number = EPSILON
): boolean {
  for (let j = 0; j < polygon.length; ++j) {
    const lineB = {
      A: {
        x: polygon[j].x,
        y: polygon[j].y
      },
      B: {
        // Checking with last and initial vertex when j on last vertex
        x: j == polygon.length - 1 ? polygon[0].x : polygon[j + 1].x,
        y: j == polygon.length - 1 ? polygon[0].y : polygon[j + 1].y
      }
    };

    if (doesLineSegmentIntersectLineSegment(lineSegment, lineB, threshold)) {
      return true;
    }
  }

  return false;
}

/**
 * https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
 * @param l1 first linesegment given with 2 endpoints (A, B)
 * @param l2 second linesegment given with 2 endpoints (A, B)
 */
function doesLineSegmentIntersectLineSegment(l1: ILineSegment, l2: ILineSegment, threshold: number = 0.01): boolean {
  const det = (l1.B.x - l1.A.x) * (l2.B.y - l2.A.y) - (l2.B.x - l2.A.x) * (l1.B.y - l1.A.y);

  if (Math.abs(det) < EPSILON) {
    return false;
  } else {
    const lambda = ((l2.B.y - l2.A.y) * (l2.B.x - l1.A.x) + (l2.A.x - l2.B.x) * (l2.B.y - l1.A.y)) / det;
    const gamma = ((l1.A.y - l1.B.y) * (l2.B.x - l1.A.x) + (l1.B.x - l1.A.x) * (l2.B.y - l1.A.y)) / det;

    return threshold < lambda && lambda < 1 - threshold && threshold < gamma && gamma < 1 - threshold;
  }
}

/**
 * Creates an axis-aligned bounding box (AABB) based on the provided vertices
 * @param vertices The vertices to calculate the box from
 */
function createBoundingBox(vertices: ISimpleVector3[]): IBox3 {
  return {
    min: {
      x: minBy(vertices, (v: ISimpleVector3): number => v.x)!.x,
      y: minBy(vertices, (v: ISimpleVector3): number => v.y)!.y,
      z: minBy(vertices, (v: ISimpleVector3): number => v.z)!.z
    },
    max: {
      x: maxBy(vertices, (v: ISimpleVector3): number => v.x)!.x,
      y: maxBy(vertices, (v: ISimpleVector3): number => v.y)!.y,
      z: maxBy(vertices, (v: ISimpleVector3): number => v.z)!.z
    }
  };
}

/**
 * Returns the center of bbox
 */
function getCenterOfBoundingBox(bbox: IBox3): ISimpleVector3 {
  return {
    x: (bbox.min.x + bbox.max.x) / 2,
    y: (bbox.min.y + bbox.max.y) / 2,
    z: (bbox.min.z + bbox.max.z) / 2
  };
}

/**
 * Returns the size of bbox
 */
function getSizeOfBoundingBox(bbox: IBox3): ISimpleVector3 {
  return {
    x: bbox.max.x - bbox.min.x,
    y: bbox.max.y - bbox.min.y,
    z: bbox.max.z - bbox.min.z
  };
}

export function getCenterOfBoundingBoxAroundVertices(vertices: ISimpleVector3[]): Vector3 {
  const bbox = createBoundingBox(vertices);
  const ivec = getCenterOfBoundingBox(bbox);
  return new Vector3(ivec.x, ivec.y, ivec.z);
}

export function objectInsideOther(
  editor: EditorStore,
  polygonToValidate: PolygonDrawable,
  nameObjectoToValidate: string
): PolygonDrawable | undefined {
  const objects: PolygonDrawable[] = editor.getObjectsByType(nameObjectoToValidate);
  let objectSelected: PolygonDrawable | undefined;

  objects.forEach((object: PolygonDrawable): void => {
    let vertexInRoofFace = true;
    polygonToValidate.boundary.segments.forEach((segment: Segment): void => {
      // Call method to validate if point is into polygon
      const polygonVertices = getDistinctSegmentVertices(object.boundary.segments);
      const pointInRoofFace: boolean = pointInsidePolygon(polygonVertices, segment.points[0].getVector3());

      if (!pointInRoofFace) {
        vertexInRoofFace = false;
      }
    });

    if (vertexInRoofFace) {
      objectSelected = object;
    }
  });

  return objectSelected;
}

// Irrespective of Order of Segments this function will return an array of unique vertices of the segment array
function getDistinctSegmentVertices(segments: Segment[]): Vector3[] {
  const vertices: Vector3[] = [];
  const segmentsArry: Segment[] = [...segments];

  vertices.push(segmentsArry[0].points[0].getVector3());
  vertices.push(segmentsArry[0].points[1].getVector3());
  segmentsArry.splice(0, 1);

  while (segmentsArry.length > 1) {
    for (const segment of segmentsArry) {
      if (vertices[vertices.length - 1].equals(segment.points[0].getVector3())) {
        vertices.push(segment.points[1].getVector3());
        segmentsArry.splice(segmentsArry.indexOf(segment), 1);
      } else if (vertices[vertices.length - 1].equals(segment.points[1].getVector3())) {
        vertices.push(segment.points[0].getVector3());
        segmentsArry.splice(segmentsArry.indexOf(segment), 1);
      }
    }
  }
  return vertices;
}

/**
 * Return count number of Vector3 between two Vector3 using Linear Interpolation
 * @param pointA First Vector
 * @param pointB Second Vector
 * @param count Number of vectors between pointA and point B needed
 * @param inclusive Weather or not to include pointA and pointB in the result
 */
function interpolateBetweenPoints(pointA: Vector3, pointB: Vector3, count: number, inclusive: boolean): Vector3[] {
  const interpolation: Vector3[] = [];
  const step = 1 / (count + 1);
  if (inclusive) {
    interpolation.push(pointA);
  }
  for (let i = step; i < 1; i += step) {
    interpolation.push(pointA.clone().add(pointB.clone().sub(pointA)
      .multiplyScalar(i)));
  }
  if (inclusive) {
    interpolation.push(pointB);
  }
  return interpolation;
}

export function isVertexLayingOnOtherLineExceptEndingsOrCollapsed(
  vertices: Vector3[],
  vertexToCheck: Vector3,
  previousVertexLocation: Vector3
): boolean {
  for (let j = 0; j < vertices.length - 1; ++j) {
    const isPointOnLineSegment = isPointOnLine(vertices[j], vertices[j + 1], vertexToCheck, EPSILON);
    if (previousVertexLocation.equals(vertices[j]) || previousVertexLocation.equals(vertices[j + 1])) {
      continue;
    }
    if (
      (isPointOnLineSegment && !vertexToCheck.equals(vertices[j]) && !vertexToCheck.equals(vertices[j + 1]))
      || (vertices[j].distanceTo(vertexToCheck) <= EPSILON && vertexToCheck !== vertices[j])
    ) {
      return true;
    }
  }
  return false;
}

/**
 * applyRotationWithTranslation
 * @param vertices
 * @param rotationAmountInRadians
 * @param rotationAxis
 * @param translation vector or vertext index, index 0 by default
 */
function applyRotationWithTranslation(
  vertices: Vector3[],
  rotationAmountInRadians: number,
  rotationAxis: Vector3,
  translation: Vector3 | number = 0
) {
  const translationVector = translation instanceof Vector3 ? translation : vertices[translation];
  const shiftX = translationVector.x;
  const shiftY = translationVector.y;
  const shiftZ = translationVector.z;

  let resultingVertices = vertices;

  // Translate to origin:
  resultingVertices = applyTranslation(resultingVertices, new Vector3(-shiftX, -shiftY, -shiftZ));
  // Rotate
  resultingVertices = applyRotation(resultingVertices, rotationAxis, rotationAmountInRadians);
  // Translate back
  resultingVertices = applyTranslation(resultingVertices, new Vector3(shiftX, shiftY, shiftZ));

  return resultingVertices;
}

export function isVertexLayingOnOneOfTheEdges(vertices: Vector3[], vertexToCheck: Vector3): boolean {
  for (let j = 0; j < vertices.length - 1; ++j) {
    const isPointOnLineSegment = isPointOnLine(vertices[j], vertices[j + 1], vertexToCheck, EPSILON);
    if (isPointOnLineSegment) {
      return true;
    }
  }
  return false;
}

/**
 * Calculate four vertices of a PV module preview
 *
 * Rotations to account for:
 * 1. flat roof face + low slope mounting system
 * 2. sloped roof face  + low slope mounting system
 * 3. sloped roof face  + steep slope mounting system
 *
 * Possible rotations to account for:
 * A. mounting system azimuth (around z axis)
 * B. tilt angle (around a bottom side of a PV module position)
 * C. roof slope angle in direction of roof azimuth (around vector horizontally
 *    perpendicular to roof slope vector directed as roof's azimuth)
 * D. roof azimuth

 *
 * case 1: B -> A
 * case 2: B -> A (-> C: temporary replaced with a similar solution
 *                 that produces right angles)
 * case 3: C -> D
 *
 * @param mousePosition
 * @param roofFace
 * @param width of PV module
 * @param length of PV module
 * @param mountingSystem
 * @param mountingSystemDefinition
 */
export function getNewPvModulePositionVertices(
  mousePosition: Vector3,
  roofFace: RoofFace,
  width: number,
  length: number,
  mountingSystem: MountingSystem,
  mountingSystemDefinition: MountingSystemDefinition
): Vector3[] {
  /*
   * 1. create a new PV module position with certain spacings and coordinates
   * 2. vertex A should be calculateZFromInfiniteMesh to the roof (at current position)
   * 3. vertices B, C, D should be calculated with roof geometry (roof slope projection and azimuth rotation)
   */
  let pvModuleLength = ProjectionUtil.convertToWorldUnits(length, Units.Meters);
  let pvModuleWidth = ProjectionUtil.convertToWorldUnits(width, Units.Meters);

  let roofSlopeInRadians = degreesToRadians(roofFace.slope ?? 0);

  const isLowSlope = roofFace.slopeType === ERoofSlopeType.LowSlope;
  const isLandscape = mountingSystem.layoutStrategy.dominantOrientation === EOrientation.LANDSCAPE;

  /*
   *  C----D
   *  |    |
   *  |    |
   *  B----A
   */
  const pivotHeight = calculateZFromInfiniteMesh(roofFace.getVector3s(), mousePosition);

  let A = new Vector3(mousePosition.x, mousePosition.y, pivotHeight);
  let D = new Vector3(mousePosition.x, mousePosition.y + pvModuleLength, pivotHeight);
  let B = A.clone().add(new Vector3(-pvModuleWidth, 0, 0));
  let C = D.clone().add(new Vector3(-pvModuleWidth, 0, 0));

  // Rotation A
  function accountForMountingSystemAzimuth() {
    let mountingSystemAzimuth = -degreesToRadians(mountingSystem.configuration?.azimuth ?? 0);
    if (isLandscape) {
      mountingSystemAzimuth += Math.PI / 2;
    }

    [A, B, C, D] = applyRotationWithTranslation([A, B, C, D], mountingSystemAzimuth, new Vector3(0, 0, 1));
  }

  // Rotation B
  function accountForTiltAngle() {
    const tiltAngle = degreesToRadians(mountingSystem.configuration?.tiltAngle ?? 0);
    [A, B, C, D] = applyRotationWithTranslation(
      [A, B, C, D],
      tiltAngle,
      new Vector3(isLandscape ? 0 : 1, isLandscape ? 1 : 0, 0)
    );
  }

  // Rotation D
  function accountForRoofAzimuth() {
    let roofAzimuthInRadians = -roofFace.getAzimuthOrSegmentAngle();
    if (isLandscape) {
      roofAzimuthInRadians += Math.PI / 2;
    }

    // Account for roof azimuth
    [A, B, C, D] = applyRotationWithTranslation([A, B, C, D], roofAzimuthInRadians, new Vector3(0, 0, 1));
  }

  // Rotation C
  function accountForRoofSlopeInAzimuthDirection() {
    const roofAzimuthInRadians = -roofFace.getAzimuthOrSegmentAngle();
    const rotationAxis = new Vector3(1, 0, 0);

    rotationAxis.applyAxisAngle(new Vector3(0, 0, 1), roofAzimuthInRadians);
    // Account for roof slope
    [A, B, C, D] = applyRotationWithTranslation([A, B, C, D], roofSlopeInRadians, rotationAxis);
  }

  // Rotation C
  function accountForRoofSlopeInAzimuthDirectionWithSquareAnglesInXYPlane() {
    const minZ = Math.min(A.z, B.z, C.z, D.z);
    A.z = B.z = C.z = D.z = minZ;
    const roofAzimuthInRadians = -roofFace.getAzimuthOrSegmentAngle();
    const rotationAxis = new Vector3(1, 0, 0);

    rotationAxis.applyAxisAngle(new Vector3(0, 0, 1), roofAzimuthInRadians);
    // Account for roof slope
    [A, B, C, D] = applyRotationWithTranslation([A, B, C, D], roofSlopeInRadians, rotationAxis);
  }

  // Case 1
  const lowSlopeMountingSystemOnFlatRoofCase: boolean = (roofFace.slope ?? 0) === 0;
  // Case 2
  const lowSlopeMountingSystemOnSlopedRoofCase: boolean = (roofFace.slope ?? 0) !== 0 && isLowSlope;
  // Case 3
  const steepSlopeMountingSystemOnSlopedRoofCase: boolean = (roofFace.slope ?? 0) !== 0 && !isLowSlope;

  /*
   * case 1: B -> A
   * case 2: B -> A (-> E: temporary disabled)
   * case 3: C -> D
   */
  if (lowSlopeMountingSystemOnFlatRoofCase) {
    accountForTiltAngle();
    accountForMountingSystemAzimuth();
  } else if (lowSlopeMountingSystemOnSlopedRoofCase) {
    accountForTiltAngle();
    accountForMountingSystemAzimuth();
    // FIXME: uncomment when backend is ready
    // accountForRoofSlopeInAzimuthDirection();
    accountForRoofSlopeInAzimuthDirectionWithSquareAnglesInXYPlane();
  } else if (steepSlopeMountingSystemOnSlopedRoofCase) {
    accountForRoofAzimuth();
    accountForRoofSlopeInAzimuthDirectionWithSquareAnglesInXYPlane();
  }

  // Translate so that PV module position center is under cursor
  // Mouse corresponds to point A, so we don't account for it in translation
  [A, B, C, D] = applyTranslation(
    [A, B, C, D],
    new Vector3(
      -(/*(a.x - a.x) + */ ((B.x - A.x + (C.x - A.x) + (D.x - A.x)) / 4)),
      -(/*(a.y - a.y) + */ (B.y - A.y + (C.y - A.y) + (D.y - A.y))) / 4,
      0
    )
  );

  return [A, B, C, D];
}

function applyRotation(vertices: Vector3[], normalisedAxisVector: Vector3, angleRadians: number) {
  const rotationMatrix = new Matrix4();
  rotationMatrix.identity();
  rotationMatrix.makeRotationAxis(normalisedAxisVector, angleRadians);

  vertices.forEach((vertex: Vector3): void => {
    vertex.applyMatrix4(rotationMatrix);
  });

  return vertices;
}

function applyTranslation(vertices: Vector3[], translationVector: Vector3) {
  const translationMatrix = new Matrix4();
  translationMatrix.identity();
  translationMatrix.elements[12] = translationVector.x;
  translationMatrix.elements[13] = translationVector.y;
  translationMatrix.elements[14] = translationVector.z;

  vertices.forEach((vertex: Vector3): void => {
    vertex.applyMatrix4(translationMatrix);
  });

  return vertices;
}

/** Returns true if polygonA encloses vertices from polygonB */
export function isPolygonEnclosingOtherPolygon(polygonA: Vector3[], polygonB: Vector3[], allowEdge: boolean): boolean {
  if (polygonB.length > 0 && polygonA.length > 0) {
    const lastVertexB = polygonB[polygonB.length - 1];
    const lastVertexA = polygonA[polygonA.length - 1];
    const deltaA = new Vector3().subVectors(lastVertexA, polygonA[0]);
    const distanceA = Math.max(Math.abs(deltaA.x), Math.abs(deltaA.y));
    const deltaB = new Vector3().subVectors(lastVertexB, polygonB[0]);
    const distanceB = Math.max(Math.abs(deltaB.x), Math.abs(deltaB.y));

    if (Math.max(distanceA, distanceB) > canvasConfig.snapThreshold) {
      return false;
    }

    const areVerticesOfPolygonBCompletelyInsidePolygonA = polygonB.every((v: Vector3): boolean =>
      pointInsidePolygon(polygonA, v, {
        onEdgeConsideredInside: allowEdge,
        noErrorMargin: !allowEdge
      })
    );
    if (areVerticesOfPolygonBCompletelyInsidePolygonA) {
      return true;
    }
  }
  return false;
}

export function convertWktPolygonToVertices(wktString: string): Vector3[] {
  return wktString
    .replace(/\n/gi, '')
    .split(',')
    .map((substr: string): Vector3 => {
      const components: number[] = [];
      for (let match of substr.match(/\-?\d+(\.\d+)?/gi)!) {
        components.push(Number(match));
      }
      return new Vector3(...components);
    });
}

export function convertVerticesToWktPolygon(vertices: Vector3[]): string {
  const components = vertices
    .map((vertex: Vector3): string => `${vertex.x.toFixed(2)} ${vertex.y.toFixed(2)} ${vertex.z.toFixed(2)}`)
    .join(', ');
  return `POLYGON Z((${components}))`;
}

/**
 * @description getSegmentsIntersectionPoint finds a point of intersection between two segments
 * defined by its' start and end points.
 * @param firstSegmentStart
 * @param firstSegmentEnd
 * @param secondSegmentStart
 * @param secondSegmentEnd
 */
export function getSegmentsIntersectionPoint(
  firstSegmentStart: Vector2 | Vector3 | SimpleVector2,
  firstSegmentEnd: Vector2 | Vector3 | SimpleVector2,
  secondSegmentStart: Vector2 | Vector3 | SimpleVector2,
  secondSegmentEnd: Vector2 | Vector3 | SimpleVector2
): Vector2 {
  /*
  Segments intersection algorithm from here: https://stackoverflow.com/a/563275
  E = B-A = ( Bx-Ax, By-Ay )
  F = D-C = ( Dx-Cx, Dy-Cy )
  P = ( -Ey, Ex )
  h = ( (A-C) * P ) / ( F * P )
  intersection is C + F * h
  */
  const C = new SimpleVector2(secondSegmentStart.x, secondSegmentStart.y);
  const D = new SimpleVector2(secondSegmentEnd.x, secondSegmentEnd.y);
  const A = new SimpleVector2(firstSegmentStart.x, firstSegmentStart.y);
  const B = new SimpleVector2(firstSegmentEnd.x, firstSegmentEnd.y);
  const E = subSimpleVector2s(B, A);
  const F = subSimpleVector2s(D, C);
  const P = new SimpleVector2(-E.y, E.x);
  const ASubC = subSimpleVector2s(A, C);
  const h = (ASubC.x * P.x + ASubC.y * P.y) / (F.x * P.x + F.y * P.y);
  const intersection = new SimpleVector2(C.x + F.x * h, C.y + F.y * h);
  return new Vector2(intersection.x, intersection.y);
}

export function calculateDistanceFromPlaneFrom3PointsToAPoint(
  planeA: ISimpleVector3,
  planeB: ISimpleVector3,
  planeC: ISimpleVector3,
  pointToMeasureDistanceTo: ISimpleVector3
) {
  //
  const ab: ISimpleVector3 = {
    x: planeB.x - planeA.x,
    y: planeB.y - planeA.y,
    z: planeB.z - planeA.z
  };
  const ac: ISimpleVector3 = {
    x: planeC.x - planeA.x,
    y: planeC.y - planeA.y,
    z: planeC.z - planeA.z
  };
  const Nx = ab.y * ac.z - ab.z * ac.y;
  const Ny = ab.z * ac.x - ab.x * ac.z;
  const Nz = ab.x * ac.y - ab.y * ac.x;
  //const Normal = [Nx, Ny, Nz];
  // Nx(x - x1) + Ny(y - y1) + Nz(z - z1) = 0
  return Math.abs(
    (Nx * (pointToMeasureDistanceTo.x - planeA.x)
      + Ny * (pointToMeasureDistanceTo.y - planeA.y)
      + Nz * (pointToMeasureDistanceTo.z - planeA.z))
      / Math.sqrt(Math.pow(Nx, 2) + Math.pow(Ny, 2) + Math.pow(Nz, 2))
  );
}

// Solution from https://math.stackexchange.com/a/3128850
// from AB segment to P
export function closestPointBetween2D(P: Vector3, A: Vector3, B: Vector3): Vector3 {
  const zero2D = new Vector3(0, 0, 0);
  function vectorToSegment2D(t: number, P: Vector3, A: Vector3, B: Vector3): Vector3 {
    return new Vector3((1 - t) * A.x + t * B.x - P.x, (1 - t) * A.y + t * B.y - P.y, 0);
  }

  function squareDiagonal2D(P: Vector3) {
    return P.x ** 2 + P.y ** 2;
  }

  const V = new Vector3(B.x - A.x, B.y - A.y, 0);
  const U = new Vector3(A.x - P.x, A.y - P.y, 0);
  const VU = V.x * U.x + V.y * U.y;
  const VV = V.x ** 2 + V.y ** 2;
  const T = -VU / VV;
  if (T >= 0 && T <= 1) {
    return vectorToSegment2D(T, zero2D, A, B);
  }
  const G0 = squareDiagonal2D(vectorToSegment2D(0, P, A, B));
  const G1 = squareDiagonal2D(vectorToSegment2D(1, P, A, B));
  return G0 <= G1 ? A : B;
}

// Clockwise angle calculation solution from https://stackoverflow.com/a/16544330/2229104
export function clockwiseRotationInXYBetweenTwoVector3(v1: Vector3, v2: Vector3): number {
  const dot = v1.x * v2.x + v1.y * v2.y;
  const det = v1.x * v2.y - v1.y * v2.x;
  return Math.atan2(det, dot);
}

export function toCanvasCoordinatesInPixels(editor: EditorStore, position: Vector3): SimpleVector2 {
  const normalisedCanvasCoordinates = position.clone().project(editor.activeCamera!);

  return new SimpleVector2(
    editor.viewport.resolution.x / 2 + (normalisedCanvasCoordinates.x * editor.viewport.resolution.x) / 2,
    editor.viewport.resolution.y / 2 - (normalisedCanvasCoordinates.y * editor.viewport.resolution.y) / 2
  );
}
