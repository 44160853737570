import type { IElectricalPanelData } from './ElectricalPanelInstance';
import type { IGenericEquipmentData } from './GenericEquipmentInstance';
import type { IMeterMainData } from './MeterMainInstance';
import type { IMainBreakerInEnclosureData } from './MainBreakerInEnclosureInstance';

export enum SiteEquipmentDataKeys {
  utilityMeter = 'utilityMeter',
  meterMain = 'meterMain',
  mainServicePanel = 'mainServicePanel',
  subpanel = 'subpanel',
  gasMeter = 'gasMeter',
  mainBreakerInEnclosure = 'mainBreakerInEnclosure'
}

export interface ISiteEquipmentData {
  [SiteEquipmentDataKeys.mainServicePanel]?: IElectricalPanelData;
  [SiteEquipmentDataKeys.utilityMeter]?: IGenericEquipmentData;
  [SiteEquipmentDataKeys.meterMain]?: IMeterMainData;
  [SiteEquipmentDataKeys.subpanel]?: IElectricalPanelData;
  [SiteEquipmentDataKeys.gasMeter]?: IGenericEquipmentData;
  [SiteEquipmentDataKeys.mainBreakerInEnclosure]?: IMainBreakerInEnclosureData;
}
