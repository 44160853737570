import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Deletable } from '../../../domain/mixins/Deletable';
import type { IConfirmableCommand } from './Command';
import { BaseCommand } from './Command';

export type DeleteObjectDependencies = Readonly<{
  editor: EditorStore;
  object: Deletable;
  domain: DomainStore;
  skipConfirmation?: boolean;
}>;

export abstract class DeleteObjectCommand extends BaseCommand implements IConfirmableCommand {
  protected readonly editor: EditorStore;
  protected readonly object: Deletable;
  protected readonly domain: DomainStore;
  protected readonly skipConfirmation: boolean;

  constructor(dependencies: DeleteObjectDependencies) {
    super();
    this.editor = dependencies.editor;
    this.object = dependencies.object;
    this.domain = dependencies.domain;
    this.skipConfirmation = dependencies.skipConfirmation ?? false;
  }

  get confirmationModalText(): string | undefined {
    if (this.skipConfirmation) {
      return undefined;
    }

    const object = this.object;
    return (
      (object.isDeletable && object.deleteConfirmationWarning) || `Are you sure you want to delete ${this.object.name}?`
    );
  }

  execute(): Promise<void> {
    this.deleteFromDomain();
    this.deleteFromEditor();
    return Promise.resolve();
  }

  protected abstract deleteFromEditor(): void;

  protected abstract deleteFromDomain(): void;
}
