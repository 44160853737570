import React, {
  BaseProps,
  ReactElement
} from 'react';
import { Divider } from '../Divider';
import { Tooltip } from  '../Tooltip';
import { Flexbox } from '../Layout';
import { Option } from './Option';
import { OptionDirection } from './OptionDirection';
import { ListBar, IconWrapper } from './styles';
import { Icon } from '../Icon';
import { Heading } from '../Typography/Heading';

type HandlerOnSelect = (value: string | number) => void;

type MenuTheme = 'secondary' | 'white';

type ListBarProps = BaseProps & {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSelect?: HandlerOnSelect;
  multiple?: boolean;
  disabled?: boolean;
  showArrowIcon?: boolean;
  optionDirection?: OptionDirection;
  menuTheme?: MenuTheme;
  options: Option[];
  header?: string;
  withDivider?: boolean;
  arrowPosition?: 'top' | 'bottom';
  tooltipPosition?: 'top' | 'bottom';
  tooltipTheme?: 'dark' | 'white';
};

const OptionsBar = ({
  arrowPosition,
  options,
  optionDirection = 'row',
  onSelect,
  withDivider = false,
  header = '',
  tooltipPosition,
  tooltipTheme
}: ListBarProps): ReactElement => {

  const onHandleClick = (option: Option): void => {
    if (option.isActive) {
      if (typeof onSelect === 'function') {
        onSelect(option.value);
      }
    }
  };

  return (
    <Flexbox
      dir="column"
      justify="space-evently"
      align="center"
    >
      {options.length && (
        <>
          <ListBar dir={optionDirection} className="list-bar">
            {options.map(
              (option: Option, index: number): ReactElement => {
                return (
                  <Flexbox
                    key={index}
                    align="center"
                    dir={optionDirection}
                    className="list-item"
                    onClick={(): void => {
                      onHandleClick(option);
                    }}
                  >
                    <IconWrapper
                      isActive={option.isActive}
                      isEnabled={option.isEnabled}
                      dir={optionDirection}
                    >
                      <Icon name={option.icon as string}/>
                    </IconWrapper>
                    {withDivider && (
                      <Divider
                        direction={optionDirection === 'row' ? 'vertical' : 'horizontal'}
                        length="50px"
                      />
                    )}
                    {option.label && (
                      <Tooltip
                        tooltipTheme={tooltipTheme}
                        tooltipPosition={tooltipPosition}
                        arrowPosition={arrowPosition}
                        tooltipVisibility={false}
                      >
                        {option.label}
                      </Tooltip>
                    )}
                  </Flexbox>
                );
              }
            )}
          </ListBar>
          {header && <Heading lineHeight={0} margin="10px 0" fontSize="12px">{header}</Heading>}
        </>
      )}
    </Flexbox>
  );
};

export { OptionsBar };
