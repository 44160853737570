import {
  LyraDivider, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import {
  type ReactElement, useContext
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { ThemeContext } from 'styled-components';
import type { SiteImage } from '../../../../domain/models/SiteDesign/SiteImage';
import useStore, { useUiStore } from '../../../../stores/useStore';
import type { IViewUploadImageDependencies } from '../../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import ViewUploadImage from '../../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import { ServiceEntranceEquipmentLoadCenterViewModel } from '../../../../stores/UiStore/Properties/SiteEquipment/ViewModels/ServiceEntranceEquipmentLoadCenterViewModel';
import MSPFeatures from '../../../containers/SiteEquipmentPanel/MSPFeatures/MSPFeatures';
import SiteImagesContainer from '../../SiteImagesContainer/SiteImagesContainer';
import type {
  IDeleteSiteImageCommandDependencies,
  TObjectWithDeletableImage
} from '../../../../stores/ServiceBus/Commands/DeleteSiteImageCommand';

import { ServiceEntranceEquipmentFormContainer } from '../ServiceEntranceEquipmentFormContainer';
import type { LoadCenter } from '../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';

function LoadCenterForm({
  title, label
}: { title: string; label: string }): ReactElement {
  const {
    editor, domain, serviceBus
  } = useStore();
  const {
    workspace, modal
  } = useUiStore();
  const { mainServicePanel } = domain.project.site.equipment;
  const [viewModel, setViewModel] = useState<ServiceEntranceEquipmentLoadCenterViewModel>();

  useEffect(() => {
    const viewModel = new ServiceEntranceEquipmentLoadCenterViewModel({
      editor,
      domain,
      serviceBus
    });
    setViewModel(viewModel);
    return () => {
      viewModel.clearShortCircuitRatingAutoUpdate();
    };
    // Adding viewModel to a list of dependencies will send this hook into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, domain, serviceBus]);

  const onAddImageClick = useCallback((): void => {
    const dependencies: IViewUploadImageDependencies = {
      modal,
      domain,
      editor,
      serviceBus,
      workspace
    };
    modal.createModal(
      'upload_image_modal',
      new ViewUploadImage({
        dependencies: dependencies,
        type: 'msp',
        label
      })
    );
  }, [modal, domain, editor, serviceBus, workspace, label]);

  const onDeleteImageClick = useCallback(
    (image: SiteImage): void => {
      const dependencies: IDeleteSiteImageCommandDependencies<LoadCenter> = {
        editor,
        domain,
        image,
        imageOwnerObject: mainServicePanel as TObjectWithDeletableImage<LoadCenter>
      };

      serviceBus.send('delete_site_image', dependencies);
    },
    [editor, domain, mainServicePanel, serviceBus]
  );
  const theme = useContext(ThemeContext);

  if (!viewModel) {
    return <></>;
  }

  return (
    <>
      {viewModel.loading ? (
        <LyraLoader.Loader text={`Loading ${title} features...`} />
      ) : (
        <ServiceEntranceEquipmentFormContainer label={label} title={title}>
          <MSPFeatures
            theme={theme!}
            viewModel={viewModel}
            mainBreakerPresent={mainServicePanel?.hasMainBreaker ?? false}
          />
          <LyraDivider.Divider direction="horizontal" thickness="1px" color={EThemeComponentColor.GRAY_200} />
          <SiteImagesContainer
            theme={theme!}
            images={mainServicePanel!.images}
            onAddImage={onAddImageClick}
            onDeleteImage={onDeleteImageClick}
          />
        </ServiceEntranceEquipmentFormContainer>
      )}
    </>
  );
}

const ObservedLoadCenterForm = observer(LoadCenterForm);

export { ObservedLoadCenterForm as LoadCenterForm };
