import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import config, { UI_MODE } from '../../../config/config';

export const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 90%;
`;

export const TextInfo = styled.span`
  ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 12px;
  word-break: break-word;
`;
