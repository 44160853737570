import * as React from 'react';

interface IFeatureFlagProps {
  flag: boolean;
  children: React.ReactNode;
}

const FeatureFlag = ({
  flag, children
}: IFeatureFlagProps): React.ReactElement => {
  if (flag) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default FeatureFlag;
