import {
  action, computed, observable
} from 'mobx';
import { ERROR } from '../../../../../domain/models/Constants';
import PermitPackageDocumentGenerationOptions, { type IPermitPackageDocumentGenerationOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import type { IPermitPackageUrls } from '../../../../../infrastructure/services/api/DocumentsService';
import type DomainStore from '../../../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../../../stores/EditorStore/EditorStore';
import { BaseViewModel } from '../../../../../stores/UiStore/Modal/BaseViewModel';
import { type ModalStore } from '../../../../../stores/UiStore/Modal/Modal';
import {
  handleApiError, notify
} from '../../../../../utils/helpers';
import { type IDocumentResponse } from '../../../../../domain/entities/Documents/DocumentsResponse';
import config from '../../../../../config/config';
import type { IDesignFileData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import { EDesignFileType } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import { CodesAndStandards } from '../../../../../domain/models/SiteDesign/CodesAndStandards';
import { getUiStore } from '../../../../../stores/RootStoreInversion';
import { PlanSetPreviewPageViewModel } from '../PlanSetPreview/PlanSetPreviewPageViewModel';

interface IPlanSetCustomizationPageViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly editor: EditorStore;
}

interface IPlanSetFileDirectDownloadFallback {
  url: string;
  isPreview: boolean;
}

export class PlanSetCustomizationPageViewModel extends BaseViewModel {
  readonly propCodeUI = 'instant_plan_set_customization_page';
  override readonly editor: EditorStore;

  @observable
  documentGenerationOptions?: PermitPackageDocumentGenerationOptions;

  @observable
  isSaving: boolean = false;

  @observable
  isGenerating: boolean = false;

  @observable
  private planSetDownloadUrls: IPermitPackageUrls = {
    preview: '',
    purchase: ''
  };

  @observable
  private planSetFileDirectDownloadFallback: IPlanSetFileDirectDownloadFallback = {
    url: '',
    isPreview: true
  };

  @observable
  lastValidationSuccessful: boolean = false;

  constructor(dependencies: IPlanSetCustomizationPageViewModelDependencies) {
    super(dependencies);
    this.editor = dependencies.editor;
  }

  get isInstallerALicensedContractor(): boolean {
    return !config.user.isHomeowner;
  }

  get canContinue(): boolean {
    return !!this.documentGenerationOptions && !this.isGenerating;
  }

  /**
   * See https://aurorasolar.atlassian.net/browse/LYRA-7082 for description of this logic.
   *
   * When changing this logic, make sure to also change the same method inside PermitPackageViewModel.tsx
   */
  @computed
  get showRotateSiteMapPlacardOption(): boolean {
    const electricalCode = this.domain.project.designParameters.codesAndStandards.electricalCode;
    return !(electricalCode === CodesAndStandards.NEC_2020 || electricalCode === CodesAndStandards.CEC_2022);
  }

  @action.bound
  async retrieveDocumentGenerationOptions(): Promise<void> {
    try {
      const permitPackageDocumentOptions: IPermitPackageDocumentGenerationOptionsData =
        await this.documentsService.getPermitPackageOptions(this.domain.design.id);

      const documentGenerationOptions = new PermitPackageDocumentGenerationOptions(
        permitPackageDocumentOptions,
        this.domain.internalReferenceId
      );
      await documentGenerationOptions.engineerOfRecord.geocodeAddress();
      this.documentGenerationOptions = documentGenerationOptions;
    } catch (error) {
      handleApiError('Could not retrieve plan set document generation options')(error);
    }
  }

  @action.bound
  async saveOptionsAndGeneratePlanSet(): Promise<void> {
    this.isGenerating = true;
    if (await this.saveDocumentGenerationOptions()) {
      await this.downloadPlanSetPreview();
    }
    this.isGenerating = false;
  }

  validate = (
    values: IPermitPackageDocumentGenerationOptionsData
  ): Record<'general' | 'engineerOfRecord.name', string | undefined> => {
    const errors: Record<string, string> = {};
    if (!this.documentGenerationOptions) {
      errors['general'] = 'Document generation options are not loaded';
      return errors;
    }

    if (!values.engineerOfRecord) {
      return errors;
    }
    const {
      address, name, licenseNumber, phoneNumber
    } = values.engineerOfRecord;

    const isAddressOneFilled = !!address?.addressOne;
    const isCityFilled = !!address?.city;
    const isProvinceFilled = !!address?.province;
    const isNameValid = !name || name.length <= 21;
    if (!isNameValid) {
      errors['engineerOfRecord.name'] = 'Engineer name must be less than 21 characters';
    }

    const addressFilled = isAddressOneFilled && isCityFilled && isProvinceFilled;
    const isEngineerOfRecordDataFilled = !!licenseNumber && !!phoneNumber;

    // For licensed contractor and checkbox for EOR checked we validate form as usual.
    if (
      values.advancedSettings.titleBlock.showEngineerOfRecordInfo
      && !(addressFilled && isEngineerOfRecordDataFilled)
    ) {
      errors['general'] = 'Please fill out engineer of record address';
    }

    this.lastValidationSuccessful = Object.keys(errors).length === 0;

    return errors;
  };

  @action.bound
  private async saveDocumentGenerationOptions(): Promise<boolean> {
    try {
      this.planSetDownloadUrls = await this.documentsService
        .savePermitPackageOptions(
          this.domain.design.id,
          this.documentGenerationOptions!.toData({
            forServer: true
          })
        )
        .catch(handleApiError('Failed to save plan set document generation options'));
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Downloads plan set document. Note: this is a long operation, can take up to 30s.
   */
  @action.bound
  private async downloadPlanSetPreview(): Promise<void> {
    try {
      const permitPackageDownloadUrl = this.planSetDownloadUrls['preview'];
      const url = await this.documentsService
        .getPermitPackageLink(permitPackageDownloadUrl)
        .catch(handleApiError('Failed to generate plan set preview'));
      this.planSetFileDirectDownloadFallback = {
        url,
        isPreview: true
      };
    } catch (e) {
      // If plan set generation failed - it was because of an API error (which is handled by `handleApiError`)
      return;
    }
    let permitPackageDoc: IDocumentResponse;
    try {
      const url = this.planSetFileDirectDownloadFallback.url;
      permitPackageDoc = await this.documentsService
        .getPermitPackageDoc(url, true)
        .catch(handleApiError('Failed to download plan set preview'));
    } catch (e) {
      // If plan set download failed - it was because of an API error (which is handled by `handleApiError`)
      return;
    }
    try {
      const url = URL.createObjectURL(permitPackageDoc.file);
      getUiStore().pages.pageViewModel = new PlanSetPreviewPageViewModel({
        domain: this.domain,
        editor: this.editor,
        modal: this.modal,
        fileURL: url,
        downloadURL: this.planSetDownloadUrls['purchase']
      });
    } catch (error) {
      // If saving failed - it was because of unknown reasons, and we need to handle it
      const errorMessage = 'Saving plan set preview document failed.';
      // eslint-disable-next-line no-console
      console.error(errorMessage, error);
      notify(errorMessage, ERROR);
      return;
    }
  }

  @action
  saveDesignFile = (file: File): Promise<void> => {
    if (!this.documentGenerationOptions) {
      return Promise.resolve();
    }
    this.isSaving = true;
    return new Promise((resolve, reject): void => {
      this.documentsService
        .uploadDesignFile(this.domain.design.id, file)
        .then((fileId: string): void => {
          const newDesignFile = {
            id: fileId,
            title: file.name.replace(/\.pdf$/, ''),
            type: EDesignFileType.CUSTOM_CONTENT
          };
          this.documentGenerationOptions!.includedFiles.designFiles.push(newDesignFile);
          resolve();
        })
        .catch(() => {
          handleApiError('Failed to upload design file');
          reject();
        })
        .finally((): void => {
          this.isSaving = false;
        });
    });
  };

  @action
  updateFileTitle = (id: string, newTitle: string): void => {
    if (!this.documentGenerationOptions) {
      return;
    }
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.map((file: IDesignFileData): IDesignFileData => {
        if (file.id === id) {
          return {
            ...file,
            title: newTitle
          };
        }

        return file;
      });
  };

  @action
  updateFileType = (id: string, newType: EDesignFileType): void => {
    if (!this.documentGenerationOptions) {
      return;
    }
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.map((file: IDesignFileData): IDesignFileData => {
        if (file.id === id) {
          return {
            ...file,
            type: newType as EDesignFileType
          };
        }

        return file;
      });
  };

  @action
  deleteFile = (designFileId: string): void => {
    if (!this.documentGenerationOptions) {
      return;
    }
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.filter(
        (designFile: IDesignFileData): boolean => designFile.id !== designFileId
      )!;
  };

  override dispose(): void {
    // do nothing
  }
}
