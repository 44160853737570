export const CARDINAL_ABBREV = ['N', 'E', 'S', 'W'];

export const ARROW_LEFT_KEY = 37;
export const ARROW_RIGHT_KEY = 39;

export const COMPASS_WIDTH = 140;
export const NEEDLE_RADIUS = '11.5px';
export const COMPASS_HEIGHT = COMPASS_WIDTH;
export const GRADIENT_COLOR_1 = '#f9f4e8';
export const GRADIENT_COLOR_2 = '#fad961';
