/**
 * Actions: [mouse down, mouse up, mouse move, mouse dbl click]
 * Each actions can look at the async state of other actions to see what's in progress
 * and either wait for them to finish or cancel themselves.
 *
 * [down]      -> cancel when [down, move, up, dbl click] are in progress
 *
 * [move]      -> cancel when [down, up, dbl click] are in progress
 *             -> wait when [move] is in progress
 *
 * [up]        -> cancel when [up, dbl click] are in progress
 *             -> wait when [down, move] is in progress
 *
 * [dbl click] -> cancel when [dbl click] is in progress
 *             -> wait when [down, move, up] are in progress
 */

export enum MouseActions {
  down = 'down',
  move = 'move',
  up = 'up',
  dblClick = 'dblClick'
}
export enum ProcessMouseActionResult {
  Cancel = 'Cancel',
  Execute = 'Execute'
}
type MouseActionInteractionsStateObject = {
  inProgress: boolean;
  promise: Promise<void>;
  processAction: () => Promise<ProcessMouseActionResult>;
};

export class MouseActionsInteractionsState {
  down: MouseActionInteractionsStateObject;
  move: MouseActionInteractionsStateObject;
  up: MouseActionInteractionsStateObject;
  dblClick: MouseActionInteractionsStateObject;
  constructor() {
    const mouseActionInteractionsStateObjectInstance = this;
    const log = this.log;
    this.down = {
      inProgress: false,
      promise: Promise.resolve(),
      async processAction(): Promise<ProcessMouseActionResult> {
        if (
          mouseActionInteractionsStateObjectInstance.down.inProgress
          || mouseActionInteractionsStateObjectInstance.move.inProgress
          || mouseActionInteractionsStateObjectInstance.up.inProgress
          || mouseActionInteractionsStateObjectInstance.dblClick.inProgress
        ) {
          log('cancel mouse down');
          return ProcessMouseActionResult.Cancel;
        }
        log('execute mouse down');
        return ProcessMouseActionResult.Execute;
      }
    };
    this.move = {
      inProgress: false,
      promise: Promise.resolve(),
      async processAction(): Promise<ProcessMouseActionResult> {
        if (
          mouseActionInteractionsStateObjectInstance.down.inProgress
          || mouseActionInteractionsStateObjectInstance.up.inProgress
          || mouseActionInteractionsStateObjectInstance.dblClick.inProgress
        ) {
          log('cancel mouse move');
          return ProcessMouseActionResult.Cancel;
        }
        if (mouseActionInteractionsStateObjectInstance.move.inProgress) {
          log('wait mouse move');
          await mouseActionInteractionsStateObjectInstance.move.promise;
        }
        log('execute mouse move');
        return ProcessMouseActionResult.Execute;
      }
    };
    this.up = {
      inProgress: false,
      promise: Promise.resolve(),
      async processAction(): Promise<ProcessMouseActionResult> {
        if (
          mouseActionInteractionsStateObjectInstance.up.inProgress
          || mouseActionInteractionsStateObjectInstance.dblClick.inProgress
        ) {
          log('cancel mouse up');
          return ProcessMouseActionResult.Cancel;
        }
        if (
          mouseActionInteractionsStateObjectInstance.down.inProgress
          || mouseActionInteractionsStateObjectInstance.move.inProgress
        ) {
          log('wait mouse up');
          await mouseActionInteractionsStateObjectInstance.down.promise;
          await mouseActionInteractionsStateObjectInstance.move.promise;
        }
        log('execute mouse up');
        return ProcessMouseActionResult.Execute;
      }
    };
    this.dblClick = {
      inProgress: false,
      promise: Promise.resolve(),
      async processAction(): Promise<ProcessMouseActionResult> {
        if (mouseActionInteractionsStateObjectInstance.dblClick.inProgress) {
          log('cancel dblClick');
          return ProcessMouseActionResult.Cancel;
        }
        if (
          mouseActionInteractionsStateObjectInstance.down.inProgress
          || mouseActionInteractionsStateObjectInstance.move.inProgress
          || mouseActionInteractionsStateObjectInstance.up.inProgress
        ) {
          log('wait dblClick');
          await mouseActionInteractionsStateObjectInstance.down.promise;
          await mouseActionInteractionsStateObjectInstance.move.promise;
          await mouseActionInteractionsStateObjectInstance.up.promise;
        }
        log('execute dblClick');
        return ProcessMouseActionResult.Execute;
      }
    };
  }
  log(...strings: (string | object | number)[]): void {
    // Uncomment to enable stringing tool mouse events logging
    // console.log(...strings);
  }
  start(action: MouseActions): () => void {
    this.log('! start action:', action);
    this[action].inProgress = true;
    let resolveHandlerInProgress: () => void = () => void 0;
    this[action].promise = new Promise((resolve): void => {
      resolveHandlerInProgress = (): void => {
        this.log('! end action:', action);
        this[action].inProgress = false;
        resolve();
      };
    });
    return resolveHandlerInProgress;
  }
}
