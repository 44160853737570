import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { useUiStore } from '../../../stores/useStore';
import { toolbarRegistry } from './registry';

const Toolbar: React.FC = (): ReactElement => {
  const { workspace } = useUiStore();
  const { currentWorkspace } = workspace;

  // Toolbar render dynamically depending on the current workspace
  const ToolbarUI = toolbarRegistry.get(currentWorkspace.toolbarRegistry);

  return ToolbarUI ? <ToolbarUI /> : <></>;
};

export default observer(Toolbar);
