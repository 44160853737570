import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import type { IBaseSiteEquipmentToolDependencies } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/BaseSiteEquipmentTool';
import { StreetLocationTool } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/StreetLocationTool';
import widthTool from '../../../Shared/withTool';

type ConstDep = Pick<
  IBaseSiteEquipmentToolDependencies,
  'properties' | 'domain' | 'drawableObjectsFactory' | 'serviceBus' | 'smartGuides' | 'currentWorkspace'
>;

export const StreetLocation = widthTool(
  StreetLocationTool,
  (store: Store): ConstDep => ({
    properties: store.properties,
    domain: store.domain,
    drawableObjectsFactory: new SiteStructureFactory(),
    serviceBus: store.serviceBus,
    smartGuides: store.smartGuides,
    currentWorkspace: store.workspace.currentWorkspace
  })
);
