import { LyraSiteStructurePanel } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactNode
} from 'react';
import React from 'react';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { RoofStory } from '../../../domain/models/SiteDesign/RoofStory';
import useStore, { useUiStore } from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyPanelContainer,
  PropertyPanelWrapper,
  ScrollablePanelContainer,
  ScrollablePanelContent
} from '../PropertyPanel/styles';
import type { RoofProtrusion } from '../../../domain/models/SiteDesign/RoofProtrusion';
import type { Building } from '../../../domain/models/SiteDesign/Building';

const { SiteStructurePanel: PanelComponents } = LyraSiteStructurePanel;

const SiteStructurePanel = observer((): ReactElement => {
  const { domain } = useStore();
  const { buildingProperties } = useUiStore();
  const { buildings } = domain;
  const viewModel = buildingProperties.buildingPropertiesViewModel;
  const parcel = domain.project.site.parcel;

  return (
    <PropertyPanelWrapper>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name={`BUILDINGS ${buildings.length}`} iconBg="#427ecd" />
      </PropertyPanelContainer>
      <ScrollablePanelContainer className="element_with_scroll">
        <ScrollablePanelContent width="100%" padding="0">
          {parcel.hasBoundary && (
            <PanelComponents.Parcel
              onVisibilityToggle={(visible: boolean): void => {
                parcel.visible = visible;
              }}
              onDeleteClick={(): void => viewModel.deleteParcelBoundary(parcel)}
            />
          )}
          {buildings.map(
            (building: Building): ReactElement => (
              <PanelComponents.Building.Building
                key={building.id}
                id={building.id}
                value={building.name}
                onNameChange={(name: string): void => viewModel.updateBuildingName(building, name)}
                onVisibilityToggle={(visible: boolean): void => viewModel.setBuildingVisibility(building, visible)}
                onDeleteClick={(): void => viewModel.deleteBuilding(building)}
                onEditClick={(): void => viewModel.openEditBuildingModal(building)}
              >
                {building.roofOutline && (
                  <PanelComponents.RoofOutline.RoofOutline
                    onVisibilityToggle={(visible: boolean): void => {
                      building.roofOutline && (building.roofOutline.mesh.visible = visible);
                    }}
                    onDeleteClick={(): void => building.roofOutline && viewModel.deleteOutline(building.roofOutline)}
                  />
                )}
                {building.stories
                  .slice() // to get rid of mobx warning
                  .sort((a: RoofStory, b: RoofStory): number => a.level - b.level)
                  .map(
                    (roofStory: RoofStory): ReactElement => (
                      <React.Fragment key={roofStory.id}>
                        <PanelComponents.RoofStory.RoofStory
                          key={roofStory.id}
                          id={roofStory.id}
                          level={roofStory.level}
                        />
                        {roofStory.roofFaces.map((roofFace: RoofFace, idxRF: number): ReactNode => {
                          const isLastRoofFace = idxRF === roofStory.roofFaces.length - 1;
                          const hasProtrusions = roofFace.protrusions.length > 0;

                          return (
                            <React.Fragment key={roofFace.serverId}>
                              <PanelComponents.RoofFace.RoofFace
                                hasBorder={!isLastRoofFace || hasProtrusions}
                                key={roofFace.serverId}
                                id={roofFace.serverId}
                                level={roofStory.level}
                                value={roofFace.name}
                                onNameChange={(name: string): void =>
                                  viewModel.updateRoofFaceName(roofFace, name, building)
                                }
                                onVisibilityToggle={(visible: boolean): void => {
                                  roofFace.mesh.visible = visible;
                                }}
                                onDeleteClick={(): void => viewModel.deleteRoofFace(roofFace)}
                                onEditClick={(): void => viewModel.openRoofFaceProperties(roofFace)}
                              />
                              {roofFace.protrusions.map((protrusion: RoofProtrusion, idxP: number): ReactElement => {
                                const isLastProtrusion = idxP === roofFace.protrusions.length - 1;

                                return (
                                  <PanelComponents.RoofProtrusion.RoofProtrusion
                                    key={protrusion.name}
                                    id={protrusion.name}
                                    hasBorder={!isLastRoofFace || !isLastProtrusion}
                                    level={roofStory.level}
                                    value={protrusion.name}
                                    onNameChange={(name: string): void =>
                                      viewModel.updateProtrusionName(roofFace, protrusion, name)
                                    }
                                    onVisibilityToggle={(visible: boolean): void => {
                                      protrusion.mesh.visible = visible;
                                    }}
                                    onDeleteClick={(): void => viewModel.deleteProtrusion(protrusion)}
                                  />
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    )
                  )}
              </PanelComponents.Building.Building>
            )
          )}
        </ScrollablePanelContent>
      </ScrollablePanelContainer>
    </PropertyPanelWrapper>
  );
});

export default SiteStructurePanel;
