import type { PropsTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import type { Color } from '../../../domain/typings';

export const FormDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 54px - 113px);
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 113px;
  width: 100%;
`;

const DefaultStylesMixin = css`
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: normal;
`;

export const LyraFormGeneratorContainer = styled.div<PropsTheme>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .lyra-form-generator {
    margin-inline: 15px;
    .generator-submit-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 0;
      button[type='submit'] {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 23px;
        border-radius: 22px;
        background-color: ${({ theme }: PropsTheme): Color => theme.colors.greenColor1};
        font-family: 'Mukta', sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        text-align: center;
        color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
        padding: 0 4%;
      }
    }
    div {
      fieldset {
        div.form-group {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor7};
          margin-bottom: 0;
          padding-inline: 0;
          position: relative;
          label,
          label.control-label,
          ul > li.text-danger {
            width: 100%;
            color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
            ${DefaultStylesMixin};
          }
          label {
            margin-right: 16px;
            width: auto;
            display: inline-block;
            margin-bottom: 4px;
          }
          ul > li.text-danger {
            color: ${({ theme }: PropsTheme): Color => theme.colors.yellowColor1};
          }
          input {
            ${DefaultStylesMixin};
            color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
            border-radius: 4px;
            border-top: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
            border-left: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
            border-right: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
            background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
            text-indent: 12px;
          }
          &.field-object {
            border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
            .field {
              margin-bottom: 15px;
              .inputTop {
                position: relative;
              }
            }
          }
          .group-head {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: start;
            cursor: pointer;
            padding-block: 16px;
            &.hidden-group ~ .hideable {
              display: none;
            }
            .group-description {
              white-space: pre-line;
              font-size: 13px;
              line-height: 1.3;
              margin-top: 6px;
              .description-entry {
                margin-top: 10px;
                color: ${({ theme }: PropsTheme): Color => theme.colors.grey2};
                span {
                  color: black;
                }
              }
            }
            .title-and-arrow {
              display: flex;
              flex-direction: row;
              width: 95%;
              justify-content: space-between;
              align-items: center;
              background: ${({ theme }: PropsTheme): Color => theme.colors.grey4};
              padding: 5px;
              border-radius: 4px;
              h4 {
                font-size: 14px;
              }
            }
          }
          &:not(.field-object):first-of-type {
            padding-top: 10px;
            margin-block: 0;
          }
          div > div {
            .select-content-wrapper {
              border-radius: 4px;
              .select-content-title {
                font-family: 'Roboto', sans-serif;
                font-size: 15px;
                font-weight: bold;
                color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
                text-align: left;
                width: 100%;
              }
              .select-content-value {
                font-family: 'Roboto', sans-serif;
                font-size: 11px;
                font-weight: normal;
                color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
                text-align: left;
                width: 100%;
              }
              .discontinued-section {
                ${DefaultStylesMixin};
                font-style: italic;
                opacity: 0.5;
                color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
                text-align: right;
                width: 100%;
              }
            }
          }
          &.hidden {
            display: none;
          }
        }
        .field-boolean {
          display: flex;
          flex-direction: row !important;
          align-items: center;
          justify-content: space-between !important;
          margin-top: 5px;
        }
        .field-string > input,
        .field-integer > input {
          width: 100%;
          height: 18px;
          padding: 6px 0;
        }
        .field-description {
          display: none;
        }
        & > .form-group.field-string,
        & > .form-group.field-integer,
        & > .form-group.field-boolean {
          margin-bottom: 15px;
        }
      }
    }
  }
`;
