import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import type { IBaseSiteEquipmentToolDependencies } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/BaseSiteEquipmentTool';
import { GasMeterTool } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/GasMeterTool';
import withTool from '../../../Shared/withTool';

type ConstDep = Pick<
  IBaseSiteEquipmentToolDependencies,
  'properties' | 'domain' | 'drawableObjectsFactory' | 'serviceBus' | 'smartGuides' | 'currentWorkspace'
>;

const GasMeter = withTool(GasMeterTool, (store: Store): ConstDep => {
  return {
    properties: store.properties,
    domain: store.domain,
    drawableObjectsFactory: new SiteStructureFactory(),
    serviceBus: store.serviceBus,
    smartGuides: store.smartGuides,
    currentWorkspace: store.workspace.currentWorkspace
  };
});

export default GasMeter;
