import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import { ListButtons } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import type {
  StringConnected,
  SystemSummary,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';

import StringingService from '../../../../services/stringing/stringingService';
import AcModulesInBranch from './AcModulesInBranch';
import {
  ContainerBranch, ListButtonsContainer, TitleBranch, TitleBranchContainer
} from './MicroinverterStyle';
import MicroinverterOrACModuleSystemPanel from './MicroinverterOrACModuleSystemPanel';
import {
  DownArrow, PanelHeader, PanelText, RightArrow, SummaryContentWrapper
} from './styles';

const AcModule = observer((): ReactElement => {
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;

  const [systemExpanded, setSystemExpanded] = useState(true);

  const summaryPanelContent = systemSummaryList.map((systemSummary: SystemSummary, index: number): ReactElement => {
    const stringsConnected = systemSummary.strings;

    const optionsListButtons: OptionProps[] = [];
    let selectedBranch = {
      index: 0,
      value: 'A'
    };

    stringsConnected.forEach((item: StringConnected, idx: number): void => {
      const value = String.fromCharCode('A'.charCodeAt(0) + idx);
      const stringWithIndex = {
        index: optionsListButtons.length,
        value
      };
      selectedBranch = selectedString === item.getId() ? stringWithIndex : selectedBranch;

      optionsListButtons.push({
        id: item.getId(),
        value
      });
    });

    const onDeleteActiveBranch = (): void => {
      if (StringingService.activeSelectedString) {
        systemSummaryViewModel.callDeleteStringCommand(
          `Branch ${selectedBranch.value}`,
          StringingService.activeSelectedString
        );
      } else if (StringingService.strings[+selectedBranch.index]) {
        systemSummaryViewModel.callDeleteStringCommand(
          `Branch ${selectedBranch.value}`,
          StringingService.strings[+selectedBranch.index]
        );
      }
    };

    return (
      <SummaryContentWrapper key={index}>
        <PanelHeader onClick={(): void => setSystemExpanded(!systemExpanded)}>
          <PanelText>System</PanelText>
          {systemExpanded ? <DownArrow name="arrow-collapse" /> : <RightArrow name="arrow-collapse" />}
        </PanelHeader>
        {systemExpanded && (
          <>
            <MicroinverterOrACModuleSystemPanel index={index} />
          </>
        )}
        <ContainerBranch>
          <TitleBranchContainer>
            <TitleBranch>Branch</TitleBranch>
          </TitleBranchContainer>
          {!!optionsListButtons.length && (
            <ListButtonsContainer>
              <ListButtons
                onClickEvent={(response: OptionProps): void => {
                  systemSummaryViewModel.setSelectedString(response.id);
                  StringingService.selectStringById(response.id);
                }}
                selected={selectedBranch.value}
                options={optionsListButtons}
              />
            </ListButtonsContainer>
          )}
        </ContainerBranch>
        <AcModulesInBranch index={index} onDeleteActiveBranch={onDeleteActiveBranch} />
      </SummaryContentWrapper>
    );
  });
  return <>{summaryPanelContent}</>;
});

export default AcModule;
