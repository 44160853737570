import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import union from 'lodash/union';
import useStore from '../../../../stores/useStore';
import type { MpptAndMultiInverterSelectionPanelViewModel } from '../../../../stores/UiStore/Panels/ViewModels/MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanelViewModel';
import type { ISeriesStringConfigurationData } from '../../../../domain/entities/StringingOption/IStringingOptionData';
import type { IInverterInformation } from '../../../../domain/models/SupplementalData/IInverterInfo';
import {
  BackHeader,
  Border,
  CircleSelect,
  Container,
  CloseButton,
  FirstSlide,
  HeaderContainer,
  InverterContainer,
  InverterData,
  InverterDescription,
  InverterTitle,
  MainInverterContainer,
  SecondSlide,
  SelectMpptChannelContainer,
  SlidesContainer,
  Title
} from './styles';

const MpptAndMultiInverterSelectionPanel = observer((): ReactElement => {
  const { panels } = useStore();
  const [mpptAndMultiInverterSelectionPanelViewModelInstance] = useState<MpptAndMultiInverterSelectionPanelViewModel>(
    panels.viewModel as MpptAndMultiInverterSelectionPanelViewModel
  );
  const [components, setComponents] = useState<ReactElement[]>([<React.Fragment key="empty" />]);
  const {
    inverters,
    showSlides,
    onCloseMPPTModal,
    inverterSelected,
    selectInverterWithMppts,
    selectInverterWithoutMppts,
    selectChannel,
    mpptHasStringConnected
  } = mpptAndMultiInverterSelectionPanelViewModelInstance;

  useEffect((): void => {
    validateDataToBuild();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inverters, showSlides, inverterSelected]);

  const validateDataToBuild = (): void => {
    if (inverters && inverters.length === 1 && inverters[0].mppts && inverters[0].mppts.length > 1) {
      setComponents(union([selectMpptChannelRender(inverters[0].mppts, inverters[0])]));
    }
    if (inverters && inverters.length > 1) {
      setComponents([firstSlide(), secondSlide()]);
    }
  };

  const firstSlide = (): ReactElement => {
    return (
      <FirstSlide show={showSlides} key={1}>
        <HeaderContainer>
          <Title>SELECT AN INVERTER FOR THIS STRING</Title>
        </HeaderContainer>
        <Border />
        <MainInverterContainer>{invertersComponent()}</MainInverterContainer>
      </FirstSlide>
    );
  };

  const invertersComponent = (): ReactElement[] => {
    return inverters.map((inverter: IInverterInformation): ReactElement => {
      const selectFunction =
        inverter.mppts === null || inverter.mppts === undefined || inverter.mppts.length === 1
          ? (): void => selectInverterWithoutMppts(inverter)
          : (): void => selectInverterWithMppts(inverter);

      return (
        <>
          <InverterContainer key={inverter.id} className={!showSlides && inverter.selected ? 'disabled' : ''}>
            <div className="left-side">
              {inverter.selected ? <LyraIcon.Icon name={'icon-tick'} /> : <CircleSelect onClick={selectFunction} />}
            </div>
            <div className="right-side">
              <InverterTitle>Inverter {inverter.id}</InverterTitle>
              <InverterDescription>{inverter.name}</InverterDescription>
              <InverterData>{returnText(inverter)}</InverterData>
            </div>
          </InverterContainer>
          <Border />
        </>
      );
    });
  };

  const returnText = (inverter?: IInverterInformation): string => {
    let text: string = '';
    if (inverter?.mppts) {
      if (inverter.mppts.length === 1) {
        text = `${inverter.inverterDefinition?.ratedPowerOutput}W string inverter with ${inverter.mppts.length} internal MPPT`;
      }
      if (inverter.mppts.length > 1) {
        text = `${inverter.inverterDefinition?.ratedPowerOutput}W string inverter with ${inverter.mppts.length} internal MPPTs`;
      }
    }
    if (inverter?.stringingOptions) {
      text = `${inverter.inverterDefinition?.ratedPowerOutput}W string inverter with DC optimizers`;
    }

    return text;
  };

  const selectMpptChannelRender = (
    channels: ISeriesStringConfigurationData[] | undefined | null,
    inverter: IInverterInformation
  ): ReactElement => {
    return (
      <SelectMpptChannelContainer>
        <div className="container">
          <span className="title">SELECT MPPT CHANNEL</span>
        </div>
        <div className="container">
          {channels?.map(
            (channel: ISeriesStringConfigurationData, index: number): ReactElement => (
              <button
                key={channel.mppt}
                onClick={(): void => selectChannel(channel, inverter)}
                disabled={mpptHasStringConnected(channel, inverter)}
              >
                {String.fromCharCode('A'.charCodeAt(0) + index)}
              </button>
            )
          )}
        </div>
      </SelectMpptChannelContainer>
    );
  };

  const secondSlide = (): ReactElement => {
    return (
      <SecondSlide show={!showSlides} key={2}>
        <BackHeader>
          <button onClick={(): void => selectInverterWithMppts()}>
            <LyraIcon.Icon name={'arrow'} />
          </button>
          <span>Back</span>
        </BackHeader>
        <Border />
        <InverterContainer>
          <div className="left-side">
            <LyraIcon.Icon name={'icon-tick'} />
          </div>
          <div className="right-side">
            <InverterTitle>Inverter {inverterSelected?.id}</InverterTitle>
            <InverterDescription>{inverterSelected?.name}</InverterDescription>
            <InverterData>{returnText(inverterSelected)}</InverterData>
          </div>
        </InverterContainer>
        <Border />
        {inverterSelected && selectMpptChannelRender(inverterSelected.mppts, inverterSelected)}
      </SecondSlide>
    );
  };

  return useObserver(
    (): ReactElement => (
      <Container>
        <CloseButton onClick={onCloseMPPTModal}>
          <LyraIcon.Icon name="close" />
        </CloseButton>
        <SlidesContainer>{components}</SlidesContainer>
      </Container>
    )
  );
});

export default MpptAndMultiInverterSelectionPanel;
