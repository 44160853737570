import React, { ReactElement } from 'react';
import { IPriceCorrections, ISummaryDataProps } from './Summary';
import {
  SummaryHeader,
  SummaryContent,
  SummaryFooter,
  BoldText,
  PriceText,
  ContentLeftView,
  ContentRightView,
  TitleText,
  FooterLeftView,
  FooterRightView,
  ThinText,
  CorrectionRow,
  AdjustmentView,
  ContentRow,
  CorrectionIcon,
  ThinPriceText,
  SummarySubFooter,
  SubFooterRow
} from './styles';
import { getPriceText } from '../../utils';

const Order = ({ data }: { data: ISummaryDataProps['order'] }): ReactElement => {
  const {
    type,
    amount,
    description,
    corrections,
    subtotal,
    credit,
    previousPayments,
    total
  } = data!;
  return (
    <>
      <SummaryHeader>
        Price Correction
      </SummaryHeader>
      <SummaryContent>
        <ContentRow>
          <ContentLeftView>
            <TitleText>
              Item
            </TitleText>
          </ContentLeftView>
          <ContentRightView>
            <TitleText>
              Price
            </TitleText>
          </ContentRightView>
        </ContentRow>
        <ContentRow>
          <ContentLeftView>
            <BoldText>
              {type}
            </BoldText>
          </ContentLeftView>
          <ContentRightView>
            <BoldText>
              {getPriceText(amount || 0)}
            </BoldText>
          </ContentRightView>
        </ContentRow>
        {description && (
          <ContentRow>
            <ContentLeftView>
              <ThinText>
                {description}
              </ThinText>
            </ContentLeftView>
          </ContentRow>
        )}
        <AdjustmentView>
          {corrections && corrections.map((correction: IPriceCorrections, index: number): ReactElement => (
            <CorrectionRow key={index}>
              <ContentLeftView>
                {correction.amount > 0
                  ? <CorrectionIcon name="list-item-check" />
                  : <CorrectionIcon name="list-item-x" />
                }
                <ThinText>
                  {correction.description}
                </ThinText>
              </ContentLeftView>
              <ContentRightView>
                <ThinPriceText>
                  {getPriceText(correction.amount || 0)}
                </ThinPriceText>
              </ContentRightView>
            </CorrectionRow>
          ))}
        </AdjustmentView>
      </SummaryContent>

      {Boolean(subtotal || credit || previousPayments) && (
        <SummarySubFooter>
          {subtotal && (
            <SubFooterRow>
              <FooterLeftView>
                <BoldText>
                  Subtotal
                </BoldText>
              </FooterLeftView>
              <FooterRightView>
                <BoldText>
                  {getPriceText(subtotal || 0)}
                </BoldText>
              </FooterRightView>
            </SubFooterRow>
          )}

          {credit && (
            <SubFooterRow>
              <FooterLeftView>
                <BoldText>
                  Credit
                </BoldText>
              </FooterLeftView>
              <FooterRightView>
                <BoldText>
                  {getPriceText(credit || 0)}
                </BoldText>
              </FooterRightView>
            </SubFooterRow>
          )}

          {previousPayments && (
            <SubFooterRow>
              <FooterLeftView>
                <BoldText>
                  Previous payments
                </BoldText>
              </FooterLeftView>
              <FooterRightView>
                <BoldText>
                  {getPriceText(previousPayments || 0)}
                </BoldText>
              </FooterRightView>
            </SubFooterRow>
          )}
        </SummarySubFooter>
      )}

      <SummaryFooter>
        <FooterLeftView>
          <BoldText>
            Total
          </BoldText>
        </FooterLeftView>
        <FooterRightView>
          <PriceText>
            {getPriceText(total || 0)}
          </PriceText>
        </FooterRightView>
      </SummaryFooter>
    </>
  );
};

export { Order };
