import type { Dictionary } from '../typings';

const ROOF_LEVEL_ONE = '#3eacf7';
const ROOF_LEVEL_TWO = '#cb5013';
const ROOF_LEVEL_THREE = '#c955ff';
const ROOF_LEVEL_FOUR = '#babe0b';
const ROOF_LEVEL_ONE_AND_HALF = '#10acb8';
const ROOF_LEVEL_TWO_AND_HALF = '#ae6600';
const ROOF_LEVEL_THREE_AND_HALF = '#ca00b0';
const ROOF_AREA = '#008b15';
const ROOF_AREA_SELECTED = '#4a4a4a';
const DEFAULT_ICON_COLOR = '#9e9e9e';

const ROOF_COLOR_LEVEL_RANGE: Dictionary<string> = {
  1: ROOF_LEVEL_ONE,
  1.5: ROOF_LEVEL_ONE_AND_HALF,
  2: ROOF_LEVEL_TWO,
  2.5: ROOF_LEVEL_TWO_AND_HALF,
  3: ROOF_LEVEL_THREE,
  3.5: ROOF_LEVEL_THREE_AND_HALF,
  4: ROOF_LEVEL_FOUR
};

export {
  ROOF_COLOR_LEVEL_RANGE, ROOF_AREA, ROOF_AREA_SELECTED, DEFAULT_ICON_COLOR
};
