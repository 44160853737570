import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import useStore from '../../../stores/useStore';
import { wizardRegistry } from './registry';

const WizardPersistant = observer((): ReactElement => {
  const { workspace } = useStore();

  // Modal to be rendered
  const ModalUI =
    workspace.currentWorkspace.wizard?.propCodeUI && wizardRegistry.get(workspace.currentWorkspace.wizard.propCodeUI);

  return <>{ModalUI && <ModalUI />}</>;
});

export default WizardPersistant;
