import React, { ReactElement, useState } from 'react';
import { Option } from '../../../typings/Option';
import { Grid, OptionProps } from '../../Grid/index';
import { Icon } from '../../Icon/index';
import { List } from '../../List/index';
import { Label, LabelProps } from '../Label/index';
import { Container, IconContainer, InputStyle, ListFlexWraper } from './styles';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';

export type ToggleSelectProps = LabelProps & {
  width?: string;
  disabled?: boolean;
  placeHolder: string;

  toggleOptions?: OptionProps[];
  selectedToggle?: OptionProps;
  onToggle?: (value: OptionProps) => void;

  listOptions?: Option[];
  selected?: Option;
  onSelect?: (value: Option) => void;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

const DEFAULT_PLACEHOLDER = '- Select an option -';

const ToggleSelect = ({
  width,
  className,
  disabled = false,
  placeHolder,
  name,
  color,
  selectedOptionColor,
  label,
  toggleOptions,
  selectedToggle,
  onToggle,
  listOptions,
  selected,
  onSelect
}: ToggleSelectProps): ReactElement => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [placeholder, setPlaceholder] = useState<string>(
    selectedToggle
      ? selected
        ? `${selectedToggle.name} ${selected.name}`
        : `${selectedToggle.name}`
      : placeHolder
      ? placeHolder
      : DEFAULT_PLACEHOLDER
  );
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    selectedToggle && selectedToggle
  );
  const [selectedOption, setSelectedOption] = useState(selected && selected);

  const onClickDropdown = (): void => {
    if (!disabled) setCollapsed(false);
  };

  const onSelectToggleOption = (option: OptionProps): void => {
    setPlaceholder(`${option.name}`);
    setSelectedToggleOption(option);
    if (onToggle) onToggle(option);
  };

  const onSelectOption = (value: string | number): void => {
    const option = listOptions?.find((opt: Option): boolean => opt.value === value);

    if (!!option) {
      setPlaceholder(
        `${selectedToggleOption ? selectedToggleOption.name : ''} ${option.name}`
      );
      setSelectedOption(option);
      if (onSelect) onSelect(option);
      setCollapsed(true);
    }
  };

  return (
    <Container width={width ? width : '100%'} className={className}>
      {label &&
        <Label className={className} color={color} name={name} label={label} />
      }

      {collapsed && (
        <InputStyle
          visible={collapsed}
          disabled={disabled}
          onClick={onClickDropdown}
          data-testid="open-select-toggle"
        >
          {placeholder}
          <IconContainer>
            <Icon name="arrow-down" />
          </IconContainer>
        </InputStyle>
      )}

      {collapsed || (
        <ListFlexWraper>
          {toggleOptions && (
            <Grid
              gutter={2}
              columns={2}
              options={toggleOptions}
              selected={selectedToggleOption}
              onSelect={onSelectToggleOption}
              selectedOptionColor={selectedOptionColor}
            />
          )}

          {listOptions && (
            <List
              options={listOptions}
              onSelect={onSelectOption}
              selected={selectedOption && selectedOption.value}
              selectedOptionColor={selectedOptionColor}
            />
          )}
        </ListFlexWraper>
      )}
    </Container>
  );
};

export { ToggleSelect };
