import { BaseProps } from 'react';
import { Dictionary } from '../typings';

type Component<T = BaseProps> =
  React.NamedExoticComponent<T> |
  React.FC<T>;

class Registry {
  private pool: Dictionary<Component>;

  constructor(initPool: Dictionary<Component> = {}) {
    this.pool = initPool;
  }

  get<PropType = {}>(key: string): Component<PropType> {
    const value = this.pool[key];

    return value as React.FC<PropType>;
  }

  add(key: string, value: Component): void {
    this.pool[key] = value;
  }

  allKeys(): string[] {
    return Object.keys(this.pool);
  }

}

export { Registry };
