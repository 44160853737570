import type {
  BaseProps, MouseEventHandler, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import IconMapper from '../Icons';
import {
  CollapseStyle, ToggleContent, ToggleHeader
} from './styles';

type Props = BaseProps & {
  open?: boolean;
  title?: string | ReactElement;
  iconToggleCollapse?: boolean;
  onToggle?: (newValue: boolean) => void;
  className?: string;
  color?: Color;
  icon?: boolean;
  type?: string;
};

type CollapseEventHandlerProp = {
  onClick: MouseEventHandler;
};

const IconWrapper = styled.div`
  margin-right: 10px;
`;

function Collapse(props: Props): ReactElement {
  const {
    className,
    color,
    open,
    title,
    onToggle,
    children,
    iconToggleCollapse = false,
    icon = true,
    type = 'collapse'
  } = props;
  const [collapse, setCollapse] = useState(open);
  const handleToggle = useCallback((): void => {
    const newValue = !collapse;

    onToggle?.(newValue);
    setCollapse(newValue);
  }, [collapse, onToggle]);

  useEffect((): void => {
    setCollapse(open);
  }, [open]);

  function addHandleToggleEvent(isIcon: boolean): CollapseEventHandlerProp | undefined {
    const eventHandler: CollapseEventHandlerProp = {
      onClick: handleToggle
    };
    return iconToggleCollapse === isIcon ? eventHandler : undefined;
  }

  return (
    <CollapseStyle className={className}>
      <ToggleHeader themeType={type} open={collapse} colorIcon={color} stretch {...addHandleToggleEvent(false)}>
        {icon && <IconMapper {...addHandleToggleEvent(true)} name="arrow-collapse" />}
        {title}
      </ToggleHeader>
      <ToggleContent collapse={collapse ? collapse : false}>{children}</ToggleContent>
    </CollapseStyle>
  );
}

export default Collapse;
