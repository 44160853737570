import type { Outline } from '../../../domain/models/SiteDesign/Outline';
import type { Deletable } from '../../../domain/mixins/Deletable';
import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteOutline extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    this.editor.removeObject((this.object as Deletable).mesh);
  }

  protected deleteFromDomain(): void {
    const outline = this.object as Outline;
    this.domain.deleteOutline(outline);
  }
}
