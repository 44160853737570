import type {
  OrthographicCamera, PerspectiveCamera, RaycasterParameters
} from 'three';
import {
  EventDispatcher, Raycaster, Vector3
} from 'three';
import type EditorStore from '../EditorStore';
import { DEFAULT_Z } from '../../../domain/models/Constants';
import type { IDisposable } from '../../../domain/typings';
import { precisionNumber } from '../../../utils/helpers';
import type { ViewPort } from '../ViewportController';
import type { IControls } from './Controls';

interface IRaycasterParams extends RaycasterParameters {
  Line2: {
    threshold: number;
  };
}
interface IEventTypes {
  PointerDown: {
    type: 'PointerDown';
    pointerStart: Vector3;
    button: number;
    originalEvent: MouseEvent | TouchEvent;
  };
  PointerUp: {
    type: 'PointerUp';
    pointerStart: Vector3;
    pointerEnd: Vector3;
    delta: Vector3;
    button: number;
    originalEvent: MouseEvent | TouchEvent;
  };
  PointerMove: {
    type: 'PointerMove';
    pointerPosition?: Vector3;
    pointer?: Vector3;
    originalEvent: MouseEvent | TouchEvent;
  };
  PointerLeft: {
    type: 'PointerLeft';
    pointerPosition: Vector3;
    originalEvent: MouseEvent;
  };
  PointerDblClick: {
    type: 'PointerDblClick';
    pointerPosition: Vector3;
    button: number;
    originalEvent: MouseEvent;
  };
}

export class BaseControl<T = {}> extends EventDispatcher<T & IEventTypes> implements IControls, IDisposable {
  protected static instance: BaseControl;
  static getInstance(
    editor?: EditorStore,
    viewport?: ViewPort,
    camera?: OrthographicCamera | PerspectiveCamera
  ): BaseControl {
    if (editor !== undefined && viewport !== undefined && camera !== undefined) {
      if (!BaseControl.instance) {
        BaseControl.instance = new BaseControl(editor, viewport, camera);
      }
    } else if (!BaseControl.instance) {
      throw new Error('Singleton instance cannot be created without parameters');
    }

    return BaseControl.instance;
  }

  protected editor: EditorStore;
  protected viewport: ViewPort;
  /**
   * Mouse down position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  protected mouseStart: Vector3 = new Vector3();
  /**
   * Mouse up position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  protected mouseEnd: Vector3 = new Vector3();
  /**
   * Delta vector in NDC, representing how far the mouse has moved
   *   since the user started to "drag"
   */
  protected mouseDelta: Vector3 = new Vector3();
  /**
   * Realtime mouse position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  protected mouse: Vector3 = new Vector3();
  protected enabled?: boolean;
  protected raycaster: Raycaster;
  protected camera: OrthographicCamera | PerspectiveCamera;

  /**
   * Creates an instance of BaseControl that enables mouse tracking.
   * @param editor Editor store to exec commands and get context data
   * @param viewport Viewport used to query UI data
   */
  protected constructor(editor: EditorStore, viewport: ViewPort, camera: OrthographicCamera | PerspectiveCamera) {
    super();
    this.editor = editor;
    this.viewport = viewport;
    this.raycaster = new Raycaster();
    const lineThreshold = {
      threshold: 25
    };

    this.raycaster.params.Line = lineThreshold;
    (this.raycaster.params as IRaycasterParams).Line2 = lineThreshold;

    this.camera = camera;

    this.activate();
  }
  /**
   * Mouse down position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  get mouseStartPosition(): Vector3 {
    return this.mouseStart;
  }

  /**
   * Mouse up position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  get mouseEndPosition(): Vector3 {
    return this.mouseEnd;
  }

  /**
   * Delta vector in NDC, representing how far the mouse has moved
   *   since the user started to "drag"
   */
  get mouseDeltaPosition(): Vector3 {
    return this.mouseDelta;
  }

  /**
   * Realtime mouse position in NDC, it is always going to be at
   *   the nearest plane of the frustum
   */
  get mousePosition(): Vector3 {
    return this.mouse;
  }

  update(): boolean {
    return false;
  }

  activate(): void {
    this.enabled = true;

    this.editor.canvasParent.addEventListener('mousedown', this.onMouseDown);
    this.editor.canvasParent.addEventListener('mouseup', this.onMouseUp);
    this.editor.canvasParent.addEventListener('mousemove', this.onMouseMove);
    this.editor.canvasParent.addEventListener('mouseleave', this.onMouseLeave);
    this.editor.canvasParent.addEventListener('touchmove', this.onTouchMove);
    this.editor.canvasParent.addEventListener('touchstart', this.onTouchStart);
    this.editor.canvasParent.addEventListener('touchend', this.onTouchEnd);
    this.editor.canvasParent.addEventListener('dblclick', this.onDblClick);
  }

  deactivate(): void {
    this.enabled = false;

    this.editor.canvasParent.removeEventListener('mousedown', this.onMouseDown);
    this.editor.canvasParent.removeEventListener('mouseup', this.onMouseUp);
    this.editor.canvasParent.removeEventListener('mousemove', this.onMouseMove);
    this.editor.canvasParent.removeEventListener('mouseleave', this.onMouseLeave);
    this.editor.canvasParent.removeEventListener('touchmove', this.onTouchMove);
    this.editor.canvasParent.removeEventListener('touchstart', this.onTouchStart);
    this.editor.canvasParent.removeEventListener('touchend', this.onTouchEnd);
    this.editor.canvasParent.removeEventListener('dblclick', this.onDblClick);
  }

  dispose(): void {
    this.deactivate();
  }

  unprojectMouseToFrustum(mouse: Vector3): Vector3 {
    const result = new Vector3().copy(mouse)
      .unproject(this.camera);
    result.setX(precisionNumber(result.x, 2));
    result.setY(precisionNumber(result.y, 2));
    result.setZ(DEFAULT_Z);
    return result;
  }

  protected setMouse(x: number, y: number): void {
    const rect = this.editor.canvasParent.getBoundingClientRect();

    this.mouse.setX(((x - rect.left) / rect.width) * 2 - 1);
    this.mouse.setY(-((y - rect.top) / rect.height) * 2 + 1);
    this.mouse.setZ(DEFAULT_Z);
  }

  unsafeDispatchEvent(event: IEventTypes[keyof IEventTypes]): void {
    // @ts-ignore
    this.dispatchEvent(event);
  }

  protected onMouseDown = (event: MouseEvent): void => {
    this.removeFocusFromElementsOutsideOfCanvas();
    event.preventDefault();
    this.mouseStart.copy(this.mouse);

    this.unsafeDispatchEvent({
      type: 'PointerDown',
      pointerStart: this.mouseStart,
      button: event.button,
      originalEvent: event
    });
  };

  /**
   * Focusing the canvas element helps remove focus from input element outside of canvas.
   * That is needed so that `KeyboardListener#isEventTargetAnInput` later does not incorrectly determine
   * that an input element (e.g. button) is focused, which then prevents keyboard events from working in canvas.
   */
  private removeFocusFromElementsOutsideOfCanvas = (): void => {
    if (document.activeElement !== this.editor.rendererDom) {
      this.editor.rendererDom.focus();
    }
  };

  protected onMouseUp = (event: MouseEvent): void => {
    event.preventDefault();

    this.editor.rendererDom.tabIndex = 0;
    this.mouseEnd.copy(this.mouse);
    this.mouseDelta.subVectors(this.mouseEnd, this.mouseStart);

    this.unsafeDispatchEvent({
      type: 'PointerUp',
      pointerStart: this.mouseStart,
      pointerEnd: this.mouseEnd,
      delta: this.mouseDelta,
      button: event.button,
      originalEvent: event
    });
  };

  protected onMouseMove = (event: MouseEvent): void => {
    event.preventDefault();
    this.setMouse(event.clientX, event.clientY);

    this.unsafeDispatchEvent({
      type: 'PointerMove',
      pointerPosition: this.mouse,
      originalEvent: event
    });
  };

  protected onMouseLeave = (event: MouseEvent): void => {
    event.preventDefault();
    this.mouse.copy(this.mouse);

    this.unsafeDispatchEvent({
      type: 'PointerLeft',
      pointerPosition: this.mouse,
      originalEvent: event
    });
  };

  protected onTouchStart = (event: TouchEvent): void => {
    event.preventDefault();
    this.mouseStart.copy(this.mouse);

    this.unsafeDispatchEvent({
      type: 'PointerDown',
      pointerStart: this.mouseStart,
      originalEvent: event,
      button: -1
    });
  };

  protected onTouchEnd = (event: TouchEvent): void => {
    event.preventDefault();
    this.mouseEnd.copy(this.mouse);

    this.mouseDelta.subVectors(this.mouseEnd, this.mouseStart);

    this.unsafeDispatchEvent({
      type: 'PointerUp',
      pointerStart: this.mouseStart,
      pointerEnd: this.mouseEnd,
      delta: this.mouseDelta,
      originalEvent: event,
      button: -1
    });
  };

  protected onTouchMove = (event: TouchEvent): void => {
    const e = event.changedTouches[0];
    this.setMouse(e.clientX, e.clientY);

    this.unsafeDispatchEvent({
      type: 'PointerMove',
      pointer: this.mouse,
      originalEvent: event
    });
  };

  protected onDblClick = (event: MouseEvent): void => {
    event.preventDefault();
    this.setMouse(event.clientX, event.clientY);

    this.unsafeDispatchEvent({
      type: 'PointerDblClick',
      pointerPosition: this.mouse,
      button: event.button,
      originalEvent: event
    });
  };
}
