import type { JSONSchema7Type } from 'json-schema';

class RegistryEntry {
  private value?: JSONSchema7Type;

  constructor(v: JSONSchema7Type) {
    this.value = v;
  }

  getValue = (): JSONSchema7Type | undefined => this.value;
  hasValue = (): boolean => this.value != undefined;
  updateValue = (v: JSONSchema7Type): void => {
    this.value = v;
  };
}

/**
 * MRU means 'Most Recently Used'
 */
class RecentValuesRegistry {
  private recentValueByTrigger = new Map<string, RegistryEntry>();
  private recentValueByField = new Map<string, RegistryEntry>();

  register = (
    field: string,
    triggerField: string,
    triggerValue: JSONSchema7Type,
    value: JSONSchema7Type
  ) => {
    const toAdd = new RegistryEntry(value);
    this.recentValueByTrigger.set(
      field + triggerField + triggerValue, toAdd
    );
    this.recentValueByField.set(
      field, toAdd
    );
  };

  setFieldByTrigger = (
    field: string,
    triggerField: string,
    triggerValue: JSONSchema7Type
  ) => {
    const entry = this.recentValueByTrigger.get(field + triggerField + triggerValue);
    if (entry) {
      this.recentValueByField.set(
        field, entry
      );
    }
    return entry;
  };

  mostRecentForField = (field: string) => this.recentValueByField.get(field);
}

export type Enclosure = {
  readonly recentValuesRegistry: RecentValuesRegistry;
}

export default RecentValuesRegistry;
