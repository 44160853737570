import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import useStore from '../../../../../stores/useStore';
import type { PermitPackageViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';

const DirectDownloadFallback = (): ReactElement => {
  const { modal } = useStore();
  const viewModel = modal.viewModel as PermitPackageViewModel;
  const {
    isPreview, url
  } = viewModel.directDownloadFallbackUrlParams;
  if (!url || viewModel.summaryComponentMode === 'progress') {
    return <></>;
  }
  return (
    <LyraTypography.Paragraph align="center" margin={0}>
      If permit package {isPreview && 'preview'} didn't download automatically,{' '}
      <a href={url} target="_blank">
        click here
      </a>
    </LyraTypography.Paragraph>
  );
};

export default observer(DirectDownloadFallback);
