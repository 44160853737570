export type Chain<T> = {
  head: Promise<T | void>;
  clear: () => void;
  queue: (func: () => Promise<T | void>) => Chain<T>;
};

export function createChainer<T = void>(): Chain<T> {
  let head = Promise.resolve();

  return {
    get head(): Promise<T | void> {
      return head;
    },
    clear(): void {
      head = Promise.resolve();
    },
    queue(func: () => Promise<T | void>): Chain<T> {
      head = head.finally((): Promise<T | void> => func());
      // Clear chain on error so that we don't get stuck on a failed promise
      head.catch((): void => {
        head = Promise.resolve();
      });

      return this;
    }
  };
}
