import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import useStore from '../../../../stores/useStore';
import {
  isDesignWorkspace, isProjectWorkspace
} from '../../../../stores/UiStore/WorkspaceStore/utils';
import type { ProjectWorkspace } from '../../../../stores/UiStore/WorkspaceStore/workspaces/ProjectWorkspace';
import TextButton from '../../../../ui/components/ButtonText';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';
import config, { UI_MODE } from '../../../../config/config';

export const SystemDesignButton = observer((): ReactElement => {
  const {
    editor, workspace, domain
  } = useStore();
  const [border, setBorder] = useState(false);

  const startSystemDesign = React.useCallback(async (): Promise<void> => {
    await editor.editorSetupPromise;
    if (config.featureFlag.uiMode !== UI_MODE.AURORA && isDesignWorkspace(workspace.currentWorkspace)) {
      workspace.currentWorkspace.startSystemDesign();
      setBorder(true);
    } else {
      setBorder(false);
    }

    editor.renderSiteMarkers(workspace.currentWorkspace);
  }, [editor, workspace.currentWorkspace]);

  useEffect((): void => {
    startSystemDesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace.currentWorkspace]);

  return (
    <TextButton
      border={border}
      label="System Design"
      colorTheme={EThemeComponentColor.BLUE}
      onClick={async (): Promise<void> => {
        if (isProjectWorkspace(workspace.currentWorkspace)) {
          const projectWorkspace: ProjectWorkspace = workspace.currentWorkspace;
          await projectWorkspace.saveManually();
          const canContinue = await projectWorkspace.canContinue();
          if (!canContinue) {
            projectWorkspace.showMissingRequiredPropertiesModal();
            return;
          }
          workspace.changeWorkspace(Workspace.Design);
          editor.renderSiteMarkers(workspace.currentWorkspace);
        }
      }}
    />
  );
});
