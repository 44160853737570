import React, { useEffect, BaseProps, ReactElement } from 'react';
import { Button } from '../Button';
import { Flexbox } from '../Layout';
import { EModalType } from './EModalType';
import { Divider, FooterStyle as Container } from './styles';

export type FooterProps = BaseProps & {
  color?: string | EModalType;
  showFooter?: boolean;
  showFooterDivider?: boolean;
  showLeftButton?: boolean;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
  onClickCloseButton?: () => void;
};

const Footer = (props: FooterProps): ReactElement => {
  const {
    color = EModalType.BLUE,
    showFooter = true,
    showFooterDivider = true,
    showLeftButton = true,
    leftButtonLabel = 'cancel',
    rightButtonLabel = 'save',
    leftButtonDisabled = false,
    rightButtonDisabled = false,
    onClickLeftButton,
    onClickRightButton,
    onClickCloseButton
  } = props;

  useEffect((): (() => void) => {
    const handleKeydown = (event: KeyboardEvent): void => {
      if (!leftButtonDisabled && event.key === 'Escape') {
        if (onClickCloseButton) {
          onClickCloseButton();
        } else {
          onClickLeftButton?.();
        }
      }
      const activeElement = document.activeElement as HTMLElement;
      if (
        // Don't close modal when we're focused on a form field.
        (!activeElement ||
          activeElement?.tagName === 'BODY' ||
          activeElement?.tagName === 'BUTTON')
        && !rightButtonDisabled && event.key === 'Enter'
      ) {
        onClickRightButton?.();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return (): void => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [leftButtonDisabled, rightButtonDisabled, onClickLeftButton, onClickRightButton]);

  return showFooter ? (
    <Container>
      {showFooterDivider && (
        <Divider direction="horizontal" length="100%" color="gray" />
      )}
      <Flexbox className="mt-xs" dir="row" justify="center" align="center">
          {showLeftButton && (
            <Button
              label={leftButtonLabel.toUpperCase()}
              disabled={leftButtonDisabled}
              onClick={onClickLeftButton}
              data-testid="modal-footer-left-button"
            />
          )}
        <Button
          label={rightButtonLabel.toUpperCase()}
          type={color === EModalType.BLUE ? 'main' : 'secondary'}
          disabled={rightButtonDisabled}
          onClick={onClickRightButton}
          data-testid="modal-footer-right-button"
        />
      </Flexbox>
    </Container>
  ) : (
    <></>
  );
};

export { Footer };
