/* eslint-disable import/no-unused-modules */
import LyraDesignTool, { IAppProps } from './App';
import {
  AnalyticsEvent, type IAnalytics
} from './services/analytics/IAnalytics';

const designToolVersion = process.env.LYRA_ENV_DESIGN_TOOL_VERSION!;

export {
  designToolVersion as lyraDesignToolVersion, LyraDesignTool, IAppProps, IAnalytics, AnalyticsEvent
};
