import styled from 'styled-components';

export const DocumentsContainer = styled.div`
  margin-bottom: 60px;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
`;
