import type { PropsTheme } from 'styled-components';
import type { BaseProps } from 'react';
import styled from 'styled-components';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import type { Color } from '../../../domain/typings';

type Props = BaseProps & {
  showHint?: boolean;
};

export const HintPanelWrapper = styled.div<Props>`
  background-color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.hintBg || ''};
  border-radius: 7px;
  box-sizing: border-box;
  display: ${({ showHint }: Props): string => (showHint ? 'block' : 'none')};
  min-height: 172px;
  margin: 15px;
  padding: 17px 14px 17px 14px;
  position: relative;
  width: auto;
`;

export const ContentStyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 33px 80%;
  margin-top: 20px;
`;

export const HintDescription = styled(LyraTypography.Paragraph).attrs({
  margin: '0 0 10px',
  fontSize: '12px',
  lineHeight: 1
})``;
