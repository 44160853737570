import { getRootStore as defaultGetRootStore } from '../../../stores/RootStoreInversion';
import type { DesignState } from '../../models/Design/DesignState';
import { DesignStep } from '../../models/Design/DesignState';
import { handleApiError } from '../../../utils/helpers';
import { SentryException } from '../../../utils/sentryLog';
import type { IDesignData } from '../../models/Design/Design';
import type { IUpdateDesignState } from '../../../stores/ServiceBus/Commands/UpdateDesignState';
import type { IProjectData } from '../../models/SiteDesign/Project';

/**
 * @description Import project and design into the document service,
 * depending on the `synchronizationRequired` flags.
 */
export async function importProjectAndDesignIntoDocumentService(getRootStore = defaultGetRootStore): Promise<void> {
  const { domain } = getRootStore();
  let designState: DesignState = domain.design.state;
  if (designState.isProjectSyncRequired()) {
    const project: IProjectData = domain.project.toData();
    try {
      await domain.documentsService.importProject(project).catch(handleApiError('Failed to import project'));
      designState = designState.withProjectSynchronized();
      updateDesignState(designState);
    } catch (e) {
      SentryException('Could not import Project into permit-ready service', e);
      throw e;
    }
  }
  if (designState.isDesignSyncRequired()) {
    const design: IDesignData = domain.design.toData();
    try {
      await domain.documentsService.importDesign(design).catch(handleApiError('Failed to import design'));
      designState = designState.withDesignSynchronized().withDataState(DesignStep.ELECTRICAL_BOS);
      updateDesignState(designState);
      updateEquipmentPlacedFlagInDesignStateTo(false);
    } catch (e) {
      SentryException('Could not import Design into permit-ready service', e);
      throw e;
    }
  }
}

export function updateDesignState(state: DesignState, getRootStore = defaultGetRootStore): void {
  const {
    domain, serviceBus
  } = getRootStore();
  serviceBus.send('update_design_state', state.toUpdateStateCommand(domain));
}

export function updateEquipmentPlacedFlagInDesignStateTo(
  newEquipmentPlacedValue: boolean,
  getRootStore = defaultGetRootStore
): void {
  const {
    domain, serviceBus
  } = getRootStore();
  const domainModel = domain.design;
  const dependencies: IUpdateDesignState = domainModel.state
    .withElectricalBosEquipmentPlaced(newEquipmentPlacedValue)
    .toUpdateStateCommand(domain);
  serviceBus.send('update_design_state', dependencies);
}
