import {
  action, computed
} from 'mobx';
import type { ILoadableContent } from './LoadableContent';

export class NotificationStore {
  loading: boolean = false;
  private loadingContent: ILoadableContent[] = [];

  @computed
  get currentLoading(): ILoadableContent[] {
    return this.loadingContent;
  }

  @action.bound
  setLoadingContent(content: ILoadableContent): void {
    this.loadingContent.push(content);
  }
}
