import type {
  Object3D, OrthographicCamera, PerspectiveCamera
} from 'three';
import { Vector2 } from 'three';
import { ViewPort } from '../../../stores/EditorStore/ViewportController';
import { ThreeUtils } from '../../../utils/ThreeUtils';

export class UnbalancedStringLabelController {
  private unbalancedStringLabelElementsAppended!: boolean;
  private unbalancedStringLabelElement!: HTMLElement;
  private unbalancedStringLabelArrowElement!: HTMLElement;
  private modulesDiscrepancyUntilBalanced!: number;
  private unbalancedStringLabelPosition!: Vector2;

  constructor() {
    Object.assign(this, {
      unbalancedStringLabelElementsAppended: false,
      unbalancedStringLabelElement: document.createElement('div'),
      unbalancedStringLabelArrowElement: document.createElement('div'),
      modulesDiscrepancyUntilBalanced: 0,
      unbalancedStringLabelPosition: new Vector2(0, 0)
    });

    this.setHtmlElementsStyle();
  }

  private get canvas(): HTMLElement {
    // Note: _editor is not available in unit tests
    return ViewPort._editor?.rendererDom;
  }

  private get camera(): OrthographicCamera | PerspectiveCamera {
    return ViewPort._editor.activeCamera!;
  }

  setModulesDiscrepancyUntilBalanced(modulesDiscrepancyUntilBalanced: number): void {
    this.modulesDiscrepancyUntilBalanced = modulesDiscrepancyUntilBalanced;
  }

  private setHtmlElementsStyle(): void {
    const { style: unbalancedLabelArrowStyle } = this.unbalancedStringLabelArrowElement;
    unbalancedLabelArrowStyle.position = 'absolute';
    unbalancedLabelArrowStyle.left = '0';
    unbalancedLabelArrowStyle.top = '10px';
    unbalancedLabelArrowStyle.height = '0px';
    unbalancedLabelArrowStyle.width = '0px';
    unbalancedLabelArrowStyle.borderLeft = '15px solid transparent';
    unbalancedLabelArrowStyle.borderRight = '15px solid transparent';
    unbalancedLabelArrowStyle.borderBottom = '15px solid #fff';

    const { style: unbalancedLabelStyle } = this.unbalancedStringLabelElement;
    unbalancedLabelStyle.position = 'absolute';
    unbalancedLabelStyle.left = '0';
    unbalancedLabelStyle.top = '20px';
    unbalancedLabelStyle.padding = '7px';
    unbalancedLabelStyle.borderRadius = '3px';
    unbalancedLabelStyle.padding = '7px';
    unbalancedLabelStyle.border = '.5px solid #F6F6F6';
    unbalancedLabelStyle.textAlign = 'center';
    unbalancedLabelStyle.fontSize = '12px';
    unbalancedLabelStyle.lineHeight = '1.5';
    unbalancedLabelStyle.background = '#fff';
    unbalancedLabelStyle.color = '#4a4a4a';
  }

  draw(stringingEnd: Object3D): void {
    if (!this.canvas) {
      // Canvas is not available in unit tests
      return;
    }

    this.unbalancedStringLabelPosition = new Vector2(stringingEnd.position.x, stringingEnd.position.y);

    if (!this.unbalancedStringLabelElementsAppended) {
      this.canvas.parentElement?.appendChild(this.unbalancedStringLabelElement);
      this.canvas.parentElement?.appendChild(this.unbalancedStringLabelArrowElement);
      this.unbalancedStringLabelElementsAppended = true;
    }

    this.unbalancedStringLabelElement.style.display = 'none';
    this.unbalancedStringLabelArrowElement.style.display = 'none';

    if (this.modulesDiscrepancyUntilBalanced === 0) {
      return;
    }

    const domCoords = ThreeUtils.worldCoordinatesToDomCoordinates(
      this.unbalancedStringLabelPosition.x,
      this.unbalancedStringLabelPosition.y,
      this.canvas,
      this.camera
    );

    this.unbalancedStringLabelElement.style.transform = `translateX(${domCoords.x}px) translateX(-50%) translateY(${
      domCoords.y + 20
    }px) translateY(-50%)`;
    this.unbalancedStringLabelArrowElement.style.transform = `translateX(${domCoords.x}px) translateX(-50%) translateY(${domCoords.y}px) translateY(-50%)`;
    this.unbalancedStringLabelArrowElement.style.display = this.unbalancedStringLabelElement.style.display = 'flex';
    this.unbalancedStringLabelElement.innerHTML =
      `${this.modulesDiscrepancyUntilBalanced > 0 ? '+' : ''}${this.modulesDiscrepancyUntilBalanced} `
      + 'for next<br/>valid configuration';
  }

  remove(): void {
    if (this.unbalancedStringLabelElementsAppended) {
      if (this.canvas?.parentElement?.contains(this.unbalancedStringLabelElement)) {
        this.canvas?.parentElement?.removeChild(this.unbalancedStringLabelElement);
      }
      if (this.canvas?.parentElement?.contains(this.unbalancedStringLabelArrowElement)) {
        this.canvas?.parentElement?.removeChild(this.unbalancedStringLabelArrowElement);
      }
      this.unbalancedStringLabelElementsAppended = false;
    }
  }
}
