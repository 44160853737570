import {
  action, computed, observable
} from 'mobx';
import { ERoofSlopeType } from '../../typings';
import { LayoutStrategy } from '../RoofTopArray/LayoutStrategy';
import type { SupplementalData } from '../SupplementalData/SupplementalData';
import { RackSpacing } from './IConfiguration';
import type { ISteepSlopeMountingSystemDefinitionData } from './MountingSystemDefinition';
import { MountingSystemDefinition } from './MountingSystemDefinition';
import type { ILowSlopeMountingSystemDefinitionData } from './LowSlopeMountingSystemDefinition';
import { LowSlopeMountingSystemDefinition } from './LowSlopeMountingSystemDefinition';

export interface IMountingSystemDefinitionsData {
  steepSlope?: ISteepSlopeMountingSystemDefinitionData;
  lowSlope?: ILowSlopeMountingSystemDefinitionData;
}

export class MountingSystemDefinitions {
  @observable
  steepSlope?: MountingSystemDefinition;
  @observable
  lowSlope?: LowSlopeMountingSystemDefinition;

  constructor(data: IMountingSystemDefinitionsData) {
    this.steepSlope = data.steepSlope ? new MountingSystemDefinition(data.steepSlope) : undefined;
    this.lowSlope = data.lowSlope ? new LowSlopeMountingSystemDefinition(data.lowSlope) : undefined;
  }

  toData(): IMountingSystemDefinitionsData {
    return {
      steepSlope: this.hasSteepSlopeDefinition ? this.steepSlope!.toData() : undefined,
      lowSlope: this.hasLowSlopeDefinition ? this.lowSlope!.toData() : undefined
    };
  }

  copy = (): MountingSystemDefinitions => {
    const copy = new MountingSystemDefinitions({});
    copy.steepSlope = this.steepSlope?.copy();
    copy.lowSlope = this.lowSlope?.copy();
    return copy;
  };

  @action
  enrichWith = (supplementalData: SupplementalData): void => {
    this.steepSlope?.setAttributes(supplementalData.steepSlopeMountingSystemInfo);
    this.lowSlope?.setAttributes(supplementalData.lowSlopeMountingSystemInfo);
  };

  @computed
  get isEmpty(): boolean {
    return !this.hasSteepSlopeDefinition && !this.hasLowSlopeDefinition;
  }

  @computed
  get hasSteepSlopeDefinition(): boolean {
    return !!this.steepSlope?.externalDefinitionId;
  }

  @computed
  get hasLowSlopeDefinition(): boolean {
    return !!this.lowSlope?.externalDefinitionId;
  }

  hasDefinition = (slopeType: ERoofSlopeType): boolean => {
    return (
      (slopeType === ERoofSlopeType.SteepSlope && this.hasSteepSlopeDefinition)
      || (slopeType === ERoofSlopeType.LowSlope && this.hasLowSlopeDefinition)
    );
  };

  @action
  setSteepSlopeDefinition = (definitionId: string): void => {
    this.steepSlope = new MountingSystemDefinition({
      externalDefinitionId: definitionId,
      defaultLayoutStrategy: (this.steepSlope?.defaultLayoutStrategy ?? LayoutStrategy.initialForSteepSlope()).toData()
    });
  };

  @action
  setLowSlopeDefinition = (definitionId: string, tiltAngle: number, tiersPerRack: number): void => {
    this.lowSlope = new LowSlopeMountingSystemDefinition({
      externalDefinitionId: definitionId,
      defaultLayoutStrategy: (this.lowSlope?.defaultLayoutStrategy ?? LayoutStrategy.initialForLowSlope()).toData(),
      defaultConfiguration: {
        azimuth: this.lowSlope?.defaultConfiguration.azimuth,
        tiltAngle,
        numberOfRowsInRack: tiersPerRack,
        rackSpacing: (this.lowSlope?.defaultConfiguration.rackSpacing ?? RackSpacing.initial()).toData()
      }
    });
  };
}
