import React, { BaseProps, ReactElement, SVGProps } from 'react';
import { Color } from '../../typings';
import { ERoofType } from '../RoofButton/ERoofType';
import { colorScheme } from '../Theme/colorScheme';

type Props = BaseProps & {
  level: number;
  fill?: Color;
  withBorder?: boolean;
  borderColor?: Color;
  groupRoofType?: ERoofType;
  opacity?: number;
};

function getSquarePoints(level: number): { gValue: string; iconHeight: number} {
  // default values
  const HEIGHT = 8;
  const HALF_HEIGHT = HEIGHT / 2;
  const STEP_MOVE = 10.5;
  const INITIAL_POS = 16;

  // just take numbers of units and decimal places with .5
  // if another number is added, it should be validate and normalize
  const decimal = level % 1;
  const hasHalfValue = decimal !== 0;
  // validating decimal points
  const levelValue =  hasHalfValue ? (Math.floor(level) + 0.5) : level;
  // next position of square level
  let increment = 0;
  // path result of svg points
  let svgPoints = '';
  let finalPos = 0;

  for (let itr = 0; itr < levelValue; itr++) {
    const isFirst = itr === 0;
    const half = isFirst && hasHalfValue;
    // y posiiton of tracing path
    const ypos = INITIAL_POS + increment;
    const height = half ? HALF_HEIGHT : HEIGHT;

    increment += half ? STEP_MOVE - HALF_HEIGHT : STEP_MOVE;
    svgPoints += `M7 ${ypos}h30v${height}H7z `;
    finalPos = ypos;
  }

  return {
    gValue: svgPoints,
    iconHeight: finalPos + (HEIGHT + 1)
  };
}

function getRoofIconProps(roofType: number): SVGProps<SVGPathElement> {
  const BASE_PROPS = {
    strokeWidth: '3.6',
    strokeDasharray: 0,
    d: 'M2 13L22 2l20 11'
  };

  if (roofType === ERoofType.FLAT) return { d: 'M2 13 H42' };
  if (roofType === ERoofType.SLOPED) return { ...BASE_PROPS };
  return {
    ...BASE_PROPS,
    strokeDasharray: '0.7999999523162842,3.199999809265137',
    strokeLinecap: 'round',
    strokeWidth: '2.4'
  };
}

const RoofLevel = ({
  level,
  fill = colorScheme.grey4 as Color,
  borderColor = colorScheme.grey2 as Color,
  withBorder = false,
  groupRoofType = ERoofType.SLOPED,
  opacity = 1
}: Props): ReactElement => {
  const { gValue, iconHeight } = getSquarePoints(level);
  const roofIconProps = getRoofIconProps(groupRoofType);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 44 ${iconHeight}`}>
      <g fill="none" fillRule="evenodd" opacity={opacity}>
          <path
            stroke={fill as string}
            {...roofIconProps}
          />
          <path
            fill={fill as string}
            fillRule="nonzero"
            stroke={borderColor as string}
            strokeWidth={withBorder ? 1 : 0}
            strokeLinejoin="round"
            d={gValue}
          />
      </g>
    </svg>
  );
};

export { RoofLevel };
