import { MathUtils as ThreeMath } from 'three';
import {
  action, observable
} from 'mobx';
import { ROOF_COLOR_LEVEL_RANGE } from '../../../../../domain/models/RoofColorLevelRange';
import { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import type {
  Color, Dictionary
} from '../../../../../domain/typings';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type { IUpdateRoofFaceLevelDependencies } from '../../../../ServiceBus/Commands/UpdateRoofFaceLevelCommand';
import type { RoofFacePropertiesStore } from '../RoofFacePropertiesStore';

export class LevelRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly DEFAULT_ICON_COLOR: Color = '#9e9e9e';

  @observable
  currentRoofFaceColor: Color = this.DEFAULT_ICON_COLOR;

  readonly DEFAULT_LEVEL_RANGE_COLOR: Dictionary<Color> = ROOF_COLOR_LEVEL_RANGE;

  @observable
  levelRange: Dictionary<Color> = this.DEFAULT_LEVEL_RANGE_COLOR;

  @observable
  description: string = '';

  @observable
  selected: number = 0;

  private roofFace!: RoofFace;
  private domain: DomainStore;
  private roofFaceProps: RoofFacePropertiesStore;
  private serviceBus: ServiceBus;

  constructor(domain: DomainStore, roofFaceProps: RoofFacePropertiesStore, serviceBus: ServiceBus) {
    this.domain = domain;
    this.roofFaceProps = roofFaceProps;
    this.setRoofFace(this.roofFaceProps.firstRoofFaceSelected!);
    this.serviceBus = serviceBus;
  }

  @action.bound
  setRoofFace(roofFace: RoofFace): void {
    this.roofFace = roofFace;
    this.description = `Level ${this.domain.getLevelOfRoofFace(this.roofFace)}`;
    this.selected = this.domain.getLevelOfRoofFace(this.roofFace);
    this.currentRoofFaceColor = ROOF_COLOR_LEVEL_RANGE[this.domain.getLevelOfRoofFace(this.roofFace)];
  }

  @action.bound
  changeLevel(level: number): void {
    const {
      roofFace, domain
    } = this;
    const dependencies: IUpdateRoofFaceLevelDependencies = {
      domain,
      roofFace,
      level
    };
    this.serviceBus.send('update_roof_face_level', dependencies);

    this.roofFace.setColor(ROOF_COLOR_LEVEL_RANGE[level]);
    this.currentRoofFaceColor = ROOF_COLOR_LEVEL_RANGE[level];
    this.description = `Level ${domain.getLevelOfRoofFace(roofFace)}`;
    this.selected = domain.getLevelOfRoofFace(roofFace);
    this.roofFaceProps.lastInteractedRoofFace = roofFace;
  }

  @action.bound
  wipeOut(): void {
    this.roofFace = new RoofFace();
    this.description = `Level ${this.domain.getLevelOfRoofFace(this.roofFace)}`;
    this.selected = this.domain.getLevelOfRoofFace(this.roofFace);
  }
}
