import type { Design } from '../../../domain/models/Design/Design';
import type PvModulePosition from '../../../domain/models/SiteDesign/PvModulePosition';
import { LayoutDesignStage } from '../../../domain/stages/DesignStages/LayoutDesignStage';
import type { IStage } from '../../../domain/stages/IStage';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { PvModuleCommand } from './PvModuleCommand';

export interface IUpdatePvModulesInstancesDependencies extends IBaseCommandDependencies {
  readonly currentStage: IStage;
  readonly pvModulePositions: PvModulePosition[];
  readonly domain: DomainStore;
}

export class UpdatePvModules extends PvModuleCommand {
  private readonly currentStage: IStage;
  private readonly pvModulePositions: PvModulePosition[];
  private readonly design: Design;

  constructor(dependencies: IUpdatePvModulesInstancesDependencies) {
    super();
    this.currentStage = dependencies.currentStage;
    this.pvModulePositions = dependencies.pvModulePositions;
    this.design = dependencies.domain.design;
  }

  async execute(): Promise<void> {
    this.pvModulePositions.forEach((pvModulePosition: PvModulePosition): void => {
      const mountingSystem = this.getMountingSystem(this.design, pvModulePosition);
      if (mountingSystem?.id && pvModulePosition.pvModule) {
        this.design.system.equipment.pvModules.addPvModule(
          pvModulePosition.pvModule.serverId,
          mountingSystem.id,
          pvModulePosition.serverId
        );
      }
      if (this.currentStage instanceof LayoutDesignStage) {
        this.currentStage.getEnergyProductionEstimate();
      }
    });
  }

  override async reExecute(): Promise<void> {
    this.pvModulePositions.forEach((pvModulePosition: PvModulePosition): void => {
      const mountingSystem = this.getMountingSystem(this.design, pvModulePosition);
      if (mountingSystem?.id && pvModulePosition.pvModule) {
        const alreadyExists = this.design.system.equipment.pvModules.hasPvModuleInPosition(pvModulePosition.serverId);
        if (!alreadyExists) {
          this.design.system.equipment.pvModules.addPvModule(
            pvModulePosition.pvModule.serverId,
            mountingSystem.id,
            pvModulePosition.serverId
          );
        }
      }
      if (this.currentStage instanceof LayoutDesignStage) {
        this.currentStage.getEnergyProductionEstimate();
      }
    });
  }
}
