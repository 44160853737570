import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import {
  LyraButtons, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import { EButtonSize } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonSize';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import type { Color } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import { rootStore } from '../../../stores/Store';
import IconMapper from '../../../ui/components/Icons';
import {
  CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID,
  TRACE_TO_DEFINE_SCALING_TOOL_ID
} from '../../../stores/UiStore/ToolbarStore/Project/constants';
import type { CustomBaseImageryTransformationTool } from '../../../stores/UiStore/ToolbarStore/Project/CustomBaseImageryTransformationTool';
import { isProjectWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import type { TraceToDefineScalingTool } from '../../../stores/UiStore/ToolbarStore/Project/TraceToDefineScalingTool';
import type Limit from '../../../domain/models/Limit';
import { isWithin } from '../../../domain/models/Limit';
import type { SimpleVector2 } from '../../../utils/ThreeUtils';
import config, { UI_MODE } from '../../../config/config';
const { NumberField } = LyraFormElementsDesignTool.FormElementsDesignTool.NumberField;

const TRACE_TO_DEFINE_SCALING_LIMIT: Limit = {
  lower: 0.000001,
  upper: Number.MAX_SAFE_INTEGER
};

type Props = BaseProps & {
  enable?: boolean;
  onClick?: (event: MouseEvent) => void;
};

const SetLengthLabel = styled.div`
  margin-left: 4px;
  font-size: 14px;
  white-space: nowrap;
`;

const NumberFieldWrapper = styled.div`
  margin-bottom: 4px;
`;

const FloatingOpacityContainer = styled.div`
  position: fixed;
  top: max(10%, 100px);
  left: calc(50% - 65px);
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  color: rgb(66, 126, 205);
  font-size: 18px;
  background: white;
  border-radius: 10px;
  gap: 10px;
`;

const FloatingTraceToDefineScalingContainer = styled.div<{ floatingElementCoordinates: SimpleVector2 }>`
  position: absolute;
  top: ${({ floatingElementCoordinates }: { floatingElementCoordinates: SimpleVector2 }): string =>
    `${floatingElementCoordinates?.y}px`};
  left: ${({ floatingElementCoordinates }: { floatingElementCoordinates: SimpleVector2 }): string =>
    `${floatingElementCoordinates?.x}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background: white;
  border-radius: 10px;
  gap: 10px;
`;

const OpacityButton = styled.div`
  border: 2px solid rgb(66, 126, 205);
  border-radius: 22px;
  font-weight: bold;
  width: 22px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  user-select: none;
`;

const OpacityTitle = styled.div``;

const FloatWrapperStyled = styled.button<PropsTheme<Props>>`
  background-color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.whiteBg || ''};
  border: ${({
    enable, theme
  }: PropsTheme<Props>): string =>
    enable ? `solid 3px ${theme.colors.main1}` : 'solid 3px #ffff'};
  color: ${({
    enable, theme
  }: PropsTheme<Props>): string | Color => (enable ? theme.colors.main1 : 'inherit')};
  font-weight: ${({ enable }: Props): string => (enable ? 'bold' : 'normal')};
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  height: 100px;
  width: 84px;
  position: absolute;
  right: 28px;
  bottom: 37px;
  z-index: 1;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    border: ${({ theme }: PropsTheme<Props>): string => `solid 3px ${theme.colors.main1}`};
    color: ${({ theme }: PropsTheme<Props>): Color => theme.colors.mainColor1};
    font-weight: bold;
  }
`;

const CaptionIconStyled = styled.p`
  text-transform: uppercase;
  margin-top: 7px;
  font-size: 13px;
`;

const FloatingElements = observer((props: Props): ReactElement => {
  const {
    editor, toolbar
  } = useStore();

  const [customBaseImageryControl, setCustomBaseImageryControl] = useState<ReactElement>(<></>);

  const showFloatingElementForTraceToDefineScalingTool = (toolbar.selectedTool as TraceToDefineScalingTool)
    ?.showFloatingElement;
  const traceToDefineScalingLengthValue = (toolbar.selectedTool as TraceToDefineScalingTool)?.userSpecifiedDistanceInFt;
  const floatingElementCoordinates = (toolbar.selectedTool as TraceToDefineScalingTool)?.floatingElementCoordinates;

  useEffect(() => {
    if (toolbar.selectedTool?.id === TRACE_TO_DEFINE_SCALING_TOOL_ID) {
      const tool = toolbar.selectedTool as TraceToDefineScalingTool;
      setCustomBaseImageryControl(
        <React.Fragment>
          {tool.showFloatingElement && (
            <FloatingTraceToDefineScalingContainer floatingElementCoordinates={floatingElementCoordinates}>
              <SetLengthLabel>Set length</SetLengthLabel>
              <NumberFieldWrapper>
                <NumberField
                  withQuantityArrows
                  allowOutOfRange
                  className="mr-xxs"
                  onChange={tool.setLength}
                  measure="ft"
                  width="30px"
                  limits={TRACE_TO_DEFINE_SCALING_LIMIT}
                  fractionDigits={2}
                  step={1}
                />
              </NumberFieldWrapper>
              <LyraButtons.Buttons
                onClick={(): void => {
                  tool.applyScale();
                }}
                styleType={ButtonStyleType.PRIMARY}
                size={EButtonSize.SMALL}
                disabled={!isWithin(TRACE_TO_DEFINE_SCALING_LIMIT, tool.userSpecifiedDistanceInFt)}
              >
                <b>Save</b>
              </LyraButtons.Buttons>
              <LyraButtons.Buttons
                styleType={ButtonStyleType.SECONDARY}
                onClick={(): void => {
                  tool.cancel();
                }}
                size={EButtonSize.SMALL}
              >
                <b>Cancel</b>
              </LyraButtons.Buttons>
            </FloatingTraceToDefineScalingContainer>
          )}
        </React.Fragment>
      );
    } else if (toolbar.selectedTool?.id === CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID) {
      const tool = toolbar.selectedTool as CustomBaseImageryTransformationTool;
      setCustomBaseImageryControl(
        <FloatingOpacityContainer>
          <OpacityButton onClick={(): void => tool.changeOpacity(-0.1)}>-</OpacityButton>
          <OpacityTitle>IMAGE OPACITY</OpacityTitle>
          <OpacityButton onClick={(): void => tool.changeOpacity(+0.1)}>+</OpacityButton>
        </FloatingOpacityContainer>
      );
    } else {
      setCustomBaseImageryControl(<></>);
    }
  }, [
    toolbar.selectedTool,
    toolbar.selectedTool?.id,
    traceToDefineScalingLengthValue,
    showFloatingElementForTraceToDefineScalingTool,
    floatingElementCoordinates
  ]);

  const toggleStreetViewModal = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      editor.clickModalSV(!editor.streetModal);
    },
    [editor]
  );

  return (
    <>
      {isProjectWorkspace(rootStore.workspace.currentWorkspace)
      && config.featureFlag.uiMode !== UI_MODE.AURORA
      && (
        <div>
          <FloatWrapperStyled onClick={toggleStreetViewModal} enable={editor.streetModal}>
            <IconMapper name="street-view" />
            <CaptionIconStyled>Other Views</CaptionIconStyled>
          </FloatWrapperStyled>
          {customBaseImageryControl}
        </div>
      )}
    </>
  );
});

export default FloatingElements;
