import { observer } from 'mobx-react-lite';
import {
  type ReactElement, type ReactNode, useState
} from 'react';
import React from 'react';
import styled from 'styled-components';
import { canvasConfig } from '../../../config/canvasConfig';
import {
  ServiceEntranceEquipmentFormHeaderMarkerIcon,
  ServiceEntranceEquipmentFormHeaderToggleIcon
} from './ServiceEntranceEquipmentFormHeaderMarkerIcon';

const Container = styled.div`
  margin-bottom: 16px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  gap: 15px;
  background-color: ${canvasConfig.mainServicePanelIconColor};
  border-radius: 4px;
  color: white;
  margin-bottom: 16px;
  justify-content: space-between;
`;
const InnerContainer = styled.div``;
const HeaderIcon = styled.div`
  padding-bottom: 4px;
`;
const HeaderTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  flex-grow: 1;
`;
const HeaderCollapseToggle = styled.div<{ rotation: number }>`
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;

  height: 16px;
  width: 16px;
  transform: rotate(${({ rotation }: { rotation: number }): string => `${rotation}`}deg);
  transition: all 0.3s ease 0s;
`;

export const ServiceEntranceEquipmentFormContainer = observer(
  ({
    title, label, children
  }: { title: string; label: string; children: ReactNode }): ReactElement => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    return (
      <Container>
        <Header>
          <HeaderIcon>
            <ServiceEntranceEquipmentFormHeaderMarkerIcon label={label} />
          </HeaderIcon>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderCollapseToggle onClick={(): void => setIsCollapsed(!isCollapsed)} rotation={isCollapsed ? 180 : 0}>
            <ServiceEntranceEquipmentFormHeaderToggleIcon />
          </HeaderCollapseToggle>
        </Header>
        {!isCollapsed && <InnerContainer>{children}</InnerContainer>}
      </Container>
    );
  }
);
