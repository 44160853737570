import {
  LyraDivider, LyraFormElementsDesignTool, LyraIcon, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactNode
} from 'react';
import React, { useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { IModulesOption } from '../../../../domain/models/SiteDesign/IModulesOption';
import type { NewSystemDesignStage } from '../../../../domain/stages/CreationDesignStages/NewSystemDesignStage';
import { useUiStore } from '../../../../stores/useStore';
import { OptionStyle } from '../../../../ui/components/List/styles';
import ModuleCard from '../../../../ui/components/ModuleCard';
import {
  getFormattedText, getTitle
} from '../../../../utils/parsing';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';

function NewSystemDesignModal(): ReactElement {
  const { workspace } = useUiStore();
  const { wizard } = workspace.currentWorkspace;

  const viewModel = wizard?.currentStage as NewSystemDesignStage;

  const onPvModuleDefinitionSearchQueryChange = useCallback(
    (query: string): void => {
      viewModel.onPvModuleDefinitionSearchQueryChange(query);
    },
    [viewModel]
  );

  const onPvModuleDefinitionSearchResultSelected = useCallback(
    (title: string, pvModuleDefinitionId: string): void => {
      viewModel.onPvModuleDefinitionSearchResultSelected(pvModuleDefinitionId, title);
    },
    [viewModel]
  );

  const mapPillsToReactElement = (pills: string[]): ReactElement[][] =>
    pills.map((pill: string): ReactElement[] => ReactHtmlParser(getFormattedText(pill)));

  const moduleResult = (): ReactNode => {
    if (viewModel.selectedPvModuleDefinitionId) {
      return <></>;
    }
    if (viewModel.loading) {
      return <OptionStyle>Loading...</OptionStyle>;
    }
    if (viewModel.pvModuleDefinitionSearchQuery && viewModel.pvModuleDefinitionSearchResults.length === 0) {
      return <OptionStyle>No module found</OptionStyle>;
    }
    return viewModel.pvModuleDefinitionSearchResults.map(
      (item: IModulesOption, index: number): ReactNode => (
        <ModuleCard
          key={index}
          pvModuleDefinitionId={item.id}
          titleAsString={getTitle(item.title)}
          titleAsHtml={ReactHtmlParser(getFormattedText(item.title))}
          pills={mapPillsToReactElement(Object.values(item.tags))}
          description={item.dateCreated}
          onSelected={onPvModuleDefinitionSearchResultSelected}
        />
      )
    );
  };

  const arrowIcon = (): ReactNode => (
    <span onClick={(): void => onPvModuleDefinitionSearchQueryChange(viewModel.pvModuleDefinitionSearchQuery)}>
      <LyraIcon.Icon
        name="arrow-down"
        style={{
          width: 12,
          height: 8
        }}
      />
    </span>
  );

  const cancelModal = (): void => {
    wizard?.clear();
    if (viewModel.isDesignCreation) {
      workspace.changeWorkspace(Workspace.Project);
    }
  };

  return (
    <LyraModal.Modal
      height="570px"
      width="500px"
      $show={true}
      title={viewModel.isDesignCreation ? 'Roof Top System' : ''}
      currentStep={wizard?.current}
      steps={viewModel.isDesignCreation ? wizard?.steps : undefined}
      leftButtonLabel={viewModel.isDesignCreation ? 'Back' : 'Cancel'}
      rightButtonLabel={viewModel.isDesignCreation ? 'Next' : 'Save'}
      rightButtonDisabled={!wizard?.currentStage?.canContinue}
      onClickLeftButton={(): void => {
        if (viewModel.isDesignCreation) {
          wizard?.previous();
        } else {
          wizard?.clear();
        }
      }}
      onClickRightButton={(): void => {
        wizard?.next();
      }}
      onClickCloseButton={(): void => cancelModal()}
    >
      <LyraTypography.TextLayout type="h2" margin={'10px 0 0'} lineHeight={1.5} align="center">
        {viewModel.isDesignCreation ? 'Select Module' : 'Update Module'}
      </LyraTypography.TextLayout>
      <LyraDivider.Divider className="mt-xs mb-xs" color={EThemeComponentColor.GRAY_200} />
      <LyraTypography.TextLayout fontSize="12px" margin="0 0 5px">
        MODULE
      </LyraTypography.TextLayout>
      <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
        placeholder="— Type a Solar Panel or Manufacturer —"
        onChange={onPvModuleDefinitionSearchQueryChange}
        value={viewModel.pvModuleDefinitionSearchQuery}
        result={viewModel.pvModuleDefinitionSearchResults.length}
        inlineIcon={arrowIcon()}
      />
      {moduleResult()}
    </LyraModal.Modal>
  );
}

export default observer(NewSystemDesignModal);
