import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { SelectableOpacity } from '../typings';
import { SceneObjectType } from '../models/Constants';
import { getParentLyraModelBySceneObjectType } from '../sceneObjectsWithLyraModelsHelpers';
import type { Outline } from '../models/SiteDesign/Outline';
import type { Parcel } from '../models/SiteDesign/Parcel';
import type { RoofFace } from '../models/SiteDesign/RoofFace';
import type { Drawable } from './Drawable';

export const Selectable = <T extends AnyConstructor<Drawable>>(SelectableBase: T) => {
  abstract class MixinClass extends SelectableBase {
    selected: boolean = false;
    isSelectable: boolean = true;
    selectableOpacity?: SelectableOpacity;
    abstract selectWithParent: boolean;
    abstract propertyId: string;

    renderOrder: number = 0;

    setSelectedMode(selected: boolean): void {
      if (selected) {
        this.select();
      } else {
        this.unselect();
      }
      this.redraw();
    }

    select(): void {
      if (!this.selected) {
        this.selected = true;
        this.redraw();
        this.renderOrder = -1;
      }
    }

    unselect(): void {
      if (this.selected) {
        this.selected = false;
        this.redraw();
        this.renderOrder = 0;
      }
    }

    get parentRoofFace(): RoofFace | undefined {
      return getParentLyraModelBySceneObjectType<RoofFace>(this, SceneObjectType.RoofFace);
    }

    get parentOutline(): Outline | undefined {
      return getParentLyraModelBySceneObjectType<Outline>(this, SceneObjectType.Outline);
    }

    get parentParcel(): Parcel | undefined {
      return getParentLyraModelBySceneObjectType<Parcel>(this, SceneObjectType.ParcelBoundary);
    }
  }

  return MixinClass;
};

export type Selectable = Mixin<typeof Selectable>;
