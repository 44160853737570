import type {
  PropsTheme
} from 'styled-components';
import styled from 'styled-components';
import type { Color } from '@aurorasolar/lyra-ui-kit';
import Flexbox from '../Layout/Flexbox';

export const EditContent = styled(Flexbox).attrs({
  dir: 'row',
  justify: 'center',
  align: 'center'
})`
  cursor: pointer;
  height: 100%;
`;

export const EditVeil = styled.div<PropsTheme>`
  background-color: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.1s ease-in-out;
  user-select: none;
  width: 100%;
  border-radius: 5px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.lineHr};
  box-sizing: border-box;
`

export const Card = styled.div<PropsTheme>`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.gray6};
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.lineHr};
  border-radius: 5px;
  overflow: hidden;
  padding: 2px 8px;
  min-width: 57px;
  height: 100%;
  box-sizing: border-box;

  & > ${EditVeil} {
    opacity: 0;
  }

  &:hover > ${EditVeil} {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;
