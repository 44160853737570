import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import useStore, { useUiStore } from './stores/useStore';
import StringingService from './services/stringing/stringingService';

export const AttributesForE2ETest = observer((): ReactElement => {
  const { domain } = useStore();
  const {
    workspace, toolbar
  } = useUiStore();

  return (
    <div data-testid="playwright-app-state-html-attribute-flags" style={{ display: 'none' }}>
      <span data-testid="playwright-app-state-html-attribute-current-tool-id">{toolbar.initialisedToolId}</span>
      <span data-testid="playwright-app-state-html-attribute-current-workspace">{workspace.currentWorkspace.id}</span>
      <span data-testid="playwright-app-state-html-attribute-current-design-stage-index">
        {workspace.currentWorkspace.stageManager?.currentIndex ?? 'none'}
      </span>
      <span data-testid="playwright-app-state-html-attribute-pathways-loading">
        {domain.guards.pathwaysLoading ? 'loading' : ''}
      </span>
      <span data-testid="playwright-app-state-html-attribute-stringing-loading">
        {StringingService.isAnyAsyncActionInProgress ? 'loading' : ''}
      </span>
    </div>
  );
});
