import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Design } from '../../../domain/models/Design/Design';
import { BaseCommand } from './Command';

export interface IUpdateSupplementalPvModuleDefinitionDataDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
}

export class UpdateSupplementalPvModuleDefinitionDataCommand extends BaseCommand {
  private readonly currentDesign: Design;

  constructor(dependencies: IUpdateSupplementalPvModuleDefinitionDataDependencies) {
    super();
    this.currentDesign = dependencies.domain.design;
  }

  async execute(): Promise<void> {
    const pvModuleDefinitionId = this.currentDesign.system.equipment.pvModules.definition;
    this.currentDesign.supplementalData.updatePvModuleInfo(pvModuleDefinitionId);
  }
}
