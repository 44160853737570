import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import type { RoofFace } from './RoofFace';

export class RoofStory {
  id: string;
  level!: number;
  /**
   * Note: in the API data model roof faces are grouped into roof sections.
   * Here frontend stores roof faces from all roof sections in a single array.
   */
  @observable
  roofFaces: IObservableArray<RoofFace> = observable([]);

  constructor() {
    this.id = ThreeMath.generateUUID();
  }
}
