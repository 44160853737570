import React, {
  BaseProps,
  ReactElement,
  useCallback
} from 'react';
import { Color } from '../../typings';
import { RoofLevel } from '../RoofLevel';
import { ERoofType } from './ERoofType';
import {
  HouseShapeWrapper,
  RoofButtonStyle
} from './styles';
import { colorScheme } from '../Theme/colorScheme';

export type Props = BaseProps & {
  onClick?: (level: number, event: MouseEvent) => void;
  level: number;
  selected?: boolean;
  withCaption?: boolean;
  width?: string;
  iconColor?: Color;
  groupRoofType?: ERoofType;
};

const RoofButton = (
  {
    onClick,
    level,
    selected,
    withCaption = true,
    width = '44px',
    groupRoofType = ERoofType.SLOPED,
    iconColor,
    className
  }: Props): ReactElement => {
  const decimal = level % 1;
  const levelValue = decimal === 0 ? level : Math.floor(level) + 0.5;

  const handleClick = useCallback((
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (typeof onClick === 'function') {
      onClick(levelValue, event.nativeEvent);
    }
  }, [onClick, levelValue]);

  const currentIconColor = selected ? iconColor : colorScheme.grayForIcons;

  return (
    <RoofButtonStyle
      className={className}
      width={width}
    >
      <HouseShapeWrapper
        onClick={handleClick}
        data-testid={`roof-button-${level}`}
        selected={selected}
      >
        <RoofLevel
          level={level}
          fill={currentIconColor}
          groupRoofType={groupRoofType}
          withBorder
        />
      </HouseShapeWrapper>
     {withCaption && <p>Level {levelValue}</p>}
    </RoofButtonStyle>
  );
};

export { RoofButton };
