import styled, {
  css,
  PropsTheme,
  SimpleInterpolation
} from 'styled-components';
import { Color } from '../../../typings/index';
import { Icon } from '../../../components/Icon';

type ContainerProps = {
  width?: string;
};

type TextFieldProps = PropsTheme & {
  height?: string;
  fontSize?: string;
  hasError?: boolean;
};

type TextFieldInputProps = {
  width: string;
  fontSize?: string;
};

const InputStyles = css`
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grayColor4};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;

export const Container = styled.div<ContainerProps>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation =>
    css`width: ${width};`}
  box-sizing: border-box;
`;

export const TextFieldStyle = styled.div<TextFieldProps>`
  border-radius: 4px;
  border: solid 1px ${({ theme, hasError }: TextFieldProps): Color => hasError ? theme.colors.redColor1 : theme.colors.grayColor4};
  background-color: ${({ theme, hasError }: TextFieldProps): Color => hasError ? theme.colors.redColor2 : theme.colors.whiteBg};
  display: flex;
  align-items: center;
  height: ${({ height }: TextFieldProps): string | undefined => height};
  & > span {
    font-style: italic;
    color: ${({ theme }: PropsTheme): Color =>
      theme.colors.secondaryFontOpacity};
    font-size: ${({ fontSize }: TextFieldProps): string | undefined => fontSize};
    display: flex;
    align-items: center;
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  margin-left: 11px;
  margin-right: 8px;
`;

const InputField = styled.input<TextFieldInputProps>`
  font-size: ${({ fontSize }: TextFieldInputProps): string | undefined => fontSize};
  min-height: 22px;
  background-color: transparent;
  border:none;
  ${({ width }: TextFieldInputProps): string => width !== ''
  ? `width: ${width};`
  : ``}
  flex: 1 1 auto;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
  &:read-only {
    background-color: inherit;
    border: none;
  }
`;

export {
  InputField
};
