import styled from 'styled-components';

export const Card = styled.div<{ opacity: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 8px;
  opacity: 0.5;
  ${(attr: { opacity: string }): string => `opacity: ${attr.opacity === 'false' ? '0.5' : '1'};`}
`;

export const TitleWrapper = styled.div<{ fontSize: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props: { fontSize: string }): string => `font-size: ${props.fontSize};`};
`;

export const ObliqueText = styled.p`
  line-height: 1;
  margin: 10px 0 0 0;
  font-style: oblique;
  font-weight: initial;
`;

export const NumberFieldWrapper = styled.div`
  width: 230px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
`;
