import {
  action, computed, observable
} from 'mobx';
import { handleApiError } from '../../../../../utils/helpers';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { IBaseViewModelDependencies } from '../../BaseViewModel';
import { BaseViewModel } from '../../BaseViewModel';
import { SiteImage } from '../../../../../domain/models/SiteDesign/SiteImage';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import { DocumentsService } from '../../../../../infrastructure/services/api/DocumentsService';
import type { IUpdateEquipmentCommand } from '../../../../ServiceBus/Commands/UpdateEquipmentCommand';
import {
  type IUpdateRoofFacePropertyDependencies,
  UpdateRoofFacePropertyKey
} from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';

export interface IViewUploadImageDependencies extends IBaseViewModelDependencies {
  readonly serviceBus: ServiceBus;
  readonly editor: EditorStore;
  readonly workspace: WorkspaceStore;
  readonly currentRoofFace?: RoofFace;
}

type TViewUploadImageType = 'roof' | 'msp';

class ViewUploadImage extends BaseViewModel {
  readonly propCodeUI: string = 'upload_image_modal';

  @observable
  file?: File = undefined;

  @observable
  imageTitle = '';

  @observable
  imageDescription = '';

  @observable
  isSaving = false;

  private readonly type: TViewUploadImageType;
  private readonly label: string;
  private readonly serviceBus: ServiceBus;
  private readonly workspace: WorkspaceStore;
  private readonly currentRoofFace?: RoofFace;

  constructor({
    dependencies,
    type,
    label
  }: {
    dependencies: IViewUploadImageDependencies;
    type: TViewUploadImageType;
    label: string;
  }) {
    super(dependencies);
    this.type = type;
    this.label = label;
    this.serviceBus = dependencies.serviceBus;
    this.workspace = dependencies.workspace;
    this.currentRoofFace = dependencies.currentRoofFace;

    if (type === 'roof') {
      const imagesCount = this.currentRoofFace!.images.length || 0;
      this.imageTitle = `${this.currentRoofFace!.name} (${imagesCount + 1})`;
    } else if (type === 'msp') {
      const imagesCount = this.domain.project.site.equipment.mainServicePanel?.images.length!;
      this.imageTitle = `${this.label} (${imagesCount + 1})`;
    }
  }

  @computed
  get getFileName(): string {
    return this.file?.name ?? 'Drag & Drop file';
  }

  @action
  setFile = (tempFile?: File): void => {
    this.file = !!tempFile ? tempFile : undefined;
  };

  @action
  setTitle = (title: string): void => {
    this.imageTitle = title;
  };

  @action
  setDescription = (description: string): void => {
    this.imageDescription = description;
  };

  @computed
  get canSave(): boolean {
    return !!this.file && !!this.imageTitle;
  }

  @action
  save = (): void => {
    this.isSaving = true;

    const accountId = this.domain.project.account;
    const projectId = this.domain.project.id;
    const documentsService = new DocumentsService();

    documentsService
      .uploadSiteImage(accountId, projectId, this.file!)
      .then(async (imageId: string): Promise<void> => {
        const newSiteImage = new SiteImage({
          id: imageId,
          title: this.imageTitle,
          description: this.imageDescription
        });
        if (this.type === 'roof') {
          await this.saveRoofImage(newSiteImage);
        } else if (this.type === 'msp') {
          await this.saveMspImage(newSiteImage);
        }
        this.closeModal();
      })
      .catch(handleApiError('Failed to upload image'))
      .finally((): void => {
        this.isSaving = false;
      });
  };

  @action
  saveRoofImage = async (image: SiteImage): Promise<void> => {
    this.currentRoofFace!.addImage(image);
    const dependencies: IUpdateRoofFacePropertyDependencies = {
      domain: this.domain,
      roofFace: this.currentRoofFace!,
      key: UpdateRoofFacePropertyKey.Images,
      value: this.currentRoofFace!.images
    };
    this.serviceBus.send('update_roof_face_property', dependencies);
    this.workspace.projectWorkspace
      ?.saveManually()
      .catch(handleApiError('Failed to save the project after adding roof face image'));
  };

  @action
  saveMspImage = async (image: SiteImage): Promise<void> => {
    const { mainServicePanel } = this.domain.siteEquipment;
    mainServicePanel!.addImage(image);
    const dependencies: IUpdateEquipmentCommand = {
      editor: this.editor,
      domain: this.domain,
      equipment: mainServicePanel!
    };
    this.serviceBus.send('update_equipment', dependencies);
    this.workspace.projectWorkspace
      ?.saveManually()
      .catch(handleApiError(`Failed to save project after adding ${this.label} image`));
  };

  override dispose(): void {
    // do nothing
  }
}

export default ViewUploadImage;
