import React, { ReactElement } from 'react';
import {
  InfoContainer,
  InfoHeading,
  InfoIcon,
  InfoText
} from './styles';

export interface IInfoProps {
  icon: string;
  heading: string | ReactElement[];
  message?: string | ReactElement | ReactElement[];
}

function Info(props: IInfoProps): ReactElement {
  const { icon, heading, message } = props;

  return (
    <InfoContainer dir="row">
      <InfoIcon name={icon} />
      <InfoHeading>
        {heading}
      </InfoHeading>
      {message && (
        <InfoText>
          {message}
        </InfoText>
      )}
    </InfoContainer>
  );
}

export { Info };
