import styled, { css, PropsTheme, SimpleInterpolation } from 'styled-components';
import { Color } from '../../typings';

type StepProps = PropsTheme & {
  circularShape?: boolean;
  auroraMode?: boolean;
};

export const PipelineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }: PropsTheme): string => theme.auroraMode ? `
  padding: 8px 16px;
  ` : `
  padding: 0 16px;
  `}
  position: relative;
  ${({ theme }: PropsTheme): string => theme.auroraMode ? `
  border-top: none;
  border-bottom: none;
  ` : `
  border-left: 1px solid ${theme.colors.grayColor6};
  border-right: 1px solid ${theme.colors.grayColor6};
  `}
  
  width: auto
`;

export const ProgressBar = styled.ul`
  counter-reset: step;
  display: flex;
  align-items: center;
`;

export const StepContainer = styled.li<StepProps>`
  display: ${({auroraMode}: StepProps): string => auroraMode ? 'flex' : 'list-item'};
  align-items: center;
  list-style-type: none;
  position: relative;
  text-align: center;
  width: ${({auroraMode}: StepProps): string => auroraMode ? 'auto' : '100px'};
  &:before {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
    border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
    border-radius: ${({ circularShape }: StepProps): string => circularShape ? '50%' : '10%'};
    color: ${({ theme }: PropsTheme): Color => theme.auroraMode ? theme.colors.gray1 : theme.colors.grayColor9};
    content: counter(step);
    counter-increment: step;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    font-size: 14px;
    line-height: 22px;
    margin: 2px auto ${({auroraMode}: StepProps): number => auroraMode ? 0 : 5}px;
    text-align: center;
    width: 25px;
    margin-right: ${({auroraMode}: StepProps): string => auroraMode ? '6px' : 'auto'};
  }
  &:after {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
    content: '';
    display: ${({auroraMode}: StepProps): string => auroraMode ? 'none' : 'block'};
    position: absolute;
    top: 15px;
    left: -50%;
    height: 2px;
    width: 100%;
    z-index: -2;
  }
  &:first-child:after {
    content: none;
  }
  &.active {
    color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
    font-weight: bold;
  }
  &.ready {
    color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
  }
  &.active:before {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
    border-color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
    color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
  &.ready:before {
    border-color: ${({ theme }: PropsTheme): Color => theme.auroraMode ? theme.colors.grayColor6 : theme.colors.grayColor9};
    content: ${({auroraMode}: StepProps): string => auroraMode ? '"\u2714"' : ''};
  }
  &.ready + &:after {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor9};
    height: 4px;
  }
`;

export const Title = styled.p<StepProps>`
  color: ${({ theme }: PropsTheme): Color => theme.auroraMode ? theme.colors.gray1 : theme.colors.grayColor9};
  white-space: ${({auroraMode}: StepProps): string => auroraMode ? 'nowrap' : 'normal'};
  font-family: ${(props: PropsTheme): string => props.theme.fonts.sans};
  font-size: ${({auroraMode}: StepProps): number => auroraMode ? 14 : 10}px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0.5px;
  &.active {
    font-weight: ${({auroraMode}: StepProps): number => auroraMode ? 500 : 800};
    color: ${({theme}: PropsTheme): Color => theme.colors.black}
  }
`;

export const AuroraStepDivider = styled.div`
  width: 16px;
  margin-inline: 8px;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  height: 0;
`;
