import {
  LyraDivider, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import type { SiteImage } from '../../../domain/models/SiteDesign/SiteImage';
import Thumbnail from '../Thumbnail/Thumbnail';
import {
  Container, FormRow, FormWrap, ThumbnailWrap, DeleteIcon, DeleteButtonWrapper
} from './styles';

interface ISiteImagesPreviewProps {
  readonly images: SiteImage[];
  readonly projectId: string;
  readonly accountId: string;
  readonly onDeleteClick?: (image: SiteImage) => void;
}

const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;
const TextArea = LyraFormElementsDesignTool.FormElementsDesignTool.TextArea.TextArea;

function ImagesPreview(props: ISiteImagesPreviewProps): ReactElement {
  const {
    images, projectId, accountId, onDeleteClick
  } = props;

  return (
    <>
      {images.map(
        (image: SiteImage, index: number): ReactElement => (
          <Container key={image.id}>
            {index > 0 && (
              <LyraDivider.Divider className="mt-xs" color={EThemeComponentColor.GRAY_200} direction="horizontal" />
            )}

            <ThumbnailWrap>
              <Thumbnail width="210px" height="205px" image={image.thumbnailUrl(accountId, projectId)} />
            </ThumbnailWrap>

            <FormWrap>
              <FormRow>
                <TextField label="title" name="nameImage" value={image.title} onChange={image.setTitle} />
                <DeleteButtonWrapper onClick={(): void => onDeleteClick?.(image)}>
                  <DeleteIcon name="icon-delete" />
                </DeleteButtonWrapper>
              </FormRow>

              <FormRow>
                <TextArea
                  name="description"
                  rows={8}
                  value={image.description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void =>
                    image.setDescription(event.target.value)
                  }
                />
              </FormRow>
            </FormWrap>
          </Container>
        )
      )}
    </>
  );
}

export default observer(ImagesPreview);
