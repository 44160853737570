import isNil from 'lodash/isNil';
import type {
  IInverterDefinitionData,
  IInverterOption,
  IInverterSearchRequest,
  IDcOptimizerDefinition,
  IOptimizerOption,
  IOptimizerRequest
} from '../../../domain/models/Inverter';
import type {
  BaseAttributes, IOption
} from '../../../domain/models/SiteDesign/IOption';
import type { IPvModuleEquipmentDefinition } from '../../../domain/models/SupplementalData/IPvModuleEquipmentDefinition';
import type { IModuleMountingSystemProductSeriesRequest } from '../../../domain/request/ModuleMountingSystemProductSeriesRequest/IModuleMountingSystemProductSeriesRequest';
import type { MountingSystemAttributes } from '../../../domain/typings';
import Http from '../Http';
import config from '../../../config/config';
import type { IModulesOption } from '../../../domain/models/SiteDesign/IModulesOption';
import type { IEssOptionsGroupData } from '../../../domain/entities/PvSystem/EnergyStorageAndBackupOptions';

/**
 * Note: only the fields used on the frontend are defined here
 */
interface IMountingSystemEquipmentDefinitionData {
  id: string;
  name: string;
  manufacturer: {
    name: string;
  };
  designConstraints: {
    rowSpacing: number;
    columnSpacing: number;
    minimumTiltAngle?: number;
    maximumTiltAngle?: number;
    minimumNumberOfTiersPerRack?: number;
    maximumNumberOfTiersPerRack?: number;
    minimumSlope: number;
    maximumSlope: number;
  };
  availability: {
    productionPeriod: {
      end?: string;
    };
  };
  lyraMetadata: {
    systemConfiguration: boolean;
    representationInPermitPackage: boolean;
  };
}

export class EquipmentService {
  get baseURL(): string {
    return config.api.equipment;
  }

  async getPanelManufacturerOptions(): Promise<IOption<BaseAttributes>[]> {
    const response = await Http.getWithCache<IOption<BaseAttributes>[]>(
      this.baseURL.concat('/selection-options/manufacturers'),
      {
        product: 'ELECTRICAL_PANEL',
        includeDissolved: true
      }
    );
    return response.data;
  }

  async getPvModuleDefinitionOptions(query: string): Promise<IModulesOption[]> {
    const response = await Http.get<IModulesOption[]>(this.baseURL.concat('/modules/index'), {
      query
    });
    return response.data;
  }

  async getMountingSystemDefinitionOptions(
    parameters: IModuleMountingSystemProductSeriesRequest
  ): Promise<IOption<MountingSystemAttributes>[]> {
    const response = await Http.get<IOption<MountingSystemAttributes>[]>(
      this.baseURL.concat('/mounting-systems/index'),
      {
        ...parameters
      }
    );
    return response.data;
  }

  async getInverterOptions(parameters: IInverterSearchRequest): Promise<IInverterOption[]> {
    return (
      await Http.get<IInverterOption[]>(this.baseURL.concat('/inverters/index'), {
        ...parameters
      })
    ).data;
  }

  async getInverterDefinition(definitionId: string): Promise<IInverterDefinitionData> {
    return (await Http.get<IInverterDefinitionData>(this.baseURL.concat(`/inverters/${definitionId}`), {})).data;
  }

  async getDcOptimizers(parameters: IOptimizerRequest): Promise<IOptimizerOption[]> {
    const response = await Http.get<IOptimizerOption[]>(this.baseURL.concat('/optimizers'), {
      ...parameters
    });
    return response.data;
  }

  async getDcOptimizerDefinition(definitionId: string): Promise<IDcOptimizerDefinition> {
    const response = await Http.get<IDcOptimizerDefinition>(this.baseURL.concat(`/optimizers/${definitionId}`), {});
    return response.data;
  }

  async getPvModuleDefinition(id: string): Promise<IPvModuleEquipmentDefinition> {
    // TODO: Create entity and model for pvModule response data
    const response = await Http.get<IPvModuleEquipmentDefinition>(this.baseURL.concat(`/modules/${id}`), {});
    return response.data;
  }

  async getMountingSystemAttributes(id: string): Promise<MountingSystemAttributes> {
    const response = await Http.get<IMountingSystemEquipmentDefinitionData>(
      this.baseURL.concat(`/mounting-systems/${id}`),
      {}
    );
    const definition = response.data;
    const mountingSystemAttributes = {
      value: definition.id,
      name: definition.name,
      manufacturer: definition.manufacturer.name,
      rowSpacing: definition.designConstraints.rowSpacing.toString(),
      columnSpacing: definition.designConstraints.columnSpacing.toString(),
      minimumTiltAngle: definition.designConstraints.minimumTiltAngle?.toString(),
      maximumTiltAngle: definition.designConstraints.maximumTiltAngle?.toString(),
      minimumNumberOfTiersPerRack: definition.designConstraints.minimumNumberOfTiersPerRack?.toString(),
      maximumNumberOfTiersPerRack: definition.designConstraints.maximumNumberOfTiersPerRack?.toString(),
      inProduction: isNil(definition.availability.productionPeriod.end).toString(),
      fullySupportedInLyra: (
        definition.lyraMetadata.systemConfiguration && definition.lyraMetadata.representationInPermitPackage
      ).toString(),
      minimumSlope: definition.designConstraints.minimumSlope.toString(),
      maximumSlope: definition.designConstraints.maximumSlope.toString()
    };
    return Promise.resolve(mountingSystemAttributes);
  }

  async getEnergyStorageAndBackupOptions(
    inverterDefinitions: readonly string[],
    pvModuleCount: number,
    siteElevationInMeters?: number
  ): Promise<readonly IEssOptionsGroupData[]> {
    const elevationParameter = siteElevationInMeters ? `&elevation=${siteElevationInMeters}` : '';
    const response = await Http.get<readonly IEssOptionsGroupData[]>(
      this.baseURL.concat(
        '/selection-options/energy-storage-systems'
          + `?inverterDefinitions=${inverterDefinitions.join(',')}&pvModuleCount=${pvModuleCount}${elevationParameter}`
      ),
      {}
    );
    return response.data;
  }
}
