import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Selectable } from './Selectable';

export const Deletable = <T extends AnyConstructor<Selectable>>(DeletableBase: T) => {
  abstract class MixinClass extends DeletableBase {
    isDeletable = true;
    deleteConfirmationWarning = '';
  }

  return MixinClass;
};

export type Deletable = Mixin<typeof Deletable>;
