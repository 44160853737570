enum ERoofFaceEdgeType {
  Eave = 'EAVE',
  Ridge = 'RIDGE',
  Rake = 'RAKE',
  Hip = 'HIP',
  Valley = 'VALLEY',
  StepFlashing = 'STEP_FLASHING'
}

export default ERoofFaceEdgeType;
