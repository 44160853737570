import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';
import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

const { H4 } = LyraFontFamily.FontFamily;

export const InverterCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px;
  margin-top: 6px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  border-radius: 4px;
  border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  &:hover {
    cursor: pointer;
    border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.primary};
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px 0;
  }
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
  justify-content: space-between;
  & .not-compatible {
    border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.error};
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor5}40;
    white-space: nowrap;
  }
  .compatible {
    border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.success};
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor3}40;
    white-space: nowrap;
  }
`;

export const Title = styled(H4)`
  font-size: 12px !important;
  font-weight: bold !important;
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
`;

export const ItemStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 2px 8px;
  margin-right: 3px;
  margin-top: -4px;
  border-radius: 15px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.07;
  margin-left: auto;
`;

export const PillsWrapper = styled.div`
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
`;

export const Description = styled.div`
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  padding-top: 6px;
  font-size: 11px;
`;

export const PillItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 15px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.gray3};
  font-size: 11px;
  font-weight: normal;
  line-height: 1.07;
`;
