import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import { ViewPort } from '../../stores/EditorStore/ViewportController';
import ProjectionUtil from '../../utils/projectionUtil';
import { ThreeUtils } from '../../utils/ThreeUtils';
import type { Drawable } from './Drawable';

// eslint-disable-next-line import/no-unused-modules
export interface RenderSegmentLengthLabelOptions {
  showSegmentLength: boolean;
  labelX: number;
  labelY: number;
  length: number;
}

export const WithLengthLabels = <T extends AnyConstructor<Drawable>>(WithLengthLabelsBase: T) => {
  abstract class MixinClass extends WithLengthLabelsBase {
    segmentLengthElement: HTMLElement = document.createElement('div');

    renderSegmentLengthLabel({
      showSegmentLength, labelX, labelY, length
    }: RenderSegmentLengthLabelOptions): void {
      if (showSegmentLength) {
        const lengthInImperial = ProjectionUtil.convertWorldUnitsToImperial(length);

        const domCoords = ThreeUtils.worldCoordinatesToDomCoordinates(
          labelX,
          labelY,
          ViewPort._editor.rendererDom,
          ViewPort._editor.activeCamera!
        );
        this.segmentLengthElement.style.transform = `
        translateX(${domCoords.x}px)
        translateX(-50%)
        translateY(${domCoords.y}px)
        translateY(-50%)
        `;
        this.segmentLengthElement.style.display = 'flex';
        this.segmentLengthElement.textContent = `${lengthInImperial.feet}'${lengthInImperial.inches}"`;

        if (!this.segmentLengthElement.parentElement) {
          ViewPort._editor.rendererDom.parentElement?.appendChild(this.segmentLengthElement);
        }
      } else {
        this.segmentLengthElement.remove();
      }
    }

    initSegmentLengthStyle(): void {
      const { style } = this.segmentLengthElement;
      style.position = 'absolute';
      style.top = '0';
      style.left = '0';
      style.color = '#e7ab39';
      style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      style.borderRadius = '4px';
      style.padding = '8px';
      style.pointerEvents = 'none';
      style.display = 'flex';
      style.justifyContent = 'center';
      style.alignItems = 'center';
    }

    dispose(): void {
      this.segmentLengthElement.remove();
    }
  }

  return MixinClass;
};

export type WithLengthLabels = Mixin<typeof WithLengthLabels>;
