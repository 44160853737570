import BaseEnvProps from './BaseEnvProps';

export class GenericEnvProp extends BaseEnvProps {
  name = '';
  icon = '';
  color = 'black';
  value = '';

  constructor(name: string, value: string) {
    super();
    this.name = name;
    this.value = value;
  }
}
