import React, { Attributes, BaseProps, CSSProperties, ReactElement } from 'react';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { registry } from './registry';
import { IconLayout } from './styles';

type Props = BaseProps & {
  name: string;
  style?: CSSProperties;
  selected?: boolean;
  colorTheme?: EThemeComponentColor;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

/**
 *
 * Render SVG icon in React component
 * It can be manipulated and add more props
 * @param props React props
 * @returns React Icon Element
 */
function Icon(props: Props): ReactElement {
  const { name = 'arrow', selected = true, colorTheme, ...rest } = props;
  const Component = registry.get(name);

  return (
    <IconLayout selected={selected} colorTheme={colorTheme}>
      {Component ? <Component { ...rest } /> : React.createElement('span', { ...rest } as Attributes, name)}
    </IconLayout>
  );
}

export { Icon };
