import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import type { IBaseSiteEquipmentToolDependencies } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/BaseSiteEquipmentTool';
import { ServiceEntranceEquipmentTool } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/ServiceEntranceEquipmentTool';
import withTool from '../../../Shared/withTool';

type ConstDep = Pick<
  IBaseSiteEquipmentToolDependencies,
  'properties' | 'domain' | 'drawableObjectsFactory' | 'serviceBus' | 'smartGuides' | 'currentWorkspace'
>;

const ServiceEntranceEquipment = withTool(
  ServiceEntranceEquipmentTool,
  (store: Store): ConstDep => ({
    properties: store.uiStore.properties,
    domain: store.domain,
    drawableObjectsFactory: new SiteStructureFactory(),
    serviceBus: store.serviceBus,
    smartGuides: store.uiStore.smartGuides,
    currentWorkspace: store.uiStore.workspace.currentWorkspace
  })
);

export default ServiceEntranceEquipment;
