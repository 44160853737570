import isNil from 'lodash/isNil';
import {
  action, computed, observable
} from 'mobx';
import type { LayoutStrategy } from '../../../domain/models/RoofTopArray/LayoutStrategy';
import type { IDesignCreationRequest } from '../../../domain/request/DesignCreationRequest/IDesignCreationRequest';
import type { MountingSystemAttributes } from '../../../domain/typings';
import type { RackSpacing } from '../../../domain/models/MountingSystemDefinition/IConfiguration';
import type { IMountingSystemDefinitionsData } from '../../../domain/models/MountingSystemDefinition/MountingSystemDefinitions';

/**
 * The purpose of this store is to record user choices made in the design creation wizard
 * without having to store those values in the domain model (domain store).
 */
export class DesignCreationWizardStore {
  // -- Target system size (set by NewSystemSizeStage)

  @observable
  dcPowerRatingTargetInKw: number = 0;

  // -- PV module definition (set by NewSystemDesignStage)

  @observable
  pvModuleDefinitionId: string = '';
  @observable
  pvModuleDefinitionTitle: string = '';

  // -- Mounting system definition (set by MountingSystemDefinitionsStage)

  @observable
  steepSlopeMountingSystemDefinitionId: string = '';
  @observable
  steepSlopeMountingSystemAttributes?: MountingSystemAttributes = undefined;
  @observable
  lowSlopeMountingSystemDefinitionId: string = '';
  @observable
  lowSlopeMountingSystemAttributes?: MountingSystemAttributes = undefined;
  @observable
  lowSlopeMountingSystemDefaultTiltAngle?: number = undefined;
  @observable
  lowSlopeMountingSystemDefaultNumberOfTiers?: number = undefined;

  // -- Layout strategy (set by NewLayoutStrategyStage)

  @observable
  private steepSlopeLayoutStrategy?: LayoutStrategy = undefined;
  @observable
  private lowSlopeLayoutStrategy?: LayoutStrategy = undefined;
  @observable
  private lowSlopeRackSpacing?: RackSpacing = undefined;

  @action
  setPvModuleDefinition = (id: string, title: string): void => {
    this.pvModuleDefinitionId = id;
    this.pvModuleDefinitionTitle = title;
  };

  @computed
  get hasMountingSystemDefinitions(): boolean {
    return this.hasSteepSlopeMountingSystemDefinition || this.hasLowSlopeMountingSystemDefinition;
  }

  @computed
  get hasSteepSlopeMountingSystemDefinition(): boolean {
    return !!this.steepSlopeMountingSystemDefinitionId && !isNil(this.steepSlopeMountingSystemAttributes);
  }

  @action
  setSteepSlopeMountingSystemDefinition = (definitionId: string, attributes: MountingSystemAttributes): void => {
    this.steepSlopeMountingSystemDefinitionId = definitionId;
    this.steepSlopeMountingSystemAttributes = attributes;
  };

  @action
  clearSteepSlopeMountingSystemDefinition = (): void => {
    this.steepSlopeMountingSystemDefinitionId = '';
    this.steepSlopeMountingSystemAttributes = undefined;
  };

  @computed
  get hasLowSlopeMountingSystemDefinition(): boolean {
    return (
      !!this.lowSlopeMountingSystemDefinitionId
      && !isNil(this.lowSlopeMountingSystemAttributes)
      && !isNil(this.lowSlopeMountingSystemDefaultTiltAngle)
      && !isNil(this.lowSlopeMountingSystemDefaultNumberOfTiers)
    );
  }

  @action
  setLowSlopeMountingSystemDefinition = (
    definitionId: string,
    attributes: MountingSystemAttributes,
    tiltAngle: number,
    numberOfTiersPerRack: number
  ): void => {
    this.lowSlopeMountingSystemDefinitionId = definitionId;
    this.lowSlopeMountingSystemAttributes = attributes;
    this.lowSlopeMountingSystemDefaultTiltAngle = tiltAngle;
    this.lowSlopeMountingSystemDefaultNumberOfTiers = numberOfTiersPerRack;
  };

  @action
  clearLowSlopeMountingSystemDefinition = (): void => {
    this.lowSlopeMountingSystemDefinitionId = '';
    this.lowSlopeMountingSystemAttributes = undefined;
    this.lowSlopeMountingSystemDefaultTiltAngle = undefined;
    this.lowSlopeMountingSystemDefaultNumberOfTiers = undefined;
  };

  @action
  setSteepSlopeLayoutStrategy = (layoutStrategy: LayoutStrategy): void => {
    this.steepSlopeLayoutStrategy = layoutStrategy;
  };

  @action
  setLowSlopeLayoutStrategy = (layoutStrategy: LayoutStrategy, rackSpacing: RackSpacing): void => {
    this.lowSlopeLayoutStrategy = layoutStrategy;
    this.lowSlopeRackSpacing = rackSpacing;
  };

  createDesignRequest(): IDesignCreationRequest {
    const mountingSystemDefinitionsData: IMountingSystemDefinitionsData = {};
    if (this.hasSteepSlopeMountingSystemDefinition) {
      mountingSystemDefinitionsData.steepSlope = {
        externalDefinitionId: this.steepSlopeMountingSystemDefinitionId,
        defaultLayoutStrategy: this.steepSlopeLayoutStrategy!.toData()
      };
    }
    if (this.hasLowSlopeMountingSystemDefinition) {
      mountingSystemDefinitionsData.lowSlope = {
        externalDefinitionId: this.lowSlopeMountingSystemDefinitionId,
        defaultLayoutStrategy: this.lowSlopeLayoutStrategy!.toData(),
        defaultConfiguration: {
          tiltAngle: this.lowSlopeMountingSystemDefaultTiltAngle!,
          numberOfRowsInRack: this.lowSlopeMountingSystemDefaultNumberOfTiers!,
          rackSpacing: this.lowSlopeRackSpacing!.toData()
        }
      };
    }
    return {
      designSpecification: {
        dcPowerRatingTarget: Math.round(this.dcPowerRatingTargetInKw * 1000)
      },
      pvModuleDefinitionId: this.pvModuleDefinitionId,
      mountingSystemDefinitions: mountingSystemDefinitionsData
    };
  }
}
