import { SiteStructureFactory } from '../../../../../domain/models/StructureFactory';
import type Store from '../../../../../stores/Store';
import {
  ParcelBoundaryTool,
  type IParcelBoundaryToolDependencies
} from '../../../../../stores/UiStore/ToolbarStore/Project/ParcelBoundaryTool';
import withTool from '../../Shared/withTool';
type ConstDep = Pick<
  IParcelBoundaryToolDependencies,
  'properties' | 'drawableObjectsFactory' | 'domain' | 'wizard' | 'smartGuides' | 'currentWorkspace' | 'serviceBus'
>;
const ParcelBoundary = withTool<ParcelBoundaryTool, IParcelBoundaryToolDependencies, ConstDep>(
  ParcelBoundaryTool,
  (store: Store): ConstDep => ({
    serviceBus: store.serviceBus,
    properties: store.properties,
    domain: store.domain,
    smartGuides: store.smartGuides,
    wizard: store.wizard,
    currentWorkspace: store.workspace.currentWorkspace,
    drawableObjectsFactory: new SiteStructureFactory()
  })
);

export default ParcelBoundary;
