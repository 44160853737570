import React, { BaseProps, MouseEventHandler, ReactElement, useEffect, useState } from 'react';
import { Footer, FooterProps } from './Footer';
import { Header, HeaderProps } from './Header';
import {
  Background,
  Container,
  Content,
  ContentWrapper
} from './styles';

export type Props = BaseProps &
  HeaderProps &
  FooterProps & {
    /** @deprecated: use $show instead */
    show?: boolean;
    /**
     * @description: Transient attribute version of "show".
     * Use it to avoid react warnings
     */
    $show?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onClickLeftButton?: () => void;
    color?: string | undefined;
    height?: string;
    width?: string;
  };

const Modal = (props: Props): ReactElement => {
  const {
    className = '',
    show = false,
    $show = false,
    height,
    width,
    onOpen,
    onClose,
    onClickLeftButton,
    onClickCloseButton,
    ...rest
  } = props;
  const [display, setDisplay] = useState(true);
  const finalShow = $show ?? show;

  useEffect((): void => {
    if (!finalShow) setTimeout((): void => setDisplay(false), 500);
    else setDisplay(true);
    if (finalShow) {
      setTimeout((): void => {
        const activeElement = document.activeElement as HTMLElement;
        activeElement?.blur?.();
      }, 0);
    }
  }, [finalShow]);

  useEffect((): void => (display ? onOpen?.() : onClose?.()), [display]);

  return !finalShow && !display ? (
    <></>
  ) : (
    <Background
      data-testid="modal-background"
      onClick={onClickCloseButton ?? onClickLeftButton}
      $show={finalShow}
    >
      <Container
        onClick={((event: MouseEvent): void => {
          event.stopPropagation();
        }) as unknown as MouseEventHandler<HTMLDivElement>}
        className={className}
        width={width}
        height={height}
      >
        <Header onClickCloseButton={onClickCloseButton} {...rest} />
        <ContentWrapper
          className="element_with_scroll"
        >
          <Content>{props.children}</Content>
        </ContentWrapper>
        <Footer onClickLeftButton={onClickLeftButton} onClickCloseButton={onClickCloseButton} { ...rest} />
      </Container>
    </Background>
  );
};

export { Modal };
