import type { Dictionary } from '../../domain/typings';

export enum DcOptimizerUse {
  PROHIBITED = 'PROHIBITED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

export type Attributes = {
  'AC Power Output': number;
  'Inverter Type': string;
  'Max DC/AC Ratio': number;
  minDcInputVoltage: number;
  maxDcInputVoltage: number;
  optimizerUse: DcOptimizerUse;
  productFamily: string;
  manufacturerId: string;
  dcInputQuantity: number;
  withinInputVoltageRange?: boolean;
};

export interface IInverterOption {
  id: string;
  title: string;
  dateCreated: string;
  attributes: Attributes;
  tags: Dictionary<string>;
}

export interface IInverterDefinitionData {
  model: string;
  manufacturer: {
    name: string;
  };
}

export interface IInverterSearchRequest {
  query: string;
  inputVoltageLowerBound: number;
  inputVoltageUpperBound: number;
  voltage: number;
  moduleMountingMethod: string;
  productFamily?: string;
  manufacturerId?: string;
}

export interface IOptimizerOption {
  optionType: string;
  attributes: Dictionary<string>;
}

export interface IDcOptimizerDefinition {
  model: string;
  manufacturer: {
    name: string;
  };
}

export interface IOptimizerRequest {
  moduleId: string;
  inverterId: string;
  temperatureAdjustedMinVmp: number;
  temperatureAdjustedMaxVoc: number;
  temperatureAdjustedMaxIsc: number;
}
