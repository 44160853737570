import type { Vector3 } from 'three';
import type { IElectricalEquipmentData } from '../../../domain/entities/SitePlan/SitePlan';
import type { EquipmentExposure } from '../SiteDesign/EquipmentExposure';
import { Marker } from '../SiteDesign/Marker';
import {
  getSiteEquipmentMarkerTypes, SceneObjectType
} from '../Constants';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type { PolygonDrawable } from '../../mixins/PolygonDrawable';

export class ElectricalEquipmentMarker extends Marker {
  label!: string;
  override readonly type: string = SceneObjectType.ElectricalEquipmentMarker;
  override readonly propertyId: string = SceneObjectType.ElectricalEquipmentMarker;

  exposure!: EquipmentExposure;
  electricalEquipment!: IElectricalEquipmentData;

  override move(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    super.move(newPositions, editor, smartGuides);

    const markerNumber = editor
      .getObjectsByTypes(getSiteEquipmentMarkerTypes())
      .findIndex((marker: PolygonDrawable) => marker.serverId === this.serverId);

    this.mesh.position.setZ(editor.getObjectRenderHeight(this.type as SceneObjectType, markerNumber));
  }
}
