import {
  action, computed, observable
} from 'mobx';
import type { IPvModuleData } from './PvModule';
import { PvModule } from './PvModule';

export interface IPvModulesData {
  readonly definition: string;
  readonly instances?: readonly IPvModuleData[];
}

export class PvModules {
  definition: string;

  @observable
  instances: PvModule[];

  constructor(data?: IPvModulesData) {
    this.definition = data?.definition ?? '';
    this.instances = data?.instances?.map((instance: IPvModuleData): PvModule => new PvModule(instance)) ?? [];
  }

  toData(): IPvModulesData {
    return {
      definition: this.definition,
      instances: this.instances.length
        ? this.instances.map((instance: PvModule): IPvModuleData => instance.toData())
        : undefined
    };
  }

  @action
  deletePvModulesInPositions = (positionIds: readonly string[]): void => {
    this.instances = this.instances.filter((pvModule: PvModule): boolean => !positionIds.includes(pvModule.positionId));
  };

  @action.bound
  instanceIdsOnPositions = (positionIds: readonly string[]): string[] => {
    return this.instances
      .filter((pvModule: PvModule) => positionIds.includes(pvModule.positionId))
      .map((pvModule: PvModule) => pvModule.id);
  };

  @computed
  get count(): number {
    return this.instances.length;
  }

  instanceWithId = (id: string): PvModule | undefined => {
    return this.instances.find((instance) => instance.id === id)!;
  };

  @action.bound
  addPvModule(id: string, mountingSystemId: string, positionId: string): void {
    this.instances.push(
      new PvModule({
        id,
        mountingSystemId,
        positionId
      })
    );
  }

  hasPvModuleInPosition(positionId: string): boolean {
    return this.instances.some((instance: PvModule) => instance.positionId === positionId);
  }

  @computed
  get idsOfMountingSystemsInUse(): Set<string> {
    const mountingSystemIds = this.instances.map((pvModule: PvModule): string => pvModule.mountingSystemId);
    return new Set<string>(mountingSystemIds);
  }

  @computed
  get idsOfPvModulePositions(): readonly string[] {
    return this.instances.map((pvModule: PvModule): string => pvModule.positionId);
  }
}
