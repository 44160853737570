import isNil from 'lodash/isNil';
import {
  computed, observable
} from 'mobx';
import type Limit from '../Limit';
import {
  type ILayoutStrategyData, LayoutStrategy
} from '../RoofTopArray/LayoutStrategy';
import type { IMountingSystemConfigurationData } from './IConfiguration';
import { Configuration } from './IConfiguration';
import { MountingSystemDefinition } from './MountingSystemDefinition';
import type { IMountingBosPreferencesData } from './MountingSystemDefinition';

export interface ILowSlopeMountingSystemDefinitionData {
  externalDefinitionId: string;
  defaultLayoutStrategy: ILayoutStrategyData;
  defaultConfiguration: IMountingSystemConfigurationData;
  readonly defaultMountingBosPreferences?: IMountingBosPreferencesData;
}

/**
 * Low-slope mounting system definition. It has all the same attributes as the steep-slope mounting system definition,
 * but it adds default low-slope mounting system configuration.
 */
export class LowSlopeMountingSystemDefinition extends MountingSystemDefinition {
  @observable
  defaultConfiguration: Configuration;

  constructor(data: ILowSlopeMountingSystemDefinitionData) {
    super(data);
    this.defaultLayoutStrategy = new LayoutStrategy(data.defaultLayoutStrategy);
    this.defaultConfiguration = new Configuration(data.defaultConfiguration);
  }

  override toData(): ILowSlopeMountingSystemDefinitionData {
    return {
      externalDefinitionId: this.externalDefinitionId,
      defaultLayoutStrategy: this.defaultLayoutStrategy.toData(),
      defaultConfiguration: this.defaultConfiguration.toData(),
      defaultMountingBosPreferences: this.defaultMountingBosPreferences
    };
  }

  override copy = (): LowSlopeMountingSystemDefinition => new LowSlopeMountingSystemDefinition(this.toData());

  @computed
  override get arrayAzimuth(): number | undefined {
    return this.defaultConfiguration?.azimuth ?? 180;
  }

  @computed
  get hasMountingConfiguration(): boolean {
    return !isNil(this.defaultConfiguration.numberOfRowsInRack) && !isNil(this.defaultConfiguration.tiltAngle);
  }

  @computed
  get tiltAngleLimits(): Limit {
    return {
      lower: Number(this.attributes?.minimumTiltAngle ?? 0),
      upper: Number(this.attributes?.maximumTiltAngle ?? 90)
    };
  }

  @computed
  get numberOfTiersPerRackLimits(): Limit {
    return {
      lower: Number(this.attributes?.minimumNumberOfTiersPerRack ?? 1),
      upper: Number(this.attributes?.maximumNumberOfTiersPerRack ?? 10)
    };
  }
}
