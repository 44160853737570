import { BaseProps } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';
import { ColorThemeProps, getColorTheme } from '../../Theme/ColorThemeProps';

type Props = BaseProps & ColorThemeProps;

export const TagsInput = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 26px;
  width: auto;
  border-bottom: 0.8px solid ${
    ({ theme }: PropsTheme): Color => theme.colors.grayColor3
  };
  input {
    flex: 1;
    border: none;
    font-size: 16px;
    &:focus {
      outline: transparent;
    }
  }
  & ul > div div {
    border: none;
    background: transparent;
  }
`;

export const Tag = styled.li<Props>`
  width: auto;
  display: flex;
  padding: 3px 8px;
  list-style: none;
  border-radius: 14px;
  margin: 0 6px 4px 0;
  background: ${getColorTheme};
`;

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
`;

export const Title = styled.span`
  margin: 0 5px;
  font-size: 14px;
  & + div svg {
    fill: #4a4a4a;
  }
`;

export const HeaderText = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;
