import debounce from 'lodash/debounce';
import find from 'lodash/find';

// I don't know why, but in this particular file my IDE doesn't recognize the following imports
// eslint-disable-next-line unused-imports/no-unused-imports, object-curly-newline
import { action, computed, observable, reaction } from 'mobx';

import type { Option } from '@aurorasolar/lyra-ui-kit';

import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../../domain/typings';
import type { IBaseSiteEquipmentViewModelDependencies } from '../BaseSiteEquipmentViewModel';
import { BaseSiteEquipmentViewModel } from '../BaseSiteEquipmentViewModel';
import { PropsPanelUICodes } from '../../propertiesStoreConstants';
import { removePropertiesIfNullOrUndefined } from '../../../../../utils/helpers';
import { getRootStore } from '../../../../RootStoreInversion';
import { MeterBasePropertiesViewModel } from './MeterBasePropertiesViewModel';
import type { BaseUtilityMeterProps } from 'domain/models/SiteDesign/BaseUtilityMeterProps';

type TProperties = {
  meterSocket?: boolean;
  utilityMeter?: BaseUtilityMeterProps | null;
  exposure?: { type: string; constrained: boolean };
  manufacturer?: string;
  model?: string;
  busbarRating?: number;
  shortCircuitCurrentRating?: string;
  mainBreakerAmpereInterruptingCapacity?: number;
  busbarPointOfConnection?: string;
  mainBreakerRating?: number;
  parallelEnergySourceInputRating?: number;
};

export class ServiceEntranceEquipmentLoadCenterViewModel extends BaseSiteEquipmentViewModel {
  readonly propUICode = PropsPanelUICodes.MainServicePanel;
  readonly title = 'Service Entrance Equipment';

  readonly exposureOptions = [
    {
      name: 'INDOOR',
      value: 'INDOOR'
    },
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  /** Service Entrance Equipment props */
  @observable
  exposure?: Option;
  @observable
  manufacturer?: Option;
  @observable
  model?: string;
  @observable
  busbarRating?: Option;
  @observable
  busbarPointOfConnection?: Option;
  @observable
  mainBreakerRating?: Option;
  @observable
  parallelEnergySourceInputRating?: Option;
  @observable
  utilityMeterProps: MeterBasePropertiesViewModel;

  /** Options */
  @observable
  manufacturerOptions: Option[] = [];
  @observable
  busbarRatingOptions: Option[] = [];
  @observable
  busbarPointOfConnectionOptions: Option[] = [];
  @observable
  parallelEnergySourceInputRatingOptions: Option[] = [];
  @observable
  mainBreakerRatingOptions: Option[] = [];
  @observable
  shortCircuitCurrentRatingOptions: Option[] = [];
  @observable
  shortCircuitCurrentRating?: Option;
  @observable
  mainBreakerAmpereInterruptingCapacityOptions: Option[] = [];
  @observable
  mainBreakerAmpereInterruptingCapacity?: Option;
  @observable
  loading = false;

  hasMainBreaker: boolean = true;

  assignModelNumber: (value: string) => void;

  clearShortCircuitRatingAutoUpdate = reaction(
    () => getRootStore().domain.siteEquipment.meterMain?.mainBreakerAmpereInterruptingCapacity,
    (mainBreakerAIC) => {
      this.loadShortCircuitCurrentRatingOptions(mainBreakerAIC);
    }
  );

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    super(dependencies);
    const setUtilityMeterPropsToDomainObject = (utilityMeter?: BaseUtilityMeterProps): void => {
      this.setDomainProps({
        utilityMeter
      });
    };
    this.utilityMeterProps = new MeterBasePropertiesViewModel(
      dependencies.domain.project.site.address.province,
      setUtilityMeterPropsToDomainObject
    );
    this.loadOptions().then(this.setViewModelPropsFromDomain);

    this.assignModelNumber = debounce((value: string): void => {
      this.setModel(value);
    }, 300);

    this.hasMainBreaker = dependencies.domain.siteEquipment.mainServicePanel?.hasMainBreaker ?? false;
  }

  @computed
  get isCombinationPanel(): boolean {
    return this.domain.siteEquipment.mainServicePanel?.meterSocket ?? false;
  }

  @action.bound
  setDomainProps(prop: TProperties, confirmationDeclinedCallback?: () => void): void {
    const mainServicePanel = this.domain.siteEquipment.mainServicePanel!;
    const mergedMSPValue = Object.assign(mainServicePanel, prop);
    if (mergedMSPValue.utilityMeter) {
      removePropertiesIfNullOrUndefined(mergedMSPValue.utilityMeter);
    }
    this.updateEquipment(mergedMSPValue, confirmationDeclinedCallback);
  }

  @action.bound
  setViewModelPropsFromDomain(): void {
    const mainServicePanel = this.domain.siteEquipment.mainServicePanel;
    const isExposureMissing = !mainServicePanel?.exposure?.type;

    const exposureValue = mainServicePanel?.exposure?.type ?? this.exposureOptions[1].value;
    const energySourceRatingValue =
      mainServicePanel?.parallelEnergySourceInputRating ?? this.parallelEnergySourceInputRatingOptions[0].value;

    // If MSP exposure is missing in the domain, we set default value - OUTDOOR;
    // This value should also be set into domain model (second param).
    this.setExposure(exposureValue, isExposureMissing);

    // If energy source input rating is missing in the domain, we set default value - N/A;
    // Such values like N/A, we should not set into domain model, to keep it clean.
    this.setParallelEnergySourceInputRating(energySourceRatingValue, false);

    this.setManufacturer(mainServicePanel?.manufacturer, false);
    this.setModel(mainServicePanel?.model, false);
    this.setBusbarRating(mainServicePanel?.busbarRating, false);
    this.setBusbarPointOfConnection(mainServicePanel?.busbarPointOfConnection, false);
    this.setMainBreakerRating(mainServicePanel?.mainBreakerRating, false);
    this.setShortCircuitCurrentRating(mainServicePanel?.shortCircuitCurrentRating, false);
    this.setMainBreakerAmpereInterruptingCapacity(mainServicePanel?.mainBreakerAmpereInterruptingCapacity, false);
    this.utilityMeterProps.setProperties(mainServicePanel?.utilityMeter as BaseUtilityMeterProps, false);
  }

  /** Service request */
  async loadOptions(): Promise<void> {
    const mainServicePanel = this.domain.siteEquipment.mainServicePanel!;
    const meterMain = this.domain.siteEquipment.meterMain;
    this.loading = true;
    // For a better UX, all the requests should execute at the same time
    await Promise.all([
      this.loadManufacturer(),
      this.loadBusbarRating(),
      this.loadShortCircuitCurrentRatingOptions(
        meterMain
          ? meterMain.mainBreakerAmpereInterruptingCapacity
          : mainServicePanel.mainBreakerAmpereInterruptingCapacity
      ),
      this.loadBusbarPointOfConnection(),
      this.loadParallelEnergySourceInput(),
      this.loadMainBreakerRating(Number(mainServicePanel.busbarRating)),
      this.loadMainBreakerAmpereInterruptingCapacityOptions(mainServicePanel.shortCircuitCurrentRating)
    ]);
    this.loading = false;
  }

  async loadManufacturer(): Promise<void> {
    await this.equipmentService.getPanelManufacturerOptions().then((response: IOption<MarkerAttributes>[]): void => {
      this.manufacturerOptions = this.mapToItemOption(response);
    });
  }

  async loadBusbarRating(): Promise<void> {
    await this.designService.getBusbarRatingOptions().then((response: IOption<MarkerAttributes>[]): void => {
      this.busbarRatingOptions = this.mapToItemOption(response);
    });
  }

  async loadBusbarPointOfConnection(): Promise<void> {
    await this.designService.getBusbarPointOfConnectionOptions().then((response: IOption<MarkerAttributes>[]): void => {
      this.busbarPointOfConnectionOptions = this.mapToItemOption(response);
    });
  }

  async loadMainBreakerRating(busbarRating: number): Promise<void> {
    if (this.domain.siteEquipment.mainServicePanel?.hasMainBreaker) {
      await this.designService
        .getMspMainBreakerRatingOptions(busbarRating)
        .then((response: IOption<MarkerAttributes>[]): void => {
          this.mainBreakerRatingOptions = this.mapToItemOption(response);
        });
    }
  }

  async loadShortCircuitCurrentRatingOptions(mainBreakerAmpereInterruptingCapacity?: number): Promise<void> {
    await this.designService
      .getShortCircuitCurrentRatingOptions(mainBreakerAmpereInterruptingCapacity)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.shortCircuitCurrentRatingOptions = this.mapToItemOption(response);
      });
  }

  async loadMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating?: string): Promise<void> {
    if (this.domain.siteEquipment.mainServicePanel?.hasMainBreaker) {
      await this.designService
        .getMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating)
        .then((response: IOption<MarkerAttributes>[]): void => {
          this.mainBreakerAmpereInterruptingCapacityOptions = this.mapToItemOption(response);
        });
    }
  }

  async loadParallelEnergySourceInput(): Promise<void> {
    await this.designService
      .getAuxiliaryEnergyInputMaxOcpdRatingOptions()
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.parallelEnergySourceInputRatingOptions = this.mapToItemOption(response);
      });
  }

  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find(this.exposureOptions, this.findOptionByValue(value)) as Option | undefined;
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        exposure: {
          type: `${value}`,
          constrained: false
        }
      });
    }
  }

  @action.bound
  setManufacturer(value?: string | number, shouldUpdate: boolean = true): void {
    const manufacturer = find(this.manufacturerOptions, this.findOptionByValue(value));
    if (manufacturer) {
      this.manufacturer = manufacturer;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        manufacturer: `${value}`
      });
    }
  }

  @action.bound
  setModel(value?: string, shouldUpdate: boolean = true): void {
    this.model = value;
    if (shouldUpdate) {
      this.setDomainProps({
        model: `${value}`
      });
    }
  }

  @action.bound
  setBusbarRating(value?: string | number, shouldUpdate: boolean = true): void {
    const busbarRating = find(this.busbarRatingOptions, this.findOptionByValue(value));
    if (busbarRating) {
      this.busbarRating = busbarRating;
    }
    if (shouldUpdate) {
      const busbarValue = this.busbarRating ? Number(this.busbarRating.value) : 0;
      const mainBreakerValue = this.mainBreakerRating ? Number(this.mainBreakerRating.value) : 0;
      if (busbarValue < mainBreakerValue) {
        this.mainBreakerRating = undefined;
      }
      const whereToUpdateMainBreaker = this.domain.siteEquipment.meterMain ?? this;
      this.loadMainBreakerRating(Number(value));
      this.loadMainBreakerAmpereInterruptingCapacityOptions(
        this.shortCircuitCurrentRating ? (this.shortCircuitCurrentRating.value as string) : undefined
      );
      this.setDomainProps({
        busbarRating: Number(value),
        mainBreakerRating: this.mainBreakerRating ? mainBreakerValue : undefined
      });
    }
  }

  @action.bound
  setBusbarPointOfConnection(value?: string | number, shouldUpdate: boolean = true): void {
    const busbarPointOfConnection = find(this.busbarPointOfConnectionOptions, this.findOptionByValue(value));
    if (busbarPointOfConnection) {
      this.busbarPointOfConnection = busbarPointOfConnection;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        busbarPointOfConnection: `${value}`
      });
    }
  }

  @action.bound
  setMainBreakerRating(value?: string | number, shouldUpdate: boolean = true): void {
    const mainBreakerRating = find(this.mainBreakerRatingOptions, this.findOptionByValue(value));
    if (mainBreakerRating) {
      this.mainBreakerRating = mainBreakerRating;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        mainBreakerRating: Number(value)
      });
    }
  }

  @action.bound
  setShortCircuitCurrentRating(value?: string | number, shouldUpdate: boolean = true): void {
    const option = find(this.shortCircuitCurrentRatingOptions, this.findOptionByValue(value));
    if (option) {
      this.shortCircuitCurrentRating = option;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        shortCircuitCurrentRating: String(value)
      });
      this.loadMainBreakerAmpereInterruptingCapacityOptions(String(value));
    }
  }

  @action.bound
  setMainBreakerAmpereInterruptingCapacity(value?: string | number, shouldUpdate: boolean = true): void {
    const option = find(this.mainBreakerAmpereInterruptingCapacityOptions, this.findOptionByValue(value));
    if (option) {
      this.mainBreakerAmpereInterruptingCapacity = option;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        mainBreakerAmpereInterruptingCapacity: Number(value)
      });
      this.loadShortCircuitCurrentRatingOptions(Number(value));
    }
  }

  @action.bound
  setParallelEnergySourceInputRating(value?: string | number, shouldUpdate: boolean = true): void {
    const parallelEnergySourceInputRating = find(
      this.parallelEnergySourceInputRatingOptions,
      this.findOptionByValue(value)
    );
    if (parallelEnergySourceInputRating) {
      this.parallelEnergySourceInputRating = parallelEnergySourceInputRating;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        parallelEnergySourceInputRating: isNaN(Number(value)) ? undefined : Number(value)
      });
    }
  }
}
