import { useState } from 'react';
import type {
  StringConnected,
  SystemSummary
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import StringingService from '../../../../services/stringing/stringingService';
import { InverterExpansionState } from './types';
import type {
  Event, SelectStringingInInverterSummaryModalPayload
} from 'services/eventSystem/eventSystemHook';

type InverterExpandedFlagsMapType = { [key: string]: InverterExpansionState };
/**
 * @description Using object here to rely on object reference to update value instantly
 * because StringingService.selectStringingById will trigger event handler synchronously
 */
type IgnoreStringingSelectionType = { value: boolean };

export const useIgnoreStringingSelection = (): [
  IgnoreStringingSelectionType,
  ({ value }: IgnoreStringingSelectionType) => void
] => {
  return useState<IgnoreStringingSelectionType>({ value: false });
};

type createOnSelectedStringingChangedParams = {
  ignoreStringingSelection: IgnoreStringingSelectionType;
  setIgnoreStringingSelection: (value: IgnoreStringingSelectionType) => void;
  inverterExpandedFlagsMap: InverterExpandedFlagsMapType;
  setInverterExpandedFlagsMap: (value: InverterExpandedFlagsMapType) => void;
  systemSummaryList: SystemSummary[];
};

/**
 * @description this factory creates handler of stringing selection change event.
 * When a new stringing is selected, we must hide all inverters, but the one selected.
 * And if stringing is deselected we must expand all inverters.
 */
export const createOnSelectedStringingChanged =
  (dependencies: createOnSelectedStringingChangedParams) =>
    (event: Event<SelectStringingInInverterSummaryModalPayload>): void => {
      const {
        ignoreStringingSelection,
        setIgnoreStringingSelection,
        inverterExpandedFlagsMap,
        setInverterExpandedFlagsMap,
        systemSummaryList
      } = dependencies;

      // Checking ignoreStringingSelection, if it's enabled - don't react on
      // stringing selection change because it originated from inverter summary panel.
      if (ignoreStringingSelection.value) {
        ignoreStringingSelection.value = false;
        setIgnoreStringingSelection(ignoreStringingSelection);
        return;
      }

      const selectedStringingServerId = event.payload!.stringingServerId;
      if (selectedStringingServerId === '') {
      // Stringing is deselected, expanding all inverters.
        for (let key in inverterExpandedFlagsMap) {
          inverterExpandedFlagsMap[key] = InverterExpansionState.Expanded;
        }
      } else {
      // Collapse all inverters, but the selected one.
        for (let key in inverterExpandedFlagsMap) {
          const isSelectedStringingPartOfInverter = !!systemSummaryList[key as unknown as number].strings.find(
            ({ serverId }: StringConnected): boolean => serverId === selectedStringingServerId
          );
          inverterExpandedFlagsMap[key] = isSelectedStringingPartOfInverter
            ? InverterExpansionState.ExpandedAndSelected
            : InverterExpansionState.Collapsed;
        }
      }

      setInverterExpandedFlagsMap({ ...inverterExpandedFlagsMap });
    };

type CreateOnSelectedStringingChangedParams = {
  inverterExpandedFlagsMap: InverterExpandedFlagsMapType;
  setInverterExpandedFlagsMap: (value: InverterExpandedFlagsMapType) => void;
  index: number;
  stringsConnected: StringConnected[];
  ignoreStringingSelection: IgnoreStringingSelectionType;
  setIgnoreStringingSelection: (value: IgnoreStringingSelectionType) => void;
};
/**
 * @description this factory creates a handler of click event on an inverter's
 * header in the inverter summary panel. It should change inverter's visual state
 * (collapsing or expanding) and select an expanded inverter's stringing.
 */
export const createHandleClickOnInverterHeader = (dependencies: CreateOnSelectedStringingChangedParams) => (): void => {
  const {
    inverterExpandedFlagsMap,
    setInverterExpandedFlagsMap,
    index,
    stringsConnected,
    ignoreStringingSelection,
    setIgnoreStringingSelection
  } = dependencies;

  if (inverterExpandedFlagsMap[index] === InverterExpansionState.Collapsed) {
    // Deselect previously selected inverter
    for (let key in inverterExpandedFlagsMap) {
      if (inverterExpandedFlagsMap[key] === InverterExpansionState.ExpandedAndSelected) {
        inverterExpandedFlagsMap[key] = InverterExpansionState.Expanded;
      }
    }
    inverterExpandedFlagsMap[index] = InverterExpansionState.ExpandedAndSelected;
    if (stringsConnected.length) {
      // Selected expanded inverter's stringing.
      // Also, set flag to ignore event triggered by StringingService.selectStringingById,
      // so that we won't collapse all inverters but the selected one. E.g. keep
      // current state of expanded and collapsed inverters
      ignoreStringingSelection.value = true;
      setIgnoreStringingSelection(ignoreStringingSelection);
      StringingService.selectStringById(stringsConnected[0].getId());
    }
  } else if (
    inverterExpandedFlagsMap[index] === InverterExpansionState.ExpandedAndSelected
    || inverterExpandedFlagsMap[index] === InverterExpansionState.Expanded
  ) {
    inverterExpandedFlagsMap[index] = InverterExpansionState.Collapsed;
  }

  setInverterExpandedFlagsMap({ ...inverterExpandedFlagsMap });
};
