import { KeyboardListener } from '../../../../utils/KeyboardListener';
import { Parcel } from '../../../../domain/models/SiteDesign/Parcel';
import type { IDrawableStructureFactory } from '../../../../domain/models/StructureFactory';
import { ECursor } from '../../../../domain/typings';
import type DomainStore from '../../../DomainStore/DomainStore';
import type SmartGuideStore from '../../SmartGuidesStore/SmartGuidesStore';
import type {
  IPointerDownControlEvent, IPointerLeftControlEvent
} from '../../../EditorStore/Controls/ControlEvents';
import type { PropertiesStore } from '../../Properties/Properties';
import type { IAddOrUpdateParcelCommandDependencies } from '../../../ServiceBus/Commands/AddOrUpdateParcelCommand';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import { canvasConfig } from '../../../../config/canvasConfig';
import type { IWorkspace } from '../../WorkspaceStore/types';
import type {
  IBaseToolDependencies, IHandleClicksTool, IHandleMoveTool
} from '../Tool';
import { PropsPanelUICodes } from '../../Properties/propertiesStoreConstants';
import { SceneObjectType } from '../../../../domain/models/Constants';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { WizardStore } from '../../Wizard/Wizard';
import { ParcelBoundaryAddedEvent } from '../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../config/config';
import {
  PANNING_TOOL_ID,
  PARCEL_BOUNDARY_TOOL_ID,
  ROOF_TRACING_TOOL_ID,
  SELECT_TOOL_ID,
  TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID
} from './constants';
import { TraceToolBase } from './TraceToolBase';

export interface IParcelBoundaryToolDependencies extends IBaseToolDependencies {
  readonly properties: PropertiesStore;
  readonly smartGuides: SmartGuideStore;
  readonly wizard: WizardStore;
  readonly domain: DomainStore;
  readonly serviceBus: ServiceBus;
  readonly drawableObjectsFactory: IDrawableStructureFactory;
  readonly currentWorkspace: IWorkspace;
}

export class ParcelBoundaryTool extends TraceToolBase implements IHandleClicksTool, IHandleMoveTool {
  static readonly toolWhitelist: string[] = [
    PANNING_TOOL_ID,
    PARCEL_BOUNDARY_TOOL_ID,
    SELECT_TOOL_ID,
    TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID,
    ROOF_TRACING_TOOL_ID
  ];
  override readonly id: string = PARCEL_BOUNDARY_TOOL_ID;
  override readonly icon: string = 'parcel-boundary';
  override readonly title: string = 'Draw Parcel Boundary';
  override readonly description: string = this.title;
  override testId: string = 'ParcelBoundary';

  override showSubmenu: boolean = false;
  override smartGuides: SmartGuideStore;
  override currentWorkspace: IWorkspace;
  private selectionControl?: SelectionControl;

  constructor(dependencies: IParcelBoundaryToolDependencies) {
    super(dependencies);

    this.properties = dependencies.properties;
    this.wizard = dependencies.wizard;
    this.currentWorkspace = dependencies.currentWorkspace;
    this.domain = dependencies.domain;
    this.cursor = ECursor.FIRST_TRACE;

    this.smartGuides = dependencies.smartGuides;
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
  }

  whenSelected(): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);

    this.wipObject = this.drawableObjectsFactory.create<Parcel>(Parcel, {
      color: canvasConfig.parcelBoundaryColor
    });

    this.initPreviewLine();
    this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelParcelBoundaryTool);

    this.hidePreviewLine();
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);
    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);

    KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

    // This should be in the end, so that unselect workflow would not fail due to absent objects like this.wipObject
    if (this.domain.project.site.parcel.hasBoundary) {
      this.toolbar.activateSelectionToolInProjectWorkspace().then(() => {
        this.selectionControl!.setSelectedObjects([this.domain.project.site.parcel]);
      });
    } else {
      this.editor.removeObjectsByType(SceneObjectType.ParcelBoundary);
    }
  }

  whenDeselected(): void {
    this.editor.removeObject(this.wipObject!.mesh);
    this.removePreviewLine();
    this.removeEventListeners();
    this.properties.setPropertyPanel();
  }

  dispose(): void {
    this.removeEventListeners();
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    super.onMouseDown(event);
    this.disableTools();
  };

  override onMouseLeave = (event: IPointerLeftControlEvent): void => {
    this.hidePreviewLine();
    this.smartGuides.resetGuides();
  };

  async finishWipPolygon(): Promise<void> {
    const parcel = this.domain.project.site.parcel;
    const boundary = (this.wipObject as Parcel).boundary;

    parcel.setVertices(boundary.vertices);

    const dependencies: IAddOrUpdateParcelCommandDependencies = {
      editor: this.editor,
      parcel
    };
    this.serviceBus.send('add_or_update_parcel', dependencies);
    config.analytics?.trackEvent(new ParcelBoundaryAddedEvent(this.domain));

    this.editor.removeObject(this.wipObject!.mesh);
    this.removePreviewLine();

    // hide smart guides displayed
    this.smartGuides.resetGuides();
    this.enableTools();
    await this.toolbar.activateSelectionToolInProjectWorkspace();
  }

  private removeEventListeners(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
    this.editor.baseMouseControl.removeEventListener('PointerDown', this.onMouseDown);
    KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
  }

  private disableTools(): void {
    this.toolbar.whitelistTools(ParcelBoundaryTool.toolWhitelist);
  }
}
