import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

type StyleProps = PropsTheme & {
  themeBg?: string;
};

type Props = BaseProps & {
  themeBg?: string;
  title?: string | ReactElement;
};

const TitleBarStyle = styled.div<StyleProps>`
  background-color: ${({
    theme, themeBg = 'primary'
  }: StyleProps): Color => theme.colors[themeBg]};
  border-radius: 4px;
  color: ${({ theme }: StyleProps): Color => theme.colors.highlight};
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  text-align: center;
`;

function TitleBar(props: Props): ReactElement {
  const title = props.title ? props.title : props.children;

  return (
    <TitleBarStyle className={props.className} themeBg={props.themeBg}>
      {title}
    </TitleBarStyle>
  );
}

export default TitleBar;
