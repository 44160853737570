import { observer } from 'mobx-react-lite';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import useStore, { useUiStore } from '../../../stores/useStore';
import { RoofDeckingViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/RoofDeckingViewModel';
import LineHr from '../../components/LineHr';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';

const RoofDeckingProperties = observer((): ReactElement => {
  const {
    domain, editor, serviceBus
  } = useStore();
  const { roofFaceProps } = useUiStore();
  const { firstRoofFaceSelected } = roofFaceProps;
  const [roofDeckingViewModel, setRoofDeckingVM] = useState<RoofDeckingViewModel>();

  const onEditClick = useCallback((): void => {
    if (roofDeckingViewModel) {
      roofFaceProps.changeShowPropertiesOf(roofDeckingViewModel);
    }
  }, [roofDeckingViewModel, roofFaceProps]);

  useEffect((): void => {
    setRoofDeckingVM(
      roofFaceProps.createRoofFacePropertyEditor(RoofDeckingViewModel, {
        serviceBus,
        editor,
        roofFaceProps,
        domain,
        roofFace: firstRoofFaceSelected!
      })
    );
  }, [firstRoofFaceSelected, domain, editor, roofFaceProps, serviceBus]);

  if (!roofDeckingViewModel) {
    return <></>;
  }
  return (
    <>
      <LineHr />
      <RoofPropertyEdition title="Roof Decking" onEditClick={onEditClick}>
        <LyraTypography.Heading margin={'0px'} lineHeight={1.4} type="h6">
          {roofDeckingViewModel.selectName}
        </LyraTypography.Heading>
      </RoofPropertyEdition>
    </>
  );
});

export default RoofDeckingProperties;
