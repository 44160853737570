import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type { SiteImage } from '../../../domain/models/SiteDesign/SiteImage';
import type { IConfirmableCommand } from '../../../stores/ServiceBus/Commands/Command';
import { BaseCommand } from '../../../stores/ServiceBus/Commands/Command';

export type TObjectWithDeletableImage<T> = T & {
  deleteImage(imageId: string): void;
};

export interface IDeleteSiteImageCommandDependencies<T> {
  editor: EditorStore;
  domain: DomainStore;

  // Object on which we'll delete image
  imageOwnerObject: TObjectWithDeletableImage<T>;

  // Image instance that going to be deleted
  image: SiteImage;
}

export class DeleteSiteImageCommand<T> extends BaseCommand implements IConfirmableCommand {
  private readonly imageOwnerObject!: TObjectWithDeletableImage<T>;
  private readonly image!: SiteImage;

  constructor(dependencies: IDeleteSiteImageCommandDependencies<T>) {
    super();

    Object.assign(this, dependencies);
  }

  get confirmationModalText(): string {
    return `Are you sure you want to delete: ${this.image.title}?`;
  }

  async execute(): Promise<void> {
    this.imageOwnerObject.deleteImage?.(this.image.id);
  }
}
