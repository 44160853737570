import type { EllipseCurve } from 'three';
import {
  BufferGeometry, MeshBasicMaterial, Line
} from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import { canvasConfig } from '../../config/canvasConfig';
import { Drawable } from '../mixins/Drawable';
import { Labelable } from '../mixins/Labelable';
import { Unzoomable } from '../mixins/Unzoomable';
import { LayerCanvas } from './LayerCanvas';

// Creating mesh for representing the arc angle
const geometry = new BufferGeometry();
const material = new MeshBasicMaterial({
  transparent: true,
  depthTest: true,
  depthWrite: false,
  ...LayerCanvas.CLOSEST,
  color: canvasConfig.liveAngleNormalColor
});

const MixedClass = Unzoomable(Drawable(class SimpleClass {}));
class Arc extends MixedClass {
  curve?: EllipseCurve;

  constructor() {
    super();
    const line = new Line(geometry, material);
    this.mesh.add(line);
    this.mesh.frustumCulled = false;
    this.mesh.userData.lyraSceneObject = this;
  }

  unzoom(factor: number): void {
    this.mesh.scale.set(factor * this.mapZoomFactor, factor * this.mapZoomFactor, factor * this.mapZoomFactor);
  }

  redraw(): void {
    throw new Error('Method not implemented.');
  }

  setCurve(curve: EllipseCurve): void {
    this.curve = curve;
    // Generating all points for the arc
    const points = curve.getPoints(40);
    this.mesh.geometry.setFromPoints(points);
  }
}

const ArcLabeledMixin = Labelable(Arc);
export class ArcLabeled extends ArcLabeledMixin {
  private fontEditor: Font;

  constructor(font: Font) {
    super();
    this.mesh.material = material;
    this.mesh.geometry = geometry;
    this.fontEditor = font;
  }

  loadFont(): Font {
    return this.fontEditor;
  }

  override unzoom(factor: number): void {
    if (this.curve) {
      super.unzoom(factor);
      const middlePoint = this.curve.getPointAt(0.5);
      // Position text based on current zoom
      const factorPosition = 1 + factor;
      const xpos = middlePoint.x * factorPosition;
      const ypos = middlePoint.y * factorPosition;
      // Unscale text
      this.text.scale.set(factor, factor, factor);
      // Positioning Text
      this.text.position.setX(xpos);
      this.text.position.setY(ypos);
    }
  }
}
