import styled from 'styled-components';
import { EditButton } from '../../components/EditButton';
import { Flexbox } from '../Layout';

export const CardWrapper = styled.div`
  position: relative;
  min-width: 195px;
  width: 100%;
`;

export const HeaderEditionWrapper = styled(Flexbox)`
  margin: 0 0 15px 0;
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const HeaderEditButtonWrapper = styled(EditButton)`
  position: absolute;
  top: 12px;
  right: 0;
`;
