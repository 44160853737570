import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { SurfaceRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/SurfaceRoofFaceViewModel';
import { LABEL_SURFACE } from '../../constants';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';
import { useUiStore } from '../../../../../stores/useStore';

const SurfaceRoofFace = (): ReactElement => {
  const {
    roofFaceProps, properties
  } = useUiStore();

  const surfaceViewModel = roofFaceProps.currentRoofPropertyEditor as SurfaceRoofFaceViewModel;

  useEffect((): void => {
    properties.setTitle(LABEL_SURFACE);
    surfaceViewModel.loadSurfaceTypes();
    surfaceViewModel.loadSeletedSurfaceSubTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surfaceViewModel.currentSurfaceMaterial]);

  return (
    <RoofPropertiesParent viewModel={surfaceViewModel} multiEdit={false}>
      <LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select
        label="SURFACE TYPE"
        listOptions={surfaceViewModel.surfaceTypes}
        onSelect={(value: string | number): void => {
          surfaceViewModel.setCurrentSurfaceType(value as string);
        }}
        selectedOptionColor={EThemeComponentColor.PURPLE}
        selected={surfaceViewModel.onSelectSurfaceType?.attributes.value || surfaceViewModel.currentSurfaceType}
      />
      {surfaceViewModel.surfaceMaterials.length > 0 ? (
        <LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select
          className="mt-xs"
          label="ROOF MATERIAL DETAILS"
          listOptions={surfaceViewModel.surfaceMaterials}
          onSelect={(value: string | number): void => {
            surfaceViewModel.setCurrentMaterial(value as string);
          }}
          selectedOptionColor={EThemeComponentColor.PURPLE}
          selected={surfaceViewModel.onSelectMaterial?.attributes.value || surfaceViewModel.currentSurfaceMaterial}
        />
      ) : (
        <></>
      )}
    </RoofPropertiesParent>
  );
};

export default observer(SurfaceRoofFace);
