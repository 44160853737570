import { canvasConfig } from '../../../../../config/canvasConfig';
import { StreetLocation } from '../../../../../domain/models/SiteDesign/StreetLocation';
import { STREET_LOCATION_ID } from '../constants';
import type { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import type { IBaseSiteEquipmentToolDependencies } from './BaseSiteEquipmentTool';
import { BaseSiteEquipmentTool } from './BaseSiteEquipmentTool';

export class StreetLocationTool extends BaseSiteEquipmentTool {
  override readonly id: string = STREET_LOCATION_ID;
  override readonly icon: string = 'STRT';
  override readonly title: string = 'Street Location';
  override readonly description: string = this.title;
  override testId: string = 'StreetLocationTool';

  constructor(dependencies: IBaseSiteEquipmentToolDependencies) {
    super(dependencies);
  }

  override createEquipment = (): Marker => {
    return this.drawableObjectsFactory.create<StreetLocation>(StreetLocation, {
      color: canvasConfig.streetLocationIconColor
    });
  };

  override getEquipment(): Marker[] {
    const streetLocation = this.domain.siteEquipment?.streetLocation;
    if (streetLocation) {
      return [streetLocation as Marker];
    }
    return [];
  }
}
