import {
  action, observable
} from 'mobx';
import type { ISeismicDesignParametersData } from './SeismicDesignParameters';
import { SeismicDesignParameters } from './SeismicDesignParameters';
import type { ICodesAndStandardsData } from './CodesAndStandards';
import { CodesAndStandards } from './CodesAndStandards';
import type { ISnowDesignParametersData } from './SnowDesignParameters';
import { SnowDesignParameters } from './SnowDesignParameters';
import type { ITemperatureDesignParametersData } from './TemperatureDesignParameters';
import { TemperatureDesignParameters } from './TemperatureDesignParameters';
import type { ITerrainDesignParametersData } from './TerrainDesignParameters';
import { TerrainDesignParameters } from './TerrainDesignParameters';
import type { IWindDesignParametersData } from './WindDesignParameters';
import { WindDesignParameters } from './WindDesignParameters';

export interface IDesignParametersData {
  readonly temperature?: ITemperatureDesignParametersData;
  readonly wind?: IWindDesignParametersData;
  readonly buildingRiskCategory: string;
  readonly snow?: ISnowDesignParametersData;
  readonly terrain?: ITerrainDesignParametersData;
  readonly seismic?: ISeismicDesignParametersData;
  readonly authorityHavingJurisdiction?: string;
  readonly loadServingEntity?: number;
  readonly codesAndStandards?: ICodesAndStandardsData;
}

export class DesignParameters {
  @observable
  temperature: TemperatureDesignParameters = new TemperatureDesignParameters();
  @observable
  wind: WindDesignParameters = new WindDesignParameters();
  /**
   * ASCE categories used to describe the risk to human life, health,
   * and welfare associated with a building's damage or failure.
   * The acceptable values for this property could change in
   * future versions of ASCE standards.
   * ENUM: I, II, III
   */
  buildingRiskCategory!: string;
  @observable
  snow: SnowDesignParameters = new SnowDesignParameters();
  @observable
  terrain: TerrainDesignParameters = new TerrainDesignParameters();
  @observable
  seismic: SeismicDesignParameters = new SeismicDesignParameters();
  /**
   * Authority that reviews a design and issues the approval prior to the issuing of a permit:
   * - string of format `dd(-ddd)?(-ddddd)?` where each number is a FIPS code
   */
  @observable
  authorityHavingJurisdiction?: string;
  /**
   * More commonly known as a utility. Utilities also impose design requirements,
   * however at this time we do not consider these design requirements,
   * we just list the LSE's name on the permit package
   */
  @observable
  loadServingEntity?: number;
  @observable
  codesAndStandards: CodesAndStandards = new CodesAndStandards();

  constructor(data: IDesignParametersData) {
    this.fromData(data);
  }

  @action.bound
  setLoadServingEntity(id: number): void {
    this.loadServingEntity = id;
  }

  @action.bound
  setAuthorityHavingJurisdiction(id: string): void {
    this.authorityHavingJurisdiction = id;
  }

  fromData(data: IDesignParametersData): void {
    this.temperature = new TemperatureDesignParameters(data.temperature);
    this.wind = new WindDesignParameters(data.wind);
    this.buildingRiskCategory = data.buildingRiskCategory;
    this.snow = new SnowDesignParameters(data.snow);
    this.terrain = new TerrainDesignParameters(data.terrain);
    this.seismic = new SeismicDesignParameters(data.seismic);
    this.authorityHavingJurisdiction = data.authorityHavingJurisdiction;
    this.loadServingEntity = data.loadServingEntity;
    this.codesAndStandards = new CodesAndStandards(data.codesAndStandards);
  }

  toData(): IDesignParametersData {
    return {
      temperature: this.temperature?.toData(),
      wind: this.wind?.toData(),
      buildingRiskCategory: this.buildingRiskCategory,
      snow: this.snow?.toData(),
      terrain: this.terrain?.toData(),
      seismic: this.seismic?.toData(),
      authorityHavingJurisdiction: this.authorityHavingJurisdiction,
      loadServingEntity: this.loadServingEntity,
      codesAndStandards: this.codesAndStandards?.toData()
    };
  }
}
