import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useContext, useEffect, useState
} from 'react';
import {
  LyraLoader, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { ThemeContext } from 'styled-components';
import { DcOptimizerUse } from '../../../../domain/models/Inverter';
import type {
  InverterSelectionViewModel,
  DcOptimizerType
} from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import useStore from '../../../../stores/useStore';
import {
  DetailItem, DetailList, DcOptimizerItem, DcOptimizerList
} from './styles';

const DcOptimizerPanel: React.FC = (): ReactElement => {
  const { modal } = useStore();
  const theme = useContext(ThemeContext);
  const [inverterSelectionViewModel] = useState(modal.viewModel as InverterSelectionViewModel);

  const [selectedDcOptimizer, setSelectedDcOptimizer] = useState<DcOptimizerType>();

  const {
    firstInverterDefinitionId,
    dcOptimizerDefinitionId,
    dcOptimizerLoading,
    dcOptimizerData,
    firstInverterSelected,
    isDcOptimizerRequired
  } = inverterSelectionViewModel;

  // Backend provides indication whether DC optimizer can be used or not (whether it is a valid option)
  const dcOptimizerCanBeUsed = (dcOptimizer: DcOptimizerType): boolean => {
    return dcOptimizer.valid === 'true' || dcOptimizer.value === 'NONE';
  };

  const determineSelectedDcOptimizer = (dcOptimizers: DcOptimizerType[]): DcOptimizerType | null => {
    if (dcOptimizerDefinitionId) {
      return (
        dcOptimizers.find((dcOptimizer: DcOptimizerType): boolean => dcOptimizer.value === dcOptimizerDefinitionId)
        ?? null
      );
    }
    if (firstInverterSelected?.attributes?.optimizerUse === DcOptimizerUse.OPTIONAL) {
      return dcOptimizers.find((dcOptimizer: DcOptimizerType): boolean => dcOptimizer.value === 'NONE') ?? null;
    }
    // Default selection must be valid and have the lowest maxPvInputPower
    return dcOptimizers.reduce(
      (best: DcOptimizerType | null, current: DcOptimizerType): DcOptimizerType | null =>
        dcOptimizerCanBeUsed(current) && (!best || current.maxPvInputPower < best.maxPvInputPower) ? current : best,
      null
    );
  };

  const validateData = (): void => {
    const dcOptimizerDefault: DcOptimizerType | null = determineSelectedDcOptimizer(dcOptimizerData);
    if (firstInverterDefinitionId && dcOptimizerData.length === 0 && !isDcOptimizerRequired) {
      inverterSelectionViewModel.changeRightButtonDisabled(false);
    } else if (dcOptimizerDefault !== null && isEmpty(selectedDcOptimizer)) {
      setSelectedDcOptimizer(dcOptimizerDefault);
      if (dcOptimizerDefault.value === 'NONE') {
        inverterSelectionViewModel.cleanDcOptimizerValue();
      } else {
        inverterSelectionViewModel.setDcOptimizerValue(dcOptimizerDefault.name, dcOptimizerDefault.value);
      }
      inverterSelectionViewModel.changeRightButtonDisabled(false);
    } else if (isEmpty(firstInverterDefinitionId)) {
      inverterSelectionViewModel.changeRightButtonDisabled(true);
      setSelectedDcOptimizer(undefined);
    } else if (dcOptimizerData.length > 0 && dcOptimizerDefault == null) {
      inverterSelectionViewModel.changeCompatibility(false);
      inverterSelectionViewModel.changeRightButtonDisabled(true);
    }
  };

  const isEmptySelectedDcOptimizer = isEmpty(selectedDcOptimizer);
  const isEmptyInverterDefinitionId = isEmpty(firstInverterDefinitionId);
  useEffect((): void => {
    validateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dcOptimizerData.length, isEmptySelectedDcOptimizer, isEmptyInverterDefinitionId]);

  useEffect((): void => {
    if (
      firstInverterDefinitionId
      && firstInverterSelected
      && firstInverterSelected.attributes.optimizerUse !== DcOptimizerUse.PROHIBITED
    ) {
      inverterSelectionViewModel.getDcOptimizers();
    } else {
      inverterSelectionViewModel.initialOptimizers();
    }

    if (dcOptimizerDefinitionId) {
      setSelectedDcOptimizer(
        dcOptimizerData.find((dcOptimizer: DcOptimizerType): boolean => dcOptimizer.value === dcOptimizerDefinitionId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstInverterSelected,
    // Checking length here as we can be sure that for one inverterId there's
    // only one list of optimizers, so if we get new inverterId and have
    // optimizerData.length > 0 - we're good to set selected optimizer.
    dcOptimizerData.length,
    firstInverterDefinitionId,
    dcOptimizerDefinitionId
  ]);

  const selectValidOptimizer = (dcOptimizer: DcOptimizerType): void => {
    if (dcOptimizer.value !== 'NONE') {
      inverterSelectionViewModel.setDcOptimizerValue(dcOptimizer.name, dcOptimizer.value);
      setSelectedDcOptimizer(dcOptimizer);
    } else {
      inverterSelectionViewModel.cleanDcOptimizerValue();
      setSelectedDcOptimizer(undefined);
    }
  };

  if (dcOptimizerLoading && !dcOptimizerData.length) {
    return (
      <div className="mb-xs">
        <LyraLoader.Loader bgColor={theme!.colors.whiteBg} text="Loading optimizers..." />
      </div>
    );
  }

  return (
    <>
      {dcOptimizerData.length !== 0 && (
        <>
          <LyraTypography.Heading fontSize="12px" margin="15px 0 5px">
            DC OPTIMIZER
          </LyraTypography.Heading>
          <DcOptimizerList>
            {dcOptimizerData.map(
              (dcOptimizer: DcOptimizerType, index: number): ReactElement => (
                <DcOptimizerItem
                  key={index}
                  active={dcOptimizerCanBeUsed(dcOptimizer)}
                  selected={dcOptimizer.name === selectedDcOptimizer?.name}
                  onClick={
                    dcOptimizerCanBeUsed(dcOptimizer) ? (): void => selectValidOptimizer(dcOptimizer) : undefined
                  }
                >
                  {dcOptimizer.name}
                </DcOptimizerItem>
              )
            )}
          </DcOptimizerList>
          {selectedDcOptimizer?.valid === 'true' && (
            <>
              <LyraTypography.Heading fontSize="12px" margin="15px 0 5px">
                DETAILS
              </LyraTypography.Heading>
              <DetailList>
                <DetailItem>Max ISC: {selectedDcOptimizer.maxIsc}A</DetailItem>
                <DetailItem>Min voltage: {selectedDcOptimizer.minVoltage}V</DetailItem>
                <DetailItem>Max voltage: {selectedDcOptimizer.maxVoltage}V</DetailItem>
                <DetailItem>Power rating: {selectedDcOptimizer.maxPvInputPower}W</DetailItem>
              </DetailList>
            </>
          )}
        </>
      )}
    </>
  );
};

export default observer(DcOptimizerPanel);
