import applyDefaults from './applyDefaults';
import hideFields from './hideFields';
import showOptions from './showOptions';
import hideOptions from './hideOptions';
import enableOptionsLessThanOrEqual from './enableOptionsLessThanOrEqual';
import showOptionsLessThanOrEqual from './showOptionsLessThanOrEqual';
import hideOptionsWithAllTags from './hideOptionsWithAllTags';
import RecentValuesRegistry from './RecentValuesRegistry';

/**
 * Note: the order of rules is extremely important. The current order may be accidental.
 * See https://github.com/RXNT/react-jsonschema-form-conditionals/#extension-with-calculated-values
 */
export default {
  applyDefaults,
  hideFields,
  showOptions,
  hideOptions,
  enableOptionsLessThanOrEqual,
  showOptionsLessThanOrEqual,
  hideOptionsWithAllTags
};

export { RecentValuesRegistry };
