import styled, {
  css,
  SimpleInterpolation,
  withTheme
} from 'styled-components';

type CardStyleProp = {
  collapsed?: boolean;
  disabled?: boolean;
  selected?: string | number;
  height?: string;
};

type ContainerProps = {
  width?: string;
};

function hideLabel(
  { collapsed,
    selected,
    height = '10px'
  }: CardStyleProp): SimpleInterpolation {
  const defaultHeight = '10px';
  return !collapsed
    ? css`
      opacity: 0; 
      visibility: hidden; 
      height: 0;
      padding: 0;
    `
    : css`
      opacity: 1;
      visibility: visible;
      height: ${selected ? height : defaultHeight};
      padding: 6px 10px;
    `;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation => css`width: ${width};`}
  box-sizing: border-box;
`;

export const SelectStyle = styled.div<CardStyleProp>`
  ${hideLabel}
  ${({ disabled = false }: CardStyleProp): string => `cursor: ${disabled ? 'not-allowed' : 'pointer'};`}
  background-color: white;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:active, &:focus{
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: 10px;
`;

export const SelectList = styled.div`
  opacity: 1;
  text-align: center;
  width: 100%;
`;
