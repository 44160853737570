import React, { BaseProps, ReactElement, useEffect, useState } from 'react';
import styled, { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';
import createCache, {
  StylisElement as EmotionStylisElement,
  StylisPlugin as EmotionStylisPlugin,
  StylisPluginCallback as EmotionStylisPluginCallback
} from '@emotion/cache';
import isPropValid from '@emotion/is-prop-valid';
import { CacheProvider } from '@emotion/react';
import { defaultTheme } from './defaultTheme';
import { GlobalStyles } from './GlobalStyles';
import { IDefaultTheme } from '../../typings';
import { ToastContainer } from '../Toast';
import { Element } from 'stylis';
import { LyraBorealisTheme, BorealisThemeProps } from './Borealis';

const LYRA_CSS_CLASS = 'lyra';
const updateSelector = (originalSelector: string): string => {
  if (originalSelector.includes(`.${LYRA_CSS_CLASS} `)) {
    return originalSelector;
  }
  return `.${LYRA_CSS_CLASS} ${originalSelector}`;
};

/**
 * Styled Components use Stylis CSS runtime processor.
 * This custom plugin appends `.lyra ` prefix to all CSS selectors generated by Styled Components.
 * See: https://styled-components.com/docs/api#stylesheetmanager
 */
const styledComponentsStylisPlugins = [
  (element: Element): void => {
    if (element.root?.value && !element.root.value.startsWith('@media')) {
      element.root.value = updateSelector(element.root.value);
    }
    return void 0;
  }
];

/**
 * `react-select` uses Emotion for styling. Emotion then also uses Stylis CSS runtime processor.
 * This custom plugin also appends `.lyra ` prefix to all CSS selectors generated by Emotion.
 * See: https://emotion.sh/docs/@emotion/cache and https://emotion.sh/docs/cache-provider
 */
const emotionStylisPlugin: EmotionStylisPlugin = (
  element: EmotionStylisElement,
  index: number,
  children: EmotionStylisElement[],
  callback: EmotionStylisPluginCallback
): string | void => {
  if (element.type !== 'rule') {
    return;
  }
  element.props = typeof element.props === 'string'
    ? updateSelector(element.props)
    : element.props.map(updateSelector);
};

const emotionCache = createCache({
  key: 'lyra-emo',
  stylisPlugins: [
    emotionStylisPlugin
    // Note: we are excluding the default prefixer plugin as we don't need vendor prefixes.
    // See: https://emotion.sh/docs/cache-provider
    // To re-add it, add `import { prefixer } from 'stylis'` and then add it here
  ],
});

type ThemeProps = BaseProps & {
  /**
   * Optional. If not provided, default theme is used.
   */
  theme?: IDefaultTheme;

  /**
   * Whether the `Theme` is nested within another `Theme`.
   *
   * A theme at any level controls styling via styled-components `ThemeProvider`.
   *
   * Only the top-level theme additionally:
   * - adds a root component marked with a `lyra` style class
   * - adds global styles
   * - configures CSS-producing components to add `.lyra ` prefix to selectors
   */
  isNested?: boolean;
};

const ThemeRoot = styled.main``;

type LyraThemeProps = ThemeProps & {
  auroraMode?: boolean;
};

/**
 * @description Theme component is intended to be a top-level wrapper around everything rendered by Lyra.
 * What it does:
 * - Creates a `main` HTML element with `lyra` CSS class to provide something like a namespace for CSS selectors
 * - Configures app-wide global styles (limiting them to `.lyra ` prefix to not affect the embedding app)
 * - Configures Styled Components to generate CSS selectors with `.lyra ` prefix
 * - Configures Emotion to generate CSS selectors with `.lyra` prefix
 * - Adds a Styled Components `ThemeProvider`. Note that these can be nested
 *    (e.g. Lyra Host app adds the top-level Lyra theme and Design Tool adds a nested theme with different color scheme)
 */
function Theme(props: LyraThemeProps): ReactElement {
  return (
    <ThemeProvider theme={props.theme || defaultTheme}>
      {props.isNested
        ? props.children
        : (
          <>
            <GlobalStyles
              theme={props.theme ?? defaultTheme}
              auroraMode={false}
              loadRoobertFonts={false}
            />
            <CacheProvider value={emotionCache}>
              <StyleSheetManager
                stylisPlugins={styledComponentsStylisPlugins}
                shouldForwardProp={(propName: string, target: WebTarget): boolean => {
                  if (typeof target === 'string') {
                    // For HTML elements, forward the prop if it is a valid HTML attribute
                    return isPropValid(propName);
                  }
                  // For other elements, forward all props
                  return true;
                }}
              >
                <ThemeRoot className={LYRA_CSS_CLASS}>
                  <ToastContainer />
                  {props.children}
                </ThemeRoot>
              </StyleSheetManager>
            </CacheProvider>
          </>
        )
      }
    </ThemeProvider>
  );
}

export { Theme, ThemeProps, defaultTheme, LyraBorealisTheme, BorealisThemeProps };
