import {
  LyraButtons, LyraDivider, LyraFormElementsDesignTool, LyraIcon, LyraModal
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import useStore from '../../../../stores/useStore';
import type { SelectBuildingViewModel } from '../../../../stores/UiStore/Wizard/ViewModels/NewRoofFace/SelectBuildingViewModel';
import {
  CenterWrapper, GlobalWrapper, LeftWrapper, RightWrapper
} from './styles';

const SelectBuildingModal: React.FC = (): ReactElement => {
  const { wizard } = useStore();
  const [viewModel] = useState<SelectBuildingViewModel>(wizard.currentViewModel as SelectBuildingViewModel);

  const modalProps = {
    title: 'Select Building',
    color: EThemeComponentColor.PURPLE,
    $show: true,
    showFooter: false,
    onClickCloseButton: (): void => viewModel.closeSteps()
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <GlobalWrapper>
        <LeftWrapper>
          <LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select
            label="Choose a Building"
            listOptions={viewModel.buildings}
            isCollapsedByDefault={false}
            onSelect={(value: string | number): void => {
              viewModel.selectBuildings(value as string);
            }}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </LeftWrapper>
        <CenterWrapper>
          <LyraDivider.Divider
            className="ph-m"
            direction="vertical"
            title="or"
            thickness="1px"
            length="105px"
            color={EThemeComponentColor.GRAY_200}
          />
        </CenterWrapper>
        <RightWrapper>
          <LyraButtons.BigButtonIcon
            fullWidth={false}
            withCaption={true}
            onClick={(): void => {
              viewModel.nextStep();
            }}
            options={{
              name: <LyraIcon.Icon name="new-building-icon" />,
              caption: 'Create New Building'
            }}
          />
        </RightWrapper>
      </GlobalWrapper>
    </LyraModal.Modal>
  );
};

export default observer(SelectBuildingModal);
