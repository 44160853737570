import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState, useCallback
} from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import useStore, { useUiStore } from '../../../stores/useStore';
import { isProjectWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import Icon from '../../../ui/components/Icons';
import LineVertical from '../../../ui/components/LineVertical';
import { UpsertInstallerAndCreateProjectViewModel } from '../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import FeatureFlag from '../../../ui/components/FeatureFlag';
import config, { UI_MODE } from '../../../config/config';
import { isLayoutDesignStage } from '../LayoutDesign/LayoutDesign';
import ProjectInfo from './ProjectInfo';
import ShowHide from './ShowHide';
import SmartGuideControls from './SmartGuideControls/SmartGuideControls';
import {
  Container, EditTooltipIcon, EditTooltipWrapper, Logo, TopBarStyle
} from './styles';
import ZoomControls from './ZoomControls';
import ToolbarTooltip from './Tooltip/ToolbarTooltip';
import { TooltipTriggerWrapper } from './Tooltip/styles';
/**
 * Gathering all containers from editor's top bar
 * It's already connected to store if we need to make operations here
 */
const Topbar = observer((): ReactElement => {
  const {
    domain, editor
  } = useStore();
  const {
    modal, workspace
  } = useUiStore();
  const {
    project, internalReferenceId
  } = domain;
  const [projectId, setProjectId] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  useEffect((): void => {
    if (project.site.address) {
      setAddress(project.site.address.asFormattedText);
    }

    if (internalReferenceId) {
      setProjectId(`ID #${internalReferenceId}`);
    }
  }, [project.site.address, internalReferenceId]);

  const goHome = useCallback(async (): Promise<void> => {
    if (isProjectWorkspace(workspace.currentWorkspace)) {
      await workspace.currentWorkspace.saveManually();
    } else {
      await domain.updateDesign();
      await domain.updateProject();
    }
    window.location.href = '/dashboard';
  }, [domain, workspace.currentWorkspace]);

  const [editCompanyDropdownIsShown, setEditCompanyDropdownIsShown] = useState<boolean>(false);
  const handleToggleEditCompanyDropdown = useCallback((): void => {
    setEditCompanyDropdownIsShown(!editCompanyDropdownIsShown);
  }, [editCompanyDropdownIsShown]);

  const handleOpenEditCompanyModal = useCallback((): void => {
    modal.createModal(
      'upsert_installer_and_create_project_modal',
      new UpsertInstallerAndCreateProjectViewModel({
        modal,
        domain,
        editor,
        startupMode: false
      })
    );
  }, [domain, editor, modal]);

  const smartGuidesAreVisible =
    isLayoutDesignStage(workspace.currentWorkspace.stageManager?.currentStage)
    || isProjectWorkspace(workspace.currentWorkspace);

  return (
    <TopBarStyle>
      <Flexbox align="center" fullHeight>
        <FeatureFlag flag={config.featureFlag.branding.showLogo}>
          <Logo onClick={goHome}>
            <Icon name="Logo" />
          </Logo>
        </FeatureFlag>
        <LineVertical margin="0" />
        <Container className="ml-m mr-m">
          <ZoomControls />
        </Container>
        {smartGuidesAreVisible && (
          <>
            <LineVertical margin="0" />
            <Container className="ml-m mr-m">
              <SmartGuideControls />
            </Container>
            <LineVertical margin="0" />
          </>
        )}
        <Container size="0.2">
          <ShowHide visibility={false} />
        </Container>
        <LineVertical visible={false} />
        {config.featureFlag.uiMode !== UI_MODE.AURORA && (
          <Container size="1" justifyContent="flex-end" hasPadding>
            <ProjectInfo projectId={projectId} address={address} />
          </Container>
        )}
      </Flexbox>
      <FeatureFlag flag={config.featureFlag.installer.showEdit}>
        <Container size="0" justifyContent="flex-end" hasPadding>
          <TooltipTriggerWrapper onClick={handleToggleEditCompanyDropdown}>
            <Icon name="vertical-dots" />
            {editCompanyDropdownIsShown && (
              <ToolbarTooltip>
                <EditTooltipWrapper onClick={handleOpenEditCompanyModal}>
                  <EditTooltipIcon>
                    <Icon name="pencil" />
                  </EditTooltipIcon>
                  <div>Edit Contractor Information</div>
                </EditTooltipWrapper>
              </ToolbarTooltip>
            )}
          </TooltipTriggerWrapper>
        </Container>
      </FeatureFlag>
    </TopBarStyle>
  );
});
export default Topbar;
