import {
  action, computed, observable
} from 'mobx';
import type { ReactText } from 'react';
import { MathUtils as ThreeMath } from 'three';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  MEASURE_LIMIT, YES_OR_NO_OPTIONS
} from '../../../../../domain/models/Constants';
import { ROOF_CONDITION_CODE } from '../../../../../ui/containers/RoofFaceProperties/constants';
import {
  UpdateRoofFacePropertyKey,
  type IUpdateRoofFacePropertyDependencies
} from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import type { RoofCondition } from '../../../../../domain/models/SiteDesign/RoofCondition';
import type { RoofSlopingStructuralMembers } from '../../../../../domain/models/SiteDesign/RoofSlopingStructuralMembers';
import { isWithin } from '../../../../../domain/models/Limit';

export class RoofConditionViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = ROOF_CONDITION_CODE;
  readonly yesOrNoOptions = YES_OR_NO_OPTIONS;
  readonly measureLimit = MEASURE_LIMIT;

  @observable
  maxHorizontalSpan?: number;
  @observable
  sag: number = 0;
  @observable
  ridgeBeamSag: number = 0;
  @observable
  disallowedHolesOrNotchesInStructuralMembers: string = 'NO';
  @observable
  structuralDecayOrFireDamage: string = 'NO';
  @observable
  evidenceOfLeaksDamageOrDeterioration: string = 'NO';

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.Condition;
  }

  @computed
  get displayEditMode(): boolean {
    return !!(
      Number(this.maxHorizontalSpan) > 0
      || Number(this.sag)
      || Number(this.ridgeBeamSag)
      || this.disallowedHolesOrNotchesInStructuralMembers === 'YES'
      || this.structuralDecayOrFireDamage === 'YES'
      || this.evidenceOfLeaksDamageOrDeterioration === 'YES'
    );
  }
  @computed
  override get savePropertyButtonDisabled(): boolean {
    return !!(
      (this.maxHorizontalSpan && !isWithin(MEASURE_LIMIT, this.maxHorizontalSpan))
      || (this.sag && !isWithin(MEASURE_LIMIT, this.sag))
      || (this.ridgeBeamSag && !isWithin(MEASURE_LIMIT, this.ridgeBeamSag))
    );
  }

  @action.bound
  setRoofFace(roofFace: RoofFace): void {
    this.maxHorizontalSpan = roofFace.structure?.slopingStructuralMembers?.maxHorizontalSpan;
    this.sag = roofFace.structure?.slopingStructuralMembers?.sag || 0;
    this.ridgeBeamSag = roofFace.structure?.condition?.ridgeBeamSag || 0;
    this.disallowedHolesOrNotchesInStructuralMembers = roofFace.structure?.condition
      ?.disallowedHolesOrNotchesInStructuralMembers
      ? 'YES'
      : 'NO';
    this.structuralDecayOrFireDamage = roofFace.structure?.condition?.structuralDecayOrFireDamage ? 'YES' : 'NO';
    this.evidenceOfLeaksDamageOrDeterioration = roofFace.structure?.condition?.evidenceOfLeaksDamageOrDeterioration
      ? 'YES'
      : 'NO';
  }

  @action.bound
  setMaxHorizontalSpan(value: ReactText): void {
    this.maxHorizontalSpan = Number(value) > 0 ? Number(value) : undefined;
  }

  @action.bound
  setSag(value: ReactText): void {
    this.sag = Number(value);
  }

  @action.bound
  setRidgeBeamSag(value: ReactText): void {
    this.ridgeBeamSag = Number(value);
  }

  @action.bound
  setDisallowedHolesOrNotchesInStructuralMembers(option: Option): void {
    this.disallowedHolesOrNotchesInStructuralMembers = String(option.value);
  }

  @action.bound
  setStructuralDecayOrFireDamage(option: Option): void {
    this.structuralDecayOrFireDamage = String(option.value);
  }

  @action.bound
  setEvidenceOfLeaksDamageOrDeterioration(option: Option): void {
    this.evidenceOfLeaksDamageOrDeterioration = String(option.value);
  }

  override saveProperty(): void {
    const {
      domain, roofFaceProps
    } = this;

    const roofConditionValue: RoofCondition = {
      ridgeBeamSag: this.ridgeBeamSag
    };
    roofConditionValue.disallowedHolesOrNotchesInStructuralMembers =
      this.disallowedHolesOrNotchesInStructuralMembers === 'YES';
    roofConditionValue.structuralDecayOrFireDamage = this.structuralDecayOrFireDamage === 'YES';
    roofConditionValue.evidenceOfLeaksDamageOrDeterioration = this.evidenceOfLeaksDamageOrDeterioration === 'YES';
    const dependenciesRoofCondition: IUpdateRoofFacePropertyDependencies = {
      domain,
      roofFace: roofFaceProps.currentSelectedRoofFaces[0],
      key: UpdateRoofFacePropertyKey.Condition,
      value: roofConditionValue
    };
    this.serviceBus.send('update_roof_face_property', dependenciesRoofCondition);

    const roofSlopingStructuralMembersValue: RoofSlopingStructuralMembers = {
      maxHorizontalSpan: this.maxHorizontalSpan,
      sag: this.sag
    };
    const dependenciesRoofSlopingStructuralMembers: IUpdateRoofFacePropertyDependencies = {
      domain,
      roofFace: roofFaceProps.currentSelectedRoofFaces[0],
      key: UpdateRoofFacePropertyKey.SlopingStructuralMembers,
      value: roofSlopingStructuralMembersValue
    };
    this.serviceBus.send('update_roof_face_property', dependenciesRoofSlopingStructuralMembers);

    roofFaceProps.reset();
    this.roofFaceProps.lastInteractedRoofFace = this.roofFace;
  }
}
