import styled, {
  CSSRule,
  PropsTheme
} from 'styled-components';
import { Color } from '../../typings';
import { Paragraph, H1 } from '../FontFamily/Fonts';
import { Flexbox } from '../Layout';

function getDividerColor(color: 'white' | 'gray' = 'white'): string {
  return color === 'white' ? 'whiteBg' : 'grey4';
}

type BackgroundProps = PropsTheme & {
  show?: boolean;
};

type ContainerProps = PropsTheme & {
  fullHeight?: boolean;
  fullWidth?: boolean;
};

type ContentProps = PropsTheme & {
  showHeader?: boolean;
};

type DividerProps = PropsTheme & {
  direction: 'vertical' | 'horizontal';
  thickness?: string;
  length: string;
  color?: 'white' | 'gray';
};

type ColorBoxProps = PropsTheme & {
  color: string;
};

export const Background = styled.div<BackgroundProps>`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(0.5, 0.5, 0.5, 0.3);
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.modal}
  visibility: ${({ show }: BackgroundProps): string => (!show ? 'hidden' : 'visible')};
  animation: ${({ show }: BackgroundProps): string => !show ? 'fadeOut' : 'fadeIn'} 0.2s linear;
  transition: visibility 0.2s linear;
`;

export const Container = styled.div`
  max-width: 85%;
  max-height: 80%;
  ${({ fullHeight }: ContainerProps): string => (fullHeight ? 'height: 100%;' : '')};
  ${({ fullWidth }: ContainerProps): string => (fullWidth ? 'width: 100%;' : '')};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px 10px 15px;
  border: solid 10px ${({ theme }: PropsTheme): Color => theme.colors.yellowColor1};
  background: ${({ theme }: PropsTheme): Color => theme.colors.secondary};
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.modal}
`;

export const Content = styled.div`
  width: 973px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: ${({ showHeader = true }: ContentProps): string =>
    showHeader ? '20px' : '0px'};
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
`;

export const HeaderStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 153px;
  border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  & > span {
    font-size: 14px;
    color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  }
`;

export const HeaderTitle = styled.div`
  width: 524px;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.42;
  text-align: center;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const Separator = styled.div`
  flex: 1;
`;

export const Divider = styled.div<DividerProps>`
  height: ${({ direction, thickness = '1px', length }: DividerProps): string =>
    direction === 'vertical' ? length : thickness};
  width: ${({ direction, thickness = '1px', length }: DividerProps): string =>
    direction === 'horizontal' ? length : thickness};
  background: ${({ theme, color = 'white' }: DividerProps): Color =>
    theme.colors[getDividerColor(color)]};
`;

export const FooterStyle = styled.div``;

export const Card = styled.div`
  width: 100%;
  min-height: 394px;
`;

export const FlexView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MissingPropertiesContainer = styled.div`
  padding: 10px;
`;

export const UpperView = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleText = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  font-weight: bold;
  margin-left: 6px;
`;

export const CenterView = styled.div`
  min-height: 100px;
  margin-top: 20px;
`;

export const PropertyText = styled(Paragraph)`
  font-size: 12px;
  line-height: 1.83;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const BottomView = styled.div`
`;

export const PropertyListContainer = styled.div`
`;

export const ButtonListContainer = styled.div`
  padding: 10px;
`;

export const PropertyHeader = styled.div`
  display: flex;
`;

export const ColorBox = styled.div<ColorBoxProps>`
  width: 13px;
  height: 13px;
  background-color: ${({ color }: ColorBoxProps): Color => color};
`;

export const PropertyHeaderText = styled(Paragraph)`
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  margin-left: 8px;
`;

export const ButtonGroupWrapper = styled.div`
  margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 13px;
`;

export const PropertyGroupContainer = styled.div`
  padding: 10px;
`;

export const GroupWrapper = styled.div`
  margin-top: 30px;
`;

export const GroupTitle = styled(Paragraph)`
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const PropertyWrapper = styled.div`
  margin-top: 30px;
`;

export const ListWrapper = styled.div`
  margin-top: 30px;
`;

export const WarningCloseButtonHeader = styled(Flexbox).attrs({
  justify: 'right',
  align: 'right',
  dir: 'column',
  stretch: 'auto'
})`
  text-align: right;
`;

export const WarningModalHeader = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center',
  dir: 'column',
  stretch: 'auto'
})`
  padding: 36px 173px 36px 173px;
  text-align: center;
`;

export const WarningModalHeaderText = styled(H1)`
  font-size: 30px;
  font-weight: 300;
  line-height: 1.13;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const WarningContentWrapper = styled.div`
  width: 100%;
  height: 456px;
  overflow-y: hidden;
  border-top: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
`;

export const ContentWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 90%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
