import {
  action, computed, observable
} from 'mobx';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../Modal/BaseViewModel';
import type { ModalStore } from '../../Modal/Modal';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateWindExposureValues } from '../../../ServiceBus/Commands/UpdateWindExposureValuesCommand';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../domain/behaviour/KeyboardBehaviour';

interface IWindExposureViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly serviceBus: ServiceBus;
  readonly editor: EditorStore;
}

export class WindExposureViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI: string = 'wind_exposure_modal';
  override readonly editor: EditorStore;

  @observable
  selectedWindExposureCategory: string = '';

  private readonly serviceBus: ServiceBus;

  constructor(dependencies: IWindExposureViewModelDependencies) {
    super(dependencies);
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;

    this.selectedWindExposureCategory = this.domain.project.designParameters.terrain.windExposureCategory ?? '';
    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @computed
  get canSave(): boolean {
    return !!this.selectedWindExposureCategory;
  }

  @action.bound
  saveWindExposureCategory(): void {
    const commandDependencies: IUpdateWindExposureValues = {
      domain: this.domain,
      windExposureCategory: this.selectedWindExposureCategory
    };
    this.serviceBus.send('update_windExposure_values', commandDependencies);
    this.closeModal();
  }

  @action.bound
  handleOptionChange(value: string | number): void {
    this.selectedWindExposureCategory = value.toString();
  }

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_ENTER) {
      this.saveWindExposureCategory();
      this.dispose();
    }
  };
}
