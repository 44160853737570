import type { Building } from '../../../domain/models/SiteDesign/Building';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IAddOrUpdateBuildingDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
  building: Building;
}

export class AddOrUpdateBuildingCommand extends BaseCommand {
  private domain: DomainStore;
  private building: Building;

  constructor(dependencies: IAddOrUpdateBuildingDependencies) {
    super();
    this.domain = dependencies.domain;
    this.building = dependencies.building;
  }

  async execute(): Promise<void> {
    this.domain.addOrUpdateBuilding(this.building);
  }
}
