/* eslint-disable no-console */
import { toJS } from 'mobx';

/**
 * @description console.log with args mapped with mobx toJS and collapsed trace
 */
export const stackTracedLog = (...args: unknown[]): void => {
  console.groupCollapsed(...args.map((item: unknown): unknown => toJS(item)));
  console.trace();
  console.groupEnd();
};
