import type {
  BufferGeometry, Intersection, Material, Object3D, Raycaster, Vector3
} from 'three';
import {
  MathUtils, Mesh
} from 'three';
import defer from 'lodash/defer';
import type {
  Near, Raynear
} from '../../infrastructure/services/Raynear';
import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Color } from '../typings';
import { ViewPort } from '../../stores/EditorStore/ViewportController';

export interface IDrawable {
  position?: Vector3;
  geometry?: BufferGeometry;
  material?: Material | Material[];
  type?: string;
  color: Color;
}

export const Drawable = <T extends AnyConstructor>(DrawableBase: T) => {
  abstract class MixinClass extends DrawableBase {
    isNewDrawable: boolean = true;
    isDrawable: boolean = true;
    color?: Color;
    vertexColor?: Color;
    type?: string;
    name?: string;
    mesh = new Mesh();
    /**
     * Item ID in our domain model. This gets persisted on the backend.
     * The default value (random UUID) may be rewritten when backend data is
     * mapped to an object inheriting this mixin.
     */
    serverId: string = MathUtils.generateUUID();

    raycast?: (raycaster: Raycaster, intersects: Intersection[]) => void;

    constructor(...rest: any[]) {
      super(...rest);
      this.mesh.userData.lyraModel = this;
      // Descendants of this class may have `raycast` property, but it's not
      // available in this constructor, hence the defer.
      defer((): void => {
        if (this.raycast) {
          this.mesh.raycast = this.raycast;
        }
      });
    }

    get visible(): boolean {
      return this.mesh.visible;
    }
    set visible(newValue: boolean) {
      this.mesh.visible = newValue;
    }

    get mapZoomFactor(): number {
      return ViewPort.mapZoomFactor;
    }

    abstract redraw(): void;

    raynear(raynear: Raynear, objects: Near[]): Near[] {
      return objects;
    }

    clearChildren(parent: Object3D | Mesh): void {
      parent.children.forEach((child: Object3D | Mesh, index: number): void => {
        parent.remove(child);
      });

      parent.children = [];
    }

    removeFromScene(): void {
      this.mesh.parent?.remove(this.mesh);
    }
  }

  return MixinClass;
};

export type Drawable = Mixin<typeof Drawable>;
