import type Store from '../../../../../stores/Store';
import { RemovePVModuleTool } from '../../../../../stores/UiStore/ToolbarStore/Design/RemovePVModuleTool';
import type { IDeleteModuleToolDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/RemovePVModuleTool';
import withTool from '../../Shared/withTool';

type DeleteModuleToolDependencies = Pick<
  IDeleteModuleToolDependencies,
  'properties' | 'domain' | 'serviceBus' | 'workspace'
>;

const RemoveModule = withTool<RemovePVModuleTool, IDeleteModuleToolDependencies, DeleteModuleToolDependencies>(
  RemovePVModuleTool,
  ({
    properties, domain, serviceBus, workspace
  }: Store): DeleteModuleToolDependencies => ({
    properties,
    domain,
    serviceBus,
    workspace
  })
);

export default RemoveModule;
