import type DomainStore from '../../../DomainStore/DomainStore';
import type { DesignWorkspace } from '../../WorkspaceStore/workspaces/DesignWorkspace';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { PanelsStore } from '../Panels';

export interface IBasePanelViewModelDependencies {
  panel: PanelsStore;
  domain: DomainStore;
  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;
  editor: EditorStore;
}

export abstract class BasePanelViewModel {
  abstract propCodeUI: string;
  protected panel: PanelsStore;
  protected domain: DomainStore;
  protected designWorkspace: DesignWorkspace;
  protected serviceBus: ServiceBus;

  constructor(dependencies: IBasePanelViewModelDependencies) {
    this.panel = dependencies.panel;
    this.domain = dependencies.domain;
    this.designWorkspace = dependencies.designWorkspace;
    this.serviceBus = dependencies.serviceBus;
  }

  clearPanel(): void {
    this.panel.destroyPanel();
  }
}
