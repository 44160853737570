import {
  LyraGrid, LyraModal
} from '@aurorasolar/lyra-ui-kit';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import type { GroundSnowViewModel } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/GroundSnowViewModel';
import useStore from '../../../../stores/useStore';

const SiteGroundSnowModal = (): ReactElement => {
  const { modal } = useStore();
  const [viewModel] = useState(modal.viewModel as GroundSnowViewModel);
  return useObserver(
    (): ReactElement => (
      <LyraModal.Modal
        $show={true}
        title="Edit Ground Snow Load"
        color={EThemeComponentColor.PURPLE}
        onClickLeftButton={viewModel.closeModal}
        onClickCloseButton={viewModel.closeModal}
        onClickRightButton={viewModel.saveSiteGroundSnowData}
        rightButtonDisabled={!viewModel.isValidGroundSnowLoadValue}
      >
        <Flexbox
          dir="column"
          style={{
            width: 400
          }}
          justify="center"
          align="center"
        >
          <LyraGrid.Grid
            options={viewModel.options}
            hasCustomValues={true}
            customValueInputAttrs={{
              autofocus: true,
              type: 'number'
            }}
            selected={viewModel.selectedOption}
            columns={3}
            gutter={1}
            selectedOptionColor={EThemeComponentColor.PURPLE}
            onSelect={viewModel.setSelectedOption}
          />
        </Flexbox>
      </LyraModal.Modal>
    )
  );
};

export default observer(SiteGroundSnowModal);
