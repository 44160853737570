import {
  observable, action
} from 'mobx';
export interface IFireSafetyPlanData {
  readonly include: boolean;
  readonly showGasMeterClearanceRequirements: boolean;
}

export default class FireSafetyPlan {
  @observable
  include: boolean;

  @observable
  showGasMeterClearanceRequirements: boolean;

  constructor(data: IFireSafetyPlanData) {
    this.include = data.include;
    this.showGasMeterClearanceRequirements = data.showGasMeterClearanceRequirements;
  }

  @action.bound
  setInclude(value: boolean): void {
    this.include = value;
  }

  @action.bound
  setShowGasMeterClearanceRequirements(value: boolean): void {
    this.showGasMeterClearanceRequirements = value;
  }

  toData(): IFireSafetyPlanData {
    return {
      include: this.include,
      showGasMeterClearanceRequirements: this.showGasMeterClearanceRequirements
    };
  }
}
