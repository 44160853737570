import type { Dictionary } from '../../../domain/typings';
import type { BasePanelViewModel } from './ViewModels/BasePanelViewModel';
import { EquipmentPanelViewModel } from './ViewModels/EquipmentPanel/EquipmentPanelViewModel';
import { MpptAndMultiInverterSelectionPanelViewModel } from './ViewModels/MPPTAndMultiInverterSelectionPanel/MPPTAndMultiInverterSelectionPanelViewModel';
import { SystemSummaryPanelViewModel } from './ViewModels/SystemSummary/SystemSummaryPanelViewModel';

type PanelViewModelConstructor<A = BasePanelViewModel> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (deps: any) => A;

export const registryPanels: Dictionary<PanelViewModelConstructor> = {
  equipment_panel: EquipmentPanelViewModel,
  system_summary_panel: SystemSummaryPanelViewModel,
  MPPT_and_multi_inverter_selection_panel: MpptAndMultiInverterSelectionPanelViewModel
};
