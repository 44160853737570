import type {
  PropsTheme, CSSProp
} from 'styled-components';
import styled from 'styled-components';

type ImageProps = PropsTheme & {
  width: string;
  height: string;
  clickable: boolean;
};

export const RoundThumbnailRoot = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RoundImage = styled.div<ImageProps>`
  align-content: center;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  font-weight: lighter;
  justify-content: center;
  margin: 15px 0 5px;
  position: relative;
  height: ${({ height }: ImageProps): string => (height ? height : '80px')};
  width: ${({ width }: ImageProps): string => (width ? width : '80px')};
  ${(props: ImageProps): CSSProp | undefined => (props.clickable ? 'cursor: pointer;' : undefined)}
`;

export const ImageStyle = styled.img`
  width: auto;
  height: 100%;
`;

export const Caption = styled.div`
  color: white;
  text-align: center;
`;
