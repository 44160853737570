import styled from 'styled-components';
import {
  LyraFontFamily, type PropsTheme, type Color
} from '@aurorasolar/lyra-ui-kit';
import { ScrollablePanelContainer } from '../PropertyPanel/styles';
import Flexbox from '../../../ui/components/Layout/Flexbox';

const {
  Notes, Paragraph, H4
} = LyraFontFamily.FontFamily;

type Props = {
  overflow?: string;
};

export const Container = styled.div<{
  whiteBackground?: boolean;
}>`
  ${({ whiteBackground }): string => (whiteBackground ? 'background: white' : '')};
  padding: 15px;
  height: inherit;
  min-width: calc(100% - 30px);
`;

export const MeterMainInputContainer = styled.div`
  margin-bottom: 15px;
`;

export const ScrollablePanelContainerMSP = styled(ScrollablePanelContainer)``;

export const ServiceEntranceEquipmentOption = styled.div<PropsTheme & {
  selected: boolean;
}>`
  border: 2px solid ${({ theme }: PropsTheme): Color => theme!.colors.grey4};
  border-radius: 4px;
  padding: 8px;
  margin: 4px 0;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  background-color: ${({ theme }: PropsTheme): Color => `${theme!.colors.whiteBg}`};
  ${({ selected }): string =>
    selected
      ? `
    border: 2px solid #685FCB;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px;
  `
      : ''}
  &:hover {
    border: 2px solid #685fcb;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px;
  }
`;
export const ServiceEntranceEquipmentSubTitle = styled(Paragraph)`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 11px;
  @media (min-width: 2560px) {
    font-size: 12px;
  }
`;
export const ServiceEntranceEquipmentOptionTitle = styled(H4)`
  font-size: 15px;
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 8px;
  max-width: calc(100% - 2px);
`;
export const ServiceEntranceEquipmentOptionDescription = styled(Notes)`
  font-size: 11px;
  color: #8f8f8f;
  max-width: calc(100% - 2px);
`;

export const EditButtonWrapper = styled.button<PropsTheme>`
  width: 46px;
  height: 22px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }: PropsTheme): Color => `${theme!.colors.whiteBg}`};
  cursor: pointer;
  flex: none;
`;

export const ServiceEntranceEquipmentHeaderWrapper = styled.div`
  background-image: linear-gradient(rgb(242, 242, 242) 0%, rgb(217, 217, 217) 100%);
`;
export const ServiceEntranceEquipmentHeaderContainer = styled.div<PropsTheme>`
  margin: 12px;
  padding: 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  background-color: white;
`;

export const ServiceEntranceEquipmentHeading = styled(Flexbox)`
  gap: 4px;
  margin-bottom: 10px;
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ServiceEntranceEquipmentHeader = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export const ServiceEntranceEquipmentDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const ServiceEntranceEquipmentConfigurationContainer = styled.div<Props>`
  padding: 15px;
  overflow: hidden ${({ overflow }: Props): string => overflow ?? 'auto'};
  border: 1px solid white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const MainBreakerProperties = styled.div`
  margin-top: 15px;
`;
