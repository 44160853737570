import {
  LyraFormElementsDesignTool, LyraTypography, LyraIcon
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, FC
} from 'react';
import React, {
  useState, useCallback
} from 'react';
import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import useStore from '../../../../../stores/useStore';
import type { UpsertInstallerAndCreateProjectViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import Icons from '../../../../components/Icons/index';
import Field from '../../../../components/Field';
import { statesDropdownList } from '../../../../../constants/statesList';
import type { ILicenseData } from '../../../../../domain/models/Installer';
import {
  CenteredTitle,
  CreateInstallerStep1Wrapper,
  CreateProjectFieldRowWrapper,
  HelpText,
  LicenseClassWrapper,
  LicenseNumberWrapper,
  LicenseStateWrapper,
  LicenseWrapper,
  LinkText,
  RemoveWrapper,
  SetupAccountLogo
} from './../styles';

export const NameAndLicensesStep: FC = observer((): ReactElement => {
  // Setting stores used in the component
  const { modal } = useStore();

  // Setting view model used by the component
  const [viewModel] = useState<UpsertInstallerAndCreateProjectViewModel>(
    modal.viewModel as UpsertInstallerAndCreateProjectViewModel
  );

  const {
    startupMode, companyName, licenses
  } = viewModel;

  const getStateDropdownOption = useCallback((value: string): IDropDownOption => {
    return statesDropdownList.find((item: IDropDownOption): boolean => item.value === value)!;
  }, []);

  const licenseComponents: ReactElement[] = licenses.map(
    (license: ILicenseData, index: number): ReactElement => (
      <CreateProjectFieldRowWrapper key={`license-${index}`}>
        <LicenseWrapper>
          <LicenseNumberWrapper>
            <Field className="mr-xxs" label="CONTRACTOR LICENSE NUMBER">
              <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                onChange={(value: string): void => viewModel.setLicenseNumber(index, value)}
                value={license.number}
                placeholder="e.g. 1234567"
              />
            </Field>
          </LicenseNumberWrapper>
          <LicenseClassWrapper>
            <Field className="mr-xxs" label="CLASS">
              <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                onChange={(value: string): void => viewModel.setLicenseClass(index, value)}
                value={license.classification}
                placeholder="e.g. C-46"
              />
            </Field>
          </LicenseClassWrapper>
          <LicenseStateWrapper
            style={{
              minWidth: '150px'
            }}
          >
            <Field className="mr-xxs" label="STATE">
              <LyraFormElementsDesignTool.FormElementsDesignTool.DropDownNew.DropDownNew
                options={statesDropdownList}
                value={getStateDropdownOption(license.issuingAuthority)}
                menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
                onChange={(newValue: unknown): void => {
                  viewModel.setLicenseState(index, (newValue as IDropDownOption).value);
                }}
              />
            </Field>
          </LicenseStateWrapper>
          {licenses.length > 1 && (
            <RemoveWrapper onClick={(): void => viewModel.removeLicense(index)}>
              <LyraIcon.Icon name="icon-delete" />
            </RemoveWrapper>
          )}
        </LicenseWrapper>
      </CreateProjectFieldRowWrapper>
    )
  );

  return (
    <CreateInstallerStep1Wrapper>
      {startupMode && (
        <>
          <SetupAccountLogo>
            <Icons name="pencil" />
          </SetupAccountLogo>
          <CenteredTitle>
            <LyraTypography.Heading type="h2" fontWeight="300">
              Set Up Your Account
            </LyraTypography.Heading>
          </CenteredTitle>
          <HelpText>
            To begin, enter your company information below. Add license numbers for each state in which you operate so
            they appear on the permit package. Information can be edited or added later.
          </HelpText>
        </>
      )}
      <CreateProjectFieldRowWrapper>
        <Field className="mr-xxs" label="COMPANY NAME">
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            onChange={viewModel.setCompanyName}
            value={companyName}
            placeholder={'e.g. My Solar Company'}
          />
        </Field>
      </CreateProjectFieldRowWrapper>
      {licenseComponents}
      <LinkText onClick={viewModel.addLicense}>Add another license</LinkText>
    </CreateInstallerStep1Wrapper>
  );
});
