import { createGlobalStyle, css, PropsTheme, RuleSet } from 'styled-components';
import { Color } from '../../typings';
import { inheritMinMaxHeight } from './mixins';
import { resetStyles, resetStylesLyraOnly } from './resetStyles';
import { spaces } from './spacing';
import { Tooltip } from './Tooltip';
import FlexboxGrid2Styles from './FlexboxGrid2Styles';
import { ToastifyRootStyles, ToastifyScopedStyles } from '../Toast/react-toastify-global-styles';

const roobertFontStyle = css`
  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 300;
    font-style: normal;
    src: url(${require('./assets/roobert-font/Roobert-Light.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-Light.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 300;
    font-style: italic;
    src: url(${require('./assets/roobert-font/Roobert-LightItalic.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-LightItalic.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 400;
    font-style: normal;
    src: url(${require('./assets/roobert-font/Roobert-Regular.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-Regular.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 400;
    font-style: italic;
    src: url(${require('./assets/roobert-font/Roobert-RegularItalic.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-RegularItalic.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 500;
    font-style: normal;
    src: url(${require('./assets/roobert-font/Roobert-Medium.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-Medium.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 600;
    font-style: normal;
    src: url(${require('./assets/roobert-font/Roobert-SemiBold.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-SemiBold.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Roobert;
    font-weight: 700;
    font-style: normal;
    src: url(${require('./assets/roobert-font/Roobert-Bold.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/Roobert-Bold.woff').default}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Roobert Mono';
    font-weight: 400;
    font-style: normal;
    src: url(${require('./assets/roobert-font/RoobertMono.woff2').default}) format('woff2'), url(${require('./assets/roobert-font/RoobertMono.woff').default}) format('woff');
  }
`;

const GlobalStyles = createGlobalStyle<PropsTheme & { auroraMode: boolean }>`

  // Animations:
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .lyra {
    line-height: 1;
    ${inheritMinMaxHeight};
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};

    ${resetStyles}
    ${FlexboxGrid2Styles}
    ${ToastifyScopedStyles}
    ${({ auroraMode }: { auroraMode: boolean }): RuleSet<object> | string => (auroraMode ? roobertFontStyle : `
      * {
        font-family: Roboto, sans-serif;
      }
      ${resetStylesLyraOnly}
    `)}
    ${Tooltip}
    ${spaces}
  }
  ${ToastifyRootStyles}
`;

export { GlobalStyles };
