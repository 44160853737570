import type Store from '../../../../../stores/Store';
import { SetBackTool } from '../../../../../stores/UiStore/ToolbarStore/Design/SetBackTool';
import type { IBaseSetBackToolDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/SetBackTool';
import withTool from '../../Shared/withTool';

type SetBackToolDependencies = Pick<IBaseSetBackToolDependencies, 'workspace'>;

const SetBack = withTool<SetBackTool, IBaseSetBackToolDependencies, SetBackToolDependencies>(
  SetBackTool,
  ({ workspace }: Store): SetBackToolDependencies => ({
    workspace
  })
);

export default SetBack;
