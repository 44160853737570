import type {
  Object3D, Vector3
} from 'three';

import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { SceneObjectType } from '../Constants';
import { LengthLabelable } from '../../mixins/LengthLabelable';
import { Deletable } from '../../mixins/Deletable';
import { Draggable } from '../../mixins/Draggable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { Drawable } from '../../mixins/Drawable';
import type { RoofFace } from './RoofFace';
import {
  isBuildingPositionValid,
  moveBuildingToANewPosition,
  moveEquipmentWithBuilding,
  revertMoveBuildingToANewPosition
} from './functions/DraggableBuildingHelpers';
import type { Building } from './Building';

const MixedClass = LengthLabelable(Deletable(Draggable(PolygonDrawable(Selectable(Drawable(class SimpleClass {}))))));
/**
 *
 * @export
 */
export class Outline extends MixedClass {
  override name!: string;
  isMultipleVertices: boolean = true;
  /**
   * Property id used to select right panel component
   */
  propertyId: string = SceneObjectType.Outline;
  selectWithParent: boolean = false;

  /** Layer Number */
  layerNumber: number = 1.5;
  private movementInProgress: boolean = false;

  constructor() {
    super();
    this.type = SceneObjectType.Outline;
  }

  get building(): Building | undefined {
    return getRootStore().domain.buildings.find(
      (building: Building): boolean => !!(building.roofOutline && building.roofOutline.serverId === this.serverId)
    );
  }

  override move(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (this.movementInProgress) {
      return;
    }

    this.movementInProgress = true;
    try {
      const building = this.building;
      if (!building || building.roofFaces.some((roofFace: RoofFace): boolean => roofFace.verticesUpdateIsInProgress)) {
        // Failsafe. Not an expected behaviour.
        return;
      }

      const deltaVector = this.boundary.vertices[0].getVector3().clone()
        .sub(newPosition[0]);
      const capturedEquipment = moveBuildingToANewPosition(deltaVector, building, editor);

      if (!isBuildingPositionValid(editor, building)) {
        revertMoveBuildingToANewPosition(deltaVector, building);
      } else {
        moveEquipmentWithBuilding(deltaVector, capturedEquipment);
      }
    } finally {
      this.movementInProgress = false;
    }
  }

  /**
   * @returns is valid
   */
  override afterMove(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    // do nothing
  }

  override resetVertex(vector: Vector3, index: number): void {
    // do nothing
  }

  showVertices(): boolean {
    return !this.boundary.closed || this.selected;
  }
  showLines(): boolean {
    return true;
  }
  dragVertices(): boolean {
    return true;
  }
  hasFill(): boolean {
    return true;
  }
  showFill(): boolean {
    return false;
  }
  onClose(): void {
    // Not implemented
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }
}
