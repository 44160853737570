import type { Vector3 } from 'three';
import {
  BufferAttribute, BufferGeometry, ShapeUtils, Vector2
} from 'three';

const DEFAULT_POINTS = 200;
// This geometry works well and fast because first we are allocating
// the number of points that will be used, and then,
// the only way to update is modifying the positions attribute
// and triangulating to get the faces.
// For more information, read about the correct way to update things in threejs
// https://threejs.org/docs/#manual/en/introduction/How-to-update-things
export class PolygonBufferGeometry extends BufferGeometry {
  constructor(maxPoints?: number) {
    super();
    const points = maxPoints ? maxPoints : DEFAULT_POINTS;
    const positions = new Float32Array(points * 3);
    this.setAttribute('position', new BufferAttribute(positions, 3));
  }

  update(polygon: Vector3[]): void {
    const indices: number[] = [];
    const shape2D: Vector2[] = [];
    const positionAttr = this.attributes.position as BufferAttribute;
    polygon.forEach((v: Vector3, index: number): void => {
      shape2D.push(new Vector2(v.x, v.y));
      if (index !== polygon.length - 1) {
        positionAttr.setXYZ(index, v.x, v.y, v.z);
      }
    });

    // Using ShapeUtils in order to get the faces giving a path
    const faces = ShapeUtils.triangulateShape(shape2D, []);

    for (let i = 0, l = faces.length; i < l; i++) {
      const face = faces[i];

      const a = face[0];
      const b = face[1];
      const c = face[2];

      indices.push(a, b, c);
    }
    // Setting indices, in order to triangulate correctly and manually a polygon
    this.setIndex(indices);
    // Set attributes position needs update
    positionAttr.needsUpdate = true;

    this.computeBoundingSphere();
    // Computes vertex normals by averaging face normals.
    this.computeVertexNormals();
  }
}
