import {
  MathUtils, Vector2, Vector3
} from 'three';
import type { Vertex } from '../domain/graphics/Vertex';
import {
  DEFAULT_Z, TWO_PI
} from '../domain/models/Constants';
import type { Vector2D } from '../domain/typings';

/**
 * Transform coordinates between differents ranges
 * For instance, transform from top-left corner coordinate 0,0
 * to webgl coordinates
 *
 * @export
 * @param value - value is going to be transformed
 * @param srcMax - maximun value from the source coordinate
 * @param srcMin - minimun value from the source coordinate
 * @param dstMax - maximun value from the destiny coordinate
 * @param dstMin - minimun value from the destiny coordinate
 * @returns value translated
 */
function normalizeCoords(value: number, srcMax: number, srcMin: number, dstMax: number, dstMin: number): number {
  return ((value - srcMin) / (srcMax - srcMin)) * (dstMax - dstMin) + dstMin;
}

/**
 * Convert angle in radians to degrees
 *
 * @param angle in radians
 * @returns angle in degrees
 */
export function radiansToDegrees(radian: number): number {
  return (radian * 180) / Math.PI;
}

/**
 * Convert angle in degrees to radians
 *
 * @param angle in degrees
 * @returns angle in radians
 */
export function degreesToRadians(degree: number): number {
  return (degree * Math.PI) / 180;
}

/**
 * Get the angle from a cartisian vector
 * go from 0 to PI^2
 *
 * @param coord - vector position
 * @param offset - offset angle in radians if you want to flip the angles
 * @return angle based in the vector position, a scalar value
 */
export function getVectorAngle(
  coord: Vector2D,
  offset: number = 0 // in radians
): number {
  // angle without normalize from  PI to -PI
  const angle = Math.atan2(coord.y, coord.x) - offset;

  // convert the partial angle(PI to -PI)
  // to a full angle (0 to PI^2)
  return (angle + TWO_PI) % TWO_PI;
}

/* Get the cartesian coords from a provided angle(in radians)
 * Useful for circular movement and placement
 *
 * @param angle - angle in radians
 * @param radius - multipy factor for the final vector
 * @param offset - final vector offset
 * @returns Vector with the coordinates
 */
export function positionFromAngle(angle: number, radius: number = 1, offset: number = 0): Vector2D {
  return {
    x: Math.cos(angle) * radius + offset,
    y: Math.sin(angle) * radius + offset
  };
}

/**
 *  Getting the polygon area from its vertices
 *
 * @param vertices - polygon vertices
 * @return area value, if it's positive was counter clockwise
 *         other way, negative.
 *
 */
export function getAreaByVertices(vertices: Vertex[]): number {
  const length = vertices.length;
  let area = 0;
  let acc = 0;

  // Getting the polygon's area
  // http://mathworld.wolfram.com/PolygonArea.html
  for (let i = 0; i < length; i++) {
    // Current point
    const cpoint = vertices[i].mesh.position;
    // Next point, first one when the current is the last
    const npoint = vertices[(i + 1) % length].mesh.position;
    // accomulating the values for the area
    acc += cpoint.x * npoint.y - npoint.x * cpoint.y;
  }

  // final tweak for calculating the area
  area = acc / 2;

  return area;
}

/**
 *
 * Extend/Scale one line created between to vectors
 *
 * @export
 * @param v1 - first point of the line
 * @param v2 - last point of the line
 * @param amount - units to extend the line
 * @returns - returns the new two vectors/points for the line extended
 *
 */
export function extendLine(v1: Vector3, v2: Vector3, amount: number = 2000): Vector3[] {
  const v1ToV2 = new Vector3().subVectors(v2, v1)
    .normalize();

  const newPoint1 = new Vector3().copy(v1)
    .addScaledVector(v1ToV2, -amount / 2);
  const newPoint2 = new Vector3().copy(v2)
    .addScaledVector(v1ToV2, amount / 2);

  return [newPoint1, newPoint2];
}

/**
 *
 * Calculate if a point is in the line, taking into account an offset (optional)
 *
 * @export
 * @param pointA Point A of a line
 * @param pointB Point B of a line
 * @param pointToCheck Point to be checked
 * @param [offset=0] Optional offset (threshold)
 * @returns True if is in the line, otherwise false
 */
export function isPointOnLine(
  pointA: Vector2 | Vector3,
  pointB: Vector2 | Vector3,
  pointToCheck: Vector2 | Vector3,
  offset: number = 10
): boolean {
  const distance = distanceBetweenPointAndLineSegment(pointToCheck, pointA, pointB);

  return distance < offset;
}

/**
 * Returns the distance between a point and a linesegment in 2d
 * Note that z values are completely ignored
 * @param p point
 * @param a one end of the linesegment
 * @param b other end of the linesegment
 */
export function distanceBetweenPointAndLineSegment(
  p: Vector2 | Vector3,
  a: Vector2 | Vector3,
  b: Vector2 | Vector3
): number {
  const pAsVec2 = new Vector2(p.x, p.y);
  const aAsVec2 = new Vector2(a.x, a.y);
  const bAsVec2 = new Vector2(b.x, b.y);
  const pa = new Vector2().subVectors(pAsVec2, aAsVec2);
  const ba = new Vector2().subVectors(bAsVec2, aAsVec2);

  const h = MathUtils.clamp(pa.dot(ba) / ba.dot(ba), 0, 1);

  return new Vector2().subVectors(pa, ba.multiplyScalar(h))
    .length();
}

/**
 * Get the angle created between two segments/lines
 * Be sure to take the difference between vectors that you want to calculate
 *
 * @param v1 - first segment/vector/line
 * @param v2 - second segment/vector/line
 * @return angle in radians from 0 to PI and -PI to 0
 */
export function angleBetweenSegments(v1: Vector3, v2: Vector3): number {
  const cross = v1.x * v2.y - v1.y * v2.x;
  const dot = v1.x * v2.x + v1.y * v2.y;
  return Math.atan2(cross, dot);
}

/**
 *
 * @param v1 - vector 1 to inspect
 * @param v2 - vector 2 to inspect
 */
function getAngleBetweenSegmentByDotProduct(v1: Vector3, v2: Vector3): number {
  // dot product
  const dotProduct: number = v1.dot(v2);

  // calculate angle
  const thetaAngle: number = dotProduct / (v1.length() * v2.length());

  return thetaAngle;
}

function projectPointIntoLine(v1: Vector3, v2: Vector3, point: Vector3): Vector3 {
  const vectorOne: Vector3 = new Vector3(v2.x - v1.x, v2.y - v1.y, DEFAULT_Z);
  const vectorTwo: Vector3 = new Vector3(point.x - v1.x, point.y - v1.y, DEFAULT_Z);

  const lengthVector: number = vectorTwo.length();

  const thetaAngle = getAngleBetweenSegmentByDotProduct(vectorOne, vectorTwo);
  const projLenOfLine: number = thetaAngle * lengthVector;

  const posX: number = v1.x + (projLenOfLine * vectorOne.x) / lengthVector;
  const posY: number = v1.y + (projLenOfLine * vectorOne.y) / lengthVector;

  return new Vector3(posX, posY, DEFAULT_Z);
}

const MILIMETERS_INCH = 25.4;
/**
 *
 * @param value value in milimeters
 * @returns Array with numerator and denominator, or null
 */
export function milimetersToImperialInches(value: number): number[] | null {
  let unit2: number;
  const result: number[] = [];
  if (value / MILIMETERS_INCH !== 0) {
    unit2 = value / MILIMETERS_INCH;
    const ft = Math.floor(unit2 / 12);
    const inch = unit2 - 12 * ft;
    let denominator = 64;
    let numerator = Math.round(denominator * (inch - Math.floor(inch)));
    const gcd = (a: number, b: number): number => {
      return b ? gcd(b, a % b) : a;
    };
    const gcd2 = gcd(numerator, denominator);
    numerator /= gcd2;
    denominator /= gcd2;
    result.push(numerator, denominator);
  } else {
    return null;
  }
  return result;
}

/**
 *
 * @param v1 is the first vector to line
 * @param v2 is the second vector to line
 * @param p is the point vector to validate
 */
export function isPointOnLineSegmentViaCrossProduct(v1: Vector3, v2: Vector3, p: Vector3, offset: number): boolean {
  if (!((v1.x <= p.x && p.x <= v2.x) || (v2.x <= p.x && p.x <= v1.x))) {
    // test point not in x-range
    return false;
  }
  if (!((v1.y <= p.y && p.y <= v2.y) || (v2.y <= p.y && p.y <= v1.y))) {
    // test point not in y-range
    return false;
  }

  return isPointOnLineviaPerDotProduct(v1, v2, p, offset);
}

const isPointOnLineviaPerDotProduct = (v1: Vector3, v2: Vector3, p: Vector3, offset: number): boolean => {
  return Math.abs(perpDotProduct(v1, v2, p)) < getEpsilon(v1, v2, offset);
};

const perpDotProduct = (a: Vector3, b: Vector3, c: Vector3): number => {
  return (a.x - c.x) * (b.y - c.y) - (a.y - c.y) * (b.x - c.x);
};

const getEpsilon = (v1: Vector3, v2: Vector3, offset: number): number => {
  const dx1 = v2.x - v1.x;
  const dy1 = v2.y - v1.y;
  const epsilon: number = offset * (dx1 * dx1 + dy1 * dy1);
  return epsilon;
};

export const EPSILON = 0.00001;
