import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteStringCommand extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    // Implemented in ElectricalDesignStage onCommandExecuted
    // and StringingService.deleteStringing
  }

  protected deleteFromDomain(): void {
    // Implemented in ElectricalDesignStage onCommandExecuted deleteCircuit
  }
}
