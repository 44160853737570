import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import {
  type IOutlineToolDependencies,
  OutlineTool
} from '../../../../../../stores/UiStore/ToolbarStore/Project/TracingTools/OutlineTool';
import withTool from '../../../Shared/withTool';

type ConstDep = Pick<
  IOutlineToolDependencies,
  'properties' | 'drawableObjectsFactory' | 'domain' | 'smartGuides' | 'wizard' | 'serviceBus' | 'currentWorkspace'
>;

export const Outline = withTool<OutlineTool, IOutlineToolDependencies, ConstDep>(
  OutlineTool,
  (store: Store): ConstDep => ({
    properties: store.properties,
    domain: store.domain,
    wizard: store.wizard,
    smartGuides: store.smartGuides,
    drawableObjectsFactory: new SiteStructureFactory(),
    serviceBus: store.serviceBus,
    currentWorkspace: store.workspace.currentWorkspace
  })
);
