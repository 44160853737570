import type { Color } from '../../../domain/typings';
import type { IBaseEnvProps } from '../../../domain/typings/IBaseEnvProps';

abstract class BaseEnvProps implements IBaseEnvProps {
  abstract name: string;
  abstract icon: string;
  abstract color: Color | string;
  abstract value: string;

  metaDetail: string = '';
}

export default BaseEnvProps;
