import styled, { CSSRule, PropsTheme, withTheme, } from 'styled-components';
import { Color } from '../../typings';

type HouseShapeProps = PropsTheme<{
  selected?: boolean;
}>;

type RoofButtonProps = {
  width: string;
};

function putStylesOnSelect({theme, selected}: HouseShapeProps): string {
  return  selected ?
  `border: 2px solid ${theme.colors.grey2};
   box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);`
  : '';
}

export const HouseShapeWrapper = styled.div<HouseShapeProps>`
  ${({ theme }: PropsTheme): CSSRule => theme.mixins.wrapperFill}
  ${
    ({theme, selected}: HouseShapeProps): string =>
    putStylesOnSelect({theme, selected})
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 58px;
  justify-content: flex-end;
  padding: 8px;
  &:hover {
    border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.grey2};
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  }
`;

export const RoofButtonStyle = styled.div<RoofButtonProps>`
  cursor: pointer;
  width: ${({width}: RoofButtonProps): string => width};
  & > p {
    font-size: 10px;
    margin-top: 7px;
    text-align: center;
  }
`;
