import {
  LyraFormElementsDesignTool, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { NewSystemSizeStage } from '../../../../domain/stages/CreationDesignStages/NewSystemSizeStage';
import { useUiStore } from '../../../../stores/useStore';
import Section from '../../../../ui/components/Section';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';
import { ValueBox } from './styles';

const NewSystemSizeModal = (props: BaseProps): ReactElement => {
  const { workspace } = useUiStore();
  const { wizard } = workspace.currentWorkspace;

  const viewModel = wizard?.currentStage as NewSystemSizeStage;

  const cancelModal = (): void => {
    wizard?.clear();
    if (viewModel.isDesignCreation) {
      workspace.changeWorkspace(Workspace.Project);
    }
  };

  return (
    <LyraModal.Modal
      title={viewModel.isDesignCreation ? 'Roof Top System' : ''}
      $show={true}
      height="365px"
      width="500px"
      currentStep={wizard?.current}
      steps={viewModel.isDesignCreation ? wizard?.steps : undefined}
      leftButtonLabel={viewModel.isDesignCreation ? 'Back' : 'Cancel'}
      rightButtonLabel={viewModel.isDesignCreation ? 'Next' : 'Save'}
      rightButtonDisabled={!wizard?.currentStage?.canContinue}
      onClickLeftButton={cancelModal}
      onClickRightButton={(): void => {
        wizard?.next();
      }}
      onClickCloseButton={cancelModal}
    >
      <Section>
        <LyraTypography.TextLayout type="h2" margin={'40px 0 40px'} lineHeight={1.5} align="center">
          {viewModel.isDesignCreation ? 'Specify System Size' : 'Update System Size'}
        </LyraTypography.TextLayout>
      </Section>
      <ValueBox className="systemSizeInputWrapper">
        <LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField
          data-testId="systemSizeInput"
          width="88px"
          height="38px"
          onChange={viewModel.onSystemSizeChange}
          onEnterKeyDown={(): void => {
            if (wizard?.currentStage?.canContinue) {
              wizard?.next();
            }
          }}
          value={viewModel.dcPowerRatingTargetInKw || undefined}
          withQuantityArrows={false}
          limits={viewModel.limits}
          autoFocus={true}
          allowOutOfRange={true}
          fractionDigits={2}
        />
        <LyraTypography.TextLayout type="h3" margin="0 10px" color={EThemeComponentColor.GRAY_700}>
          kW DC
        </LyraTypography.TextLayout>
      </ValueBox>
    </LyraModal.Modal>
  );
};

export default observer(NewSystemSizeModal);
