import type { IRoofFaceData } from '../../domain/entities/SiteDesign/RoofFace';
import type { IRoofSectionData } from '../../domain/entities/SiteDesign/RoofSection';
import type { IRoofStoryData } from '../../domain/entities/SiteDesign/RoofStory';
import type { RoofFace as RoofFaceDto } from '../../domain/models/SiteDesign/RoofFace';
import { RoofStory as RoofStoryDto } from '../../domain/models/SiteDesign/RoofStory';
import type { IMapping } from './IMapping';
import { RoofFaceMapping } from './RoofFaceMapping';

export class RoofStoryMapping implements IMapping<IRoofStoryData, RoofStoryDto> {
  private readonly roofFaceMapper = new RoofFaceMapping();

  convertEntityToDomainModel(entity: IRoofStoryData): RoofStoryDto {
    const domainModel = new RoofStoryDto();

    domainModel.level = entity.level;
    domainModel.roofFaces.push(
      ...entity.roofSections.reduce((prev: RoofFaceDto[], roofSectionEntity: IRoofSectionData): RoofFaceDto[] => {
        const { faces } = roofSectionEntity;
        const roofFaces: RoofFaceDto[] = faces.map((roofFaceEntity: IRoofFaceData): RoofFaceDto => {
          const roofFace = {
            ...roofFaceEntity,
            level: entity.level
          };
          return this.roofFaceMapper.convertEntityToDomainModel(roofFace);
        });
        return [...prev, ...roofFaces];
      }, [])
    );
    return domainModel;
  }

  convertDomainModelToEntity(domainModel: RoofStoryDto): IRoofStoryData {
    return {
      level: domainModel.level,
      roofSections: [
        {
          faces: domainModel.roofFaces.map(
            (roofFace: RoofFaceDto): IRoofFaceData => this.roofFaceMapper.convertDomainModelToEntity(roofFace)
          )
        }
      ]
    };
  }
}
