import {
  LyraIcon, LyraTypography, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, FC
} from 'react';
import React, { useState } from 'react';
import useStore from '../../../../../stores/useStore';
import type { UpsertInstallerAndCreateProjectViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import {
  BoldLink,
  BrowseText,
  CenteredTitle,
  CreateInstallerStep1Wrapper,
  DropText,
  DropView,
  UploadLogoBottomControls,
  HelpText,
  IconWrapper,
  LogoImage,
  UploadLogoWrapper
} from './../styles';

const allowedMimeTypes = ['image/png', 'image/jpeg', 'image/gif'];
/*const acceptedFormats: string[] = ['.png', '.jpeg', '.jpg', '.gif'];
const tenMegabytesAsBytes = 10 * Math.pow(1024, 2);*/

export const UploadCompanyLogoStep: FC = observer((): ReactElement => {
  // Setting stores used in the component
  const { modal } = useStore();

  // Setting view model used by the component
  const [upsertInstallerAndCreateProjectViewModel] = useState<UpsertInstallerAndCreateProjectViewModel>(
    modal.viewModel as UpsertInstallerAndCreateProjectViewModel
  );

  const {
    isLogoPresent, checkingLogo, logoUrl, logoUploadingInProgress, startupMode
  } =
    upsertInstallerAndCreateProjectViewModel;

  const saltedLogoUrl: string = `${logoUrl}?salt=${new Date().getTime()}`;

  const [updatingLogo, setUpdatingLogo] = useState<boolean>(false);
  const showLogoUploadArea = !checkingLogo && (!isLogoPresent || updatingLogo);
  const showLogo = !checkingLogo && isLogoPresent && !updatingLogo;

  /*const onFileDrop = useCallback(async (acceptedFiles: File[]): void => {
    const file: File = acceptedFiles[0];
    if (allowedMimeTypes.indexOf(file.type) !== -1) {
      await upsertInstallerAndCreateProjectViewModel.uploadLogo(file);
      setUpdatingLogo(false);
    }
  }, []);*/

  return (
    <CreateInstallerStep1Wrapper>
      <CenteredTitle>
        <LyraTypography.Heading type="h2" fontWeight="300">
          Upload a Company Logo
        </LyraTypography.Heading>
      </CenteredTitle>
      {startupMode && (
        <HelpText>
          Give your permit package a personal touch! The logo will appear on each page of the permit package.
        </HelpText>
      )}
      <UploadLogoWrapper>
        {showLogoUploadArea && (
          <>
            {logoUploadingInProgress ? (
              <LyraLoader.Loader text="Uploading..." />
            ) : (
              <>
                <DropView
                  onDragEnter={(e: React.DragEvent<HTMLDivElement>): void => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragOver={(e: React.DragEvent<HTMLDivElement>): void => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
                    e.preventDefault();
                    e.stopPropagation();

                    const file: File = e.dataTransfer.files[0];
                    if (allowedMimeTypes.indexOf(file.type) !== -1) {
                      await upsertInstallerAndCreateProjectViewModel.uploadLogo(file);
                      setUpdatingLogo(false);
                    }
                  }}
                >
                  <DropText>Drag & Drop file</DropText>
                </DropView>
                <input
                  type="file"
                  name="logo"
                  id="logo-file"
                  style={{
                    display: 'none'
                  }}
                  accept={allowedMimeTypes.join(', ')}
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
                    const file: File = e.target.files![0];

                    await upsertInstallerAndCreateProjectViewModel.uploadLogo(file);
                    setUpdatingLogo(false);
                  }}
                />

                <label htmlFor="logo-file">
                  <BrowseText>or Browse file</BrowseText>
                </label>
              </>
            )}
          </>
        )}

        {showLogo && (
          <>
            <LogoImage src={saltedLogoUrl} />
            <UploadLogoBottomControls>
              <BoldLink onClick={(): void => setUpdatingLogo(true)}>Replace&nbsp;Logo</BoldLink>
              <IconWrapper
                onClick={(): void => {
                  // eslint-disable-next-line no-alert
                  if (confirm('Are you sure you want to delete your company\'s logo?')) {
                    upsertInstallerAndCreateProjectViewModel.deleteLogo();
                  }
                }}
              >
                <LyraIcon.Icon name="icon-delete" />
              </IconWrapper>
            </UploadLogoBottomControls>
          </>
        )}

        {checkingLogo && <LyraLoader.Loader text="Loading..." />}
      </UploadLogoWrapper>
    </CreateInstallerStep1Wrapper>
  );
});
