import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { RoofProtrusion } from '../../../domain/models/SiteDesign/RoofProtrusion';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IAddProtrusionDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
  protrusion: RoofProtrusion;
  roofFace: RoofFace;
}

export class AddProtrusionCommand extends BaseCommand {
  private domain: DomainStore;
  private protrusion: RoofProtrusion;
  private roofFace: RoofFace;

  constructor(dependencies: IAddProtrusionDependencies) {
    super();
    this.domain = dependencies.domain;
    this.protrusion = dependencies.protrusion;
    this.roofFace = dependencies.roofFace;
  }

  async execute(): Promise<void> {
    this.roofFace.mesh.add(this.protrusion.mesh);
    this.domain.addOrUpdateRoofProtrusion(this.roofFace, this.protrusion);
  }
}
