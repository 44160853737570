import type { ModalStore } from '../../Modal/Modal';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import { SITE_INFO_TOOL_ID } from './constants';

export interface ISiteInfoToolDependencies extends IBaseToolDependencies {
  modal: ModalStore;
}

export class SiteInfoTool extends BaseTool {
  readonly id: string = SITE_INFO_TOOL_ID;
  readonly icon: string = 'info';
  readonly title: string = 'Site Info';
  readonly description: string = this.title;

  constructor(dependencies: ISiteInfoToolDependencies) {
    super(dependencies);
  }

  override onClick = (): void => {
    this.modal.createModalBasedOnViewModalCode('project_properties_modal');
  };

  whenSelected(): void {
    /** */
  }

  whenDeselected(): void {
    /** */
  }

  dispose(): void {
    /** */
  }
}
