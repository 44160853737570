import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';

interface IArrowIconProps {
  inverterName: string;
  onClick: (inverterName: string) => void;
}

function ArrowIcon(props: IArrowIconProps): ReactElement {
  const {
    inverterName, onClick
  } = props;
  const onIconClick = useCallback((): void => {
    onClick(inverterName);
  }, [inverterName, onClick]);

  return (
    <span onClick={onIconClick}>
      <LyraIcon.Icon
        name="arrow-down"
        style={{
          width: 12,
          height: 8
        }}
      />
    </span>
  );
}

export default ArrowIcon;
