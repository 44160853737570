import type { Option } from '@aurorasolar/lyra-ui-kit';
import type DomainStore from '../../../DomainStore/DomainStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { WizardStore } from '../Wizard';
import type { IOption } from '../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../domain/typings';
import { DesignService } from '../../../../infrastructure/services/api/DesignService';

export interface IBaseStepViewModelDependencies {
  wizard: WizardStore;
  domain: DomainStore;
  serviceBus: ServiceBus;
}

export abstract class BaseStepViewModel {
  abstract propCodeUI: string;
  protected step: number = 1;
  protected wizard: WizardStore;
  protected domain: DomainStore;
  protected serviceBus: ServiceBus;
  protected designService = new DesignService();

  constructor(dependencies: IBaseStepViewModelDependencies) {
    this.wizard = dependencies.wizard;
    this.domain = dependencies.domain;
    this.serviceBus = dependencies.serviceBus;
  }

  nextStep(): void {
    this.wizard.changeStep(this.wizard.currentStep + 1);
  }

  previousStep(): void {
    this.wizard.changeStep(this.wizard.currentStep - 1);
  }

  changeStep(step: number): void {
    this.wizard.changeStep(step);
  }

  closeSteps(): void {
    this.wizard.clear();
  }

  protected mapToItemOption(options: IOption<MarkerAttributes>[]): Option[] {
    return options.map(
      (option: IOption<MarkerAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }
}
