import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import type { MeterBasePropertiesViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/MeterBasePropertiesViewModel';

const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;

interface IProps {
  viewModel: MeterBasePropertiesViewModel;
}

const Label = LyraTypography.Paragraph;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

const UtilityMeterProperties = (props: IProps): ReactElement => {
  const { viewModel } = props;

  return (
    <>
      <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
        SERIAL NUMBER
      </Label>
      <TextField
        height="20px"
        value={viewModel.serialNumber}
        onChange={(value): void => viewModel.assignSerialNumber(value, true)}
      />

      {viewModel.isElectricServiceIdFieldVisible && (
        <>
          <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
            ELECTRIC SERVICE IDENTIFIER (ESID)
          </Label>
          <TextField
            height="20px"
            value={viewModel.electricServiceId}
            maxLength={22}
            onChange={(value): void => viewModel.assignElectricServiceId(value, true)}
            hasError={!viewModel.isValidElectricServiceIdField}
          />
          {!viewModel.isValidElectricServiceIdField && (
            <Label lineHeight={1} margin="4px 4px" type="h6" color={EThemeComponentColor.RED}>
              It must be a 17 or 22-digit number.
            </Label>
          )}
        </>
      )}
    </>
  );
};

export default observer(UtilityMeterProperties);
