import debounce from 'lodash/debounce';
import {
  action, computed, observable, runInAction
} from 'mobx';
import isEmpty from 'lodash/isEmpty';
import type { ISearchInverterParameters } from '../../../../../domain/entities/Design/ISearchInverterParameters';
import {
  AC_POINT_OF_INTERCONNECTION_VOLTAGE,
  ERROR,
  INVERTER_TYPE_MICROINVERTER_SHORT
} from '../../../../../domain/models/Constants';
import type {
  Attributes,
  IInverterSearchRequest,
  IOptimizerOption,
  IInverterOption
} from '../../../../../domain/models/Inverter';
import { DcOptimizerUse } from '../../../../../domain/models/Inverter';
import type { IPvModuleInfo } from '../../../../../domain/models/SupplementalData/SupplementalData';
import type { IPowerConversionEquipmentChangeRequest } from '../../../../../domain/request/PowerConversionChangeRequest/IPowerConversionChangeRequest';
import type { ElectricalDesignStage } from '../../../../../domain/stages/DesignStages/ElectricalDesignStage';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type { DesignWorkspace } from '../../../WorkspaceStore/workspaces/DesignWorkspace';
import type { IBaseViewModelDependencies } from '../../BaseViewModel';
import { BaseViewModel } from '../../BaseViewModel';
import StringingService from '../../../../../services/stringing/stringingService';
import {
  handleApiError, notify
} from '../../../../../utils/helpers';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../../../../domain/models/SiteDesign/RoofFace';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type { EssOptionsPair } from '../../../../../domain/entities/PvSystem/EnergyStorageAndBackupOptions';
import {
  EnergyStorageAndBackupOptions,
  EssBackupConfiguration
} from '../../../../../domain/entities/PvSystem/EnergyStorageAndBackupOptions';
import { BackupStrategy } from '../../../../../domain/models/PvSystem/PvSystem';
import type { Design } from '../../../../../domain/models/Design/Design';
import type { PowerConversionAndStorageEquipmentDefinition } from '../../../../../domain/models/PvSystem/Equipment';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../../domain/behaviour/KeyboardBehaviour';
import { PowerConversionAndStorageEquipmentSelectedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../../config/config';
import {
  calcAcPowerOutput, calcCurrentRatio, calcTotalMaxDcInput
} from './inverterSelectionUtils';

type TagType = {
  key: string;
  value: string;
};

export type InverterType = {
  /**
   * Inverter definition ID
   */
  definitionId: string;
  name: string;
  description: string;
  tags: TagType[];
  attributes: Attributes;
};

export function isInverterOutsideInputVoltageRange(inverter?: InverterType): boolean {
  return inverter?.attributes.withinInputVoltageRange === false;
}

export type DcOptimizerType = {
  value: string;
  name: string;
  abbreviatedName: string;
  valid: string;
  maxVoltage: string;
  violations: string;
  maxPvInputPower: string;
  model: string;
  maxIsc: string;
  minVoltage: string;
  manufacturer: string;
};

export const DEFAULT_HOVERED_INVERTER_OBJECT = {
  definitionId: '',
  name: '',
  description: '',
  compatible: true,
  tags: [],
  attributes: {} as Attributes
};

const DEFAULT_MAX_RATIO = 160;

interface IInverterSelectionViewModelDependencies extends IBaseViewModelDependencies {
  serviceBus: ServiceBus;
  designWorkspace: DesignWorkspace;
  searchInverterParameters: ISearchInverterParameters;
}

export class InverterSelectionViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  override readonly propCodeUI = 'inverter_selection_modal';
  override readonly editor!: EditorStore;
  @observable
  firstInverterOptions: InverterType[] = observable([]);
  /**
   * Selected first inverter definition option (structure returned by equipment definitions service)
   */
  @observable
  firstInverterSelected?: InverterType;
  @observable
  firstInverterName?: string;
  /**
   * ID of the first Inverter definition
   */
  @observable
  firstInverterDefinitionId?: string;
  @observable
  loading: boolean = false;

  @observable
  secondInverterOptions: InverterType[] = observable([]);
  /**
   * Selected second inverter definition option (structure returned by equipment definitions service)
   */
  @observable
  secondInverterSelected: InverterType | undefined;
  @observable
  secondInverterValue?: string;
  /**
   * ID of the second Inverter definition
   */
  @observable
  secondInverterDefinitionId?: string;
  @observable
  secondInverterLoading: boolean = false;

  @observable
  dcOptimizerData: DcOptimizerType[] = [];
  @observable
  dcOptimizerDefinitionId?: string;
  @observable
  dcOptimizerValue?: string;
  @observable
  dcOptimizerLoading: boolean = false;

  @observable
  private hoveredInverterItem: InverterType = DEFAULT_HOVERED_INVERTER_OBJECT;
  @observable
  private hoveredInverterKind = 'first';

  private combinedSelectionOptionsForBothInverters: InverterType[] = [];

  @observable
  rightButtonDisabled: boolean = true;
  @observable
  compatibility: boolean = true;

  private lastInverterProductFamily?: string;
  private lastInverterManufacturerId?: string;
  private readonly designWorkspace: DesignWorkspace;
  private readonly design: Design;
  private readonly serviceBus: ServiceBus;
  private readonly searchInverterParameters: ISearchInverterParameters;
  private firstInverterPreloadedOptions?: InverterType[];

  @observable
  energyStorageAndBackupOptionsAreLoading: boolean = false;
  /**
   * This object represents all the possible ESS selection options, as received from backend
   */
  @observable
  energyStorageAndBackupOptions: EnergyStorageAndBackupOptions | undefined;
  /**
   * @description this object contains a selected ESS configuration that will be sent to the server.
   * This is the main source of truth for the UI.
   */
  @observable
  private selectedEnergyStorageAndBackup?: PowerConversionAndStorageEquipmentDefinition['energyStorageAndBackup'] =
    undefined;

  constructor(dependencies: IInverterSelectionViewModelDependencies) {
    super(dependencies);
    const {
      searchInverterParameters, designWorkspace, serviceBus, domain
    } = dependencies;
    this.design = domain.design;
    const anyInvertersSelected = !!this.design.supplementalData.invertersSelected;
    if (anyInvertersSelected) {
      this.rightButtonDisabled = false;
    }

    this.designWorkspace = designWorkspace;
    this.serviceBus = serviceBus;
    this.searchInverterParameters = searchInverterParameters;
  }

  // Bounce function, Call api with a delay of 1 second.
  // eslint-disable-next-line @typescript-eslint/member-ordering
  bouncedLoadFirstInverterOptions = debounce((): void => {
    this.changeCompatibility(true);
    this.loadInverters(this.firstInverterName!).then((response: InverterType[]): void => {
      this.loading = false;
      this.firstInverterOptions = response;
      this.updateSelectionOptionsForBothInverters(response);
    });
  }, 1000);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  bouncedSecondInverterOptions = debounce((): void => {
    this.loadInverters(this.secondInverterValue!).then((response: InverterType[]): void => {
      this.secondInverterLoading = false;
      this.secondInverterOptions = response;
      this.updateSelectionOptionsForBothInverters(response);
    });
  }, 1000);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  dcOptimizerBounced = debounce((inverterDefinitionId: string): void => {
    if (inverterDefinitionId === this.firstInverterDefinitionId) {
      this.loadOptimizers(inverterDefinitionId, this.firstInverterSelected!.attributes.optimizerUse)
        .catch(handleApiError('Failed to retrieve DC optimizers data'))
        .finally((): void => {
          this.dcOptimizerLoading = false;
        });
    } else {
      this.dcOptimizerLoading = false;
    }
  }, 1000);

  updateSelectionOptionsForBothInverters(inverterOptions: InverterType[]): void {
    // This data changes very rarely, so it would be safe to assume backend will always return exactly the
    // same selection option for the same inverter, duplicates in this list should not be a problem.
    this.combinedSelectionOptionsForBothInverters.push(...inverterOptions);
  }

  @computed
  get isMicroInverter(): boolean {
    if (!this.firstInverterSelected) {
      return false;
    }
    const attributes = this.firstInverterSelected.attributes;
    return attributes['Inverter Type'] === INVERTER_TYPE_MICROINVERTER_SHORT;
  }

  @computed
  get isDcOptimizerRequired(): boolean {
    if (!this.firstInverterSelected) {
      return false;
    }
    const attributes = this.firstInverterSelected.attributes;
    return attributes.optimizerUse === 'REQUIRED';
  }

  @computed
  get inverterSuitableForEnergyStorageAndBackup(): boolean {
    return !!(this.firstInverterDefinitionId || this.secondInverterDefinitionId);
  }

  @computed
  get inverterDefinitionsToUseInCalculation(): (InverterType | undefined)[] {
    const isHoveredInverterSet = !!this.hoveredInverterItem.definitionId;
    const isFirstInverterHovered = this.hoveredInverterKind === 'first';

    if (!isHoveredInverterSet) {
      return [this.firstInverterSelected, this.secondInverterSelected];
    }

    return isFirstInverterHovered
      ? [this.hoveredInverterItem, this.secondInverterSelected]
      : [this.hoveredInverterItem, this.firstInverterSelected];
  }

  @computed
  get acPowerOutput(): number {
    return calcAcPowerOutput(this.inverterDefinitionsToUseInCalculation);
  }

  @computed
  get totalMaxDcInput(): number {
    return calcTotalMaxDcInput(this.inverterDefinitionsToUseInCalculation);
  }

  @computed
  get maxRatio(): number {
    const possibleMaxValue = Math.round((this.totalMaxDcInput / this.acPowerOutput) * 100);
    if (!possibleMaxValue) {
      return DEFAULT_MAX_RATIO;
    }
    return possibleMaxValue;
  }

  @computed
  get currentRatio(): number {
    const selectedInverter = (this.firstInverterSelected || this.secondInverterSelected) as InverterType;
    return calcCurrentRatio(
      this.acPowerOutput,
      this.dcPowerRating,
      this.hoveredInverterItem.definitionId ? this.hoveredInverterItem : selectedInverter,
      this.totalModules
    );
  }

  @computed
  get pvModuleInfo(): IPvModuleInfo | undefined {
    return this.design.supplementalData.pvModuleInfo;
  }

  @computed
  get dcPowerRating(): number {
    return this.pvModuleInfo?.powerRating ?? 0;
  }

  @computed
  get totalModules(): number {
    return this.design.system.equipment.pvModules.count;
  }

  @computed
  get inputVoltageLowerBound(): number {
    return this.searchInverterParameters.inputVoltageLowerBound;
  }

  @computed
  get inputVoltageUpperBound(): number {
    return this.searchInverterParameters.inputVoltageUpperBound;
  }

  @computed
  get hoveredOrSelectedFirstInverter(): InverterType {
    const isHoveredFirstInverter = this.hoveredInverterKind === 'first' && !!this.hoveredInverterItem?.definitionId;

    return isHoveredFirstInverter ? this.hoveredInverterItem : this.firstInverterSelected!;
  }

  @computed
  get isSaveButtonDisabled(): boolean {
    return (
      this.rightButtonDisabled
      || !this.firstInverterDefinitionId
      || !this.firstInverterSelected
      || isInverterOutsideInputVoltageRange(this.firstInverterSelected)
      || !!this.currentlySelectedEssCompatibilityErrorMessage
    );
  }

  @action
  continue = (): void => {
    this.changeRightButtonDisabled(true);
    this.savePowerConversion().then((): void => {
      this.changeRightButtonDisabled(false);
      this.closeModal();
    });
  };

  @action
  cancel = (): void => {
    this.closeModal();
    this.isSelectedInverterOutsideInputVoltageRange().then((outsideInputVoltageRange) => {
      if (outsideInputVoltageRange || !StringingService.inverters.length) {
        this.designWorkspace.stageManager!.previous();
      } else {
        const electricalDesignStage = this.designWorkspace.stageManager!.currentStage as ElectricalDesignStage;
        electricalDesignStage.reloadStringingOptionsAndShowSummaryPanel();
      }
      this.dispose();
    });
  };

  /**
   * @description this method loads ESS configurations and options and stores in
   * this the `lastEnergyStorageAndBackupOptions` field.
   */
  @action
  async loadEnergyStorageAndBackupOptions(): Promise<void> {
    this.energyStorageAndBackupOptionsAreLoading = true;

    const pvModuleCount = this.design.system.equipment.pvModules.count;
    const siteElevationInMeters = this.domain.project.site.elevation;
    const inverterDefinitionIds: string[] = [];
    if (this.firstInverterDefinitionId) {
      inverterDefinitionIds.push(this.firstInverterDefinitionId);
    }
    if (this.secondInverterDefinitionId) {
      inverterDefinitionIds.push(this.secondInverterDefinitionId);
    }
    try {
      if (inverterDefinitionIds.length > 0) {
        const optionsData = await this.equipmentService.getEnergyStorageAndBackupOptions(
          inverterDefinitionIds,
          pvModuleCount,
          siteElevationInMeters
        );
        this.energyStorageAndBackupOptions = new EnergyStorageAndBackupOptions(optionsData);
        // Set selected options to those matching the state of the Design model
        const system = this.domain.design.system;
        const backupPowerStrategy =
          this.selectedEnergyStorageAndBackup?.backupStrategy ?? system.features?.backupPower ?? BackupStrategy.NONE;
        const acCoupledEssUnits =
          this.selectedEnergyStorageAndBackup?.acCoupledEnergyStorageSystems
          ?? system.equipment.acCoupledEnergyStorageSystems?.instanceDefinitionIds
          ?? [];
        const matchingOptionsPair = this.energyStorageAndBackupOptions.findMatchingAvailableOptionsPair(
          backupPowerStrategy,
          acCoupledEssUnits
        );
        this.updateEnergyStorageAndBackup(matchingOptionsPair);
      }
    } finally {
      this.energyStorageAndBackupOptionsAreLoading = false;
    }
  }

  /**
   * The source of truth for selection state is the `selectedEnergyStorageAndBackup` field.
   * However, it stores the selection in a minimal data structure that is later sent to the backend.
   * This method converts that data to a pair of selection options which contain additional data.
   */
  @computed
  get currentlySelectedEssOptionsPair(): EssOptionsPair {
    if (!this.energyStorageAndBackupOptions) {
      // Options are not yet loaded
      return {
        backup: EssBackupConfiguration.NO_BACKUP,
        storage: undefined
      };
    }
    const currentlySelectedBackupStrategy = this.selectedEnergyStorageAndBackup?.backupStrategy ?? BackupStrategy.NONE;
    const currentlySelectedEssUnits = this.selectedEnergyStorageAndBackup?.acCoupledEnergyStorageSystems ?? [];
    return this.energyStorageAndBackupOptions.findMatchingAvailableOptionsPair(
      currentlySelectedBackupStrategy,
      currentlySelectedEssUnits
    );
  }

  /**
   * Updating selected energy storage configuration and storage options in a format that can be sent to BE later
   * @see savePowerConversion
   */
  @action
  updateEnergyStorageAndBackup = (newConfigurationAndStorageSolution: EssOptionsPair): void => {
    const newBackupConfiguration = newConfigurationAndStorageSolution.backup;
    if (newBackupConfiguration === EssBackupConfiguration.NO_BACKUP) {
      this.selectedEnergyStorageAndBackup = undefined;
      return;
    }
    const newStorageSolution = newConfigurationAndStorageSolution.storage;
    this.selectedEnergyStorageAndBackup = {
      backupStrategy: EnergyStorageAndBackupOptions.backupStrategyFromEssBackupConfiguration(newBackupConfiguration),
      acCoupledEnergyStorageSystems: newStorageSolution?.units
    };
  };

  /**
   * Returns an error message in case the selected ESS configuration and storage solution is not compatible
   * with the rest of the system.
   */
  @computed
  get currentlySelectedEssCompatibilityErrorMessage(): string | undefined {
    return this.currentlySelectedEssOptionsPair.storage?.compatibilityError;
  }

  changeFirstInverterName(value: string): void {
    this.setFirstInverterName(value);
    this.cleanDcOptimizerValue();
    if (!value) {
      this.loading = false;
      return;
    }
    if (!this.loading) {
      this.loading = true;
      this.bouncedLoadFirstInverterOptions();
    }
  }

  changeSecondInverterName(value: string): void {
    this.setSecondInverterName(value);
    if (!value) {
      this.secondInverterLoading = false;
      return;
    }
    if (!this.loading) {
      this.secondInverterLoading = true;
      this.bouncedSecondInverterOptions();
    }
  }

  setDcOptimizerValue(value: string, definitionId: string): void {
    this.dcOptimizerDefinitionId = definitionId;
    this.dcOptimizerValue = value;
  }

  cleanDcOptimizerValue(): void {
    this.dcOptimizerDefinitionId = '';
    this.dcOptimizerValue = '';
  }

  setFirstInverterName(value: string): void {
    this.firstInverterName = value;

    // Clear second inverter search clauses if second inverter option is not selected
    if (!this.secondInverterSelected) {
      this.lastInverterProductFamily = '';
      this.lastInverterManufacturerId = '';
    }

    //  Clear options while user enters a name
    this.firstInverterDefinitionId = '';
    this.firstInverterOptions = [];
  }

  setFirstInverter(value: string, definitionId: string): void {
    this.firstInverterName = value;

    const inverterSelected: InverterType | undefined = this.findInverterByDefinitionId(
      this.combinedSelectionOptionsForBothInverters,
      definitionId
    );

    if (inverterSelected) {
      this.lastInverterProductFamily = inverterSelected.attributes.productFamily;
      this.lastInverterManufacturerId = inverterSelected.attributes.manufacturerId;
      inverterSelected.name = this.firstInverterName;
      this.firstInverterSelected = inverterSelected;
      this.secondInverterDefinitionId = '';
    }

    this.firstInverterDefinitionId = definitionId;
    this.firstInverterOptions = [];
  }

  setSecondInverterName(inverterName?: string): void {
    this.secondInverterValue = inverterName ?? '';

    if (!this.firstInverterDefinitionId) {
      this.lastInverterProductFamily = '';
      this.lastInverterManufacturerId = '';
    }
    this.secondInverterDefinitionId = '';

    if (!inverterName) {
      // second inverter deleted
      this.secondInverterSelected = undefined;
    }

    this.secondInverterOptions = [];
  }

  setSecondInverter(inverterName: string, definitionId: string): void {
    this.secondInverterValue = inverterName ?? '';

    this.secondInverterDefinitionId = definitionId;
    const inverterSelected: InverterType | undefined = this.findInverterByDefinitionId(
      this.combinedSelectionOptionsForBothInverters,
      definitionId
    );

    if (inverterSelected) {
      this.lastInverterProductFamily = inverterSelected.attributes.productFamily;
      this.lastInverterManufacturerId = inverterSelected.attributes.manufacturerId;
      inverterSelected.name = this.secondInverterValue;
      this.secondInverterSelected = inverterSelected;
    }

    this.secondInverterOptions = [];
  }

  findInverterByDefinitionId(inverterList: InverterType[], inverterDefinitionId: string): InverterType | undefined {
    return inverterList.find((inv: InverterType): boolean => inv.definitionId === inverterDefinitionId);
  }

  initialOptimizers(): void {
    this.dcOptimizerData = [];
  }

  getDcOptimizers(): void {
    if (!this.dcOptimizerLoading && this.firstInverterDefinitionId?.length) {
      this.dcOptimizerLoading = true;
      this.dcOptimizerBounced(this.firstInverterDefinitionId.trim());
    }
  }

  setHoveredInverter(params?: { item: InverterType; kind: 'first' | 'second' }): void {
    this.hoveredInverterItem = params?.item || DEFAULT_HOVERED_INVERTER_OBJECT;
    this.hoveredInverterKind = params?.kind || 'first';
  }

  changeRightButtonDisabled(value: boolean): void {
    this.rightButtonDisabled = value;
  }

  changeCompatibility(value: boolean): void {
    this.compatibility = value;
  }

  disableKeyboardEventListeners() {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  enableKeyboardEventListeners() {
    KeyboardBehaviour.addKeyboardEvents(this);
  }

  override dispose(): void {
    this.disableKeyboardEventListeners();
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (!this.isSaveButtonDisabled && event.key === KeyboardListener.KEY_ENTER) {
      this.continue();
      this.dispose();
    }
  };

  async isSelectedInverterOutsideInputVoltageRange(): Promise<boolean> {
    const selectedInverterOption = await this.getSelectedInverterOption();
    return isInverterOutsideInputVoltageRange(selectedInverterOption);
  }

  async getSelectedInverterOption(): Promise<InverterType | undefined> {
    const design = this.domain.design;
    const invertersSelected = design.supplementalData.invertersSelected ?? [];
    if (!design.system.equipment.inverters || !invertersSelected.length) {
      return;
    }
    this.firstInverterPreloadedOptions = await this.loadInverters(invertersSelected[0].name!);
    return this.findInverterByDefinitionId(this.firstInverterPreloadedOptions, invertersSelected[0].definitionId!);
  }

  async resumeInverterSelection(): Promise<void> {
    const design = this.domain.design;
    const invertersSelected = design.supplementalData.invertersSelected ?? [];
    if (design.system.equipment.inverters && invertersSelected.length) {
      if (this.firstInverterPreloadedOptions) {
        this.firstInverterOptions = this.firstInverterPreloadedOptions;
        this.firstInverterPreloadedOptions = undefined;
      } else {
        this.firstInverterOptions = await this.loadInverters(invertersSelected[0].name!);
      }
      this.updateSelectionOptionsForBothInverters(this.firstInverterOptions);
      if (invertersSelected[0].definitionId) {
        this.setFirstInverter(invertersSelected[0].name!, invertersSelected[0].definitionId);
      } else {
        this.setFirstInverterName(invertersSelected[0].name!);
      }
    }

    if (design.system.equipment.inverters?.secondStringInverterId && invertersSelected[1].name) {
      const response: InverterType[] = await this.loadInverters(invertersSelected[1].name!);
      this.secondInverterOptions = response;
      this.updateSelectionOptionsForBothInverters(response);
      if (invertersSelected[1].definitionId) {
        this.setSecondInverter(invertersSelected[1].name!, invertersSelected[1].definitionId);
      } else {
        this.setSecondInverterName(invertersSelected[1].name);
      }
    }
    if (design.system.equipment.hasDcOptimizerDefinition) {
      this.setDcOptimizerValue(
        design.supplementalData.optimizerInfo!.model,
        design.system.equipment.optimizers!.definition
      );
    }
  }

  private async loadInverters(inverterValue: string): Promise<InverterType[]> {
    const {
      inputVoltageLowerBound, inputVoltageUpperBound, pvModuleInstallationMethods
    } =
      this.searchInverterParameters;

    const inverterSearchRequest: IInverterSearchRequest = {
      query: inverterValue,
      inputVoltageLowerBound: inputVoltageLowerBound,
      inputVoltageUpperBound: inputVoltageUpperBound,
      voltage: AC_POINT_OF_INTERCONNECTION_VOLTAGE,
      moduleMountingMethod: pvModuleInstallationMethods.join(',')
    };
    if (this.lastInverterManufacturerId && this.lastInverterProductFamily) {
      inverterSearchRequest.manufacturerId = this.lastInverterManufacturerId;
      inverterSearchRequest.productFamily = this.lastInverterProductFamily;
    }
    const data = await this.equipmentService
      .getInverterOptions(inverterSearchRequest)
      .catch(handleApiError('Failed to retrieve inverter options'));

    return data.map((option: IInverterOption): InverterType => {
      const { tags } = option;
      const pills: TagType[] = Object.keys(tags).map(
        (key: string): TagType => ({
          key,
          value: tags[key]
        })
      );

      return {
        definitionId: option.id,
        name: option.title,
        description: option.dateCreated,
        tags: pills,
        attributes: option.attributes
      };
    });
  }

  private async loadOptimizers(inverterId: string, dcOptimizerUse: DcOptimizerUse): Promise<void> {
    const currentDesign = this.domain.design;
    const moduleId = currentDesign.system.equipment.pvModules.definition;
    const {
      inputVoltageLowerBound, inputVoltageUpperBound, inputCurrentUpperBound
    } = this.searchInverterParameters;

    const data = await this.equipmentService
      .getDcOptimizers({
        moduleId,
        inverterId,
        temperatureAdjustedMinVmp: inputVoltageLowerBound,
        temperatureAdjustedMaxVoc: inputVoltageUpperBound,
        temperatureAdjustedMaxIsc: inputCurrentUpperBound
      })
      .catch(handleApiError('Failed to fetch DC optimizers data'));

    runInAction((): void => {
      this.dcOptimizerData = data.map((item: IOptimizerOption): DcOptimizerType => item.attributes as DcOptimizerType);
      if (dcOptimizerUse === DcOptimizerUse.OPTIONAL && this.dcOptimizerData.length > 0) {
        const noneItem: DcOptimizerType = {
          value: 'NONE',
          name: 'None',
          abbreviatedName: 'None',
          valid: 'false',
          maxVoltage: 'None',
          violations: 'None',
          maxPvInputPower: 'None',
          model: 'None',
          maxIsc: 'None',
          minVoltage: 'None',
          manufacturer: 'None'
        };
        this.dcOptimizerData.splice(0, 0, noneItem);
      }
    });
  }

  private async savePowerConversion(): Promise<void> {
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor);

    const electricalDesignStage = this.designWorkspace.stageManager!.currentStage as ElectricalDesignStage;
    const inverterDefinitionIds: string[] = [];

    electricalDesignStage.resetInvertersAndDcOptimizerValues();

    if (this.firstInverterDefinitionId) {
      inverterDefinitionIds.push(this.firstInverterDefinitionId);
      electricalDesignStage.setInverter({
        model: this.firstInverterName!,
        units: this.isMicroInverter ? this.design.system.equipment.inverters?.microinverterCount ?? 0 : 1
      });
    }
    if (this.secondInverterDefinitionId) {
      if (this.isMicroInverter) {
        throw new Error('Second inverter definition cannot be selected when the first one is a microinverter');
      }
      inverterDefinitionIds.push(this.secondInverterDefinitionId);
      electricalDesignStage.setInverter({
        model: this.secondInverterValue!,
        units: 1
      });
    }

    const powerConversionAndStorageEquipment: PowerConversionAndStorageEquipmentDefinition = {
      inverterDefinitionIds,
      optimizerDefinitionId: !isEmpty(this.dcOptimizerDefinitionId) ? this.dcOptimizerDefinitionId : undefined,
      energyStorageAndBackup: this.selectedEnergyStorageAndBackup
    };
    if (this.design.system.doesPowerConversionAndStorageMatch(powerConversionAndStorageEquipment)) {
      // Power conversion and storage equipment did not change, therefore simply close the modal
      electricalDesignStage.reloadStringingOptionsAndShowSummaryPanel();
      hideLoaders();
      return;
    }
    try {
      const powerConversionChangeRequest: IPowerConversionEquipmentChangeRequest = {
        powerConversionAndStorageEquipment,
        design: this.design.toData()
      };
      const designDelta = await this.designService.setPowerConversionAndStorageEquipment(powerConversionChangeRequest);
      config.analytics?.trackEvent(new PowerConversionAndStorageEquipmentSelectedEvent(this.domain));
      this.serviceBus.send('update_design_delta', designDelta.toApplyDesignDeltaCommand(this.domain));
      this.design.supplementalData.updatePowerConversionEquipmentInfo(
        this.design.system.equipment.inverters!,
        this.firstInverterSelected!.name,
        this.secondInverterSelected?.name,
        this.dcOptimizerValue
      );
      electricalDesignStage.initStringingService();
    } catch (error) {
      console.error('Power conversion and storage equipment change failed: ', error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      notify(`Error: ${(error as any)?.data?.message}`, ERROR);
      electricalDesignStage.editInverterAndDcOptimizer();
    }
    hideLoaders();
  }
}
