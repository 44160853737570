import React, {
    BaseProps,
    ReactElement,
    ReactNode,
    ReactText
  } from 'react';
import {
  CardWrapper,
  HeaderEditButtonWrapper,
  HeaderEditionWrapper
} from './styles';

type RoofEdition = BaseProps & {
    children?: ReactNode;
    editable?: boolean;
    title?: ReactElement | ReactText;
    onEditClick?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
  };

const Card = (props: RoofEdition): ReactElement => {
    const {children, onEditClick, title, className, editable = true} = props;
    return (
      <CardWrapper className={className}>
        {title &&
          <HeaderEditionWrapper dir="row" justify="space-between" >
            {title}
          </HeaderEditionWrapper>
        }
        {editable &&
          <HeaderEditButtonWrapper onEditClick={onEditClick} />
        }
          {children}
      </CardWrapper>
    );
  };

export { Card };
