import React, { BaseProps, ReactElement } from 'react';
import { StepProps } from '../../typings';
import { Notes } from '../FontFamily/Fonts';
import {
  Button,
  ButtonContainer,
  IconContainer,
  Label,
  StepContainer,
  StyledIcon as Icon
} from './styles';

export type StepData = {
  isOptional?: boolean;
  icon?: string;
  title?: string;
  onClickStep?: () => void;
};

export type PipelineProps = BaseProps & StepData & StepProps;

const Step = (props: PipelineProps): ReactElement => {
  const {
    state,
    isOptional = false,
    icon = 'arrow-down',
    title = '',
    onClickStep
  } = props;

  return (
    <>
      <StepContainer state={state} onClick={(): void => onClickStep && onClickStep()}>
        <IconContainer>
          {state === 'active' && <Icon className="mt-xxs" name="arrow-right" />}
        </IconContainer>

        <ButtonContainer>
          <Button state={state}>
            <Icon name={icon} />
            <Label>{title}</Label>
          </Button>

          {isOptional && <Notes className="mt-xxs">(Optional)</Notes>}
        </ButtonContainer>

        <IconContainer>
          {state === 'pending' || (
            <Icon
              className="mt-xxs"
              name={
                state === 'ready'
                  ? 'icon-pp-purchased-purple'
                  : 'icon-pp-purchased-disabled'
              }
            />
          )}
        </IconContainer>
      </StepContainer>
    </>
  );
};

export { Step };
