import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateRoofFaceLevelDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
  roofFace: RoofFace;
  level: number;
}

export class UpdateRoofFaceLevelCommand extends BaseCommand {
  private roofFace: RoofFace;
  private level: number;
  private domain: DomainStore;

  constructor(dependencies: IUpdateRoofFaceLevelDependencies) {
    super();
    const {
      roofFace, level, domain
    } = dependencies;
    this.roofFace = roofFace;
    this.level = level;
    this.domain = domain;
  }

  async execute(): Promise<void> {
    this.domain.addOrUpdateRoofFace(this.roofFace, this.level);
  }
}
