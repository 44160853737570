import styled from 'styled-components';
import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import IconMapper from '../../../../../ui/components/Icons';

export const Icon = styled(IconMapper)`
  width: 25px;
  height: 20px;
  @media (max-width: 1366px) {
    margin-right: 3px;
    width: 18px;
    height: 18px;
  }
`;

export const DetailsStyle = styled.div`
  width: 240px;
`;

export const DetailsContent = styled.div<{ color: string }>`
  color: ${({ color }: { color: string }): string => color};
  font-size: 20px;
  font-weight: bold;
`;

export const InfoTextHeader = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
  white-space: nowrap;

  @media (max-width: 1366px) {
    font-size: 10px;
    font-weight: bold;
  }
`;

export const InfoDescription = styled.div`
  font-size: 10px;
  line-height: 1;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor2};
  white-space: normal;
  padding-top: 3px;
  width: 100%;

  @media (max-width: 1366px) {
    display: none;
  }
`;

export const DetailsIcon = styled.span`
  width: 20px;
`;
