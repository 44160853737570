import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  LyraDivider, LyraFormElementsDesignTool, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { MountingSystemDefinition } from '../../../../domain/models/MountingSystemDefinition/MountingSystemDefinition';
import type { IOption } from '../../../../domain/models/SiteDesign/IOption';
import type { MountingSystemDefinitionsStage } from '../../../../domain/stages/CreationDesignStages/MountingSystemDefinitionsStage';
import type { MountingSystemAttributes } from '../../../../domain/typings/Attributes';
import useStore from '../../../../stores/useStore';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';
import {
  Card, CheckBoxWrapper, NumberFieldWrapper, ObliqueText, TitleWrapper
} from './styles';

const {
  CheckBox, NumberField, Select
} = LyraFormElementsDesignTool.FormElementsDesignTool;

const formatMountingSystemOptions = (items: IOption<MountingSystemAttributes>[]): Option[] => {
  return items.map((item: IOption<MountingSystemAttributes>): Option => {
    const { attributes } = item;
    const {
      value, inProduction, columnSpacing, name, manufacturer, fullySupportedInLyra
    } =
      attributes as MountingSystemAttributes;

    const supportText = fullySupportedInLyra === 'true' ? '' : 'Partial Support';
    const productionText = inProduction === 'false' ? 'Discontinued' : '';
    const spacingText = MountingSystemDefinition.columnSpacingToLabel(Number(columnSpacing));
    return {
      value,
      name: (
        <Card opacity={inProduction}>
          <TitleWrapper fontSize="13px">
            <LyraTypography.Paragraph lineHeight={1} margin="0" fontWeight="bold">
              {`${manufacturer} ${name}`}
            </LyraTypography.Paragraph>
            <LyraTypography.Paragraph lineHeight={1} margin="0">
              {spacingText}
            </LyraTypography.Paragraph>
          </TitleWrapper>

          <TitleWrapper fontSize="11px">
            {supportText && (
              <LyraTypography.Paragraph lineHeight={1} margin="10px 0 0 0">
                {supportText}
              </LyraTypography.Paragraph>
            )}
            {productionText && <ObliqueText>{productionText}</ObliqueText>}
          </TitleWrapper>
        </Card>
      )
    };
  });
};

const MountingSystemDefinitionsModal: React.FC = (): ReactElement => {
  const {
    workspace, domain
  } = useStore();
  const { wizard } = workspace.currentWorkspace;

  const [viewModel] = useState<MountingSystemDefinitionsStage>(wizard?.currentStage as MountingSystemDefinitionsStage);

  useEffect((): void => {
    viewModel.loadMountingSystemOptions();
  }, [viewModel]);

  const {
    hasSteepSlopeRoofFaces, hasLowSlopeRoofFaces
  } = viewModel;
  const isUpdating = !viewModel.isDesignCreation;
  const modalTitle = isUpdating ? 'Update Mounting System' : 'Roof Top System';
  const showSteps = isUpdating ? undefined : wizard?.steps;
  const leftLabel = isUpdating ? 'CANCEL' : 'BACK';
  const rightLabel = isUpdating ? 'SAVE' : 'NEXT';

  const cancelModal = (): void => {
    wizard?.clear();
    if (!isUpdating) {
      workspace.changeWorkspace(Workspace.Project);
    }
  };

  const modalProps = {
    $show: true,
    title: modalTitle,
    currentStep: wizard?.current,
    steps: showSteps,
    width: '500px',
    height: '645px',
    leftButtonLabel: leftLabel,
    rightButtonLabel: rightLabel,
    rightButtonDisabled: !viewModel.canContinue,
    onClickLeftButton: (): void => {
      if (isUpdating) {
        wizard?.clear();
      } else {
        wizard?.previous();
      }
    },
    onClickRightButton: (): void => {
      wizard?.next();
    },
    onClickCloseButton: (): void => cancelModal()
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <LyraTypography.Paragraph type="h2" lineHeight={1.5} margin="10px 0" align="center">
        Select Mounting System
      </LyraTypography.Paragraph>

      <LyraDivider.Divider className="mb-xs" color={EThemeComponentColor.GRAY_200} />

      {hasSteepSlopeRoofFaces && hasLowSlopeRoofFaces && (
        <>
          <CheckBoxWrapper>
            My system will be mounted on:
            <CheckBox.Checkbox
              className="ml-xs"
              disabled={viewModel.designHasSteepSlopeArrayAreas}
              text="Steep-slope"
              indeterminate={true}
              checked={viewModel.systemWillBeMountedOnSteepSlope}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                viewModel.setSystemWillBeMountedOnSteepSlope(event.target.checked)
              }
            />
            <CheckBox.Checkbox
              className="ml-xs"
              disabled={viewModel.designHasLowSlopeArrayAreas}
              text="Low-slope"
              indeterminate={true}
              checked={viewModel.systemWillBeMountedOnLowSlope}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                viewModel.setSystemWillBeMountedOnLowSlope(event.target.checked)
              }
            />
          </CheckBoxWrapper>

          <LyraDivider.Divider className="mb-xs" color={EThemeComponentColor.GRAY_200} />
        </>
      )}

      {hasSteepSlopeRoofFaces && viewModel.systemWillBeMountedOnSteepSlope && (
        <>
          <Select.Select
            name="slope"
            label={viewModel.getMountingSystemLabel('FOR STEEP-SLOPED ROOFS')}
            selected={viewModel.selectedSteepSlopeMountingSystemId}
            listOptions={formatMountingSystemOptions(viewModel.steepSlopeMountingSystemOptions)}
            onSelect={(value: string | number): void => {
              viewModel.setSteepSlopeMountingSystem(value as string);
            }}
          />
          {hasLowSlopeRoofFaces && viewModel.systemWillBeMountedOnLowSlope && (
            <LyraDivider.Divider className="mt-xs mb-xs" color={EThemeComponentColor.GRAY_200} />
          )}
        </>
      )}

      {hasLowSlopeRoofFaces && viewModel.systemWillBeMountedOnLowSlope && (
        <>
          <Select.Select
            name="flat"
            label={viewModel.getMountingSystemLabel('FOR LOW-SLOPED ROOFS')}
            selected={viewModel.selectedLowSlopeMountingSystemId}
            listOptions={formatMountingSystemOptions(viewModel.lowSlopeMountingSystemOptions)}
            onSelect={(value: string | number): void => {
              viewModel.setLowSlopeMountingSystem(value as string);
            }}
          />

          <LyraDivider.Divider className="mt-xs mb-xs" color={EThemeComponentColor.GRAY_200} />

          <NumberFieldWrapper>
            <NumberField.NumberField
              width="70px"
              label="TILT ANGLE"
              measure="°"
              withQuantityArrows
              limits={viewModel.selectedLowSlopeMountingSystemTiltAngleLimits}
              value={viewModel.lowSlopeMountingSystemDefaultTiltAngle}
              allowOutOfRange={false}
              onChange={viewModel.changeTiltAngle}
              fractionDigits={2}
            />

            <NumberField.NumberField
              width="70px"
              label="N° OF ROWS"
              withQuantityArrows
              limits={viewModel.selectedLowSlopeMountingSystemTierLimits}
              value={viewModel.lowSlopeMountingSystemDefaultNumberOfTiers}
              allowOutOfRange={false}
              onChange={viewModel.changeNumberOfRowsPerRack}
            />
          </NumberFieldWrapper>
        </>
      )}
    </LyraModal.Modal>
  );
};

export default observer(MountingSystemDefinitionsModal);
