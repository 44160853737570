import type {
  JSONSchema7, JSONSchema7Type
} from 'json-schema';
import type { UiSchema } from '@rjsf/utils/lib/types';
import type { FormData } from '../FormOptionsRulesAndState';
import {
  findFormSchemaField, removeEnumByIndex, updateDefaultValue
} from '../effects/util';

function doSelection(
  field: string,
  schema: JSONSchema7,
  arrayOfEnumsToInclude: JSONSchema7Type[],
  formData: FormData
) {
  const fieldInSchema = findFormSchemaField(
    field, schema.properties
  );
  if (!fieldInSchema) {
    return;
  }
  const fieldEnums = fieldInSchema.enum;

  for (let index = 0; index < fieldEnums!.length; index++) {
    if (!arrayOfEnumsToInclude.includes(fieldEnums![index])) {
      removeEnumByIndex(
        schema, field, index
      );
      index--;
    }
  }
  updateDefaultValue(
    field, fieldInSchema, formData
  );
}

type OptionsToSelect = {
  field: string,
  options: JSONSchema7Type[]
}

/**
 * Removes enum options that are not present in `options` array
 *
 * @param field field id
 * @param options array of options that should be displayed
 * @param schema
 * @param uiSchema
 * @param formData
 * @returns {{schema: *, uiSchema: *}}
 */
const showOptions = (
  { field, options }: OptionsToSelect,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
) => {
  doSelection(
    field.split('.')[1],
    schema,
    options,
    formData
  );
};
export default showOptions;
