export interface IPvModuleData {
  readonly id: string;
  readonly mountingSystemId: string;
  readonly positionId: string;
}

export class PvModule {
  id: string;
  mountingSystemId: string;
  positionId: string;

  constructor(data: IPvModuleData) {
    this.id = data.id;
    this.mountingSystemId = data.mountingSystemId;
    this.positionId = data.positionId;
  }

  toData(): IPvModuleData {
    return {
      id: this.id,
      mountingSystemId: this.mountingSystemId,
      positionId: this.positionId
    };
  }
}

export { PvModule as PvModuleData };
