import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import {
  LyraLayout, LyraProgressBar
} from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';
import { PADDING_CARD } from '../../components/Layout/SolarLayout/constants';

export const ArrayTableWrapper = styled.div`
  padding: ${PADDING_CARD};
  overflow: auto;
  & table {
    & tr {
      display: table-row;
      & th {
        display: table-cell;
        flex: 0 1 auto;
        vertical-align: top;
        text-align: left;
      }
    }
  }
`;

export const ArrayPlacementProgressBar = styled(LyraProgressBar.ProgressBar)`
  width: auto;
  margin: 0 auto 30px 0;
`;

export const NextStepWrapper = styled(LyraLayout.Col)<PropsTheme>`
  padding-top: 0px !important;
  background: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  width: 100%;
  button {
    width: 100%;
  }
  .back-button {
    button {
      margin: 8% 0;
      justify-content: flex-start;
    }
  }
`;
