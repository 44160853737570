import {
  action, computed
} from 'mobx';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import { DesignStep } from '../../models/Design/DesignState';
import { getUiStore } from '../../../stores/RootStoreInversion';
import { PageId } from '../../../stores/UiStore/Pages/PagesStore';
import { MOUNTING_BOS_FORM_ID } from '../../../ui/containers/MountingBos/MountingBos';
import type { PlanSetCustomizationPageViewModel } from '../../../ui/containers/Pages/pages/PlanSetCustomization/PlanSetCustomizationPageViewModel';

export class PlanSetCustomizationStage implements IProgressStepperStage {
  readonly title: string = 'Customization';
  readonly id: DesignStep = DesignStep.CUSTOMIZATION;
  readonly submitForm: string = MOUNTING_BOS_FORM_ID;

  // Stage lifecycle methods:
  @action.bound
  setUp = (): void => {
    const { pages } = getUiStore();
    pages.openPage(PageId.PlanSetCustomization);
  };

  resume = (lastValidStage: string): void => {
    const isLastValidStage: boolean = lastValidStage === this.id;
    if (isLastValidStage) {
      this.setUp();
    }
  };

  cancel = (): void => {
    this.dispose();
  };

  dispose = (): void => {
    const { pages } = getUiStore();
    pages.closePage();
  };

  @computed
  get lastValidationSuccessful(): boolean {
    const { pages } = getUiStore();
    const customizationPageViewModel = pages.pageViewModel as PlanSetCustomizationPageViewModel;
    return customizationPageViewModel?.lastValidationSuccessful;
  }

  @computed
  get isGeneratingPlanSet(): boolean {
    const { pages } = getUiStore();
    const customizationPageViewModel = pages.pageViewModel as PlanSetCustomizationPageViewModel;
    return customizationPageViewModel?.isGenerating;
  }

  @computed
  get isFormValid(): boolean {
    const { pages } = getUiStore();
    const customizationPageViewModel = pages.pageViewModel as PlanSetCustomizationPageViewModel;
    const errors =
      (customizationPageViewModel?.documentGenerationOptions
        && customizationPageViewModel.validate(
          customizationPageViewModel.documentGenerationOptions.toData({ forServer: false })
        ))
      ?? {};
    return Object.keys(errors).length === 0;
  }

  @computed
  get canContinue(): boolean {
    return !this.isGeneratingPlanSet && this.isFormValid;
  }

  continue = (): void => {
    this.dispose();
  };
}
