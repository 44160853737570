import styled, {
  PropsTheme,
  withTheme
} from 'styled-components';
import type { BaseProps } from 'react';
import { TooltipWrapper } from '../Tooltip';
import { Color } from '~/typings';

type Props = {
  dir: string;
};

type SmartGuidesMenuIconProps = BaseProps & Props &
  PropsTheme & {
  isActive?: boolean;
  isEnabled?: boolean;
};

export const ListBar = styled.div<Props>`
  display: flex;
  flex-direction: ${({ dir }: Props): string => (dir)};
  padding: 3px 10px 5px;
  border-radius: 7px;
  box-sizing: border-box;
  align-items: center;
  
  .list-item {
    cursor: ${({ isActive }: SmartGuidesMenuIconProps): string => isActive ? 'no-allowed' : 'pointer'};
    &:hover {
      & > ${TooltipWrapper} {
        display: block;
      }
    }
  }
`;

export const IconWrapper = styled.div<SmartGuidesMenuIconProps & Props>`
  position: relative;
  margin:  ${({ dir }: Props): string => dir === 'row' ? '0 7px' : '7px 0'};
  border-radius: 6px;
  width: 39px;
  height: 29px;
  border: 2px solid ${({
   isActive, isEnabled, theme
  }: SmartGuidesMenuIconProps): Color | string =>
    (isActive && isEnabled) ? theme.colors.primary2 : 'transparent'};
  outline: 0.5px solid ${({
    isActive, isEnabled, theme
  }: SmartGuidesMenuIconProps): Color | string =>
  (isActive && isEnabled) ? theme.colors.primary2 : 'transparent'};
  svg {
    ${({ isActive }: SmartGuidesMenuIconProps): string => isActive ? 'opacity: 1' : 'opacity: 0.2'};
  }
`;
