import React, {
  type ReactElement,
  useCallback, useState
} from 'react';
import { KeyboardListener } from '../../../../../../../utils/KeyboardListener';
import {
  TitleContainer, Text, EditButton, TitleInput
} from './DocumentTitle.styles';

interface IDocumentTitleProps {
  title: string;
  onTitleChange: (value: string) => void;
}

function DocumentTitle(props: IDocumentTitleProps): ReactElement {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitleValue, setNewTitleValue] = useState<string | null>(null);
  const {
    title, onTitleChange
  } = props;

  const turnOnEditingMode = (): void => {
    setIsEditing(true);
  };

  const turnOffEditingMode = (): void => {
    setIsEditing(false);
  };

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewTitleValue(event.target.value);
  }, []);

  const onKeyUp = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyboardListener.KEY_ENTER) {
      event.currentTarget.blur();
    }
  }, []);

  const saveNewTitle = (): void => {
    onTitleChange(newTitleValue || title);
    turnOffEditingMode();
    setNewTitleValue(null);
  };

  if (!isEditing) {
    return (
      <TitleContainer onDoubleClick={turnOnEditingMode}>
        <Text>{title}</Text>
        <EditButton name="pencil" onClick={turnOnEditingMode} />
      </TitleContainer>
    );
  }

  return (
    <TitleContainer>
      <TitleInput
        type="text"
        name="fileTitle"
        value={newTitleValue ?? title}
        onChange={onInputChange}
        onBlur={saveNewTitle}
        onKeyUp={onKeyUp}
      />
    </TitleContainer>
  );
}

export default DocumentTitle;
