import type { Marker } from '../../../domain/models/SiteDesign/Marker';
import { SiteMarkerDeletedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../config/config';
import type { SiteEquipmentUnionType } from '../../../domain/models/SiteDesign/SiteEquipment';
import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteSiteEquipment extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    // do nothing, this action is handled by the SiteEquipment.deleteEquipment
  }

  protected deleteFromDomain(): void {
    this.domain.siteEquipment.deleteEquipment({
      siteEquipmentToDelete: this.object as SiteEquipmentUnionType,
      deleteSubpanelWithServiceEntranceEquipment: !this.skipConfirmation
    });
    config.analytics?.trackEvent(new SiteMarkerDeletedEvent(this.domain, (this.object as Marker).label));
  }
}
