import type { ReactElement } from 'react';
import React, {
  useCallback, useState
} from 'react';
import type { ColorResult } from 'react-color';
import { SketchPicker } from 'react-color';
import {
  ColorGroup,
  ColorPanel,
  ColorText,
  CoverPickerView,
  LeftView,
  LogoView,
  MainText,
  PickerWrapper,
  RightView
} from '../styles';

interface IColorRowProps {
  name: string;

  title: string;
  color: string;
  onChange?: (value: string) => void;
}

const ColorRow: React.FC<IColorRowProps> = (props: IColorRowProps): ReactElement => {
  const {
    title, color, onChange
  } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleHidePicker = useCallback((): void => {
    setShowColorPicker(false);
  }, []);

  const handleShowPicker = useCallback((): void => {
    setShowColorPicker(true);
  }, []);

  const handleChangeColor = useCallback(
    (value: ColorResult): void => {
      onChange?.(value.hex);
    },
    [onChange]
  );

  return (
    <LogoView height="46px">
      <LeftView>
        <MainText>{title}</MainText>
      </LeftView>

      <RightView>
        <ColorGroup>
          {showColorPicker ? (
            <PickerWrapper>
              <CoverPickerView onClick={handleHidePicker} />
              <SketchPicker color={color} onChange={handleChangeColor} />
            </PickerWrapper>
          ) : null}

          <ColorPanel color={color} onClick={handleShowPicker} />
          <ColorText>{color}</ColorText>
        </ColorGroup>
      </RightView>
    </LogoView>
  );
};

export default ColorRow;
