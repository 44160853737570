import React, { ReactElement } from 'react';
import Col from '../../utils/flexbox-grid/components/Col';

import { Button } from '../Buttons/Button';
import { Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon/Icon';
import { Tag } from '../Tag';
import { CardContainer } from './CardContainer';
import { CardTitle } from './CardTitle';
import {
  CreatorComment,
  CreatorRow,
  FlexColumn,
  ListContainer,
  RightColumn,
  SeparatorLine,
  TagRow,
  TitleRow
} from './styles';
import { ButtonStyleType } from '../Buttons/styles';

type CardTagProps = {
  name: string;
  favorite?: boolean;
};

type CardProps = {
  id: string | number;
  name?: string;
  creator?: string;
  siteTags?: CardTagProps[];
  systemTags?: CardTagProps[];
  purchased?: boolean;
};

type CardListProps = {
  lists?: CardProps[];
};

const CardList = (props: CardListProps): ReactElement => {
  const { lists } = props;
  return (
    <ListContainer>
      {lists &&
        lists.map(
          (item: CardProps, index: number): ReactElement => {
            const { name, creator, siteTags, systemTags, purchased } = item;
            return (
              <CardContainer key={index}>
                <TitleRow>
                  <Col md={8}>
                    <CardTitle>{name}</CardTitle>
                  </Col>
                  <RightColumn md={4}>
                    <Button styleType={ButtonStyleType.THIRD}>OPEN PROJECT</Button>
                    <Icon
                      name="three-dots"
                      style={{ marginLeft: 9, cursor: 'pointer' }}
                    />
                  </RightColumn>
                </TitleRow>
                <CreatorRow>
                  <FlexColumn md={12}>
                    <CreatorComment>Created by:</CreatorComment>
                    <Paragraph>{creator}</Paragraph>
                  </FlexColumn>
                  <SeparatorLine />
                </CreatorRow>
                <TagRow>
                  <FlexColumn md={12}>
                    {siteTags &&
                      siteTags.map(
                        (siteTag: CardTagProps, siteIndex: number): ReactElement => {
                          return (
                            <Tag type="site" key={siteIndex} style={{ marginRight: 7 }}>
                              {siteTag.name}
                            </Tag>
                          );
                        }
                      )}
                  </FlexColumn>
                  <SeparatorLine />
                </TagRow>
                <TagRow>
                  <FlexColumn md={12}>
                    {systemTags &&
                      systemTags.map(
                        (systemTag: CardTagProps, systemIndex: number): ReactElement => {
                          return (
                            <Tag
                              type="system"
                              key={systemIndex}
                              style={{ marginRight: 7 }}
                              iconName={
                                systemTag.favorite ? 'icon-favorite-selected' : undefined
                              }
                            >
                              {systemTag.name}
                            </Tag>
                          );
                        }
                      )}
                    {purchased && (
                      <React.Fragment>
                        <Icon name="icon-pp-purchased" style={{ marginRight: 4 }} />
                        <Paragraph>Permit Package Purchased</Paragraph>
                      </React.Fragment>
                    )}
                  </FlexColumn>
                </TagRow>
              </CardContainer>
            );
          }
        )}
    </ListContainer>
  );
};

export { CardList };
