import type {
  JSONSchema7, JSONSchema7Type
} from 'json-schema';
import type { UiSchema } from '@rjsf/utils';
import type { FormData } from '../FormOptionsRulesAndState';
import {
  findFormSchemaField, removeEnumByIndex, updateDefaultValue
} from '../effects/util';

function doExclude(
  field: string,
  schema: JSONSchema7,
  arrayOfEnumsToExclude: JSONSchema7Type[],
  formData: FormData
) {
  const fieldInSchema = findFormSchemaField(
    field, schema.properties
  );
  if (!fieldInSchema) {
    return;
  }
  const fieldEnums = fieldInSchema.enum;
  arrayOfEnumsToExclude.forEach((toExclude): void => {
    const index = fieldEnums?.indexOf(toExclude) ?? -1;
    if (index !== -1) {
      removeEnumByIndex(
        schema, field, index
      );
    }
  });
  updateDefaultValue(
    field, fieldInSchema, formData
  );
}

type HideOptions = {
  field: string,
  options: JSONSchema7Type[]
}

/**
 * Removes enum options that are present in `options` array
 *
 * @param field field id
 * @param options array of options that should be hidden
 * @param options
 * @param schema
 * @param uiSchema
 * @param formData
 * @returns {{schema: *, uiSchema: *}}
 */
export default function hideOptions(
  { field, options }: HideOptions,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
) {
  doExclude(
    field.split('.')[1],
    schema,
    options,
    formData
  );
}
