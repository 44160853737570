import {
  LyraButtons, LyraWarningModal
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, { useState } from 'react';
import {
  Col, Row
} from 'react-flexbox-grid';
import type { ProjectReadinessProperties } from '../../../../domain/models/SiteDesign/ProjectReadiness';
import useStore from '../../../../stores/useStore';
import type { MissingPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/MissingProperties/MissingPropertiesViewModel';

type MissingPropertiesProps = BaseProps & {
  missingProperties: ProjectReadinessProperties;
};

const MissingProperties = observer(({ missingProperties }: MissingPropertiesProps): ReactElement => {
  const {
    siteEquipmentProperties, environmentalProperties, projectProperties, buildingList
  } = missingProperties;

  const { modal } = useStore();
  const [missingPropertiesViewModel] = useState(modal.viewModel as MissingPropertiesViewModel);

  const getColumns = (missingproperties: ProjectReadinessProperties): number => {
    let total = 0;
    if (missingproperties.buildingList && missingproperties.buildingList.length) {
      total += 1;
    }
    if (missingproperties.environmentalProperties) {
      total += 1;
    }
    if (missingproperties.projectProperties) {
      total += 1;
    }
    if (missingproperties.siteEquipmentProperties) {
      total += 1;
    }
    total = total ?? 1;
    return total;
  };

  const columnSize = 12 / getColumns(missingProperties);

  return (
    <Row>
      {siteEquipmentProperties ? (
        <Col sm={columnSize}>
          <LyraWarningModal.ButtonList
            iconName="site-equipment-icon"
            label="Site Equipment"
            buttons={siteEquipmentProperties.missingProperties}
          />
        </Col>
      ) : (
        <></>
      )}
      {environmentalProperties ? (
        <Col sm={columnSize}>
          <LyraWarningModal.ButtonList
            label="Environmental Properties"
            iconName="environmental-parameters-icon"
            buttons={environmentalProperties.buttonList}
          />
        </Col>
      ) : (
        <></>
      )}

      {projectProperties ? (
        <Col sm={columnSize}>
          <LyraWarningModal.GroupPanel
            iconName="project-properties-icon"
            title="Project Properties"
            missingProperties={projectProperties.missingProperties}
            ctaButton={
              <LyraButtons.CTAButton
                label="Open Project Properties"
                trigger={(): void => missingPropertiesViewModel.openSiteInfo()}
                fullWidth={false}
              />
            }
          />
        </Col>
      ) : (
        <></>
      )}
      {buildingList?.length ? (
        <Col sm={columnSize}>
          <LyraWarningModal.PropertyGroup
            buildingName="Building Properties"
            iconName="building-properties-icon"
            groups={buildingList}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
});

export default MissingProperties;
