import type { LyraTable } from '@aurorasolar/lyra-ui-kit';
import type { IObservableArray } from 'mobx';
import {
  action, computed, observable
} from 'mobx';
import {
  MathUtils, Vector2
} from 'three';
import defer from 'lodash/defer';
import type { IVertexData } from '../../../domain/entities/SiteDesign/Vertex';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type {
  IControlSelectionChange,
  IPointerHoveringControlEvent
} from '../../../stores/EditorStore/Controls/ControlEvents';
import type { PropertiesStore } from '../../../stores/UiStore/Properties/Properties';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import {
  ADD_MODULE_ID,
  CHANGE_ORIENTATION_ID,
  PANNING_TOOL_ID,
  REMOVE_MODULE_ID,
  REVIEW_CIRCUITS_ID,
  SELECT_TOOL_ID,
  SET_BACK_ID,
  STRINGING_ID
} from '../../../stores/UiStore/ToolbarStore/Design/constants';
import { typesToSupportSetBack } from '../../../stores/UiStore/ToolbarStore/Design/SetBackTool';
import type {
  IHandleHoverTool, IHandleSelectionTool
} from '../../../stores/UiStore/ToolbarStore/Tool';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import ProjectionUtil from '../../../utils/projectionUtil';
import type { IPolygon } from '../../entities/Polygon/IPolygon';
import { Segment } from '../../graphics/Segment';
import { isRoofFace } from '../../Guards';
import {
  ERROR, SceneObjectType
} from '../../models/Constants';
import type { Design } from '../../models/Design/Design';
import type { IPolygonWithHoles } from '../../models/Polygon/IPolygon';
import type {
  Layout, IPvModulePositionData
} from '../../models/RoofTopArray/Layout';
import type { RoofFace } from '../../models/SiteDesign/RoofFace';
import PvModulePosition from '../../models/SiteDesign/PvModulePosition';
import { DesignReadiness } from '../../models/SiteDesign/DesignReadiness';
import Pathway from '../../models/SiteDesign/Pathway';
import VentilationSetback from '../../models/SiteDesign/VentilationSetback';
import type { IUpdateFireVentilationAreasRequest } from '../../request/ArrayPlacement/IUpdateFireVentilationAreasRequest';
import type { IPvArrayAreaChangeRequest } from '../../request/PvArrayAreaChangeRequest/IPvArrayAreaChangeRequest';
import {
  ERoofSlopeType, Units
} from '../../typings';
import type { INewLayoutStrategyStageDeps } from '../CreationDesignStages/NewLayoutStrategyStage';
import { NewLayoutStrategyStage } from '../CreationDesignStages/NewLayoutStrategyStage';
import type { INewSystemDesignStageDeps } from '../CreationDesignStages/NewSystemDesignStage';
import { NewSystemDesignStage } from '../CreationDesignStages/NewSystemDesignStage';
import type { INewSystemSizeStageDeps } from '../CreationDesignStages/NewSystemSizeStage';
import { NewSystemSizeStage } from '../CreationDesignStages/NewSystemSizeStage';
import type { IMountingSystemDefinitionsStageDeps } from '../CreationDesignStages/MountingSystemDefinitionsStage';
import { MountingSystemDefinitionsStage } from '../CreationDesignStages/MountingSystemDefinitionsStage';
import type { StageFactoryParameters } from '../StageFactory';
import { WizardStager } from '../WizardStager';
import type { DesignDelta } from '../../../domain/entities/Design/DesignDelta';
import { DesignStep } from '../../models/Design/DesignState';
import { isWithin } from '../../models/Limit';
import type {
  FireVentilation,
  IRestrictedAreaData,
  RestrictedAreaType
} from '../../models/RoofTopArray/FireVentilation';
import { restrictedAreaWidthInInches } from '../../models/RoofTopArray/FireVentilation';
import { PropsPanelUICodes } from '../../../stores/UiStore/Properties/propertiesStoreConstants';
import type { ModalStore } from '../../../stores/UiStore/Modal/Modal';
import { SteepSlopeViewModel } from '../../../stores/UiStore/Modal/ViewModels/SteepSlopeViewModel/SteepSlopeViewModel';
import { LowSlopeViewModel } from '../../../stores/UiStore/Modal/ViewModels/LowSlopeViewModal.ts/LowSlopeViewModal';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import type { Drawable } from '../../mixins/Drawable';
import {
  getLyraModelByMesh, getParentLyraModelByMesh
} from '../../sceneObjectsWithLyraModelsHelpers';
import {
  CancellablePromiseKeys, cancelablePromise
} from '../../../utils/CancellablePromise';
import { KeyboardListener } from '../../../utils/KeyboardListener';
import { getRootStore } from '../../../stores/RootStoreInversion';
import type { WorkspaceStore } from '../../../stores/UiStore/WorkspaceStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { Selectable } from '../../mixins/Selectable';
import {
  handleApiError, notify
} from '../../../utils/helpers';
import {
  getEdgeIndex, segmentEqualsOtherSegment
} from '../../../utils/spatial';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import type { IWizardStage } from '../IWizardStage';
import { HoverBehaviour } from '../../behaviour/HoverBehaviour';
import { KeyboardBehaviour } from '../../behaviour/KeyboardBehaviour';
import { SelectionBehaviour } from '../../behaviour/SelectionBehaviour';
import {
  ArrayPlacementCompleted,
  FireVentilationAreasUpdatedEvent,
  ArrayAreasSelectedEvent
} from '../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../config/config';

export interface IArrayPlacementDependencies {
  readonly editor: EditorStore;
  readonly domain: DomainStore;
  readonly designWorkspace: DesignWorkspace;
  readonly serviceBus: ServiceBus;
  readonly toolbar: ToolbarStore;
  readonly properties: PropertiesStore;
  readonly modal: ModalStore;
}

export type SegmentOrSetbackOrPathway = Segment | VentilationSetback | Pathway | null;
export type RestrictedAreaCategory = SceneObjectType.Setback | SceneObjectType.Pathway;

const KILO_FACTOR = 0.001;

export class ArrayPlacementStage implements IProgressStepperStage, IHandleSelectionTool, IHandleHoverTool {
  static readonly toolWhitelist: string[] = [PANNING_TOOL_ID, SELECT_TOOL_ID, SET_BACK_ID];

  static readonly toolBlacklist: string[] = [
    ADD_MODULE_ID,
    CHANGE_ORIENTATION_ID,
    REMOVE_MODULE_ID,
    REVIEW_CIRCUITS_ID,
    STRINGING_ID
  ];
  readonly propCodeUI = PropsPanelUICodes.ArrayPlacement;
  readonly title = 'Array Placement';
  readonly id: DesignStep = DesignStep.ARRAY_PLACEMENT;

  readonly unit: string = 'kW';

  private arrayAreasSelectedForSolar: IObservableArray<RoofFace> = observable([]);

  @observable
  private _selectedItem?: SegmentOrSetbackOrPathway;

  private readonly editor: EditorStore;
  private readonly domain: DomainStore;
  private readonly designWorkspace: DesignWorkspace;
  private readonly serviceBus: ServiceBus;
  private readonly selectionBehaviour: SelectionBehaviour;
  private readonly hoverBehaviour: HoverBehaviour;
  private readonly modal: ModalStore;
  private readonly toolbar: ToolbarStore;
  private readonly designService = new DesignService();
  private hasMissingProperties: boolean = false;
  private readonly properties: PropertiesStore;
  private loadingArrayAreas: boolean = false;

  constructor(dependencies: IArrayPlacementDependencies) {
    const {
      editor, domain, designWorkspace, serviceBus, toolbar, properties, modal
    } = dependencies;
    this.editor = editor;
    this.domain = domain;
    this.designWorkspace = designWorkspace;
    this.serviceBus = serviceBus;
    this.toolbar = toolbar;
    this.modal = modal;
    this.properties = properties;
    this.selectionBehaviour = new SelectionBehaviour(this.editor);
    this.hoverBehaviour = new HoverBehaviour(this.editor);
    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @computed
  get domainModel(): Design {
    return this.domain.design;
  }

  @computed
  get totalTargetSizeInKw(): number {
    return this.domainModel.designSpecification.dcPowerRatingTargetInKw;
  }

  // Current target size in %
  @computed
  get currentTargetSize(): number {
    const powerRatingTargetInWatts = this.totalTargetSizeInKw * 1000;
    const pvModulePowerRatingInWatts = this.domainModel.supplementalData.pvModuleInfo?.powerRating ?? 0;
    const totalSizeInWatts = this.domainModel.roofTopArrayAreas.totalSizeInWatts(pvModulePowerRatingInWatts);
    const percentageOfTargetPowerRatingReached = Math.round((totalSizeInWatts / powerRatingTargetInWatts) * 100);
    return percentageOfTargetPowerRatingReached;
  }

  @computed
  get selectedItem(): SegmentOrSetbackOrPathway | undefined {
    return this._selectedItem;
  }

  @computed
  get selectedItemName(): string {
    return this._selectedItem?.type ?? '';
  }

  private get selectedRestrictedAreaCategory(): RestrictedAreaCategory {
    return this._selectedItem instanceof VentilationSetback
      || (this._selectedItem instanceof Segment && typesToSupportSetBack.includes(this._selectedItem.type!))
      ? SceneObjectType.Setback
      : SceneObjectType.Pathway;
  }

  private get workspaceObj(): WorkspaceStore {
    return getRootStore().workspace;
  }

  @computed
  get canContinue(): boolean {
    return !this.hasMissingProperties;
  }

  @computed
  get layoutsTableData(): LyraTable.DataProps[] {
    const roofTopArrayAreas = this.domainModel.roofTopArrayAreas;
    const mountingSystems = this.domainModel.system.equipment.mountingSystems;

    const layoutTableData: LyraTable.DataProps[] = [];

    roofTopArrayAreas.layouts.forEach((layout: Layout): void => {
      const roofFace = this.arrayAreasSelectedForSolar.find(
        (item: RoofFace): boolean => item.serverId === layout.arrayAreaId
      );
      const mountingSystem = mountingSystems.mountingSystemOn(layout.arrayAreaId);

      if (!roofFace || !mountingSystem) {
        return;
      }

      const pvModulePowerRating = this.domainModel.supplementalData?.pvModuleInfo?.powerRating ?? 0;
      const sizeInKw = layout.sizeInWatts(pvModulePowerRating) * KILO_FACTOR;

      layoutTableData.push({
        name: roofFace.name,
        level: this.domain.getLevelOfRoofFace(roofFace),
        unit: this.unit,
        wattage: sizeInKw.toFixed(2),
        modules: layout.numberOfPositions,
        positioning: mountingSystem.layoutStrategy.icon,
        arrayAreaId: layout.arrayAreaId,
        roofFace
      });
    });

    return layoutTableData;
  }

  continue(): void {
    this.arrayAreasSelectedForSolar.forEach((roofFace: RoofFace): void => roofFace.hidePotentialInfo());
    this.toolbar.deselectTool();
    this.disposeEventListeners();
    config.analytics?.trackEvent(new ArrayPlacementCompleted(this.domain));
  }

  cancel(): void {
    return;
  }

  @action
  setSelectedItem(selectedItem: SegmentOrSetbackOrPathway): void {
    this._selectedItem?.unselect();
    this._selectedItem = selectedItem;
    this._selectedItem?.select();

    const propertyPanel = this._selectedItem ? PropsPanelUICodes.SetbackPathwayProps : PropsPanelUICodes.ArrayPlacement;
    this.properties.setPropertyPanel(propertyPanel);
  }

  /**
   * If a setback or a pathway is selected, then we change its width
   * If a segment (edge) is selected, we create a new setback or pathway, based on the edgetype
   */
  @action
  async changeRestrictedAreaWidthForSelectedEdge(restrictedAreaType: RestrictedAreaType): Promise<void> {
    if (this.domain.guards.pathwaysLoading) {
      return;
    }

    try {
      this.domain.guards.pathwaysLoading = true;

      const fireVentilation = this.domainModel.roofTopArrayAreas.fireVentilation;
      if (!this._selectedItem) {
        return;
      }
      const roofFace: RoofFace =
        this._selectedItem instanceof Segment
          ? getParentLyraModelByMesh<RoofFace>(this._selectedItem.mesh, 2)
          : getParentLyraModelByMesh<RoofFace>(this._selectedItem.mesh);
      if (!roofFace.serverId) {
        // eslint-disable-next-line no-console
        console.warn('Could not determine RoofFace from selected item');
        return;
      }
      const selectedGeometry: IVertexData[] =
        this._selectedItem instanceof Segment ? [...this._selectedItem.points] : [...this._selectedItem.getVector3s()];
      const roofFaces = this.domain.allRoofFaces;

      // check if it's a shared edge...
      const twinEdgeData = this.getTwinEdgeData(roofFaces, roofFace);

      const restrictedAreaServerId = this._selectedItem.serverId || MathUtils.generateUUID();
      const restrictedAreaCategory = this.selectedRestrictedAreaCategory;

      await this.updateEdge(
        roofFace,
        getEdgeIndex(selectedGeometry, roofFace.getVector3s())?.index,
        restrictedAreaType,
        fireVentilation,
        restrictedAreaServerId,
        restrictedAreaCategory
      );

      if (twinEdgeData.edgeIndex > -1) {
        const newFireVentilation = this.domainModel.roofTopArrayAreas.fireVentilation;
        await this.updateEdge(
          twinEdgeData.roofFace as RoofFace,
          twinEdgeData.edgeIndex,
          restrictedAreaType,
          newFireVentilation,
          restrictedAreaServerId,
          restrictedAreaCategory
        );
      }
      config.analytics?.trackEvent(new FireVentilationAreasUpdatedEvent(this.domain));
    } finally {
      // There is the problem with MobX and React integration.
      // React's functional component is executed with no errors, but the actual DOM is not getting updated.
      // Multiple updates (relevant for the SetbackPathwayProps component) to the observable state are made synchronously.
      // They use an "exotic memo" component under the hood, and that's where the chain of updates is broken.
      // So, it can be fixed by timing the second update later.
      defer((): void => {
        this.domain.guards.pathwaysLoading = false;
      });
    }
  }

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_BACKSPACE || event.key === KeyboardListener.KEY_DELETE) {
      this.removeSelectedItem();
    }
  };

  onKeyDown = (event: KeyboardEvent): void => {
    // Not implemented
  };

  @action
  async removeSelectedItem(): Promise<void> {
    if (this.domain.guards.pathwaysLoading) {
      return;
    }

    try {
      this.domain.guards.pathwaysLoading = true;

      const selectedItem = this._selectedItem;
      if (selectedItem instanceof VentilationSetback || selectedItem instanceof Pathway) {
        const parentRoofFace = selectedItem.parentRoofFace as RoofFace;
        const hideLoader: () => void = parentRoofFace
          ? parentRoofFace.showLoader()
          : (): void => {
            /* no-op */
          };

        const request: IUpdateFireVentilationAreasRequest = {
          fireVentilation: this.domainModel.roofTopArrayAreas.fireVentilation
            .copyWithRestrictedAreaRemoved(selectedItem.serverId, parentRoofFace.serverId)
            .toData(),
          design: this.domainModel.toData()
        };
        const response = await this.designService
          .updateFireVentilationAreas(request)
          .catch(handleApiError('Failed to update fire ventilation areas'));
        this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        config.analytics?.trackEvent(new FireVentilationAreasUpdatedEvent(this.domain));

        // Deselecting visual representation of setback/pathway, then removing it from the 3d world
        this.setSelectedItem(null);
        selectedItem.removeFromScene();

        this.redrawRoofTopArrayAreas();
        hideLoader();
      }
    } finally {
      defer((): void => {
        this.domain.guards.pathwaysLoading = false;
      });
    }
  }

  editDefaultLayoutStrategy(): void {
    const layoutStrategyStage: StageFactoryParameters<INewLayoutStrategyStageDeps, IWizardStage> = {
      c: NewLayoutStrategyStage,
      dependencies: {
        isDesignCreation: false,
        domain: this.domain,
        serviceBus: this.serviceBus,
        editor: this.editor,
        onContinue: this.redrawRoofTopArrayAreas
      }
    };
    this.designWorkspace.setWizardStagerToWorkspace(
      new WizardStager({
        stageFactoryParameters: [layoutStrategyStage],
        designWorkspace: this.designWorkspace
      })
    );
  }

  editPvModuleDefinition(): void {
    const pvModuleDefinitionStage: StageFactoryParameters<INewSystemDesignStageDeps, IWizardStage> = {
      c: NewSystemDesignStage,
      dependencies: {
        isDesignCreation: false,
        domain: this.domain,
        serviceBus: this.serviceBus,
        editor: this.editor,
        onContinue: this.redrawRoofTopArrayAreas
      }
    };
    this.designWorkspace.setWizardStagerToWorkspace(
      new WizardStager({
        stageFactoryParameters: [pvModuleDefinitionStage],
        designWorkspace: this.designWorkspace
      })
    );
  }

  editMountingSystemDefinitions(): void {
    const mountingSystemDefinitionsStage: StageFactoryParameters<IMountingSystemDefinitionsStageDeps, IWizardStage> = {
      c: MountingSystemDefinitionsStage,
      dependencies: {
        isDesignCreation: false,
        domain: this.domain,
        serviceBus: this.serviceBus,
        editor: this.editor,
        onContinue: this.syncSelectedArrayAreasFromDomain
      }
    };
    this.designWorkspace.setWizardStagerToWorkspace(
      new WizardStager({
        stageFactoryParameters: [mountingSystemDefinitionsStage],
        designWorkspace: this.designWorkspace
      })
    );
  }

  editTargetSystemSize(): void {
    const targetSystemSizeStage: StageFactoryParameters<INewSystemSizeStageDeps, IWizardStage> = {
      c: NewSystemSizeStage,
      dependencies: {
        isDesignCreation: false,
        domain: this.domain,
        serviceBus: this.serviceBus,
        onContinue: this.redrawRoofTopArrayAreas
      }
    };
    this.designWorkspace.setWizardStagerToWorkspace(
      new WizardStager({
        stageFactoryParameters: [targetSystemSizeStage],
        designWorkspace: this.designWorkspace
      })
    );
  }

  dispose(): void {
    this.toolbar.deselectTool();
    this.disposeEventListeners();
    this.cleanRoofTopArrayAreas();
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  setUp = (): void => {
    this.disableTools();
    this.setDesignState();

    this.paintRoofFaces();
    if (this.arrayAreasSelectedForSolar.length === 0) {
      this.autoselectArrayAreasForSolar();
    } else {
      this.redrawRoofTopArrayAreas();
    }
    this.toolbar.activateToolInDesignWorkspaceWithoutClick(SELECT_TOOL_ID, this);
    this.editor.renderSiteMarkers(this.designWorkspace);
  };

  setUpTool = (toolId: string): void => {
    if (toolId === SELECT_TOOL_ID) {
      // Initialize selection control
      this.selectionBehaviour.setTargetObjects(this.domain.allRoofFaces, false);
      this.selectionBehaviour.addSelectionChangeEvent(this);
      // Initialize Hover control
      this.hoverBehaviour.setTargetObjects(this.domain.allRoofFaces);
      this.hoverBehaviour.addHoverEvents(this);
    }
  };

  updateDataTableArea(arrayAreaIds: string[]): void {
    const arrayAreaPayload: IPvArrayAreaChangeRequest = {
      arrayAreaIds,
      design: this.domainModel.toData()
    };
    this.designService
      .changeArrayAreas(arrayAreaPayload)
      .then((response: DesignDelta): void => {
        if (!response.isEmpty()) {
          this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
          this.redrawRoofTopArrayAreas();
        }
      })
      .catch(handleApiError('Failed to change array areas', arrayAreaPayload));
  }

  onClickLayoutStrategyTable(arrayAreaId: string, roofFace: RoofFace): void {
    if (roofFace.slopeType === ERoofSlopeType.SteepSlope) {
      const modalData = new SteepSlopeViewModel({
        modal: this.modal,
        domain: this.domain,
        designWorkspace: this.designWorkspace,
        serviceBus: this.serviceBus,
        editor: this.editor,
        roofFace
      });
      this.modal.createModal('steep_slope_modal', modalData);
    }
    if (roofFace.slopeType === ERoofSlopeType.LowSlope) {
      const modalData = new LowSlopeViewModel({
        modal: this.modal,
        domain: this.domain,
        designWorkspace: this.designWorkspace,
        serviceBus: this.serviceBus,
        editor: this.editor,
        roofFace
      });
      this.modal.createModal('low_slope_modal', modalData);
    }
  }

  resume(lastValidStage: string): void {
    if (lastValidStage === this.id) {
      this.setUp();
    } else {
      this.paintRoofFaces();
      this.renderRoofTopArrayAreas();
    }
  }

  async beforeContinue(continueBackwards: boolean = false): Promise<void> {
    if (!continueBackwards) {
      await this.validateMissingProperties();
    }
  }
  async validateMissingProperties(): Promise<void> {
    const nextStep = this.designWorkspace.stageManager!.currentIndex + 1;
    const nextStage = this.designWorkspace.stageManager!.getStageData(nextStep);
    if (nextStage) {
      const missingPropertiesResponse = await this.designService
        .getDesignMissingProperties(this.domainModel.id, nextStage.id)
        .catch(handleApiError('Failed to get missing design properties'));
      const designReadiness = new DesignReadiness({
        missingPropertiesResponse
      });
      this.hasMissingProperties = designReadiness.hasMissingProperties();
      designReadiness.createNotifications();
    }
  }

  onObjectHoverIn = (event: IPointerHoveringControlEvent): void => {
    if (!event.hoverObject || this.toolbar.selectedTool?.id !== SELECT_TOOL_ID) {
      return;
    }

    const hoveredRoofFace: RoofFace | undefined =
      (event.hoverObject && isRoofFace(event.hoverObject) && getLyraModelByMesh(event.hoverObject.mesh)) || undefined;
    if (!hoveredRoofFace) {
      return;
    }

    if (this.arrayAreasSelectedForSolar.length < 2) {
      const hoveredRoofFaceIsSelectedForSolar = this.arrayAreasSelectedForSolar.some(
        (arrayArea: RoofFace): boolean => arrayArea.serverId === hoveredRoofFace.serverId
      );
      if (!hoveredRoofFaceIsSelectedForSolar) {
        hoveredRoofFace.hoverInPotentialInfo();
      }
    } else {
      hoveredRoofFace.hoverInPotentialInfo();
    }
  };

  onObjectHoverOut = (event: IPointerHoveringControlEvent): void => {
    if (!event.hoverObject) {
      return;
    }

    const hoverOutRoofFace: RoofFace | undefined =
      (event.hoverObject && isRoofFace(event.hoverObject) && getLyraModelByMesh(event.hoverObject.mesh)) || undefined;
    hoverOutRoofFace?.hoverOutPotentialInfo();
  };

  onSelectionChange = (event: IControlSelectionChange): void => {
    if (
      event.selection === undefined
      || event.unselected === undefined
      || event.selection.length === 0
      || this.loadingArrayAreas
    ) {
      return;
    }

    this.loadingArrayAreas = true;

    event.selection.forEach((selectedObject: Selectable): void => {
      const roofFace = selectedObject as unknown as Drawable;
      if (isRoofFace(roofFace)) {
        const roofFaceIsSelectedForSolar = this.arrayAreasSelectedForSolar.some(
          (target: RoofFace): boolean => target.serverId === roofFace.serverId
        );
        if (roofFaceIsSelectedForSolar) {
          if (this.arrayAreasSelectedForSolar.length > 1) {
            this.removeArea(roofFace).finally((): void => {
              this.loadingArrayAreas = false;
            });
          } else {
            this.loadingArrayAreas = false;
          }
        } else {
          this.addArea(roofFace).finally((): void => {
            this.loadingArrayAreas = false;
          });
        }
      }
    });

    this.selectionBehaviour.clearSelection();
  };

  /**
   * Cleans and re-renders `roofTopArrayAreas` (i.e. PV module positions + fire ventilation setbacks and pathways)
   */
  redrawRoofTopArrayAreas = (): void => {
    this.cleanRoofTopArrayAreas();
    this.renderRoofTopArrayAreas();
  };

  private syncSelectedArrayAreasFromDomain = (): void => {
    this.cleanRoofTopArrayAreas();

    this.domain.allRoofFaces
      .filter((roofFace: RoofFace): boolean => !this.domainModel.roofTopArrayAreas.hasLayoutOn(roofFace.serverId))
      .forEach((roofFaceWithNoLayout: RoofFace): void => {
        this.arrayAreasSelectedForSolar.remove(roofFaceWithNoLayout);
        roofFaceWithNoLayout.setRoofFaceIsUsedForSolar(this.editor.font, false);
      });

    this.renderRoofTopArrayAreas();
  };

  private autoselectArrayAreasForSolar(): Promise<void> {
    const allRoofFaces: RoofFace[] = this.domain.allRoofFaces;
    if (allRoofFaces.length > 3) {
      // More than 3 roof faces exist - let the user select which ones to use for solar
      allRoofFaces.forEach((roofFace: RoofFace): void => roofFace.setRoofFaceIsUsedForSolar(this.editor.font, false));
      this.editor.updateUnzoomable();
      return Promise.resolve();
    }

    // One/two roof faces exist - we assume the user wants to use them for solar
    const roofFacesToBeUsedForSolar: RoofFace[] = allRoofFaces.filter((roofFace: RoofFace): boolean =>
      this.isRoofCompatibleWithAvailableMountingSystem(roofFace)
    );

    this.arrayAreasSelectedForSolar.push(...roofFacesToBeUsedForSolar);
    return this.placeArrayAreas(roofFacesToBeUsedForSolar);
  }

  private isRoofCompatibleWithAvailableMountingSystem(roofFace: RoofFace): boolean {
    const mountingDefinitions = this.domain.design.system.equipment.mountingSystems;

    if (!mountingDefinitions.definitions.hasDefinition(roofFace.slopeType!)) {
      return false;
    }

    const isLowSlope = roofFace.slopeType == ERoofSlopeType.LowSlope;

    return isWithin(
      isLowSlope
        ? mountingDefinitions.definitions.lowSlope!.acceptableSlopeLimits
        : mountingDefinitions.definitions.steepSlope!.acceptableSlopeLimits,
      roofFace.slope ?? -1
    );
  }

  private async placeArrayAreas(areas: RoofFace[]): Promise<void> {
    const loaderHiders: { (): void }[] = [];
    this.arrayAreasSelectedForSolar.forEach((area: RoofFace): void => {
      area.setRoofFaceIsUsedForSolar(this.editor.font, false);
      loaderHiders.push(area.showLoader());
    });

    const arrayAreaPayload: IPvArrayAreaChangeRequest = {
      arrayAreaIds: areas
        .filter((roofFace: RoofFace): boolean => this.isRoofCompatibleWithAvailableMountingSystem(roofFace))
        .map((roofFace: RoofFace): string => roofFace.serverId),
      design: this.domainModel.toData()
    };

    try {
      const response = await cancelablePromise<DesignDelta>(
        CancellablePromiseKeys.ChangeArrayAreas,
        this.designService.changeArrayAreas(arrayAreaPayload)
      );

      if (!response.isEmpty()) {
        this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        config.analytics?.trackEvent(new ArrayAreasSelectedEvent(this.domain));
        this.renderRoofTopArrayAreas();
      }
    } catch (error) {
      handleApiError('Failed to update array areas')(error);
    } finally {
      loaderHiders.forEach((hideLoader: () => void) => {
        hideLoader();
      });
    }
  }

  private getTwinEdgeData(
    roofFaces: RoofFace[],
    originalRoofFace: RoofFace
  ): { edgeIndex: number; roofFace: RoofFace | null } {
    const twinEdgeData: {
      edgeIndex: number;
      roofFace: RoofFace | null;
    } = {
      edgeIndex: -1,
      roofFace: null
    };

    const pointsToCheck: Vector2[][] = [];
    if (this._selectedItem instanceof Segment) {
      const originalSegment = this._selectedItem;
      const originalPoints = originalSegment.points.map((point: IVertexData): Vector2 => new Vector2(point.x, point.y));
      pointsToCheck.push(originalPoints);
    }
    if (this._selectedItem instanceof VentilationSetback) {
      pointsToCheck.push(...this._selectedItem.segmentsPointPairs);
    }
    if (!pointsToCheck.length) {
      return twinEdgeData;
    }
    for (const originalPoints of pointsToCheck) {
      for (const roofFace of roofFaces) {
        if (roofFace.serverId !== originalRoofFace.serverId) {
          const rfVertices = roofFace.getVector3s();
          for (let i = 0; i < rfVertices.length - 1; ++i) {
            const potentialTwinPoints = [
              new Vector2(rfVertices[i].x, rfVertices[i].y),
              new Vector2(rfVertices[i + 1].x, rfVertices[i + 1].y)
            ];

            const areEdgesOnTheSameCoords = segmentEqualsOtherSegment(
              {
                A: originalPoints[0],
                B: originalPoints[1]
              },
              {
                A: potentialTwinPoints[0],
                B: potentialTwinPoints[1]
              }
            );

            if (areEdgesOnTheSameCoords) {
              twinEdgeData.roofFace = roofFace;
              twinEdgeData.edgeIndex = i;
              break;
            }
          }
        }
      }
    }
    return twinEdgeData;
  }

  private async updateEdge(
    roofFace: RoofFace,
    edgeIndex: number,
    restrictedAreaType: RestrictedAreaType,
    fireVentilation: FireVentilation,
    restrictedAreaId: string,
    restrictedAreaCategory: RestrictedAreaCategory
  ): Promise<void> {
    const targetWidthInInches = restrictedAreaWidthInInches(restrictedAreaType);
    const targetWidthInWorldUnits = ProjectionUtil.convertToWorldUnits(targetWidthInInches, Units.Inches);

    const roofFaceVertices = roofFace.getVector3s();
    const payload = {
      roofFace: roofFaceVertices.slice(0, roofFaceVertices.length - 1),
      edgeIndex,
      width: targetWidthInWorldUnits
    };

    const hideLoader = roofFace.showLoader();

    const restrictedAreaPolygon = await this.designService
      .createFireVentilationArea(payload)
      .catch(handleApiError('Failed to create fire ventilation area'));

    const generatedRestrictedArea: IRestrictedAreaData = {
      id: restrictedAreaId,
      type: restrictedAreaType,
      polygon: restrictedAreaPolygon
    };
    await this.updateFireVentilationWithNewData(
      fireVentilation,
      roofFace.serverId,
      restrictedAreaCategory,
      generatedRestrictedArea
    );

    hideLoader();
  }

  private async updateFireVentilationWithNewData(
    fireVentilation: FireVentilation,
    roofFaceId: string,
    restrictedAreaCategory: RestrictedAreaCategory,
    restrictedArea: IRestrictedAreaData
  ): Promise<void> {
    const fireVentilationUpdateFunction =
      restrictedAreaCategory === SceneObjectType.Setback
        ? fireVentilation.copyWithVentilationSetbackAddedOrUpdated
        : fireVentilation.copyWithPathwayAddedOrUpdated;
    const request: IUpdateFireVentilationAreasRequest = {
      fireVentilation: fireVentilationUpdateFunction(roofFaceId, restrictedArea).toData(),
      design: this.domainModel.toData()
    };
    const response = await this.designService
      .updateFireVentilationAreas(request)
      .catch(handleApiError('Failed to update fire ventilation areas'));
    this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
    this.redrawRoofTopArrayAreas();
    this.selectRestrictedAreaByServerId(restrictedArea.id);
  }

  private selectRestrictedAreaByServerId(id: string): void {
    this.domain.allRoofFaces.forEach((roofFace: RoofFace): void => {
      roofFace.ventilationSetbacks.forEach((setback: VentilationSetback): void => {
        if (setback.serverId === id) {
          this.setSelectedItem(setback);
        }
      });
      roofFace.pathways.forEach((pathway: Pathway): void => {
        if (pathway.serverId === id) {
          this.setSelectedItem(pathway);
        }
      });
    });
  }

  private disposeEventListeners = (): void => {
    this.hoverBehaviour.removeHoverEvents(this);
    this.selectionBehaviour.removeSelectionChangeEvent(this);
  };

  private async addArea(roofFace: RoofFace): Promise<void> {
    const mountingDefinitions = this.domain.design.system.equipment.mountingSystems.definitions;
    if (roofFace.slopeType === ERoofSlopeType.LowSlope && !mountingDefinitions.lowSlope) {
      notify(
        `Roof face ${roofFace.name} cannot be selected for solar because no mounting system`
          + ' has been specified for low-slope roofs.',
        ERROR
      );
    } else if (roofFace.slopeType === ERoofSlopeType.SteepSlope && !mountingDefinitions.steepSlope) {
      notify(
        `Roof face ${roofFace.name} cannot be selected for solar because no mounting system`
          + ' has been specified for steep-slope roofs.',
        ERROR
      );
    } else if (!this.isRoofCompatibleWithAvailableMountingSystem(roofFace)) {
      const currentMountingSystemDefinition =
        roofFace.slopeType == ERoofSlopeType.LowSlope ? mountingDefinitions.lowSlope! : mountingDefinitions.steepSlope!;
      const maximumSlope = currentMountingSystemDefinition.maximumSlope.toFixed(1);
      const definitionName = currentMountingSystemDefinition?.name ?? '';
      notify(
        `Cannot use roof face for solar because ${roofFace.name} has slope of ${roofFace.slope}° and `
          + `${definitionName} is compatible with slopes up to ${maximumSlope}°.`,
        ERROR
      );
    } else {
      this.cleanRoofTopArrayAreas();
      this.arrayAreasSelectedForSolar.push(roofFace);
      await this.placeArrayAreas(this.arrayAreasSelectedForSolar);
    }
  }

  private async removeArea(roofFace: RoofFace): Promise<void> {
    this.cleanRoofTopArrayAreas();
    roofFace.setRoofFaceIsUsedForSolar(this.editor.font, false);
    this.arrayAreasSelectedForSolar.remove(roofFace);
    await this.placeArrayAreas(this.arrayAreasSelectedForSolar);
  }

  private cleanRoofTopArrayAreas(): void {
    this.arrayAreasSelectedForSolar.forEach((area: RoofFace): void => {
      area.removePvModulePositions();
      area.removeFireVentilationSetbacksAndPathways();
    });
  }

  /**
   * Renders `roofTopArrayAreas` (i.e. PV module positions + fire ventilation setbacks and pathways)
   */
  private renderRoofTopArrayAreas(): void {
    const areas: RoofFace[] = this.domain.allRoofFaces;
    const roofTopArrayAreas = this.domainModel.roofTopArrayAreas;
    if (this.workspaceObj.isCurrentWorkspaceProjectWorkspace()) {
      this.workspaceObj.currentWorkspace.repaintRoofFaces?.();
      return;
    }

    for (const roofFace of areas) {
      roofFace.removePvModulePositions();
      const layoutOnRoofFace = roofTopArrayAreas.layoutOn(roofFace.serverId);
      if (!layoutOnRoofFace) {
        continue;
      }

      const pvModulePowerRating = this.domainModel.supplementalData.pvModuleInfo?.powerRating ?? 0;
      const maxPotentialInKw = layoutOnRoofFace.sizeInWatts(pvModulePowerRating) * KILO_FACTOR;
      roofFace.setRoofFaceIsUsedForSolar(this.editor.font, true, maxPotentialInKw);
      const mountingSystemDefinition = this.domainModel.system.equipment.mountingSystems.definitionFor(
        layoutOnRoofFace.arrayAreaId
      );
      layoutOnRoofFace.forEachPosition((position: IPvModulePositionData): void => {
        const pvModulePosition = new PvModulePosition({
          id: position.id,
          orientation: position.orientation,
          positionVertices: position.polygon,
          rowSpacing: mountingSystemDefinition.rowSpacing,
          columnSpacing: mountingSystemDefinition.columnSpacing,
          designWorkspace: this.designWorkspace
        });
        pvModulePosition.setSelectedMode(false);
        roofFace.addPvModulePosition(pvModulePosition);
        pvModulePosition.addSpacing();
      });
    }

    for (const roofFace of areas) {
      roofFace.removeFireVentilationSetbacksAndPathways();
      const restrictedAreasOnRoofFace = roofTopArrayAreas.restrictedAreasOn(roofFace.serverId);
      if (!restrictedAreasOnRoofFace) {
        continue;
      }
      restrictedAreasOnRoofFace.forEachPathway((restrictedArea: IRestrictedAreaData): void => {
        const polygon: IPolygon | IPolygonWithHoles = restrictedArea.polygon;
        const pathway = new Pathway({
          pathwayVertices: polygon instanceof Array ? polygon : polygon.vertices,
          serverId: restrictedArea.id
        });
        roofFace.addPathway(pathway);
      });
      restrictedAreasOnRoofFace.forEachVentilationSetback((restrictedArea: IRestrictedAreaData): void => {
        const polygon: IPolygon | IPolygonWithHoles = restrictedArea.polygon;
        const setback = new VentilationSetback({
          setbackVertices: polygon instanceof Array ? polygon : polygon.vertices,
          serverId: restrictedArea.id
        });
        roofFace.addVentilationSetback(setback);
      });
    }

    this.editor.viewport.render();
    this.editor.updateUnzoomable();
  }

  private paintRoofFaces(): void {
    this.arrayAreasSelectedForSolar.clear();
    const allRoofFaces: RoofFace[] = this.domain.allRoofFaces;
    allRoofFaces.forEach((roof: RoofFace): void => {
      const layoutExists = this.domainModel.roofTopArrayAreas.hasLayoutOn(roof.serverId);
      if (layoutExists) {
        roof.setRoofFaceIsUsedForSolar(this.editor.font, true);
        this.arrayAreasSelectedForSolar.push(roof);
      } else {
        roof.setRoofFaceIsUsedForSolar(this.editor.font, false);
      }
    });
  }

  private disableTools(): void {
    this.toolbar.whitelistTools(ArrayPlacementStage.toolWhitelist);
    this.toolbar.blacklistTools(ArrayPlacementStage.toolBlacklist);
  }

  private setDesignState(): void {
    const dependencies = this.domainModel.state.withUserState(this.id).toUpdateStateCommand(this.domain);
    this.serviceBus.send('update_design_state', dependencies);
  }
}
