import {
  action, observable, runInAction, toJS
} from 'mobx';
import { handleApiError } from '../../../utils/helpers';
import type { MountingSystemAttributes } from '../../typings';
import {
  AC_MODULE, DC_MODULE
} from '../Constants';
import type { MountingSystemDefinitions } from '../MountingSystemDefinition/MountingSystemDefinitions';
import type { Inverters } from '../PvSystem/Inverters';
import type {
  IDcOptimizerDefinition, IInverterDefinitionData
} from '../Inverter';
import { EquipmentService } from '../../../infrastructure/services/api/EquipmentService';
import type { IInverterSelected } from './IInverterInfo';
import type { IPvModuleEquipmentDefinition } from './IPvModuleEquipmentDefinition';

export interface IPvModuleInfo {
  readonly make: string;
  readonly model: string;
  readonly type: 'MODULE' | 'AC_MODULE';
  readonly powerRating: number;
  readonly dimensions: {
    length: number;
    width: number;
  };
}

interface IDcOptimizerInfo {
  readonly model: string;
}

export interface ISupplementalData {
  readonly pvModuleInfo?: IPvModuleInfo;
  readonly steepSlopeMountingSystemInfo?: MountingSystemAttributes;
  readonly lowSlopeMountingSystemInfo?: MountingSystemAttributes;
  readonly invertersSelected?: IInverterSelected[];
  readonly optimizerInfo?: IDcOptimizerInfo;
}

/**
 * Supplemental design data
 */
export class SupplementalData {
  @observable
  pvModuleInfo?: IPvModuleInfo;
  @observable
  steepSlopeMountingSystemInfo?: MountingSystemAttributes;
  @observable
  lowSlopeMountingSystemInfo?: MountingSystemAttributes;
  invertersSelected?: IInverterSelected[];
  @observable
  optimizerInfo?: IDcOptimizerInfo;

  constructor(data?: ISupplementalData) {
    this.pvModuleInfo = data?.pvModuleInfo;
    this.steepSlopeMountingSystemInfo = data?.steepSlopeMountingSystemInfo;
    this.lowSlopeMountingSystemInfo = data?.lowSlopeMountingSystemInfo;
    this.invertersSelected = data?.invertersSelected;
    this.optimizerInfo = data?.optimizerInfo;
  }

  toData = (): ISupplementalData | undefined => {
    if (
      !this.pvModuleInfo
      && !this.steepSlopeMountingSystemInfo
      && !this.lowSlopeMountingSystemInfo
      && !this.invertersSelected
      && !this.optimizerInfo
    ) {
      return undefined;
    }
    return toJS(this);
  };

  copy = (): SupplementalData => new SupplementalData(this.toData());

  @action
  setPvModuleInfo = (pvModuleEquipmentDefinition: IPvModuleEquipmentDefinition): void => {
    this.pvModuleInfo = {
      make: pvModuleEquipmentDefinition.manufacturer.name,
      model: pvModuleEquipmentDefinition.model,
      type: pvModuleEquipmentDefinition.electricalComponents.inverter ? AC_MODULE : DC_MODULE,
      powerRating: pvModuleEquipmentDefinition.definitions.pvCellsDefinition.outputProfile.stc.power,
      dimensions: {
        length: pvModuleEquipmentDefinition.dimensionsAndWeights.length,
        width: pvModuleEquipmentDefinition.dimensionsAndWeights.width
      }
    };
    if (this.pvModuleInfo?.type === AC_MODULE) {
      this.invertersSelected = undefined;
      this.optimizerInfo = undefined;
    }
  };

  @action
  setMountingSystemInfo = (steepSlope?: MountingSystemAttributes, lowSlope?: MountingSystemAttributes): void => {
    this.steepSlopeMountingSystemInfo = steepSlope;
    this.lowSlopeMountingSystemInfo = lowSlope;
  };

  @action
  updatePvModuleInfo = async (pvModuleDefinitionId: string): Promise<void> => {
    const pvModuleDefinition: IPvModuleEquipmentDefinition = await new EquipmentService()
      .getPvModuleDefinition(pvModuleDefinitionId)
      .catch(handleApiError(`Failed to fetch PV module definition with ID ${pvModuleDefinitionId}`));
    this.setPvModuleInfo(pvModuleDefinition);
  };

  @action
  updateMountingSystemDataIfNeeded = async (mountingSystemDefinitions: MountingSystemDefinitions): Promise<void> => {
    const steepSlopeDefinitionId = mountingSystemDefinitions.steepSlope?.externalDefinitionId;
    const steepSlopeDataUpToDate = steepSlopeDefinitionId === this.steepSlopeMountingSystemInfo?.value;
    if (!steepSlopeDataUpToDate) {
      const attributes = steepSlopeDefinitionId
        ? await new EquipmentService().getMountingSystemAttributes(steepSlopeDefinitionId)
        : undefined;
      runInAction((): void => {
        this.steepSlopeMountingSystemInfo = attributes;
      });
    }
    const lowSlopeDefinitionId = mountingSystemDefinitions.lowSlope?.externalDefinitionId;
    const lowSlopeDataUpToDate = lowSlopeDefinitionId === this.lowSlopeMountingSystemInfo?.value;
    if (!lowSlopeDataUpToDate) {
      const attributes = lowSlopeDefinitionId
        ? await new EquipmentService().getMountingSystemAttributes(lowSlopeDefinitionId)
        : undefined;
      runInAction((): void => {
        this.lowSlopeMountingSystemInfo = attributes;
      });
    }
  };

  @action
  updatePowerConversionEquipmentInfo = (
    inverters: Inverters,
    firstInverterModel: string,
    secondInverterModel?: string,
    dcOptimizerModel?: string
  ): void => {
    this.invertersSelected = inverters.buildSupplementalData(firstInverterModel, secondInverterModel);
    this.optimizerInfo = dcOptimizerModel ? { model: dcOptimizerModel } : undefined;
  };

  /**
   * @description Fills supplementalData object with inverter and optimizer definition data. This data is essential
   * for stringing. Keep in mind that for stringing to work correctly it's required to have a call
   * ElectricalDesignStage.loadStringingOption. This call would enrich supplemental data with stringing options,
   * otherwise stringing would fail. It's not a problem if this call would run only on initial page load because
   * after that loadStringingOption would be called on ElectricalDesignStage. To recap - the logic of "recovering"
   * inverter/optimizer supplemental data has to be run only on page startup and only when there's a good reason
   * to do so (e.g. no correct inverter definition present in supplemental data).
   * @param inverters
   * @param firstInverterDefinitionId
   * @param secondInverterDefinitionId
   * @param dcOptimizerDefinitionId
   */
  @action
  updatePowerConversionEquipmentInfoIfNeeded = async (
    inverters: Inverters,
    firstInverterDefinitionId: string,
    secondInverterDefinitionId?: string,
    dcOptimizerDefinitionId?: string
  ): Promise<void> => {
    const firstInverterPresent = this.invertersSelected?.find(
      ({ definitionId }): boolean => definitionId === firstInverterDefinitionId
    );

    if (
      !firstInverterPresent
      /**
       * @summary This should account for a particular edge case of corrupt supplemental data for a second inverter
       * with the same definition as first one {@see https://aurorasolar.atlassian.net/browse/LYRA-7085}.
       */
      || (!!this.invertersSelected?.[1] && !this.invertersSelected[1]?.name)
    ) {
      const firstInverterDefinition = await new EquipmentService().getInverterDefinition(firstInverterDefinitionId);
      const secondInverterDefinition = secondInverterDefinitionId
        ? await new EquipmentService().getInverterDefinition(secondInverterDefinitionId)
        : undefined;
      const dcOptimizerDefinition = dcOptimizerDefinitionId
        ? await new EquipmentService().getDcOptimizerDefinition(dcOptimizerDefinitionId)
        : undefined;

      this.updatePowerConversionEquipmentInfo(
        inverters,
        this.getInverterModel(firstInverterDefinition)!,
        this.getInverterModel(secondInverterDefinition),
        this.getOptimizerModel(firstInverterDefinition, dcOptimizerDefinition)
      );
    }
  };

  private getInverterModel = (inverterDefinition?: IInverterDefinitionData): string | undefined => {
    return inverterDefinition ? `${inverterDefinition.manufacturer.name} ${inverterDefinition.model}` : undefined;
  };

  private getOptimizerModel = (
    firstInverterDefinition: IInverterDefinitionData,
    dcOptimizerDefinition?: IDcOptimizerDefinition
  ): string | undefined => {
    if (
      dcOptimizerDefinition
      && firstInverterDefinition.manufacturer.name !== dcOptimizerDefinition.manufacturer.name
      && !dcOptimizerDefinition.model.startsWith(firstInverterDefinition.manufacturer.name)
    ) {
      return `${dcOptimizerDefinition.manufacturer.name} ${dcOptimizerDefinition.model}`;
    }
    return dcOptimizerDefinition?.model;
  };
}
