import poleOfInaccessibilityAlgorithm from 'polylabel';
import type {
  Material, Object3D
} from 'three';
import {
  Color as ThreeColor, DoubleSide, MeshBasicMaterial, Vector3
} from 'three';

import isPositionCollinearToLines from '../../application/isPositionCollinearToLines';
import { vertexArrayToVector3Array } from '../../utils/helpers';
import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import { Boundary } from '../graphics/Boundary';
import { PolygonBufferGeometry } from '../graphics/PolygonBufferGeometry';
import { Vertex } from '../graphics/Vertex';
import type {
  Color, SelectableOpacity, Vector3D
} from '../typings';
import Vector2D from '../models/Vector2D';
import type { Selectable } from './Selectable';

// Assume the a polygon will have a maximum of 200 points
const MAX_POINTS = 200;

/**
 *  The precision used in the calculation to find the pole of inaccessibility.
 */
const POLE_OF_INACCESSIBILITY_ALGORITHM_PRECISION = 1.0;

export const PolygonDrawable = <T extends AnyConstructor<Selectable>>(PolygonDrawableBase: T) => {
  abstract class MixinClass extends PolygonDrawableBase {
    /**
     * Material
     */
    material!: Material;
    /**
     * Default Buffer Geometry
     */
    geometry!: PolygonBufferGeometry;
    /**
     * HEX representation of the color
     */
    override color!: Color;
    boundary!: Boundary;
    /**
     * Polygon border
     */

    /**
     * @see getPoleOfInaccessibility
     */
    cachedPoleOfInaccessibility?: Vector2D;

    /**
     * Opacity depending on selectable state
     */
    override selectableOpacity: SelectableOpacity;

    abstract layerNumber: number;

    constructor(...rest: any[]) {
      super();
      this.createMeshes();
      this.selectableOpacity = {
        whenSelected: 0.7,
        whenDeselected: 0.3
      };
    }
    /**
     * @deprecated Use boundary instead
     */
    get edge(): Boundary {
      return this.boundary;
    }
    /**
     * @deprecated Use boundary instead
     */
    set edge(newBoundary: Boundary) {
      this.boundary = newBoundary;
    }
    get polygon(): Vertex[] {
      return this.boundary.vertices;
    }

    abstract showVertices(): boolean;
    abstract dragVertices(): boolean;
    abstract showLines(): boolean;
    abstract showFill(): boolean;
    abstract hasFill(): boolean;
    abstract onClose(): void;
    abstract removeChildFromModel(object3D: Object3D): void;

    addVertex({
      vertex,
      removeIfCollinear,
      originatingFromTracing
    }: {
      vertex: Vector3;
      removeIfCollinear: boolean;
      originatingFromTracing: boolean;
    }): void {
      const validVertex = this.validateNewVertex(vertex);
      if (!validVertex) {
        return;
      }

      // Validate if the next point is collinear
      // If true, remove the last point added, and add the new point
      if (removeIfCollinear && isPositionCollinearToLines(this.boundary, new Vector2D(vertex.x, vertex.y))) {
        this.boundary.removeLast();
      }

      this.boundary.addVertex(
        vertex,
        // Presuming that the trace is valid, so that we can set hasEverBeenValidAfterUserInteraction right away
        originatingFromTracing
      );
      this.redraw();
    }

    getVector3s(): Vector3[] {
      return this.boundary.vector3s;
    }

    /**
     * Returns this.boundary.vertices as Vertex[] except the last element, because it's the same as the first
     */
    getUniqueVertices(): Vertex[] {
      return this.boundary.vertices.slice(0, -1);
    }

    setVerticesFromVector3s(vectors: Vector3[], originatingFromTracing: boolean = false): void {
      this.boundary.setVertices(vectors.map(Vertex.fromXyzValuesObject));
      this.boundary.vertices.forEach((vertex: Vertex): void => {
        vertex.hasEverBeenValidAfterUserInteraction = originatingFromTracing;
      });
      this.redraw();
    }
    setVertices(vertices: Vertex[]): void {
      this.boundary.setVertices(vertices.map(Vertex.fromAnotherVertex));
      this.redraw();
    }

    updateEdgeFactor(factor: number): void {
      this.boundary.vertices.forEach((vertex: Vertex): void => vertex.unzoom(factor));
    }

    removeLast(): void {
      if (!this.polygon.length) {
        return;
      }
      this.boundary.removeLast();
      this.redraw();
    }

    delete(): void {
      if (!this.polygon.length) {
        return;
      }
      this.boundary.removeAll();
      this.clearChildren(this.mesh);
    }

    redraw(): void {
      this.checkClosed();
      this.updateMesh();
    }

    hoverIn(): void {
      this.redraw();
    }

    hoverOut(): void {
      this.redraw();
    }

    /**
     * Computes the most distant internal point from the polygon outline. Similar to a centroid.
     * Useful for optimal placement of a text label on a polygon.
     */
    getPoleOfInaccessibility(recalculateIfCached?: boolean): Vector2D {
      if ((!this.cachedPoleOfInaccessibility || recalculateIfCached) && this.polygon.length) {
        const polygonCordinates: number[][] = this.polygon.map((vertex: Vertex): number[] => [vertex.x, vertex.y]);
        const poleOfInaccessibility = poleOfInaccessibilityAlgorithm(
          [polygonCordinates],
          POLE_OF_INACCESSIBILITY_ALGORITHM_PRECISION
        );
        this.cachedPoleOfInaccessibility = new Vector2D(poleOfInaccessibility[0], poleOfInaccessibility[1]);
      }
      return this.cachedPoleOfInaccessibility!;
    }

    updateMesh(): void {
      this.mesh.position.setZ(this.layerNumber);
      this.boundary.mesh.position.setZ(this.layerNumber);
      this.boundary.color = this.color;
      this.boundary.setVertexDragable(this.dragVertices());
      this.boundary.setEdgeVisibility(this.showLines());
      this.boundary.setVerticesVisibility(this.showVertices());
      this.boundary.redraw();

      if (this.hasFill() && this.polygon.length > 0) {
        this.geometry.update(vertexArrayToVector3Array(this.polygon));
        const material = this.material as MeshBasicMaterial;
        if (this.showFill()) {
          const {
            whenSelected, whenDeselected
          } = this.selectableOpacity;

          material.opacity = this.selected ? whenSelected : whenDeselected;
          material.color = new ThreeColor(this.color);
          material.needsUpdate = true;
        } else {
          material.color = new ThreeColor(this.color);
          material.opacity = 0;
          material.needsUpdate = true;
        }
        this.mesh.updateMatrix();
      }
    }

    /**
     * Create the meshes for fill and edge
     *
     */
    createMeshes(): void {
      this.createMeshPolygon();
      this.createMeshBoundary();
    }

    createMeshPolygon(): void {
      // Create geometry
      this.geometry = new PolygonBufferGeometry(MAX_POINTS);

      // Create Material
      this.material = new MeshBasicMaterial({
        side: DoubleSide,
        transparent: true
      });

      this.mesh.material = this.material;
      this.mesh.geometry = this.geometry;
    }

    createMeshBoundary(): void {
      this.mesh.children = [];
      this.boundary = new Boundary();
      this.mesh.add(this.boundary.mesh);
    }

    validateNewVertex(vertex: Vector3): boolean {
      return true;
    }

    checkClosed(): boolean {
      const { closed } = this.boundary;
      if (closed) {
        this.onClose();
      }
      return closed;
    }

    addVertices(vertices: Vector3D[], removeIfCollinear: boolean = false): void {
      /** Converting positions from pathways to Threejs objects */
      if (vertices.length) {
        const [firstVertex] = vertices.map(({
          x, y, z
        }: Vector3D): Vector3 => {
          const vertex = new Vector3(x, y, z);
          this.addVertex({
            vertex,
            removeIfCollinear,
            originatingFromTracing: false
          });

          return vertex;
        });

        // Adding the first vertex at the end in order to close the polygon
        this.addVertex({
          vertex: firstVertex,
          removeIfCollinear,
          originatingFromTracing: false
        });
      }
    }
  }
  return MixinClass;
};

export type PolygonDrawable = Mixin<typeof PolygonDrawable>;
