import { observer } from 'mobx-react-lite';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import useStore, { useUiStore } from '../../../stores/useStore';
import { SurfaceRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/SurfaceRoofFaceViewModel';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';
import IconMapper from '../../components/Icons';
import LineHr from '../../components/LineHr';
import {
  RoofPropertyButton, RoofPropertyButtonWrapper
} from './styles';
import { LABEL_SURFACE } from './constants';

const SurfaceRoofProperties = (): ReactElement => {
  const {
    domain, editor, serviceBus
  } = useStore();
  const { roofFaceProps } = useUiStore();
  const { firstRoofFaceSelected } = roofFaceProps;
  const [viewModel, setViewModel] = useState<SurfaceRoofFaceViewModel>();

  useEffect((): void => {
    setViewModel(
      roofFaceProps.createRoofFacePropertyEditor(SurfaceRoofFaceViewModel, {
        editor,
        roofFaceProps,
        domain,
        roofFace: firstRoofFaceSelected!,
        serviceBus
      })
    );
  }, [firstRoofFaceSelected, domain, editor, roofFaceProps, serviceBus]);

  if (!viewModel) {
    return <></>;
  }
  return (
    <>
      <LineHr />
      {viewModel.currentValue ? (
        <RoofPropertyEdition
          title="Roof Surface"
          onEditClick={(): void => roofFaceProps.changeShowPropertiesOf(viewModel)}
        >
          <LyraTypography.Heading margin={'0px'} lineHeight={1.4} type="h6">
            SURFACE TYPE:
          </LyraTypography.Heading>
          <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6} type="h6">
            {viewModel.currentLabel}
          </LyraTypography.TextLayout>
        </RoofPropertyEdition>
      ) : (
        <RoofPropertyButtonWrapper>
          <RoofPropertyButton onClick={(): void => roofFaceProps.changeShowPropertiesOf(viewModel)}>
            <IconMapper name="roof-surface" />
            {LABEL_SURFACE}
          </RoofPropertyButton>
        </RoofPropertyButtonWrapper>
      )}
    </>
  );
};

export default observer(SurfaceRoofProperties);
