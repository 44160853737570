import { LyraGrid } from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { canvasConfig } from '../../../config/canvasConfig';
import useStore from '../../../stores/useStore';
import { UtilityMeterViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/UtilityMeterViewModel';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import { Container } from './styles';
import UtilityMeterProperties from './UtilityMeterProperties';

const UtilityMeterPanel = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<UtilityMeterViewModel>();
  const utilityMeterExists = domain.project.site.equipment.utilityMeter;

  useEffect((): void => {
    setViewModel(
      new UtilityMeterViewModel({
        editor,
        domain,
        serviceBus
      })
    );
  }, [editor, domain, serviceBus]);

  if (!viewModel) {
    return <></>;
  }
  return (
    <>
      <PropertyPanelHeader icon="tool-properties" iconBg={canvasConfig.utilityMeterIconColor} name={viewModel.title} />

      {utilityMeterExists && (
        <Container>
          <LyraGrid.Grid
            options={viewModel.exposureOptions}
            columns={1}
            selected={viewModel.exposure as LyraGrid.OptionProps}
            onSelect={(option: Option): void => viewModel.setExposure(option.value)}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
          <UtilityMeterProperties viewModel={viewModel.utilityMeterProps} />
        </Container>
      )}
    </>
  );
};
export default observer(UtilityMeterPanel);
