import {
  computed, observable
} from 'mobx';
import isNil from 'lodash/isNil';
import { precisionNumber } from '../../../utils/helpers';

interface IDcVoltageLossesData {
  /**
   * As a PV module degrades over time, its Vmp will decrease
   */
  readonly voltageLossDueToDegradation: number;
  /**
   * Losses due to variation among various PV modules' Vmp
   */
  readonly voltageLossDueToTolerance: number;
  /**
   * Losses due to fluctuation of grid voltage that results in a
   * higher than average ac voltage. This will result in an increase
   * in the inverter's min input voltage by the provided coefficient.
   */
  readonly inverterMinVoltageIncreaseDueToHighAcVoltage: number;
}

interface IEnergyProductionLossesData {
  readonly combinedLosses: number;
}

export interface IDesignSpecificationData {
  /**
   * System target power rating in watts
   */
  readonly dcPowerRatingTarget: number;
  readonly energyProductionLosses?: IEnergyProductionLossesData;
  readonly dcVoltageLosses?: IDcVoltageLossesData;
}

export class DesignSpecification {
  @observable
  dcPowerRatingTargetInKw: number;
  @observable
  private readonly energyProductionLosses?: IEnergyProductionLossesData;
  @observable
  private readonly dcVoltageLosses?: IDcVoltageLossesData;

  constructor(data: IDesignSpecificationData) {
    this.dcPowerRatingTargetInKw = (data.dcPowerRatingTarget ?? 0) / 1000;
    this.energyProductionLosses = data.energyProductionLosses;
    this.dcVoltageLosses = data.dcVoltageLosses;
  }

  toData(): IDesignSpecificationData {
    return {
      dcPowerRatingTarget: Math.round(this.dcPowerRatingTargetInKw * 1000),
      energyProductionLosses: this.energyProductionLosses,
      dcVoltageLosses: this.dcVoltageLosses
    };
  }

  @computed
  get energyProductionLossesDisplayValue(): number | undefined {
    return !isNil(this.energyProductionLosses)
      ? precisionNumber(this.energyProductionLosses.combinedLosses * 100, 2)
      : undefined;
  }
}
