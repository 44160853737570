import type { Vector3D } from '../../typings';
import type { IEquipmentExposureData } from '../SiteDesign/EquipmentExposure';
import type { IElectricalEquipmentData } from './SitePlan';

export interface IUpdatedSitePlanData {
  readonly electricalEquipment: IUpdatedSiteFeatureLocationData[];
}

export interface IUpdatedSiteFeatureLocationData {
  readonly id: string;
  readonly exposure: IEquipmentExposureData;
  readonly location: Vector3D;
}

export class UpdatedSiteFeatureLocation {
  id: string;
  exposure: IEquipmentExposureData;
  location: Vector3D;

  constructor(data: IElectricalEquipmentData) {
    this.id = data.id;
    this.exposure = data.exposure;
    this.location = data.marker!.positionWithRealWorldZValue;
  }
}
