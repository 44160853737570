import { observer } from 'mobx-react-lite';
import type {
  FunctionComponent, ReactElement
} from 'react';
import * as React from 'react';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { HintObjectType } from '../../../domain/models/Constants';
import {
  PropsPanelHintsForTools, SceneObjectType
} from '../../../domain/models/Constants';
import LineHr from '../../components/LineHr';
import IconMapper from '../../components/Icons';
import {
  HintDescription, HintPanelWrapper, ContentStyledWrapper
} from './styles';

type HintType =
  | SceneObjectType.RoofFace
  | SceneObjectType.Outline
  | SceneObjectType.ParcelBoundary
  | HintObjectType.GasMeter
  | HintObjectType.Street
  | HintObjectType.ServiceEntranceEquipment
  | HintObjectType.Subpanel
  | PropsPanelHintsForTools.OutlineTool
  | PropsPanelHintsForTools.ParcelBoundaryTool;

const headlessHintTypes: HintType[] = [SceneObjectType.ParcelBoundary, SceneObjectType.Outline];

const iconByType = {
  roof_face: 'trace-roof',
  [PropsPanelHintsForTools.OutlineTool]: 'trace-outlines',
  outline: 'trace-outlines',
  parcel_boundary: 'parcel-boundary',
  [PropsPanelHintsForTools.ParcelBoundaryTool]: 'parcel-boundary',
  site_marker_service_entrance_equipment: 'site-equipment',
  site_marker_utility_meter: 'site-equipment',
  site_marker_gas_meter: 'site-equipment',
  site_marker_street_location: 'site-equipment',
  site_marker_subpanel: 'site-equipment'
};

const titleByType = {
  roof_face: 'TRACE ROOF FACES',
  outline: 'THE ROOF OUTLINE',
  [PropsPanelHintsForTools.OutlineTool]: 'TRACE THE ROOF OUTLINE',
  parcel_boundary: 'THE PARCEL BOUNDARY',
  [PropsPanelHintsForTools.ParcelBoundaryTool]: 'TRACE THE PARCEL BOUNDARY',
  site_marker_gas_meter: 'GAS METER',
  site_marker_street_location: 'STREET LOCATION',
  site_marker_subpanel: 'SUBPANEL',
  site_marker_service_entrance_equipment: 'SERVICE ENTRANCE EQUIPMENT'
};

const getMarkerDescription = (markerName: string, canBePlural: boolean = false): string => {
  const markerText = `${canBePlural ? 'marker(s)' : 'the marker'}`;

  return `Click on the canvas to locate the ${markerName} within the site.
  Once placed, click and drag ${markerText} to change its location.
  To delete ${markerText}, select ${markerText} and hit the delete key.`;
};

const parcelBoundaryDescription = (
  <HintDescription>
    Your AHJ may require that the parcel or property boundary be displayed in the permit package.
  </HintDescription>
);

const outlineDescription = (
  <>
    <HintDescription>
      The roof outline encompasses the whole building and all the roof faces that make up the building.
    </HintDescription>
    <HintDescription>
      It is used to infer the building footprint to calculate the least horizontal dimension (LHD), necessary to
      calculate the wind zones, and suggest locations of balance of system (BOS) equipment near the point of
      interconnection later in the design process.
    </HintDescription>
  </>
);

const contentByType = {
  roof_face: (
    <HintDescription>
      After tracing, you will need to provide some information about the building and roof face.
    </HintDescription>
  ),
  [PropsPanelHintsForTools.OutlineTool]: outlineDescription,
  outline: outlineDescription,
  [PropsPanelHintsForTools.ParcelBoundaryTool]: parcelBoundaryDescription,
  parcel_boundary: parcelBoundaryDescription,
  site_marker_gas_meter: <HintDescription>{getMarkerDescription('gas meter')}</HintDescription>,
  site_marker_street_location: <HintDescription>{getMarkerDescription('street location')}</HintDescription>,
  site_marker_subpanel: <HintDescription>{getMarkerDescription('subpanel')}</HintDescription>,
  site_marker_service_entrance_equipment: (
    <HintDescription>{getMarkerDescription('service entrance equipment', true)}</HintDescription>
  )
};

const HintPanel = (type: HintType): FunctionComponent<object> =>
  observer((): ReactElement => {
    return (
      <HintPanelWrapper showHint>
        {!headlessHintTypes.includes(type) && (
          <>
            <LyraTypography.Heading
              fontSize="16px"
              margin="0 0 10px"
              fontWeight="bold"
              align="center"
              color={EThemeComponentColor.GRAY_800 || ''}
            >
              WHAT'S NEXT?
            </LyraTypography.Heading>
            <LineHr />
          </>
        )}
        <ContentStyledWrapper>
          <div>
            <IconMapper name={iconByType[type]} />
          </div>
          <div>
            <LyraTypography.Heading
              fontSize="14px"
              margin="0 0 5px"
              fontWeight="bold"
              lineHeight={1}
              color={EThemeComponentColor.GRAY_800 || ''}
            >
              {titleByType[type]}
            </LyraTypography.Heading>
            {contentByType[type]}
          </div>
        </ContentStyledWrapper>
      </HintPanelWrapper>
    );
  });

export default HintPanel;
