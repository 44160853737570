import React, { ReactElement, useCallback } from 'react';
import Select, { CSSObjectWithLabel, Props } from 'react-select';
import Async from 'react-select/async';
import { HeaderText } from './DropDownNew.styles';

export interface IDropDownProps extends Props {
  name?: string;
  isAsync?: boolean;
  hideDropdownIndicator?: boolean;
  placeholder?: string;
  // tslint:disable-next-line:no-any
  loadOptions?: (input: string, callback: (options: any) => void) => void;
  // tslint:disable-next-line:no-any
  onChange?: (newValue: unknown, actionMeta: unknown) => void;
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  menuPortalTarget?: HTMLElement | null;
  // tslint:disable-next-line:no-any
  forwardRef?: { current: any };
  menuIsOpen?: boolean;
  isClearable?: boolean;
  hideControl?: boolean;
  options?: IDropDownOption[];
  width?: string;
  closeMenuOnScroll?: () => boolean;
}

export interface IDropDownOption {
  value: string;
  label: string;
}

const SELECT_CONTAINER_HEIGHT = '34px';

/**
 * Dropdown component based on react-select (https://react-select.com/)
 */
const DropDownNew = (props: IDropDownProps): ReactElement => {
  const getPortalStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    zIndex: 2000,
    fontFamily: 'Roboto',
  }), []);

  const getControlStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    ...(props.width ? { width: props.width } : {}),
    borderRadius: 0,
    borderColor: 'transparent',
    borderBottomColor: '#ADADAD',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '14px',
    minHeight: SELECT_CONTAINER_HEIGHT,
    // @ts-ignore
    '&:hover': {
      borderColor: 'transparent',
      borderBottomColor: '#ADADAD',
    }
  }), []);

  const getContainerStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    outline: 'none',
    fontFamily: 'Roboto',
    ...(props.hideControl ? {
      opacity: '0',
      display: 'inline',
      width: '0',
    } : {})
  }), []);

  // tslint:disable-next-line:no-any
  const getOptionStyles = useCallback((provided: CSSObjectWithLabel, state: any): CSSObjectWithLabel => ({
    ...provided,
    backgroundColor: state.isFocused ? '#D8D8D8' : 'transparent',
    color: '#333038',
  }), []);

  const getIndicatorStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    padding: '6px !important',
    ...(props.hideDropdownIndicator ? { display: 'none' } : {}),
  }), []);

  const getMenuListStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    zIndex: 999
  }), []);

  const getPlaceholderStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    fontFamily: 'Roboto',
  }), []);

  const getIndicatorSeparatorStyles = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    ...(props.hideDropdownIndicator ? { display: 'none' } : {}),
  }), []);

  const getInputContainerHeight = useCallback((provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...provided,
    height: SELECT_CONTAINER_HEIGHT
  }), []);

  const FinalSelect = props.isAsync ? Async : Select;

  return (
    <>
      {props.name &&
        <HeaderText>{props.name}</HeaderText>
      }
      <FinalSelect
        ref={props.forwardRef}
        menuPlacement="auto"
        styles={{
          container: getContainerStyles,
          menuPortal: getPortalStyles,
          control: getControlStyles,
          option: getOptionStyles,
          dropdownIndicator: getIndicatorStyles,
          menuList: getMenuListStyles,
          placeholder: getPlaceholderStyles,
          indicatorSeparator: getIndicatorSeparatorStyles,
          indicatorsContainer: getInputContainerHeight,
          valueContainer: getInputContainerHeight
        }}
        {...props}
      />
    </>
  );
};

export { DropDownNew, Select };
