import styled, { withTheme } from 'styled-components';
import Flexbox from '../../../components/Layout/Flexbox';

export const ValueBox = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  margin-bottom: 80px;
  & label > div {
    margin: 0px;
    & div {
      height: 40px;
      padding: 0px;
      & input {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding: 0px;
      }
    }
  }
`;
