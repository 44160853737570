import { Vector3 } from 'three';
import type { IPolygon2D } from '../../domain/entities/Polygon/IPolygon2D';
import { SiteStructureFactory } from '../../domain/models/StructureFactory';
import type { RoofStory as RoofStoryDTO } from '../../domain/models/SiteDesign/RoofStory';
import { Outline as OutlineDTO } from '../../domain/models/SiteDesign/Outline';

import type { Vector2D } from '../../domain/typings';
import { Building as BuildingDTO } from '../../domain/models/SiteDesign/Building';
import type { IPolygonWithHoles2D } from '../../domain/entities/Polygon/PolygonWithHoles2D';
import type { IBuildingData } from '../../domain/entities/SiteDesign/Building';
import type { IRoofStoryData } from '../../domain/entities/SiteDesign/RoofStory';
import type { IMapping } from './IMapping';
import { RoofStoryMapping } from './RoofStoryMapping';
import { VerticesMapping } from './VerticesMapping';

export class BuildingsMapping implements IMapping<IBuildingData, BuildingDTO> {
  private readonly roofStoryMapper = new RoofStoryMapping();
  private readonly verticesMapper = new VerticesMapping();

  convertEntityToDomainModel(entity: IBuildingData): BuildingDTO {
    const domainModel = new BuildingDTO();

    domainModel.id = entity.id;
    domainModel.source = entity.source;
    domainModel.name = entity.name;
    domainModel.automaticSprinklerSystem = entity.automaticSprinklerSystem;
    domainModel.useAndOccupancyClassification = entity.useAndOccupancyClassification;

    if (entity.roofOutline) {
      domainModel.roofOutline = this.outlineMapper(entity.roofOutline);
    }

    domainModel.stories.push(
      ...entity.stories.map(
        (roofStoryEntity: IRoofStoryData): RoofStoryDTO =>
          this.roofStoryMapper.convertEntityToDomainModel(roofStoryEntity)
      )
    );

    return domainModel;
  }

  convertDomainModelToEntity(domainModel: BuildingDTO): IBuildingData {
    const entity = {} as IBuildingData;

    entity.id = domainModel.id;
    entity.name = domainModel.name;
    entity.source = domainModel.source;
    entity.automaticSprinklerSystem = domainModel.automaticSprinklerSystem ?? false;
    entity.useAndOccupancyClassification = domainModel.useAndOccupancyClassification;

    const vertexModel = domainModel.roofOutline?.boundary.vertices;

    if (vertexModel) {
      entity.roofOutline = this.verticesMapper.convertDomainModelToEntity2d(vertexModel);
    }

    entity.stories = domainModel.stories.map(
      (story: RoofStoryDTO): IRoofStoryData => this.roofStoryMapper.convertDomainModelToEntity(story)
    );
    return entity;
  }

  private outlineMapper(outlineEntity: IPolygon2D | IPolygonWithHoles2D): OutlineDTO {
    const drawableObjectsFactory = new SiteStructureFactory();
    const outlineDTO = drawableObjectsFactory.create<OutlineDTO>(OutlineDTO, {
      color: 0x28a2f6
    });
    outlineDTO.name = 'Default Outline';

    /* There are two types of outlines:
     *  - simple outline with no holes, it has structure of flat array like that [{x: 0.1 }, { y: 0.2 }]
     *  - outline with holes, it has structure like that { holes: [], vertices: [{x: 0.1 }, { y: 0.2 }] }
     *
     * Right now FE doesn't support drawing holes on the outline, but it may support it later, also
     * BE support holes in the imported 3D roof schemas.
     */
    const outlineVertices = (outlineEntity as IPolygonWithHoles2D)?.vertices || (outlineEntity as IPolygon2D);
    const outlineHoles = (outlineEntity as IPolygonWithHoles2D)?.holes || [];

    if (outlineVertices.length) {
      outlineVertices.forEach(({
        x, y
      }: Vector2D): void => {
        const coords = new Vector3(x, y, 0);
        outlineDTO.addVertex({
          vertex: coords,
          removeIfCollinear: false,
          originatingFromTracing: false
        });
        outlineDTO.redraw();
      });
      const firstVertex = outlineVertices[0];
      outlineDTO.addVertex({
        vertex: new Vector3(firstVertex.x, firstVertex.y, 0),
        removeIfCollinear: false,
        originatingFromTracing: false
      });
      outlineDTO.redraw();
    }

    if (outlineHoles.length) {
      // TODO: Map Outlines with holes
    }

    return outlineDTO;
  }
}
