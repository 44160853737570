import React, {
  useEffect, useRef, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  LyraFormElementsDesignTool, LyraGrid, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { MainBreakerViewModel } from '../../../../stores/UiStore/Properties/SiteEquipment/ViewModels/MainBreakerViewModel';
import {
  getServiceEntranceAndSiteEquipmentProperties, ServiceEntranceEquipmentDevice, ServiceEntranceEquipmentType
} from '../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import { ServiceEntranceEquipmentFormContainer } from '../ServiceEntranceEquipmentFormContainer';
import useStore from '../../../../stores/useStore';
import {
  MainBreakerProperties, MeterMainInputContainer
} from '../styles';

const Label = LyraTypography.Paragraph;
const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;

export const MainBreakerForm = observer(() => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<MainBreakerViewModel>();
  const cleanupFunction = useRef<() => void>();

  useEffect(() => {
    setViewModel(new MainBreakerViewModel(
      editor,
      domain,
      serviceBus
    ));
    cleanupFunction.current = (): void => {
      viewModel?.clearMainBreakerAICOptionsAutoUpdate();
    };
    return (): void => {
      cleanupFunction.current?.();
    };
    // Adding viewModel to a list of dependencies will send this hook into an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, domain, serviceBus]);

  if (!viewModel) {
    return <></>;
  }

  const {
    label, name
  } = getServiceEntranceAndSiteEquipmentProperties(
    ServiceEntranceEquipmentDevice.MainBreakerInEnclosure,
    // It doesn't matter what to add as a second argument — it will be the same main breaker
    ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter
  );


  return (
    <ServiceEntranceEquipmentFormContainer title={name} label={label}>
      <LyraGrid.Grid
        options={viewModel.exposureOptions}
        columns={2}
        selected={viewModel.exposure as LyraGrid.OptionProps}
        onSelect={(option: Option): void => viewModel.setExposure(option.value)}
        selectedOptionColor={EThemeComponentColor.PURPLE}
      />
      <MainBreakerProperties>
        <MeterMainInputContainer>
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MAIN BREAKER RATING
          </Label>
          <Select
            name="breakerOptions"
            listOptions={viewModel.mainBreakerRatingOptions}
            selected={viewModel.mainBreakerRating?.value}
            onSelect={viewModel.setMainBreakerRating}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MeterMainInputContainer>
        <MeterMainInputContainer>
          <Label lineHeight={1} margin="0 0 4px 0" type="h6">
            MAIN BREAKER INTERRUPTING CAPACITY (AIC)
          </Label>
          <Select
            name="mainBreakerAmpereInterruptingCapacityOptions"
            listOptions={viewModel.mainBreakerAmpereInterruptingCapacityOptions}
            selected={viewModel.mainBreakerAmpereInterruptingCapacity?.value}
            onSelect={viewModel.setMainBreakerAmpereInterruptingCapacity}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </MeterMainInputContainer>
      </MainBreakerProperties>
    </ServiceEntranceEquipmentFormContainer>
  );
});
