import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  action, computed, observable
} from 'mobx';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import { SnowDesignParameters } from '../../../../domain/models/SiteDesign/SnowDesignParameters';
import { isWithin } from '../../../../domain/models/Limit';
import type DomainStore from '../../../DomainStore/DomainStore';
import { BaseViewModel } from '../../Modal/BaseViewModel';
import type { ModalStore } from '../../Modal/Modal';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateGroundSnowLoadCommandDependencies } from '../../../ServiceBus/Commands/UpdateGroundSnowLoadCommand';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../domain/behaviour/KeyboardBehaviour';

interface ISiteGroundSnowViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly serviceBus: ServiceBus;
  readonly editor: EditorStore;
}

export class GroundSnowViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI = 'groundsnow_modal';

  @observable
  selectedOption?: OptionProps;

  override readonly editor: EditorStore;
  private readonly serviceBus: ServiceBus;

  constructor(dependencies: ISiteGroundSnowViewModelDependencies) {
    super(dependencies);
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;
    KeyboardBehaviour.addKeyboardEvents(this);
    this.setInitialValues();
  }

  @action
  setInitialValues = (): void => {
    this.selectedOption = this.domain.project.designParameters.snow.groundSnowLoadAsOption;
  };

  get options(): OptionProps[] {
    return SnowDesignParameters.GROUND_SNOW_LOAD_OPTIONS;
  }

  @action.bound
  setSelectedOption(snowSelected: OptionProps): void {
    this.selectedOption = snowSelected;
  }

  @computed
  get isValidGroundSnowLoadValue(): boolean {
    const customValue: number = parseInt(this.selectedOption?.value ?? '', 10);
    return !isNaN(customValue) && isWithin(SnowDesignParameters.GROUND_SNOW_LOAD_PSF_LIMITS, customValue);
  }

  saveSiteGroundSnowData = (): void => {
    const groundSnowLoadAsNumber = Math.round(Number(this.selectedOption!.value));
    const commandDependencies: IUpdateGroundSnowLoadCommandDependencies = {
      domain: this.domain,
      groundSnowLoad: groundSnowLoadAsNumber
    };
    this.serviceBus.send('update_groundSnow_values', commandDependencies);
    this.closeModal();
  };

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (event.key === KeyboardListener.KEY_ENTER && this.isValidGroundSnowLoadValue) {
      this.saveSiteGroundSnowData();
      this.dispose();
    }
  };
}
