import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { Icon } from '../Icon';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { EButtonSize } from './EButtonSize';
import {
  ButtonStyled,
  ButtonWrapper,
  FlatButton,
  FlatText,
  IStyleProps,
  ButtonThemed
} from './styles';

export interface IProps {
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  styles?: CSSProperties;
  isLeft?: boolean;
  colorTheme?: EThemeComponentColor;
  fontColorTheme?: EThemeComponentColor;
  size?: EButtonSize;
  type?: 'button' | 'reset' | 'submit' | undefined;
  className?: string;
}

const ThemedButton =
  (props: IProps & IStyleProps): ReactElement => {

    const {
      fontColorTheme = EThemeComponentColor.BLACK,
      colorTheme = EThemeComponentColor.WHITE,
      size = EButtonSize.MEDIUM,
      isLeft,
      styles,
      styleType,
      children,
      onClick,
      type = 'button'
    } = props;

    return (
      <ButtonWrapper
        className={props.className}
        styleType={styleType}
      >
        <ButtonThemed
          onClick={onClick}
          styleType={styleType}
          styles={styles}
          size={size}
          colorTheme={colorTheme}
          type={type}
        >
          {children}
        </ButtonThemed>
      </ButtonWrapper >
    );
  };

export { ThemedButton };
