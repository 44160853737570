import { LyraTable } from '@aurorasolar/lyra-ui-kit';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  action, computed, observable
} from 'mobx';
import {
  EOrientation, ERowAlignmentStrategy
} from '../../typings';

export interface ILayoutStrategyData {
  dominantOrientation: EOrientation;
  rowAlignmentStrategy: ERowAlignmentStrategy;
  mixedOrientationsAllowed: boolean;
}

export class LayoutStrategy {
  static readonly ORIENTATION_LABELS: { [orientation in EOrientation]: string } = {
    PORTRAIT: 'Portrait',
    LANDSCAPE: 'Landscape'
  };
  static readonly ROW_ALIGNMENT_STEEP_SLOPE_LABELS: { [alignment in ERowAlignmentStrategy]: string } = {
    MAXIMIZE_MODULE_QUANTITY: 'Maximized modules',
    ALIGNED: 'Aligned Rows',
    STAGGERED: 'Staggered Rows'
  };
  static readonly ROW_ALIGNMENT_LOW_SLOPE_LABELS: { [alignment in ERowAlignmentStrategy]: string } = {
    MAXIMIZE_MODULE_QUANTITY: 'Maximized modules',
    ALIGNED: 'Aligned Racks',
    STAGGERED: 'Staggered Racks'
  };

  static initialForSteepSlope = (): LayoutStrategy =>
    new LayoutStrategy({
      dominantOrientation: EOrientation.PORTRAIT,
      rowAlignmentStrategy: ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY,
      mixedOrientationsAllowed: false
    });

  static initialForLowSlope = (): LayoutStrategy =>
    new LayoutStrategy({
      dominantOrientation: EOrientation.LANDSCAPE,
      rowAlignmentStrategy: ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY,
      mixedOrientationsAllowed: false
    });

  @observable
  dominantOrientation: EOrientation;
  @observable
  rowAlignmentStrategy: ERowAlignmentStrategy;
  @observable
  mixedOrientationsAllowed: boolean;

  /**
   * Constructor for creating a class instance from data of the same structure
   * @see ILayoutStrategyData
   */
  constructor(data: ILayoutStrategyData) {
    this.dominantOrientation = data.dominantOrientation;
    this.rowAlignmentStrategy = data.rowAlignmentStrategy;
    this.mixedOrientationsAllowed = data.mixedOrientationsAllowed;
  }

  toData(): ILayoutStrategyData {
    return {
      dominantOrientation: this.dominantOrientation,
      rowAlignmentStrategy: this.rowAlignmentStrategy,
      mixedOrientationsAllowed: this.areMixedOrientationsPossible && this.mixedOrientationsAllowed
    };
  }

  copy = (): LayoutStrategy => new LayoutStrategy(this.toData());

  @computed
  get dominantOrientationAsText(): string {
    return LayoutStrategy.ORIENTATION_LABELS[this.dominantOrientation];
  }

  @computed
  get dominantOrientationAsOption(): OptionProps {
    return {
      name: this.dominantOrientationAsText,
      value: this.dominantOrientation
    };
  }

  @action
  setDominantOrientation = (value: EOrientation): void => {
    this.dominantOrientation = value;
  };

  @action
  setDominantOrientationAsOption = (option: OptionProps): void => {
    this.setDominantOrientation(EOrientation[option.value as keyof typeof EOrientation]);
  };

  @computed
  get rowAlignmentStrategyAsTextForSteepSlope(): string {
    return LayoutStrategy.ROW_ALIGNMENT_STEEP_SLOPE_LABELS[this.rowAlignmentStrategy];
  }

  @computed
  get rowAlignmentStrategyAsOptionForSteepSlope(): OptionProps {
    return {
      name: this.rowAlignmentStrategyAsTextForSteepSlope,
      value: this.rowAlignmentStrategy
    };
  }

  @computed
  get rowAlignmentStrategyAsOptionForLowSlope(): OptionProps {
    return {
      name: LayoutStrategy.ROW_ALIGNMENT_LOW_SLOPE_LABELS[this.rowAlignmentStrategy],
      value: this.rowAlignmentStrategy
    };
  }

  @action
  setRowAlignmentStrategy = (value: ERowAlignmentStrategy): void => {
    this.rowAlignmentStrategy = value;
  };
  @action
  setRowAlignmentStrategyAsOption = (option: OptionProps): void => {
    this.setRowAlignmentStrategy(ERowAlignmentStrategy[option.value as keyof typeof ERowAlignmentStrategy]);
  };

  @computed
  get hasAllRequiredProperties(): boolean {
    return !!(this.dominantOrientation && this.rowAlignmentStrategy);
  }

  @computed
  get areMixedOrientationsPossible(): boolean {
    return (
      this.dominantOrientation === EOrientation.PORTRAIT
      && this.rowAlignmentStrategy !== ERowAlignmentStrategy.STAGGERED
    );
  }

  @action
  setMixedOrientationAllowed = (value: boolean): void => {
    this.mixedOrientationsAllowed = value;
  };

  @computed
  get icon(): LyraTable.ELayoutType {
    const alignment: string =
      this.rowAlignmentStrategy === ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY
        ? 'MAXIMIZED'
        : ERowAlignmentStrategy[this.rowAlignmentStrategy];
    const notMixedSuffix =
      this.dominantOrientation === EOrientation.PORTRAIT && alignment === 'STAGGERED' ? '-NOT-MIXED' : '';
    const mixedOrientationsSuffix = this.areMixedOrientationsPossible
      ? `-${this.mixedOrientationsAllowed ? 'MIXED' : 'NOT-MIXED'}`
      : notMixedSuffix;

    const iconKey = `${this.dominantOrientation}-${alignment}${mixedOrientationsSuffix}`;
    return LyraTable.ELayoutType[iconKey as keyof typeof LyraTable.ELayoutType];
  }
}
