import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  Fragment, useState
} from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import type {
  IAcModuleBranchOptionsData,
  IStringingOptionData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import type {
  StringConnected,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';
import {
  ContentItem,
  ContentText,
  DeleteButtonWrapper,
  DeleteIcon,
  ItemText,
  ProgressItemContainer,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperValueText
} from './styles';

type Props = BaseProps & {
  index: number;
  onDeleteActiveBranch: () => void;
};

const { StepDottedProgressBar } = LyraProgressBar;

const DEFAULT_MAX_VALUE = 10;

const AcModulesInBranch = observer((props: Props): ReactElement => {
  const {
    index = 0, onDeleteActiveBranch
  } = props;
  let numberOfModules = 0;
  let maxContinuousOutputCurrent = 0;
  let maxOutputPower: number | undefined;
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);

  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;
  const stringsConnected = systemSummaryList[index].strings;
  if (stringsConnected.length) {
    const existingString = stringsConnected.find((item: StringConnected): boolean => item.getId() === selectedString);
    const stringingOption: IStringingOptionData | undefined =
      (existingString?.stringingOption as IStringingOptionData) ?? undefined;
    numberOfModules = stringingOption?.numberOfModules ?? 0;
    maxContinuousOutputCurrent = stringingOption?.maxContinuousOutputCurrent ?? 0;
    maxOutputPower = stringingOption?.maxOutputPower ?? 0;
  }

  const definition = systemSummaryList[index].definition as IAcModuleBranchOptionsData;
  const { acModuleDefinition } = definition;
  return (
    <Fragment>
      <ContentItem>
        <Flexbox justify="space-between" align="center" className="pt-xs">
          <ContentText className="mt-xxs">AC modules in branch</ContentText>
          {numberOfModules > 0 && (
            <DeleteButtonWrapper onClick={onDeleteActiveBranch}>
              <DeleteIcon name="icon-delete" />
            </DeleteButtonWrapper>
          )}
        </Flexbox>
        <ProgressItemContainer>
          <StepDottedProgressBar
            currentValue={numberOfModules ?? 0}
            minValue={0}
            maxValue={acModuleDefinition?.maxNumberOfMicroinvertersPerBranch ?? DEFAULT_MAX_VALUE}
            possibleMinValue={0}
            possibleMaxValue={1 + (acModuleDefinition?.maxNumberOfMicroinvertersPerBranch ?? DEFAULT_MAX_VALUE)}
            size="small"
          />
        </ProgressItemContainer>

        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>AC Current Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{maxContinuousOutputCurrent ?? 0}</ValueText>
            <UnitText>A</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>

        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>AC Power Output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{maxOutputPower ?? 0}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
      </ContentItem>
    </Fragment>
  );
});

export default AcModulesInBranch;
