import type {
  Attributes, BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import registry from './registry';

type Props = BaseProps & {
  name: string;
};

/**
 *
 * Render SVG icon in React component
 * It can be manipulated and add more props
 * @param props React props
 * @returns React Icon Element
 */
function IconMapper(props: Props): ReactElement {
  const {
    name, ...rest
  } = props;
  const component = registry.get(name);
  // if no icon is found, just display an empty html element as fallback

  return component
    ? React.createElement(component, {
      ...rest
    } as Attributes)
    : React.createElement(
      'span',
        {
          ...rest
        } as Attributes,
        name
    );
}

export default IconMapper;
