import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import {
  type ITraceIndividualRoofFaceToolDependencies,
  TraceIndividualRoofFaceTool
} from '../../../../../../stores/UiStore/ToolbarStore/Project/TracingTools/TraceIndividualRoofFaceTool';
import withTool from '../../../Shared/withTool';
type ConstDep = Pick<
  ITraceIndividualRoofFaceToolDependencies,
  | 'properties'
  | 'drawableObjectsFactory'
  | 'domain'
  | 'wizard'
  | 'smartGuides'
  | 'currentWorkspace'
  | 'serviceBus'
  | 'roofFaceProps'
>;
export const TraceIndividualRoofFace = withTool<
  TraceIndividualRoofFaceTool,
  ITraceIndividualRoofFaceToolDependencies,
  ConstDep
>(
  TraceIndividualRoofFaceTool,
  (store: Store): ConstDep => ({
    serviceBus: store.serviceBus,
    properties: store.properties,
    domain: store.domain,
    smartGuides: store.smartGuides,
    wizard: store.wizard,
    currentWorkspace: store.workspace.currentWorkspace,
    drawableObjectsFactory: new SiteStructureFactory(),
    roofFaceProps: store.roofFaceProps
  })
);
