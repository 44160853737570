import type {
  BaseProps, ReactElement, ReactText
} from 'react';
import * as React from 'react';
import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

type Props = BaseProps & {
  title?: string | ReactElement | ReactText;
  divider?: boolean;
};

const SectionStyle = styled.section`
  width: 100%;
  & > header {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
  }
  & > hr {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.tertiary};
    border: 0px;
    height: 1px;
  }
`;

function Section(props: Props): ReactElement {
  return (
    <SectionStyle className={props.className}>
      {props.title ? <header>{props.title}</header> : null}
      <div>{props.children}</div>
      {props.divider ? <hr /> : null}
    </SectionStyle>
  );
}

export default Section;
