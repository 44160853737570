import defer from 'lodash/defer';
import {
  action, observable
} from 'mobx';
import * as Sentry from '@sentry/react';
import type Store from '../../Store';
import type { IStage } from '../../../domain/stages/IStage';
import { EApplicationContext } from '../../../domain/typings';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IApplicationContextChangeEvent } from '../../EditorStore/Controls/ControlEvents';
import type { ModalStore } from '../Modal/Modal';
import type { ServiceBus } from '../../ServiceBus/ServiceBus';
import type DomainStore from '../../DomainStore/DomainStore';
import type { GuardStore } from '../GuardStore/GuardStore';
import type { WorkspaceStore } from '../WorkspaceStore';
import type { PropertiesStore } from '../Properties/Properties';
import type SmartGuidesStore from '../SmartGuidesStore/SmartGuidesStore';
import { KeyboardBehaviour } from '../../../domain/behaviour/KeyboardBehaviour';
import { SiteStructureFactory } from '../../../domain/models/StructureFactory';
import type { UiStore } from '../UiStore';
import { getUiStore } from '../../RootStoreInversion';
import config, { UI_MODE } from '../../../config/config';
import { SelectionTool as DesignSelectionTool } from './Design/SelectionTool';
import {
  SELECT_TOOL_ID, STRINGING_ID
} from './Design/constants';
import type {
  BaseTool, IBaseToolDependencies
} from './Tool';
import { SelectionTool } from './Project/SelectionTool';
import { OutlineTool } from './Project/TracingTools/OutlineTool';
import { StringingTool } from './Design/StringingTool/StringingTool';
import { OUTLINE_TOOL_ID } from './Project/constants';
import {
  SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID,
  ServiceEntranceEquipmentTool
} from './Project/SiteEquipmentTools/ServiceEntranceEquipmentTool';

export class ToolbarStore {
  @observable
  selectedTool?: BaseTool;
  @observable
  private blacklist: string[] = [];
  @observable
  private whitelist: string[] = [];

  private readonly editor: EditorStore;
  private readonly domain: DomainStore;
  private readonly guardStore: GuardStore;
  private readonly properties: PropertiesStore;
  private readonly serviceBus: ServiceBus;
  private readonly smartGuides: SmartGuidesStore;
  private readonly getWorkspace: () => WorkspaceStore;

  @observable
  initialisedToolId: string = '';

  constructor(root: Store, uiStore: UiStore) {
    this.enableTools();
    this.editor = root.editor;
    this.domain = root.domain;
    this.guardStore = uiStore.guardStore;
    this.getWorkspace = (): WorkspaceStore => uiStore.workspace;
    this.properties = uiStore.properties;
    this.serviceBus = root.serviceBus;
    this.smartGuides = uiStore.smartGuides;
    this.editor.addEventListener('application_control_state_change', (event: IApplicationContextChangeEvent): void => {
      this.onApplicationStateChange(event);
    });
  }

  onApplicationStateChange(event: IApplicationContextChangeEvent): void {
    if (this.selectedTool) {
      switch (event.state) {
      case EApplicationContext.PROPERTIES_CONTEXT: {
        KeyboardBehaviour.removeKeyboardEvents(this.selectedTool);
        break;
      }
      case EApplicationContext.DRAW_CONTEXT: {
        KeyboardBehaviour.addKeyboardEvents(this.selectedTool);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn('Toolbar store: This state doesn\'t exist');
      }
      }
    }
  }

  @action
  selectTool(tool: BaseTool): void {
    if (this.guardStore.workspace.frozen) {
      return;
    }
    if (tool.onClick) {
      tool.onClick();
      this.initialisedToolId = tool.id;
      return;
    }
    this.enableTools();
    if (this.selectedTool === tool && config.featureFlag.uiMode === UI_MODE.AURORA) {
      /*
          In Aurora mode we can only click on a panning tool.
          Clicking on an already selected tool should instead select a selection tool,
          which will simultaneously unselect panning tool.
      */
      this.activateToolInDesignWorkspaceWithoutClick(
        SELECT_TOOL_ID,
        this.getWorkspace().currentWorkspace.stageManager?.currentStage!
      );
      return;
    }
    if (this.selectedTool !== tool) {
      // If tool selected is different from current deselect the current
      this.deselectTool();
    }
    Sentry.setTag('currentTool', tool.id);
    this.selectedTool = tool;
    this.selectedTool.updateCursor(this.selectedTool.cursor, true);
    this.selectedTool.whenSelected();
    this.initialisedToolId = tool.id;
  }

  activateToolInDesignWorkspaceWithoutClick(id: string, stage: IStage): void {
    this.deselectTool();

    if (id === STRINGING_ID) {
      const stringingTool = new StringingTool({
        editor: this.editor,
        serviceBus: this.serviceBus,
        toolbar: this,
        currentStage: stage
      });

      this.selectTool(stringingTool);
    } else if (id === SELECT_TOOL_ID) {
      const selectionTool = new DesignSelectionTool({
        editor: this.editor,
        toolbar: this,
        serviceBus: this.serviceBus,
        domain: this.domain,
        properties: this.properties,
        workspace: this.getWorkspace()
      });

      defer((): void => {
        this.selectTool(selectionTool);
      });
    }
  }

  activateToolInProjectWorkspaceWithoutClick(id: string): void {
    this.deselectTool();

    switch (id) {
    case OUTLINE_TOOL_ID: {
      const { wizard } = getUiStore();
      const outlineTool = new OutlineTool({
        editor: this.editor,
        toolbar: this,
        modal: {} as ModalStore,
        serviceBus: this.serviceBus,
        domain: this.domain,
        properties: this.properties,
        currentWorkspace: this.getWorkspace().currentWorkspace,
        smartGuides: this.smartGuides,
        wizard: wizard,
        drawableObjectsFactory: new SiteStructureFactory()
      });

      this.selectTool(outlineTool);

      break;
    }
    case SERVICE_ENTRANCE_EQUIPMENT_TOOL_ID: {
      const serviceEntranceEquipmentTool = new ServiceEntranceEquipmentTool({
        editor: this.editor,
        toolbar: this,
        serviceBus: this.serviceBus,
        domain: this.domain,
        properties: this.properties,
        currentWorkspace: this.getWorkspace().currentWorkspace,
        smartGuides: this.smartGuides,
        drawableObjectsFactory: new SiteStructureFactory()
      });

      this.selectTool(serviceEntranceEquipmentTool);

      break;
    }
      // no default
    }
  }

  activateSelectionToolInProjectWorkspace = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const selectionTool = new SelectionTool({
          editor: this.editor,
          toolbar: this,
          domain: this.domain,
          properties: this.properties,
          serviceBus: this.serviceBus,
          smartGuides: this.smartGuides
        });

        defer((): void => {
          this.selectTool(selectionTool);
          resolve();
        });
      } catch (error) {
        reject();
      }
    });
  };

  @action
  deselectTool(): void {
    if (this.selectedTool) {
      this.selectedTool.whenDeselected();
      this.selectedTool = undefined;
    }
    this.initialisedToolId = '';
  }

  createTool<T extends BaseTool, K extends IBaseToolDependencies>(c: new (dep: K) => T, dependencies: K): T {
    return new c(dependencies);
  }

  @action.bound
  enableTools(): void {
    this.blacklist = [];
  }

  @action.bound
  whitelistTools(toolWhitelist: string[]): void {
    this.whitelist = toolWhitelist;
  }

  @action.bound
  blacklistTools(toolBlacklist: string[]): void {
    this.blacklist = toolBlacklist;
  }

  isToolDisabled(toolId: string): boolean {
    if (this.whitelist.length > 0) {
      return !this.whitelist.includes(toolId);
    }
    if (this.blacklist.length > 0) {
      return this.blacklist.includes(toolId);
    }
    return false;
  }

  reset(defaultWhitelist: string[]): void {
    this.deselectTool();
    this.whitelist = defaultWhitelist;
  }
}
