import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import {
  Address, Id, Label, ProjectInfoStyle
} from './styles';

type Props = BaseProps & {
  projectId: string;
  address: string;
};

function ProjectInfo(props: Props): ReactElement {
  const {
    projectId = '', address = ''
  } = props;

  return (
    <ProjectInfoStyle>
      <Label hideLabel={!projectId}>
        <Id>{projectId}</Id>
      </Label>
      <Label>
        <Address>{address}</Address>
      </Label>
    </ProjectInfoStyle>
  );
}

export default ProjectInfo;
