import React, { BaseProps, ReactElement, useCallback } from 'react';
import { Button, ButtonWrapper } from './styles';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { Flexbox } from '../../components/Layout';

type ButtonOption = {
  name: string | ReactElement;
  value: string;
  caption?: string;
};

type Props = BaseProps & {
  selected?: string[];
  onSelect?: (value: string, values: string[]) => void;
  options: ButtonOption[];
  height?: string;
  withCaption?: boolean;
  disabled?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

const MultiButtonGroup = ({
 height,
 onSelect,
 selected,
 options,
 withCaption = false,
 disabled = false,
 className,
 selectedOptionColor
}: Props): ReactElement => {
  const handleSelect = useCallback((currentSelectedValue: string): void => {
    if (typeof onSelect !== 'function') return;

    if (!selected?.includes(currentSelectedValue)) {
      const selectedValuesWithNewSelected = [...selected!, currentSelectedValue];
      onSelect(currentSelectedValue, selectedValuesWithNewSelected);
      return;
    }

    if (selected?.includes(currentSelectedValue)) {
      const selectedValuesWithoutUnselected =
        selected?.filter((item: string): boolean => item !== currentSelectedValue);
      onSelect(currentSelectedValue, selectedValuesWithoutUnselected);
    }
  }, [selected]);

  const optionsElements = options.map(
    (option: ButtonOption, index: number): ReactElement => (
      <ButtonWrapper key={index} flexGrow={1 / options.length} height={height} disabled={disabled}>
        <Button
          disabled={disabled}
          selectedOptionColor={selectedOptionColor}
          selected={selected?.includes(option.value)}
          onClick={disabled ? undefined : handleSelect.bind(null, option.value)}
        >
          {option.name}
        </Button>
        {withCaption && <p>{option.caption ?? ''}</p>}
      </ButtonWrapper>
    )
  );
  return <Flexbox justify="space-between" align="center" wrap="wrap" className={className}>{optionsElements}</Flexbox>;
};

export { MultiButtonGroup };
