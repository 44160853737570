/* tslint:disable: no-any */
import React, { BaseProps, ReactElement, useCallback } from 'react';
import { HeaderCellProps, TableRowDataProps, Table } from '../Table/Table';
import { ELayoutType } from './ELayoutType';
import { BoldLabel, Cell, RegularLabel, StyledIcon } from './styles';

export type RoofFaceProps = { name: string; level: number };
export type PotentialProps = { wattage: string; unit: string; modules: number };
export type ModulePositionProps = { positioning: ELayoutType };
export type DataProps = RoofFaceProps &
  PotentialProps &
  ModulePositionProps & {
    [key: string]: any;
  };

export type LayoutStrategyTableProps = BaseProps & {
  width?: string;
  label?: string;
  draggable?: boolean;
  data?: DataProps[];
  unit?: string;
  indexFieldName?: string;
  onItemDrag?: (dragIndex: number, hoverIndex: number) => void;
  onItemClick?: (row: TableRowDataProps, event: MouseEvent) => void;
  onItemDragEnd?: (orderedRows: TableRowDataProps[]) => void;
};

const RoofFaceColumn = ({ name, level }: RoofFaceProps): ReactElement => (
  <Cell className="mt-xxxs">
    <RegularLabel>{name}</RegularLabel>
    <RegularLabel className="mt-xxxs">LEVEL {level}</RegularLabel>
  </Cell>
);

const PotentialColumn = ({ wattage, modules, unit }: PotentialProps): ReactElement => (
  <Cell className="mt-xxxs">
    <BoldLabel>{wattage} {unit}</BoldLabel>
    <RegularLabel className="mt-xxs">{modules} Modules</RegularLabel>
  </Cell>
);

const HEADER: HeaderCellProps[] = [
  {
    content: '',
    headerCell: { size: 2, separator: false },
    contentCell: { separator: false, verticalAlign: 'flex-start' }
  },
  {
    content: 'Roof Face Name',
    headerCell: {
      size: 5,
      separator: false,
      horizontalAlign: 'flex-start',
      verticalAlign: 'flex-start'
    },
    contentCell: {
      separator: true,
      horizontalAlign: 'flex-start',
      verticalAlign: 'flex-start'
    }
  },
  {
    content: 'Max. Potential',
    headerCell: {
      size: 6,
      separator: false,
      horizontalAlign: 'flex-start',
      verticalAlign: 'flex-start'
    },
    contentCell: {
      separator: true,
      horizontalAlign: 'flex-start',
      verticalAlign: 'flex-start'
    }
  },
  {
    content: <StyledIcon name="pencil" width="10px" />,
    headerCell: { size: 2, separator: false },
    contentCell: {
      separator: true,
      verticalAlign: 'flex-start'
    }
  }
];

function LayoutStrategyTable(props: LayoutStrategyTableProps): ReactElement {
  const {
    width, label, draggable, data = [], unit, indexFieldName,
    onItemDrag, onItemClick, onItemDragEnd
  } = props;

  const renderRows = (): TableRowDataProps[] => data.map((row: DataProps, idx: number): TableRowDataProps => ({
    content: [
      <StyledIcon
        key={`c1${idx}`}
        className="mt-xxs"
        name="handler"
        cursor="grabbing"
      />,
      <RoofFaceColumn
        key={`c2${idx}`}
        name={row.name}
        level={row.level}
      />,
      <PotentialColumn
        key={`c3${idx}`}
        wattage={row.wattage}
        unit={row.unit}
        modules={row.modules}
      />,
      <StyledIcon
        key={`c4${idx}`}
        className="mt-xxs"
        name={row.positioning}
        cursor="pointer"
      />
    ],
    data: row
  }));

  return (
    <Table
      width={width}
      draggable={draggable}
      label={label}
      unit={unit}
      indexFieldName={indexFieldName}
      header={HEADER}
      rows={renderRows()}
      onItemDrag={onItemDrag}
      onItemDragEnd={onItemDragEnd}
      onItemClick={onItemClick}
    />
  );
}

export { LayoutStrategyTable };
