import {
  computed, observable
} from 'mobx';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import type { IInverterSelected } from '../SupplementalData/IInverterInfo';

export enum InvertersType {
  MICROINVERTER = 'MICROINVERTER',
  CENTRAL_INVERTER = 'CENTRAL_INVERTER'
}

export interface IInvertersData {
  readonly type: InvertersType;
  readonly definitions: readonly string[];
  readonly instances?: readonly IInverterData[];
}

interface IInverterData {
  readonly id: string;
  readonly definitionId: string;
  readonly stringInverterMppts?: readonly IStringInverterMpptData[];
}

interface IStringInverterMpptData {
  readonly id: string;
  readonly definitionId: string;
}

export class Inverters {
  private readonly type: InvertersType;
  /**
   * Note: this array contains only unique inverter definition IDs.
   * So if two identical string inverters are used, there will be just one definition present.
   */
  private readonly definitions: readonly string[];
  @observable
  private instances: Inverter[];

  constructor(data: IInvertersData) {
    this.type = data?.type;
    this.definitions = [...(data?.definitions ?? [])];
    this.instances = data?.instances?.map((instanceData: IInverterData): Inverter => new Inverter(instanceData)) ?? [];
  }

  toData(): IInvertersData {
    return {
      type: this.type,
      definitions: [...this.definitions],
      instances:
        this.instances.length > 0
          ? this.instances.map((instance: Inverter): IInverterData => instance.toData())
          : undefined
    };
  }

  /**
   * @description supplementalDataInverterDefinitions. Return definition ids according to inverters (this is not applied
   * to microinverters, for microinverters we'll just return the list of global unique definition ids).
   */
  get supplementalDataInverterDefinitions(): readonly string[] {
    return this.areMicroinverters
      ? this.definitions
      : this.instances.map((instance: Inverter): string => instance.definitionId);
  }

  get invertersMappedToSortedMpptIds(): { [key: string]: string[] } {
    const inverterToMpptIds: { [key: string]: string[] } = {};
    for (const inverter of this.instances) {
      inverterToMpptIds[inverter.id] = map(inverter.toData().stringInverterMppts, 'id');
    }
    return inverterToMpptIds;
  }

  getSortedInverterIds = (): string[] => {
    return map(this.instances, 'id');
  };

  /**
   * Sort inverter and mppt ids using `system.equipment`.
   * @param ids
   */
  sortIds = (ids: string[]): string[] => {
    const inverters = this.instances.map((instance: Inverter) => instance.toData().id) ?? [];
    const mppts = flatMap(this.instances, (instance: Inverter): string[] =>
      map(instance.toData().stringInverterMppts, 'id')
    );

    return sortBy(ids, [(id: string): number => inverters.indexOf(id), (id: string): number => mppts.indexOf(id)]);
  };

  clearMicroinvertersIfPresent = (): void => {
    if (!this.areMicroinverters) {
      return;
    }
    this.instances = [];
  };

  @computed
  get areMicroinverters(): boolean {
    return this.type === InvertersType.MICROINVERTER;
  }

  @computed
  get areStringInverters(): boolean {
    return this.type === InvertersType.CENTRAL_INVERTER;
  }

  @computed
  get microinverterCount(): number {
    if (this.type !== InvertersType.MICROINVERTER) {
      return 0;
    }
    return this.instances?.length ?? 0;
  }

  @computed
  get firstStringInverterId(): string | undefined {
    if (this.type !== InvertersType.CENTRAL_INVERTER) {
      return undefined;
    }
    return this.instances[0]?.id;
  }

  @computed
  get secondStringInverterId(): string | undefined {
    if (this.type !== InvertersType.CENTRAL_INVERTER || this.instances.length < 2) {
      return undefined;
    }
    return this.instances[1].id;
  }

  match = (definitionIds: readonly string[]): boolean => {
    if (this.areMicroinverters) {
      return definitionIds.length === 1 && this.definitions[0] === definitionIds[0];
    }
    if (definitionIds.length === 1) {
      return this.instances.length === 1 && this.instances[0]?.definitionId === definitionIds[0];
    }
    return (
      this.instances.length == 2
      && this.instances[0]?.definitionId === definitionIds[0]
      && this.instances[1]?.definitionId === definitionIds[1]
    );
  };

  buildSupplementalData = (firstInverterModel: string, secondInverterModel?: string): IInverterSelected[] => {
    if (this.areMicroinverters) {
      return [
        {
          definitionId: this.definitions[0],
          name: firstInverterModel
        }
      ];
    }
    // String inverters
    const inverters: IInverterSelected[] = [
      {
        definitionId: this.instances[0]?.definitionId,
        instanceId: this.firstStringInverterId,
        name: firstInverterModel
      }
    ];

    if (this.secondStringInverterId) {
      inverters.push({
        definitionId: this.instances[1]?.definitionId,
        instanceId: this.secondStringInverterId,
        name: secondInverterModel
      });
    }
    return inverters;
  };
}

class Inverter {
  readonly id: string;
  readonly definitionId: string;
  /**
   * Note: these would only be present on a string inverter that has internal MPPTs
   */
  private readonly stringInverterMppts: readonly IStringInverterMpptData[];

  constructor(data: IInverterData) {
    this.id = data.id;
    this.definitionId = data.definitionId;
    this.stringInverterMppts = data.stringInverterMppts ?? [];
  }

  toData(): IInverterData {
    return {
      id: this.id,
      definitionId: this.definitionId,
      stringInverterMppts: this.stringInverterMppts.length > 0 ? this.stringInverterMppts : undefined
    };
  }
}
