import {
  action, computed, observable
} from 'mobx';
import { handleApiError } from '../../../../../utils/helpers';
import type EditorStore from '../../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { DesignDelta } from '../../../../../domain/entities/Design/DesignDelta';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import type Limit from '../../../../../domain/models/Limit';
import type { Design } from '../../../../../domain/models/Design/Design';
import type { ArrayPlacementStage } from '../../../../../domain/stages/DesignStages/ArrayPlacementStage';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../../../../domain/models/SiteDesign/RoofFace';
import type { LayoutStrategy } from '../../../../../domain/models/RoofTopArray/LayoutStrategy';
import type { ModalStore } from '../../Modal';
import type { DesignWorkspace } from '../../../WorkspaceStore/workspaces/DesignWorkspace';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';
import type { LowSlopeMountingSystemDefinition } from '../../../../../domain/models/MountingSystemDefinition/LowSlopeMountingSystemDefinition';
import type { Configuration } from '../../../../../domain/models/MountingSystemDefinition/IConfiguration';

interface ILowSlopeViewModelDependencies {
  roofFace: RoofFace;
  modal: ModalStore;
  editor: EditorStore;
  domain: DomainStore;
  serviceBus: ServiceBus;
  designWorkspace: DesignWorkspace;
}

export class LowSlopeViewModel extends BaseViewModel {
  readonly propCodeUI: string = 'low_slope_modal';

  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;

  @observable
  private mountingSystemDefinition!: LowSlopeMountingSystemDefinition;

  @observable
  isRackSpacingAuto: boolean = true;

  @observable
  layoutStrategy!: LayoutStrategy;

  @observable
  private configuration!: Configuration;

  private readonly design: Design;
  private readonly roofFace: RoofFace;

  constructor(dependencies: ILowSlopeViewModelDependencies) {
    super(dependencies);
    this.design = dependencies.domain.design;
    this.designWorkspace = dependencies.designWorkspace;
    this.editor = dependencies.editor;
    this.roofFace = dependencies.roofFace;
    this.domain = dependencies.domain;
    this.serviceBus = dependencies.serviceBus;
    this.setInitialValues(dependencies.roofFace.serverId);
  }

  @action.bound
  setInitialValues(arrayAreaId: string): void {
    const mountingSystems = this.design.system.equipment.mountingSystems;
    const mountingSystem = mountingSystems.mountingSystemOn(arrayAreaId);
    if (!mountingSystem) {
      console.warn(`Mounting system not found for array area = ${arrayAreaId} in design = ${this.design.id}`);
      return;
    }
    if (!mountingSystem.configuration) {
      console.warn(`No configuration on mounting system on array area = ${arrayAreaId} in design = ${this.design.id}`);
      return;
    }
    this.layoutStrategy = mountingSystem.layoutStrategy.copy();
    this.configuration = mountingSystem.configuration.copy();
    this.isRackSpacingAuto = this.configuration.rackSpacing.isAutomatic;
    this.mountingSystemDefinition = mountingSystems.definitionFor(arrayAreaId) as LowSlopeMountingSystemDefinition;
  }

  @computed
  get tiltAngleLimits(): Limit {
    return this.mountingSystemDefinition.tiltAngleLimits;
  }

  @computed
  get tiltAngle(): number | undefined {
    return this.configuration.tiltAngle;
  }

  @computed
  get numberOfTiersPerRackLimits(): Limit {
    return this.mountingSystemDefinition.numberOfTiersPerRackLimits;
  }

  @computed
  get numberOfRowsInRack(): number | undefined {
    return this.configuration.numberOfRowsInRack;
  }

  @computed
  get azimuthValue(): number | undefined {
    return this.configuration.azimuth;
  }

  @computed
  get roofFaceName(): string {
    return this.roofFace.name;
  }

  @action.bound
  changeAzimuthValue(angle: number): void {
    this.configuration.azimuth = angle;
  }

  @action.bound
  changeTiltAngle(value: number): void {
    this.configuration.tiltAngle = value;
  }

  @action.bound
  changeNumberOfRows(value: number): void {
    this.configuration.numberOfRowsInRack = value;
  }

  @action.bound
  changeAutomaticRackSpacing(value: boolean): void {
    this.isRackSpacingAuto = value;
  }

  @computed
  get rackSpacingExactValueInInches(): number | undefined {
    return this.configuration?.rackSpacing?.exactValueInInchesRoundedToAWholeNumber;
  }

  @computed
  get rackSpacingDesignMonthForAutomaticCalculation(): string | undefined {
    return this.configuration?.rackSpacing?.autoForDesignMonth;
  }

  @action.bound
  changeAutoRackSpacingDesignMonth(value: number | string): void {
    this.configuration?.rackSpacing?.setDesignMonthForAutomaticSpacing(value.toString());
  }

  @action.bound
  changeRackSpacingExactValueInInches(inputValue: number): void {
    this.configuration?.rackSpacing?.setExactValueInInches(inputValue);
  }

  @computed
  get canApplyChanges(): boolean {
    return (
      this.layoutStrategy.hasAllRequiredProperties && this.configuration.rackSpacing.isValid(this.isRackSpacingAuto)
    );
  }

  @action.bound
  applyChanges = (): void => {
    const designService = new DesignService();
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor);

    const designBaseline = this.design.toData();
    designService
      .updateMountingSystem(this.roofFace.serverId, this.layoutStrategy.toData(), designBaseline, this.configuration)
      .then((response: DesignDelta): void => {
        const arrayPlacementStage = this.designWorkspace.stageManager!.currentStage as ArrayPlacementStage;

        this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        this.closeModal();
        arrayPlacementStage.redrawRoofTopArrayAreas();
      })
      .catch(handleApiError('Failed to apply changes to mounting system'))
      .finally((): void => hideLoaders());
  };

  polygonAzimuth(): number[] {
    return this.roofFace.azimuthAngles ?? [];
  }

  dispose(): void {
    return;
  }
}
