import { computed } from 'mobx';
import { canvasConfig } from '../../../../../config/canvasConfig';
import { Subpanel } from '../../../../../domain/models/SiteDesign/Subpanel';
import { SUBPANEL_TOOL_ID } from '../constants';
import type { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import type { IBaseSiteEquipmentToolDependencies } from './BaseSiteEquipmentTool';
import { BaseSiteEquipmentTool } from './BaseSiteEquipmentTool';

export class SubpanelTool extends BaseSiteEquipmentTool {
  override readonly id: string = SUBPANEL_TOOL_ID;
  override readonly icon: string = 'SP';
  override readonly title: string = 'Subpanel';
  override readonly description: string = this.title;

  constructor(dependencies: IBaseSiteEquipmentToolDependencies) {
    super(dependencies);
  }

  override createEquipment = (): Marker => {
    return this.drawableObjectsFactory.create<Subpanel>(Subpanel, {
      color: canvasConfig.subpanelIconColor
    });
  };

  override getEquipment(): Marker[] {
    const subpanel = this.domain.siteEquipment?.subpanel;
    if (subpanel) {
      return [subpanel as Marker];
    }
    return [];
  }

  @computed
  override get isDisabled(): boolean {
    const mainServicePanel = this.domain.siteEquipment?.mainServicePanel;
    return !mainServicePanel || this.toolbar.isToolDisabled(this.id);
  }
}
