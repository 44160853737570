import { observable } from 'mobx';
import type { Vector3 } from 'three';
import type { EProtrusionProfile } from '../../../domain/typings';
import type { Vertex } from '../../graphics/Vertex';
import type { IDrawable } from '../../mixins/Drawable';
import type { RoofFace } from '../../models/SiteDesign/RoofFace';
import { Deletable } from '../../mixins/Deletable';
import { Draggable } from '../../mixins/Draggable';
import { Drawable } from '../../mixins/Drawable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { WARNING } from '../Constants';
import {
  notify, vertexArrayToVector3Array
} from '../../../utils/helpers';
import {
  isPolygonInsideAnother, isPolygonIntersectingAnother, areVerticesInsidePolygon
} from '../../../utils/spatial';

const MixedClass = Deletable(Draggable(PolygonDrawable(Selectable(Drawable(class SimpleClass {})))));

export abstract class RoofProtrusion extends MixedClass implements IDrawable {
  layerNumber: number = 3;

  @observable
  profile?: EProtrusionProfile;
  @observable
  override name!: string;
  @observable
  firefighter: boolean = true;
  shape?: string;

  constructor() {
    super();
    this.type = 'protrusion';
  }

  drawVertices(vertices: Vector3[], removeIfCollinear: boolean = false): void {
    this.boundary.removeAll();
    vertices.forEach((vertex: Vector3): void =>
      this.addVertex({
        vertex,
        removeIfCollinear,
        originatingFromTracing: false
      })
    );
    this.redraw();
  }

  isValidAgainstRoofFace(roofFace: RoofFace, polygon?: Vector3[]): boolean {
    const protrusionVertices = polygon ?? this.getPolygonVertices(this.polygon);
    const roofFaceVertices = this.getPolygonVertices(roofFace.polygon);

    return isPolygonInsideAnother(protrusionVertices, roofFaceVertices, false);
  }

  notifyTooSmall(): void {
    return notify('Protrusion cannot be smaller than one inch', WARNING);
  }

  isValidAgainstProtrusions(roofFace: RoofFace, polygon?: Vector3[]): boolean {
    const protrusionId = this.mesh.id;
    const protrusionVertices = polygon ?? this.getPolygonVertices(this.polygon);

    for (const protrusion of roofFace.protrusions) {
      if (protrusionId !== protrusion.mesh.id) {
        const vertices = this.getPolygonVertices(protrusion.polygon);
        const notValid =
          isPolygonIntersectingAnother(protrusionVertices, vertices, false)
          || areVerticesInsidePolygon(protrusionVertices, vertices, false)
          || areVerticesInsidePolygon(vertices, protrusionVertices, false);

        if (notValid) {
          return false;
        }
      }
    }

    return true;
  }

  private getPolygonVertices(polygon: Vertex[]): Vector3[] {
    const vertices = vertexArrayToVector3Array(polygon);
    vertices.splice(vertices.length - 1, 1);
    return vertices;
  }
}
