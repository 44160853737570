import {
  action, computed, observable
} from 'mobx';
import * as Sentry from '@sentry/react';
import type Store from '../../Store';
import type { GuardStore } from '../GuardStore/GuardStore';
import type { ToolbarStore } from '../ToolbarStore/Toolbar';
import { ERROR } from '../../../domain/models/Constants';
import { notify } from '../../../utils/helpers';
import {
  CancellablePromiseKeys, cancelPromiseKeys
} from '../../../utils/CancellablePromise';
import type { UiStore } from '../UiStore';
import WorkspacesRegistry from './utils/registry';
import type {
  IWorkspace, TWorkspaceItem
} from './types';
import {
  isDesignWorkspace, isProjectWorkspace
} from './utils';

export enum Workspace {
  Project = 'project',
  Design = 'design'
}
export class WorkspaceStore {
  @observable
  private workspaces: TWorkspaceItem<IWorkspace>[] = [];

  @observable
  currentWorkspace!: IWorkspace;

  private readonly toolbar: ToolbarStore;
  private readonly guardStore: GuardStore;

  constructor(root: Store, uiStore: UiStore) {
    this.toolbar = uiStore.toolbar;
    this.guardStore = uiStore.guardStore;

    // Create instances of all available workspaces;
    this.createWorkspaces(root, uiStore);

    // Set project workspace as default since we have it
    // as first step of project configuration in most used scenario;
    this.changeWorkspace(Workspace.Project);
  }

  @computed
  get projectWorkspace(): IWorkspace | undefined {
    return this.workspaces.find((value: TWorkspaceItem<IWorkspace>): boolean => value.key === 'project')?.value;
  }

  @computed
  get designWorkspace(): IWorkspace | undefined {
    return this.workspaces.find((value: TWorkspaceItem<IWorkspace>): boolean => value.key === 'design')?.value;
  }

  @action
  createWorkspaces = (root: Store, uiStore: UiStore): void => {
    Object.keys(WorkspacesRegistry).forEach((key: string): void => {
      const WorkspaceClass = WorkspacesRegistry[key];
      const WorkspaceInstance = new WorkspaceClass(root, uiStore);

      this.workspaces.push({
        key,
        value: WorkspaceInstance
      });
    });
  };

  @action
  reset(): void {
    try {
      if (this.currentWorkspace) {
        this.currentWorkspace?.dispose();
        this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action
  changeWorkspace = (key: Workspace): void => {
    if (this.guardStore.workspace.frozen) {
      return;
    }

    // Cancel stringing initialization promise chain.
    if (key === Workspace.Project) {
      cancelPromiseKeys(
        CancellablePromiseKeys.StringingOptionsAndShowSummaryPanel,
        CancellablePromiseKeys.CreateInverterSelectionViewModel,
        CancellablePromiseKeys.GetSelectedInverterOption,
        CancellablePromiseKeys.LoadStringingOptions,
        CancellablePromiseKeys.ChangeArrayAreas
      );
    }

    const newWorkspace = this.workspaces.find((value: TWorkspaceItem<IWorkspace>): boolean => value.key === key)?.value;
    if (!newWorkspace) {
      throw new Error(`Cannot change to workspace ${key} - it does not exist`);
    }

    this.asyncChangeWorkspace(key);
  };

  @action
  asyncChangeWorkspace = async (key: Workspace): Promise<void> => {
    const newWorkspace = this.workspaces.find((value: TWorkspaceItem<IWorkspace>): boolean => value.key === key)?.value;
    if (!newWorkspace) {
      throw new Error(`Cannot change to workspace ${key} - it does not exist`);
    }

    try {
      Sentry.setTag('currentWorkspace', newWorkspace.id);
      await this.currentWorkspace?.dispose();
      this.currentWorkspace = newWorkspace;
      this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
      this.currentWorkspace.setup();
    } catch (e) {
      console.error(e);
      notify(`${key} workspace can't start - please reopen the project`, ERROR);
    }
  };

  isCurrentWorkspaceProjectWorkspace(): boolean {
    if (!this.currentWorkspace) {
      return false;
    }
    return isProjectWorkspace(this.currentWorkspace);
  }

  isCurrentWorkspaceDesignWorkspace(): boolean {
    if (!this.currentWorkspace) {
      return false;
    }
    return isDesignWorkspace(this.currentWorkspace);
  }
}
