import type {
  Line, Vector2
} from 'three';
import {
  EllipseCurve, MathUtils as ThreeMath, Mesh
} from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import {
  HALF_PI, SceneObjectType, TWO_PI
} from '../models/Constants';
import { getRootStore } from '../../stores/RootStoreInversion';
import { Unzoomable } from '../mixins/Unzoomable';
import { Drawable } from '../mixins/Drawable';
import { Arrow } from './Arrow';
import Line2DMaterial from './Line2DMaterial';
import Line3DGeometry from './Line3DGeometry';
import { LetterObject } from './LetterObject';

/** In Pixels */
const DEFAULT_COMPASS_RADIUS = 200;
const MINIMUM_COMPASS_RADIUS = 80;
const CARDINAL_LETTERS = ['N', 'W', 'S', 'E'];

const Mixing = Unzoomable(Drawable(class SimpleClass {}));

export class Compass extends Mixing {
  ring!: Mesh | Line;
  font: Font;
  radius: number;
  renderHeight: number;

  constructor(fontLoaded: Font, radius: number = DEFAULT_COMPASS_RADIUS) {
    super();

    this.font = fontLoaded;
    this.radius = ThreeMath.clamp(
      radius * this.mapZoomFactor,
      MINIMUM_COMPASS_RADIUS * this.mapZoomFactor,
      DEFAULT_COMPASS_RADIUS * this.mapZoomFactor
    );
    this.renderHeight = getRootStore().editor.getObjectRenderHeight(SceneObjectType.Compass);
    this.draw();
  }

  unzoom(factor: number): void {
    this.mesh.scale.set(factor, factor, factor);
  }

  rotateRing(angle: number): void {
    this.ring.rotation.z = angle;
  }

  changeRadius(radius: number): void {
    const curve = new EllipseCurve(0, 0, radius * this.mapZoomFactor, radius * this.mapZoomFactor, 0, TWO_PI, false, 0);
    const points = curve.getPoints(200);
    const vertices = points.map((vertex: Vector2): number[] => [vertex.x, vertex.y, 0.0]);
    const geometry = new Line3DGeometry(vertices);

    this.ring.geometry = geometry;
  }

  redraw(): void {
    return;
  }

  private draw(): void {
    const curve = new EllipseCurve(0, 0, this.radius, this.radius, 0, TWO_PI, false, 0);
    const points = curve.getPoints(200);
    const vertices = points.map((vertex: Vector2): number[] => [vertex.x, vertex.y, this.renderHeight]);
    const geometry = new Line3DGeometry(vertices);
    const material = new Line2DMaterial({
      diffuse: 0xffffff,
      thickness: 15 * this.mapZoomFactor
    });
    this.ring = new Mesh(geometry, material);
    this.mesh.add(this.ring);

    const midLine = new Mesh(
      new Line3DGeometry([
        [this.radius, 0, -5 * this.mapZoomFactor],
        [-this.radius, 0, -5 * this.mapZoomFactor]
      ]),
      new Line2DMaterial({
        diffuse: 0xfdec00,
        thickness: 5 * this.mapZoomFactor
      })
    );
    midLine.position.z = this.renderHeight;
    this.ring.add(midLine);

    const arrow = new Arrow();
    arrow.setColor(0xfdec00);
    /**
     * @NOTE: the z scale value is set as 0.00001
     * in order to remove the THREE.js warning
     */
    arrow.mesh.scale.set(1.75 * this.mapZoomFactor, 1.75 * this.mapZoomFactor, 1 * this.mapZoomFactor);
    arrow.mesh.position.y = this.radius / 4;
    arrow.mesh.position.z = this.renderHeight;
    this.ring.add(arrow.mesh);

    this.setCardinalPoints();
  }

  private setCardinalPoints(): void {
    const CARDINAL_OFFSET = this.radius + 50 * this.mapZoomFactor;
    const { font } = this;

    for (let itr = 0; itr < 4; itr++) {
      const circleSlice = itr * HALF_PI + HALF_PI;
      const letter = CARDINAL_LETTERS[itr];
      const posx = Math.cos(circleSlice) * CARDINAL_OFFSET;
      const posy = Math.sin(circleSlice) * CARDINAL_OFFSET;
      const cardinalPoint = new LetterObject(letter, font, 18 * this.mapZoomFactor, this.renderHeight);
      cardinalPoint.position.set(posx, posy, 0);

      this.mesh.add(cardinalPoint);
    }
  }
}
