import * as FileSaver from 'file-saver';
import set from 'lodash/set';
import React from 'react';
import type { ReactElement } from 'react';
import {
  action, computed, observable
} from 'mobx';
import type {
  ISummaryDataProps, SummaryMode
} from '@aurorasolar/lyra-ui-kit/lib/components/Summary';
import type { AxiosError } from 'axios';
import { ERROR } from '../../../../../domain/models/Constants';
import type { GalleryItemProps } from '../../../../../ui/components/Gallery/Gallery';
import {
  handleApiError, notify
} from '../../../../../utils/helpers';
import PermitPackageDocumentGenerationOptions, { type IPermitPackageDocumentGenerationOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import type { IPermitPackageUrls } from '../../../../../infrastructure/services/api/DocumentsService';
import type DomainStore from '../../../../DomainStore/DomainStore';
import { BaseViewModel } from '../../BaseViewModel';
import type { ModalStore } from '../../Modal';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type IOrderPreview from '../../../../../domain/entities/OrderPreview/IOrderPreview';
import {
  EPaymentType, isExternalOrder, isLyraOrder
} from '../../../../../domain/entities/OrderPreview/IOrderPreview';
import type {
  ILyraOrderPreview, IPaymentStructure
} from '../../../../../domain/entities/OrderPreview/IOrderPreview';
import config from '../../../../../config/config';
import {
  EDesignFileType,
  type IDesignFileData,
  type ISiteImageData
} from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageFiles';
import type { Watermark } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageOptions';
import { CodesAndStandards } from '../../../../../domain/models/SiteDesign/CodesAndStandards';

interface IPermitPackageViewModelDependencies {
  readonly modal: ModalStore;
  readonly domain: DomainStore;
  readonly editor: EditorStore;
  forwardToPermitPackageDownload?: boolean;
}

enum EPermitPackageAction {
  PREVIEW = 'PREVIEW',
  PURCHASE_IN_DESIGN_TOOL = 'PURCHASE_IN_DESIGN_TOOL',
  PURCHASE_IN_HOST_APP = 'PURCHASE_IN_HOST_APP',
  DOWNLOAD = 'DOWNLOAD',
  CLOSE = 'CLOSE'
}

interface IPermitPackageFileDirectDownloadFallback {
  url: string;
  isPreview: boolean;
}

type PermitPackageActionError = {
  message: string;
  status: number;
};

export class PermitPackageViewModel extends BaseViewModel {
  readonly propCodeUI = 'permit_package_modal';
  override readonly editor: EditorStore;

  @observable
  documentGenerationOptions!: PermitPackageDocumentGenerationOptions;

  @observable
  summaryComponentMode: SummaryMode = 'preview';

  @observable
  summaryComponentData: ISummaryDataProps = {};

  @observable
  action: EPermitPackageAction = EPermitPackageAction.PREVIEW;

  @observable
  isSaving: boolean = false;

  @observable
  editingFileTitleByIndex: Array<number> = [];

  @observable
  private permitPackageDownloadUrls: IPermitPackageUrls = {
    preview: '',
    purchase: ''
  };

  @observable
  private permitPackageFileDirectDownloadFallback: IPermitPackageFileDirectDownloadFallback = {
    url: '',
    isPreview: true
  };

  private externalPaymentUrl?: string;

  constructor(dependencies: IPermitPackageViewModelDependencies) {
    super(dependencies);
    this.editor = dependencies.editor;

    this.getOrderPreview().then((orderPreview: IOrderPreview): void => {
      if (isExternalOrder(orderPreview) && !orderPreview.requiresPayment) {
        this.setSummaryData(
          'success',
          this.buildSuccessProps('Permit package ready for download'),
          EPermitPackageAction.DOWNLOAD
        );
      }
    });
  }

  /**
   * See https://aurorasolar.atlassian.net/browse/LYRA-7082 for description of this logic.
   *
   * When changing this logic, make sure to also change the same method inside PlanSetCustomizationPageViewModel.tsx
   */
  @computed
  get showRotateSiteMapPlacardOption(): boolean {
    const electricalCode = this.domain.project.designParameters.codesAndStandards.electricalCode;
    return !(electricalCode === CodesAndStandards.NEC_2020 || electricalCode === CodesAndStandards.CEC_2022);
  }

  @action
  setEditingFileTitle = (index: number): void => {
    const indexOf = this.editingFileTitleByIndex.indexOf(index);
    if (indexOf === -1) {
      this.editingFileTitleByIndex.push(index);
    } else {
      this.editingFileTitleByIndex.splice(indexOf, 1);
    }
  };

  @computed
  get getCurrentDesignId(): string {
    return this.domain.design.id ?? '';
  }

  @computed
  get isInstallerALicensedContractor(): boolean {
    return !config.user.isHomeowner;
  }

  @computed
  get isPermitPackageDocumentGenerationOptionsValid(): boolean {
    if (!this.documentGenerationOptions || this.summaryComponentMode === 'progress') {
      // Prevent user from clicking button, until options are loaded
      return false;
    }

    const {
      address, name, licenseNumber, phoneNumber
    } = this.documentGenerationOptions.engineerOfRecord;
    const { showEngineerOfRecordInfo } = this.documentGenerationOptions.advancedSettings.titleBlock;

    if (!this.isInstallerALicensedContractor) {
      // When installer is a homeowner we just skip the validations below
      return true;
    }

    if (!showEngineerOfRecordInfo) {
      // For licensed contractor, but with checkbox for EOR info unchecked we also skip validations
      return true;
    }

    const isAddressOneFilled = !!address?.addressOne;
    const isCityFilled = !!address?.city;
    const isProvinceFilled = !!address?.province;
    const isNameValid = !!name && name.length <= 21;

    const addressFilled = isAddressOneFilled && isCityFilled && isProvinceFilled;
    const isEngineerOfRecordDataFilled = isNameValid && !!licenseNumber && !!phoneNumber;

    // For licensed contractor and checkbox for EOR checked we validate form as usual.
    return addressFilled && isEngineerOfRecordDataFilled;
  }

  @action.bound
  getPermitPackageGenerationOptions(): void {
    this.documentsService
      .getPermitPackageOptions(this.domain.design.id)
      .then((permitPackageDocumentOptions: IPermitPackageDocumentGenerationOptionsData): void => {
        this.documentGenerationOptions = new PermitPackageDocumentGenerationOptions(
          permitPackageDocumentOptions,
          this.domain.internalReferenceId
        );
      })
      .catch(handleApiError('Could not retrieve permit package generation options'));
  }

  @computed
  get siteImagesAsGalleryItems(): GalleryItemProps[] {
    return this.documentGenerationOptions?.includedFiles.siteImages.map(
      (imageOption: ISiteImageData): GalleryItemProps => {
        const image = this.domain.project.site.imageWithId(imageOption.id)!;
        return {
          id: image.id,
          image: image.thumbnailUrl(this.domain.project.account, this.domain.project.id),
          name: image.title,
          description: image.description ?? '',
          selected: imageOption.selected
        };
      }
    );
  }

  @action
  toggleImageSelected = (imageId: string): void => {
    this.documentGenerationOptions.includedFiles.siteImages =
      this.documentGenerationOptions.includedFiles.siteImages.map((image: ISiteImageData): ISiteImageData => {
        if (imageId === image.id) {
          image.selected = !image.selected;
        }
        return image;
      });
  };

  @action.bound
  onChangeReviewerName(value: string): void {
    this.documentGenerationOptions.options.reviewerFullName = value;
  }

  @action.bound
  onHandleChangeWatermark(value: string | number): void {
    this.documentGenerationOptions.options.watermarks = [value.toString() as Watermark];
  }

  @action.bound
  onHandleChangeIncludeSourceCadFiles(value: boolean): void {
    this.documentGenerationOptions.options.includeSourceCadFiles = value;
  }

  @action
  onHandleEngineerOfRecordFormFieldChange(field: string, value: string): void {
    set(this.documentGenerationOptions.engineerOfRecord, field, value);
  }

  @computed
  get leftButtonLabel(): string {
    return 'Cancel';
  }

  @computed
  get actionButtonLabel(): string {
    switch (this.action) {
      case EPermitPackageAction.PREVIEW:
        return 'Preview Permit Package';
      case EPermitPackageAction.PURCHASE_IN_DESIGN_TOOL:
        return !this.summaryComponentData.order?.total && !this.summaryComponentData.order?.credit
          ? 'Download'
          : 'Purchase';
      case EPermitPackageAction.PURCHASE_IN_HOST_APP:
        return 'Purchase';
      case EPermitPackageAction.DOWNLOAD:
        return 'Download';
      case EPermitPackageAction.CLOSE:
        return 'Close';
      default:
        // eslint-disable-next-line no-console
        console.error(`Unsupported case: ${this.action}`);
        return '???';
    }
  }

  @computed
  get actionButtonDisabled(): boolean {
    return !this.documentGenerationOptions || this.summaryComponentMode === 'progress';
  }

  @computed
  get areWatermarkOptionsDisabled(): boolean {
    return this.action === EPermitPackageAction.PREVIEW;
  }

  @computed
  get directDownloadFallbackUrlParams(): IPermitPackageFileDirectDownloadFallback {
    const {
      url, isPreview
    } = this.permitPackageFileDirectDownloadFallback;
    return {
      url,
      isPreview
    };
  }

  @action.bound
  async proceed(): Promise<void> {
    if (!this.isPermitPackageDocumentGenerationOptionsValid) {
      const message = 'Please correctly fill in all the required details on the Engineer of Record tab first.';

      notify(message, ERROR);
      return;
    }

    switch (this.action) {
      case EPermitPackageAction.PREVIEW:
        this.setSummaryData('progress', this.buildProgressProps('Generating Permit Package Preview', 30));
        try {
          if (await this.savePermitPackageDocumentGenerationOptions()) {
            await this.getPermitPackage(true);
            await this.proceedBasedOnOrderDetails();
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);

          if (this.permitPackageFileDirectDownloadFallback.url) {
            await this.proceedBasedOnOrderDetails();
          } else {
            this.setSummaryData(
              'failure',
              this.buildFailureProps(
                'Failed to generate permit package',
                this.buildErrorElement(e as AxiosError<PermitPackageActionError>)
              )
            );
          }
        }
        break;

      case EPermitPackageAction.PURCHASE_IN_HOST_APP:
        await this.domain.updateDesign();
        if (this.externalPaymentUrl) {
          window.location.href = this.externalPaymentUrl;
        } else {
          // eslint-disable-next-line no-console
          console.error('External purchase URL is not specified - cannot redirect user to it');
        }
        break;

      case EPermitPackageAction.DOWNLOAD:
      case EPermitPackageAction.PURCHASE_IN_DESIGN_TOOL:
        this.setSummaryData('progress', this.buildProgressProps('Generating Permit Package', 30));
        try {
          this.permitPackageFileDirectDownloadFallback.url = '';
          await this.savePermitPackageDocumentGenerationOptions();
          await this.getPermitPackage();
          this.setSummaryData(
            'success',
            this.buildSuccessProps('Permit package downloaded'),
            EPermitPackageAction.CLOSE
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          if (this.action === EPermitPackageAction.DOWNLOAD || this.permitPackageFileDirectDownloadFallback.url) {
            if (this.permitPackageFileDirectDownloadFallback.url) {
              this.setSummaryData(
                'failure',
                this.buildFailureProps(
                  'Automatic download failed',
                  'Failed to automatically download permit package. Click the direct download link below.'
                ),
                EPermitPackageAction.CLOSE
              );
            } else {
              this.setSummaryData('failure', this.buildDocumentGenerationFailureProps());
            }
          } else {
            this.setSummaryData(
              'failure',
              this.buildFailureProps(
                'Something went wrong when downloading the permit package!',
                this.buildErrorElement(e as AxiosError<PermitPackageActionError>)
              )
            );
          }
        }
        break;

      case EPermitPackageAction.CLOSE:
        this.closeModal();
        break;

      default:
        // eslint-disable-next-line no-console
        console.error(`Unsupported case: ${this.action}`);
    }
  }

  private buildErrorElement = ({
    response, code
  }: AxiosError<PermitPackageActionError>): ReactElement[] => {
    let explanation: ReactElement;
    switch (code) {
      case '402':
        const paymentMethodElement = config.host.updatePaymentUrl ? (
          <a href={config.host.updatePaymentUrl}>payment method</a>
        ) : (
          'payment method'
        );
        explanation = <p>Please update your {paymentMethodElement} and try again.</p>;
        break;
      case '500':
        explanation = <p>Please try again or contact customer support.</p>;
        break;
      default:
        explanation = <p>Please try again, maybe check your internet connection.</p>;
    }

    let errorElement = (
      <>
        <p>{response?.data?.message ?? 'Something went wrong!'}</p>
        {explanation}
      </>
    );

    return [errorElement];
  };

  @action.bound
  private async proceedBasedOnOrderDetails(): Promise<void> {
    const orderPreview: IOrderPreview = await this.getOrderPreview();

    if (isLyraOrder(orderPreview)) {
      this.setSummaryData('order', this.buildOrderProps(orderPreview), EPermitPackageAction.PURCHASE_IN_DESIGN_TOOL);
    } else if (isExternalOrder(orderPreview)) {
      if (orderPreview.requiresPayment) {
        this.setSummaryData(
          'externalPayment',
          this.buildExternalPaymentProps(),
          EPermitPackageAction.PURCHASE_IN_HOST_APP,
          orderPreview.paymentUrl
        );
      } else {
        this.setSummaryData(
          'success',
          this.buildSuccessProps('Permit package is ready for download'),
          EPermitPackageAction.DOWNLOAD
        );
      }
    }
  }

  @action
  saveDesignFile = (file: File): void => {
    this.isSaving = true;
    this.documentsService
      .uploadDesignFile(this.getCurrentDesignId, file)
      .then((fileId: string): void => {
        const newDesignFile = {
          id: fileId,
          title: file.name.replace(/\.pdf$/, ''),
          type: EDesignFileType.CUSTOM_CONTENT
        };
        this.documentGenerationOptions.includedFiles.designFiles.push(newDesignFile);
      })
      .catch(handleApiError('Failed to upload design file'))
      .finally((): void => {
        this.isSaving = false;
      });
  };

  @action
  updateFileTitle = (id: string, newTitle: string): void => {
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.map((file: IDesignFileData): IDesignFileData => {
        if (file.id === id) {
          return {
            ...file,
            title: newTitle
          };
        }

        return file;
      });
  };

  @action
  updateFileType = (id: string, newType: EDesignFileType): void => {
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.map((file: IDesignFileData): IDesignFileData => {
        if (file.id === id) {
          return {
            ...file,
            type: newType as EDesignFileType
          };
        }

        return file;
      });
  };

  @action
  deleteFile = (designFileId: string): void => {
    this.documentGenerationOptions.includedFiles.designFiles =
      this.documentGenerationOptions.includedFiles.designFiles.filter(
        (designFile: IDesignFileData): boolean => designFile.id !== designFileId
      )!;
  };

  @action
  private setSummaryData = (
    mode: SummaryMode,
    data: ISummaryDataProps,
    nextAction?: EPermitPackageAction,
    externalPaymentUrl?: string
  ): void => {
    this.summaryComponentMode = mode;
    this.summaryComponentData = data;
    if (nextAction) {
      this.action = nextAction;
    }
    this.externalPaymentUrl = externalPaymentUrl;
  };

  private buildProgressProps = (message: string | ReactElement[], waitTimeInSeconds: number): ISummaryDataProps => ({
    progress: {
      message,
      estimatedWaitTimeInSeconds: waitTimeInSeconds
    }
  });

  private buildOrderProps = (orderPreview: ILyraOrderPreview): ISummaryDataProps => {
    const credit = orderPreview.paymentStructure.find(
      (item: IPaymentStructure): boolean => item.type === EPaymentType.CREDIT
    )?.amount;
    const alreadyPaid = orderPreview.paymentStructure.find(
      (item: IPaymentStructure): boolean => item.type === EPaymentType.PAID
    )?.amount;
    const charge = orderPreview.paymentStructure.find(
      (item: IPaymentStructure): boolean => item.type === EPaymentType.CHARGE
    )?.amount;
    return {
      order: {
        type: orderPreview.product.title,
        amount: orderPreview.product.price.amount,
        description: orderPreview.product.price.description,
        corrections: orderPreview.product?.price?.corrections,
        subtotal: credit || alreadyPaid ? orderPreview.order.total : undefined,
        credit,
        previousPayments: alreadyPaid,
        total: charge || 0
      }
    };
  };

  private buildExternalPaymentProps = (): ISummaryDataProps => ({
    externalPayment: {
      message: 'Purchase required',
      explanation: 'You will be taken to another page to complete the purchase'
    }
  });

  private buildSuccessProps = (message: string | ReactElement[]): ISummaryDataProps => ({
    success: {
      message
    }
  });

  private buildDocumentGenerationFailureProps = (): ISummaryDataProps =>
    this.buildFailureProps(
      'Document generation failed',
      'Please try again. If the issue persists, contact customer support.'
    );

  private buildFailureProps = (
    message: string | ReactElement[],
    explanation: string | ReactElement[]
  ): ISummaryDataProps => ({
    failure: {
      message,
      explanation
    }
  });

  @action.bound
  private async savePermitPackageDocumentGenerationOptions(): Promise<boolean> {
    try {
      this.permitPackageDownloadUrls = await this.documentsService.savePermitPackageOptions(
        this.domain.design.id,
        this.documentGenerationOptions.toData({
          forServer: true
        })
      );
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      this.setSummaryData(
        'failure',
        this.buildFailureProps(
          'Failed to save document generation options',
          this.buildErrorElement(e as AxiosError<PermitPackageActionError>)
        )
      );
      return false;
    }
  }

  @action.bound
  private async getOrderPreview(): Promise<IOrderPreview> {
    return this.documentsService
      .getOrder(this.domain.design.id)
      .catch(handleApiError('Failed to retrieve order preview'));
  }

  /**
   * Downloads preview and actual permit package
   */
  private getPermitPackage = async (isPreview: boolean = false): Promise<void> => {
    try {
      const permitPackageDownloadUrl = this.permitPackageDownloadUrls[isPreview ? 'preview' : 'purchase'];
      const url = await this.documentsService.getPermitPackageLink(permitPackageDownloadUrl);
      this.permitPackageFileDirectDownloadFallback = {
        url,
        isPreview
      };
      this.setSummaryData(
        'progress',
        this.buildProgressProps(`Downloading Permit Package${isPreview ? ' Preview' : ''}.`, 30)
      );
      const permitPackageDoc = await this.documentsService.getPermitPackageDoc(url, isPreview);
      FileSaver.saveAs(permitPackageDoc.file, permitPackageDoc.fileName);
    } catch (e) {
      const errorMsg = `Failed to download permit package ${isPreview ? 'preview' : ''}`;
      return handleApiError(errorMsg)(e);
    }
  };

  override dispose(): void {
    // do nothing
  }
}
