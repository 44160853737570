import type { Option } from '@aurorasolar/lyra-ui-kit';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactText
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import useStore from '../../../../../stores/useStore';
import type { FramingRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/FramingRoofFaceViewModel';
import {
  LABEL_FRAMING, TRUSS_SPACING_LIMIT
} from '../../constants';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';

function mapTrussCrossDimensionOptions(options: IOption<BaseAttributes>[] | undefined): Option[] {
  return !options
    ? []
    : options.map(
      (option: IOption<BaseAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
}

const FramingRoofFace = observer((): ReactElement => {
  const {
    NumberField, Select, SelectToggle
  } = LyraFormElementsDesignTool.FormElementsDesignTool;
  const {
    roofFaceProps, properties
  } = useStore();
  const [viewModel] = useState<FramingRoofFaceViewModel>(
    roofFaceProps.currentRoofPropertyEditor as FramingRoofFaceViewModel
  );

  useEffect((): void => {
    properties.setTitle(LABEL_FRAMING);
    viewModel.loadOptions();
  }, [viewModel, properties]);

  const onYearToggle = useCallback(
    (option: OptionProps): Promise<void> => viewModel.setYearOfConstruction(option.value),
    [viewModel]
  );

  const onTrussDimensionSelect = useCallback(
    (option: Option): void => viewModel.setTrussCrossDimensions(option),
    [viewModel]
  );

  const onConstructionTypeSelect = useCallback(
    (value: ReactText): void => viewModel.setConstructionType(value),
    [viewModel]
  );

  const onTrussSpacingSelect = useCallback((value: ReactText): void => viewModel.setTrussSpacing(value), [viewModel]);

  const onOtherTrussSpacingSelect = useCallback(
    (value: ReactText): void => viewModel.setOtherTrussSpacing(value),
    [viewModel]
  );

  return (
    <RoofPropertiesParent viewModel={viewModel} multiEdit={false}>
      <Select.Select
        label="CONSTRUCTION TYPE"
        listOptions={mapTrussCrossDimensionOptions(viewModel.constructionTypeOptions)}
        onSelect={onConstructionTypeSelect}
        selectedOptionColor={EThemeComponentColor.PURPLE}
        selected={viewModel.constructionType?.attributes.value}
      />
      <SelectToggle.ToggleSelect
        className="mt-xs"
        label={viewModel.topChordLabel}
        placeHolder=""
        toggleOptions={viewModel.yearsOfConstructionOptions}
        selectedToggle={
          viewModel.yearOfConstruction !== undefined
            ? {
              name: viewModel.yearOfConstruction === 1950 ? 'Pre-1960' : 'Post-1960',
              value: viewModel.yearOfConstruction.toString()
            }
            : undefined
        }
        onToggle={onYearToggle}
        listOptions={mapTrussCrossDimensionOptions(viewModel.trussCrossDimensionOptions)}
        selected={viewModel.trussCrossDimensions?.attributes}
        selectedOptionColor={EThemeComponentColor.PURPLE}
        onSelect={onTrussDimensionSelect}
      />
      <Select.Select
        className="mt-xs"
        label={viewModel.spacingLabel}
        listOptions={mapTrussCrossDimensionOptions(viewModel.trussSpacingOptions)}
        onSelect={onTrussSpacingSelect}
        selectedOptionColor={EThemeComponentColor.PURPLE}
        selected={viewModel.trussSpacing?.attributes.value}
      />
      {viewModel.trussSpacing?.attributes.name === 'other' && (
        <NumberField.NumberField
          className="mt-xs"
          measure="in"
          limits={TRUSS_SPACING_LIMIT}
          value={Number(viewModel.trussSpacing.attributes.value)}
          onChange={onOtherTrussSpacingSelect}
          fractionDigits={2}
          allowOutOfRange={false}
        />
      )}
    </RoofPropertiesParent>
  );
});

export { FramingRoofFace };
