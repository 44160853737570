import styled, { PropsTheme, SimpleInterpolation, withTheme } from 'styled-components';
import { ColorThemeProps, getColorTheme, getFontColorDependingOnTheme } from '../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { Flexbox } from '../Layout';
import { Color } from '../../index';

type TabsWrapperProps = PropsTheme & {
  inactiveThemeColor: EThemeComponentColor;
};

type TabLayoutProps = TabsWrapperProps & {
  activeThemeColor: EThemeComponentColor;
  inactiveBorderThemeColor: EThemeComponentColor;
  active: boolean;
};

function getColorProps(themeProps: ColorThemeProps): SimpleInterpolation {
  return`
    background: ${getColorTheme(themeProps)};
    color: ${getFontColorDependingOnTheme(themeProps)};
  `;
}

function getInactiveThemeColor(
  {inactiveThemeColor, theme}: TabsWrapperProps
  ): SimpleInterpolation {
 return getColorProps({
   colorTheme: inactiveThemeColor,
   theme
 });
}

function getActiveThemeColor(
  {active, activeThemeColor, theme, inactiveBorderThemeColor}: TabLayoutProps
  ): SimpleInterpolation {
    const themeProps: ColorThemeProps = {
      colorTheme: activeThemeColor,
      theme
    };

    const borderThemeProps: ColorThemeProps = {
      colorTheme: inactiveBorderThemeColor,
      theme
    };

    return active ? getColorProps(themeProps) + `
    border: 1.6px solid ${getColorTheme(themeProps)};
  ` : `
    border: 1.6px solid ${getColorTheme(borderThemeProps)};
  `;
}

export const TabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  & :first-child {
    border-top-left-radius: 6.4px;
    border-bottom-left-radius: 6.4px;
  }

  & :last-child {
    border-top-right-radius: 6.4px;
    border-bottom-right-radius: 6.4px;
  }

  ${getInactiveThemeColor}
`;

export const TabLayout = styled.div<TabLayoutProps>`
  cursor: pointer;
  flex: 1;
  ${getActiveThemeColor}
`;

export const CustomTabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  text-align: center;
`;

export const CustomTabLayout = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center',
  stretch: 'auto'
})<TabLayoutProps>`
  cursor: pointer;
  padding: 12px 15px;
  margin-right: 2px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  svg {
    path {
      fill: ${({
       theme,
       active,
       activeThemeColor,
       inactiveThemeColor
     }: TabLayoutProps): Color => active
          ? getColorTheme({ colorTheme: activeThemeColor, theme })
          : getColorTheme({ colorTheme: inactiveThemeColor, theme })};
    }
  }
  
  &.active-tab {
    background: #fafafa;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: -1px;
      background-color: #fafafa;
    }
  }

  .name {
    font-weight: 400;
    margin-left: 10px;
    font-size: 19px;
    color: ${({
      theme,
      active,
      activeThemeColor,
      inactiveThemeColor
    }: TabLayoutProps): Color => active
        ? getColorTheme({ colorTheme: activeThemeColor, theme })
        : getColorTheme({ colorTheme: inactiveThemeColor, theme })};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  max-height: 455px;
  overflow: auto;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  border-radius: 0 0 4px 4px;
  padding: 40px 10px 10px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.grayColor11};;
  box-sizing: border-box;
  @media (max-height: 768px) {
    max-height: 350px;
  }
`;
