import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import {
  Caption, ImageStyle, RoundImage, RoundThumbnailRoot
} from './styles';

type Props = BaseProps & {
  onClick?: (event: MouseEvent) => void;
  image?: string;
  caption?: string | ReactElement;
  width?: string;
  height?: string;
};

function RoundThumbnail(props: Props): ReactElement {
  const {
    caption, image, onClick, height, width
  } = props;

  return (
    <RoundThumbnailRoot onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      onClick?.(event.nativeEvent);
    }}>
      <RoundImage width={width as string} height={height as string} clickable={!!onClick}>
        <ImageStyle src={image} />
      </RoundImage>
      <Caption>{caption}</Caption>
    </RoundThumbnailRoot>
  );
}

export default RoundThumbnail;
