import type {
  Intersection, Object3D, OrthographicCamera, PerspectiveCamera
} from 'three';
import type EditorStore from '../EditorStore';
import type { ViewPort } from '../ViewportController';
import type { Selectable } from '../../../domain/mixins/Selectable';
import { getLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import type { Hoverable } from '../../../domain/mixins/Hoverable';
import { BaseCastObjectControl } from './BaseCastObjectControl';

type HoverEvents = {
  HoverInObject: { hoverObject: Hoverable };
  HoverOutObject: { hoverObject: Hoverable };
};

export class HoverControl extends BaseCastObjectControl<HoverEvents> {
  private previousHoveredObjects: Object3D[] = [];
  constructor(
    editor: EditorStore,
    viewport: ViewPort,
    camera: OrthographicCamera | PerspectiveCamera,
    objects?: Selectable[]
  ) {
    super(editor, viewport, camera);
    this.objects = objects;

    this.deactivate();
    this.activate();
  }

  override dispose(): void {
    this.deactivate();
  }

  protected override onMouseMove = (event: MouseEvent): void => {
    event.preventDefault();
    this.setMouse(event.clientX, event.clientY);

    const intersected = this.getCastedObjects();

    const newHovered = intersected
      .filter((i: Intersection, index: number): boolean => (getLyraModelByMesh(i.object) as Hoverable).isHoverable)
      .map((i: Intersection, index: number): Object3D => {
        this.dispatchEvent({
          type: 'HoverInObject',
          hoverObject: getLyraModelByMesh(i.object)
        });

        return i.object;
      });

    this.previousHoveredObjects.forEach((pObject: Object3D, index: number): void => {
      if (newHovered.indexOf(pObject) === -1) {
        this.dispatchEvent({
          type: 'HoverOutObject',
          hoverObject: getLyraModelByMesh(pObject)
        });
      }
    });

    this.previousHoveredObjects = newHovered;
  };
}
