import {
  action, observable
} from 'mobx';
import { DocumentsService } from '../../../infrastructure/services/api/DocumentsService';

export interface ISiteImageData {
  id: string;
  title: string;
  description?: string;
}

export class SiteImage {
  @observable
  id: string;
  @observable
  title: string;
  @observable
  description?: string;

  constructor(data: ISiteImageData) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
  }

  toData(): ISiteImageData {
    return {
      id: this.id,
      title: this.title,
      description: this.description || undefined
    };
  }

  @action
  setTitle = (title: string): void => {
    if (title) {
      this.title = title;
    }
  };

  @action
  setDescription = (description: string): void => {
    this.description = description;
  };

  thumbnailUrl = (accountId: string, projectId: string): string => {
    return new DocumentsService().getSiteImageThumbnailUrl(accountId, projectId, this.id);
  };
}
