import { MathUtils as ThreeMath } from 'three';
import {
  action, computed, observable, runInAction
} from 'mobx';
import find from 'lodash/find';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import type { Framing } from '../../../../../domain/models/SiteDesign/Framing';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import {
  UpdateRoofFacePropertyKey,
  type IUpdateRoofFacePropertyDependencies
} from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import { FRAMING_CODE } from '../../../../../ui/containers/RoofFaceProperties/constants';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import { inchesInMeters } from '../../../../../constants/distances';
import { convertMeterToInches } from '../../../../../utils/Convertions';
import {
  roofTypeKeyToAttribute,
  SAMPLE_YEAR_BEFORE_1960,
  SAMPLE_YEAR_AFTER_1960,
  crossSectionKeyToAttribute
} from './constants';

type YearOfConstruction = {
  year: number;
  label: string;
};

type FilterFunction = (option: IOption<BaseAttributes>) => boolean;

const yearConstruction = 1960;

const getYearOfConstructionByCrossSection = (crossSectionKey: string): number => {
  return crossSectionKey.includes('POST1960') ? SAMPLE_YEAR_AFTER_1960 : SAMPLE_YEAR_BEFORE_1960;
};

const getOtherTrussSpacingOption = (value: string): IOption<BaseAttributes> => ({
  id: 'other',
  optionType: 'trussSpacingOptions',
  attributes: {
    name: 'other',
    value
  }
});

const trussSpacingOptions: IOption<BaseAttributes>[] = [
  {
    id: '12in',
    optionType: 'trussSpacingOptions',
    attributes: {
      name: '12 in',
      value: '12'
    }
  },
  {
    id: '16in',
    optionType: 'trussSpacingOptions',
    attributes: {
      name: '16 in',
      value: '16'
    }
  },
  {
    id: '24in',
    optionType: 'trussSpacingOptions',
    attributes: {
      name: '24 in',
      value: '24'
    }
  },
  {
    id: '32in',
    optionType: 'trussSpacingOptions',
    attributes: {
      name: '32 in',
      value: '32'
    }
  },
  {
    id: '48in',
    optionType: 'trussSpacingOptions',
    attributes: {
      name: '48 in',
      value: '48'
    }
  },
  getOtherTrussSpacingOption('1')
];

const updateOtherTrussSpacinsOption = (value: string): void => {
  const otherOption = trussSpacingOptions.find(
    ({ attributes: { name } }: IOption<BaseAttributes>): boolean => name === 'other'
  );
  otherOption!.attributes.value = value;
};

const findOptionByValue =
  (value: string | number): FilterFunction =>
    (option: IOption<BaseAttributes>): boolean =>
      option.attributes.value === `${value}`;

export class FramingRoofFaceViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = FRAMING_CODE;
  yearsOfConstructionOptions: OptionProps[] = [
    {
      name: 'Pre-1960',
      value: '1950'
    },
    {
      name: 'Post-1960',
      value: '1970'
    }
  ];

  @observable
  constructionTypeOptions: IOption<BaseAttributes>[] = [];

  @observable
  trussSpacingOptions: IOption<BaseAttributes>[] = trussSpacingOptions;

  @observable
  trussCrossDimensionOptions: IOption<BaseAttributes>[] = [];

  @observable
  yearOfConstruction: number | undefined = 1970;

  @observable
  trussCrossDimensions?: IOption<BaseAttributes>;

  @observable
  offsetDistance: number | undefined;

  @observable
  trussSpacing?: IOption<BaseAttributes>;

  @observable
  constructionType?: IOption<BaseAttributes>;

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.Framing;
  }

  get dimensions(): string {
    return this.trussCrossDimensions?.attributes?.name ?? '- Select an option -';
  }

  @computed
  get yearOfConstructionDesc(): string {
    if (!this.yearOfConstruction) {
      return '';
    }
    return this.yearOfConstruction < yearConstruction ? 'PRE-1960' : 'POST-1960';
  }

  @computed
  get topChordLabel(): string {
    if (this.getConstructionType() !== undefined) {
      if (this.getConstructionType() === 'RAFTERS') {
        return 'RAFTER TOP-CHORD CROSS DIMENSIONS';
      } else if (this.getConstructionType() === 'BEAMS_AND_PURLINS' || this.getConstructionType() === 'VAULTED_BEAMS') {
        return 'BEAM TOP-CHORD CROSS DIMENSIONS';
      } else {
        return 'TRUSS TOP-CHORD CROSS DIMENSIONS';
      }
    }
    return 'TRUSS TOP-CHORD CROSS DIMENSIONS';
  }

  @computed
  get spacingLabel(): string {
    if (this.getConstructionType() !== undefined) {
      if (this.getConstructionType() === 'RAFTERS') {
        return 'RAFTER SPACING (O.C. IN INCHES)';
      } else if (this.getConstructionType() === 'BEAMS_AND_PURLINS' || this.getConstructionType() === 'VAULTED_BEAMS') {
        return 'BEAM SPACING (O.C. IN INCHESs)';
      } else {
        return 'TRUSS SPACING (O.C. IN INCHES)';
      }
    }
    return 'TRUSS SPACING (O.C. IN INCHES)';
  }

  @computed
  get spacingValueText(): string {
    if (this.trussSpacing!.attributes.name === 'other') {
      return `other (${this.trussSpacing!.attributes.value} in)`;
    }
    return this.trussSpacing!.attributes.name;
  }

  @computed
  get showInput(): boolean {
    const value = this.trussSpacing?.attributes.value;
    if (!value) {
      return false;
    }
    return !find(this.trussSpacingOptions, findOptionByValue(value)) || value === '0';
  }

  @action.bound
  setTrussCrossDimensions(option: Option): void {
    if (!option) {
      return;
    }
    const trussCrossDimensions = find(this.trussCrossDimensionOptions, findOptionByValue(option.value));

    if (trussCrossDimensions) {
      this.trussCrossDimensions = trussCrossDimensions;
    }
  }

  @action.bound
  setTrussSpacing(value: string | number): void {
    const spacing = find(this.trussSpacingOptions, findOptionByValue(value));
    if (spacing) {
      this.trussSpacing = spacing;
    }
  }

  @action.bound
  setOtherTrussSpacing(value: string | number): void {
    const lastOption = this.trussSpacingOptions.length - 1;
    this.trussSpacing = this.trussSpacingOptions[lastOption];
    this.trussSpacing.attributes.value = `${value}`;
  }

  @action.bound
  setConstructionType(value: string | number): void {
    if (!value) {
      return;
    }

    const constructionType = find(this.constructionTypeOptions, findOptionByValue(value));

    if (constructionType) {
      this.constructionType = constructionType;
    }
  }

  getConstructionType(): string | number {
    if (this.constructionType !== undefined) {
      return this.constructionType.attributes.value;
    }
    return 'MANUFACTURED_TRUSS';
  }

  @action.bound
  setOffsetDistance(value: number): void {
    if (!value) {
      return;
    }
    this.offsetDistance = value;
  }

  async setYearOfConstruction(value: string | number): Promise<void> {
    if (!value) {
      return;
    }

    await this.domain.getDimensionCrossSectionOptions(Number(value));

    runInAction((): void => {
      this.trussCrossDimensionOptions = this.domain.dimensionCrossSectionOptions;
      this.yearOfConstruction = Number(value);
    });
  }

  async loadOptions(): Promise<void> {
    await this.domain.getRoofConstructionTypes();

    if (this.value) {
      const target = this.value as Framing;
      await this.domain.getDimensionCrossSectionOptions(Number(target.yearOfConstruction));
    }

    await runInAction(async (): Promise<void> => {
      this.constructionTypeOptions = this.domain.roofConstructionTypesOptions;
      if (this.value) {
        const target = this.value as Framing;

        this.trussCrossDimensionOptions = this.domain.dimensionCrossSectionOptions;
        this.setTrussCrossDimensions(target.crossSectionDimension.attributes);
        this.setConstructionType(target.constructionType.attributes.value);
        this.setTrussSpacing(target.trussSpacing.attributes.value);
        this.setOffsetDistance(target.offsetDistanceFromLeftEdge);
        await this.setYearOfConstruction(`${target.yearOfConstruction}`);
      } else {
        this.setConstructionType('MANUFACTURED_TRUSS');
        this.setTrussSpacing('24');
      }
    });
  }

  override saveProperty(): void {
    const trussSpacing = Number(this.trussSpacing!.attributes.value);
    if (isNaN(trussSpacing) || trussSpacing < 1) {
      return;
    }

    const value: Framing = {
      constructionType: this.constructionType!,
      yearOfConstruction: this.yearOfConstruction,
      crossSectionDimension: this.trussCrossDimensions!,
      trussSpacing: this.trussSpacing!,
      offsetDistanceFromLeftEdge: this.offsetDistance as number
    };

    const {
      domain, roofFaceProps, property
    } = this;
    const dependencies: IUpdateRoofFacePropertyDependencies = {
      domain,
      roofFace: roofFaceProps.currentSelectedRoofFaces[0],
      key: UpdateRoofFacePropertyKey.Framing,
      value
    };
    this.serviceBus.send('update_roof_face_property', dependencies);
    roofFaceProps.reset();
    this.roofFaceProps.lastInteractedRoofFace = this.roofFace;
  }

  protected override calculateCurrentValue(): RoofFace[keyof RoofFace] {
    const structure = this.roofFace.structure;
    const trussSpacing: number = Math.round(
      convertMeterToInches(structure?.slopingStructuralMembers?.spacing || inchesInMeters['24'])
    );

    const existingTrussSpacingOption = trussSpacingOptions.find(
      (item: IOption<BaseAttributes>): boolean => Number(item.attributes.value) === trussSpacing
    );
    if (!existingTrussSpacingOption) {
      updateOtherTrussSpacinsOption(trussSpacing.toString());
    }

    const trussSpacingOption = existingTrussSpacingOption || getOtherTrussSpacingOption(trussSpacing.toString());

    const yearOfConstruction = getYearOfConstructionByCrossSection(
      structure?.slopingStructuralMembers?.crossSection || 'L2X4_POST1960'
    );

    const target: RoofFace['framing'] = {
      constructionType: roofTypeKeyToAttribute[structure?.constructionStyle || 'MANUFACTURED_TRUSS'],
      yearOfConstruction,
      crossSectionDimension:
        crossSectionKeyToAttribute[structure?.slopingStructuralMembers?.crossSection || 'L2X4_POST1960'],
      trussSpacing: trussSpacingOption,
      offsetDistanceFromLeftEdge: 0
    };

    if (target) {
      this.constructionType = target.constructionType;
      this.trussCrossDimensions = target.crossSectionDimension;
      this.trussSpacing = target.trussSpacing;
      this.setYearOfConstruction(target.yearOfConstruction!.toString());
    }
    return target;
  }
}
