import styled from 'styled-components';
import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { PropsTheme } from 'styled-components';
import type { Color } from '../../../../../../../domain/typings';

const { Icon } = LyraIcon;

export const DocumentsListContainer = styled.div`
  margin-left: 15px;
  flex: 1 0;

  @media (max-width: 1180px) {
    margin-left: 0;
  }
`;
export const SelectWrapper = styled.div`
  width: 35%;
  position: relative;

  > div {
    position: absolute;
    background: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  }
`;
export const DocumentIcon = styled(Icon).attrs<{ name?: string }>({
  name: 'icon-document'
})`
  height: 28px;
  width: 22px;
  margin-right: 12px;
`;

export const DeleteIcon = styled(Icon).attrs<{ name?: string }>({
  name: 'icon-delete'
})`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  top: 5px;
`;

export const DocumentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};

  &:first-of-type {
    border-top: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  }
`;
