import {
  observable, action
} from 'mobx';

export interface IAttachmentPlanData {
  readonly showAttachmentPoints: boolean;
  readonly showDeadLoadCalculations: boolean;
  readonly showRoofWindZones: boolean;
  readonly showRoofFaceEdgeLength: boolean;
}

export default class AttachmentPlan {
  @observable
  showAttachmentPoints: boolean;

  @observable
  showDeadLoadCalculations: boolean;

  @observable
  showRoofWindZones: boolean;

  @observable
  showRoofFaceEdgeLength: boolean;

  constructor(data: IAttachmentPlanData) {
    this.showAttachmentPoints = data.showAttachmentPoints;
    this.showDeadLoadCalculations = data.showDeadLoadCalculations;
    this.showRoofWindZones = data.showRoofWindZones;
    this.showRoofFaceEdgeLength = data.showRoofFaceEdgeLength;
  }

  @action.bound
  setShowAttachmentPoints(value: boolean): void {
    this.showAttachmentPoints = value;
  }

  @action.bound
  setShowDeadLoadCalculations(value: boolean): void {
    this.showDeadLoadCalculations = value;
  }

  @action.bound
  setShowRoofFaceEdgeLength(value: boolean): void {
    this.showRoofFaceEdgeLength = value;
  }

  toData(): IAttachmentPlanData {
    return {
      showAttachmentPoints: this.showAttachmentPoints,
      showDeadLoadCalculations: this.showDeadLoadCalculations,
      showRoofWindZones: this.showRoofWindZones,
      showRoofFaceEdgeLength: this.showRoofFaceEdgeLength
    };
  }
}
