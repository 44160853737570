import { observable } from 'mobx';
import type { IMeterMainData } from '../../../entities/SiteDesign/MeterMainInstance';
import { SceneObjectType } from '../../Constants';
import {
  DEFAULT_MAIN_BREAKER_RATING,
  getServiceEntranceAndSiteEquipmentProperties,
  ServiceEntranceEquipmentDevice,
  ServiceEntranceEquipmentType
} from '../SiteEquipmentTypesAndHelpers';
import type {
  ISiteEquipmentData, SiteEquipmentDataKeys
} from '../../../entities/SiteDesign/SiteEquipment';
import type { GenericMarkerDependencies } from './GenericSiteEquipment';
import { MeterBase } from './MeterBase';

export class MeterMain extends MeterBase {
  override type = SceneObjectType.MeterMain;

  mainBreakerRating: number = DEFAULT_MAIN_BREAKER_RATING;
  @observable
  mainBreakerAmpereInterruptingCapacity?: number;
  model?: string;
  manufacturer?: string;

  constructor(dependencies: GenericMarkerDependencies) {
    super(dependencies);

    const {
      label, name
    } = getServiceEntranceAndSiteEquipmentProperties(
      ServiceEntranceEquipmentDevice.UtilityMeter,
      ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter
    );
    this.label = label;
    this.name = name;
  }

  override toData(): ISiteEquipmentData[SiteEquipmentDataKeys.meterMain] {
    const meterBaseData = super.toData()!;
    return {
      ...meterBaseData,
      model: this.model,
      manufacturer: this.manufacturer,
      mainBreakerRating: this.mainBreakerRating,
      mainBreakerAmpereInterruptingCapacity: this.mainBreakerAmpereInterruptingCapacity
    };
  }

  enrichWithMeterMainData(data: IMeterMainData): void {
    this.enrichWithMeterBaseData(data);
    this.model = data.model;
    this.manufacturer = data.manufacturer;
    this.mainBreakerRating = data.mainBreakerRating ?? DEFAULT_MAIN_BREAKER_RATING;
    this.mainBreakerAmpereInterruptingCapacity = data?.mainBreakerAmpereInterruptingCapacity;
  }
}
