import type {
  Object3D, Vector3
} from 'three';
import type {
  Near, Raynear
} from '../../infrastructure/services/Raynear';
import type { PolygonDrawable } from '../mixins/PolygonDrawable';
import { getLyraModelByMesh } from '../sceneObjectsWithLyraModelsHelpers';
import { Drawable } from '../mixins/Drawable';
import type { SegmentStyle } from './Segment';
import { Segment } from './Segment';
import { Vertex } from './Vertex';

const MixedClass = Drawable(class SimpleClass {});

export class Boundary extends MixedClass {
  propertyId?: string;
  defaultThickness?: number;
  segmentStyle?: SegmentStyle;

  private verticesArray: Vertex[] = [];
  private segmentsArray: Segment[] = [];
  private verticesVisible: boolean = false;
  private edgeVisible: boolean = false;
  private isVertexDragable: boolean = false;

  constructor() {
    super();
    this.type = 'boundary';
    this.mesh.frustumCulled = false;
  }

  get segments(): Segment[] {
    return this.segmentsArray;
  }

  set segments(segmentsArray: Segment[]) {
    this.segmentsArray = segmentsArray;
  }

  get vertices(): Vertex[] {
    return this.verticesArray;
  }

  get vector3s(): Vector3[] {
    return this.verticesArray.map((v: Vertex): Vector3 => v.getVector3());
  }

  /**
   * Store if is closed
   */
  get closed(): boolean {
    if (this.vertices.length < 3) {
      return false;
    }
    const firstVertex = this.vertices[0].mesh.position;
    const finalVertex = this.vertices[this.vertices.length - 1].mesh.position;
    return firstVertex.distanceTo(finalVertex) === 0;
  }

  override raynear(raynear: Raynear, objects: Near[]): Near[] {
    if (!this.closed) {
      return objects;
    }

    const currentObject: Near = {
      distance: raynear.far,
      object: this.mesh,
      closestPosition: this.mesh.position
    };

    for (let index = 0; index < this.verticesArray.length - 1; index++) {
      const vPosition: Vector3 = this.verticesArray[index].mesh.position.clone().setZ(0);
      const distanceToRef = raynear.refPosition?.distanceTo(vPosition) ?? 0;

      if (distanceToRef <= currentObject.distance && distanceToRef >= raynear.near) {
        currentObject.distance = distanceToRef;
        currentObject.object = this.mesh;
        currentObject.closestPosition = vPosition.clone();
      }
    }

    if (currentObject.distance < raynear.far && currentObject.distance > raynear.near) {
      objects.push(currentObject);
    }

    return objects;
  }

  removeLast(): void {
    // Remove last children
    this.mesh.children.pop();
    // Remove last vertex of vertices
    this.verticesArray.pop();
    // Remove last segment if apply
    if (this.segments.length > 0) {
      // TODO: remove segment line off graphic object
      this.segmentsArray.pop();
    }

    this.mesh.remove(this.mesh.children[this.mesh.children.length - 1]);
  }

  removeAll(): void {
    // Remove last children
    this.mesh.children = [];
    // Remove last vertex of vertices
    this.verticesArray = [];
    // Remove last segment if apply
    if (this.segments.length > 0) {
      this.segmentsArray.length = 0;
    }

    this.mesh.remove(...this.mesh.children);
  }

  addVertex(point: Vector3, setValidAfterUserInteraction: boolean = false): void {
    // Creating vertex and adding to array of current vertices and to childrens
    const vertex = Vertex.fromXyzValuesObject(point);
    if (setValidAfterUserInteraction) {
      vertex.hasEverBeenValidAfterUserInteraction = true;
    }
    vertex.isDraggable = this.isVertexDragable;
    vertex.isSelectable = this.isVertexDragable;

    // Push local vertices array
    this.verticesArray.push(vertex);

    const { length } = this.vertices;
    const index = length - 1;
    // Adding userData
    vertex.mesh.userData.index = index;

    // Adding as children in order to render
    if (length > 3) {
      const firstVertex = this.vertices[0].mesh.position;
      const finalVertex = this.vertices[length - 1].mesh.position;
      if (firstVertex.distanceTo(finalVertex) !== 0) {
        // Adding as children in order to render
        this.mesh.add(this.vertices[index].mesh);
      } else {
        this.verticesArray[length - 1] = this.vertices[0];
      }
    } else {
      // Adding as children in order to render
      this.mesh.add(this.vertices[index].mesh);
    }

    // Adding segment if apply
    this.addSegment();
  }

  updateFirstOrLastVertexIfNeeded(vertex: Vertex): void {
    const indexOfThis = this.vertices.indexOf(vertex);
    if (indexOfThis === 0) {
      this.vertices[this.vertices.length - 1].mesh.position.set(vertex.x, vertex.y, vertex.z);
    } else if (indexOfThis === this.vertices.length - 1) {
      this.vertices[0].mesh.position.set(vertex.x, vertex.y, vertex.z);
    }
    if (this.mesh.parent) {
      (getLyraModelByMesh(this.mesh.parent) as PolygonDrawable).redraw();
    }
  }

  updateAdjacentSegmentsAt(vertex: Vertex): void {
    const changedSegments: Segment[] = this.segments.filter((segment: Segment): boolean =>
      segment.points.some((point: Vertex): boolean => point.mesh.position.equals(vertex.mesh.position))
    );

    changedSegments.forEach((segment: Segment): void => segment.updateLines());
  }

  setVertices(vertices: Vertex[]): void {
    this.clearChildren(this.mesh);
    this.verticesArray = vertices;
    const setShowSegmentLengths = this.segmentsArray.some((segment: Segment): boolean =>
      segment.getShowSegmentLength()
    );
    this.segmentsArray.forEach((segment: Segment): void => segment.dispose());
    this.segmentsArray = [];
    vertices.forEach((element: Vertex, index: number): void => {
      this.mesh.add(element.mesh);
      if (index !== vertices.length - 1) {
        const segment = new Segment({
          v1: this.vertices[index],
          v2: this.vertices[index + 1],
          thickness: this.defaultThickness,
          color: this.color,
          segmentStyle: this.segmentStyle,
          showSegmentLength: setShowSegmentLengths
        });
        this.segmentsArray.push(segment);
        this.mesh.add(segment.mesh);
      }
    });
  }

  setVerticesVisibility(visible: boolean): void {
    this.verticesVisible = visible;
  }

  setVertexDragable(isVertexDragable: boolean): void {
    this.isVertexDragable = isVertexDragable;
  }

  setEdgeVisibility(visible: boolean): void {
    this.edgeVisible = visible;
  }

  redraw(): void {
    // Show or hide objects
    this.mesh.visible = this.edgeVisible;

    this.mesh.children.forEach((childMesh: Object3D): void => {
      const child = getLyraModelByMesh(childMesh);
      if (child instanceof Vertex) {
        child.isDraggable = this.isVertexDragable;
        child.isSelectable = this.isVertexDragable;
        child.mesh.visible = this.verticesVisible;
      }

      if (child instanceof Segment) {
        child.color = this.color!;
        child.redraw();
      }
    });
  }

  getLargestSegment(): number {
    let size = 0;
    for (const segment of this.segmentsArray) {
      const length = segment.length;
      if (length > size) {
        size = length;
      }
    }
    return size;
  }

  private addSegment(): void {
    const { length } = this.vertices;
    if (length > 1) {
      const segment = new Segment({
        v1: this.vertices[length - 2],
        v2: this.vertices[length - 1],
        thickness: this.defaultThickness,
        color: this.color,
        segmentStyle: this.segmentStyle
      });
      this.segmentsArray.push(segment);
      this.mesh.add(segment.mesh);
    }
  }
}
