import isNil from 'lodash/isNil';
import {
  computed, observable
} from 'mobx';
import type { Vector3 } from 'three';
import { canvasConfig } from '../../../config/canvasConfig';
import { SceneObjectType } from '../Constants';
import { VerticesMapping } from '../../../infrastructure/mapping/VerticesMapping';

import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import { SegmentStyle } from '../../graphics/Segment';
import { ParcelBoundaryUpdatedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import { LengthLabelable } from '../../mixins/LengthLabelable';
import { Deletable } from '../../mixins/Deletable';
import { Draggable } from '../../mixins/Draggable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { Drawable } from '../../mixins/Drawable';
import config from '../../../config/config';
import {
  isParcelPositionValid,
  moveParcelToANewPosition,
  revertMoveParcelToANewPosition
} from './functions/DraggableParcelHelpers';
import type { IPolygon2D } from 'domain/entities/Polygon/IPolygon2D';

export interface IParcelData {
  assessorsParcelNumber?: string;
  zoning?: string;
  boundary?: IPolygon2D;
}

const MixedClass = LengthLabelable(Deletable(Draggable(PolygonDrawable(Selectable(Drawable(class SimpleClass {}))))));

export class Parcel extends MixedClass {
  override name: string = 'Parcel Boundary';

  selectWithParent: boolean = false;
  isMultipleVertices: boolean = true;
  layerNumber: number = 1;
  verticesUpdateIsInProgress: boolean = false;
  private movementInProgress: boolean = false;
  override color = canvasConfig.parcelBoundaryColor;

  /**
   * Property id used to select right panel component
   */
  readonly propertyId: string = SceneObjectType.ParcelBoundary;

  @observable
  assessorsParcelNumber?: string;
  @observable
  zoning?: string;

  constructor(data?: IParcelData) {
    super();
    this.assessorsParcelNumber = data?.assessorsParcelNumber;
    this.zoning = data?.zoning;

    this.type = SceneObjectType.ParcelBoundary;
    this.boundary.segmentStyle = SegmentStyle.DOUBLE_DASHED;
    this.boundary.color = canvasConfig.parcelBoundaryColor;
  }

  get hasBoundary(): boolean {
    return this.getVector3s().length > 0;
  }

  override move(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (this.movementInProgress) {
      return;
    }

    this.movementInProgress = true;
    try {
      const parcel = this;
      if (parcel.verticesUpdateIsInProgress) {
        // Failsafe. Not an expected behaviour.
        return;
      }

      const deltaVector = this.boundary.vertices[0].getVector3().clone()
        .sub(newPosition[0]);
      moveParcelToANewPosition(deltaVector, parcel);
      if (!isParcelPositionValid(editor, parcel)) {
        revertMoveParcelToANewPosition(deltaVector, parcel);
      }
    } finally {
      this.movementInProgress = false;
    }
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    super.onDragFinish(editor, smartGuides);
    config.analytics?.trackEvent(new ParcelBoundaryUpdatedEvent(editor.domain));
  }

  /**
   * @returns is valid
   */
  override afterMove(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }

  override resetVertex(vector: Vector3, index: number): void {
    // do nothing
  }

  toData = (): IParcelData | undefined => {
    if (this.isEmpty && !this.hasBoundary) {
      return undefined;
    }
    const data: IParcelData = {
      assessorsParcelNumber: this.assessorsParcelNumber,
      zoning: this.zoning
    };

    if (this.hasBoundary) {
      data.boundary = new VerticesMapping().convertDomainModelToEntity2d(this.boundary.vertices);
    }

    return data;
  };

  @computed
  get isEmpty(): boolean {
    return isNil(this.assessorsParcelNumber) && isNil(this.zoning);
  }

  showVertices(): boolean {
    return !this.boundary.closed || this.selected;
  }

  showLines(): boolean {
    return true;
  }

  hasFill(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return true;
  }

  showFill(): boolean {
    return false;
  }

  onClose(): void {
    // do nothing
  }
}
