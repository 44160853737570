import { action } from 'mobx';
import { WARNING } from '../../../../domain/models/Constants';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import { notify } from '../../../../utils/helpers';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { RoofFacePropertiesStore } from '../RoofProperties/RoofFacePropertiesStore';
import type { ModalStore } from '../../Modal/Modal';
import { NewOrEditBuildingViewModel } from '../../Wizard/ViewModels/NewRoofFace/NewOrEditBuildingViewModel';
import type { WizardStore } from '../../Wizard/Wizard';
import type { IUpdateRoofFacePropertyDependencies } from '../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import { UpdateRoofFacePropertyKey } from '../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import type { DeleteObjectDependencies } from '../../../ServiceBus/Commands/DeleteObjectCommand';
import type { Parcel } from '../../../../domain/models/SiteDesign/Parcel';
import type { Building } from '../../../../domain/models/SiteDesign/Building';
import type { Outline } from '../../../../domain/models/SiteDesign/Outline';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type { RoofProtrusion } from '../../../../domain/models/SiteDesign/RoofProtrusion';
import type { IDeleteBuildingDependencies } from '../../../ServiceBus/Commands/DeleteBuildingCommand';
import type { IUpdateBuildingPropertyDependencies } from '../../../ServiceBus/Commands/UpdateBuildingPropertyCommand';
import type { IUpdateBuildingVisibilityDependencies } from '../../../ServiceBus/Commands/UpdateBuildingVisibilityCommand';
import type { IUpdateRoofProtrusionPropertyDependencies } from '../../../ServiceBus/Commands/UpdateRoofProtrusionPropertyCommand';

interface IBuildingPropertiesViewModelDependencies {
  readonly domain: DomainStore;
  readonly editor: EditorStore;
  readonly serviceBus: ServiceBus;
  readonly propertyStore: RoofFacePropertiesStore;
  readonly modal: ModalStore;
  readonly wizard: WizardStore;
}

export class BuildingPropertiesViewModel {
  private readonly domain: DomainStore;
  private readonly editor: EditorStore;
  private readonly serviceBus: ServiceBus;
  private readonly propertyStore: RoofFacePropertiesStore;
  private readonly modal: ModalStore;
  private readonly wizard: WizardStore;
  private selectionControl?: SelectionControl;

  constructor(dependencies: IBuildingPropertiesViewModelDependencies) {
    this.domain = dependencies.domain;
    this.editor = dependencies.editor;
    this.serviceBus = dependencies.serviceBus;
    this.propertyStore = dependencies.propertyStore;
    this.modal = dependencies.modal;
    this.wizard = dependencies.wizard;
  }

  @action.bound
  setBuildingVisibility(building: Building, visible: boolean): void {
    const dependencies: IUpdateBuildingVisibilityDependencies = {
      domain: this.domain,
      building,
      visible
    };
    this.serviceBus.send('update_building_visibility', dependencies);
  }

  @action.bound
  updateBuildingName(building: Building, name: string): void {
    const existingBuilding = this.domain.buildingWithName(name);
    if (existingBuilding && existingBuilding.id !== building.id) {
      notify('A building with that name already exists. Choose a different name', WARNING);
      return;
    }
    const dependencies: IUpdateBuildingPropertyDependencies<'name'> = {
      domain: this.domain,
      building,
      key: 'name',
      value: name
    };
    this.serviceBus.send('update_building_property', dependencies);
  }

  @action.bound
  deleteBuilding(building: Building): void {
    const dependencies: IDeleteBuildingDependencies = {
      editor: this.editor,
      domain: this.domain,
      object: building
    };
    this.serviceBus.send('delete_building', dependencies);
  }

  @action.bound
  openEditBuildingModal(building: Building): void {
    this.wizard.createOneStepWizard(NewOrEditBuildingViewModel, {
      wizard: this.wizard,
      domain: this.domain,
      serviceBus: this.serviceBus,
      editBuilding: building
    });
  }

  @action.bound
  deleteOutline(outline: Outline): void {
    const dependencies: IDeleteBuildingDependencies = {
      editor: this.editor,
      domain: this.domain,
      object: outline
    };
    this.serviceBus.send('delete_outline', dependencies);
  }

  @action.bound
  updateRoofFaceName(roofFace: RoofFace, newName: string, building: Building): void {
    newName = newName.trim();
    if (roofFace.name === newName) {
      // Roof face name is up to date
      return;
    }
    const existingRoofFaceWithNewName = this.domain.buildingWithName(building.name)?.roofFaceWithName(newName);
    if (existingRoofFaceWithNewName) {
      notify(`A Roof face named ${newName} already exists. Choose a different name`, WARNING);
      return;
    }
    const dependencies: IUpdateRoofFacePropertyDependencies = {
      domain: this.domain,
      roofFace,
      key: UpdateRoofFacePropertyKey.Name,
      value: newName
    };
    this.serviceBus.send('update_roof_face_property', dependencies);
  }

  openRoofFaceProperties(roofFace: RoofFace): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    this.selectionControl.setSelectedObjects([roofFace]);
    this.propertyStore.setSelectedRoofFaces([roofFace]);
  }

  @action.bound
  deleteRoofFace(roofFace: RoofFace): void {
    const dependencies: IDeleteBuildingDependencies = {
      editor: this.editor,
      domain: this.domain,
      object: roofFace
    };
    this.serviceBus.send('delete_roof_face', dependencies);
  }

  @action.bound
  updateProtrusionName(roofFace: RoofFace, protrusion: RoofProtrusion, name: string): void {
    const dependencies: IUpdateRoofProtrusionPropertyDependencies<'name'> = {
      domain: this.domain,
      roofFace,
      protrusion,
      key: 'name',
      value: name
    };
    this.serviceBus.send('update_roof_protrusion_property', dependencies);
  }

  @action.bound
  deleteProtrusion(protrusion: RoofProtrusion): void {
    const dependencies: IDeleteBuildingDependencies = {
      editor: this.editor,
      domain: this.domain,
      object: protrusion
    };
    this.serviceBus.send('delete_rectangular_protrusion', dependencies);
  }

  @action.bound
  deleteParcelBoundary(parcel: Parcel): void {
    const dependencies: DeleteObjectDependencies = {
      editor: this.editor,
      domain: this.domain,
      object: parcel
    };
    this.serviceBus.send('delete_parcel_boundary', dependencies);
  }
}
