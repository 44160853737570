import type {
  Intersection, Raycaster, Object3D
} from 'three';
import {
  Vector3, MathUtils as ThreeMath, Color, MeshBasicMaterial, DoubleSide, Mesh, Box3
} from 'three';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { LyraTheme } from '@aurorasolar/lyra-ui-kit';
import { computed } from 'mobx';
import { Units } from '../../../domain/typings';
import type { SVGObject } from '../../../infrastructure/services/drawingArea/SVGLoader';
import ProjectionUtil from '../../../utils/projectionUtil';
import { Draggable } from '../../mixins/Draggable';
import { Deletable } from '../../mixins/Deletable';
import { Drawable } from '../../mixins/Drawable';
import { Selectable } from '../../mixins/Selectable';
import { Unzoomable } from '../../mixins/Unzoomable';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import marker from '../../../canvas-assets/site-equipment-marker.svg';
import fontJson from '../../../canvas-assets/fonts/helvetiker_regular.typeface.json';
import { TEXT_HEIGHT } from '../../../stores/EditorStore/constants';
import loadSVG from '../../../infrastructure/services/drawingArea/SVGLoader';
import { ViewPort } from '../../../stores/EditorStore/ViewportController';
import { KindGuides } from '../../../stores/UiStore/SmartGuidesStore/IApplyParams';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { convertFeetToMeters } from '../../../utils/Convertions';
import type { SceneObjectType } from '../Constants';

const DEFAULT_MARKER_LABEl_SIZE = 12;
const MAX_MARKER_LABEL_SIZE = 10;
const MAX_LENGTH_FOR_DEFAULT_MARKER_LABEL = 4;
const COLOR_SELECTED = 0x000000;
const MixedClass = Deletable(Draggable(Selectable(Unzoomable(Drawable(class SimpleClass {})))));

export abstract class Marker extends MixedClass {
  /**
   * Default equipment height of 5 feet converted to world units. Equipment that uses this value:
   * - service entrance equipment
   * - sub panel
   * - gas meter
   * - electrical equipment locations
   * The value is defined as a function to prevent underlying methods from accessing non-existent coordinates
   * when the value is initialized (before the world loads)
   */
  static defaultEquipmentZValueInWorldUnits = (): number =>
    ProjectionUtil.convertToWorldUnits(convertFeetToMeters(5), Units.Meters);

  private editor: EditorStore;
  realWorldZValue: number = 0;
  /* Vertical position of the three.js object */
  renderZValue = 200;

  abstract label: string;
  private svgMesh?: SVGObject;

  override readonly isMultipleVertices: boolean = false;
  override readonly selectWithParent: boolean = false;

  // Instance unique index
  definitionId: string;

  // Used to add visual paddings between multiple markers when multiple markers
  // have to be dragged simultaneously after adding new service entrance equipment.
  positionShift: number = 0;

  constructor() {
    super();
    this.serverId = ThreeMath.generateUUID();
    this.definitionId = ThreeMath.generateUUID();
    this.type = 'Marker';
    this.editor = getRootStore().editor;
  }

  private async create(position?: Vector3): Promise<void> {
    this.mesh.children.forEach((child: Object3D): void => {
      this.mesh.remove(child);
    });
    this.mesh.children = [];

    const markerIconContent = marker;

    const markerIconContentAsBlob = new Blob([markerIconContent], {
      type: 'image/svg+xml'
    });

    this.svgMesh = await loadSVG(URL.createObjectURL(markerIconContentAsBlob), undefined, true);

    // children should be added backwards
    for (let i = this.svgMesh.path.children.length - 1; i >= 0; i--) {
      const child = this.svgMesh.path.children[i];
      child.position.setZ((0.1 * i) / this.svgMesh.path.children.length);
      this.mesh.add(child);
    }

    if (position) {
      this.mesh.position.copy(position);
    } else {
      this.mesh.position.setZ(this.renderZValue);
    }

    this.mesh.scale.set(this.mapZoomFactor, this.mapZoomFactor, 1);
    this.renderOrder = -1;
    this.unzoom(ViewPort.scaleFactor);
    this.editor.cameraControls?.update(true);
    this.unselect();
  }

  @computed
  get positionWithRealWorldZValue(): Vector3 {
    return new Vector3(this.mesh.position.x, this.mesh.position.y, this.realWorldZValue);
  }

  get hasChildren(): boolean {
    return this.mesh.children.length > 0;
  }

  override raycast = (raycaster: Raycaster, intersects: Intersection[]): void => {
    const childIntersects: Intersection[] = [];
    this.mesh.children.forEach((child: Object3D): void => child.raycast(raycaster, childIntersects));

    if (childIntersects.length > 0) {
      const firstChild = childIntersects[0];
      intersects.push({
        distance: firstChild.distance,
        face: firstChild.face,
        faceIndex: firstChild.faceIndex,
        point: firstChild.point.clone(),
        object: this.mesh,
        uv: firstChild.uv
      });
    }
  };

  override unzoom(factor: number): void {
    this.mesh.scale.set(factor * this.mapZoomFactor, factor * this.mapZoomFactor, factor * this.mapZoomFactor);
  }

  /**
   * Change the fill color of the Meshes. If there are exceptions,
   * it does not take them into account
   */
  changeFillColor(color: Color, exceptions: number[] = [0]): void {
    const meshes = this.mesh.children as Mesh[];
    meshes.forEach((mesh: Mesh, index: number): void => {
      if (mesh.name === 'mesh-fill' && !exceptions.includes(index)) {
        const material = mesh.material as MeshBasicMaterial;
        material.color = color;
        mesh.material = material;
      }
      this.mesh.children[index] = mesh;
    });
  }

  override select(): void {
    super.select();
    this.drawStroke();
  }

  override unselect(): void {
    super.unselect();
    this.drawStroke();
  }

  redraw(): void {
    /*
     * In one of the parent classes, this is an abstract class, so it must be implemented
     * TODO: Review why the compiler doesn't catch if this function is not implemented.
     */
  }

  getVector3s(): Vector3[] {
    return [new Vector3(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z)];
  }

  override move(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    const { objectVertex } = smartGuides.applyGuides({
      // By default, markers are created with Z = 0
      // To lift them above the steepest roofs, an offset must be applied
      // This offset is created every time onDragStart is called, and it is called
      // when BaseSiteEquipmentTool creates a marker
      // The check is done to avoid marker's Z value skyrocketing beyond camera's FOV every frame it is dragged
      mousePos: newPositions[0].add(newPositions[0].z ? new Vector3() : this.offset!),
      kind: KindGuides.MOVE_OBJECT,
      wipObject: this,
      vertexObject: newPositions
    });

    if (objectVertex) {
      this.mesh.position.set(
        objectVertex.x + this.positionShift * 30,
        objectVertex.y + this.positionShift,
        this.editor.getObjectRenderHeight(this.type as SceneObjectType)
      );
    }
  }

  override afterMove(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }

  resetVertex(vector: Vector3, index: number): void {
    this.mesh.position.set(vector.x, vector.y, vector.z);
  }

  async addLabel(text: string): Promise<void> {
    const font = new Font(fontJson);
    const size = text.length > MAX_LENGTH_FOR_DEFAULT_MARKER_LABEL ? MAX_MARKER_LABEL_SIZE : DEFAULT_MARKER_LABEl_SIZE;
    const textGeometry = new TextGeometry(text, {
      font,
      size,
      height: TEXT_HEIGHT
    });
    const materialText = new MeshBasicMaterial({
      color: new Color(LyraTheme.defaultTheme.colors.info),
      side: DoubleSide
    });

    const meshText = new Mesh(textGeometry, materialText);
    meshText.geometry.scale(-1, -1, 1);
    meshText.name = 'mesh-label';

    const boxLabel = new Box3().setFromObject(meshText);
    const widthLbl = boxLabel.min.x - boxLabel.max.x;

    const heightMarker = this.svgMesh!.boundingBox.min.y - this.svgMesh!.boundingBox.max.y;

    this.mesh.add(meshText);

    // Centering label
    meshText.position.setX(-Math.abs(widthLbl / 2));
    meshText.position.setY(Math.abs(heightMarker) / 2 + MAX_MARKER_LABEL_SIZE / 2);
    meshText.rotateZ(Math.PI);
  }

  /**
   *  Draw marker in a specified position
   *  @param position The position where the marker will be rendered
   */
  draw(position?: Vector3): void {
    this.create(position).then((): void => {
      this.changeFillColor(new Color(this.color));
      this.addLabel(this.label);
    });
  }

  private drawStroke(): void {
    const meshes = this.mesh.children as Mesh[];
    meshes.forEach((mesh: Mesh, index: number): void => {
      if (mesh.name === 'mesh-stroke') {
        (mesh.material as MeshBasicMaterial).color = new Color(
          this.selected ? LyraTheme.defaultTheme.colors.info : COLOR_SELECTED
        );
      }
      this.mesh.children[index] = mesh;
    });
  }
}
