import React, { BaseProps, ReactElement } from 'react';
import { Button } from '../Button';
import { Flexbox } from '../Layout';
import { Divider, FooterStyle as Container } from './styles';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

export type FooterProps = BaseProps & {
  color?: string | EThemeComponentColor;
  showFooter?: boolean;
  showFooterDivider?: boolean;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  onClickButton?: () => void;
};

const Footer = (props: FooterProps): ReactElement => {
  const {
    color = EThemeComponentColor.BLUE,
    showFooter = true,
    showFooterDivider = false,
    buttonLabel = 'skip & complete later',
    buttonDisabled = false,
    onClickButton
  } = props;

  return showFooter ? (
    <Container>
      {showFooterDivider && (
        <Divider direction="horizontal" length="100%" color="gray" />
      )}
      <Flexbox dir="row" justify="center" align="center">
        <Button
          className="ml-xxs"
          label={buttonLabel.toUpperCase()}
          type={color === EThemeComponentColor.BLUE ? 'main' : 'secondary'}
          disabled={buttonDisabled}
          data-testid="warning-footer-button"
          onClick={onClickButton}
        />
      </Flexbox>
    </Container>
  ) : (
    <></>
  );
};

export { Footer };
