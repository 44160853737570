import { css } from 'styled-components';
import { Mixin } from '../../typings';

/**
 * CSS utility for margin and padding
 *
 * Just add these classes to whatever component to apply them.
 * We don't need to specify the spacing from a component
 * in different cases.
 * In this way, we can avoid to apply custom spacing.
 * Just apply the CSS class
 *
 */

const NULL = '0';
const XXXS = '4px';
const XXS = '8px';
const XS = '16px';
const S = '24px';
const M = '36px';
const L = '48px';
const XL = '60px';
const XXL = '96px';
const XXXL = '144px';

const spaces: Mixin = css`
  /* margin-top */
  .mt-a { margin-top: auto  ; }

  /* margin-right */
  .mr-a { margin-right: auto  ; }

  /* margin-bottom */
  .mb-a { margin-bottom: auto  ; }

  /* margin-left */
  .ml-a { margin-left: auto  ; }

  /* horizontal margins */
  .mh-a { margin-left: auto  ; margin-right: auto  ; }

  /* vertical margins */
  .mv-a { margin-top: auto  ; margin-bottom: auto  ; }

  /* all margins */
  .m-a { margin: auto  ; }

  /* margin-top */
  .mt-0 { margin-top:   ${NULL}  ; }

  /* margin-right */
  .mr-0 { margin-right:   ${NULL}  ; }

  /* margin-bottom */
  .mb-0 { margin-bottom:   ${NULL}  ; }

  /* margin-left */
  .ml-0 { margin-left:   ${NULL}  ; }

  /* horizontal margins */
  .mh-0 { margin-left:   ${NULL}  ; margin-right:   ${NULL}  ; }

  /* vertical margins */
  .mv-0 { margin-top:   ${NULL}  ; margin-bottom:   ${NULL}  ; }

  /* all margins */
  .m-0 { margin:   ${NULL}  ; }

  /* margin-top */
  .mt-xxxs { margin-top:   ${XXXS}  ; }

  /* margin-right */
  .mr-xxxs { margin-right:   ${XXXS}  ; }

  /* margin-bottom */
  .mb-xxxs { margin-bottom:   ${XXXS}  ; }

  /* margin-left */
  .ml-xxxs { margin-left:   ${XXXS}  ; }

  /* horizontal margins */
  .mh-xxxs { margin-left:   ${XXXS}  ; margin-right:   ${XXXS}  ; }

  /* vertical margins */
  .mv-xxxs { margin-top:   ${XXXS}  ; margin-bottom:   ${XXXS}  ; }

  /* all margins */
  .m-xxxs { margin:   ${XXXS}  ; }

  /* margin-top */
  .mt-xxs { margin-top: ${XXS}  ; }

  /* margin-right */
  .mr-xxs { margin-right: ${XXS}  ; }

  /* margin-bottom */
  .mb-xxs { margin-bottom: ${XXS}  ; }

  /* margin-left */
  .ml-xxs { margin-left: ${XXS}  ; }

  /* horizontal margins */
  .mh-xxs { margin-left: ${XXS}  ; margin-right: ${XXS}  ; }

  /* vertical margins */
  .mv-xxs { margin-top: ${XXS}  ; margin-bottom: ${XXS}  ; }

  /* all margins */
  .m-xxs { margin: ${XXS}  ; }

  /* margin-top */
  .mt-xs { margin-top:  ${XS}  ; }

  /* margin-right */
  .mr-xs { margin-right:  ${XS}  ; }

  /* margin-bottom */
  .mb-xs { margin-bottom:  ${XS}  ; }

  /* margin-left */
  .ml-xs { margin-left:  ${XS}  ; }

  /* horizontal margins */
  .mh-xs { margin-left:  ${XS}  ; margin-right:  ${XS}  ; }

  /* vertical margins */
  .mv-xs { margin-top:  ${XS}  ; margin-bottom:  ${XS}  ; }

  /* all margins */
  .m-xs { margin:  ${XS}  ; }

  /* margin-top */
  .mt-s { margin-top:  ${S}  ; }

  /* margin-right */
  .mr-s { margin-right:  ${S}  ; }

  /* margin-bottom */
  .mb-s { margin-bottom:  ${S}  ; }

  /* margin-left */
  .ml-s { margin-left:  ${S}  ; }

  /* horizontal margins */
  .mh-s { margin-left:  ${S}  ; margin-right:  ${S}  ; }

  /* vertical margins */
  .mv-s { margin-top:  ${S}  ; margin-bottom:  ${S}  ; }

  /* all margins */
  .m-s { margin:  ${S}  ; }

  /* margin-top */
  .mt-m { margin-top:  ${M}  ; }

  /* margin-right */
  .mr-m { margin-right:  ${M}  ; }

  /* margin-bottom */
  .mb-m { margin-bottom:  ${M}  ; }

  /* margin-left */
  .ml-m { margin-left:  ${M}  ; }

  /* horizontal margins */
  .mh-m { margin-left:  ${M}  ; margin-right:  ${M}  ; }

  /* vertical margins */
  .mv-m { margin-top:  ${M}  ; margin-bottom:  ${M}  ; }

  /* all margins */
  .m-m { margin:  ${M}  ; }

  /* margin-top */
  .mt-l { margin-top:  ${L}  ; }

  /* margin-right */
  .mr-l { margin-right:  ${L}  ; }

  /* margin-bottom */
  .mb-l { margin-bottom:  ${L}  ; }

  /* margin-left */
  .ml-l { margin-left:  ${L}  ; }

  /* horizontal margins */
  .mh-l { margin-left:  ${L}  ; margin-right:  ${L}  ; }

  /* vertical margins */
  .mv-l { margin-top:  ${L}  ; margin-bottom:  ${L}  ; }

  /* all margins */
  .m-l { margin:  ${L}  ; }

  /* margin-top */
  .mt-xl { margin-top:  ${XL}  ; }

  /* margin-right */
  .mr-xl { margin-right:  ${XL}  ; }

  /* margin-bottom */
  .mb-xl { margin-bottom:  ${XL}  ; }

  /* margin-left */
  .ml-xl { margin-left:  ${XL}  ; }

  /* horizontal margins */
  .mh-xl { margin-left:  ${XL}  ; margin-right:  ${XL}  ; }

  /* vertical margins */
  .mv-xl { margin-top:  ${XL}  ; margin-bottom:  ${XL}  ; }

  /* all margins */
  .m-xl { margin:  ${XL}  ; }

  /* margin-top */
  .mt-xxl { margin-top:  ${XXL}  ; }

  /* margin-right */
  .mr-xxl { margin-right:  ${XXL}  ; }

  /* margin-bottom */
  .mb-xxl { margin-bottom:  ${XXL}  ; }

  /* margin-left */
  .ml-xxl { margin-left:  ${XXL}  ; }

  /* horizontal margins */
  .mh-xxl { margin-left:  ${XXL}  ; margin-right:  ${XXL}  ; }

  /* vertical margins */
  .mv-xxl { margin-top:  ${XXL}  ; margin-bottom:  ${XXL}  ; }

  /* all margins */
  .m-xxl { margin:  ${XXL}  ; }

  /* margin-top */
  .mt-xxxl { margin-top:  ${XXXL}  ; }

  /* margin-right */
  .mr-xxxl { margin-right:  ${XXXL}  ; }

  /* margin-bottom */
  .mb-xxxl { margin-bottom:  ${XXXL}  ; }

  /* margin-left */
  .ml-xxxl { margin-left:  ${XXXL}  ; }

  /* horizontal margins */
  .mh-xxxl { margin-left:  ${XXXL}  ; margin-right:  ${XXXL}  ; }

  /* vertical margins */
  .mv-xxxl { margin-top:  ${XXXL}  ; margin-bottom:  ${XXXL}  ; }

  /* all margins */
  .m-xxxl { margin:  ${XXXL}  ; }

  /* NEGATIVE MARGINS */
  /* margin-top */
  .mt--xxxs { margin-top: -4px  ; }

  /* margin-right */
  .mr--xxxs { margin-right: -4px  ; }

  /* margin-bottom */
  .mb--xxxs { margin-bottom: -4px  ; }

  /* margin-left */
  .ml--xxxs { margin-left: -4px  ; }

  /* horizontal margins */
  .mh--xxxs { margin-left: -4px  ; margin-right: -4px  ; }

  /* vertical margins */
  .mv--xxxs { margin-top: -4px  ; margin-bottom: -4px  ; }

  /* all margins */
  .m--xxxs { margin: -4px  ; }

  /* margin-top */
  .mt--xxs { margin-top: -8px  ; }

  /* margin-right */
  .mr--xxs { margin-right: -8px  ; }

  /* margin-bottom */
  .mb--xxs { margin-bottom: -8px  ; }

  /* margin-left */
  .ml--xxs { margin-left: -8px  ; }

  /* horizontal margins */
  .mh--xxs { margin-left: -8px  ; margin-right: -8px  ; }

  /* vertical margins */
  .mv--xxs { margin-top: -8px  ; margin-bottom: -8px  ; }

  /* all margins */
  .m--xxs { margin: -8px  ; }

  /* margin-top */
  .mt--xs { margin-top: -16px  ; }

  /* margin-right */
  .mr--xs { margin-right: -16px  ; }

  /* margin-bottom */
  .mb--xs { margin-bottom: -16px  ; }

  /* margin-left */
  .ml--xs { margin-left: -16px  ; }

  /* horizontal margins */
  .mh--xs { margin-left: -16px  ; margin-right: -16px  ; }

  /* vertical margins */
  .mv--xs { margin-top: -16px  ; margin-bottom: -16px  ; }

  /* all margins */
  .m--xs { margin: -16px  ; }

  /* margin-top */
  .mt--s { margin-top: -24px  ; }

  /* margin-right */
  .mr--s { margin-right: -24px  ; }

  /* margin-bottom */
  .mb--s { margin-bottom: -24px  ; }

  /* margin-left */
  .ml--s { margin-left: -24px  ; }

  /* horizontal margins */
  .mh--s { margin-left: -24px  ; margin-right: -24px  ; }

  /* vertical margins */
  .mv--s { margin-top: -24px  ; margin-bottom: -24px  ; }

  /* all margins */
  .m--s { margin: -24px  ; }

  /* margin-top */
  .mt--m { margin-top: -36px  ; }

  /* margin-right */
  .mr--m { margin-right: -36px  ; }

  /* margin-bottom */
  .mb--m { margin-bottom: -36px  ; }

  /* margin-left */
  .ml--m { margin-left: -36px  ; }

  /* horizontal margins */
  .mh--m { margin-left: -36px  ; margin-right: -36px  ; }

  /* vertical margins */
  .mv--m { margin-top: -36px  ; margin-bottom: -36px  ; }

  /* all margins */
  .m--m { margin: -36px  ; }

  /* margin-top */
  .mt--l { margin-top: -48px  ; }

  /* margin-right */
  .mr--l { margin-right: -48px  ; }

  /* margin-bottom */
  .mb--l { margin-bottom: -48px  ; }

  /* margin-left */
  .ml--l { margin-left: -48px  ; }

  /* horizontal margins */
  .mh--l { margin-left: -48px  ; margin-right: -48px  ; }

  /* vertical margins */
  .mv--l { margin-top: -48px  ; margin-bottom: -48px  ; }

  /* all margins */
  .m--l { margin: -48px  ; }

  /* margin-top */
  .mt--xl { margin-top: -60px  ; }

  /* margin-right */
  .mr--xl { margin-right: -60px  ; }

  /* margin-bottom */
  .mb--xl { margin-bottom: -60px  ; }

  /* margin-left */
  .ml--xl { margin-left: -60px  ; }

  /* horizontal margins */
  .mh--xl { margin-left: -60px  ; margin-right: -60px  ; }

  /* vertical margins */
  .mv--xl { margin-top: -60px  ; margin-bottom: -60px  ; }

  /* all margins */
  .m--xl { margin: -60px  ; }

  /* margin-top */
  .mt--xxl { margin-top: -96px  ; }

  /* margin-right */
  .mr--xxl { margin-right: -96px  ; }

  /* margin-bottom */
  .mb--xxl { margin-bottom: -96px  ; }

  /* margin-left */
  .ml--xxl { margin-left: -96px  ; }

  /* horizontal margins */
  .mh--xxl { margin-left: -96px  ; margin-right: -96px  ; }

  /* vertical margins */
  .mv--xxl { margin-top: -96px  ; margin-bottom: -96px  ; }

  /* all margins */
  .m--xxl { margin: -96px  ; }

  /* margin-top */
  .mt--xxxl { margin-top: -144px  ; }

  /* margin-right */
  .mr--xxxl { margin-right: -144px  ; }

  /* margin-bottom */
  .mb--xxxl { margin-bottom: -144px  ; }

  /* margin-left */
  .ml--xxxl { margin-left: -144px  ; }

  /* horizontal margins */
  .mh--xxxl { margin-left: -144px  ; margin-right: -144px  ; }

  /* vertical margins */
  .mv--xxxl { margin-top: -144px  ; margin-bottom: -144px  ; }

  /* all margins */
  .m--xxxl { margin: -144px  ; }

  /* padding-top */
  .pt-a { padding-top: auto  ; }

  /* padding-right */
  .pr-a { padding-right: auto  ; }

  /* padding-bottom */
  .pb-a { padding-bottom: auto  ; }

  /* padding-left */
  .pl-a { padding-left: auto  ; }

  /* horizontal paddings */
  .ph-a { padding-left: auto  ; padding-right: auto  ; }

  /* vertical paddings */
  .pv-a { padding-top: auto  ; padding-bottom: auto  ; }

  /* all paddings */
  .p-a { padding: auto  ; }

  /* padding-top */
  .pt-0 { padding-top:   ${NULL}  ; }

  /* padding-right */
  .pr-0 { padding-right:   ${NULL}  ; }

  /* padding-bottom */
  .pb-0 { padding-bottom:   ${NULL}  ; }

  /* padding-left */
  .pl-0 { padding-left:   ${NULL}  ; }

  /* horizontal paddings */
  .ph-0 { padding-left:   ${NULL}  ; padding-right:   ${NULL}  ; }

  /* vertical paddings */
  .pv-0 { padding-top:   ${NULL}  ; padding-bottom:   ${NULL}  ; }

  /* all paddings */
  .p-0 { padding:   ${NULL}  ; }

  /* padding-top */
  .pt-xxxs { padding-top:   ${XXXS}  ; }

  /* padding-right */
  .pr-xxxs { padding-right:   ${XXXS}  ; }

  /* padding-bottom */
  .pb-xxxs { padding-bottom:   ${XXXS}  ; }

  /* padding-left */
  .pl-xxxs { padding-left:   ${XXXS}  ; }

  /* horizontal paddings */
  .ph-xxxs { padding-left:   ${XXXS}  ; padding-right:   ${XXXS}  ; }

  /* vertical paddings */
  .pv-xxxs { padding-top:   ${XXXS}  ; padding-bottom:   ${XXXS}  ; }

  /* all paddings */
  .p-xxxs { padding:   ${XXXS}  ; }

  /* padding-top */
  .pt-xxs { padding-top: ${XXS}  ; }

  /* padding-right */
  .pr-xxs { padding-right: ${XXS}  ; }

  /* padding-bottom */
  .pb-xxs { padding-bottom: ${XXS}  ; }

  /* padding-left */
  .pl-xxs { padding-left: ${XXS}  ; }

  /* horizontal paddings */
  .ph-xxs { padding-left: ${XXS}  ; padding-right: ${XXS}  ; }

  /* vertical paddings */
  .pv-xxs { padding-top: ${XXS}  ; padding-bottom: ${XXS}  ; }

  /* all paddings */
  .p-xxs { padding: ${XXS}  ; }

  /* padding-top */
  .pt-xs { padding-top:  ${XS}  ; }

  /* padding-right */
  .pr-xs { padding-right:  ${XS}  ; }

  /* padding-bottom */
  .pb-xs { padding-bottom:  ${XS}  ; }

  /* padding-left */
  .pl-xs { padding-left:  ${XS}  ; }

  /* horizontal paddings */
  .ph-xs { padding-left:  ${XS}  ; padding-right:  ${XS}  ; }

  /* vertical paddings */
  .pv-xs { padding-top:  ${XS}  ; padding-bottom:  ${XS}  ; }

  /* all paddings */
  .p-xs { padding:  ${XS}  ; }

  /* padding-top */
  .pt-s { padding-top:  ${S}  ; }

  /* padding-right */
  .pr-s { padding-right:  ${S}  ; }

  /* padding-bottom */
  .pb-s { padding-bottom:  ${S}  ; }

  /* padding-left */
  .pl-s { padding-left:  ${S}  ; }

  /* horizontal paddings */
  .ph-s { padding-left:  ${S}  ; padding-right:  ${S}  ; }

  /* vertical paddings */
  .pv-s { padding-top:  ${S}  ; padding-bottom:  ${S}  ; }

  /* all paddings */
  .p-s { padding:  ${S}  ; }

  /* padding-top */
  .pt-m { padding-top:  ${M}  ; }

  /* padding-right */
  .pr-m { padding-right:  ${M}  ; }

  /* padding-bottom */
  .pb-m { padding-bottom:  ${M}  ; }

  /* padding-left */
  .pl-m { padding-left:  ${M}  ; }

  /* horizontal paddings */
  .ph-m { padding-left:  ${M}  ; padding-right:  ${M}  ; }

  /* vertical paddings */
  .pv-m { padding-top:  ${M}  ; padding-bottom:  ${M}  ; }

  /* all paddings */
  .p-m { padding:  ${M}  ; }

  /* padding-top */
  .pt-l { padding-top:  ${L}  ; }

  /* padding-right */
  .pr-l { padding-right:  ${L}  ; }

  /* padding-bottom */
  .pb-l { padding-bottom:  ${L}  ; }

  /* padding-left */
  .pl-l { padding-left:  ${L}  ; }

  /* horizontal paddings */
  .ph-l { padding-left:  ${L}  ; padding-right:  ${L}  ; }

  /* vertical paddings */
  .pv-l { padding-top:  ${L}  ; padding-bottom:  ${L}  ; }

  /* all paddings */
  .p-l { padding:  ${L}  ; }

  /* padding-top */
  .pt-xl { padding-top:  ${XL}  ; }

  /* padding-right */
  .pr-xl { padding-right:  ${XL}  ; }

  /* padding-bottom */
  .pb-xl { padding-bottom:  ${XL}  ; }

  /* padding-left */
  .pl-xl { padding-left:  ${XL}  ; }

  /* horizontal paddings */
  .ph-xl { padding-left:  ${XL}  ; padding-right:  ${XL}  ; }

  /* vertical paddings */
  .pv-xl { padding-top:  ${XL}  ; padding-bottom:  ${XL}  ; }

  /* all paddings */
  .p-xl { padding:  ${XL}  ; }

  /* padding-top */
  .pt-xxl { padding-top:  ${XXL}  ; }

  /* padding-right */
  .pr-xxl { padding-right:  ${XXL}  ; }

  /* padding-bottom */
  .pb-xxl { padding-bottom:  ${XXL}  ; }

  /* padding-left */
  .pl-xxl { padding-left:  ${XXL}  ; }

  /* horizontal paddings */
  .ph-xxl { padding-left:  ${XXL}  ; padding-right:  ${XXL}  ; }

  /* vertical paddings */
  .pv-xxl { padding-top:  ${XXL}  ; padding-bottom:  ${XXL}  ; }

  /* all paddings */
  .p-xxl { padding:  ${XXL}  ; }

  /* padding-top */
  .pt-xxxl { padding-top:  ${XXXL}  ; }

  /* padding-right */
  .pr-xxxl { padding-right:  ${XXXL}  ; }

  /* padding-bottom */
  .pb-xxxl { padding-bottom:  ${XXXL}  ; }

  /* padding-left */
  .pl-xxxl { padding-left:  ${XXXL}  ; }

  /* horizontal paddings */
  .ph-xxxl { padding-left:  ${XXXL}  ; padding-right:  ${XXXL}  ; }

  /* vertical paddings */
  .pv-xxxl { padding-top:  ${XXXL}  ; padding-bottom:  ${XXXL}  ; }

  /* all paddings */
  .p-xxxl { padding:  ${XXXL}  ; }
`;

export { spaces };
