import { computed } from 'mobx';
import type Store from '../../Store';
import type DomainStore from '../../DomainStore/DomainStore';

interface IWorkspaceGuards {
  frozen: boolean;
}

class GuardStore {
  private readonly domain: DomainStore;

  constructor(root: Store) {
    this.domain = root.domain;
  }

  @computed
  get workspace(): IWorkspaceGuards {
    return {
      frozen: this.domain.guards.pathwaysLoading
    };
  }
}

export { GuardStore };
