import React, { BaseProps, ReactElement } from 'react';
import {
  DescriptionView,
  DescriptionTitle,
  ValueView,
  ValueLabel,
  ValueText
} from './styles';

export type MountingBosFormGroupDescriptionProps = BaseProps & {
  slopePitchRise: number;
  slopeDegrees: number;
  roofMaterial: string;
  construction: string;
  structuralMemberType: string;
  structuralMemberSpacing: number;
};

const MountingBosFormGroupDescription = ({
  slopePitchRise,
  slopeDegrees,
  roofMaterial,
  construction,
  structuralMemberType,
  structuralMemberSpacing,
  className
}: MountingBosFormGroupDescriptionProps): ReactElement => {
  return (
    <DescriptionView
      className={className}
    >
      <DescriptionTitle>
        Mounting Specification
      </DescriptionTitle>
      <ValueView>
        <ValueLabel>Slope:</ValueLabel>
        <ValueText>{slopePitchRise}/12</ValueText>
        <ValueLabel>({slopeDegrees}°)</ValueLabel>
      </ValueView>
      <ValueView>
        <ValueLabel>Composition: </ValueLabel>
        <ValueText>{roofMaterial}</ValueText>
      </ValueView>
      <ValueView>
        <ValueLabel>Construction:</ValueLabel>
        <ValueText>{construction}</ValueText>
      </ValueView>
      <ValueView>
        <ValueLabel>{structuralMemberType} Spacing:</ValueLabel>
        <ValueText>{structuralMemberSpacing}</ValueText>
      </ValueView>
    </DescriptionView>
  );
};

export { MountingBosFormGroupDescription };
