import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import { FieldStyle } from './styles';

type Props = BaseProps & {
  label?: string;
  inline?: boolean;
  visibility?: boolean;
  /**
   * @description Will be passed as data-testid for testing purposes
   */
  testid?: string;
};

function Field({
  inline = false, label, children, visibility = true, ...rest
}: Props): ReactElement {
  return (
    <FieldStyle
      className={rest.className}
      inline={inline}
      title={label}
      hideLabel={visibility}
      data-testid={rest.testid}
    >
      <div>{label}</div>
      {children}
    </FieldStyle>
  );
}

export default Field;
