import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';
import Flexbox from '../../../ui/components/Layout/Flexbox';
import { PADDING_CARD } from '../Layout/SolarLayout/constants';

export const EditButtonWrapper = styled.button`
  width: 46px;
  height: 22px;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }: PropsTheme): Color => `${theme.colors.whiteBg}`};
  cursor: pointer;
`;

export const RoofPropertyEditionWrapper = styled.div`
  padding: ${PADDING_CARD};
  &:last-child {
    padding-bottom: 0;
  }
`;

export const HeaderEditionWrapper = styled(Flexbox)`
  margin-bottom: 10px;
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
`;
