import Coordinate from 'coordinate-parser';
import React, {
  forwardRef, useCallback, useContext, useEffect, useMemo, useState
} from 'react';

import { ThemeContext } from 'styled-components';
import {
  useGeocoding, useGoogleMap
} from '../../../utils/maps';
import type { IAddressData } from '../../../domain/models/SiteDesign/Address';
import { Address } from '../../../domain/models/SiteDesign/Address';

// Uncomment to use a self-sufficient address autocomplete component in Aurora style
/*export const DSAddressAutoCompleteWrappedIntoFormik = ({
  onChange
}: {
  onChange: (address: string) => void;
}): ReactElement => {
  return (
    <Formik<{
      address: { address: string }[];
    }>
      initialValues={{ address: [{ address: '' }] }}
      validate={(values): {} => {
        onChange(values.address[0].address);
        return {};
      }}
      onSubmit={(values): void => {
        // const unWrappedAddress = ((address as unknown) as string[])[0];
        onChange(values.address[0].address);
      }}
    >
      <DS.Form autoSave>
        <DS.Field
          component={DSAddressAutoComplete}
          name="engineerOfRecord.address"
          label="Engineer address"
          size="sm"
          onSelect={() => {
            console.log('on select');
          }}
          onChange={() => {
            console.log('on change');
          }}
        />
      </DS.Form>
    </Formik>
  );
};*/

const isValidCoordinate = (coordinate: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new Coordinate(coordinate);
    return true;
  } catch (_e) {
    return false;
  }
};

interface AutocompleteAddress {
  placeId: string;
  main: string;
  secondary: string;
  type: string;
}

// let queryInterval: number;

const useGoogleAutocomplete = (
  autocompleteRequestLocationType?: AutocompleteRequestLocationType[],
  autocompleteComponentRestrictions?: google.maps.places.ComponentRestrictions
): {
  currentItems: AutocompleteAddress[];
  updateQuery: (term: string) => Promise<AutocompleteAddress[]>;
} => {
  const googleMap = useGoogleMap();
  //return new googleMap.maps.places.AutocompleteService();
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  useEffect(() => {
    if (googleMap) {
      setAutocompleteService(new googleMap.places.AutocompleteService());
    }
  }, [googleMap]);

  const [currentItems, setCurrentItems] = useState<AutocompleteAddress[]>([]);

  const queryGooglePlaces = useCallback(
    async (term: string) => {
      if (autocompleteService) {
        if (term.trim() === '') {
          term = ' ';
        }
        const { predictions } = await autocompleteService.getPlacePredictions({
          componentRestrictions: autocompleteComponentRestrictions,
          input: term,
          language: 'EN',
          types: autocompleteRequestLocationType
        });

        const mappedPredictions = predictions.map(({
          place_id, structured_formatting, types
        }) => ({
          main: structured_formatting.main_text,
          placeId: place_id,
          secondary: structured_formatting.secondary_text,
          type: types[0]
        }));

        setCurrentItems(mappedPredictions);

        return mappedPredictions;
      }
      return Promise.resolve([]);
    },
    [autocompleteComponentRestrictions, autocompleteRequestLocationType, autocompleteService]
  );

  return {
    currentItems,
    updateQuery: queryGooglePlaces
  };
};

enum AutocompleteRequestLocationType {
  address = 'address',
  administrative_area_level_1 = 'administrative_area_level_1', // state
  administrative_area_level_2 = 'administrative_area_level_2', // county
  cities = '(cities)',
  establishment = 'establishment',
  postal_code = 'postal_code',
  regions = '(regions)'
}

type IDSAddressAutoComplete = {
  /**
   * Specify what types of data to return, could be regions or addresses.
   */
  autocompleteRequestLocationType?: AutocompleteRequestLocationType[];
  autocompleteComponentRestrictions?: google.maps.places.ComponentRestrictions;
  onSelect?: (address: IAddressData, selectedItem: AutoCompleteValue) => void;
  handleSelectionChange?: (selection: AutoCompleteValue[]) => void;
  field: {
    value: AutoCompleteValue;
  };
};

export interface AutoCompleteValue {
  address: string;
  placeId: string;
  type: string;
}

interface CoordinateValue {
  coordinate: {
    lat: number;
    lng: number;
  };
}

type JurisdictionValue = string;

type AddressAutoCompleteOption = {
  label: string;
  value: AutoCompleteValue | CoordinateValue | JurisdictionValue;
};

export const DSAddressAutoComplete = forwardRef<HTMLInputElement, IDSAddressAutoComplete>(
  ({
    autocompleteRequestLocationType, autocompleteComponentRestrictions, onSelect, ...props
  }, forwardedRef) => {
    const DS = useContext(ThemeContext)!.DS;
    const inputRef = DS.useForwardedRef(forwardedRef);
    const [inputData, setInputData] = useState<{
      input: string | undefined;
      isValidCoordinate: boolean;
    }>({
      input: undefined,
      isValidCoordinate: false
    });
    const {
      currentItems, updateQuery
    } = useGoogleAutocomplete(
      autocompleteRequestLocationType,
      autocompleteComponentRestrictions
    );

    const handleInput = (input: string): void => {
      if (isValidCoordinate(input)) {
        setInputData({
          input,
          isValidCoordinate: true
        });
      } else {
        setInputData({
          input,
          isValidCoordinate: false
        });
        updateQuery?.(input);
      }
    };

    const geocoder = useGeocoding(google.maps);

    const options: AddressAutoCompleteOption[] = useMemo(() => {
      if (inputData.isValidCoordinate) {
        const coordinate = new Coordinate(inputData.input!);
        return [
          {
            label: inputData.input!,
            value: {
              coordinate: {
                lat: coordinate.getLatitude(),
                lng: coordinate.getLongitude()
              }
            }
          }
        ];
      }
      if (currentItems.length === 0 && typeof props.field.value?.placeId === 'string') {
        return [
          {
            label: props.field.value.address,
            value: props.field.value
          }
        ];
      }
      if (currentItems.length === 0 && typeof props.field.value === 'string') {
        return [
          {
            label: props.field.value,
            value: props.field.value
          }
        ];
      }
      return currentItems.map(({
        main, secondary, placeId, type
      }) => {
        const fullAddress = [main, secondary].filter(Boolean).join(', ');
        return {
          label: fullAddress,
          value: {
            address: fullAddress,
            placeId,
            type
          }
        };
      });
    }, [currentItems, inputData.input, inputData.isValidCoordinate, props.field.value]);

    return (
      <DS.AutocompleteInput
        {...props}
        ref={inputRef}
        emptiable
        filterAlgorithm={() => true}
        handleSelectionChange={(selection: (AutoCompleteValue | CoordinateValue | JurisdictionValue)[]) => {
          const placeId = (selection?.[0] as AutoCompleteValue)?.placeId;
          (async (): Promise<void> => {
            if (!placeId) {
              return;
            }
            const geocoderResult = await geocoder.geocode({ placeId });
            const addressComponents = geocoderResult.results[0]?.address_components;

            const addressData = Address.fromGoogleMapsAddressComponents(addressComponents);
            const addressObject = addressData.toData();

            // Notify parent component about the selected address
            // Formik will report and track AutoCompleteValue structure, while
            // onSelect will return a nice data structure mapped into address fields.
            // @ts-ignore
            onSelect?.(addressObject, selection[0]);
          })();

          inputRef.current?.blur();
        }}
        // this bypass the built-in filter functionality from dropdown input
        // because google map already tries to match what user has typed
        handleUserInput={handleInput}
        options={options.length === 1 && options[0].label === '' ? [] : options}
      />
    );
  }
);
