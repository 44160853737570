import styled, { PropsTheme } from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  background: rgb(31, 192, 192, 0.1);
  border-radius: 2.9px;
  border: solid 0.7px #c9c9c9;
  padding: 5px 2px;
  & code {
    background: rgb(128, 128, 128, 0.5);
    border-radius: 2px;
    padding: 1px 4px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 90%;
`;

export const Message = styled.span`
  font-family: ${(props: PropsTheme): string => props.theme.fonts.sans};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 12px;
`;
