/** CONVERTER UTILITY */
import {
  degreesToRadians, radiansToDegrees
} from './math';

export const DENOMINATOR_PITCH = 12;

/**
 * Convert roof angle in degrees into fractional format (x/12), where x is
 * the roof rise factor that will be returned by this function.
 * @param angle in degrees
 * @returns rise factor
 */
export function degreesToPitch(angle: number): number {
  return Math.tan(degreesToRadians(angle)) * DENOMINATOR_PITCH;
}

/**
 * Convert roof rise factor (x/12) into angle in degrees.
 * @param rise roof rise factor
 * @returns angle in degrees
 */
export function pitchToDegrees(rise: number): number {
  if (rise === 0) {
    return rise;
  }

  const pitchFraction = rise / DENOMINATOR_PITCH;
  const radians = Math.atan(pitchFraction);
  const angle = radiansToDegrees(radians);

  return Number(angle);
}
