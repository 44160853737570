import { Vector3 } from 'three';
import {
  observable, runInAction
} from 'mobx';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import {
  ECursor, Units
} from '../../../../domain/typings';
import { TraceToDefineScalingLine } from '../../../../domain/models/SiteDesign/TraceToDefineScalingLine';
import { canvasConfig } from '../../../../config/canvasConfig';
import { PreviewLine } from '../../../../domain/graphics/PreviewLine';
import type { IAddVertexToRoofFaceDependencies } from '../../../ServiceBus/Commands/AddVertexToRoofFaceCommand';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import ProjectionUtil from '../../../../utils/projectionUtil';
import type {
  IPointerDownControlEvent,
  IPointerLeftControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../../EditorStore/Controls/ControlEvents';
import { getRootStore } from '../../../RootStoreInversion';
import BaseImageryProvider from '../../../../domain/typings/BaseImageryProvider';
import type { IUpdateCustomBaseImageryCommandDependencies } from '../../../ServiceBus/Commands/UpdateBaseImageryCommand';
import type { SimpleVector2 } from '../../../../utils/ThreeUtils';
import { PropsPanelUICodes } from '../../Properties/propertiesStoreConstants';
import type { PolygonDrawable } from '../../../../domain/mixins/PolygonDrawable';
import { convertMeterToFeet } from '../../../../utils/Convertions';
import { TRACE_TO_DEFINE_SCALING_TOOL_ID } from './constants';
import { CustomBaseImageryTransformationTool } from './CustomBaseImageryTransformationTool';
import type { IDrawableStructureFactory } from 'domain/models/StructureFactory';

export class TraceToDefineScalingTool extends BaseTool {
  readonly id: string = TRACE_TO_DEFINE_SCALING_TOOL_ID;
  readonly icon: string = '';
  readonly title: string = '';
  readonly description: string = '';
  private previewLine?: PreviewLine;
  floatingElementCoordinates: SimpleVector2 = {
    x: 0,
    y: 0
  };
  wipObject: PolygonDrawable;
  protected drawableObjectsFactory: IDrawableStructureFactory;
  @observable
  showFloatingElement: boolean = false;
  @observable
  userSpecifiedDistanceInFt: number = 0;
  tracedDistanceInFt: number = 0;

  constructor(
    dependencies: IBaseToolDependencies & {
      drawableObjectsFactory: IDrawableStructureFactory;
    }
  ) {
    super(dependencies);
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
    this.cursor = ECursor.FIRST_TRACE;
    this.wipObject = this.drawableObjectsFactory.create<TraceToDefineScalingLine>(TraceToDefineScalingLine, {
      color: canvasConfig.wipOutlineColor
    });
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    const {
      target, pointerStart
    } = event;

    if (pointerStart && target) {
      const vertexWorldCoords = target.unprojectMouseToFrustum(pointerStart);

      if (this.wipObject.boundary.vertices.length === 0) {
        this.previewLine!.set({
          start: vertexWorldCoords,
          end: vertexWorldCoords
        });
        this.previewLine!.visible = false;
      }
    }
  };

  override onMouseUp = (event: IPointerUpControlEvent): void => {
    const {
      target, pointerEnd, originalEvent
    } = event;
    if (!this.isValidUpEvent(event) || !target || !pointerEnd) {
      return;
    }

    const vertexWorldCoords = target.unprojectMouseToFrustum(pointerEnd);
    const dependencies: IAddVertexToRoofFaceDependencies = {
      editor: this.editor,
      targetCoordinates: vertexWorldCoords,
      wipRoofface: this.wipObject as RoofFace
    };
    this.serviceBus.send('add_vertex_to_roof_face', dependencies);

    if (this.wipObject.boundary.vertices.length === 2) {
      runInAction(() => {
        const vertex1 = this.wipObject.boundary.vertices[0];
        const vertex2 = this.wipObject.boundary.vertices[1];
        const distanceInWorldUnits = vertex1.mesh.position.distanceTo(vertex2.mesh.position);
        const distanceInMeters = ProjectionUtil.convertFromWorldUnits(distanceInWorldUnits, Units.Meters);

        this.tracedDistanceInFt = convertMeterToFeet(distanceInMeters);
        this.floatingElementCoordinates.x =
          -47
          /* left menu */ + Math.min(
            originalEvent?.clientX ?? 500,
            (window.innerWidth ?? 800) - (365 /* floating window*/ + 245) /* right menu */
          );
        this.floatingElementCoordinates.y =
          -64
          /* top menu */ + Math.min(
            originalEvent?.clientY ?? 500,
            (window.innerHeight ?? 800) - (50 /* floating window*/ + 64) /* bottom menu */
          );

        this.showFloatingElement = true;
        this.editor.updateCursor(ECursor.DEFAULT);
        this.removePreviewLine();
        this.removeEventListeners();
      });
    }
    this.hidePreviewLine();
  };

  applyScale = async (): Promise<void> => {
    const ratio = this.userSpecifiedDistanceInFt / this.tracedDistanceInFt;
    const store = getRootStore();
    const imagery = store.domain.project.site.imagery;
    if (!imagery.CUSTOM) {
      console.error('Trying to use scale-to-define-scaling when not using custom base imagery');
      return;
    }

    imagery.CUSTOM.scaleFactor *= ratio;

    this.serviceBus.send('update_base_imagery_command', {
      domain: store.domain,
      customBaseImagery: imagery.CUSTOM,
      newProvider: BaseImageryProvider.CUSTOM
    } as IUpdateCustomBaseImageryCommandDependencies);

    await this.editor.finishCustomBaseImageryTransformation();

    this.resetTrace();
    this.selectCustomBaseImageryTransformationTool();
  };

  selectCustomBaseImageryTransformationTool = async (): Promise<void> => {
    const store = getRootStore();
    await store.editor.changeMapBackground(BaseImageryProvider.GOOGLE_MAPS, false);
    const tool = new CustomBaseImageryTransformationTool({
      ...store,
      toolbar: store.toolbar,
      smartGuides: store.smartGuides,
      mapStore: store.map,
      isCustomBaseImageryConfigured: !!store.domain.project.site.imagery.CUSTOM?.scaleFactor
    });
    store.toolbar.selectTool(tool);
  };

  setLength = (length: number): void => {
    this.userSpecifiedDistanceInFt = length;
  };

  override onMouseLeave = (event: IPointerLeftControlEvent): void => {
    this.hidePreviewLine();
  };

  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    const {
      target, pointerPosition
    } = event;

    if (target === undefined || pointerPosition === undefined) {
      return;
    }

    const mousePos = target.unprojectMouseToFrustum(pointerPosition);

    const prevLine = this.previewLine!;
    const prevLinePosition: Vector3 = new Vector3().copy(mousePos);

    this.previewLine!.visible = true;

    const vectorStart: Vector3 = prevLinePosition.clone();
    if (this.wipObject.polygon.length > 0) {
      vectorStart.copy(this.wipObject.polygon[this.wipObject.polygon.length - 1].mesh.position);
    }

    prevLine.color = canvasConfig.previewObjectColor;
    prevLine.set({
      start: vectorStart,
      end: mousePos
    });
  };

  initPreviewLine(): void {
    this.previewLine = this.previewLine || this.getPreviewObject();
    this.previewLine.showSegmentLength = false;
    this.editor.addOrUpdateObject(this.previewLine.mesh);
  }

  protected getPreviewObject(): PreviewLine {
    return this.drawableObjectsFactory.create<PreviewLine>(PreviewLine, {
      color: canvasConfig.previewObjectColor,
      getWipObject: (): PolygonDrawable | undefined => this.wipObject
    });
  }
  protected hidePreviewLine(): void {
    this.previewLine!.visible = false;
    this.previewLine!.segmentLengthElement.style.display = 'none';
  }

  protected removePreviewLine(): void {
    this.editor.removeObject(this.previewLine!.mesh);
    this.previewLine!.segmentLengthElement.remove();
  }

  whenSelected(): void {
    this.initPreviewLine();
    this.hidePreviewLine();
    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.addEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);
    getRootStore().properties.setPropertyPanel(PropsPanelUICodes.ScaleToDefineScaling);
  }

  whenDeselected(): void {
    this.editor.removeObject(this.wipObject.mesh);
    this.removePreviewLine();
    this.removeEventListeners();
  }
  dispose(): void {
    this.removeEventListeners();
  }

  resetTrace(): void {
    this.wipObject.removeLast();
    this.wipObject.removeLast();
    this.showFloatingElement = false;
  }

  cancel(): void {
    this.resetTrace();
    this.selectCustomBaseImageryTransformationTool();
  }

  private removeEventListeners(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
    this.editor.baseMouseControl.removeEventListener('PointerDown', this.onMouseDown);
  }
}
