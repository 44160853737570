import type { StructuralStandard } from '../../../domain/models/SiteDesign/CodesAndStandards';
import type { IDesignParametersData } from '../../../domain/models/SiteDesign/DesignParameters';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateWindDesignParametersCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly windSpeed: number;
  readonly structuralStandardSelected: StructuralStandard;
  readonly riskCategorySelected: string;
}

export class UpdateWindDesignParametersCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly windSpeed: number;
  private readonly structuralStandardSelected: StructuralStandard;
  private readonly riskCategorySelected: string;

  constructor(dependencies: IUpdateWindDesignParametersCommandDependencies) {
    super();
    this.domain = dependencies.domain;
    this.windSpeed = dependencies.windSpeed;
    this.structuralStandardSelected = dependencies.structuralStandardSelected;
    this.riskCategorySelected = dependencies.riskCategorySelected;
  }

  async execute(): Promise<void> {
    const designParameters: IDesignParametersData = {
      ...this.domain.project.designParameters.toData(),
      wind: this.domain.project.designParameters.wind.withWindSpeed(this.windSpeed).toData(),
      buildingRiskCategory: this.riskCategorySelected,
      codesAndStandards: this.domain.project.designParameters.codesAndStandards
        .withStructuralStandard(this.structuralStandardSelected)
        .toData()
    };
    this.domain.project.updateDesignParameters(designParameters);
  }
}
