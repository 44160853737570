import { BaseProps } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { colorScheme } from '../Theme/colorScheme';

type Props = BaseProps & {
  type?: string;
};

const TagWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, type }: PropsTheme<Props>): Color =>
    type === 'site' ? theme.colors.mainColor2 :
    type === 'system' ? theme.colors.mainColor1 :
    type === 'user' ? theme.colors.grayColor2 : theme.colors.mainColor5
  };
  border-radius: 4px;
  cursor: pointer;
  height: 25px;
  padding: 0 10px 0 10px;
`;

const TagText = styled.span<Props>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${colorScheme.highlight};
  text-transform: uppercase;
`;

export {
  TagWrapper,
  TagText
};
