import React, {
    BaseProps,
    ReactElement
  } from 'react';
import { Icon } from '../Icon';
import { Edit } from './styles';

type RoofEdition = BaseProps & {
    onEditClick?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
  };
  
const EditButton = (props: RoofEdition): ReactElement => {
    const {onEditClick, className} = props;
    return (
        <Edit onClick={onEditClick} className={className}>
          <Icon  name="pencil" />
        </Edit>
    );
  };
  
export { EditButton };
