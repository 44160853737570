import type Store from '../../../../../../stores/Store';
import {
  type IImportModelToolDependencies,
  ImportModelTool
} from '../../../../../../stores/UiStore/ToolbarStore/Project/TracingTools/ImportModelTool';
import withTool from '../../../Shared/withTool';

type ConstDep = Pick<IImportModelToolDependencies, 'domain' | 'wizard' | 'serviceBus' | 'roofProtrusion'>;

export const ImportModel = withTool<ImportModelTool, IImportModelToolDependencies, ConstDep>(
  ImportModelTool,
  (store: Store): ConstDep => ({
    domain: store.domain,
    wizard: store.wizard,
    serviceBus: store.serviceBus,
    roofProtrusion: store.roofProtrusion
  })
);
