import React, { ReactElement } from 'react';
import { DropDownNew, ErrorText, HeaderText, IDropDownOption } from '../DropDownNew';

interface IDropDownProxyProps {
  placeholder?: string;
  items: IDropDownOption[];
  name?: string;
  value?: string;
  errorText?: string;
  onSelect: (value: IDropDownOption['value']) => void;
  menuPortalTarget?: HTMLElement | null;
}

const DropDownProxy = (props: IDropDownProxyProps): ReactElement => {
  return (
    <>
      {props.name && (
        <HeaderText>{props.name}</HeaderText>
      )}
      <DropDownNew
        placeholder={props.placeholder}
        menuPortalTarget={props.menuPortalTarget ?? document.body}
        options={props.items}
        value={props.items.find((option: IDropDownOption): boolean => option.value === props.value) || ''}
        onChange={(option: unknown): void => props.onSelect((option as IDropDownOption).value)}
      />
      {props.errorText && <ErrorText>{props.errorText}</ErrorText>}
    </>
  );
};

export { IDropDownProxyProps, DropDownProxy };
