import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

export const Container = styled.div<{ step: number }>`
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: ${({ step }: { step: number }): string => (step === 1 ? '350' : '500')}px;

  > a {
    color: ${({ theme }: PropsTheme): Color => theme.colors.main1};
  }
`;

export const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const InlineRow = styled.div`
  width: 48%;
`;
