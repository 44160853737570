import { LyraGrid } from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import useStore from '../../../../stores/useStore';
import { UtilityMeterViewModel } from '../../../../stores/UiStore/Properties/SiteEquipment/ViewModels/UtilityMeterViewModel';

import { ServiceEntranceEquipmentFormContainer } from '../ServiceEntranceEquipmentFormContainer';
import {
  getServiceEntranceAndSiteEquipmentProperties, ServiceEntranceEquipmentDevice, ServiceEntranceEquipmentType
} from '../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import UtilityMeterProperties from './../UtilityMeterProperties';

const MeterBaseForm = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<UtilityMeterViewModel>();

  useEffect((): void => {
    setViewModel(
      new UtilityMeterViewModel({
        editor,
        domain,
        serviceBus
      })
    );
  }, [editor, domain, serviceBus]);

  const {
    label, name
  } = getServiceEntranceAndSiteEquipmentProperties(
    ServiceEntranceEquipmentDevice.UtilityMeter,
    ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter
  );

  if (!viewModel) {
    return <></>;
  }
  return (
    <ServiceEntranceEquipmentFormContainer title={name} label={label}>
      <LyraGrid.Grid
        options={viewModel.exposureOptions}
        columns={1}
        selected={viewModel.exposure as LyraGrid.OptionProps}
        onSelect={(option: Option): void => viewModel.setExposure(option.value)}
        selectedOptionColor={EThemeComponentColor.PURPLE}
      />
      <UtilityMeterProperties viewModel={viewModel.utilityMeterProps} />
    </ServiceEntranceEquipmentFormContainer>
  );
};
export default observer(MeterBaseForm);
