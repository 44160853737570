import React, {
  BaseProps,
  ReactElement,
  ReactText,
  useCallback,
  useEffect,
  useState
} from 'react';
import styled from 'styled-components';
import { Icon } from '../../Icon';
import { ColorThemeProps, getColorTheme } from '../../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { HATextField } from '../HATextField';

type Props = BaseProps & ColorThemeProps;

const TagsInput = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 26px;
  width: auto;
  border-bottom: 0.8px solid gray;
  input {
    flex: 1;
    border: none;
    font-size: 16px;
    &:focus {
      outline: transparent;
    }
  }
`;

const Tag = styled.li<Props>`
  width: auto;
  display: flex;
  padding: 3px 8px;
  list-style: none;
  border-radius: 14px;
  margin: 0 6px 6px 0;
  background: ${getColorTheme};
`;

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
`;

const Title = styled.span`
  margin: 0 5px;
`;

export interface IProps extends BaseProps {
  propTags: ReactText[];
  onChange?: (tags: ReactText[]) => void;
  colorTheme?: EThemeComponentColor;
  className?: string;
}

const HATagInput = ({
  propTags,
  onChange,
  colorTheme = EThemeComponentColor.GRAY
}: IProps): ReactElement => {
  const [tags, setTags] = useState(propTags);
  const [value, setValue] = useState<string | number>('');

  const removeTags = useCallback(
    (indexToRemove: number): void => {
      setTags([
        ...tags.filter((_: ReactText, index: number): boolean => index !== indexToRemove)
      ]);
    },
    [tags]
  );

  const addTags = useCallback(
    (_: ReactText): void => {
      if (value !== '') {
        setTags([...tags, value]);
        setValue('');
      }
    },
    [tags, value]
  );

  useEffect((): void => {
    if (onChange) {
      onChange(tags);
    }
  }, [tags]);

  useEffect((): void => {
    setTags(propTags);
  }, [propTags]);

  const updateTags = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      addTags(value);
    }
  };

  return (
    <>
      <TagsInput>
        <Tags>
          {tags &&
            tags.map(
              (tag: ReactText, index: number): ReactElement =>
                index < 8 ? (
                  <Tag key={index} colorTheme={colorTheme}>
                    <Title>{tag}</Title>
                    <Icon onClick={(): void => removeTags(index)} name="close-icon" />
                  </Tag>
                ) : (
                  <></>
                )
            )}
          <HATextField
            width="auto"
            name="tag"
            type="text"
            value={value}
            onKeyUp={updateTags}
            onChange={(inputValue: ReactText): void => setValue(inputValue)}
          />
        </Tags>
      </TagsInput>
    </>
  );
};

export { HATagInput };
