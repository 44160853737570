import { computed } from 'mobx';
import { ROOF_COLOR_LEVEL_RANGE } from '../RoofColorLevelRange';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import { SelectionControl } from '../../../stores/EditorStore/Controls/SelectionControl';
import type { EnvironmentalPropsStore } from '../../../stores/UiStore/EnvironmentalProps/EnvironmentalPropsStore';
import type { ModalStore } from '../../../stores/UiStore/Modal/Modal';
import type { PropertiesStore } from '../../../stores/UiStore/Properties/Properties';
import type { RoofFacePropertiesStore } from '../../../stores/UiStore/Properties/RoofProperties/RoofFacePropertiesStore';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { OutlineTool } from '../../../stores/UiStore/ToolbarStore/Project/TracingTools/OutlineTool';
import type { ToolbarStore } from '../../../stores/UiStore/ToolbarStore/Toolbar';
import type { WizardStore } from '../../../stores/UiStore/Wizard/Wizard';
import type { IWorkspace } from '../../../stores/UiStore/WorkspaceStore/types';
import { isEmpty } from '../../../utils/helpers';
import type {
  IBuildingMissingProperties,
  IBuildingObjectList,
  IProjectMissingPropertyObject,
  ISiteEquipmentMissingProperties,
  IProjectMissingPropertiesResponse,
  IProjectMissingProperty,
  IRoofFaceMissingProperties
} from '../../entities/MissingProperties/IMissingProperties';
import {
  MissingPropertyIcon as EnumIcon,
  MissingPropertyLabel as EnumLabel,
  MissingPropertyType as EnumType
} from '../../entities/MissingProperties/IMissingProperties';
import { SiteStructureFactory } from '../StructureFactory';
import { SceneObjectType } from '../Constants';
import type { IBaseRoofFaceViewModelDependencies } from '../../../stores/UiStore/Properties/RoofProperties/BaseRoofFaceViewModel';
import { FramingRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/FramingRoofFaceViewModel';
import { RoofDeckingViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/RoofDeckingViewModel';
import { SurfaceRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/SurfaceRoofFaceViewModel';
import { SelectionTool } from '../../../stores/UiStore/ToolbarStore/Project/SelectionTool';
import { ParcelBoundaryTool } from '../../../stores/UiStore/ToolbarStore/Project/ParcelBoundaryTool';
import { StreetLocationTool } from '../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/StreetLocationTool';
import { SubpanelTool } from '../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/SubpanelTool';
import { TraceIndividualRoofFaceTool } from '../../../stores/UiStore/ToolbarStore/Project/TracingTools/TraceIndividualRoofFaceTool';
import type { Building } from './Building';
import type { RoofFace } from './RoofFace';
import {
  ServiceEntranceEquipmentTool
} from '../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/ServiceEntranceEquipmentTool';

type MapperFunction = (blocking: boolean) => void;

type RooFaceMissingProperty = {
  name: string;
  level: number;
  levelColor: string;
  buttons: ButtonMissingProperty[];
};

type BuildingMissingProperty = {
  id: string;
  name: string;
  iconName: string;
  label: string;
  trigger: () => void;
  properties: RooFaceMissingProperty[];
};
type ButtonMissingProperty = {
  iconName: string;
  label: string;
  trigger: () => void;
};

type SiteEquipmentProperties = {
  missingProperties: ButtonMissingProperty[];
};

type EnvironmentalProperties = {
  buttonList: ButtonMissingProperty[];
};

type ProjectProperties = {
  missingProperties: string[];
};

export type ProjectReadinessProperties = {
  siteEquipmentProperties?: SiteEquipmentProperties;
  environmentalProperties?: EnvironmentalProperties;
  projectProperties?: ProjectProperties;
  buildingList?: BuildingMissingProperty[];
};

export interface IProjectReadinessDependencies {
  editor: EditorStore;
  domain: DomainStore;
  modal: ModalStore;
  serviceBus: ServiceBus;
  toolbar: ToolbarStore;
  environmentalProps: EnvironmentalPropsStore;
  properties: PropertiesStore;
  smartGuides: SmartGuidesStore;
  wizard: WizardStore;
  currentWorkspace: IWorkspace;
  roofFaceProps: RoofFacePropertiesStore;
  missingPropertiesResponse: IProjectMissingPropertiesResponse;
}

export class ProjectReadiness {
  private mandatoryTotalCount: number = 0;
  private optionalTotalCount: number = 0;
  private readonly domain: DomainStore;
  private readonly modal: ModalStore;
  private readonly editor: EditorStore;
  private readonly serviceBus: ServiceBus;
  private readonly toolbar: ToolbarStore;
  private readonly properties: PropertiesStore;
  private readonly smartGuides: SmartGuidesStore;
  private readonly wizard: WizardStore;
  private readonly currentWorkspace: IWorkspace;
  private readonly environmentalProps: EnvironmentalPropsStore;
  private readonly roofFaceProps: RoofFacePropertiesStore;
  private readonly mandatory: ProjectReadinessProperties = {};
  private readonly optional: ProjectReadinessProperties = {};
  private readonly mappedValues: Map<string, MapperFunction>;
  private readonly missingPropertiesResponse: IProjectMissingPropertiesResponse;
  private readonly selectionTool: SelectionTool;
  private readonly selectionControl: SelectionControl;

  constructor(dependencies: IProjectReadinessDependencies) {
    const {
      missingPropertiesResponse,
      editor,
      serviceBus,
      domain,
      modal,
      toolbar,
      properties,
      smartGuides,
      wizard,
      currentWorkspace,
      environmentalProps,
      roofFaceProps
    } = dependencies;
    this.editor = editor;
    this.domain = domain;
    this.modal = modal;
    this.toolbar = toolbar;
    this.properties = properties;
    this.serviceBus = serviceBus;
    this.smartGuides = smartGuides;
    this.wizard = wizard;
    this.currentWorkspace = currentWorkspace;
    this.environmentalProps = environmentalProps;
    this.roofFaceProps = roofFaceProps;
    this.mappedValues = this.createMap();
    this.missingPropertiesResponse = missingPropertiesResponse;
    this.selectionTool = new SelectionTool({
      properties: this.properties,
      editor: this.editor,
      toolbar: this.toolbar,
      domain: this.domain,
      serviceBus: this.serviceBus,
      smartGuides: this.smartGuides
    });
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    if (this.missingPropertiesResponse.designParameters) {
      this.mapDesignParameters(missingPropertiesResponse.designParameters);
    }
    if (this.missingPropertiesResponse.site) {
      this.mapSite(missingPropertiesResponse.site);
    }
    if (this.missingPropertiesResponse.siteEquipment) {
      this.mapSiteEquipment(missingPropertiesResponse.siteEquipment);
    }
    if (this.missingPropertiesResponse.buildings) {
      this.mapBuildings(missingPropertiesResponse.buildings);
    }
    if (this.mandatory.buildingList) {
      this.mandatory.buildingList = this.mandatory.buildingList.filter(
        (item: BuildingMissingProperty): unknown => item.label || item.properties.length
      );
    }
    if (this.optional.buildingList) {
      this.optional.buildingList = this.optional.buildingList.filter(
        (item: BuildingMissingProperty): unknown => item.label || item.properties.length
      );
    }
  }

  hasMissingProperties(): boolean {
    return !isEmpty(this.mandatory) || !isEmpty(this.optional);
  }

  @computed
  get mandatoryMissingProperties(): ProjectReadinessProperties {
    return this.mandatory;
  }

  @computed
  get optionalMissingProperties(): ProjectReadinessProperties {
    return this.optional;
  }

  getTotalMandatoryMissingProperties(): number {
    return this.mandatoryTotalCount;
  }

  getTotalOptionalMissingProperties(): number {
    return this.optionalTotalCount;
  }

  get hasMissingMandatoryProperties(): boolean {
    return this.getTotalMandatoryMissingProperties() > 0;
  }

  private selectRoofFaceTool(): void {
    const roofFaceTool = new TraceIndividualRoofFaceTool({
      properties: this.properties,
      editor: this.editor,
      toolbar: this.toolbar,
      domain: this.domain,
      roofFaceProps: this.roofFaceProps,
      serviceBus: this.serviceBus,
      smartGuides: this.smartGuides,
      wizard: this.wizard,
      currentWorkspace: this.currentWorkspace,
      drawableObjectsFactory: new SiteStructureFactory()
    });
    this.toolbar.selectTool(roofFaceTool);
  }

  private selectServiceEntranceEquipmentTool(): void {
    this.modal.closeModal();

    const serviceEntranceEquipmentTool = new ServiceEntranceEquipmentTool({
      editor: this.editor,
      toolbar: this.toolbar,
      properties: this.properties,
      domain: this.domain,
      drawableObjectsFactory: new SiteStructureFactory(),
      serviceBus: this.serviceBus,
      smartGuides: this.smartGuides,
      currentWorkspace: this.currentWorkspace
    });

    this.toolbar.selectTool(serviceEntranceEquipmentTool);
  }

  private selectParcelBoundary(): void {
    this.modal.closeModal();

    const parcelBoundaryTool = new ParcelBoundaryTool({
      editor: this.editor,
      toolbar: this.toolbar,
      serviceBus: this.serviceBus,
      properties: this.properties,
      smartGuides: this.smartGuides,
      wizard: this.wizard,
      domain: this.domain,
      drawableObjectsFactory: new SiteStructureFactory(),
      currentWorkspace: this.currentWorkspace
    });

    this.toolbar.selectTool(parcelBoundaryTool);
  }

  private selectSubpanelTool(): void {
    this.modal.closeModal();

    const subpanelTool = new SubpanelTool({
      editor: this.editor,
      toolbar: this.toolbar,
      properties: this.properties,
      domain: this.domain,
      drawableObjectsFactory: new SiteStructureFactory(),
      serviceBus: this.serviceBus,
      smartGuides: this.smartGuides,
      currentWorkspace: this.currentWorkspace
    });

    const [equipment] = subpanelTool.getEquipment();

    if (!equipment) {
      this.toolbar.selectTool(subpanelTool);
    } else {
      this.toolbar.enableTools();
      this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
      this.toolbar.selectTool(this.selectionTool);
      this.selectionControl.setSelectedObjects([equipment]);
    }
  }

  private selectStreetLocationTool(): void {
    this.modal.closeModal();

    const streetLocationTool = new StreetLocationTool({
      editor: this.editor,
      toolbar: this.toolbar,
      properties: this.properties,
      domain: this.domain,
      drawableObjectsFactory: new SiteStructureFactory(),
      serviceBus: this.serviceBus,
      smartGuides: this.smartGuides,
      currentWorkspace: this.currentWorkspace
    });

    const [equipment] = streetLocationTool.getEquipment();

    if (!equipment) {
      this.toolbar.selectTool(streetLocationTool);
    } else {
      this.toolbar.enableTools();
      this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
      this.toolbar.selectTool(this.selectionTool);
      this.selectionControl.setSelectedObjects([equipment]);
    }
  }

  private mapDesignParameters(designParameters: IProjectMissingPropertyObject): void {
    const { missingProperties } = designParameters;
    this.mapMissingProperties(missingProperties);
  }

  private mapSite(site: IProjectMissingPropertyObject): void {
    const { missingProperties } = site;
    this.mapMissingProperties(missingProperties);
  }

  private mapSiteEquipment(siteEquipment: ISiteEquipmentMissingProperties): void {
    const {
      missingProperties, mainServicePanel, subpanel
    } = siteEquipment;
    if (missingProperties) {
      this.mapMissingProperties(missingProperties);
    }
    if (mainServicePanel) {
      this.mapMissingProperties(mainServicePanel.missingProperties);
    }
    if (subpanel) {
      this.mapMissingProperties(subpanel.missingProperties);
    }
  }

  private mapBuildings(buildings: IBuildingObjectList): void {
    Object.entries(buildings).forEach(
      ([buildingId, buildingMissingProperties]: [string, IBuildingMissingProperties]): void => {
        const domainBuilding = this.domain.buildings.find((building: Building): boolean => building.id === buildingId);
        if (domainBuilding) {
          const {
            missingProperties = [], roofFaces = {}
          } = buildingMissingProperties;
          const missingOutline = missingProperties.find((missingProperty: IProjectMissingProperty): boolean => {
            return missingProperty.type === EnumType.ROOF_OUTLINE;
          });
          this.addBuilding(domainBuilding, missingOutline);
          const auxRoofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace);
          Object.entries(roofFaces).forEach(
            ([roofFaceId, roofFaceMissingProperties]: [string, IRoofFaceMissingProperties]): void => {
              const domainRoofFace = auxRoofFaces.find(
                (roofFace: RoofFace): boolean => roofFace.serverId === roofFaceId
              );
              if (domainRoofFace) {
                this.addRoofFace(domainBuilding, domainRoofFace, roofFaceMissingProperties.missingProperties);
              }
            }
          );
        }
      }
    );
  }

  private mapMissingProperties(missingProperties: IProjectMissingProperty[] = []): void {
    missingProperties.forEach((missingProperty: IProjectMissingProperty): void => {
      const { blocking } = missingProperty;
      const mapper: MapperFunction | undefined = this.mappedValues.get(missingProperty.type);
      if (mapper) {
        mapper(blocking);
      }
    });
  }

  private addProjectProperty(label: string, blocking: boolean): void {
    if (blocking) {
      if (this.mandatory.projectProperties) {
        this.mandatory.projectProperties.missingProperties.push(label);
        this.mandatoryTotalCount += 1;
      } else {
        this.mandatory.projectProperties = {
          missingProperties: [label]
        };
        this.mandatoryTotalCount += 1;
      }
    } else {
      if (this.optional.projectProperties) {
        this.optional.projectProperties.missingProperties.push(label);
        this.optionalTotalCount += 1;
      } else {
        this.optional.projectProperties = {
          missingProperties: [label]
        };
        this.optionalTotalCount += 1;
      }
    }
  }

  private addEnvironmentalProperty(button: ButtonMissingProperty, blocking: boolean): void {
    if (blocking) {
      if (this.mandatory.environmentalProperties) {
        this.mandatory.environmentalProperties.buttonList.push(button);
        this.mandatoryTotalCount += 1;
      } else {
        this.mandatory.environmentalProperties = {
          buttonList: [button]
        };
        this.mandatoryTotalCount += 1;
      }
    } else {
      if (this.optional.environmentalProperties) {
        this.optional.environmentalProperties.buttonList.push(button);
        this.optionalTotalCount += 1;
      } else {
        this.optional.environmentalProperties = {
          buttonList: [button]
        };
        this.optionalTotalCount += 1;
      }
    }
  }

  private addMissingProperty(button: ButtonMissingProperty, blocking: boolean): void {
    if (blocking) {
      if (this.mandatory.siteEquipmentProperties) {
        this.mandatory.siteEquipmentProperties.missingProperties.push(button);
        this.mandatoryTotalCount += 1;
      } else {
        this.mandatory.siteEquipmentProperties = {
          missingProperties: [button]
        };
        this.mandatoryTotalCount += 1;
      }
    } else {
      if (this.optional.siteEquipmentProperties) {
        this.optional.siteEquipmentProperties.missingProperties.push(button);
        this.optionalTotalCount += 1;
      } else {
        this.optional.siteEquipmentProperties = {
          missingProperties: [button]
        };
        this.optionalTotalCount += 1;
      }
    }
  }

  private addBuilding(building: Building, missingOutline: IProjectMissingProperty | undefined): void {
    const mandatoryBuildingProperties: BuildingMissingProperty = {
      id: building.id,
      name: building.name,
      iconName: missingOutline?.blocking === true ? EnumIcon.ROOF_OUTLINE : '',
      label: missingOutline?.blocking === true ? EnumLabel.ROOF_OUTLINE : '',
      trigger: (): void => {
        const outlineTool = new OutlineTool({
          properties: this.properties,
          editor: this.editor,
          toolbar: this.toolbar,
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          smartGuides: this.smartGuides,
          wizard: this.wizard,
          currentWorkspace: this.currentWorkspace,
          drawableObjectsFactory: new SiteStructureFactory()
        });
        this.modal.closeModal();
        this.toolbar.selectTool(outlineTool);
      },
      properties: []
    };
    if (!this.mandatory.buildingList) {
      this.mandatory.buildingList = [];
    }
    this.mandatory.buildingList.push(mandatoryBuildingProperties);

    const optionalBuildingProperties: BuildingMissingProperty = {
      id: building.id,
      name: building.name,
      iconName: missingOutline?.blocking === false ? EnumIcon.ROOF_OUTLINE : '',
      label: missingOutline?.blocking === false ? EnumLabel.ROOF_OUTLINE : '',
      trigger: (): void => {
        const outlineTool = new OutlineTool({
          properties: this.properties,
          editor: this.editor,
          toolbar: this.toolbar,
          domain: this.domain,
          modal: this.modal,
          serviceBus: this.serviceBus,
          smartGuides: this.smartGuides,
          wizard: this.wizard,
          currentWorkspace: this.currentWorkspace,
          drawableObjectsFactory: new SiteStructureFactory()
        });
        this.modal.closeModal();
        this.toolbar.selectTool(outlineTool);
      },
      properties: []
    };
    if (!this.optional.buildingList) {
      this.optional.buildingList = [];
    }
    this.optional.buildingList.push(optionalBuildingProperties);
    if (missingOutline?.blocking === true) {
      this.mandatoryTotalCount += 1;
    } else if (missingOutline?.blocking === false) {
      this.optionalTotalCount += 1;
    }
  }

  private addRoofFace(building: Building, roofFace: RoofFace, missingProperties: IProjectMissingProperty[]): void {
    const mappedValues = this.createRoofFaceMap(roofFace);
    this.roofFaceProps.setSelectedRoofFaces([roofFace]);
    const level = building.storyLevelOfRoofFace(roofFace.serverId) ?? 1;
    const levelColor = ROOF_COLOR_LEVEL_RANGE[level];
    const mandatoryRooFaceProperties: RooFaceMissingProperty = {
      name: roofFace.name,
      level,
      levelColor,
      buttons: []
    };
    const optionalRoofFaceProperties: RooFaceMissingProperty = {
      name: roofFace.name,
      level,
      levelColor,
      buttons: []
    };

    missingProperties.forEach((missingProperty: IProjectMissingProperty): void => {
      const {
        type, blocking
      } = missingProperty;
      const button = mappedValues.get(type);
      if (button) {
        if (blocking) {
          mandatoryRooFaceProperties.buttons.push(button);
          this.mandatoryTotalCount += 1;
        } else {
          optionalRoofFaceProperties.buttons.push(button);
          this.optionalTotalCount += 1;
        }
      }
    });

    if (mandatoryRooFaceProperties.buttons.length && this.mandatory.buildingList) {
      const index = this.mandatory.buildingList.findIndex(
        (item: BuildingMissingProperty): boolean => item.id === building.id
      );
      this.mandatory.buildingList[index].properties.push(mandatoryRooFaceProperties);
    }
    if (optionalRoofFaceProperties.buttons.length && this.optional.buildingList) {
      const index = this.optional.buildingList.findIndex(
        (item: BuildingMissingProperty): boolean => item.id === building.id
      );
      this.optional.buildingList[index].properties.push(optionalRoofFaceProperties);
    }
  }

  private showPropertyEditor(enumType: EnumType, roofFace: RoofFace): void {
    this.modal.closeModal();
    this.toolbar.enableTools();
    this.toolbar.reset(this.currentWorkspace.defaultToolsWhiteList);
    this.toolbar.selectTool(this.selectionTool);
    this.selectionControl.setSelectedObjects([roofFace]);
    this.roofFaceProps.setSelectedRoofFaces([roofFace]);
    const dependencies: IBaseRoofFaceViewModelDependencies = {
      domain: this.domain,
      roofFace: this.roofFaceProps.firstRoofFaceSelected!,
      editor: this.editor,
      roofFaceProps: this.roofFaceProps,
      serviceBus: this.serviceBus
    };
    if (enumType === EnumType.ROOF_SURFACE_TYPE) {
      const viewModel = this.roofFaceProps.createRoofFacePropertyEditor(SurfaceRoofFaceViewModel, dependencies);
      this.roofFaceProps.changeShowPropertiesOf(viewModel);
    }
    if (enumType === EnumType.ROOF_FRAMING_SPACING || enumType === EnumType.ROOF_FRAMING_DIMENSIONS) {
      const viewModel = this.roofFaceProps.createRoofFacePropertyEditor(FramingRoofFaceViewModel, dependencies);
      this.roofFaceProps.changeShowPropertiesOf(viewModel);
    }
    if (enumType === EnumType.ROOF_DECKING_TYPE) {
      const viewModel = this.roofFaceProps.createRoofFacePropertyEditor(RoofDeckingViewModel, dependencies);
      this.roofFaceProps.changeShowPropertiesOf(viewModel);
    }
    if (enumType === EnumType.ROOF_SLOPE) {
      const viewModel = this.roofFaceProps.slopeRoofFaceViewModel!;
      this.roofFaceProps.changeShowPropertiesOf(viewModel);
    }
    if (enumType === EnumType.ROOF_AZIMUTH) {
      const viewModel = this.roofFaceProps.azimuthRoofFaceModel!;
      this.roofFaceProps.changeShowPropertiesOf(viewModel);
    }
  }

  private createMap(): Map<string, MapperFunction> {
    return new Map()
      .set(EnumType.FIRE_CODE, (blocking: boolean): void => this.addProjectProperty(EnumLabel.FIRE_CODE, blocking))
      .set(EnumType.AHJ, (blocking: boolean): void => this.addProjectProperty(EnumLabel.AHJ, blocking))
      .set(EnumType.APN, (blocking: boolean): void => this.addProjectProperty(EnumLabel.APN, blocking))
      .set(EnumType.UTILITY_COMPANY, (blocking: boolean): void =>
        this.addProjectProperty(EnumLabel.UTILITY_COMPANY, blocking)
      )
      .set(EnumType.ELECTRICAL_CODE, (blocking: boolean): void =>
        this.addProjectProperty(EnumLabel.ELECTRICAL_CODE, blocking)
      )
      .set(EnumType.MSP_LOCATION, (blocking: boolean): void =>
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.MSP_LOCATION,
            trigger: (): void => this.selectServiceEntranceEquipmentTool()
          },
          blocking
        )
      )
      .set(EnumType.MSP_EXPOSURE, (blocking: boolean): void => {
        if (this.mspLocationIsPresent()) {
          this.addMissingProperty(
            {
              iconName: '',
              label: EnumLabel.MSP_EXPOSURE,
              trigger: (): void => this.selectServiceEntranceEquipmentTool()
            },
            blocking
          );
        }
      })
      .set(EnumType.PARCEL_BOUNDARY, (blocking: boolean): void =>
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.PARCEL_BOUNDARY,
            trigger: (): void => this.selectParcelBoundary()
          },
          blocking
        )
      )
      .set(EnumType.MSP_BUSBAR_RATING, (blocking: boolean): void => {
        if (this.mspLocationIsPresent()) {
          this.addMissingProperty(
            {
              iconName: '',
              label: EnumLabel.MSP_BUSBAR_RATING,
              trigger: (): void => this.selectServiceEntranceEquipmentTool()
            },
            blocking
          );
        }
      })
      .set(EnumType.MSP_BUSBAR_CONNECTION_POINT, (blocking: boolean): void => {
        if (this.mspLocationIsPresent()) {
          this.addMissingProperty(
            {
              iconName: '',
              label: EnumLabel.MSP_BUSBAR_CONNECTION_POINT,
              trigger: (): void => this.selectServiceEntranceEquipmentTool()
            },
            blocking
          );
        }
      })
      .set(EnumType.MAIN_BREAKER_RATING, (blocking: boolean): void => {
        if (this.mspLocationIsPresent()) {
          this.addMissingProperty(
            {
              iconName: '',
              label: EnumLabel.MAIN_BREAKER_RATING,
              trigger: (): void => this.selectServiceEntranceEquipmentTool()
            },
            blocking
          );
        }
      })
      .set(EnumType.SUBPANEL_LOCATION, (blocking: boolean): void => {
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.SUBPANEL_LOCATION,
            trigger: (): void => this.selectSubpanelTool()
          },
          blocking
        );
      })
      .set(EnumType.SUBPANEL_EXPOSURE, (blocking: boolean): void => {
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.SUBPANEL_EXPOSURE,
            trigger: (): void => this.selectSubpanelTool()
          },
          blocking
        );
      })
      .set(EnumType.SUBPANEL_MAIN_BREAKER_RATING_OR_FEEDER_OCPD_RATING, (blocking: boolean): void => {
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.SUBPANEL_MAIN_BREAKER_RATING_OR_FEEDER_OCPD_RATING,
            trigger: (): void => this.selectSubpanelTool()
          },
          blocking
        );
      })
      .set(EnumType.SUBPANEL_BUSBAR_RATING, (blocking: boolean): void => {
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.SUBPANEL_BUSBAR_RATING,
            trigger: (): void => this.selectSubpanelTool()
          },
          blocking
        );
      })
      .set(EnumType.SUBPANEL_BUSBAR_CONNECTION_POINT, (blocking: boolean): void => {
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.SUBPANEL_BUSBAR_CONNECTION_POINT,
            trigger: (): void => this.selectSubpanelTool()
          },
          blocking
        );
      })
      .set(EnumType.ROOF_FACE, (blocking: boolean): void =>
        this.addMissingProperty(
          {
            iconName: EnumIcon.ROOF_FACE,
            label: EnumLabel.ROOF_FACE,
            trigger: (): void => {
              this.modal.closeModal();
              this.selectRoofFaceTool();
            }
          },
          blocking
        )
      )
      .set(EnumType.STREET_LOCATION, (blocking: boolean): void =>
        this.addMissingProperty(
          {
            iconName: '',
            label: EnumLabel.STREET_LOCATION,
            trigger: (): void => {
              this.modal.closeModal();
              this.selectStreetLocationTool();
            }
          },
          blocking
        )
      )
      .set(EnumType.LOW_TEMPERATURE, (blocking: boolean): void =>
        this.addEnvironmentalProperty(
          {
            iconName: EnumIcon.LOW_TEMPERATURE,
            label: EnumLabel.LOW_TEMPERATURE,
            trigger: (): void => {
              this.modal.closeModal();
              this.environmentalProps.showSiteTemperatureModal();
            }
          },
          blocking
        )
      )
      .set(EnumType.HIGH_TEMPERATURE, (blocking: boolean): void =>
        this.addEnvironmentalProperty(
          {
            iconName: EnumIcon.HIGH_TEMPERATURE,
            label: EnumLabel.HIGH_TEMPERATURE,
            trigger: (): void => {
              this.modal.closeModal();
              this.environmentalProps.showSiteTemperatureModal();
            }
          },
          blocking
        )
      )
      .set(EnumType.WIND_SPEED, (blocking: boolean): void =>
        this.addEnvironmentalProperty(
          {
            iconName: EnumIcon.WIND_SPEED,
            label: EnumLabel.WIND_SPEED,
            trigger: (): void => {
              this.modal.closeModal();
              this.environmentalProps.showWindSpeedModal();
            }
          },
          blocking
        )
      )
      .set(EnumType.WIND_EXPOSURE_CATEGORY, (blocking: boolean): void =>
        this.addEnvironmentalProperty(
          {
            iconName: EnumIcon.WIND_EXPOSURE_CATEGORY,
            label: EnumLabel.WIND_EXPOSURE_CATEGORY,
            trigger: (): void => {
              this.modal.closeModal();
              this.environmentalProps.showWindExposureModal();
            }
          },
          blocking
        )
      )
      .set(EnumType.GROUND_SNOW_LOAD, (blocking: boolean): void =>
        this.addEnvironmentalProperty(
          {
            iconName: EnumIcon.GROUND_SNOW_LOAD,
            label: EnumLabel.GROUND_SNOW_LOAD,
            trigger: (): void => {
              this.modal.closeModal();
              this.environmentalProps.showSiteGroundSnowModal();
            }
          },
          blocking
        )
      );
  }

  private createRoofFaceMap(roofFace: RoofFace): Map<string, ButtonMissingProperty> {
    return new Map<string, ButtonMissingProperty>()
      .set(EnumType.ROOF_SURFACE_TYPE, {
        iconName: EnumIcon.ROOF_SURFACE_TYPE,
        label: EnumLabel.ROOF_SURFACE_TYPE,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_SURFACE_TYPE, roofFace)
      })
      .set(EnumType.ROOF_FRAMING_SPACING, {
        iconName: EnumIcon.ROOF_FRAMING_SPACING,
        label: EnumLabel.ROOF_FRAMING_SPACING,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_FRAMING_SPACING, roofFace)
      })
      .set(EnumType.ROOF_FRAMING_DIMENSIONS, {
        iconName: EnumIcon.ROOF_FRAMING_DIMENSIONS,
        label: EnumLabel.ROOF_FRAMING_DIMENSIONS,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_FRAMING_DIMENSIONS, roofFace)
      })
      .set(EnumType.ROOF_DECKING_TYPE, {
        iconName: EnumIcon.ROOF_DECKING_TYPE,
        label: EnumLabel.ROOF_DECKING_TYPE,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_DECKING_TYPE, roofFace)
      })
      .set(EnumType.ROOF_SLOPE, {
        iconName: EnumIcon.ROOF_SLOPE,
        label: EnumLabel.ROOF_SLOPE,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_SLOPE, roofFace)
      })
      .set(EnumType.ROOF_AZIMUTH, {
        iconName: EnumIcon.ROOF_AZIMUTH,
        label: EnumLabel.ROOF_AZIMUTH,
        trigger: (): void => this.showPropertyEditor(EnumType.ROOF_AZIMUTH, roofFace)
      });
  }

  private mspLocationIsPresent(): boolean {
    return !this.missingPropertiesResponse.siteEquipment.mainServicePanel?.missingProperties.some(
      (missingProperty: IProjectMissingProperty): boolean => missingProperty.type === EnumType.MSP_LOCATION
    );
  }
}
