import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import { updateRoofFace3dValues } from '../../../utils/UpdateRoofface3DValuesHandler';
import type { IBaseHandlerDependencies } from '../IServiceBus';
import type { BaseRoofFaceViewModel } from '../../UiStore/Properties/RoofProperties/BaseRoofFaceViewModel';
import type { IHandler } from './IHandler';
import { clearRoofSurface } from './helpers/helpers';

export interface IRoofTypeChangedDependencies extends IBaseHandlerDependencies {
  readonly roofFace: RoofFace;
  readonly domainStore: DomainStore;
  readonly roofFacePropertyViewModelsRefs?: {
    [key: string]: BaseRoofFaceViewModel;
  };
}

export class RoofTypeChangedHandler implements IHandler {
  readonly name: string = 'roofType_changed';
  private readonly roofFace: RoofFace;
  private readonly domainStore: DomainStore;
  private readonly roofFacePropertyViewModelsRefs:
    | {
        [key: string]: BaseRoofFaceViewModel;
      }
    | undefined;

  constructor(dependencies: IRoofTypeChangedDependencies) {
    const {
      roofFace, domainStore, roofFacePropertyViewModelsRefs
    } = dependencies;
    this.roofFace = roofFace;
    this.domainStore = domainStore;
    this.roofFacePropertyViewModelsRefs = roofFacePropertyViewModelsRefs;
  }

  async execute(): Promise<void> {
    clearRoofSurface(this.domainStore, this.roofFace);

    await updateRoofFace3dValues(this.roofFace, this.domainStore);
    this.domainStore.addOrUpdateRoofFace(this.roofFace);
    this.roofFace.redraw();
  }
}
