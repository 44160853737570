import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';

type InputProps = {
  height?: string;
  width?: string;
  extra?: boolean | undefined;
};

type ArrowProps = {
  direction: 'up' | 'down';
};

export const Label = styled.label`
  font-family: ${(props: PropsTheme): string => props.theme.fonts.sans};
  font-size: 10px;
  line-height: 1;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const Container = styled.div<InputProps>`
  display: flex;
  align-items: center;
  margin-top: 4px;
  border: ${({ extra }: InputProps): string => {
    return extra ? `1px solid #ae0318` : `1px solid #d9d9d9`;
  }};
  border-radius: 4px;
  background-color: ${({ extra }: InputProps): string => {
     return extra ? `rgba(174, 3, 24, 0.07)` : `#ffffff`;
  }};
`;

export const InputWrapper = styled.div`
  flex: 1;
  padding: 4px 8px;
`;

export const Input = styled.input<InputProps>`
  display: block;
  width: ${({ width }: InputProps): string => (!!width ? width : '100%')};
  ${({ height }: InputProps): string => (!!height ? `height: ${height};` : '')}
  min-width: 0px;
  border: none;
  background-color: ${({ extra }: InputProps): string => extra ? `rgba(174, 3, 24, 0)` : `#ffffff`};
  outline: none;
  font-family: ${(props: PropsTheme): string => props.theme.fonts.sans};
  font-size: 10px;
  line-height: 1;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const MeasureText = styled.span`
  font-size: 10px;
  line-height: 1;
  margin-right: 8px;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey3};
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const ArrowWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.tertiary};
  padding: 3px;

  &:first-child {
    border-bottom: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.tertiary};
  }
`;

export const Arrow = styled.div<ArrowProps & PropsTheme>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  ${({ direction, theme }: ArrowProps & PropsTheme): string =>
    `border-${direction === 'down' ? 'top' : 'bottom'}: 4px solid ${theme.colors.grayColor2};`
  }
`;
