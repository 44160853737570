import type { FormFieldValue } from '../FormOptionsRulesAndState';

/**
 * Throughout the schema specification `null` values are represented as `'NULL'` strings.
 * We need to replace those strings with the `null` value when interpreting the schema.
 */
const encodeValue = (value: FormFieldValue): FormFieldValue | null => {
  return value !== 'NULL' ? value : null;
};

export default encodeValue;
