import type { MeshBasicMaterial } from 'three';
import { Mesh } from 'three';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import type { AnyConstructor } from '../../utils/Mixin';
import type { Drawable } from './Drawable';

export const Labelable = <T extends AnyConstructor<Drawable>>(LabelableBase: T) => {
  abstract class MixinClass extends LabelableBase {
    text: Mesh = new Mesh();

    constructor(...args: any[]) {
      super(args);

      // @ts-ignore
      this.text.type = 'label';

      // Add as a child of the current object
      this.mesh.add(this.text);
    }

    /**
     * Abstract method to load the font in order to display the text
     * @returns the font
     */
    abstract loadFont(): Font;

    setTextMaterial(material: MeshBasicMaterial): void {
      this.text.material = material;
    }
  }
  return MixinClass;
};
