import type { SupplementalData } from '../../../domain/models/SupplementalData/SupplementalData';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateDesignSupplementalDataDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly updatedSupplementalData: SupplementalData;
}

export class UpdateDesignSupplementalDataCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly updatedSupplementalData: SupplementalData;

  constructor(dependencies: IUpdateDesignSupplementalDataDependencies) {
    super();
    this.domain = dependencies.domain;
    this.updatedSupplementalData = dependencies.updatedSupplementalData;
  }

  async execute(): Promise<void> {
    this.domain.design.supplementalData = this.updatedSupplementalData;
  }

  override async reExecute(): Promise<void> {
    return this.execute();
  }
}
