import {
  action, observable
} from 'mobx';

export interface ITitleBlockData {
  readonly propertyDescriptions: string[];
  readonly showContractorLogo: boolean;
  readonly showContractorInfo: boolean;
  readonly showEngineerOfRecordInfo?: boolean;
}

export default class TitleBlock {
  @observable
  propertyDescriptions: string[];

  @observable
  showContractorLogo: boolean;

  @observable
  showContractorInfo: boolean;

  @observable
  showEngineerOfRecordInfo: boolean;

  constructor(data: ITitleBlockData) {
    this.propertyDescriptions = data.propertyDescriptions;
    this.showContractorInfo = data.showContractorInfo;
    this.showContractorLogo = data.showContractorLogo;
    this.showEngineerOfRecordInfo = data.showEngineerOfRecordInfo ?? false;
  }

  @action.bound
  setShowLogo(value: boolean): void {
    this.showContractorLogo = value;
  }

  @action.bound
  setShowInfo(value: boolean): void {
    this.showContractorInfo = value;
  }

  @action.bound
  setShowEngineerOfRecordInfo(value: boolean): void {
    this.showEngineerOfRecordInfo = value;
  }

  @action.bound
  setPropertyDescriptions(propertyDescriptions: string[]): void {
    this.propertyDescriptions = propertyDescriptions;
  }

  toData(): ITitleBlockData {
    return {
      propertyDescriptions: this.propertyDescriptions.length > 0 ? this.propertyDescriptions : ['NAME_OF_RESIDENCE'],
      showContractorInfo: this.showContractorInfo,
      showContractorLogo: this.showContractorLogo,
      showEngineerOfRecordInfo: this.showEngineerOfRecordInfo
    };
  }
}
