import type { Color } from '@aurorasolar/lyra-ui-kit';
import type { Object3D } from 'three';
import {
  MeshBasicMaterial, Vector2
} from 'three';
import EWorkspace from '../../../domain/typings/EWorkspace';

import { Drawable } from '../../mixins/Drawable';
import { Hoverable } from '../../mixins/Hoverable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { WorkspaceTagged } from '../../mixins/WorkspaceTagged';
import type { Vector3D } from '../../typings';
import { SceneObjectType } from '../Constants';

interface ISetbackParams {
  serverId: string;
  setbackVertices: Vector3D[];
}

const SETBACK_THICKNESS = 1;

const MixedClass = PolygonDrawable(Hoverable(Selectable(WorkspaceTagged(Drawable(class SimpleClass {})))));

class VentilationSetback extends MixedClass {
  readonly tag: EWorkspace = EWorkspace.DESIGN;
  override readonly type: string = SceneObjectType.Setback;
  readonly selectWithParent: boolean = false;
  readonly propertyId: string = SceneObjectType.Setback;
  override color: Color = '#a92329';
  /** Layer Number */
  layerNumber: number = 4;

  constructor({
    setbackVertices, serverId
  }: ISetbackParams) {
    super();
    this.serverId = serverId;
    this.boundary.defaultThickness = SETBACK_THICKNESS;
    this.changeMeshMaterial(this.color);

    const verticesData = setbackVertices.map(
      (currentVertice: Vector3D): Vector3D => ({
        ...currentVertice,
        z: currentVertice.z + 1
      })
    );
    this.addVertices(verticesData);
    this.redraw();
  }

  get segmentsPointPairs(): Vector2[][] {
    const pairs = [];
    const allVertices = this.getVector3s();
    for (let index = 1; index < allVertices.length; index++) {
      pairs.push([
        new Vector2(allVertices[index - 1].x, allVertices[index - 1].y),
        new Vector2(allVertices[index].x, allVertices[index].y)
      ]);
    }
    return pairs;
  }

  hasFill(): boolean {
    return true;
  }

  onClose(): void {
    /** */
  }

  showFill(): boolean {
    return true;
  }

  showLines(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return false;
  }

  showVertices(): boolean {
    return this.selected;
  }

  changeMeshMaterial(color: Color): void {
    this.mesh.material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.5
    });
    this.color = color;
    this.redraw();
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }
}

export default VentilationSetback;
