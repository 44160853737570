import React, { BaseProps, CSSProperties, ReactElement } from 'react';
import { Icon } from '../Icon/Icon';
import { TagText, TagWrapper } from './styles';

type ITagProps = BaseProps & {
  type: string;
  children?: ReactElement | string;
  style?: CSSProperties;
  onClick?: (event: MouseEvent) => void;
  iconName?: string;
};

const Tag = ({className, children, type, style, iconName}: ITagProps): ReactElement => (
  <TagWrapper className={className} type={type} style={style}>
    <TagText style={style}>
      {children}
    </TagText>
    {iconName &&
      <Icon name={iconName} style={{ width: 10, height: 9.2, marginLeft: 7 }}/>
    }
  </TagWrapper>
);

export { Tag };
