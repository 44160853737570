import type {
  FC, ReactElement
} from 'react';
import * as React from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type Color from '../../../../../domain/typings/Color';

type WrapperProps = PropsTheme & {
  onClick?: () => void;
};

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.black};
  border: 2px solid ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props: WrapperProps): string => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

type MarkerProps = {
  onClick?: () => void;
};

const Marker: FC<MarkerProps> = (props: MarkerProps): ReactElement => (
  <Wrapper
    {...(props.onClick
      ? {
        onClick: props.onClick
      }
      : {})}
  />
);

export default Marker;
