import type {
  BaseProps, ReactElement
} from 'react';
import React from 'react';
import {
  GalleryWrapper,
  GalleryItem,
  GalleryItemImage,
  GalleryItemTitle,
  GalleryItemDescription,
  CheckIcon,
  UncheckIcon
} from './Gallery.styles';

export type GalleryItemProps = {
  id: string;
  image: string;
  name: string;
  description: string;
  selected: boolean;
};

type GalleryProps = BaseProps & {
  items: GalleryItemProps[];
  onItemCheck?: (id: string) => void;
};

function Gallery({
  className, items, onItemCheck
}: GalleryProps): ReactElement {
  return (
    <GalleryWrapper className={className}>
      {items.map(
        (item: GalleryItemProps, index: number): ReactElement => (
          <GalleryItem key={index} sm={6} lg={4} onClick={(): void => onItemCheck?.(item.id)}>
            {item.selected ? <CheckIcon /> : <UncheckIcon />}
            <GalleryItemImage src={item.image} selected={item.selected} />
            <GalleryItemTitle>{item.name}</GalleryItemTitle>
            <GalleryItemDescription>{item.description}</GalleryItemDescription>
          </GalleryItem>
        )
      )}
    </GalleryWrapper>
  );
}

export default Gallery;
