import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, ReactNode
} from 'react';
import React, { useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import type {
  InverterSelectionViewModel,
  InverterType
} from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import useStore from '../../../../stores/useStore';
import {
  getFormattedText, getTitle
} from '../../../../utils/parsing';
import InverterCard from '../../../components/InverterSelectOption';
import { OptionStyle } from '../../../components/List/styles';
import type {
  SelectedInverter, IPill
} from './types';
import ArrowIcon from './ArrowIcon';
import {
  InverterListWrapper, InverterView
} from './styles';

const { TextField } = LyraFormElementsDesignTool.FormElementsDesignTool;

function InverterPanel(props: { inverter: SelectedInverter }): ReactElement {
  const {
    inverter: {
      name, definitionId, options, index
    }
  } = props;
  const { modal } = useStore();
  const inverterSelectionViewModel = modal.viewModel as InverterSelectionViewModel;
  const { loading } = inverterSelectionViewModel;

  const onChange = useCallback(
    (inputValue: string): void => {
      if (index === 1) {
        inverterSelectionViewModel.changeFirstInverterName(inputValue);
      }
      if (index === 2) {
        inverterSelectionViewModel.changeSecondInverterName(inputValue);
      }

      inverterSelectionViewModel.setHoveredInverter();
    },
    [inverterSelectionViewModel, index]
  );

  const onInverterSelected = useCallback(
    (value: string, id: string): void => {
      if (index === 1) {
        inverterSelectionViewModel.setFirstInverter(value, id);
      }
      if (index === 2) {
        inverterSelectionViewModel.setSecondInverter(value, id);
      }

      inverterSelectionViewModel.setHoveredInverter();
    },
    [inverterSelectionViewModel, index]
  );

  const onInverterHovered = useCallback(
    (item: InverterType): void => {
      inverterSelectionViewModel.setHoveredInverter({
        item,
        kind: index === 1 ? 'first' : 'second'
      });
    },
    [inverterSelectionViewModel, index]
  );

  const mapPillsToReactElement = (pills: IPill[]): ReactElement[] => {
    return pills.map((pill: IPill): ReactElement => ReactHtmlParser(getFormattedText(pill.value))[0]);
  };

  const currentInvertersResult = (): ReactNode => {
    //Showing options when an inverter is not selected
    if (definitionId) {
      return <></>;
    }
    if (loading) {
      return <OptionStyle>Loading...</OptionStyle>;
    }
    if (name && options.length === 0) {
      return <OptionStyle>No inverter found</OptionStyle>;
    }

    return options.map(
      (item: InverterType, index: number): ReactElement => (
        <InverterCard
          inverterId={item.definitionId}
          value={getTitle(item.name)}
          title={ReactHtmlParser(getFormattedText(item.name))}
          pills={mapPillsToReactElement(item.tags)}
          item={item}
          key={index}
          onSelected={onInverterSelected}
          onMouseOver={onInverterHovered}
        />
      )
    );
  };

  return (
    <InverterView>
      <LyraTypography.Heading fontSize="12px" margin="10px 0 4px">
        INVERTER
      </LyraTypography.Heading>
      <TextField.TextField
        name="inverters_search"
        placeholder="— Type Inverter Model or Manufacturer —"
        onChange={onChange}
        value={name}
        result={options.length}
        inlineIcon={<ArrowIcon inverterName={name || ''} onClick={onChange} />}
      />
      <InverterListWrapper>{currentInvertersResult()}</InverterListWrapper>
    </InverterView>
  );
}

export default observer(InverterPanel);
