import { observer } from 'mobx-react-lite';
import React, {
  useCallback, useContext, useEffect, type ReactElement
} from 'react';
import { ThemeContext } from 'styled-components';
import { useUiStore } from '../../../../../stores/useStore';
import type { PlanSetPreviewPageViewModel } from './PlanSetPreviewPageViewModel';

export const PlanSetPreviewPage = observer((): ReactElement => {
  const DS = useContext(ThemeContext)!.DS;
  const { pages } = useUiStore();
  const viewModel = pages.pageViewModel as PlanSetPreviewPageViewModel;

  const handleWindowResize = useCallback((): void => {
    const SolarLayoutElement = document.getElementsByName('content')[0].parentElement!;
    viewModel?.resizeEmbed(
      // Unlike PagesWrapper and LyraLayout.Content,
      // LyraLayout.SolarLayout component changes its size when shrinking the window
      // 45 is the height of the header, so if you change the size of the header,
      // you'll have to change this number, as well
      SolarLayoutElement.clientWidth,
      SolarLayoutElement.clientHeight - 45
    );
  }, [viewModel]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  if (!viewModel) {
    return <></>;
  }

  return (
    <>
      <object
        type="application/pdf"
        data={viewModel.fileURL}
        width={viewModel.embedDimensions.width}
        height={viewModel.embedDimensions.height}
      >
        Looks like the embed doesn't work.{' '}
        <a href={viewModel.fileURL} target="_blank">
          Click here
        </a>{' '}
        to open the file manually
      </object>
    </>
  );
});
