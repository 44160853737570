import {
  action, computed, observable
} from 'mobx';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type { Building } from '../../../../../domain/models/SiteDesign/Building';
import {
  KeyboardBehaviour, type IKeyboardBehaviourHandler
} from '../../../../../domain/behaviour/KeyboardBehaviour';
import { ROOF_COLOR_LEVEL_RANGE } from '../../../../../domain/models/RoofColorLevelRange';
import type {
  Color, Dictionary
} from '../../../../../domain/typings';
import type { IDeleteEmptyBuildingsDependencies } from '../../../../ServiceBus/Commands/DeleteEmptyBuildingsCommand';
import type { TraceToolBase } from '../../../ToolbarStore/Project/TraceToolBase';
import type { IBaseStepViewModelDependencies } from '../BaseStepViewModel';
import { BaseStepViewModel } from '../BaseStepViewModel';

interface INewRoofFaceStepViewModel extends IBaseStepViewModelDependencies {
  readonly traceTool: TraceToolBase;
}

export class NewRoofFaceViewModel extends BaseStepViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI = 'new_roof_face_modal';

  private readonly defaultRoofFacename = this.domain.calculatedRoofFaceName;
  private readonly DEFAULT_ICON_COLOR = '#9e9e9e';
  private readonly DEFAULT_LEVEL_RANGE: Dictionary<Color> = {
    1: this.DEFAULT_ICON_COLOR,
    1.5: this.DEFAULT_ICON_COLOR,
    2: this.DEFAULT_ICON_COLOR,
    2.5: this.DEFAULT_ICON_COLOR,
    3: this.DEFAULT_ICON_COLOR,
    3.5: this.DEFAULT_ICON_COLOR,
    4: this.DEFAULT_ICON_COLOR
  };

  @observable
  levelRange: Dictionary<Color> = this.DEFAULT_LEVEL_RANGE;

  @observable
  name: string = this.defaultRoofFacename;

  @observable
  color: Color = ROOF_COLOR_LEVEL_RANGE[1];

  @observable
  level: number = 1;

  @observable
  building: Building | undefined;

  @observable
  repeatedName: boolean = false;

  @observable
  disableBack: boolean = false;

  private readonly traceTool: TraceToolBase;

  constructor(dependencies: INewRoofFaceStepViewModel) {
    super(dependencies);
    this.traceTool = dependencies.traceTool;
    this.levelRange[this.level] = this.color;
    this.getBuilding();
    this.validateRoofFaceName();
  }

  @computed
  get disableSave(): boolean {
    return !this.name || !this.color || !this.level || !this.building || this.repeatedName;
  }

  @action.bound
  getBuilding(): void {
    this.building = this.domain.currentBuilding;
    this.validateRoofFaceName();
  }

  @action.bound
  changeLevel(level: number): void {
    this.level = level;
    this.setColorRoofButton();
  }

  @action.bound
  setName(name: string): void {
    this.name = name.trim();
    this.validateRoofFaceName();
  }

  @action.bound
  validateRoofFaceName(): void {
    const roofFaceWithName = this.domain.buildingWithName(this.building?.name)?.roofFaceWithName(this.name);
    this.repeatedName = !!roofFaceWithName;
  }

  @action.bound
  setColor(color: Color): void {
    this.color = color;
  }

  @action.bound
  setBuilding(buildingId: string): void {
    const buildingSelected = this.domain.buildings.find((building: Building): boolean => building.id === buildingId);
    if (buildingSelected) {
      this.building = buildingSelected;
    }
  }

  goBack(): void {
    const prevStep = this.wizard.prevStep;
    this.deleteEmptyBuildings();
    this.dispose();
    if (prevStep) {
      this.changeStep(prevStep);
    } else {
      this.changeStep(2);
    }
  }

  closeModal(): void {
    this.traceTool.removeWipPolygon();
    this.deleteEmptyBuildings();
    this.closeSteps();
    this.dispose();
  }

  saveRoofFace(): void {
    this.wizard.clear();
    this.traceTool.finishWipPolygon({
      name: this.name,
      color: this.color,
      building: this.building,
      level: this.level
    });
    this.resetViewModel();
    this.dispose();
  }

  dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (!this.disableSave && this.wizard.currentViewModel.propCodeUI === this.propCodeUI) {
      if (event.key === KeyboardListener.KEY_ENTER) {
        this.saveRoofFace();
      }
    }
  };

  enableBehaviors = (): void => {
    KeyboardBehaviour.addKeyboardEvents(this);
  };

  private deleteEmptyBuildings(): void {
    const commandDependencies: IDeleteEmptyBuildingsDependencies = {
      domain: this.domain
    };
    this.serviceBus.send('delete_empty_buildings', commandDependencies);
  }

  private resetViewModel(): void {
    this.name = this.defaultRoofFacename;
    this.color = ROOF_COLOR_LEVEL_RANGE[this.level];
    this.levelRange = this.DEFAULT_LEVEL_RANGE;
    this.level = 1;
    this.deleteEmptyBuildings();
  }

  private setColorRoofButton(): void {
    this.levelRange = this.DEFAULT_LEVEL_RANGE;
    this.setColor(ROOF_COLOR_LEVEL_RANGE[this.level]);
    this.levelRange[this.level] = this.color;
  }
}
