import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';

export const roofTypeKeyToAttribute: { [key: string]: IOption<BaseAttributes> } = {
  RAFTERS: {
    id: '1',
    optionType: 'ROOF_CONSTRUCTION_TYPE',
    attributes: {
      value: 'RAFTERS',
      name: 'Rafters',
      abbreviatedName: 'Rafters'
    }
  },
  MANUFACTURED_TRUSS: {
    id: '2',
    optionType: 'ROOF_CONSTRUCTION_TYPE',
    attributes: {
      value: 'MANUFACTURED_TRUSS',
      name: 'Manufactured Truss',
      abbreviatedName: 'Manufactured Truss'
    }
  }
};

export const SAMPLE_YEAR_BEFORE_1960 = 1950;
export const SAMPLE_YEAR_AFTER_1960 = 1970;

export const crossSectionKeyToAttribute: { [key: string]: IOption<BaseAttributes> } = {
  L2X4_POST1960: {
    id: '1',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L2X4_POST1960',
      name: '2x4',
      abbreviatedName: '2x4'
    }
  },
  L2X6_POST1960: {
    id: '2',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L2X6_POST1960',
      name: '2x6',
      abbreviatedName: '2x6'
    }
  },
  L2X8_POST1960: {
    id: '3',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L2X8_POST1960',
      name: '2x8',
      abbreviatedName: '2x8'
    }
  },
  L2X10_POST1960: {
    id: '4',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L2X10_POST1960',
      name: '2x10',
      abbreviatedName: '2x10'
    }
  },
  L2X12_POST1960: {
    id: '5',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L2X12_POST1960',
      name: '2x12',
      abbreviatedName: '2x12'
    }
  },
  L4X6_POST1960: {
    id: '6',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L4X6_POST1960',
      name: '4x6',
      abbreviatedName: '4x6'
    }
  },
  L4X8_POST1960: {
    id: '7',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L4X8_POST1960',
      name: '4x8',
      abbreviatedName: '4x8'
    }
  },
  L4X10_POST1960: {
    id: '8',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L4X10_POST1960',
      name: '4x10',
      abbreviatedName: '4x10'
    }
  },
  L4X12_POST1960: {
    id: '9',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_AFTER_1960',
    attributes: {
      value: 'L4X12_POST1960',
      name: '4x12',
      abbreviatedName: '4x12'
    }
  },

  L2X4_PRE1960: {
    id: '11',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L2X4_PRE1960',
      name: '2x4',
      abbreviatedName: '2x4'
    }
  },
  L2X6_PRE1960: {
    id: '12',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L2X6_PRE1960',
      name: '2x6',
      abbreviatedName: '2x6'
    }
  },
  L2X8_PRE1960: {
    id: '13',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L2X8_PRE1960',
      name: '2x8',
      abbreviatedName: '2x8'
    }
  },
  L2X10_PRE1960: {
    id: '14',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L2X10_PRE1960',
      name: '2x10',
      abbreviatedName: '2x10'
    }
  },
  L2X12_PRE1960: {
    id: '15',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L2X12_PRE1960',
      name: '2x12',
      abbreviatedName: '2x12'
    }
  },
  L4X6_PRE1960: {
    id: '16',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L4X6_PRE1960',
      name: '4x6',
      abbreviatedName: '4x6'
    }
  },
  L4X8_PRE1960: {
    id: '17',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L4X8_PRE1960',
      name: '4x8',
      abbreviatedName: '4x8'
    }
  },
  L4X10_PRE1960: {
    id: '18',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L4X10_PRE1960',
      name: '4x10',
      abbreviatedName: '4x10'
    }
  },
  L4X12_PRE1960: {
    id: '19',
    optionType: 'DIMENSION_LUMBER_CROSS_SECTIONS_DURING_OR_BEFORE_1960',
    attributes: {
      value: 'L4X12_PRE1960',
      name: '4x12',
      abbreviatedName: '4x12'
    }
  }
};

export const surfaceKeyToLabelMap: { [key: string]: string } = {
  COMPOSITION_SHINGLE: 'Composition Shingle / Regular',
  HIGH_DEFINITION_COMP_SHINGLE: 'Composition Shingle / High-Definition',
  COMPOSITION_ROLL: 'Composition Roll',
  TILE_CONCRETE_FLAT: 'Tile / Flat',
  TILE_CONCRETE_SPANISH_BARREL_S_STYLE: 'Tile / Spanish Barrel (S-Shape)',
  TILE_CONCRETE_SPANISH_BARREL_W_STYLE: 'Tile / Spanish Barrel (W-Shape)',
  TILE_OTHER: 'Tile / Other',
  SLATE: 'Slate',
  WOOD_SHAKE: 'Wood Shake',
  METAL_SHINGLES: 'Metal Shingles',
  SINGLE_PLY_MEMBRANE: 'Single-Ply Membrane',
  TAR_GRAVEL: 'Tar & Gravel',
  STANDING_SEAM_METAL_12IN: 'Standing Seam Metal / 12 inch spacing OC',
  STANDING_SEAM_METAL_16IN: 'Standing Seam Metal / 16 inch spacing OC',
  STANDING_SEAM_METAL_18IN: 'Standing Seam Metal / 18 inch spacing OC',
  STANDING_SEAM_METAL_24IN: 'Standing Seam Metal / 24 inch spacing OC',
  TRAPEZOIDAL_METAL_12IN: 'Trapezoidal Metal / 1-4 inch spacing',
  TRAPEZOIDAL_METAL_16IN: 'Trapezoidal Metal / 5-7 inch spacing OC',
  TRAPEZOIDAL_METAL_18IN: 'Trapezoidal Metal / 8-10 inch spacing OC',
  TRAPEZOIDAL_METAL_24IN: 'Trapezoidal Metal / 11-13 inch spacing OC',
  CORRUGATED: 'Corrugated Metal',
  FOAM: 'Foam'
};

export const surfaceMaterialToParentSurfaceKey: { [key: string]: string } = {
  COMPOSITION_SHINGLE: 'Composition Shingle',
  HIGH_DEFINITION_COMP_SHINGLE: 'Composition Shingle',
  TILE_CONCRETE_FLAT: 'Tile',
  TILE_CONCRETE_SPANISH_BARREL_S_STYLE: 'Tile',
  TILE_CONCRETE_SPANISH_BARREL_W_STYLE: 'Tile',
  TILE_OTHER: 'Tile',
  STANDING_SEAM_METAL_12IN: 'Standing Seam Metal',
  STANDING_SEAM_METAL_16IN: 'Standing Seam Metal',
  STANDING_SEAM_METAL_18IN: 'Standing Seam Metal',
  STANDING_SEAM_METAL_24IN: 'Standing Seam Metal',
  TRAPEZOIDAL_METAL_12IN: 'Trapezoidal Metal',
  TRAPEZOIDAL_METAL_16IN: 'Trapezoidal Metal',
  TRAPEZOIDAL_METAL_18IN: 'Trapezoidal Metal',
  TRAPEZOIDAL_METAL_24IN: 'Trapezoidal Metal'
};

export const surfaceKeyToSurfaceMaterial: { [key: string]: string } = {
  COMPOSITION_SHINGLE: 'COMPOSITION_SHINGLE',
  HIGH_DEFINITION_COMP_SHINGLE: 'HIGH_DEFINITION_COMP_SHINGLE',
  TILE_CONCRETE_FLAT: 'TILE_CONCRETE_FLAT',
  TILE_CONCRETE_SPANISH_BARREL_S_STYLE: 'TILE_CONCRETE_SPANISH_BARREL_S_STYLE',
  TILE_CONCRETE_SPANISH_BARREL_W_STYLE: 'TILE_CONCRETE_SPANISH_BARREL_W_STYLE',
  TILE_OTHER: 'TILE_OTHER',
  STANDING_SEAM_METAL_12IN: 'STANDING_SEAM_METAL_12IN',
  STANDING_SEAM_METAL_16IN: 'STANDING_SEAM_METAL_16IN',
  STANDING_SEAM_METAL_18IN: 'STANDING_SEAM_METAL_18IN',
  STANDING_SEAM_METAL_24IN: 'STANDING_SEAM_METAL_24IN',
  TRAPEZOIDAL_METAL_12IN: 'TRAPEZOIDAL_METAL_12IN',
  TRAPEZOIDAL_METAL_16IN: 'TRAPEZOIDAL_METAL_16IN',
  TRAPEZOIDAL_METAL_18IN: 'TRAPEZOIDAL_METAL_18IN',
  TRAPEZOIDAL_METAL_24IN: 'TRAPEZOIDAL_METAL_24IN'
};

export const surfaceKeyToSurfaceType: { [key: string]: string } = {
  COMPOSITION_SHINGLE: 'COMPOSITION_SHINGLE',
  HIGH_DEFINITION_COMP_SHINGLE: 'COMPOSITION_SHINGLE',
  COMPOSITION_ROLL: 'COMPOSITION_ROLL',
  TILE_CONCRETE_FLAT: 'TILE_CONCRETE_FLAT',
  TILE_CONCRETE_SPANISH_BARREL_S_STYLE: 'TILE_CONCRETE_FLAT',
  TILE_CONCRETE_SPANISH_BARREL_W_STYLE: 'TILE_CONCRETE_FLAT',
  TILE_OTHER: 'TILE_CONCRETE_FLAT',
  SLATE: 'SLATE',
  WOOD_SHAKE: 'WOOD_SHAKE',
  METAL_SHINGLES: 'METAL_SHINGLES',
  SINGLE_PLY_MEMBRANE: 'SINGLE_PLY_MEMBRANE',
  TAR_GRAVEL: 'TAR_GRAVEL',
  STANDING_SEAM_METAL_12IN: 'STANDING_SEAM_METAL_12IN',
  STANDING_SEAM_METAL_16IN: 'STANDING_SEAM_METAL_12IN',
  STANDING_SEAM_METAL_18IN: 'STANDING_SEAM_METAL_12IN',
  STANDING_SEAM_METAL_24IN: 'STANDING_SEAM_METAL_12IN',
  TRAPEZOIDAL_METAL_12IN: 'TRAPEZOIDAL_METAL_12IN',
  TRAPEZOIDAL_METAL_16IN: 'TRAPEZOIDAL_METAL_12IN',
  TRAPEZOIDAL_METAL_18IN: 'TRAPEZOIDAL_METAL_12IN',
  TRAPEZOIDAL_METAL_24IN: 'TRAPEZOIDAL_METAL_12IN',
  CORRUGATED: 'CORRUGATED',
  FOAM: 'FOAM'
};

export const deckingKeyToOption: { [key: string]: IOption<BaseAttributes> } = {
  PLYWOOD_11_32_INCH_THICK: {
    id: '1',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'PLYWOOD_11_32_INCH_THICK',
      name: '11/32\' (3/8\' nom.) plywood',
      abbreviatedName: '11/32\' (3/8\' nom.) plywood'
    }
  },
  PLYWOOD_15_32_INCH_THICK: {
    id: '2',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'PLYWOOD_15_32_INCH_THICK',
      name: '15/32\' (1/2\' nom.) plywood',
      abbreviatedName: '15/32\' (1/2\' nom.) plywood'
    }
  },
  PLYWOOD_19_32_INCH_THICK: {
    id: '3',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'PLYWOOD_19_32_INCH_THICK',
      name: '19/32\' (5/8\' nom.) plywood',
      abbreviatedName: '19/32\' (5/8\' nom.) plywood'
    }
  },
  PLYWOOD_23_32_INCH_OR_THICKER: {
    id: '4',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'PLYWOOD_23_32_INCH_OR_THICKER',
      name: '23/32\' (3/4\' nom.) plywood',
      abbreviatedName: '23/32\' (3/4\' nom.) plywood'
    }
  },
  ORIENTED_STRAND_BOARD_11_32_INCH_THICK: {
    id: '5',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'ORIENTED_STRAND_BOARD_11_32_INCH_THICK',
      name: '11/32\' OSB',
      abbreviatedName: '11/32\' OSB'
    }
  },
  ORIENTED_STRAND_BOARD_7_16_INCH_THICK: {
    id: '6',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'ORIENTED_STRAND_BOARD_7_16_INCH_THICK',
      name: '7/16\' OSB',
      abbreviatedName: '7/16\' OSB'
    }
  },
  ORIENTED_STRAND_BOARD_15_32_INCH_THICK: {
    id: '7',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'ORIENTED_STRAND_BOARD_15_32_INCH_THICK',
      name: '15/32\' OSB',
      abbreviatedName: '15/32\' OSB'
    }
  },
  ORIENTED_STRAND_BOARD_19_32_INCH_THICK: {
    id: '8',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'ORIENTED_STRAND_BOARD_19_32_INCH_THICK',
      name: '19/32\' OSB',
      abbreviatedName: '19/32\' OSB'
    }
  },
  ORIENTED_STRAND_BOARD_23_32_INCH_OR_THICKER: {
    id: '9',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'ORIENTED_STRAND_BOARD_23_32_INCH_OR_THICKER',
      name: '23/32\' OSB',
      abbreviatedName: '23/32\' OSB'
    }
  },
  SKIP_SHEATHING_1X: {
    id: '10',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'SKIP_SHEATHING_1X',
      name: '1X skip sheathing',
      abbreviatedName: '1X skip sheathing'
    }
  },
  TONGUE_AND_GROOVE: {
    id: '11',
    optionType: 'DECK_SHEATHING_TYPE',
    attributes: {
      value: 'TONGUE_AND_GROOVE',
      name: 'Tongue and groove',
      abbreviatedName: 'Tongue and groove'
    }
  }
};
