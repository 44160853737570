import React from 'react';
import { observer } from 'mobx-react-lite';
import useStore from '../../../../../stores/useStore';
import type { PermitPackageViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import DocumentsSection from './DocumentsSection/DocumentsSection';
import SiteImagesSection from './SiteImagesSection/SiteImagesSection';

export interface ISectionProps {
  viewModel: PermitPackageViewModel;
}

function ImagesAndDocuments() {
  const { modal } = useStore();
  const viewModel = modal.viewModel as PermitPackageViewModel;

  return (
    <>
      <SiteImagesSection viewModel={viewModel} />
      <DocumentsSection viewModel={viewModel} />
    </>
  );
}

export default observer(ImagesAndDocuments);
