import isNil from 'lodash/isNil';
import {
  action, computed, observable, runInAction
} from 'mobx';
import { PvSystem } from '../PvSystem/PvSystem';
import { RoofTopArrayAreas } from '../RoofTopArray/RoofTopArrayAreas';
import { SupplementalData } from '../SupplementalData/SupplementalData';
import type { IRoofTopArrayAreasData } from '../RoofTopArray/RoofTopArrayAreas';
import type { IPvSystemData } from '../PvSystem/PvSystem';
import type { ISupplementalData } from '../SupplementalData/SupplementalData';
import { DesignState } from './DesignState';
import type { IDesignStateData } from './DesignState';
import {
  DesignSpecification, type IDesignSpecificationData
} from './DesignSpecification';

export interface IDesignData {
  readonly id: string;
  readonly projectId: string;
  readonly designSpecification: IDesignSpecificationData;
  readonly roofTopArrayAreas?: IRoofTopArrayAreasData;
  readonly system: IPvSystemData;
  readonly state: IDesignStateData;
  readonly supplementalData?: ISupplementalData;
}

export class Design {
  @observable
  id: string;
  @observable
  projectId: string;
  @observable
  designSpecification: DesignSpecification;
  @observable
  system: PvSystem;
  @observable
  state: DesignState;
  @observable
  roofTopArrayAreas: RoofTopArrayAreas;
  @observable
  supplementalData: SupplementalData;

  constructor(design: IDesignData) {
    this.id = design.id;
    this.projectId = design.projectId;
    this.designSpecification = new DesignSpecification(design.designSpecification);
    this.roofTopArrayAreas = new RoofTopArrayAreas(design.roofTopArrayAreas);
    this.state = new DesignState(design.state);
    this.supplementalData = new SupplementalData(design.supplementalData);
    this.system = new PvSystem(design.system);
  }

  toData(): IDesignData {
    return {
      id: this.id,
      projectId: this.projectId,
      state: this.state.toData(),
      designSpecification: this.designSpecification.toData(),
      roofTopArrayAreas: this.roofTopArrayAreas.toData(),
      system: this.system.toData(),
      supplementalData: this.supplementalData.toData()
    };
  }

  @action
  deletePvModulePositions = (positionIds: string[]): void => {
    if (!positionIds.length) {
      return;
    }
    this.system.deletePvModulesInPositions(positionIds);
    this.roofTopArrayAreas.deletePvModulePositions(positionIds);
  };

  @action
  updateSupplementalDataIfNeeded = async (): Promise<void> => {
    if (!this.supplementalData.pvModuleInfo?.dimensions) {
      // In some cases the addition of PV module data may not have been persisted
      // This makes sure the data is retrieved and persisted
      await this.supplementalData.updatePvModuleInfo(this.system.equipment.pvModules.definition);
    }
    const inverters = this.system.equipment.inverters;
    const inverterDefinitionsIds = inverters?.supplementalDataInverterDefinitions;
    if (inverters && inverterDefinitionsIds && inverterDefinitionsIds.length > 0) {
      await this.supplementalData.updatePowerConversionEquipmentInfoIfNeeded(
        inverters!,
        inverterDefinitionsIds[0],
        inverterDefinitionsIds[1],
        this.system.equipment.optimizers?.definition
      );
    }

    await this.supplementalData.updateMountingSystemDataIfNeeded(this.system.equipment.mountingSystems.definitions);
    runInAction((): void => {
      this.system.equipment.mountingSystems.definitions.enrichWith(this.supplementalData);
    });
  };

  @computed
  get isCreated(): boolean {
    return !isNil(this.id);
  }

  @computed
  get hasSteepSlopeArrayAreas(): boolean {
    return this.system.equipment.mountingSystems.hasSteepSlopeMountingSystems;
  }

  @computed
  get hasLowSlopeArrayAreas(): boolean {
    return this.system.equipment.mountingSystems.hasLowSlopeMountingSystems;
  }

  @computed
  get hasPvModules(): boolean {
    return this.system.equipment.pvModules.count > 0;
  }
}
