import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { CollapseStyle } from '../../../components/Collapse/styles';
import config, { UI_MODE } from '../../../../config/config';

export const MSPRowWrapper = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BasicPropsContainer = styled.div`
  width: 100%;

  & > ${CollapseStyle} {
    position: relative;

    & > div:first-child {
      background: none;
      padding: 0;
      ${(): SimpleInterpolation => config.featureFlag.uiMode !== UI_MODE.AURORA ? css`font-family: 'Roboto', sans-serif` : css``};
      font-weight: bold;
      font-size: 14px;
      color: #4a4a4a;

      & > span {
        font-size: 14px;
        font-weight: bold;
      }

      & svg {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

export const IndoorOutdoorWrapper = styled.div`
  padding: 11px 0;
`;
