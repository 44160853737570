import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import useStore from '../../../../../stores/useStore';
import { RoofIconWrapper } from '../../../../../ui/containers/RoofFaceProperties/styles';
import {
  BasicPropsBox, CustomContainer, WrapperContainer
} from '../RoofType/styles';
import RoofButton from '../../../../components/RoofButton';
import Flexbox from '../../../../components/Layout/Flexbox';

const getIconFromRequest = (num: number): string => {
  let icon: string = 'icon-level-1';
  if (num === 1) {
    icon = 'icon-level-1';
  }
  if (num === 1.5) {
    icon = 'icon-level-1-5';
  }
  if (num === 2) {
    icon = 'icon-level-2';
  }
  if (num === 2.5) {
    icon = 'icon-level-2-5';
  }
  if (num === 3) {
    icon = 'icon-level-3';
  }
  if (num === 3.5) {
    icon = 'icon-level-3-5';
  }
  if (num === 4) {
    icon = 'icon-level-4';
  }

  return icon;
};

export const RoofFaceLevel = observer((): ReactElement => {
  const { roofFaceProps } = useStore();
  const [editing, setEditing] = useState(false);
  const levelViewModel = roofFaceProps.levelRoofFaceViewModel!;

  const onChangeLevel = useCallback(
    (level: number, event: MouseEvent): void => {
      levelViewModel.changeLevel(level);
      setEditing(false);
    },
    [levelViewModel]
  );

  useEffect((): void => {
    setEditing(false);
  }, [roofFaceProps.firstRoofFaceSelected, levelViewModel]);

  const getLevelButtons = (): ReactElement[] => {
    return Object.keys(levelViewModel.levelRange)
      .sort()
      .map((level: string): ReactElement => {
        const currentRoofFaceColor =
          Number(level) === levelViewModel.selected
            ? levelViewModel.currentRoofFaceColor
            : levelViewModel.levelRange[level];
        return (
          <RoofButton
            key={level}
            level={Number(level)}
            onClick={onChangeLevel}
            selectedRoofFaceColor={levelViewModel.currentRoofFaceColor}
            currentRoofFaceColor={currentRoofFaceColor}
            selected={Number(level) === levelViewModel.selected}
            icon={getIconFromRequest(Number(level))}
          />
        );
      });
  };

  return (
    <WrapperContainer editing={editing}>
      <BasicPropsBox editing={editing} onClick={(): void => setEditing(!editing)}>
        <Flexbox className="box" align="center" justify="center">
          <Flexbox dir="column" className="text-box">
            <span className="title-box">Level of roof face eave</span>
            <span className="name-box"> {levelViewModel.description}</span>
          </Flexbox>
          <RoofIconWrapper>
            <LyraIcon.Icon name={getIconFromRequest(levelViewModel.selected)} />
          </RoofIconWrapper>
        </Flexbox>
      </BasicPropsBox>
      {editing && (
        <CustomContainer>
          <Flexbox wrap="wrap" align="center" justify="space-around">
            {getLevelButtons()}
          </Flexbox>
        </CustomContainer>
      )}
    </WrapperContainer>
  );
});
