import {
  BufferGeometry, MeshBasicMaterial, Vector3
} from 'three';
import { canvasConfig } from '../../../../config/canvasConfig';
import {
  PI, TWO_PI
} from '../../../../domain/models/Constants';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import { setLinePositionAttribute } from '../../../../utils/helpers';
import { extendLine } from '../../../../utils/math';
import { Line } from '../../../../domain/graphics/Line';
import { LayerCanvas } from '../../../../domain/graphics/LayerCanvas';
import type { Boundary } from '../../../../domain/graphics/Boundary';
import { BaseGuide } from './BaseGuide';
import type { ISmartGuideDependencies } from './BaseGuide';

const KEYCODE_P = 80;

export class ParallelGuide extends BaseGuide {
  icon = 'smartguide-parallel-lines';
  name = 'Parallel Lines';
  hotkey = 'S';
  commandKeyCode = KEYCODE_P;
  identifier = EGuideIdentifier.PARALLEL;
  kindSupport = [KindGuides.TRACE_TOOL];

  match: boolean = false;

  // Shows from the line which does match with the previewline
  lineGuide1!: Line;
  // Shows from the preview line matches with another line
  lineGuide2!: Line;

  constructor(deps: ISmartGuideDependencies) {
    super(deps);
  }

  setup(): void {
    this.enableKeyListener();
    const { parallelLinesColor } = canvasConfig;
    const lineMaterial = new MeshBasicMaterial({
      transparent: true,
      depthTest: true,
      depthWrite: false,
      ...LayerCanvas.CLOSEST,
      color: parallelLinesColor
    });
    this.lineGuide1 = new Line(new BufferGeometry(), lineMaterial);
    this.lineGuide2 = new Line(new BufferGeometry(), lineMaterial);
  }

  apply({
    mousePos, wipBoundary, kind
  }: IApplyParams): IGuideResult {
    this.editor.addOrUpdateObject(this.lineGuide1);
    this.editor.addOrUpdateObject(this.lineGuide2);

    if (kind === KindGuides.TRACE_TOOL && wipBoundary && mousePos) {
      return this.applyWithTraceTool(mousePos, wipBoundary);
    } else {
      return {
        color: '',
        position: mousePos
      };
    }
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    const {
      lineGuide1, lineGuide2
    } = this;
    const emptyVector = new Vector3();

    setLinePositionAttribute(emptyVector, emptyVector, lineGuide1);
    setLinePositionAttribute(emptyVector, emptyVector, lineGuide2);

    this.editor.removeObject(this.lineGuide1);
    this.editor.removeObject(this.lineGuide2);
  }

  override hideUiElements(): void {
    this.lineGuide1.visible = false;
    this.lineGuide2.visible = false;
  }

  private applyWithTraceTool(mousePos: Vector3, wipBoundary: Boundary): IGuideResult {
    const allboundaries: Boundary[] = this.editor.getObjectsByType('boundary', true);
    const newMousePos = new Vector3().copy(mousePos);
    const vertices = wipBoundary.vertices;
    const verticeslength = vertices.length;
    const dotProductLimit = 1;
    const toleranceRange = 0.005;
    const {
      lineGuide1, lineGuide2
    } = this;
    const threshold = canvasConfig.parallelLineThreshold;
    const parallelLinesColor = canvasConfig.parallelLinesColor;
    const linePos1 = {
      start: new Vector3(),
      end: new Vector3()
    };
    const linePos2 = {
      start: new Vector3(),
      end: new Vector3()
    };
    let match: boolean = false;

    if (verticeslength) {
      const lastIndex = verticeslength - 1;
      const lastVertex = vertices[lastIndex].mesh.position;
      // crop vector for preview line
      const prevDistX = lastVertex.x - mousePos.x;
      const prevDistY = lastVertex.y - mousePos.y;

      allboundaries.forEach((boundary: Boundary): void => {
        const roofVertices = boundary.vertices;
        const roofLength = roofVertices.length;
        for (let itr = 0; itr < roofLength; itr++) {
          // Ignore the las vertex when it's an opened polygon
          const isLastVertex = itr === verticeslength - 1;
          if (isLastVertex && !boundary.closed) {
            break;
          }

          // getting vertices positions
          const vertex1 = roofVertices[itr].mesh.position;
          const vertex2 = roofVertices[(itr + 1) % roofLength].mesh.position;

          // get the vector based on the vertices
          // normalize it beacuse we need the orientation only
          const segmentVector = new Vector3(vertex2.x - vertex1.x, vertex2.y - vertex1.y).normalize();
          // preview line vector based on the mouse position and last vertex
          // normalize it beacuse we need the orientation only
          const previewLineVector = new Vector3(prevDistX, prevDistY).normalize();
          // Calculating if the segment is parallel to the preview line
          // if they're parallels the dot product value are close to 1 or -1
          // if they're perpendicular, dot product are close to 0
          // so, the values go to 1, 0 and -1
          const dotProduct = segmentVector.dot(previewLineVector);
          // checking if vectors are close to be parallels
          const tolerance = dotProductLimit - Math.abs(dotProduct);

          if (tolerance <= toleranceRange) {
            const previewLength = Math.sqrt(prevDistX * prevDistX + prevDistY * prevDistY);
            let matchLineAngle = Math.atan2(segmentVector.y, segmentVector.x);

            if (dotProduct > 0) {
              // inverting the angle
              // i.e. 45 degrees is the inverted angle for 225
              //      or 270 is the inverted angle for 90
              // we do so because it doesn't matter the vector orientation
              // if the vector is up or down, if they're parallels in both ways
              // they should match
              matchLineAngle = (matchLineAngle + PI) % TWO_PI;
            }

            // Getting the new position in order to snap/connect the preview line
            const xcos = Math.cos(matchLineAngle);
            const ysin = Math.sin(matchLineAngle);
            const snapPos = new Vector3(lastVertex.x + xcos * previewLength, lastVertex.y + ysin * previewLength);
            const snapDistx = snapPos.x - mousePos.x;
            const snapDisty = snapPos.y - mousePos.y;
            const snapDist = Math.sqrt(snapDistx * snapDistx + snapDisty * snapDisty);

            // Display guidelines if the snap line distance is close to
            // the preview line
            if (snapDist <= threshold) {
              // guideline for the polygon line
              const [startPos1, endPos1] = extendLine(vertex1, vertex2);
              linePos1.start = startPos1;
              linePos1.end = endPos1;

              // guideline for the preview line
              const [startPos2, endPos2] = extendLine(
                lastVertex,
                new Vector3(lastVertex.x + xcos, lastVertex.y + ysin)
              );
              linePos2.start = startPos2;
              linePos2.end = endPos2;

              // Modify the mouse position in order to snap
              // the preview line.
              newMousePos.x = lastVertex.x + xcos * previewLength;
              newMousePos.y = lastVertex.y + ysin * previewLength;
              match = true;
            }
          }
        }
      });

      // should display the guidelines and setting their position
      lineGuide1.visible = match;
      lineGuide2.visible = match;
      setLinePositionAttribute(linePos1.start, linePos1.end, lineGuide1);
      setLinePositionAttribute(linePos2.start, linePos2.end, lineGuide2);
    }

    return {
      color: match ? parallelLinesColor : '',
      position: newMousePos
    };
  }
}
