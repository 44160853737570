import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon';
import { Flexbox } from '../Layout';

type Props = {
  active?: boolean | string;
};

const SideBarContainer = styled.div`
  width: 272px;
  min-height: 100vh;
  background: ${({ theme }: PropsTheme): Color => theme.colors.mainColor1};
`;

const ImageContainer = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  height: 128px;
`;

const LogoIcon = styled(Icon)`
  width: 120px;
`;

const NavContainer = styled.div`
  z-index: 100;
`;

const NavItemStyle = styled(Flexbox).attrs({
  justify: 'flex-start',
  align: 'center'
})<Props>`
  height: 56px;
  cursor: pointer;
  margin-top: 32px;
  background: ${
    ({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.highlight : ''
  };
  border-left: ${
    ({ theme, active }: PropsTheme<Props>): string =>
    active
    ? `8px solid ${theme.colors.grayColor1}`
    : `8px solid ${theme.colors.mainColor1}`
  };
  z-index: 100;
  text-decoration: none;
  width: calc(100% - 8px);
  &.highlighted:hover {
    width: calc(100% - 8px);
    background-color: ${
    ({ theme, active }: PropsTheme<Props>): Color =>
      active ? theme.colors.highlight : theme.colors.mainColor7
    };
    border-left: 8px solid ${
    ({ theme, active }: PropsTheme<Props>): Color =>
      active ? theme.colors.grayColor1 : theme.colors.transparent
    };
  }
  a {
    padding: 0;
    margin: 0;
    &:after {
      border-bottom: 0;
    }
  }
`;

const NavItemText = styled(Paragraph)<Props>`
  text-transform: uppercase;
  color: ${
    ({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.grayColor1 : theme.colors.whiteBg
  };
  font-weight: ${
    ({ active }: PropsTheme<Props>): string =>
    active ? 'bold' : 'normal'
  };
`;

export {
  NavItemText,
  NavItemStyle,
  NavContainer,
  LogoIcon,
  ImageContainer,
  SideBarContainer
};
