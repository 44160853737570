import type Limit from '../../../domain/models/Limit';
import { DENOMINATOR_PITCH } from '../../../utils/slope';

export const SLOPE_CODE = 'slope_property_editor';
export const AZIMUTH_CODE = 'azimuth_property_editor';
export const FRAMING_CODE = 'framing_property_editor';
export const ROOF_DECKING_CODE = 'roof_decking_property_editor';
export const ROOF_CONDITION_CODE = 'roof_condition_property_editor';
export const SURFACE_CODE = 'surface_property_editor';
export const SOLAR_ACCESS_CODE = 'solar_access_editor';
// Labels
export const LABEL_SLOPE = 'Define Slope';
export const LABEL_AZIMUTH = 'Define Azimuth';
export const LABEL_FRAMING = 'Define Framing';
export const LABEL_ROOF_CONDITION = 'Condition of Roof';
export const LABEL_SURFACE = 'Define Roof Surface';
export const DEGREES_SYMBOL = '°';
export const LABEL_SOLAR_ACCESS = 'Solar Access';

export { DENOMINATOR_PITCH };

export const TRUSS_SPACING_LIMIT: Limit = {
  lower: 1,
  upper: 200
};
