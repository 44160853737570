import { action } from 'mobx';
import { DesignStep } from '../../models/Design/DesignState';
import { PageId } from '../../../stores/UiStore/Pages/PagesStore';
import { getUiStore } from '../../../stores/RootStoreInversion';
import type { IProgressStepperStage } from '../IProgressStepperStage';

/*
 * This string is located here because if we try to export-import it from PlanSetCustomizationPage.tsx,
 * then import resolver will try to import DSAdressAutocomplete from Aurora's DS package,
 * causing errors during unit tests. No idea why it does that
 */
export const PLAN_SET_CUSTOMIZATION_FORM_ID = 'plan-set-customization-form';

export class PlanSetPreviewStage implements IProgressStepperStage {
  readonly id: DesignStep = DesignStep.PLAN_SET_PREVIEW;
  readonly title: string = 'Preview';
  readonly submitForm: string = PLAN_SET_CUSTOMIZATION_FORM_ID;

  @action.bound
  setUp = (): void => {
    const { pages } = getUiStore();
    pages.openPage(PageId.PlanSetPreview);
  };

  resume = (lastValidStage: string): void => {
    const isLastValidStage: boolean = lastValidStage === this.id;
    if (isLastValidStage) {
      this.setUp();
    }
  };

  cancel = (): void => {
    this.dispose();
  };

  dispose = (): void => {
    const { pages } = getUiStore();
    pages.closePage();
  };

  get canContinue(): boolean {
    return true;
  }

  continue = (): void => {
    this.dispose();
  };
}
