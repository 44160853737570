import type { Building } from '../../../domain/models/SiteDesign/Building';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateBuildingPropertyDependencies<T extends keyof Building> extends IBaseCommandDependencies {
  domain: DomainStore;
  building: Building;
  key: T;
  value: Building[T];
}

export class UpdateBuildingPropertyCommand<T extends keyof Building> extends BaseCommand {
  private building: Building;
  private key: T;
  private value: Building[T];
  private domain: DomainStore;

  constructor(dependencies: IUpdateBuildingPropertyDependencies<T>) {
    super();
    const {
      domain, building, key, value
    } = dependencies;
    this.domain = domain;
    this.building = building;
    this.key = key;
    this.value = value;
  }

  async execute(): Promise<void> {
    this.building[this.key] = this.value;
    this.domain.addOrUpdateBuilding(this.building);
  }
}
