import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { ORDER_LAYER_TOP, PROGRESS_STEPPER } from './constants';
import { FlexBG } from './FlexBG';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '@xstyled/styled-components';

const ProgressStepperArea = styled(Area)`
  border-bottom: ${({theme}: PropsTheme): Color => theme.auroraMode ? `1px solid ${theme.colors.grey4}` : 'none'};
`;

const ProgressStepperContainer = ({ children, className }: BaseProps): ReactElement => {
  return (
    <ProgressStepperArea
      name={PROGRESS_STEPPER}
      className={className}
      layer={ORDER_LAYER_TOP}
    >
      <FlexBG
        dir="row"
        align="center"
        justify="center"
        fullHeight
        >
        {children}
      </FlexBG>
    </ProgressStepperArea>
  );
};

export { ProgressStepperContainer };
