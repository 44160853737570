import type { RectangleProtrusion } from '../../../domain/models/SiteDesign/RectangleProtrusion';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface ISaveRoofProtrusionPropertyCommand extends IBaseCommandDependencies {
  domain: DomainStore;
  roofFace: RoofFace;
  protrusion: RectangleProtrusion;
  key: keyof RectangleProtrusion;
  value: RectangleProtrusion[keyof RectangleProtrusion];
}

export class SaveRoofProtrusionPropertyCommand extends BaseCommand {
  value: RectangleProtrusion[keyof RectangleProtrusion];
  private domain: DomainStore;
  private roofFace: RoofFace;
  private protrusion: RectangleProtrusion;
  private key: keyof RectangleProtrusion;

  constructor(dependencies: ISaveRoofProtrusionPropertyCommand) {
    super();
    const {
      domain, roofFace, protrusion, key, value
    } = dependencies;
    this.domain = domain;
    this.roofFace = roofFace;
    this.protrusion = protrusion;
    this.key = key;
    this.value = value;
  }

  async execute(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.protrusion[this.key] as any) = this.value;
    this.domain.addOrUpdateRoofProtrusion(this.roofFace, this.protrusion);
  }
}
