import {
  action, observable, reaction
} from 'mobx';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';
import type { ISiteData as SiteEntity } from '../../entities/SiteDesign/Site';
import { SiteMapping } from '../../../infrastructure/mapping/SiteMapping';
import type { IRoofFaceSupplementalData } from '../../entities/SiteDesign/RoofFaceSupplementalData';
import type { RoofFace } from '../../models/SiteDesign/RoofFace';
import type { IDesignParametersData } from './DesignParameters';
import { DesignParameters } from './DesignParameters';
import { Participants } from './Participant';
import type {
  IParticipantData, IPersonParticipant
} from './Participant';
import { Site } from './Site';
import type { Building } from './Building';
import type { ISupplementalProjectData } from './SupplementalProjectData';
import { SupplementalProjectData } from './SupplementalProjectData';

export interface IProjectData {
  readonly id: string;
  readonly account: string;
  readonly description?: string;
  readonly site: SiteEntity;
  readonly designParameters: IDesignParametersData;
  readonly participants: IParticipantData[];
  readonly internalReferenceId?: string;
  readonly supplementalData: ISupplementalProjectData;
}

/**
 * Additional project data that may be either:
 * - Managed externally (host app passes it through `IAppProps`), frontend uses it, but does not persist it
 * - Managed internally (collected in Design Tool's create project dialog) and persited under supplementalData
 */
export interface IAdditionalProjectData {
  /**
   * User-provided project identifier (e.g. the one used in Installer's internal system)
   */
  internalReferenceId?: string;
  /**
   * Information about the customer for whom the installer is designing the PV system
   */
  readonly customer?: IPersonParticipant;
}

/**
 * External integration project data, may be incomplete.
 * It's not processed by frontend, so we're just passing it to the backend.
 * Hence, the allowed any type exception.
 */
export type ExternalProposalData = unknown;

export class Project {
  id!: string;

  /**
   * ID of Account which owns this Project
   */
  account!: string;

  /**
   * Short description of a project
   */
  description?: string;

  @observable
  site: Site;

  @observable
  designParameters: DesignParameters;

  participants!: Participants;

  /**
   * User-provided reference ID for this project used internally within the Account.
   * A company might use other software for tracking projects so they may want to enter the project's ID used there.
   *
   * Note: this is only persisted here in cases when a project is created within the design tool.
   */
  internalReferenceId?: string;

  @observable
  supplementalData: SupplementalProjectData = new SupplementalProjectData();

  /**
   * Note: This constructor is a temporary hack to avoid large-scale changes in the domain store.
   *
   * The problem there is that `project` property there is expected to always be present, while in reality Project
   * is only available after it has been created or loaded from the backend.
   *
   * A lot of code relies on DomainStore#project being initialized with an "empty" project, hence this constructor.
   * Long-term static `Project#fromData` method should be removed in favor of performing that within the constructor.
   */
  constructor() {
    this.site = new Site();
    this.designParameters = new DesignParameters({
      buildingRiskCategory: ''
    });
    reaction(
      (): string[] => this.site.buildings.map((building: Building) => building.id),
      (buildingIds: string[]): void => {
        this.supplementalData.buildingsHeightsData = pickBy(
          this.supplementalData.buildingsHeightsData,
          (_, key: string): boolean => buildingIds.includes(key)
        );
      }
    );
    reaction(
      (): string[] => this.site.roofFaces.map((roofFace: RoofFace) => roofFace.serverId),
      (roofFaceServerIds: string[]): void => {
        this.supplementalData.roofFacesSupplementalData = filter(
          this.supplementalData.roofFacesSupplementalData,
          (roofFace: IRoofFaceSupplementalData) => roofFaceServerIds.includes(roofFace.serverId)
        );
      }
    );
  }

  toData(): IProjectData {
    return {
      id: this.id,
      account: this.account,
      description: this.description || undefined,
      site: new SiteMapping().convertDomainModelToEntity(this.site),
      designParameters: this.designParameters.toData(),
      participants: this.participants.toData(),
      internalReferenceId: this.internalReferenceId || undefined,
      supplementalData: this.supplementalData
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static fromData = (data: IProjectData): Project => {
    const project = new Project();
    project.id = data.id;
    project.account = data.account;
    project.description = data.description;
    project.site = new SiteMapping().convertEntityToDomainModel(data.site);
    project.designParameters = new DesignParameters(data.designParameters);
    project.participants = new Participants(data.participants);
    project.internalReferenceId = data.internalReferenceId;
    project.supplementalData = new SupplementalProjectData(data.supplementalData);
    return project;
  };

  @action
  updateDesignParameters = (data: IDesignParametersData): void => {
    this.designParameters = new DesignParameters(data);
  };
}
