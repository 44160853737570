import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import * as React from 'react';
import {
  IconWrapper, TextInfo, TextWrapper, ToastContainer
} from './styles';

const ToastRender = (text: string, icon: string): ReactElement => {
  return (
    <ToastContainer>
      <IconWrapper>
        <LyraIcon.Icon name={icon} />
      </IconWrapper>
      <TextWrapper>
        <TextInfo>{text}</TextInfo>
      </TextWrapper>
    </ToastContainer>
  );
};

export default ToastRender;
