import styled, { PropsTheme } from 'styled-components';
import { TableRowDataProps } from './Table';

export type ContainerProps = {
  width?: string;
};

export type TableRowProps = ContainerProps & {
  isDragging?: boolean;
};

export type CellProps = {
  size?: number;
  separator?: boolean;
  verticalAlign?: 'flex-start' | 'center' | 'flex-end';
  horizontalAlign?: 'flex-start' | 'center' | 'flex-end';
};

export const Container = styled.div<TableRowProps>`
  ${({ width }: TableRowProps): string => (width ? `width: ${width};` : '')}
  user-select: none;
`;

export const Label = styled.h4`
  margin-bottom: 12px;
  font-family: ${(props: PropsTheme): string => props.theme.fonts.sans};
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  color: #4a4a4a;
`;

export const HeaderLabel = styled(Label)`
  margin-bottom: 0px;
  font-style: italic;
`;

export const CellLabel = styled(Label)`
  margin-bottom: 0px;
  font-size: 12px;
`;

export const TableContainer = styled.table`
  width: 100%;
`;

export const TableHeader = styled.thead`
  border-top: solid 1px #d9d9d9;
  background: #e8e8e8;
`;

export const TableHeaderRow = styled.tr<TableRowProps>`
  ${({ width }: TableRowProps): string => (width ? `width: ${width};` : '')}
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  border-bottom: solid 1px #d9d9d9;
`;

export const TableBody = styled.tbody`
  background: #f2f2f2;
`;

export const Row = styled(TableHeaderRow)<TableRowProps & TableRowDataProps>`
  &:hover {
    background: #ebebeb;
  }

  /* CSS Drag props */
  opacity: ${({ isDragging }: TableRowProps): string => (isDragging ? '0' : '1')};
  cursor: ${({ isDragging }: TableRowProps): string => isDragging ? 'grabbing' : 'default'};
  background: #f2f2f2;
  z-index: 1;
`;

export const Cell = styled.th<CellProps>`
  flex: ${({ size = 1 }: CellProps): number => size};
  display: flex;
  flex-direction: row;
  justify-content: ${({ horizontalAlign = 'center' }: CellProps): string => horizontalAlign};
  align-items: ${({ verticalAlign = 'center' }: CellProps): string => verticalAlign};
  ${({ separator = true }: CellProps): string => separator ? 'border-right: solid 1px #d9d9d9;' : ''}
  padding: 6px 5px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
    padding-right: 0;
  }
`;
