import {
  LyraModal, LyraLoader, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { SystemDatasheetOptionViewModel } from '../../../../stores/UiStore/Modal/ViewModels/SystemDatasheetOption/SystemDatasheetOptionViewModel';
import useStore from '../../../../stores/useStore';
import ColorRow from './ColorRow/ColorRow';
import TextareaRow from './TextareaRow/TextareaRow';
import TextfieldRow from './TextfieldRow/TextfieldRow';
import ToggleRow from './ToggleRow/ToggleRow';

const SystemDatasheetOptionModal: React.FC = (): ReactElement => {
  const { modal } = useStore();
  const [systemDatasheetOptionViewModel] = useState(modal.viewModel as SystemDatasheetOptionViewModel);

  const {
    formContent, setFieldValue, annualProductionEstimate, errors
  } = systemDatasheetOptionViewModel;

  if (!formContent) {
    return <></>;
  }

  const {
    includes, colorScheme, company, system
  } = formContent;

  const rightButtonLabel = systemDatasheetOptionViewModel.generatingDocument ? 'Downloading...' : 'Download';

  const rightButtonDisabled =
    !systemDatasheetOptionViewModel.documentGenerationOptionsLoaded
    || systemDatasheetOptionViewModel.generatingDocument
    || !isEmpty(errors);

  const modalProps = {
    title: 'Options',
    leftButtonLabel: 'Cancel',
    rightButtonLabel,
    rightButtonDisabled,
    $show: true,
    height: '100%',
    width: '470px',
    onClickCloseButton: (): void => systemDatasheetOptionViewModel.closeModal(),
    onClickLeftButton: (): void => systemDatasheetOptionViewModel.closeModal(),
    onClickRightButton: (): void => systemDatasheetOptionViewModel.downloadSystemDatasheet()
  };

  const logoRowProps = {
    title: 'Logo',
    id: 'logoName',
    leftValue: 'No',
    rightValue: 'Yes',
    checked: includes.logo,
    onChange: (value: boolean): void => setFieldValue('includes.logo', value)
  };

  const billMaterialProps = {
    title: 'Include Bill of Materials',
    id: 'billMaterial',
    leftValue: 'No',
    rightValue: 'Yes',
    checked: includes.billOfMaterials,
    onChange: (value: boolean): void => setFieldValue('includes.billOfMaterials', value)
  };

  const equipmentIdentificationProps = {
    title: 'Include Equipment Identification',
    id: 'equipmentIdentification',
    leftValue: 'No',
    rightValue: 'Yes',
    checked: includes.equipmentIdentification,
    onChange: (value: boolean): void => setFieldValue('includes.equipmentIdentification', value)
  };

  const annualProductionProps = {
    title: 'Annual Production',
    value: annualProductionEstimate.toString(),
    onChange: (value: string): void => setFieldValue('system.annualProductionEstimate', parseInt(value, 10))
  };

  const companyNameProps = {
    title: 'Company Name',
    value: company?.name,
    onChange: (value: string): void => setFieldValue('company.name', value)
  };

  const companyRepProps = {
    title: 'Company Rep.',
    value: company?.representative?.name,
    onChange: (value: string): void => setFieldValue('company.representative.name', value)
  };

  const phoneProps = {
    title: 'Phone',
    value: company?.representative?.phone,
    onChange: (value: string): void => setFieldValue('company.representative.phone', value)
  };

  const emailProps = {
    title: 'Email',
    value: company?.representative?.email,
    error: !!errors['company.representative.email'],
    onChange: (value: string): void => setFieldValue('company.representative.email', value)
  };

  const systemHighlightsProps = {
    title: 'System Highlights',
    value: system.highlights,
    onChange: (value: string): void => setFieldValue('system.highlights', value)
  };

  const darkColorProps = {
    title: 'Color Scheme Dark Color',
    color: colorScheme.dark,
    onChange: (value: string): void => setFieldValue('colorScheme.dark', value)
  };

  const lightColorProps = {
    title: 'Color Scheme Light Color',
    color: colorScheme.light,
    onChange: (value: string): void => setFieldValue('colorScheme.light', value)
  };

  const companyHighlightsProps = {
    title: 'Company Highlights',
    value: company?.highlights,
    onChange: (value: string): void => setFieldValue('company.highlights', value)
  };

  const salesPitchTitleProps = {
    title: 'Company Sales Pitch Heading',
    value: company?.salesPitch?.heading,
    onChange: (value: string): void => setFieldValue('company.salesPitch.heading', value)
  };

  const salesPitchTextProps = {
    title: 'Company Sales Pitch',
    value: company?.salesPitch?.text,
    onChange: (value: string): void => setFieldValue('company.salesPitch.text', value)
  };

  return (
    <LyraModal.Modal {...modalProps}>
      {systemDatasheetOptionViewModel.documentGenerationOptionsLoaded ? (
        <>
          <LyraTypography.TextLayout type="h2" margin={'10px 0 0'} lineHeight={1.5} align="center">
            System Datasheet
          </LyraTypography.TextLayout>
          <div className="mt-xs">
            <ToggleRow name="logo" {...logoRowProps} />
            <ToggleRow name="billMaterial" {...billMaterialProps} />
            <ToggleRow name="equipmentIdentification" {...equipmentIdentificationProps} />

            <TextfieldRow name="annualProduction" {...annualProductionProps} />

            <TextfieldRow name="companyName" {...companyNameProps} />
            <TextfieldRow name="companyRep" {...companyRepProps} />
            <TextfieldRow name="phone" {...phoneProps} />
            <TextfieldRow name="email" {...emailProps} />

            <TextareaRow name="systemHighlights" {...systemHighlightsProps} />

            <ColorRow name="darkColor" {...darkColorProps} />
            <ColorRow name="lightColor" {...lightColorProps} />

            <TextareaRow name="companyHighlights" {...companyHighlightsProps} />

            <TextfieldRow name="salesPitchTitle" {...salesPitchTitleProps} />
            <TextareaRow name="salesPitchText" {...salesPitchTextProps} />
          </div>
        </>
      ) : (
        <LyraLoader.Loader width="470px" text="Loading..." />
      )}
    </LyraModal.Modal>
  );
};

export default observer(SystemDatasheetOptionModal);
