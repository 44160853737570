import React, {
  BaseProps,
  ReactElement,
  ReactText,
  useCallback, useContext
} from 'react';
import { EButtonType } from './EButtonType';
import { ButtonStyle } from './styles';
import { ThemeContext } from 'styled-components';
import { EButtonSizeToBorealisSize } from '~/components/Buttons/EButtonSize';
import { size } from 'lodash';

export type Props = BaseProps & {
  onClick?: (event: MouseEvent) => void;
  label?: string | ReactElement | ReactText;
  tooltip?: string;
  type?: string | EButtonType;
  selected?: boolean;
  disabled?: boolean;
};

const Button = (props: Props): ReactElement => {
  const onClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (typeof props.onClick === 'function') {
        props.onClick(event.nativeEvent);
      }
    },
    []
  );
  const theme = useContext(ThemeContext);
  const auroraMode = !!theme?.auroraMode;

  return auroraMode ? (
    <theme.DS.Button
      variant={props.type === EButtonType.MAIN ? 'primary' : 'secondary'}
      size="md"
      disabled={props.disabled}
      tooltip={props.tooltip}
      onClick={onClick}
    >
      {props.label || props.children}
    </theme.DS.Button>
  ) : (
    <ButtonStyle
      className={props.className}
      onClick={onClick}
      themeType={props.type}
      disabled={props.disabled}
      selected={props.selected}
      data-tooltip={props.tooltip}
    >
      {props.label || props.children}
    </ButtonStyle>
  );
};

export { Button };
