import isNil from 'lodash/isNil';
import type { IObservableArray } from 'mobx';
import {
  action, computed, observable
} from 'mobx';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type Store from '../../../Store';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { IControlSelectionChange } from '../../../EditorStore/Controls/ControlEvents';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type {
  IPanelChangedEvent, PropertiesStore
} from '../Properties';
import { getUiStore } from '../../../RootStoreInversion';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { UiStore } from '../../UiStore';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import { PropsPanelUICodes } from '../propertiesStoreConstants';
import type {
  BaseRoofFaceViewModel, IBaseRoofFaceViewModelDependencies
} from './BaseRoofFaceViewModel';
import { LevelRoofFaceViewModel } from './ViewModels/LevelRoofFaceViewModel';
import { TypeRoofFaceViewModel } from './ViewModels/TypeRoofFaceViewModel';
import { AzimuthRoofFaceViewModel } from './ViewModels/AzimuthRoofFaceViewModel';
import { SlopeRoofFaceViewModel } from './ViewModels/SlopeRoofFaceViewModel';

const DEFAULT_TITLE = 'Roof Property';

export class RoofFacePropertiesStore {
  @observable
  currentRoofPropertyEditor?: BaseRoofFaceViewModel;

  @observable
  typeRoofFaceViewModel?: TypeRoofFaceViewModel;

  @observable
  slopeRoofFaceViewModel?: SlopeRoofFaceViewModel;

  @observable
  azimuthRoofFaceModel?: AzimuthRoofFaceViewModel;

  @observable
  levelRoofFaceViewModel?: LevelRoofFaceViewModel;

  @observable
  firstRoofFaceSelected?: RoofFace;

  /**
   * The roof face that was the last to have its property(-ies) modified
   */
  @observable
  lastInteractedRoofFace?: RoofFace;

  roofFacePropertyViewModelsRefs: {
    [key: string]: BaseRoofFaceViewModel;
  } = {};

  @observable
  private selectedRoofFaces: IObservableArray<RoofFace> = observable([]);

  private editor: EditorStore;
  private domain: DomainStore;
  private properties: PropertiesStore;
  private serviceBus: ServiceBus;
  private selectionControl?: SelectionControl;

  /**
   * TODO
   * this is a temporal solution because the outline and roofface
   * are in z 0
   */
  private overOutline: number = 1;

  constructor(root: Store, uiStore: UiStore) {
    this.editor = root.editor;
    this.domain = root.domain;
    this.properties = uiStore.properties;
    this.serviceBus = root.serviceBus;

    // Initialize default variables
    this.init();
  }

  @computed get currentSelectedRoofFaces(): RoofFace[] {
    return this.selectedRoofFaces;
  }

  @action.bound
  setSelectedRoofFaces(roofFaces: RoofFace[]): void {
    getUiStore().roofFaceProps.levelRoofFaceViewModel!.setRoofFace(roofFaces[0]);
    this.selectedRoofFaces.replace(roofFaces);
    const isAlreadySelected = !!(
      this.selectedRoofFaces.length
      && this.firstRoofFaceSelected
      && this.firstRoofFaceSelected.serverId === this.selectedRoofFaces[0].serverId
    );
    if (this.selectedRoofFaces.length && !isAlreadySelected) {
      this.firstRoofFaceSelected = this.selectedRoofFaces[0];
      this.slopeRoofFaceViewModel = new SlopeRoofFaceViewModel({
        editor: this.editor,
        domain: this.domain,
        roofFaceProps: this,
        roofFace: this.firstRoofFaceSelected!,
        serviceBus: this.serviceBus,
        roofFacePropertyViewModelsRefs: this.roofFacePropertyViewModelsRefs
      });

      this.azimuthRoofFaceModel = new AzimuthRoofFaceViewModel({
        editor: this.editor,
        domain: this.domain,
        roofFaceProps: this,
        roofFace: this.firstRoofFaceSelected!,
        serviceBus: this.serviceBus
      });
    }
  }

  /**
   * Initialize variables to the default state
   *
   */
  init(): void {
    // Adding event listeners
    this.editor.addEventListener('canvasReady', this.setup.bind(this));
    this.properties.addEventListener('panel_changed', this.onPanelChanged.bind(this));
    // Initialize variables
    this.currentRoofPropertyEditor = undefined;
    this.typeRoofFaceViewModel = new TypeRoofFaceViewModel(
      this.domain,
      this,
      this.serviceBus,
      this.roofFacePropertyViewModelsRefs
    );
    this.levelRoofFaceViewModel = new LevelRoofFaceViewModel(this.domain, this, this.serviceBus);
    this.properties.setTitle(DEFAULT_TITLE);
  }

  setup(): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    this.selectionControl.addEventListener('selection_change', (event: IControlSelectionChange): void => {
      this.onSelectionChange(event);
    });
  }

  /**
   * Used to change the current viewmodel
   * in order to display the correct
   * component in Roof Properties container
   *
   */
  @action.bound
  changeShowPropertiesOf<T extends BaseRoofFaceViewModel>(viewModel: T): void {
    this.currentRoofPropertyEditor = viewModel;
  }

  /**
   * Factory used to create the desired
   * ViewModel with its dependencies
   *
   */
  @action.bound
  createRoofFacePropertyEditor<T extends BaseRoofFaceViewModel, K extends IBaseRoofFaceViewModelDependencies>(
    c: new (dep: K) => T,
    dependencies: K
  ): T {
    const newPropVM = new c(dependencies);

    this.roofFacePropertyViewModelsRefs[newPropVM.propUICode] = newPropVM;

    return newPropVM;
  }

  /**
   * Reset values to its inital state
   *
   */
  reset(): void {
    this.setRoofFaceAzimuthArrowVisibility();
    this.init();
  }

  setRoofFaceAzimuthArrowVisibility(): void {
    if (this.firstRoofFaceSelected && !isNil(this.firstRoofFaceSelected.azimuth)) {
      this.firstRoofFaceSelected.setAzimuthArrowVisibility(true);
    }
  }

  private onPanelChanged(event: IPanelChangedEvent): void {
    const { showingPanelOf } = event;
    if (showingPanelOf === undefined) {
      return;
    }
    if (showingPanelOf === PropsPanelUICodes.RoofProperties || showingPanelOf === PropsPanelUICodes.SiteStructure) {
      this.setRoofFaceAzimuthArrowVisibility();
    }
  }

  private onSelectionChange(event: IControlSelectionChange): void {
    const { selection } = event;

    selection?.forEach((object: Selectable): void => {
      if (object.type === SceneObjectType.RoofFace) {
        object.mesh.position.setZ(this.overOutline);
        this.currentRoofPropertyEditor = undefined;
        this.properties.setTitle(DEFAULT_TITLE);
        this.setSelectedRoofFaces([object as RoofFace]);
      }
    });
  }
}
