import { LyraHint } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';

type TipProps = {
  isShow: boolean;
  onClick: () => void;
  showHintForMPPT: boolean;
};

const TipComponent = observer((props: TipProps): ReactElement => {
  const {
    isShow,
    onClick,
    showHintForMPPT
  } = props;
  return <LyraHint.Hint isShow={isShow} showHintForMPPT={showHintForMPPT} trigger={onClick} />;
});

export default TipComponent;
