import {
  action, observable
} from 'mobx';
import { Vector3 } from 'three';
import type Store from '../../Store';
import * as guideStorage from '../../../infrastructure/services/storages/smartguide';
import type EditorStore from '../../EditorStore/EditorStore';
import { SceneObjectType } from '../../../domain/models/Constants';
import { getParentLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import {
  getCenterOfBoundingBoxAroundVertices, isILineSegment
} from '../../../utils/spatial';
import EGuideIdentifier from './EGuideIdentifier';
import type {
  IApplyParams, KindGuides
} from './IApplyParams';
import type IGuideResult from './IGuideResult';
import type { BaseGuide } from './SmartGuides/BaseGuide';
import { ExtensionLinesGuide } from './SmartGuides/ExtensionLinesGuide';
import { LiveAnglesGuide } from './SmartGuides/LiveAnglesGuide';
import { MidPointsGuide } from './SmartGuides/MidPointsGuide';
import { ParallelGuide } from './SmartGuides/ParallelGuide';
import { PerpendicularGuide } from './SmartGuides/PerpendicularGuide';
import { SnapGuide } from './SmartGuides/SnapGuide';

class SmartGuidesStore {
  @observable
  guides: BaseGuide[] = [];

  @observable
  defaultGuides: BaseGuide[];

  @observable
  enableGuides?: BaseGuide[];

  // When whatever smartguide is turned on
  @observable
  active?: boolean;

  @observable
  enable?: boolean;

  protected editor: EditorStore;

  private readonly lineSnapGuide: SnapGuide;
  private readonly angleSnapGuide: LiveAnglesGuide;

  constructor(root: Store) {
    this.editor = root.editor;
    const { editor } = this;

    this.angleSnapGuide = new LiveAnglesGuide({
      editor
    });
    this.lineSnapGuide = new SnapGuide({
      editor
    });

    // Base on priority order
    this.defaultGuides = [
      // It's important to have snap guide coming after angle snap as sometimes both guides
      // have to cooperate, solution for this case is in angle snap, so after it does its thing
      // we're skipping a regular snap guide handling.
      this.angleSnapGuide,
      this.lineSnapGuide,
      new ExtensionLinesGuide({
        editor
      }),
      new ParallelGuide({
        editor
      }),
      new PerpendicularGuide({
        editor
      }),
      new MidPointsGuide({
        editor
      })
    ];

    this.sortedGuides();
  }

  private existingAndEnabledGuides = (guideKind: KindGuides): BaseGuide[] => {
    const existingAndEnabledGuides = [];

    let angleGuideEnabled = false;
    let snapGuideEnabled = false;
    for (const guide of this.guides) {
      const kindExists = guide.kindSupport.find((kind: KindGuides): boolean => kind === guideKind);
      if (kindExists && guide.isEnabled) {
        if (guide.identifier === EGuideIdentifier.SNAP_LINES) {
          snapGuideEnabled = true;
        } else if (guide.identifier === EGuideIdentifier.LIVE_ANGLES) {
          angleGuideEnabled = true;
        } else {
          existingAndEnabledGuides.push(guide);
        }
      }
    }
    // Hence angle and line guides will be in the end, eliminating a set of errors occurring due to other guides putting
    // vertex into an unexpected location (e.g. slightly out of roof outline, making it impossible to trace inside
    if (angleGuideEnabled) {
      existingAndEnabledGuides.push(this.angleSnapGuide);
    }
    if (snapGuideEnabled) {
      existingAndEnabledGuides.push(this.lineSnapGuide);
    }

    return existingAndEnabledGuides;
  };

  enableLineSnapGuide(): void {
    this.lineSnapGuide.isEnabled = true;
  }
  disableLineSnapGuide(): void {
    this.lineSnapGuide.isEnabled = false;
  }
  enableAngleSnapGuide(): void {
    this.angleSnapGuide.isEnabled = true;
  }
  disableAngleSnapGuide(): void {
    this.angleSnapGuide.isEnabled = false;
  }

  @action.bound
  initGuides(whitelist: EGuideIdentifier[]): void {
    this.guides = [];
    this.defaultGuides.forEach((guide: BaseGuide): void => {
      const nameExist = whitelist.find(
        (nameGuideWhite: EGuideIdentifier): boolean => guide.identifier === nameGuideWhite
      );
      if (nameExist !== undefined) {
        guide.isActive = true;
        this.guides.push(guide);
      } else {
        guide.isActive = false;
      }
    });

    this.setActive();
  }

  applyGuides(params: IApplyParams): IGuideResult {
    const iterator = this.applyGuidesViaIterator(params);

    // Initialize iterator. It'll return snap result and take validation result for each guide.
    let iterationResult = iterator.next();
    while (!iterationResult.done) {
      // Send validation result to iterator and take next snapped position.
      iterationResult = iterator.next(
        // Supposing that the result is valid
        true
      );
    }

    return iterationResult.value;
  }

  /**
   * @description applyGuidesViaIterator - Iterator approach is used to be able to jump back and forth between looping
   * though guides, getting back to the caller context to run validation routines,
   * and pushing validation result back here. It's required because sometimes it's
   * important to ignore snap if it was not valid (tracing).
   */
  *applyGuidesViaIterator(params: IApplyParams): Generator<IGuideResult, IGuideResult, boolean> {
    const parameters = {
      ...params
    };

    const originalCenter = params.vertexObject ? getCenterOfBoundingBoxAroundVertices(params.vertexObject) : undefined;

    const defaultResult: IGuideResult = {
      position: params.mousePos,
      color: '',
      lastAppliedGuideId: undefined,
      objectVertex: originalCenter,
      snapOccurred: false
    };

    let result: IGuideResult = {
      ...defaultResult
    };

    // Don't apply any guides to protrusions
    if (params.wipBoundary?.mesh.parent) {
      const isRectangularProtrusion =
        getParentLyraModelByMesh(params.wipBoundary.mesh).type === SceneObjectType.Protrusion;
      if (isRectangularProtrusion) {
        return defaultResult;
      }
    }

    /**
     * Invalid position may be in {@see result}, but the next guide run (in this
     * loop) must not depend on an invalid position, and to get validation we're
     * yielding back to the caller, then back here with validation result boolean.
     */
    const lastValidPosition = params.mousePos?.clone();

    const existingAndEnabledGuides = this.existingAndEnabledGuides(params.kind);
    let skipSnapGuide = false;
    for (const guide of existingAndEnabledGuides) {
      if (skipSnapGuide && guide.identifier === EGuideIdentifier.SNAP_LINES) {
        continue;
      }

      parameters.mousePos = lastValidPosition?.clone();

      if (guide.identifier === EGuideIdentifier.LIVE_ANGLES && existingAndEnabledGuides.includes(this.lineSnapGuide)) {
        const possibleLineSnapTarget = this.lineSnapGuide.getClosestEdgeOrVertex(parameters.mousePos!, [
          SceneObjectType.ParcelBoundary,
          SceneObjectType.Outline,
          SceneObjectType.RoofFace
        ]);
        const goingToSnapToASegment = isILineSegment(possibleLineSnapTarget);
        result = guide.apply({
          ...parameters,
          ...(goingToSnapToASegment ? { possibleLineSnapTarget: possibleLineSnapTarget } : {})
        });
        skipSnapGuide = goingToSnapToASegment && !!result.snapOccurred;
      } else {
        result = guide.apply(parameters);
      }
      if (parameters.mousePos && result.position) {
        // Loop entry
        const isValid = (yield result) as boolean;
        // Validation result passed here via iterator.next
        if (isValid) {
          lastValidPosition?.copy(result.position);
          // Update state only when valid
          const newCenter = result.objectVertex;
          if (originalCenter && newCenter && parameters.vertexObject) {
            const offset = new Vector3().subVectors(newCenter, originalCenter);
            for (const vertex of parameters.vertexObject) {
              vertex.add(offset);
            }
            originalCenter.copy(newCenter);
          }
        } else {
          guide.hideUiElements();
        }
      }
    }

    return result;
  }

  resetGuides(): void {
    this.guides.forEach((guide: BaseGuide): void => {
      if (guide.isEnabled) {
        guide.reset(false);
      }
    });
  }

  @action.bound
  unSelectGuides(): void {
    this.guides.forEach((guide: BaseGuide): void => {
      guide.reset(false);

      if (guide.isEnabled) {
        guideStorage.remove(guide.identifier);
      }

      guide.isEnabled = false;
    });

    this.setActive();
  }

  @action.bound
  selectGuides(guideId: number): void {
    const guideIdentifier = guideId as EGuideIdentifier;
    const guide = this.getGuideByIdentifier(guideIdentifier);

    if (guide) {
      guide.isEnabled = !guide.isEnabled;

      if (guide.isEnabled) {
        guideStorage.save(guideId);
      } else {
        guideStorage.remove(guideId);
      }
    }

    this.setActive();
    this.sortedGuides();
  }

  private sortedGuides(): void {
    this.getPersistenceData();
    const guides = this.defaultGuides.slice();
    this.enableGuides = guides.sort((g1: BaseGuide, g2: BaseGuide): number => (g1.identifier < g2.identifier ? -1 : 1));
  }

  private getPersistenceData(): void {
    const savedData = guideStorage.load();

    // Select guides based on selected smart guide previously
    if (savedData !== null) {
      savedData.enables.forEach(this.enableGuide);
    }
  }

  private enableGuide = (guideId: EGuideIdentifier): BaseGuide | null => {
    const guide = this.getGuideByIdentifier(guideId);

    if (guide) {
      guide.isEnabled = true;
    }

    return guide || null;
  };

  private getGuideByIdentifier(guideId: EGuideIdentifier): BaseGuide | null {
    const guideIdentifier = guideId;
    const guide = this.defaultGuides.find(({ identifier }: BaseGuide): boolean => identifier === guideIdentifier);

    return guide || null;
  }

  /**
   * active if there is any smart guide enable
   * this is for optimizing a little bit
   */
  private setActive(): void {
    this.active = this.guides.some((guide: BaseGuide): boolean => guide.isEnabled && guide.isActive);
  }
}

export default SmartGuidesStore;
