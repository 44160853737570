import {
  BufferAttribute, BufferGeometry, MeshBasicMaterial, Vector3
} from 'three';
import { canvasConfig } from '../../../../config/canvasConfig';
import { HALF_PI } from '../../../../domain/models/Constants';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import {
  angleBetweenSegments, extendLine
} from '../../../../utils/math';
import { Line } from '../../../../domain/graphics/Line';
import type { Boundary } from '../../../../domain/graphics/Boundary';
import { LayerCanvas } from '../../../../domain/graphics/LayerCanvas';
import type { Vertex } from '../../../../domain/graphics/Vertex';
import { BaseGuide } from './BaseGuide';
import type { ISmartGuideDependencies } from './BaseGuide';

const KEYCODE_R = 82;
const SNAP_DIST_LIMIT = 10;

export class PerpendicularGuide extends BaseGuide {
  icon = 'smartguide-perpendicular-alignment';
  name = 'Perpendicular Alignment';
  commandKeyCode = KEYCODE_R;
  identifier = EGuideIdentifier.PERPENDICULAR;
  kindSupport = [KindGuides.TRACE_TOOL];

  match: boolean = false;

  lineGuide?: Line;
  private lineGeometry?: BufferGeometry;

  constructor(deps: ISmartGuideDependencies) {
    super(deps);
  }

  setup(): void {
    this.enableKeyListener();
    const { perpendicularGuideColor } = canvasConfig;

    this.lineGeometry = new BufferGeometry();
    this.setPositionAttribute(new Float32Array([]));
    const lineMaterial = new MeshBasicMaterial({
      transparent: true,
      depthTest: true,
      depthWrite: false,
      ...LayerCanvas.CLOSEST,
      color: perpendicularGuideColor
    });
    this.lineGuide = new Line(this.lineGeometry, lineMaterial);
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    this.lineGuide!.visible = false;
    this.editor.removeObject(this.lineGuide!);
  }

  override hideUiElements(): void {
    this.lineGuide!.visible = false;
  }

  apply({
    mousePos, wipBoundary, kind
  }: IApplyParams): IGuideResult {
    this.editor.addOrUpdateObject(this.lineGuide!);
    if (kind === KindGuides.TRACE_TOOL && wipBoundary && mousePos) {
      return this.applyWithTraceTool(mousePos, wipBoundary);
    } else {
      return {
        color: '',
        position: mousePos
      };
    }
  }

  private setPositionAttribute(float32Array: Float32Array): void {
    this.lineGeometry!.setAttribute('position', new BufferAttribute(float32Array, 3));
  }

  private applyWithTraceTool(mousePos: Vector3, wipBoundary: Boundary): IGuideResult {
    const { snapPerpendicularGuide } = canvasConfig;
    const allboundaries: Boundary[] = this.editor.getObjectsByType('boundary', true);
    const newMousePos = new Vector3().copy(mousePos);
    let showLine = false;

    // getting the perpendicular line shaped between the preview line vertex
    // and all segments in each roof face
    allboundaries.forEach((boundary: Boundary): void => {
      const vertices = boundary.vertices;
      const verticesLength = vertices.length;

      for (let itr = 0; itr < verticesLength; itr++) {
        // Ignore the las vertex when it's an opened polygon
        const isLastVertex = itr === verticesLength - 1;
        if (isLastVertex && !boundary.closed) {
          // TODO: Hardcode
          break;
        }

        // Getting the back and forward segments relate to a vertex
        const segments = this.getSegmentsInBetween(itr, vertices, boundary.closed);
        // imaginary vector/segment between the preview line vertex
        // and the the current vertex of the polygon
        const currVertex = vertices[itr].mesh.position;
        const prevlineVector = new Vector3(mousePos.x - currVertex.x, mousePos.y - currVertex.y);

        segments.forEach((segment: Vector3): void => {
          // local angle shaped between the actual roof face segment
          // and the imaginary line between preview line vertex
          // and the current vertex of the roof face
          const shapedAngle = Math.abs(angleBetweenSegments(segment, prevlineVector));

          const perpendicularAngle = Math.abs(shapedAngle - HALF_PI);
          const threshold = perpendicularAngle <= snapPerpendicularGuide;

          if (threshold) {
            const {
              linePos, snapPos
            } = this.getSnapPoint(prevlineVector, segment, currVertex);
            // Distance between snap point and the initial mouse point
            // validation to avoid increasing the tolerance base on the distance
            const snapDistance = snapPos.distanceTo(mousePos);

            if (snapDistance <= SNAP_DIST_LIMIT) {
              const [extFirst, extLast] = extendLine(currVertex, linePos);
              this.setPositionAttribute(new Float32Array([extFirst.x, extFirst.y, 0, extLast.x, extLast.y, 0]));
              showLine = true;

              newMousePos.x = snapPos.x;
              newMousePos.y = snapPos.y;
            }
          }
        });
      }
    });

    this.lineGuide!.visible = showLine;

    return {
      color: '',
      position: newMousePos
    };
  }

  /**
   * Get the two segments generated between three vertices
   *
   * @param index - index of the vertex to look
   * @param vertices - list of vertices for a polygon/roof face
   * @param roofClosed - check if the roof face is closed in order to ignore
   *                     the segment generated between
   *                     the last vertex and first vertex
   * @returns - two segments/vertices
   */
  private getSegmentsInBetween(index: number, vertices: Vertex[], roofClosed: boolean): Vector3[] {
    const verticesLength = vertices.length;
    // getting the beside vertices(previus and next one) from a vertex
    const prevIndex = (index - 1 + verticesLength) % verticesLength;
    const nextIndex = (index + 1) % verticesLength;
    const prevVertex = vertices[prevIndex].mesh.position;
    const currVertex = vertices[index].mesh.position;
    const nextVertex = vertices[nextIndex].mesh.position;
    // create the segments/vectors between the three vertex positions
    const prevVector = new Vector3(prevVertex.x - currVertex.x, prevVertex.y - currVertex.y);
    const nextVector = new Vector3(nextVertex.x - currVertex.x, nextVertex.y - currVertex.y);
    const segments: Vector3[] = [];

    // When it's an open roof face
    // Ignore the last segment created between
    // the last vertex and the first vertex
    if (!roofClosed && index !== 0) {
      segments.push(prevVector);
    }
    segments.push(nextVector);

    return segments;
  }

  /**
   * Calculate the snap line position and the snap point for the preview line
   *
   * @param prevVertexPos - preview line vertex position
   * @param segment - segment matched for this smart guide
   * @param offsetPos - position for the vertex where it's going to start
   *                    drawing/extending the line guide
   * @returns - Get the line position for the guide line and the point position
   *            for snapping the preview line
   */
  private getSnapPoint(
    prevVertexPos: Vector3,
    segment: Vector3,
    offsetPos: Vector3
  ): { linePos: Vector3; snapPos: Vector3 } {
    const prevlineLength = prevVertexPos.length();
    const prevVertexDir = new Vector3().copy(prevVertexPos)
      .normalize();
    const globalSegAngle = Math.atan2(segment.y, segment.x);
    // get the perpedicular angle for in order to calculate the snap position
    const snapAngle = globalSegAngle - HALF_PI;
    // get the direction for the guide line
    const xcos = Math.cos(snapAngle);
    const ysin = Math.sin(snapAngle);
    const guidePos = new Vector3(xcos, ysin).normalize();
    // getting the orientation of the snap position via dot product
    // you can expect two values: 1 or -1
    // go to 1 for up-right positions and -1 for down-left
    const orientationValue = Math.round(prevVertexDir.dot(guidePos));
    const directionLength = prevlineLength * orientationValue;
    const linePosition = new Vector3(offsetPos.x + xcos, offsetPos.y + ysin);
    const snapPosition = new Vector3(offsetPos.x + xcos * directionLength, offsetPos.y + ysin * directionLength);

    return {
      linePos: linePosition,
      snapPos: snapPosition
    };
  }
}
