import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';
import type { PropsWithTheme } from '../Button/styles';
import { PADDING_CARD } from '../Layout/SolarLayout/constants';

export const CollapseStyle = styled.div`
  transition: all 0.3s ease;
  width: 100%;
`;

type ToggleHeaderProps = PropsTheme & {
  open?: boolean;
  colorIcon?: Color;
  stretch?: boolean;
  themeType?: string;
};

type ToggleContentProps = {
  collapse: boolean;
};

type CollapseStyleProps = {
  themeType?: string;
};

function backgroundType({
  themeType, theme
}: PropsWithTheme & ToggleHeaderProps): Color {
  return themeType === 'property' ? theme.colors.whiteBg : 'linear-gradient(to bottom, #bbbbbb, #737373 99%)';
}

export const ToggleHeader = styled(LyraLayout.Flexbox)<ToggleHeaderProps>`
  background-image: ${backgroundType};
  box-sizing: border-box;
  cursor: pointer;
  padding: ${PADDING_CARD};
  user-select: none;
  & svg {
    height: 16px;
    margin-right: 10px;
    width: 16px;
    transform: ${({ open }: ToggleHeaderProps): string => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 0.3s ease;
    .fill-arrow {
      fill: ${({
    colorIcon, theme
  }: ToggleHeaderProps): Color => colorIcon || theme.colors.highlight};
    }
  }

  & > span {
    color: ${({
    colorIcon, theme
  }: ToggleHeaderProps): Color => colorIcon || theme.colors.highlight};
  }
`;

export const ToggleContent = styled.div<ToggleContentProps>`
  display: ${({ collapse }: ToggleContentProps): string => (collapse ? 'inherit' : 'none')};
`;
