import {
  action, observable
} from 'mobx';
import type { CircuitTableViewModel } from '../../../ui/containers/Pages/viewModels/CircuitTable/CircuitTableViewModel';
import type { PlanSetCustomizationPageViewModel } from '../../../ui/containers/Pages/pages/PlanSetCustomization/PlanSetCustomizationPageViewModel';
import type Store from '../../Store';
import type { PlanSetPreviewPageViewModel } from '../../../ui/containers/Pages/pages/PlanSetPreview/PlanSetPreviewPageViewModel';

export enum PageId {
  CircuitTable = 'circuit-table',
  PlanSetCustomization = 'plan-set-document-generation-options',
  PlanSetPreview = 'plan-set-preview-page'
}

export class PagesStore {
  @observable
  pageId?: PageId;
  @observable
  isLoading: boolean = false;
  @observable
  pageViewModel?: CircuitTableViewModel | PlanSetCustomizationPageViewModel | PlanSetPreviewPageViewModel;

  private readonly rootStore: Store;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  @action
  openPage(page: PageId): void {
    this.pageId = page;
  }

  @action
  closePage(): void {
    this.pageId = undefined;
  }
}
