import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import useStore from '../../../../stores/useStore';
import {
  isDesignWorkspace, isProjectWorkspace
} from '../../../../stores/UiStore/WorkspaceStore/utils';
import TextButton from '../../../../ui/components/ButtonText';
import { Workspace } from '../../../../stores/UiStore/WorkspaceStore/index';

export const SiteDesignButton = observer((): ReactElement => {
  const {
    domain, editor, workspace
  } = useStore();

  const [active, setActive] = useState(true);

  useEffect((): void => {
    setActive(isProjectWorkspace(workspace.currentWorkspace));
  }, [workspace.currentWorkspace]);

  const onHandleClick = (): void => {
    if (isDesignWorkspace(workspace.currentWorkspace)) {
      workspace.currentWorkspace.saveManually();
      workspace.changeWorkspace(Workspace.Project);
      workspace.projectWorkspace?.saveManually();
    }

    editor.renderSiteMarkers(workspace.currentWorkspace);
  };

  return <TextButton border={active} label="Site" colorTheme={EThemeComponentColor.PURPLE} onClick={onHandleClick} />;
});
