import {
  computed, observable
} from 'mobx';

export type ProvinceDropdownOption = {
  name: string;
  abbreviation: string;
};

export interface IAddressData {
  addressOne: string;
  addressTwo?: string;
  city: string;
  province: string;
  postalCode: string;
}

export class Address {
  @observable
  private readonly addressOne: string;
  @observable
  private readonly addressTwo?: string;
  @observable
  readonly city: string;
  @observable
  readonly province: string;
  @observable
  private readonly postalCode: string;

  constructor(data: IAddressData) {
    this.addressOne = data.addressOne;
    this.addressTwo = data.addressTwo;
    this.city = data.city;
    this.province = data.province;
    this.postalCode = data.postalCode;
  }

  toData(): IAddressData {
    return {
      addressOne: this.addressOne,
      addressTwo: this.addressTwo || undefined,
      city: this.city,
      province: this.province,
      postalCode: this.postalCode
    };
  }

  static readonly fromGoogleMapsAddressComponents = (
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): Address => {
    let streetNumber: string = '';
    let route: string = '';
    let city: string = '';
    let province: string = '';
    let postalCode: string = '';
    let sublocality: string = '';
    let neighborhood: string = '';
    let country: string = '';

    addressComponents.forEach((component: google.maps.GeocoderAddressComponent): void => {
      if (component.types.includes('street_number')) {
        streetNumber = component.short_name;
      } else if (component.types.includes('route')) {
        route = component.short_name;
      } else if (component.types.includes('locality')) {
        city = component.short_name;
      } else if (!city && component.types.includes('administrative_area_level_3')) {
        city = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        province = component.short_name;
      } else if (component.types.includes('postal_code')) {
        postalCode = component.short_name;
      } else if (component.types.includes('sublocality') || component.types.includes('sublocality_level_1')) {
        sublocality = component.short_name;
      } else if (component.types.includes('neighborhood')) {
        neighborhood = component.short_name;
      } else if (component.types.includes('country')) {
        country = component.short_name;
      }
    });
    return new Address({
      addressOne: `${streetNumber} ${route}`,
      city: city || sublocality || neighborhood, // Special cases if no values for city found
      province: country === 'PR' ? 'PR' : province, // Special case when GoogleMaps has PuertoRico as different country.
      postalCode
    });
  };

  @computed
  get isValid(): boolean {
    return Boolean(this.addressOne.trim() && this.city.trim() && this.province.trim() && this.postalCode.trim());
  }

  @computed
  get asFormattedText(): string {
    return [this.addressOne, this.city, this.province, this.postalCode]
      .map((addressComponent: string): string => addressComponent.trim())
      .filter((trimmedAddressComponent: string): boolean => trimmedAddressComponent.length > 0)
      .join(', ');
  }
}
