import styled from 'styled-components';

export const FormInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 12px;
  padding: 12px;
  background-color: rgb(31, 192, 192, 0.1);
  flex-direction: column;
  flex: 1;
`;

export const FormInfoHeader = styled.h1`
  font-size: 18px;
  margin: 10px 0 20px 0;
  text-align: center;
`;

export const FormInfoText = styled.p`
  font-size: 14px;
  margin: 20px 10px 10px 10px;
`;

export const FormInfoTextBold = styled.p`
  font-size: 14px;
  margin: 10px;
  font-weight: bold;
`;

export const BackButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100px;
  width: 100%;
`;
