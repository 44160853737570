import styled, { css, PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';

export type TBuildingContainer = PropsTheme & { collapse: boolean };
export type TLeftIconContainer = PropsTheme & { bgColor: string };
export type TRoofFaceStrip = PropsTheme & { bgColor: string };
export type TBorderedWrapper = PropsTheme & {
  hasLeftPadding?: boolean;
  hasBorder: boolean;
};

const rowMixin = css`
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`;

const fontMixin = css`
  font-size: 14px;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grayColor1}`};
`;

const fontBoldMixin = css`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grayColor1}`};
`;

const iconMixin = css`
  height: 12px;
  width: 14px;
  .fill-arrow {
    fill: ${({ theme }: PropsTheme): Color => theme.colors.highlight};
  }
`;

export const BuildingsContainer = styled.div`
  ${rowMixin}
  padding: 0px 12px;
  justify-content: space-between;
  ${fontBoldMixin}
  background-image: ${({ theme }: PropsTheme): string =>
    `linear-gradient(to bottom, ${theme.colors.secondary}, ${theme.colors.tertiary})`};
`;

export const PlusIcon = styled.span`
  cursor: pointer;
  font-size: 24px;
  &:after {
    content: '+';
  }
`;

export const BuildingContainer = styled.div<TBuildingContainer>`
  ${rowMixin}
  padding: 0px 6px;
  ${fontBoldMixin}
  cursor: pointer;
  user-select: none;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.whiteBg}`};
  background-image: ${({ theme }: PropsTheme): string =>
    `linear-gradient(to bottom, ${theme.colors.grey3}, ${theme.colors.iconBg2})`};
  & > div > svg {
    ${iconMixin}
    margin-right: 8px;
    transform: rotate(
      ${({ collapse }: TBuildingContainer): string => (collapse ? '0' : '180')}deg
    );
    transition: all 0.3s ease;
  }
`;

export const SiteStructureItemContainer = styled.div<PropsTheme>`
  ${rowMixin}
  ${fontBoldMixin}
  border-top: 1px solid ${({ theme }: PropsTheme): string => `${theme.colors.grey3}`};
  background-image: ${({ theme }: PropsTheme): string =>
    `linear-gradient(to bottom, ${theme.colors.secondary}, ${theme.colors.tertiary})`};
`;

export const PaddedSiteStructureItemContainer = styled(SiteStructureItemContainer)`
  padding-right: 6px;
`;

export const RoofFaceContainer = styled.div`
  ${rowMixin}
  ${fontMixin}
  cursor: pointer;
  user-select: none;
  background: ${({ theme }: PropsTheme): string => `${theme.colors.secondary}`};
`;

export const RoofProtrusionContainer = styled(RoofFaceContainer)`
  color: ${({ theme }: PropsTheme): string => `${theme.colors.error}`};
`;

export const LevelStrip = styled.div<TRoofFaceStrip>`
  height: 100%;
  width: 15px;
  margin-right: 26px;
  background: ${({ bgColor }: TRoofFaceStrip): string => bgColor};
  opacity: 0.25;
`;


export const LeftIconContainer = styled.span<TLeftIconContainer>`
  height: 100%;
  margin-right: 12px;
  & > div {
    height: 100%;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ bgColor }: TLeftIconContainer): string => bgColor};
    & > svg {
      width: 24px;
      path.outline {
        fill: ${({ theme }: PropsTheme): string => `${theme.colors.whiteBg}`};
        stroke: ${({ theme }: PropsTheme): string => `${theme.colors.whiteBg}`};
      }
      #Oval {
        stroke: ${({ theme }: PropsTheme): string => `${theme.colors.whiteBg}`};
      }
      #Group-Copy>path, #Group-Copy>rect {
        fill: ${({ theme }: PropsTheme): string => `${theme.colors.whiteBg}`};
      }
    }
  }
`;

export const RightIconsContainer = styled.span`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & div {
    & > :first-child {
      margin-left: 6px;
    }
    & svg {
      ${iconMixin}
    }
  }
`;

export const Separator = styled.div`
  flex: 1;
`;

export const TextRow = styled.div`
  width: 130px;
  position: relative;
`;

export const BorderedWrapper = styled.div<TBorderedWrapper>`
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding-right: 6px;
  border-bottom: ${({ theme, hasBorder }: TBorderedWrapper): string =>
    `${hasBorder ? 1 : 0}px solid ${theme.colors.grey3}`};
  
  ${TextRow} {
   left: ${({ hasLeftPadding }: TBorderedWrapper): string => hasLeftPadding ? '15px' : '0px'};
  }
`;
