import isEmpty from 'lodash/isEmpty';
import type { MutableRefObject } from 'react';
import {
  useEffect, useState
} from 'react';
import type { Coords } from '../domain/typings';
import useStore from '../stores/useStore';

export type MapType<T> = {
  google: typeof google.maps | undefined;
  config: T;
  containerRef: MutableRefObject<HTMLDivElement>;
};

// Custom hook that return google instance using promise to control async load
export const useGoogleMap = (): typeof google.maps | undefined => {
  const { map } = useStore();
  const [googleMap, setGoogleMap] = useState<typeof google.maps | undefined>(map.googleMap);
  useEffect((): void => {
    setGoogleMap(map.googleMap);
  }, [map.googleMap]);
  return googleMap;
};

// Custom hook that return map instance and loads it in a container
export const useMap = ({
  google, config, containerRef
}: MapType<google.maps.MapOptions>): google.maps.Map => {
  const { current } = containerRef;
  const [map, setMap] = useState<google.maps.Map>({} as google.maps.Map);

  useEffect((): void => {
    if (!google || !current || isEmpty(current)) {
      return;
    }

    const mapInstance = new google.Map(current, config);

    setMap(mapInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, current]);

  return map;
};

// Custom hook that return street view instance and loads it in a container
export const useStreetView = (
  params: MapType<google.maps.StreetViewPanoramaOptions>,
  open: boolean
): google.maps.StreetViewPanorama => {
  const {
    google, config, containerRef
  } = params;
  const { current } = containerRef;
  const [streetView, setStreetView] = useState<google.maps.StreetViewPanorama>({} as google.maps.StreetViewPanorama);

  useEffect((): void => {
    if (!google || !open || !current || isEmpty(current)) {
      return;
    }
    const panorama = new google.StreetViewPanorama(current, config);

    setStreetView(panorama);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, open, current]);

  return streetView;
};

/**
 * Custom hook that return geocoder instance and loads it in a container
 * @param googleMap instance of google maps
 * @return instance of geocoder to use as state of container
 */
export const useGeocoding = (googleMap: typeof google.maps | undefined): google.maps.Geocoder => {
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>({} as google.maps.Geocoder);

  useEffect((): void => {
    if (googleMap) {
      const geocode = new googleMap.Geocoder();
      setGeocoder(geocode);
    }
  }, [googleMap]);

  return geocoder;
};

/**
 * Custom hook that allows to use places in any input element
 * @param googleMap instance of google maps
 * @param inputElement input where will be used the autocomplete google places
 * @param onPlaceChanged optional callback to perform
 * some actions when place changed
 * @param opts Here could be configured some restrictions
 */
export const usePlaces = (
  googleMap: typeof google.maps | undefined,
  inputElement: HTMLInputElement,
  onPlaceChanged?: (autocomplete: google.maps.places.Autocomplete) => void,
  opts?: google.maps.places.AutocompleteOptions
): void => {
  const [autocompleteInstance, setAutocompleteInstance] = useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (!googleMap || !googleMap.places || !inputElement.validity) {
      return;
    }

    if (!autocompleteInstance) {
      setAutocompleteInstance(new googleMap.places.Autocomplete(inputElement, opts));
      return;
    }

    googleMap.event.addListener(autocompleteInstance!, 'place_changed', (): void => {
      if (autocompleteInstance && onPlaceChanged) {
        onPlaceChanged(autocompleteInstance);
      }
    });

    return () => {
      googleMap.event.clearListeners(autocompleteInstance!, 'place_changed');
    };
  }, [autocompleteInstance, googleMap, inputElement, onPlaceChanged, opts]);
};

/**
 *
 * Function that geocode an address into lat long coordinates
 * @param geocoder Instance of geocoder google maps
 * @param address The string of the address
 * @param callback callback to send the coordinates
 */
export function geocodeAddress(
  geocoder: google.maps.Geocoder,
  address: string,
  callback: (latLong: Coords) => void
): void {
  if (!!geocoder.geocode) {
    const data: google.maps.GeocoderRequest = {
      address
    };

    geocoder.geocode(data, (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus): void => {
      if (results && status === google.maps.GeocoderStatus.OK) {
        const latLong: Coords = {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        };
        // call the callback
        callback(latLong);
      }
    });
  }
}
