import { css } from 'styled-components';
import { Mixin } from '../../typings';

export const resetStylesLyraOnly = css`
  *, ::before, ::after {
    box-sizing: content-box;
  }
`;

const resetStyles: Mixin = css`
  html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;

    ::before, ::after {
      content: '';
      content: none;
    }
  }

  button {
    border: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: normal;

    &:focus, &:active {
      outline: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  h1 { font-size: 32px; }
  h2 { font-size: 24px; }
  h3 { font-size: 18px; }
  h4 { font-size: 16px; }
  h5 { font-size: 13px; }
  h6 { font-size: 11px; }

  label {
    display: inline;
  }
`;

export { resetStyles };
