import {
  computed, observable
} from 'mobx';
import { convertFromCentigradesToFahrenheit } from '../../../utils/helpers';

export type TSourceType = 'WEATHER_STATION' | 'USER';

export interface IDataSourceData {
  type: TSourceType;
  id?: number;
  name?: string;
  data?: TTemperatureDataSource;
}

export type TTemperatureDataSource = {
  [key in ETemperatureType]: number;
};

export interface ITemperatureOptionsResponse {
  highTemperatures: ITemperatureOption[];
  lowTemperatures: ITemperatureOption[];
}

export interface ITemperatureOption {
  temperatureData: TTemperatureDataSource;
  weatherStation?: {
    id: number;
    name: string;
  };
}

export enum ETemperatureType {
  ASHRAE_EXTREME_LOW = 'ASHRAE_EXTREME_LOW',
  ASHRAE_2_PERCENT_HIGH_DRY_BULB = 'ASHRAE_2_PERCENT_HIGH_DRY_BULB',
  ASHRAE_4_PERCENT_HIGH_DRY_BULB = 'ASHRAE_4_PERCENT_HIGH_DRY_BULB',
  ASHRAE_EXTREME_HIGH = 'ASHRAE_EXTREME_HIGH',
  TMY3_DRY_BULB_EXTREME_LOW = 'TMY3_DRY_BULB_EXTREME_LOW',
  TMY3_DRY_BULB_EXTREME_HIGH = 'TMY3_DRY_BULB_EXTREME_HIGH'
}

export const TEMPERATURE_TYPE_LABELS: { [temperatureType in ETemperatureType]: string } = {
  ASHRAE_EXTREME_LOW: 'ASHRAE Extreme Low Temperature',
  ASHRAE_2_PERCENT_HIGH_DRY_BULB: 'ASHRAE 2.0% Dry Bulb Temperature',
  ASHRAE_4_PERCENT_HIGH_DRY_BULB: 'ASHRAE 0.4% Dry Bulb Temperature',
  ASHRAE_EXTREME_HIGH: 'ASHRAE Extreme High Temperature',
  TMY3_DRY_BULB_EXTREME_LOW: 'TMY3 Extreme Low Temperature',
  TMY3_DRY_BULB_EXTREME_HIGH: 'TMY3 Extreme High Temperature'
};

export interface ITemperatureDesignParameterData {
  value: number;
  description: ETemperatureType;
  dataSource: IDataSourceData;
}

export class TemperatureDesignParameter {
  /**
   * Value in degrees Celsius
   */
  @observable
  value: number;

  @observable
  description: ETemperatureType;

  @observable
  dataSource: IDataSourceData;

  constructor(data: ITemperatureDesignParameterData) {
    this.value = data.value;
    this.description = data.description;
    this.dataSource = data.dataSource;
  }

  toData(): ITemperatureDesignParameterData {
    return {
      value: this.value,
      description: this.description,
      dataSource: this.dataSource
    };
  }

  @computed
  get valueInFahrenheit(): number {
    return convertFromCentigradesToFahrenheit(this.value);
  }

  @computed
  get valueAsText(): string {
    return `${this.valueInFahrenheit.toFixed(0)}°F (${this.value.toFixed(0)}°C)`;
  }

  @computed
  get descriptionAsText(): string {
    return TEMPERATURE_TYPE_LABELS[this.description];
  }

  @computed
  get dataSourceName(): string {
    return this.dataSource.name ?? '';
  }
}
