import type { JSONSchema7 } from 'json-schema';
import type { UiSchema } from '@rjsf/utils/lib/types';
import { arrayIncludesAll } from '../engine/util';
import type {
  FormData, FormFieldProp
} from '../FormOptionsRulesAndState';
import {
  findFormSchemaField, removeEnumByIndex, updateDefaultValue
} from './util';

function doExclude(
  field: string,
  schema: JSONSchema7,
  tags: string[],
  formData: FormData
) {
  const fieldInSchema: FormFieldProp = findFormSchemaField(
    field, schema.properties
  )!;
  const fieldEnumTags = fieldInSchema.enumTags;

  for (let index = 0; index < (fieldEnumTags?.length ?? 0); index++) {
    if (arrayIncludesAll(
      fieldEnumTags![index], tags
    )) {
      removeEnumByIndex(
        schema, field, index
      );
      index--;
    }
  }
  updateDefaultValue(
    field, fieldInSchema, formData
  );
}

type HideOptionsWithTags = {
  field: string,
  tags: string[]
}

/**
 * Removes enum options that match all tags provided
 *
 * @param field field id
 * @param tags a set of tags that must all be present on enum option for it to be hidden
 * @param schema
 * @param uiSchema
 * @returns {{schema: *, uiSchema: *}}
 */
export default function hideOptionsWithAllTags(
  { field, tags }: HideOptionsWithTags,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
) {
  doExclude(
    field.split('.')[1],
    schema,
    tags,
    formData
  );
}
