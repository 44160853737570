import type { Color } from '@aurorasolar/lyra-ui-kit';
import type { Object3D } from 'three';
import { MeshBasicMaterial } from 'three';
import EWorkspace from '../../../domain/typings/EWorkspace';
import { Drawable } from '../../mixins/Drawable';
import { Hoverable } from '../../mixins/Hoverable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { WorkspaceTagged } from '../../mixins/WorkspaceTagged';
import type { Vector3D } from '../../typings';
import { SceneObjectType } from '../Constants';

interface IPathwayParams {
  serverId: string;
  pathwayVertices: Vector3D[];
}

const PATHWAY_THICKNESS = 1;

const MixedClass = PolygonDrawable(Hoverable(Selectable(WorkspaceTagged(Drawable(class SimpleClass {})))));

class Pathway extends MixedClass {
  readonly tag: EWorkspace = EWorkspace.DESIGN;
  override readonly type: string = SceneObjectType.Pathway;
  readonly propertyId: string = SceneObjectType.Pathway;
  override color: Color = '#167524';
  readonly selectWithParent: boolean = false;

  /** Layer Number */
  layerNumber: number = 4;

  constructor({
    pathwayVertices, serverId
  }: IPathwayParams) {
    super();
    this.serverId = serverId;
    this.boundary.defaultThickness = PATHWAY_THICKNESS;
    this.changeMeshMaterial(this.color);

    const vertexData = pathwayVertices.map(
      (currentVertex: Vector3D): Vector3D => ({
        ...currentVertex,
        z: currentVertex.z + 1
      })
    );
    this.addVertices(vertexData);
    this.redraw();
  }

  hasFill(): boolean {
    return true;
  }

  onClose(): void {
    /** */
  }

  showFill(): boolean {
    return true;
  }

  showLines(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return false;
  }

  showVertices(): boolean {
    return this.selected;
  }

  changeMeshMaterial(color: Color): void {
    this.mesh.material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.5
    });
    this.color = color;
    this.redraw();
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }
}

export default Pathway;
