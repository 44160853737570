import React, { BaseProps } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color, InputProps } from '../../../typings';

type Props = BaseProps & InputProps;

export const Label = styled.label`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
  height: 23px;
`;

export const TextAreaField = styled.textarea<Props>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${(props: Props): Color => (props.isError ? '#d34a4a' : '#333038')};
  width: 100%;
  border: none;
  border-bottom: 1px solid #adadad;
  min-width: 86px;
  outline: none;
  padding: 0;
  background: inherit;
  resize: none;
`;
