import { observer } from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import styled from 'styled-components';
import IconMapper from '../../../components/Icons';

type DefinedPropertiesProps = BaseProps & {
  headerText: string;
};

const Container = styled.div`
  width: 500px;
  text-align: center;
  margin: 30px 0;
`;

const Header = styled.div`
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 300;
  margin-bottom: 50px;
`;

const Description = styled.div`
  font-size: 18px;
  color: #4a4a4a;
  font-weight: 300;
  margin-top: 50px;
`;

const DefinedProperties = observer(({ headerText }: DefinedPropertiesProps): ReactElement => {
  return (
    <Flexbox justify="center">
      <Container>
        <Header>{headerText}</Header>
        <IconMapper name="sun-success" />
        <Description>
          Continue on to create a proposal-ready design or define additional site parameters to generate a permit
          package.
        </Description>
      </Container>
    </Flexbox>
  );
});

export default DefinedProperties;
