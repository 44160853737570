import React, { BaseProps, ReactElement, useCallback, useEffect, useState } from 'react';
import { ButtonList, WrapperButtons } from './ListButtonStyle';

export type OptionProps = {
  // tslint:disable-next-line: no-any
  [key: string]: any;
};

export type ListProps = BaseProps & {
  onClickEvent: (option: OptionProps) => void;
  options: OptionProps[];
  selected?: string;
};

const ListButtons = (props: ListProps): ReactElement => {
  const { onClickEvent, options, selected } = props;

  const defaultValue = selected ?? options[0].value;

  const [selVal, setSelVal] = useState(selected);

  const handleOnClick = useCallback(
    (option: OptionProps): void => {
      onClickEvent(option);
      setSelVal(option.value);
    },
    [options]
  );

  useEffect((): void => {
    setSelVal(defaultValue);
  }, [selected]);

  return (
    <WrapperButtons>
      {options?.map((option: OptionProps, index: number): ReactElement => (
        <ButtonList
          key={index}
          type="button"
          selected={option.value === selVal}
          onClick={(): void => handleOnClick(option)}
        >
          {' '}
          {option.value}
        </ButtonList>
      ))}
    </WrapperButtons>
  );
};

export { ListButtons };
