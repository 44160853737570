(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("react-select"), require("@emotion/react"), require("react-dom"), require("@emotion/cache"), require("react-toastify"));
	else if(typeof define === 'function' && define.amd)
		define("lyra-ui-kit", ["react", "styled-components", "react-select", "@emotion/react", "react-dom", "@emotion/cache", "react-toastify"], factory);
	else if(typeof exports === 'object')
		exports["lyra-ui-kit"] = factory(require("react"), require("styled-components"), require("react-select"), require("@emotion/react"), require("react-dom"), require("@emotion/cache"), require("react-toastify"));
	else
		root["lyra-ui-kit"] = factory(root["react"], root["styled-components"], root["react-select"], root["@emotion/react"], root["react-dom"], root["@emotion/cache"], root["react-toastify"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__8694__, __WEBPACK_EXTERNAL_MODULE__3110__, __WEBPACK_EXTERNAL_MODULE__1869__, __WEBPACK_EXTERNAL_MODULE__9514__, __WEBPACK_EXTERNAL_MODULE__8384__, __WEBPACK_EXTERNAL_MODULE__8673__) => {
return 