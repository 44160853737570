import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateGroundSnowLoadCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly groundSnowLoad: number;
}

export class UpdateGroundSnowLoadCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly groundSnowLoad: number;

  constructor(dependencies: IUpdateGroundSnowLoadCommandDependencies) {
    super();
    this.domain = dependencies.domain;
    this.groundSnowLoad = dependencies.groundSnowLoad;
  }

  async execute(): Promise<void> {
    this.domain.project.designParameters.snow.setGroundSnowLoad(this.groundSnowLoad);
  }
}
