import { BaseTool } from '../Tool';
import { ROOF_TRACING_TOOL_ID } from './constants';

export class RoofTracingTools extends BaseTool {
  id: string = ROOF_TRACING_TOOL_ID;
  icon: string = 'tracing-tool';
  title: string = 'Tracing tools';
  description: string = this.title;
  override testId: string = 'TraceRoofToolContainer';

  whenSelected(): void {
    //
  }

  whenDeselected(): void {
    // console.info('The subtool bar should be hidden.');
  }

  dispose(): void {
    // console.info('The subtool bar should be hidden.');
  }
}
