import type { Color } from '@aurorasolar/lyra-ui-kit';
import {
  Color as ThreeColor, Vector2, Vector3
} from 'three';
import { Line2 as Line } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { ViewPort } from '../../stores/EditorStore/ViewportController';
import { getVectorAngle } from '../../utils/math';
import {
  HALF_PI, SceneObjectType
} from '../models/Constants';
import type { Vector2D } from '../typings';
import type ERoofFaceEdgeType from '../typings/ERoofFaceEdgeType';
import { WithLengthLabels } from '../mixins/WithLengthLabels';
import { Unzoomable } from '../mixins/Unzoomable';
import { Selectable } from '../mixins/Selectable';
import { Drawable } from '../mixins/Drawable';
import type { Vertex } from './Vertex';

const MixedClass = WithLengthLabels(Unzoomable(Selectable(Drawable(class SimpleClass {}))));

export enum SegmentStyle {
  SOLID,
  DASHED,
  DOUBLE_DASHED
}

interface SegmentProps {
  v1: Vertex;
  v2: Vertex;
  thickness?: number;
  color?: Color;
  segmentStyle?: SegmentStyle;
  showSegmentLength?: boolean;
}

export class Segment extends MixedClass {
  propertyId: string = SceneObjectType.Segment;
  selectWithParent: boolean = false;
  points: Vertex[] = [];
  angle: number;
  length: number;
  override type?: ERoofFaceEdgeType;
  override color: Color = 0xffffff;
  line: Line;
  dottedLine?: Line;

  private resolution: Vector2;
  private zoomFactor = 1;
  private defaultThickness = 3;
  private segmentStyle = SegmentStyle.SOLID;
  /** dashSize changes during zooming, dashScale mustn't */
  private dashSize = 3;
  private readonly dashScale = this.dashSize;
  private showSegmentLength: boolean = false;

  constructor({
    v1, v2, thickness, color, segmentStyle, showSegmentLength
  }: SegmentProps) {
    super();
    this.points.push(v1, v2);
    const canvasResolution = ViewPort.resolution;
    this.resolution = new Vector2(canvasResolution.x, canvasResolution.y);

    const vec1: Vector3 = new Vector3(v1.x, v1.y, v1.z);
    const vec2: Vector3 = new Vector3(v2.x, v2.y, v2.z);

    this.length = vec1.distanceTo(vec2);
    this.angle = getVectorAngle(
      {
        x: v1.x - v2.x,
        y: v1.y - v2.y
      },
      HALF_PI
    );

    if (thickness) {
      this.defaultThickness = thickness;
    }

    if (color) {
      this.color = color;
    }

    if (showSegmentLength) {
      this.showSegmentLength = showSegmentLength;
    }

    this.segmentStyle = segmentStyle ?? SegmentStyle.SOLID;

    const geometry = new LineGeometry();
    const positions: number[] = [v1.x, v1.y, v1.z, v2.x, v2.y, v2.z];
    geometry.setPositions(positions);

    const dashOptions =
      this.segmentStyle !== SegmentStyle.SOLID
        ? {
          dashed: true,
          gapSize: this.dashSize * 5,
          dashSize: this.dashSize * 5
        }
        : {};

    const lineOptions = {
      color: this.color as number,
      linewidth: this.thickness,
      resolution: this.resolution,
      ...dashOptions
    };

    const lineMaterial = new LineMaterial({ ...lineOptions });

    this.line = new Line(geometry, lineMaterial);
    this.line.computeLineDistances();
    this.line.scale.set(1, 1, 1);
    this.mesh.add(this.line);
    if (this.segmentStyle === SegmentStyle.DOUBLE_DASHED) {
      this.dottedLine = new Line(
        geometry,
        new LineMaterial({
          ...lineOptions,
          dashed: true,
          gapSize: this.dashSize,
          dashSize: this.dashSize
        })
      );
      this.dottedLine.computeLineDistances();
      this.dottedLine.scale.set(1, 1, 1);
      this.mesh.add(this.dottedLine);
    }
    this.initSegmentLengthStyle();
  }

  updateLines(): void {
    this.line.computeLineDistances();
    this.dottedLine?.computeLineDistances();
  }

  private get thickness(): number {
    return this.defaultThickness * this.zoomFactor;
  }

  normalized(): Vector3 {
    const p1 = this.points[0].getVector3();
    const p2 = this.points[1].getVector3();
    return p2.clone().sub(p1)
      .normalize();
  }

  getShowSegmentLength(): boolean {
    return this.showSegmentLength;
  }

  setShowSegmentLength(show: boolean): void {
    this.showSegmentLength = show;
  }

  onCanvasResize(newSize: Vector2D): void {
    this.resolution.set(newSize.x, newSize.y);
    this.line.material.resolution = this.resolution;
  }

  override select(): void {
    if (!this.selected) {
      this.selected = true;
      this.redraw();
    }
  }

  override unselect(): void {
    if (this.selected) {
      this.selected = false;
      this.redraw();
    }
  }

  override unzoom(factor: number): void {
    this.dashSize = factor * this.mapZoomFactor * this.dashScale;
    this.redraw();
  }

  redraw(): void {
    const v1: Vertex = this.points[0];
    const v2: Vertex = this.points[1];
    const positions: number[] = [v1.x, v1.y, v1.z, v2.x, v2.y, v2.z];
    const vec1: Vector3 = new Vector3(v1.x, v1.y, v1.z);
    const vec2: Vector3 = new Vector3(v2.x, v2.y, v2.z);

    this.length = vec1.distanceTo(vec2);

    this.angle = getVectorAngle(
      {
        x: v1.x - v2.x,
        y: v1.y - v2.y
      },
      HALF_PI
    );

    const labelX = (v1.x + v2.x) / 2;
    const labelY = (v1.y + v2.y) / 2;
    this.renderSegmentLengthLabel({
      showSegmentLength: this.showSegmentLength,
      labelX,
      labelY,
      length: this.length
    });

    this.line.geometry.setPositions(positions);
    this.line.material.dashSize = this.dashSize * 5;
    this.line.material.gapSize = this.dashSize * 5;
    if (this.dottedLine) {
      this.dottedLine.material.dashSize = this.dashSize;
      this.dottedLine.material.gapSize = this.dashSize;
    }
    this.line.material.color = new ThreeColor(this.selected ? 0x00ff00 : this.color);
    this.line.material.linewidth = this.selected ? this.thickness * 2 : this.thickness;
  }
}
