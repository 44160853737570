import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { Color } from '../../../domain/typings';
import { RoofFaceAddedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Building } from '../../../domain/models/SiteDesign/Building';
import config from '../../../config/config';
import { BaseCommand } from './Command';

export interface IAddRoofFaceCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly newRoofFace: RoofFace;
  readonly color: Color;
  readonly name: string;
  readonly level: number;
  readonly building: Building;
  readonly templateRoofFace?: RoofFace;
}

export class AddRoofFaceCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly color: Color;
  private readonly name: string;
  private readonly level: number;
  private readonly newRoofFace: RoofFace;
  private readonly building: Building;
  private readonly templateRoofFace?: RoofFace;

  constructor(dependencies: IAddRoofFaceCommandDependencies) {
    super();
    const {
      newRoofFace, color, level, domain, name, building, templateRoofFace
    } = dependencies;
    this.newRoofFace = newRoofFace;
    this.color = color;
    this.name = name;
    this.level = level;
    this.domain = domain;
    this.building = building;
    this.templateRoofFace = templateRoofFace;
  }

  async execute(): Promise<void> {
    this.newRoofFace.color = this.color;
    this.newRoofFace.name = this.name;
    if (this.templateRoofFace) {
      this.newRoofFace.copySlopeAndStructuralPropertiesFrom(this.templateRoofFace);
    }
    this.newRoofFace.redraw();

    this.domain.addOrUpdateRoofFace(this.newRoofFace, this.level, this.building);

    config.analytics?.trackEvent(new RoofFaceAddedEvent(this.domain));
  }
}
