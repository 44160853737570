import { DeleteObjectCommand } from './DeleteObjectCommand';

export class DeleteParcelCommand extends DeleteObjectCommand {
  protected deleteFromEditor(): void {
    const object = this.object.mesh;
    this.editor.removeObject(object);
    this.editor.viewport.render();
  }

  protected deleteFromDomain(): void {
    this.domain.deleteParcelBoundary();
  }
}
