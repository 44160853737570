import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type {
  ElectricalDesignStage,
  IInverterInfo
} from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import { sectionTitle } from '../../SystemDesign/SectionTitle';
import StringingService from '../../../../services/stringing/stringingService';
import {
  Quantity, QuantityValue
} from '../CommonElectricalDesignRightMenuCards.styles';
import {
  ElectricalDesignCard,
  InverterDescription,
  InverterDescriptName,
  InverterDescriptTitle,
  InverterRow,
  InverterWrapper
} from './style';

interface IInverterProps {
  electricalDesignStage: ElectricalDesignStage;
  editable?: boolean;
}

const Inverter: React.FC<IInverterProps> = (props: IInverterProps): ReactElement => {
  const {
    electricalDesignStage, editable
  } = props;
  const inverterList = StringingService.inverters;

  const groupElements = (array: IInverterInfo[]): IInverterInfo[] => {
    if (array.length === 2 && array[0].model === array[1].model) {
      return [
        {
          model: array[0].model,
          units: 2
        }
      ];
    }
    return array;
  };

  const handleEditClick = useCallback((): void => {
    electricalDesignStage.editInverterAndDcOptimizer();
  }, [electricalDesignStage]);

  const electricalDesignProps = {
    editable,
    title: sectionTitle(inverterList.length === 2 ? 'inverters' : 'inverter'),
    onEditClick: handleEditClick
  };

  const invertersGrouped = groupElements(inverterList);
  return (
    <div data-testid="inverter-card">
      <ElectricalDesignCard {...electricalDesignProps}>
        <InverterWrapper>
          {invertersGrouped.map((inverter: IInverterInfo, index: number): ReactElement => {
            return (
              <InverterRow key={index}>
                <Quantity>
                  <QuantityValue data-testid="inverters-count">{inverter.units}</QuantityValue>
                </Quantity>
                <InverterDescription>
                  {inverterList.length === 2 && (
                    <InverterDescriptTitle>{inverter.units !== 2 && `Inverter ${index + 1}:`}</InverterDescriptTitle>
                  )}
                  <InverterDescriptName>{inverter.model}</InverterDescriptName>
                </InverterDescription>
              </InverterRow>
            );
          })}
        </InverterWrapper>
      </ElectricalDesignCard>
    </div>
  );
};

export default observer(Inverter);
