import isNil from 'lodash/isNil';
import {
  action, computed, observable, runInAction
} from 'mobx';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { ServiceBus } from '../../../stores/ServiceBus/ServiceBus';
import { DesignStep } from '../../models/Design/DesignState';
import type { Design } from '../../models/Design/Design';
import type Limit from '../../models/Limit';
import { isWithin } from '../../models/Limit';
import type { IStage } from '../IStage';
import type { DesignCreationWizardStore } from '../../../stores/UiStore/Wizard/DesignCreationWizardStore';
import type { IChangeDCPowerRatingTargetRequest } from '../../request/ArrayPlacement/IChangeDCPowerRatingTargetRequest';
import { handleApiError } from '../../../utils/helpers';
import { DesignService } from '../../../infrastructure/services/api/DesignService';
import type { DesignDelta } from '../../entities/Design/DesignDelta';
import { DcPowerRatingTargetSpecifiedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../config/config';

export interface INewSystemSizeStageDeps {
  readonly isDesignCreation: boolean;
  readonly domain: DomainStore;
  readonly designCreationWizardStore?: DesignCreationWizardStore;
  readonly serviceBus?: ServiceBus;
  readonly onContinue?: () => void;
}

export class NewSystemSizeStage implements IStage {
  readonly id: string = 'new_system_size_stage';
  readonly limits: Limit = {
    lower: 0.1,
    upper: 35
  };
  readonly propCodeUI = 'new_system_size_modal';

  @observable
  dcPowerRatingTargetInKw: number = 0;

  readonly isDesignCreation: boolean;
  private readonly designService = new DesignService();
  private readonly designCreationWizardStore?: DesignCreationWizardStore;
  private readonly domain: DomainStore;
  private readonly currentDesign?: Design;
  private readonly serviceBus?: ServiceBus;
  private readonly onContinue?: () => void;

  constructor(dependencies: INewSystemSizeStageDeps) {
    const {
      isDesignCreation, domain, serviceBus, designCreationWizardStore, onContinue
    } = dependencies;
    this.isDesignCreation = isDesignCreation;
    this.domain = domain;
    if (this.isDesignCreation) {
      this.designCreationWizardStore = designCreationWizardStore;
      this.setInitialValuesFromIncompleteWizard();
    } else {
      this.currentDesign = this.domain.design;
      this.serviceBus = serviceBus;
      this.onContinue = onContinue;
      this.setInitialValuesFromDomainModel();
    }
  }

  @action.bound
  private setInitialValuesFromIncompleteWizard(): void {
    this.dcPowerRatingTargetInKw = this.designCreationWizardStore!.dcPowerRatingTargetInKw;
  }

  @action.bound
  private setInitialValuesFromDomainModel(): void {
    this.dcPowerRatingTargetInKw = this.currentDesign!.designSpecification.dcPowerRatingTargetInKw;
  }

  @action.bound
  onSystemSizeChange(value: number): void {
    if (isNil(value)) {
      return;
    }
    this.dcPowerRatingTargetInKw = value;
  }

  @computed
  get canContinue(): boolean {
    return !!this.dcPowerRatingTargetInKw && isWithin(this.limits, this.dcPowerRatingTargetInKw);
  }

  @action.bound
  continue(): void {
    if (this.isDesignCreation) {
      this.designCreationWizardStore!.dcPowerRatingTargetInKw = this.dcPowerRatingTargetInKw;
    } else {
      if (this.currentDesign!.state.isUserIn(DesignStep.ARRAY_PLACEMENT)) {
        this.updateDcPowerRatingTarget();
      }
    }
    config.analytics?.trackEvent(new DcPowerRatingTargetSpecifiedEvent(this.domain, this.dcPowerRatingTargetInKw));
  }

  private updateDcPowerRatingTarget(): void {
    const IDesignData = this.currentDesign!.toData();
    const payload: IChangeDCPowerRatingTargetRequest = {
      dcPowerRatingTarget: Math.round(this.dcPowerRatingTargetInKw * 1000),
      design: IDesignData
    };
    this.designService
      .updateDcPowerRatingTarget(payload)
      .then((response: DesignDelta): void => {
        runInAction((): void => {
          this.serviceBus!.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        });
        this.onContinue?.();
      })
      .catch(handleApiError('Failed to update DC power rating target'));
  }

  cancel(): void {
    /** */
  }

  dispose(): void {
    // Deallocate stuff here
  }

  resume(lastValidStage: string): void {
    /** Not needed */
  }
}
