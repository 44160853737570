import {
  action, observable
} from 'mobx';
import intersection from 'lodash/intersection';
import { getIdListByType } from '../../../services/stringing/stringingFunctions';
import {
  PvSystemCircuit, type IPvSystemCircuitData
} from './PvSystemCircuit';
import { Equipment } from './Equipment';
import type {
  PowerConversionAndStorageEquipmentDefinition, IEquipmentData
} from './Equipment';

export enum BackupStrategy {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE'
}

interface ISystemFeaturesData {
  readonly backupPower?: BackupStrategy;
}

export interface IPvSystemData {
  readonly equipment: IEquipmentData;
  readonly circuits?: IPvSystemCircuitData[];
  readonly features?: ISystemFeaturesData;
}

export class PvSystem {
  equipment: Equipment;
  circuits: PvSystemCircuit[];
  @observable
  features?: ISystemFeaturesData;

  constructor(data: IPvSystemData) {
    this.equipment = new Equipment(data.equipment);
    this.circuits =
      data.circuits?.map((circuit: IPvSystemCircuitData): PvSystemCircuit => new PvSystemCircuit(circuit)) ?? [];
    this.features = data.features;
  }

  toData(): IPvSystemData {
    return {
      equipment: this.equipment.toData(),
      circuits: this.circuits.length
        ? this.circuits.map((circuit: PvSystemCircuit): IPvSystemCircuitData => circuit.toData())
        : undefined,
      features: this.features
    };
  }

  @action
  deletePvModulesInPositions = (idsOfPvModulePositionsToDelete: string[]): void => {
    const shouldDisconnectCircuits = this.circuits.some((circuit: PvSystemCircuit): boolean => {
      const pvModulesInCircuit = [
        ...getIdListByType({
          circuitSources: [circuit],
          sourceType: 'MODULE'
        }),
        ...getIdListByType({
          circuitSources: [circuit],
          sourceType: 'AC_MODULE'
        })
      ];
      const pvModulesInEquipment = this.equipment.pvModules.instanceIdsOnPositions(idsOfPvModulePositionsToDelete);
      return intersection(pvModulesInCircuit, pvModulesInEquipment).length !== 0;
    });

    this.equipment.deletePvModulesInPositions(idsOfPvModulePositionsToDelete);
    if (shouldDisconnectCircuits) {
      this.disconnectCircuits();
    }
  };

  /**
   * Clears circuits along with all microinverter and DC optimizer instances
   */
  @action
  disconnectCircuits = (): void => {
    this.circuits = [];
    this.equipment.inverters?.clearMicroinvertersIfPresent();
    this.equipment.optimizers?.clearInstancesIfPresent();
  };

  /**
   * Checks whether the current power conversion and storage equipment and features match the given specification
   */
  doesPowerConversionAndStorageMatch = (specification: PowerConversionAndStorageEquipmentDefinition): boolean => {
    const currentBackupStrategy = this.features?.backupPower ?? BackupStrategy.NONE;
    const specifiedBackupStrategy = specification.energyStorageAndBackup?.backupStrategy ?? BackupStrategy.NONE;
    return (
      currentBackupStrategy === specifiedBackupStrategy
      && this.equipment.doesPowerConversionEquipmentMatch(specification)
    );
  };
}
