import type { BaseProps } from 'react';
import styled, {
  PropsTheme, withTheme
} from 'styled-components';

type TooltipPositionProps = BaseProps & {
  tooltipPosition?: 'top' | 'bottom';
  arrowStyles?: number;
  arrowPosition?: 'top' | 'bottom';
};

type TooltipWrapperProps = BaseProps & TooltipPositionProps & PropsTheme & {
  tooltipPosition?: 'top' | 'bottom';
  tooltipTheme?: 'dark' | 'white';
  tooltipVisibility?: boolean;
  tooltipContentAlign?: string;
};

function calculateTooltipContent ({ tooltipPosition }: TooltipPositionProps): string {
  if (tooltipPosition === 'bottom') {
    return `
      left: 50%;
      top: 45px;
    `;
  }
  return `
    bottom: 100%;
    left: 50%;
  `;
}

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  min-width: 100%;
  position: absolute;
  display: ${({ tooltipVisibility }: TooltipWrapperProps): string => (tooltipVisibility ? 'block' : 'none')};
  ${({ tooltipPosition }: TooltipPositionProps): string => calculateTooltipContent({ tooltipPosition })};
  text-align: ${({ tooltipContentAlign }: TooltipWrapperProps): string => (tooltipContentAlign ?? 'center')};
  transform: translate(-50%);
  z-index: 2;
  
  a {
    text-decoration: none;
    color: rgb(66, 126, 205);
  }
`;

function setTooltipTheme({ tooltipTheme, theme }: TooltipWrapperProps): string {
  if (tooltipTheme === 'dark') {
    return `
      background-color: ${theme.colors.black};
      color: ${theme.colors.whiteBg}
    `;
  } else {
    return `
      background-color: ${theme.colors.whiteBg};
    `;
  }
}

export const TooltipStyle = styled.div<TooltipWrapperProps>`
  ${({ tooltipTheme, theme }: TooltipWrapperProps): string => setTooltipTheme({ tooltipTheme, theme })};
  box-shadow: rgba(0, 0, 0, 0.5) 2px 3px 9px 1px;
  border-radius: 5px;
`;

export const TooltipConternt = styled.div`
  padding: 10px;
  color: rgb(74, 74, 74);
`;
export const Arrow = styled.span<TooltipPositionProps>`
  background-color: inherit;
  border-bottom: none;
  border-right: none;
  display: block;
  height: 12px;
  position: absolute;
  transform: rotate(45deg);
  width: 12px;
  ${({ arrowPosition }: TooltipPositionProps): string => arrowPosition ? `${arrowPosition}: -5px` : ''};
  left: ${({ arrowStyles }: TooltipPositionProps): string => arrowStyles ? `calc(${arrowStyles}% - 6px)` : `calc(50% - 6px)`};
`;
