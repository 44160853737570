import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import { ListButtons } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import type {
  StringConnected,
  SystemSummary,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';

import StringingService from '../../../../services/stringing/stringingService';
import MicroInvertersInBranch from './MicroInvertersInBranch';
import {
  ContainerBranch, ListButtonsContainer, TitleBranch, TitleBranchContainer
} from './MicroinverterStyle';
import MicroinverterOrACModuleSystemPanel from './MicroinverterOrACModuleSystemPanel';
import {
  DownArrow, PanelContent, PanelHeader, PanelText, RightArrow, SummaryContentWrapper
} from './styles';

const MicroInverterComponent = observer((): ReactElement => {
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);

  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;

  const [systemExpanded, setSystemExpanded] = useState(true);

  const summaryPanelContent = systemSummaryList.map((systemSummary: SystemSummary, index: number): ReactElement => {
    const stringsConnected = systemSummary.strings;

    const optionsListButtons: OptionProps[] = stringsConnected.map(
      (item: StringConnected, idx: number): OptionProps => ({
        id: item.getId(),
        value: (idx + 1).toString()
      })
    );
    const selected =
      optionsListButtons.find((option: OptionProps): boolean => selectedString === option.id)?.value ?? '1';

    const onDeleteActiveBranch = (): void => {
      if (StringingService.activeSelectedString) {
        systemSummaryViewModel.callDeleteStringCommand(`Branch ${selected}`, StringingService.activeSelectedString);
      } else if (StringingService.strings[+selected - 1]) {
        systemSummaryViewModel.callDeleteStringCommand(`Branch ${selected}`, StringingService.strings[+selected - 1]);
      }
    };

    return (
      <SummaryContentWrapper key={index}>
        <PanelHeader onClick={(): void => setSystemExpanded(!systemExpanded)}>
          <PanelText>System</PanelText>
          {systemExpanded ? <DownArrow name="arrow-collapse" /> : <RightArrow name="arrow-collapse" />}
        </PanelHeader>
        {systemExpanded && (
          <PanelContent>
            <MicroinverterOrACModuleSystemPanel index={index} />
          </PanelContent>
        )}
        <ContainerBranch>
          <TitleBranchContainer>
            <TitleBranch>Branch</TitleBranch>
          </TitleBranchContainer>
          {!!optionsListButtons.length && (
            <ListButtonsContainer>
              <ListButtons
                onClickEvent={(response: OptionProps): void => {
                  systemSummaryViewModel.setSelectedString(response.id);
                  StringingService.selectStringById(response.id);
                }}
                selected={selected}
                options={optionsListButtons}
              />
            </ListButtonsContainer>
          )}
        </ContainerBranch>
        <MicroInvertersInBranch index={index} onDeleteActiveBranch={onDeleteActiveBranch} />
      </SummaryContentWrapper>
    );
  });
  return <>{summaryPanelContent}</>;
});

export default MicroInverterComponent;
