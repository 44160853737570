import partition from 'lodash/partition';
import {
  action, computed, observable
} from 'mobx';
import type { IMountingSystemDefinitionsData } from '../MountingSystemDefinition/MountingSystemDefinitions';
import { MountingSystemDefinitions } from '../MountingSystemDefinition/MountingSystemDefinitions';
import type { MountingSystemDefinition } from '../MountingSystemDefinition/MountingSystemDefinition';
import { MountingSystem } from './MountingSystem';
import type { IMountingSystemData } from './MountingSystem';

export interface IMountingSystemsData {
  readonly definitions: IMountingSystemDefinitionsData;
  readonly templates?: IMountingSystemData[];
  readonly instances?: IMountingSystemData[];
}

export class MountingSystems {
  @observable
  definitions: MountingSystemDefinitions;
  @observable
  templates?: MountingSystem[];
  @observable
  instances?: MountingSystem[];

  constructor(data: IMountingSystemsData) {
    this.definitions = new MountingSystemDefinitions(data.definitions);
    this.templates = data.templates
      ? data.templates.map((template: IMountingSystemData): MountingSystem => new MountingSystem(template))
      : undefined;
    this.instances = data.instances
      ? data.instances.map((instance: IMountingSystemData): MountingSystem => new MountingSystem(instance))
      : undefined;
  }

  toData(): IMountingSystemsData {
    return {
      definitions: this.definitions.toData(),
      templates: this.templates?.map((template: MountingSystem): IMountingSystemData => template.toData()),
      instances: this.instances?.map((instance: MountingSystem): IMountingSystemData => instance.toData())
    };
  }

  @action
  getOrCreateMountingSystemOn(roofFaceId: string): MountingSystem {
    const existingMountingSystem = this.mountingSystemOn(roofFaceId)!;
    if (existingMountingSystem.isInstance) {
      return existingMountingSystem;
    }
    this.templates = this.templates?.filter((template: MountingSystem): boolean => template.arrayAreaId !== roofFaceId);
    const templateConvertedToInstance = existingMountingSystem.toInstance();
    if (!this.instances) {
      this.instances = [];
    }
    this.instances.push(templateConvertedToInstance);
    return templateConvertedToInstance;
  }

  @action
  convertInstancesToTemplatesIfNeeded = (idsOfMountingSystemsInUse: Set<string>): void => {
    const [updatedInstances, instancesToBeConvertedToTemplates] = partition(
      this.instances,
      (mountingSystem: MountingSystem): boolean => idsOfMountingSystemsInUse.has(mountingSystem.id!)
    );
    if (!instancesToBeConvertedToTemplates.length) {
      return;
    }
    this.instances = updatedInstances;
    if (!this.templates) {
      this.templates = [];
    }
    instancesToBeConvertedToTemplates.forEach((mountingSystem: MountingSystem): void => {
      this.templates!.push(mountingSystem.toTemplate());
    });
  };

  mountingSystemOn = (arrayAreaId: string): MountingSystem | undefined => {
    return (
      this.instances?.find((instance: MountingSystem): boolean => instance.isOn(arrayAreaId))
      ?? this.templates?.find((template: MountingSystem): boolean => template.isOn(arrayAreaId))
    );
  };

  @computed
  get hasSteepSlopeMountingSystems(): boolean {
    return (
      !!this.instances?.some((instance: MountingSystem): boolean => instance.definitionId === 'steepSlope')
      || !!this.templates?.some((template: MountingSystem): boolean => template.definitionId === 'steepSlope')
    );
  }

  @computed
  get hasLowSlopeMountingSystems(): boolean {
    return (
      !!this.instances?.some((instance: MountingSystem): boolean => instance.definitionId === 'lowSlope')
      || !!this.templates?.some((template: MountingSystem): boolean => template.definitionId === 'lowSlope')
    );
  }

  definitionFor = (arrayAreaId: string): MountingSystemDefinition => {
    const mountingSystem = this.mountingSystemOn(arrayAreaId);
    if (!mountingSystem) {
      throw new Error(`Mounting system for array area ${arrayAreaId} was not found`);
    }
    return mountingSystem.definitionId === 'steepSlope' ? this.definitions.steepSlope! : this.definitions.lowSlope!;
  };
}
