import Registry from '../../../utils/Registry';
import {
  AZIMUTH_CODE,
  FRAMING_CODE,
  ROOF_DECKING_CODE,
  ROOF_CONDITION_CODE,
  SLOPE_CODE,
  SOLAR_ACCESS_CODE,
  SURFACE_CODE
} from './constants';
import AzimuthCompass from './containers/AzimuthCompass/AzimuthRoofFace';
import { FramingRoofFace } from './containers/Framing/FramingRoofFace';
import RoofDecking from './containers/RoofDecking/RoofDecking';
import RoofCondition from './containers/RoofCondition/RoofCondition';
import SlopeRoofFace from './containers/Slope/SlopeRoofFace';
import SolarAccess from './containers/SolarAccess/SolarAccess';
import SurfaceRoofFace from './containers/Surface/SurfaceRoofFace';

const propsRegistry = new Registry();

propsRegistry.add(SLOPE_CODE, SlopeRoofFace);
propsRegistry.add(AZIMUTH_CODE, AzimuthCompass);
propsRegistry.add(FRAMING_CODE, FramingRoofFace);
propsRegistry.add(ROOF_DECKING_CODE, RoofDecking);
propsRegistry.add(ROOF_CONDITION_CODE, RoofCondition);
propsRegistry.add(SURFACE_CODE, SurfaceRoofFace);
propsRegistry.add(SOLAR_ACCESS_CODE, SolarAccess);

export { propsRegistry };
