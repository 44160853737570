import { canvasConfig } from '../../../../../config/canvasConfig';
import { GasMeter } from '../../../../../domain/models/SiteDesign/GasMeter';
import { GM_TOOL_ID } from '../constants';
import type { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import type { IBaseSiteEquipmentToolDependencies } from './BaseSiteEquipmentTool';
import { BaseSiteEquipmentTool } from './BaseSiteEquipmentTool';

export class GasMeterTool extends BaseSiteEquipmentTool {
  override readonly id: string = GM_TOOL_ID;
  override readonly icon: string = 'GM';
  override readonly title: string = 'Gas Meter';
  override readonly description: string = this.title;

  constructor(dependencies: IBaseSiteEquipmentToolDependencies) {
    super(dependencies);
  }

  override createEquipment = (): Marker => {
    return this.drawableObjectsFactory.create<GasMeter>(GasMeter, {
      color: canvasConfig.gasMeterIconColor
    });
  };

  override getEquipment(): Marker[] {
    const gasMeter = this.domain.siteEquipment?.gasMeter;
    if (gasMeter) {
      return [gasMeter as Marker];
    }
    return [];
  }
}
