import type {
  FC, ReactElement
} from 'react';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useStore from '../../../stores/useStore';
import config from '../../../config/config';

const TitleUpdater: FC = (): ReactElement => {
  const { domain } = useStore();
  const projectId = domain.project?.id;
  const address = domain.project?.site.address;

  useEffect((): void => {
    if (config.featureFlag.branding.showTabTitle && domain.project?.site?.address) {
      const internalRefIdPrefix = domain.internalReferenceId ? `ID ${domain.internalReferenceId} - ` : '';
      document.title = `${internalRefIdPrefix}${domain.project.site.address.asFormattedText} - Lyra Solar`;
    }
  }, [projectId, address, domain.internalReferenceId, domain.project?.site?.address]);

  return <></>;
};

export default observer(TitleUpdater);
