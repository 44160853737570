import styled from 'styled-components';
import Flexbox from '../../../../ui/components/Layout/Flexbox';

type HideProps = {
  hideLabel?: boolean;
};

export const ShowHideStyle = styled(Flexbox).attrs({
  dir: 'column',
  align: 'center',
  justify: 'space-evenly'
})<HideProps>`
  height: 100%;
  display: ${({ hideLabel }: HideProps): string => (!hideLabel ? 'none' : '')};
  visibility: ${({ hideLabel }: HideProps): string => (!hideLabel ? 'hidden' : 'visible')};
`;

export const Button = styled(Flexbox).attrs({
  align: 'center',
  justify: 'center'
})`
  width: 50px;
  height: 20px;
  padding: 2px;
  border: solid 1px #d3d3d3;
  border-radius: 4px;
  background-color: #fff;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonLabel = styled.div`
  font-size: 12px;
`;

export const IconContainer = styled.div`
  /*  */
`;
