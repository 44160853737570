import React, { BaseProps, ReactElement, ReactNode, useCallback } from 'react';
import { Icon } from '../Icon';
import { NavItemStyle, NavItemText } from './styles';

type NavItemProps = BaseProps & {
  icon?: string;
  active?: boolean;
  children: string | ReactNode;
  onClick?: () => void;
};

const NavItemLayout = ({
  className,
  icon,
  children,
  active,
  onClick
}: NavItemProps): ReactElement => {
  const onClickHandler = useCallback(
    (): void => {
      if (typeof onClick === 'function') onClick();
    },
    [onClick, active]);
  return <NavItemStyle
    dir="row"
    className={className}
    onClick={(): void => {
      onClickHandler();
    }}
    active={active}
  >
    {icon &&
      <Icon
        name={icon}
        style={{ width: 24, height: 24, marginLeft: 40, marginRight: 12 }}
      />
    }
    {typeof children === 'string' &&
      <NavItemText active={active}>{children}</NavItemText>
    }
    {typeof children !== 'string' && children}
  </NavItemStyle>;
};

export { NavItemLayout, NavItemProps };
