import styled, { PropsTheme } from 'styled-components';
import { H2, H3, H4, Paragraph, H1 } from '../FontFamily/Fonts';
import { Icon } from '../Icon';
import { Flexbox } from '../Layout';

export const SummaryContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsTheme): string => `${theme.colors.grey4}`};
  background-color: ${({ theme }: PropsTheme): string => `${theme.colors.grayColor11}`};
  position: relative;
  box-sizing: border-box;
  @media (max-height: 768px) {
    padding: 0;
  }
`;

export const SummaryHeader = styled(H2)`
  font-size: 26px;
  font-weight: 300;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grey1}`};
  padding: 16px 30px;
  border-bottom: solid 1px ${({ theme }: PropsTheme): string => `${theme.colors.grey4}`};
`;

export const SummaryContent = styled.div`
  overflow-y: auto;
  max-height: 400px;
  padding: 0 30px;
  margin-right: 3px;
  @media (max-height: 768px) {
    max-height: 260px;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const ContentLeftView = styled.div`
  width: 60%;
  display: flex;
`;

export const ContentRightView = styled.div`
  width: 40%;
  text-align: right;
`;

export const ThinText = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grey1}`};
  line-height: normal;
`;

export const ThinPriceText = styled(ThinText)`
  font-weight: bold;
`;

export const TitleText = styled(H4)`
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grey1}`};  
`;

export const SummaryFooter = styled.div`
  border-top: solid 1px ${({ theme }: PropsTheme): string => `${theme.colors.grey4}`};
  display: flex;
  margin-top: 20px;
  padding: 20px 30px;
`;

export const SummarySubFooter = styled(SummaryFooter)`
  margin-top: 20px;
  display: block;
  padding: 0 30px;
`;

export const SubFooterRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const FooterLeftView = styled.div`
  width: 50%;
`;

export const FooterRightView = styled(FooterLeftView)`
  text-align: right;
`;

export const BoldText = styled(H3)`
  font-size: 19px;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grey1}`};  
  font-weight: bold;
`;

export const PriceText = styled(BoldText)`
  color: ${({ theme }: PropsTheme): string => `${theme.colors.greenColor1}`};  
`;

export const AdjustmentView = styled.div`
  margin-top: 30px;
`;

export const CorrectionRow = styled(ContentRow)`
  margin-top: 20px;
`;

export const CorrectionIcon = styled(Icon)`
  padding-top: 4px;
  margin-right: 13px;
`;

export const InfoContainer = styled(Flexbox).attrs({
  dir: 'column',
  justify: 'center',
  align: 'center'
})`
  width: calc(100% - 140px);
  height: 500px;
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
  @media (max-height: 768px) {
    height: 398px;
  }
`;

export const InfoHeading = styled(H1)`
  font-size: 26px;
  font-weight: 300;
  color: ${({ theme }: PropsTheme): string => `${theme.colors.grey1}`};  
`;

export const InfoIcon = styled(Icon)`
  margin-bottom: 40px;
`;

export const InfoText = styled(TitleText)`
  margin-top: 20px;
  width: 280px;
`;

export const BlueIcon = styled(Icon)``;

export const CenterView = styled.div`
  position: relative;
  width: 165px;
  height: 165px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  ${BlueIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
`;

