import type { Enclosure } from '../effects/RecentValuesRegistry';
import type {
  EngineCombinedCondition,
  EngineCondition,
  EngineConditionEquals,
  EngineConditionEqualsOneOf,
  EngineConditionIncludesAllTags,
  EngineConditionNotEquals,
  EngineEvent, EngineRule
} from '../engine/Engine';
import { encodeFieldName } from '../fieldNameUtils';
import type {
  FormConditionWithPredicate,
  FormEffectWithFields,
  FormCondition,
  FormConditionCombinedConditions,
  FormConditionEquals,
  FormConditionEqualsOneOf,
  FormConditionIncludesAllTags,
  FormConditionNotEqual,
  FormEffect,
  FormFieldValue,
  FormRule,
  HideFieldsFormEffect,
  FormEffectWithOptions,
  FormConditionWithFields,
  PredicateWithArguments,
  DataSchema,
  FormField
} from '../FormOptionsRulesAndState';
import encodeValue from './values';

export default (
  apiRules: FormRule[], enclosure: Enclosure, apiSchema: DataSchema
) => {
  const result = [] as EngineRule[];

  for (const apiRule of apiRules) {
    encodeConditionFieldName(
      apiRule.condition, apiSchema.fields
    );
    if ((apiRule.effect as FormEffectWithOptions).field) {
      const effect = apiRule.effect as FormEffectWithOptions;
      effect.field = encodeFieldName(effect.field);
    }

    if (Array.isArray(apiRule.effect)) {
      apiRule.effect = apiRule.effect.map((effect: FormEffect): FormEffect => {
        const hideFieldsEffect = effect as HideFieldsFormEffect;
        const effectWithFields = effect as FormEffectWithFields;
        if (effect.type === 'hideFields') {
          effect.type = 'hideField';
        }
        if (hideFieldsEffect.fields) {
          hideFieldsEffect.field = [...hideFieldsEffect.fields];
          delete hideFieldsEffect.fields;
        }
        if (Array.isArray(effectWithFields.field)) {
          effectWithFields.field = effectWithFields.field.map(encodeFieldName);
        } else {
          effectWithFields.field = encodeFieldName(effectWithFields.field);
        }
        return effect;
      });
    }

    if (!Array.isArray(apiRule.effect) && apiRule.effect.type === 'hideFields') {
      apiRule.effect.type = 'hideField';

      if (apiRule.effect.fields) {
        apiRule.effect.field = apiRule.effect.fields.map((field): string => encodeFieldName(String(field)));
        delete apiRule.effect.fields;
      }
    }

    const conditions = processCondition(
      apiRule.condition, enclosure
    );
    result.push({
      conditions,
      event: processEffect(
        apiRule, conditions, enclosure, apiSchema.fields
      )
    });
  }
  return result;
};

const encodeConditionFieldName = (
  condition: FormCondition, fields: FormField[]
): void => {
  const conditionWithFields = condition as FormConditionWithFields;
  if (conditionWithFields.field) {
    const encodedConditionFieldName = encodeFieldName(conditionWithFields.field);
    const group = fields.find(field => encodeFieldName(field.id) === encodedConditionFieldName)?.group;
    conditionWithFields.field = `${group}.${encodedConditionFieldName}`;
  } else {
    for (const combinationKey in condition) {
      (condition as FormConditionCombinedConditions)[combinationKey as keyof FormConditionCombinedConditions]
        .forEach?.((condition: FormCondition): void => encodeConditionFieldName(
          condition, fields
        ));
    }
  }
};

const processCondition = (
  condition: FormCondition, enclosure: Enclosure
): EngineCondition => {
  let result: EngineCondition = {};

  const conditionWithPredicate = condition as FormConditionWithPredicate;
  const conditionWithFields = condition as FormConditionWithFields;

  if (conditionWithPredicate.predicate === 'always') {
    return {
      // dummy field needed to maintain predicate library syntax
      alwaysField: 'always'
    };
  } else if (conditionWithPredicate.predicate === 'never') {
    return {
      // dummy field needed to maintain predicate library syntax
      neverField: 'never'
    };
  }

  if (conditionWithFields.field) {
    result[conditionWithFields.field as keyof EngineCondition] = {};
  }

  const predicateFunction = predicateMap[
    conditionWithPredicate.predicate ?? Object.keys(condition as FormConditionCombinedConditions)[0]
  ] as (condition: FormCondition, enclosure?: Enclosure) => EngineCondition;
  if (conditionWithFields.field) {
    result[conditionWithFields.field] = predicateFunction(
      conditionWithFields, enclosure
    );
  } else {
    result = predicateFunction(
      condition, enclosure
    );
  }
  return result;
};

const processEquals = (condition: FormConditionEquals): EngineConditionEquals => ({
  is: encodeValue(condition.value)
});

const processEqualsOneOf = (condition: FormConditionEqualsOneOf): EngineConditionEqualsOneOf => ({
  or: condition.values.map((v): { is: FormFieldValue } => ({ is: encodeValue(v) }))
});

const processNotEqual = (condition: FormConditionNotEqual): EngineConditionNotEquals => ({
  not: {
    equal: encodeValue(condition.value)
  }
});

const processIncludesAllTags = (condition: FormConditionIncludesAllTags): EngineConditionIncludesAllTags => ({
  includesAllTags: condition.tags
});

const processCombinedCondition = (
  condition: FormConditionCombinedConditions,
  enclosure: Enclosure
): EngineCombinedCondition => {
  const result = {} as EngineCombinedCondition;
  const key = Object.keys(condition)[0];
  switch (key) {
    case 'allOf':
      condition[key].forEach((nestedCondition): void => {
        const typedCondition = nestedCondition as FormConditionEquals
                                                  & FormConditionEqualsOneOf
                                                  & FormConditionNotEqual
                                                  & FormConditionIncludesAllTags
                                                  & FormConditionCombinedConditions;
        result[typedCondition.field] = predicateMap[
          typedCondition.predicate as PredicateWithArguments
        ](
          typedCondition, enclosure
        );
      });
      break;
    // TODO: (LYRA-8368) -- enable when adding support for `anyOf`
    // case 'anyOf':
    //   result.or = condition[key].map((nestedCondition) => processCondition(nestedCondition, enclosure));
    //   break;
    default:
      throw new Error(`Rule condition ${key} is not supported`);
  }
  return result;
};

/**
 * A map of either:
 * a) a predicate to substitute directly (e.g. `'is'`), or
 * b) a function to implement the conversation
 */
const predicateMap = {
  equals: processEquals,
  equalsOneOf: processEqualsOneOf,
  notEqual: processNotEqual,
  includesAllTags: processIncludesAllTags,
  // TODO: (LYRA-8368) -- enable when adding support for `anyOf`
  // anyOf: processCombinedCondition,
  allOf: processCombinedCondition
};

// TODO: (LYRA-8369) -- move logic related to this object inside separate file, akin to `validation.ts`
const effectMap: Record<string, string> = {
  hideField: 'hideFields'
};


export type EffectParamKey = 'field' | 'options' | 'tags' | 'constrainedBy' | 'defaults';

// TODO: THis should be done by introspection
// effect properties that map to 'param's in events
const effectParamMap: Record<string, EffectParamKey[]> = {
  hideField: ['field'],
  hideOptions: ['field', 'options'],
  showOptions: ['field', 'options'],
  hideOptionsWithAllTags: ['field', 'tags'],
  enableOptionsLessThanOrEqual: ['field', 'constrainedBy'],
  showOptionsLessThanOrEqual: ['field', 'constrainedBy'],
  applyDefaults: ['defaults']
};

const processEffect = (
  rule: FormRule,
  conditions: EngineCondition,
  enclosure: Enclosure,
  formFields: FormField[]
): EngineEvent | EngineEvent[] => {
  return Array.isArray(rule.effect)
    ? rule.effect.map((e: FormEffect) => _processEffect(
      e, conditions, enclosure, formFields
    ))
    : _processEffect(
      rule.effect, conditions, enclosure, formFields
    );
};

const _processEffect = (
  effect: FormEffect,
  conditions: EngineCondition,
  enclosure: Enclosure,
  formFields: FormField[]
) => {
  const result: EngineEvent = {
    type: effectMap[effect.type] || effect.type,
    // condition and Engine enclosure are always provided as params
    params: {
      enclosure,
      conditions
    }
  };

  const paramsInfo: EffectParamKey[] = effectParamMap[effect.type];
  for (const p of paramsInfo) {
    let paramValueInApiSchema: FormFieldValue | FormFieldValue[] = effect[p as keyof FormEffect];
    if (p === 'options' && Array.isArray(paramValueInApiSchema)) {
      paramValueInApiSchema = paramValueInApiSchema.map(encodeValue);
    } else if (p === 'field') {
      if (Array.isArray(paramValueInApiSchema)) {
        paramValueInApiSchema = paramValueInApiSchema.map(field => prependGroupNameToFieldName(
          field, formFields
        ));
      } else {
        paramValueInApiSchema = prependGroupNameToFieldName(
          paramValueInApiSchema, formFields
        );
      }
    }
    result.params[p] = paramValueInApiSchema;
  }
  return result;
};

const prependGroupNameToFieldName = (
  paramValue: string, formFields: FormField[]
): string => {
  const group = formFields.find(field => encodeFieldName(field.id) === paramValue)?.group;
  return `${group}.${paramValue}`;
};
