/* eslint-disable react-hooks/exhaustive-deps */
import {
  observer, useObservable
} from 'mobx-react-lite';
import Draggable from 'react-draggable';
import type { ReactElement } from 'react';
import React, {
  useEffect, useRef, useState
} from 'react';
import type { LatLonCoords } from '../../../domain/models/SiteDesign/LatLonCoords';
import type { Coords } from '../../../domain/typings';
import { BaseImageryProvider } from '../../../domain/typings';
import useStore from '../../../stores/useStore';
import IconMapper from '../../../ui/components/Icons';
import config from '../../../config/config';
import {
  useStreetView, useMap, useGoogleMap
} from '../../../utils/maps';
import {
  ModalContentStyled,
  ModalControlStyled,
  ModalDialogStyled,
  ModalOvalStyled,
  ModalWrapperContent,
  TriangleBorderStyled,
  WrapperSVGStyled
} from './styles';

const ModalStreetView = observer((): ReactElement => {
  // Setting stores used in the component
  const {
    editor, domain
  } = useStore();

  const [center, setCenter] = useState({
    latitude: 0,
    longitude: 0
  } as Coords);

  // Ref Elements for map and street views containers
  const mapContainerRef = useRef({} as HTMLDivElement);
  const svContainerRef = useRef({} as HTMLDivElement);

  // State for step
  const state = useObservable({
    step: 0,
    showSV: true
  });

  // Global Config options
  const globalConfig = config.baseImageryConfig(BaseImageryProvider.GOOGLE_MAPS);
  // Instance of google maps using custom hook
  const google = useGoogleMap();

  useEffect((): void => {
    const coords = domain.project.site.coordinateSystemOrigin;
    if (!!coords.latitude && !!coords.longitude) {
      setCenter(coords);
    }
  }, [domain.project.site.coordinateSystemOrigin]);

  const {
    latitude: lat, longitude: lng
  } = center;
  const centerLatLng: LatLonCoords = {
    lat,
    lng
  };

  // Initial configuration for obliqueImagery
  const obliqueImageryConfig: google.maps.MapOptions = google
    ? {
      center: centerLatLng,
      zoom: globalConfig.zoomLimit,
      tilt: Number(globalConfig.options.tilt),
      heading: state.step * Number(globalConfig.options.factorHeading),
      disableDefaultUI: globalConfig.options.disableDefaultUI === 'true',
      mapTypeId: google.MapTypeId.SATELLITE
    }
    : {};

  // Initial configuration for street view imagery
  const svImageryConfig: google.maps.StreetViewPanoramaOptions = google
    ? {
      position: centerLatLng,
      disableDefaultUI: globalConfig.options.disableDefaultUI === 'true',
      pov: {
        heading: state.step * Number(globalConfig.options.factorHeading),
        pitch: Number(globalConfig.options.pitch)
      }
    }
    : {};

  const mapInitial = {
    google,
    config: obliqueImageryConfig,
    containerRef: mapContainerRef
  };

  const svInitial = {
    google,
    config: svImageryConfig,
    containerRef: svContainerRef
  };

  // Using custom hooks to streetView and googleMap state in the component
  useStreetView(svInitial, editor.streetModal && state.showSV);
  const googleMap = useMap(mapInitial);

  // Change oblique heading when state.step change
  useEffect((): void => {
    if (google) {
      if (state.step !== 0) {
        obliqueImageryConfig.heading = state.step * Number(globalConfig.options.factorHeading);
        googleMap.setOptions(obliqueImageryConfig);
        state.showSV = false;
      } else {
        state.showSV = true;
      }
    }
  }, [state.step, globalConfig.options.factorHeading, google, googleMap, state]);

  const prevSlide = (): void => {
    if (state.step > 0) {
      state.step -= 1;
    }
  };

  const nextSlide = (): void => {
    if (state.step < 4) {
      state.step += 1;
    }
  };

  const nextButtonDisabled = state.step === 4;
  const prevButtonDisabled = state.step === 0;

  const modalOffset = {
    x: '46px',
    y: '65px'
  };

  return (
    <Draggable handle="#street-view-drag-handle" bounds="#lyra-canvas-container" positionOffset={modalOffset}>
      <ModalDialogStyled open={editor.streetModal}>
        <ModalControlStyled>
          <WrapperSVGStyled id="street-view-drag-handle">
            <IconMapper name="handler" />
          </WrapperSVGStyled>
          <TriangleBorderStyled />
        </ModalControlStyled>
        <ModalWrapperContent>
          <ModalOvalStyled previous onClick={prevSlide} disabled={prevButtonDisabled}>
            <IconMapper name="arrow" />
          </ModalOvalStyled>
          <ModalOvalStyled onClick={nextSlide} disabled={nextButtonDisabled}>
            <IconMapper name="arrow" />
          </ModalOvalStyled>
          <ModalContentStyled step={state.step}>
            <div ref={mapContainerRef} />
            <div ref={svContainerRef} />
          </ModalContentStyled>
        </ModalWrapperContent>
      </ModalDialogStyled>
    </Draggable>
  );
});

export default ModalStreetView;
