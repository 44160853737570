import React, { BaseProps, ReactElement, useState } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { Flexbox } from '../Layout/Flexbox';
import { Paragraph } from '../FontFamily/Fonts';
import { Icon } from '../Icon';

type Props = BaseProps & {
  order?: string;
  active?: boolean;
};

const TabBarStyled = styled.div`
  display: flex;
`;

const CustomTabStyled = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})<Props & { onClick: () => void }>`
  width: 200px;
  height: 30px;
  border-top-left-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'first' ? '6.4px' : ''};
  border-bottom-left-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'first' ? '6.4px' : ''};
  border-top-right-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'last' ? '6.4px' : ''};
  border-bottom-right-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'last' ? '6.4px' : ''};
  background: ${({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.yellowColor1 : theme.colors.highlight};
  box-sizing: border-box;
  border: ${({ theme, active }: PropsTheme<Props>): Color =>
    active ? 'none' : `1.5px solid ${theme.colors.lineHr}`};
  cursor: pointer;
`;

const TabText = styled(Paragraph)<Props>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  line-height: 1.31;
  text-align: center;
  color: ${({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.highlight : theme.colors.mainColor6};
`;

const CompleteIcon = styled(Icon)`
  margin-left: 8px;
`;

export interface ITabsProps {
  active?: boolean;
  label?: string;
  order?: string;
  messageId?: string;
  count?: number;
}

export interface IProps extends BaseProps {
  tabItems: ITabsProps[];
  handleClick: (index: number) => void;
  className?: string;
}

const CustomTabBar = ({ className, tabItems, handleClick }: IProps): ReactElement => {
  const activeTabIndex = tabItems.findIndex(
    (i: ITabsProps, index: number): number | undefined => {
      if (i.active === true) return index;
  });
  const [tabIndex, setTabState] = useState(activeTabIndex < 0 ? 0 : activeTabIndex);

  function changeTab(index: number): void {
    setTabState(index);
    handleClick(index);
  }

  return (
    <TabBarStyled className={className}>
      {tabItems.map(
        (tab: ITabsProps, index: number): ReactElement => {
          return (
            <CustomTabStyled
              key={index}
              order={tab.order}
              active={tabIndex === index}
              onClick={(): void => changeTab(index)}
              data-testid={`custom-tab-${index}`}
              dir="row"
            >
              <TabText active={tabIndex === index}>
                {tab.label} {tab.count !== 0 && `(${tab.count})`}
              </TabText>
              {tab.count === 0 && <CompleteIcon name="icon-tick" />}
            </CustomTabStyled>
          );
        }
      )}
    </TabBarStyled>
  );
};

export { CustomTabBar };
