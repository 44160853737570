import React, { BaseProps, ReactElement } from 'react';
import { Icon } from '../Icon';
import {
  MissingPropertiesContainer,
  UpperView,
  TitleText,
  CenterView,
  BottomView,
  PropertyText
} from './styles';

export type GroupPanelProps = BaseProps & {
  iconName: string;
  title: string;
  missingProperties: string[];
  ctaButton: ReactElement;
};

const GroupPanel = ({
  iconName,
  title,
  missingProperties,
  ctaButton
}: GroupPanelProps): ReactElement => {
  return (
    <MissingPropertiesContainer>
      <UpperView>
        <Icon name={iconName} />
        <TitleText>{title}</TitleText>
      </UpperView>
      <CenterView>
        {missingProperties.map((property: string, index: number): ReactElement => {
          return (
            <PropertyText key={index}>
              - {property}
            </PropertyText>
          );
        })}
      </CenterView>
      <BottomView>
        {ctaButton}
      </BottomView>
    </MissingPropertiesContainer>
  );
};

export { GroupPanel };
