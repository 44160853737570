import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import { LyraPanel } from '@aurorasolar/lyra-ui-kit';
import type Color from '../../../../domain/typings/Color';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  overflow: hidden;
`;

export const BigButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  height: 268px;
`;

export const BigButtonContainer = styled.div`
  width: 170px;
  height: 170px;
  & button {
    box-shadow: 0 0 0 0;
    border: solid 1.5px ${({ theme }: PropsTheme): Color => theme.colors.grayColor4};
  }
`;

export const ButtonsDivider = styled.div`
  width: 1.5px;
  margin: 0 25px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.grayColor4};
  height: 275px;
`;

export const HalfPanel = styled.div`
  width: 170px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LyraPanelMessage = styled(LyraPanel.Panel)`
  padding: 10px;
  margin: 10px 0;
  flex: 2;
`;
