import React, { ReactElement } from 'react';
import Col from '../../utils/flexbox-grid/components/Col';
import { DataProps } from './DataTable';
import {
  CellTitle,
  CenterColumn,
  ContentRow,
  DownloadIcon
} from './styles';

type TableCellProps = {
  data: DataProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  noDownloadColumn?: boolean;
};

const DataTableCell = ({ data, onClick, noDownloadColumn }: TableCellProps): ReactElement => {
  const { id, name, billingAddress, date, price } = data;
  return (
    <ContentRow key={id}>
      <Col md={6}>
        <CellTitle>
          {name}
        </CellTitle>
        <CellTitle>
          {billingAddress}
        </CellTitle>
      </Col>
      <CenterColumn md={2}>
        <CellTitle>
          {date}
        </CellTitle>
      </CenterColumn>
      <CenterColumn md={2}>
        <CellTitle>
          {price}
        </CellTitle>
      </CenterColumn>
      {noDownloadColumn ? null : (
        <CenterColumn md={2}>
          <DownloadIcon
            data-testid="data-table-download-button"
            name="icon-download"
            onClick={onClick}
          />
        </CenterColumn>
      )}
    </ContentRow>
  );
};

export { DataTableCell };
