/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import useStore from '../../../../stores/useStore';
import type { EquipmentPanelViewModel } from '../../../../stores/UiStore/Panels/ViewModels/EquipmentPanel/EquipmentPanelViewModel';
import config, { UI_MODE } from '../../../../config/config';
import {
  EquipmentPanelContainer,
  LocationHeader,
  LocationText,
  SiteEquipmentFormGroup,
  TodoHeader,
  TodoIcon,
  TodoItemWrapper,
  TodoText
} from './styles';

import EquipmentItem from './EquipmentItem';

const EquipmentPanel = observer((): ReactElement => {
  const {
    panels, workspace, smartGuides, properties
  } = useStore();

  const equipmentPanelViewModel = panels.viewModel as EquipmentPanelViewModel;
  const {
    equipmentPlaced, equipmentNotPlaced
  } = equipmentPanelViewModel;

  useEffect(() => {
    equipmentPanelViewModel.setStoreDependencies(workspace, smartGuides, properties);
  }, [equipmentPanelViewModel]);

  const Container = config.featureFlag.uiMode === UI_MODE.AURORA ? SiteEquipmentFormGroup : EquipmentPanelContainer;

  return (
    <Container>
      <LocationHeader>
        <LocationText>Equipment</LocationText>
      </LocationHeader>

      {equipmentPlaced.length ? (
        <EquipmentItem
          placed
          equipment={equipmentPlaced}
          onIconClick={equipmentPanelViewModel.setSelectedMarker}
          onExposureChange={equipmentPanelViewModel.setEquipmentExposure}
        />
      ) : (
        <TodoItemWrapper>
          <TodoHeader>
            <TodoIcon name="icon-warning" />
            <TodoText>Yet to be placed</TodoText>
          </TodoHeader>
          <EquipmentItem
            placed={false}
            equipment={equipmentNotPlaced}
            onIconClick={equipmentPanelViewModel.setSelectedMarker}
            onExposureChange={equipmentPanelViewModel.setEquipmentExposure}
          />
        </TodoItemWrapper>
      )}
    </Container>
  );
});

export default EquipmentPanel;
