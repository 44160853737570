import type DomainStore from '../../DomainStore/DomainStore';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type { Marker } from '../../../domain/models/SiteDesign/Marker';
import type { IConfirmableCommand } from './Command';
import { BaseCommand } from './Command';

export interface IUpdateEquipmentCommand extends IBaseCommandDependencies {
  readonly editor: EditorStore;
  readonly domain: DomainStore;
  readonly equipment: Marker;
  readonly confirmationModalText?: string;
}

export class UpdateEquipmentCommand extends BaseCommand implements IConfirmableCommand {
  readonly confirmationModalText?: string;
  private readonly editor: EditorStore;
  private readonly domain: DomainStore;
  private readonly equipment: Marker;

  constructor(dependencies: IUpdateEquipmentCommand) {
    super();
    this.editor = dependencies.editor;
    this.domain = dependencies.domain;
    this.equipment = dependencies.equipment;
    this.confirmationModalText = dependencies.confirmationModalText;
  }

  async execute(): Promise<void> {
    const addedNewEquipment = this.domain.addOrUpdateSiteEquipment(this.equipment);
    if (addedNewEquipment) {
      this.equipment.draw();
    }
  }
}
