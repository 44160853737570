import React, { BaseProps, ReactElement } from 'react';
import { Icon } from '../../Icon';
import { LeftIconContainer } from '../styles';

export type LeftIconProps = BaseProps & {
  icon: string;
  bgColor: string;
};

const LeftIcon = (props: LeftIconProps): ReactElement => {
  const { icon, bgColor } = props;

  return (
    <LeftIconContainer bgColor={bgColor}>
      <Icon name={icon} />
    </LeftIconContainer>
  );
};

export { LeftIcon };
