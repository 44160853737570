import React, { BaseProps, MouseEventHandler, ReactElement } from 'react';
import {
  AccordionWrapper,
  AccordionHeader,
  AccordionName,
  ArrowIcon,
  IconWrapper,
  AccordionContent
} from './styles';

export type AccordionProps = BaseProps & {
  name: string;
  isOpen: boolean;
  onClick: (event: MouseEvent) => void;
};

const Accordion = ({
  name, isOpen, children, onClick, className
}: AccordionProps): ReactElement => {
  return (
    <AccordionWrapper
      className={className}
    >
      <AccordionHeader
        data-testid="accordeon-header"
        onClick={((event: MouseEvent): void => {
          onClick(event);
        }) as unknown as MouseEventHandler<HTMLDivElement>}
      >
        <AccordionName>
          {name}
        </AccordionName>
        <IconWrapper>
          <ArrowIcon name={isOpen ? 'accordion-arrow-up' : 'accordion-arrow-down'} />
        </IconWrapper>
      </AccordionHeader>
      {isOpen && (
        <AccordionContent>
          {children}
        </AccordionContent>
      )}
    </AccordionWrapper>
  );
};

export { Accordion };
