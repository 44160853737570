import styled, { PropsTheme } from 'styled-components';

type Props = PropsTheme & {
  wrap?: 'wrap' | 'nowrap' | 'initial';
  justify?: string;
  align?: string;
  dir?: 'row' | 'row-reverse' | 'inverse-row' | 'column' | 'inverse-column' | 'revert';
  fullHeight?: boolean;
  stretch?: 'auto' | '100%';
  disabled?: boolean;
};

const Flexbox = styled.div<Props>`
  display: flex;
  position: relative;
  width: ${({ stretch = '100%' }: Props): string => stretch};
  ${({ fullHeight = false }: Props): string => fullHeight ? 'height: 100%;' : ''};
  flex-direction: ${({ dir = 'row' }: Props): string => dir};
  align-items: ${({ align = 'baseline' }: Props): string => align};
  justify-content: ${({ justify = 'flex-start' }: Props): string => justify};
  flex-wrap: ${({ wrap = 'nowrap' }: Props): string => wrap};

  &:focus {
    outline: none;
  }
`;

export {
  Flexbox,
  Props
};
