import type { Color } from '@aurorasolar/lyra-ui-kit';
import {
  decorate, observable
} from 'mobx';
import type { Object3D } from 'three';
import {
  BoxGeometry, MathUtils as ThreeMath, Mesh, MeshBasicMaterial, Vector3
} from 'three';
import type { SimpleVector2 } from '../../../utils/ThreeUtils';
import type { RoofFace } from '../../models/SiteDesign/RoofFace';
import {
  EOrientation, ERoofSlopeType, EWorkspace, Units
} from '../../../domain/typings';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import ProjectionUtil from '../../../utils/projectionUtil';
import type { Segment } from '../../graphics/Segment';
import {
  QUARTER_PI, SceneObjectType
} from '../Constants';
import { canvasConfig } from '../../../config/canvasConfig';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import {
  getParentLyraModelByMesh, getParentLyraModelByMeshOrLyraModel
} from '../../sceneObjectsWithLyraModelsHelpers';
import { getRootStore } from '../../../stores/RootStoreInversion';
import { Hoverable } from '../../mixins/Hoverable';
import { Draggable } from '../../mixins/Draggable';
import { Selectable } from '../../mixins/Selectable';
import { WorkspaceTagged } from '../../mixins/WorkspaceTagged';
import { Drawable } from '../../mixins/Drawable';
import { degreesToRadians } from '../../../utils/math';
import { vertexArrayToVector3Array } from '../../../utils/helpers';
import {
  getCenterOfBoundingBoxAroundVertices,
  calculateZFromInfiniteMesh,
  isPolygonInsideAnother,
  isPolygonIntersection,
  areVerticesInsidePolygon,
  createPlaneFromVertices,
  toCanvasCoordinatesInPixels
} from '../../../utils/spatial';
import { Vertex } from '../../graphics/Vertex';
import { Boundary } from '../../graphics/Boundary';
import PvModule from './PvModule';
import {
  getPvModulePositionSizeWithSpacing, getVerticesWithSpacing
} from './functions/PvModuleHelpers';

type PolygonData = { x: number; y: number; z: number };

interface IPvModulePositionParams {
  positionVertices: PolygonData[];
  orientation: EOrientation;
  id: string;
  rowSpacing: number;
  columnSpacing: number;
  designWorkspace: DesignWorkspace;
}

const PANEL_ARRAY_THICKNESS = 1;

const MixedClass = PolygonDrawable(Hoverable(Draggable(Selectable(WorkspaceTagged(Drawable(class SimpleClass {}))))));

decorate(PvModule, {
  color: observable,
  material: observable
});

/**
 * This class represents a PV module position
 */
class PvModulePosition extends MixedClass {
  private plusSignElements: Object3D[] = [];
  private moduleLength?: number;
  private moduleWidth?: number;
  isPvModulePositionPreview: boolean = false;
  override nonResettable: boolean = true;

  tag: EWorkspace = EWorkspace.DESIGN;
  propertyId: string = SceneObjectType.PvModulePosition;
  selectWithParent: boolean = false;
  orientation: EOrientation;
  pvModule?: PvModule;
  override color: Color;
  disabledColor: Color = '#9d0007';
  isMultipleVertices: boolean = true;
  /** Layer Number */
  layerNumber: number = 4;
  /** spacing */
  rowSpacing: number;
  columnSpacing: number;
  spacing?: Boundary;

  private designWorkspace: DesignWorkspace;

  constructor({
    positionVertices,
    orientation,
    id,
    rowSpacing,
    columnSpacing,
    designWorkspace
  }: IPvModulePositionParams) {
    super();
    this.serverId = id;
    this.type = SceneObjectType.PvModulePosition;
    this.orientation = orientation;
    this.boundary.defaultThickness = PANEL_ARRAY_THICKNESS;
    this.rowSpacing = rowSpacing;
    this.columnSpacing = columnSpacing;

    this.designWorkspace = designWorkspace;
    this.color = this.defaultColor;
    this.mesh.material = new MeshBasicMaterial({
      transparent: true
    });
    this.changeMeshMaterial(this.color);

    this.selectableOpacity = {
      whenSelected: 0.5,
      whenDeselected: 0.1
    };
    this.mesh.renderOrder = 1;

    this.addVertices(positionVertices);
  }

  /**
   * Azimuth in radians, in the following range: [0, 2*Math.PI]
   */
  get azimuth(): number {
    const mountingSystems = getRootStore().domain.design.system.equipment.mountingSystems;
    const mountingSystemInstance = mountingSystems.mountingSystemOn(this.roofFace.serverId)!;
    const arrayAzimuth = mountingSystemInstance.configuration?.azimuth ?? this.roofFace.azimuth ?? 180;
    return (-ThreeMath.DEG2RAD * arrayAzimuth) % (Math.PI * 2);
  }

  get rackSpacing(): number {
    if (getParentLyraModelByMesh<RoofFace>(this.mesh).slopeType !== ERoofSlopeType.LowSlope) {
      return 0;
    }

    const mountingSystems = getRootStore().domain.design.system.equipment.mountingSystems;
    const mountingSystemInstance = mountingSystems.mountingSystemOn(this.roofFace.serverId)!;
    return mountingSystemInstance.configuration?.rackSpacing.value ?? 0;
  }

  get projectedRackSpacing(): number {
    return this.rackSpacing * Math.cos(this.parentRoofFaceSlopeInRadians);
  }

  get parentRoofFaceSlopeInRadians(): number {
    return degreesToRadians(getParentLyraModelByMesh<RoofFace>(this.mesh).slope ?? 0);
  }

  get projectedRowSpacing(): number {
    return Math.cos(this.parentRoofFaceSlopeInRadians) * this.rowSpacing;
  }

  get defaultColor(): Color {
    return canvasConfig.pvModulePositionDefaultColor;
  }

  get isColliding(): boolean {
    return !this.canMove;
  }

  get enabledColor(): Color {
    // Used to be #167524
    return canvasConfig.pvModulePositionHoveredColor;
  }

  positionPlusSignElement = (element: Object3D) => {
    const [a, b, c, d] = this.getVector3s();
    element.position.set((a.x + b.x + c.x + d.x) / 4, (a.y + b.y + c.y + d.y) / 4, a.z + 10);
  };

  removePlusSign = () => {
    this.plusSignElements.forEach((element: Object3D) => {
      this.mesh.remove(element);
    });
    this.redraw();
  };

  addPlusSign = (
    editor: EditorStore,
    domain: DomainStore,
    roofFaceToUseForPvModulePositionPreview?: RoofFace
  ): void => {
    const roofFace =
      this.isPvModulePositionPreview && roofFaceToUseForPvModulePositionPreview
        ? roofFaceToUseForPvModulePositionPreview
        : getParentLyraModelByMesh<RoofFace>(this.mesh);
    const isLowSlope = roofFace.slopeType === ERoofSlopeType.LowSlope;
    if (!domain.optionalDesign) {
      return;
    }
    const supplementalData = domain.optionalDesign.supplementalData;
    const mountingSystems = domain.optionalDesign.system.equipment.mountingSystems;
    const mountingSystem = mountingSystems.mountingSystemOn(roofFace.serverId)!;
    const angle: number = isLowSlope
      ? degreesToRadians(mountingSystem.configuration?.azimuth ?? 0)
      : roofFace.getAzimuthOrSegmentAngle();
    const widthInMeters: number = supplementalData.pvModuleInfo!.dimensions.width;
    const lengthInMeters: number = supplementalData.pvModuleInfo!.dimensions.length;

    this.moduleWidth = ProjectionUtil.convertToWorldUnits(widthInMeters, Units.Meters);
    this.moduleLength = ProjectionUtil.convertToWorldUnits(lengthInMeters, Units.Meters);
    const geometryHorizontal = new BoxGeometry(this.moduleWidth / 2, this.moduleWidth / 15, 1);
    const geometryVertical = new BoxGeometry(this.moduleWidth / 15, this.moduleWidth / 2, 1);
    const material = new MeshBasicMaterial({ color: 0x58ac65 });

    const lineHorizontal = new Mesh(geometryHorizontal, material);
    this.positionPlusSignElement(lineHorizontal);
    this.mesh.add(lineHorizontal);
    this.plusSignElements.push(lineHorizontal);

    const lineVertical = new Mesh(geometryVertical, material);
    this.positionPlusSignElement(lineVertical);
    this.mesh.add(lineVertical);
    this.plusSignElements.push(lineVertical);

    lineHorizontal.rotateZ(-angle);
    lineVertical.rotateZ(-angle);

    this.redraw();
  };

  get roofFace(): RoofFace {
    const parent: RoofFace = getParentLyraModelByMesh(this.mesh);
    if (!parent) {
      throw new Error(`PV module position ${this.serverId} does not have a parent Roof Face`);
    }
    return parent;
  }

  getCenter(): Vector3 {
    return getCenterOfBoundingBoxAroundVertices(this.getVector3s());
  }

  /**
   * @description Converts the center point of the PV module position to canvas coordinates in pixels.
   * @returns {SimpleVector2} - The canvas coordinates of the point in pixels.
   */
  toCanvasCoordinatesInPixels(): SimpleVector2 {
    const centerPoint = this.polygon
      .reduce(
        (accumulator: Vector3, vertex: Vertex): Vector3 => accumulator.add(vertex.mesh.position),
        new Vector3(0, 0, 0)
      )
      .divideScalar(this.polygon.length);

    return toCanvasCoordinatesInPixels(getRootStore().editor, centerPoint);
  }

  override onDragStart(mousePosition: Vector3): void {
    super.onDragStart(mousePosition);
    this.initialCenter = this.getCenter();
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    super.onDragFinish(editor, smartGuides);
  }

  override move(newVertices: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (this.roofFace && newVertices && this.validAgainstRoofFace(this.roofFace, newVertices)) {
      newVertices.forEach((vertex: Vector3): void => {
        vertex.z = calculateZFromInfiniteMesh(this.getVector3s(), vertex);
      });
      this.updatePolygonVertices(editor, newVertices, this.roofFace);
      this.plusSignElements.forEach((element: Object3D) => {
        this.positionPlusSignElement(element);
      });
    }
  }

  override afterMove(newVertices: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    if (this.roofFace && newVertices && this.validAgainstRoofFace(this.roofFace, newVertices)) {
      this.updateCanMove(editor);

      if (this.canMove) {
        const cloneVertices = newVertices.map((vector: Vector3): Vector3 => vector.clone());
        this.lastValidVertices = [];
        this.lastValidVertices.push(...cloneVertices);
      }
    } else {
      this.canMove = false;
    }
    this.updateColor();
    return true;
  }

  validAgainstRoofFace(roofFace: RoofFace, positionVertices: Vector3[]): boolean {
    const roofFaceVertices = this.getPolygonVertices(roofFace.polygon);
    return isPolygonInsideAnother(positionVertices, roofFaceVertices);
  }

  private getPolygonVertices(polygon: Vertex[]): Vector3[] {
    const vertices = vertexArrayToVector3Array(polygon);
    vertices.splice(vertices.length - 1, 1);
    return vertices;
  }

  updateColor(): void {
    this.changeMeshMaterial(this.currentColor());
  }

  private currentColor(): Color {
    const {
      pvModulePositionHoveredColor, pvModulePositionDefaultColor
    } = canvasConfig;

    if (!this.canMove) {
      return this.disabledColor;
    }
    if (this.isDragging || this.selected) {
      return this.enabledColor;
    }

    return this.hovered ? pvModulePositionHoveredColor : pvModulePositionDefaultColor;
  }

  resetVertex(vector: Vector3, index: number): void {
    this.polygon[index].mesh.position.x = vector.x;
    this.polygon[index].mesh.position.y = vector.y;
    this.polygon[index].mesh.position.z = vector.z;
    this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
  }

  hasFill(): boolean {
    return true;
  }

  onClose(): void {
    /** */
  }

  showFill(): boolean {
    return true;
  }

  showLines(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return false;
  }

  showVertices(): boolean {
    return this.selected;
  }

  hasNoPvModule(): boolean {
    return this.pvModule === undefined;
  }

  addSpacing(): void {
    this.spacing = new Boundary();

    const row: number = ProjectionUtil.convertToWorldUnits(this.projectedRowSpacing / 2, Units.Meters);
    const column: number = ProjectionUtil.convertToWorldUnits(this.columnSpacing / 2, Units.Meters);

    const newVertices = this.boundary.vertices.map((vertex: Vertex, index: number): Vertex => {
      const vector3New: Vector3 = vertex.mesh.position.clone();

      const h = Math.sqrt(column * column + row * row);
      const vectorFinal: Vector3 = this.calculatePosition(QUARTER_PI, h, vector3New, index);
      return Vertex.fromXyzValuesObject(vectorFinal);
    });

    this.spacing.setVertices(newVertices);
  }

  calculatePosition(angle: number, vecLength: number, position: Vector3, index: number): Vector3 {
    let xPos: number = 0;
    let yPos: number = 0;

    switch (index) {
    case 1: {
      xPos = position.x + Math.cos(angle) * vecLength;
      yPos = position.y + Math.sin(angle) * vecLength;
      break;
    }

    case 2: {
      xPos = position.x + Math.cos(angle) * vecLength;
      yPos = position.y - Math.sin(angle) * vecLength;
      break;
    }

    case 3: {
      xPos = position.x - Math.cos(angle) * vecLength;
      yPos = position.y - Math.sin(angle) * vecLength;
      break;
    }

    default:
      xPos = position.x - Math.cos(angle) * vecLength;
      yPos = position.y + Math.sin(angle) * vecLength;
      break;
    }

    const zPos = this.roofFace.calculateZ(new Vector3(xPos, yPos, 0));
    return new Vector3(xPos, yPos, zPos);
  }

  addPvModule(pvModule: PvModule): boolean {
    if (!this.hasNoPvModule()) {
      return false;
    }
    pvModule.boundary.removeAll();
    this.boundary.vertices.forEach(({
      x, y, z
    }: Vertex): void => {
      pvModule.addVertex({
        vertex: new Vector3(x, y, z + 10),
        removeIfCollinear: false,
        originatingFromTracing: false
      });
    });
    pvModule.mesh.position.copy(this.mesh.position);
    pvModule.redraw();
    this.mesh.add(pvModule.mesh);
    this.pvModule = pvModule;
    this.redraw();
    return true;
  }

  deletePvModule(): void {
    if (this.pvModule) {
      this.mesh.remove(this.pvModule.mesh);
      this.pvModule = undefined;
      this.redraw();
    }
  }

  changeMeshMaterial(color: Color): void {
    this.boundary.segments.forEach((segment: Segment): void => {
      segment.line.material.transparent = true;
      segment.line.material.opacity = 0.5;
    });
    (this.mesh.material as MeshBasicMaterial).opacity = 0.1;
    (this.mesh.material as MeshBasicMaterial).color.set(color);
    this.color = color;
    this.redraw();
  }

  selectMultiple(color: Color): void {
    (this.mesh.material as MeshBasicMaterial).opacity = 0.7;
    (this.mesh.material as MeshBasicMaterial).color.set(color);
    this.color = color;
    this.redraw();
  }

  unselectMultiple(color: Color): void {
    (this.mesh.material as MeshBasicMaterial).opacity = 0.1;
    (this.mesh.material as MeshBasicMaterial).color.set(color);
    this.color = color;
    this.redraw();
  }

  show(): void {
    this.visible = true;
  }

  hideIfEmpty(): void {
    if (!this.pvModule) {
      this.visible = false;
    }
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }

  getVerticesWithSpacing(updatedVertices: Vector3[] | undefined): Vector3[] {
    const calculateZ = (v: Vector3): number => getParentLyraModelByMesh<RoofFace>(this.mesh).calculateZ(v);
    return getVerticesWithSpacing({
      pvModulePositionCenter: updatedVertices
        ? getCenterOfBoundingBoxAroundVertices(updatedVertices)
        : this.getCenter(),
      pvModulePanelSizeWithSpacing: this.getPositionSizeWithSpacing(),
      azimuth: this.azimuth,
      calculateZ
    });
  }

  validateCollision(vertices: Vector3[], collidableObjects: PolygonDrawable[]): boolean {
    const thisModulesVerticesWithSpacing = this.getVerticesWithSpacing(vertices);
    for (const polygon of collidableObjects) {
      if (
        (polygon as PvModulePosition).serverId === this.serverId
        || (getParentLyraModelByMeshOrLyraModel(polygon) as RoofFace).serverId !== this.roofFace.serverId
      ) {
        continue;
      } else {
        const collidableVertices = polygon.getVector3s();
        // Only add spacing when validating against another PV module position
        const addSpacingForValidation = polygon instanceof PvModulePosition;
        const validationVertices = addSpacingForValidation ? thisModulesVerticesWithSpacing : vertices;
        if (
          isPolygonIntersection(validationVertices, collidableVertices)
          || areVerticesInsidePolygon(validationVertices, collidableVertices)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  changeOrientation(editor: EditorStore): void {
    const rotatedVertices = this.getRotatedVertices(Math.PI / 2);

    rotatedVertices.forEach((vector: Vector3, index: number): void => {
      this.polygon[index].mesh.position.x = vector.x;
      this.polygon[index].mesh.position.y = vector.y;
      this.polygon[index].mesh.position.z = vector.z;
      this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
      if (this.pvModule) {
        this.pvModule.polygon[index].mesh.position.x = vector.x;
        this.pvModule.polygon[index].mesh.position.y = vector.y;
        this.pvModule.polygon[index].mesh.position.z = vector.z;
        this.pvModule.boundary.updateFirstOrLastVertexIfNeeded(this.pvModule.polygon[index]);
      }
    });

    this.orientation = this.orientation === EOrientation.PORTRAIT ? EOrientation.LANDSCAPE : EOrientation.PORTRAIT;

    this.updateCanMove(editor, rotatedVertices);

    if (this.canMove) {
      this.changeMeshMaterial('#000000');
      if (this.pvModule) {
        this.pvModule.changeMeshMaterial('#000000');
      }
    } else {
      this.changeMeshMaterial(this.disabledColor);
      if (this.pvModule) {
        this.pvModule.changeMeshMaterial(this.pvModule.disabledColor);
      }
    }
  }

  /**
   * Sets validation state for PV module position
   * @param editor
   * @param newVertexData
   * @param presetCollidableObjects, isPvModulePositionPreview supposed to be already filtered out of this list
   */
  updateCanMove(
    editor: EditorStore,
    newVertexData: Vector3[] = this.getVector3s(),
    presetCollidableObjects: PolygonDrawable[] = []
  ): void {
    if (this.isPvModulePositionPreview) {
      this.canMove = true;
      return;
    }

    const collidableObjects: PolygonDrawable[] = presetCollidableObjects.length
      ? presetCollidableObjects
      : editor.getCollidableObjects();

    this.canMove =
      isPolygonInsideAnother(newVertexData, this.roofFace.getVector3s())
      && this.validateCollision(newVertexData, collidableObjects);
  }

  /**
   * @description Returns the size of a PV module position with spacings (paddings)
   */
  getPositionSizeWithSpacing(): SimpleVector2 {
    const { domain } = getRootStore();
    const pvModuleDimensions = domain.optionalDesign?.supplementalData.pvModuleInfo?.dimensions;
    const mountingSystems = domain.optionalDesign?.system.equipment.mountingSystems;
    const mountingSystem = getParentLyraModelByMesh<RoofFace>(this.mesh).serverId
      ? mountingSystems?.mountingSystemOn(this.roofFace.serverId)
      : undefined;
    const tiltAngleInRadians = degreesToRadians(mountingSystem?.configuration?.tiltAngle ?? 0);

    return getPvModulePositionSizeWithSpacing({
      projectedRowSpacing: this.projectedRowSpacing,
      columnSpacing: this.columnSpacing,
      tiltAngleInRadians,
      parentRoofFaceSlopeInRadians: this.parentRoofFaceSlopeInRadians,
      orientation: this.orientation,
      vertices: this.boundary.vertices,
      pvModuleDimensions: pvModuleDimensions
    });
  }
  getPositionSizeWithSpacingAndLongestAndShortestSides(): {
    size: SimpleVector2;
    longestSide: number;
    shortestSide: number;
    } {
    const size = this.getPositionSizeWithSpacing();

    return {
      size,
      longestSide: Math.max(size.x, size.y),
      shortestSide: Math.min(size.x, size.y)
    };
  }

  /**
   * Updates Z values so that all vertices of the moved PV module position are on the plane of the roof face.
   */
  updateZValuesAfterMove = (): void => {
    const vertices = this.getVector3s();
    this.boundary.removeAll();
    vertices.forEach((vertex: Vector3): void => {
      const vertexWithUpdatedZValue = vertex.setZ(this.roofFace.calculateZ(vertex));
      this.addVertex({
        vertex: vertexWithUpdatedZValue,
        removeIfCollinear: false,
        originatingFromTracing: false
      });
    });
  };

  private getRotatedVertices(angle: number): Vector3[] {
    const vertices = this.getVector3s();
    const moduleCenter = this.getCenter();
    const normal = createPlaneFromVertices(vertices)?.normal;

    if (normal) {
      const rotatedVertices = [];
      for (const vertex of vertices) {
        const cv = new Vector3().subVectors(vertex, moduleCenter);
        cv.applyAxisAngle(normal, angle);
        rotatedVertices.push(new Vector3().addVectors(moduleCenter, cv));
      }
      return rotatedVertices;
    } else {
      console.warn('Cannot calculate plane from vertices!');
      return vertices;
    }
  }

  private updatePolygonVertices(editor: EditorStore, newVerticesInVector3: Vector3[], currentRoofFace: RoofFace): void {
    newVerticesInVector3.forEach((vector: Vector3, index: number): void => {
      this.polygon[index].mesh.position.x = vector.x;
      this.polygon[index].mesh.position.y = vector.y;
      this.polygon[index].mesh.position.z = currentRoofFace.calculateZ(vector);
      this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
    });
  }
}

export default PvModulePosition;
