import { css, Mixin } from 'styled-components';
import { colorScheme } from '../colorScheme';

export const inheritMinMaxHeight: Mixin = css`
  min-height: inherit;
  max-height: inherit;
`;

export const flatBg: Mixin = css`
  background-color: ${colorScheme.secondary};
`;

export const gradientBg: Mixin = css`
  background-image: linear-gradient(
    to bottom,
    ${colorScheme.secondary} 0%,
    ${colorScheme.tertiary} 100%
  );
`;

export const foreground: Mixin = css`
  z-index: 1;
`;

export const modal: Mixin = css`
  z-index: 3;
`;

export const middleground: Mixin = css`
  z-index: 0;
`;

export const background: Mixin = css`
  z-index: -1;
`;

export const wrapperFill: Mixin = css`
  background-color: ${colorScheme.highlight};
  border: solid 1px ${colorScheme.tertiary};
  border-radius: 4px;
`;
