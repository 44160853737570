import uniq from 'lodash/uniq';
import {
  action, computed, observable
} from 'mobx';
import { EventDispatcher } from 'three';
import type { Selectable } from '../../../domain/mixins/Selectable';
import type Store from '../../Store';
import {
  PropsPanelServiceEntranceEquipmentUICodesSet,
  PropsPanelUICodes, toolOverwriteMap
} from './propertiesStoreConstants';

export class PropertiesStore extends EventDispatcher<{
  panel_changed: IPanelChangedEvent;
}> {
  @observable
  showingPanelOf: string = '';

  @observable
  private title?: string;
  // We don't have a proper services initialisation that'd allow passing references
  // to a non-initialized services, hence this function returning root store.
  private readonly getRootStore: () => Store;
  constructor(getRootStore: () => Store) {
    super();
    this.getRootStore = getRootStore;
  }
  /**
   * Get the title of the header
   */
  @computed
  get currentTitle(): string {
    return this.title ?? '';
  }

  /**
   * Set the title of the header
   */
  @action
  setTitle = (title: string): void => {
    this.title = title;
  };

  setPropertyPanel(value?: string): void {
    this.showingPanelOf = value === undefined ? '' : value;
    this.dispatchEvent({
      type: 'panel_changed',
      showingPanelOf: this.showingPanelOf
    });
  }

  computePropertiesPanel(objects: Selectable[]): void {
    const propertiesUICode = this.inferPanelOverwriteByTool() ?? this.inferPropertiesPanelUICodeBySelection(objects);
    this.setPropertyPanel(propertiesUICode);
  }

  private inferPanelOverwriteByTool(): string | undefined {
    return toolOverwriteMap[this.getRootStore().toolbar.selectedTool?.id ?? ''];
  }

  private inferPropertiesPanelUICodeBySelection(selection: Selectable[]): string {
    const propertyIds = uniq(selection.map((selected: Selectable): string => selected.propertyId));
    const availableKeys = Object.values(PropsPanelUICodes);

    for (const type of propertyIds) {
      const possiblePropertyPanelId = type;
      if (PropsPanelServiceEntranceEquipmentUICodesSet.has(possiblePropertyPanelId as PropsPanelUICodes)) {
        return PropsPanelUICodes.ServiceEntranceEquipmentForm;
      } else if (availableKeys.includes(possiblePropertyPanelId as PropsPanelUICodes)) {
        return possiblePropertyPanelId;
      }
    }

    return '';
  }
}

export interface IPanelChangedEvent {
  target?: PropertiesStore;
  showingPanelOf?: string;
}
