import {
  action, observable
} from 'mobx';

export interface ISitePlanData {
  readonly sitePlanBackgroundImage: string;
  readonly includeParcelBoundaries: boolean;
  readonly includeParcelBoundaryDistanceLines: boolean;
}

export default class SitePlan {
  @observable
  sitePlanBackgroundImage: string;

  @observable
  includeParcelBoundaries: boolean;

  @observable
  includeParcelBoundaryDistanceLines: boolean;

  constructor(data: ISitePlanData) {
    this.sitePlanBackgroundImage = data.sitePlanBackgroundImage;
    this.includeParcelBoundaries = data.includeParcelBoundaries;
    this.includeParcelBoundaryDistanceLines = data.includeParcelBoundaryDistanceLines;
  }

  @action.bound
  setSitePlanBackgroundImage(value: string): void {
    this.sitePlanBackgroundImage = value;
    this.includeParcelBoundaries = false;
  }

  @action.bound
  setIncludeParcelBoundaries(value: boolean): void {
    this.includeParcelBoundaries = value;
  }

  @action.bound
  setShowParcelBoundaryDistanceLines(value: boolean): void {
    this.includeParcelBoundaryDistanceLines = value;
  }

  toData(): ISitePlanData {
    return {
      sitePlanBackgroundImage: this.sitePlanBackgroundImage,
      includeParcelBoundaries: this.includeParcelBoundaries,
      includeParcelBoundaryDistanceLines: this.includeParcelBoundaryDistanceLines
    };
  }
}
