export interface IAnalytics {
  /**
   * Tracks a given event in an implementation-specific way (e.g., by reporting it to a third-party service)
   */
  trackEvent(event: AnalyticsEvent): void;
}

type EventProperties = Record<string, string | number | boolean | undefined>;

export abstract class AnalyticsEvent {
  /**
   * Name of the event.
   */
  abstract readonly name: string;
  /**
   * Properties of the event providing additional context.
   * For example, a "Project Deleted" event may have `{ projectId: idOfDeletedProject }` properties.
   */
  readonly properties: EventProperties;
  /**
   * (internal metadata)
   * Should identical events (same name and same properties) be tracked only once?
   */
  readonly oneTimeTrackedEvent: boolean = false;
  /**
   * (internal metadata)
   * Source of the event.
   * Primarily used to differentiate between Design Tool and Host App events within the application
   * (before tracking them in an external service like Segment).
   */
  abstract readonly source: string;

  constructor(properties: EventProperties) {
    this.properties = properties;
  }
}
