import { IDefaultTheme } from '../../typings';
import { colorScheme } from './colorScheme';
import * as mixins from './mixins';

const defaultTheme: IDefaultTheme = {
  auroraMode: false,
  colors: colorScheme,
  fonts: {
    // Lyra: { fontFamily }; Aurora: { sans, monospace }
    fontFamily: 'Roboto, sans-serif',
  },
  // z-index values
  layers: {
    closest: 2,
    foreground: 1,
    middleground: 0,
    background: -1,
  },
  mixins
};

export { defaultTheme };
