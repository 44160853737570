import {
  observable, action
} from 'mobx';

export enum SchematicType {
  SINGLE_LINE_DIAGRAM = 'SINGLE_LINE_DIAGRAM',
  THREE_LINE_DIAGRAM = 'THREE_LINE_DIAGRAM'
}

export interface IElectricalPlanData {
  readonly includeVoltageDropInConductorTable: boolean;
  readonly schematicTypes: SchematicType[];
}

export default class ElectricalPlan {
  @observable
  includeVoltageDropInConductorTable: boolean;

  @observable
  schematicTypes: SchematicType[];

  constructor(data: IElectricalPlanData) {
    this.includeVoltageDropInConductorTable = data.includeVoltageDropInConductorTable;
    this.schematicTypes = [...data.schematicTypes];
  }

  @action.bound
  setIncludeVoltageDropInConductorTable(value: boolean): void {
    this.includeVoltageDropInConductorTable = value;
  }

  @action.bound
  setElectricalDiagramType(value: string): void {
    this.schematicTypes =
      value === 'both'
        ? [SchematicType.SINGLE_LINE_DIAGRAM, SchematicType.THREE_LINE_DIAGRAM]
        : [value as SchematicType];
  }

  toData(): IElectricalPlanData {
    return {
      includeVoltageDropInConductorTable: this.includeVoltageDropInConductorTable,
      schematicTypes: [...this.schematicTypes]
    };
  }
}
