import Registry from '../../../utils/Registry';
import { PageId } from '../../../stores/UiStore/Pages/PagesStore';
import { CircuitTableAuroraDesignPage } from './pages/CircuitTable/CircuitTableAuroraDesign';
import PlanSetCustomizationPage from './pages/PlanSetCustomization/PlanSetCustomizationPage';
import { PlanSetPreviewPage } from './pages/PlanSetPreview/PlanSetPreviewPage';

export const pagesRegistry = new Registry();

pagesRegistry.add(PageId.CircuitTable, CircuitTableAuroraDesignPage);
pagesRegistry.add(PageId.PlanSetCustomization, PlanSetCustomizationPage);
pagesRegistry.add(PageId.PlanSetPreview, PlanSetPreviewPage);
