import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import {
  LyraFormElementsDesignTool, LyraGrid, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { useUiStore } from '../../../../../stores/useStore';
import type { RoofConditionViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/RoofConditionViewModel';
import RoofPropertiesParent from '../../RoofPropertiesParent/RoofPropertiesParent';
import { RoofConditionWrapper } from './styles';

const Label = LyraTypography.Paragraph;
const NumberField = LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField;

const RoofCondition = (): ReactElement => {
  const { roofFaceProps } = useUiStore();
  const viewModel = roofFaceProps.currentRoofPropertyEditor as RoofConditionViewModel;

  useEffect((): void => {
    viewModel.setRoofFace(roofFaceProps.firstRoofFaceSelected!);
  }, [viewModel, roofFaceProps.firstRoofFaceSelected]);

  return (
    <RoofPropertiesParent viewModel={viewModel} multiEdit={true} visibility={false}>
      <RoofConditionWrapper>
        <Label lineHeight={1} margin="0 0 4px 0" type="h6">
          MAX. HORIZONTAL RAFTER SPAN
        </Label>
        <NumberField
          onChange={viewModel.setMaxHorizontalSpan}
          value={viewModel.maxHorizontalSpan}
          measure="in"
          withQuantityArrows={false}
          limits={viewModel.measureLimit}
          fractionDigits={2}
          allowEmptyField={true}
        />
        <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
          MAX. RAFTER SAG
        </Label>
        <NumberField
          onChange={viewModel.setSag}
          value={viewModel.sag}
          measure="in"
          withQuantityArrows={false}
          limits={viewModel.measureLimit}
          fractionDigits={2}
          allowEmptyField={true}
        />
        <Label lineHeight={1} margin="15px 0 4px 0" type="h6">
          MAX. RIDGE BEAM SAG
        </Label>
        <NumberField
          onChange={viewModel.setRidgeBeamSag}
          value={viewModel.ridgeBeamSag}
          measure="in"
          withQuantityArrows={false}
          limits={viewModel.measureLimit}
          fractionDigits={2}
          allowEmptyField={true}
        />
        <Label lineHeight={1} margin="15px 0 0" type="h6">
          DISALLOWED HOLES AND NOTCHES IN RAFTERS AND BEAMS
        </Label>
        <LyraGrid.Grid
          options={viewModel.yesOrNoOptions}
          columns={2}
          selected={{
            name: String(viewModel.disallowedHolesOrNotchesInStructuralMembers),
            value: String(viewModel.disallowedHolesOrNotchesInStructuralMembers)
          }}
          onSelect={viewModel.setDisallowedHolesOrNotchesInStructuralMembers}
          selectedOptionColor={EThemeComponentColor.PURPLE}
        />
        <Label lineHeight={1} margin="15px 0 0" type="h6">
          STRUCTURAL DECAY OR UNREPAIRED FIRE DAMAGE
        </Label>
        <LyraGrid.Grid
          options={viewModel.yesOrNoOptions}
          columns={2}
          selected={{
            name: String(viewModel.structuralDecayOrFireDamage),
            value: String(viewModel.structuralDecayOrFireDamage)
          }}
          onSelect={viewModel.setStructuralDecayOrFireDamage}
          selectedOptionColor={EThemeComponentColor.PURPLE}
        />
        <Label lineHeight={1} margin="15px 0 0" type="h6">
          EVIDENCE OF LEAKS, DAMAGE, OR DETERIORATION
        </Label>
        <LyraGrid.Grid
          options={viewModel.yesOrNoOptions}
          columns={2}
          selected={{
            name: String(viewModel.evidenceOfLeaksDamageOrDeterioration),
            value: String(viewModel.evidenceOfLeaksDamageOrDeterioration)
          }}
          onSelect={viewModel.setEvidenceOfLeaksDamageOrDeterioration}
          selectedOptionColor={EThemeComponentColor.PURPLE}
        />
      </RoofConditionWrapper>
    </RoofPropertiesParent>
  );
};

export default observer(RoofCondition);
