import type { Vector3 } from 'three';
import type { Outline } from '../../../domain/models/SiteDesign/Outline';
import type EditorStore from '../../EditorStore/EditorStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

interface IAddVertexToOutlineDependencies extends IBaseCommandDependencies {
  editor: EditorStore;
  targetCoordinates: Vector3;
  wipOutline: Outline;
}

export class AddVertexToOutlineCommand extends BaseCommand {
  private editor: EditorStore;
  private targetCoordinates: Vector3;
  private wipOutline: Outline;

  constructor(dependencies: IAddVertexToOutlineDependencies) {
    super();
    this.editor = dependencies.editor;
    const {
      targetCoordinates, wipOutline
    } = dependencies;
    this.targetCoordinates = targetCoordinates;
    this.wipOutline = wipOutline;
  }

  async execute(): Promise<void> {
    this.wipOutline.addVertex({
      vertex: this.targetCoordinates,
      removeIfCollinear: true,
      originatingFromTracing: true
    });
    this.editor.addOrUpdateObject(this.wipOutline.mesh);
  }
}
