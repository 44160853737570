import { MathUtils as ThreeMath } from 'three';
import { TimeStamp } from '../stores/EditorStore/TimeStamp';
import type { ISignalP1 } from './signal/Signal';
import { Signal } from './signal/Signal';

export const DURATIONS = {
  // ms
  DEFAULT_ANIMATION: 400,
  CAMERA_MOVEMENT: 1500
};

/**
 * This class is mainly for animating anything seamlessly and smoothly.
 *  If you modify the "end", and you call "update" every frame,
 *  then "value" will get closer and closer to the value of "end"
 */

export enum Easing {
  EASE_OUT,
  EASE_IN_OUT
}

type ConvergenceSignals = {
  onUpdate: ISignalP1<number>;
  onComplete: ISignalP1<number>;
};

export class Convergence {
  private static _activeInstances: Convergence[] = [];
  private _timeStampAtSetEnd: number = 0;
  protected _originalStart: number;
  protected _originalEnd: number;
  protected _start: number;
  protected _end: number;
  protected _min: number = -Infinity;
  protected _max: number = Infinity;
  private _value: number; // current value (between start and end)
  private _animationDuration: number; // ms
  private _originalAnimationDuration: number; // ms
  private _hasChanged: boolean = false;
  private _prevDeltaValue: number = 0;
  private _prevTimeStamp: number = 1;
  private _prevDeltaTime: number = 1;
  private _easing: Easing = Easing.EASE_OUT;
  private _timeoutId: number = -1;
  private _triggerRender: boolean;

  signals: ConvergenceSignals = {
    onUpdate: Signal.create<number>(),
    onComplete: Signal.create<number>() // dispatches one frame after the last modification (when newValue === prevValue)
  };

  constructor(
    start: number,
    end: number,
    easing: Easing = Easing.EASE_OUT,
    animationDuration: number = DURATIONS.DEFAULT_ANIMATION,
    triggerRender: boolean = true
  ) {
    this._originalStart = start;
    this._start = start;
    this._originalEnd = end;
    this._end = end;
    this._value = this._start;
    this._originalAnimationDuration = this._animationDuration = animationDuration;
    this._easing = easing;
    this._triggerRender = triggerRender;
  }

  private static removeFromActiveOnes(convergenceToRemove: Convergence): void {
    Convergence._activeInstances = Convergence._activeInstances.filter(
      (convergence: Convergence): boolean => convergence !== convergenceToRemove
    );
  }

  private static addToActiveOnes(convergenceToAdd: Convergence): void {
    if (!Convergence._activeInstances.includes(convergenceToAdd)) {
      Convergence._activeInstances.push(convergenceToAdd);
    }
  }

  static updateActiveOnes(timeStamp: number): boolean {
    let triggerRender = false;
    for (const c of Convergence._activeInstances) {
      triggerRender = triggerRender || c._triggerRender;
      c.update(timeStamp);
    }

    return triggerRender;
  }

  private smoothStep(elapsedTime: number): number {
    if (elapsedTime < this._animationDuration) {
      const x = elapsedTime / this._animationDuration;
      return ThreeMath.clamp(x ** 2 * (3 - 2 * x) * (this._end - this._start) + this._start, this._min, this._max);
    } else {
      this._end = ThreeMath.clamp(this._end, this._min, this._max);
      return this._end;
    }
  }

  private exponentialOut(elapsedTime: number): number {
    if (elapsedTime < this._animationDuration) {
      const x = elapsedTime / this._animationDuration;
      return ThreeMath.clamp(
        (1 - 2 ** (-10 * x)) * (1024 / 1023) * (this._end - this._start) + this._start,
        this._min,
        this._max
      );
    } else {
      this._end = ThreeMath.clamp(this._end, this._min, this._max);
      return this._end;
    }
  }

  // elapsedTime since "setEnd" called in ms
  private getNextValue(elapsedTime: number): number {
    return this._easing === Easing.EASE_IN_OUT ? this.smoothStep(elapsedTime) : this.exponentialOut(elapsedTime);
  }

  increaseEndBy(value: number, clampBetweenMinAndMax: boolean = false): void {
    this.setEnd(this._end + value, clampBetweenMinAndMax);
  }

  decreaseEndBy(value: number, clampBetweenMinAndMax: boolean = false): void {
    this.setEnd(this._end - value, clampBetweenMinAndMax);
  }

  setEnd(
    value: number,
    clampBetweenMinAndMax: boolean = false,
    animationDuration: number = this._originalAnimationDuration
  ): void {
    this._animationDuration = animationDuration;
    const newEnd = clampBetweenMinAndMax ? ThreeMath.clamp(value, this._min, this._max) : value;
    Convergence.addToActiveOnes(this);
    this._start = this._value;
    this._end = newEnd;
    this._timeStampAtSetEnd = TimeStamp.value;

    if (!clampBetweenMinAndMax) {
      clearTimeout(this._timeoutId);
      this._timeoutId = window.setTimeout((): void => {
        this._end = ThreeMath.clamp(this._end, this._min, this._max);
      }, this._animationDuration);
    }
  }

  reset(start?: number, end?: number, animationDuration: number = this._originalAnimationDuration): void {
    this._animationDuration = animationDuration;
    Convergence.addToActiveOnes(this);
    this._start = start != null ? start : this._originalStart;
    this._end = end != null ? end : this._originalEnd;
    this._timeStampAtSetEnd = TimeStamp.value;
  }

  private update(timeStamp: number): void {
    this._prevDeltaTime = timeStamp - this._prevTimeStamp;
    const elapsedTime = timeStamp - this._timeStampAtSetEnd;
    const prevValue = this._value;
    this._value = this.getNextValue(elapsedTime);
    this._prevDeltaValue = this._value - prevValue;
    this._prevTimeStamp = timeStamp;

    if (this._value === prevValue) {
      this._start = this._end;
      this._hasChanged = false;
      Convergence.removeFromActiveOnes(this);
      this.signals.onComplete.dispatch(this._value);
    } else {
      this._hasChanged = true;
      this.signals.onUpdate.dispatch(this._value);
    }
  }

  get animationDuration(): number {
    return this._animationDuration;
  }

  get originalAnimationDuration(): number {
    return this._originalAnimationDuration;
  }

  get start(): number {
    return this._start;
  }

  get value(): number {
    return this._value;
  }

  get end(): number {
    return this._end;
  }

  get hasChangedSinceLastTick(): boolean {
    return this._hasChanged;
  }

  get prevDeltaValue(): number {
    return this._prevDeltaValue;
  }

  get prevDeltaTime(): number {
    return this._prevDeltaTime;
  }

  get derivateAt0(): number {
    return this._easing === Easing.EASE_OUT
      ? 6.938247437862991 // Equals: (5*Math.log(2) * 2**11) / 1023;
      : 0; // Smoothstep's derivate is 0 at 0
  }
}
