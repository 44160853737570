import {
  BufferGeometry, MeshBasicMaterial, Vector3
} from 'three';
import { canvasConfig } from '../../../../config/canvasConfig';
import PvModulePosition from '../../../../domain/models/SiteDesign/PvModulePosition';
import type { Dictionary } from '../../../../domain/typings';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import { isPositionCollinearToVector3Lines } from '../../../../application/isPositionCollinearToLines';
import { SceneObjectType } from '../../../../domain/models/Constants';
import {
  projectPointOnEdge,
  getCenterOfBoundingBoxAroundVertices,
  isPolygonInsideOrIntersectingAnother
} from '../../../../utils/spatial';
import { setLinePositionAttribute } from '../../../../utils/helpers';
import {
  extendLine, distanceBetweenPointAndLineSegment, isPointOnLine
} from '../../../../utils/math';
import { Line } from '../../../../domain/graphics/Line';
import type { Boundary } from '../../../../domain/graphics/Boundary';
import { LayerCanvas } from '../../../../domain/graphics/LayerCanvas';
import type { Segment } from '../../../../domain/graphics/Segment';
import { BaseGuide } from './BaseGuide';

const KEYCODE_E = 69;

type LineExtension = {
  start: Vector3;
  end: Vector3;
  isCollinearToTheNewSegment?: boolean;
};

export class ExtensionLinesGuide extends BaseGuide {
  icon = 'smartguide-extension-lines';
  name = 'Extension Lines';
  hr = false;
  override offsetPoint = 10;
  identifier = EGuideIdentifier.EXTENSION_LINES;
  kindSupport = [KindGuides.TRACE_TOOL, KindGuides.MOVE_OBJECT];

  commandKeyCode = KEYCODE_E;

  // Shows from the line which does match with the previewline
  lineGuideRef?: Line;
  // line ref
  lineDataRef?: LineExtension;

  lineGuideRefOpt?: Line;
  lineDataRefOpt?: LineExtension;

  linesExtensions: Dictionary<LineExtension[]> = {};
  applyLinesExtensions: LineExtension[] = [];

  private objectSnapThreshold: number = 5;

  setup(): void {
    this.enableKeyListener();
    const { extensionLinesColor } = canvasConfig;
    const lineMaterial = new MeshBasicMaterial({
      transparent: true,
      depthTest: true,
      depthWrite: false,
      ...LayerCanvas.CLOSEST,
      color: extensionLinesColor
    });
    this.lineGuideRef = new Line(new BufferGeometry(), lineMaterial);
    this.lineGuideRefOpt = new Line(new BufferGeometry(), lineMaterial);
  }

  apply(applyParams: IApplyParams): IGuideResult {
    this.editor.addOrUpdateObject(this.lineGuideRef!);
    this.editor.addOrUpdateObject(this.lineGuideRefOpt!);

    if (applyParams.kind === KindGuides.TRACE_TOOL && applyParams.wipBoundary && applyParams.mousePos) {
      return this.applyWithTraceTool(applyParams.mousePos, applyParams.wipBoundary);
    } else if (
      applyParams.kind === KindGuides.MOVE_OBJECT
      && applyParams.wipObject
      && applyParams.wipObject instanceof PvModulePosition
      && applyParams.vertexObject
    ) {
      return this.applyWithObject(applyParams.vertexObject, applyParams.wipObject);
    } else {
      return {
        color: '',
        position: applyParams.mousePos,
        objectVertex: applyParams.mousePos
      };
    }
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    this.linesExtensions = {};
    this.lineGuideRef!.visible = false;
    this.lineDataRef = {
      start: new Vector3(0, 0, 0),
      end: new Vector3(0, 0, 0)
    };

    this.lineGuideRefOpt!.visible = false;
    this.lineDataRefOpt = {
      start: new Vector3(0, 0, 0),
      end: new Vector3(0, 0, 0)
    };

    this.editor.removeObject(this.lineGuideRef!);
    this.editor.removeObject(this.lineGuideRefOpt!);
  }

  private applyWithTraceTool(mousePos: Vector3, wipBoundary: Boundary): IGuideResult {
    const { extensionLinesColor } = canvasConfig;
    const newMousePos = new Vector3().copy(mousePos);

    this.lineGuideRef!.visible = false;

    const currentBoundary = wipBoundary;

    this.linesExtensions = {};

    // Get boundaries in the scene
    const allBoundaries: Boundary[] = this.editor.getObjectsByType('boundary', true);

    // Validate drawing boundary
    const currentBoundaryExtension: LineExtension[] = [];
    currentBoundary.segments.forEach((segment: Segment, index: number): void => {
      if (index < currentBoundary.segments.length) {
        const [startPos, endPos] = extendLine(segment.points[0].getVector3(), segment.points[1].getVector3());
        currentBoundaryExtension.push({
          start: startPos,
          end: endPos
        });
      }
    });

    // Validate boundaries in scene
    const allBoundaryExtension: LineExtension[] = [];
    allBoundaries.forEach((boundary: Boundary): void => {
      if (boundary !== currentBoundary) {
        boundary.segments.forEach((segment: Segment): void => {
          const [startPos, endPos] = extendLine(segment.points[0].getVector3(), segment.points[1].getVector3());
          allBoundaryExtension.push({
            start: startPos,
            end: endPos
          });
        });
      }
    });

    this.linesExtensions = {
      group0: currentBoundaryExtension,
      group1: allBoundaryExtension
    };

    // Function to validate if mouse position is in raycast line
    this.vectorBelongsToSegment(newMousePos, wipBoundary);

    // Set snap point in the guideline
    if (this.lineGuideRef!.visible) {
      const {
        start, end
      } = this.lineDataRef!;
      const snapPosition = projectPointOnEdge(newMousePos, start, end);

      if (snapPosition) {
        newMousePos.x = snapPosition.x;
        newMousePos.y = snapPosition.y;
      }
    }

    return {
      color: extensionLinesColor,
      position: newMousePos
    };
  }

  override hideUiElements(): void {
    this.lineGuideRefOpt!.visible = false;
    this.lineGuideRef!.visible = false;
  }

  private applyWithObject(points: Vector3[], wipObject: PvModulePosition): IGuideResult {
    this.lineGuideRef!.visible = false;
    this.lineGuideRefOpt!.visible = false;
    this.applyLinesExtensions = [];

    if (wipObject instanceof PvModulePosition) {
      const pvModulePositions: PvModulePosition[] = this.editor.getObjectsByType(
        SceneObjectType.PvModulePosition,
        true
      );

      this.linesExtensions = {};

      const positionsGroup: Dictionary<Vector3[]> = {};
      const groupPair: Dictionary<string[]> = {};

      let indexGroup: number = 0;

      for (const pvModulePosition of pvModulePositions) {
        if (pvModulePosition.serverId !== wipObject.serverId) {
          const eachGroupPair: Dictionary<string> = {};
          const vertices = (pvModulePosition as PvModulePosition).getVector3s();

          // exclude first is equal to last
          for (let i = 1; i < vertices.length; ++i) {
            const vertex = vertices[i];
            const groupValue = vertex.z.toFixed(2);

            if (!eachGroupPair[groupValue]) {
              eachGroupPair[groupValue] = 'Group';
            }

            if (positionsGroup[groupValue]) {
              positionsGroup[groupValue].push(vertex);
            } else {
              positionsGroup[groupValue] = [vertex];
            }
          }
          const values: string[] = Object.keys(eachGroupPair);
          const stringifiedValues: string = JSON.stringify(values);
          let existInGroup: boolean = false;

          for (const key in groupPair) {
            if (JSON.stringify(groupPair[key]) === stringifiedValues) {
              existInGroup = true;
              break;
            }
          }

          if (!existInGroup) {
            indexGroup++;
            groupPair[`GROUP${indexGroup}`] = values;
          }
        }
      }

      for (const key in groupPair) {
        if (groupPair.hasOwnProperty(key)) {
          const allExtension: LineExtension[] = groupPair[key].map((subgroup: string): LineExtension => {
            const firstVector: Vector3 = positionsGroup[subgroup][0];
            const lastVector: Vector3 = positionsGroup[subgroup][positionsGroup[subgroup].length - 1];
            const [startPos, endPos] = extendLine(firstVector, lastVector);
            return {
              start: startPos,
              end: endPos
            };
          });
          this.linesExtensions[key] = allExtension;
        }
      }

      // Function to validate if mouse position is in raycast line
      this.pointsBelongToSegment(points, wipObject);
    }

    const nonSnappedCenter = getCenterOfBoundingBoxAroundVertices(points);
    let snappedCenter: Vector3 | undefined;

    if (this.lineGuideRef!.visible && this.lineGuideRefOpt!.visible) {
      const min: {
        point: Vector3 | null;
        snapPoint: Vector3 | null;
        distance: number;
      } = {
        point: null,
        snapPoint: null,
        distance: Infinity
      };

      for (let i = 1; i < points.length; ++i) {
        const pos = points[i];

        for (const pointsExt of this.applyLinesExtensions) {
          const {
            start, end
          } = pointsExt;

          const dist = distanceBetweenPointAndLineSegment(pos, start, end);
          if (dist < this.objectSnapThreshold) {
            min.distance = dist;
            min.point = pos;
            min.snapPoint = pos.clone();

            const snapPosition = projectPointOnEdge(pos, start, end);

            if (snapPosition) {
              min.snapPoint.x = snapPosition.x;
              min.snapPoint.y = snapPosition.y;
            }
          }
        }
      }

      if (min.snapPoint && min.point) {
        const offset = new Vector3().subVectors(min.snapPoint, min.point);
        snappedCenter = new Vector3().addVectors(nonSnappedCenter, offset);
      }
    }

    return {
      color: '',
      objectVertex: snappedCenter ? snappedCenter : nonSnappedCenter
    };
  }

  private vectorBelongsToSegment(pos: Vector3, wipBoundary: Boundary): void {
    const newSegmentVertices = wipBoundary.vertices.length
      ? [wipBoundary.vertices[wipBoundary.vertices.length - 1].getVector3(), pos]
      : [];

    for (const key in this.linesExtensions) {
      if (this.linesExtensions.hasOwnProperty(key)) {
        const extensionLines = this.linesExtensions[key];

        for (const points of extensionLines) {
          const {
            start, end
          } = points;
          if (isPointOnLine(start, end, pos)) {
            const isLineCollinearToTheNewSegment =
              isPositionCollinearToVector3Lines(newSegmentVertices, start)
              && isPositionCollinearToVector3Lines(newSegmentVertices, end);
            if (
              !this.lineGuideRef!.visible
              // Prefer non-collinear snap point
              || (this.lineDataRef!.isCollinearToTheNewSegment && !isLineCollinearToTheNewSegment)
            ) {
              this.lineDataRef = points;
              this.lineDataRef.isCollinearToTheNewSegment = isLineCollinearToTheNewSegment;
              this.lineGuideRef!.visible = true;
              setLinePositionAttribute(start, end, this.lineGuideRef!);
              this.applyLinesExtensions.push(points);
            }
          }
        }
      }
    }
  }

  private largestDistanceFromPointsToSegment(points: Vector3[], pointA: Vector3, pointB: Vector3): number {
    let max = 0;
    for (const point of points) {
      const distance = distanceBetweenPointAndLineSegment(point, pointA, pointB);
      if (distance > max) {
        max = distance;
      }
    }

    return max;
  }

  private pointsBelongToSegment(points: Vector3[], position: PvModulePosition): void {
    const positionSize = position.getPositionSizeWithSpacing();
    const groupByNumberConsequences: Dictionary<number> = {};

    const threshold = this.objectSnapThreshold;
    for (const key in this.linesExtensions) {
      if (!this.linesExtensions.hasOwnProperty(key)) {
        continue;
      }
      groupByNumberConsequences[key] = 0;

      const extensionLines = this.linesExtensions[key];
      if (extensionLines.length < 2) {
        continue;
      }
      const bigPolygon: Vector3[] = [
        extensionLines[0].start,
        extensionLines[0].end,
        extensionLines[1].end,
        extensionLines[1].start,
        extensionLines[0].start
      ];

      const isSnapPointValid = isPolygonInsideOrIntersectingAnother(points, bigPolygon);

      if (!isSnapPointValid) {
        continue;
      }
      const largestDistToLines = Math.max(
        this.largestDistanceFromPointsToSegment(points, extensionLines[0].start, extensionLines[0].end),
        this.largestDistanceFromPointsToSegment(points, extensionLines[1].start, extensionLines[1].end)
      );
      if (largestDistToLines > Math.max(positionSize.x, positionSize.y) * 1.2) {
        continue;
      }

      // skip points[0], because it's the same as points[4]
      for (let i = 1; i < points.length; ++i) {
        const pos = points[i];

        const pointInExtOne = isPointOnLine(extensionLines[0].start, extensionLines[0].end, pos, threshold);
        const pointInExtTwo = isPointOnLine(extensionLines[1].start, extensionLines[1].end, pos, threshold);
        if (pointInExtOne || pointInExtTwo) {
          groupByNumberConsequences[key]++;
        }
      }
    }

    for (const key in groupByNumberConsequences) {
      if (groupByNumberConsequences.hasOwnProperty(key) && groupByNumberConsequences[key] >= 2) {
        const extensionLines = this.linesExtensions[key];

        this.lineDataRef = extensionLines[0];
        this.lineGuideRef!.visible = true;
        setLinePositionAttribute(extensionLines[0].start, extensionLines[0].end, this.lineGuideRef!);

        this.lineDataRefOpt = extensionLines[1];
        this.lineGuideRefOpt!.visible = true;
        setLinePositionAttribute(extensionLines[1].start, extensionLines[1].end, this.lineGuideRefOpt!);

        this.applyLinesExtensions.push(this.lineDataRef);
        this.applyLinesExtensions.push(this.lineDataRefOpt);
        break;
      }
    }
  }
}
