import React, { BaseProps, ReactElement, ReactText, useCallback } from 'react';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { TabLayout, TabsWrapper } from './styles';
import { TabOption } from './TabOption';

export type TabsProps = BaseProps & {
  options: TabOption[];
  layoutClassName?: string;
  value: ReactText;
  onClick?: (event: ReactText) => void;
  activeThemeColor?: EThemeComponentColor;
  inactiveThemeColor?: EThemeComponentColor;
  inactiveBorderThemeColor?: EThemeComponentColor;
};

const Tabs = ({
  options,
  className,
  layoutClassName,
  value,
  onClick,
  activeThemeColor = EThemeComponentColor.PURPLE,
  inactiveThemeColor = EThemeComponentColor.WHITE,
  inactiveBorderThemeColor = EThemeComponentColor.GRAY_200
}: TabsProps): ReactElement => {
  const handleClick = useCallback(
    (event: ReactText): void => onClick && onClick(event),
    []
  );

  return (
    <TabsWrapper inactiveThemeColor={inactiveThemeColor} className={className}>
      {options.map(
        ({ label, ...props }: TabOption, index: number): ReactElement => (
          <TabLayout
            key={index}
            className={layoutClassName}
            active={props.value === value}
            inactiveThemeColor={inactiveThemeColor}
            activeThemeColor={activeThemeColor}
            inactiveBorderThemeColor={inactiveBorderThemeColor}
            data-testid={`tab-${index}`}
            onClick={(): void => handleClick(props.value)}
          >
            {label}
          </TabLayout>
        )
      )}
    </TabsWrapper>
  );
};

export { Tabs };
