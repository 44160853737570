import {
  HALF_PI, PI, QUARTER_PI, RIGHT_ANGLE, TWO_PI
} from '../../../domain/models/Constants';
import { positionFromAngle } from '../../../utils/math';
import {
  CARDINAL_ABBREV, COMPASS_WIDTH
} from './constants';

export function getCardinalPointStyles(): string {
  // flip the compass:
  // in order to set North cardinal as the 0 degree angle
  // it needs to set an offset of 180 degrees(PI in radians)
  const angleOffset = PI;
  // cardinals letters will have a gap from the compass ring
  const ringPadding = 40;
  let styles: string = '';

  // spinning around the compass, in each quadrant
  // based on the cardinal points
  for (let itr = 0; itr <= TWO_PI; itr += HALF_PI) {
    const index = itr / HALF_PI;
    const radius = (COMPASS_WIDTH + ringPadding) / 2;
    const pos = positionFromAngle(itr - angleOffset, radius);

    // set the style and the letter for the cardinal point
    styles += `
      &:nth-child(${index}) {
        transform: translate(${pos.x}px, ${pos.y}px);

        &::before {
          content: '${CARDINAL_ABBREV[index - 1]}';
        }
      }
    `;
  }

  return styles;
}

export function getRingPipeStyles(): string {
  const ringPipePadding = 61;
  let styles: string = '';

  // Spinning around the compass by small portions
  // in order to place the key points direction
  for (let itr = 0; itr <= TWO_PI; itr += QUARTER_PI) {
    const index = itr / QUARTER_PI;
    const pos = positionFromAngle(itr, ringPipePadding);
    const translate = `translate(${pos.x}px, ${pos.y}px)`;
    const rotate = `rotate(${itr - HALF_PI}rad)`;

    styles += `
      &:nth-child(${index}) {
        transform: ${translate} ${rotate};
      }
    `;
  }

  return styles;
}

/**
 * Get the cardinal point abbreviation
 * depending on the angle (in degrees)
 *
 * @param angle - angle in degrees of the compass needle's direction
 * @return Cardinal direction abbrev (i.e. NW = North-West)
 */
export function getCardinalAbbrev(angle: number): string {
  angle = angle % 360;
  if (angle < 0) {
    angle += 360;
  }
  // Cardinal directions
  const [NORTH, EAST, SOUTH, WEST] = CARDINAL_ABBREV;
  // Split the compass in 4 cardinal quadrants
  const compassQuadrant: number = Math.floor(angle) / RIGHT_ANGLE;
  // cardinal direction name based on the compass quadrant
  let cardinalDir: string = '';

  // when if an intercardinal direction based in the quadrant
  // like north-west or south-east
  if (compassQuadrant % 1) {
    // I Quadrant
    if (compassQuadrant < 1) {
      cardinalDir = `${NORTH}${EAST}`;
      // II Quadrant
    } else if (compassQuadrant < 2) {
      cardinalDir = `${SOUTH}${EAST}`;
      // III Quadrant
    } else if (compassQuadrant < 3) {
      cardinalDir = `${SOUTH}${WEST}`;
      // IV Quadrant
    } else {
      cardinalDir = `${NORTH}${WEST}`;
    }
    // when if it's a raw cardinal direction
    // like only north or west
  } else {
    cardinalDir = `${CARDINAL_ABBREV[compassQuadrant]}`;
  }

  return cardinalDir;
}
