import type Store from '../../../../../stores/Store';
import {
  type ISiteInfoToolDependencies,
  SiteInfoTool
} from '../../../../../stores/UiStore/ToolbarStore/Project/SiteInfoTool';
import withTool from '../../Shared/withTool';

type SiteInfoToolDependencies = Pick<ISiteInfoToolDependencies, 'modal'>;

const SiteInfo = withTool(
  SiteInfoTool,
  (store: Store): SiteInfoToolDependencies => ({
    modal: store.modal
  })
);

export default SiteInfo;
