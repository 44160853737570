import React, { BaseProps, ReactElement } from 'react';
import styled, { css, PropsTheme, SimpleInterpolation } from 'styled-components';
import { Color } from '../../../typings';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { Label, LabelProps } from '../Label/index';

type ToogleColorProp = {
  toogleColorTheme?: EThemeComponentColor;
};

type ContainerProps = {
  width?: string;
};

type Props = BaseProps &
  ToogleColorProp & {
    checked?: boolean;
  };

type SwitchInputTypes = ToogleColorProp &
  PropsTheme & {
    checked?: boolean;
  };

type ToggleProps = BaseProps &
  LabelProps &
  ToogleColorProp &
  ContainerProps & {
    id?: string;
    checked?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

function getColorToogleTheme({ toogleColorTheme, theme }: SwitchInputTypes): Color {
  return toogleColorTheme === EThemeComponentColor.BLUE
    ? theme.colors.mainColor1
    : theme.colors.mainColor2;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ width }: ContainerProps): SimpleInterpolation =>
    css`
      width: ${width};
    `}
  box-sizing: border-box;
`;
const SwitchContainer = styled.div`
  display: inline;
  position: relative;
  font-size: 16px;
`;

const SwitchInput = styled.input<SwitchInputTypes>`
  position: relative;
  width: 36px;
  height: 20px;
  margin: 0;
  padding: 0;

  opacity: 0;

  &:before {
    background-color: ${(props: SwitchInputTypes): Color =>
      props.checked ? getColorToogleTheme(props) : ''};
  }

  &:after {
    left: 16px;
    background-color: ${(props: SwitchInputTypes): Color =>
      props.checked ? getColorToogleTheme(props) : ''};
  }
`;

const SwitchBox = styled.label<Props>`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  left: 0;
  top: 0;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 0;
    width: 31px;
    height: 15.5px;
    opacity: 0.5;
    background-color: ${(props: SwitchInputTypes): Color =>
      props.checked ? getColorToogleTheme(props) : props.theme.colors.grayColor3};
    border-radius: 7.7px;
    z-index: 1;
    transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:after {
    content: '';
    position: absolute;
    left: ${(props: SwitchInputTypes): string => (props.checked ? '21px' : '5.3px')};
    top: 0.3px;
    width: 12.8px;
    height: 12.8px;
    background-color: ${(props: SwitchInputTypes): Color =>
      props.checked ? getColorToogleTheme(props) : props.theme.colors.grayColor7};
    border-radius: 7px;
    z-index: 2;
    transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: left, background-color;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.06) 20%,
      rgba(255, 255, 255, 0)
    );
    border-image-slice: 1;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  }
`;

const Toggle = (props: ToggleProps): ReactElement => {
  const {
    className,
    id,
    checked,
    color,
    name,
    label,
    toogleColorTheme = EThemeComponentColor.BLUE,
    onChange,
    width
  } = props;
  return (
    <Container width={width ? width : '100%'} className={className}>
      <Label className={className} color={color} name={name} label={label} />
      <SwitchContainer className={className}>
        <SwitchInput
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          toogleColorTheme={toogleColorTheme}
        />
        <SwitchBox htmlFor={id} checked={checked} toogleColorTheme={toogleColorTheme} />
      </SwitchContainer>
    </Container>
  );
};
export { Toggle };
