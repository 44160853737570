import React, { BaseProps, ReactElement } from 'react';
import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';

type StyleProps = PropsTheme & {
  themeBg?: string;
};

type Props = BaseProps & {
  themeBg?: string;
  title?: string | ReactElement;
  currentStep?: string;
  nextStep?: string;
};

const TitleBarStyle = styled.div<StyleProps>`
  display: flex;
  justify-content: space-between;
  background-color: ${
    ({theme, themeBg = 'primary'}: StyleProps): Color => theme.colors[themeBg]
  };
  border-radius: 4px;
  color: ${
    ({theme}: StyleProps): Color => theme.colors.highlight
  };
  font-size: 18px;
  font-weight: bold;
  padding: 12px;
  text-align: center;
`;

const TitleBar = (props: Props): ReactElement => {
  const title = props.title ? props.title : props.children;
  const steps = props.currentStep
    ? `Step ${props.currentStep} of ${props.nextStep}`
    : <></>;

  return (
    <TitleBarStyle className={props.className} themeBg={props.themeBg}>
      <span>{title}</span><span>{steps}</span>
    </TitleBarStyle>
  );
};

export { TitleBar };
