import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useContext, useEffect, useMemo
} from 'react';
import { Formik } from 'formik';
import type { FormikProps } from 'formik';
import { ThemeContext } from 'styled-components';
import type { IPermitPackageDocumentGenerationOptionsData } from '../../../../../domain/models/DocumentGenerationOptions/PermitPackage/PermitPackageDocumentGenerationOptions';
import {
  getRootStore, getUiStore
} from '../../../../../stores/RootStoreInversion';
import { notify } from '../../../../../utils/helpers';
import { ERROR } from '../../../../../domain/models/Constants';
import { PLAN_SET_CUSTOMIZATION_FORM_ID } from '../../../../../domain/stages/DesignStages/PlanSetPreviewStage';
import config from '../../../../../config/config';
import { PlanSetPreviewGeneratedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import { PlanSetCustomizationPageViewModel } from './PlanSetCustomizationPageViewModel';
import { PlanSetCustomizationAdvancedTab } from './PlanSetCustomizationAdvancedTab';
import { PlanSetCustomizationGeneralTab } from './PlanSetCustomizationGeneralTab';

const PlanSetCustomizationPage = observer((): ReactElement => {
  const { domain } = getRootStore();
  const { workspace } = getUiStore();
  const viewModel = useMemo(() => {
    const {
      modal, pages
    } = getUiStore();
    const { editor } = getRootStore();
    pages.pageViewModel = new PlanSetCustomizationPageViewModel({
      modal,
      domain,
      editor
    });
    return pages.pageViewModel;
  }, [domain]);

  const { showRotateSiteMapPlacardOption } = viewModel;
  const hasBoundary = domain.project.site.parcel.hasBoundary;

  // Aurora Design System
  const DS = useContext(ThemeContext)!.DS;

  useEffect((): void => {
    viewModel.retrieveDocumentGenerationOptions();
  }, [viewModel]);

  if (!viewModel.documentGenerationOptions) {
    return <DS.Loader />;
  }

  // When initializing a form, Formik requires an object with all fields present
  const initialFormValues = viewModel.documentGenerationOptions.toData({ forServer: false });

  return (
    <Formik<IPermitPackageDocumentGenerationOptionsData>
      enableReinitialize={false}
      initialValues={initialFormValues}
      validateOnMount={true}
      validate={(
        values: IPermitPackageDocumentGenerationOptionsData
      ): Record<'general' | 'engineerOfRecord.name', string | undefined> => viewModel.validate(values)}
      onSubmit={async (values: IPermitPackageDocumentGenerationOptionsData, formikHelpers): Promise<void> => {
        const { domain } = getRootStore();
        if (viewModel.documentGenerationOptions) {
          const uploadedDesignFiles = viewModel.documentGenerationOptions?.includedFiles.designFiles ?? [];
          viewModel.documentGenerationOptions.fromData(values, domain.internalReferenceId);
          viewModel.documentGenerationOptions.includedFiles.designFiles = uploadedDesignFiles;
        }
        try {
          await viewModel.saveOptionsAndGeneratePlanSet().then(() => workspace.designWorkspace?.stageManager?.next());
          config.analytics?.trackEvent(new PlanSetPreviewGeneratedEvent(domain));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Failed to generate or download plan set', e);
          notify(`Failed to download document: ${e}`, ERROR);
        }
      }}
    >
      {(formikbag: FormikProps<IPermitPackageDocumentGenerationOptionsData>) => (
        <DS.Form id={PLAN_SET_CUSTOMIZATION_FORM_ID}>
          <DS.Flex flexDirection="row" py={6} px={44} justifyContent="space-between">
            <DS.PageTemplateHeaderTitle>Plan set customization</DS.PageTemplateHeaderTitle>
          </DS.Flex>
          {viewModel.documentGenerationOptions ? (
            <DS.TabbedView
              applySystemPadding={true}
              tabs={[
                {
                  key: '1',
                  label: 'General',
                  view: <PlanSetCustomizationGeneralTab formikbag={formikbag} viewModel={viewModel} />
                },
                {
                  key: '2',
                  label: 'Advanced',
                  view: (
                    <PlanSetCustomizationAdvancedTab
                      formikbag={formikbag}
                      showRotateSiteMapPlacardOption={showRotateSiteMapPlacardOption}
                      hasBoundary={hasBoundary}
                    />
                  )
                }
              ]}
            />
          ) : (
            <DS.Loader />
          )}
        </DS.Form>
      )}
    </Formik>
  );
});

export default PlanSetCustomizationPage;
