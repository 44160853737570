import type { ShapePath } from 'three';
import {
  Box3, Color, DoubleSide, Group, Matrix4, Mesh, MeshBasicMaterial, ShapeGeometry
} from 'three';
import type { Dictionary } from '../../../domain/typings';

const SVGLoad = require('./THREE.SVGLoader.js');

type ShapePathExtended = ShapePath & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userData: Dictionary<any>;
};

type SVGResult = {
  paths: ShapePathExtended[];
  xml: XMLDocument;
};

export type SVGObject = {
  path: Group;
  boundingBox: Box3;
};

type ProgressCallback = (progress: ProgressEvent) => void;

const cache: {
  [key: string]: () => Promise<SVGObject>;
} = {};

function SVGResultToMesh(result: SVGResult, applyDefaultTranslation: boolean): Group {
  const group: Group = new Group();
  const paths = result.paths;
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    const fillColor = path.userData.style.fill;
    if (fillColor !== undefined && fillColor !== 'none') {
      const materialFill = new MeshBasicMaterial({
        color: new Color().setStyle(fillColor),
        opacity: path.userData.style.fillOpacity,
        transparent: path.userData.style.fillOpacity < 1,
        side: DoubleSide
      });

      const shapes = path.toShapes(true);
      for (let j = 0; j < shapes.length; j++) {
        const shape = shapes[j];
        const geometryFill = new ShapeGeometry(shape);
        const meshFill = new Mesh(geometryFill, materialFill);
        meshFill.name = 'mesh-fill';
        if (applyDefaultTranslation) {
          meshFill.applyMatrix4(new Matrix4().makeTranslation(30, 80, 0));
        }
        meshFill.rotateZ(Math.PI);
        group.add(meshFill);
      }
    }

    const strokeColor = path.userData.style.stroke;
    if (strokeColor !== undefined && strokeColor !== 'none') {
      const materialStroke = new MeshBasicMaterial({
        color: new Color().setStyle(strokeColor),
        opacity: path.userData.style.strokeOpacity,
        transparent: path.userData.style.strokeOpacity < 1,
        side: DoubleSide
      });

      for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
        const subPath = path.subPaths[j];
        const geometryStroke = SVGLoad.SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style);
        if (geometryStroke) {
          const meshStroke = new Mesh(geometryStroke, materialStroke);
          meshStroke.name = 'mesh-stroke';
          if (applyDefaultTranslation) {
            meshStroke.applyMatrix4(new Matrix4().makeTranslation(30, 80, 0));
          }
          meshStroke.rotateZ(Math.PI);
          group.add(meshStroke);
        }
      }
    }
  }
  return group;
}

/**
 *
 * @param url blob url of the svg image
 * @param onProgress
 * @param applyDefaultTranslation
 * @param cacheKey, use this key to rely on cache to avoid repeated loading of the same svg image
 */
function loadSVG(
  url: string,
  onProgress?: ProgressCallback,
  applyDefaultTranslation: boolean = false,
  cacheKey?: string
): Promise<SVGObject> {
  const loader = new SVGLoad.SVGLoader();
  if (cacheKey?.trim() && cache.hasOwnProperty(cacheKey)) {
    return cache[cacheKey]();
  }

  const promise = new Promise<SVGObject>(
    (resolve: (group: SVGObject) => void, reject: (error: ErrorEvent) => void): void => {
      loader.load(
        // resource URL
        url,
        // called when the resource is loaded
        (data: SVGResult): void => {
          const group = SVGResultToMesh(data, applyDefaultTranslation);

          resolve({
            path: group,
            boundingBox: new Box3().setFromObject(group)
          });
        },
        // called when loading is in progresses
        (xhr: ProgressEvent): void => {
          if (typeof onProgress === 'function') {
            onProgress(xhr);
          }
        },
        // called when loading has errors
        (error: ErrorEvent): void => {
          console.error('An error loading SVG in canvas happened', error);
          reject(error);
        }
      );
    }
  );

  if (cacheKey?.trim()) {
    cache[cacheKey] = () =>
      promise.then(
        ({
          path, boundingBox
        }: SVGObject): SVGObject => ({
          path: path.clone(),
          boundingBox: boundingBox.clone()
        })
      );
  }

  return promise;
}

export default loadSVG;
