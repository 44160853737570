import type { JSONSchema7 } from 'json-schema';
import type { UiSchema } from '@rjsf/utils';
import { encodeFieldName } from '../fieldNameUtils';
import type { FormData } from '../FormOptionsRulesAndState';
import {
  findFormDataValue, findFormSchemaField, removeEnumByIndex
} from '../effects/util';

const doSelection = (
  field: string,
  schema: JSONSchema7,
  constrainedByField: string,
  formData: FormData
): void => {
  const fieldEnums = findFormSchemaField(field, schema.properties)?.enum;
  if (fieldEnums) {
    const constrainingValue = findFormDataValue(
      encodeFieldName(constrainedByField), formData
    );
    if (fieldEnums) {
      for (let index = 0; index < fieldEnums.length; index++) {
        if ((fieldEnums[index] ?? 0) > (constrainingValue ?? 0)) {
          removeEnumByIndex(
            schema, field, index
          );
          index--;
        }
      }
    }
  }
};

type Constraint = {
  field: string,
  constrainedBy: string
}

const showOptionsLessThanOrEqual = (
  { field, constrainedBy }: Constraint,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
): void => {
  doSelection(
    field.split('.')[1],
    schema,
    constrainedBy,
    formData
  );
};

export default showOptionsLessThanOrEqual;
