export const HEADER: string = 'header';
export const PROGRESS_STEPPER = 'progress_stepper';
export const ORDER_LAYER_TOP: string = '2';
export const TOOL: string = 'tool';
export const CONTENT: string = 'content';
export const ASIDE: string = 'aside';
export const FOOTER: string = 'footer';
// CSS properties
export const WIDTH_TOOL: string = '46px';
export const WIDTH_ASIDE: number = innerWidth >= 2560 ? 370 : 250;
export const HEIGHT_TOP: string = '67px';
export const HEIGHT_BOTTOM: string = '64px';
export const HEIGHT_PROGRESS_STEPPER = '45px';
