import { Icon } from '../Icon';
import React, { ReactElement, ReactNode } from 'react';
import { IconWrapper, Message, MessageWrapper, MessageContainer } from './styles';
import { Paragraph } from '../../components/Typography';

export type Props = {
    icon?: string;
    className?: string;
    children: ReactNode;
};

const MessageBox = (props: Props): ReactElement => {
    const {
        icon,
        className
    } = props;
    return (
        <MessageContainer className={className}>
            {icon &&
            <IconWrapper>
                <Icon name={icon}/>
            </IconWrapper>
            }
            <MessageWrapper>
                <Message>
                    <Paragraph margin="0px">{props.children}</Paragraph>
                </Message>
            </MessageWrapper>
        </MessageContainer>
    );
};

export { MessageBox };
