import {
  LyraFontFamily, LyraIcon
} from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';

const {
  H4, Notes, Link
} = LyraFontFamily.FontFamily;

type Props = PropsWithTheme & {
  isSecondInverterColumnVisible?: boolean;
  active?: boolean;
  selected?: boolean;
};

export const progressBarStyle = {
  primaryFontSize: '12px',
  secondaryFontSize: '14px',
  barHeight: '20px'
};

export const ModalWrapper = styled.div<Props>`
  width: ${({ isSecondInverterColumnVisible }: Props): string => (isSecondInverterColumnVisible ? '100%' : '630px')};
`;

export const StatusView = styled.div<PropsWithTheme>`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
`;

export const StatusText = styled(H4)`
  font-size: 14px;
  font-weight: 300;
`;

export const StatusBar = styled.div`
  margin-top: 30px;
  margin-left: 21px;
`;

export const InverterAndEssView = styled.div<Props>`
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
`;

export const AlertViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const AlertViewDivider = styled.div`
  width: 20px;
  flex-shrink: 0;
`;

export const AlertView = styled.div<PropsWithTheme>`
  box-shadow: 0 2px 4px 0 ${({ theme }: PropsWithTheme): Color => theme.colors.blackOpacity};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
  height: fit-content;
`;

export const IconView = styled.div`
  display: flex;
  align-items: center;
`;

export const AlertIcon = styled(LyraIcon.Icon)``;

export const AlertText = styled(Notes)`
  line-height: 1.5;
`;

export const AddInverterView = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AddInverterText = styled(Link)<PropsWithTheme>`
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.primary};
  font-style: italic;
  line-height: 1.14;
  text-decoration: underline;
  cursor: pointer;
`;

export const DcOptimizerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const DcOptimizerItem = styled.div<Props>`
  padding: 5px;
  border: ${({
    selected, theme
  }: Props): string =>
    selected ? `solid 2px ${theme.colors.primary}` : `solid 1px ${theme.colors.tertiary}`};
  box-shadow: ${({ selected }: Props): string => (selected ? '0 2px 4px 0 rgba(0, 0, 0, 0.4)' : 'none')};
  opacity: ${({ active }: Props): number => (active ? 1 : 0.5)};
  border-radius: 4px;
  cursor: ${({ active }: Props): string => (active ? 'pointer' : 'not-allowed')};
  font-size: 11px;
  outline: solid 1px ${({
    selected, theme
  }: Props): string | Color => (selected ? 'none' : theme.colors.whiteBg)};
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    border: ${({
    theme, active, selected
  }: Props): string =>
    active && !selected ? ` solid 1px ${theme.colors.primary}` : ''};
    outline: ${({
    theme, active, selected
  }: Props): string =>
    active && !selected ? ` solid 1px ${theme.colors.primary}` : ''};
  }
`;

export const DetailList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  gap: 5px;
`;

export const DetailItem = styled(Notes)<PropsWithTheme>`
  border-right: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
  padding: 2px 5px 2px 0;
  :first-child {
    padding-left: 0;
  }
  :last-child {
    border: none;
  }
`;

export const InverterColumn = styled.div<Props>`
  width: ${({ isSecondInverterColumnVisible }: Props): string => (isSecondInverterColumnVisible ? '50%' : '100%')};
  border-radius: 4px;
  background: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  position: relative;
`;

export const TitleText = styled(H4)`
  font-size: 22px;
`;

export const InverterView = styled.div<Props>`
  max-height: 360px;
`;

export const DeleteIcon = styled(LyraIcon.Icon)`
  position: absolute;
  right: 5px;
  top: 15px;
  cursor: pointer;
`;

export const InverterListWrapper = styled.div`
  max-height: 310px;
  overflow-y: auto;
`;

export const OverdriveWrapper = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
`;
