/* eslint-disable react-hooks/exhaustive-deps */
import type { StepData } from '@aurorasolar/lyra-ui-kit';
import {
  LyraButtons, LyraIcon, LyraPipelineSnapping
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type {
  PropsWithChildren, ReactElement
} from 'react';
import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import defer from 'lodash/defer';
import slice from 'lodash/slice';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import { ThemeContext } from 'styled-components';
import type { IProgressStepperStage } from '../../../domain/stages/IProgressStepperStage';
import useStore, { useUiStore } from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import type { ElectricalBosStage } from '../../../domain/stages/DesignStages/ElectricalBosStage';
import type { MountingBosStage } from '../../../domain/stages/DesignStages/MountingBosStage';
import { Workspace } from '../../../stores/UiStore/WorkspaceStore/index';
import config, { UI_MODE } from '../../../config/config';
import { MOUNTING_BOS_FORM_ID } from '../MountingBos/MountingBos';
import { getUiStore } from '../../../stores/RootStoreInversion';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import { ProjectPropertiesModalAuroraUI } from '../Modals/ProjectPropertiesModalAuroraUI/ProjectPropertiesModalAuroraUI';
import type { PlanSetCustomizationStage } from '../../../domain/stages/DesignStages/PlanSetCustomizationStage';
import type { PlanSetPreviewPageViewModel } from '../Pages/pages/PlanSetPreview/PlanSetPreviewPageViewModel';
import { PlanSetPurchasedOrRevisionedEvent } from '../../../services/analytics/DesignToolAnalyticsEvents';
import DocumentGenerationMenu from './DocumentGenerationMenu';

import {
  ButtonPermitPackagePreview,
  ButtonSiteDefinition,
  DocumentWrapper,
  ProgressContainer,
  WrapperStepper
} from './styles';

interface IProgressStepperProps {
  showSalesMenuSection: boolean;
}

const ProgressStepper = (props: IProgressStepperProps): ReactElement => {
  const { domain } = useStore();
  const {
    properties, workspace
  } = useUiStore();

  const designWorkspace = workspace.designWorkspace as DesignWorkspace;
  const currentDesign = domain.optionalDesign;
  const stageManager = designWorkspace?.stageManager;
  const currentStage = stageManager?.currentStage;
  const minEnabledStage = config.featureFlag.uiMode === UI_MODE.AURORA ? 3 : 0;

  const [menuVisible, setMenuVisible] = useState(false);
  const [stepData, setStepData] = useState<StepData[]>([]);
  const [documentsAvailable, setDocumentsAvailable] = useState<number>(0);

  const theme = useContext(ThemeContext);
  let DS = null;

  if (config.featureFlag.uiMode === UI_MODE.AURORA) {
    DS = theme!.DS;
  }

  const calculateStepData = (): void => {
    const stages = stageManager?.stages ?? [];
    const result: StepData[] = slice(
      stages.filter((stage: IProgressStepperStage): boolean => !!stage.title),
      minEnabledStage
    );
    setStepData(result);
  };

  useEffect((): void => {
    if (currentStage) {
      calculateStepData();
      getStatusNumber();
      properties.setPropertyPanel(currentStage.propCodeUI);
    }
  }, [currentStage, properties]);

  const openDocumentGenerationMenu = (): void => {
    setMenuVisible(!menuVisible);
  };

  const showStepper = (): boolean => {
    return !!(currentStage?.title && stepData.length);
  };

  const previousStep = useCallback((): Promise<void> => {
    if (!stageManager!.stageTransitionInProgress) {
      return stageManager!.previous();
    }
    return Promise.resolve();
  }, [stageManager]);

  const nextStep = useCallback((): Promise<void> => {
    if (!stageManager || stageManager?.stageTransitionInProgress) {
      return Promise.resolve();
    }

    // If we let this method run normally,
    // the submit buttons will never fire a submit event on forms they link to
    // Stage 6 — Mounting BOS
    // Stage 7 — Plan set customization
    const auroraMountingBosStageHasFormFields =
      stageManager.currentIndex === 6 && (stageManager.currentStage as MountingBosStage)?.hasFormFields;
    const isAuroraCustomizationStage = stageManager.currentIndex === 7;

    if (
      config.featureFlag.uiMode === UI_MODE.AURORA
      && (auroraMountingBosStageHasFormFields || isAuroraCustomizationStage)
    ) {
      return Promise.resolve();
    }

    if (
      stageManager.currentStage.id !== DesignStep.ELECTRICAL_BOS
      // Electrical stage is exceptional here. We need to check
      // if electrical equipment placed first and then let user
      // review circuits in separate modal. After review, user can proceed to the next stage.
      || (currentStage as ElectricalBosStage).isEquipmentPlaced
    ) {
      return stageManager.next();
    }

    return Promise.resolve();
  }, [stageManager, currentStage]);

  const isCurrentAndLastStep = (step: DesignStep): boolean => {
    return (
      !!stageManager
      && stageManager.currentIndex === stageManager.stages.length - 1
      && stageManager.currentStage.id === step
    );
  };

  const mountingBosStep = (): MountingBosStage => {
    return stageManager!.currentStage as MountingBosStage;
  };

  const getStatusNumber = (): void => {
    const stageIndex = stageManager!.currentIndex;
    const stageId = stageManager!.currentStage.id;
    switch (stageId) {
      case DesignStep.ARRAY_PLACEMENT: {
        setDocumentsAvailable(0);
        break;
      }
      case DesignStep.LAYOUT_DESIGN: {
        setDocumentsAvailable(2);
        break;
      }
      case DesignStep.ELECTRICAL_DESIGN: {
        setDocumentsAvailable(3);
        break;
      }
      case DesignStep.ELECTRICAL_BOS:
      case DesignStep.CIRCUIT_TABLE:
      case DesignStep.MOUNTING_BOS:
      case DesignStep.CUSTOMIZATION:
      case DesignStep.PLAN_SET_PREVIEW: {
        setDocumentsAvailable(6);
        break;
      }
      case DesignStep.COMPLETED: {
        setDocumentsAvailable(7);
      }
    }
  };

  const changeWorkspace = useCallback((): void => {
    if (!stageManager?.stageTransitionInProgress) {
      workspace.changeWorkspace(Workspace.Project);
    }
  }, [workspace]);

  const getDropdownIcon = (): string => {
    if (config.featureFlag.uiMode !== UI_MODE.AURORA) {
      return 'icon-available-documents';
    } else if (menuVisible) {
      return 'aurora-download-chevron-up';
    }
    return 'aurora-download';
  };

  const showProgressContainer = designWorkspace && showStepper();
  const [propertiesModalOpened, setPropertiesModalOpened] = useState(false);

  if (!showProgressContainer) {
    return <></>;
  }
  const currentStep = stageManager!.currentIndex - 1;
  const isArrayPlacementStep = currentStep === 0;
  const documentsButtonDisabled = documentsAvailable === 0 || !currentDesign?.hasPvModules;
  const showDocumentGenerationMenu = menuVisible && !isArrayPlacementStep;

  const electricalBosStage = currentStage as ElectricalBosStage;
  const cannotContinueToMountingBosStage =
    stageManager?.currentStage.id === DesignStep.ELECTRICAL_BOS
    && (!electricalBosStage?.isEquipmentPlaced || electricalBosStage?.showCircuitTableOrGenerateLocationsInProgress);

  const mountingBosStage = currentStage as MountingBosStage;
  const cannotContinueToPlanSetCustomization =
    stageManager?.currentStage.id === DesignStep.MOUNTING_BOS
    && (!mountingBosStage?.formSpecificationLoaded || mountingBosStage?.formSubmitInProgress);

  const planSetCustomizationStage = currentStage as PlanSetCustomizationStage;
  const cannotContinueToPlanSetPreview =
    stageManager?.currentStage.id === DesignStep.CUSTOMIZATION
    && (!planSetCustomizationStage?.lastValidationSuccessful || planSetCustomizationStage?.isGeneratingPlanSet);

  const isRightButtonDisabled =
    cannotContinueToMountingBosStage || cannotContinueToPlanSetCustomization || cannotContinueToPlanSetPreview;

  return (
    <ProgressContainer>
      <WrapperStepper>
        {isArrayPlacementStep && (
          <ButtonSiteDefinition onClick={changeWorkspace}>
            <LyraIcon.Icon name="arrow" colorTheme={EThemeComponentColor.AQUAMARINE} />
            <span>Back to Site Definition</span>
          </ButtonSiteDefinition>
        )}

        <LyraPipelineSnapping.PipelineSnapping
          currentStep={currentStep < minEnabledStage ? 0 : currentStep - minEnabledStage}
          data={stepData}
          onClickLeft={previousStep}
          onClickRight={nextStep}
          rightButtonDisabled={isRightButtonDisabled}
          isLoading={planSetCustomizationStage?.isGeneratingPlanSet}
          auroraMode={config.featureFlag.uiMode === UI_MODE.AURORA}
        />

        {/* Last step for Lyra mode */}
        {isCurrentAndLastStep(DesignStep.MOUNTING_BOS) && (
          <PermitPackagePreviewButton
            disabled={!mountingBosStep().formSpecificationLoaded}
            openPermitPackagePreviewModal={(): void => {
              // API doesn't allow receiving empty BOS forms,
              // so we should just open the modal/page without sending any requests
              if (mountingBosStep().formSpecificationLoaded && !mountingBosStep().hasFormFields) {
                defer((): void => mountingBosStep().openPermitPackageModalOrPage());
              }
            }}
            auroraMode={config.featureFlag.uiMode === UI_MODE.AURORA}
            isSubmitButton={mountingBosStep().hasFormFields}
          >
            <span>Preview {config.featureFlag.uiMode !== UI_MODE.AURORA ? 'Permit Package' : 'permit package'}</span>
          </PermitPackagePreviewButton>
        )}

        {isCurrentAndLastStep(DesignStep.PLAN_SET_PREVIEW) && <GeneratePlanSetButton />}
      </WrapperStepper>

      <DocumentWrapper>
        <LyraButtons.ButtonIcon
          icon={getDropdownIcon()}
          className={config.featureFlag.uiMode !== UI_MODE.AURORA ? '' : 'ph-xs'}
          fullWidth={config.featureFlag.uiMode !== UI_MODE.AURORA}
          colorTheme={
            config.featureFlag.uiMode !== UI_MODE.AURORA ? EThemeComponentColor.GRAY : EThemeComponentColor.WHITE
          }
          iconStyles={
            config.featureFlag.uiMode !== UI_MODE.AURORA
              ? undefined
              : {
                width: '24px',
                height: '24px'
              }
          }
          disabled={documentsButtonDisabled}
          onClick={openDocumentGenerationMenu}
        >
          {config.featureFlag.uiMode !== UI_MODE.AURORA && 'Available Documents'}
        </LyraButtons.ButtonIcon>
        {showDocumentGenerationMenu && <DocumentGenerationMenu showSalesMenuSection={props.showSalesMenuSection} />}

        {config.featureFlag.uiMode === UI_MODE.AURORA ? (
          <>
            <DS.Button
              icon={DS!.IconCustomization}
              variant="tertiary"
              action={(): void => {
                setPropertiesModalOpened(true);
              }}
            />
            <ProjectPropertiesModalAuroraUI
              isOpened={propertiesModalOpened}
              onClose={(): void => setPropertiesModalOpened(false)}
            />
          </>
        ) : (
          ''
        )}
      </DocumentWrapper>
    </ProgressContainer>
  );
};

interface IPermitPackagePreviewButtonProps {
  disabled: boolean;
  auroraMode?: boolean;
  openPermitPackagePreviewModal?: () => void;
  isSubmitButton: boolean;
}

/**
 * There are two scenarios:
 * 1. Mounting BOS form exists, then we render a button that submits the form (so that form validation can run)
 * 2. Mounting BOS form does not exist, then we simply open permit package dialog
 */
function PermitPackagePreviewButton(props: PropsWithChildren<IPermitPackagePreviewButtonProps>): ReactElement {
  const {
    disabled, children, openPermitPackagePreviewModal, auroraMode, isSubmitButton
  } = props;

  if (auroraMode) {
    const mountingBosStage = getUiStore().workspace.designWorkspace?.stageManager?.currentStage as
      | MountingBosStage
      | undefined;
    return (
      <LyraButtons.Buttons
        styleType={ButtonStyleType.AURORA}
        onClick={openPermitPackagePreviewModal}
        disabled={disabled}
        submitForm={mountingBosStage?.hasFormFields && isSubmitButton ? MOUNTING_BOS_FORM_ID : ''}
      >
        {children}
      </LyraButtons.Buttons>
    );
  }
  return (
    <ButtonPermitPackagePreview onClick={openPermitPackagePreviewModal} disabled={disabled} auroraMode={auroraMode}>
      {children}
    </ButtonPermitPackagePreview>
  );
}

const GeneratePlanSetButton = observer((): ReactElement => {
  const DS = useContext(ThemeContext)!.DS;
  const { domain } = useStore();
  const { pages } = getUiStore();
  const viewModel = pages.pageViewModel as PlanSetPreviewPageViewModel;

  if (!viewModel) {
    return <></>;
  }

  return (
    <DS.Button
      variant="primary"
      h={44}
      // If using a boolean instead of a number, the loading shim inside the button will have inline style of
      // width: (100% + 2px), which causes scrollbars to show.
      // Otherwise, its width will be a percentage of button's width, defined below
      loading={viewModel.isDownloading ? 0.1 : 0}
      disabled={viewModel.isDownloading}
      onClick={() => {
        viewModel.downloadPlanSet();
        config.analytics?.trackEvent(new PlanSetPurchasedOrRevisionedEvent(domain));
      }}
    >
      Create plan set
    </DS.Button>
  );
});

export default observer(ProgressStepper);
