import React, { BaseProps, ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, ButtonWrapper } from './styles';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';
import { Flexbox } from '../../components/Layout';

export type ButtonOption = {
  name: string | ReactElement;
  value: string | number;
  caption?: string;
};

export type Props = BaseProps & {
  selected?: string | number;
  onSelect?: (value: string | number) => void;
  options: ButtonOption[];
  height?: string;
  withCaption?: boolean;
  disabled?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

const ButtonGroup = ({
  height,
  onSelect,
  selected,
  options,
  withCaption = false,
  disabled = false,
  className,
  selectedOptionColor
}: Props): ReactElement => {

  const handleSelect = useCallback((currentSelectedValue: string | number): void => {
    if (typeof onSelect !== 'function') return;
    onSelect(currentSelectedValue);

  }, [selected]);

  const optionsElements = options.map(
    (option: ButtonOption, index: number): ReactElement => (
      <ButtonWrapper key={index} flexGrow={1 / options.length} height={height} disabled={disabled}>
        <Button
          disabled={disabled}
          selectedOptionColor={selectedOptionColor}
          selected={option.value === selected}
          onClick={disabled ? undefined : handleSelect.bind(null, option.value)}
        >
          {option.name}
        </Button>
        {withCaption && <p>{option.caption ?? ''}</p>}
      </ButtonWrapper>
    )
  );
  return <Flexbox justify="space-between" align="center" wrap="wrap" className={className}>{optionsElements}</Flexbox>;
};

export { ButtonGroup };
