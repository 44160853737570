import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../../../domain/typings';
import Flexbox from '../../../../../ui/components/Layout/Flexbox';

type Container = PropsTheme & {
  editing?: boolean;
  padding?: string;
};

export const RoofTypeSelectionWrapper = styled(Flexbox).attrs({
  dir: 'column',
  justify: 'center'
})`
  width: 100%;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  padding: 15px 10px 0;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  & div:first-child {
    & > li {
      &:first-child {
        margin: 0 4px 0 12px;
      }
      &:last-child {
        margin: 0 12px 0 4px;
      }
      min-height: 68px;
      & svg {
        display: block;
        margin: auto;
      }
    }
  }
`;

export const WrapperContainer = styled.div<Container>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
`;

export const CustomContainer = styled.div<Container>`
  border-top: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  padding: 15px 5px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  display: flex;
  justify-content: center;
`;

export const BasicPropsBox = styled.div<Container>`
  border-radius: ${({ editing }: Container): string => (editing ? '4px 4px 0px 0px ' : '4px')};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  .box {
    height: 68px;
    .text-box {
      width: 130px;
      .title-box {
        font-size: 10px;
        margin-bottom: 3px;
      }
      .name-box {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
`;
