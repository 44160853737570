import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useState
} from 'react';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import { ListButtons } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import map from 'lodash/map';
import type {
  StringConnected,
  SystemSummary,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';

import type {
  IMpptStringingOptionData,
  ISeriesStringConfigurationData,
  ISeriesStringConfigurationOptionsData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import { precisionNumber } from '../../../../utils/helpers';
import StringingService from '../../../../services/stringing/stringingService';
import type { SelectStringingInInverterSummaryModalPayload } from '../../../../services/eventSystem/eventSystemHook';
import {
  EventType, useEventSystemSubscribe
} from '../../../../services/eventSystem/eventSystemHook';
import type { Stringing } from '../../../../domain/graphics/stringing/Stringing';
import {
  ContainerBranch, ListButtonsContainer, TitleBranch, TitleBranchContainer
} from './MicroinverterStyle';
import {
  DCACRatioContainer,
  DCACRatioProgressBarContainer,
  DCACRatioTitle,
  DCACRatioTitleContainer
} from './StringInverterWithMLPEMPPTsStyle';
import StringInParallel from './StringsInParallel';
import {
  DownArrow, PanelHeader, PanelText, RightArrow, SummaryContentWrapper
} from './styles';
import { InverterExpansionState } from './types';
import {
  createHandleClickOnInverterHeader,
  createOnSelectedStringingChanged,
  useIgnoreStringingSelection
} from './commonSummaryPanelFunctions';

const { DottedProgressBar } = LyraProgressBar;

const StringInverterWithInternalMPPTS = observer((): ReactElement => {
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, selectedString = ''
  } = systemSummaryViewModel;

  const [inverterExpandedFlagsMap, setInverterExpandedFlagsMap] = useState<{ [key: number]: InverterExpansionState }>(
    new Array(systemSummaryList.length).fill(InverterExpansionState.Expanded)
      .reduce((accumulator, value, index) => {
        accumulator[index] = value;
        return accumulator;
      }, {})
  );

  const [ignoreStringingSelection, setIgnoreStringingSelection] = useIgnoreStringingSelection();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelectedStringingChanged = useCallback(
    createOnSelectedStringingChanged({
      ignoreStringingSelection,
      setIgnoreStringingSelection,
      inverterExpandedFlagsMap,
      setInverterExpandedFlagsMap,
      systemSummaryList
    }),
    [inverterExpandedFlagsMap, ignoreStringingSelection, systemSummaryList]
  );

  useEventSystemSubscribe<SelectStringingInInverterSummaryModalPayload>(
    EventType.SelectStringingInInverterSummaryModal,
    onSelectedStringingChanged
  );

  const summaryPanelContent = systemSummaryList.map((systemSummary: SystemSummary, index: number): ReactElement => {
    const stringsConnected = systemSummary.strings;
    const definition = systemSummary.definition as ISeriesStringConfigurationOptionsData;
    const optionsListButtons: OptionProps[] = [];
    let selectedBranch = {
      index: -1,
      stringsFoundServerIds: [] as string[],
      value: '-'
    };

    let anyStringIsSelected = false;

    definition.mppts?.forEach((item: ISeriesStringConfigurationData, idx: number): void => {
      const value = String.fromCharCode('A'.charCodeAt(0) + idx);
      const stringsFound = stringsConnected.filter((elem: StringConnected): boolean => elem.getMpptId() === item.mppt);
      const stringsFoundServerIds = map(stringsFound, 'serverId');
      const mpptIsSelected = stringsFoundServerIds.includes(selectedString);
      let selectCurrentBranch = false;

      if (mpptIsSelected && selectedBranch.value !== value) {
        selectCurrentBranch = true;
        anyStringIsSelected = true;
      }

      if (stringsFound[0]?.getId()) {
        if (!anyStringIsSelected && selectedBranch.value !== value) {
          selectCurrentBranch = true;
          anyStringIsSelected = true;
        }

        optionsListButtons.push({
          id: stringsFound[0].getId(),
          index: optionsListButtons.length,
          value
        });
      }

      if (selectCurrentBranch) {
        selectedBranch = {
          index: optionsListButtons.length,
          stringsFoundServerIds,
          value
        };
      }
    });

    const isInverterExpandedOrSelected = inverterExpandedFlagsMap[index] !== InverterExpansionState.Collapsed;

    const handleClickOnInverterHeader = createHandleClickOnInverterHeader({
      inverterExpandedFlagsMap,
      setInverterExpandedFlagsMap,
      index,
      stringsConnected,
      ignoreStringingSelection: ignoreStringingSelection,
      setIgnoreStringingSelection: setIgnoreStringingSelection
    });

    const getDcAcRatio = (): number => {
      let pStc = 0;
      const ratedPowerOutput = definition.inverterDefinition.ratedPowerOutput ?? 1;
      pStc = stringsConnected.reduce((acc: number, item: StringConnected): number => {
        if (!item.stringingOption) {
          return acc;
        }
        const stringingOption = item.stringingOption as IMpptStringingOptionData;
        return acc + (stringingOption.seriesStringDefinition?.pStc ?? 0);
      }, 0);
      return precisionNumber((pStc * 100) / ratedPowerOutput, 2);
    };

    const getMaxDcAcRatio = (): number => {
      const maxDcACRationValue = Math.round(definition.inverterDefinition.maxDcAcRatio * 100);
      return maxDcACRationValue || 100;
    };

    const inverterNumber = systemSummaryList.length > 1 ? `${index + 1}-` : '';

    const onDeleteActiveString = (): void => {
      if (StringingService.activeSelectedString) {
        systemSummaryViewModel.callDeleteStringCommand(
          `String ${inverterNumber}${selectedBranch.value}`,
          StringingService.activeSelectedString
        );
      } else {
        const stringToDelete = StringingService.strings.find(
          (string: Stringing): boolean => string.serverId === selectedBranch.stringsFoundServerIds[0]
        );
        if (stringToDelete) {
          systemSummaryViewModel.callDeleteStringCommand(
            `String ${inverterNumber}${selectedBranch.value}`,
            stringToDelete
          );
        }
      }
    };

    return (
      <SummaryContentWrapper key={index}>
        <PanelHeader onClick={handleClickOnInverterHeader}>
          <PanelText>{`Inverter ${index + 1}`}</PanelText>
          {isInverterExpandedOrSelected ? <DownArrow name="arrow-collapse" /> : <RightArrow name="arrow-collapse" />}
        </PanelHeader>
        {isInverterExpandedOrSelected && (
          <>
            <DCACRatioContainer>
              <DCACRatioTitleContainer>
                <DCACRatioTitle>DC-AC Ratio</DCACRatioTitle>
              </DCACRatioTitleContainer>
              <DCACRatioProgressBarContainer>
                <DottedProgressBar
                  currentValue={getDcAcRatio()}
                  units={'%'}
                  minValue={0}
                  maxValue={100}
                  possibleMaxValue={getMaxDcAcRatio()}
                  strokeColor={'#36A247'}
                  style={{
                    primaryFontSize: '8px',
                    secondaryFontSize: '10px',
                    barHeight: '14.6px'
                  }}
                />
              </DCACRatioProgressBarContainer>
            </DCACRatioContainer>

            <ContainerBranch>
              <TitleBranchContainer>
                <TitleBranch>MPPT</TitleBranch>
              </TitleBranchContainer>
              {!!optionsListButtons.length && (
                <ListButtonsContainer>
                  <ListButtons
                    onClickEvent={(response: OptionProps): void => {
                      ignoreStringingSelection.value = true;
                      setIgnoreStringingSelection(ignoreStringingSelection);
                      StringingService.selectStringById(response.id);
                    }}
                    selected={selectedBranch.value}
                    options={optionsListButtons}
                  />
                </ListButtonsContainer>
              )}
            </ContainerBranch>
            <StringInParallel index={index} onDeleteActiveString={onDeleteActiveString} />
          </>
        )}
      </SummaryContentWrapper>
    );
  });

  return <>{summaryPanelContent}</>;
});

export default StringInverterWithInternalMPPTS;
