import type { MeshBasicMaterial } from 'three';
import {
  PlaneGeometry, CircleGeometry, Mesh, Group
} from 'three';

const WrapperMesh = (height: number, width: number, material: MeshBasicMaterial): Group => {
  const group = new Group();

  const planeMesh = new Mesh(new PlaneGeometry(width, height * 2), material);
  const sphereMesh = new Mesh(new CircleGeometry(height, 32, Math.PI / 2, Math.PI), material);
  const sphereMesh2 = new Mesh(new CircleGeometry(height, 32, -Math.PI / 2, Math.PI), material);

  sphereMesh.position.x = -width / 2;
  sphereMesh2.position.x = width / 2;
  group.add(planeMesh);
  group.add(sphereMesh);
  group.add(sphereMesh2);

  return group;
};

export default WrapperMesh;
