import React, { BaseProps, ReactElement, useEffect, useState } from 'react';
import {
  Background,
  Container,
  WarningModalHeader,
  WarningModalHeaderText,
  WarningContentWrapper,
  WarningCloseButtonHeader,
  ContentWrapper,
  FooterWrapper
} from './styles';
import { CustomTabBar, ITabsProps } from '../Buttons';
import { FooterProps, Footer } from './Footer';
import { Icon } from '../Icon';

export type Props = BaseProps &
  FooterProps & {
    show?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    tabItems: ITabsProps[];
    trigger: (index: number) => void;
    children: ReactElement;
    customFooter?: ReactElement;
  };

const WarningModal = (props: Props): ReactElement => {
  const {
    className = '',
    show = false,
    onOpen,
    onClose,
    tabItems,
    trigger,
    children,
    customFooter,
    ...rest
  } = props;
  const [display, setDisplay] = useState(true);

  useEffect((): void => {
    if (!show) setTimeout((): void => setDisplay(false), 500);
    else setDisplay(true);
  }, [show]);

  useEffect((): void => (display ? onOpen && onOpen() : onClose && onClose()), [display]);

  return !show && !display ? (
    <></>
  ) : (
    <Background show>
      <Container className={className}>
        <WarningCloseButtonHeader dir="row">
          <span
            onClick={onClose}
            data-testid="warning-modal-close-button"
          >
            <Icon name="close"/>
          </span>
        </WarningCloseButtonHeader>
        <WarningModalHeader dir="row">
          <WarningModalHeaderText>
            Before moving to System Designs,
            please complete the following parameters!
          </WarningModalHeaderText>
          <CustomTabBar
            tabItems={tabItems}
            handleClick={trigger}
            className="mt-m"
          />
        </WarningModalHeader>
        <WarningContentWrapper>
          <ContentWrapper>
            {children}
          </ContentWrapper>
          <FooterWrapper>
            { customFooter ? customFooter :
              <Footer {...rest} />
            }
          </FooterWrapper>
        </WarningContentWrapper>
      </Container>
    </Background>
  );
};

export { WarningModal };
