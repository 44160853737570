import { LyraOptionsBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import { useUiStore } from '../../../../stores/useStore';
import type { BaseGuide } from '../../../../stores/UiStore/SmartGuidesStore/SmartGuides/BaseGuide';
import Flexbox from '../../../components/Layout/Flexbox';
import Item from './SmartGuideTooltip';
const { OptionsBar } = LyraOptionsBar;

function getOptions(guides: BaseGuide[]): LyraOptionsBar.Option[] {
  return guides.map(
    (guide: BaseGuide, index: number): LyraOptionsBar.Option => ({
      ...guide,
      label: <Item key={index} guide={guide} />,
      value: guide.identifier,
      isActive: guide.isActive,
      isEnabled: guide.isEnabled
    })
  );
}

const SmartGuideControls: React.FC = (): ReactElement => {
  const { smartGuides } = useUiStore();

  const onSelect = useCallback(
    (value: string | number): void => {
      smartGuides.selectGuides(value as number);
    },
    [smartGuides]
  );

  const options = getOptions(smartGuides.enableGuides ?? []);

  return (
    <Flexbox dir="column" align="center" justify="center">
      <OptionsBar
        options={options}
        onSelect={onSelect}
        header="Smart Guides"
        tooltipPosition="bottom"
        tooltipTheme="dark"
        arrowPosition="top"
      />
    </Flexbox>
  );
};

export default observer(SmartGuideControls);
