import { CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID } from '../ToolbarStore/Project/constants';

export enum PropsPanelUICodes {
  ArrayPlacement = 'array_placement',
  CustomBaseImageryTransformation = 'custom_base_imagery_transformation_panel',
  ElectricalBos = 'electrical_bos',
  ElectricalDesign = 'electrical_design',
  GasMeter = 'gas_meter',
  HintPanelOutline = 'outline',
  HintPanelOutlineTool = 'hint_outline_tool',
  HintPanelParcelBoundary = 'parcel_boundary',
  HintPanelParcelBoundaryTool = 'parcel_boundary_tool',
  HintPanelSiteMarkerGasMeter = 'hint_gas_meter',
  HintPanelServiceEntranceEquipment = 'hint_service_entrance_equipment',
  HintPanelSiteMarkerStreetLocation = 'hint_street_location',
  HintPanelSiteMarkerSubpanel = 'hint_subpanel',
  HintPanelTraceIndividualRoofFace = 'hint_trace_individual_roof_face',
  ServiceEntranceEquipmentOptions = 'service_entrance_equipment_options',
  LayoutDesign = 'layout_design',
  MainServicePanel = 'main_service_panel',
  MountingBos = 'mounting_bos',
  RoofProperties = 'roof_face',
  RoofProtrusions = 'rectangular_protrusion',
  ScaleToDefineScaling = 'scale_to_define_scaling',
  SetbackPathwayProps = 'setback_pathway',
  SiteStructure = 'site_structure',
  StreetLocation = 'street_location',
  Subpanel = 'subpanel',
  UtilityMeter = 'utility_meter',
  ServiceEntranceEquipmentForm = 'service_entrance_equipment_form'
}

export const PropsPanelServiceEntranceEquipmentUICodesSet: Set<PropsPanelUICodes> = new Set([
  PropsPanelUICodes.MainServicePanel,
  PropsPanelUICodes.UtilityMeter
]);

export const toolOverwriteMap: {
  [key: string]: PropsPanelUICodes;
} = {
  [CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID]: PropsPanelUICodes.CustomBaseImageryTransformation
};
