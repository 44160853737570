import {
  action, observable
} from 'mobx';
import {
  type Material, type Object3D, SRGBColorSpace
} from 'three';
import {
  NearestFilter, Mesh, DoubleSide, MeshBasicMaterial, PlaneGeometry, Texture
} from 'three';
import env from '@beam-australia/react-env';
import getGoogleMapStaticImageUrl from '../../../application/getGoogleMapStaticImageUrl';
import type { Coords } from '../../../domain/typings';
import { BaseImageryProvider } from '../../../domain/typings';
import config from '../../../config/config';
import type EditorStore from '../../EditorStore/EditorStore';
import type { ISiteData } from '../../../domain/entities/SiteDesign/Site';
import { MAXIMUM_ZOOM_LEVEL } from '../../EditorStore/constants';
import {
  DEFAULT_Z, ERROR
} from '../../../domain/models/Constants';
import { LayerCanvas } from '../../../domain/graphics/LayerCanvas';
import { load } from '../../../infrastructure/services/drawingArea/loader';
import { CustomBaseImageryMesh } from '../../../domain/models/CustomBaseImageryMesh';
import { notify } from '../../../utils/helpers';
import { getLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';

const noMapSizeX = Number(env('MAPS_RESOLUTION_X')) * 2;
const noMapSizeY = Number(env('MAPS_RESOLUTION_Y')) * 2;

class MapStore {
  private zoomLimit?: number;

  @observable
  googleMap: typeof google.maps | undefined;
  private jwtToken: string = '';

  @observable
  selectedProvider: BaseImageryProvider;

  previousCanvasBackground?: Object3D;

  constructor() {
    this.selectedProvider = BaseImageryProvider.GOOGLE_MAPS;
  }

  setAuthToken(token: string): void {
    this.jwtToken = token;
  }

  private async createTextureMesh(
    texture: Texture,
    mapZoomFactor: number,
    editor: EditorStore,
    isCustom: boolean = false
  ): Promise<Mesh> {
    texture.minFilter = NearestFilter;
    texture.colorSpace = SRGBColorSpace;

    const material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      transparent: true,
      opacity: 1,
      ...LayerCanvas.BACKGROUND
    });
    const planeWidth = texture.image.width;
    const planeHeight = texture.image.height;

    return this.createBackgroundMeshWithMaterial({
      material,
      width: planeWidth * Math.pow(2, mapZoomFactor),
      height: planeHeight * Math.pow(2, mapZoomFactor),
      isCustom
    });
  }

  private createBackgroundMeshWithMaterial({
    width,
    height,
    material,
    isCustom
  }: {
    width: number;
    height: number;
    material: Material;
    isCustom: boolean;
  }): Mesh {
    const planeGeometry = new PlaneGeometry(1, 1);
    const canvasBackground = isCustom
      ? new CustomBaseImageryMesh(planeGeometry, material).mesh
      : new Mesh(planeGeometry, material);
    canvasBackground.scale.x = width;
    canvasBackground.scale.y = height;
    canvasBackground.position.set(0, 0, DEFAULT_Z);
    canvasBackground.name = 'canvasBackground';
    return canvasBackground;
  }

  private async createWhiteEmptyMesh(editor: EditorStore, mapZoomFactor: number): Promise<Mesh> {
    if (this.previousCanvasBackground) {
      editor.removeObject(this.previousCanvasBackground);
    }

    const material = new MeshBasicMaterial({
      color: 'rgb(242, 242, 242)',
      transparent: false,
      side: DoubleSide,
      ...LayerCanvas.BACKGROUND
    });

    const canvasBackground = this.createBackgroundMeshWithMaterial({
      material,
      width: noMapSizeX * Math.pow(2, mapZoomFactor),
      height: noMapSizeY * Math.pow(2, mapZoomFactor),
      isCustom: false
    });

    editor.addBackground(canvasBackground);

    this.previousCanvasBackground = canvasBackground;

    return canvasBackground;
  }

  @action.bound
  setProvider(provider: BaseImageryProvider, zoomLevel: number): void {
    this.selectedProvider = provider;
    this.zoomLimit = zoomLevel;
  }

  // Used from map store when we're loading or switching base imagery (map), and from
  // CustomBaseImageryTransformationTool when editing a custom base (map) image.
  async loadCustomBaseImageryAndCreateMesh({
    projectId,
    editor,
    mapZoomFactor,
    projectSiteImagery
  }: {
    projectId: string;
    mapZoomFactor: number;
    editor: EditorStore;
    projectSiteImagery: ISiteData['imagery'];
  }): Promise<CustomBaseImageryMesh> {
    return new Promise<CustomBaseImageryMesh>((resolve): void => {
      const context = this;
      // Wrapping in immediately invoked async function to use await.
      (async function asyncAwaitWrapper() {
        try {
          // TextureLoader accepts headers according to documentation, but it doesn't
          // work. Hence, the fetch-base solution.
          const textureUrl = context.getCustomMapImageUrl(projectId, editor.domain.project.account);
          const response = await fetch(textureUrl, {
            headers: {
              Authorization: `Bearer ${context.jwtToken}`,
              Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8'
            }
          });
          const imageBase64 = URL.createObjectURL(await response.blob());
          const texture = new Texture();
          const image = new Image();
          image.src = imageBase64;
          image.onload = async () => {
            texture.image = image;
            texture.needsUpdate = true;

            const mesh = await context.createTextureMesh(texture, mapZoomFactor, editor, true);
            mesh.position.set(
              projectSiteImagery.CUSTOM!.translationVector.x,
              projectSiteImagery.CUSTOM!.translationVector.y,
              0
            );
            mesh.rotation.z = projectSiteImagery.CUSTOM!.rotation;
            mesh.scale.x *= projectSiteImagery.CUSTOM!.scaleFactor;
            mesh.scale.y *= projectSiteImagery.CUSTOM!.scaleFactor;

            resolve(getLyraModelByMesh(mesh));
          };
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Custom base imagery loading failed', e);
          notify('Custom base imagery loading failed', ERROR);
        }
      })();
    });
  }

  async createMapTextureMesh({
    projectId,
    projectBaseImagery,
    coordinateSystemOrigin,
    editor
  }: {
    projectId: string;
    projectBaseImagery: ISiteData['imagery'];
    coordinateSystemOrigin: Coords;
    editor: EditorStore;
  }): Promise<Mesh> {
    const {
      zoomLevel, provider: baseImageryProvider
    } = projectBaseImagery;

    const mapZoomFactor = zoomLevel > MAXIMUM_ZOOM_LEVEL ? -1 : 20 - zoomLevel;
    this.setProvider(baseImageryProvider, zoomLevel > MAXIMUM_ZOOM_LEVEL ? MAXIMUM_ZOOM_LEVEL : zoomLevel);

    switch (baseImageryProvider) {
    case BaseImageryProvider.CUSTOM: {
      const customImageryMesh = await this.loadCustomBaseImageryAndCreateMesh({
        projectId,
        editor,
        mapZoomFactor,
        projectSiteImagery: projectBaseImagery
      });
      return customImageryMesh.mesh;
    }

    case BaseImageryProvider.NONE: {
      return await this.createWhiteEmptyMesh(editor, mapZoomFactor);
    }

    case BaseImageryProvider.GOOGLE_MAPS:
    default: {
      const textureUrl = this.getGoogleMapImageUrl(coordinateSystemOrigin);
      const texture = await load(textureUrl);
      return await this.createTextureMesh(texture, mapZoomFactor, editor);
    }
    }
  }

  private getGoogleMapImageUrl(projectCoordinates: Coords): string {
    if (!this.isMapsApiInitialized()) {
      throw new Error('Google Maps API has not been initialized yet');
    }
    return getGoogleMapStaticImageUrl(projectCoordinates, {
      ...config.baseImageryConfig(BaseImageryProvider.GOOGLE_MAPS),
      zoomLimit: this.zoomLimit ?? 1
    });
  }

  private getCustomMapImageUrl(projectId: string, accountId: string): string {
    return `${config.api.documents}/accounts/${accountId}/projects/${projectId}/custom-base-imagery`;
  }

  setGoogleMapsFromGlobal(): void {
    if (!this.googleMap) {
      this.googleMap = window.google.maps;
    }
  }

  private isMapsApiInitialized(): boolean {
    return this.googleMap !== undefined;
  }
}

export default MapStore;
