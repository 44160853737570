import type { IChangeEvent } from '@rjsf/core';
import type { JSONSchema7Object } from 'json-schema';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {
  decodeFieldName, encodeFieldName
} from '../fieldNameUtils';
import type {
  DataSchema, FormData
} from '../FormOptionsRulesAndState';
import { DEFAULT_GROUP } from './schema';

export const interpretFormData = (
  formData: FormData, apiSchema: DataSchema
): FormData => {
  const dataFieldsWithoutGroup: {[DEFAULT_GROUP]: FormData} = {
    [DEFAULT_GROUP]: {}
  };
  Object.keys(formData).forEach((key): void => {
    const value = formData[key];
    delete formData[key];
    const encodedFieldName = encodeFieldName(key);
    /*
     * apiSchema carries unencoded field keys, thus compared key from form data should not be pre-encoded
     */
    const groupName = apiSchema.fields.find(field => field.id === key)?.group;
    if (groupName) {
      formData[groupName] = {
        ...formData[groupName] as JSONSchema7Object,
        [encodedFieldName]: value
      };
    } else {
      dataFieldsWithoutGroup[DEFAULT_GROUP][encodedFieldName] = value;
    }
  });
  return isEmpty(dataFieldsWithoutGroup[DEFAULT_GROUP]) ? formData : {
    ...formData,
    ...dataFieldsWithoutGroup
  };
};

/**
 * Decodes form data into the format supported by the API:
 * - Make flattened copy of argument object (copy key-values from nested objects)
 * - Make sure null values do not end up inside result object
 * - Decode field names
 *
 * Assume and assert that data inside data argument is always nested inside group objects
 */
export const decodeFormData = (event: IChangeEvent['formData']): IChangeEvent['formData'] => {
  let result: FormData = {};
  Object.values(event)
    .filter(value => isObject(value))
    .forEach(group => {
      for (const key in group as FormData) {
        if ((group as FormData)[key] !== null) {
          result[key] = (group as FormData)[key];
        }
      }
    });
  for (const key in result) {
    result[decodeFieldName(key)] = result[key];
    delete result[key];
  }
  return Object.fromEntries(Object.entries(result).filter(([_, value]) => !isObject(value)));
};
