import React, { ReactElement } from 'react';
import { CardWrapper } from './styles';

type Props = {
  children?: ReactElement[] | string;
};

const CardContainer = ({ children }: Props): ReactElement => {
  return (
    <CardWrapper>
      {typeof children === 'string' ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <React.Fragment>
          {children &&
            children.map(
              (child: ReactElement, index: number): ReactElement => {
                return <React.Fragment key={index}>{child}</React.Fragment>;
              }
            )}
        </React.Fragment>
      )}
    </CardWrapper>
  );
};

export { CardContainer };
