import type { UiSchema } from '@rjsf/utils/lib/types';
import type { JSONSchema7 } from 'json-schema';
import {
  findFormDataGroupContainingField, getFieldByGroupName
} from '../effects/util';
import { encodeFieldName } from '../fieldNameUtils';
import type {
  FormData, FormFieldValue
} from '../FormOptionsRulesAndState';
import type RecentValuesRegistry from './RecentValuesRegistry';

const _applyDefaults = (
  formData: FormData,
  defaults: {[key: string]: FormFieldValue},
  triggerField: string,
  triggerValue: FormFieldValue,
  recentValuesRegistry: RecentValuesRegistry
): void => {
  for (const [fieldName, fieldDefault] of Object.entries(defaults)) {
    const encodedFieldName = encodeFieldName(fieldName);
    const [groupName, oldValue] = findFormDataGroupContainingField(
      encodedFieldName, formData
    );
    const valueLocation = getFieldByGroupName(formData, groupName);
    const mruForField = recentValuesRegistry.mostRecentForField(fieldName);
    if (!mruForField) {
      valueLocation[encodedFieldName] = fieldDefault;
      recentValuesRegistry.register(
        fieldName, triggerField, triggerValue, fieldDefault
      );
    } else {
      if (mruForField.getValue() !== oldValue) {
        mruForField.updateValue(oldValue);
      }
      const byTrigger = recentValuesRegistry.setFieldByTrigger(
        fieldName, triggerField, triggerValue
      );
      if (byTrigger?.hasValue()) {
        valueLocation[encodedFieldName] = byTrigger.getValue()!;
      } else {
        valueLocation[encodedFieldName] = fieldDefault;
        recentValuesRegistry.register(
          fieldName, triggerField, triggerValue, fieldDefault
        );
      }
    }
  }
};

const applyDefaults = (
  {
    defaults, enclosure: { recentValuesRegistry }, conditions
  }: {
    defaults: {[key: string]: FormFieldValue},
    enclosure: {recentValuesRegistry: RecentValuesRegistry},
    conditions: {[key: string]: {is: FormFieldValue}}
  },
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
): void => {
  // Parse from how we know the condition gets built.
  const triggerField = Object.keys(conditions)[0];
  const triggerValue = conditions[triggerField].is;
  _applyDefaults(
    formData, defaults, triggerField, triggerValue, recentValuesRegistry
  );
};

export default applyDefaults;
