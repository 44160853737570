import React, {
  BaseProps,
  ReactElement,
  ReactText,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Icon } from '../../Icon';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { TextField } from '../TextField';
import { HeaderText, Tag, Tags, TagsInput, Title } from './styles';

export interface IProps extends BaseProps {
  propTags: ReactText[];
  onChange?: (tags: ReactText[], event?: React.KeyboardEvent) => void;
  colorTheme?: EThemeComponentColor;
  name?: string;
  placeholder?: string;
  className?: string;
}

const TagInput = ({
  propTags,
  onChange,
  colorTheme = EThemeComponentColor.GRAY,
  name,
  placeholder
}: IProps): ReactElement => {
  const [tags, setTags] = useState(propTags);
  const [value, setValue] = useState<string | number>('');

  const removeTags = useCallback(
    (indexToRemove: number): void => {
      const newTags = [
        ...tags.filter((_: ReactText, index: number): boolean => index !== indexToRemove)
      ];
      setTags(newTags);
      if (onChange && typeof onChange === 'function') {
        onChange(newTags);
      }
    },
    [tags, onChange]
  );

  const addTags = useCallback((_value: ReactText, event: React.KeyboardEvent): void => {
    if (value !== '') {
      tags.push(value);
      setTags(tags);
      setValue('');
      if (onChange && typeof onChange === 'function') {
        onChange(tags, event);
      }
    }
  }, [tags, value]);

  useEffect((): void => {
    setTags(propTags);
  }, [propTags]);

  const updateTags = (e: React.KeyboardEvent): void => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      addTags(value, e);
    }
  };

  return (
    <>
      {name && <HeaderText>{name}</HeaderText>}
      <TagsInput>
        <Tags>
          {tags &&
            tags.map(
              (tag: ReactText, index: number): ReactElement => (
                <Tag key={`${index}-${tag}`} colorTheme={colorTheme}>
                  <Title>{tag}</Title>
                  <Icon onClick={(): void => removeTags(index)} name="close-icon" />
                </Tag>
              ))}
          <TextField
            width="auto"
            name="tag"
            type="text"
            value={value}
            onKeyUp={updateTags}
            onChange={(inputValue: ReactText): void => setValue(inputValue)}
            placeholder={placeholder}
          />
        </Tags>
      </TagsInput>
    </>
  );
};

export { TagInput };
