import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import isNil from 'lodash/isNil';
import {
  action, computed, observable
} from 'mobx';
import type Limit from '../Limit';

export interface ISnowDesignParametersData {
  readonly groundSnowLoad?: number;
}

export class SnowDesignParameters {
  static readonly GROUND_SNOW_LOAD_OPTIONS: OptionProps[] = [
    {
      name: '0 PSF',
      value: '0'
    },
    {
      name: '10 PSF',
      value: '10'
    },
    {
      name: '20 PSF',
      value: '20'
    },
    {
      name: '30 PSF',
      value: '30'
    },
    {
      name: '40 PSF',
      value: '40'
    },
    {
      name: '50 PSF',
      value: '50'
    },
    {
      name: '60 PSF',
      value: '60'
    },
    {
      name: '70 PSF',
      value: '70'
    },
    {
      name: '80 PSF',
      value: '80'
    },
    {
      name: '90 PSF',
      value: '90'
    }
  ];
  static readonly GROUND_SNOW_LOAD_PSF_LIMITS: Limit = {
    lower: 0,
    upper: 500
  };

  /**
   * In pounds per square foot (PSF)
   */
  @observable
  private groundSnowLoad?: number;

  constructor(data?: ISnowDesignParametersData) {
    this.groundSnowLoad = data?.groundSnowLoad;
  }

  toData = (): ISnowDesignParametersData | undefined => {
    if (this.isEmpty) {
      return undefined;
    }
    return {
      groundSnowLoad: this.groundSnowLoad
    };
  };

  @computed
  get isEmpty(): boolean {
    return isNil(this.groundSnowLoad);
  }

  @computed
  private get groundSnowLoadAsString(): string | undefined {
    return this.groundSnowLoad?.toString();
  }

  @computed
  get groundSnowLoadAsText(): string {
    return `${this.groundSnowLoadAsString ?? '--'} PSF`;
  }

  @computed
  get groundSnowLoadAsOption(): OptionProps | undefined {
    if (this.isEmpty) {
      return undefined;
    }
    const matchingPredefinedOption = SnowDesignParameters.GROUND_SNOW_LOAD_OPTIONS.find(
      (option: OptionProps): boolean => option.value === this.groundSnowLoadAsString
    );
    return (
      matchingPredefinedOption ?? {
        name: 'Other',
        value: this.groundSnowLoadAsString!
      }
    );
  }

  @action
  setGroundSnowLoad = (updatedGroundSnowLoad: number): void => {
    this.groundSnowLoad = updatedGroundSnowLoad;
  };
}
