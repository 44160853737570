import {
  LyraLayout, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import React from 'react';

type DeleteConfirmationModalProps = {
  onCancel: () => void;
  onDelete: () => void;
  description: string;
};
export const DeleteConfirmationModal = ({
  description,
  onCancel,
  onDelete
}: DeleteConfirmationModalProps): ReactElement => {
  return (
    <LyraModal.Modal
      $show={true}
      showHeaderDivider={false}
      showCloseButton={false}
      title="Warning"
      leftButtonLabel="No"
      rightButtonLabel="Yes"
      onClickLeftButton={onCancel}
      onClickRightButton={onDelete}
    >
      <LyraLayout.Flexbox justify="center">
        <LyraTypography.Paragraph>{description}</LyraTypography.Paragraph>
      </LyraLayout.Flexbox>
    </LyraModal.Modal>
  );
};
