import {
  LyraDivider, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { ThemeContext } from 'styled-components';
import type { SiteImage } from '../../../domain/models/SiteDesign/SiteImage';
import useStore, { useUiStore } from '../../../stores/useStore';
import type { IViewUploadImageDependencies } from '../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import ViewUploadImage from '../../../stores/UiStore/Modal/ViewModels/UploadImage/ViewUploadImage';
import { ServiceEntranceEquipmentLoadCenterViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/ServiceEntranceEquipmentLoadCenterViewModel';
import MSPFeatures from '../../containers/SiteEquipmentPanel/MSPFeatures/MSPFeatures';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyPanelWrapper, ScrollablePanelContent
} from '../PropertyPanel/styles';
import SiteImagesContainer from '../SiteImagesContainer/SiteImagesContainer';
import { canvasConfig } from '../../../config/canvasConfig';
import type {
  IDeleteSiteImageCommandDependencies,
  TObjectWithDeletableImage
} from '../../../stores/ServiceBus/Commands/DeleteSiteImageCommand';
import type { LoadCenter } from '../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';
import { ScrollablePanelContainerMSP } from './styles';

const MainServicePanelUI: React.FC = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const {
    workspace, modal
  } = useUiStore();
  const theme = useContext(ThemeContext);
  const { mainServicePanel } = domain.project.site.equipment;
  const [viewModel, setViewModel] = useState<ServiceEntranceEquipmentLoadCenterViewModel>();
  useEffect((): void => {
    setViewModel(
      new ServiceEntranceEquipmentLoadCenterViewModel({
        editor,
        domain,
        serviceBus
      })
    );
  }, [editor, domain, serviceBus]);

  const onAddImageClick = useCallback((): void => {
    const dependencies: IViewUploadImageDependencies = {
      modal,
      domain,
      editor,
      serviceBus,
      workspace
    };
    modal.createModal(
      'upload_image_modal',
      new ViewUploadImage({
        dependencies: dependencies,
        type: 'msp',
        label: 'Main Service Panel'
      })
    );
  }, [modal, domain, editor, serviceBus, workspace]);

  const onDeleteImageClick = useCallback(
    (image: SiteImage): void => {
      const dependencies: IDeleteSiteImageCommandDependencies<LoadCenter> = {
        editor,
        domain,
        image,
        imageOwnerObject: mainServicePanel as TObjectWithDeletableImage<LoadCenter>
      };

      serviceBus.send('delete_site_image', dependencies);
    },
    [editor, domain, mainServicePanel, serviceBus]
  );

  if (!viewModel) {
    return <></>;
  }
  return (
    <PropertyPanelWrapper>
      <PropertyPanelHeader
        icon="tool-properties"
        iconBg={canvasConfig.mainServicePanelIconColor}
        name={viewModel.title}
      />

      {viewModel.loading ? (
        <LyraLoader.Loader bgColor={theme!.colors.grayColor7} text="Loading MSP features..." />
      ) : (
        <ScrollablePanelContainerMSP className="element_with_scroll">
          <ScrollablePanelContent>
            <MSPFeatures theme={theme!} viewModel={viewModel} mainBreakerPresent={!domain.siteEquipment.meterMain} />

            <LyraDivider.Divider
              className="mt-xs"
              direction="horizontal"
              thickness="1px"
              color={EThemeComponentColor.GRAY_200}
            />

            <SiteImagesContainer
              theme={theme!}
              images={mainServicePanel!.images}
              onAddImage={onAddImageClick}
              onDeleteImage={onDeleteImageClick}
            />
          </ScrollablePanelContent>
        </ScrollablePanelContainerMSP>
      )}
    </PropertyPanelWrapper>
  );
};
export default observer(MainServicePanelUI);
