import type { ReactElement } from 'react';
import * as React from 'react';
import {
  useCallback, useContext, useEffect, useMemo, useRef
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import defer from 'lodash/defer';
import { ThemeContext } from 'styled-components';
import { useUiStore } from '../../../../stores/useStore';
import type { InverterSelectionViewModel } from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import type { IEssStorageOptionData } from '../../../../domain/entities/PvSystem/EnergyStorageAndBackupOptions';
import { EssBackupConfiguration } from '../../../../domain/entities/PvSystem/EnergyStorageAndBackupOptions';
import {
  OptionContainer,
  OptionDescription,
  OptionHeader,
  StorageSolutionContainer,
  NotCompatibleError
} from './EnergyStorageSystemSelection.styles';

const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;

export const EnergyStorageSystemSelection = observer((): ReactElement => {
  const modalStore = useUiStore().modal;
  const theme = useContext(ThemeContext);
  const inverterSelectionViewModel = modalStore.viewModel as InverterSelectionViewModel;
  // The private `selectedEnergyStorageAndBackup` field of InverterSelectionViewModel is the source of truth.
  // `currentlySelectedEssOptionsPair` derives corresponding selection options from it.
  // `updateEnergyStorageAndBackup` updates it.
  const {
    firstInverterDefinitionId,
    secondInverterDefinitionId,
    updateEnergyStorageAndBackup,
    currentlySelectedEssOptionsPair,
    energyStorageAndBackupOptions
  } = inverterSelectionViewModel;

  const handleChangeEssConfiguration = useCallback(
    (option: string | number): void => {
      const selectedEssConfiguration = option as unknown as EssBackupConfiguration;
      const defaultEssOption = energyStorageAndBackupOptions!.defaultAcCoupledStorageOption(selectedEssConfiguration);
      updateEnergyStorageAndBackup({
        backup: selectedEssConfiguration,
        storage: defaultEssOption
      });
    },
    [updateEnergyStorageAndBackup, energyStorageAndBackupOptions]
  );

  const handleChangeEssStorageSolution = useCallback(
    (option: string | number): void => {
      const storageSolutionOptionName = option as string;
      const essOption = energyStorageAndBackupOptions!.findAcCoupledStorageSolutionOption(
        currentlySelectedEssOptionsPair.backup,
        storageSolutionOptionName
      );
      updateEnergyStorageAndBackup({
        backup: currentlySelectedEssOptionsPair.backup,
        storage: essOption
      });
    },
    [currentlySelectedEssOptionsPair, energyStorageAndBackupOptions, updateEnergyStorageAndBackup]
  );

  const containerRef = useRef({} as HTMLDivElement);

  useEffect(() => {
    inverterSelectionViewModel.loadEnergyStorageAndBackupOptions().finally((): void => {
      defer((): void => containerRef.current?.scrollIntoView?.());
    });
  }, [firstInverterDefinitionId, secondInverterDefinitionId, inverterSelectionViewModel]);

  const essConfigurationToSelectOption = useMemo<{ [key: string]: Option }>(() => {
    return {
      [EssBackupConfiguration.NO_BACKUP]: {
        name: (
          <OptionContainer>
            <OptionHeader>No Storage or Backup</OptionHeader>
          </OptionContainer>
        ),
        value: EssBackupConfiguration.NO_BACKUP
      },
      [EssBackupConfiguration.SUNLIGHT_BACKUP]: {
        name: (
          <OptionContainer>
            <OptionHeader>Solar Backup</OptionHeader>
            <OptionDescription>Backup power available when the sun is shining. No energy storage.</OptionDescription>
          </OptionContainer>
        ),
        value: EssBackupConfiguration.SUNLIGHT_BACKUP
      },
      [EssBackupConfiguration.PARTIAL_BACKUP]: {
        name: (
          <OptionContainer>
            <OptionHeader>Partial Home Backup</OptionHeader>
            <OptionDescription>Backup select loads with a protected loads panel</OptionDescription>
          </OptionContainer>
        ),
        value: EssBackupConfiguration.PARTIAL_BACKUP
      },
      [EssBackupConfiguration.SELF_CONSUMPTION]: {
        name: (
          <OptionContainer>
            <OptionHeader>Self-Consumption Only</OptionHeader>
            <OptionDescription>
              Maximize on-site consumption of generated PV energy. No backup. Power will not be available when grid is
              down.
            </OptionDescription>
          </OptionContainer>
        ),
        value: EssBackupConfiguration.SELF_CONSUMPTION
      },
      [EssBackupConfiguration.FULL_BACKUP]: {
        name: (
          <OptionContainer>
            <OptionHeader>Full Home Backup</OptionHeader>
            <OptionDescription>Backup all loads</OptionDescription>
          </OptionContainer>
        ),
        value: EssBackupConfiguration.FULL_BACKUP
      }
    };
  }, []);

  if (!energyStorageAndBackupOptions?.isAnyBackupConfigurationAvailable) {
    // If the only possible option is "No backup", then there is no reason to show this component
    return <></>;
  }
  const selectedConfiguration = currentlySelectedEssOptionsPair.backup;
  const storageSolutionOptions = energyStorageAndBackupOptions.availableAcCoupledStorageOptions(selectedConfiguration);
  const selectedStorageSolution = currentlySelectedEssOptionsPair.storage;
  return (
    <div ref={containerRef}>
      <LyraTypography.Heading fontSize="12px" margin="10px 0 5px">
        ENERGY STORAGE & BACKUP
      </LyraTypography.Heading>
      <Select
        listOptions={energyStorageAndBackupOptions.availableBackupConfigurations.map(
          (configuration: EssBackupConfiguration): Option => essConfigurationToSelectOption[configuration]
        )}
        onSelect={handleChangeEssConfiguration}
        selected={selectedConfiguration}
      />

      <StorageSolutionContainer visible={storageSolutionOptions.length > 0}>
        <LyraTypography.Heading fontSize="12px" margin="10px 0 5px">
          STORAGE SOLUTION
        </LyraTypography.Heading>
        <Select
          listOptions={storageSolutionOptions.map(
            (option: IEssStorageOptionData): Option => ({
              name: (
                <OptionContainer>
                  <OptionHeader>{option.name}</OptionHeader>
                  {option.compatibilityError && <NotCompatibleError />}
                  <OptionDescription>{option.usableEnergyCapacityInKwh} kWh of usable capacity</OptionDescription>
                </OptionContainer>
              ),
              value: option.name
            })
          )}
          onSelect={handleChangeEssStorageSolution}
          selected={selectedStorageSolution?.name}
        />
      </StorageSolutionContainer>
    </div>
  );
});
