import type {
  JSONSchema7, JSONSchema7Object, JSONSchema7Type
} from 'json-schema';
import type {
  GenericObjectType,
  UIOptionsType, UiSchema
} from '@rjsf/utils';
import type {
  FormData, FormFieldProp
} from '../FormOptionsRulesAndState';

export const removeEnumByIndex = (
  schema: FormFieldProp, fieldName: string, index: number
): void => {
  const property: FormFieldProp | undefined = findFormSchemaField(
    fieldName, schema.properties
  );
  if (!property) {
    return;
  }
  property.enum!.splice(index, 1);
  property.enumNames?.splice(index, 1);
  property.enumDescriptions?.splice(index, 1);
  property.enumTags?.splice(index, 1);
};

/**
 * @param field field id
 * @param fieldInSchema field object in JSON schema (typically `schema.properties[fieldGroup].properties[field]`)
 * @param formData current form data
 */
export const updateDefaultValue = (
  field: string, fieldInSchema: JSONSchema7, formData: FormData
): void => {
  if (!fieldInSchema.enum) {
    return;
  }
  const firstOption = fieldInSchema.enum[0];
  fieldInSchema.default = firstOption;
  const [groupName, currentValueInFormData] = findFormDataGroupContainingField(
    field, formData
  );
  const valueLocation = getFieldByGroupName(formData, groupName);
  if (currentValueInFormData !== undefined && !fieldInSchema.enum.includes(currentValueInFormData)) {
    // Update the value in formData in case it is no longer compatible with options available
    valueLocation[field] = firstOption;
  }
};

/**
 * @returns [string, JSONSchema7Definition] -- the name of the group and field's JSON schema
 */
const findFormSchemaGroupContainingField = (
  field: string, groups: JSONSchema7['properties'] | undefined
): [string, JSONSchema7] | undefined => {
  const result = Object.entries(groups as {[key: string]: JSONSchema7})
    .filter(([_, group]: [string, JSONSchema7]) => group?.properties)
    .find(([_, group]: [string, JSONSchema7]) => group.properties?.[field]);
  return (result?.[1].properties ? [result[0], result[1].properties[field] as JSONSchema7] : result);
};

export const findFormSchemaGroup = (
  field: string, groups: JSONSchema7['properties'] | undefined
): string | undefined => findFormSchemaGroupContainingField(field, groups)?.[0];

export const findFormSchemaField = (
  field: string, groups: JSONSchema7['properties'] | undefined
): JSONSchema7 | undefined => findFormSchemaGroupContainingField(field, groups)?.[1];

/**
 * @returns [string, JSONSchema7Definition] -- the name of the group and field's UI schema
 */
const findUiSchemaGroupContainingField = (
  field: string, groups: UiSchema
): [string, UIOptionsType] | undefined => {
  const result = Object.entries(groups)
    .filter(([_, group]: [string, UIOptionsType]) => group)
    .find(([_, group]: [string, UIOptionsType]) => group[field]);
  return result ? [result[0], result[1][field]] : undefined;
};

export const findUiSchemaGroup = (
  field: string, groups: UiSchema
): string | undefined => findUiSchemaGroupContainingField(field, groups)?.[0];

export const findUiSchemaField = (
  field: string, groups: UiSchema
): UIOptionsType | undefined => findUiSchemaGroupContainingField(field, groups)?.[1];

/**
 * @returns [string, JSONSchema7Definition] -- the name of the group and field's value
 */
export const findFormDataGroupContainingField = (
  field: string, formData: FormData
): [string | undefined, JSONSchema7Type] => {
  const result = Object.entries(formData)
    .filter(([_, group]: [string, JSONSchema7Type]) => group)
    .find(([_, group]: [string, JSONSchema7Type]) => (group as JSONSchema7Object)?.[field] !== undefined);
  if (result?.[1]) {
    return [result[0], (result[1] as JSONSchema7Object)[field]];
  } else {
    return [undefined, formData[field]];
  }
};

export const findGroupInFormData = (
  field: string, formData: FormData
): string | undefined => findFormDataGroupContainingField(field, formData)[0];

export const findFormDataValue = (
  field: string, formData: FormData
): JSONSchema7Type | undefined => findFormDataGroupContainingField(field, formData)[1];

/**
 * Extracts field schema depending on whether the name of the group was passed as a second argument.
 * Usually called when field's parent object is needed, and you don't know whether a field belongs to a group or is a read-only field on a top level
 *
 * Example of uses:
 * - Building initial UI schema
 * - Removing field in question via `delete`
 * - Getting access to form data value
 *
 */
export const getFieldByGroupName = <T extends GenericObjectType>(
  schema: T, groupName?: string
): T => (
  groupName ? schema[groupName] : schema
);
