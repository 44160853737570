import React, { BaseProps, ReactElement } from 'react';
import {
  StatusIconWrapper
} from './styles';
import { Icon } from '../Icon';

export type StatusIconProps = BaseProps & {
  status: string;
};

const StatusIcon = ({
  status, className
}: StatusIconProps): ReactElement => {
  const iconName = status === 'success' ? 'save-successful' : status === 'fail' ? 'save-failed' : 'changes-pending';
  return (
    <StatusIconWrapper
      className={className}
      status={status}
      dir="row"
    >
      <Icon name={iconName} />
    </StatusIconWrapper>
  );
};

export { StatusIcon };
