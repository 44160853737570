import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IDeleteEmptyBuildingsDependencies extends IBaseCommandDependencies {
  domain: DomainStore;
}

export class DeleteEmptyBuildings extends BaseCommand {
  private domain: DomainStore;

  constructor(dependencies: IDeleteEmptyBuildingsDependencies) {
    super();
    this.domain = dependencies.domain;
  }

  async execute(): Promise<void> {
    this.domain.project.site.deleteEmptyBuildings();
  }
}
