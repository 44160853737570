import {
  action, computed, observable
} from 'mobx';
import { handleApiError } from '../../../../../utils/helpers';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import type { DesignDelta } from '../../../../../domain/entities/Design/DesignDelta';
import type { Design } from '../../../../../domain/models/Design/Design';
import type { LayoutStrategy } from '../../../../../domain/models/RoofTopArray/LayoutStrategy';
import { showLoadersOnAllRoofFacesUsedForSolar } from '../../../../../domain/models/SiteDesign/RoofFace';
import type { ArrayPlacementStage } from '../../../../../domain/stages/DesignStages/ArrayPlacementStage';
import type EditorStore from '../../../../EditorStore/EditorStore';
import { BaseViewModel } from '../../BaseViewModel';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type { DesignWorkspace } from '../../../WorkspaceStore/workspaces/DesignWorkspace';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import type { ModalStore } from '../../Modal';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';

interface ISteepSlopeViewModelDependencies {
  domain: DomainStore;
  roofFace: RoofFace;
  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;
  editor: EditorStore;
  modal: ModalStore;
}

export class SteepSlopeViewModel extends BaseViewModel {
  readonly propCodeUI: string = 'steep_slope_modal';

  designWorkspace: DesignWorkspace;
  serviceBus: ServiceBus;

  @observable
  layoutStrategy!: LayoutStrategy;

  private readonly design: Design;
  private readonly roofFace: RoofFace;

  constructor(dependencies: ISteepSlopeViewModelDependencies) {
    super(dependencies);
    this.roofFace = dependencies.roofFace;
    this.design = dependencies.domain.design;
    this.setInitialValues(dependencies.roofFace.serverId);
    this.designWorkspace = dependencies.designWorkspace;
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;
  }

  @computed
  get roofFaceName(): string {
    return this.roofFace.name;
  }

  @action.bound
  setInitialValues(arrayAreaId: string): void {
    const mountingSystem = this.design.system.equipment.mountingSystems.mountingSystemOn(arrayAreaId);
    if (!mountingSystem) {
      console.warn(`Mounting system not found for array area = ${arrayAreaId} in design = ${this.design.id}`);
      return;
    }
    this.layoutStrategy = mountingSystem.layoutStrategy.copy();
  }

  @action.bound
  applyChanges(): void {
    const hideLoaders: () => void = showLoadersOnAllRoofFacesUsedForSolar(this.editor);

    const arrayPlacementStage = this.designWorkspace.stageManager!.currentStage as ArrayPlacementStage;
    const designService = new DesignService();
    designService
      .updateMountingSystem(this.roofFace.serverId, this.layoutStrategy.toData(), this.design.toData())
      .then((response: DesignDelta): void => {
        this.serviceBus.send('update_design_delta', response.toApplyDesignDeltaCommand(this.domain));
        this.closeModal();
        arrayPlacementStage.redrawRoofTopArrayAreas();
      })
      .catch(handleApiError('Failed to apply changes to mounting system'))
      .finally((): void => hideLoaders());
  }

  dispose(): void {
    return;
  }
}
