import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import useStore from '../../../../stores/useStore';
import type { ProjectPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/ProjectPropertiesModal/ProjectPropertiesViewModel';
import { PropertiesTitle } from './styles';

const ProjectPropertiesForm = observer((): ReactElement => {
  const { modal } = useStore();
  const [projectPropertiesViewModel] = useState(modal.viewModel as ProjectPropertiesViewModel);

  return (
    <>
      <PropertiesTitle className="pv-xxxs ph-xs mt-xs">
        <LyraTypography.Heading margin="0">GENERAL</LyraTypography.Heading>
      </PropertiesTitle>
      <LyraTypography.Heading
        fontSize="14px"
        color={EThemeComponentColor.GRAY_700}
        fontWeight="bold"
        margin="15px 0 0 8px"
      >
        Short Description
      </LyraTypography.Heading>
      <LyraFormElementsDesignTool.FormElementsDesignTool.TextArea.TextArea
        className="ml-xxs mr-xxs"
        rows={3}
        value={projectPropertiesViewModel.projectDescription}
        onChange={projectPropertiesViewModel.handleChangeInputProjectDescription}
      />
    </>
  );
});

export { ProjectPropertiesForm };
