import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';

const hexadecimalOpacity = '1a';

export const PropertiesTitle = styled.div`
  background: ${({ theme }: PropsTheme): Color => `${theme.colors.mainColor2}${hexadecimalOpacity}`};
  border-radius: 4px;
`;

export const TagsContainer = styled.div`
  ul {
    padding: 10px 0;
  }
  input {
    background-color: transparent;
  }
`;

export const LocallyAdoptedCodes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'defaultCodes additionalCodes' 'APIAttribution additionalCodes';
  gap: 16px;
`;

export const DefaultCodes = styled.div`
  grid-area: defaultCodes;
`;

export const AdditionalCodes = styled.div`
  grid-area: additionalCodes;
`;

export const APIAttribution = styled.div`
  grid-area: APIAttribution;
  align-content: end;
`;

export const APIAttributionText = styled.p`
  font-size: 11px;
  font-style: italic;
`;
