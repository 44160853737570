import styled, {
  CSSRule,
  PropsTheme,
  withTheme
} from 'styled-components';
import { Color, IDefaultTheme } from '../../typings';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

export type OptionProps = {
  selected?: boolean;
  disabled?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

const getSelectedColor = (theme: IDefaultTheme, selectedOptionColor: EThemeComponentColor | undefined): Color => {
  return selectedOptionColor === EThemeComponentColor.PURPLE ? theme.colors.mainColor2 : theme.colors.mainColor1;
};

const updateBorderStyleOption = ({
  selected,
  disabled,
  selectedOptionColor,
  theme
}: PropsTheme<OptionProps>): Color => {
  if (disabled) {
    return theme.colors.grayColor10;
  }
  return selected
    ? getSelectedColor(theme, selectedOptionColor)
    : theme.colors.grayColor6;
};

export const OptionStyle = styled.div<PropsTheme<OptionProps>>`
  ${
    ({ theme }: PropsTheme<OptionProps>): CSSRule => theme.mixins.wrapperFill
  }
  ${
    ({ selected }: PropsTheme<OptionProps>): string =>
    selected ? `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);` : ''
  }
  border: 2px solid ${(props: PropsTheme<OptionProps>): Color =>  updateBorderStyleOption(props)};
  color: ${
    (props: PropsTheme<OptionProps>): Color => (props.disabled)
      ? props.theme.colors.grayColor3
            : props.theme.colors.secondaryFont
  };
  background-color: ${
    (props: PropsTheme<OptionProps>): Color => (props.disabled)
      ? props.theme.colors.grayColor8
            : props.theme.colors.whiteBg
  };
  cursor: ${
    (props: PropsTheme<OptionProps>): string => (props.disabled)
      ? 'not-allowed' : 'pointer'
  };
  
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 4px;
  padding: 7px 10px;
  transition: all 0.3s ease;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  
   &:hover {
     border: 2px solid ${({ selectedOptionColor, theme }: PropsTheme<OptionProps>): Color => getSelectedColor(theme, selectedOptionColor)};
     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;
