import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { RoofProtrusion } from '../../../domain/models/SiteDesign/RoofProtrusion';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IUpdateRoofProtrusionPropertyDependencies<T extends keyof RoofProtrusion>
  extends IBaseCommandDependencies {
  domain: DomainStore;
  roofFace: RoofFace;
  protrusion: RoofProtrusion;
  key: T;
  value: RoofProtrusion[T];
}

export class UpdateRoofProtrussionPropertyCommand<T extends keyof RoofProtrusion> extends BaseCommand {
  private domain: DomainStore;
  private roofFace: RoofFace;
  private protrusion: RoofProtrusion;
  private key: T;
  private value: RoofProtrusion[T];

  constructor(dependencies: IUpdateRoofProtrusionPropertyDependencies<T>) {
    super();
    const {
      domain, roofFace, protrusion, key, value
    } = dependencies;
    this.domain = domain;
    this.roofFace = roofFace;
    this.protrusion = protrusion;
    this.key = key;
    this.value = value;
  }

  async execute(): Promise<void> {
    this.protrusion[this.key] = this.value;
    this.domain.addOrUpdateRoofProtrusion(this.roofFace, this.protrusion);
  }
}
