import React, { BaseProps, ReactElement, useCallback, useState } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from 'typings';
import { ColorThemeProps, getColorTheme } from '../../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';

type RadioProps = ColorThemeProps & {
  size: number;
};

const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const RadioStyled = styled.div<RadioProps>`
  position: absolute;
  margin: 0;
  padding: 0;
  width: ${(props: RadioProps): number => props.size}px;
  height: ${(props: RadioProps): number => props.size}px;
  border-radius: 50%;
  
  border: 2px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor3};
  box-sizing: border-box;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 50%);
    height: calc(20px - 50%);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props: ColorThemeProps): Color => getColorTheme({ ...props })};
  }
`;

const RadioInput = styled.input<ColorThemeProps>`
  opacity: 0;
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${RadioStyled}{
      border: 2px solid ${(props: ColorThemeProps): Color => getColorTheme({ ...props })};
      &::before {
         opacity: 1;
      }
    }
  }
`;

export interface IProps extends BaseProps {
  size?: number;
  value?: string | number;
  text?: string | number | ReactElement;
  name?: string;
  checked?: boolean;
  colorTheme?: EThemeComponentColor;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const HARadio = ({
  size = 20,
  colorTheme = EThemeComponentColor.BLUE,
  value,
  name,
  text,
  onChange,
  checked = false
}: IProps): ReactElement => {
  return (
    <>
      <Row>
        <RadioInput
          onChange={onChange}
          type="radio"
          value={value}
          name={name}
          colorTheme={colorTheme}
          checked={checked}
        />
        <RadioStyled size={size} colorTheme={colorTheme} />
        <label htmlFor="test">{text}</label>
      </Row>
    </>
  );
};

export { HARadio };
