import { getColorTheme } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/ColorThemeProps';
import type { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type {
  BaseProps, ReactElement
} from 'react';
import React, { useCallback } from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';

type TextButtonProps = {
  border?: boolean;
  colorTheme: EThemeComponentColor;
};

const TextButtonStyle = styled.div<PropsTheme<TextButtonProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: ${({
    border, ...themeProps
  }: PropsTheme<TextButtonProps>): string =>
    border
      ? `5px solid ${getColorTheme({
        ...themeProps
      })}`
      : ''};
  @media (max-width: 980px) {
    padding: 0 6px;
  }

  & > span {
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};

    @media (max-width: 980px) {
      font-size: 16px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

type Props = BaseProps &
  TextButtonProps & {
    label?: string;
    colorTheme: EThemeComponentColor;
    onClick: () => void;
  };

const TextButton = (props: Props): ReactElement => {
  const {
    label, border, colorTheme, onClick, ...rest
  } = props;

  const handleClick = useCallback((): void => {
    onClick?.();
  }, [onClick]);

  return (
    <TextButtonStyle onClick={handleClick} border={border} colorTheme={colorTheme} {...rest}>
      <span>{label}</span>
    </TextButtonStyle>
  );
};

export default TextButton;
