import type {
  IUniform, ShaderMaterialParameters, Vector2
} from 'three';
import {
  Color as ThreeColor, DoubleSide, ShaderMaterial
} from 'three';

type Attribute = {
  type: string;
  value: Vector2 | number;
};

type Line2DMaterialParameters = ShaderMaterialParameters & {
  attributes?: {
    lineMiter: Attribute;
    lineNormal: Attribute;
  };
};

interface ILine2DMaterialOptions {
  thickness?: number;
  diffuse?: number;
  opacity?: number;
}

const defaultOptions = {
  thickness: 1,
  diffuse: 0xffffff,
  opacity: 1.0
};

const vertexShader = `
uniform float thickness;
attribute float lineMiter;
attribute vec2 lineNormal;

void main() {
  vec3 pointPos = position.xyz + vec3(
    lineNormal * thickness / 2.0 * lineMiter, 0.0
  );
  gl_Position = projectionMatrix * modelViewMatrix * vec4(pointPos, 1.0);
}
`;

const fragmentShader = `
uniform vec3 diffuse;
uniform float opacity;

void main() {
  gl_FragColor = vec4(diffuse, opacity);
}
`;
function line2DMaterialShader(options: ILine2DMaterialOptions = defaultOptions): Line2DMaterialParameters {
  const shaders: Line2DMaterialParameters = {
    uniforms: {
      thickness: {
        type: 'f',
        value: options.thickness
      },
      opacity: {
        type: 'f',
        value: options.opacity
      },
      diffuse: {
        type: 'c',
        value: new ThreeColor(options.diffuse)
      }
    } as { [uniform: string]: IUniform },
    vertexShader,
    fragmentShader,
    side: DoubleSide
  };

  return shaders;
}

class Line2DMaterial extends ShaderMaterial {
  constructor(options: ILine2DMaterialOptions) {
    const shaderOpt = line2DMaterialShader(options);
    super(shaderOpt);
  }
}

export default Line2DMaterial;
