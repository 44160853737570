import {
  observer, useObserver
} from 'mobx-react-lite';
import type {
  BaseProps, FC, ReactElement
} from 'react';
import React, {
  useEffect, useState
} from 'react';
import type Store from '../../../../stores/Store';
import useStore from '../../../../stores/useStore';
import type {
  BaseTool, IBaseToolDependencies
} from '../../../../stores/UiStore/ToolbarStore/Tool';
import { SubMenuWrapper } from './styles';
import ToolbarButton from './ToolbarButton';

function withTool<C extends BaseTool, K extends IBaseToolDependencies, T = {}>(
  toolConstructor: new (dep: K) => C,
  getDependencies?: (store: Store) => T,
  subTools?: ReactElement[] | ReactElement
): FC<BaseProps> {
  const ToolComponent = (): ReactElement => {
    const store = useStore();
    const {
      toolbar, editor
    } = store;
    const [tool, setTool] = useState<C>();

    const isToolSelected = !!tool && tool.isSelected();
    const [selected, setSelected] = useState<boolean>(isToolSelected);
    const hasSubmenu = subTools !== undefined;

    useEffect((): void => {
      let deps: K = {
        toolbar,
        editor
      } as K;

      if (typeof getDependencies === 'function') {
        deps = {
          ...deps,
          ...getDependencies(store)
        };
      }

      setTool(toolbar.createTool<C, K>(toolConstructor, deps));
    }, [editor, store, toolbar]);

    useEffect((): void => {
      setSelected(tool !== undefined ? tool.isSelected() : false);
    }, [toolbar.selectedTool, tool]);

    return useObserver((): ReactElement => {
      return tool ? (
        <ToolbarButton
          key={tool.id}
          icon={tool.icon}
          title={tool.title}
          onClick={!hasSubmenu ? (): void => toolbar.selectTool(tool) : undefined}
          selected={selected && !hasSubmenu}
          submenu={hasSubmenu}
          disabled={tool.isDisabled}
          {...(tool.testId ? { 'data-testid': tool.testId } : {})}
        >
          <SubMenuWrapper>{subTools}</SubMenuWrapper>
        </ToolbarButton>
      ) : (
        <></>
      );
    });
  };

  return observer(ToolComponent);
}

export default withTool;
