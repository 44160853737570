import makeCancellablePromise from 'make-cancellable-promise';

const cancellableMap: { [key: string]: () => void } = {};

export function cancelPromiseKeys(...keys: string[]): void {
  for (let key of keys) {
    cancellableMap[key]?.();
  }
}

export enum CancellablePromiseKeys {
  StringingOptionsAndShowSummaryPanel = 'StringingOptionsAndShowSummaryPanel',
  CreateInverterSelectionViewModel = 'CreateInverterSelectionViewModel',
  GetSelectedInverterOption = 'GetSelectedInverterOption',
  LoadStringingOptions = 'LoadStringingOptions',
  ChangeArrayAreas = 'ChangeArrayAreas'
}

export function cancelablePromise<T>(key: string, cb: Function | Promise<T>): Promise<T> {
  const {
    promise, cancel
  } = makeCancellablePromise<T>(typeof cb == 'function' ? new Promise(cb()) : cb);
  cancellableMap[key] = cancel;
  return promise;
}
