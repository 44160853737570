import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';

const PillStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 8px;
  margin-left: 3px;
  margin-bottom: 3px;
  border-radius: 11px;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.gray3};
  & > span {
    font-size: 11px;
    white-space: nowrap;
    color: ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  }
`;

type Props = BaseProps & {
  label?: string | ReactElement[];
};

const Pill = (props: Props): ReactElement => {
  return (
    <PillStyle>
      <span>{props.label}</span>
    </PillStyle>
  );
};

export default Pill;
