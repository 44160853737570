import { observable } from 'mobx';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import { handleApiError } from '../../../../../utils/helpers';
import type {
  BaseAttributes, IOption
} from '../../../../../domain/models/SiteDesign/IOption';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';

export class RoofFramingDataFetcher {
  @observable
  constructionStyleOptions: IOption<BaseAttributes>[] = [];

  readonly yearsOfConstructionOptions: OptionProps[] = [
    {
      name: 'Pre-1960',
      value: '1950'
    },
    {
      name: 'Post-1960',
      value: '1970'
    }
  ];

  @observable
  crossSectionOptions: IOption<BaseAttributes>[] = [];

  readonly spacingOptions: IOption<BaseAttributes>[] = [
    {
      id: '12in',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: '12 in',
        value: '12'
      }
    },
    {
      id: '16in',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: '16 in',
        value: '16'
      }
    },
    {
      id: '24in',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: '24 in',
        value: '24'
      }
    },
    {
      id: '32in',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: '32 in',
        value: '32'
      }
    },
    {
      id: '48in',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: '48 in',
        value: '48'
      }
    },
    {
      id: 'other',
      optionType: 'trussSpacingOptions',
      attributes: {
        name: 'other',
        value: '1'
      }
    }
  ];

  private designService = new DesignService();

  async loadOptions(): Promise<void> {
    this.loadConstructionStyleOptions();
    this.loadCrossSectionOptions();
  }

  async loadConstructionStyleOptions(): Promise<void> {
    this.designService
      .getRoofConstructionTypes()
      .then((response: IOption<BaseAttributes>[]): void => {
        this.constructionStyleOptions = response;
      })
      .catch(handleApiError('Failed to fetch construction style options data'));
  }

  async loadCrossSectionOptions(year: number = 1970): Promise<void> {
    this.designService
      .getLumberCrossSections({
        yearOfConstruction: year
      })
      .then((response: IOption<BaseAttributes>[]): void => {
        this.crossSectionOptions = response;
      })
      .catch(handleApiError('Failed to fetch cross section options data'));
  }
}
