import type { BaseProps } from 'react';
import styled from 'styled-components';

export type FlexboxDirection = 'row' | 'inverse-row' | 'column' | 'inverse-column' | 'revert' | 'row-reverse';
type Props = BaseProps & {
  wrap?: 'wrap' | 'nowrap' | 'initial';
  justify?: string;
  align?: string;
  dir?: FlexboxDirection;
  fullHeight?: boolean;
  stretch?: 'auto' | '100%';
};

const Flexbox = styled.div<Props>`
  display: flex;
  position: relative;
  width: ${({ stretch = '100%' }: Props): string => stretch};
  ${({ fullHeight = false }: Props): string => (fullHeight ? 'height: 100%;' : '')};
  flex-direction: ${({ dir = 'row' }: Props): string => dir};
  align-items: ${({ align = 'baseline' }: Props): string => align};
  justify-content: ${({ justify = 'flex-start' }: Props): string => justify};
  flex-wrap: ${({ wrap = 'nowrap' }: Props): string => wrap};
`;

export default Flexbox;
