import React, { BaseProps, ReactElement } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';
import { Paragraph, Notes } from '../../../components/FontFamily/Fonts';

type InputStyledProps = BaseProps & PropsTheme & {
  isError?: boolean;
};

const HeaderText = styled(Paragraph)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
  font-weight: bold;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: 6px;
`;

const InputField = styled.input<InputStyledProps>`
  font-family: ${({ theme }: InputStyledProps): string => theme.fonts.fontFamily};
  font-size: 14px;
  line-height: 23px;
  color: ${({ theme, isError }: InputStyledProps): Color => isError
    ? theme.colors.mainColor4
    : theme.colors.grayColor1
  };
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0 0 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, isError }: InputStyledProps): Color => isError
    ? theme.colors.mainColor4
    : theme.colors.grayColor3
  };

  &:focus {
    border-bottom-color: ${({ theme, isError }: InputStyledProps): Color => isError
      ? theme.colors.mainColor4
      : theme.colors.mainColor1
    };
    border-bottom-width: 2px;
    line-height: 21px;
  }
`;

const ErrorText = styled(Notes)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor4};
  margin-top: 5px;
`;

type InputProps = BaseProps & {
  className?: string;
  name: string;
  label?: string;
  type?: string;
  defaultValue?: string | number;
  value?: string | number;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  leftText?: string;
  rightText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  readOnly?: boolean;
};

const HAInput = ({
  className,
  name,
  label,
  type,
  defaultValue,
  value,
  placeholder = '',
  error,
  errorText,
  leftText,
  rightText,
  onChange,
  onBlur,
  maxLength,
  readOnly
}: InputProps): ReactElement => {
  return (
    <div className={className}>
      {label && <HeaderText>{label}</HeaderText>}
      <InputWrapper>
        {leftText && leftText}
        <InputField
          name={name}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          defaultValue={defaultValue}
          value={value}
          isError={error}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
        />
        {rightText && rightText}
      </InputWrapper>
      {error && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};

export { HAInput };
