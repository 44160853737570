import {
  CircleGeometry, Mesh, MeshBasicMaterial, Vector2, Vector3
} from 'three';
import type { SimpleVector2 } from '../../utils/ThreeUtils';
import { ThreeUtils } from '../../utils/ThreeUtils';
import type EditorStore from '../../stores/EditorStore/EditorStore';
import { ViewPort } from '../../stores/EditorStore/ViewportController';
import type SmartGuidesStore from '../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { KindGuides } from '../../stores/UiStore/SmartGuidesStore/IApplyParams';
import type { BaseGuide } from '../../stores/UiStore/SmartGuidesStore/SmartGuides/BaseGuide';
import type { SnapGuide } from '../../stores/UiStore/SmartGuidesStore/SmartGuides/SnapGuide';
import {
  arePolygonEdgesIntersecting,
  areVerticesInsidePolygon,
  calculateZFromInfiniteMesh,
  isPolygonInsideAnother,
  isPolygonIntersectingAnother,
  isPolygonSelfIntersecting,
  isVertexLayingOnOtherLineExceptEndingsOrCollapsed,
  toCanvasCoordinatesInPixels
} from '../../utils/spatial';
import type { IDrawable } from '../mixins/Drawable';
import type {
  Color, Vector3D
} from '../typings';
import { SceneObjectType } from '../models/Constants';
import { Draggable } from '../mixins/Draggable';
import { Drawable } from '../mixins/Drawable';
import { Selectable } from '../mixins/Selectable';
import { Unzoomable } from '../mixins/Unzoomable';
import {
  getLyraModelByOptionalMesh, getParentLyraModelByMesh
} from '../sceneObjectsWithLyraModelsHelpers';
import { ParcelBoundaryUpdatedEvent } from '../../services/analytics/DesignToolAnalyticsEvents';
import { getRootStore } from '../../stores/RootStoreInversion';
import type { Outline } from '../models/SiteDesign/Outline';
import type { RectangleProtrusion } from '../models/SiteDesign/RectangleProtrusion';
import type { RoofFace } from '../models/SiteDesign/RoofFace';
import config from '../../config/config';
import type { Boundary } from './Boundary';
const MixedClass = Draggable(Selectable(Unzoomable(Drawable(class SimpleClass {}))));

/**
 * Class Vertex is descendant of ThreeJS Mesh. Its mesh is transparent, while
 * a child mesh - {@see dotMesh} - is not. This is done so that the transparent
 * mesh is wider for easy grabbing.
 */
export class Vertex extends MixedClass implements IDrawable {
  static fromXyzValuesObject({
    x, y, z
  }: Vector3D): Vertex {
    return Vertex.fromXyzValues(x, y, z);
  }

  static fromXyzValues(x: number, y: number, z: number): Vertex {
    const newVertex = new Vertex();
    newVertex.mesh.position.set(x, y, z);
    return newVertex;
  }

  static fromAnotherVertex(proto: Vertex): Vertex {
    const newVertex = new Vertex();
    newVertex.mesh.position.set(proto.x, proto.y, proto.z);
    newVertex.hasEverBeenValidAfterUserInteraction = proto.hasEverBeenValidAfterUserInteraction;
    return newVertex;
  }

  autoResetToValidPosition: boolean = true;
  hasEverBeenValidAfterUserInteraction: boolean = false;
  private cachedBuildingName: string | undefined = '';
  // static configuration
  debugValidation: boolean = false;

  readonly propertyId: string = SceneObjectType.Vertex;
  isMultipleVertices: boolean = false;
  selectWithParent: boolean = true;

  override color!: Color;

  private zoomFactor: number = 1;
  private radiusDefault: number = 3;
  private radiusSelected: number = 5;
  private currentRadius: number = this.radiusDefault;
  private dotMesh: Mesh;

  constructor() {
    super();
    this.type = SceneObjectType.Vertex;

    const dotGeometry = new CircleGeometry(1, 12);
    ThreeUtils.applyOffsetToBufferGeometry(dotGeometry, {
      x: 0,
      y: 0,
      z: 0.5
    }); // to solve z issues with lines / edges
    const dotMaterial = new MeshBasicMaterial({
      color: 0xffffff
    });
    this.dotMesh = new Mesh(dotGeometry, dotMaterial);
    this.mesh.add(this.dotMesh);

    this.currentRadius = this.currentRadius * this.mapZoomFactor;
    this.mesh.geometry = new CircleGeometry(3, 12);
    ThreeUtils.applyOffsetToBufferGeometry(this.mesh.geometry, {
      x: 0,
      y: 0,
      z: 5
    }); // to solve z issues with lines / edges
    this.mesh.material = new MeshBasicMaterial({
      color: 0xffffffff,
      transparent: true,
      opacity: 0.0,
      // This alphaTest is set this way so that we'd get a truly transparent "grabbing area"
      // for the vertex. Only opacity: 0 is sometimes results in roof fill becoming transparent where
      // the vertex grabbing area is, which is and looks weird.
      alphaTest: 1
    });
    // To fix the oversize problem at initial scale:
    this.unzoom(ViewPort.scaleFactor);
  }

  get x(): number {
    return this.mesh.position.x;
  }
  get y(): number {
    return this.mesh.position.y;
  }
  get z(): number {
    return this.mesh.position.z;
  }

  private getBuildingName(editor: EditorStore): string | undefined {
    if (this.cachedBuildingName === '') {
      const buildingName = editor.domain.findBuildingByChild(getParentLyraModelByMesh<Outline>(this.mesh, 2))?.name;
      if (!buildingName) {
        return '';
      }
      for (const vertex of getParentLyraModelByMesh<Boundary>(this.mesh).vertices) {
        vertex.setBuildingName(buildingName);
      }
    }
    return this.cachedBuildingName;
  }
  setBuildingName(name: string): void {
    this.cachedBuildingName = name;
  }

  override select(): void {
    this.currentRadius = this.radiusSelected * this.mapZoomFactor;
    super.select();
    this.renderOrder = 0;
    this.dotMesh.renderOrder = -1;
  }

  override unselect(): void {
    this.currentRadius = this.radiusDefault * this.mapZoomFactor;
    this.autoResetToValidPosition = true;
    super.unselect();
    this.renderOrder = 0;
    this.dotMesh.renderOrder = 0;
  }

  redraw(): void {
    this.updateScale();
  }

  unzoom(factor: number): void {
    this.zoomFactor = factor;
    this.updateScale();
  }

  getVector3s(): Vector3[] {
    return [this.getVector3()];
  }

  private checkRectangularProtrusionIntersections(newPosition: Vector3): boolean {
    const protrusion = getParentLyraModelByMesh<RectangleProtrusion>(this.mesh, 2);
    protrusion.calculateInitialCorner(newPosition);
    return protrusion.onMoveVertex();
  }

  private isNoGeneralIntersections(
    isRectangularProtrusion: boolean,
    myParentVertices: Vector3[],
    newPosition: Vector3
  ): boolean {
    if (isRectangularProtrusion) {
      const result = this.checkRectangularProtrusionIntersections(newPosition);
      this.moveValidationLog('noGeneralIntersections(isRectangularProtrusion), result: ', result);
      return result;
    }
    const result = !isPolygonSelfIntersecting(myParentVertices);
    this.moveValidationLog('noGeneralIntersections, result: ', result);
    return result;
  }

  private isNoOutlineIntersections(
    isOutline: boolean,
    outlines: Outline[],
    roofs: RoofFace[],
    myParentVertices: Vector3[],
    editor: EditorStore,
    myRoofFaceOrOutlineId?: string
  ): boolean {
    let isValidPosition: boolean = true;

    if (isOutline) {
      this.moveValidationLog('noOutlineIntersections, check other outlines: ', outlines);
      // Checking against other Outlines
      for (const outline of outlines) {
        // Avoid Self Check
        if (myRoofFaceOrOutlineId !== outline.serverId) {
          const outlineVertices: Vector3[] = (outline as Outline).boundary.vector3s;
          if (isPolygonIntersectingAnother(outlineVertices, myParentVertices)) {
            this.moveValidationLog('[failed] noOutlineIntersections, intersecting another outline');
            isValidPosition = false;
            break;
          }
        }
      }
      // Checking against RoofFaces
      if (isValidPosition) {
        this.moveValidationLog('noOutlineIntersections, check against roofs: ', roofs);
        for (const roof of roofs) {
          const roofVertices: Vector3[] = roof.boundary.vector3s;

          // Checking Inside Roofs
          if (this.getBuildingName(editor) === editor.domain.findBuildingByChild(roof)?.name) {
            if (!isPolygonInsideAnother(roofVertices, myParentVertices)) {
              this.moveValidationLog('[failed] roof from another building inside this outline');
              isValidPosition = false;
              break;
            }
          } else {
            // Checking Outside Roofs
            if (
              isPolygonIntersectingAnother(roofVertices, myParentVertices)
              || areVerticesInsidePolygon(roofVertices, myParentVertices)
            ) {
              this.moveValidationLog('[failed] intersecting a roof');
              isValidPosition = false;
              break;
            }
          }
        }
      }
    }
    return isValidPosition;
  }

  private isNoParcelIntersections(
    isParcelBoundary: boolean,
    parcelVertices: Vector3[],
    myParentVertices: Vector3[],
    outlines: Outline[],
    roofFaces: RoofFace[]
  ): boolean {
    if (isParcelBoundary) {
      for (const outlineOrRoofFace of [...outlines, ...roofFaces]) {
        // Avoid Self Check
        const outlineVertices: Vector3[] = (outlineOrRoofFace as Outline).boundary.vector3s;
        const intersectingOutlineOrRoof =
          arePolygonEdgesIntersecting(outlineVertices, myParentVertices)
          || !areVerticesInsidePolygon(outlineVertices, myParentVertices, true);
        if (intersectingOutlineOrRoof) {
          this.moveValidationLog('[failed] noParcelIntersections, intersecting an outline or a roof face');
          return false;
        }
      }

      return true;
    }

    const isInvalid =
      arePolygonEdgesIntersecting(parcelVertices, myParentVertices)
      || !isPolygonInsideAnother(myParentVertices, parcelVertices);

    if (isInvalid) {
      this.moveValidationLog(
        '[failed] noParcelIntersections, intersecting the parcel boundary or parcel '
          + 'boundary does not enclose all buildings'
      );
    }

    return !isInvalid;
  }

  private isNoRoofFaceIntersections(
    isRoofFace: boolean,
    outlines: Outline[],
    roofs: RoofFace[],
    myParentVertices: Vector3[],
    editor: EditorStore,
    myRoofFaceId?: string
  ): boolean {
    let isValidPosition: boolean = true;

    if (isRoofFace) {
      let myOutline: Outline | undefined;
      // Checking Roof Face if Enclosed in Outline
      for (const outline of outlines) {
        if (this.getBuildingName(editor) === editor.domain.findBuildingByChild(outline as Outline)?.name) {
          myOutline = outline as Outline;
          break;
        }
      }
      this.moveValidationLog('noRoofFaceIntersections, found my outline?: ', myOutline);
      // Checking against Protrusions
      for (const protrusion of getParentLyraModelByMesh<RoofFace>(this.mesh, 2).protrusions) {
        const protrusionVertices: Vector3[] = protrusion.boundary.vector3s;
        if (!isPolygonInsideAnother(protrusionVertices, myParentVertices)) {
          this.moveValidationLog('[failed] noRoofFaceIntersections, protrusion is not inside this roof');
          isValidPosition = false;
        }
      }
      // RoofFace is inside Outline
      if (myOutline) {
        if (isValidPosition) {
          // Checking against my Outline
          const outlineVertices: Vector3[] = myOutline.boundary.vector3s;
          if (!isPolygonInsideAnother(myParentVertices, outlineVertices)) {
            this.moveValidationLog('[failed] noRoofFaceIntersections, this roof is not inside its outline');
            isValidPosition = false;
          }
          if (isValidPosition) {
            // Checking against fellow RoofFaces
            for (const roof of roofs) {
              if (myRoofFaceId !== roof.serverId) {
                const roofVertices: Vector3[] = (roof as RoofFace).boundary.vector3s;
                if (
                  isPolygonIntersectingAnother(roofVertices, myParentVertices)
                  || areVerticesInsidePolygon(roofVertices, myParentVertices)
                ) {
                  this.moveValidationLog(
                    '[failed] noRoofFaceIntersections, roofs are intersecting or one roof intersects another'
                  );
                  isValidPosition = false;
                }
              }
            }
          }
        }
      } else {
        // RoofFace has no Outline
        if (isValidPosition) {
          // Checking against Outlines
          for (const outline of outlines) {
            const outlineVertices: Vector3[] = (outline as Outline).boundary.vector3s;
            if (
              isPolygonIntersectingAnother(outlineVertices, myParentVertices)
              // Check that this roof is not enclosing an outline:
              || areVerticesInsidePolygon(outlineVertices, myParentVertices)
              // Check that this roof is not inside an outline that has no roof faces with this roof face's id:
              || (areVerticesInsidePolygon(myParentVertices, outlineVertices)
                && !outline.building?.roofFaces.some(({ serverId }: RoofFace): boolean => serverId === myRoofFaceId))
            ) {
              this.moveValidationLog(
                '[failed] noRoofFaceIntersections, this roof intersects an outline or encloses an outline'
              );
              isValidPosition = false;
            }
          }
        }
        if (isValidPosition) {
          // Checking against RoofFaces
          for (const roof of roofs) {
            if (myRoofFaceId !== roof.serverId) {
              const roofVertices: Vector3[] = (roof as RoofFace).boundary.vector3s;
              if (
                isPolygonIntersectingAnother(roofVertices, myParentVertices)
                || areVerticesInsidePolygon(roofVertices, myParentVertices)
              ) {
                this.moveValidationLog(
                  '[failed] noRoofFaceIntersections (this roof has no outline), roofs are '
                    + 'intersecting or one roof intersects another'
                );
                isValidPosition = false;
              }
            }
          }
        }
      }
    }

    return isValidPosition;
  }

  getPendingVertices(replaceId: string, replace: Vector3): Vector3[] {
    return getParentLyraModelByMesh<Outline>(this.mesh, 2).boundary.vertices.map((vertex: Vertex): Vector3 => {
      if (vertex.serverId === replaceId) {
        return replace;
      }
      return vertex.getVector3();
    });
  }

  getParentVertices(): Vector3[] {
    return getParentLyraModelByMesh<Outline>(this.mesh, 2).boundary.vector3s;
  }

  toCanvasCoordinatesInPixels(): SimpleVector2 {
    const editor = getRootStore().editor;
    return toCanvasCoordinatesInPixels(editor, this.mesh.position);
  }

  override move(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    const roofFace = this.parentRoofFace;
    const roofFaceOrOutlineOrParcel = roofFace ?? this.parentOutline ?? this.parentParcel;
    if (!roofFaceOrOutlineOrParcel) {
      console.error('This vertex is not part of any roof face / outline:', this);
      return;
    }
    if (roofFace?.verticesUpdateIsInProgress) {
      return;
    }

    const newPosition: Vector3 = newPositions[0];

    const { position: pendingPosition } = smartGuides.applyGuides({
      mousePos: new Vector3(newPosition.x, newPosition.y, newPosition.z),
      wipBoundary: getParentLyraModelByMesh<Boundary>(this.mesh),
      vertexId: this.serverId,
      kind: KindGuides.MOVE_VERTEX
    });

    const boundary = getParentLyraModelByMesh<Boundary>(this.mesh);

    // This call to updateFirstOrLastVertexIfNeeded is required to
    // calculate isVertexLyingOnOtherLineExceptEndingsOrCollapsed correctly.
    boundary.updateFirstOrLastVertexIfNeeded(this);

    if (pendingPosition) {
      // Do not apply snap if the vertex is lying on one of its parent polygon edges
      // or collapsed to another one.
      const pendingVertices = this.getPendingVertices(this.serverId, pendingPosition);
      if (isVertexLayingOnOtherLineExceptEndingsOrCollapsed(pendingVertices, pendingPosition, this.mesh.position)) {
        // Trying to change position without snap:
        this.mesh.position.set(newPosition.x, newPosition.y, newPosition.z);
      } else {
        this.mesh.position.set(pendingPosition.x, pendingPosition.y, pendingPosition.z);
      }
    }

    // Calling updateFirstOrLastVertexIfNeeded again because pendingPosition might
    // be the first or the last vertex, and now we need to update its counterpart.
    boundary.updateFirstOrLastVertexIfNeeded(this);
    boundary.updateAdjacentSegmentsAt(this);
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    super.onDragFinish(editor, smartGuides);
    /**
     * this.parent => Boundary
     * Boundary.parent => Parcel
     */
    if (getLyraModelByOptionalMesh(this.mesh.parent?.parent)?.type === SceneObjectType.ParcelBoundary) {
      config.analytics?.trackEvent(new ParcelBoundaryUpdatedEvent(editor.domain));
    }
  }

  private moveValidationLog(message: string, ...args: any[]): void {
    if (this.debugValidation) {
      // eslint-disable-next-line no-console
      console.log(`Vertex.move ${message}`, ...args);
    }
  }
  isValidCurrentPosition(editor: EditorStore): boolean {
    const outlines = editor.getObjectsByType(SceneObjectType.Outline);
    const roofFaces = editor.getObjectsByType(SceneObjectType.RoofFace);
    const parcel = editor.domain.project.site.parcel;
    const parent = getParentLyraModelByMesh<RoofFace>(this.mesh, 2);
    const parentId = parent?.serverId;

    if (!parent?.boundary?.vertices) {
      return false;
    }

    const myParentVertices: Vector3[] = this.getParentVertices();

    const parentType = parent?.type;
    const isRectangularProtrusion = parentType === SceneObjectType.Protrusion;
    const isRoofFace = parentType === SceneObjectType.RoofFace;
    const isOutline = parentType === SceneObjectType.Outline;
    const isParcelBoundary = parentType === SceneObjectType.ParcelBoundary;

    const noGeneralIntersections = this.isNoGeneralIntersections(
      isRectangularProtrusion,
      myParentVertices,
      this.mesh.position
    );
    const noOutlineIntersections =
      noGeneralIntersections
      && this.isNoOutlineIntersections(
        isOutline,
        outlines as Outline[],
        roofFaces as RoofFace[],
        myParentVertices,
        editor,
        parentId
      );
    const noParcelIntersections =
      noOutlineIntersections
      && (!parcel.hasBoundary
        || this.isNoParcelIntersections(
          isParcelBoundary,
          parcel.getVector3s(),
          myParentVertices,
          outlines as Outline[],
          roofFaces as RoofFace[]
        ));
    const noRoofIntersections =
      noParcelIntersections
      && this.isNoRoofFaceIntersections(
        isRoofFace,
        outlines as Outline[],
        roofFaces as RoofFace[],
        myParentVertices,
        editor,
        parentId
      );

    const result = noRoofIntersections;
    this.moveValidationLog('validation, result: ', result);
    if (result) {
      this.hasEverBeenValidAfterUserInteraction = true;
    }
    return result;
  }

  override afterMove(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    const parentType = getParentLyraModelByMesh<RoofFace>(this.mesh, 2)?.type;
    const isRectangularProtrusion = parentType === SceneObjectType.Protrusion;
    const isRoofFace = parentType === SceneObjectType.RoofFace;

    const isValidPosition: boolean = this.isValidCurrentPosition(editor);

    this.onPositionUpdate(isValidPosition, isRoofFace, isRectangularProtrusion, smartGuides, editor);

    return true;
  }

  private onPositionUpdate(
    isValidPosition: boolean,
    isRoofFace: boolean,
    isRectangularProtrusion: boolean,
    smartGuides: SmartGuidesStore,
    editor: EditorStore
  ): void {
    const lineSnapGuide: SnapGuide = smartGuides.guides.find(
      (guide: BaseGuide): boolean => guide.name === 'Line Snap'
    ) as SnapGuide;
    const parentBoundary = getParentLyraModelByMesh<Boundary>(this.mesh);

    if (isValidPosition) {
      if (isRoofFace) {
        const roofFace = getParentLyraModelByMesh<RoofFace>(this.mesh, 2);
        if (roofFace) {
          // calculate new Z value
          const indexOfThis = roofFace.boundary.vertices.indexOf(this);
          if (indexOfThis > -1) {
            // Don't change the plane of the roofface, calculate it with the previous vertex values
            const verticesClone = roofFace.boundary.vertices.map(
              (vertex: Vertex): Vector3 => new Vector3(vertex.x, vertex.y, vertex.z)
            );
            verticesClone[indexOfThis] = this.lastValidPosition!.clone();

            const thisVector = new Vector3(this.x, this.y, this.z);
            this.mesh.position.z = calculateZFromInfiniteMesh(verticesClone, thisVector) || this.mesh.position.z;
          }
        }
      }

      parentBoundary.updateFirstOrLastVertexIfNeeded(this);
      this.lastValidPosition = this.getVector3();

      if (lineSnapGuide.isEnabled) {
        lineSnapGuide.previewVertex!.mesh.visible = true;
      }
    } else {
      // Trying to reset position.
      // If it's not possible, or somehow reset position is invalid -
      // temporarily disable resetting logic. Until the drag ends.
      if (this.autoResetToValidPosition) {
        this.mesh.position.set(this.lastValidPosition!.x, this.lastValidPosition!.y, this.lastValidPosition!.z);
        parentBoundary.updateFirstOrLastVertexIfNeeded(this);

        const isResetValidPosition: boolean = this.isValidCurrentPosition(editor);
        if (!isResetValidPosition) {
          // disable resetting logic until the drag ends.
          this.autoResetToValidPosition = false;
        }
      }

      if (lineSnapGuide.isEnabled) {
        lineSnapGuide.previewVertex!.mesh.visible = false;
      }
    }
    if (isRectangularProtrusion) {
      getParentLyraModelByMesh<RectangleProtrusion>(this.mesh, 2).calculateInitialCorner(this.lastValidPosition!);
    }
  }

  resetVertex(vector: Vector3, index: number, editor: EditorStore): void {
    this.mesh.position.set(vector.x, vector.y, vector.z);

    const rectangularProtrusion: RectangleProtrusion | undefined = this.isParentIntoElement(
      editor,
      SceneObjectType.Protrusion
    );

    if (rectangularProtrusion && this.mesh.parent) {
      rectangularProtrusion.calculateInitialCorner(vector);
    }
  }

  getVector3(): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }

  getVector2(): Vector2 {
    return new Vector2(this.x, this.y);
  }

  private updateScale(): void {
    const scaleValue = this.zoomFactor * this.currentRadius;
    this.mesh.scale.set(scaleValue, scaleValue, scaleValue);
  }

  private isParentIntoElement(editor: EditorStore, type: string): RectangleProtrusion | undefined {
    const ele: RectangleProtrusion[] = editor.getObjectsByType(type, true);
    const parent: Boundary = getParentLyraModelByMesh<Boundary>(this.mesh);
    return ele.find((el: RectangleProtrusion): boolean => el.boundary.serverId === parent.serverId);
  }
}

export function convertVector3toVertex(list: Vector3[]): Vertex[] {
  return list.map((item: Vector3): Vertex => {
    const newVertex = new Vertex();
    newVertex.mesh.position.set(item.x, item.y, item.z);
    return newVertex;
  });
}
