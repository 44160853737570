import {
  validateFields
  // @ts-ignore
} from 'react-jsonschema-form-conditionals';
import type { JSONSchema7 } from 'json-schema';
import type { UiSchema } from '@rjsf/utils/lib/types';
import { toArray } from '../engine/util';
import type { FormData } from '../FormOptionsRulesAndState';
import {
  findFormSchemaGroup, findGroupInFormData, findUiSchemaGroup, getFieldByGroupName
} from './util';

function doRemove(
  field: string,
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
): void {
  const fieldGroup = findFormSchemaGroup(
    field, schema.properties
  );
  const requiredArrayLocation = fieldGroup
    ? (schema.properties![fieldGroup] as JSONSchema7).required
    : schema.required;

  const requiredIndex = requiredArrayLocation?.indexOf(field) ?? -1;
  if (requiredIndex !== -1) {
    requiredArrayLocation!.splice(
      requiredIndex, 1
    );
  }

  const uiSchemaGroup = findUiSchemaGroup(
    field, uiSchema
  );
  const formDataGroup = findGroupInFormData(
    field, formData
  );

  const formDataPropertyLocation = getFieldByGroupName(formData, formDataGroup);
  if (!fieldGroup) {
    return;
  }
  delete (schema.properties?.[fieldGroup] as JSONSchema7)?.properties?.[field];
  if (!uiSchemaGroup) {
    return;
  }
  delete uiSchema[uiSchemaGroup][field];
  delete formDataPropertyLocation?.[field];

  const fieldIndex = (uiSchema['ui:order'] ?? []).indexOf(field);
  if (fieldIndex !== -1) {
    uiSchema['ui:order']?.splice(
      fieldIndex, 1
    );
  }
}

export default function hideFields(
  { field }: {
    field: string | string[]
  },
  schema: JSONSchema7,
  uiSchema: UiSchema,
  formData: FormData
): void {
  const fieldArr = toArray(field);
  fieldArr.forEach((field): void => {
    doRemove(
      field.split('.')[1],
      schema,
      uiSchema,
      formData
    );
  });
}

hideFields.validate = validateFields(
  'hideFields', ({ field }: {field: string | string[]}) => field
);
