(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define("lyra-form-generator", ["react", "react-dom", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["lyra-form-generator"] = factory(require("react"), require("react-dom"), require("styled-components"));
	else
		root["lyra-form-generator"] = factory(root["react"], root["react-dom"], root["styled-components"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__39155__, __WEBPACK_EXTERNAL_MODULE__69514__, __WEBPACK_EXTERNAL_MODULE__88694__) => {
return 