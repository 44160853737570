import type PvModulePosition from '../../../domain/models/SiteDesign/PvModulePosition';
import type { Design } from '../../../domain/models/Design/Design';
import { LayoutDesignStage } from '../../../domain/stages/DesignStages/LayoutDesignStage';
import type { IStage } from '../../../domain/stages/IStage';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IDeletePvModulesAndPositionsCommandDependencies extends IBaseCommandDependencies {
  readonly currentStage: IStage;
  readonly positionsToDelete: PvModulePosition[];
  readonly positionsToDeletePvModulesIn: PvModulePosition[];
  readonly domain: DomainStore;
}

export class DeletePvModulesPositionsCommand extends BaseCommand {
  private readonly currentStage: IStage;
  private readonly positionsToDelete: PvModulePosition[];
  private readonly positionsToDeletePvModulesIn: PvModulePosition[];
  private readonly design: Design;

  constructor(dependencies: IDeletePvModulesAndPositionsCommandDependencies) {
    super();
    this.currentStage = dependencies.currentStage;
    this.positionsToDelete = dependencies.positionsToDelete;
    this.positionsToDeletePvModulesIn = dependencies.positionsToDeletePvModulesIn;
    this.design = dependencies.domain.design;
  }

  async execute(): Promise<void> {
    this.positionsToDeletePvModulesIn.forEach((position: PvModulePosition): void => position.deletePvModule());
    const positionsToDeletePvModulesInIds = this.positionsToDeletePvModulesIn.map(
      (position: PvModulePosition): string => position.serverId
    );
    this.design.system.deletePvModulesInPositions(positionsToDeletePvModulesInIds);

    this.positionsToDelete.forEach((position: PvModulePosition): void =>
      position.roofFace.deletePvModulePosition(position)
    );
    const positionIds = this.positionsToDelete.map((position: PvModulePosition): string => position.serverId);
    this.design.deletePvModulePositions(positionIds);

    if (this.currentStage instanceof LayoutDesignStage) {
      this.currentStage.getEnergyProductionEstimate();
      this.currentStage.updateAllPositionsCollision(this.currentStage.editor);
    }
  }
}
