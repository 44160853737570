import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';

const { H4 } = LyraFontFamily.FontFamily;

type Props = PropsWithTheme & {
  active?: boolean;
  status?: boolean;
  height?: string;
  color?: string;
  error?: boolean;
};

export const LogoView = styled.div<Props>`
  height: ${({ height }: Props): string => (height ? height : '73px')};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const HightLighView = styled.div<Props>`
  height: ${({ height }: Props): string => (height ? height : '73px')};
  display: flex;
  border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
`;

export const LeftView = styled.div`
  width: 50%;
`;

export const RightView = styled(LeftView)`
  display: flex;
  justify-content: flex-end;
`;

export const MainText = styled(H4)`
  font-size: 14px;
`;

export const StatusText = styled(H4)<Props>`
  font-size: 14px;
  color: ${({
    theme, status
  }: Props): Color => (status ? theme.colors.gray1 : theme.colors.grayOpacity1)};
`;

export const RadioWrapper = styled.div`
  display: flex;
`;

export const InputLeftView = styled(LeftView)`
  width: 30%;
`;

export const InputRightView = styled(RightView)<Props>`
  width: 70%;
  border: ${({ error }: Props): string => (error ? '1px solid red' : '')};
`;

export const DataInput = styled.input`
  width: 100%;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  font-size: 10px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
  padding-left: 7px;
`;

export const ColorText = styled(H4)`
  font-size: 10px;
  line-height: 2.4;
  width: 50px;
  text-align: right;
`;

export const ColorGroup = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

export const ColorPanel = styled.div<Props>`
  width: 28px;
  height: 28px;
  border-radius: 2.8px;
  background-color: ${({ color }: Props): string | undefined => color};
`;

export const InputTextArea = styled.textarea`
  width: 100%;
  height: 87px;
  border-radius: 4px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.tertiary};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  font-size: 10px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray1};
  resize: none;
  margin-top: 13px;
  padding-left: 12px;
  padding-top: 5px;
  line-height: 1.8;
`;

export const PickerWrapper = styled.div`
  position: absolute;
  z-index: 2;
`;

export const CoverPickerView = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
