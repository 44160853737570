import { Vector3 } from 'three';
import type EditorStore from '../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import { getCenterOfBoundingBoxAroundVertices } from '../../utils/spatial';
import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Vertex } from '../graphics/Vertex';
import { getParentLyraModelByMesh } from '../sceneObjectsWithLyraModelsHelpers';
import { SceneObjectType } from '../models/Constants';
import { KindGuides } from '../../stores/UiStore/SmartGuidesStore/IApplyParams';
import type { Selectable } from './Selectable';

const MINIMAL_DRAG_DISTANCE_BEFORE_MOVING = 2.9;

export const Draggable = <T extends AnyConstructor<Selectable>>(DraggableBase: T) => {
  /**
   * @description Draggable has {@see performMove} method to do new positions calculation. {@see move} and
   * {@see afterMove} abstract methods to apply position changes to meshes and do business logic (respectively).
   */

  abstract class DraggableMixin extends DraggableBase {
    isDraggable = true;
    isDragging = false;
    initialPositions: Vector3[] = [];
    moveDeltaVector: Vector3 = new Vector3(0, 0, 0);
    previousMousePosition?: Vector3;
    startDragPosition?: Vector3;
    canMove: boolean = true;
    lastValidPosition?: Vector3;
    lastValidVertices: Vector3[] = [];
    offset?: Vector3;
    snapOccurredOnLastMove: boolean = false;
    defaultRenderOrder: number = 0;
    /**
     * @description inherited classes can override `nonResettable` field to true in order to
     * disable resetting behaviour: draggable will not be reset to a previous valid position if
     * drag ended at an invalid position.
     */
    nonResettable: boolean = false;

    initialCenter?: Vector3;

    abstract isMultipleVertices: boolean;

    onDragStarted?: (mousePosition: Vector3) => void;
    onDragFinished?: () => void;

    canDrag(): boolean {
      return this.selected;
    }

    onDragStart(mousePosition: Vector3): void {
      this.isDragging = true;
      this.startDragPosition = mousePosition.clone().setZ(mousePosition.z * -1);
      this.previousMousePosition = mousePosition.clone();
      this.offset = this.mesh.position.clone().addScaledVector(this.startDragPosition, -1);
      this.initialPositions = [];
      this.lastValidVertices = [];
      this.renderOrder = -1;

      const vertices = this.getVector3s();

      if (!this.isMultipleVertices) {
        this.initialPositions.push(this.mesh.position.clone());
        this.lastValidPosition = this.mesh.position.clone();
      } else {
        const cloneVertices = vertices.map((vector: Vector3): Vector3 => vector.clone());
        this.initialPositions.push(...cloneVertices);
        this.lastValidVertices.push(...cloneVertices);
      }

      this.initialCenter = getCenterOfBoundingBoxAroundVertices(vertices);

      this.onDragStarted?.(mousePosition);
    }

    /**
     * @description performMove should only do movement, and {@see afterMove} should do validation, etc.
     * @param mousePosition
     * @param editor
     * @param smartGuides
     * @param snapIgnorePvModulePositionServerIds
     * @param callAfterMoveCallbackImmediately
     */
    performMove(
      mousePosition: Vector3,
      editor: EditorStore,
      smartGuides: SmartGuidesStore,
      snapIgnorePvModulePositionServerIds: string[] = [],
      callAfterMoveCallbackImmediately: boolean = false
    ): Vector3[] {
      const moveDeltaSinceDragStartVector = this.getMoveDeltaVector(
        mousePosition,
        smartGuides,
        snapIgnorePvModulePositionServerIds
      );
      const newPosition = this.initialPositions.map(
        (v: Vector3): Vector3 => v.clone().add(moveDeltaSinceDragStartVector)
      );

      this.move(newPosition, editor, smartGuides);

      if (callAfterMoveCallbackImmediately) {
        this.afterMove(newPosition, editor, smartGuides);
      }

      return newPosition;
    }

    getMoveDeltaVector(
      mousePosition: Vector3,
      smartGuides: SmartGuidesStore,
      snapIgnorePvModulePositionServerIds: string[] = []
    ): Vector3 {
      let newVertexPosition: Vector3[] = [];

      // Edge case of enabling draggable behaviour while dragging.
      if (!this.previousMousePosition) {
        this.previousMousePosition = mousePosition.clone();
      }
      if (!this.initialCenter) {
        this.initialCenter = getCenterOfBoundingBoxAroundVertices(this.getVector3s());
      }

      this.moveDeltaVector = new Vector3().subVectors(mousePosition, this.previousMousePosition);
      this.previousMousePosition.copy(mousePosition.clone());

      if (!this.isMultipleVertices) {
        newVertexPosition.push(mousePosition);
      } else {
        const moveDiff = new Vector3().subVectors(mousePosition, this.startDragPosition!);
        newVertexPosition = this.initialPositions.map((vector: Vector3): Vector3 => {
          return vector.clone().add(moveDiff);
        });
      }

      const newCenter = getCenterOfBoundingBoxAroundVertices(newVertexPosition);

      const objectToCheck: Draggable =
        (getParentLyraModelByMesh(this.mesh) as Selectable)?.propertyId === SceneObjectType.PvModulePosition
          ? getParentLyraModelByMesh(this.mesh)
          : this;

      const {
        objectVertex, snapOccurred
      } = smartGuides.applyGuides({
        kind: KindGuides.MOVE_OBJECT,
        vertexObject: newVertexPosition,
        wipObject: objectToCheck,
        ignorePvModulePositionServerIds: snapIgnorePvModulePositionServerIds
      });

      this.snapOccurredOnLastMove = !!snapOccurred;

      return new Vector3().subVectors(objectVertex || newCenter, this.initialCenter);
    }

    isMovePossible(
      mousePosition: Vector3,
      smartGuides: SmartGuidesStore,
      snapIgnorePvModulePositionServerIds: string[] = []
    ): boolean {
      const moveDeltaVector = this.getMoveDeltaVector(mousePosition, smartGuides, snapIgnorePvModulePositionServerIds);
      return moveDeltaVector.length() > MINIMAL_DRAG_DISTANCE_BEFORE_MOVING;
    }

    onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
      this.isDragging = false;
      smartGuides.resetGuides();

      if (!this.nonResettable && !this.canMove) {
        if (!this.isMultipleVertices) {
          this.resetVertex(this.initialPositions[0], 0, editor);
        } else {
          this.lastValidVertices.forEach((vector: Vector3, index: number): void =>
            this.resetVertex(vector, index, editor)
          );
        }
      }
      this.renderOrder = this.defaultRenderOrder;

      if ((this as unknown as Vertex).autoResetToValidPosition === false) {
        (this as unknown as Vertex).autoResetToValidPosition = true;
      }

      this.onDragFinished?.();
    }

    abstract getVector3s(): Vector3[];
    abstract resetVertex(vector: Vector3, index: number, editor: EditorStore): void;

    /**
     * @description move applies results of {@see performMove}-calculated positions, but only do mesh movement,
     * while {@see afterMove} should do validation, etc.
     * @param newVertexPosition
     * @param editor
     * @param smartGuides
     */
    abstract move(newVertexPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void;

    /**
     * @description afterMove does validation and other business logic, while
     * {@see afterMove} does the actual positions updates to meshes.
     * @param newVertexPosition
     * @param editor
     * @param smartGuides
     * @returns isValid flag, true if new position is valid
     */
    abstract afterMove(newVertexPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean;
  }

  return DraggableMixin;
};

export type Draggable = Mixin<typeof Draggable>;
