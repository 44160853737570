import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { BaseProps, ReactElement } from 'react';
import React, { Fragment, useState } from 'react';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import type {
  IMpptStringingOptionData,
  ISeriesStringConfigurationData
} from '../../../../domain/entities/StringingOption/IStringingOptionData';
import useStore from '../../../../stores/useStore';
import type {
  StringConnected,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import {
  ContentItem,
  ContentText,
  DeleteButtonWrapper,
  DeleteIcon,
  ItemText,
  DcOptimizerIcon,
  DcOptimizerItem,
  DcOptimizerText,
  DcOptimizerWrapper,
  PanelHeader,
  PanelText,
  TextWrapper,
  UnitText,
  ValueItemWrapper,
  ValueText,
  WrapperItemText,
  WrapperLinear,
  WrapperLinearProgress,
  WrapperLinearText,
  WrapperValueText
} from './styles';

type Props = BaseProps & {
  index: number;
  onDeleteActiveString: () => void;
};

const { CircularProgressBar, LinearProgressBar } = LyraProgressBar;

const VOLTS_MAX_VALUE = 60;

const StringInParallel = observer((props: Props): ReactElement => {
  let seriesStringDefinition;
  let numberOfStrings = 0;
  const { index = 0, onDeleteActiveString } = props;
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const { systemSummaryList, selectedString, minDcInputVoltage, maxDcInputVoltage } = systemSummaryViewModel;
  const { mppts = [] } = systemSummaryList[index].definition;
  const stringsConnected = systemSummaryList[index].strings;
  const existingString =
    stringsConnected.find((item: StringConnected): boolean => item.getId() === selectedString) || stringsConnected[0];
  if (stringsConnected.length) {
    const stringingOption: IMpptStringingOptionData | undefined =
      (existingString?.stringingOption as IMpptStringingOptionData) ?? undefined;

    seriesStringDefinition = stringingOption?.seriesStringDefinition;
    numberOfStrings = stringingOption?.numberOfStrings ?? 1;
  }
  const mpptDefinition =
    mppts.find((value: ISeriesStringConfigurationData): boolean => value.mppt === existingString?.getMpptId())
      ?.mpptDefinition ?? undefined;
  const minInputVoltage = minDcInputVoltage ?? VOLTS_MAX_VALUE / 3;
  const maxInputVoltage = maxDcInputVoltage ?? (VOLTS_MAX_VALUE / 3) * 2;

  const vmpAtDesignHighTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Not Optimal Voltage, But Still Functional',
      color: '#90C725',
      value: maxInputVoltage - 1
    }
  ];

  const vocAtDesignLowTemperatureMarkers = [
    {
      name: 'Under Voltage',
      color: '#BEB53D',
      value: 0
    },
    {
      name: 'Optimal Voltage',
      color: '#29A63C',
      value: minInputVoltage
    },
    {
      name: 'Upper Voltage',
      color: '#D34A4A',
      value: maxInputVoltage - 1
    }
  ];

  return (
    <Fragment>
      <ContentItem>
        <ValueItemWrapper bordered className="pb-xxs">
          <Flexbox justify="space-between" dir="row-reverse" align="center" className="pt-xs pb-xxs">
            {numberOfStrings > 0 && (
              <DeleteButtonWrapper onClick={onDeleteActiveString}>
                <DeleteIcon name="icon-delete" />
              </DeleteButtonWrapper>
            )}
            {numberOfStrings > 1 && <ContentText className="mt-xxs">{numberOfStrings} Strings in parallel</ContentText>}
          </Flexbox>
        </ValueItemWrapper>
        <WrapperLinearProgress>
          <WrapperLinearText>lsc</WrapperLinearText>
          <WrapperLinear>
            <LinearProgressBar
              units={'A'}
              minValue={0}
              maxValue={mpptDefinition?.maxInputIsc ?? 15}
              currentValue={(seriesStringDefinition?.iscAtDesignHighTemperature ?? 0) * numberOfStrings}
              strokeColor={'#36A247'}
              size="min"
            />
          </WrapperLinear>
        </WrapperLinearProgress>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>DC power output</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{(seriesStringDefinition?.pStc ?? 0) * numberOfStrings}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
        <PanelHeader className="mt-s" black>
          <PanelText>Strings</PanelText>
        </PanelHeader>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>Modules in series</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{seriesStringDefinition?.numberOfModules ?? 0}</ValueText>
          </WrapperValueText>
        </ValueItemWrapper>
        <DcOptimizerWrapper>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={seriesStringDefinition?.vmpAtDesignHighTemperature ?? 0}
              markers={vmpAtDesignHighTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
            />
            <TextWrapper>
              <DcOptimizerIcon name="highTemp" />
              <DcOptimizerText>Vmp at high temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
          <DcOptimizerItem>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={80}
              currentValue={seriesStringDefinition?.vocAtDesignLowTemperature ?? 0}
              markers={vocAtDesignLowTemperatureMarkers}
              minValue={0}
              maxValue={maxInputVoltage}
              unitName={'Volts'}
              unitAbbreviation={'V'}
              allowOutOfRange={true}
              outOfRangeLabelColor="#D34A4A"
            />
            <TextWrapper>
              <DcOptimizerIcon name="lowTemp" />
              <DcOptimizerText>Voc at low temp.</DcOptimizerText>
            </TextWrapper>
          </DcOptimizerItem>
        </DcOptimizerWrapper>
        <ValueItemWrapper bordered>
          <WrapperItemText>
            <ItemText>DC power rating</ItemText>
          </WrapperItemText>
          <WrapperValueText>
            <ValueText>{seriesStringDefinition?.pStc ?? 0}</ValueText>
            <UnitText>W</UnitText>
          </WrapperValueText>
        </ValueItemWrapper>
      </ContentItem>
    </Fragment>
  );
});

export default StringInParallel;
