import type {
  Intersection, Vector3
} from 'three';
import { MathUtils } from 'three';
import throttle from 'lodash/throttle';
import { MouseBehaviour } from '../../../../domain/behaviour/MouseBehaviour';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import type { IStage } from '../../../../domain/stages/IStage';
import { BaseCastObjectControl } from '../../../EditorStore/Controls/BaseCastObjectControl';
import type { IUpdatePvModulesInstancesDependencies } from '../../../ServiceBus/Commands/UpdatePvModules';
import { canvasConfig } from '../../../../config/canvasConfig';
import type DomainStore from '../../../DomainStore/DomainStore';
import type { PropertiesStore } from '../../Properties/Properties';
import type { WorkspaceStore } from '../../WorkspaceStore';
import type {
  IBaseToolDependencies, IHandleClicksTool, IHandleMoveTool
} from '../Tool';
import { BaseTool } from '../Tool';
import type { DesignWorkspace } from '../../WorkspaceStore/workspaces/DesignWorkspace';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import type { IAddPvModulesInstancesDependencies } from '../../../ServiceBus/Commands/AddPvModulePositionsCommand';
import type { LayoutDesignStage } from '../../../../domain/stages/DesignStages/LayoutDesignStage';
import { getNewPvModulePositionVertices } from '../../../../utils/spatial';
import type { BaseControl } from '../../../EditorStore/Controls/BaseControl';
import type { IPointerMoveControlEvent } from '../../../EditorStore/Controls/ControlEvents';
import { SceneObjectType } from '../../../../domain/models/Constants';
import {
  getLyraModelByMesh,
  getLyraModelByOptionalMesh,
  getParentLyraModelByMeshOrLyraModel
} from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import PvModule from '../../../../domain/models/SiteDesign/PvModule';
import PvModulePosition from '../../../../domain/models/SiteDesign/PvModulePosition';
import { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import { ADD_MODULE_ID } from './constants';

export interface IAddModuleToolDependencies extends IBaseToolDependencies {
  properties: PropertiesStore;
  domain: DomainStore;
  workspace: WorkspaceStore;
  smartGuides: SmartGuidesStore;
}

export class AddModuleTool extends BaseTool implements IHandleClicksTool, IHandleMoveTool {
  private castObjectControl?: BaseCastObjectControl;
  private currentStage?: IStage;
  private domain: DomainStore;
  private workspace: WorkspaceStore;
  private addModulesToExistingPositionsInProgress: boolean = false;
  private lastRoofFaceForNewPvModulePositionPreview: RoofFace | undefined;
  private selectedPvModulePositionList: PvModulePosition[] = [];
  private mouseBehaviour: MouseBehaviour;
  private smartGuides: SmartGuidesStore;
  private previewPvModulePosition: PvModulePosition | undefined;
  private addedPvModulePositions: PvModulePosition[] = [];
  private addNewModulesInProgress: boolean = false;
  private lastPvModulePositionWithPlusSign: PvModulePosition | undefined;
  id: string = ADD_MODULE_ID;
  icon: string = 'add-module';
  title: string = 'Add module';
  override testId: string = 'AddModuleTool';
  description: string = this.title;
  showSubmenu: boolean = false;

  constructor(dependencies: IAddModuleToolDependencies) {
    super(dependencies);
    const {
      domain, workspace, smartGuides
    } = dependencies;
    this.domain = domain;
    this.workspace = workspace;
    this.mouseBehaviour = new MouseBehaviour(this.editor);
    this.smartGuides = smartGuides;
  }

  private updatePvModulePositionsCollision = (): void => {
    (this.currentStage as LayoutDesignStage).updateAllPositionsCollision(this.editor);
  };

  private throttledUpdatePvModulePositionsCollision = throttle(this.updatePvModulePositionsCollision, 150, {
    trailing: true
  });

  getCastedObjects = (): Intersection[] => [];

  whenSelected(): void {
    this.init();
    /** Adding event listeners */
    this.mouseBehaviour.addMouseClickEvents(this);
    this.mouseBehaviour.addMouseMoveEvents(this);
  }

  whenDeselected(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
    this.mouseBehaviour.removeMouseMoveEvents(this);
    this.removePvModulePreview();
    this.updatePvModulePositionsCollision();
  }

  addNewPvModuleAndPvModulePosition = (): void => {
    if (!this.previewPvModulePosition) {
      return;
    }
    this.previewPvModulePosition.onDragFinish(this.editor, this.smartGuides);
    const commandDependencies: IAddPvModulesInstancesDependencies = {
      currentStage: this.currentStage!,
      PvModulePositions: [this.previewPvModulePosition],
      domain: this.domain
    };
    this.serviceBus.send('add_pv_module_positions', commandDependencies);

    this.addedPvModulePositions.push(this.previewPvModulePosition);
    this.selectedPvModulePositionList.push(this.previewPvModulePosition);
    this.previewPvModulePosition.isPvModulePositionPreview = false;
    this.previewPvModulePosition.removePlusSign();

    // Resetting roof to re-create PV module preview
    this.lastRoofFaceForNewPvModulePositionPreview = undefined;
    this.previewPvModulePosition = undefined;

    this.updatePvModulePositionsCollision();
  };

  override onMouseDown = (): void => {
    const [intersectedPvModulePosition] = this.getCastedObjects();
    if (getLyraModelByOptionalMesh(intersectedPvModulePosition?.object) instanceof PvModulePosition) {
      this.addPvModulePositionToList(getLyraModelByMesh(intersectedPvModulePosition.object));
      this.addModulesToExistingPositionsInProgress = true;
    } else if (
      getLyraModelByOptionalMesh(intersectedPvModulePosition?.object) instanceof RoofFace
      && this.previewPvModulePosition
    ) {
      this.addNewPvModuleAndPvModulePosition();
      this.addNewModulesInProgress = true;
    }
  };

  createPreviewPvModulePosition(roofFace: RoofFace, position: Vector3): void {
    const supplementalData = this.domain.design.supplementalData;

    const mountingSystems = this.domain.design.system.equipment.mountingSystems;
    const mountingSystemInstance = mountingSystems.mountingSystemOn(roofFace.serverId)!;

    const mountingSystemDefinition = this.domain.design.system.equipment.mountingSystems.definitionFor(
      roofFace.serverId
    );

    const positionVertices = getNewPvModulePositionVertices(
      position,
      roofFace,
      supplementalData.pvModuleInfo!.dimensions.width,
      supplementalData.pvModuleInfo!.dimensions.length,
      mountingSystemInstance,
      mountingSystemDefinition
    );

    this.lastPvModulePositionWithPlusSign?.removePlusSign();

    this.previewPvModulePosition = new PvModulePosition({
      id: MathUtils.generateUUID(),
      orientation: mountingSystemInstance.layoutStrategy.dominantOrientation,
      positionVertices,
      rowSpacing: mountingSystemDefinition.rowSpacing,
      columnSpacing: mountingSystemDefinition.columnSpacing,
      designWorkspace: this.workspace.designWorkspace! as DesignWorkspace
    });
    this.previewPvModulePosition.isPvModulePositionPreview = true;
    this.previewPvModulePosition.addPlusSign(this.editor, this.domain, roofFace);

    this.previewPvModulePosition.setSelectedMode(false);
  }

  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    if (this.addModulesToExistingPositionsInProgress) {
      const intersected = this.getCastedObjects();
      const model = getLyraModelByOptionalMesh(intersected[0]?.object);
      if (model instanceof PvModulePosition) {
        this.addPvModulePositionToList(model);
      }

      return;
    }

    const {
      target, pointerPosition
    } = event;
    const [intersectedPvModulePosition] = this.getCastedObjects();

    const shouldShowPlusSignOnIntersected: boolean =
      getLyraModelByOptionalMesh(intersectedPvModulePosition?.object) instanceof PvModulePosition
      && getParentLyraModelByMeshOrLyraModel(intersectedPvModulePosition?.object) instanceof RoofFace
      && getLyraModelByMesh<PvModulePosition>(intersectedPvModulePosition.object).hasNoPvModule();

    if (shouldShowPlusSignOnIntersected) {
      this.lastPvModulePositionWithPlusSign?.removePlusSign();
      this.lastPvModulePositionWithPlusSign = getLyraModelByMesh(intersectedPvModulePosition?.object)!;
      this.lastPvModulePositionWithPlusSign.addPlusSign(this.editor, this.domain);
    }

    const updatePvModuleCollision = this.updatePvModulePositionPreviewOnMouseMove(
      intersectedPvModulePosition,
      pointerPosition,
      target
    );

    if (updatePvModuleCollision) {
      this.throttledUpdatePvModulePositionsCollision();
    }
  };

  private updatePvModulePositionPreviewOnMouseMove(
    intersectedPvModulePosition?: Intersection,
    pointerPosition?: Vector3,
    target?: BaseControl
  ): boolean {
    let showPvModulePreview = false;
    let updatePvModuleCollision = false;

    const intersectedLyraModel = getLyraModelByOptionalMesh(intersectedPvModulePosition?.object);
    const parentLyraModel = getParentLyraModelByMeshOrLyraModel(intersectedPvModulePosition?.object);
    const isRoofFace = intersectedLyraModel instanceof RoofFace;
    const isPvModulePosition = intersectedLyraModel instanceof PvModulePosition;
    // Showing PV module preview over roof faces and over PV module positions with PV modules
    const shouldShowPvModulePreview: boolean =
      isRoofFace
      || (isPvModulePosition && !intersectedLyraModel.hasNoPvModule() && parentLyraModel instanceof RoofFace);
    if (shouldShowPvModulePreview) {
      const roofFace: RoofFace = isRoofFace
        ? getLyraModelByMesh(intersectedPvModulePosition!.object)
        : (parentLyraModel as RoofFace);
      const position = pointerPosition && target?.unprojectMouseToFrustum(pointerPosition);

      // Don't create PV module preview if roof face is not selected for solar
      if (position && roofFace.hasLayout) {
        showPvModulePreview = true;

        updatePvModuleCollision = this.movePvModulePositionPreview(roofFace, position);
      }
    }

    if (!showPvModulePreview && this.lastRoofFaceForNewPvModulePositionPreview) {
      // Remove PV module preview from a roof
      this.removePvModulePreview();
    }

    return updatePvModuleCollision;
  }

  private movePvModulePositionPreview(roofFace: RoofFace, position: Vector3): boolean {
    // If we jumped to another roof, or roof face has no PV module preview yet:
    if (this.lastRoofFaceForNewPvModulePositionPreview !== roofFace) {
      // Remove PV module preview from previous roof if present
      this.removePvModulePreview();
      // PV module preview model will be written to this.previewPvModulePosition
      this.createPreviewPvModulePosition(roofFace, position);

      roofFace.addPvModulePosition(this.previewPvModulePosition!);
      this.previewPvModulePosition!.addSpacing();

      this.lastRoofFaceForNewPvModulePositionPreview = roofFace;

      // The logic of moving PV module preview upon a roof face is reused from dragging
      this.previewPvModulePosition!.onDragStart(position);
    } else {
      this.previewPvModulePosition!.performMove(position, this.editor, this.smartGuides, [], true);
      if (this.previewPvModulePosition!.snapOccurredOnLastMove && this.addNewModulesInProgress) {
        this.updatePvModulePositionsCollision();
        if (this.previewPvModulePosition!.canMove) {
          // valid, add module and move on
          this.addNewPvModuleAndPvModulePosition();
        }
      }

      return true;
    }

    return false;
  }

  removePvModulePreview(): void {
    if (this.previewPvModulePosition?.mesh && this.lastRoofFaceForNewPvModulePositionPreview) {
      this.lastRoofFaceForNewPvModulePositionPreview.removePvModulePosition(this.previewPvModulePosition);
      this.editor.removeObject(this.previewPvModulePosition.mesh);
      this.previewPvModulePosition = undefined;
    }
    this.lastRoofFaceForNewPvModulePositionPreview = undefined;
    setTimeout(() => {
      this.updatePvModulePositionsCollision();
    }, 100);
  }

  override onMouseUp = (): void => {
    this.addNewModulesInProgress = false;
    this.addModulesToExistingPositionsInProgress = false;
    this.drawPvModules();
  };

  override onMouseLeave = (): void => {
    this.addNewModulesInProgress = false;
    this.addModulesToExistingPositionsInProgress = false;
    this.resetPositions();
    this.lastPvModulePositionWithPlusSign?.removePlusSign();
  };

  dispose(): void {
    this.removePvModulePreview();
    this.updatePvModulePositionsCollision();
  }

  private init(): void {
    this.configureBaseCastObjectControl();
    const currentStage = this.workspace.currentWorkspace.stageManager?.currentStage;
    if (currentStage) {
      this.currentStage = currentStage;
    }
    this.currentStage?.setUpTool?.(ADD_MODULE_ID);
  }

  private configureBaseCastObjectControl(): void {
    const pvModulePositions: Selectable[] = this.editor.getObjectsByType(SceneObjectType.PvModulePosition, true);
    const roofFaces: RoofFace[] = this.editor.getObjectsByType(SceneObjectType.RoofFace);
    this.castObjectControl = new BaseCastObjectControl(this.editor, this.editor.viewport, this.editor.activeCamera!, [
      ...pvModulePositions,
      ...roofFaces
    ]);
    this.castObjectControl.recursiveRaycast = false;
    this.getCastedObjects = this.castObjectControl.getCastedObjects.bind(this.castObjectControl);
  }

  private addPvModulePositionToList(newPosition: PvModulePosition): void {
    if (!newPosition.hasNoPvModule()) {
      return;
    }

    const { pvModuleHoveredColor } = canvasConfig;
    newPosition.selectMultiple(pvModuleHoveredColor);
    newPosition.setHoverableMode(true);
    newPosition.setSelectedMode(true);
    const found = this.selectedPvModulePositionList.some(
      (selectedPosition: PvModulePosition): boolean => selectedPosition.serverId === newPosition.serverId
    );
    if (!found) {
      this.selectedPvModulePositionList.push(newPosition);
    }
  }

  private resetPositions(): void {
    const { pvModulePositionDefaultColor } = canvasConfig;
    this.selectedPvModulePositionList.forEach((selectedPosition: PvModulePosition): void => {
      selectedPosition.unselectMultiple(pvModulePositionDefaultColor);
      selectedPosition.setHoverableMode(false);
      selectedPosition.setSelectedMode(false);
    });
    this.selectedPvModulePositionList = [];
  }

  private drawPvModules(): void {
    const { pvModulePositionDefaultColor } = canvasConfig;
    const updatedPvModulePositionList = this.selectedPvModulePositionList.filter(
      (pvModulePosition: PvModulePosition): boolean => {
        pvModulePosition.unselectMultiple(pvModulePositionDefaultColor);
        pvModulePosition.setHoverableMode(false);
        pvModulePosition.setSelectedMode(false);

        const pvModule = new PvModule({
          id: MathUtils.generateUUID(),
          positionVertices: pvModulePosition.polygon
        });

        const wasPvModuleAdded = pvModulePosition.addPvModule(pvModule);

        if (pvModulePosition.isColliding) {
          pvModulePosition.changeMeshMaterial(pvModulePosition.disabledColor);
          pvModulePosition.pvModule?.changeMeshMaterial(pvModule.disabledColor);
        }

        return wasPvModuleAdded;
      }
    );

    const commandDependencies: IUpdatePvModulesInstancesDependencies = {
      currentStage: this.currentStage!,
      pvModulePositions: updatedPvModulePositionList,
      domain: this.domain
    };
    this.serviceBus.send('update_pv_modules', commandDependencies);
    this.selectedPvModulePositionList = [];
  }
}
