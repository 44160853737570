import type {
  PropsTheme, SimpleInterpolation
} from 'styled-components';
import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import styled, { css } from 'styled-components';
import type { Color } from '../../../../domain/typings';

const WIDTH_ICON = 60;
const HEIGHT_ICON = 54;

type HeaderContainerProps = PropsTheme & {
  color?: Color;
  isFailedStatus: boolean;
};

export const HeaderContainer = styled.div`
  width: 100%;
  height: ${HEIGHT_ICON}px;
  display: flex;
  align-items: center;
  ${({ theme }: PropsTheme): SimpleInterpolation =>
    theme.auroraMode
      ? css`
          background-color: ${theme.colors.white};
          border-bottom: 1px solid ${theme.colors.grayColor6};
        `
      : css`
          background-image: linear-gradient(to bottom, ${theme.colors.gray6}, ${theme.colors.gray3});
        `}
`;

export const HeaderIconContainer = styled(LyraLayout.Flexbox).attrs({
  align: 'center',
  justify: 'center'
})<HeaderContainerProps>`
  width: ${WIDTH_ICON}px !important;
  height: ${HEIGHT_ICON}px;
  padding: 0 4px;
  background-color: ${({
    theme, isFailedStatus
  }: HeaderContainerProps): Color =>
    isFailedStatus ? theme.colors.error : theme.colors.main1};
`;

export const HeaderTitleContainer = styled.div`
  padding-left: 12px;
`;

export const HeaderTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1px;
`;

export const SubHeaderTitle = styled.span`
  font-size: 12px;
  line-height: 1.33;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${HEIGHT_ICON}px;
  box-sizing: border-box;
  align-items: inherit;
  justify-content: center;
`;

export const StatusText = styled.span`
  color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  font-size: 7px;
  padding-bottom: 5px;
`;
