import type {
  BufferGeometry, Material
} from 'three';
import { Line } from 'three';

class CustomLine extends Line {
  constructor(geometry?: BufferGeometry | undefined, material?: Material | Material[] | undefined) {
    super(geometry, material);
    this.frustumCulled = false;
  }
}

export { CustomLine as Line };
