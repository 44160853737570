import React, {
  useRef, useEffect, useState, useCallback
} from 'react';
import { observer } from 'mobx-react-lite';
import { Col } from 'react-flexbox-grid';
import { Address } from '../../../../../domain/models/SiteDesign/Address';
import type { IAddressData } from '../../../../../domain/models/SiteDesign/Address';
import useStore from '../../../../../stores/useStore';
import type { PermitPackageViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import {
  OptionContainer,
  OptionView,
  OptionViewFluid,
  PrimaryText,
  TextFieldStyled,
  GridContainer,
  GridRow,
  PrimaryTextAlignedTop,
  Message
} from '../styles';
import {
  useGoogleMap, usePlaces
} from '../../../../../utils/maps';

function EngineerOfRecord() {
  const { modal } = useStore();
  const permitPackageViewModel = modal.viewModel as PermitPackageViewModel;
  const { engineerOfRecord } = permitPackageViewModel.documentGenerationOptions;
  const [address, setAddressObject] = useState<IAddressData>(engineerOfRecord?.address || ({} as IAddressData));
  const [addressFormatted, setAddressFormatted] = useState(engineerOfRecord.address.addressOne ?? '');

  // Instance of google maps variables using custom hooks
  const googleMap = useGoogleMap();
  const addressRef = useRef({} as HTMLInputElement);
  const [addressFieldRef, setAddressFieldRef] = useState({} as HTMLInputElement);

  useEffect(() => {
    setAddressFieldRef(addressRef.current);
  }, []);

  // Set address fields after user selected value from autocomplete results list
  useEffect(() => {
    Object.keys(address).forEach((key: string): void => {
      const fieldValue = address[key as keyof IAddressData] ?? '';
      const fieldKey = `address.${key}`;
      permitPackageViewModel.onHandleEngineerOfRecordFormFieldChange(fieldKey, fieldValue);
    });
  }, [address, permitPackageViewModel]);

  // Once user selected something from autocomplete list,
  // we set full formatted address and address object to local state.
  // Then later we track changes of that state, to fill mobx store.
  const onPlaceChanged = useCallback((autocomplete: google.maps.places.Autocomplete): void => {
    const place = autocomplete.getPlace();

    const addressComponents = place.address_components as google.maps.GeocoderAddressComponent[];
    const addressData = Address.fromGoogleMapsAddressComponents(addressComponents);
    const addressObject = addressData.toData();

    setAddressFormatted(addressObject.addressOne);
    setAddressObject(addressObject);
  }, []);

  // All fields trigger same viewModel method, except `addressOne` field.
  // That field is exclusive case, because of integration with google places autocomplete.
  const onFieldValueChange = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      const name = event.currentTarget.name;

      permitPackageViewModel.onHandleEngineerOfRecordFormFieldChange(name, value);
    },
    [permitPackageViewModel]
  );

  // Custom-made react hook for google places api integration
  usePlaces(googleMap, addressFieldRef, onPlaceChanged, {
    componentRestrictions: {
      country: ['us', 'pr']
    }
  });

  return (
    <OptionContainer>
      <Message>
        Please pay attention that <strong>ALL</strong> fields of this form are required, if you want Engineer of Record
        information in title block of Permit Package document.
      </Message>

      <OptionView>
        <PrimaryText>Engineer name</PrimaryText>
        <TextFieldStyled
          value={engineerOfRecord.name}
          placeholder="Enter full name"
          name="name"
          hasError={!(engineerOfRecord.name.length < 21)}
          onKeyUp={onFieldValueChange}
        />
      </OptionView>

      <OptionView>
        <PrimaryText>Engineer license number</PrimaryText>
        <TextFieldStyled
          value={engineerOfRecord.licenseNumber}
          placeholder="Enter license number"
          name="licenseNumber"
          onKeyUp={onFieldValueChange}
        />
      </OptionView>

      <OptionViewFluid>
        <PrimaryTextAlignedTop>Engineer address</PrimaryTextAlignedTop>
        <GridContainer fluid>
          <GridRow>
            <Col xs>
              <TextFieldStyled
                value={addressFormatted}
                placeholder="Address 1"
                inputRef={addressRef}
                name="address.addressOne"
              />
            </Col>
          </GridRow>
          <GridRow>
            <Col xs>
              <TextFieldStyled
                value={address.addressTwo}
                placeholder="Address 2 (optional)"
                name="address.addressTwo"
                onKeyUp={onFieldValueChange}
              />
            </Col>
          </GridRow>
          <GridRow>
            <Col xs={4}>
              <TextFieldStyled
                value={address.city}
                placeholder="City"
                name="address.city"
                onKeyUp={onFieldValueChange}
              />
            </Col>
            <Col xs={4}>
              <TextFieldStyled
                value={address.province}
                placeholder="State"
                name="address.province"
                onKeyUp={onFieldValueChange}
              />
            </Col>
            <Col xs={4}>
              <TextFieldStyled
                value={address.postalCode}
                placeholder="ZIP Code"
                name="address.postalCode"
                onKeyUp={onFieldValueChange}
              />
            </Col>
          </GridRow>
        </GridContainer>
      </OptionViewFluid>

      <OptionView>
        <PrimaryText>Engineer phone number</PrimaryText>
        <TextFieldStyled
          value={engineerOfRecord.phoneNumber}
          placeholder="Ex: (555) 555-5555"
          name="phoneNumber"
          onKeyUp={onFieldValueChange}
        />
      </OptionView>
    </OptionContainer>
  );
}

export default observer(EngineerOfRecord);
