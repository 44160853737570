import type EditorStore from '../../stores/EditorStore/EditorStore';
import { SelectionControl } from '../../stores/EditorStore/Controls/SelectionControl';
import type { IHandleSelectionTool } from '../../stores/UiStore/ToolbarStore/Tool';
import { pushUniqueItem } from '../../utils/helpers';
import type { Selectable } from '../mixins/Selectable';

export class SelectionBehaviour {
  private readonly selectionControl: SelectionControl;

  constructor(editor: EditorStore) {
    this.selectionControl = SelectionControl.getInstance(editor, editor.viewport, editor.activeCamera);
  }

  addSelectionChangeEvent(instance: IHandleSelectionTool): void {
    this.selectionControl.activate();
    this.selectionControl.addEventListener('selection_change', instance.onSelectionChange);
  }

  removeSelectionChangeEvent(instance: IHandleSelectionTool, recursive: boolean = false): void {
    this.selectionControl.removeEventListener('selection_change', instance.onSelectionChange);
    this.selectionControl.deactivate();
    this.setTargetObjects(undefined, recursive);
  }

  setTargetObjects(objects: Selectable[] | undefined, recursiveRaycast: boolean = true): void {
    this.selectionControl.setTargetObjects(objects, recursiveRaycast);
  }

  unselectAllObjects(): void {
    this.selectionControl.unselectAll();
  }

  setSelectedObjects(selection: Selectable[]): void {
    this.selectionControl.setSelectedObjects(selection);
  }

  filterAndUpdateTargetObjects(filterFn: (object: Selectable) => boolean): void {
    this.selectionControl.filterAndUpdateTargetObjects(filterFn);
  }

  addToSelectedItems(items: Selectable[]): void {
    for (let item of items) {
      pushUniqueItem<Selectable>(this.selectionControl.selectedItems, item);
    }
  }

  ignoreMouseUp(item: Selectable): void {
    pushUniqueItem<Selectable>(this.selectionControl.ignoreMouseUpForItems, item);
  }

  clearIgnoreMouseUp(): void {
    this.selectionControl.ignoreMouseUpForItems = [];
  }

  clearSelection(): void {
    this.selectionControl.clearSelection();
  }
}
