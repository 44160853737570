import { action } from 'mobx';
import { Vector3 } from 'three';
import type { IProgressStepperStage } from '../IProgressStepperStage';
import { DesignStep } from '../../models/Design/DesignState';
import {
  getRootStore, getUiStore
} from '../../../stores/RootStoreInversion';
import { PageId } from '../../../stores/UiStore/Pages/PagesStore';
import { CircuitTableViewModel } from '../../../ui/containers/Pages/viewModels/CircuitTable/CircuitTableViewModel';
import { handleApiError } from '../../../utils/helpers';
import type { IUpdatedSiteFeatureLocationData } from '../../entities/SitePlan/UpdatedSitePlan';
import type { IElectricalEquipmentData } from '../../entities/SitePlan/SitePlan';
import { DocumentsService } from '../../../infrastructure/services/api/DocumentsService';
import config, { UI_MODE } from '../../../config/config';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { Marker } from '../../models/SiteDesign/Marker';

export class CircuitTableStage implements IProgressStepperStage {
  readonly title: string = `Circuit${config.featureFlag.uiMode !== UI_MODE.AURORA ? ' Table' : 's'}`;
  readonly id: DesignStep = DesignStep.CIRCUIT_TABLE;

  private readonly documentsService = new DocumentsService();

  // Stage lifecycle methods:

  @action.bound
  setUp = (): void => {
    const { pages } = getUiStore();

    pages.isLoading = true;
    pages.openPage(PageId.CircuitTable);
    // create view model
    this.createCircuitTableViewModel()
      .then((viewModel: CircuitTableViewModel) => {
        pages.pageViewModel = viewModel;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Circuit table view model initialization error: ', error);
      })
      .finally(() => {
        pages.isLoading = false;
      });
  };

  async createCircuitTableViewModel(): Promise<CircuitTableViewModel> {
    const {
      workspace, modal
    } = getUiStore();
    const {
      domain, editor
    } = getRootStore();
    const designWorkspace = workspace.currentWorkspace as DesignWorkspace;
    const designId = domain.design.id;

    const sitePlanResponse = await this.documentsService
      .getSitePlan(designId)
      .catch(handleApiError('Failed to get site plan'));

    const placedElectricalEquipment: IUpdatedSiteFeatureLocationData[] = [];
    sitePlanResponse.electricalEquipment.forEach((item: IElectricalEquipmentData): void => {
      if (item.location) {
        placedElectricalEquipment.push({
          id: item.id,
          exposure: item.exposure,
          location: new Vector3(
            item.location.x,
            item.location.y,
            item.location.z ?? Marker.defaultEquipmentZValueInWorldUnits()
          )
        });
      }
    });

    if (placedElectricalEquipment.length === 0) {
      throw new Error('Equipment is not placed');
    }
    // When (if?) BE accepts circuit table design step, call updateUserState/updateDataState here
    const circuitConnectionsData = await this.documentsService
      .getCircuitConnections(designId)
      .catch(handleApiError('Failed to get circuit connections'));
    const populateForm = {
      circuitConnections: circuitConnectionsData.circuitConnections,
      circuitVoltageDrops: [...circuitConnectionsData.circuitVoltageDrops]
    };

    return new CircuitTableViewModel({
      domain,
      designWorkspace,
      modal,
      designId: domain.design.id,
      data: populateForm,
      editor
    });
  }

  resume = (lastValidStage: string): void => {
    const isLastValidStage: boolean = lastValidStage === this.id;
    if (isLastValidStage) {
      this.setUp();
    }
  };

  cancel = (): void => {
    this.dispose();
  };

  dispose = (): void => {
    const { pages } = getUiStore();
    pages.closePage();
  };

  get canContinue(): boolean {
    return true;
  }

  continue = (): void => {
    this.dispose();
  };

  // End of stage lifecycle methods
}
