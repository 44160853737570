import React, { BaseProps, ReactElement } from 'react';
import {
  GalleryWrapper,
  GalleryHeader,
  GalleryHeaderTile,
  GalleryHeaderDescription,
  GalleryContent,
  GalleryItem,
  GalleryItemImage,
  GalleryItemTitle,
  GalleryItemDescription,
  CommentItem,
  ContentView,
  ContentText,
  CheckIcon
} from './styles';

export type GalleryItemProps = {
  id: string | number;
  image: string;
  name: string;
  description: string;
  selected: boolean;
};

export type GalleryProps = BaseProps & {
  items: GalleryItemProps[];
};

const Gallery = ({
  className,
  items,
}: GalleryProps): ReactElement => {

  return (
    <GalleryWrapper className={className}>
      <GalleryHeader>
        <GalleryHeaderTile>
          Sites Images
        </GalleryHeaderTile>
        <GalleryHeaderDescription>
          (check/uncheck images to include them to Permit Package)
        </GalleryHeaderDescription>
      </GalleryHeader>
      <GalleryContent>
        {items.map(
          (item: GalleryItemProps, index: number): ReactElement => {
            return (
              <GalleryItem
                key={index}
                md={3}
                sm={6}
              >
                {item.selected && <CheckIcon name="image-selection-check" />}
                <GalleryItemImage src={item.image} selected={item.selected} />
                <GalleryItemTitle>
                  {item.name}
                </GalleryItemTitle>
                <GalleryItemDescription>
                  {item.description}
                </GalleryItemDescription>
              </GalleryItem>
            );
          }
        )}
        <CommentItem md={3} sm={6}>
          <ContentView>
            <ContentText>
              Upload site images in Site Definition under roof face and/or
              MSP properties if this project requires additional site imagery.
            </ContentText>
          </ContentView>
        </CommentItem>
      </GalleryContent>
    </GalleryWrapper>
  );
};

export { Gallery };
