import {
  DoubleSide, MeshBasicMaterial, Vector3
} from 'three';
import type { Color } from '../typings';
import { SceneObjectType } from '../models/Constants';
import { Unzoomable } from '../mixins/Unzoomable';
import { Drawable } from '../mixins/Drawable';
import { getLyraModelByMesh } from '../sceneObjectsWithLyraModelsHelpers';
import { PolygonBufferGeometry } from './PolygonBufferGeometry';

const MixedClass = Unzoomable(Drawable(class SimpleClass {}));
export class Arrow extends MixedClass {
  propertyId: string = SceneObjectType.Arrow;

  /** Layer Number */
  layerNumber: number = 1500;

  constructor() {
    super();
    this.mesh.geometry = new PolygonBufferGeometry();

    const vertices: Vector3[] = [
      new Vector3(0, 16, 0),
      new Vector3(-6, 0, 0),
      new Vector3(-1, 0, 0),
      new Vector3(-1, -10, 0),
      new Vector3(1, -10, 0),
      new Vector3(1, 0, 0),
      new Vector3(6, 0, 0),
      new Vector3(0, 16, 0)
    ];

    (this.mesh.geometry as PolygonBufferGeometry).update(vertices);

    this.mesh.position.setZ(this.layerNumber);
  }

  unzoom(factor: number): void {
    if (!this.hasUnzoomableParent()) {
      this.mesh.scale.set(factor * this.mapZoomFactor, factor * this.mapZoomFactor, factor * this.mapZoomFactor);
    }
  }

  // TODO: this doesn't seem to be right at all
  setColor(color: Color): void {
    if (color) {
      this.mesh.material = new MeshBasicMaterial({
        color,
        side: DoubleSide
      });

      this.mesh.material.dispose();
    }
  }

  setVisible(visible: boolean): void {
    this.visible = visible;
  }

  redraw(): void {
    // Not Implementing yet
  }

  private hasUnzoomableParent(): boolean {
    let parent = this.mesh.parent;

    while (parent) {
      if (getLyraModelByMesh<Unzoomable>(parent).isUnzoomable) {
        return true;
      }
      parent = parent.parent;
    }

    return false;
  }
}
