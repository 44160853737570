import type EGuideIdentifier from '../../../stores/UiStore/SmartGuidesStore/EGuideIdentifier';
import * as Storage from '../WebStorage';

const GUIDE_STORAGE_KEY = 'smartguides';

export interface IGuideStorage {
  enables: EGuideIdentifier[];
}

function update(data: IGuideStorage): void {
  Storage.set<IGuideStorage>(GUIDE_STORAGE_KEY, data);
}

export function load(): IGuideStorage {
  /** Default data */
  let data: IGuideStorage = {
    enables: []
  };
  try {
    data = Storage.get<IGuideStorage>(GUIDE_STORAGE_KEY);
  } catch (_) {
    /** First time when local storage is called, create values by defaults */
    Storage.set(GUIDE_STORAGE_KEY, data);
  }

  return data;
}

export function save(guideId: EGuideIdentifier): void {
  const data = load();

  if (data !== null) {
    const exist: boolean = data.enables.some((value: EGuideIdentifier): boolean => value === guideId);
    if (!exist) {
      data.enables.push(guideId);
      update(data);
    }
  }
}

export function remove(guideId: EGuideIdentifier): void {
  const data = load();

  if (data !== null) {
    const index = data.enables.indexOf(guideId);
    data.enables.splice(index, 1);
    update(data);
  }
}
