import type {
  RJSFSchema, UiSchema
} from '@rjsf/utils/lib/types';
import type {
  JSONSchema7, JSONSchema7TypeName, JSONSchema7Type
} from 'json-schema';
import { encodeFieldName } from '../fieldNameUtils';
import type {
  FieldType,
  DataSchema, FormField, FormFieldProp, FormGroup
} from '../FormOptionsRulesAndState';

type InterpretedSchemas = {
  formSchema: RJSFSchema;
  uiSchema: UiSchema
}

/**
 * Preprocesses apiSchema fields - encodes field IDs and updates field types where needed.
 * @param {*} apiSchema
 */
const preprocessApiSchema = (apiSchema: DataSchema): void => {
  apiSchema.fields = apiSchema.fields.map((field: FormField) => {
    field.id = encodeFieldName(field.id);
    field.type = mapFieldType(field.type) || 'string';
    return field;
  });
};

const mapFieldType = (fieldType: FieldType | FieldType[]): JSONSchema7TypeName | JSONSchema7TypeName[] => {
  if (Array.isArray(fieldType)) {
    return fieldType.flatMap(mapFieldType);
  }
  switch (fieldType) {
    case 'long':
      return 'integer';
    case 'double':
      return 'number';
    default:
      return fieldType;
  }
};

export const ATTRIBUTE_DELIMITER = '$';
export const ATTRIBUTE_KEY_VALUE_DELIMITER = ': ';
export const DEFAULT_GROUP = 'ungrouped';

const parseGroupAttributes = (attributes: {[key: string] : string}): string => {
  return Object.entries(attributes)
    .map(attributePair => attributePair.join(ATTRIBUTE_KEY_VALUE_DELIMITER))
    .join(ATTRIBUTE_DELIMITER);
};

/**
 * Converts our custom schema specification (as received from API) to one expected by
 * react-jsonschema-form library.
 */
export default (
  apiSchema: DataSchema,
  initialDataWithEncodedFieldNames: { [key: string]: JSONSchema7Type },
): InterpretedSchemas => {
  preprocessApiSchema(apiSchema);
  const interpretedSchema: RJSFSchema = {
    type: 'object',
    required: [],
    properties: {}
  };
  const uiSchema: UiSchema = {
    'ui:order': ['*']
  };

  interpretedSchema.properties![DEFAULT_GROUP] = {
    required: [],
    properties: {},
    type: 'object'
  };
  uiSchema[DEFAULT_GROUP] = {};

  apiSchema.groups.forEach((group: FormGroup) => {
    interpretedSchema.properties![group.id] = {
      title: group.name,
      required: [],
      properties: {},
      type: 'object',
    };
    if (group.attributes) {
      (interpretedSchema.properties![group.id] as JSONSchema7).description = parseGroupAttributes(group.attributes);
    }
    uiSchema[group.id] = {};
  });
  for (const field of apiSchema.fields) {
    const prop: FormFieldProp = {
      title: field.title,
      type: field.type,
    };

    if (field.group) {
      (interpretedSchema.properties![field.group] as JSONSchema7).properties![field.id] = prop;
    } else {
      interpretedSchema.properties![DEFAULT_GROUP].properties![field.id] = prop;
      uiSchema['ui:order']!.unshift(field.id);
    }

    const interpretedSchemaField = interpretedSchema.properties![field.group ?? DEFAULT_GROUP] as JSONSchema7;
    let uiSchemaField: UiSchema = uiSchema[field.group ?? DEFAULT_GROUP];
    if (Array.isArray(field.type) && field.type.includes('null')) {
      if (field.type[0] === 'integer' || field.type[0] === 'string') {
        uiSchemaField[field.id] = {
          'ui:widget': 'customSelect',
          'ui:emptyValue': null
        };
      }
    } else if (field.type === 'boolean') {
      prop.enum = [true, false];
      uiSchemaField[field.id] = {
        'ui:widget': 'CheckboxWidget'
      };
    } else if (hasValues(field)) {
      uiSchemaField[field.id] = {
        'ui:widget': 'customSelect'
      };
    } else if (isNumeric(field)) {
      if (field.required) {
        interpretedSchemaField.required!.push(field.id);
      }
    } else if (isTextual(field)) {
      if (field.required) {
        interpretedSchemaField.required!.push(field.id);
      }
      if (field.disabled) {
        prop.disabled = true;
        uiSchemaField[field.id] = {
          'ui:disabled': true
        };
      } else if (field.readOnly) {
        prop.readOnly = true;
        uiSchemaField[field.id] = {
          'ui:readonly': true
        };
      }
    }

    if ('helpText' in field) {
      uiSchemaField[field.id] = {
        ...uiSchemaField[field.id],
        'ui:help': field.helpText
      };
    }

    uiSchemaField[field.id] = {
      ...uiSchemaField[field.id],
      'ui:classNames': field.group ? 'hideable' : 'hidden'
    };

    if ('values' in field) {
      prop.enum = [];
      prop.enumNames = [];
      prop.enumDescriptions = [];
      prop.enumTags = [];
      for (const v of (field.values ?? [])) {
        prop.enum.push(v.value === 'NULL' ? null : v.value);
        prop.enumNames.push(v.name ?? '');
        prop.enumDescriptions.push(v.desc ?? null);
        prop.enumTags.push(v.tags ?? []);
      }
    }

    // We leverage the `default` attribute supported by react-jsonschema-form to restore field value to default
    const initialFieldValue = initialDataWithEncodedFieldNames[field.id] || prop.enum?.[0]; // For enum fields with no default values, assume the first option is the default.
    if (initialFieldValue !== undefined) {
      prop.default = initialFieldValue;
    }
  }

  return {
    formSchema: interpretedSchema,
    uiSchema
  };
};

const isTextual = (field: FormField): boolean => field.type === 'string';
const isNumeric = (field: FormField): boolean => field.type === 'integer' || field.type === 'number';
const hasValues = (field: FormField): boolean => !!field.values?.length;
