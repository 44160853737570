import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import useStore, { useUiStore } from '../../../stores/useStore';
import { FramingRoofFaceViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/FramingRoofFaceViewModel';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';
import IconMapper from '../../components/Icons';
import {
  RoofPropertyButton, RoofPropertyButtonWrapper
} from './styles';

const FramingRoofProperties: React.FC = (): ReactElement => {
  const {
    domain, editor, serviceBus
  } = useStore();
  const { roofFaceProps } = useUiStore();
  const { firstRoofFaceSelected } = roofFaceProps;
  const [viewModel, setViewModel] = useState<FramingRoofFaceViewModel>();

  const onEditClick = useCallback((): void => {
    if (viewModel) {
      roofFaceProps.changeShowPropertiesOf(viewModel);
    }
  }, [viewModel, roofFaceProps]);

  useEffect((): void => {
    const roofFaceEditor = roofFaceProps.createRoofFacePropertyEditor(FramingRoofFaceViewModel, {
      serviceBus,
      editor,
      roofFaceProps,
      domain,
      roofFace: firstRoofFaceSelected!
    });

    setViewModel(roofFaceEditor);
  }, [firstRoofFaceSelected, domain, editor, roofFaceProps, serviceBus]);

  return (
    <>
      {viewModel?.currentValue && (
        <RoofPropertyEdition title="Framing" onEditClick={onEditClick}>
          {viewModel.constructionType && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                CONSTRUCTION TYPE:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.constructionType.attributes.name}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.trussCrossDimensions && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                {`${viewModel.topChordLabel}:`}
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {`${viewModel.trussCrossDimensions.attributes.name}
                ${viewModel.yearOfConstructionDesc}`}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.trussSpacing && (
            <LyraTypography.Heading margin={'0px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                {`${viewModel.spacingLabel}:`}
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.spacingValueText}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
        </RoofPropertyEdition>
      )}

      {viewModel && !viewModel.currentValue && (
        <RoofPropertyButtonWrapper>
          <RoofPropertyButton onClick={onEditClick}>
            <IconMapper name="roof-framing" />
            Define Roof Framing
          </RoofPropertyButton>
        </RoofPropertyButtonWrapper>
      )}
    </>
  );
};

export default observer(FramingRoofProperties);
