import { BaseProps } from 'react';
import { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { EThemeComponentColor } from './EThemeComponentColor';

export type ColorThemeProps = BaseProps & PropsTheme & {
  colorTheme: EThemeComponentColor;
};

export function getColorTheme({ colorTheme, theme }: ColorThemeProps): Color {
  const themeColors = {
    blue: theme.colors.mainColor1,
    purple: theme.colors.mainColor2,
    aquamarine: theme.colors.mainColor3,
    red: theme.colors.mainColor4,
    gray: theme.colors.grayColor7,
    gray_100: theme.colors.grayColor8,
    gray_200: theme.colors.lineHr,
    gray_300: theme.colors.grayColor6,
    gray_400: theme.colors.grayColor4,
    gray_500: theme.colors.grayColor3,
    gray_600: theme.colors.grayColor2,
    gray_700: theme.colors.grayColor9,
    gray_800: theme.colors.grayColor1,
    gray_900: theme.colors.grayColor5,
    white: theme.colors.whiteBg,
    black: theme.colors.mainColor6,
    transparent: theme.colors.transparent
  };

  return themeColors[colorTheme] ? themeColors[colorTheme] : theme.colors.mainColor1;
}

  /**
   *  @returns retorna el color opuesto al tema
   */
export function getFontColorDependingOnTheme(
  {colorTheme, theme}: ColorThemeProps): Color {
  const fontColors = {
    blue: theme.colors.whiteBg,
    purple: theme.colors.whiteBg,
    aquamarine: theme.colors.whiteBg,
    red: theme.colors.whiteBg,
    gray: theme.colors.mainColor6,
    gray_100: theme.colors.mainColor6,
    gray_200: theme.colors.mainColor6,
    gray_300: theme.colors.mainColor6,
    gray_400: theme.colors.mainColor6,
    gray_500: theme.colors.mainColor6,
    gray_600: theme.colors.mainColor6,
    gray_700: theme.colors.whiteBg,
    gray_800: theme.colors.whiteBg,
    gray_900: theme.colors.whiteBg,
    white: theme.colors.mainColor6,
    black: theme.colors.whiteBg,
    transparent: theme.colors.mainColor6
  };

  return fontColors[colorTheme] ? fontColors[colorTheme] : theme.colors.mainColor1;
}

export function getFontPropDependingOnTheme(
  colorTheme: EThemeComponentColor): EThemeComponentColor {
  const fontColors = {
      blue: EThemeComponentColor.WHITE,
      purple: EThemeComponentColor.WHITE,
      aquamarine: EThemeComponentColor.WHITE,
      red: EThemeComponentColor.WHITE,
      gray: EThemeComponentColor.BLACK,
      gray_100: EThemeComponentColor.BLACK,
      gray_200: EThemeComponentColor.BLACK,
      gray_300: EThemeComponentColor.BLACK,
      gray_400: EThemeComponentColor.BLACK,
      gray_500: EThemeComponentColor.BLACK,
      gray_600: EThemeComponentColor.BLACK,
      gray_800: EThemeComponentColor.WHITE,
      gray_900: EThemeComponentColor.WHITE,
      gray_700: EThemeComponentColor.WHITE,
      white: EThemeComponentColor.BLACK,
      black: EThemeComponentColor.WHITE,
      transparent: EThemeComponentColor.BLACK
    };
  return fontColors[colorTheme] ? fontColors[colorTheme] : EThemeComponentColor.WHITE;
}
