import React, { BaseProps, ReactElement, useState } from 'react';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { Flexbox } from '../Layout/Flexbox';

type Props = BaseProps & {
  order?: string;
  active?: boolean;
};

const TabBarStyled = styled.div`
  display: flex;
`;

const TabStyled = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})<Props & { onClick: () => void }>`
  width: 120px;
  height: 32px;
  border-top-left-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'first' ? '16px' : ''};
  border-bottom-left-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'first' ? '16px' : ''};
  border-top-right-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'last' ? '16px' : ''};
  border-bottom-right-radius: ${({ order }: PropsTheme<Props>): string =>
    order === 'last' ? '16px' : ''};
  background: ${({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.grayColor1 : theme.colors.grayColor5};
  cursor: pointer;
`;

const TabText = styled.span<Props>`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme, active }: PropsTheme<Props>): Color =>
    active ? theme.colors.highlight : theme.colors.mainColor6};
`;

interface ITabsProps {
  active?: boolean;
  label?: string;
  order?: string;
  messageId?: string;
  count?: number;
}

export interface IProps extends BaseProps {
  tabItems: ITabsProps[];
  handleClick: (index: number) => void;
  className?: string;
}

const TabBar = ({ className, tabItems, handleClick }: IProps): ReactElement => {
  const activeTabIndex = tabItems.findIndex(
    (i: ITabsProps, index: number): number | undefined => {
      if (i.active === true) return index;
  });
  const [tabIndex, setTabState] = useState(activeTabIndex < 0 ? 0 : activeTabIndex);

  function changeTab(index: number): void {
    setTabState(index);
    handleClick(index);
  }

  return (
    <TabBarStyled className={className}>
      {tabItems.map(
        (tab: ITabsProps, index: number): ReactElement => {
          return (
            <TabStyled
              dir="row"
              key={index}
              order={tab.order}
              active={tabIndex === index}
              onClick={(): void => changeTab(index)}
              data-testid={`tab-${index}`}
            >
              <TabText active={tabIndex === index}>
                {tab.label} {tab.count !== undefined && `(${tab.count})`}
              </TabText>
            </TabStyled>
          );
        }
      )}
    </TabBarStyled>
  );
};

export { TabBar };
