import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '@aurorasolar/lyra-ui-kit';
import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';

const { H4 } = LyraFontFamily.FontFamily;

type Props = PropsTheme & {
  visible?: boolean;
};

export const StorageSolutionContainer = styled.div<Props>`
  display: ${({ visible }: Props): string => (visible ? 'block' : 'none')};
  margin-top: 20px;
`;

export const OptionHeader = styled(H4)`
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
`;

export const OptionDescription = styled.div`
  font-size: 10px;
  font-weight: normal;
  margin: 5px;
`;

export const OptionContainer = styled.div`
  text-align: left;
  width: 100%;
  position: relative;
`;

export const NotCompatibleError = styled.div`
  &:before {
    content: 'Not compatible';
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.07;
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.error};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor5}40;
  white-space: nowrap;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
`;
