import type {
  FormProps, IChangeEvent
} from '@rjsf/core';
import type Form from '@rjsf/core';
import type {
  RJSFSchema, UiSchema
} from '@rjsf/utils';
import React, { Component } from 'react';
// @ts-ignore
import rulesRunner from 'react-jsonschema-form-conditionals/lib/rulesRunner';
import type { Engine } from '../engine';
import type { EngineRule } from '../engine/Engine';
import type { FormData } from '../FormOptionsRulesAndState';

type RulesRunnerConfiguration = {
  readonly schema: RJSFSchema;
  readonly uiSchema: UiSchema;
  readonly formData: FormData;
}

export default function applyRules(
  schema: RJSFSchema,
  uiSchema: UiSchema,
  rules: EngineRule[],
  Engine: Engine,
  extraActions = {}
) {
  const runRules = rulesRunner(
    schema, uiSchema, rules, Engine, extraActions
  );

  return (FormComponent: typeof Form) => {
    class FormWithConditionals extends Component<FormProps> {
      constructor(props: FormProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.updateConf = this.updateConf.bind(this);
        let { formData = {} } = this.props;

        this.state = {
          schema,
          uiSchema,
          formData
        };
        this.updateConf(formData);
      }

      override componentDidUpdate(prevProps: Readonly<FormProps>): void {
        if (this.props !== prevProps) {
          this.updateConf(this.props.formData);
        }
      }

      updateConf(formData: FormData) {
        return runRules(formData).then((conf: RulesRunnerConfiguration) => {
          const newConf = JSON.stringify(conf);
          const oldConf = JSON.stringify(this.state);

          if (newConf !== oldConf) {
            this.setState(conf);
          }

          return conf;
        });
      }

      handleChange(change: IChangeEvent) {
        let { formData } = change;
        let updTask = this.updateConf(formData);

        let { onChange } = this.props;
        if (onChange) {
          updTask.then((conf: RulesRunnerConfiguration) => {
            let updChange = {
              ...change,
              ...conf
            };
            onChange!(updChange);
          });
        }
      }

      override render() {
        // Assignment order is important
        let formConf = {
          ...this.props,
          ...this.state,
          onChange: this.handleChange
        };

        return <FormComponent {...formConf} />;
      }
    }

    return FormWithConditionals;
  };
}
