import { SceneObjectType } from '../Constants';
import { canvasConfig } from '../../../config/canvasConfig';
import { LoadCenter } from './SiteEquipment/LoadCenter';
import type { IElectricalPanelData } from 'domain/entities/SiteDesign/ElectricalPanelInstance';
import type { ISiteEquipmentData } from 'domain/entities/SiteDesign/SiteEquipment';

export class Subpanel extends LoadCenter {
  override readonly type: string = SceneObjectType.Subpanel;
  override readonly propertyId: string = SceneObjectType.Subpanel;
  // @ts-ignore
  override mainBreakerRating: number | undefined = undefined;
  override readonly isServiceEntranceEquipment: boolean = false;
  constructor() {
    super({
      sceneObjectType: SceneObjectType.Subpanel,
      color: canvasConfig.subpanelIconColor,
      meterSocket: false
    });
    this.label = 'SP';
    this.name = 'Subpanel';
  }

  fromData(data: IElectricalPanelData): void {
    super.enrichWithData(data);
  }

  override toData(): ISiteEquipmentData['subpanel'] {
    return super.toData();
  }
}
