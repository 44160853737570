import { SiteStructureFactory } from '../../../../../../domain/models/StructureFactory';
import type Store from '../../../../../../stores/Store';
import type { IBaseProtrusionToolDependencies } from '../../../../../../stores/UiStore/ToolbarStore/Project/ProtrusionTools/ProtrusionTool';
import { RectangularProtrusionTool } from '../../../../../../stores/UiStore/ToolbarStore/Project/ProtrusionTools/RectangularTool';
import withTool from '../../../Shared/withTool';

type ConstDep = Pick<
  IBaseProtrusionToolDependencies,
  'properties' | 'domain' | 'drawableObjectsFactory' | 'roofProtrusion' | 'serviceBus'
>;

const RectangularProtrusion = withTool(
  RectangularProtrusionTool,
  (store: Store): ConstDep => ({
    properties: store.properties,
    domain: store.domain,
    drawableObjectsFactory: new SiteStructureFactory(),
    roofProtrusion: store.roofProtrusion,
    serviceBus: store.serviceBus
  })
);

export default RectangularProtrusion;
