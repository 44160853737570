import {
  LyraLayout, LyraModal, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type {
  EffectCallback, ReactElement
} from 'react';
import React, {
  useEffect, useState
} from 'react';

import useStore from '../../../stores/useStore';
import type { IConfirmableCommand } from '../../../stores/ServiceBus/Commands/Command';
import type { CommandNeedsConfirmationEvent } from '../../../stores/ServiceBus/ServiceBus';

export const ConfirmCommandModal = (): ReactElement => {
  const {
    editor, serviceBus
  } = useStore();
  const [command, setCommand] = useState<IConfirmableCommand>();

  useEffect((): ReturnType<EffectCallback> => {
    const callback = (event: CommandNeedsConfirmationEvent): void => setCommand(event.command);
    serviceBus.addEventListener('commandNeedsConfirmation', callback);
    return (): void => serviceBus.removeEventListener('commandNeedsConfirmation', callback);
  }, [serviceBus]);

  return (
    <>
      {command && (
        <LyraModal.Modal
          $show={true}
          showHeaderDivider={false}
          showCloseButton={false}
          title="Warning"
          leftButtonLabel="No"
          rightButtonLabel="Yes"
          onClickLeftButton={(): void => {
            (
              serviceBus.send('command', {
                editor
              }) as Generator<string, string, boolean>
            ).next(false);
            setCommand(undefined);
          }}
          onClickRightButton={(): void => {
            (
              serviceBus.send('command', {
                editor
              }) as Generator<string, string, boolean>
            ).next(true);
            setCommand(undefined);
          }}
        >
          <LyraLayout.Flexbox justify="center">
            <LyraTypography.Paragraph>{command.confirmationModalText}</LyraTypography.Paragraph>
          </LyraLayout.Flexbox>
        </LyraModal.Modal>
      )}
    </>
  );
};
