import isNil from 'lodash/isNil';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  action, computed, observable, runInAction
} from 'mobx';
import {
  searchValueFromOptions, handleApiError
} from '../../../../utils/helpers';
import type EditorStore from '../../../EditorStore/EditorStore';
import type { IBaseViewModelDependencies } from '../../Modal/BaseViewModel';
import { BaseViewModel } from '../../Modal/BaseViewModel';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type { IUpdateOtherSitePropertiesDependencies } from '../../../ServiceBus/Commands/UpdateOtherSitePropertiesCommand';
import { KeyboardListener } from '../../../../utils/KeyboardListener';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../domain/behaviour/KeyboardBehaviour';
import type {
  IOption, BaseAttributes
} from '../../../../domain/models/SiteDesign/IOption';

interface ISiteOtherPropertiesViewModelDependencies extends IBaseViewModelDependencies {
  serviceBus: ServiceBus;
}

export const averagePropertyGradeOptions: OptionProps[] = [
  {
    name: 'Not Known',
    value: 'Not Known',
    component: 'Not Known'
  },
  {
    name: '0',
    value: '0',
    component: '0%'
  },
  {
    name: '5',
    value: '0.05',
    component: 'Up to 5%'
  },
  {
    name: '10',
    value: '0.1',
    component: 'Up to 10%'
  },
  {
    name: '15',
    value: '0.15',
    component: 'Up to 15%'
  },
  {
    name: '20',
    value: '0.2',
    component: 'Up to 20%'
  },
  {
    name: '25',
    value: '0.25',
    component: 'Up to 25%'
  },
  {
    name: '30',
    value: '0.3',
    component: 'Up to 30%'
  },
  {
    name: '35',
    value: '0.35',
    component: 'Up to 35%'
  },
  {
    name: '40',
    value: '0.4',
    component: 'Up to 40%'
  },
  {
    name: '45',
    value: '0.45',
    component: 'Up to 45%'
  },
  {
    name: '50',
    value: '0.5',
    component: 'Up to 50%'
  }
];

export class SiteOtherPropertiesViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI: string = 'site_other_properties_modal';
  override readonly editor: EditorStore;

  @observable
  isLoading?: boolean;

  @observable
  topographicalConditionOptions: OptionProps[] = [];

  @observable
  currentTopographicalCondition?: OptionProps;

  @observable
  currentAveragePropertyGrade?: OptionProps;

  @observable
  sds?: number;

  @observable
  elevationInFeet?: number;

  private readonly serviceBus: ServiceBus;

  constructor(dependencies: ISiteOtherPropertiesViewModelDependencies) {
    super(dependencies);
    this.serviceBus = dependencies.serviceBus;
    this.editor = dependencies.editor;
    this.setInitialValues();
    KeyboardBehaviour.addKeyboardEvents(this);
  }

  @action
  setInitialValues = (): void => {
    this.sds = this.domain.project.designParameters.seismic?.sds;
    this.elevationInFeet = this.domain.project.site.elevationInFeet;
    this.currentAveragePropertyGrade = searchValueFromOptions(
      averagePropertyGradeOptions,
      this.domain.project.designParameters.terrain.averagePropertyGradeAsOptionValue ?? ''
    );
  };

  async loadSelectionOptions(): Promise<void> {
    this.isLoading = true;
    const topographicalConditionBackendOptions = await this.designService
      .getTopographicalCondition()
      .catch(handleApiError('Failed to fetch topographical options data'));
    runInAction((): void => {
      this.topographicalConditionOptions = this.mapItemOptions(topographicalConditionBackendOptions);
      this.currentTopographicalCondition = searchValueFromOptions(
        this.topographicalConditionOptions,
        this.domain.project.designParameters.terrain.topographicalCondition ?? ''
      );
    });
    this.isLoading = false;
  }

  mapItemOptions(options: IOption<BaseAttributes>[]): OptionProps[] {
    return options.map(
      (option: IOption<BaseAttributes>): OptionProps => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  @computed
  get isValid(): boolean {
    return (
      !isNil(this.currentTopographicalCondition)
      || !isNil(this.currentAveragePropertyGrade)
      || !isNil(this.sds)
      || !isNil(this.elevationInFeet)
    );
  }

  @action
  changeTopographicalCondition = (topographicalValue: OptionProps): void => {
    this.currentTopographicalCondition = topographicalValue;
  };

  @action
  changeAverageGrade = (averageValue: OptionProps): void => {
    this.currentAveragePropertyGrade = averageValue;
  };

  @action
  changeSds = (value: number): void => {
    this.sds = value;
  };

  @action
  changeElevation = (value: number): void => {
    this.elevationInFeet = value;
  };

  @action
  save = (): void => {
    const propertyGradeAsNumber = Number(this.currentAveragePropertyGrade!.value);
    const updatedPropertyGradeValue = !Number.isNaN(propertyGradeAsNumber) ? propertyGradeAsNumber : undefined;
    const commandDependencies: IUpdateOtherSitePropertiesDependencies = {
      domain: this.domain,
      updatedTopographicalCondition: this.currentTopographicalCondition?.value,
      updatedPropertyGrade: updatedPropertyGradeValue,
      updatedElevationInFeet: this.elevationInFeet,
      updatedSds: this.sds
    };
    this.serviceBus.send('update_other_site_properties', commandDependencies);
    this.closeModal();
  };

  override dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (this.isValid) {
      if (event.key === KeyboardListener.KEY_ENTER) {
        this.save();
        this.dispose();
      }
    }
  };
}
