import { GoogleMap } from '@react-google-maps/api';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import isNil from 'lodash/isNil';
import Marker from './Marker';
import {
  Container, EmptyMapView, GoogleMapsWrapper
} from './PreviewGoogleMap.styles';

export const DEFAULT_MAP_ZOOM_LEVEL: number = 20;
export const MAX_MAP_ZOOM_LEVEL: number = 21;
export const MIN_MAP_ZOOM_LEVEL: number = 15;

type GoogleMapProps = {
  mapTypeId: string;
  fullscreenControl: boolean;
  rotateControl: boolean;
  tilt: number;
  disableDefaultUI: boolean;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  zoomControl: boolean;
};

export type PreviewGoogleMapChangeParams = {
  latitude: number;
  longitude: number;
  zoom: number;
};

type PreviewGoogleMapCoordinates = {
  lat: number;
  lng: number;
  zoom: number;
};
export type PreviewGoogleMapOptions = {
  mapVisible: boolean;
  minZoom?: number;
  maxZoom?: number;
};

export type PreviewGoogleMapProps = {
  coordinates: PreviewGoogleMapCoordinates | null;
  options: PreviewGoogleMapOptions | null;
  onChange?: (params: PreviewGoogleMapChangeParams) => void;
};

// It's important to keep all maps configs
// outside the react component, to prevent
// re-rendering for the sake of best performance.
const mapContainerStyle = {
  width: '100%',
  height: '100%'
};

function PreviewGoogleMap({
  coordinates, options, onChange
}: PreviewGoogleMapProps): ReactElement {
  const hasCoordinates = !isNil(coordinates);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const centerPosition: google.maps.LatLng | google.maps.LatLngLiteral = useMemo(
    (): google.maps.LatLng | google.maps.LatLngLiteral =>
      hasCoordinates
        ? coordinates
        : ({
          lng: 0,
          lat: 0
        } as google.maps.LatLngLiteral),
    [hasCoordinates, coordinates]
  );

  const onLoad = useCallback((map: google.maps.Map): void => {
    setMapInstance(map);
  }, []);

  const onUnmount = useCallback((): void => {
    setMapInstance(null);
  }, []);

  useEffect((): void => {
    if (coordinates && mapInstance) {
      mapInstance.setCenter(coordinates);
      mapInstance.setZoom(coordinates.zoom);
    }
  }, [coordinates, mapInstance]);

  const googleMapsVisible = !!(hasCoordinates && mapInstance !== null && options?.mapVisible);

  const handleMapChange = useCallback((): void => {
    if (!mapInstance || !onChange) {
      return;
    }

    const center = mapInstance.getCenter();
    if (center) {
      onChange({
        latitude: center.lat(),
        longitude: center.lng(),
        zoom: mapInstance.getZoom() || DEFAULT_MAP_ZOOM_LEVEL
      });
    }
  }, [mapInstance, onChange]);

  const mapOptions = useMemo(
    (): GoogleMapProps => ({
      mapTypeId: 'satellite',
      fullscreenControl: false,
      rotateControl: false,
      tilt: 0,
      disableDefaultUI: true,
      zoom: coordinates ? coordinates.zoom : DEFAULT_MAP_ZOOM_LEVEL,
      zoomControl: true,
      minZoom: options?.minZoom ?? MIN_MAP_ZOOM_LEVEL,
      maxZoom: options?.maxZoom ?? MAX_MAP_ZOOM_LEVEL
    }),
    [coordinates, options?.maxZoom, options?.minZoom]
  );

  return (
    <Container>
      <GoogleMapsWrapper visible={googleMapsVisible}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onLoad}
          onUnmount={onUnmount}
          center={centerPosition}
          options={mapOptions}
          onCenterChanged={handleMapChange}
          onZoomChanged={handleMapChange}
        >
          <Marker />
        </GoogleMap>
      </GoogleMapsWrapper>

      <EmptyMapView>
        <div
          style={{
            width: 56,
            height: 78
          }}
        />
        <div
          className="mt-xxs"
          style={{
            fontSize: 11
          }}
        >
          Enter an address to see a satellite preview here
        </div>
      </EmptyMapView>
    </Container>
  );
}

export default PreviewGoogleMap;
