class Vector2D {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  clone(): Vector2D {
    return new Vector2D(this.x, this.y);
  }

  sub(vector: Vector2D): Vector2D {
    this.x -= vector.x;
    this.y -= vector.y;

    return this;
  }

  add(vector: Vector2D): Vector2D {
    this.x += vector.x;
    this.y += vector.y;

    return this;
  }
}

export default Vector2D;
