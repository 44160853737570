import isNil from 'lodash/isNil';
import { Vector3 } from 'three';
import { SiteStructureFactory } from '../../domain/models/StructureFactory';
import type { RoofProtrusion } from '../../domain/models/SiteDesign/RoofProtrusion';
import { RoofFace as RoofFaceDTO } from '../../domain/models/SiteDesign/RoofFace';
import {
  ERoofSlopeType, ERoofType
} from '../../domain/typings';
import { inchesInMeters } from '../../constants/distances';
import type { ISiteImageData } from '../../domain/models/SiteDesign/SiteImage';
import { SiteImage } from '../../domain/models/SiteDesign/SiteImage';
import {
  convertMeterToInches, convertInchesToMeters
} from '../../utils/Convertions';
import { ROOF_COLOR_LEVEL_RANGE } from '../../domain/models/RoofColorLevelRange';
import type { IRoofFaceData } from '../../domain/entities/SiteDesign/RoofFace';
import type { IRoofFaceGeometryData } from '../../domain/entities/SiteDesign/RoofFaceGeometry';
import type { IRoofFaceStructureData } from '../../domain/entities/SiteDesign/RoofFaceStructure';
import type { IRoofProtrusionData } from '../../domain/entities/SiteDesign/RoofProtrusion';
import type { IVertexData } from '../../domain/entities/SiteDesign/Vertex';
import { VerticesMapping } from './VerticesMapping';
import { RoofProtrusionMapping } from './RoofProtrusionMapping';
import type { IMapping } from './IMapping';

interface IRoofFaceAdditions {
  level: number;
}

export class RoofFaceMapping implements IMapping<IRoofFaceData, RoofFaceDTO> {
  private readonly protrusionMapping = new RoofProtrusionMapping();
  private readonly verticesMapping = new VerticesMapping();

  convertEntityToDomainModel(entity: IRoofFaceData & IRoofFaceAdditions): RoofFaceDTO {
    const {
      geometry, level, name, id, structure
    } = entity;

    const {
      polygon, protrusions, edgeTypes, azimuth, slope, slopeType
    } = geometry;
    const drawableObjectsFactory = new SiteStructureFactory();
    const domainModel = drawableObjectsFactory.create<RoofFaceDTO>(RoofFaceDTO, {
      color: '#ffffff'
    });

    domainModel.serverId = id;
    domainModel.name = name ? name : 'RoofFace';
    domainModel.images.replace(
      entity.images?.map((imageData: ISiteImageData): SiteImage => new SiteImage(imageData)) ?? []
    );

    domainModel.setColor(ROOF_COLOR_LEVEL_RANGE[level]);

    domainModel.structure = {
      ...structure
    };
    if (structure?.surfaceType) {
      domainModel.surface = structure.surfaceType;
    }
    if (domainModel.structure.condition?.ridgeBeamSag) {
      domainModel.structure.condition.ridgeBeamSag = convertMeterToInches(domainModel.structure.condition.ridgeBeamSag);
    }
    if (domainModel.structure.slopingStructuralMembers?.maxHorizontalSpan) {
      domainModel.structure.slopingStructuralMembers.maxHorizontalSpan = convertMeterToInches(
        domainModel.structure.slopingStructuralMembers.maxHorizontalSpan
      );
    }
    if (domainModel.structure.slopingStructuralMembers?.sag) {
      domainModel.structure.slopingStructuralMembers.sag = convertMeterToInches(
        domainModel.structure.slopingStructuralMembers.sag
      );
    }

    domainModel.deckSheathingType = structure.deckSheathingType;

    polygon.forEach(({
      x, y, z
    }: IVertexData): void => {
      const coords = new Vector3(x, y, z);
      domainModel.addVertex({
        vertex: coords,
        removeIfCollinear: false,
        originatingFromTracing: false
      });
      domainModel.redraw();
    });
    domainModel.addVertex({
      vertex: new Vector3(polygon[0].x, polygon[0].y, polygon[0].z),
      removeIfCollinear: false,
      originatingFromTracing: false
    });
    domainModel.redraw();

    if (edgeTypes) {
      domainModel.setEdgeTypes(edgeTypes);
    }

    protrusions?.forEach((protrusion: IRoofProtrusionData): void => {
      // We are only supporting Rectangle protrusions for the MVP
      if (protrusion.boundingBox.length === 4) {
        const protrusionDTO = this.protrusionMapping.convertEntityToDomainModel(protrusion);
        domainModel.mesh.add(protrusionDTO.mesh);
        domainModel.protrusions.push(protrusionDTO);
      }
    });

    // This should be assigned at the end
    domainModel.slope = slope;
    if (!isNil(azimuth)) {
      domainModel.setAzimuth(azimuth);
    }
    domainModel.slopeType = slopeType;
    if (slopeType === ERoofSlopeType.LowSlope && slope === 0) {
      domainModel.roofType = ERoofType.FLAT;
    }

    return domainModel;
  }

  convertDomainModelToEntity(domainModel: RoofFaceDTO): IRoofFaceData {
    const polygonData = this.verticesMapping.convertDomainModelToEntity3d(domainModel.boundary.vertices);
    const protrusionData = domainModel.protrusions.map(
      (protrusion: RoofProtrusion): IRoofProtrusionData => this.protrusionMapping.convertDomainModelToEntity(protrusion)
    );
    const geometry: IRoofFaceGeometryData = {
      polygon: polygonData,
      protrusions: protrusionData.length ? protrusionData : undefined,
      edgeTypes: domainModel.edgeTypes.length ? domainModel.edgeTypes : undefined,
      slope: domainModel.slope,
      slopeType: domainModel.slopeType,
      azimuth: domainModel.azimuth
    };

    const slopingStructuralMemberSpacing: number =
      // Is user edited truss spacing, we're taking domainModel.framing?.trussSpacing
      (domainModel.framing?.trussSpacing?.attributes.value !== undefined
        && convertInchesToMeters(Number(domainModel.framing.trussSpacing.attributes.value)))
      // Otherwise, take the previously persisted value
      || domainModel.structure?.slopingStructuralMembers?.spacing
      // And if it's not there - fallback to 24 inches
      || inchesInMeters['24'];

    const slopingStructuralMembersMaxHorizontalSpan: number | undefined = domainModel.structure
      ?.slopingStructuralMembers?.maxHorizontalSpan
      ? convertInchesToMeters(domainModel.structure.slopingStructuralMembers.maxHorizontalSpan)
      : undefined;
    const structure: IRoofFaceStructureData = {
      constructionStyle: domainModel.structure?.constructionStyle ?? 'MANUFACTURED_TRUSS',
      deckSheathingType: domainModel.structure?.deckSheathingType ?? domainModel.deckSheathingType,
      surfaceType: domainModel.surface || undefined,
      slopingStructuralMembers: {
        spacing: slopingStructuralMemberSpacing,
        crossSection: domainModel.structure?.slopingStructuralMembers?.crossSection ?? 'L2X4_POST1960',
        maxHorizontalSpan: slopingStructuralMembersMaxHorizontalSpan,
        sag: convertInchesToMeters(domainModel.structure?.slopingStructuralMembers?.sag ?? 0)
      },
      condition: {
        disallowedHolesOrNotchesInStructuralMembers:
          domainModel.structure?.condition?.disallowedHolesOrNotchesInStructuralMembers ?? false,
        ridgeBeamSag: convertInchesToMeters(domainModel.structure?.condition?.ridgeBeamSag ?? 0),
        evidenceOfLeaksDamageOrDeterioration:
          domainModel.structure?.condition?.evidenceOfLeaksDamageOrDeterioration ?? false,
        structuralDecayOrFireDamage: domainModel.structure?.condition?.structuralDecayOrFireDamage ?? false
      }
    };
    const images = domainModel.images.map((image: SiteImage): ISiteImageData => image.toData());
    return {
      id: domainModel.serverId,
      name: domainModel.name,
      geometry,
      structure,
      images: images.length ? images : undefined
    };
  }
}
