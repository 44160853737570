export enum StringingAcceptabilityRating {
  Optimal = 'OPTIMAL',
  Standard = 'STANDARD',
  UnderperformingBelowMpptVoltage = 'UNDERPERFORMING_BELOW_MPPT_VOLTAGE',
  UnderperformingAboveMpptVoltage = 'UNDERPERFORMING_ABOVE_MPPT_VOLTAGE',
  UnderperformingLowPower = 'UNDERPERFORMING_LOW_POWER',
  NonfunctionalBelowMinAllowableNumberOfModulesPerString = 'NONFUNCTIONAL_BELOW_MIN_ALLOWABLE_NUMBER_OF_MODULES_PER_STRING',
  NonfunctionalVmpBelowInverterMinimumInputVoltage = 'NONFUNCTIONAL_VMP_BELOW_INVERTER_MINIMUM_INPUT_VOLTAGE',
  UnderperformingExcessPower = 'UNDERPERFORMING_EXCESS_POWER',
  UnderperformingImpExceedsMpptMaxInputCurrent = 'UNDERPERFORMING_IMP_EXCEEDS_MPPT_MAX_INPUT_CURRENT',
  NonfunctionalAboveMaxAllowableNumberOfInvertersPerBranch = 'NONFUNCTIONAL_ABOVE_MAX_ALLOWABLE_NUMBER_OF_INVERTERS_PER_BRANCH',
  NonfunctionalAboveMaxAllowableNumberOfModulesPerString = 'NONFUNCTIONAL_ABOVE_MAX_ALLOWABLE_NUMBER_OF_MODULES_PER_STRING',
  NonfunctionalIscExceedsMpptAbsoluteMaxIsc = 'NONFUNCTIONAL_ISC_EXCEEDS_MPPT_ABSOLUTE_MAX_ISC',
  DangerousVocAboveInverterMaximumInputVoltage = 'DANGEROUS_VOC_ABOVE_INVERTER_MAXIMUM_INPUT_VOLTAGE',
  DangerousVocAboveModuleMaximumSystemVoltage = 'DANGEROUS_VOC_ABOVE_MODULE_MAXIMUM_SYSTEM_VOLTAGE',
  DangerousExceedsElectricalCodeLimit = 'DANGEROUS_EXCEEDS_ELECTRICAL_CODE_LIMIT',
  ParallelStringsUnbalanced = 'PARALLEL_STRINGS_UNBALANCED'
}

export function isInvalidRatingDueToHavingNotEnoughPvModules(
  acceptabilityRating: StringingAcceptabilityRating
): boolean {
  return [
    StringingAcceptabilityRating.UnderperformingBelowMpptVoltage,
    StringingAcceptabilityRating.UnderperformingLowPower,
    StringingAcceptabilityRating.NonfunctionalBelowMinAllowableNumberOfModulesPerString,
    StringingAcceptabilityRating.NonfunctionalVmpBelowInverterMinimumInputVoltage
  ].includes(acceptabilityRating);
}

export function isAcceptableRating(rating: StringingAcceptabilityRating): boolean {
  return isOptimalRating(rating) || isUnderperformingRating(rating);
}
function isOptimalRating(rating: StringingAcceptabilityRating): boolean {
  return [StringingAcceptabilityRating.Optimal, StringingAcceptabilityRating.Standard].includes(rating);
}
function isUnderperformingRating(rating: StringingAcceptabilityRating): boolean {
  return [
    StringingAcceptabilityRating.UnderperformingBelowMpptVoltage,
    StringingAcceptabilityRating.UnderperformingAboveMpptVoltage,
    StringingAcceptabilityRating.UnderperformingLowPower,
    StringingAcceptabilityRating.UnderperformingExcessPower,
    StringingAcceptabilityRating.UnderperformingImpExceedsMpptMaxInputCurrent
  ].includes(rating);
}
