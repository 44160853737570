import selectn from 'selectn';
import type { FormData } from '../FormOptionsRulesAndState';

function normalizeRef(ref: string) {
  return ref.replace(
    /\$/g, '.'
  );
}

export function selectRef(
  field: string, formData: FormData
) {
  const ref = normalizeRef(field);
  return selectn(
    ref, formData
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(obj: any): obj is Record<string, any> {
  return typeof obj === 'object' && obj !== null;
}

export function toArray(event: unknown) {
  if (Array.isArray(event)) {
    return event;
  } else {
    return [event];
  }
}

export function toError(message: string) {
  throw new ReferenceError(message);
}

export const arrayIncludesAll = (
  source: unknown[],
  elementsToSearch: unknown[],
  from?: number
): boolean => elementsToSearch.every((elem): boolean => source.includes(
  elem, from
));

