import React, { BaseProps, ReactElement } from 'react';
import { ErrorText, HeaderText, InputField, InputWrapper } from './styles';

type InputProps = BaseProps & {
  className?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  leftText?: string;
  rightText?: string;
  type?: string;
  min?: number;
  step?: number;
  autofocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input = (props: InputProps): ReactElement => {
  const {
    className,
    name,
    value,
    placeholder = '',
    error,
    errorText,
    leftText,
    rightText,
    onChange,
    onBlur,
    type = 'text',
    step,
    autofocus,
    min
  } = props;
  return (
    <div className={className}>
      {name && <HeaderText>{name}</HeaderText>}
      <InputWrapper>
        {leftText && leftText}
        <InputField
          placeholder={placeholder}
          value={value}
          isError={error}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          min={min}
          step={step}
          autoFocus={autofocus}
        />
        {rightText && rightText}
      </InputWrapper>
      {error && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};
export { Input };
