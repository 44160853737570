import React, { BaseProps, ReactElement, ReactNode } from 'react';
import { Label, TextAreaField } from './styles';

type TextAreaProps = BaseProps & {
  className?: string;
  name?: ReactNode;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows: number;
};

const TextArea = (textAreaProps: TextAreaProps): ReactElement => {
  const {
    className,
    name,
    value,
    onChange,
    rows
  } = textAreaProps;
  return (
    <div className={className}>
      <Label>{name}</Label>
      <TextAreaField
        value={value}
        onChange={onChange}
        rows={rows}
      />
    </div>
  );
};
export { TextArea };
