import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { ReactElement } from 'react';
import * as React from 'react';
import { CustomButton } from './IconButton.styles';

interface IIconButtonProps {
  icon: string;
  text: string;
  width?: string;
}

const IconButton: React.FC<IIconButtonProps> = (props: IIconButtonProps): ReactElement => {
  const {
    icon, width, text
  } = props;

  return (
    <CustomButton width={width}>
      <LyraIcon.Icon name={icon} />
      {text}
    </CustomButton>
  );
};

export default IconButton;
