import type { IStage } from './IStage';

// T: Generic type (optional) for IStage dependencies
type IStageConstructor<T = null, A extends IStage = IStage> = new (deps: T) => A;

export type StageFactoryParameters<T, A extends IStage = IStage> = {
  c: IStageConstructor<T, A>;
  dependencies: T;
};

interface IStageFactory {
  createStage<K, T extends IStage>(params: StageFactoryParameters<K>): T;
}

export class StageFactory implements IStageFactory {
  createStage<K, T extends IStage>(params: StageFactoryParameters<K>): T {
    const {
      c, dependencies
    } = params;
    const concreteStage = new c(dependencies);
    return concreteStage as T;
  }
}
