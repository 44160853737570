import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../index';
import { Paragraph, Notes } from '../FontFamily/Fonts';
import { Icon } from '../Icon';

export const HintWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
  height: 50px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.opacityColor3};
  border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.opacityColor4};
  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: -40px;
    left: 50%;
    height: 50px;
    width: 50px;
    border: solid 1px ${({ theme }: PropsTheme): Color => theme.colors.opacityColor4};
    transform: translate(-50%, 50%);
    border-radius: 50%;
    z-index: 0;
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.greenColor2};
  }
`;

export const HintContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.greenColor2};
  z-index: 99;
`;

export const TipText = styled(Paragraph)`
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const DirectionArrowView = styled.div`
  width: 100%;
  position: absolute;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowView = styled.div`
`;

export const ArrowIcon = styled(Icon)`
`;

export const ShowHintWrapper = styled(HintWrapper)`
  height: calc(100% - 34px);
  left: 9px;
`;

export const InstructionView = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.greenColor2};
  padding: 20px 23px 20px 23px;
  overflow-y: auto;
  height: calc(100% - 90px);
  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const BoldTitleText = styled(Paragraph)`
  font-weight: bold;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const LightDescriptionText = styled(Notes)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  margin-top: 10px;
`;

export const SeparatorView = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.opacityColor5};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ColorPanelView = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const ColorView = styled.div`
  width: 20px;
  height: 36px;
  border: solid 2px ${({ theme }: PropsTheme): Color => theme.colors.blackColor1};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.str01};
`;

export const GreenColorView = styled(ColorView)`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.str02};
`;

export const BlueColorView = styled(ColorView)`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.success};
`;

export const YellowColorView = styled(ColorView)`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.warn};
`;

export const RedColorView = styled(ColorView)`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.error};
`;

export const ColoredText = styled(Notes)`
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
  margin-left: 10px;
`;
