import React, { BaseProps, ReactElement, useContext, useState } from 'react';
import { LeftIcon } from './LeftIcon';
import { RightIcons } from './RightButtons';
import { PaddedSiteStructureItemContainer, Separator } from './styles';
import { ThemeContext } from 'styled-components';

export type ParcelProps = BaseProps & {
  onDeleteClick?: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

const Parcel = (props: ParcelProps): ReactElement => {
  const { className = '', styles = {}, onDeleteClick, onVisibilityToggle } = props;
  const [hover, setHover] = useState(false);
  const theme = useContext(ThemeContext);

  return (
    <PaddedSiteStructureItemContainer
      className={className}
      styles={styles}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      <LeftIcon icon="property-boundaries" bgColor={theme?.colors.brightOrange as unknown as string ?? ''} />
      Parcel Boundary
      <Separator />
      {hover && (
        <RightIcons
          theme="dark"
          onDeleteClick={onDeleteClick}
          onVisibilityToggle={onVisibilityToggle}
        />
      )}
    </PaddedSiteStructureItemContainer>
  );
};

export { Parcel };
