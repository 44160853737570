import type { Texture } from 'three';
import {
  Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, TextureLoader, SRGBColorSpace
} from 'three';
import { ThreeUtils } from '../../utils/ThreeUtils';
import config from '../../config/config';

const LOADER_RADIUS = 70;
const ANIMATION_SPEED = 0.0042; // radians / ms
export default class Loader extends Object3D {
  private _mesh!: Mesh;
  private _isDestroyed: boolean = false;
  private _lastTimestamp: number = 0;
  private _requestAnimationFrameId: number = 0;

  constructor() {
    super();
    const loader: TextureLoader = new TextureLoader();
    // temporarily not using this until new method is created
    // const { origin } = new window.URL(window.location.href);
    // Use cdn address
    loader.load(`${config.api.cdn}/assets/spinner.png`, this.onTextureLoaded);
  }

  destroy(): void {
    cancelAnimationFrame(this._requestAnimationFrameId);
    ThreeUtils.destroyObject(this);
    this._isDestroyed = true;
  }

  private onTextureLoaded = (texture: Texture): void => {
    // In case it's getting destroyed before the texture loaded
    if (this._isDestroyed) {
      texture.dispose();
    } else {
      texture.colorSpace = SRGBColorSpace;
      this._mesh = new Mesh(
        new PlaneGeometry(LOADER_RADIUS, LOADER_RADIUS),
        new MeshBasicMaterial({
          map: texture,
          transparent: true
        })
      );

      // Otherwise the loader will have a slightly non-transparent background
      // that's only visible over array areas with PV modules. The root cause is unknown.
      this._mesh.renderOrder = 1;

      this.add(this._mesh);

      this._lastTimestamp = performance.now();
      this._requestAnimationFrameId = requestAnimationFrame(this.animate);
    }
  };

  private animate = (): void => {
    this._requestAnimationFrameId = requestAnimationFrame(this.animate);

    const isRemoved = !this.parent || !this._mesh.parent;

    if (this.visible && !isRemoved) {
      const now = performance.now();
      const delta = now - this._lastTimestamp;
      this._lastTimestamp = now;

      this.rotation.z -= ANIMATION_SPEED * delta;
    } else if (isRemoved) {
      // We should only cancel the animation when the object is removed, because
      // the visibility of the existing loader is being changed dynamically
      cancelAnimationFrame(this._requestAnimationFrameId);
    }
  };
}
