import type { ReactElement } from 'react';
import * as React from 'react';
import { MathUtils as ThreeMath } from 'three';
import { canvasConfig } from '../../../../../../config/canvasConfig';
import withTool from '../../../Shared/withTool';
import { SiteEquipmentTool } from '../../../../../../stores/UiStore/ToolbarStore/Project/SiteEquipmentTools/SiteEquipmentTool';
import GasMeter from './GasMeter';
import ServiceEntranceEquipment from './ServiceEntranceEquipment';
import { StreetLocation } from './StreetLocation';
import { SubToolSiteEquipmentWrapper } from './styles';
import Subpanel from './Subpanel';

const SiteEquipment = (): ReactElement => {
  const {
    mainServicePanelIconColor,
    gasMeterIconColor,
    subpanelIconColor,
    streetLocationIconColor
  } = canvasConfig;

  const SubTools: ReactElement[] = [
    <ServiceEntranceEquipment key={ThreeMath.generateUUID()} />,
    <GasMeter key={ThreeMath.generateUUID()} />,
    <Subpanel key={ThreeMath.generateUUID()} />,
    <StreetLocation key={ThreeMath.generateUUID()} />
  ];

  const SubToolWrapper = (
    <SubToolSiteEquipmentWrapper
      colors={[
        mainServicePanelIconColor,
        gasMeterIconColor,
        subpanelIconColor,
        streetLocationIconColor
      ]}
    >
      {SubTools}
    </SubToolSiteEquipmentWrapper>
  );

  const Element = withTool(SiteEquipmentTool, undefined, SubToolWrapper);

  return <Element />;
};

export default SiteEquipment;
