import type {
  PropsTheme, SimpleInterpolation
} from 'styled-components';
import styled, { css } from 'styled-components';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { Color } from '../../../domain/typings';
import Flexbox from '../Layout/Flexbox';

type OptionProps = {
  height?: string;
  width?: string;
  selected?: boolean;
  buttonBackground?: Color;
  withBorder?: boolean;
  selectedOptionColor?: EThemeComponentColor | undefined;
};

export const ButtonGroupStyle = styled(Flexbox)`
  gap: 7px;
  position: static;
  align-items: center;
  justify-content: space-evenly;
  font-size: 13px;
  margin: 5px 0;
  text-align: center;
`;

function borderStyle({
  selected,
  theme,
  withBorder,
  selectedOptionColor
}: PropsTheme<OptionProps>): SimpleInterpolation {
  return selected
    ? css`
        border: solid 1px
          ${selectedOptionColor === EThemeComponentColor.PURPLE ? theme.colors.mainColor2 : theme.colors.mainColor1};
      `
    : css`
        border: solid 1px ${withBorder ? theme.colors.grey4 : 'transparent'};
      `;
}

export const Button = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})<PropsTheme<OptionProps> & { hideElement?: boolean }>`
  background-color: ${({
    theme, buttonBackground
  }: PropsTheme<OptionProps>): Color | string =>
    buttonBackground ? theme.colors.whiteBg : 'none'};
  padding: ${({ hideElement = true }: { hideElement?: boolean }): string => (hideElement ? '10px 0px' : 'none')};
  ${(props: PropsTheme<OptionProps>): SimpleInterpolation =>
    borderStyle({
      ...props
    })}
  position: static;
  box-sizing: content-box;
  height: ${({ height = '100%' }: OptionProps): string => height};
  width: ${({ width = '100%' }: OptionProps): string => width};
  border-radius: 4px;
  outline: solid 1px ${({
    theme, selected
  }: PropsTheme<OptionProps>): Color =>
    selected ? theme.colors.mainColor1 : 'transparent'};
  box-shadow: ${({ selected }: OptionProps): string => (selected ? '0 2px 4px 0 rgba(0, 0, 0, 0.4)' : 'none')};
  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    border: solid 1px ${({
    theme, selectedOptionColor
  }: PropsTheme<OptionProps>): Color =>
    selectedOptionColor === EThemeComponentColor.PURPLE ? theme.colors.mainColor2 : theme.colors.mainColor1};
    outline: solid 1px ${({
    theme, selectedOptionColor
  }: PropsTheme<OptionProps>): Color =>
    selectedOptionColor === EThemeComponentColor.PURPLE ? theme.colors.mainColor2 : theme.colors.mainColor1};
    }
  }
`;
export const ButtonWrapper = styled.div`
  position: static;
`;
