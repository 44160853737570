import type {
  BaseProps, ReactElement, ReactNode
} from 'react';
import * as React from 'react';
import IconMapper from '../../../ui/components/Icons';
import {
  EditButtonWrapper, HeaderEditionWrapper, RoofPropertyEditionWrapper
} from './styles';

type RoofEdition = BaseProps & {
  children?: ReactNode;
  title: string;
  onEditClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
};

const RoofPropertyEdition = (props: RoofEdition): ReactElement => {
  const {
    children, onEditClick, title, disabled
  } = props;
  return (
    <RoofPropertyEditionWrapper>
      <HeaderEditionWrapper dir="row" justify="space-between">
        <h4>{title}</h4>
        <EditButtonWrapper onClick={onEditClick} disabled={disabled}>
          <IconMapper name="pencil" />
        </EditButtonWrapper>
      </HeaderEditionWrapper>
      {children}
    </RoofPropertyEditionWrapper>
  );
};

export default RoofPropertyEdition;
