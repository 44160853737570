import type { ISignalP1 } from './signal/Signal';
import { Signal } from './signal/Signal';

type KeyboardSignals = {
  down: ISignalP1<KeyboardEvent>;
  up: ISignalP1<KeyboardEvent>;
  windowBlur: ISignalP1<Event>;
};

export class KeyboardListener {
  private static _isCtrlDown: boolean = false;
  private static _isShiftDown: boolean = false;
  private static _isAltDown: boolean = false;
  private static _isTabDown: boolean = false;
  private static _isSpaceDown: boolean = false;

  private static _instance: KeyboardListener;
  static getInstance(): KeyboardListener {
    return KeyboardListener._instance ?? (KeyboardListener._instance = new KeyboardListener());
  }

  /**
   * Returns if we allow the given event as keyboard event.
   * We don't allow it if the current focus is on an input/textarea because in that case
   * the user is typing and we don't want to mess with that.
   */
  private static allowEvent(event: KeyboardEvent): boolean {
    if (event.key === KeyboardListener.KEY_ESCAPE || event.key === KeyboardListener.KEY_ENTER) {
      return true;
    }
    return !KeyboardListener.isEventTargetAnInput(event);
  }

  private static isEventTargetAnInput(event: Event): boolean {
    const target = event.target as HTMLElement;
    const name = target.nodeName.toLowerCase();
    return name === 'input' || name === 'textarea';
  }

  static readonly KEY_DOWN = 'ArrowDown';
  static readonly KEY_UP = 'ArrowUp';
  static readonly KEY_LEFT = 'ArrowLeft';
  static readonly KEY_RIGHT = 'ArrowRight';
  static readonly KEY_ESCAPE = 'Escape';
  static readonly KEY_DELETE = 'Delete';
  static readonly KEY_BACKSPACE = 'Backspace';
  static readonly KEY_ENTER = 'Enter';
  static readonly KEY_SPACE = ' ';
  static readonly KEY_TAB = 'Tab';
  static readonly KEY_CTRL = 'Control';
  static readonly KEY_SHIFT = 'Shift';
  static readonly KEY_ALT = 'Alt';
  static readonly KEY_ARROW_UP = 'ArrowUp';
  static readonly KEY_ARROW_RIGHT = 'ArrowRight';
  static readonly KEY_ARROW_DOWN = 'ArrowDown';
  static readonly KEY_ARROW_LEFT = 'ArrowLeft';

  signals: KeyboardSignals = {
    down: Signal.create<KeyboardEvent>(),
    up: Signal.create<KeyboardEvent>(),
    // AKA: focus is lost -> the user clicked outside the browser, or changed tabs, or something similar
    windowBlur: Signal.create<Event>()
  };

  protected _domElement: HTMLElement;

  constructor() {
    this._domElement = document.body;
    this.setEnabled(true);
  }

  setEnabled(value: boolean): void {
    if (value) {
      this.addListeners();
    } else {
      this.removeListeners();
    }
  }

  protected addListeners(): void {
    this._domElement.addEventListener('keydown', this.onKeyDown);
    this._domElement.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('blur', this.windowBlur);
  }

  protected removeListeners(): void {
    this._domElement.removeEventListener('keydown', this.onKeyDown);
    this._domElement.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('blur', this.windowBlur);
  }

  private windowBlur = (event: Event): void => {
    this.resetFlags();
    this.signals.windowBlur.dispatch(event);
  };

  private resetFlags(): void {
    KeyboardListener._isAltDown =
      KeyboardListener._isCtrlDown =
      KeyboardListener._isShiftDown =
      KeyboardListener._isTabDown =
      KeyboardListener._isSpaceDown =
        false;
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    switch (event.key) {
    case KeyboardListener.KEY_ALT:
      KeyboardListener._isAltDown = true;
      break;
    case KeyboardListener.KEY_CTRL:
      KeyboardListener._isCtrlDown = true;
      break;
    case KeyboardListener.KEY_SHIFT:
      KeyboardListener._isShiftDown = true;
      break;
    case KeyboardListener.KEY_TAB:
      KeyboardListener._isTabDown = true;
      break;
    case KeyboardListener.KEY_SPACE:
      KeyboardListener._isSpaceDown = true;
      break;
    default:
      break;
    }

    if (this.allow(event)) {
      this.signals.down.dispatch(event);
    }
  };

  private onKeyUp = (event: KeyboardEvent): void => {
    switch (event.key) {
    case KeyboardListener.KEY_ALT:
      KeyboardListener._isAltDown = false;
      // When alt is released, a blur event is triggered, so we need to prevent that
      // Consider preventdefault regardless of which key was released..?
      event.preventDefault();
      break;
    case KeyboardListener.KEY_CTRL:
      KeyboardListener._isCtrlDown = false;
      break;
    case KeyboardListener.KEY_SHIFT:
      KeyboardListener._isShiftDown = false;
      break;
    case KeyboardListener.KEY_TAB:
      KeyboardListener._isTabDown = false;
      break;
    case KeyboardListener.KEY_SPACE:
      KeyboardListener._isSpaceDown = false;
      break;
    default:
      break;
    }

    if (this.allow(event)) {
      this.signals.up.dispatch(event);
    }
  };

  protected allow(event: KeyboardEvent): boolean {
    return KeyboardListener.allowEvent(event);
  }

  get element(): HTMLElement {
    return this._domElement;
  }

  static get isAltDown(): boolean {
    return KeyboardListener._isAltDown;
  }

  static get isCtrlDown(): boolean {
    return KeyboardListener._isCtrlDown;
  }

  static get isShiftDown(): boolean {
    return KeyboardListener._isShiftDown;
  }

  static get isTabDown(): boolean {
    return KeyboardListener._isTabDown;
  }

  static get isSpaceDown(): boolean {
    return KeyboardListener._isSpaceDown;
  }

  dispose(): void {
    this.removeListeners();
  }
}

KeyboardListener.getInstance();
