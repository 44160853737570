import type {
  Intersection, Vector3
} from 'three';
import type { EApplicationContext } from '../../../domain/typings';
import type { Draggable } from '../../../domain/mixins/Draggable';
import type { Selectable } from '../../../domain/mixins/Selectable';
import type { Drawable } from '../../../domain/mixins/Drawable';
import type { BaseControl } from './BaseControl';

export enum EMouseButtons {
  LEFT,
  MIDDLE,
  RIGHT
}
interface IBaseControlEvent<T = string> {
  type: T;
  target?: BaseControl;
  originalEvent?: MouseEvent;
}
export interface IPointerDownControlEvent extends IBaseControlEvent {
  pointerStart?: Vector3;
  button?: EMouseButtons;
}

export interface IPointerUpControlEvent extends IBaseControlEvent {
  pointerStart?: Vector3;
  pointerEnd?: Vector3;
  delta?: Vector3;
  button?: EMouseButtons;
}

export interface IPointerMoveControlEvent extends IBaseControlEvent {
  delta?: Vector3;
  pointerPosition?: Vector3;
}

export interface IPointerDblClickControlEvent extends IBaseControlEvent {
  pointerPosition?: Vector3;
  button?: EMouseButtons;
}

export interface IPointerLeftControlEvent extends IBaseControlEvent {
  pointer?: Vector3;
}

export interface IPointerHoveringControlEvent extends IBaseControlEvent {
  hoverObject?: Drawable;
}

export interface IControlSelectionChange {
  type: 'selection_change';
  unselected?: Selectable[];
  selection?: Selectable[];
}

export interface IControlDragging extends IBaseControlEvent {
  selectedObjects?: Draggable[];
  object?: Draggable;
  objectTarget?: Intersection[];
}

export interface IApplicationContextChangeEvent {
  type: 'application_control_state_change';
  state?: EApplicationContext;
}
