import React, { BaseProps, ReactElement } from 'react';
import { Flexbox } from '../Flexbox';
import { Area } from '../Grid';
import { CONTENT } from './constants';

const Content = ({ children, className }: BaseProps): ReactElement => {
  return (
    <Area name={CONTENT} className={className}>
      <Flexbox align="center" justify="center" fullHeight>
        {children}
      </Flexbox>
    </Area>
  );
};

export { Content };
