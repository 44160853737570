import type {
  ReactElement, ReactNode
} from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import {
  LyraDivider,
  LyraFormElementsDesignTool,
  LyraLayout,
  LyraModal,
  LyraRoofButton,
  LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { ERoofType } from '@aurorasolar/lyra-ui-kit/lib/components/RoofButton';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import { useUiStore } from '../../../../stores/useStore';
import type { NewRoofFaceViewModel } from '../../../../stores/UiStore/Wizard/ViewModels/NewRoofFace/NewRoofFaceViewModel';
import { ROOF_COLOR_LEVEL_RANGE } from '../../../../domain/models/RoofColorLevelRange';

const NewRoofFaceModal: React.FC = (): ReactElement => {
  const { wizard } = useUiStore();
  const [viewModel] = useState<NewRoofFaceViewModel>((): NewRoofFaceViewModel => {
    const currentViewModel = wizard.currentViewModel as NewRoofFaceViewModel;
    currentViewModel.getBuilding();
    return currentViewModel;
  });

  const [hoveredIndex, setHovered] = useState<number>(-1);
  const onHoverOut = useCallback((): void => {
    setHovered(-1);
  }, [setHovered]);

  useEffect((): void => {
    viewModel?.enableBehaviors();
  }, [viewModel]);

  if (!viewModel) {
    return <></>;
  }

  const modalProps = {
    onClickLeftButton: (): void => viewModel.goBack(),
    onClickCloseButton: (): void => viewModel.closeModal(),
    onClickRightButton: (): void => viewModel.saveRoofFace(),
    leftButtonLabel: 'Back',
    leftButtonDisabled: viewModel.disableBack,
    rightButtonLabel: 'Save',
    rightButtonDisabled: viewModel.disableSave,
    $show: true,
    title: 'New Roof Face',
    width: '410px',
    color: EThemeComponentColor.PURPLE
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <LyraTypography.Heading fontWeight="bold">Level of Roof Face Eave</LyraTypography.Heading>
      <LyraLayout.Flexbox justify="space-between" align="center">
        {Object.keys(viewModel.levelRange)
          .sort()
          .map(
            (level: string, index: number): ReactNode => (
              <div key={level} onMouseOut={onHoverOut} onMouseOver={(): void => setHovered(index)}>
                <LyraRoofButton.RoofButton
                  className="mh-xxxs"
                  key={level}
                  level={Number(level)}
                  iconColor={ROOF_COLOR_LEVEL_RANGE[level]}
                  onClick={viewModel.changeLevel}
                  selected={hoveredIndex === index || Number(level) === viewModel.level}
                  groupRoofType={ERoofType.MIXED}
                />
              </div>
            )
          )}
      </LyraLayout.Flexbox>
      <LyraDivider.Divider color={EThemeComponentColor.GRAY_200} length="100%" className="mt-xs mb-xs" />
      <LyraLayout.Row>
        <LyraLayout.Col xs={6}>
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            name="roofface-name"
            type="text"
            label="ROOF FACE NAME"
            value={viewModel.name}
            onChange={viewModel.setName}
            hasError={viewModel.repeatedName}
          />
        </LyraLayout.Col>
        <LyraLayout.Col xs={6}>
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            name="building-name"
            type="text"
            label="BUILDING"
            disabled={true}
            value={viewModel.building?.name}
          />
        </LyraLayout.Col>
      </LyraLayout.Row>
    </LyraModal.Modal>
  );
};

export default observer(NewRoofFaceModal);
