import {
  CircleGeometry, DoubleSide, Mesh, MeshBasicMaterial, Object3D, Vector3
} from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import type { Font } from 'three/examples/jsm/loaders/FontLoader';
import { TEXT_HEIGHT } from '../../stores/EditorStore/constants';

export class LetterObject extends Object3D {
  constructor(letter: string, font: Font, size: number, renderingDistance: number) {
    super();

    const textGeometry = new TextGeometry(letter, {
      font,
      size,
      height: TEXT_HEIGHT
    });
    const text = new Mesh(
      textGeometry,
      new MeshBasicMaterial({
        color: 0x000,
        side: DoubleSide
      })
    );
    const textSize = new Vector3();
    textGeometry.computeBoundingBox();
    textGeometry.boundingBox?.getCenter(textSize);
    text.position.x = -textSize.x;
    text.position.y = -textSize.y;
    // The text will be put above their circles, so that the letter is visible in front of the background
    text.position.z = renderingDistance + 1;
    const circle = new Mesh(
      new CircleGeometry(size * 1.1, 50),
      new MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.5
      })
    );
    circle.position.z = renderingDistance;
    this.add(text);
    this.add(circle);
  }
}
