import { ECursor } from '../../domain/typings';

import firstTraceCursor from '../../canvas-assets/cursors/first-trace.svg';
import traceCursor from '../../canvas-assets/cursors/trace.svg';
import forbiddenCursor from '../../canvas-assets/cursors/forbidden.svg';
import * as CURSORS from './constants';

const cursorValues = {
  DEFAULT_CURSOR: 'default',
  SELECTION_CURSOR: 'default',
  PANNING_CURSOR: 'grab',
  FIRST_TRACE_CURSOR: firstTraceCursor,
  TRACE_CURSOR: traceCursor,
  FORBIDDEN_CURSOR: forbiddenCursor
};

export default class CursorController {
  private history: ECursor[] = [];

  private readonly canvasParent: HTMLElement;

  private buildCursorStyle(cursorValue: string): string {
    const cursorContentAsBlob = new Blob([cursorValue], {
      type: 'image/svg+xml'
    });

    return `url('${URL.createObjectURL(cursorContentAsBlob)}'), auto`;
  }

  constructor(rendererDom: HTMLElement) {
    const { origin } = new window.URL(window.location.href);

    this.canvasParent = rendererDom;
  }

  cleanHistory(): void {
    this.history = [];
  }

  loadCursor(cursor: ECursor = ECursor.DEFAULT): void {
    this.history.push(cursor);
    this.updateCursor(cursor);
  }

  loadLastCursor(): void {
    const lastCursor = this.history[this.history.length - 2];
    const cursor = !lastCursor ? ECursor.DEFAULT : lastCursor;

    if (!!lastCursor) {
      this.history.pop();
    }
    this.updateCursor(cursor);
  }

  private updateCursor(cursor: ECursor = ECursor.DEFAULT): void {
    const cursorName = CURSORS[cursor];

    this.canvasParent.style.cursor = cursorName.includes('.svg')
      ? this.buildCursorStyle(cursorValues[cursor])
      : cursorName;
  }
}
