import forEach from 'lodash/forEach';
import { observable } from 'mobx';
import partition from 'lodash/partition';
import type React from 'react';
import {
  handleApiError, isEmpty
} from '../../../../../utils/helpers';
import type DomainStore from '../../../../../stores/DomainStore/DomainStore';
import { BaseViewModel } from '../../../../../stores/UiStore/Modal/BaseViewModel';
import type { ModalStore } from '../../../../../stores/UiStore/Modal/Modal';
import type EditorStore from '../../../../../stores/EditorStore/EditorStore';
import type { DesignWorkspace } from '../../../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { SentryException } from '../../../../../utils/sentryLog';
import {
  ElectricalBosCompleted,
  CircuitConnectionsSpecifiedEvent
} from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../../config/config';

export type TableHeaderType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: string | any;
  title: string;
  align?: string;
};

export type OptionType = {
  value: string | number;
  name?: string;
  id?: string;
};

type CircuitFields = { [key: string]: FieldProps | {} };
export type CircuitsConnectionsResponse = {
  fields: CircuitFields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
};

class FieldProps {
  id: string = '';
  title: string = '';
  value: string | number = '';
  max?: number;
  min?: number;
  readOnly?: boolean;
  options?: OptionType[];
  class?: string = 'default';
}

export interface VoltageDropSummary {
  readonly id: string;
  readonly title: string;
  value: string | number;
  class?: string;
}

export type RowProps = {
  numberOfConductors: FieldProps;
  conductorSize: FieldProps;
  voltageDrop: FieldProps;
  equipmentGroundingConductorSize: FieldProps;
  length: FieldProps;
  description: FieldProps;
  maxConductorTemperature: FieldProps;
  terminalTemperatureRating: FieldProps;
  conductorType: FieldProps;
  conduitDiameter?: FieldProps;
  conduitType?: FieldProps;
};

export type CircuitDataType = {
  circuitConnections: RowProps[];
  circuitVoltageDrops: VoltageDropSummary[];
};

interface ICircuitTableViewModelDependencies {
  modal: ModalStore;
  designWorkspace: DesignWorkspace;
  domain: DomainStore;
  data: CircuitDataType;
  designId: string;
  editor: EditorStore;
}

type TableValuesDelta = {
  [key: string]: string;
};

export class CircuitTableViewModel extends BaseViewModel {
  propCodeUI = 'circuit_table_modal';
  override editor: EditorStore;
  @observable
  circuitData!: CircuitDataType;
  @observable
  tableData!: RowProps[];
  @observable
  headerItems!: TableHeaderType[];
  @observable
  errors: Record<string, Record<string, boolean>> = {};
  @observable
  disableAllFields?: boolean;

  private readonly designId: string;
  private designWorkspace: DesignWorkspace;

  constructor(dependencies: ICircuitTableViewModelDependencies) {
    super(dependencies);
    this.loadTableData(dependencies.data, true);
    this.getOrderedHeaderItems();
    this.designId = dependencies.designId;
    this.designWorkspace = dependencies.designWorkspace;
    this.editor = dependencies.editor;
  }

  backToElectricalBosStage(): void {
    this.closeModal();
  }

  processToMountingBosStage(): void {
    this.closeModal();
    this.designWorkspace.stageManager?.next();
    config.analytics?.trackEvent(new ElectricalBosCompleted(this.domain));
  }

  getHeaderName(originalName: string): string {
    let name;
    switch (originalName) {
      case 'numberOfConductors': {
        name = 'Number of conductors in conduit/cable';
        break;
      }
      case 'conductorSize': {
        name = 'Conductor size';
        break;
      }
      case 'voltageDrop': {
        name = 'Voltage drop (Vd)';
        break;
      }
      case 'length': {
        name = 'Length';
        break;
      }
      case 'description': {
        name = 'Description';
        break;
      }
      case 'maxConductorTemperature': {
        name = 'Max. wire temp.';
        break;
      }
      case 'terminalTemperatureRating': {
        name = 'Terminal temp. rating';
        break;
      }
      case 'conductorType': {
        name = 'Conductor type';
        break;
      }
      case 'conduitDiameter': {
        name = 'Conduit dia (inches)';
        break;
      }
      case 'conduitType': {
        name = 'Conduit type';
        break;
      }
      case 'equipmentGroundingConductorSize': {
        name = 'EGC size';
        break;
      }
      case 'ocpdRating': {
        name = 'OCPD rating';
        break;
      }
      default: {
        name = originalName;
      }
    }
    return name;
  }

  loadTableData(data: CircuitDataType, reload: boolean): void {
    this.circuitData = data;
    this.tableData = data.circuitConnections;

    if (reload) {
      setTimeout((): void => {
        this.resetAllColors();
      }, 500);
    }
  }

  resetAllColors = (): void => {
    this.circuitData.circuitConnections.forEach((connection: RowProps): void => {
      forEach(connection, (row: FieldProps | undefined): void => {
        if (row) {
          row.class = 'default';
        }
      });
    });
    this.circuitData.circuitVoltageDrops.forEach((voltageDrop: VoltageDropSummary): void => {
      voltageDrop.class = 'default';
    });
    this.loadTableData(this.circuitData, false);
  };

  inputChange = async (value: string, rowIndex: number, columnIndex: number, array: string[]): Promise<void> => {
    const valueFinal = parseFloat(value);
    const element = {
      [`${array[0]}.${array[1]}`]: isNaN(valueFinal) ? value : valueFinal
    };

    await this.reloadChanges(this.designId, element, rowIndex, columnIndex);
  };

  optionChange = async (event: React.ChangeEvent<HTMLSelectElement>, array: string[]): Promise<void> => {
    const valueFinal = parseFloat(event.target.value);

    const element = {
      [`${array[0]}.${array[1]}`]: isNaN(valueFinal) ? event.target.value : valueFinal
    };
    await this.reloadChanges(this.designId, element, 0, 0);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadChanges = async (designId: string, element: any, rowIndex: number, columnIndex: number): Promise<void> => {
    this.disableAllFields = true;
    try {
      this.circuitData = this.updateDataSelected(element, rowIndex, columnIndex);
      if (!this.errors[rowIndex]?.[columnIndex]) {
        await this.documentsService
          .patchCircuitConnections(designId, element)
          .then((result: CircuitsConnectionsResponse): void => {
            if (!isEmpty(result)) {
              this.setFields(result.fields);
              this.setOptions(result.options);
              this.setValues(result.values);
            }
            config.analytics?.trackEvent(new CircuitConnectionsSpecifiedEvent(this.domain));
          })
          .catch(handleApiError('Failed to update circuit connections data'));
      }
      this.loadTableData(this.circuitData, true);
    } catch (error) {
      SentryException('Error reloading Circuit Table', error);
    } finally {
      this.disableAllFields = false;
    }
  };

  setFields(fieldsFromServer: CircuitFields): void {
    const [fieldsToShow, fieldsToHide] = partition(
      Object.keys(fieldsFromServer),
      (key: string): boolean => Object.keys(fieldsFromServer[key] ?? {}).length > 0
    );

    // Hiding fields:
    forEach(this.circuitData.circuitConnections, (circuitRow: RowProps): void => {
      forEach(circuitRow, (circuitField: FieldProps | undefined): void => {
        if (circuitField && fieldsToHide.includes(circuitField.id)) {
          circuitField.options = [];
          circuitField.value = 'N/A';
          circuitField.readOnly = true;
          circuitField.class = 'changed';
        }
      });
    });

    // Showing fields:
    fieldsToShow.forEach((key: string): void => {
      const digitMatches = /\[(\d+)]/gi.exec(key);
      const index = Number(digitMatches?.[1] ?? -1);
      const fieldName: keyof RowProps = key.substring(key.lastIndexOf('.') + 1) as keyof RowProps;
      const row = this.circuitData.circuitConnections[index];
      if (row) {
        row[fieldName] = fieldsFromServer[key] as FieldProps;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setOptions(options: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forEach(options, (value: any, key: any): void => {
      const newKey = key.split('.')[1];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.circuitData.circuitConnections.forEach((option: any): void => {
        const elementFound = option[newKey];
        if (elementFound && elementFound.id === key) {
          elementFound.options = value;
          elementFound.class = 'changed';
        }
      });
    });
  }

  setValues(values: TableValuesDelta): void {
    forEach(values, (value: string, key: string): void => {
      const fieldIdParts = key.split('.');
      forEach(this.circuitData, (circuitDataValue: (RowProps | VoltageDropSummary)[]): void => {
        forEach(circuitDataValue, (option: RowProps | VoltageDropSummary): void => {
          const elementFound =
            fieldIdParts[0] === 'circuitVoltageDrops'
              ? (option as VoltageDropSummary)
              : (option as RowProps)[fieldIdParts[1] as keyof RowProps];
          if (elementFound?.id === key) {
            elementFound.value = value;
            elementFound.class = 'changed';
          }
        });
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateDataSelected(dataChanged: any, rowIndex: number, columnIndex: number): CircuitDataType {
    const key = Object.keys(dataChanged)[0];
    const newKey = key.split('.')[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.circuitData.circuitConnections.forEach((option: any): void => {
      const elementFound = option[newKey];
      if (elementFound && elementFound.id === key) {
        if (newKey === 'maxConductorTemperature' || newKey === 'minConductorTemperature') {
          this.validateConductorTemperatureValues(dataChanged[key], elementFound, rowIndex, columnIndex);
        }
        elementFound.value = dataChanged[key];
        dataChanged[key] = elementFound.value;
      }
    });
    return this.circuitData;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validateConductorTemperatureValues(value: number, element: any, rowIndex: number, columnIndex: number): void {
    if ((element.max && value > element.max) || (element.min && value < element.min)) {
      this.errors[rowIndex] = {
        [columnIndex]: true
      };
    } else {
      delete this.errors[rowIndex];
    }
  }

  private getOrderedHeaderItems(): void {
    this.headerItems = [
      {
        id: 'description',
        title: this.getHeaderName('description'),
        align: 'left'
      },
      {
        id: 'ocpdRating',
        title: this.getHeaderName('ocpdRating')
      },
      {
        id: 'conductorType',
        title: this.getHeaderName('conductorType')
      },
      {
        id: 'conductorSize',
        title: this.getHeaderName('conductorSize')
      },
      {
        id: 'terminalTemperatureRating',
        title: this.getHeaderName('terminalTemperatureRating')
      },
      {
        id: 'maxConductorTemperature',
        title: this.getHeaderName('maxConductorTemperature')
      },
      {
        id: 'numberOfConductors',
        title: this.getHeaderName('numberOfConductors')
      },
      {
        id: 'equipmentGroundingConductorSize',
        title: this.getHeaderName('equipmentGroundingConductorSize')
      },
      {
        id: 'conduitType',
        title: this.getHeaderName('conduitType')
      },
      {
        id: 'conduitDiameter',
        title: this.getHeaderName('conduitDiameter')
      },
      {
        id: 'length',
        title: this.getHeaderName('length')
      },
      {
        id: 'voltageDrop',
        title: this.getHeaderName('voltageDrop')
      }
    ];
  }

  override dispose(): void {
    // do nothing
  }
}
