import { LyraProgressBar } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useState, useCallback
} from 'react';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import { ListButtons } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import type {
  StringConnected,
  SystemSummary,
  SystemSummaryPanelViewModel
} from '../../../../stores/UiStore/Panels/ViewModels/SystemSummary/SystemSummaryPanelViewModel';
import useStore from '../../../../stores/useStore';
import type { Stringing } from '../../../../domain/graphics/stringing/Stringing';
import type { IDcOptimizerStringOptionsData } from '../../../../domain/entities/StringingOption/IStringingOptionData';
import { precisionNumber } from '../../../../utils/helpers';
import StringingService from '../../../../services/stringing/stringingService';
import type { SelectStringingInInverterSummaryModalPayload } from '../../../../services/eventSystem/eventSystemHook';
import {
  EventType, useEventSystemSubscribe
} from '../../../../services/eventSystem/eventSystemHook';
import {
  ContainerBranch, ListButtonsContainer, TitleBranch, TitleBranchContainer
} from './MicroinverterStyle';
import ModulesInStringComponent from './ModulesInString';
import {
  DCACRatioContainer,
  DCACRatioProgressBarContainer,
  DCACRatioTitle,
  DCACRatioTitleContainer
} from './StringInverterWithMLPEMPPTsStyle';
import {
  DownArrow, PanelHeader, PanelText, RightArrow, SummaryContentWrapper
} from './styles';
import { InverterExpansionState } from './types';
import {
  createOnSelectedStringingChanged,
  createHandleClickOnInverterHeader,
  useIgnoreStringingSelection
} from './commonSummaryPanelFunctions';

const { DottedProgressBar } = LyraProgressBar;

const StringInverterWithMLPEMPPTS = observer((): ReactElement => {
  const { panels } = useStore();
  const [systemSummaryViewModel] = useState(panels.viewModel as SystemSummaryPanelViewModel);
  const {
    systemSummaryList, selectedString
  } = systemSummaryViewModel;

  const [inverterExpandedFlagsMap, setInverterExpandedFlagsMap] = useState<{ [key: number]: InverterExpansionState }>(
    new Array(systemSummaryList.length).fill(InverterExpansionState.Expanded)
      .reduce((accumulator, value, index) => {
        accumulator[index] = value;
        return accumulator;
      }, {})
  );

  const [ignoreStringingSelection, setIgnoreStringingSelection] = useIgnoreStringingSelection();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelectedStringingChanged = useCallback(
    createOnSelectedStringingChanged({
      ignoreStringingSelection,
      setIgnoreStringingSelection,
      inverterExpandedFlagsMap,
      setInverterExpandedFlagsMap,
      systemSummaryList
    }),
    [inverterExpandedFlagsMap, ignoreStringingSelection, systemSummaryList]
  );

  useEventSystemSubscribe<SelectStringingInInverterSummaryModalPayload>(
    EventType.SelectStringingInInverterSummaryModal,
    onSelectedStringingChanged
  );

  const summaryPanelContent = systemSummaryList.map((systemSummary: SystemSummary, index: number): ReactElement => {
    const stringsConnected = systemSummary.strings;
    const definition = systemSummary.definition as IDcOptimizerStringOptionsData;
    const optionsListButtons: OptionProps[] = [];
    let selected = '';
    let stringsFoundServerIds = '';

    stringsConnected.forEach((item: StringConnected, idx: number): void => {
      const value = (idx + 1).toString();
      if (!selected || selectedString === item.getId()) {
        selected = value;
        stringsFoundServerIds = item.serverId;
      }
      optionsListButtons.push({
        id: item.getId(),
        value
      });
    });

    const isInverterExpandedOrSelected = inverterExpandedFlagsMap[index] !== InverterExpansionState.Collapsed;

    const handleClickOnInverterHeader = createHandleClickOnInverterHeader({
      inverterExpandedFlagsMap,
      setInverterExpandedFlagsMap,
      index,
      stringsConnected,
      ignoreStringingSelection,
      setIgnoreStringingSelection
    });

    const getDcAcRatio = (): number => {
      const totalModules = stringsConnected.reduce(
        (acc: number, stringConnected: StringConnected): number => acc + stringConnected.totalModules,
        0
      );
      const ratedPowerOutput = definition.inverterDefinition.ratedPowerOutput ?? 1;
      const pStc = definition.pvInput.pStc ?? 0;
      return precisionNumber((pStc * totalModules * 100) / ratedPowerOutput, 2);
    };

    const maxDcAcRatioAsPercentage = (): number => {
      const maxDcACRationValue = Math.round(definition.inverterDefinition.maxDcAcRatio * 100);
      return maxDcACRationValue || 100;
    };

    const inverterNumber = systemSummaryList.length > 1 ? `${index + 1}-` : '';

    const onDeleteActiveString = (): void => {
      if (StringingService.activeSelectedString) {
        systemSummaryViewModel.callDeleteStringCommand(
          `String ${inverterNumber}${selected}`,
          StringingService.activeSelectedString
        );
      } else {
        const stringToDelete = StringingService.strings.find(
          (string: Stringing): boolean => string.serverId === stringsFoundServerIds
        );
        if (stringToDelete) {
          systemSummaryViewModel.callDeleteStringCommand(`String ${inverterNumber}${selected}`, stringToDelete);
        }
      }
    };

    return (
      <SummaryContentWrapper key={index}>
        <PanelHeader onClick={handleClickOnInverterHeader}>
          <PanelText>Inverter {index + 1}</PanelText>
          {isInverterExpandedOrSelected ? <DownArrow name="arrow-collapse" /> : <RightArrow name="arrow-collapse" />}
        </PanelHeader>
        {isInverterExpandedOrSelected && (
          <>
            <DCACRatioContainer>
              <DCACRatioTitleContainer>
                <DCACRatioTitle>DC-AC Ratio</DCACRatioTitle>
              </DCACRatioTitleContainer>
              <DCACRatioProgressBarContainer>
                <DottedProgressBar
                  currentValue={getDcAcRatio()}
                  units={'%'}
                  minValue={0}
                  maxValue={100}
                  possibleMaxValue={maxDcAcRatioAsPercentage()}
                  strokeColor={'#36A247'}
                  style={{
                    primaryFontSize: '8px',
                    secondaryFontSize: '10px',
                    barHeight: '14.6px'
                  }}
                />
              </DCACRatioProgressBarContainer>
            </DCACRatioContainer>

            <ContainerBranch>
              <TitleBranchContainer>
                <TitleBranch>String</TitleBranch>
              </TitleBranchContainer>
              {!!optionsListButtons.length && (
                <ListButtonsContainer>
                  <ListButtons
                    onClickEvent={(response: OptionProps): void => {
                      ignoreStringingSelection.value = true;
                      setIgnoreStringingSelection(ignoreStringingSelection);
                      StringingService.selectStringById(response.id);
                    }}
                    selected={selected}
                    options={optionsListButtons}
                  />
                </ListButtonsContainer>
              )}
            </ContainerBranch>
            <ModulesInStringComponent index={index} onDeleteActiveString={onDeleteActiveString} />
          </>
        )}
      </SummaryContentWrapper>
    );
  });
  return <>{summaryPanelContent}</>;
});

export default StringInverterWithMLPEMPPTS;
