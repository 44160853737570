import type { ICommand } from './Commands/Command';

interface IQueueElement {
  name: string;
  commandInstance: ICommand;
}

export class CommandQueue {
  private elements: IQueueElement[];

  constructor() {
    this.elements = [];
  }

  // Adds element to the queue
  enqueue(e: IQueueElement): void {
    this.elements.push(e);
  }

  // Removes last element from the queue
  dequeue(): IQueueElement | undefined {
    return this.elements.shift();
  }

  // Checks whether current queue is empty or not
  isEmpty(): boolean {
    return this.elements.length === 0;
  }

  // Returns head element of the queue
  // or undefined if queue is empty
  peek(): IQueueElement | undefined {
    return !this.isEmpty() ? this.elements[0] : undefined;
  }
}
