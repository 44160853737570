import React, {
  BaseProps,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import { Option } from '../../../typings/Option';
import { Icon } from '../../Icon';
import { List } from '../../List';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { Label, LabelProps } from '../Label/index';
import { Container, IconContainer, SelectList, SelectStyle } from './styles';

type Props = BaseProps & LabelProps & {
  label?: string | ReactNode;
  placeholder?: string | ReactNode;
  listOptions?: Option[];
  onSelect?: (value: string | number) => void;
  selected?: string | number;
  disabled?: boolean;
  width?: string;
  height?: string;
  textAlign?: string;
  selectedOptionColor?: EThemeComponentColor | undefined;
  /**
   * Leave the placeholder in the select field
   * and the selected option will be below the select field
   */
  displayOptionBelow?: boolean;
  isCollapsedByDefault?: boolean;
};

const DEFAULT_PLACEHOLDER = '- Select an option -';

const Select = ({
  className,
  disabled,
  label,
  listOptions = [],
  onSelect,
  selected,
  textAlign,
  width,
  height,
  color,
  selectedOptionColor,
  isCollapsedByDefault = true
}: Props): ReactElement => {
  const [currentSelected, setCurrentSelected] = useState(
    selected
      ? listOptions.find((option: Option): boolean => option.value === selected)
      : null
  );
  const [currentDescription, setCurrentDescription] = useState<string | ReactNode>(
    DEFAULT_PLACEHOLDER
  );

  const [collapsed, setCollapsed] = useState(isCollapsedByDefault);
  const [disable, setDisable] = useState(disabled);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const isUnselectedSingleValue = currentDescription === DEFAULT_PLACEHOLDER && listOptions.length === 1;
  const selectIsClickable = (listOptions.length > 1 || isUnselectedSingleValue);

  const onInputClick = useCallback((): void => {
    if (!disabled && selectIsClickable) {
      setCollapsed(false);
    }
  }, [disabled, selectIsClickable]);

  useEffect((): void => {
    const selectedOption: Option | undefined = listOptions
        .find((option: Option): boolean => {
          return option.value === selected;
        });

    if (selectedOption !== undefined) {
      setCurrentSelected(selectedOption);
    } else {
      setCurrentSelected(null);
    }
  }, [selected, listOptions]);

  useEffect((): void => {
    if (!listOptions || !listOptions.length) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [listOptions, disabled]);

  useEffect((): void => {
    if (currentSelected) {
      setCurrentDescription(currentSelected.name);
    } else {
      setCurrentDescription(DEFAULT_PLACEHOLDER);
    }
  }, [currentSelected]);

  useEffect((): (() => void) => {
    const closeOnClickedOutside = (event: MouseEvent): void => {
      // We need to add this class to every select parent wrapper (with a scrollbar)
      // if we want to not close the dropdown on scrolling the list of options.
      // Parent wrapper with element_with_scroll class should be just a wrapper without padding and margins.
      const clickOnParentScrollbar = (event.target as Element).classList.contains('element_with_scroll');
      if (!clickOnParentScrollbar
          && !collapsed
          && (selectIsClickable && isCollapsedByDefault)
          && selectContainerRef.current
          && !selectContainerRef.current?.contains(event.target as Node)
      ) {
        setCollapsed(true);
      }
    };

    window.addEventListener('mouseup', closeOnClickedOutside);
    return (): void => {
      window.removeEventListener('mouseup', closeOnClickedOutside);
    };
  }, [collapsed, selectIsClickable]);

  useEffect((): (() => void) => {
    const handleKeydown = (event: KeyboardEvent): void => {
      if (!collapsed && event.key === 'Escape') {
        setCollapsed(true);
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return (): void => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [collapsed]);


  const onSelectList = (value: string | number): void => {
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
    const selectedData = listOptions.find((option: Option): boolean => {
      return option.value === value;
    });

    setCollapsed(true);
    setCurrentSelected(selectedData);
  };

  return (
    <Container
        ref={selectContainerRef}
        width={width ?? '100%'}
        className={`${!collapsed ? 'is-opened' : null} ${className ?? null}`}
    >
      <Label color={color} label={label} />
      <SelectStyle
        collapsed={collapsed}
        onClick={onInputClick}
        disabled={disable}
        selectIsClickable={selectIsClickable}
        height={height}
        data-testid="open-select"
      >
        {currentDescription}
        {selectIsClickable && (
          <IconContainer>
            <Icon name="arrow-down" />
          </IconContainer>
        )}
      </SelectStyle>
      {!collapsed && (
        <SelectList textAlign={textAlign}>
          <List
            options={listOptions}
            onSelect={onSelectList}
            selected={currentSelected ? currentSelected.value : undefined}
            selectedOptionColor={selectedOptionColor}
          />
        </SelectList>
      )}
    </Container>
  );
};

export { Select };
