import type { LineBasicMaterial } from 'three';
import {
  Color, EllipseCurve, Matrix4, MeshBasicMaterial, SphereGeometry, Vector2, Vector3
} from 'three';
import { SquareRightAngle } from '../../../../domain/graphics/SquareRightAngle';
import { canvasConfig } from '../../../../config/canvasConfig';
import {
  HALF_PI, PI, QUARTER_PI, TWO_PI
} from '../../../../domain/models/Constants';
import EGuideIdentifier from '../EGuideIdentifier';
import type { IApplyParams } from '../IApplyParams';
import { KindGuides } from '../IApplyParams';
import type IGuideResult from '../IGuideResult';
import {
  type ILineSegment, getSegmentsIntersectionPoint
} from '../../../../utils/spatial';
import { calculateAngle } from '../../../../utils/helpers';
import { EPSILON } from '../../../../utils/math';
import { ArcLabeled } from '../../../../domain/graphics/Arc';
import type { Boundary } from '../../../../domain/graphics/Boundary';
import { LayerCanvas } from '../../../../domain/graphics/LayerCanvas';
import { Segment } from '../../../../domain/graphics/Segment';
import { Vertex } from '../../../../domain/graphics/Vertex';
import { BaseGuide } from './BaseGuide';

const KEYCODE_A = 65;
const ARC_SIZE = 15;
const MINIMUM_TOLERANCE = 13;

export class LiveAnglesGuide extends BaseGuide {
  icon = 'smartguide-live-angles';
  name = 'Angle Snap';
  hotkey = 'S';
  override newCategory = true;
  commandKeyCode = KEYCODE_A;
  identifier = EGuideIdentifier.LIVE_ANGLES;
  kindSupport = [KindGuides.TRACE_TOOL, KindGuides.MOVE_VERTEX];

  arcDefault?: ArcLabeled;
  squareDefault?: SquareRightAngle;

  arcAditionalOne?: ArcLabeled;
  squareAditionalOne?: SquareRightAngle;

  arcAditionalTwo?: ArcLabeled;
  squareAditionalTwo?: SquareRightAngle;
  previewVertex?: Vertex;

  setup(): void {
    this.enableKeyListener();
    const { liveAngleNormalColor } = canvasConfig;
    if (!this.previewVertex) {
      this.previewVertex = new Vertex();
      this.previewVertex.mesh.geometry = new SphereGeometry(2, 32, 32);
      this.previewVertex.mesh.material = new MeshBasicMaterial({
        color: canvasConfig.snapPointColor
      });
    }
    this.previewVertex.mesh.visible = false;
    this.previewVertex.unzoom(this.editor.scaleFactor * 2);

    const arcDefault = new ArcLabeled(this.editor.font);
    arcDefault.name = 'live-angle-arc-default';
    this.arcDefault = arcDefault;
    this.arcDefault.visible = false;

    const arcAdditionalO = new ArcLabeled(this.editor.font);
    arcAdditionalO.name = 'live-angle-arc-aditional-one';
    this.arcAditionalOne = arcAdditionalO;
    this.arcAditionalOne.visible = false;

    const arcAdditionalT = new ArcLabeled(this.editor.font);
    arcAdditionalT.name = 'live-angle-arc-aditional-two';
    this.arcAditionalTwo = arcAdditionalT;
    this.arcAditionalTwo.visible = false;

    const squareDefault = new SquareRightAngle();
    squareDefault.name = 'live-angle-square-default';
    this.squareDefault = squareDefault;
    this.squareDefault.visible = false;

    const squareAdditionalOne = new SquareRightAngle();
    squareAdditionalOne.name = 'live-angle-square-aditional-one';
    this.squareAditionalOne = squareAdditionalOne;
    this.squareAditionalOne.visible = false;

    const squareAdditionalTwo = new SquareRightAngle();
    squareAdditionalTwo.name = 'live-angle-square-aditional-one';
    this.squareAditionalTwo = squareAdditionalTwo;
    this.squareAditionalTwo.visible = false;

    // Setting material to label of arc
    const textMaterial = new MeshBasicMaterial({
      color: liveAngleNormalColor,
      ...LayerCanvas.CLOSEST
    });

    this.arcDefault.setTextMaterial(textMaterial.clone());
    this.arcDefault.text.position.z = 100;

    this.arcAditionalOne.setTextMaterial(textMaterial.clone());
    this.arcAditionalOne.text.position.z = 100;

    this.arcAditionalTwo.setTextMaterial(textMaterial.clone());
    this.arcAditionalTwo.text.position.z = 100;
  }

  apply(params: IApplyParams): IGuideResult {
    this.editor.addOrUpdateObject(this.previewVertex!.mesh);
    this.editor.addOrUpdateObject(this.arcDefault!.mesh);
    this.editor.addOrUpdateObject(this.arcAditionalOne!.mesh);
    this.editor.addOrUpdateObject(this.arcAditionalTwo!.mesh);

    this.editor.addOrUpdateObject(this.squareAditionalOne!.mesh);
    this.editor.addOrUpdateObject(this.squareDefault!.mesh);
    this.editor.addOrUpdateObject(this.squareAditionalTwo!.mesh);

    if (params.kind === KindGuides.TRACE_TOOL && params.mousePos && params.wipBoundary) {
      return this.applyWithTraceTool(params.mousePos, params.wipBoundary, params.possibleLineSnapTarget);
    } else if (params.kind === KindGuides.MOVE_VERTEX && params.mousePos && params.wipBoundary && params.vertexId) {
      return this.applyWithMoveVertex(params.mousePos, params.wipBoundary, params.vertexId);
    } else {
      return {
        color: '',
        position: params.mousePos
      };
    }
  }

  applyWithMoveVertex(mousePos: Vector3, wipBoundary: Boundary, vertexId: string): IGuideResult {
    if (vertexId && wipBoundary && wipBoundary.closed) {
      const vertex = wipBoundary.vertices.find((v: Vertex): boolean => v.serverId === vertexId);
      if (vertex) {
        const segmentsMainAngle = this.findSegments(vertex, wipBoundary.segments);
        const angleMain = this.getAngleBetweenSegments(segmentsMainAngle[0], segmentsMainAngle[1]);

        const secondVertex: Vertex = this.getSecondVertex(vertexId, segmentsMainAngle[0]);
        const segmentsSecondAngle = this.findSegments(secondVertex, wipBoundary.segments);
        const angleSecond = this.getAngleBetweenSegments(segmentsSecondAngle[0], segmentsSecondAngle[1]);

        const thirdVertex: Vertex = this.getSecondVertex(vertexId, segmentsMainAngle[1]);
        const thirdSecondAngle = this.findSegments(thirdVertex, wipBoundary.segments);
        const angleThird = this.getAngleBetweenSegments(thirdSecondAngle[0], thirdSecondAngle[1]);

        const snapMainAngle = this.shouldSnap(angleMain[0]);

        let resultMainAngle = angleMain[0];

        /*
        We work with a vertex (mousePos) that connects two segments.
        Let's call first segment's start A, its end B,
        second segment's first point would be B and last - C.
        The vertex we receive as mousePos is B.

        We need to decide if snap must occur.
        `angleMain` is an angle between segments AB and BC.
        `shouldSnap` checks if `angleMain` is within a certain threshold range,
        in which case we do the snapping.

        We need to decide where we should put the snap point because there's
        more than one point that result in a desired angle.
        A path formed by the potential snap point is called locus.

        Not sure if the proposed solution is the most optimal one, but it is
        the best I came up with so far:

        We'll 'fix' first segment in terms of its angle.
        So a desired snap point will lie on a ray based on AB (starting at A,
        moving towards B). Let's create AC segment (called "base") for ease of
        calculation, it'll be the base of a triangle formed by ABC points.
        Let's calculate angle between AB and AC and call it Alpha.
        Since we fixed ABxAC angle, and we know what snapped angle should be -
        we can just calculate ACxCB angle (180 - (Alpha + snapAngle). Let's call
        it Beta. Since we know the base's (AC) length, snapAngle and Beta, we
        can use the rule of sines to calculate the AB length
        (AC/sin(snapAngle) == AB/sin(Beta)
        =>
        AB == sin(Beta) * AC / sin(snapAngle)
        And knowing AB length we can find the exact location of B, which is
        out desired snap point.

        This approach results in a characteristic behavior users can use to
        their advantage: the segment closer to a polygon's start will be fixed
        in its angle, so to get some ideal location for a snap point user can
        drag B in way so that AB will pass over a desired snap place, after that
        a user can move B closer to A until angle snaps. This way it'll snap
        in a predictable and agile way.
        */

        if (snapMainAngle.snapping) {
          resultMainAngle = snapMainAngle.closestAngle;
          let angleCalculate = snapMainAngle.closestAngle;
          if (angleCalculate === QUARTER_PI + PI) {
            angleCalculate = TWO_PI - QUARTER_PI;
          } else if (angleCalculate === TWO_PI - QUARTER_PI) {
            angleCalculate = QUARTER_PI + PI;
          }

          const base = new Segment({
            v1: segmentsMainAngle[0].points[0],
            v2: segmentsMainAngle[1].points[1]
          });
          const [basePoint0, basePoint1] = [
            segmentsMainAngle[0].points[0].getVector3(),
            segmentsMainAngle[1].points[1].getVector3()
          ];
          const angleAlpha = segmentsMainAngle[0].normalized().angleTo(base.normalized());
          const angleBeta = Math.PI - (angleAlpha + angleCalculate);
          const segmentLength = (Math.sin(angleBeta) * base.length) / Math.sin(angleCalculate);
          const [segment0point0, segment0point1] = [
            segmentsMainAngle[0].points[0].getVector3(),
            segmentsMainAngle[0].points[1].getVector3()
          ];
          const snapPoint =
            angleCalculate % Math.PI < 0.01
              ? basePoint0.clone().add(
                basePoint1
                  .clone()
                  .sub(basePoint0)
                  .normalize()
                  .multiplyScalar(
                    (base.length * segmentsMainAngle[0].length)
                        / (segmentsMainAngle[0].length + segmentsMainAngle[1].length)
                  )
              )
              : segment0point0
                .clone()
                .add(segment0point1.clone().sub(segment0point0)
                  .normalize()
                  .multiplyScalar(segmentLength));

          const threshold = canvasConfig.smartSnapFarThreshold;
          if (mousePos.distanceTo(snapPoint) <= threshold) {
            mousePos.x = snapPoint.x;
            mousePos.y = snapPoint.y;
            this.previewVertex!.mesh.visible = true;
            this.previewVertex!.mesh.position.copy(mousePos);
            this.previewVertex!.unzoom(this.editor.scaleFactor);
          }
        } else {
          this.previewVertex!.mesh.visible = false;
        }

        /** Show angle */
        const isMainAngleClockwise = angleMain[3] === 1;
        this.showAngle(
          this.arcDefault!,
          this.squareDefault!,
          vertex.mesh.position,
          resultMainAngle,
          isMainAngleClockwise ? angleMain[1] + PI : angleMain[1],
          isMainAngleClockwise ? angleMain[2] + PI : angleMain[2],
          isMainAngleClockwise,
          snapMainAngle.snapping
        );

        const snapSecondAngle = this.shouldSnap(angleSecond[0]);
        let resultSecondAngle = angleSecond[0];

        if (snapSecondAngle.snapping) {
          resultSecondAngle = snapSecondAngle.closestAngle;
        }

        const secondAngleClockwise = angleSecond[3] === 1;

        this.showAngle(
          this.arcAditionalOne!,
          this.squareAditionalOne!,
          secondVertex.mesh.position,
          resultSecondAngle,
          secondAngleClockwise ? angleSecond[1] + PI : angleSecond[1],
          secondAngleClockwise ? angleSecond[2] + PI : angleSecond[2],
          secondAngleClockwise,
          snapSecondAngle.snapping
        );

        const snapThirdAngle = this.shouldSnap(angleThird[0]);
        let resultThirdAngle = angleThird[0];

        if (snapThirdAngle.snapping) {
          resultThirdAngle = snapThirdAngle.closestAngle;
        }

        const thirdAngleClockwise = angleThird[3] === 1;

        this.showAngle(
          this.arcAditionalTwo!,
          this.squareAditionalTwo!,
          thirdVertex.mesh.position,
          resultThirdAngle,
          thirdAngleClockwise ? angleThird[1] + PI : angleThird[1],
          thirdAngleClockwise ? angleThird[2] + PI : angleThird[2],
          thirdAngleClockwise,
          snapThirdAngle.snapping
        );
      }
    }
    return {
      color: '',
      position: mousePos
    };
  }

  applyWithTraceTool(mousePos: Vector3, wipBoundary: Boundary, possibleLineSnapTarget?: ILineSegment): IGuideResult {
    const result: IGuideResult = {
      color: '',
      position: mousePos,
      snapOccurred: false
    };
    if (wipBoundary) {
      const segments: Segment[] = wipBoundary.segments;
      const segmentsLength = segments.length;

      if (segmentsLength > 0) {
        // Angle will show only for the last segment and the preview line
        // Angle value will be relative to those lines
        const lastSegment: Segment = segments[segmentsLength - 1];
        const mouseVertex: Vertex = Vertex.fromXyzValuesObject(mousePos);
        const prevSegment: Segment = new Segment({
          v1: lastSegment.points[1],
          v2: mouseVertex
        });

        const lastLineLength = lastSegment.length + MINIMUM_TOLERANCE;
        const prevLength = prevSegment.length + MINIMUM_TOLERANCE;

        if (lastLineLength > ARC_SIZE && prevLength > ARC_SIZE) {
          const angleMain: number[] = this.getAngleBetweenSegments(lastSegment, prevSegment);
          const clockwise: boolean = angleMain[3] === 1;

          const {
            snapping, closestAngle
          } = this.shouldSnap(angleMain[0]);
          // Save the result angle for preview line after snap
          let resultAngle = angleMain[0];

          if (snapping) {
            // TODO: review formula to calculate snap point
            let angleCalculate = closestAngle;
            if (clockwise) {
              angleCalculate = TWO_PI - angleCalculate;
            }

            const angleCalculatePoint: number = angleCalculate + angleMain[1];
            try {
              const snapVec = this.getSnapPosition(
                angleCalculatePoint,
                prevLength - MINIMUM_TOLERANCE,
                lastSegment.points[1].mesh.position,
                possibleLineSnapTarget
              );

              mousePos.x = snapVec.x;
              mousePos.y = snapVec.y;
              resultAngle = closestAngle;

              this.previewVertex!.mesh.position.copy(mousePos);
              this.previewVertex!.mesh.scale.set(1, 1, 1);
              this.previewVertex!.unzoom(this.editor.scaleFactor);
              result.snapOccurred = true;
            } catch (e) {
              this.previewVertex!.mesh.visible = false;
            }
          } else {
            this.previewVertex!.mesh.visible = false;
          }

          this.showAngle(
            this.arcDefault!,
            this.squareDefault!,
            lastSegment.points[1].mesh.position,
            resultAngle,
            angleMain[1],
            angleMain[2],
            clockwise,
            snapping
          );
        } else {
          this.reset(false);
        }
      }
    }
    return result;
  }

  showAngle(
    arc: ArcLabeled,
    square: SquareRightAngle,
    position: Vector3,
    angle: number,
    lineAngle: number,
    prevAngle: number,
    clockwise: boolean,
    snapping: boolean
  ): void {
    // Crearing the arc shape
    const curve: EllipseCurve = this.getCurve(lineAngle, prevAngle, clockwise);

    this.setArcAngle(arc, square, angle, lineAngle, curve, position, clockwise, snapping);
    this.setTextAngle(arc, angle, clockwise, snapping);
  }

  reset(disableKeyListener: boolean): void {
    if (disableKeyListener) {
      this.disableKeyListener();
    }
    this.arcDefault!.visible = false;
    this.arcDefault!.text.visible = false;
    this.squareDefault!.visible = false;

    this.arcAditionalOne!.visible = false;
    this.arcAditionalOne!.text.visible = false;
    this.squareAditionalOne!.visible = false;

    this.arcAditionalTwo!.visible = false;
    this.arcAditionalTwo!.text.visible = false;
    this.squareAditionalTwo!.visible = false;

    this.editor.removeObject(this.arcDefault!.mesh);
    this.editor.removeObject(this.arcAditionalOne!.mesh);
    this.editor.removeObject(this.arcAditionalTwo!.mesh);

    this.editor.removeObject(this.squareDefault!.mesh);
    this.editor.removeObject(this.squareAditionalOne!.mesh);
    this.editor.removeObject(this.squareAditionalTwo!.mesh);

    this.previewVertex!.mesh.visible = false;
    this.editor.removeObject(this.previewVertex!.mesh);
  }

  override hideUiElements(): void {
    this.arcDefault!.visible = false;
    this.arcDefault!.text.visible = false;
    this.squareDefault!.visible = false;

    this.arcAditionalOne!.visible = false;
    this.arcAditionalOne!.text.visible = false;
    this.squareAditionalOne!.visible = false;

    this.arcAditionalTwo!.visible = false;
    this.arcAditionalTwo!.text.visible = false;
    this.squareAditionalTwo!.visible = false;

    this.previewVertex!.mesh.visible = false;
  }

  /**
   * Draw the arc or square in the right position and the right angle
   *
   * @param angle - angle between last segment and preview line
   * @param offsetAngle - global angle used as base angle for the square
   * @param curve - points for drawing the arc
   * @param lastVertex - point position where it sets the arc/square position
   * @param clockwise - flip the arc
   * @param snap - for changing the color
   */
  setArcAngle(
    arc: ArcLabeled,
    square: SquareRightAngle,
    angle: number,
    offsetAngle: number,
    curve: EllipseCurve,
    position: Vector2 | Vector3,
    clockwise: boolean,
    snap: boolean
  ): void {
    // when the angle is 90 degrees
    // draw the square instead of the arc
    if (this.isMiddleAngle(angle)) {
      arc.visible = false;
      square.visible = true;
      square.mesh.position.x = position.x;
      square.mesh.position.y = position.y;
      square.mesh.rotation.z = clockwise ? angle + offsetAngle + PI : angle + offsetAngle - HALF_PI;
    } else {
      arc.visible = true;
      square.visible = false;

      arc.setCurve(curve);
      this.snapColor(snap, arc.mesh.material as MeshBasicMaterial);

      arc.mesh.position.x = position.x;
      arc.mesh.position.y = position.y;
    }
  }

  /**
   * Place and give a value to the angle text mesh
   *
   * @param angle - value in radians
   * @param lastVertex - last polygon's vertex position
   * @param curve - arc in order to get the middle point
   * @param clockwise - flip the text position
   * @param snap - change the color when snap
   */
  setTextAngle(arc: ArcLabeled, angle: number, clockwise: boolean, snap: boolean): void {
    this.snapColor(snap, arc.text.material as MeshBasicMaterial);
    arc.visible = !this.isMiddleAngle(angle);
    arc.text.visible = true;

    const center = new Vector3();

    arc.text.geometry.computeBoundingBox();
    if (arc.text.geometry.boundingBox) {
      arc.text.geometry.boundingBox.getCenter(center);
    }

    // adding pivot point in the middle
    arc.text.geometry.applyMatrix4(new Matrix4().setPosition(new Vector3(-center.x, -center.y, 0)));
    // // Position and size text must be calculated based on current zoom
    const { scaleFactor } = this.editor;
    arc.unzoom(scaleFactor);
  }

  /**
   * Convert the snapped angle in x, y coordinates
   *
   * @param angle - angle to get position
   * @param vecLength - length of the new vector position
   * @param offsetPos - beginning position
   * @param possibleLineSnapTarget
   * @return - vector with the new vectorposition
   */
  getSnapPosition(
    angle: number,
    vecLength: number,
    offsetPos: Vector2 | Vector3,
    possibleLineSnapTarget?: ILineSegment
  ): Vector2 {
    const previewEnd = new Vector2(
      offsetPos.x + Math.cos(angle) * vecLength,
      offsetPos.y + Math.sin(angle) * vecLength
    );
    if (possibleLineSnapTarget) {
      const intersectionPoint = getSegmentsIntersectionPoint(
        offsetPos,
        previewEnd,
        possibleLineSnapTarget.A,
        possibleLineSnapTarget.B
      );
      const angleAndLineGuidesDesiredIntersectionPointDistance = intersectionPoint.distanceTo(previewEnd);
      if (angleAndLineGuidesDesiredIntersectionPointDistance > 10) {
        throw new Error('Angle and Line guides intersection point is too far away');
      }
    }
    return possibleLineSnapTarget
      ? getSegmentsIntersectionPoint(offsetPos, previewEnd, possibleLineSnapTarget.A, possibleLineSnapTarget.B)
      : previewEnd;
  }

  snapColor(changeColor: boolean, material: MeshBasicMaterial | LineBasicMaterial): void {
    const {
      liveAngleSnapColor, liveAngleNormalColor
    } = canvasConfig;

    if (changeColor) {
      material.color.set(new Color(liveAngleSnapColor));
    } else {
      material.color.set(new Color(liveAngleNormalColor));
    }
  }

  isMiddleAngle(angle: number): boolean {
    return angle === HALF_PI || angle === HALF_PI + PI;
  }

  /**
   * Looking if the angle between to lines it's close to a
   * multiple of 45 like90, 135, 180 and so on....
   *
   * @param angle - angle to test
   * @returns - returns if the angle matches and returns the matched angle
   */
  private shouldSnap(angle: number): { closestAngle: number; snapping: boolean } {
    const threshold = canvasConfig.liveAngleThreshold;

    const toleranceQuarter: number[] = [QUARTER_PI - threshold, QUARTER_PI + threshold];
    const toleranceUpperQuarter: number[] = [QUARTER_PI + HALF_PI - threshold, QUARTER_PI + HALF_PI + threshold];
    const toleranceHalf: number[] = [HALF_PI - threshold, HALF_PI + threshold];
    const tolerance: number[] = [PI - threshold, PI + threshold];

    const result = {
      closestAngle: angle,
      snapping: false
    };

    if (angle >= toleranceQuarter[0] && angle <= toleranceQuarter[1]) {
      result.snapping = true;
      result.closestAngle = QUARTER_PI;
    }

    if (angle >= toleranceUpperQuarter[0] && angle <= toleranceUpperQuarter[1]) {
      result.snapping = true;
      result.closestAngle = QUARTER_PI + HALF_PI;
    }

    if (angle >= toleranceHalf[0] && angle <= toleranceHalf[1]) {
      result.snapping = true;
      result.closestAngle = HALF_PI;
    }

    if (angle >= tolerance[0] && angle <= tolerance[1]) {
      result.snapping = true;
      result.closestAngle = PI;
    }

    return result;
  }

  private getCurve(startAngle: number, endAngle: number, clockwise: boolean): EllipseCurve {
    const radio: number = ARC_SIZE;

    // Crearing the arc shape
    return new EllipseCurve(0, 0, radio, radio, startAngle, endAngle, clockwise, 0);
  }

  private findSegments(vertex: Vertex, segments: Segment[]): Segment[] {
    const adjacentSegments: Segment[] = segments.filter(
      (segment: Segment): boolean =>
        (Math.abs(segment.points[0].mesh.position.x - vertex.x) < EPSILON
          && Math.abs(segment.points[0].mesh.position.y - vertex.y) < EPSILON)
        || (Math.abs(segment.points[1].mesh.position.x - vertex.x) < EPSILON
          && Math.abs(segment.points[1].mesh.position.y - vertex.y) < EPSILON)
    );

    if (adjacentSegments.length !== 2) {
      console.warn('There must be exactly 2 adjacent segments for a vertex. Check what\'s wrong.');
    }

    return adjacentSegments;
  }

  private getSecondVertex(vertexId: string, segment: Segment): Vertex {
    if (segment.points[0].serverId !== vertexId) {
      return segment.points[0];
    } else {
      return segment.points[1];
    }
  }

  private getAngleBetweenSegments(one: Segment, two: Segment): number[] {
    const angles: number[] = [];
    let clockwise: number = 0;

    const lastLine = new Vector2(one.points[0].x - one.points[1].x, one.points[0].y - one.points[1].y);

    const previewLine = new Vector2(two.points[1].x - two.points[0].x, two.points[1].y - two.points[0].y);

    let angle = calculateAngle(lastLine, previewLine);

    if (angle > PI) {
      angle = TWO_PI - angle;
      clockwise = 1;
    }

    angles.push(angle);

    const lineAngle = Math.atan2(lastLine.y, lastLine.x);
    angles.push(lineAngle);
    // global angle of the preview line

    const prevAngle = Math.atan2(previewLine.y, previewLine.x);
    angles.push(prevAngle);

    angles.push(clockwise);

    return angles;
  }
}
