import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import type { Drawable } from './Drawable';

export const Hoverable = <T extends AnyConstructor<Drawable>>(HoverableBase: T) => {
  abstract class MixinClass extends HoverableBase {
    hovered: boolean = false;
    isHoverable: boolean = true;
    abstract propertyId: string;

    setHoverableMode(hovered: boolean): void {
      if (hovered !== this.hovered) {
        if (hovered) {
          this.hoverIn();
        } else {
          this.hoverOut();
        }
        this.hovered = hovered;
      }
    }

    abstract hoverIn(): void;
    abstract hoverOut(): void;
  }

  return MixinClass;
};

export type Hoverable = Mixin<typeof Hoverable>;
