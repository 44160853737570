import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../domain/typings';
import {
  BR_MODALSV, HEIGHT_HANDLER
} from '../../../ui/components/Layout/SolarLayout/constants';

type ModalProps = {
  previous?: boolean;
  open?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  step?: number;
};

const ModalDialogStyled = styled.div<ModalProps>`
  display: ${({ open }: ModalProps): string => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  text-align: center;
  z-index: 999;
  //Setting initial position
  right: 28%;
  bottom: 12%;
`;

const ModalWrapperContent = styled.div<PropsTheme<ModalProps>>`
  align-items: center;
  background-color: ${({ theme }: PropsTheme<ModalProps>): Color => theme.colors.whiteBg || ''};
  border-radius: ${BR_MODALSV} 0 ${BR_MODALSV} ${BR_MODALSV};
  display: flex;
  height: 187px;
  justify-content: center;
  width: 324px;
`;

const ModalContentStyled = styled.div<ModalProps>`
  border-radius: ${BR_MODALSV};
  height: 176.2px;
  width: 313.2px;

  & > div {
    height: 100%;
    width: 100%;
  }

  & > div:first-child {
    display: ${({ step }: ModalProps): string => (step === 0 ? 'none' : 'block')};
  }

  & > div:last-child {
    display: ${({ step }: ModalProps): string => (step !== 0 ? 'none' : 'block')};
  }
`;

const ModalControlStyled = styled.div`
  align-items: center;
  border-radius: ${BR_MODALSV};
  border-radius: ${BR_MODALSV};
  border-radius: ${BR_MODALSV};
  border-radius: 1px ${BR_MODALSV} 0 0;
  display: flex;
  height: ${HEIGHT_HANDLER};
  justify-content: flex-end;
  position: relative;

  svg {
    cursor: move;
  }
`;

const ModalOvalStyled = styled.button<PropsTheme<ModalProps>>`
  background-color: ${({ theme }: PropsTheme<ModalProps>): Color => theme.colors.whiteBg || ''};
  ${({ previous }: ModalProps): string => (previous ? 'left: 15px' : 'right: 15px')};
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  height: 24px;
  position: absolute;
  width: 24px;
  z-index: 2;

  &:disabled {
    opacity: 0.25;
  }

  svg {
    display: block;
    margin: auto;
    ${({ previous }: ModalProps): string => (previous ? 'transform: rotate(180deg)' : '')};
  }
`;

const TriangleBorderStyled = styled.div<PropsTheme<ModalProps>>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 ${HEIGHT_HANDLER} 8px;
  border-color: transparent transparent ${({ theme }: PropsTheme<ModalProps>): Color => theme.colors.whiteBg || ''}
    transparent;
  order: 1;
  margin: 2.5px -0.19px 0 0;
`;

const WrapperSVGStyled = styled.button<PropsTheme<ModalProps>>`
  background-color: ${({ theme }: PropsTheme<ModalProps>): Color => theme.colors.whiteBg || ''};
  border-style: none;
  order: 2;
  padding: 0 5.4px 0 1px;
  height: 100%;
  border-radius: 2px ${BR_MODALSV} 0 0;

  &:focus {
    outline: none;
  }
`;

export {
  ModalContentStyled,
  ModalControlStyled,
  ModalDialogStyled,
  ModalOvalStyled,
  ModalWrapperContent,
  TriangleBorderStyled,
  WrapperSVGStyled
};
