import type { Vector3 } from 'three';
import type EditorStore from '../../../../stores/EditorStore/EditorStore';
import type { Vertex } from '../../../graphics/Vertex';
import type { Parcel } from '../Parcel';

export function moveParcelToANewPosition(deltaVector: Vector3, parcel: Parcel): void {
  const firstVertex = parcel.boundary.vertices[0];
  const lastVertex = parcel.boundary.vertices[parcel.boundary.vertices.length - 1];
  const lastOneIsRefToFirstOne = firstVertex === lastVertex;

  parcel.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
    if (index === 0 && lastOneIsRefToFirstOne) {
      return;
    }
    vertex.mesh.position.sub(deltaVector);
  });
  parcel.updateMesh();
}

export function revertMoveParcelToANewPosition(deltaVector: Vector3, parcel: Parcel): void {
  const firstVertex = parcel.boundary.vertices[0];
  const lastVertex = parcel.boundary.vertices[parcel.boundary.vertices.length - 1];
  const lastOneIsRefToFirstOne = firstVertex === lastVertex;
  parcel.boundary.vertices.forEach((vertex: Vertex, index: number): void => {
    if (index === 0 && lastOneIsRefToFirstOne) {
      return;
    }
    vertex.mesh.position.add(deltaVector);
  });
  parcel.updateMesh();
}

export function isParcelPositionValid(editor: EditorStore, parcel: Parcel): boolean {
  const parcelEdgeVertices: Vertex[] = parcel?.boundary.vertices ?? [];

  for (const vertex of parcelEdgeVertices) {
    const hasEverBeenValidAfterUserInteraction = vertex.hasEverBeenValidAfterUserInteraction;
    const isValidPosition = vertex.isValidCurrentPosition(editor);
    if (hasEverBeenValidAfterUserInteraction && !isValidPosition) {
      // // Uncomment to see why validation fails:
      // vertex.debugValidation = true;
      // vertex.isValidCurrentPosition(editor);

      return false;
    }
  }

  return true;
}
