import type { ReactElement } from 'react';
import React, {
  useContext, useEffect, useState
} from 'react';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  LyraDivider,
  LyraFormElementsDesignTool,
  LyraLayout,
  LyraList,
  LyraLoader,
  LyraModal,
  LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { ThemeContext } from 'styled-components';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import useStore from '../../../../stores/useStore';
import type { NewOrEditBuildingViewModel } from '../../../../stores/UiStore/Wizard/ViewModels/NewRoofFace/NewOrEditBuildingViewModel';
import {
  CEILING_HEIGHT_LIMIT, FOUNDATION_HEIGHT_LIMIT
} from '../../../../domain/models/Constants';

const NewOrEditBuildingModal: React.FC = (): ReactElement => {
  const { wizard } = useStore();
  const theme = useContext(ThemeContext);
  const [viewModel] = useState<NewOrEditBuildingViewModel>(wizard.currentViewModel as NewOrEditBuildingViewModel);

  useEffect((): void => {
    viewModel.enableBehaviors();
  }, [viewModel]);

  const OccupancyItems = viewModel.useAndOccupancyClassificationOptions.map(
    ({
      value, name
    }: Option): Option => ({
      value,
      name: (
        <Flexbox align="center">
          <LyraTypography.Paragraph
            fontSize="11px"
            margin="0"
            fontWeight="bold"
            lineHeight={1}
            color={EThemeComponentColor.GRAY_800}
          >
            Group {value}
          </LyraTypography.Paragraph>
          <LyraDivider.Divider
            direction="vertical"
            color={EThemeComponentColor.GRAY_600}
            thickness="2px"
            length="12px"
            className="mr-xxs ml-xxs"
          />
          {name}
        </Flexbox>
      )
    })
  );

  const modalProps = {
    title: viewModel.isEditMode ? 'Edit Building' : 'New Building',
    color: EThemeComponentColor.PURPLE,
    $show: true,
    width: '415px',
    onClickRightButton: (): void => viewModel.nextStep(),
    onClickLeftButton: (): void => viewModel.closeSteps(),
    onClickCloseButton: (): void => viewModel.closeSteps(),
    rightButtonDisabled: viewModel.disabledSave || viewModel.loading
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <LyraLayout.Row className="mb-xxs">
        <LyraLayout.Col xs={6} className="pr-xxxs">
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            name="building-name"
            label="BUILDING NAME"
            value={viewModel.name}
            hasError={!viewModel.validName}
            placeholder={viewModel.defaultNameBuilding}
            onChange={viewModel.setName}
          />
        </LyraLayout.Col>
        <LyraLayout.Col xs={6}>
          <LyraFormElementsDesignTool.FormElementsDesignTool.Toggle.Toggle
            label="SPRINKLER SYSTEM PRESENT?"
            id="sprinkler-building-creation"
            checked={viewModel.automaticSprinklerSystem}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>): void => {
              viewModel.setAutomaticSprinklerSystem(target.checked);
            }}
            toogleColorTheme={EThemeComponentColor.PURPLE}
          />
        </LyraLayout.Col>
      </LyraLayout.Row>
      {viewModel.hasBuildingHeightsData && (
        <LyraLayout.Row>
          <LyraLayout.Col xs={6} className="pr-xxxs">
            <LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField
              label="CEILING HEIGHT"
              measure="ft"
              limits={CEILING_HEIGHT_LIMIT}
              value={viewModel.ceilingHeight}
              onChange={viewModel.setCeilingHeight}
              fractionDigits={2}
            />
          </LyraLayout.Col>
          <LyraLayout.Col xs={6} className="pr-xxxs">
            <LyraFormElementsDesignTool.FormElementsDesignTool.NumberField.NumberField
              label="FOUNDATION HEIGHT"
              measure="ft"
              limits={FOUNDATION_HEIGHT_LIMIT}
              value={viewModel.foundationHeight}
              onChange={viewModel.setFoundationHeight}
              fractionDigits={2}
              step={0.5}
            />
          </LyraLayout.Col>
        </LyraLayout.Row>
      )}
      <LyraDivider.Divider className="mt-xs mb-xs" color={EThemeComponentColor.GRAY_200} direction="horizontal" />
      {viewModel.loading ? (
        <LyraLoader.Loader bgColor={theme!.colors.grayColor7} text="Loading Use and Occupancy Classifications..." />
      ) : (
        <>
          <LyraTypography.Heading fontWeight="bold" fontSize="14px" lineHeight={1} margin="0 0 10px">
            Use and Occupancy Classification
          </LyraTypography.Heading>
          <LyraList.List
            onSelect={viewModel.setUseAndOccupancyClassification}
            options={OccupancyItems}
            selected={viewModel.useAndOccupancyClassification}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />
        </>
      )}
    </LyraModal.Modal>
  );
};

export default observer(NewOrEditBuildingModal);
