import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../typings';
import { Icon } from '../Icon';

type StepProps = PropsTheme & {
  state?: 'ready' | 'active' | 'pending';
};

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 300;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const Subtitle = styled.h6`
  margin: 15px 0;
  font-size: 12px;
  font-weight: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const Label = styled.h6`
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grey1};
`;

export const LeftContainer = styled.div`
  flex: 6;
`;

export const RightContainer = styled.div`
  flex: 5;
`;

export const Divider = styled.div`
  min-height: 100%;
  width: 1.5px;
  margin: 0px 16px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.grey4};
`;

export const StepContainer = styled.div<StepProps>`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  opacity: ${({ state = 'ready' }: StepProps): string =>
    state === 'pending' ? '0.25' : '1'};
`;

export const IconContainer = styled.div`
  flex: 1;
  display: flex;

  &:first-child {
    justify-content: flex-start;
    padding-right: 8px;
  }

  &:last-child {
    justify-content: flex-end;
    padding-left: 8px;
  }
`;

export const StyledIcon = styled(Icon)`
  height: 20px;
  width: 20px;
`;

export const ButtonContainer = styled.div`
  flex: 10;
`;

export const Button = styled.div<StepProps>`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  border-radius: 16px;
  border: solid
    ${({ state = 'ready' }: StepProps): string => (state === 'ready' ? '1px' : '0px')};
  border-color: ${({ theme }: PropsTheme): Color => theme.colors.grey4};
  box-shadow: ${({ state = 'ready' }: StepProps): string =>
    state === 'ready' ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.5)'};
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
`;
