import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import styled, { withTheme } from 'styled-components';

export const CancelOptiontWrapper = styled(LyraLayout.Col)`
  background: #fafafa;
`;

export const AcceptOptionWrapper = styled(LyraLayout.Col)`
  background: #fafafa;
  button {
    width: 100%;
  }
`;
