import {
  Vector2, Vector3
} from 'three';
import Units from '../../../typings/Units';
import type { Vertex } from '../../../graphics/Vertex';
import { EOrientation } from '../../../typings/index';
import ProjectionUtil from '../../../../utils/projectionUtil';
import type { SimpleVector2 } from '../../../../utils/ThreeUtils';

export function getPvModulePositionSizeWithSpacing({
  projectedRowSpacing,
  columnSpacing,
  tiltAngleInRadians,
  parentRoofFaceSlopeInRadians,
  pvModuleDimensions,
  orientation,
  vertices
}: {
  projectedRowSpacing: number;
  columnSpacing: number;
  tiltAngleInRadians: number;
  parentRoofFaceSlopeInRadians: number;
  orientation: EOrientation;
  vertices: Vertex[];
  pvModuleDimensions?: {
    length: number;
    width: number;
  };
}): SimpleVector2 {
  const rowSpacingInMeters: number = ProjectionUtil.convertToWorldUnits(projectedRowSpacing, Units.Meters);
  const columnSpacingInMeters: number = ProjectionUtil.convertToWorldUnits(columnSpacing, Units.Meters);

  if (pvModuleDimensions) {
    const pvModuleLengthInMeters =
      ProjectionUtil.convertToWorldUnits(pvModuleDimensions.length, Units.Meters)
      * (orientation === EOrientation.PORTRAIT ? Math.cos(tiltAngleInRadians) : 1);
    const pvModuleWidthInMeters =
      ProjectionUtil.convertToWorldUnits(pvModuleDimensions.width, Units.Meters)
      * (orientation === EOrientation.LANDSCAPE ? Math.cos(tiltAngleInRadians) : 1);

    let result: { x: number; y: number };
    if (orientation === EOrientation.LANDSCAPE) {
      result = {
        x: pvModuleLengthInMeters + columnSpacingInMeters,
        y: pvModuleWidthInMeters * Math.cos(parentRoofFaceSlopeInRadians) + rowSpacingInMeters
      };
    } else {
      result = {
        x: pvModuleWidthInMeters + columnSpacingInMeters,
        y: pvModuleLengthInMeters * Math.cos(parentRoofFaceSlopeInRadians) + rowSpacingInMeters
      };
    }
    return result;
  }

  // Fallback in case supplemental data is corrupted:
  // (may not work on extremely steep slopes)

  const side1 = new Vector2(vertices[0].x, vertices[0].y).distanceTo(new Vector2(vertices[1].x, vertices[1].y));
  const side2 = new Vector2(vertices[1].x, vertices[1].y).distanceTo(new Vector2(vertices[2].x, vertices[2].y));

  const smallerSide = Math.min(side1, side2);
  const largerSide = Math.max(side1, side2);

  const positionSizeWithSpacing = {
    x: smallerSide,
    y: largerSide
  };

  if (orientation === 'LANDSCAPE') {
    // swap values
    [positionSizeWithSpacing.x, positionSizeWithSpacing.y] = [positionSizeWithSpacing.y, positionSizeWithSpacing.x];
  }

  positionSizeWithSpacing.x += columnSpacingInMeters;
  positionSizeWithSpacing.y += rowSpacingInMeters;

  return positionSizeWithSpacing;
}

export function getVerticesWithSpacing({
  pvModulePositionCenter,
  pvModulePanelSizeWithSpacing,
  azimuth,
  calculateZ
}: {
  pvModulePositionCenter: Vector3;
  pvModulePanelSizeWithSpacing: SimpleVector2;
  azimuth: number;
  calculateZ: (v: Vector3) => number;
}): Vector3[] {
  const center = pvModulePositionCenter;
  const panelSize = pvModulePanelSizeWithSpacing;

  const expandedVertices = [
    new Vector2(center.x - panelSize.x / 2, center.y - panelSize.y / 2),
    new Vector2(center.x + panelSize.x / 2, center.y - panelSize.y / 2),
    new Vector2(center.x + panelSize.x / 2, center.y + panelSize.y / 2),
    new Vector2(center.x - panelSize.x / 2, center.y + panelSize.y / 2)
  ];

  const finalVertices: Vector3[] = [];
  expandedVertices.forEach((vertex: Vector2): void => {
    vertex.rotateAround(new Vector2(center.x, center.y), azimuth);
    const vector3: Vector3 = new Vector3(vertex.x, vertex.y, 0);
    const vertexWithUpdatedZValue = vector3.setZ(calculateZ(vector3));
    finalVertices.push(vertexWithUpdatedZValue);
  });
  finalVertices.push(finalVertices[0].clone());

  return finalVertices;
}
