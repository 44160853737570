import React, { BaseProps, ReactElement, useEffect, useState } from 'react';
import { Icon } from '../../Icon';
import { EThemeComponentColor } from '../../Theme/EThemeComponentColor';
import { RightIconsContainer } from '../styles';

export type RightIconsProps = BaseProps & {
  theme?: 'light' | 'dark';
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

const RightIcons = (props: RightIconsProps): ReactElement => {
  const { theme = 'light', onDeleteClick, onEditClick, onVisibilityToggle } = props;
  const [visible, setVisible] = useState(true);
  const iconColor =
    theme === 'light' ? EThemeComponentColor.WHITE : EThemeComponentColor.GRAY_700;

  const handleVisibilityToggle = (newValue: boolean): void => {
    setVisible(newValue);
    onVisibilityToggle?.(newValue);
  };

  return (
    <RightIconsContainer>
      {onEditClick && (
        <Icon
          name="pencil"
          colorTheme={iconColor}
          data-testid="right-edit-button"
          onClick={onEditClick}
        />
      )}
      { onVisibilityToggle
          && <Icon
              name={visible ? 'showHideElements' : 'hide-icon'}
              colorTheme={iconColor}
              onMouseDown={(): void => handleVisibilityToggle(false)}
              onMouseUp={(): void => handleVisibilityToggle(true)}
          />
      }
      {
        onDeleteClick
        && <Icon
          name="icon-delete"
          colorTheme={iconColor}
          data-testid="right-delete-button"
          onClick={onDeleteClick}
        />
      }
    </RightIconsContainer>
  );
};

export { RightIcons };
