import type { Vector3 } from 'three';
import type { Boundary } from '../../../domain/graphics/Boundary';
import type { Drawable } from '../../../domain/mixins/Drawable';
import type { ILineSegment } from '../../../utils/spatial';

enum KindGuides {
  TRACE_TOOL = 'TRACE_TOOL',
  MOVE_VERTEX = 'MOVE_VERTEX',
  MOVE_OBJECT = 'MOVE_OBJECT'
}

interface IApplyParams {
  kind: KindGuides;
  vertexId?: string;
  mousePos?: Vector3;
  wipBoundary?: Boundary;
  vertexObject?: Vector3[];
  wipObject?: Drawable;
  ignorePvModulePositionServerIds?: string[];
  possibleLineSnapTarget?: ILineSegment;
}

export {
  IApplyParams, KindGuides
};
