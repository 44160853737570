import type { Intersection } from 'three';
import PvModulePosition from '../../../../domain/models/SiteDesign/PvModulePosition';
import { MouseBehaviour } from '../../../../domain/behaviour/MouseBehaviour';
import { LayoutDesignStage } from '../../../../domain/stages/DesignStages/LayoutDesignStage';
import type { IStage } from '../../../../domain/stages/IStage';
import type DomainStore from '../../../DomainStore/DomainStore';
import { BaseCastObjectControl } from '../../../EditorStore/Controls/BaseCastObjectControl';
import type { PropertiesStore } from '../../Properties/Properties';
import type { WorkspaceStore } from '../../WorkspaceStore';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import type { IUpdatePvModulePositions } from '../../../ServiceBus/Commands/UpdatePvModulePositionsCommand';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import {
  getLyraModelByMesh, getLyraModelByOptionalMesh
} from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { CHANGE_ORIENTATION_ID } from './constants';

export interface IChangeOrientationToolDependencies extends IBaseToolDependencies {
  properties: PropertiesStore;
  domain: DomainStore;
  workspace: WorkspaceStore;
}

export class ChangeOrientationTool extends BaseTool {
  id: string = CHANGE_ORIENTATION_ID;
  icon: string = 'change-module';
  title: string = 'Change Orientation';
  description: string = this.title;
  showSubmenu: boolean = false;
  private currentStage?: IStage;
  private castPvModulePositionControl?: BaseCastObjectControl;
  private workspace: WorkspaceStore;
  private mouseBehaviour: MouseBehaviour;
  private domain: DomainStore;
  override testId: string = 'ChangeOrientationTool';

  constructor(dependencies: IChangeOrientationToolDependencies) {
    super(dependencies);
    const {
      workspace, domain
    } = dependencies;
    this.workspace = workspace;
    this.domain = domain;
    this.mouseBehaviour = new MouseBehaviour(this.editor);
  }

  whenSelected(): void {
    this.init();
    this.initListeners();
  }

  whenDeselected(): void {
    this.removeListeners();
  }

  dispose(): void {
    /** */
  }

  override onMouseDown = (): void => {
    const intersected: Intersection[] = this.castPvModulePositionControl!.getCastedObjects();
    this.reactionToSelection(intersected);
  };

  private init(): void {
    this.configureBaseCastObjectControl();
    const currentStage = this.workspace.currentWorkspace.stageManager?.currentStage;
    if (currentStage) {
      this.currentStage = currentStage;
    }
    this.currentStage?.setUpTool?.(CHANGE_ORIENTATION_ID);
  }

  private initListeners(): void {
    this.mouseBehaviour.addMouseClickEvents(this);
  }

  private removeListeners(): void {
    this.mouseBehaviour.removeMouseClickEvents(this);
  }

  private configureBaseCastObjectControl(): void {
    const pvModulePositions: Selectable[] = this.editor.getObjectsByType(SceneObjectType.RoofFace, true);
    this.castPvModulePositionControl = new BaseCastObjectControl(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera!,
      pvModulePositions
    );
  }

  private reactionToSelection(intersected: Intersection[]): void {
    if (intersected.length > 0) {
      const instance = this.getInstanceToRotate(intersected);
      if (instance) {
        instance.changeOrientation(this.editor);
        if (this.currentStage instanceof LayoutDesignStage) {
          this.currentStage.updateAllPositionsCollision(this.editor);
        }
        const commandDependencies: IUpdatePvModulePositions = {
          domain: this.domain,
          pvModulePositions: [instance]
        };
        this.serviceBus.send('update_pv_module_position', commandDependencies);
      }
    }
  }

  private getInstanceToRotate(intersection: Intersection[]): PvModulePosition | undefined {
    const firstIntersectedPosition: Intersection | undefined = intersection.find(
      (element: Intersection): boolean => getLyraModelByMesh(element.object) instanceof PvModulePosition
    );
    return getLyraModelByOptionalMesh(firstIntersectedPosition?.object);
  }
}
