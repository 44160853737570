import styled from 'styled-components';
import { LyraIcon } from '@aurorasolar/lyra-ui-kit';

export const EditButton = styled(LyraIcon.Icon)`
  display: none;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-left: -20px;
`;

export const TitleContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover ${EditButton} {
    display: block;
  }
`;

export const TitleInput = styled.input`
  width: 100%;
  min-width: 100px;
  box-sizing: border-box;
`;

export const Text = styled.span`
  min-height: 20px;
  min-width: 10px;
  margin-right: 25px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
