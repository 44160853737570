import sumBy from 'lodash/sumBy';
import {
  action, computed
} from 'mobx';
import flatMap from 'lodash/flatMap';
import type { IPolygon } from '../Polygon/IPolygon';
import type { EOrientation } from '../../typings/index';
import type {
  IFireVentilationData, RoofFaceRestrictedAreas
} from './FireVentilation';
import { FireVentilation } from './FireVentilation';
import { Layout } from './Layout';
import type {
  ILayoutData, IPvModulePositionData
} from './Layout';

export interface IRoofTopArrayAreasData {
  readonly layouts: ILayoutData[];
  readonly fireVentilation: IFireVentilationData;
}

export class RoofTopArrayAreas {
  readonly layouts: Layout[];
  readonly fireVentilation: FireVentilation;

  constructor(data?: IRoofTopArrayAreasData) {
    this.layouts = data?.layouts.map((layoutData: ILayoutData): Layout => new Layout(layoutData)) ?? [];
    this.fireVentilation = new FireVentilation(data?.fireVentilation);
  }

  toData = (): IRoofTopArrayAreasData | undefined => {
    if (this.layouts.length === 0 && this.fireVentilation.isEmpty) {
      return undefined;
    }
    return {
      layouts: this.layouts.map((layout: Layout): ILayoutData => layout.toData()),
      fireVentilation: this.fireVentilation.toData()
    };
  };

  layoutOn = (roofFaceId: string): Layout | undefined => {
    return this.layouts.find((layout: Layout): boolean => layout.arrayAreaId === roofFaceId);
  };

  hasLayoutOn = (roofFaceId: string): boolean => {
    return this.layoutOn(roofFaceId) !== undefined;
  };

  restrictedAreasOn = (roofFaceId: string): RoofFaceRestrictedAreas | undefined => {
    return this.fireVentilation.restrictedAreasOn(roofFaceId);
  };

  totalSizeInWatts = (pvModulePowerRating: number): number => {
    return sumBy(this.layouts, (layout: Layout): number => layout.sizeInWatts(pvModulePowerRating));
  };

  energyProductionEstimateInKwh = (idsOfPositionsToInclude: readonly string[]): number => {
    return sumBy(this.layouts, (layout: Layout): number =>
      layout.energyProductionEstimateInKwh(idsOfPositionsToInclude)
    );
  };

  @action
  deletePvModulePositions = (positionIds: readonly string[]): void => {
    this.layouts.forEach((layout: Layout): void => layout.deletePvModulePositions(positionIds));
  };

  @action
  addPvModulePosition(roofFaceId: string, positionId: string, orientation: EOrientation, polygon: IPolygon) {
    this.layoutOn(roofFaceId)!.addPvModulePosition(positionId, orientation, polygon);
  }

  @computed
  get pvModulePositionIds() {
    return flatMap(this.layouts, (layout: ILayoutData): string[] =>
      layout.positions.map((position: IPvModulePositionData): string => position.id)
    );
  }
}
