import {
  LyraFormElementsDesignTool, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, FC
} from 'react';
import React, {
  useState, useCallback
} from 'react';
import type { IDropDownOption } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownNew';
import useStore from '../../../../../stores/useStore';
import type { UpsertInstallerAndCreateProjectViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import Field from '../../../../components/Field';
import { statesDropdownList } from '../../../../../constants/statesList';
import {
  AddressHorizontalItemWrapper,
  AddressWrapper,
  CenteredTitle,
  CreateInstallerStep1Wrapper,
  CreateProjectFieldRowWrapper,
  CreateProjectSubTitle,
  HelpText,
  OtherInformationItemWrapper,
  OtherInformationWrapper
} from './../styles';

export const AddressAndPhoneNumberStep: FC = observer((): ReactElement => {
  // Setting stores used in the component
  const { modal } = useStore();

  // Setting view model used by the component
  const [upsertInstallerAndCreateProjectViewModel] = useState<UpsertInstallerAndCreateProjectViewModel>(
    modal.viewModel as UpsertInstallerAndCreateProjectViewModel
  );

  const {
    startupMode, phoneNumber
  } = upsertInstallerAndCreateProjectViewModel;

  const getStateDropdownOption = useCallback((value: string): IDropDownOption => {
    return statesDropdownList.find((item: IDropDownOption): boolean => item.value === value)!;
  }, []);

  return (
    <CreateInstallerStep1Wrapper>
      <CenteredTitle>
        <LyraTypography.Heading type="h2" fontWeight="300">
          Additional Company Information
        </LyraTypography.Heading>
      </CenteredTitle>
      {startupMode && <HelpText>This information will appear on the permit package.</HelpText>}
      <CreateProjectSubTitle>COMPANY ADDRESS</CreateProjectSubTitle>
      <CreateProjectFieldRowWrapper>
        <Field className="mr-xxs" label="ADDRESS 1">
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            onChange={upsertInstallerAndCreateProjectViewModel.setInstallerAddress1}
            value={upsertInstallerAndCreateProjectViewModel.installerAddress.addressOne}
          />
        </Field>
      </CreateProjectFieldRowWrapper>
      <CreateProjectFieldRowWrapper>
        <Field className="mr-xxs" label="ADDRESS 2">
          <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
            onChange={upsertInstallerAndCreateProjectViewModel.setInstallerAddress2}
            value={upsertInstallerAndCreateProjectViewModel.installerAddress.addressTwo}
            placeholder="Optional"
          />
        </Field>
      </CreateProjectFieldRowWrapper>

      <AddressWrapper>
        <AddressHorizontalItemWrapper>
          <Field className="mr-xxs" label="CITY">
            <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
              onChange={upsertInstallerAndCreateProjectViewModel.setInstallerCity}
              value={upsertInstallerAndCreateProjectViewModel.installerAddress.city}
            />
          </Field>
        </AddressHorizontalItemWrapper>
        <AddressHorizontalItemWrapper
          style={{
            minWidth: '150px'
          }}
        >
          <Field className="mr-xxs" label="STATE">
            <LyraFormElementsDesignTool.FormElementsDesignTool.DropDownNew.DropDownNew
              options={statesDropdownList}
              value={getStateDropdownOption(upsertInstallerAndCreateProjectViewModel.installerAddress.province)}
              menuPortalTarget={document.getElementById('lyra-dropdown-portal')}
              onChange={(newValue: unknown): void => {
                upsertInstallerAndCreateProjectViewModel.setInstallerState((newValue as IDropDownOption).value);
              }}
            />
          </Field>
        </AddressHorizontalItemWrapper>
        <AddressHorizontalItemWrapper>
          <Field className="mr-xxs" label="ZIP CODE">
            <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
              onChange={upsertInstallerAndCreateProjectViewModel.setInstallerPostalCode}
              value={upsertInstallerAndCreateProjectViewModel.installerAddress.postalCode}
            />
          </Field>
        </AddressHorizontalItemWrapper>
      </AddressWrapper>

      <CreateProjectSubTitle>OTHER INFORMATION</CreateProjectSubTitle>
      <OtherInformationWrapper>
        <OtherInformationItemWrapper>
          <Field className="mr-xxs" label="PHONE NUMBER">
            <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
              onChange={upsertInstallerAndCreateProjectViewModel.setPhoneNumber}
              value={phoneNumber}
              placeholder="Optional"
            />
          </Field>
        </OtherInformationItemWrapper>
      </OtherInformationWrapper>
    </CreateInstallerStep1Wrapper>
  );
});
