import styled from 'styled-components';
import type { Color } from '../../../../../../domain/typings';

type SiteEquipmentWrapperTypes = {
  colors: Color[];
};

export const SubToolSiteEquipmentWrapper = styled.div<SiteEquipmentWrapperTypes>`
  ${({ colors }: SiteEquipmentWrapperTypes): string => {
    let styleColors: string = '';
    colors.forEach((color: Color, index: number): void => {
      styleColors = styleColors.concat(`div:nth-child(${index + 1}) {
            div {
              background-color: ${color};
            }
        };`);
    });
    return styleColors;
  }}

  div {
    > span {
      font-size: 13px;
      font-weight: bold;
      letter-spacing: -0.46px;
      color: white;
    }
  }
`;
