import {
  action, observable
} from 'mobx';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type { RectangleProtrusion } from '../../../../domain/models/SiteDesign/RectangleProtrusion';
import type DomainStore from '../../../DomainStore/DomainStore';
import type EditorStore from '../../../EditorStore/EditorStore';
import type Store from '../../../Store';
import type { IControlSelectionChange } from '../../../EditorStore/Controls/ControlEvents';
import { SelectionControl } from '../../../EditorStore/Controls/SelectionControl';
import type { ServiceBus } from '../../../ServiceBus/ServiceBus';
import type {
  IPanelChangedEvent, PropertiesStore
} from '../Properties';
import { SceneObjectType } from '../../../../domain/models/Constants';
import type { UiStore } from '../../UiStore';
import type {
  IRoofProtrusionViewModel, ProtrusionType
} from './RoofProtrusionViewModel';
import { RoofProtrusionViewModel } from './RoofProtrusionViewModel';

export class RoofProtrusionStore {
  @observable
  roofProtrusions: IRoofProtrusionViewModel[] = [];
  @observable
  currentRoofProtrusion?: IRoofProtrusionViewModel;

  private editor: EditorStore;
  private domain: DomainStore;
  private properties: PropertiesStore;
  private selectionControl?: SelectionControl;
  private serviceBus: ServiceBus;

  constructor(root: Store, uiStore: UiStore) {
    this.editor = root.editor;
    this.domain = root.domain;
    this.properties = uiStore.properties;
    this.serviceBus = root.serviceBus;

    this.editor.addEventListener('canvasReady', this.setup);
  }

  setup = (): void => {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    this.properties.addEventListener('panel_changed', this.onPanelChanged);
    this.selectionControl.addEventListener('selection_change', this.onSelectionChange);
  };

  @action.bound
  createProtrusionViewModel(
    type: ProtrusionType,
    roofProtrusion: RectangleProtrusion,
    roofFace: RoofFace
  ): IRoofProtrusionViewModel {
    this.unSelectAll();
    // Create ViewModel
    const viewModel: IRoofProtrusionViewModel = new RoofProtrusionViewModel(
      this.domain,
      this.serviceBus,
      type,
      roofProtrusion,
      roofFace
    );
    this.roofProtrusions.push(viewModel);
    // Set current protrusion
    this.currentRoofProtrusion = viewModel;
    // Select current protrusion
    viewModel.roofProtrusion.setSelectedMode(false);
    return viewModel;
  }

  unSelectAll(): void {
    this.roofProtrusions.forEach((roofProtVM: IRoofProtrusionViewModel): void =>
      roofProtVM.roofProtrusion.setSelectedMode(false)
    );
  }

  private onSelectionChange = (event: IControlSelectionChange): void => {
    if (event.selection === undefined) {
      return;
    }
    const { selection } = event;
    const idProtrusions = selection.filter(
      (object: Selectable): boolean => object.type === SceneObjectType.Protrusion && !!object.serverId
    );
    if (idProtrusions.length > 0) {
      // Search in roofViewmodelArray the first selected and set this to current
      this.currentRoofProtrusion = this.roofProtrusions.filter(
        (roofProt: IRoofProtrusionViewModel): boolean =>
          roofProt.roofProtrusion && roofProt.roofProtrusion.serverId === idProtrusions[0].serverId
      )[0];
    }
  };

  private onPanelChanged = (event: IPanelChangedEvent): void => {
    const { showingPanelOf } = event;
    if (showingPanelOf === undefined) {
      return;
    }
  };
}
