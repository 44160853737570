import {
  action, computed, observable
} from 'mobx';
import isNil from 'lodash/isNil';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import type Limit from '../Limit';
import type { ButtonOption } from '../../../ui/components/ButtonGroup/ButtonGroup';
import {
  convertMeterToInches, convertInchesToMeters
} from '../../../utils/Convertions';

export interface IMountingSystemConfigurationData {
  readonly azimuth?: number;
  readonly tiltAngle: number;
  readonly numberOfRowsInRack: number;
  readonly rackSpacing: IRackSpacingData;
}

export class Configuration {
  @observable
  azimuth?: number;
  @observable
  tiltAngle: number;
  @observable
  numberOfRowsInRack: number;
  @observable
  rackSpacing: RackSpacing;

  constructor(data: IMountingSystemConfigurationData) {
    this.azimuth = data.azimuth;
    this.tiltAngle = data.tiltAngle;
    this.numberOfRowsInRack = data.numberOfRowsInRack;
    this.rackSpacing = new RackSpacing(data.rackSpacing);
  }

  toData(): IMountingSystemConfigurationData {
    return {
      azimuth: this.azimuth,
      tiltAngle: this.tiltAngle,
      numberOfRowsInRack: this.numberOfRowsInRack,
      rackSpacing: this.rackSpacing.toData()
    };
  }

  copy = (): Configuration => new Configuration(this.toData());
}

interface IRackSpacingData {
  autoForDesignMonth?: string;
  value?: number;
}

export class RackSpacing {
  static readonly AUTOMATIC_SPACING_YES_OPTION: OptionProps = {
    name: 'Yes',
    value: String(true)
  };
  static readonly AUTOMATIC_SPACING_NO_OPTION: OptionProps = {
    name: 'No',
    value: String(false)
  };
  static readonly AUTOMATIC_SPACING_GRID_OPTIONS: OptionProps[] = [
    RackSpacing.AUTOMATIC_SPACING_YES_OPTION,
    RackSpacing.AUTOMATIC_SPACING_NO_OPTION
  ];
  static readonly AUTOMATIC_SPACING_BUTTON_OPTIONS: ButtonOption[] = RackSpacing.AUTOMATIC_SPACING_GRID_OPTIONS;
  static readonly DESIGN_MONTH_OPTIONS: ButtonOption[] = [
    {
      value: 'DEC',
      name: 'Dec'
    },
    {
      value: 'JAN_NOV',
      name: 'Jan-Nov'
    },
    {
      value: 'FEB_OCT',
      name: 'Feb-Oct'
    },
    {
      value: 'MAR_SEP',
      name: 'Mar-Sep'
    },
    {
      value: 'APR_AUG',
      name: 'Apr-Aug'
    },
    {
      value: 'MAY_JUL',
      name: 'May-Jul'
    },
    {
      value: 'JUN',
      name: 'Jun'
    }
  ];
  static readonly EXACT_VALUE_LIMIT_IN_INCHES: Limit = {
    lower: 1,
    upper: 9999
  };

  static initial = (): RackSpacing =>
    new RackSpacing({
      autoForDesignMonth: 'DEC'
    });

  /**
   * If optimal rack spacing should be computed automatically on the backend,
   * this will hold the month of year for which to optimize.
   */
  @observable
  autoForDesignMonth?: string;
  /**
   * If automatic calculation is selected, then this will hold the value computed on the backend.
   * Otherwise this holds the user-entered spacing value.
   *
   * Note: the value is in meters.
   */
  @observable
  value?: number;

  /**
   * Constructor for creating a class instance from data of the same structure
   * @see IRackSpacingData
   */
  constructor(data: IRackSpacingData) {
    this.autoForDesignMonth = data.autoForDesignMonth;
    this.value = data.value;
  }

  toData(): IRackSpacingData {
    return {
      autoForDesignMonth: this.autoForDesignMonth,
      value: this.value
    };
  }

  copy = (): RackSpacing => new RackSpacing(this.toData());

  isValid = (automaticSelected: boolean): boolean => {
    return automaticSelected ? !isNil(this.autoForDesignMonth) : !isNil(this.value);
  };

  @computed
  get isAutomatic(): boolean {
    return !isNil(this.autoForDesignMonth);
  }

  /**
   * Sets the design month for which to optimize rack spacing. Exact value is then calculated on the backend.
   */
  @action
  setDesignMonthForAutomaticSpacing = (designMonth: string): void => {
    const designMonthIsValid = RackSpacing.DESIGN_MONTH_OPTIONS.some(
      (option: ButtonOption): boolean => designMonth === option.value
    );
    this.autoForDesignMonth = designMonthIsValid ? designMonth : undefined;
    this.value = undefined;
  };

  @computed
  get exactValueInInchesRoundedToAWholeNumber(): number | undefined {
    return !isNil(this.value) ? Math.round(convertMeterToInches(this.value)) : undefined;
  }

  /**
   * Sets the exact user-provided value (and disables automatic spacing if it was enabled previously)
   */
  @action
  setExactValueInInches = (valueInInches: number): void => {
    const valueInInchesIsValid =
      valueInInches >= RackSpacing.EXACT_VALUE_LIMIT_IN_INCHES.lower
      && valueInInches <= RackSpacing.EXACT_VALUE_LIMIT_IN_INCHES.upper;
    this.autoForDesignMonth = undefined;
    this.value = valueInInchesIsValid ? convertInchesToMeters(valueInInches) : undefined;
  };

  @computed
  get asText(): string {
    const spacingInInches = this.exactValueInInchesRoundedToAWholeNumber;
    return isNil(spacingInInches) ? 'Automatic rack spacing' : `${spacingInInches}″ rack spacing`;
  }
}
