import type { PropsTheme } from '@aurorasolar/lyra-ui-kit';
import {
  LyraIcon, LyraLayout
} from '@aurorasolar/lyra-ui-kit';
import styled from 'styled-components';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Button from '../../../components/Button';
import Flexbox from '../../../components/Layout/Flexbox';
import Section from '../../../components/Section';

type OrientationContentWrapperProps = PropsTheme & {
  selected: boolean;
};

export const ContentStyle = styled(Flexbox).attrs({
  dir: 'row',
  wrap: 'nowrap'
})`
  box-sizing: border-box;
  align-items: start;
`;

export const ContentLayout = styled(Flexbox).attrs({
  dir: 'column',
  align: 'initial'
})`
  width: 460px;
`;

export const OrientationContentWrapper = styled(LyraLayout.Flexbox).attrs({
  dir: 'column',
  justify: 'space-evenly',
  align: 'center'
})<OrientationContentWrapperProps>`
  width: 124px;
  height: 114px;
  font-weight: bold;
  font-size: 12px;
  ${({
    selected, theme
  }: OrientationContentWrapperProps): string =>
    `background-color: ${selected ? theme.colors.whiteBg : theme.colors.grayColor8}`}
`;

export const OrientationContentIcon = styled(LyraIcon.Icon)`
  width: 58px;
  height: 58px;
`;

export const LayoutStrategySection = styled(Section)`
  display: flex;
  justify-content: center;
`;

export const Row = styled(Flexbox)`
  align-items: center;
  width: auto;

  > div {
    margin-right: 25px;
    width: auto;
  }
`;

export const HoverAlignmentFlexbox = styled(Flexbox).attrs({
  dir: 'column'
})`
  justify-content: space-evenly;
  text-align: center;
  height: 215px;
`;

export const HoverAlignmentContainer = styled.div`
  width: 100%;
  font-size: 14px;

  & svg {
    width: inherit;
    height: 160px;
  }
`;

export const SquareButton = styled(Button).attrs({
  type: 'square'
})`
  height: 38px;
  font-size: 13px;

  & svg {
    margin: 0 !important;
    margin-right: 3px !important;
    height: 19px;
  }
`;

export const RowAlignmentBtnGroup = styled(ButtonGroup)`
  flex-wrap: nowrap;
  width: auto;
  button {
    border: none;
    background: inherit;
    font-weight: normal;
    :hover {
      border: none;
      background: inherit;
    }
  }
`;
