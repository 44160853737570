const LocalStorage = window.localStorage;
const SessionStorage = window.sessionStorage;

export function addListener(callback: EmptyFunc): void {
  window.addEventListener('storage', callback);
}

export function get<T>(key: string, throwErrorIfAbsent: boolean = true): T {
  const savedValue = LocalStorage.getItem(key);
  if (throwErrorIfAbsent && savedValue === null) {
    throw new Error(`The key storage '${key}' doesn't exist`);
  }
  return (savedValue === null ? savedValue : JSON.parse(savedValue)) as T;
}

export function set<T>(key: string, value: T): void {
  LocalStorage.setItem(key, JSON.stringify(value));
}

export function clear(key?: string): void {
  if (key !== undefined && key !== null) {
    LocalStorage.removeItem(key);
  } else {
    LocalStorage.clear();
  }
}

export function getSessionStorage<T>(key: string): T | null {
  const savedValue = SessionStorage.getItem(key);
  if (savedValue !== null) {
    return JSON.parse(savedValue) as T;
  }
  return savedValue;
}

export function setSessionStorage<T>(key: string, value: T): void {
  SessionStorage.setItem(key, JSON.stringify(value));
}

export function clearSessionStorage(key?: string): void {
  if (key !== undefined && key !== null) {
    SessionStorage.removeItem(key);
  } else {
    SessionStorage.clear();
  }
}
