import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';
import { H4 } from '../../FontFamily/Fonts';

type DropdownStyleType = PropsTheme & {
  fixHeight?: boolean;
};

export const HeaderText = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const ClearButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.hintBg};
  margin-left: -1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  path {
    fill: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  border: 0.8px solid ${({ theme }: PropsTheme): Color => theme.colors.neutral};
  border-top: 0;
  border-left: 0;
  border-right: 0;
  &:hover > * {
    color: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
    background: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor4};
    border-color: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
  }
  &.selected > * {
    color: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
    border-color: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
  }
  &:hover,
  &.selected > div > div > svg {
    path {
      fill: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
    }
  }
`;

export const DropdownStyled = styled.div`
  width: 100%;
  background: ${(props: DropdownStyleType): Color => props.theme.colors.secondary};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  color: ${(props: DropdownStyleType): Color => props.theme.colors.grayColor6};
  height: 34px;
  display: inline-block;
  padding: 0 10px;
`;

export const DropdownBtn = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropdownContent = styled.div<DropdownStyleType>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: -1px;
  left: -1px;
  width: 100%;
  max-height: ${({ fixHeight }: DropdownStyleType): string => fixHeight ? '200px' : ''};
  overflow-y: ${({ fixHeight }: DropdownStyleType): string => fixHeight ? 'auto' : ''};
`;

export const DropdownContentPanel = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
`;

export const DropdownContentLink = styled(H4)`
  float: none;
  padding: 7px 15px;
  height: 31px;
  text-decoration: none;
  display: block;
  text-align: left;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
  }
  z-index: 1;
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const LeftIcon = styled.div`
  margin-left: 5px;
  line-height: 1;
  margin-right: 5px;
`;

export const LinkText = styled.span`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;
