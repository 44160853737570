import React, { Key, ReactElement, useCallback, useState } from 'react';
import { Label, LabelProps } from '../HALabel/index';
import { Container, InputField } from './styles';

type HATextFieldProps = LabelProps &
{
  placeholder?: string;
  type: string;
  min?: number;
  max?: number;
  width?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  onKeyUp?: (value: React.KeyboardEvent) => void;
};

const HATextField = ({
  className,
  color,
  name,
  value,
  label,
  type,
  placeholder,
  width,
  min,
  max,
  onChange,
  onKeyUp
}: HATextFieldProps): ReactElement => {
  const [text, setText] = useState<string | number>(value ? value : '');
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>): void => {
      if (typeof onChange === 'function') {
        onChange(event.currentTarget.value);
      }
      setText(event.currentTarget.value);
    },
    [text]
  );

  const onKeyPressed = (e: React.KeyboardEvent): void =>
    onKeyUp && onKeyUp(e);

  return (
    <Container width={width ? width : '100%'} className={className}>
      <Label
        className={className}
        color={color}
        name={name}
        label={label}
      />
      <InputField
        type={type}
        onChange={handleChange}
        onKeyUp={onKeyPressed}
        placeholder={placeholder}
        value={value}
      />
    </Container>
  );
};

export { HATextField, HATextFieldProps };
