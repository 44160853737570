import { LyraModal } from '@aurorasolar/lyra-ui-kit';
import { ButtonGroup } from '@aurorasolar/lyra-ui-kit/lib/components/ButtonGroup';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { WindExposureViewModel } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/WindExposureViewModel';
import { useUiStore } from '../../../../stores/useStore';
import IconMapper from '../../../components/Icons';
import { CategoryText } from './styles';

export type WindExposureCategoryProp = {
  key: string;
  text: string;
  image?: ReactElement;
};

export const windExposureCategories: WindExposureCategoryProp[] = [
  {
    key: 'B',
    text:
      'Urban and suburban areas, wooded areas or other terrain with numerous closely spaced obstructions having'
      + ' the size of single-family dwellings or larger.'
  },
  {
    key: 'C',
    text:
      'Open terrain with scattered obstructions, including surface undulations or other irregularities, having '
      + 'heights generally less than 30 feet (9144 mm) extending more than 1,500 feet (457.2 m) from the building '
      + 'site in any quadrant. This exposure shall also apply to any building located within Exposure B-type '
      + 'terrain where the building is directly adjacent to open areas of ExposureC-type terrain in any quadrant '
      + 'for a distance of more than 600 feet (182.9 m). This category includes flat open country, grasslands and '
      + 'shorelines in hurricane-prone regions. '
  },
  {
    key: 'D',
    text:
      'Flat, unobstructed areas exposed to wind flowing over open water (excluding shorelines in hurricane-'
      + 'prone regions) for a distance of at least 1 mile (1.61 km). Shorelines in Exposure D include inland '
      + 'waterways, the Great Lakes and coastal areas of California, Oregon, Washington and Alaska. This exposure '
      + 'shall apply only to thos buildings and other structures exposed to the wind coming from over the water. '
      + 'Exposure D extends inland from the shoreline a distance of 1,500 feet (460 m) or 10 times the height of '
      + 'the building or structure, whichever is greater.'
  }
];

const SiteTemperatureModal = (): ReactElement => {
  const { modal } = useUiStore();
  const [windExposureViewModel] = React.useState(modal.viewModel as WindExposureViewModel);

  const displayTextCategory = (): WindExposureCategoryProp | undefined => {
    const iconB = <IconMapper name="wind-cat-b" />;
    const iconC = <IconMapper name="wind-cat-c" />;
    const iconD = <IconMapper name="wind-cat-d" />;
    const icons = [iconB, iconC, iconD];

    return windExposureCategories
      .map(
        (value: WindExposureCategoryProp, index: number): WindExposureCategoryProp => ({
          ...value,
          image: icons[index]
        })
      )
      .find(
        (currentCategory: WindExposureCategoryProp): boolean =>
          currentCategory.key === windExposureViewModel.selectedWindExposureCategory
      );
  };

  const getImage = (): ReactElement | null => {
    const category: WindExposureCategoryProp | undefined = displayTextCategory();
    return category?.image || null;
  };

  const getText = (): string => {
    const category: WindExposureCategoryProp | undefined = displayTextCategory();
    return category?.image ? category.text || '' : '';
  };

  return useObserver(
    (): ReactElement => (
      <LyraModal.Modal
        $show={true}
        title="Edit Wind Exposure Category"
        color={EThemeComponentColor.PURPLE}
        rightButtonDisabled={!windExposureViewModel.canSave}
        onClickLeftButton={windExposureViewModel.closeModal}
        onClickRightButton={windExposureViewModel.saveWindExposureCategory}
        onClickCloseButton={windExposureViewModel.closeModal}
      >
        <Flexbox
          dir="column"
          className="p-xxs"
          style={{
            width: 450
          }}
        >
          {getImage()}
          <CategoryText>{getText()}</CategoryText>
          <ButtonGroup
            options={[
              {
                name: 'B',
                value: 'B'
              },
              {
                name: 'C',
                value: 'C'
              },
              {
                name: 'D',
                value: 'D'
              }
            ]}
            height="30px"
            selectedOptionColor={EThemeComponentColor.PURPLE}
            onSelect={windExposureViewModel.handleOptionChange}
            selected={windExposureViewModel.selectedWindExposureCategory}
          />
        </Flexbox>
      </LyraModal.Modal>
    )
  );
};

export default observer(SiteTemperatureModal);
