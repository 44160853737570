import type { ReactElement } from 'react';
import React from 'react';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import type {
  EnumOptionsType, WidgetProps
} from '@rjsf/utils/lib/types';
import {
  selectContentTitle, selectContentWrapper, selectDiscontinued
} from './lyraSelectStyle';

const { Select } = LyraFormElementsDesignTool.FormElementsDesignTool;

const LyraSelect = (props: WidgetProps): ReactElement => {
  const {
    schema, value, options, onChange, id
  } = props;
  const {
    enumOptions, enumDisabled
  } = options;

  const getSelectValue = (
    option: EnumOptionsType,
    description: string,
    disabled: boolean,
    tags: string[]
  ) => {
    const discontinued = tags?.length > 0 && tags?.includes('discontinued');
    const discontinuedLabel = discontinued ? <span className="discontinued-section">discontinued</span> : <></>;
    const wrapperClassName = `select-content-wrapper${ disabled ? ' select-disabled' : ''}`;
    let styles = { ...selectContentWrapper };
    if (discontinued) {
      styles = {
        ...styles,
        ...selectDiscontinued
      };
    }
    return (
      <div className={wrapperClassName} style={styles}>
        <div className="select-content-title" style={description ? selectContentTitle : {}}>
          {option.label + (disabled ? ' (disabled)' : '')}
        </div>
        <div className="select-content-value">{description || ''}</div>
        {discontinuedLabel}
      </div>
    );
  };

  const listOptions = enumOptions?.map((
    option: EnumOptionsType, i
  ): Option => {
    let description = null;
    const disabled = enumDisabled && enumDisabled.includes(option.value);
    if ('enum' in schema) {
      const index = schema.enum?.indexOf(option.value) ?? -1;
      if (index !== -1) {
        description = schema.enumDescriptions[index];
      }
    }

    return {
      name: getSelectValue(
        option, description, disabled ?? false, schema.enumTags[i]
      ),
      value: option.value,
      disabled: disabled
    };
  });

  const element = listOptions?.length ? (
    <Select.Select
      id={id}
      // Assume the first item is selected by default
      selected={value === undefined ? enumOptions?.[0].value : value}
      showList
      listOptions={listOptions}
      onSelect={onChange}
    />
  ) : <></>;
  return element;
};

export default LyraSelect;
