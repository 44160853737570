import {
  LyraModal, LyraDivider
} from '@aurorasolar/lyra-ui-kit';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useState
} from 'react';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { isDesignWorkspace } from '../../../../stores/UiStore/WorkspaceStore/utils';
import { ElectricalDesignStage } from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import type { InverterSelectionViewModel } from '../../../../stores/UiStore/Modal/ViewModels/InverterSelectionModal/InverterSelectionViewModel';
import { useUiStore } from '../../../../stores/useStore';
import DcOptimizerPanel from './DcOptimizerPanel';
import {
  AddInverterText,
  AddInverterView,
  DeleteIcon,
  InverterAndEssView,
  ModalWrapper,
  TitleText,
  InverterColumn
} from './styles';
import { EnergyStorageSystemSelection } from './EnergyStorageSystemSelection';
import InverterStatusAndErrorText from './InverterStatusAndAlertText';
import InverterPanel from './InverterPanel';

function InverterSelectionModal(): ReactElement {
  const {
    modal, workspace
  } = useUiStore();
  const [inverterSelectionViewModel] = useState(modal.viewModel as InverterSelectionViewModel);

  const {
    secondInverterValue, isMicroInverter, inverterSuitableForEnergyStorageAndBackup, dcOptimizerData
  } =
    inverterSelectionViewModel;

  const [isSecondInverterColumnVisible, setSecondInverterColumnVisibility] = useState(!!secondInverterValue);

  const deleteInverter = useCallback(
    (index: number): void => {
      setSecondInverterColumnVisibility(false);
      if (index === 0) {
        inverterSelectionViewModel.setFirstInverter(
          inverterSelectionViewModel.secondInverterValue!,
          inverterSelectionViewModel.secondInverterDefinitionId!
        );
      }
      inverterSelectionViewModel.setSecondInverterName();
    },
    [inverterSelectionViewModel]
  );

  const showAddSecondInverterButton = !isSecondInverterColumnVisible && !isMicroInverter;

  const firstSelectedInverterData = {
    index: 1,
    name: inverterSelectionViewModel.firstInverterName,
    options: inverterSelectionViewModel.firstInverterOptions,
    definitionId: inverterSelectionViewModel.firstInverterDefinitionId
  };

  const secondSelectedInverterData = {
    index: 2,
    name: inverterSelectionViewModel.secondInverterValue,
    options: inverterSelectionViewModel.secondInverterOptions,
    definitionId: inverterSelectionViewModel.secondInverterDefinitionId
  };

  const currentStage = workspace?.currentWorkspace.stageManager?.currentStage;

  if (!isDesignWorkspace(workspace.currentWorkspace) || !(currentStage instanceof ElectricalDesignStage)) {
    return <></>;
  }

  return (
    <LyraModal.Modal
      title="Select Inverter"
      leftButtonLabel="Cancel"
      rightButtonLabel="Save"
      rightButtonDisabled={inverterSelectionViewModel.isSaveButtonDisabled}
      $show={true}
      height="700px"
      width={isSecondInverterColumnVisible ? '100%' : ''}
      onClickCloseButton={inverterSelectionViewModel.cancel}
      onClickLeftButton={inverterSelectionViewModel.cancel}
      onClickRightButton={inverterSelectionViewModel.continue}
    >
      <ModalWrapper isSecondInverterColumnVisible={isSecondInverterColumnVisible}>
        <InverterStatusAndErrorText />

        <AddInverterView className="mt-xxs">
          {showAddSecondInverterButton && (
            <AddInverterText onClick={(): void => setSecondInverterColumnVisibility(true)}>
              Add a Second Inverter
            </AddInverterText>
          )}
        </AddInverterView>

        <InverterAndEssView>
          <Flexbox>
            <InverterColumn isSecondInverterColumnVisible={isSecondInverterColumnVisible} className="mr-xxs">
              {isSecondInverterColumnVisible && <TitleText className="mb-xs">1</TitleText>}
              <InverterPanel inverter={firstSelectedInverterData} />
              {isSecondInverterColumnVisible && (
                <DeleteIcon name="remove-icon" className="mb-xs" onClick={(): void => deleteInverter(0)} />
              )}

              <DcOptimizerPanel />

              {inverterSuitableForEnergyStorageAndBackup && !!dcOptimizerData.length && (
                <LyraDivider.Divider direction="horizontal" className="mt-xs" color={EThemeComponentColor.GRAY_200} />
              )}

              {inverterSuitableForEnergyStorageAndBackup && <EnergyStorageSystemSelection />}
            </InverterColumn>

            {isSecondInverterColumnVisible && (
              <InverterColumn isSecondInverterColumnVisible={isSecondInverterColumnVisible} className="mr-xxs">
                <TitleText className="mb-xs">2</TitleText>
                <InverterPanel inverter={secondSelectedInverterData} />
                <DeleteIcon name="remove-icon" className="mb-xs" onClick={(): void => deleteInverter(1)} />
              </InverterColumn>
            )}
          </Flexbox>
        </InverterAndEssView>
      </ModalWrapper>
    </LyraModal.Modal>
  );
}

export default observer(InverterSelectionModal);
