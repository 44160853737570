import { action } from 'mobx';
import type { IRoofFaceSupplementalData } from '../../entities/SiteDesign/RoofFaceSupplementalData';
import type { IBuildingsHeightsData } from '../SupplementalData/IBuildingsHeightsData';
import type { IPersonParticipant } from './Participant';
import type { IAdditionalProjectData } from './Project';

export interface ISupplementalProjectData {
  readonly additionalProjectData?: IAdditionalProjectData;
  readonly buildingsHeightsData?: IBuildingsHeightsData;
  readonly supplementalDataProject?: IRoofFaceSupplementalData[];
}

export class SupplementalProjectData {
  additionalProjectData?: {
    internalReferenceId?: string;
    customer?: IPersonParticipant;
  };
  buildingsHeightsData?: IBuildingsHeightsData;
  roofFacesSupplementalData?: IRoofFaceSupplementalData[];

  constructor(data?: ISupplementalProjectData) {
    this.additionalProjectData = { ...data?.additionalProjectData };
    this.buildingsHeightsData = { ...data?.buildingsHeightsData };
    this.roofFacesSupplementalData = [...(data?.supplementalDataProject ?? [])];
  }

  @action
  addHeightDataForBuilding = (buildingId: string, ceilingHeight: number, foundationHeight: number): void => {
    if (!this.buildingsHeightsData) {
      this.buildingsHeightsData = {};
    }
    this.buildingsHeightsData[buildingId] = {
      ceilingHeight,
      foundationHeight
    };
  };

  toData = (): ISupplementalProjectData => ({
    additionalProjectData: this.additionalProjectData,
    buildingsHeightsData: this.buildingsHeightsData,
    supplementalDataProject: this.roofFacesSupplementalData
  });
}
