import {
  LyraDivider,
  LyraFormElementsDesignTool,
  LyraGrid,
  LyraModal,
  LyraTypography,
  LyraVerticalPipeline,
  LyraDropzone
} from '@aurorasolar/lyra-ui-kit';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import {
  observer, useObserver
} from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useEffect, useState
} from 'react';
import type { FileError } from 'react-dropzone';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type {
  BaseAttributes, IOption
} from '../../../domain/models/SiteDesign/IOption';
import {
  CEILING_HEIGHT_LIMIT,
  ERROR,
  FOUNDATION_HEIGHT_LIMIT,
  MEASURE_LIMIT,
  NUMBER_OF_STORIES_LIMIT
} from '../../../domain/models/Constants';
import useStore from '../../../stores/useStore';
import type { Import3DModelViewModel } from '../../../stores/UiStore/Wizard/ViewModels/Import3DModel/Import3DModelViewModel';
import { notify } from '../../../utils/helpers';
import {
  Container, InlineContainer, InlineRow
} from './styles';

const Label = LyraTypography.Paragraph;

const steps: LyraVerticalPipeline.StepData[] = [
  {
    icon: '',
    title: 'Upload XML file'
  },
  {
    icon: 'roof-framing',
    title: 'Roof framing'
  },
  {
    icon: 'roof-surface',
    title: 'Roof Surface'
  },
  {
    icon: 'roof-decking',
    title: 'Roof Decking'
  },
  {
    icon: 'condition-roof',
    title: 'Condition of Roof',
    isOptional: true
  }
];

const mapOptions = (options: IOption<BaseAttributes>[] | undefined): Option[] => {
  return !options
    ? []
    : options.map(
      (option: IOption<BaseAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
};

const Import3DModelModal = observer((props: BaseProps): ReactElement => {
  const {
    NumberField, Select, SelectToggle
  } = LyraFormElementsDesignTool.FormElementsDesignTool;
  const { wizard } = useStore();
  const [viewModel] = useState<Import3DModelViewModel>(wizard.currentViewModel as Import3DModelViewModel);

  useEffect((): void => {
    viewModel.loadOptions();
  }, [viewModel]);

  return useObserver(
    (): ReactElement => (
      <LyraModal.Modal
        $show={true}
        title={viewModel.modalTitle}
        steps={5}
        color="purple"
        currentStep={viewModel.step}
        leftButtonLabel={viewModel.leftButtonLabel}
        rightButtonLabel={viewModel.rightButtonLabel}
        onClickLeftButton={(): void => (viewModel.step > 1 ? viewModel.changeStep(-1) : viewModel.closeModal())}
        onClickRightButton={async (): Promise<void> =>
          viewModel.step > 4 ? viewModel.import3dModel() : viewModel.changeStep(1)
        }
        onClickCloseButton={viewModel.closeModal}
        rightButtonDisabled={!viewModel.canProceed}
      >
        <Container step={viewModel.step}>
          {viewModel.step === 1 && (
            <>
              <LyraDropzone.DropzoneContainer
                accept={{ 'text/xml': ['.xml'] }}
                canSave={viewModel.canProceed}
                fileName={viewModel.getFileName}
                supportedFormat="text/xml"
                onError={(rejectedFiles: FileError): void => {
                  notify(rejectedFiles.message, ERROR);
                }}
                saveFiles={(files: File[]): void => {
                  !files.length ? viewModel.setFile() : viewModel.setFile(files[0]);
                }}
              />
              <Label lineHeight={1} margin="0" type="h6">
                VENDOR
              </Label>
              <Select.Select
                className="mt-xxs"
                listOptions={viewModel.VENDORS_LIST}
                onSelect={(value: string | number): void => {
                  viewModel.setModelProvider(value as string);
                }}
                selectedOptionColor={EThemeComponentColor.PURPLE}
                selected={viewModel.modelProvider}
              />

              <LyraDivider.Divider className="mt-s" direction="horizontal" color={EThemeComponentColor.GRAY_200} />

              {viewModel.showExtraFields && (
                <>
                  <Label lineHeight={1} margin="24px 0 0 0" type="h5" fontWeight="bold">
                    Please provide Building Height
                  </Label>

                  <InlineContainer>
                    <InlineRow>
                      <Label lineHeight={1} margin="0" type="h6">
                        FOUNDATION HEIGHT
                      </Label>
                      <NumberField.NumberField
                        measure={'ft'}
                        step={0.5}
                        onChange={viewModel.setFoundationHeight}
                        value={viewModel.foundationHeight}
                        limits={FOUNDATION_HEIGHT_LIMIT}
                        fractionDigits={2}
                      />
                    </InlineRow>

                    <InlineRow className="ml-xs">
                      <Label lineHeight={1} margin="0" type="h6">
                        CEILING HEIGHT
                      </Label>
                      <NumberField.NumberField
                        measure={'ft'}
                        onChange={viewModel.setCeilingHeight}
                        value={viewModel.ceilingHeight}
                        limits={CEILING_HEIGHT_LIMIT}
                        fractionDigits={2}
                      />
                    </InlineRow>
                  </InlineContainer>

                  <InlineContainer>
                    <InlineRow>
                      <Label lineHeight={1} margin="0" type="h6">
                        N° OF STORIES
                      </Label>
                      <NumberField.NumberField
                        measure={'floors'}
                        step={0.5}
                        onChange={viewModel.setNumberOfStories}
                        value={viewModel.numberOfStories}
                        limits={NUMBER_OF_STORIES_LIMIT}
                      />
                    </InlineRow>
                  </InlineContainer>
                </>
              )}

              <Label lineHeight={1} margin="24px 0 0 0" type="h5" fontWeight="bold">
                Order Model from 3rd Party
              </Label>

              <Label lineHeight={1} margin="16px 0 0 0" type="h6">
                Supported Vendors:
              </Label>

              <a className="mt-s" href="https://eagleview.com/product/property-reports/">
                Eagleview.com
              </a>

              <a className="mt-xs" href="https://rooforders.com/">
                RoofOrders.com
              </a>
            </>
          )}

          {viewModel.step >= 2 && (
            <LyraVerticalPipeline.Pipeline
              title="Please confirm or modify the following parameters:"
              subtitle="You can still modify these parameters later on."
              data={steps}
              currentStep={viewModel.step - 1}
            >
              {viewModel.step === 2 && (
                <>
                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    CONSTRUCTION TYPE
                  </Label>
                  <Select.Select
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                    listOptions={mapOptions(viewModel.getRoofFramingData.constructionStyleOptions)}
                    onSelect={viewModel.setConstructionStyle}
                    selected={viewModel.constructionStyle?.attributes.value}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    TRUSS TOP-CHORD DIMENSIONS
                  </Label>
                  <SelectToggle.ToggleSelect
                    toggleOptions={viewModel.getRoofFramingData.yearsOfConstructionOptions}
                    listOptions={mapOptions(viewModel.getRoofFramingData.crossSectionOptions)}
                    selectedToggle={viewModel.yearOfConstruction}
                    selected={viewModel.crossSection?.attributes}
                    onToggle={viewModel.setYearOfConstruction}
                    onSelect={viewModel.setCrossSection}
                    placeHolder=""
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    TRUSS SPACING (O.C., in inches)
                  </Label>
                  <Select.Select
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                    listOptions={mapOptions(viewModel.getRoofFramingData.spacingOptions)}
                    onSelect={viewModel.setSpacing}
                    selected={
                      viewModel.spacing?.attributes.name === 'other' ? '0' : viewModel.spacing?.attributes.value
                    }
                  />
                  {viewModel.spacing?.attributes.name === 'other' && (
                    <NumberField.NumberField
                      measure="in"
                      limits={{
                        lower: 1,
                        upper: Number.MAX_VALUE
                      }}
                      value={Number(viewModel.spacing.attributes.value)}
                      onChange={viewModel.setOtherSpacing}
                      fractionDigits={2}
                    />
                  )}
                </>
              )}

              {viewModel.step === 3 && (
                <>
                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    SURFACE TYPE
                  </Label>
                  <Select.Select
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                    listOptions={mapOptions(viewModel.getRoofSurfaceData.surfaceTypeOptions)}
                    onSelect={viewModel.setSurfaceType}
                    selected={viewModel.surfaceType?.attributes.value}
                  />

                  {!!viewModel.getRoofSurfaceData.roofMaterialOptions.length && (
                    <>
                      <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                        ROOF MATERIAL DETAILS
                      </Label>
                      <Select.Select
                        selectedOptionColor={EThemeComponentColor.PURPLE}
                        listOptions={mapOptions(viewModel.getRoofSurfaceData.roofMaterialOptions)}
                        onSelect={viewModel.setRoofMaterial}
                        selected={viewModel.roofMaterial?.attributes.value}
                      />
                    </>
                  )}
                </>
              )}

              {viewModel.step === 4 && (
                <>
                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    DECK TYPE
                  </Label>
                  <Select.Select
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                    listOptions={mapOptions(viewModel.getRoofDeckingData.deckSheathingTypeOptions)}
                    onSelect={viewModel.setDeckSheathingType}
                    selected={viewModel.deckSheathingType?.attributes.value}
                  />
                </>
              )}

              {viewModel.step === 5 && (
                <>
                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    MAX. HORIZONTAL RAFTER SPAN
                  </Label>
                  <NumberField.NumberField
                    limits={MEASURE_LIMIT}
                    measure="in"
                    step={1}
                    fractionDigits={2}
                    value={viewModel.maxHorizontalSpan}
                    withQuantityArrows={false}
                    onChange={viewModel.setMaxHorizontalSpan}
                    allowEmptyField={true}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    MAX. RAFTER SAG
                  </Label>
                  <NumberField.NumberField
                    limits={MEASURE_LIMIT}
                    measure="in"
                    step={1}
                    fractionDigits={2}
                    value={viewModel.sag}
                    withQuantityArrows={false}
                    onChange={viewModel.setSag}
                    allowEmptyField={true}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    MAX. RIDGE BEAM SAG
                  </Label>
                  <NumberField.NumberField
                    limits={MEASURE_LIMIT}
                    measure="in"
                    step={1}
                    fractionDigits={2}
                    value={viewModel.ridgeBeamSag}
                    withQuantityArrows={false}
                    onChange={viewModel.setRidgeBeamSag}
                    allowEmptyField={true}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    DISALLOWED HOLES AND NOTCHES IN RAFTERS AND BEAMS
                  </Label>
                  <LyraGrid.Grid
                    options={viewModel.yesOrNoOptions}
                    columns={2}
                    selected={{
                      name: viewModel.disallowedHolesOrNotchesInStructuralMembers!,
                      value: viewModel.disallowedHolesOrNotchesInStructuralMembers!
                    }}
                    onSelect={viewModel.setDisallowedHolesOrNotchesInStructuralMembers}
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    STRUCTURAL DECAY OR UNREPAIRED FIRE DAMAGE
                  </Label>
                  <LyraGrid.Grid
                    options={viewModel.yesOrNoOptions}
                    columns={2}
                    selected={{
                      name: viewModel.structuralDecayOrFireDamage!,
                      value: viewModel.structuralDecayOrFireDamage!
                    }}
                    onSelect={viewModel.setStructuralDecayOrFireDamage}
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                  />

                  <Label lineHeight={1} margin="16px 0 4px 0" type="h6">
                    EVIDENCE OF LEAKS, DAMAGE, OR DETERIORATION
                  </Label>
                  <LyraGrid.Grid
                    options={viewModel.yesOrNoOptions}
                    columns={2}
                    selected={{
                      name: viewModel.evidenceOfLeaksDamageOrDeterioration!,
                      value: viewModel.evidenceOfLeaksDamageOrDeterioration!
                    }}
                    onSelect={viewModel.setEvidenceOfLeaksDamageOrDeterioration}
                    selectedOptionColor={EThemeComponentColor.PURPLE}
                  />
                </>
              )}
            </LyraVerticalPipeline.Pipeline>
          )}
        </Container>
      </LyraModal.Modal>
    )
  );
});

export default Import3DModelModal;
