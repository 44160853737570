import type { Coords } from '../domain/typings';
import { Units } from '../domain/typings';
import {
  convertInchesToFeet, convertMeterToInches
} from './Convertions';

class Projection {
  static getInstance(): Projection {
    if (!Projection.instance) {
      Projection.instance = new Projection();
    }

    return Projection.instance;
  }
  private static FACTOR_INCH: number = 39.37;
  private static EARTH_CURVE: number = 6378137 * 2 * Math.PI;

  private static instance: Projection;

  private coordinates?: Coords;

  private constructor() {}

  setCoordinates(coordinates: Coords): void {
    this.coordinates = coordinates;
  }

  convertFromWorldUnits(wordUnit: number, type: Units = Units.Meters): number {
    const factor = Math.pow(
      2,
      // hardcoded zoom level 20
      8 + 20
    );
    const meterPerPixel =
      (Math.cos((this.coordinates!.latitude * Math.PI) / 180) * Projection.EARTH_CURVE) / factor / 2;

    const valueInMeters = wordUnit * meterPerPixel;

    if (type === Units.Meters) {
      return valueInMeters;
    }
    if (type === Units.Inches) {
      return valueInMeters * Projection.FACTOR_INCH;
    }

    return 0;
  }

  convertToWorldUnits(value: number, units: Units = Units.Meters): number {
    const factor = Math.pow(
      2,
      // hardcoded zoom level 20 because backend always expects world units at zoom level 20:
      8 + 20
    );
    const meterPerPixel =
      (Math.cos((this.coordinates!.latitude * Math.PI) / 180) * Projection.EARTH_CURVE) / factor / 2;

    const valueInMeters = value / meterPerPixel;

    if (units === Units.Meters) {
      return valueInMeters;
    }
    if (units === Units.Inches) {
      return valueInMeters / Projection.FACTOR_INCH;
    }

    return 0;
  }

  /** Converts distanceInWorldUnit to feet, and inches with a precision of 1 inch */
  convertWorldUnitsToImperial(distanceInWorldUnit: number): { feet: number; inches: number } {
    const fullDistanceInMeters = ProjectionUtil.convertFromWorldUnits(distanceInWorldUnit);
    const fullDistanceInInches = convertMeterToInches(fullDistanceInMeters);
    let feetPartOfDistance = Math.floor(convertInchesToFeet(fullDistanceInInches));
    const numberOfInchesInOneFoot = 12;
    let inchesPartOfDistance = Math.round(fullDistanceInInches - feetPartOfDistance * numberOfInchesInOneFoot);

    if (inchesPartOfDistance === numberOfInchesInOneFoot) {
      feetPartOfDistance++;
      inchesPartOfDistance = 0;
    }

    return {
      feet: feetPartOfDistance,
      inches: inchesPartOfDistance
    };
  }
}

const ProjectionUtil = Projection.getInstance();

export default ProjectionUtil;
