type ValueOf<T> = T[keyof T];

export function recursiveObjectAssign<T1 = Object, T2 = T1, ReturnType = T1 & T2>(
  target: Object,
  source: Object
): ReturnType {
  const result = Object.assign(target, source) as {
    [key: string]: ValueOf<T1 & T2>;
  };
  for (const key of Object.keys(result)) {
    if (result[key] === null || result[key] === ('' as unknown)) {
      // @ts-ignore
      result[key] = undefined;
    }
  }

  return result as unknown as ReturnType;
}
