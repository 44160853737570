import type EditorStore from '../../stores/EditorStore/EditorStore';
import { HoverControl } from '../../stores/EditorStore/Controls/HoverControl';
import type { IHandleHoverTool } from '../../stores/UiStore/ToolbarStore/Tool';
import type { Selectable } from '../mixins/Selectable';

export class HoverBehaviour {
  protected hoverControl: HoverControl;

  constructor(editor: EditorStore) {
    this.hoverControl = new HoverControl(editor, editor.viewport, editor.activeCamera!);
  }

  addHoverEvents(instance: IHandleHoverTool): void {
    this.hoverControl.activate();
    this.hoverControl.addEventListener('HoverInObject', instance.onObjectHoverIn);
    this.hoverControl.addEventListener('HoverOutObject', instance.onObjectHoverOut);
  }

  removeHoverEvents(instance: IHandleHoverTool, recursive: boolean = false): void {
    this.hoverControl.removeEventListener('HoverInObject', instance.onObjectHoverIn);
    this.hoverControl.removeEventListener('HoverOutObject', instance.onObjectHoverOut);
    this.hoverControl.deactivate();
    this.setTargetObjects(undefined, recursive);
  }

  setTargetObjects(objects: Selectable[] | undefined, recursiveRaycast: boolean = true): void {
    this.hoverControl.setTargetObjects(objects, recursiveRaycast);
  }

  filterAndUpdateTargetObjects(filterFn: (object: Selectable) => boolean): void {
    this.hoverControl.filterAndUpdateTargetObjects(filterFn);
  }
}
