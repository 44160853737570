import {
  action, observable
} from 'mobx';

export interface ISitePlacardsData {
  readonly showNonAnsiLabelsAsRed: boolean;
  readonly forceInclusion: boolean;
  readonly showAsRed: boolean;
  readonly rotateToOrientationOfPocLocation: boolean;
}
export default class SitePlacards {
  @observable
  showNonAnsiLabelsAsRed: boolean;

  @observable
  forceInclusion: boolean;

  @observable
  showAsRed: boolean;

  @observable
  rotateToOrientationOfPocLocation: boolean;

  constructor(data: ISitePlacardsData) {
    this.showNonAnsiLabelsAsRed = data.showNonAnsiLabelsAsRed;
    this.forceInclusion = data.forceInclusion;
    this.showAsRed = data.showAsRed;
    this.rotateToOrientationOfPocLocation = data.rotateToOrientationOfPocLocation;
  }

  @action.bound
  setShowNonAnsiLabelsAsRed(value: boolean): void {
    this.showNonAnsiLabelsAsRed = value;
  }

  @action.bound
  setForceInclusion(value: boolean): void {
    this.forceInclusion = value;
  }

  @action.bound
  setShowAsRed(value: boolean): void {
    this.showAsRed = value;
  }

  @action.bound
  setRotateToOrientationOfPocLocation(value: boolean): void {
    this.rotateToOrientationOfPocLocation = value;
  }

  toData(): ISitePlacardsData {
    return {
      showNonAnsiLabelsAsRed: this.showNonAnsiLabelsAsRed,
      forceInclusion: this.forceInclusion,
      showAsRed: this.showAsRed,
      rotateToOrientationOfPocLocation: this.rotateToOrientationOfPocLocation
    };
  }
}
