import React, {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Label, LabelProps } from '../Label/index';
import { Container, InputField, TextFieldStyle, VerticalSeparator, CloseIcon } from './styles';

type TextFieldProps = LabelProps & {
  inputRef?: RefObject<HTMLInputElement>;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  onChange?: (newValue: string) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>, newValue: string) => void;
  placeholder?: string;
  readonly?: boolean;
  type?: string;
  value?: string | number;
  result?: number | boolean;
  width?: string;
  height?: string;
  fontSize?: string;
  inlineIcon?: ReactNode;
  hasError?: boolean;
};

const TextField = ({
  className,
  color,
  name,
  value = '',
  label,
  type,
  placeholder,
  width = '',
  height = '',
  fontSize = '',
  onChange,
  onKeyUp,
  inputRef,
  disabled,
  maxLength,
  readonly = false,
  result = 0,
  inlineIcon,
  hasError
}: TextFieldProps): ReactElement => {
  const [text, setText] = useState<string | number>(value ?? '');

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>): void => {
    const currentValue: string = event?.currentTarget?.value ?? '';

    setText(currentValue);
    onChange?.(currentValue);
  }, [text]);

  const handleKeyUp = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
    const stringValue = text.toString();

    onKeyUp?.(event, stringValue);
  }, [text]);

  useEffect((): void => {
    setText(value);
  }, [value]);

  const containerWidth = width ?? '100%';
  const inputHeight = height ?? '24px';
  const inputFontSize = fontSize ?? '12px';

  return (
    <Container width={containerWidth} className={className}>
      <Label className={className} color={color} name={name} label={label} />
      <TextFieldStyle
        className="ph-xxs"
        height={inputHeight}
        fontSize={inputFontSize}
        hasError={hasError}
      >
        <InputField
          className={className}
          maxLength={maxLength}
          name={name}
          type={type}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          value={text}
          readOnly={readonly}
          width={width}
          fontSize={inputFontSize}
          ref={inputRef}
          onKeyUp={handleKeyUp}
        />
        {result >= 1 ? (
          <span>
            {result} Result{result > 1 && `s`}
            <VerticalSeparator />
            <CloseIcon
              data-testid="textfield-clear"
              name="close-icon-gray"
              onClick={
                (): void => {
                  const emptyValue: unknown = null;
                  handleChange(emptyValue as React.FormEvent<HTMLInputElement>);
                }
              }
            />
          </span>
        ) : inlineIcon && text ? (
          <div>{inlineIcon}</div>
        ) : (
          <></>
        )}
      </TextFieldStyle>
    </Container>
  );
};

export { TextField, TextFieldProps };
