import type { Design } from '../../../domain/models/Design/Design';
import type PvModulePosition from '../../../domain/models/SiteDesign/PvModulePosition';
import type { MountingSystem } from '../../../domain/models/PvSystem/MountingSystem';
import { getParentLyraModelByMesh } from '../../../domain/sceneObjectsWithLyraModelsHelpers';
import type { Selectable } from '../../../domain/mixins/Selectable';
import { BaseCommand } from './Command';

/**
 * This BaseCommand extension adds pv-related ability to get (or create
 * if necessary) a mounting system using design and pvModulePosition instances.
 */
export abstract class PvModuleCommand extends BaseCommand {
  getMountingSystem(domainModel: Design, pvModulePosition: PvModulePosition): MountingSystem | undefined {
    const roofFaceId = getParentLyraModelByMesh<Selectable>(pvModulePosition.mesh).serverId;
    const mountingSystems = domainModel.system.equipment.mountingSystems;
    return mountingSystems.getOrCreateMountingSystemOn(roofFaceId);
  }
}
