import type { Object3D } from 'three';
import { SceneObjectType } from '../Constants';
import { Deletable } from '../../mixins/Deletable';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { Selectable } from '../../mixins/Selectable';
import { Drawable } from '../../mixins/Drawable';

const MixedClass = Deletable(PolygonDrawable(Selectable(Drawable(class SimpleClass {}))));
export class TraceToDefineScalingLine extends MixedClass {
  id?: number;
  override name?: string;
  isMultipleVertices: boolean = true;
  /**
   * Property id used to select right panel component
   */
  readonly propertyId: string = SceneObjectType.TraceToDefineScalingLine;
  override type: string = SceneObjectType.TraceToDefineScalingLine;
  selectWithParent: boolean = false;

  /** Layer Number */
  layerNumber: number = 1;
  private movementInProgress: boolean = false;

  showVertices(): boolean {
    return !this.boundary.closed || this.selected;
  }
  showLines(): boolean {
    return true;
  }
  dragVertices(): boolean {
    return true;
  }
  hasFill(): boolean {
    return true;
  }
  showFill(): boolean {
    return false;
  }
  onClose(): void {
    // Not implemented
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }
}
