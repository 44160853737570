import cloneDeep from 'lodash/cloneDeep';
import { computed } from 'mobx';
import type { IVertexData } from '../../entities/SiteDesign/Vertex';
import type { IPolygon } from '../../entities/Polygon/IPolygon';
import type { IPolygonWithHoles } from '../../entities/Polygon/PolygonWithHoles';

export function restrictedAreaWidthInInches(restrictedAreaType: RestrictedAreaType): number {
  const inchesAsString = restrictedAreaType.split('_')[1];
  if (!inchesAsString) {
    throw new Error(`Unsupported RestrictedAreaType: ${restrictedAreaType}`);
  }
  return parseInt(inchesAsString);
}

export enum RestrictedAreaType {
  AREA = 'AREA',
  STANDARD_12_INCHES = 'STANDARD_12_INCHES',
  STANDARD_18_INCHES = 'STANDARD_18_INCHES',
  STANDARD_24_INCHES = 'STANDARD_24_INCHES',
  STANDARD_30_INCHES = 'STANDARD_30_INCHES',
  STANDARD_36_INCHES = 'STANDARD_36_INCHES',
  STANDARD_72_INCHES = 'STANDARD_72_INCHES'
}

export interface IRestrictedAreaData {
  readonly id: string;
  readonly type: RestrictedAreaType;
  readonly polygon: IPolygon | IPolygonWithHoles;
}

interface IRoofFaceRestrictedAreasData {
  readonly roofFaceId: string;
  readonly ventilationSetbacks: IRestrictedAreaData[];
  readonly pathways: IRestrictedAreaData[];
}

export class RoofFaceRestrictedAreas {
  static readonly empty = (roofFaceId: string) =>
    new RoofFaceRestrictedAreas({
      roofFaceId: roofFaceId,
      ventilationSetbacks: [],
      pathways: []
    });

  readonly roofFaceId: string;
  /**
   * Ventilation setbacks are setbacks at the ridge of a roof where a firefighter would cut ventilation holes
   * during an effort to extinguish a fire.
   */
  private readonly ventilationSetbacks: IRestrictedAreaData[];
  /**
   * PV-free areas intended to provide a pathway for fire fighters to access ventilation setback areas
   * in the event of a fire.
   */
  private readonly pathways: IRestrictedAreaData[];

  constructor(data: IRoofFaceRestrictedAreasData) {
    this.roofFaceId = data.roofFaceId;
    this.ventilationSetbacks = data.ventilationSetbacks;
    this.pathways = data.pathways;
  }

  toData(): IRoofFaceRestrictedAreasData {
    return {
      roofFaceId: this.roofFaceId,
      ventilationSetbacks: this.ventilationSetbacks,
      pathways: this.pathways
    };
  }

  findRestrictedArea = (restrictedAreaId: string): IRestrictedAreaData | undefined => {
    return [...this.ventilationSetbacks, ...this.pathways].find(
      (restrictedArea: IRestrictedAreaData): boolean => restrictedArea.id === restrictedAreaId
    );
  };

  copyWithVentilationSetbackAddedOrUpdated = (ventilationSetback: IRestrictedAreaData): RoofFaceRestrictedAreas => {
    return new RoofFaceRestrictedAreas({
      roofFaceId: this.roofFaceId,
      ventilationSetbacks: [
        ...this.ventilationSetbacks.filter((area: IRestrictedAreaData): boolean => area.id !== ventilationSetback.id),
        ventilationSetback
      ],
      pathways: this.pathways
    });
  };

  copyWithPathwayAddedOrUpdated = (pathway: IRestrictedAreaData): RoofFaceRestrictedAreas => {
    return new RoofFaceRestrictedAreas({
      roofFaceId: this.roofFaceId,
      ventilationSetbacks: this.ventilationSetbacks,
      pathways: [...this.pathways.filter((area: IRestrictedAreaData): boolean => area.id !== pathway.id), pathway]
    });
  };

  copyWithAreaRemoved = (restrictedAreaId: string): RoofFaceRestrictedAreas => {
    return new RoofFaceRestrictedAreas({
      roofFaceId: this.roofFaceId,
      ventilationSetbacks: this.ventilationSetbacks.filter(
        (restrictedArea: IRestrictedAreaData): boolean => restrictedArea.id !== restrictedAreaId
      ),
      pathways: this.pathways.filter(
        (restrictedArea: IRestrictedAreaData): boolean => restrictedArea.id !== restrictedAreaId
      )
    });
  };

  forEachVentilationSetback = (callback: (ventilationSetback: IRestrictedAreaData) => void): void => {
    return this.ventilationSetbacks
      .map((ventilationSetback: IRestrictedAreaData): IRestrictedAreaData => cloneDeep(ventilationSetback))
      .forEach(callback);
  };

  forEachPathway = (callback: (pathway: IRestrictedAreaData) => void): void => {
    return this.pathways
      .map((pathway: IRestrictedAreaData): IRestrictedAreaData => cloneDeep(pathway))
      .forEach(callback);
  };
}

export interface IFireVentilationData {
  readonly roofFaces: IRoofFaceRestrictedAreasData[];
}

export class FireVentilation {
  private readonly roofFaces: RoofFaceRestrictedAreas[];

  constructor(data: IFireVentilationData | undefined) {
    this.roofFaces = (data?.roofFaces ?? []).map(
      (restrictedAreas: IRoofFaceRestrictedAreasData): RoofFaceRestrictedAreas =>
        new RoofFaceRestrictedAreas(restrictedAreas)
    );
  }

  toData(): IFireVentilationData {
    return {
      roofFaces: this.roofFaces.map(
        (restrictedAreas: RoofFaceRestrictedAreas): IRoofFaceRestrictedAreasData => restrictedAreas.toData()
      )
    };
  }

  @computed
  get isEmpty(): boolean {
    return this.roofFaces.length === 0;
  }

  restrictedAreasOn = (roofFaceId: string): RoofFaceRestrictedAreas | undefined => {
    return this.roofFaces.find(
      (restrictedAreas: RoofFaceRestrictedAreas): boolean => restrictedAreas.roofFaceId === roofFaceId
    );
  };

  copyWithVentilationSetbackAddedOrUpdated = (roofFaceId: string, setback: IRestrictedAreaData): FireVentilation => {
    return this.copyWithRestrictedAreaAddedOrUpdated(roofFaceId, setback, 'copyWithVentilationSetbackAddedOrUpdated');
  };

  copyWithPathwayAddedOrUpdated = (roofFaceId: string, pathway: IRestrictedAreaData): FireVentilation => {
    return this.copyWithRestrictedAreaAddedOrUpdated(roofFaceId, pathway, 'copyWithPathwayAddedOrUpdated');
  };

  private copyWithRestrictedAreaAddedOrUpdated = (
    roofFaceId: string,
    restrictedArea: IRestrictedAreaData,
    updateFunctionName: 'copyWithVentilationSetbackAddedOrUpdated' | 'copyWithPathwayAddedOrUpdated'
  ): FireVentilation => {
    const existing = this.restrictedAreasOn(roofFaceId);
    const unaffected = this.roofFaces.filter(
      (areas: RoofFaceRestrictedAreas): boolean => areas.roofFaceId !== existing?.roofFaceId
    );
    const baselineToUpdate = existing ?? RoofFaceRestrictedAreas.empty(roofFaceId);
    const updated = baselineToUpdate[updateFunctionName](restrictedArea);
    return new FireVentilation({
      roofFaces: [...unaffected, updated].map(
        (areas: RoofFaceRestrictedAreas): IRoofFaceRestrictedAreasData => areas.toData()
      )
    });
  };

  copyWithRestrictedAreaRemoved = (restrictedAreaId: string, roofFaceServerId: string): FireVentilation => {
    return new FireVentilation({
      roofFaces: this.roofFaces.map(
        (areas: RoofFaceRestrictedAreas): IRoofFaceRestrictedAreasData =>
          areas.roofFaceId === roofFaceServerId ? areas.copyWithAreaRemoved(restrictedAreaId).toData() : areas.toData()
      )
    });
  };
}

export interface IPayloadForFireVentilationArea {
  readonly roofFace: IVertexData[];
  readonly edgeIndex: number;
  readonly width: number;
}
