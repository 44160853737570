import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { LyraTypography } from '@aurorasolar/lyra-ui-kit';
import useStore, { useUiStore } from '../../../stores/useStore';
import { RoofConditionViewModel } from '../../../stores/UiStore/Properties/RoofProperties/ViewModels/RoofConditionViewModel';
import RoofPropertyEdition from '../../components/RoofPropertyEdition/RoofPropertyEdition';
import IconMapper from '../../components/Icons';
import { LABEL_ROOF_CONDITION } from './constants';
import {
  RoofPropertyButton, RoofPropertyButtonWrapper
} from './styles';

const RoofDeckingProperties = observer((): ReactElement => {
  const {
    domain, editor, serviceBus
  } = useStore();
  const { roofFaceProps } = useUiStore();
  const { firstRoofFaceSelected } = roofFaceProps;
  const [viewModel, setViewModel] = useState<RoofConditionViewModel>();

  const onEditClick = useCallback((): void => {
    if (viewModel) {
      roofFaceProps.changeShowPropertiesOf(viewModel);
    }
  }, [viewModel, roofFaceProps]);

  useEffect((): void => {
    const roofConditionViewModel = roofFaceProps.createRoofFacePropertyEditor(RoofConditionViewModel, {
      serviceBus,
      editor,
      roofFaceProps,
      domain,
      roofFace: firstRoofFaceSelected!
    });
    roofConditionViewModel.setRoofFace(firstRoofFaceSelected!);
    setViewModel(roofConditionViewModel);
  }, [firstRoofFaceSelected, domain, editor, roofFaceProps, serviceBus]);

  if (!viewModel) {
    return <></>;
  }
  return (
    <>
      {viewModel.displayEditMode ? (
        <RoofPropertyEdition title="Roof Condition" onEditClick={onEditClick}>
          {viewModel.maxHorizontalSpan !== undefined && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                MAX HORIZONTAL RAFTER SPAN:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.maxHorizontalSpan} in
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.sag !== undefined && viewModel.sag !== 0 && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                MAX RAFTER SAG:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.sag} in
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.ridgeBeamSag !== undefined && viewModel.ridgeBeamSag !== 0 && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                MAX RIDGE BEAM SAG:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.ridgeBeamSag} in
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.disallowedHolesOrNotchesInStructuralMembers === 'YES' && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                DISALLOWED HOLES AND NOTCHES IN RAFTERS AND BEAMS:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.disallowedHolesOrNotchesInStructuralMembers}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.structuralDecayOrFireDamage === 'YES' && (
            <LyraTypography.Heading margin={'0px 0px 10px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                STRUCTURAL DECAY OR UNREPAIRED FIRE DAMAGE:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.6}>
                {viewModel.structuralDecayOrFireDamage}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
          {viewModel.evidenceOfLeaksDamageOrDeterioration === 'YES' && (
            <LyraTypography.Heading margin={'0px'} type="h6">
              <LyraTypography.TextLayout margin={'0px'} lineHeight={1.4}>
                EVIDENCE OF LEAKS, DAMAGE, OR DETERIORATION:
              </LyraTypography.TextLayout>
              <LyraTypography.TextLayout margin={'0px 0px 15px'} lineHeight={1.6}>
                {viewModel.evidenceOfLeaksDamageOrDeterioration}
              </LyraTypography.TextLayout>
            </LyraTypography.Heading>
          )}
        </RoofPropertyEdition>
      ) : (
        <RoofPropertyButtonWrapper>
          <RoofPropertyButton onClick={onEditClick}>
            <IconMapper name="roof-condition" />
            {LABEL_ROOF_CONDITION}
          </RoofPropertyButton>
        </RoofPropertyButtonWrapper>
      )}
    </>
  );
});

export default RoofDeckingProperties;
