import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useEffect
} from 'react';
import Icons from '../Icons';
import {
  ARROW_LEFT_KEY, ARROW_RIGHT_KEY
} from './constants';
import { ArrowButtonsStyle } from './styles';

type Props = BaseProps & {
  caption?: string;
  onLeft: EmptyFunc;
  onRight: EmptyFunc;
};

function ArrowButtons(props: Props): ReactElement {
  const {
    caption = '', onLeft, onRight
  } = props;

  const hotKeyArrowCommand = useCallback(
    (event: KeyboardEvent): void => {
      if (event.keyCode === ARROW_LEFT_KEY) {
        onLeft();
      } else if (event.keyCode === ARROW_RIGHT_KEY) {
        onRight();
      }
    },
    [onLeft, onRight]
  );

  // binding key events
  useEffect((): EmptyFunc => {
    window.addEventListener('keyup', hotKeyArrowCommand);

    return (): void => window.removeEventListener('keyup', hotKeyArrowCommand);
  }, [hotKeyArrowCommand]);

  return (
    <ArrowButtonsStyle>
      <div>
        <span onClick={onLeft}>
          <Icons name="bold-arrow" />
        </span>

        <span onClick={onRight}>
          <Icons name="bold-arrow" />
        </span>
      </div>
      <p>{caption}</p>
    </ArrowButtonsStyle>
  );
}

export default ArrowButtons;
