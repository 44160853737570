import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import LineHr from '../../../../ui/components/LineHr';
import {
  HR_MARGIN, HR_TOOLBAR_WIDTH
} from '../../../../ui/components/Layout/SolarLayout/constants';
import { MainToolbarWrapper } from '../Shared/styles';
import config, { UI_MODE } from '../../../../config/config';
import PanningButton from './Tools/Panning';
import ParcelBoundaryButton from './Tools/ParcelBoundary';
import RectangularProtrusion from './Tools/Protrusions/Rectangular';
import SelectionButton from './Tools/Selection';
import SiteEquipmentButton from './Tools/SiteEquipment/SiteEquiment';
import SiteInfo from './Tools/SiteInfo';
import { TraceRoof } from './Tools/Tracing/TraceRoof';

/* We may need it in future. DO NOT DELETE. */
// import PanelsButton from './Tools/Panels';
// import ProtrusionButton from './Tools/Protrusions/Protrusion';
// import SiteFeatures from './Tools/SiteFeatures';
// import SiteImage from './Tools/SiteImage';

const MainToolbar = (): ReactElement => (
  <MainToolbarWrapper data-testid="mainToolbar">
    <SelectionButton />
    <PanningButton />
    {config.featureFlag.uiMode !== UI_MODE.AURORA && (
      <>
        <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />

        <ParcelBoundaryButton />
        <TraceRoof />
        <RectangularProtrusion />
        <SiteEquipmentButton />
        {/*<SiteFeatures />*/}
        {/*<SiteImage />*/}
        <SiteInfo />

        <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
      </>
    )}

    {/*<PanelsButton />*/}
  </MainToolbarWrapper>
);

export default observer(MainToolbar);
