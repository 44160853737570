import {
  LyraGrid, LyraModal, LyraTypography, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { Divider } from '@aurorasolar/lyra-ui-kit/lib/components/Divider';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import { WindSpeedViewModel } from '../../../../stores/UiStore/EnvironmentalProps/ViewModels/WindSpeedViewModel';
import useStore from '../../../../stores/useStore';
import { LoaderContainer } from './styles';

const WindSpeedModal: React.FC = (): ReactElement => {
  const { modal } = useStore();
  const [windSpeedViewModel] = useState(modal.viewModel as WindSpeedViewModel);

  return (
    <LyraModal.Modal
      $show={true}
      title="Edit Wind Speed"
      color={EThemeComponentColor.PURPLE}
      onClickLeftButton={windSpeedViewModel.closeModal}
      onClickCloseButton={windSpeedViewModel.closeModal}
      onClickRightButton={windSpeedViewModel.save}
      rightButtonDisabled={!windSpeedViewModel.saveButtonEnabled}
    >
      {windSpeedViewModel.isLoading ? (
        <LoaderContainer>
          <Divider
            className="mt-s"
            direction="horizontal"
            color={EThemeComponentColor.WHITE}
            thickness="1px"
            length="400px"
          />
          <LyraLoader.Loader text="Loading wind speed..." />
          <Divider
            className="m-s"
            direction="horizontal"
            color={EThemeComponentColor.WHITE}
            thickness="1px"
            length="400px"
          />
        </LoaderContainer>
      ) : (
        <>
          <Flexbox
            dir="column"
            style={{
              width: 450
            }}
            justify="center"
            align="center"
          >
            <LyraGrid.Grid
              options={windSpeedViewModel.structuralStandardOptions}
              hasCustomValues={false}
              selected={windSpeedViewModel.selectedStructuralStandardOption}
              columns={3}
              gutter={1}
              selectedOptionColor={EThemeComponentColor.PURPLE}
              onSelect={windSpeedViewModel.setSelectedStructuralStandardOption}
            />
          </Flexbox>
          <Divider
            className="m-s"
            direction="horizontal"
            color={EThemeComponentColor.WHITE}
            thickness="1px"
            length="400px"
          />
          <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
            Wind Speed
          </LyraTypography.Heading>
          <Flexbox
            dir="column"
            style={{
              width: 450
            }}
            justify="center"
            align="center"
          >
            <LyraGrid.Grid
              options={windSpeedViewModel.optionsSpeed}
              hasCustomValues={true}
              customCellLabel={WindSpeedViewModel.CUSTOM_WIND_SPEED_OPTION}
              customValueInputAttrs={{
                type: 'number',
                min: 0
              }}
              selected={windSpeedViewModel.currentWindSpeed}
              columns={4}
              gutter={1}
              selectedOptionColor={EThemeComponentColor.PURPLE}
              onSelect={windSpeedViewModel.setSpeed}
            />
          </Flexbox>
          <Divider
            className="m-s"
            direction="horizontal"
            color={EThemeComponentColor.WHITE}
            thickness="1px"
            length="400px"
          />
          <LyraTypography.Heading fontWeight="bold" margin="0" type="h5">
            Risk Category
          </LyraTypography.Heading>
          <Flexbox
            dir="row"
            style={{
              width: 450
            }}
            justify="center"
            align="center"
          >
            <LyraGrid.Grid
              options={windSpeedViewModel.optionsRisk}
              hasCustomValues={false}
              selected={windSpeedViewModel.currentRiskCategory}
              columns={2}
              gutter={1}
              onSelect={windSpeedViewModel.setCategory}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </Flexbox>
        </>
      )}
    </LyraModal.Modal>
  );
};

export default observer(WindSpeedModal);
