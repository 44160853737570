import union from 'lodash/union';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import {
  LyraDivider, LyraIcon, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import useStore from '../../../stores/useStore';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import config, { UI_MODE } from '../../../config/config';
import {
  DocumentGenerationMenuContainer, Row, TooltipOnHover, TooltipOnHoverText
} from './styles';
import {
  CompleteStageValue,
  ElectricalBosStageValue,
  ElectricalDesignStageValue,
  LayoutDesignStageValue,
  MountingBosStageValue
} from './stages';

interface IDocumentGenerationMenuProps {
  showSalesMenuSection: boolean;
}

interface DocumentGenerationItemProps {
  documentName: string;
  documentIcon?: string;
  documentAvailable: boolean;
  downloadDocumentWithFormat: () => void;
  openDocumentModal: () => void;
}

const DocumentGenerationItem = (props: DocumentGenerationItemProps): ReactElement => {
  const {
    documentName, documentIcon, documentAvailable, downloadDocumentWithFormat, openDocumentModal
  } = props;

  const getDocumentIcon = (): string => {
    if (config.featureFlag.uiMode === UI_MODE.AURORA) {
      return documentIcon ?? '';
    }
    return documentAvailable ? 'icon-download-default' : 'icon-document-empty';
  };

  return (
    <>
      <Flexbox
        justify="space-between"
        className={`pt-xs pb-xs${config.featureFlag.uiMode !== UI_MODE.AURORA ? '' : ' ph-xs'}`}
        onClick={(): void => (config.featureFlag.uiMode !== UI_MODE.AURORA ? void 0 : downloadDocumentWithFormat())}
        style={{ width: config.featureFlag.uiMode !== UI_MODE.AURORA ? '100%' : 'auto' }}
      >
        <Flexbox align="center">
          <TooltipOnHover available={documentAvailable}>
            <LyraIcon.Icon
              name={getDocumentIcon()}
              onClick={(): void | false => (documentAvailable ? downloadDocumentWithFormat() : false)}
              style={{
                width: 16,
                height: 20
              }}
            />
            <TooltipOnHoverText>Download the {documentName}</TooltipOnHoverText>
          </TooltipOnHover>
          <LyraTypography.Heading fontSize="10px" fontWeight="bold" lineHeight={1.2} margin="0 0 0 10px">
            {documentName}
          </LyraTypography.Heading>
        </Flexbox>
        {config.featureFlag.uiMode !== UI_MODE.AURORA && (
          <Flexbox justify="end" align="center" stretch="auto">
            <LyraDivider.Divider
              direction={'vertical'}
              thickness="1px"
              length="20px"
              color={EThemeComponentColor.GRAY_200}
            />
            <TooltipOnHover available={documentAvailable}>
              <LyraIcon.Icon
                onClick={(): void | false => (documentAvailable ? openDocumentModal() : false)}
                name="tool-settings"
                className="ml-xs"
                style={{
                  width: 14,
                  height: 14,
                  cursor: documentAvailable ? 'pointer' : 'not-allowed'
                }}
              />
              <TooltipOnHoverText>Edit the {documentName}</TooltipOnHoverText>
            </TooltipOnHover>
          </Flexbox>
        )}
      </Flexbox>
    </>
  );
};

const DocumentGenerationMenu = observer((props: IDocumentGenerationMenuProps): ReactElement => {
  const { showSalesMenuSection } = props;
  const { workspace } = useStore();
  // We can be sure that currentWorkspace here is DesignWorkspace
  // because we have additional check in ProgressStepper.tsx that is parent component;
  const designWorkspace = workspace.currentWorkspace as DesignWorkspace;
  const stageManager = designWorkspace.stageManager;

  const Sales = useCallback(
    (systemDatasheetAvailable: boolean, conceptDesignAvailable: boolean): ReactElement => (
      <React.Fragment key="sales">
        {showSalesMenuSection && (
          <>
            <LyraTypography.Heading type="h4" fontWeight="bold" color={EThemeComponentColor.BLUE}>
              SALES
            </LyraTypography.Heading>
            <Row available={systemDatasheetAvailable}>
              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

              <DocumentGenerationItem
                documentName="System Datasheet"
                documentAvailable={systemDatasheetAvailable}
                downloadDocumentWithFormat={designWorkspace?.downloadSystemDataSheetWithDefaultValues}
                openDocumentModal={designWorkspace?.openSystemDataSheetModal}
              />

              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

              <DocumentGenerationItem
                documentName="Concept Design"
                documentAvailable={conceptDesignAvailable}
                downloadDocumentWithFormat={designWorkspace?.downloadConceptDesignWithDefaultValues}
                openDocumentModal={designWorkspace?.openConceptDesignModal}
              />

              <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />
            </Row>
          </>
        )}
      </React.Fragment>
    ),
    [designWorkspace, showSalesMenuSection]
  );

  const Procurement = useCallback(
    (billOfMaterialsAvailable: boolean, wireAndConduitScheduleAvailable: boolean): ReactElement => (
      <React.Fragment key="procurement">
        <LyraTypography.Heading type="h4" fontWeight="bold" color={EThemeComponentColor.BLUE} margin="30px 0 0">
          PROCUREMENT
        </LyraTypography.Heading>
        <Row available={billOfMaterialsAvailable}>
          <LyraDivider.Divider direction={'horizontal'} color={EThemeComponentColor.GRAY_200} />

          <DocumentGenerationItem
            documentName="Bill of Materials"
            documentAvailable={billOfMaterialsAvailable}
            downloadDocumentWithFormat={designWorkspace?.downloadBillOfMaterialsWithFormat}
            openDocumentModal={designWorkspace?.openBillOfMaterialsModal}
          />
        </Row>
      </React.Fragment>
    ),
    [designWorkspace]
  );

  const BillOfMaterialsOnly = useCallback(
    () => (
      <React.Fragment key="procurement">
        <Row available>
          <DocumentGenerationItem
            documentName="Bill of Materials (.pdf)"
            documentIcon="aurora-pdf"
            documentAvailable
            downloadDocumentWithFormat={(): void => {
              designWorkspace?.downloadBillOfMaterialsWithFormat('PDF');
            }}
            openDocumentModal={(): void => void 0}
          />
        </Row>
        <Row available>
          <DocumentGenerationItem
            documentName="Bill of Materials (.xls)"
            documentIcon="aurora-xls"
            documentAvailable
            downloadDocumentWithFormat={(): void => {
              designWorkspace?.downloadBillOfMaterialsWithFormat('EXCEL');
            }}
            openDocumentModal={(): void => void 0}
          />
        </Row>
      </React.Fragment>
    ),
    [designWorkspace]
  );

  const selectComponentsToShow = useCallback(
    (currentDesignStage: number): JSX.Element[] => {
      let components = [<></>];

      // Validation and Procurement documents are temporarily hidden (backend does not support them yet)
      if (currentDesignStage === LayoutDesignStageValue) {
        components = union(
          [Sales(true, true)],
          // [Validation(false, false)],
          [Procurement(false, false)]
        );
      }
      if (currentDesignStage === ElectricalDesignStageValue) {
        components = union(
          [Sales(true, true)],
          // [Validation(true, false)],
          [Procurement(false, false)]
        );
      }
      if (currentDesignStage === ElectricalBosStageValue || currentDesignStage === MountingBosStageValue) {
        components =
          config.featureFlag.uiMode !== UI_MODE.AURORA
            ? union(
              [Sales(true, true)],
              // [Validation(true, true)],
              [Procurement(true, false)]
            )
            : [BillOfMaterialsOnly()];
      }
      if (currentDesignStage === CompleteStageValue) {
        components = union(
          [Sales(true, true)],
          // [Validation(true, true)],
          [Procurement(true, false)]
        );
      }

      return components;
    },
    [BillOfMaterialsOnly, Procurement, Sales]
  );

  return (
    <DocumentGenerationMenuContainer>
      {selectComponentsToShow(stageManager!.currentIndex)}
    </DocumentGenerationMenuContainer>
  );
});

export default DocumentGenerationMenu;
