import type { RackSpacing } from '../../domain/models/MountingSystemDefinition/IConfiguration';
import type { ILayoutStrategyData } from '../../domain/models/RoofTopArray/LayoutStrategy';
import type { IProjectData } from '../../domain/models/SiteDesign/Project';
import type DomainStore from '../../stores/DomainStore/DomainStore';
import type { IAnalytics } from './IAnalytics';
import { AnalyticsEvent } from './IAnalytics';
import type { ICompanyInstallerData } from 'domain/models/Installer';

abstract class DesignToolAnalyticsEvent extends AnalyticsEvent {
  override readonly source: string = 'DESIGN_TOOL';
}

/**
 * A wrapper around provided IAnalytics object that decorates it with the possibility
 * to temporarily disable Design Tool event tracking.
 */
export class DesignToolAnalytics {
  private readonly delegate: IAnalytics;
  private designToolEventTrackingDisabled: boolean = false;

  constructor(delegate: IAnalytics) {
    this.delegate = delegate;
  }

  trackEvent(event: DesignToolAnalyticsEvent): void {
    if (this.designToolEventTrackingDisabled) {
      return;
    }
    try {
      this.delegate.trackEvent(event);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to track analytics event: ', e as Error);
    }
  }

  /**
   * Allows to temporarily disable tracking of Design Tool events.
   * This is useful to disable event tracking temporarily while the design state is being restored.
   * Does nothing if event tracking is already disabled.
   */
  disableDesignToolEventTracking(): void {
    this.designToolEventTrackingDisabled = true;
  }

  /**
   * Allows to re-enable disabled tracking of Design Tool events.
   * Does nothing if event tracking was not previously disabled.
   */
  enableDesignToolEventTracking(): void {
    this.designToolEventTrackingDisabled = false;
  }
}

/**
 * Note: this only covers Project creation from within the Design Tool.
 * Projects created in Host App or via API are not tracked here.
 */
export class ProjectCreatedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Project Created';

  constructor(data: IProjectData) {
    super({
      projectId: data.id,
      account: data.account,
      address_state: data.site.address.province,
      address_city: data.site.address.city,
      address_postalCode: data.site.address.postalCode,
      source: 'DESIGN_TOOL'
    });
  }
}

export class ThreeDimensionalModelImportedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = '3D Model Imported';

  constructor(projectId: string, modelProvider: string) {
    super({
      projectId,
      modelProvider
    });
  }
}

export class ProjectOpenedInDesignToolEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Opened project in design tool';

  constructor(projectId: string) {
    super({
      projectId
    });
  }
}

export class SiteImageUploadedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Uploaded site image';

  constructor(projectId: string, imageId: string) {
    super({
      projectId,
      imageId
    });
  }
}

export class CustomBaseImageryUploadedEvent extends ProjectOpenedInDesignToolEvent {
  override readonly name: string = 'Uploaded custom base imagery';
}

export class InstallerCreatedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Created installer';

  constructor(installerProps: ICompanyInstallerData) {
    super({
      installer: installerProps.id,
      account: installerProps.account
    });
  }
}

export class InstallerUpdatedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Updated installer';

  constructor(installerProps: ICompanyInstallerData) {
    super({
      installer: installerProps.id,
      account: installerProps.account
    });
  }
}

abstract class AbstractProjectEvent extends DesignToolAnalyticsEvent {
  constructor(domainStore: DomainStore) {
    super({
      projectId: domainStore.project.id
    });
  }
}

export class RoofOutlineAddedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Roof Outline Added';
}

export class RoofOutlineDeletedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Roof Outline Deleted';
}

export class RoofFaceAddedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Roof Face Added';
}

export class RoofFaceDeletedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Roof Face Deleted';
}

export class ParcelBoundaryAddedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Parcel Boundary Added';
}

export class ParcelBoundaryDeletedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Parcel Boundary Deleted';
}

export class ParcelBoundaryUpdatedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Parcel Boundary Updated';
}

export class SiteMarkerAddedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Site Marker Added';

  constructor(domainStore: DomainStore, markerLabel: string) {
    super(domainStore);
    this.properties.markerLabel = markerLabel;
  }
}

export class SiteMarkerDeletedEvent extends AbstractProjectEvent {
  override readonly name: string = 'Site Marker Deleted';

  constructor(domainStore: DomainStore, markerLabel: string) {
    super(domainStore);
    this.properties.markerLabel = markerLabel;
  }
}

abstract class AbstractDesignEvent extends DesignToolAnalyticsEvent {
  constructor(domainStore: DomainStore) {
    super({
      projectId: domainStore.project.id,
      // Note: designId may be undefined when reporting events from design creation wizard (when it is not yet created)
      designId: domainStore.optionalDesign?.id
    });
  }
}

/**
 * This covers both setting the initial target in the design creation wizard, and updating it later
 */
export class DcPowerRatingTargetSpecifiedEvent extends AbstractDesignEvent {
  override readonly name: string = 'DC Power Rating Target Specified';

  constructor(domainStore: DomainStore, dcPowerRatingTargetInKw: number) {
    super(domainStore);
    this.properties.dcPowerRatingTargetInKw = dcPowerRatingTargetInKw;
  }
}

/**
 * This covers both setting the initial PV module definition in the design creation wizard, and updating it later
 */
export class PvModuleDefinitionSpecifiedEvent extends AbstractDesignEvent {
  override readonly name: string = 'PV Module Definition Specified';

  constructor(domainStore: DomainStore, pvModuleDefinitionId: string, pvModuleDefinitionTitle: string) {
    super(domainStore);
    this.properties.pvModule_definitionId = pvModuleDefinitionId;
    this.properties.pvModule_manufacturerAndModel = pvModuleDefinitionTitle;
  }
}

/**
 * This covers both setting the initial mounting system definition in the design creation wizard, and updating it later
 */
export class MountingSystemDefinitionSpecifiedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Mounting System Definition Specified';

  constructor(
    domainStore: DomainStore,
    steepSlopeMountingSystemId: string | undefined,
    steepSlopeMountingSystemManufacturer: string | undefined,
    lowSlopeMountingSystemId: string | undefined,
    lowSlopeMountingSystemManufacturer: string | undefined
  ) {
    super(domainStore);
    if (steepSlopeMountingSystemId) {
      this.properties.mountingSystem_steepSlope_definitionId = steepSlopeMountingSystemId;
      this.properties.mountingSystem_steepSlope_manufacturer = steepSlopeMountingSystemManufacturer!;
    }
    if (lowSlopeMountingSystemId) {
      this.properties.mountingSystem_lowSlope_definitionId = lowSlopeMountingSystemId;
      this.properties.mountingSystem_lowSlope_manufacturer = lowSlopeMountingSystemManufacturer!;
    }
  }
}

/**
 * This covers both setting the initial default layout strategy in the design creation wizard, and updating it later
 */
export class DefaultLayoutStrategySpecifiedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Default Layout Strategy Specified';

  constructor(
    domainStore: DomainStore,
    steepSlopeLayoutStrategy: ILayoutStrategyData | undefined,
    lowSlopeLayoutStrategy: ILayoutStrategyData | undefined,
    lowSlopeRackSpacing: RackSpacing | undefined
  ) {
    super(domainStore);
    if (steepSlopeLayoutStrategy) {
      this.properties.layoutStrategy_steepSlope_dominantOrientation = steepSlopeLayoutStrategy.dominantOrientation;
      this.properties.layoutStrategy_steepSlope_mixedOrientationsAllowed =
        steepSlopeLayoutStrategy.mixedOrientationsAllowed;
      this.properties.layoutStrategy_steepSlope_rowAlignmentStrategy = steepSlopeLayoutStrategy.rowAlignmentStrategy;
    }
    if (lowSlopeLayoutStrategy) {
      this.properties.layoutStrategy_lowSlope_dominantOrientation = lowSlopeLayoutStrategy.dominantOrientation;
      this.properties.layoutStrategy_lowSlope_mixedOrientationsAllowed =
        lowSlopeLayoutStrategy.mixedOrientationsAllowed;
      this.properties.layoutStrategy_lowSlope_rowAlignmentStrategy = lowSlopeLayoutStrategy.rowAlignmentStrategy;
      this.properties.layoutStrategy_lowSlope_rackSpacing_isAutomatic = lowSlopeRackSpacing!.isAutomatic;
    }
  }
}

export class DesignCreatedEvent extends DesignToolAnalyticsEvent {
  override readonly name: string = 'Design Created';
  constructor(projectId: string, designId: string) {
    super({
      projectId,
      designId
    });
  }
}

export class ArrayAreasSelectedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Array Areas Selected';
  override readonly oneTimeTrackedEvent: boolean = true;
}

export class FireVentilationAreasUpdatedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Fire Ventilation Areas Updated';
  override readonly oneTimeTrackedEvent: boolean = true;
}

export class ArrayPlacementCompleted extends AbstractDesignEvent {
  override readonly name: string = 'Array Placement Completed';
}

export class EnergyProductionLossesUpdatedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Energy Production Losses Updated';
}

export class LayoutDesignCompleted extends AbstractDesignEvent {
  override readonly name: string = 'Layout Design Completed';
}

export class PowerConversionAndStorageEquipmentSelectedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Power Conversion And Storage Equipment Selected';
}

export class PvSourceCircuitAddedEvent extends AbstractDesignEvent {
  override readonly name: string = 'PV Source Circuit Added';
}

export class PvSourceCircuitUpdatedEvent extends AbstractDesignEvent {
  override readonly name: string = 'PV Source Circuit Updated';
}

export class PvSourceCircuitDeletedEvent extends AbstractDesignEvent {
  override readonly name: string = 'PV Source Circuit Deleted';
}

export class DcSourceCircuitAddedEvent extends AbstractDesignEvent {
  override readonly name: string = 'DC Source Circuit Added';
}

export class DcSourceCircuitUpdatedEvent extends AbstractDesignEvent {
  override readonly name: string = 'DC Source Circuit Updated';
}

export class DcSourceCircuitDeletedEvent extends AbstractDesignEvent {
  override readonly name: string = 'DC Source Circuit Deleted';
}

export class DcSourceCircuitsDeletedEvent extends AbstractDesignEvent {
  override readonly name: string = 'DC Source Circuits Deleted';
}

export class AcBranchCircuitAddedEvent extends AbstractDesignEvent {
  override readonly name: string = 'AC Branch Circuit Added';
}

export class AcBranchCircuitUpdatedEvent extends AbstractDesignEvent {
  override readonly name: string = 'AC Branch Circuit Updated';
}

export class AcBranchCircuitDeletedEvent extends AbstractDesignEvent {
  override readonly name: string = 'AC Branch Circuit Deleted';
}

export class ElectricalDesignCompleted extends AbstractDesignEvent {
  override readonly name: string = 'Electrical Design Completed';
}

export class ElectricalBosFormSubmittedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Electrical BOS Form Submitted';
}

export class ElectricalBosSitePlanSubmittedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Electrical BOS Site Plan Submitted';
}

export class CircuitConnectionsSpecifiedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Circuit Connections Specified';
  override readonly oneTimeTrackedEvent: boolean = true;
}

export class ElectricalBosCompleted extends AbstractDesignEvent {
  override readonly name: string = 'Electrical BOS Completed';
}

export class MountingBosFormSubmittedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Mounting BOS Form Submitted';
}

export class ConceptDesignGeneratedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Concept Design Generated';
}

export class SystemDatasheetGeneratedEvent extends AbstractDesignEvent {
  override readonly name: string = 'System Datasheet Generated';
}

export class BillOfMaterialsGeneratedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Bill of Materials Generated';
}

export class PlanSetPreviewGeneratedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Plan Set Preview Generated';
}

export class PlanSetPurchasedOrRevisionedEvent extends AbstractDesignEvent {
  override readonly name: string = 'Plan Set Purchased or Revisioned';
}
