import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import type { PropsWithTheme } from '../../../ui/components/Button/styles';
import { MOUNTING_BOS_FORM_ID } from '../MountingBos/MountingBos';
import config, { UI_MODE } from '../../../config/config';

type ButtonStyleProps = {
  disabled?: boolean;
  available?: boolean;
};

export const ProgressContainer = styled.div`
  background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // Linter insists on separating 'row' and '-reverse' if I extract the former, so it's been moved inside the template string
  flex-direction: ${(): string => (config.featureFlag.uiMode === UI_MODE.AURORA ? 'row-reverse' : 'row')};
  align-items: center;
  height: auto;
  width: 100%;
  padding: 4px 0;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  z-index: 4;
  transition: all 0.6s ease-in-out;
  transform: translateY(50px);
`;

export const DocumentWrapper = styled.div`
  position: relative;
  width: 240px;
  margin-right: 20px;
  & div > button {
    width: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? '100%' : 'auto')};
    border-radius: 4px;
  }
`;

export const WrapperStepper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonSiteDefinition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
  & > div {
    & svg {
      transform: rotate(180deg) !important;
    }
  }
  & > span {
    font-size: 13px;
    line-height: 1.3;
    color: ${({ theme }: PropsTheme): Color => theme.colors.mainColor3};
    padding: 0 8px;
    text-align: center;
  }
`;

export const ButtonPermitPackagePreview = styled.button.attrs({
  type: 'submit',
  form: MOUNTING_BOS_FORM_ID
})`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0 16px;
  font-size: 13px;
  line-height: 1.3;
  color: ${({
    theme, disabled
  }: PropsTheme<ButtonStyleProps>): Color =>
    disabled ? theme.colors.grey : theme.colors.mainColor3};
  cursor: ${({ disabled }: ButtonStyleProps): Color => (disabled ? 'not-allowed' : 'pointer')};
`;

export const DocumentGenerationMenuContainer = styled.div`
  width: 208px;
  border-radius: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 3 : 0)}px;
  border: ${(): string =>
    config.featureFlag.uiMode !== UI_MODE.AURORA
      ? `solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.gray2}`
      : 'none'};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  position: absolute;
  top: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 70 : 43)}px;
  ${(): SimpleInterpolation =>
    config.featureFlag.uiMode !== UI_MODE.AURORA
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
  z-index: 4;
  padding: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? '15px 15px 0 15px' : '0')};
`;

export const Row = styled.div<{ available: boolean }>`
  opacity: ${({ available }: { available: boolean }): number => (available ? 1 : 0.5)};
  margin-top: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 15 : 0)}px;
  padding-inline: 0;
  cursor: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 'default' : 'pointer')};

  p {
    cursor: ${(): string => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 'default' : 'pointer')};
  }

  :hover {
    background-color: ${({ theme }: PropsWithTheme): Color =>
    config.featureFlag.uiMode !== UI_MODE.AURORA ? theme.colors.whiteBg : theme.colors.grey4};
  }
`;

export const TooltipOnHoverText = styled.div`
  visibility: hidden;
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  top: 25px;
  right: -30px;
  z-index: 1;
  font-size: 12px;
  width: max-content;
`;

export const TooltipOnHover = styled.div<ButtonStyleProps>`
  position: relative;
  cursor: ${({ available }: ButtonStyleProps): string => (available ? 'pointer' : 'not-allowed')};

  &:hover > ${TooltipOnHoverText} {
    visibility: ${({ available }: ButtonStyleProps): string => (available ? 'visible' : 'hidden')};
  }
`;
