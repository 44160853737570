enum ELayoutType {
  'LANDSCAPE-ALIGNED' = 'landscape-aligned',
  'LANDSCAPE-MAXIMIZED' = 'landscape-maximized',
  'LANDSCAPE-STAGGERED' = 'landscape-staggered',
  'PORTRAIT-ALIGNED-MIXED' = 'portrait-aligned-mixed',
  'PORTRAIT-ALIGNED-NOT-MIXED' = 'portrait-aligned-not-mixed',
  'PORTRAIT-MAXIMIZED-MIXED' = 'portrait-maximized-mixed',
  'PORTRAIT-MAXIMIZED-NOT-MIXED' = 'portrait-maximized-not-mixed',
  'PORTRAIT-STAGGERED-MIXED' = 'portrait-staggered-mixed',
  'PORTRAIT-STAGGERED-NOT-MIXED' = 'portrait-staggered-not-mixed'
}

export { ELayoutType };
