import {
  action, observable, reaction
} from 'mobx';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import find from 'lodash/find';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { getRootStore } from '../../../../RootStoreInversion';
import type { LoadCenter } from '../../../../../domain/models/SiteDesign/SiteEquipment/LoadCenter';
import { DEFAULT_MAIN_BREAKER_RATING } from '../../../../../domain/models/SiteDesign/SiteEquipmentTypesAndHelpers';
import type { IOption } from '../../../../../domain/models/SiteDesign/IOption';
import type { MarkerAttributes } from '../../../../../domain/typings';
import {
  handleApiError, removePropertiesIfNullOrUndefined
} from '../../../../../utils/helpers';

export type TProperties = {
  mainBreakerRating?: number;
  mainBreakerAmpereInterruptingCapacity?: number;
  exposure?: { type: string; constrained: boolean };
};

export class MainBreakerViewModel {
  designService: DesignService = new DesignService();
  editor: EditorStore;
  domain: DomainStore;
  serviceBus: ServiceBus;
  readonly exposureOptions = [
    {
      name: 'INDOOR',
      value: 'INDOOR'
    },
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  @observable
  exposure?: Option = this.exposureOptions[1];
  @observable
  mainBreakerRating?: Option;
  @observable
  mainBreakerAmpereInterruptingCapacity?: Option;

  @observable
  mainBreakerRatingOptions: Option[] = [];
  @observable
  mainBreakerAmpereInterruptingCapacityOptions: Option[] = [];
  @observable
  loading = false;

  /**
    There is no real connection between view models,
    the only trackable values come from the objects inside domain
    Thus the usage of MobX side effects, reacting
    when load center's view model changes anything in former's object
    {@link clearMainBreakerRatingOptions}
   */
  clearMainBreakerAICOptionsAutoUpdate = reaction(
    () => getRootStore().domain.siteEquipment.mainServicePanel?.shortCircuitCurrentRating,
    (shortCircuitCurrentRating) => {
      this.loadMainBreakerAmpereInterruptingCapacityOptions(shortCircuitCurrentRating).then(() => {
        const mainBreakerAICOptions = this.mainBreakerAmpereInterruptingCapacityOptions;
        const isInvalidAIC = !find(
          mainBreakerAICOptions,
          this.findOptionByValue(this.mainBreakerAmpereInterruptingCapacity?.value)
        );
        if (this.mainBreakerAmpereInterruptingCapacity?.value && isInvalidAIC) {
          this.setMainBreakerAmpereInterruptingCapacity(mainBreakerAICOptions[mainBreakerAICOptions.length - 1].value);
        }
      });
    }
  );

  constructor(editor: EditorStore, domain: DomainStore, serviceBus: ServiceBus) {
    this.editor = editor;
    this.domain = domain;
    this.serviceBus = serviceBus;
    this.loadMainBreakerOptions(domain.siteEquipment.mainServicePanel).then(this.setPropertiesFromDomainObject);
  }

  async loadMainBreakerOptions(loadCenter?: LoadCenter): Promise<void> {
    this.loading = true;
    // For a better UX, all the requests should execute at the same time
    await Promise.all([
      this.loadMainBreakerRatingOptions(),
      this.loadMainBreakerAmpereInterruptingCapacityOptions(loadCenter?.shortCircuitCurrentRating)
    ]).catch(handleApiError('Failed to load main breaker options'));
    this.loading = false;
  }

  @action.bound
  setPropertiesFromDomainObject(): void {
    const mainBreaker = this.domain.siteEquipment.mainBreakerInEnclosure;
    this.setMainBreakerRating(mainBreaker?.mainBreakerRating ?? DEFAULT_MAIN_BREAKER_RATING, false);
    this.setMainBreakerAmpereInterruptingCapacity(mainBreaker?.mainBreakerAmpereInterruptingCapacity, false);
  }

  @action.bound
  setMainBreakerRating(value?: string | number, shouldUpdate: boolean = true): void {
    const mainBreakerRating = find(this.mainBreakerRatingOptions, this.findOptionByValue(value));
    if (mainBreakerRating) {
      this.mainBreakerRating = mainBreakerRating;
    }
    if (shouldUpdate) {
      this.setPropertiesToDomain({
        mainBreakerRating: Number(value ?? DEFAULT_MAIN_BREAKER_RATING)
      });
    }
  }

  @action.bound
  setMainBreakerAmpereInterruptingCapacity(value?: string | number, shouldUpdate: boolean = true): void {
    const option = find(this.mainBreakerAmpereInterruptingCapacityOptions, this.findOptionByValue(value));
    if (option) {
      this.mainBreakerAmpereInterruptingCapacity = option;
    }
    if (shouldUpdate) {
      this.setPropertiesToDomain({
        mainBreakerAmpereInterruptingCapacity: Number(value)
      });
    }
  }

  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find(this.exposureOptions, this.findOptionByValue(value)) as Option | undefined;
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setPropertiesToDomain({
        exposure: {
          type: `${value}`,
          constrained: false
        }
      });
    }
  }

  @action.bound
  setPropertiesToDomain(properties: TProperties): void {
    const mainBreaker = this.domain.siteEquipment.mainBreakerInEnclosure!;
    const mergedMainBreaker = Object.assign(mainBreaker, properties);
    removePropertiesIfNullOrUndefined(mergedMainBreaker);
    this.serviceBus.send('update_equipment', {
      editor: this.editor,
      domain: this.domain,
      equipment: mergedMainBreaker
    });
  }

  // This method has to have an argument, otherwise its override in meter main view model doesn't work
  async loadMainBreakerRatingOptions(busbarRating?: number): Promise<void> {
    await this.designService
      // For equipment type: Meter Base/Main Breaker/Main Breaker Load Center
      // Load Center already makes sure that its main breaker rating is below busbar rating
      // We don't need to do the same in a second main breaker
      .getMspMainBreakerRatingOptions()
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerRatingOptions = this.mapToItemOption(response);
      });
  }

  async loadMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating?: string): Promise<void> {
    await this.designService
      .getMainBreakerAmpereInterruptingCapacityOptions(panelShortCircuitCurrentRating)
      .then((response: IOption<MarkerAttributes>[]): void => {
        this.mainBreakerAmpereInterruptingCapacityOptions = this.mapToItemOption(response);
      });
  }

  mapToItemOption(options: IOption<MarkerAttributes>[]): Option[] {
    return options.map(
      (option: IOption<MarkerAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }

  protected findOptionByValue(value: string | number = ''): (option: Option) => boolean {
    return (option: Option): boolean => `${option.value}` === `${value}`;
  }
}
