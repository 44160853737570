import React, { BaseProps, ReactElement } from 'react';
import { Area } from '../Grid';
import { ASIDE } from './constants';
import { FlexBG } from './FlexBG';
import styled, { PropsTheme } from 'styled-components';
import { Color } from '../../../typings';

const AsideArea = styled(Area)`
  border-left: ${({theme}: PropsTheme): Color => theme.auroraMode ? `1px solid ${theme.colors.grey4}` : 'none'}
`;

const Aside = ({ children, className }: BaseProps): ReactElement => {
  return (
    <AsideArea name={ASIDE} className={className}>
      <FlexBG
        dir="column"
        align="center"
        justify="normal"
        fullHeight
      >
        {children}
      </FlexBG>
    </AsideArea>
  );
};

export { Aside };
