import React, { BaseProps, ReactElement } from 'react';
import {
  PropertyListContainer,
  PropertyHeader,
  ColorBox,
  PropertyHeaderText,
  ButtonWrapper
} from './styles';
import { CTAButtonProps, CTAButton } from '../Buttons/CTAButton';

export type PropertyListProps = BaseProps & {
  name: string;
  level: number;
  levelColor: string;
  buttons: CTAButtonProps[];
};

const PropertyList = ({
  buttons, levelColor, level, name
}: PropertyListProps): ReactElement => {
  return (
    <PropertyListContainer>
      <PropertyHeader>
        <ColorBox color={levelColor} />
        <PropertyHeaderText>
          Level {level} / {name}
        </PropertyHeaderText>
      </PropertyHeader>
      {buttons.map((button: CTAButtonProps, index: number): ReactElement => {
        return (
          <ButtonWrapper key={index}>
            <CTAButton
              iconName={button.iconName}
              label={button.label}
              trigger={button.trigger}
              fullWidth={button.fullWidth}
            />
          </ButtonWrapper>
        );
      })}
    </PropertyListContainer>
  );
};

export { PropertyList };
