import React, { BaseProps, ReactElement } from 'react';
import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';

type Props = BaseProps & {
  fontSize?: string;
};

const Wrapper = styled.div`
  padding: 5px;
  margin: 15px;
  margin-top: 30px;
`;

export const H1 = styled.div`
  font-family: ${
    ({ theme }: PropsTheme): string => theme.fonts.fontFamily
  };
  font-size: ${
    ({ fontSize }: PropsTheme<Props>): string  =>
    fontSize === 'small' ? '28px' : '36px'
  };
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${
    ({ theme }: PropsTheme): Color => theme.colors.grayColor1
  };
`;

export const H2 = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const H3 = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const H4 = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const Paragraph = styled.div`
  text-align: left;
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const Link = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

export const Notes = styled.div`
  font-family: ${({ theme }: PropsTheme): string => theme.fonts.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${({ theme }: PropsTheme): Color => theme.colors.grayColor1};
`;

const Separator = styled.div`
  margin-top: 30px;
`;

const Fonts = (): ReactElement => {
  return (
    <Wrapper>
      <Separator>
        <H1>
          H1: Permit packages made easy
        </H1>
      </Separator>
      <Separator>
        <H2>
          H2: Permit packages made easy
        </H2>
      </Separator>
      <Separator>
        <H3>
          H3: Permit packages made easy
        </H3>
      </Separator>
      <Separator>
        <H4>
          H4: Permit packages made easy
        </H4>
      </Separator>
      <Separator>
        <Paragraph>
          Paragraph: Permit packages made easy
        </Paragraph>
      </Separator>
      <Separator>
        <Link>
          Link: Permit packages made easy
        </Link>
      </Separator>
      <Separator>
        <Notes>
          Notes: Permit packages made easy
        </Notes>
      </Separator>
    </Wrapper>
  );
};

export { Fonts };
