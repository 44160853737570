import isNil from 'lodash/isNil';
import type { IRoofProtrusionData as RoofProtrusionEntity } from '../domain/entities/SiteDesign/RoofProtrusion';
import type { RoofProtrusion as RoofProtrusionDTO } from '../domain/models/SiteDesign/RoofProtrusion';
import type {
  IProtrusion2d, IRoofFace3D as RoofFace3DEntity
} from '../domain/request/Util/IRoofFace3D';
import type DomainStore from '../stores/DomainStore/DomainStore';
import { VerticesMapping } from '../infrastructure/mapping/VerticesMapping';
import { RoofProtrusionMapping } from '../infrastructure/mapping/RoofProtrusionMapping';
import type { Coords } from '../domain/typings';
import { ERoofType } from '../domain/typings';
import { DesignService } from '../infrastructure/services/api/DesignService';
import type { RoofFace } from '../domain/models/SiteDesign/RoofFace';
import { handleApiError } from './helpers';

const verticesMapping = new VerticesMapping();
const roofProtusionsMapping = new RoofProtrusionMapping();
const designService = new DesignService();

function convertDomainModelToEntity(
  roofFace: RoofFace,
  level: number,
  coordinateSystemOrigin: Coords,
  foundationHeight: number,
  averageCeilingHeight: number,
  isBuildingFrom3dModel: boolean
): RoofFace3DEntity {
  const polygonWith2dGeometry = verticesMapping.convertDomainModelToEntity2d(roofFace.polygon);
  const protrusionsWith2dGeometry = roofFace.protrusions.map((protrusion: RoofProtrusionDTO): IProtrusion2d => {
    const protrusionData = roofProtusionsMapping.convertDomainModelToEntity(protrusion);
    return {
      ...protrusionData,
      boundingBox: verticesMapping.convertDomainModelToEntity2d(protrusion.boundary.vertices)
    };
  });

  const roofFace3DEntity: RoofFace3DEntity = {
    azimuth: roofFace.azimuth!,
    slope: roofFace.slope!,
    baseHeight: {
      approximate: {
        foundationHeight,
        averageCeilingHeight,
        level,
        coordinateSystemOrigin
      }
    },
    geometry: {
      polygon: polygonWith2dGeometry,
      protrusions: protrusionsWith2dGeometry
    }
  };

  /**
   * If the building is imported from 3d model, we can set "exact" base height using the lowest point of the roof face.
   * When roof face type is changed to flat and then back to sloped, the lowest point would be 0, which is not suitable
   * for base height, so we'll ignore it.
   */
  if (isBuildingFrom3dModel && roofFace.heightOfLowestPointInPrimaryCoordinateSystem > 0) {
    roofFace3DEntity.baseHeight = {
      exact: roofFace.heightOfLowestPointInPrimaryCoordinateSystem
    };
  }
  return roofFace3DEntity;
}

async function unprojectRoofFace(roofFace: RoofFace, domainStore: DomainStore): Promise<void> {
  const level = domainStore.getLevelOfRoofFace(roofFace);
  const building = domainStore.findBuildingByChild(roofFace);
  const isBuildingFrom3dModel = building!.isImportedFrom3dModel;
  const roofEntity: RoofFace3DEntity = convertDomainModelToEntity(
    roofFace,
    level,
    domainStore.project.site.coordinateSystemOrigin,
    domainStore.project.supplementalData.buildingsHeightsData?.[building!.id]?.foundationHeight ?? 0.6,
    domainStore.project.supplementalData.buildingsHeightsData?.[building!.id]?.ceilingHeight ?? 2.43,
    isBuildingFrom3dModel
  );
  const {
    polygon, edgeTypes, protrusions, slope, azimuth, slopeType
  } = await designService
    .setRoofFaceZ(roofEntity)
    .catch(handleApiError('Failed to set roof face z'));
  protrusions?.forEach((protrusion: RoofProtrusionEntity): void => {
    const existProtrusion = roofFace.protrusions.find(
      (protrusionActual: RoofProtrusionDTO): boolean => protrusionActual.name === protrusion.name
    );
    if (existProtrusion) {
      const protrusionVertices = verticesMapping.convertEntityToDomainModel(protrusion.boundingBox);
      existProtrusion.boundary.setVertices(protrusionVertices);
      existProtrusion.redraw();
    }
  });

  roofFace.boundary.setVertices(verticesMapping.convertEntityToDomainModel(polygon));
  roofFace.setEdgeTypes(edgeTypes!);
  roofFace.slopeType = slopeType;
  roofFace.slope = slope;
  if (!isNil(azimuth)) {
    roofFace.setAzimuth(azimuth);
  }
}

export async function updateRoofFace3dValues(roofFace: RoofFace, domainStore: DomainStore): Promise<void> {
  if (roofFace.roofType === ERoofType.FLAT) {
    return unprojectRoofFace(roofFace, domainStore);
  }
  if (roofFace.roofType === ERoofType.SLOPED && !isNil(roofFace.slope) && !isNil(roofFace.azimuth)) {
    return unprojectRoofFace(roofFace, domainStore);
  }
  roofFace.clearZValues();
}
