import { LyraFormGenerator } from '@aurorasolar/lyra-form-generator/dist';
import {
  LyraButtons, LyraLoader
} from '@aurorasolar/lyra-ui-kit';
import { EButtonType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/EButtonType';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useContext, useEffect, useState
} from 'react';
import { ButtonStyleType } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/styles';
import { ThemeContext } from 'styled-components';
import { DesignStep } from '../../../domain/models/Design/DesignState';
import type { IStage } from '../../../domain/stages/IStage';
import { stageIs } from '../../../domain/stages/DesignStages/utils';
import type { ElectricalBosStage } from '../../../domain/stages/DesignStages/ElectricalBosStage';
import { useUiStore } from '../../../stores/useStore';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  NextStepWrapper, PropertyPanelContainer, SubmitSectionWrapper
} from '../PropertyPanel/styles';
import config, { UI_MODE } from '../../../config/config';
import EquipmentPanel from '../Panels/EquipmentPanel/EquipmentPanel';
import '../SharedStyles/FormGenerator.css';
import type { DesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/workspaces/DesignWorkspace';
import { isDesignWorkspace } from '../../../stores/UiStore/WorkspaceStore/utils';
import {
  AuroraFormGeneratorContainer,
  BackButtonsContainer,
  ContentContainer,
  FormDataContainer,
  GenerateLocationsButtonContainer,
  LyraFormGeneratorContainer
} from './styles';

function isElectricalBOSStage(stage: IStage | undefined): boolean {
  if (stage) {
    return stageIs<ElectricalBosStage>(stage, DesignStep.ELECTRICAL_BOS);
  }
  return false;
}

type LoaderProps = {
  text?: string;
};

const LoaderElement = ({ text }: LoaderProps): ReactElement => {
  const DS = useContext(ThemeContext)!.DS;

  return config.featureFlag.uiMode === UI_MODE.AURORA ? <DS.Loader size={30} /> : <LyraLoader.Loader text={text} />;
};

type Props = {
  electricalBosStage: ElectricalBosStage;
};

const ElectricalBosContainer = observer((props: Props): ReactElement => {
  const { electricalBosStage } = props;
  const {
    formSpecification, showCircuitTableOrGenerateLocationsInProgress, generateLocations, onChangeBosFormValue
  } =
    electricalBosStage;

  const isAuroraMode = config.featureFlag.uiMode === UI_MODE.AURORA;

  const FormGeneratorContainer = isAuroraMode ? AuroraFormGeneratorContainer : LyraFormGeneratorContainer;

  return (
    <FormGeneratorContainer>
      {!!formSpecification ? (
        <LyraFormGenerator
          formId="electrical-bos-form"
          formData={toJS(formSpecification.data)}
          apiSchema={toJS(formSpecification.form)}
          apiRules={toJS(formSpecification.rules)}
          onSubmit={generateLocations}
          onChange={onChangeBosFormValue}
          showSubmitButton={config.featureFlag.uiMode !== UI_MODE.AURORA}
          disableSubmitButton={showCircuitTableOrGenerateLocationsInProgress}
          submitButtonLabel="Generate Locations"
          hideMoreInfoBottomButtom={isAuroraMode}
        />
      ) : (
        <LoaderElement text="Loading..." />
      )}
    </FormGeneratorContainer>
  );
});

export const ElectricalBos = observer((): ReactElement => {
  const { workspace } = useUiStore();
  const [designWorkspace, setDesignWorkspace] = useState<DesignWorkspace | null>(null);

  useEffect((): void => {
    if (isDesignWorkspace(workspace.currentWorkspace)) {
      setDesignWorkspace(workspace.currentWorkspace as DesignWorkspace);
    } else {
      setDesignWorkspace(null);
    }
  }, [workspace.currentWorkspace]);

  const stage = workspace?.currentWorkspace?.stageManager?.currentStage;
  const electricalBosStage = isElectricalBOSStage(stage) ? (stage as ElectricalBosStage) : undefined;

  if (!electricalBosStage) {
    return <></>;
  }

  return (
    <FormDataContainer>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name={electricalBosStage.title} iconBg="#427ecd" />
      </PropertyPanelContainer>
      <ContentContainer>
        <ElectricalBosContainer electricalBosStage={electricalBosStage} />
        {electricalBosStage.canShowEquipmentPanel && config.featureFlag.uiMode === UI_MODE.AURORA && <EquipmentPanel />}
      </ContentContainer>
      {config.featureFlag.uiMode !== UI_MODE.AURORA ? (
        <BackButtonsContainer>
          <SubmitSectionWrapper>
            <NextStepWrapper xs={12} className="pv-xs">
              <LyraButtons.Buttons
                className="back-button"
                styleType={ButtonStyleType.FLAT}
                colorTheme={EThemeComponentColor.TRANSPARENT}
                fontColorTheme={EThemeComponentColor.AQUAMARINE}
                isLeft
                onClick={(): void => {
                  workspace.currentWorkspace.stageManager?.previous();
                }}
              >
                <b>Back</b>
              </LyraButtons.Buttons>
              <LyraButtons.ButtonIcon
                icon="arrow"
                disabled={electricalBosStage.isReviewCircuitsButtonDisabled}
                {...(electricalBosStage.isReviewCircuitsButtonDisabled && {
                  tooltip: electricalBosStage.showCircuitTableOrGenerateLocationsInProgress
                    ? 'Operation in progress...'
                    : 'Generate equipment locations first'
                })}
                type={EButtonType.ROUNDED_BUTTON}
                colorTheme={EThemeComponentColor.AQUAMARINE}
                withIconTheme
                onClick={(): void => {
                  electricalBosStage.openReviewCircuitsAndProceedToMountingModal();
                }}
              >
                <b>Review Circuits and Proceed to Mounting</b>
              </LyraButtons.ButtonIcon>
            </NextStepWrapper>
          </SubmitSectionWrapper>
        </BackButtonsContainer>
      ) : (
        <GenerateLocationsButtonContainer>
          <LyraButtons.Buttons
            styleType={ButtonStyleType.AURORA}
            colorTheme={EThemeComponentColor.BLACK}
            fontColorTheme={EThemeComponentColor.WHITE}
            disabled={electricalBosStage.showCircuitTableOrGenerateLocationsInProgress}
            submitForm="electrical-bos-form"
          >
            {electricalBosStage.showCircuitTableOrGenerateLocationsInProgress ? (
              <LoaderElement />
            ) : (
              `${electricalBosStage.isEquipmentPanelDisplayed ? 'Re-g' : 'G'}enerate equipment`
            )}
          </LyraButtons.Buttons>
        </GenerateLocationsButtonContainer>
      )}
    </FormDataContainer>
  );
});
