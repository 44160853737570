import type {
  BaseProps, ReactElement
} from 'react';
import React, { useState } from 'react';
import { LyraTooltip } from '@aurorasolar/lyra-ui-kit';

type Props = BaseProps & {
  contentComponent?: string | ReactElement;
  tooltipContent?: string | ReactElement;
  parentWidth?: number;
  step?: number;
  totalSteps?: number;
};

function ContentTooltip({
  contentComponent, tooltipContent, parentWidth, step, totalSteps
}: Props): ReactElement {
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  return (
    <div onMouseOver={() => setTooltipVisibility(true)} onMouseOut={() => setTooltipVisibility(false)}>
      {contentComponent}
      <LyraTooltip.Tooltip
        tooltipVisibility={tooltipVisibility}
        parentWidth={parentWidth}
        step={step}
        totalSteps={totalSteps}
      >
        {tooltipContent}
      </LyraTooltip.Tooltip>
    </div>
  );
}

export default ContentTooltip;
