import type {
  IDrawable, Drawable
} from '../mixins/Drawable';

type Modify<T, R> = Omit<T, keyof R> & R;

/**
 * Creates elements that can be drawn in a scene
 *
 */
export interface IDrawableStructureFactory {
  create<T extends Drawable>(
    c: new () => T,
    // Make IDrawable fields required, but T fields optional
    options: Modify<Partial<T>, IDrawable>
  ): T;
}

export class SiteStructureFactory implements IDrawableStructureFactory {
  create<T extends Drawable>(c: new () => T, options: Modify<Partial<T>, IDrawable>): T {
    const result = new c();
    Object.keys(options).forEach((key: string): void => {
      result[key as keyof T] = options[key as keyof Modify<Partial<T>, IDrawable>] as T[keyof T];
    });

    if (options.position) {
      result.mesh.position.copy(options.position);
    }
    if (result.mesh.isMesh) {
      if (options.material) {
        result.mesh.material = options.material;
      }
      if (options.geometry) {
        result.mesh.geometry = options.geometry;
      }
    }
    return result;
  }
}
