import {
  LyraDivider, LyraFormElementsDesignTool, LyraGrid, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import type { Option } from '@aurorasolar/lyra-ui-kit/lib/typings';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import { canvasConfig } from '../../../config/canvasConfig';
import useStore from '../../../stores/useStore';
import { SubpanelViewModel } from '../../../stores/UiStore/Properties/SiteEquipment/ViewModels/SubpanelViewModel';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyPanelContainer,
  PropertyPanelWrapper,
  ScrollablePanelContainer,
  ScrollablePanelContent,
  SubpanelRowWrapper
} from '../PropertyPanel/styles';

const Label = LyraTypography.Paragraph;
const Select = LyraFormElementsDesignTool.FormElementsDesignTool.Select.Select;
const TextField = LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField;

const Subpanel = (): ReactElement => {
  const {
    editor, domain, serviceBus
  } = useStore();
  const [viewModel, setViewModel] = useState<SubpanelViewModel>();

  useEffect((): void => {
    setViewModel(
      new SubpanelViewModel({
        editor,
        domain,
        serviceBus
      })
    );
  }, [editor, domain, serviceBus]);

  if (!viewModel) {
    return <></>;
  }
  return (
    <PropertyPanelWrapper>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon={'tool-properties'} iconBg={canvasConfig.subpanelIconColor} name={viewModel.title} />
      </PropertyPanelContainer>
      <ScrollablePanelContainer className="element_with_scroll">
        <ScrollablePanelContent padding="15px">
          <LyraGrid.Grid
            options={viewModel.exposureOptions}
            columns={2}
            selected={viewModel.exposure as LyraGrid.OptionProps}
            onSelect={(option: Option): void => viewModel.setExposure(option.value)}
            selectedOptionColor={EThemeComponentColor.PURPLE}
          />

          <LyraDivider.Divider
            className="mt-xs mb-xs"
            direction="horizontal"
            thickness="1px"
            color={EThemeComponentColor.GRAY_200}
          />
          <SubpanelRowWrapper>
            <Label lineHeight={1} margin="0 0 4px 0" type="h6">
              MANUFACTURER
            </Label>
            <Select
              listOptions={viewModel.manufacturerOptions}
              selected={viewModel.manufacturer?.value}
              onSelect={viewModel.setManufacturer}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </SubpanelRowWrapper>
          <SubpanelRowWrapper>
            <Label lineHeight={1} margin="0 0 4px 0" type="h6">
              MODEL NUMBER
            </Label>
            <TextField fontSize="10px" value={viewModel.model} onChange={viewModel.setModel} />
          </SubpanelRowWrapper>
          <SubpanelRowWrapper>
            <Label lineHeight={1} margin="0 0 4px 0" type="h6">
              BUSBAR RATING
            </Label>
            <Select
              listOptions={viewModel.busbarRatingOptions}
              selected={viewModel.busbarRating?.value}
              onSelect={viewModel.setBusbarRating}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </SubpanelRowWrapper>
          <SubpanelRowWrapper>
            <Label lineHeight={1} margin="0 0 4px 0" type="h6">
              BUSBAR POINT OF CONNECTION
            </Label>
            <Select
              listOptions={viewModel.busbarPointOfConnectionOptions}
              selected={viewModel.busbarPointOfConnection?.value}
              onSelect={viewModel.setBusbarPointOfConnection}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </SubpanelRowWrapper>
          <SubpanelRowWrapper>
            <Label lineHeight={1} margin="0 0 4px 0" type="h6">
              PANEL TYPE
            </Label>
            <Select
              listOptions={viewModel.typeOptions}
              selected={viewModel.type?.value}
              onSelect={viewModel.setType}
              selectedOptionColor={EThemeComponentColor.PURPLE}
            />
          </SubpanelRowWrapper>
          {viewModel.busbarRating && (
            <SubpanelRowWrapper>
              <Label lineHeight={1} margin="0 0 4px 0" type="h6">
                {viewModel.type.value === 'REGULAR' ? 'MAIN BREAKER RATING' : 'FEEDER BREAKER RATING'}
              </Label>
              {viewModel.type.value === 'REGULAR' ? (
                <Select
                  listOptions={viewModel.mainBreakerRatingOptions}
                  selected={viewModel.mainBreakerRating?.value}
                  onSelect={viewModel.setMainBreakerRating}
                  selectedOptionColor={EThemeComponentColor.PURPLE}
                />
              ) : (
                <Select
                  listOptions={viewModel.feederOcpdRatingOptions}
                  selected={viewModel.feederOcpdRating?.value}
                  onSelect={viewModel.setFeederOcpdRating}
                  selectedOptionColor={EThemeComponentColor.PURPLE}
                />
              )}
            </SubpanelRowWrapper>
          )}
        </ScrollablePanelContent>
      </ScrollablePanelContainer>

      <LyraDivider.Divider
        className="mt-xs"
        direction="horizontal"
        thickness="1px"
        color={EThemeComponentColor.GRAY_200}
      />
    </PropertyPanelWrapper>
  );
};
export default observer(Subpanel);
