import React, { ReactElement } from 'react';
import styled, { PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { BaseReactProps } from '../../typings/BaseReactProps';
import { getColorTheme } from '../Theme/ColorThemeProps';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

export const Regular = 'normal';
export type Margin = string | number;

export type FontWight = 'bold' | 'normal' | '300';

type TextProps = {
  color?: EThemeComponentColor;
  fontFamily?: string;
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  lineHeight: number;
  fontWeight: FontWight;
  fontSize: string;
  margin: Margin;
  textDecoration?: string;
};

export type TextLayoutProps = BaseReactProps & {
  color?: EThemeComponentColor;
  fontFamily?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  lineHeight?: number;
  fontWeight?: FontWight;
  fontSize?: string;
  margin?: Margin;
  text?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onClick?: () => void;
  textDecoration?: string;
};

const Text = styled.p<PropsTheme<TextProps>>`
  color: ${({ theme, color }: PropsTheme<TextProps>): Color | string =>
    color ? getColorTheme({ colorTheme: color, theme }) : 'inherit'};
  font-family: ${({ theme, fontFamily }: PropsTheme<TextProps>): string =>
    fontFamily || theme.fonts.fontFamily};
  text-align: ${({ align }: PropsTheme<TextProps>): string => align};
  line-height: ${({ lineHeight }: PropsTheme<TextProps>): number => lineHeight};
  font-weight: ${({ fontWeight }: PropsTheme<TextProps>): FontWight =>
    fontWeight || Regular};
  font-size: ${({ fontSize }: PropsTheme<TextProps>): string => fontSize};
  margin: ${({ margin }: PropsTheme<TextProps>): string =>
    margin + (typeof margin === 'string' ? '' : 'px')};
  text-decoration: ${({ textDecoration }: PropsTheme<TextProps>): string => textDecoration ? textDecoration : 'none'};
`;

export const TextLayout = ({
  color,
  fontFamily,
  align = 'left',
  lineHeight = 1.7,
  fontWeight = 'normal',
  fontSize,
  margin = '10px 0',
  onClick,
  children,
  textDecoration,
  text,
  type
}: TextLayoutProps): ReactElement => {
  return (
    <Text
      color={color}
      fontFamily={fontFamily}
      align={align}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      fontSize={fontSize ?? ''}
      textDecoration={textDecoration}
      margin={margin}
      as={type}
      onClick={onClick}
    >
      {text || children}
    </Text>
  );
};
