import React, { BaseProps, ChangeEvent, ReactElement, useMemo } from 'react';
import { Input } from '../FormElementsDesignTool/Input';
import { Cell, OptionProps } from './Cell';
import { Card, Container } from './styles';
import { EThemeComponentColor } from '../Theme/EThemeComponentColor';

type CustomValueInputAttrsType = {
  type?: string;
  min?: number;
  step?: number;
  autofocus?: boolean;
};
type GridProps = {
  options?: OptionProps[];
  hasCustomValues?: boolean;
  customCellLabel?: string;
  customValueInputAttrs?: CustomValueInputAttrsType;
  selectedOptionColor?: EThemeComponentColor | undefined;
  inputLabel?: string;
  selected?: OptionProps;
  columns?: number;
  gutter?: number;
  onSelect?: (option: OptionProps) => void;
};

const Grid = (props: BaseProps & GridProps): ReactElement => {
  const {
    className = '',
    options = [],
    hasCustomValues = false,
    customValueInputAttrs = null,
    selectedOptionColor,
    customCellLabel = 'Other',
    inputLabel,
    selected,
    columns = 1,
    gutter = 4,
    onSelect
  } = props;
  const gridProps = { columns, gutter, selectedOptionColor };
  const isCustomSelected = selected ? selected.name === customCellLabel : false;
  const customOption: OptionProps = {
    name: customCellLabel,
    value: isCustomSelected ? (selected ? selected.value : '') : ''
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void =>
    onSelect && onSelect({ ...customOption, value: e.target.value });

  return (
    <>
      <Container className={className}>
        {options.map(
          (option: OptionProps, index: number): ReactElement => (
            <Cell
              key={`cell-${index}`}
              option={option}
              isSelected={selected ? selected.name === option.name : false}
              onSelect={onSelect}
              {...gridProps}
            >
              {!!option.component ? option.component : option.name}
            </Cell>
          )
        )}
      </Container>

      {hasCustomValues && (
        <Container className={`${className} mt-xxs`}>
          <Cell
            option={customOption}
            isSelected={isCustomSelected}
            onSelect={onSelect}
            {...gridProps}
          >
            {customCellLabel}
          </Cell>

          {isCustomSelected && (
            <Card
              className="custom-input"
              {...gridProps}
              selectedOptionColor={gridProps.selectedOptionColor as unknown as number}
            >
              <Input
                className="ph-xxs"
                rightText={inputLabel}
                value={customOption.value}
                onChange={onInputChange}
                {...customValueInputAttrs}
              />
            </Card>
          )}
        </Container>
      )}
    </>
  );
};

export { Grid, GridProps };
