import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useEffect, useState
} from 'react';
import TipComponent from './Tip';

type Props = {
  displayTip: boolean;
  onShowTip: (show: boolean) => void;
  showHintForMPPT: boolean;
};

const TipSection = observer(({
  displayTip, onShowTip, showHintForMPPT
}: Props): ReactElement => {
  const [showTip, setShowTip] = useState(true);

  useEffect((): void => {
    setShowTip(displayTip);
  }, [displayTip]);

  const handleClick = (): void => {
    setShowTip(!showTip);
    onShowTip(showTip);
  };

  return <TipComponent isShow={showTip} showHintForMPPT={showHintForMPPT} onClick={(): void => handleClick()} />;
});

export default TipSection;
