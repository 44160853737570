import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import Flexbox from '../../../components/Layout/Flexbox';

type MultiEditProps = {
  hideLabel: boolean;
};

export const Stretcher = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Scrollable = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
`;

export const MultiEditWrapper = styled.div<PropsTheme<MultiEditProps>>`
  width: 90%;
  text-align: end;
  ${({ hideLabel }: PropsTheme<MultiEditProps>): string => {
    if (hideLabel) {
      return `
      margin-bottom: 10px;
      visibility: visible;
    `;
    } else {
      return `
      display: none;
      visibility: hidden;
    `;
    }
  }}
`;

export const SubmitSectionWrapper = styled(Flexbox).attrs({
  dir: 'column',
  justify: 'center'
})`
  margin-top: auto;
  width: calc(100% + 30px);
  margin-left: -15px;
`;
