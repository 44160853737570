import React, { BaseProps, ReactElement, useCallback, useState } from 'react';
import { TextField } from '../../FormElementsDesignTool/TextField';
import { RightIcons } from '../RightButtons';
import { BorderedWrapper, LevelStrip, RoofFaceContainer, Separator, TextRow } from '../styles';
import { ColorsFactory } from '../ColorsFactory';

export type RoofFaceProps = BaseProps & {
  id?: string;
  hasBorder?: boolean;
  level?: number;
  value?: string;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onNameChange?: (value: string) => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

const RoofFace = (props: RoofFaceProps): ReactElement => {
  const {
    id,
    className = '',
    styles = {},
    hasBorder = true,
    level = 1,
    value = '',
    onDeleteClick,
    onEditClick,
    onNameChange,
    onVisibilityToggle
  } = props;
  const [hover, setHover] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    (event: React.KeyboardEvent, text: string): void => {
      if (event.key === 'Enter') {
        setEditing(false);
        if (!!text) {
          onNameChange?.(text);
        }
      }
    },
    [setEditing, onNameChange]
  );

  // Fallback to default color
  // in case no such level found.
  const bgColor = ColorsFactory[level] || ColorsFactory[1];

  return (
    <RoofFaceContainer
      key={id}
      className={className}
      style={styles}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      <LevelStrip bgColor={bgColor} />
      <BorderedWrapper hasBorder={hasBorder}>
        {editing ? (
          <TextField
            width="130px"
            name="building-name"
            value={value}
            onKeyUp={handleChange}
          />
        ) : (
          <TextRow
            onClick={(): void => setEditing(true)}
            data-testid="roof-face-button"
          >
            {value}
          </TextRow>
          )}
        <Separator />
        {hover && !editing
          && (
            <RightIcons
              theme="dark"
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              onVisibilityToggle={onVisibilityToggle}
            />
          )
        }
      </BorderedWrapper>
    </RoofFaceContainer>
  );
};

export { RoofFace };
