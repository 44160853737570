import React from 'react';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import type { WidgetProps } from '@rjsf/utils/lib/types';

const { NumberField } = LyraFormElementsDesignTool.FormElementsDesignTool;

const LyraNumberSpinner = (props: WidgetProps) => {
  const { onChange } = props;
  return <NumberField.NumberField className="roller-field" onChange={(event) => onChange(event)} />;
};

export default LyraNumberSpinner;
