import type { ReactElement } from 'react';
import React, { useState } from 'react';
import {
  LyraFormElementsDesignTool, LyraLayout
} from '@aurorasolar/lyra-ui-kit';
import type { WidgetProps } from '@rjsf/utils/lib/types';

const { Toggle } = LyraFormElementsDesignTool.FormElementsDesignTool;

const LyraToggle = (props: WidgetProps): ReactElement => {
  const {
    value, onChange, id
  } = props;
  const [checked, setChecked] = useState(value || false);

  return (
    <LyraLayout.ContainerWithTheme>
      <Toggle.Toggle
        id={id}
        name={id}
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setChecked(event.target.checked);
          onChange(event.target.checked);
        }}
      />
    </LyraLayout.ContainerWithTheme>
  );
};

export default LyraToggle;
