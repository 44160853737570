import type { IDesignCreationRequest } from '../../../domain/request/DesignCreationRequest/IDesignCreationRequest';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface ICreateDesignDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly designCreationRequest: IDesignCreationRequest;
}

export class CreateDesignCommand extends BaseCommand {
  private readonly designCreationRequest: IDesignCreationRequest;
  private readonly domain: DomainStore;

  constructor(dependencies: ICreateDesignDependencies) {
    super();
    this.domain = dependencies.domain;
    this.designCreationRequest = dependencies.designCreationRequest;
  }

  async execute(): Promise<void> {
    await this.domain.createDesign(this.designCreationRequest);
  }
}
