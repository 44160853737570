import { LyraModal } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { CircuitTableViewModel } from '../../Pages/viewModels/CircuitTable/CircuitTableViewModel';
import { useUiStore } from '../../../../stores/useStore';
import '../../Pages/pages/CircuitTable/custom.css';
import { isEmpty } from '../../../../utils/helpers';
import { CircuitTableLyraDesign } from '../../Pages/pages/CircuitTable/CircuitTableLyraDesign';

const ModalWrapper = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 10px;
`;

const CircuitTableModal = observer((): ReactElement => {
  const { modal } = useUiStore();
  const circuitTableViewModel = modal.viewModel as CircuitTableViewModel;

  const {
    circuitData, tableData, headerItems, optionChange, inputChange, errors, disableAllFields
  } =
    circuitTableViewModel;

  return (
    <LyraModal.Modal
      title="Circuit Table"
      leftButtonLabel="Back to Electrical BOS"
      rightButtonLabel="Proceed to Mounting BOS"
      rightButtonDisabled={!isEmpty(errors) || disableAllFields}
      $show={true}
      showFooterDivider={false}
      width="100%"
      onClickCloseButton={(): void => circuitTableViewModel.backToElectricalBosStage()}
      onClickLeftButton={(): void => circuitTableViewModel.backToElectricalBosStage()}
      onClickRightButton={(): void => circuitTableViewModel.processToMountingBosStage()}
    >
      <ModalWrapper>
        <CircuitTableLyraDesign circuitTableViewModel={circuitTableViewModel} />
      </ModalWrapper>
    </LyraModal.Modal>
  );
});

export default CircuitTableModal;
