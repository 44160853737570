import { LyraIcon } from '@aurorasolar/lyra-ui-kit';
import type { TitleFieldProps } from '@rjsf/utils';
import React, { useState } from 'react';
import {
  ATTRIBUTE_DELIMITER, ATTRIBUTE_KEY_VALUE_DELIMITER, DEFAULT_GROUP
} from '../interpreters/schema';

/**
 * This is a template for group headers
 *
 * Reference: https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates/#titlefieldtemplate
 *
 * Modifications:
 * - The entire header is clickable, which causes the group it belongs to to collapse
 * - Title has a "dropdown" arrow next to it
 * - Don't render '*' if underlying field is required
 * - If present, render group attributes under the title
 */
const GroupHeaderTemplate = ({ title, schema }: TitleFieldProps): JSX.Element => {
  const [hideGroup, setHideGroup] = useState(false);

  const descriptionToHtml = (description?: string): JSX.Element[] | undefined => {
    if (!description) {
      return;
    }
    return description.split(ATTRIBUTE_DELIMITER)
      .map((entry: string): string[] => entry.split(ATTRIBUTE_KEY_VALUE_DELIMITER))
      .flatMap((splitEntry: string[]) => (
        <div key={splitEntry[0]} className="description-entry">{splitEntry[0]}: <span>{splitEntry[1]}</span></div>
      ));
  };

  return title !== DEFAULT_GROUP ? (
    <div className={`group-head${hideGroup ? ' hidden-group' : ''}${schema.description? ' withDescription' : ''}`} onClick={() => setHideGroup(!hideGroup)}>
      <div className="title-and-arrow">
        <h4>{title}</h4>
        <LyraIcon.Icon name={`accordion-arrow-${hideGroup ? 'up' : 'down'}`} />
      </div>
      {
        schema.description
          ? <div className="group-description">{descriptionToHtml(schema.description)}</div>
          : null
      }
    </div>
  ) : <></>;
};

export default GroupHeaderTemplate;
