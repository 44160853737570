import styled from 'styled-components';

type Props = {
  size?: string;
  justifyContent?: string;
  hasPadding?: boolean;
  margin?: string;
  visible?: boolean;
};

function containerSize(props: Props): string {
  return `flex: ${props.size} 1 auto`;
}

export const TopBarStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div<Props>`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  ${containerSize};
`;

export const Container = styled.div<Props>`
  display: ${({ visible = true }: Props): string => (visible ? 'flex' : 'none')};
  justify-content: ${({ justifyContent = 'space-around' }: Props): string => justifyContent};
  height: 100%;
  box-sizing: border-box;
  padding-right: ${({ hasPadding }: Props): string => (hasPadding ? '8px' : '')};
  ${containerSize}
`;

export const EditTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 120px;
`;

export const EditTooltipIcon = styled.div`
  width: 30px;
  height: 15px;
  margin-right: 5px;
`;
