import type { Color } from '@aurorasolar/lyra-ui-kit';
import type {
  Object3D, Vector3
} from 'three';
import type PvModulePosition from '../../models/SiteDesign/PvModulePosition';
import type EditorStore from '../../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import {
  getCenterOfBoundingBoxAroundVertices, isPolygonInsideAnother
} from '../../../utils/spatial';
import type { IVertexData } from '../../entities/SiteDesign/Vertex';
import { canvasConfig } from '../../../config/canvasConfig';
import { PolygonDrawable } from '../../mixins/PolygonDrawable';
import { SceneObjectType } from '../Constants';
import { Hoverable } from '../../mixins/Hoverable';
import { Draggable } from '../../mixins/Draggable';
import { Selectable } from '../../mixins/Selectable';
import { Drawable } from '../../mixins/Drawable';
import {
  getLyraModelByOptionalMesh, getParentLyraModelByMesh
} from '../../sceneObjectsWithLyraModelsHelpers';
import type { SimpleVector2 } from '../../../utils/ThreeUtils';

type PolygonData = { x: number; y: number; z: number };

interface IPvModuleParams {
  positionVertices: PolygonData[];
  id: string;
}

const PANEL_ARRAY_THICKNESS = 1;

const MixedClass = PolygonDrawable(Hoverable(Draggable(Selectable(Drawable(class SimpleClass {})))));

class PvModule extends MixedClass {
  propertyId: string = SceneObjectType.PvModule;
  selectWithParent: boolean = false;
  override color: Color = '#000000';
  disabledColor: Color = '#9d0007';
  selectedColor: Color = '#167524';
  isMultipleVertices: boolean = true;
  withParent: boolean = true;
  override nonResettable: boolean = true;

  /** Layer Number */
  layerNumber: number = 5;

  constructor({
    positionVertices, id
  }: IPvModuleParams) {
    super();
    this.serverId = id;
    this.type = SceneObjectType.PvModule;
    this.boundary.defaultThickness = PANEL_ARRAY_THICKNESS;
    this.changeMeshMaterial(this.color);
    this.addVertices(positionVertices);
    this.mesh.renderOrder = 1;
    this.redraw();
  }

  toCanvasCoordinatesInPixels(): SimpleVector2 {
    return this.pvModulePosition.toCanvasCoordinatesInPixels();
  }

  get pvModulePosition(): PvModulePosition {
    const parent: PvModulePosition = getParentLyraModelByMesh(this.mesh);
    if (!parent) {
      throw new Error(`PV module ${this.serverId} does not have a parent PV module position`);
    }
    return parent;
  }

  hasFill(): boolean {
    return true;
  }

  onClose(): void {
    /** */
  }

  showFill(): boolean {
    return true;
  }

  showLines(): boolean {
    return true;
  }

  dragVertices(): boolean {
    return false;
  }

  showVertices(): boolean {
    return this.selected;
  }

  getCenter(): Vector3 {
    return getCenterOfBoundingBoxAroundVertices(this.getVector3s());
  }

  moveWithParent(withParent: boolean): void {
    this.withParent = withParent;
  }

  override move(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (this.isDetached) {
      return;
    }

    const parent = this.pvModulePosition;

    if (this.withParent) {
      parent.move(newPosition, editor, smartGuides);

      parent.boundary.vertices.forEach((vector: IVertexData, index: number): void => {
        this.polygon[index].mesh.position.x = vector.x;
        this.polygon[index].mesh.position.y = vector.y;
        this.polygon[index].mesh.position.z = vector.z;
        this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
      });
    } else {
      this.initialPositions.forEach((vector: Vector3, index: number): void => parent.resetVertex(vector, index));
      newPosition.forEach((vector: Vector3, index: number): void => {
        this.polygon[index].mesh.position.x = vector.x;
        this.polygon[index].mesh.position.y = vector.y;
        this.polygon[index].mesh.position.z = vector.z;
        this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
      });
    }
  }

  /**
   * @returns is valid
   */
  override afterMove(newPosition: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    if (this.isDetached) {
      return false;
    }

    const parent = this.pvModulePosition;

    if (this.withParent) {
      parent.afterMove(newPosition, editor, smartGuides);

      this.updateCanMove(editor, newPosition);
    } else {
      this.canMove = true;
    }
    this.updateColor();
    return true;
  }

  override onDragFinish(editor: EditorStore, smartGuides: SmartGuidesStore): void {
    this.isDragging = false;
    smartGuides.resetGuides();
    this.renderOrder = 0;
  }

  resetVertex(vector: Vector3, index: number): void {
    if (this.isDetached) {
      return;
    }

    this.polygon[index].mesh.position.x = vector.x;
    this.polygon[index].mesh.position.y = vector.y;
    this.polygon[index].mesh.position.z = vector.z;
    this.boundary.updateFirstOrLastVertexIfNeeded(this.polygon[index]);
    this.pvModulePosition.resetVertex(vector, index);
  }

  changeMeshMaterial(color: Color): void {
    this.color = color;
    this.redraw();
  }

  changeMeshDefaultMaterial(): void {
    this.color = '#000000';
    this.redraw();
  }

  removeChildFromModel(object3D: Object3D): void {
    // Not implemented
  }

  /**
   * Checks whether PvModule is detached from parent object (roof face).
   */
  get isDetached(): boolean {
    return !getLyraModelByOptionalMesh(this.mesh.parent?.parent);
  }

  /**
   * Sets validation state for PV module
   * @param editor
   * @param newPosition
   * @param presetCollidableObjects, isPvModulePositionPreview supposed to be already filtered out of this list
   */
  updateCanMove(
    editor: EditorStore,
    newPosition: Vector3[] = this.getVector3s(),
    presetCollidableObjects: PolygonDrawable[] = []
  ): void {
    // When switching stages it's possible to handle selection change with a list of pv modules,
    // detached from parent object (roof face).
    if (this.isDetached) {
      return;
    }

    const collidableObjects: PolygonDrawable[] = presetCollidableObjects.length
      ? presetCollidableObjects
      : editor.getCollidableObjects();

    if (this.isDetached) {
      this.canMove = false;
      return;
    }

    const parent = this.pvModulePosition;
    this.canMove =
      isPolygonInsideAnother(newPosition, parent.roofFace.getVector3s())
      && parent.validateCollision(newPosition, collidableObjects);
    parent.canMove = this.canMove;
  }

  updateColor(): void {
    this.changeMeshMaterial(this.currentColor());
    if (this.isDetached) {
      return;
    }
    this.pvModulePosition.updateColor();
  }

  private currentColor(): Color {
    const {
      pvModuleHoveredColor, pvModuleDefaultColor
    } = canvasConfig;

    if (!this.canMove) {
      return this.disabledColor;
    }
    if (this.isDragging || this.selected) {
      return this.selectedColor;
    }

    return this.hovered ? pvModuleHoveredColor : pvModuleDefaultColor;
  }

  highlightStringingState(color: string): void {
    this.changeMeshMaterial(color);
  }

  equals(module: PvModule): boolean {
    return this.serverId === module.serverId;
  }
}

export default PvModule;
