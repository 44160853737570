import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import type { IWorkspace } from '../types';
import type { IStage } from '../../../../domain/stages/IStage';
import type { IStageManager } from '../../../../domain/stages/IStageManager';
import type { IWizardStage } from '../../../../domain/stages/IWizardStage';
import type { IWizardStager } from '../../../../domain/stages/IWizardStager';

export abstract class DefaultWorkspace implements IWorkspace {
  id: string = '';

  toolbarRegistry: string = '';

  defaultToolsWhiteList: string[] = [];

  @observable
  wizard?: IWizardStager<IWizardStage>;

  stageManager?: IStageManager<IStage>;

  @observable
  saving: boolean = false;

  @observable
  inProgress: boolean = false;

  @observable
  errors: IObservableArray<Error> = observable([]);

  abstract setup(): void;

  abstract dispose(): void;

  abstract saveManually(): Promise<void>;

  abstract canContinue(): Promise<boolean>;
}
