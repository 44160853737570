import type { INameable } from '../../../domain/typings/INameable';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type EditorStore from '../../EditorStore/EditorStore';
import type { Building } from '../../../domain/models/SiteDesign/Building';
import type { IConfirmableCommand } from './Command';
import { BaseCommand } from './Command';

export interface IDeleteBuildingDependencies extends IBaseCommandDependencies {
  readonly editor: EditorStore;
  readonly object: INameable;
  readonly domain: DomainStore;
}

// DeleteObjectCommand should only be used with Drawable objects
export class DeleteBuildingCommand extends BaseCommand implements IConfirmableCommand {
  private readonly editor: EditorStore;
  private readonly building: Building;
  private readonly domain: DomainStore;

  constructor(dependencies: IDeleteBuildingDependencies) {
    super();
    this.editor = dependencies.editor;
    this.building = dependencies.object as Building;
    this.domain = dependencies.domain;
  }

  get confirmationModalText(): string {
    return `Are you sure you want to delete ${this.building.name}?`;
  }

  execute(): Promise<void> {
    this.deleteFromDomain();
    this.deleteFromEditor();
    return Promise.resolve();
  }

  private deleteFromDomain(): void {
    this.domain.project.site.deleteBuildings([this.building.id]);
  }

  private deleteFromEditor(): void {
    this.building.roofFaces.forEach((roofface: RoofFace): void => this.editor.removeObject(roofface.mesh));
    if (this.building.roofOutline) {
      this.editor.removeObject(this.building.roofOutline.mesh);
    }
  }
}
