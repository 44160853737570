/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import type {
  FC, EffectCallback, ReactElement
} from 'react';
import React, {
  useContext, useEffect, useRef
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import Vector2D from '../../../domain/models/Vector2D';
import useStore, { useUiStore } from '../../../stores/useStore';
import config, { UI_MODE } from '../../../config/config';

type ContainerPanelProps = {
  hiddenPanel?: boolean;
  hiddenCanvas?: boolean;
};
const ContainerPanel = styled.div<ContainerPanelProps>`
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ hiddenPanel }: ContainerPanelProps): string => (hiddenPanel ? 'display: none;' : '')}

  & > canvas {
    ${({ hiddenCanvas }: ContainerPanelProps): string => (hiddenCanvas ? 'visibility: hidden;' : '')}
    flex: 0 1 auto;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
  }
`;

const DrawViewport: FC = (): ReactElement => {
  const { editor } = useStore();
  const containerRef = useRef({} as HTMLDivElement);
  const DS = useContext(ThemeContext)!.DS;

  const resizeWindow = (): void => {
    editor.onWindowResize(new Vector2D(containerRef.current.offsetWidth, containerRef.current.offsetHeight));
  };

  useEffect((): ReturnType<EffectCallback> => {
    window.addEventListener('resize', resizeWindow);
    return (): void => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect((): void => {
    editor.setup(
      new Vector2D(containerRef.current.offsetWidth, containerRef.current.offsetHeight),
      containerRef.current
    );
  }, [
    // Editor should be constant throughout whole app lifecycle though.
    // Reinitialization of EditorStore is not supported.
    editor
  ]);

  const {
    pages, workspace
  } = useUiStore();

  const pagesMode = !!pages.pageId;
  const stageManager = workspace.currentWorkspace.stageManager;
  const currentStageNumber = stageManager?.stages.indexOf(stageManager?.currentStage) ?? -1;
  const isAuroraUiBeforeElectricalStage = config.featureFlag.uiMode === UI_MODE.AURORA && currentStageNumber < 4;

  return (
    <ContainerPanel
      hiddenPanel={pagesMode}
      hiddenCanvas={isAuroraUiBeforeElectricalStage}
      ref={containerRef}
      id="lyra-canvas-container"
    >
      {isAuroraUiBeforeElectricalStage && <DS.Loader />}
    </ContainerPanel>
  );
};

export default observer(DrawViewport);
