import { LyraFontFamily } from '@aurorasolar/lyra-ui-kit';
import type {
  DefaultTheme, PropsTheme, SimpleInterpolation
} from 'styled-components';
import styled, { css } from 'styled-components';
import type { Color } from '../../../../../domain/typings';
import Flexbox from '../../../../components/Layout/Flexbox';
import type { PropsWithTheme } from '../../../../components/Button/styles';
import config, { UI_MODE } from '../../../../../config/config';

const {
  H4, Notes
} = LyraFontFamily.FontFamily;

type Props = {
  align?: string;
};

type DataInputProps = PropsTheme & {
  error?: boolean;
};

export const SummaryHeader = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  height: 30px;
  border-radius: 2px;
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity1};
  margin-top: 20px;
  width: 100%;
`;

export const SummaryText = styled(Notes)`
  font-weight: normal;
  text-align: center;
  font-size: 14px;
`;

export const SeparatorLine = styled.div`
  height: 1px;
  background-color: rgba(107, 111, 113, 0.25);
`;

export const VoltageDropView = styled.div`
  display: flex;
  justify-content: space-between;
  width: 450px;
`;

export const VoltageDropText = styled(H4)`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  line-height: 0.94;
  margin-top: 11px;
  margin-left: 5px;
`;

export const Table = styled.div`
  width: 100%;
  display: inline-table;
  ${(): SimpleInterpolation =>
    config.featureFlag.uiMode !== UI_MODE.AURORA
      ? css`
        font-family: 'Roboto', sans-serif;
      `
      : css``};
  font-size: 12px;
  font-weight: normal;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableHeaderWrapper = styled.div`
  display: table-row;
  width: 100%;
`;

export const HeaderItem = styled.div<Props>`
  background: ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity2};
  border-radius: 2px;
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  text-align: ${({ align }: Props): string => align ?? 'center'};
  border: 1px solid white;
`;

export const TableRowWrapper = styled.div`
  display: table-row;
  width: 100%;
  margin-top: 1px;
`;

export const RowItem = styled.div`
  display: table-cell;
  vertical-align: middle;
  margin: 5px 0;
  /*padding: 10px 0;*/
  text-align: center;
  /*border-bottom: 1px solid ${({ theme }: PropsWithTheme): Color => theme.colors.grayOpacity3};*/

  /* Cells */
  /* Auto layout */
  /*display: flex;
  flex-direction: column;
  align-items: flex-start;*/
  /*padding: 12px 16px;*/
  padding: ${({ theme }: PropsTheme): string => (theme.auroraMode ? '12px 6px' : '12px 12px 12px 10px')};
  /*gap: 16px;*/

  /* Brand/White — Primary */
  background-color: ${({ theme }: PropsWithTheme): Color => (theme.auroraMode ? 'white' : theme.colors.grayColor7)};
  mix-blend-mode: normal;
  /* HR/Top/Default */
  box-shadow: inset 0px 1px 0px #dbdcdd;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &.firstItem {
    padding-left: 40px;
  }

  &.lastItem {
    padding-right: 40px;
  }

  .changed {
    background-color: ${({ theme }: PropsTheme): Color =>
    theme.auroraMode ? theme.colors.uiHighlight : theme.colors.greenColor1} !important;
  }
`;

export const PlainText = styled.div<Props>`
  font-size: 12px;
  text-align: ${({ align }: Props): string => align ?? 'center'};
  font-weight: normal;
`;

const getInputBg = (theme: DefaultTheme): Color => (theme.auroraMode ? theme.colors['gray-200'] : theme.colors.whiteBg);

export const DataSelect = styled.select`
  height: ${({ theme }: PropsTheme): Color => (theme.auroraMode ? 'auto' : '22px')};
  font-size: 12px;
  border: ${({ theme }: PropsTheme): string => (theme.auroraMode ? 'none' : `1px solid ${theme.colors.lineHr}`)};
  border-radius: ${({ theme }: PropsTheme): string => (theme.auroraMode ? 'none' : '5px')};
  padding: 4px;
  box-shadow: ${({ theme }: PropsTheme): string => (theme.auroraMode ? 'none' : 'inset 0px 1px 0px #DBDCDD')};

  color: ${({ theme }: PropsWithTheme): Color => theme.colors['gray-600']};
  background-color: ${({
    theme, error
  }: DataInputProps): Color =>
    error ? theme.colors.redColor2 : getInputBg(theme)};
  &:disabled,
  [disabled] {
    cursor: not-allowed;
    color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
    background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray3};
  }
`;

export const DataInput = styled.input<DataInputProps>`
  width: 100%;
  height: ${({ theme }: PropsTheme): Color => (theme.auroraMode ? 'auto' : '22px')};
  border: ${({ theme }: PropsTheme): Color => (theme.auroraMode ? 'none' : `1px solid ${theme.colors.lineHr}`)};
  border-radius: ${({ theme }: PropsTheme): string => (theme.auroraMode ? 'none' : '5px')};
  padding: 4px;
  box-shadow: ${({ theme }: PropsTheme): string => (theme.auroraMode ? 'none' : 'inset 0px 1px 0px #DBDCDD')};

  font-size: 12px;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors['gray-600']};
  background-color: ${({
    theme, error
  }: DataInputProps): Color =>
    error ? theme.colors.redColor2 : getInputBg(theme)};
  margin: 0;
  &:disabled,
  [disabled] {
    cursor: not-allowed;
    color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
    background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.gray3};
  }
`;
