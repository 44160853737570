import {
  LyraDivider, LyraFormElementsDesignTool
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import { type ReactElement, useContext } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { type PropsTheme, ThemeContext } from 'styled-components';
import { withTheme } from 'styled-components';
import { useUiStore } from '../../../stores/useStore';
import type { IRoofProtrusionViewModel } from '../../../stores/UiStore/Properties/RoofProtrusion/RoofProtrusionViewModel';
import PropertyPanelHeader from '../PropertyPanel/PropertyPanelHeader/PropertyPanelHeader';
import {
  PropertyPanelContainer,
  PropertyPanelWrapper,
  ScrollablePanelContainer,
  ScrollablePanelContent
} from '../PropertyPanel/styles';
import { BasicPropsContainer } from '../RoofFaceProperties/styles';
import type {
  Event, EditProtrusionEventPayload
} from '../../../services/eventSystem/eventSystemHook';
import {
  EventType, useEventSystemSubscribe
} from '../../../services/eventSystem/eventSystemHook';
import { RoofProtrusionViewModel } from '../../../stores/UiStore/Properties/RoofProtrusion/RoofProtrusionViewModel';
import Field from '../../components/Field';
import Collapse from '../../components/Collapse';
import Switch from '../../components/Switch/Switch';
import {
  FieldsetInlineStyle, FieldsetWrapper
} from './styles';

const RoofProtrusions = (): ReactElement => {
  const { roofProtrusion: roofProtrusionStore } = useUiStore();
  const theme = useContext(ThemeContext);
  const { currentRoofProtrusion } = roofProtrusionStore;
  const [roofProtrusionViewModel, setViewModel] = useState<IRoofProtrusionViewModel>(currentRoofProtrusion!);
  const [[width, length], setWidthAndLength] = useState<[number, number]>([
    roofProtrusionViewModel.width,
    roofProtrusionViewModel.length
  ]);

  const protrusionDimensionsChangedHandler = useCallback(
    (event: Event<EditProtrusionEventPayload>) => {
      setWidthAndLength([
        RoofProtrusionViewModel.convertProtrusionDistanceFromWorldUnits(event.payload!.width),
        RoofProtrusionViewModel.convertProtrusionDistanceFromWorldUnits(event.payload!.length)
      ]);
    },
    [setWidthAndLength]
  );
  useEventSystemSubscribe(EventType.EditProtrusion, protrusionDimensionsChangedHandler);

  useEffect((): void => {
    setViewModel(currentRoofProtrusion!);
    setWidthAndLength([currentRoofProtrusion!.width, currentRoofProtrusion!.length]);
  }, [currentRoofProtrusion]);

  // Redraw protrusion when changes dimensions through the form
  useEffect((): void => {
    currentRoofProtrusion!.roofProtrusion.redraw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoofProtrusion!.width, currentRoofProtrusion!.length]);

  const onSwitch = useCallback(
    (value: boolean): void => {
      roofProtrusionViewModel.changeFirefighter(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roofProtrusionViewModel.roofProtrusion.firefighter]
  );

  return (
    <PropertyPanelWrapper>
      <PropertyPanelContainer>
        <PropertyPanelHeader icon="tool-properties" name="Protrusion Properties" />
      </PropertyPanelContainer>
      <ScrollablePanelContainer className="element_with_scroll">
        <ScrollablePanelContent>
          <BasicPropsContainer>
            <Collapse title="Properties" open={true} color={theme!.colors.secondaryFont}>
              <Field label="PROTRUSION NAME">
                <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                  key={roofProtrusionViewModel.id}
                  value={roofProtrusionViewModel.roofProtrusion.name}
                  onChange={roofProtrusionViewModel.changeName}
                />
              </Field>
              {/* TODO (LYRA-4986): Revert width and height fields to number inputs and then fix them */}
              <FieldsetWrapper>
                <FieldsetInlineStyle label="WIDTH" width="48%">
                  <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                    key={roofProtrusionViewModel.id}
                    value={`${width} in`}
                    disabled={true}
                    width="100%"
                  />
                </FieldsetInlineStyle>
                <FieldsetInlineStyle label="LENGTH" width="48%">
                  <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                    key={roofProtrusionViewModel.id}
                    value={`${length} in`}
                    disabled={true}
                    width="100%"
                  />
                </FieldsetInlineStyle>
              </FieldsetWrapper>
              <Field inline={true} label="FIREFIGHTER CAN SAFELY STEP OVER">
                <Switch onChange={onSwitch} actived={roofProtrusionViewModel.roofProtrusion.firefighter} />
              </Field>
            </Collapse>
          </BasicPropsContainer>
          <LyraDivider.Divider className="mt-xs" direction="horizontal" color={EThemeComponentColor.GRAY_200} />
        </ScrollablePanelContent>
      </ScrollablePanelContainer>
    </PropertyPanelWrapper>
  );
};

export default observer(RoofProtrusions);
