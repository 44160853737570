import { MathUtils } from 'three';

type Limit = {
  lower: number;
  upper: number;
};

export default Limit;

/**
 * Tests whether the value provided is within limits (inclusive)
 */
export function isWithin(limit: Limit, value: number): boolean {
  return value >= limit.lower && value <= limit.upper;
}

/**
 * Fits the value to the limit:
 * - If the value is within limits, returns the value as-is
 * - If the value is outside of the limit, returns the nearest value within limit
 */
export function fit(limit: Limit, value: number): number {
  return MathUtils.clamp(value, limit.lower, limit.upper);
}
