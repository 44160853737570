import env from '@beam-australia/react-env';
import type IBaseImageryProviderConfig from '../domain/models/IBaseImageryProviderConfig';
import type {
  ApiConfig, Config, SentryConfig, FeatureFlagConfig
} from '../domain/typings';
import {
  EGoogleMapType, BaseImageryProvider
} from '../domain/typings';
import type {
  IHostAppConfig, IUserConfig
} from '../domain/typings/Config';
import type { IAnalytics } from '../services/analytics/IAnalytics';
import { DesignToolAnalytics } from '../services/analytics/DesignToolAnalyticsEvents';

export enum UI_MODE {
  AURORA = 'aurora',
  LYRA = 'lyra'
}

const designTool = {
  name: process.env.LYRA_ENV_DESIGN_TOOL_NAME ?? '', // set in webpack/utils/plugins.js
  version: process.env.LYRA_ENV_DESIGN_TOOL_VERSION ?? '' // set in webpack/utils/plugins.js
};

// To pass environment variables to the client app in the build
// script, react-scripts requires using the  prefix to avoid
// exposing server secrets to the client side.

// Lyra API
const apiDesignUrl = env('API_DESIGN');
const apiDocumentsUrl = env('API_DOCUMENTS');
const apiEquipmentUrl = env('API_EQUIPMENT');
const apiAuthoritiesUrl = env('API_AUTHORITIES');
const apiUtilitiesUrl = env('API_UTILITIES');
const apiCdnBase = env('CDN_BASE');

const api: ApiConfig = {
  design: apiDesignUrl,
  documents: apiDocumentsUrl,
  equipment: apiEquipmentUrl,
  authorities: apiAuthoritiesUrl,
  utilities: apiUtilitiesUrl,
  cdn: apiCdnBase
};

/* Site settings */

// Site and Meta info
const site: Config['site'] = {
  canonicalRootUrl: env('CANONICAL_ROOT_URL'),
  knowledgeBaseUrl: env('SUPPORT_SITE')
};

// Map imagery config
const mapSize = `${env('MAPS_RESOLUTION_X')}x${env('MAPS_RESOLUTION_Y')}`;

function baseImageryConfig(provider: BaseImageryProvider): IBaseImageryProviderConfig {
  if (provider === BaseImageryProvider.GOOGLE_MAPS) {
    return {
      name: BaseImageryProvider.GOOGLE_MAPS,
      url: env('GOOGLE_MAPS_STATIC_URL'),
      zoomLimit: Number(env('GOOGLE_MAPS_ZOOM')),
      options: {
        key: env('GOOGLE_MAPS_API_KEY'),
        // Google Maps URL signing secret
        privateKey: env('GOOGLE_MAPS_PRIVATE_KEY'),
        scale: env('GOOGLE_MAPS_SCALE'),
        maptype: EGoogleMapType.HYBRID,
        size: mapSize,
        // Street view and oblique imagery
        disableDefaultUI: env('GOOGLE_MAPS_DISABLE_DEFAULT_UI'),
        tilt: env('GOOGLE_MAPS_TILT'),
        factorHeading: env('GOOGLE_MAPS_FACTOR_HEADING'),
        pitch: env('GOOGLE_MAPS_PITCH')
      }
    };
  }
  // Note: Nearmap provider is not yet supported
  // if (provider === BaseImageryProvider.NEARMAP) {
  //   return {
  //     name: BaseImageryProvider.NEARMAP,
  //     url: env('NEARBOX_STATIC_URL'),
  //     zoomLimit: Number(env('NEARBOX_ZOOM')),
  //     options: {
  //       apikey: env('NEARBOX_API_KEY'),
  //       httpauth: env('NEARBOX_HTTP'),
  //       size: mapSize
  //     }
  //   };
  // }
  throw new Error(`Unsupported provider: ${provider}`);
}

const sentryMode = env('SENTRY_ENABLED') === 'true';
const sentryDsn = env('SENTRY_DSN');

const sentryMaxBreadcrumbs = Number(env('SENTRY_BREADCRUMBS')) || 100;
const sentryDebug = env('SENTRY_DEBUG') === 'true';
const sentryLogLevel = env('SENTRY_LOG_LEVEL') || 'debug';
const sentryAttachStacktrace = env('SENTRY_STACKTRACE') === 'true';
const sentryAutoSessionTracking = env('SENTRY_SESSION_TRACKING') === 'true';
const sentryMaxStoredEvents = Number(env('SENTRY_MAX_STORED_EVENTS'));
const sentryErrorDataIntegrationDepth = Number(env('SENTRY_DATA_INTEGRATION_DEPTH')) || 3;
// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
// It is recommended to adjust this value in production
const sentryTraceSampleRate = Number(env('SENTRY_TRACE_SAMPLE_RATE')) || 1.0;
// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
const sentryCaptureConsole = ['error', 'warn', 'debug'];
const showSentryDialog = env('SHOW_ERROR_DIALOG') === 'true';

function shouldIgnoreErrorCode(code: string): boolean {
  return [
    'csp-violation',
    'server-side-data-load-failed',
    'card_declined',
    'currency-input-invalid-currency',
    'auth-info-failed',
    'signup-failed',
    'fetch-current-user-failed'
    // 'browser-side-render-failed' // catched intl invariant errors,
  ].includes(code);
}
const sentry: SentryConfig = {
  enabled: sentryMode,
  dsn: sentryDsn,
  debug: sentryDebug,
  showDialog: showSentryDialog,
  shouldIgnoreErrorCode,
  tracesSampleRate: sentryTraceSampleRate,
  dataIntegrationDepth: sentryErrorDataIntegrationDepth,
  maxBreadcrumbs: sentryMaxBreadcrumbs,
  logLevel: sentryLogLevel,
  attachStacktrace: sentryAttachStacktrace,
  autoSessionTracking: sentryAutoSessionTracking,
  maxStoredEvents: sentryMaxStoredEvents,
  captureConsole: sentryCaptureConsole
};

const featureFlag: FeatureFlagConfig = {
  branding: {
    showLogo: env('FEATURE_BRANDING_SHOW_LOGO') === 'true',
    showTabTitle: env('FEATURE_BRANDING_UPDATE_TAB_TITLE') === 'true'
  },
  installer: {
    showEdit: env('FEATURE_EDIT_INSTALLER_ENABLED') === 'true'
  },
  uiMode: UI_MODE.LYRA
};

const config: Config = {
  api,
  designTool,
  baseImageryConfig,
  sentry,
  site,
  styling: {
    isWrappedWithExternalLyraTheme: env('STYLING_EXTERNAL_LYRA_THEME_USED') === 'true'
  },
  featureFlag,
  // The properties below this comment get overridden in `addConfigFromDesignToolComponentProps`
  host: {
    app: '',
    version: '',
    environment: '',
    updatePaymentUrl: undefined
  },
  user: {
    id: '',
    firstName: '',
    lastName: ''
  },
  analytics: undefined,
  forwardToPermitPackageDownload: false
};

export function addConfigFromDesignToolComponentProps(
  hostAppConfig: IHostAppConfig,
  userConfig: IUserConfig,
  analytics?: IAnalytics,
  forwardToPermitPackageDownload: boolean = false
): void {
  config.host = hostAppConfig;
  config.user = userConfig;
  config.forwardToPermitPackageDownload = forwardToPermitPackageDownload;
  if (analytics) {
    // eslint-disable-next-line no-console
    console.log('Design tool configured to use external analytics implementation');
    config.analytics = new DesignToolAnalytics(analytics);
  }
}

export default config;
