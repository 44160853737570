import {
  action, observable
} from 'mobx';
import { registryPanels } from './registry';
import type {
  BasePanelViewModel, IBasePanelViewModelDependencies
} from './ViewModels/BasePanelViewModel';

export class PanelsStore {
  @observable
  viewModel: BasePanelViewModel | undefined;

  @action.bound
  createPanel(panelName: string, dependencies: IBasePanelViewModelDependencies): void {
    this.viewModel = new registryPanels[panelName](dependencies);
  }

  @action.bound
  destroyPanel(): void {
    this.viewModel = undefined;
  }
}
