import isEmpty from 'lodash/isEmpty';
import type { IDesignStateData } from '../../models/Design/DesignState';
import type { IRoofTopArrayAreasData } from '../../models/RoofTopArray/RoofTopArrayAreas';
import type { IPvSystemData } from '../../models/PvSystem/PvSystem';
import type { IDesignSpecificationData } from '../../models/Design/DesignSpecification';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { IUpdateDesignDeltaDependencies } from '../../../stores/ServiceBus/Commands/UpdateDesignDeltaCommand';

export interface IDesignDeltaData {
  readonly state?: IDesignStateData;
  readonly designSpecification?: IDesignSpecificationData;
  readonly roofTopArrayAreas?: IRoofTopArrayAreasData;
  readonly system?: IPvSystemData;
}

/**
 * Delta between updated Design and its baseline.
 * Only top-level elements that contain changes are included.
 * Always includes full representation of these elements, even if only some small part of them changed.
 */
export class DesignDelta {
  state?: IDesignStateData;
  designSpecification?: IDesignSpecificationData;
  roofTopArrayAreas?: IRoofTopArrayAreasData;
  system?: IPvSystemData;

  /**
   * Constructor for creating a class instance from object of the same structure
   */
  constructor(data: IDesignDeltaData) {
    Object.assign(this, data);
  }

  isEmpty(): boolean {
    return isEmpty(this);
  }

  /**
   * Produces a dependency object for 'update_design_delta' command
   * @see UpdateDesignDeltaCommand
   */
  toApplyDesignDeltaCommand(domainStore: DomainStore): IUpdateDesignDeltaDependencies {
    return {
      domain: domainStore,
      designDelta: this
    };
  }
}
