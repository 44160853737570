import * as Building from './Building';
import * as Buildings from './Buildings';
import * as LeftIcon from './LeftIcon';
import * as RightButtons from './RightButtons';
import * as RoofFace from './RoofFace';
import * as RoofOutline from './RoofOutline';
import * as RoofProtrusion from './RoofProtrusion';
import * as RoofStory from './RoofStory';
import { Parcel } from './Parcel';

const SiteStructurePanel = {
  Building,
  Buildings,
  LeftIcon,
  RightButtons,
  RoofFace,
  RoofOutline,
  RoofProtrusion,
  RoofStory,
  Parcel
};

export { SiteStructurePanel };
