import React, { BaseProps, ReactElement } from 'react';
import { BuildingsContainer, PlusIcon } from '../styles';

export type BuildingsProps = BaseProps & {
  label?: string;
};

const Buildings = (props: BuildingsProps): ReactElement => {
  const { className = '', styles = {}, label = '' } = props;

  return (
    <BuildingsContainer className={className} style={styles}>
      {label}
    </BuildingsContainer>
  );
};

export { Buildings };
