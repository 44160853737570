import type Store from '../../../../../stores/Store';
import { ChangeOrientationTool } from '../../../../../stores/UiStore/ToolbarStore/Design/ChangeOrientationTool';
import type { IChangeOrientationToolDependencies } from '../../../../../stores/UiStore/ToolbarStore/Design/ChangeOrientationTool';
import withTool from '../../Shared/withTool';

type ChangeOrientationToolDependencies = Pick<
  IChangeOrientationToolDependencies,
  'properties' | 'domain' | 'serviceBus' | 'workspace'
>;

const ChangeOrientation = withTool<
  ChangeOrientationTool,
  IChangeOrientationToolDependencies,
  ChangeOrientationToolDependencies
>(
  ChangeOrientationTool,
  ({
    properties, domain, serviceBus, workspace
  }: Store): ChangeOrientationToolDependencies => ({
    properties,
    domain,
    serviceBus,
    workspace
  })
);

export default ChangeOrientation;
