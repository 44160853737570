import React from 'react';
import type { ReactElement } from 'react';
import { LyraFormElementsDesignTool } from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type { SlopeRoofFaceViewModel } from '../../../../../stores/UiStore/Properties/RoofProperties/ViewModels/SlopeRoofFaceViewModel';
import IconMapper from '../../../../../ui/components/Icons';
import { ROOF_FACE_SLOPE_FRACTION_DIGITS } from '../../../../../domain/models/Constants';
import { CustomSlopeWrapper } from './styles';

type Props = { viewModel: SlopeRoofFaceViewModel };

const WIDTH_INPUT = '30px';
const { NumberField } = LyraFormElementsDesignTool.FormElementsDesignTool.NumberField;

const CustomSlope = (props: Props): ReactElement => {
  const { viewModel } = props;
  const { customSlopeInputProps } = viewModel;

  return (
    <CustomSlopeWrapper>
      <NumberField
        withQuantityArrows
        className="mr-xxs"
        allowEmptyField={true}
        allowOutOfRange={false}
        onChange={viewModel.selectCustomSlope}
        value={Number(customSlopeInputProps.value.toFixed(ROOF_FACE_SLOPE_FRACTION_DIGITS))}
        measure={customSlopeInputProps.insideInputRightLabel}
        width={WIDTH_INPUT}
        limits={customSlopeInputProps.limits}
        fractionDigits={customSlopeInputProps.precision}
        step={1}
      />
      <div className="container-result">
        <div className="switch-icon" onClick={viewModel.toggleCustomSlopeInputMode}>
          <IconMapper name="switch-values" />
        </div>
        <div className="container-value">{viewModel.customSlopeInputProps.outsideInputRightLabel}</div>
      </div>
    </CustomSlopeWrapper>
  );
};

export default observer(CustomSlope);
