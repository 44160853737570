import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import BaseImageryProvider from '../../../domain/typings/BaseImageryProvider';
import { BaseCommand } from './Command';
import type { Site } from 'domain/models/SiteDesign/Site';

export interface IUpdateBaseImageryCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly newProvider: BaseImageryProvider;
  readonly clearCustomImageryData: boolean;
}

export interface IUpdateCustomBaseImageryCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly newProvider: BaseImageryProvider.CUSTOM;
  readonly customBaseImagery: Site['imagery']['CUSTOM'];
}

type UpdateCustomBaseImageryCommandDependencies =
  | IUpdateCustomBaseImageryCommandDependencies
  | IUpdateBaseImageryCommandDependencies;

export class UpdateBaseImageryCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly newProvider: BaseImageryProvider;
  private readonly customBaseImagery?: Site['imagery']['CUSTOM'];
  private readonly updateCustomImageryField: boolean = false;

  constructor(dependencies: UpdateCustomBaseImageryCommandDependencies) {
    super();
    const {
      domain, newProvider
    } = dependencies;
    this.domain = domain;
    this.newProvider = newProvider as BaseImageryProvider;
    // if clearCustomImageryData flag is passed, CUSTOM field is cleared without regard to the provider
    const clearCustomImageryData = (dependencies as IUpdateBaseImageryCommandDependencies).clearCustomImageryData;
    this.updateCustomImageryField = newProvider === BaseImageryProvider.CUSTOM || clearCustomImageryData;
    if (this.updateCustomImageryField) {
      // If CUSTOM provider was passed, but no customBaseImagery, we're reusing the existing data
      this.customBaseImagery = clearCustomImageryData
        ? undefined
        : (dependencies as IUpdateCustomBaseImageryCommandDependencies).customBaseImagery
          || this.domain.project.site.imagery.CUSTOM;
    }
  }

  async execute(): Promise<void> {
    this.domain.project.site.imagery.provider = this.newProvider;
    if (this.updateCustomImageryField) {
      this.domain.project.site.imagery.CUSTOM = this.customBaseImagery!;
    }
  }
}
