import type {
  BaseProps, ReactElement
} from 'react';
import React, {
  useCallback, useState
} from 'react';
import { LyraTooltip } from '@aurorasolar/lyra-ui-kit';
import Icon from '../Icons';
import {
  EditContent, EditVeil, Card, Wrapper
} from './styles';

type Props = BaseProps & {
  onClick?: () => void;
  onHover?: () => void;
  cardContent?: string | ReactElement;
  tooltipContent?: string | ReactElement;
  editButtonComplete?: boolean;
};

/**
 * InFoCard
 *
 * This component display a tooltip over itself when is hover
 */
function EnvironmentalInfoCard({
  onHover,
  onClick,
  cardContent = '',
  tooltipContent = '',
  editButtonComplete = true
}: Props): ReactElement {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const handlerHoverEvent = useCallback((): void => onHover?.(), [onHover]);

  return (
    <Wrapper>
      <Card
        onMouseOver={(): void => setTooltipVisible(true)}
        onMouseOut={(): void => setTooltipVisible(false)}
        onMouseEnter={handlerHoverEvent}
        onMouseLeave={handlerHoverEvent}
      >
        {cardContent}
        {editButtonComplete && (
          <EditVeil onClick={(): void => onClick && onClick()}>
            <EditContent>
              <Icon name="pencil" />
              {editButtonComplete && <span className="pl-xxxs">EDIT</span>}
            </EditContent>
          </EditVeil>
        )}
      </Card>
      {editButtonComplete && (
        <LyraTooltip.Tooltip tooltipVisibility={tooltipVisible}>{tooltipContent}</LyraTooltip.Tooltip>
      )}
    </Wrapper>
  );
}

export default EnvironmentalInfoCard;
