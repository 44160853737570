import type { Object3D } from 'three';
import {
  TextureLoader, BoxGeometry, MeshBasicMaterial, Mesh, Vector3
} from 'three';
import type EditorStore from '../../stores/EditorStore/EditorStore';
import type SmartGuidesStore from '../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import {
  clockwiseRotationInXYBetweenTwoVector3, closestPointBetween2D, pointInsidePolygon
} from '../../utils/spatial';
import iconBase64 from '../../canvas-assets/base64-encoded-assets/rotateIcon.base64.json';
import { Draggable } from '../mixins/Draggable';
import { Drawable } from '../mixins/Drawable';
import { Selectable } from '../mixins/Selectable';
import { Unzoomable } from '../mixins/Unzoomable';
import { getLyraModelByMesh } from '../sceneObjectsWithLyraModelsHelpers';
import { SceneObjectType } from './Constants';
import type { CustomBaseImageryMesh } from './CustomBaseImageryMesh';

const MixedClass = Draggable(Selectable(Unzoomable(Drawable(class SimpleClass {}))));

// static configuration
const performanceLogging = false;
const performanceLog = (...strs: string[]): void => {
  if (performanceLogging) {
    // eslint-disable-next-line no-console
    console.log(...strs);
  }
};

export class CustomBaseImageryTransformationHandleMesh extends MixedClass {
  override isDrawable: boolean = true;
  override isDraggable: boolean = true;

  private readonly imageWidth: number;
  private readonly imageHeight: number;
  private readonly getAbsoluteImageCornerPositions: () => Vector3[];
  private readonly getThisCorner: () => Vector3;
  private readonly side: number;
  private readonly clickAreaZValue: number = 15;

  private initialRotationAngleOnDrag = 0;
  private initialScalingFactorOnDrag: number = 1;
  private unzoomFactor: number = 1;
  private svgWidth = 48;
  private svgHeight = 48;
  // Static configuration
  private scaleFactorClickArea = 1.5;
  private sideToIconRotation: {
    [key: number]: number;
  } = {
      0: Math.PI / 2,
      1: Math.PI,
      2: 0,
      3: Math.PI + Math.PI / 2
    };

  // Useful for debugging
  private sideToColor: {
    [key: number]: number;
  } = {
    // Colours handy for debugging, change opacity to enable
      0: 0xff0000,
      1: 0x00ff00,
      2: 0x0000ff,
      3: 0xffffff
    };

  clickArea!: Mesh;
  selectWithParent: boolean = false;
  propertyId: string = SceneObjectType.CustomBaseImageryHandle;
  initializationPromise: Promise<void>;
  isMultipleVertices: boolean = false;
  groupScaleFactor: number = 1;

  constructor(side: number, imageWidth: number, imageHeight: number, getAbsoluteImageCornerPositions: () => Vector3[]) {
    super();
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;

    this.initializationPromise = this.initIcon(side);
    this.side = side;
    this.getAbsoluteImageCornerPositions = getAbsoluteImageCornerPositions;
    this.getThisCorner = () => {
      const [A, B, C, D] = this.getAbsoluteImageCornerPositions();
      return [B, C, D, A][side];
    };
  }

  resetVertex(vector: Vector3, index: number, editor: EditorStore): void {
    //
  }

  async initIcon(side: number): Promise<void> {
    performanceLog('initSvg');
    const geometry = new BoxGeometry(
      this.svgWidth * this.scaleFactorClickArea,
      this.svgHeight * this.scaleFactorClickArea,
      1
    );
    const material = new MeshBasicMaterial({
      // Comment `map:` and uncomment following 2 lines to see the color of the click area to help debugging
      // color: this.sideToColor[side],
      // opacity: 0.5,
      map: new TextureLoader().load(iconBase64),
      transparent: true
    });
    const clickArea = new Mesh(geometry, material);
    clickArea.position.set(
      side < 2 ? this.imageWidth / 2 : -this.imageWidth / 2,
      (side + 1) % 2 === 0 ? this.imageHeight / 2 : -this.imageHeight / 2,
      this.clickAreaZValue
    );
    clickArea.rotation.z = this.sideToIconRotation[side];

    // This is needed for the click area to be draggable
    // @ts-ignore
    clickArea.isDrawable = true;

    this.mesh.add(clickArea);

    clickArea.userData.draggableParent = this.mesh;

    this.clickArea = clickArea;
  }

  override unzoom(factor: number): void {
    performanceLog('unzoom');
    this.unzoomFactor = factor * this.mapZoomFactor;
    this.clickArea?.scale.set(this.unzoomFactor / this.groupScaleFactor, this.unzoomFactor / this.groupScaleFactor, 1);
  }

  override select(): void {
    //
  }

  override unselect(): void {
    //
  }

  redraw(): void {
    //
  }

  getVector3s(): Vector3[] {
    return [new Vector3(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z)];
  }

  getCenter(): Vector3 {
    const imageryMesh = this.mesh.userData.groupReference.userData.imageryMesh as CustomBaseImageryMesh;
    return this.mesh.userData.groupReference.localToWorld(imageryMesh.mesh.position.clone());
  }

  override onDragFinished = (): void => {
    this.mesh.userData.groupReference.userData.positionHandles();
  };

  /**
   * This function is responsible for moving the handle closer to the center of
   * the image when the image is larger than the screen.
   * @param windowBoundaries
   * @param cornerVertexInAbsolutPosition an image's corner absolute position
   * @param neighbouringVertices vertices adjacent to the corner, with the cornerVertexInAbsolutPosition in the middle
   * @param corners: [A, B, C, D] all corners' in absolution position
   * @param xOverflow how much the image overflows from the screen edge + threshold on the x axis
   * @param yOverflow how much the image overflows from the screen edge + threshold on the y axis
   * @param rotation current group (image & controls) rotation
   * @param threshold threshold to add to screen edge to consider the image overflowing
   */
  moveCloserToCenter({
    windowBoundaries,
    cornerVertexInAbsolutPosition,
    neighbouringVertices,
    corners: [A, B, C, D],
    overflows: [xOverflow, yOverflow],
    rotation,
    threshold
  }: {
    windowBoundaries: Vector3[];
    cornerVertexInAbsolutPosition: Vector3;
    neighbouringVertices: Vector3[];
    corners: Vector3[];
    overflows: number[];
    rotation: number;
    threshold: number;
  }): void {
    performanceLog('moveCloserToCenter');
    const [topRight, bottomRight, bottomLeft, topLeft] = windowBoundaries;
    const boundaries = [
      [
        topLeft.clone().add(new Vector3(threshold, -threshold, 0)),
        bottomLeft.clone().add(new Vector3(threshold, threshold, 0))
      ],
      [
        topLeft.clone().add(new Vector3(threshold, -threshold, 0)),
        topRight.clone().add(new Vector3(-threshold, -threshold, 0))
      ],
      [
        topRight.clone().add(new Vector3(-threshold, -threshold, 0)),
        bottomRight.clone().add(new Vector3(-threshold, threshold, 0))
      ],
      [
        bottomRight.clone().add(new Vector3(-threshold, threshold, 0)),
        bottomLeft.clone().add(new Vector3(threshold, threshold, 0))
      ]
    ];

    const diffBetweenPositionsInLocalCoords = (initialVector: Vector3, newPosition: Vector3): Vector3 => {
      const diff = initialVector
        .clone()
        .sub(newPosition)
        .multiplyScalar(1 / this.mesh.userData.groupReference.scale.x);
      diff.applyAxisAngle(new Vector3(0, 0, 1), -rotation);
      diff.z = 0;
      return diff;
    };

    /*
     * Find the closest corner.
     * Use two boundaries that are connected to that corner.
     * Check that the control is inside the polygon, if not - place it on edge.
     * */
    const realHalfWidth = this.imageWidth / 2;
    const realHalfHeight = this.imageHeight / 2;
    const cornerPositionX = this.side < 2 ? realHalfWidth : -realHalfWidth;
    const cornerPositionY = (this.side + 1) % 2 === 0 ? realHalfHeight : -realHalfHeight;

    this.clickArea.position.set(cornerPositionX, cornerPositionY, this.clickAreaZValue);

    // Overflow is less than threshold, no need to move the control inside the image
    if (xOverflow < -threshold && yOverflow < -threshold) {
      return;
    }

    let shortestDistance = Infinity;
    let bestPointToLimitBy: Vector3 = new Vector3(0, 0, 0);
    boundaries.forEach(([A, B]: Vector3[]): void => {
      const closestPoint = closestPointBetween2D(cornerVertexInAbsolutPosition, A, B);
      const closestPointInLocalCoords = diffBetweenPositionsInLocalCoords(cornerVertexInAbsolutPosition, closestPoint);
      if (closestPoint.distanceTo(cornerVertexInAbsolutPosition) < shortestDistance) {
        shortestDistance = closestPoint.distanceTo(cornerVertexInAbsolutPosition);
        bestPointToLimitBy = closestPointInLocalCoords;
      }
    });

    if (bestPointToLimitBy) {
      this.clickArea.position.set(
        cornerPositionX - bestPointToLimitBy.x,
        cornerPositionY - bestPointToLimitBy.y,
        this.clickAreaZValue
      );

      const absoluteNewPosition = this.mesh.userData.groupReference.localToWorld(this.clickArea.position.clone());
      // If the control ended up being outside of an image, we're choosing the closest
      // point lying on adjacent edges to the corner.
      if (!pointInsidePolygon([A, B, C, D], absoluteNewPosition)) {
        const closestPointOnPreviousAndCurrent = closestPointBetween2D(
          absoluteNewPosition,
          neighbouringVertices[1],
          neighbouringVertices[2]
        );
        const closestPointOnCurrentAndNext = closestPointBetween2D(
          absoluteNewPosition,
          neighbouringVertices[0],
          neighbouringVertices[1]
        );
        const distanceToPreviousAndCurrent = absoluteNewPosition.distanceTo(closestPointOnPreviousAndCurrent);
        const distanceToCurrentAndNext = absoluteNewPosition.distanceTo(closestPointOnCurrentAndNext);
        if (distanceToPreviousAndCurrent < distanceToCurrentAndNext) {
          this.clickArea.position.sub(
            diffBetweenPositionsInLocalCoords(absoluteNewPosition, closestPointOnPreviousAndCurrent)
          );
        } else {
          this.clickArea.position.sub(
            diffBetweenPositionsInLocalCoords(absoluteNewPosition, closestPointOnCurrentAndNext)
          );
        }
      }
    }
  }

  override onDragStarted = (mousePosition: Vector3): void => {
    if (!this.mesh.userData.groupReference) {
      return;
    }

    this.initialRotationAngleOnDrag = this.mesh.userData.groupReference.rotation.z;

    const center = this.getCenter();
    this.initialScalingFactorOnDrag = center.distanceTo(mousePosition) / center.distanceTo(this.getThisCorner());
  };

  override move(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): void {
    if (!this.mesh.userData.groupReference) {
      return;
    }
    performanceLog('move');

    const center = this.getCenter();

    // Scaling

    const distanceToCenter = Math.max(center.distanceTo(newPositions[0]), 10);

    const factor =
      (2 * distanceToCenter)
      / Math.sqrt(
        Math.pow(this.imageWidth * this.initialScalingFactorOnDrag, 2)
          + Math.pow(this.imageHeight * this.initialScalingFactorOnDrag, 2)
      );
    this.mesh.userData.groupReference.scale.set(factor, factor, 1);
    this.mesh.userData.groupReference.children.forEach((child: Object3D): void => {
      if (
        (child as Mesh).isMesh
        && getLyraModelByMesh<CustomBaseImageryTransformationHandleMesh>(child).propertyId
          === SceneObjectType.CustomBaseImageryHandle
      ) {
        const handle = getLyraModelByMesh<CustomBaseImageryTransformationHandleMesh>(child);
        handle.groupScaleFactor = factor;
        handle.clickArea.scale.set(handle.unzoomFactor / factor, handle.unzoomFactor / factor, 1);
      }
    });

    // Rotation:

    const currentRotationVector = newPositions[0].clone().sub(center);
    const initialRotationVector = this.startDragPosition!.clone().sub(center);

    this.mesh.userData.groupReference.rotation.z =
      this.initialRotationAngleOnDrag
      - clockwiseRotationInXYBetweenTwoVector3(currentRotationVector, initialRotationVector);
  }

  override afterMove(newPositions: Vector3[], editor: EditorStore, smartGuides: SmartGuidesStore): boolean {
    return true;
  }
}
