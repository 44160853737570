import {
  MathUtils as ThreeMath, Vector3
} from 'three';
import type { IDrawableStructureFactory } from '../../../../../domain/models/StructureFactory';
import { Marker } from '../../../../../domain/models/SiteDesign/Marker';
import type DomainStore from '../../../../DomainStore/DomainStore';
import { SelectionControl } from '../../../../EditorStore/Controls/SelectionControl';
import type { PropertiesStore } from '../../../Properties/Properties';
import type { IAddSiteEquipmentDependencies } from '../../../../ServiceBus/Commands/AddSiteEquipmentCommand';
import type {
  IPointerDownControlEvent,
  IPointerLeftControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../../../EditorStore/Controls/ControlEvents';
import type SmartGuidesStore from '../../../SmartGuidesStore/SmartGuidesStore';
import type { IWorkspace } from '../../../WorkspaceStore/types';
import { BaseSuperTool } from '../../SuperTool';
import type {
  IBaseToolDependencies, IHandleClicksTool, IHandleMoveTool
} from '../../Tool';
import {
  GM_TOOL_ID,
  MSP_TOOL_ID,
  SITE_EQUIPMENT_TOOL_ID,
  STREET_LOCATION_ID,
  SUBPANEL_TOOL_ID,
  UTILITY_METER_TOOL_ID
} from '../constants';
import { SceneObjectType } from '../../../../../domain/models/Constants';
import { StreetLocation } from '../../../../../domain/models/SiteDesign/StreetLocation';
import { SiteMarkerAddedEvent } from '../../../../../services/analytics/DesignToolAnalyticsEvents';
import config from '../../../../../config/config';

export interface IBaseSiteEquipmentToolDependencies extends IBaseToolDependencies {
  readonly domain: DomainStore;
  readonly properties: PropertiesStore;
  readonly drawableObjectsFactory: IDrawableStructureFactory;
  readonly smartGuides: SmartGuidesStore;
  readonly currentWorkspace: IWorkspace;
}

export abstract class BaseSiteEquipmentTool extends BaseSuperTool implements IHandleClicksTool, IHandleMoveTool {
  static readonly toolWhitelist: string[] = [
    SITE_EQUIPMENT_TOOL_ID,
    MSP_TOOL_ID,
    SUBPANEL_TOOL_ID,
    STREET_LOCATION_ID,
    GM_TOOL_ID,
    UTILITY_METER_TOOL_ID
  ];

  override readonly id: string = ThreeMath.generateUUID();
  override readonly showSubmenu: boolean = false;

  protected readonly properties: PropertiesStore;
  protected readonly domain: DomainStore;
  protected readonly currentWorkspace: IWorkspace;
  protected readonly drawableObjectsFactory: IDrawableStructureFactory;
  protected selectionControl?: SelectionControl;
  private readonly smartGuides: SmartGuidesStore;

  constructor(dependencies: IBaseSiteEquipmentToolDependencies) {
    super(dependencies);
    this.properties = dependencies.properties;
    this.domain = dependencies.domain;
    this.currentWorkspace = dependencies.currentWorkspace;
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
    this.smartGuides = dependencies.smartGuides;
  }

  createEquipment?: () => Marker;
  abstract getEquipment(): Marker[];

  createAndInitializeEquipment = (): Marker => {
    const equipment = this.createEquipment!();
    equipment.realWorldZValue =
      equipment.type === SceneObjectType.Street
        ? StreetLocation.defaultEquipmentZValueInWorldUnits()
        : Marker.defaultEquipmentZValueInWorldUnits();
    return equipment;
  };

  override whenSelected(): void {
    this.selectionControl = SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera);
    if (!this.getEquipment().length) {
      this.whenSelectedAndNoMarkerExists();
      return;
    }
    for (const equipment of this.getEquipment()) {
      this.whenSelectedAndMarkerAlreadyExists(equipment);
    }
  }

  protected whenSelectedAndMarkerAlreadyExists(equipment: Marker): void {
    this.editor.addOrUpdateObject(equipment.mesh);
    equipment.setSelectedMode(true);
    this.properties.setPropertyPanel(equipment.type);
  }

  private whenSelectedAndNoMarkerExists(): void {
    this.createSiteEquipment();
    this.addMouseEventListeners();
  }

  protected addMouseEventListeners(): void {
    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);
  }

  protected removeMouseEventListeners(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
  }

  private createSiteEquipment(): void {
    const equipment = this.createAndInitializeEquipment();
    if (equipment && !equipment.hasChildren) {
      this.properties.setPropertyPanel(`hint_${equipment?.type}`);
      const renderHeight = this.editor.getObjectRenderHeight(equipment.type as SceneObjectType);
      const newPosition = new Vector3(undefined, undefined, renderHeight);
      equipment.draw(newPosition);
      const commandDependencies: IAddSiteEquipmentDependencies = {
        editor: this.editor,
        domain: this.domain,
        equipment,
      };
      this.serviceBus.send('add_site_equipment', commandDependencies);
      equipment.onDragStart(newPosition);
      equipment.mesh.visible = false;
    }
  }

  override whenDeselected(): void {
    this.removeMouseEventListeners();
    this.deleteMarkerIfNotPlaced();
  }

  private deleteMarkerIfNotPlaced(): void {
    for (const equipment of this.getEquipment()) {
      if (equipment.isDragging) {
        this.domain.deleteGenericSiteEquipment(equipment);
        this.editor.removeObject((equipment as Marker).mesh ?? equipment);
      }
    }
  }

  override dispose(): void {
    // do nothing
  }

  override onMouseUp = async (event: IPointerUpControlEvent): Promise<void> => {
    // Note: we call completeMarkerMove first, because activating selection
    // tools triggers deselect of this tool which in turn deletes any in-flight (i.e. not placed) markers
    for (const equipment of this.getEquipment()) {
      if (equipment.isDragging) {
        this.completeMarkerMove(equipment);
      }
      equipment.positionShift = 0;
    }

    await this.toolbar.activateSelectionToolInProjectWorkspace();
    this.selectionControl!.setSelectedObjects(this.getEquipment());
  };

  private completeMarkerMove(equipment: Marker): void {
    equipment.onDragFinish(this.editor, this.smartGuides);
    config.analytics?.trackEvent(new SiteMarkerAddedEvent(this.domain, equipment.label));
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    // do nothing
  };

  override onMouseMove = (event: IPointerMoveControlEvent): void => {
    for (const equipment of this.getEquipment()) {
      if (equipment.isDragging && event.target && event.pointerPosition) {
        equipment.mesh.visible = true;
        const worldPosition = event.target.unprojectMouseToFrustum(event.pointerPosition);
        equipment.performMove(worldPosition, this.editor, this.smartGuides, [], true);
      }
    }
  };

  override onMouseLeave = (event: IPointerLeftControlEvent): void => {
    // do nothing
  };
}
