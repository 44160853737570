export enum ServiceEntranceEquipmentDevice {
  LoadCenter = 'LoadCenter',
  UtilityMeter = 'UtilityMeter',
  MainBreakerInEnclosure = 'MainBreakerInEnclosure'
}

/**
 * @description Service Entrance Equipment is site equipment that receives
 * power from the utility and distributes it to the site. Usually, it is a
 * device or a set of devices (see {@link ServiceEntranceEquipmentDevice})
 * including a utility meter and a load center (which usually
 * consists of main breaker, busbar, etc.)
 */
export enum ServiceEntranceEquipmentType {
  /**
   * Utility meter and load center are in one enclosure
   */
  MeterMainLoadCenter = 'MeterMainLoadCenter',
  /**
   * Utility meter is in a separate device user can move on the map
   * independently of the load center.
   */
  MeterBaseAndMainBreakerLoadCenter = 'MeterBaseAndMainBreakerLoadCenter',
  /**
   * Utility meter and main breaker in the same enclosure, load center in a separate one
   */
  MeterMainAndMainLugLoadCenter = 'MeterMainAndMainLugLoadCenter',
  /**
   * Utility meter and one main breaker in the enclosure, load center and another main breaker in another enclosure
   */
  MeterMainAndMainBreakerLoadCenter = 'MeterMainAndMainBreakerLoadCenter',
  /**
   * Three separate enclosures — utility meter, main breaker and main lug panelboard
   */
  MeterBase_MainBreaker_MainLugLoadCenter = 'MeterBase_MainBreaker_MainLugLoadCenter',
  /**
   * Three separate enclosures — utility meter, main breaker and main breaker panelboard
   */
  MeterBase_MainBreaker_MainBreakerLoadCenter = 'MeterBase_MainBreaker_MainBreakerLoadCenter'
}

export enum LoadCenterName {
  MainBreakerLoadCenter = 'Main Breaker Load Center',
  MeterMainLoadCenter = 'Meter-Main-Load Center',
  MainLugLoadCenter = 'Main Lug Load Center'
}

enum LoadCenterLabel {
  MainBreakerLoadCenter = 'MBLC',
  MeterMainLoadCenter = 'MMLC',
  MainLugLoadCenter = 'MLLC'
}

const loadCenterNamesAndLabels = {
  [ServiceEntranceEquipmentType.MeterBaseAndMainBreakerLoadCenter]: {
    name: LoadCenterName.MainBreakerLoadCenter,
    label: LoadCenterLabel.MainBreakerLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterMainLoadCenter]: {
    name: LoadCenterName.MeterMainLoadCenter,
    label: LoadCenterLabel.MeterMainLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter]: {
    name: LoadCenterName.MainLugLoadCenter,
    label: LoadCenterLabel.MainLugLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainLugLoadCenter]: {
    name: LoadCenterName.MainLugLoadCenter,
    label: LoadCenterLabel.MainLugLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterBase_MainBreaker_MainBreakerLoadCenter]: {
    name: LoadCenterName.MainBreakerLoadCenter,
    label: LoadCenterLabel.MainBreakerLoadCenter
  },
  [ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter]: {
    name: LoadCenterName.MainBreakerLoadCenter,
    label: LoadCenterLabel.MainBreakerLoadCenter
  }
};

export function getServiceEntranceAndSiteEquipmentProperties(
  device: ServiceEntranceEquipmentDevice,
  serviceEntranceEquipmentType: ServiceEntranceEquipmentType
): {
  label: string;
  name: string;
} {
  switch (device) {
    case ServiceEntranceEquipmentDevice.LoadCenter: {
      return (
        loadCenterNamesAndLabels[serviceEntranceEquipmentType] ?? {
          label: 'LC',
          name: 'Load Center'
        }
      );
    }
    case ServiceEntranceEquipmentDevice.UtilityMeter: {
      switch (serviceEntranceEquipmentType) {
        case ServiceEntranceEquipmentType.MeterMainAndMainLugLoadCenter:
        case ServiceEntranceEquipmentType.MeterMainAndMainBreakerLoadCenter: {
          return {
            name: 'Meter-Main',
            label: 'MM'
          };
        }
        default: {
          return {
            name: 'Utility Meter',
            label: 'UM'
          };
        }
      }
    }
    case ServiceEntranceEquipmentDevice.MainBreakerInEnclosure: {
      return {
        name: 'Main Breaker in Enclosure',
        label: 'BRKR'
      };
    }
    // no default
  }
}

/**
 * @description Service entrance equipment field names in domain (`project.site.equipment`)
 */
export enum SiteEquipmentItemKeyName {
  mainServicePanel = 'mainServicePanel',
  utilityMeter = 'utilityMeter',
  subpanel = 'subpanel',
  gasMeter = 'gasMeter',
  streetLocation = 'streetLocation',
  meterMain = 'meterMain',
  mainBreakerInEnclosure = 'mainBreakerInEnclosure'
}

export const DEFAULT_MAIN_BREAKER_RATING = 200;
