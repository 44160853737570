import {
  LyraLayout, LyraModal, LyraTabs
} from '@aurorasolar/lyra-ui-kit';
import type { TabOption } from '@aurorasolar/lyra-ui-kit/lib/components/Tabs';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useState } from 'react';

import { EProjectPropertyOption } from '../../../../domain/typings';
import useStore from '../../../../stores/useStore';
import type { ProjectPropertiesViewModel } from '../../../../stores/UiStore/Modal/ViewModels/ProjectPropertiesModal/ProjectPropertiesViewModel';
import { ProjectPropertiesForm } from './ProjectPropertiesForm';
import SitePropertyForm from './SitePropertyForm';

const tabOptions = (): TabOption[] => {
  return [
    {
      label: <b>Project</b>,
      value: EProjectPropertyOption.PROJECT
    },
    {
      label: <b>Site</b>,
      value: EProjectPropertyOption.SITE
    }
  ];
};

const ProjectPropertiesModal: React.FC = (): ReactElement => {
  const { modal } = useStore();
  const [projectPropertiesViewModel] = useState(modal.viewModel as ProjectPropertiesViewModel);

  const {
    projectPropertyTabSelected, isValid
  } = projectPropertiesViewModel;

  const modalProps = {
    $show: true,
    showCloseButton: true,
    rightButtonDisabled: !isValid,
    title: 'Properties',
    color: EThemeComponentColor.PURPLE,
    onClickLeftButton: (): void => projectPropertiesViewModel.closeModal(),
    onClickCloseButton: (): void => projectPropertiesViewModel.closeModal(),
    onClickRightButton: (): void => {
      projectPropertiesViewModel.saveProjectProperties();
    }
  };

  const tabsProps = {
    options: tabOptions(),
    value: projectPropertyTabSelected,
    onClick: projectPropertiesViewModel.handleClickTab
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <LyraLayout.Container
        style={{
          width: 900,
          minHeight: 485
        }}
      >
        <LyraLayout.Flexbox justify="center">
          <LyraLayout.Col md={4}>
            <LyraTabs.Tabs
              {...tabsProps}
              layoutClassName="ph-xs pv-xxs"
              activeThemeColor={EThemeComponentColor.PURPLE}
              inactiveThemeColor={EThemeComponentColor.TRANSPARENT}
            />
          </LyraLayout.Col>
        </LyraLayout.Flexbox>

        {projectPropertyTabSelected === EProjectPropertyOption.PROJECT ? (
          <ProjectPropertiesForm />
        ) : (
          <SitePropertyForm />
        )}
      </LyraLayout.Container>
    </LyraModal.Modal>
  );
};

export default observer(ProjectPropertiesModal);
