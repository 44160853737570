import { Registry } from '../../utils';

const mappingIcons = {
  'arrow': require('./assets/icon-arrow.svg').default,
  'arrow-collapse': require('./assets/arrow-collapse.svg').default,
  'arrow-down': require('./assets/arrow-down.svg').default,
  'arrow-right': require('./assets/arrow-right.svg').default,
  'aurora-download': require('./assets/aurora-download.svg').default,
  'aurora-download-chevron-up': require('./assets/aurora-download-chevron-up.svg').default,
  'aurora-pdf': require('./assets/aurora-pdf-icon.svg').default,
  'aurora-xls': require('./assets/aurora-xls-icon.svg').default,
  'bold-arrow': require('./assets/icon-bold-arrow.svg').default,
  'circle': require('./assets/circle-protrusion-tool.svg').default,
  'check': require('./assets/check.svg').default,
  'check-white': require('./assets/check-white.svg').default,
  'close-icon': require('./assets/close-icon.svg').default,
  'condition-roof': require('./assets/condition-of-roof-icon.svg').default,
  'define-azimuth': require('./assets/define-azimuth-icon.svg').default,
  'define-slope': require('./assets/define-slope-icon.svg').default,
  'ground-snow': require('./assets/ground-snow-load-icon.svg').default,
  'handler': require('./assets/handler.svg').default,
  'hide-icon': require('./assets/hide-icon.svg').default,
  'high-temperature': require('./assets/high-temperature-icon.svg').default,
  'horizontal-protrusion': require('./assets/horizontal-protrusion.svg').default,
  'import-3D': require('./assets/subtool-import-3-d.svg').default,
  'increase-decrease-arrow':
  require('./assets/increase-decrease-arrow-icon.svg').default,
  'info': require('./assets/icon-info.svg').default,
  'tooltip': require('./assets/icon-info-light.svg').default,
  'Logo': require('./assets/Lyra-logo.svg').default,
  'low-temperature': require('./assets/low-temperature-icon.svg').default,
  'map-source': require('./assets/icon-map-source.svg').default,
  'other': require('./assets/other-icon.svg').default,
  'pan': require('./assets/icon-pan.svg').default,
  'pannels': require('./assets/icon-pannels.svg').default,
  'parallel-protrusion': require('./assets/parallel-protrusion.svg').default,
  'pencil': require('./assets/icon-pencil.svg').default,
  'protrusions': require('./assets/icon-protrusions.svg').default,
  'protrusion-height': require('./assets/protrusion-height.svg').default,
  'rect-tool': require('./assets/icon-rectification-tool.svg').default,
  'undo': require('./assets/undo-icon.svg').default,
  'rectangle': require('./assets/rectangle-protrusion-tool.svg').default,
  'redo': require('./assets/redo-icon.svg').default,
  'showHideElements': require('./assets/showHideElements-icon.svg').default,
  'roof-decking': require('./assets/roof-decking-icon.svg').default,
  'roof-framing': require('./assets/roof-framing-icon.svg').default,
  'roof-predictions': require('./assets/subtool-roof-predictions.svg').default,
  'roof-surface': require('./assets/roof-surface-icon.svg').default,
  'roof-type-flat': require('./assets/roof-type-flat.svg').default,
  'roof-type-sloped': require('./assets/roof-type-sloped.svg').default,
  'roof-type-flat-purple': require('./assets/roof-type-flat-purple.svg').default,
  'roof-type-sloped-purple': require('./assets/roof-type-sloped-purple.svg').default,
  'selection': require('./assets/icon-selection-tool.svg').default,
  'site-equipment': require('./assets/icon-site-equipment.svg').default,
  'site-features': require('./assets/icon-site-features.svg').default,
  'site-images': require('./assets/icon-site-images.svg').default,
  'smartguide-extension-lines':
    require('./assets/smartguide-extension-lines.svg').default,
  'smartguide-live-angles':
    require('./assets/smartguide-live-angles.svg').default,
  'smartguide-midpoints-lines':
    require('./assets/smartguide-midpoints-lines.svg').default,
  'smartguide-parallel-lines':
    require('./assets/smartguide-parallel-lines.svg').default,
  'smartguide-perpendicular-alignment':
    require('./assets/smartguide-perpendicular-alignment.svg').default,
  'smartguide-snap': require('./assets/smartguide-snap.svg').default,
  'smartguide-square': require('./assets/smart-square.svg').default,
  'smartguides': require('./assets/smartguides.svg').default,
  'solar-access': require('./assets/solar-access-icon.svg').default,
  'split-roof': require('./assets/subtool-split-roof.svg').default,
  'street-view': require('./assets/street-view.svg').default,
  'switch-values': require('./assets/icon-switch-values.svg').default,
  'tool': require('./assets/tool-icon.svg').default,
  'tool-import-3d': require('./assets/import-3-d-model-tool.svg').default,
  'tool-properties': require('./assets/tool-properties-icon.svg').default,
  'trace-outlines': require('./assets/subtool-trace-outlines.svg').default,
  'trace-roof': require('./assets/subtool-trace-roof.svg').default,
  'tracing-tool': require('./assets/icon-tracing-tool.svg').default,
  'wind-exposure': require('./assets/wind-exposure-cat-icon.svg').default,
  'wind-speed': require('./assets/wind-speed-icon.svg').default,
  'yellow-arrow': require('./assets/yellow-arrow.svg').default,
  'drag-edge': require('./assets/hint-drag-edge.svg').default,
  'move': require('./assets/hint-move.svg').default,
  'rotate': require('./assets/hint-rotate.svg').default,
  'scale': require('./assets/hint-scale.svg').default,
  'pull-edge': require('./assets/hnt-pull-edge.svg').default,
  'node-blue': require('./assets/node-blue.svg').default,
  'aligned-rows': require('./assets/icon-aligned-rows.svg').default,
  'maximized-modules': require('./assets/icon-maximized-modules.svg').default,
  'staggered-rows': require('./assets/icon-staggered-rows.svg').default,
  'strategy-portrait': require('./assets/strategy-portrait.svg').default,
  'strategy-landscape': require('./assets/strategy-landscape.svg').default,
  'alert-icon': require('./assets/alert-icon.svg').default,
  'icon-tick': require('./assets/icon-tick.svg').default,
  'icon-download': require('./assets/icon-download.svg').default,
  'icon-download-default': require('./assets/icon-download-default.svg').default,
  'icon-favorite-selected': require('./assets/icon-favorite-selected.svg').default,
  'icon-favorite': require('./assets/icon-favorite.svg').default,
  'icon-pp-purchased': require('./assets/icon-pp-purchased.svg').default,
  'icon-pp-purchased-purple': require('./assets/icon-pp-purchased-purple.svg').default,
  'icon-pp-purchased-disabled': require('./assets/icon-pp-purchased-disabled.svg').default,
  'icon-dashboard': require('./assets/icon-dashboard.svg').default,
  'icon-user': require('./assets/icon-user.svg').default,
  'icon-account': require('./assets/icon-account.svg').default,
  'icon-dashboard-white': require('./assets/icon-dashboard-white.svg').default,
  'icon-user-white': require('./assets/icon-user-white.svg').default,
  'icon-account-white': require('./assets/icon-account-white.svg').default,
  'icon-available-documents': require('./assets/icon-available-documents.svg').default,
  'pattern': require('./assets/BackgroundPattern.svg').default,
  'logo-white': require('./assets/Lyra-logo-white.svg').default,
  'icon-settings': require('./assets/icon-settings.svg').default,
  'icon-logout': require('./assets/icon-logout.svg').default,
  'three-dots': require('./assets/three-dots.svg').default,
  'icon-notification': require('./assets/icon-notifications.svg').default,
  'sales': require('./assets/sales.svg').default,
  'sun-line': require('./assets/sun-line.svg').default,
  'doubt-sun': require('./assets/doubt-sun.svg').default,
  'icon-document-empty': require('./assets/icon-document-empty.svg').default,
  'icon-address': require('./assets/icon-address.svg').default,
  'sun-success': require('./assets/sun-success.svg').default,
  'icon-filter-enabled': require('./assets/icon-filter-enabled.svg').default,
  'icon-filter': require('./assets/icon-filter.svg').default,
  'icon-phone-number': require('./assets/icon-phone-number.svg').default,
  'ready-PermitPackage': require('./assets/ready-PermitPackage.svg').default,
  'icon-account-big': require('./assets/icon-account-big.svg').default,
  'icon-permit-package': require('./assets/icon-permit-package.svg').default,
  'tick': require('./assets/tick.svg').default,
  'icon-delete': require('./assets/icon-delete.svg').default,
  'card-external': require('./assets/card-external.svg').default,
  'card-logo': require('./assets/card-logo.svg').default,
  'close': require('./assets/close.svg').default,
  'arrow-dropdown': require('./assets/arrow-dropdown.svg').default,
  'icon-edit-circuits': require('./assets/icon-edit-circuits.svg').default,
  'tool-images': require('./assets/tool-images.svg').default,
  'tool-settings': require('./assets/tool-settings.svg').default,
  'tool-wrench': require('./assets/tool-wrench.svg').default,
  'icon-document': require('./assets/icon-document.svg').default,
  'icon-billable': require('./assets/icon-billable.svg').default,
  'icon-danger': require('./assets/icon-danger.svg').default,
  'icon-warning': require('./assets/icon-warning.svg').default,
  'overdrive-alert-icon': require('./assets/overdrive-alert-icon.svg').default,
  'remove-icon': require('./assets/remove-icon.svg').default,
  'highTemp': require('./assets/highTemp.svg').default,
  'lowTemp': require('./assets/lowTemp.svg').default,
  'landscape-aligned': require('./assets/landscape-aligned.svg').default,
  'landscape-maximized': require('./assets/landscape-maximized.svg').default,
  'landscape-staggered': require('./assets/landscape-staggered.svg').default,
  'portrait-aligned-mixed': require('./assets/portrait-aligned-mixed.svg').default,
  'portrait-aligned-not-mixed': require('./assets/portrait-aligned-not-mixed.svg').default,
  'portrait-maximized-mixed': require('./assets/portrait-maximized-mixed.svg').default,
  'portrait-maximized-not-mixed': require('./assets/portrait-maximized-not-mixed.svg').default,
  'portrait-staggered-mixed': require('./assets/portrait-staggered-mixed.svg').default,
  'portrait-staggered-not-mixed': require('./assets/portrait-staggered-not-mixed.svg').default,
  'landscape-icon-not-selected': require('./assets/landscape-icon-not-selected.svg').default,
  'landscape-icon-selected': require('./assets/landscape-icon-selected.svg').default,
  'portrait-icon-not-selected': require('./assets/portrait-icon-not-selected.svg').default,
  'portrait-icon-selected': require('./assets/portrait-icon-selected.svg').default,
  'icon-level-1': require('./assets/level-1.svg').default,
  'icon-level-1-5': require('./assets/level-1-5.svg').default,
  'icon-level-2': require('./assets/level-2.svg').default,
  'icon-level-2-5': require('./assets/level-2-5.svg').default,
  'icon-level-3': require('./assets/level-3.svg').default,
  'icon-level-3-5': require('./assets/level-3-5.svg').default,
  'icon-level-4': require('./assets/level-4.svg').default,
  'new-building-icon': require('./assets/new-building-icon.svg').default,
  'building-properties-icon': require('./assets/building-properties-icon.svg').default,
  'environmental-parameters-icon': require('./assets/environmental-parameters-icon.svg').default,
  'project-properties-icon': require('./assets/project-properties-icon.svg').default,
  'site-equipment-icon': require('./assets/site-equipment-icon.svg').default,
  'image-selection-check': require('./assets/image-selection-check.svg').default,
  'list-item-check': require('./assets/list-item-check.svg').default,
  'list-item-x': require('./assets/list-item-x.svg').default,
  'doc-blue': require('./assets/doc-blue.svg').default,
  'doc-check': require('./assets/doc-check.svg').default,
  'doc-x': require('./assets/doc-x.svg').default,
  'doc-preview': require('./assets/doc-preview.svg').default,
  'angle_snap_disabled': require('./assets/angle_snap_disabled.svg').default,
  'angle_snap_enabled': require('./assets/angle_snap_enabled.svg').default,
  'angle_snap_selected': require('./assets/angle_snap_selected.svg').default,
  'extension_lines_disabled': require('./assets/extension_lines_disabled.svg').default,
  'extension_lines_enabled': require('./assets/extension_lines_enabled.svg').default,
  'extension_lines_selected': require('./assets/extension_lines_selected.svg').default,
  'midpoints_disabled': require('./assets/midpoints_disabled.svg').default,
  'midpoints_enabled': require('./assets/midpoints_enabled.svg').default,
  'midpoints_selected': require('./assets/midpoints_selected.svg').default,
  'parallel_lines_disabled': require('./assets/parallel_lines_disabled.svg').default,
  'parallel_lines_enabled': require('./assets/parallel_lines_enabled.svg').default,
  'parallel_lines_selected': require('./assets/parallel_lines_selected.svg').default,
  'perpendicular_alignment_disabled': require('./assets/perpendicular_alignment_disabled.svg').default,
  'perpendicular_alignment_enabled': require('./assets/perpendicular_alignment_enabled.svg').default,
  'perpendicular_alignment_selected': require('./assets/perpendicular_alignment_selected.svg').default,
  'smart_line_disabled': require('./assets/smart_line_disabled.svg').default,
  'smart_line_enabled': require('./assets/smart_line_enabled.svg').default,
  'smart_line_selected': require('./assets/smart_line_selected.svg').default,
  'smart_snap_disabled': require('./assets/smart_snap_disabled.svg').default,
  'smart_snap_enabled': require('./assets/smart_snap_enabled.svg').default,
  'smart_snap_selected': require('./assets/smart_snap_selected.svg').default,
  'accordion-arrow-down': require('./assets/accordion-arrow-down.svg').default,
  'accordion-arrow-left': require('./assets/accordion-arrow-left.svg').default,
  'accordion-arrow-right': require('./assets/accordion-arrow-right.svg').default,
  'accordion-arrow-up': require('./assets/accordion-arrow-up.svg').default,
  'msp-icon': require('./assets/msp-icon.svg').default,
  'sp-icon': require('./assets/sp-icon.svg').default,
  'warning': require('./assets/warning.svg').default,
  'error': require('./assets/error.svg').default,
  'confirmation': require('./assets/confirmation.svg').default,
  'shade-analysis-enabled': require('./assets/shade-analysis-enabled.svg').default,
  'tool-solar-access-inspector': require('./assets/tool-solar-access-inspector.svg').default,
  'smart-guide-disabled': require('./assets/smart-guide-disabled.svg').default,
  'smart-guide-enabled': require('./assets/smart-guide-enabled.svg').default,
  'dropdown-arrow-disabled': require('./assets/dropdown-arrow-disabled.svg').default,
  'dropdown-arrow-enabled': require('./assets/dropdown-arrow-enabled.svg').default,
  'close-icon-gray': require('./assets/close-icon-gray.svg').default,
  'changes-pending': require('./assets/changes-pending.svg').default,
  'save-failed': require('./assets/save-failed.svg').default,
  'save-successful': require('./assets/save-successful.svg').default,
  'engineer-of-record': require('./assets/icon-engineer-of-record.svg').default,
  'property-boundaries': require('./assets/property-boundaries.svg').default
};

const registry = new Registry(mappingIcons);

export { registry };
