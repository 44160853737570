const C = '-';

const encodeFieldName = (str: string): string => {
  const myType = typeof str;
  if (myType !== 'string') {
    throw new Error(`ERROR: type of ${str}(${myType}) is not 'string'!`);
  }
  return _encode(str);
};

const decodeFieldName = (str: string): string => {
  const myType = typeof str;
  if (myType !== 'string') {
    throw new Error(`ERROR: type of ${str}(${myType}) is not 'string'!`);
  }
  return _decode(str);
};

const _encode = (str: string): string => {
  str = str.split('.').join(C);

  const re = /\[\d*\]/g;
  const matches = str.matchAll(re);
  let result = matches.next();
  while (!result.done) {
    str = str.replace(
      result.value[0], C + result.value[0].slice(
        1, -1
      ) + C
    );
    result = matches.next();
  }
  return str;
};

const _decode = (str: string): string => {
  const re = /-\d*-/g;
  const matches = str.matchAll(re);
  let result = matches.next();
  while (!result.done) {
    str = str.replace(
      result.value[0], `[${ result.value[0].slice(
        1, -1
      ) }]`
    );
    result = matches.next();
  }

  str = str.split(C).join('.');

  return str;
};

export {
  encodeFieldName, decodeFieldName
};
