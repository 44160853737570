import type {
  Mesh, Material, Object3D
} from 'three';
import {
  Group, Vector3
} from 'three';
import filter from 'lodash/filter';
import type { IBaseToolDependencies } from '../Tool';
import { BaseTool } from '../Tool';
import {
  BaseImageryProvider, ECursor
} from '../../../../domain/typings';
import type MapStore from '../../MapStore/MapStore';
import { DragControl } from '../../../EditorStore/Controls/DragControl';
import type SmartGuidesStore from '../../SmartGuidesStore/SmartGuidesStore';
import type { CustomBaseImageryMesh } from '../../../../domain/models/CustomBaseImageryMesh';
import { CustomBaseImageryTransformationHandleMesh } from '../../../../domain/models/CustomBaseImageryTransformationHandleMesh';
import { rootStore } from '../../../Store';
import { PropsPanelUICodes } from '../../Properties/propertiesStoreConstants';
import type { IUpdateCustomBaseImageryCommandDependencies } from '../../../ServiceBus/Commands/UpdateBaseImageryCommand';
import type { Marker } from '../../../../domain/models/SiteDesign/Marker';

import { SceneObjectType } from '../../../../domain/models/Constants';
import type { Selectable } from '../../../../domain/mixins/Selectable';
import type { Drawable } from '../../../../domain/mixins/Drawable';
import { getLyraModelByMesh } from '../../../../domain/sceneObjectsWithLyraModelsHelpers';
import { CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID } from './constants';
import { SelectionTool } from './SelectionTool';

// static configuration
const performanceLogging = false;
const performanceLog = (...strs: string[]): void => {
  if (performanceLogging) {
    // eslint-disable-next-line no-console
    console.trace(...strs);
  }
};

export class CustomBaseImageryTransformationTool extends BaseTool {
  readonly id: string = CUSTOM_BASE_IMAGERY_TRANSFORMATION_TOOL_ID;
  readonly icon: string = '';
  readonly title: string = '';
  readonly description: string = '';
  private readonly mapStore: MapStore;

  private dragControl?: DragControl;
  private readonly smartGuides: SmartGuidesStore;
  private transformationHandles: CustomBaseImageryTransformationHandleMesh[] = [];
  private group: Group = new Group();
  private handleOnScreenThreshold = 130;

  private customBaseImageryMesh?: CustomBaseImageryMesh;
  private isCustomBaseImageryConfigured: boolean;
  private initializationComplete!: () => void;

  initializationPromise: Promise<void>;

  constructor(
    dependencies: IBaseToolDependencies & {
      mapStore: MapStore;
      smartGuides: SmartGuidesStore;
      isCustomBaseImageryConfigured: boolean;
    }
  ) {
    super(dependencies);
    performanceLog('tool constructor');
    this.cursor = ECursor.PANNING;
    this.mapStore = dependencies.mapStore;
    this.smartGuides = dependencies.smartGuides;
    this.isCustomBaseImageryConfigured = dependencies.isCustomBaseImageryConfigured;
    this.initializationPromise = new Promise((resolve) => {
      this.initializationComplete = resolve;
    });
  }

  async createHandles(width: number, height: number): Promise<void> {
    this.group.userData.handles = this.transformationHandles = [];
    for (let i = 0; i < 4; i++) {
      /*
       * 3--1
       * |  |
       * 2--0
       * */
      const handle = new CustomBaseImageryTransformationHandleMesh(i, width, height, () =>
        this.getAbsoluteImageCornerPositions()
      );
      this.group.add(handle.mesh);
      await handle.initializationPromise;
      this.group.add(handle.clickArea);
      handle.mesh.userData.groupReference = this.group;
      this.transformationHandles.push(handle);
      performanceLog('create handle');
    }
    this.group.userData.positionHandles = () => this.positionHandles();
  }

  getAbsoluteImageCornerPositions(): Vector3[] {
    performanceLog('getAbsoluteImageCornerPositions');
    const imageryMesh = this.group.userData.imageryMesh as CustomBaseImageryMesh;
    const imageWidth = imageryMesh.mesh.scale.x; // * this.group.scale.x;
    const imageHeight = imageryMesh.mesh.scale.y; // * this.group.scale.y;
    /*
     * a--b
     * |  |
     * d--c
     * */
    return [
      new Vector3(-imageWidth / 2, imageHeight / 2, 0),
      new Vector3(imageWidth / 2, imageHeight / 2, 0),
      new Vector3(imageWidth / 2, -imageHeight / 2, 0),
      new Vector3(-imageWidth / 2, -imageHeight / 2, 0)
    ].map((vector: Vector3): Vector3 => this.group.localToWorld(vector));
  }

  positionHandles(): void {
    performanceLog('positionHandles');
    // Project right top point to scene space
    const windowBoundaries = [
      /*
       * 3--0
       * |  |
       * 2--1
       * */
      new Vector3().copy(new Vector3(1, 1, 0))
        .unproject(rootStore.editor.activeCamera!),
      new Vector3().copy(new Vector3(1, -1, 0))
        .unproject(rootStore.editor.activeCamera!),
      new Vector3().copy(new Vector3(-1, -1, 0))
        .unproject(rootStore.editor.activeCamera!),
      new Vector3().copy(new Vector3(-1, 1, 0))
        .unproject(rootStore.editor.activeCamera!)
    ];
    /*
     * a--b
     * |  |
     * d--c
     * */
    const [A, B, C, D] = this.getAbsoluteImageCornerPositions();

    const mapSideToVertex: {
      [key: number]: Vector3;
    } = {
      0: C,
      1: B,
      2: D,
      3: A
    };
    for (let imageCornerIndex = 0; imageCornerIndex < 4; imageCornerIndex++) {
      const distanceMap: {
        [key: number]: Vector3;
      } = {};
      for (let windowCornerIndex = 0; windowCornerIndex < 4; windowCornerIndex++) {
        distanceMap[mapSideToVertex[imageCornerIndex].distanceTo(windowBoundaries[windowCornerIndex])] =
          windowBoundaries[windowCornerIndex];
      }
      const theClosestWindowCorner =
        distanceMap[Math.min(...Object.keys(distanceMap).map((item: string): number => Number(item)))];

      const yOverflow = -(Math.abs(theClosestWindowCorner.y) - Math.abs(mapSideToVertex[imageCornerIndex].y));
      const xOverflow = -(Math.abs(theClosestWindowCorner.x) - Math.abs(mapSideToVertex[imageCornerIndex].x));

      const neighbouringVertices = [];
      switch (imageCornerIndex) {
      case 0:
        neighbouringVertices.push(A, B, C);
        break;
      case 1:
        neighbouringVertices.push(B, C, D);
        break;
      case 2:
        neighbouringVertices.push(C, D, A);
        break;
      case 3:
        neighbouringVertices.push(D, A, B);
      }
      (this.group.userData.handles[imageCornerIndex] as CustomBaseImageryTransformationHandleMesh).moveCloserToCenter({
        windowBoundaries,
        cornerVertexInAbsolutPosition: mapSideToVertex[imageCornerIndex],
        neighbouringVertices,
        corners: [A, B, C, D],
        overflows: [xOverflow, yOverflow],
        rotation: this.group.rotation.z,
        threshold: this.handleOnScreenThreshold * rootStore.editor.scaleFactor
      });
    }
  }

  async initializeImageAndDragControl() {
    performanceLog('initializeImageAndDragControl');
    const projectId = this.editor.domain.project.id;

    this.customBaseImageryMesh = await this.mapStore.loadCustomBaseImageryAndCreateMesh({
      projectId,
      editor: this.editor,
      mapZoomFactor: this.editor.mapZoomFactor,
      projectSiteImagery: {
        provider: BaseImageryProvider.CUSTOM,
        zoomLevel: this.editor.domain.project.site.imagery.zoomLevel,
        CUSTOM: {
          scaleFactor: 1,
          rotation: 0,
          translationVector: {
            x: 0,
            y: 0
          }
        }
      }
    });

    if (this.group) {
      this.editor.removeObject(this.group);
    }
    this.group = new Group();
    this.group.position.z = 15;

    await this.createHandles(this.customBaseImageryMesh.mesh.scale.x, this.customBaseImageryMesh.mesh.scale.y);

    this.group.userData.imageryMesh = this.customBaseImageryMesh;
    this.group.add(this.customBaseImageryMesh.mesh);

    this.editor.addOrUpdateObject(this.group);

    this.customBaseImageryMesh.mesh.userData.groupReference = this.group;

    // At this point we're sure that this.customBaseImageryMesh is present, and we can pass it to drag control.
    this.configureDragControl();

    if (this.isCustomBaseImageryConfigured) {
      const site = rootStore.domain.project.site;
      this.group.position.copy(
        new Vector3(site.imagery.CUSTOM?.translationVector.x ?? 0, site.imagery.CUSTOM?.translationVector.y ?? 0, 15)
      );
      this.group.rotation.z = site.imagery.CUSTOM?.rotation ?? 0;
      this.group.scale.x = this.group.scale.y = site.imagery.CUSTOM?.scaleFactor ?? 0;
      this.positionHandles();
      this.transformationHandles.forEach((handle: CustomBaseImageryTransformationHandleMesh): void => {
        handle.groupScaleFactor = this.group.scale.x;
        handle.unzoom(rootStore.editor.scaleFactor);
      });
    }

    this.initializationComplete();
  }

  configureDragControl(): void {
    this.dragControl = DragControl.getInstance(
      this.editor,
      this.editor.viewport,
      this.editor.activeCamera,
      this.smartGuides
    );

    this.dragControl.activate();
    this.dragControl.setTargetObjects(
      this.group.children.map((child: Object3D) => getLyraModelByMesh<Selectable>(child)),
      false
    );
  }

  async persist(): Promise<void> {
    const rotation = this.group.rotation.z < 0 ? this.group.rotation.z + 2 * Math.PI : this.group.rotation.z;
    this.serviceBus.send('update_base_imagery_command', {
      domain: rootStore.domain,
      customBaseImagery: {
        scaleFactor: this.group.scale.x,
        rotation,
        translationVector: {
          x: this.group.position.x,
          y: this.group.position.y
        }
      },
      newProvider: BaseImageryProvider.CUSTOM
    } as IUpdateCustomBaseImageryCommandDependencies);

    if (this.group) {
      this.editor.removeObject(this.group);
    }
    // Move mesh with editing controls from main scene and run the imagery initialization routine that'd use custom one
    await this.editor.finishCustomBaseImageryTransformation();
    // Select "SelectionTool"
    rootStore.toolbar.selectTool(new SelectionTool(rootStore));
  }

  async whenSelected(): Promise<void> {
    this.updateCursor(this.cursor);
    await this.initializeImageAndDragControl();
    rootStore.properties.setPropertyPanel(PropsPanelUICodes.CustomBaseImageryTransformation);
    this.setSiteElementsVisibility(false);
  }

  changeOpacity = (delta: number) => {
    if (!this.customBaseImageryMesh) {
      return;
    }
    const material = this.customBaseImageryMesh.mesh.material as Material;
    material.transparent = true;
    material.opacity = Math.min(1, Math.max(material.opacity + delta, 0));
  };

  whenDeselected(): void {
    this.deactivateControls();
    this.updateCursor(this.cursor);
    if (this.group) {
      this.editor.removeObject(this.group);
    }
    if (this.customBaseImageryMesh) {
      this.editor.removeObject(this.customBaseImageryMesh.mesh);
    }
    this.setSiteElementsVisibility(true);
  }

  private setSiteElementsVisibility(visible: boolean): void {
    const markers = rootStore.domain.siteEquipment.getEquipmentMarkerObjects();
    const objects: Object3D[] = [
      ...(
        this.editor.getObjectsByTypes([
          SceneObjectType.Outline,
          SceneObjectType.ParcelBoundary,
          SceneObjectType.RoofFace
        ]) as Drawable[]
      ).map((object: Drawable): Mesh => object.mesh),
      ...markers.map((marker: Marker): Mesh => marker.mesh)
    ];
    for (const object of objects) {
      object.visible = visible;
    }
  }

  dispose(): void {
    this.whenDeselected();
  }

  private deactivateControls(): void {
    this.dragControl?.deactivate();
  }
}
