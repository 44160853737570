import { Color, ThemeOption } from '../../typings';

export interface IColorBasePalette {
  // Greys
  grey1: Color;
  grey2: Color;
  grey3: Color;
  grey4: Color;
  grey5: Color;

  // Main colors
  main1: Color;
  main2: Color;
  main3: Color;

  // Greys
  lighter: Color;
  darker: Color;
  neutral: Color;

  secondaryFont: Color;

  // Stringings colors
  str01: Color;
  str02: Color;
  success: Color;
  warn: Color;
  error: Color;
}

export type ColorScheme = ThemeOption<Color> & IColorBasePalette;

/**
 * This is a bit of a mess.
 * It seems it has constants for two different color schemes - Design tool and Host App,
 * but the distinction is not very explicit, especially where both schemes define multiple main colors/shades of grey
 */
const colorScheme: ColorScheme = {
  // The primary color scheme used in the Design Tool can be found here:
  // https://app.zeplin.io/project/5daddbd530b1aa127f0d7ad8/screen/5db20c7fe0e5042c2d416f33

  grey1: '#4A4A4A',
  grey2: '#8F8F8F',
  grey3: '#BFBFBF',
  grey4: '#D9D9D9',
  grey5: '#ECECEC',

  main1: '#427ECD',
  main2: '#1FC0C0',
  main3: '#685FCB',

  darker: '#4A4A4A',
  neutral: '#979797',
  lighter: '#E2E2E2',

  str01: '#C3BC6A',
  str02: '#B0DF6C',
  str03: '#36A247',
  success: '#58AC65',
  warn: '#E7AB39',
  error: '#D34A4A',

  // LEGACY COLOR SCHEMA
  primary: '#427ecd',
  primaryHover: '#31609e',
  secondary: '#f2f2f2',
  tertiary: '#d9d9d9',
  quaternary: '#979797',
  info: '#ffffff',
  highlight: '#ffffff',
  primaryFont: '#201e39',
  secondaryFont: '#4a4a4a',
  tertiaryFont: '#f8f7fc',
  iconBg2: '#595959',
  lineHr: '#d3d3d3',
  whiteBg: '#ffffff',
  black: '#000000',
  hintBg: 'rgb(59, 152, 148, .1)',

  // The primary color scheme used in the Host App can be found here:
  // https://app.zeplin.io/project/5db36bd7a165755768bb06e4/screen/5db74a8d4683c656203abcd1
  mainColor1: '#427ecd',    // Note: this should be '#2D80EF' but changing it affected Design Tool too
  mainColor2: '#685FCB',
  mainColor3: '#1FC0C0',
  mainColor4: '#D34A4A',
  mainColor5: '#FF736E',
  mainColor6: '#100E2A',
  mainColor7: '#3B71B9',

  grayColor1: '#333038',
  grayColor2: '#858585',
  grayColor3: '#ADADAD',
  grayColor4: '#D9D9D9',
  grayColor5: 'rgba(173, 173, 173, .2)',
  grayColor6: '#d8d8d8',
  grayColor7: '#f2f2f2',
  grayColor8: '#eaeaea',
  grayColor9: '#4a4a4a',
  grayColor10: '#e7e7e7',
  grayColor11: '#fafafa',
  grayForIcons: '#9e9e9e',

  redColor1: '#ae0318',
  redColor2: 'rgba(174, 3, 24, 0.07)',

  transparent: 'transparent',
  yellowColor1: '#cb5013',

  greenColor1: '#52b8b8',
  greenColor2: '#d9e2e2',

  opacityColor1: 'rgba(0, 0, 0, 0.5)',
  opacityColor2: 'rgba(211, 211, 211, 0.5)',
  secondaryFontOpacity: 'rgba(74, 74, 74, 0.5)',
  opacityColor3: 'rgba(59, 152, 148, 0.1)',
  opacityColor4: 'rgba(0, 155, 149, 0.15)',
  opacityColor5: 'rgba(74, 74, 74, 0.5)',

  blackColor1: '#282829',

  // Note: The following styles were copied over from design tool when duplicated theme management was removed.
  // It's likely that some of these can (and should) be removed, but due to lack of time this was left as tech debt.
  gray1: '#4a4a4a',
  gray2: '#979797',
  gray3: '#e2e2e2',
  grayOpacity1: 'rgba(74, 74, 74, 0.25)',
  grayOpacity2: 'rgba(74, 74, 74, 0.15)',
  grayOpacity3: 'rgba(107, 111, 113, 0.25)',
  grayOpacity4: 'rgba(74, 74, 74, 0.35)',
  gray4: '#eaeaea',
  gray5: '#a8a8a8',
  gray6: '#f4f4f4',
  gray7: '#e7e7e7',
  gray8: '#fafafa',
  gray9: '#f4f8fe',
  gray10: '#C4C4C4',
  gray11: '#AAAAAA',
  grayLightBg: '#F0F0F0',

  newGray1: '#cccccc',
  newGray2: '#ececec',
  newGray3: '#c9c9c9',

  primary1: '#388deb',
  primary2: '#2d80ef',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  orange: '#F0BA44',
  brightOrange: '#ff8c00',
  orangeLightBg: '#FFF8E5',
  blue: '#427ECD',
  blueLight: '#417ECD',
  blueGreen: '#3B9894',
  red: '#D8000A',
  redLightBg: '#FFEAE9'
};

// tslint:disable-next-line:no-any
export const aurorifyColorScheme = (dsColors: any /*typeof BorealisTheme.colors*/): ColorScheme => {
  colorScheme.grey1 = dsColors['gray-700'];
  colorScheme.grey2 = dsColors['gray-500'];
  colorScheme.grey3 = dsColors['gray-400'];
  colorScheme.grey4 = dsColors['gray-300'];
  colorScheme.grey5 = dsColors['gray-200'];

  colorScheme.main1 = dsColors.maroon;
  colorScheme.main2 = dsColors['yellow-100'];
  colorScheme.main3 = dsColors['maroon-800'];
  colorScheme.darker = dsColors['gray-800'];
  colorScheme.neutral = dsColors['gray-600'];
  colorScheme.lighter = dsColors['gray-400'];
  colorScheme.str01 = dsColors['yellow-200'];
  colorScheme.str02 = dsColors['green-200'];
  colorScheme.str03 = dsColors['green-500'];
  colorScheme.success = dsColors['green-500'];
  colorScheme.warn = dsColors['orange-400'];
  colorScheme.error = dsColors['red-500'];

  colorScheme.primary = dsColors.maroon;
  colorScheme.primaryHover = dsColors['maroon-900'];
  colorScheme.secondary = dsColors['gray-200'];
  colorScheme.tertiary = dsColors['gray-400'];
  colorScheme.quaternary = dsColors['gray-600'];
  colorScheme.info = dsColors.white;
  colorScheme.highlight = dsColors.white;
  colorScheme.primaryFont = dsColors.blue;
  colorScheme.tertiaryFont = dsColors['gray-200'];
  colorScheme.iconBg2 = dsColors['gray-600'];
  colorScheme.lineHr = dsColors['gray-300'];
  colorScheme.whiteBg = dsColors.white;
  colorScheme.black = '#000000';
  colorScheme.hintBg = dsColors['cyan-800'];

  colorScheme.mainColor1 = colorScheme.main1;
  colorScheme.mainColor2 = colorScheme.main3; // 2 vs 3 is intentional
  colorScheme.mainColor3 = colorScheme.main2;
  colorScheme.mainColor4 = dsColors['red-400'];
  colorScheme.mainColor5 = dsColors['red-300'];
  colorScheme.mainColor6 = dsColors['blue-1000'];
  colorScheme.mainColor7 = dsColors['cyan-400'];

  colorScheme.grayColor1 = dsColors['gray-800'];
  colorScheme.grayColor2 = dsColors['gray-600'];
  colorScheme.grayColor3 = dsColors['gray-500'];
  colorScheme.grayColor4 = dsColors['gray-300'];
  // gray-500->rgb(153, 161, 166)
  colorScheme.grayColor5 = '#99a1a633';
  colorScheme.grayColor6 = dsColors['gray-300'];
  colorScheme.grayColor7 = dsColors['gray-200'];
  colorScheme.grayColor8 = dsColors['gray-100'];
  colorScheme.grayColor9 = dsColors['gray-800'];
  colorScheme.grayColor10 = dsColors['gray-200'];
  colorScheme.grayColor11 = dsColors['gray-100'];
  colorScheme.grayForIcons = dsColors['gray-300'];

  colorScheme.redColor1 = dsColors['gray-500'];
  // #d8092f12 is the same as rgba(216, 9, 47, 0.07), where rgb(216, 9, 47) is red-500
  colorScheme.redColor2 = '#d8092f12';

  colorScheme.transparent = 'transparent';
  colorScheme.yellowColor1 = dsColors['orange-500'];

  colorScheme.greenColor1 = dsColors['green-200'];
  colorScheme.greenColor2 = dsColors['green-100'];

  colorScheme.opacityColor1 = 'rgba(0, 0, 0, 0.5)';
  colorScheme.opacityColor2 = '#99a1a680';
  colorScheme.secondaryFontOpacity = '#343a4580';
  colorScheme.opacityColor3 = '#3b989480';
  colorScheme.opacityColor4 = '#009b9580';
  colorScheme.opacityColor5 = colorScheme.secondaryFontOpacity;

  colorScheme.blackColor1 = dsColors['gray-900'];

  colorScheme.gray1 = dsColors['gray-700'];
  colorScheme.gray2 = dsColors['gray-600'];
  colorScheme.gray3 = dsColors['gray-100'];
  colorScheme.grayOpacity1 = '#343a4540';
  colorScheme.grayOpacity2 = '#343a4530';
  colorScheme.grayOpacity2 = '#343a4550';
  colorScheme.grayOpacity3 = '#343a4550';
  colorScheme.grayOpacity4 = '#343a4565';
  colorScheme.gray4 = dsColors['gray-100'];
  colorScheme.gray5 = dsColors['gray-200'];
  colorScheme.gray6 = dsColors['gray-100'];
  colorScheme.gray7 = dsColors['gray-200'];
  colorScheme.gray8 = dsColors['gray-100'];
  colorScheme.gray9 = dsColors['gray-200'];
  colorScheme.gray10 = dsColors['gray-300'];
  colorScheme.gray11 = dsColors['gray-400'];
  colorScheme.grayLightBg = dsColors['gray-200'];

  colorScheme.newGray1 = dsColors['gray-400'];
  colorScheme.newGray2 = dsColors['gray-300'];
  colorScheme.newGray3 = dsColors['gray-500'];

  colorScheme.primary1 = dsColors['maroon-300'];
  colorScheme.primary2 = dsColors['maroon-400'];
  colorScheme.blackOpacity = '#01091680';
  colorScheme.orange = dsColors['yellow-300'];
  colorScheme.brightOrange = dsColors['orange-400'];
  colorScheme.orangeLightBg = dsColors['orange-100'];
  colorScheme.blue = dsColors['blue-500'];
  colorScheme.blueLight = dsColors['blue-600'];
  colorScheme.blueGreen = dsColors['cyan-400'];
  colorScheme.red = dsColors['red-500'];
  colorScheme.redLightBg = dsColors['red-100'];

  colorScheme.error = colorScheme.mainColor4;
  colorScheme.warn = colorScheme.mainColor5;
  colorScheme.secondaryFont = colorScheme.mainColor6;
  colorScheme.str01 = colorScheme.mainColor7;

  return colorScheme;
};

export { colorScheme };
