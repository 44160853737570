import find from 'lodash/find';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import {
  action, observable
} from 'mobx';
import type { IBaseSiteEquipmentViewModelDependencies } from '../BaseSiteEquipmentViewModel';
import { BaseSiteEquipmentViewModel } from '../BaseSiteEquipmentViewModel';
import { PropsPanelUICodes } from '../../propertiesStoreConstants';
import type { StreetLocation } from '../../../../../domain/models/SiteDesign/StreetLocation';
import { recursiveObjectAssign } from './siteEquipmentViewModelHelpers';

type TProperties = {
  exposure?: { type: string; constrained: boolean };
};
export class StreetLocationViewModel extends BaseSiteEquipmentViewModel {
  readonly propUICode = PropsPanelUICodes.StreetLocation;
  readonly title = 'Street Location';

  readonly exposureOptions: OptionProps[] = [
    {
      name: 'OUTDOOR',
      value: 'OUTDOOR'
    }
  ];

  /** Street location props */
  @observable
  exposure?: OptionProps;

  constructor(dependencies: IBaseSiteEquipmentViewModelDependencies) {
    super(dependencies);
    this.getDomainProps();
  }

  setDomainProps(prop: TProperties): void {
    const streetLocation = this.domain.siteEquipment.streetLocation;
    this.updateEquipment(recursiveObjectAssign<StreetLocation>(streetLocation ?? {}, prop));
  }

  @action.bound
  getDomainProps(): void {
    const streetLocation = this.domain.siteEquipment.streetLocation;
    this.setExposure(streetLocation?.exposure?.type, false);
  }

  /** Setter methods */
  @action.bound
  setExposure(value?: string | number, shouldUpdate: boolean = true): void {
    const exposure = find(this.exposureOptions, this.findOptionByValue(value)) as OptionProps;
    if (exposure) {
      this.exposure = exposure;
    }
    if (shouldUpdate) {
      this.setDomainProps({
        exposure: {
          type: `${value}`,
          constrained: true
        }
      });
    }
  }
}
