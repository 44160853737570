import type { Outline } from '../../../domain/models/SiteDesign/Outline';
import type { Building } from '../../../domain/models/SiteDesign/Building';
import type { Color } from '../../../domain/typings';
import type DomainStore from '../../DomainStore/DomainStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import { BaseCommand } from './Command';

export interface IAddRoofOutlineCommandDependencies extends IBaseCommandDependencies {
  readonly domain: DomainStore;
  readonly newOutline: Outline;
  readonly color: Color;
  readonly name: string;
  readonly building: Building;
}

export class AddRoofOutlineCommand extends BaseCommand {
  private readonly domain: DomainStore;
  private readonly color: Color;
  private readonly name: string;
  private readonly newOutline: Outline;
  private readonly building: Building;

  constructor(dependencies: IAddRoofOutlineCommandDependencies) {
    super();
    const {
      newOutline, color, name, domain, building
    } = dependencies;
    this.newOutline = newOutline;
    this.color = color;
    this.name = name;
    this.domain = domain;
    this.building = building;
  }

  async execute(): Promise<void> {
    this.newOutline.color = this.color;
    this.newOutline.name = this.name;
    this.domain.addOutline(this.newOutline, this.building);
    this.newOutline.redraw();
  }
}
