import { Flexbox } from '@aurorasolar/lyra-ui-kit/lib/components/Layout';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import type { PropsWithTheme } from '../../../../ui/components/Button/styles';
import {
  MESSAGE_BACKGROUND_COLOR, MESSAGE_BORDER_COLOR
} from '../../../../domain/models/Constants';

export const CreateProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  & > fieldset {
    width: 49% !important;
    margin-right: 0;
  }
`;
export const CreateProjectFieldRowWrapper = styled.div`
  padding-top: 8px;
  & fieldset {
    min-width: 0;
    width: 100%;
    & > div {
      margin-bottom: 4px;
    }
  }
  // disable icon for Dashline and LastPass password managers
  div[data-lastpass-icon-root],
  span[data-dashlanecreated] {
    display: none !important;
  }
`;

export const CreateProjectSubTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: 300;
`;

export const CreateProjectContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 900px;
  overflow: hidden;
  align-items: flex-start;
`;

export const CreateProjectLeftContentSide = styled.div`
  width: calc(45% - 15px);
`;
export const CreateProjectRightContentSide = styled.div`
  width: calc(55% - 15px);
  & .messageInfo {
    margin-top: 10px;
    border: solid 0.7px ${MESSAGE_BORDER_COLOR.success} !important;
    background: ${MESSAGE_BACKGROUND_COLOR.success} !important;
    box-sizing: border-box;
  }
`;
export const CreateProjectContentSideSeparator = styled.div`
  width: 5px;
`;
export const ModalWrapper = styled.div<{ step: number }>`
  overflow: hidden;
  padding: 0 ${({ step }: { step: number }): number => (step === 4 ? 40 : 10)}px;
`;

export const CenteredTitle = styled.div`
  text-align: center;
`;

export const CreateInstallerStep1Wrapper = styled.div`
  width: 640px;
`;

export const HelpText = styled.div`
  background-color: rgba(59, 142, 148, 0.1);
  padding: 13px;
  border-radius: 7px;
  margin-bottom: 8px;
`;

export const SetupAccountLogo = styled.div`
  font-size: 48px;
  text-align: center;
  & svg {
    width: 48px;
    height: 48px;
  }
`;

export const LicenseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  & fieldset {
    min-width: 0;
    & > div {
      margin-bottom: 4px;
    }
  }
`;

export const LicenseNumberWrapper = styled.div`
  flex-grow: 2;
  margin-right: 8px;
`;
export const LicenseClassWrapper = styled.div`
  flex-grow: 1;
  margin-right: 8px;
`;
export const LicenseStateWrapper = styled.div`
  flex-grow: 1;
`;

export const RemoveWrapper = styled.div`
  cursor: pointer;
  top: 8px;
  margin-left: 8px;
  position: relative;
`;

export const LinkText = styled.a`
  font: normal italic 12px Roboto;
  display: inline-block;
  text-decoration: underline;
  letter-spacing: normal;
  font-stretch: normal;
  cursor: pointer;
  color: #427ecd;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  & fieldset {
    min-width: 0;
    & > div {
      margin-bottom: 4px;
    }
  }
`;

export const AddressHorizontalItemWrapper = styled.div`
  flex-grow: 1;
`;

export const OtherInformationWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  & fieldset {
    min-width: 0;
    & > div {
      margin-bottom: 4px;
    }
  }
`;

export const OtherInformationItemWrapper = styled.div`
  flex-grow: 1;
`;

export const DropView = styled(Flexbox).attrs({
  justify: 'center',
  align: 'center'
})`
  width: 100%;
  height: 165px;
  border-radius: 8px;
  border: dotted 1px ${({ theme }: PropsWithTheme): Color => theme.colors.grayColor3};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.grayColor7};
  margin-top: 15px;
  cursor: pointer;
`;

export const DropText = styled.h4`
  font-size: 19px;
  font-weight: bold;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.grayColor2};
`;

export const BrowseText = styled.h4`
  font-weight: bold;
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.main1};
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 250px;
  margin-top: 24px;
  min-width: 250px;
  max-width: 300px;
`;

export const UploadLogoBottomControls = styled(Flexbox).attrs({
  justify: 'space-between',
  align: 'center',
  dir: 'row'
})`
  width: 200px;
`;

export const IconWrapper = styled(Flexbox).attrs({
  justify: 'flex-end'
})`
  width: 100%;
  margin-top: 15px;
  text-align: right;
  margin-right: 10px;
  cursor: pointer;
`;

export const BoldLink = styled.span`
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.mainColor1};
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
`;

export const UploadLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CompanySettingsTabsWrapper = styled.div`
  margin-bottom: 15px;
`;

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: #f2f2f2;
`;

export const EmptyMapView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 105px;
  z-index: 10;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 285px;
  border-radius: 6px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
`;
