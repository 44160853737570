import Registry from '../../../utils/Registry';
import { ModalUiCodes } from '../../../stores/UiStore/Modal/ModalUiCodes';
import BillOfMaterialsModal from './BillOfMaterialsModal/BillOfMaterialsModal';
import CircuitTableModal from './CircuitTableModal/CircuitTableModal';
import ConceptDesignOptionModal from './ConceptDesignOptionModal/ConceptDesignOptionModal';
import UpsertInstallerAndCreateProjectModal from './UpsertInstallerAndCreateProjectModal/UpsertInstallerAndCreateProjectModal';
import SiteGroundSnowModal from './GroundSnowModal/SiteGroundSnowModal';
import InverterSelectionModal from './InverterSelectionModal/InverterSelectionModal';
import MissingPropertyModal from './MissingPropertiesModal/MissingPropertiesModal';
import PermitPackageModal from './PermitPackageModal/PermitPackageModal';
import ProjectPropertiesModal from './ProjectPropertiesModal/ProjectPropertiesModal';
import SiteTemperatureModal from './SiteTemperatureModal/SiteTemperatureModal';
import SiteOtherPropertiesModal from './SiteOtherPropertiesModal/SiteOtherPropertiesModal';
import SystemDatasheetOptionModal from './SystemDatasheetOptionModal/SystemDatasheetOptionModal';
import UploadImageModal from './UploadImageModal/UploadImageModal';
import WindExposureModal from './WindExposureModal/WindExposureModal';
import WindSpeedModal from './WindSpeedModal/WindSpeedModal';
import UploadCustomSiteImageryModal from './UploadCustomBaseImageryModal/UploadCustomBaseImageryModal';
import { QuickStartGuideModal } from './QuickStartGuideModal/QuickStartGuideModal';
import SteepSlopeModal from './SteepSlopeModal/SteepSlopeModal';
import LowSlopeModal from './LowSlopeModal/LowSlopeModal';

const modalRegistry = new Registry();

modalRegistry.add('upsert_installer_and_create_project_modal', UpsertInstallerAndCreateProjectModal);
modalRegistry.add('site_temperature_modal', SiteTemperatureModal);
modalRegistry.add('wind_exposure_modal', WindExposureModal);
modalRegistry.add('wind_speed_modal', WindSpeedModal);
modalRegistry.add('groundsnow_modal', SiteGroundSnowModal);
modalRegistry.add('site_other_properties_modal', SiteOtherPropertiesModal);
modalRegistry.add('project_properties_modal', ProjectPropertiesModal);
modalRegistry.add('circuit_table_modal', CircuitTableModal);
modalRegistry.add('permit_package_modal', PermitPackageModal);
modalRegistry.add('inverter_selection_modal', InverterSelectionModal);
modalRegistry.add('bill_of_materials_modal', BillOfMaterialsModal);
modalRegistry.add('concept_design_option_modal', ConceptDesignOptionModal);
modalRegistry.add('system_datasheet_option_modal', SystemDatasheetOptionModal);
modalRegistry.add('upload_image_modal', UploadImageModal);
modalRegistry.add('missing_properties_modal', MissingPropertyModal);
modalRegistry.add('quick_start_guide_modal', QuickStartGuideModal);
modalRegistry.add(ModalUiCodes.UploadCustomSiteImageryModal, UploadCustomSiteImageryModal);
modalRegistry.add('steep_slope_modal', SteepSlopeModal);
modalRegistry.add('low_slope_modal', LowSlopeModal);

export { modalRegistry };
