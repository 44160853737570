import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { ElectricalDesignStage } from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import { sectionTitle } from '../../SystemDesign/SectionTitle';
import type { IAcCoupledEnergyStorageSystemDefinitionData } from '../../../../domain/models/PvSystem/AcCoupledEnergyStorageSystems';
import {
  CardRow,
  CardWrapper,
  Description,
  DescriptionName,
  ElectricalDesignCard,
  Quantity,
  QuantityValue
} from './../CommonElectricalDesignRightMenuCards.styles';

interface IEnergyStorageAndBackupCardProps {
  readonly electricalDesignStage: ElectricalDesignStage;
}

const EnergyStorageAndBackupCard: React.FC<IEnergyStorageAndBackupCardProps> = (
  props: IEnergyStorageAndBackupCardProps
): ReactElement => {
  const { electricalDesignStage } = props;
  const handleEditClick = useCallback((): void => {
    electricalDesignStage.editInverterAndDcOptimizer();
  }, [electricalDesignStage]);

  const cardProps = {
    editable: true,
    title: sectionTitle('energy storage'),
    onEditClick: handleEditClick
  };

  return (
    <ElectricalDesignCard {...cardProps}>
      <CardWrapper>
        {electricalDesignStage.energyStorageAndBackup.map(
          (energyStorageSystemDefinition: IAcCoupledEnergyStorageSystemDefinitionData): ReactElement => (
            <CardRow key={energyStorageSystemDefinition.id}>
              <Quantity>
                <QuantityValue>
                  {electricalDesignStage.energyStorageAndBackupEquipmentCountByDefinition(
                    energyStorageSystemDefinition.id
                  )}
                </QuantityValue>
              </Quantity>
              <Description>
                <DescriptionName>{energyStorageSystemDefinition.name}</DescriptionName>
              </Description>
            </CardRow>
          )
        )}
      </CardWrapper>
    </ElectricalDesignCard>
  );
};

export default observer(EnergyStorageAndBackupCard);
