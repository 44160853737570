import {
  LyraDivider, LyraFormElementsDesignTool, LyraGrid, LyraModal
} from '@aurorasolar/lyra-ui-kit';
import type { OptionProps } from '@aurorasolar/lyra-ui-kit/lib/components/Grid';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import { RackSpacing } from '../../../../domain/models/MountingSystemDefinition/IConfiguration';
import { LayoutStrategy } from '../../../../domain/models/RoofTopArray/LayoutStrategy';
import {
  EOrientation, ERowAlignmentStrategy
} from '../../../../domain/typings';
import { useUiStore } from '../../../../stores/useStore';
import IconButton from '../../../components/IconButton/IconButton';
import CustomLayout from '../../../containers/Panels/CustomLayout/CustomLayout';
import type { LowSlopeViewModel } from '../../../../stores/UiStore/Modal/ViewModels/LowSlopeViewModal.ts/LowSlopeViewModal';
import Flexbox from '../../../components/Layout/Flexbox';
import Compass from '../../../components/Compass/Compass';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import {
  AzimuthContainer, ColumnLayout, RowLayout, Wrapper
} from './styles';

const { NumberField } = LyraFormElementsDesignTool.FormElementsDesignTool;

export const rowAlignmentStrategyOption = (
  labels: { [alignment in ERowAlignmentStrategy]: string },
  strategy: ERowAlignmentStrategy,
  icon: string
): OptionProps => ({
  name: labels[strategy],
  value: strategy,
  component: <IconButton icon={icon} text={labels[strategy]} width="33%" />
});

const LOW_SLOPE_ROW_ALIGNMENT_STRATEGY_OPTIONS = [
  rowAlignmentStrategyOption(
    LayoutStrategy.ROW_ALIGNMENT_LOW_SLOPE_LABELS,
    ERowAlignmentStrategy.MAXIMIZE_MODULE_QUANTITY,
    'maximized-modules'
  ),
  rowAlignmentStrategyOption(
    LayoutStrategy.ROW_ALIGNMENT_LOW_SLOPE_LABELS,
    ERowAlignmentStrategy.ALIGNED,
    'aligned-rows'
  ),
  rowAlignmentStrategyOption(
    LayoutStrategy.ROW_ALIGNMENT_LOW_SLOPE_LABELS,
    ERowAlignmentStrategy.STAGGERED,
    'staggered-rows'
  )
];

export const ORIENTATION_OPTIONS = [
  {
    name: LayoutStrategy.ORIENTATION_LABELS.PORTRAIT,
    value: EOrientation.PORTRAIT,
    component: <IconButton icon="portrait-icon-not-selected" text={LayoutStrategy.ORIENTATION_LABELS.PORTRAIT} />
  },
  {
    name: LayoutStrategy.ORIENTATION_LABELS.LANDSCAPE,
    value: EOrientation.LANDSCAPE,
    component: <IconButton icon="landscape-icon-not-selected" text={LayoutStrategy.ORIENTATION_LABELS.LANDSCAPE} />
  }
];

const LowSlopeModal: React.FC = (): ReactElement => {
  const { modal } = useUiStore();
  const lowSlopeViewModel = modal.viewModel as LowSlopeViewModel;

  return (
    <LyraModal.Modal
      $show={true}
      leftButtonLabel="BACK"
      rightButtonLabel="APPLY"
      title={lowSlopeViewModel.roofFaceName}
      rightButtonDisabled={!lowSlopeViewModel.canApplyChanges}
      onClickCloseButton={lowSlopeViewModel.closeModal}
      onClickLeftButton={lowSlopeViewModel.closeModal}
      onClickRightButton={lowSlopeViewModel.applyChanges}
    >
      <CustomLayout
        fontSize="12px"
        text="ORIENTATION"
        component={
          <LyraGrid.Grid
            options={ORIENTATION_OPTIONS}
            columns={3}
            selected={lowSlopeViewModel.layoutStrategy.dominantOrientationAsOption}
            onSelect={lowSlopeViewModel.layoutStrategy.setDominantOrientationAsOption}
          />
        }
      />

      <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />

      <CustomLayout
        fontSize="12px"
        text="ARRAY AZIMUTH"
        component={
          <RowLayout align="flex-start">
            <ColumnLayout width="50%">
              <RowLayout>
                <Wrapper>
                  <NumberField.NumberField
                    width="70px"
                    label="TILT ANGLE"
                    measure="°"
                    withQuantityArrows
                    limits={lowSlopeViewModel.tiltAngleLimits}
                    value={lowSlopeViewModel.tiltAngle}
                    onChange={lowSlopeViewModel.changeTiltAngle}
                    fractionDigits={2}
                    allowOutOfRange={false}
                  />
                </Wrapper>
                <Wrapper padding="12px">
                  <NumberField.NumberField
                    width="70px"
                    label="N° OF ROWS"
                    withQuantityArrows
                    limits={lowSlopeViewModel.numberOfTiersPerRackLimits}
                    value={lowSlopeViewModel.numberOfRowsInRack}
                    onChange={lowSlopeViewModel.changeNumberOfRows}
                    allowOutOfRange={false}
                  />
                </Wrapper>
              </RowLayout>
            </ColumnLayout>
            <ColumnLayout width="50%">
              <AzimuthContainer>
                <Compass
                  angle={lowSlopeViewModel.azimuthValue as number}
                  onChange={lowSlopeViewModel.changeAzimuthValue}
                  snaps={lowSlopeViewModel.polygonAzimuth()}
                />
              </AzimuthContainer>
            </ColumnLayout>
          </RowLayout>
        }
      />

      <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
      <CustomLayout
        fontSize="12px"
        text="ROW ALIGNMENT"
        component={
          <LyraGrid.Grid
            options={LOW_SLOPE_ROW_ALIGNMENT_STRATEGY_OPTIONS}
            columns={3}
            selected={lowSlopeViewModel.layoutStrategy.rowAlignmentStrategyAsOptionForLowSlope}
            onSelect={lowSlopeViewModel.layoutStrategy.setRowAlignmentStrategyAsOption}
            selectedOptionColor={EThemeComponentColor.BLUE}
          />
        }
      />

      <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />

      <CustomLayout
        fontSize="12px"
        text="AUTO ROW SPACING"
        component={
          <Flexbox align="center">
            <ColumnLayout width="auto">
              <ButtonGroup
                width="75px"
                height="24px"
                withBorder={true}
                buttonBackground={'white'}
                options={RackSpacing.AUTOMATIC_SPACING_GRID_OPTIONS}
                onSelect={(value: string | number): void => {
                  lowSlopeViewModel.changeAutomaticRackSpacing(
                    value === RackSpacing.AUTOMATIC_SPACING_YES_OPTION.value
                  );
                }}
                selected={String(lowSlopeViewModel.isRackSpacingAuto)}
                selectedOptionColor={EThemeComponentColor.BLUE}
              />
            </ColumnLayout>
            <ColumnLayout width="50%" className="ml-xs">
              {!lowSlopeViewModel.isRackSpacingAuto && (
                <NumberField.NumberField
                  label="ROW SPACING"
                  onChange={lowSlopeViewModel.changeRackSpacingExactValueInInches}
                  limits={RackSpacing.EXACT_VALUE_LIMIT_IN_INCHES}
                  value={lowSlopeViewModel.rackSpacingExactValueInInches}
                  measure="in"
                  width="100px"
                  fractionDigits={2}
                />
              )}
            </ColumnLayout>
          </Flexbox>
        }
      />
      {lowSlopeViewModel.isRackSpacingAuto && (
        <>
          <LyraDivider.Divider direction="horizontal" color={EThemeComponentColor.GRAY_200} />
          <CustomLayout
            text="SHADE MONTH"
            fontSize="12px"
            padding="15px 0 0"
            component={
              <ButtonGroup
                width="75px"
                height="24px"
                withBorder={true}
                buttonBackground={'white'}
                options={RackSpacing.DESIGN_MONTH_OPTIONS}
                onSelect={lowSlopeViewModel.changeAutoRackSpacingDesignMonth}
                selected={lowSlopeViewModel.rackSpacingDesignMonthForAutomaticCalculation}
              />
            }
          />
        </>
      )}
    </LyraModal.Modal>
  );
};

export default observer(LowSlopeModal);
