import { runInAction } from 'mobx';
import filter from 'lodash/filter';
import type { RoofFace } from '../../../../domain/models/SiteDesign/RoofFace';
import type DomainStore from '../../../DomainStore/DomainStore';
import type { SurfaceRoofFaceViewModel } from '../../../UiStore/Properties/RoofProperties/ViewModels/SurfaceRoofFaceViewModel';
import { handleApiError } from '../../../../utils/helpers';
import { getRootStore } from '../../../RootStoreInversion';
import { SURFACE_CODE } from '../../../../ui/containers/RoofFaceProperties/constants';
import { DesignService } from '../../../../infrastructure/services/api/DesignService';
import type { IRoofFaceSupplementalData } from '../../../../domain/entities/SiteDesign/RoofFaceSupplementalData';

export const clearRoofSurface = (domainStore: DomainStore, updatedRoofFace: RoofFace): void => {
  const supplementalData = domainStore.project.supplementalData;
  if (supplementalData?.roofFacesSupplementalData) {
    // Primary: Clearing supplemental data for the currently updated roof face
    // Secondary: Removing supplemental data for roof faces no longer present in the project
    const idsOfAllRoofFaces = domainStore.allRoofFaces.map((roofFace: RoofFace): string =>
      roofFace.serverId.toLowerCase()
    );
    supplementalData.roofFacesSupplementalData = filter(
      supplementalData.roofFacesSupplementalData,
      (item: IRoofFaceSupplementalData): boolean =>
        idsOfAllRoofFaces.includes(item.serverId.toLowerCase())
        && item.serverId.toLowerCase() !== updatedRoofFace.serverId.toLowerCase()
    );
  }

  new DesignService()
    .getRoofSurfaceTypeOptions(updatedRoofFace.slope)
    .then((surfaceTypesList) => {
      const isSurfaceCompatibleWithNewSlope = surfaceTypesList.some((type) => {
        return updatedRoofFace.surface === type.attributes.value;
      });

      if (isSurfaceCompatibleWithNewSlope === false) {
        runInAction((): void => {
          updatedRoofFace.surface = '';
          domainStore.addOrUpdateRoofFace(updatedRoofFace);
          (
            getRootStore().roofFaceProps.roofFacePropertyViewModelsRefs[SURFACE_CODE] as SurfaceRoofFaceViewModel
          )?.clear();
        });
      }
    })
    .catch(handleApiError('Failed to fetch surface types data'));
};
