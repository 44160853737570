import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import LineHr from '../../../../ui/components/LineHr';
import {
  HR_TOOLBAR_WIDTH, HR_MARGIN
} from '../../../../ui/components/Layout/SolarLayout/constants';
import PanningButton from '../ProjectToolbar/Tools/Panning';
import { MainToolbarWrapper } from '../Shared/styles';
import config, { UI_MODE } from '../../../../config/config';
import AddModuleButton from './Tools/AddModule';
import ChangeOrientationButton from './Tools/ChangeOrientation';
import RemoveModuleButton from './Tools/RemoveModule';
import SelectionButton from './Tools/Selection';
import SetBackButton from './Tools/SetBack';
import StringingButton from './Tools/Stringing';

const MainToolbar = observer(
  (): ReactElement => (
    <MainToolbarWrapper data-testid="mainToolbar">
      <SelectionButton />
      <PanningButton />
      {config.featureFlag.uiMode !== UI_MODE.AURORA && (
        <>
          <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
          <SetBackButton />
          <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
          <AddModuleButton />
          <RemoveModuleButton />
          <ChangeOrientationButton />
          <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
          <StringingButton />
          <LineHr width={HR_TOOLBAR_WIDTH} margin={HR_MARGIN} />
        </>
      )}
    </MainToolbarWrapper>
  )
);

export default MainToolbar;
