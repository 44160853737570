import {
  action, observable
} from 'mobx';
import type { ISeriesStringConfigurationData } from '../../../../../domain/entities/StringingOption/IStringingOptionData';
import type { Stringing } from '../../../../../domain/graphics/stringing/Stringing';
import type { IInverterInformation } from '../../../../../domain/models/SupplementalData/IInverterInfo';
import type { ElectricalDesignStage } from '../../../../../domain/stages/DesignStages/ElectricalDesignStage';
import type { IBasePanelViewModelDependencies } from '../BasePanelViewModel';
import { BasePanelViewModel } from '../BasePanelViewModel';
import StringingService from '../../../../../services/stringing/stringingService';

export interface IMpptAndMultiInverterSelectionPanelViewModelDependencies extends IBasePanelViewModelDependencies {
  readonly inverters: IInverterInformation[];
  readonly string: Stringing;
}

export class MpptAndMultiInverterSelectionPanelViewModel extends BasePanelViewModel {
  override readonly propCodeUI: string = 'MPPT_and_multi_inverter_selection_panel';

  @observable
  showSlides: boolean = false;

  @observable
  inverters!: IInverterInformation[];

  @observable
  inverterSelected?: IInverterInformation;

  private readonly stringing: Stringing;
  private readonly currentStage: ElectricalDesignStage;

  constructor(dependencies: IMpptAndMultiInverterSelectionPanelViewModelDependencies) {
    super(dependencies);
    this.stringing = dependencies.string;
    this.setInverters(dependencies.inverters);
    this.currentStage = this.designWorkspace.stageManager!.currentStage as ElectricalDesignStage;
  }

  selectChannel = (channel: ISeriesStringConfigurationData, inverter: IInverterInformation): void => {
    if (inverter.inverterId) {
      this.stringing.setInverterId(inverter.inverterId);
    }
    const mpptId = channel.mppt;
    this.stringing.setMpptId(mpptId);

    this.closePanel();

    this.currentStage.setStringingOption(this.stringing);
  };

  @action.bound
  setInverters = (inverters: IInverterInformation[]): void => {
    this.inverters = inverters;
  };

  @action.bound
  selectInverterWithMppts = (inverter?: IInverterInformation): void => {
    this.showSlides = !this.showSlides;
    if (inverter) {
      this.inverterSelected = inverter;
      if (inverter.inverterId) {
        this.stringing.setInverterId(inverter.inverterId);
      }
    }
  };

  @action.bound
  onCloseMPPTModal = (): void => {
    this.stringing.unselect();
    this.currentStage.closeMPPTModal();
  };

  @action.bound
  selectInverterWithoutMppts = (inverter?: IInverterInformation): void => {
    if (inverter?.inverterId) {
      this.stringing.setInverterId(inverter.inverterId);
      if (inverter.mppts?.length === 1) {
        this.stringing.setMpptId(inverter.mppts[0].mppt);
      }
      this.closePanel();
    }
  };

  @action.bound
  closePanel(): void {
    this.clearPanel();
    StringingService.resolveMpptOrInverterSelectionPromise!();
    this.currentStage.showSummaryPanel();
  }

  @action.bound
  mpptHasStringConnected(channel: ISeriesStringConfigurationData, inverter: IInverterInformation): boolean {
    return StringingService.getStrings().some(
      (stringing: Stringing): boolean =>
        stringing.getMpptId() === channel.mppt && inverter.inverterId === stringing.getInverterId()
    );
  }
}
