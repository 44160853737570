import type { RoofFace } from '../../../domain/models/SiteDesign/RoofFace';
import type { IBuildingData } from '../../../domain/entities/SiteDesign/Building';
import { BuildingsMapping } from '../../../infrastructure/mapping/BuildingsMapping';
import type DomainStore from '../../../stores/DomainStore/DomainStore';
import type { RoofProtrusionStore } from '../../UiStore/Properties/RoofProtrusion/RoofProtrusionStore';
import type { IBaseCommandDependencies } from '../IServiceBus';
import type EditorStore from '../../EditorStore/EditorStore';
import type { Vertex } from '../../../domain/graphics/Vertex';
import type { Building as BuildingDTO } from '../../../domain/models/SiteDesign/Building';
import type { RoofProtrusion } from '../../../domain/models/SiteDesign/RoofProtrusion';
import type { RectangleProtrusion } from '../../../domain/models/SiteDesign/RectangleProtrusion';
import { BaseCommand } from './Command';

export interface IImport3DModelCommandDependencies extends IBaseCommandDependencies {
  readonly buildingsData: IBuildingData[];
  readonly domain: DomainStore;
  readonly editor: EditorStore;
  readonly roofProtrusion: RoofProtrusionStore;
}

export class Import3DModelFromWizard extends BaseCommand {
  private readonly buildingsData: IBuildingData[];
  private readonly domain: DomainStore;
  private readonly editor: EditorStore;
  private readonly roofProtrusion: RoofProtrusionStore;

  constructor(dependencies: IImport3DModelCommandDependencies) {
    super();
    this.buildingsData = dependencies.buildingsData;
    this.domain = dependencies.domain;
    this.editor = dependencies.editor;
    this.roofProtrusion = dependencies.roofProtrusion;
  }

  async execute(): Promise<void> {
    const buildings: BuildingDTO[] = this.buildingsData.map(
      (buildingData: IBuildingData): BuildingDTO => new BuildingsMapping().convertEntityToDomainModel(buildingData)
    );

    this.domain.project.site.setBuildings(buildings);

    buildings.forEach((building: BuildingDTO): void => {
      if (building.roofOutline) {
        this.editor.addOrUpdateObject(building.roofOutline.mesh);
      }
      building.roofFaces.forEach((roofFace: RoofFace): void => {
        this.editor.addOrUpdateObject(roofFace.mesh);
        roofFace.protrusions.forEach((protrusion: RoofProtrusion): void => {
          this.roofProtrusion.createProtrusionViewModel('rectangular', protrusion as RectangleProtrusion, roofFace);
        });
      });

      // Set `hasEverBeenValidAfterUserInteraction` field to each vertex so that it'd be possible to
      // disable building movement validation when the project is in an invalid state from the start.
      const allEdgeVertices: Vertex[] = [
        ...(building?.roofFaces ?? []).flatMap((roofFace: RoofFace) => roofFace.boundary.vertices),
        ...(building?.roofOutline?.boundary.vertices ?? [])
      ];

      for (const vertex of allEdgeVertices) {
        vertex.hasEverBeenValidAfterUserInteraction = vertex.isValidCurrentPosition(this.editor);
      }
    });
  }
}
