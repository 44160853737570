import type { ReactElement } from 'react';
import { createElement } from 'react';

/**
 * Some components need empty html children in order to apply styles
 * this Hooks generate it
 */
export function useEmptyChildren(many: number, HTMLType: string = 'span'): ReactElement[] {
  const elements: ReactElement[] = [];

  for (let itr = 0; itr < many; itr++) {
    elements.push(
      createElement(HTMLType, {
        key: itr
      })
    );
  }

  return elements;
}
