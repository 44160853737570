import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import type { IDefaultTheme } from '@aurorasolar/lyra-ui-kit';
import { LyraLayout } from '@aurorasolar/lyra-ui-kit';
import type {
  Attributes, BaseProps, ReactElement, ReactNode
} from 'react';
import React, {
  useCallback, useState
} from 'react';
import type { PropsTheme } from 'styled-components';
import styled from 'styled-components';
import type { Color } from '../../../../domain/typings';
import IconMapper from '../../../components/Icons';
import config, { UI_MODE } from '../../../../config/config';

interface IToolProps extends BaseProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title?: string;
  icon?: string | ReactElement;
  // Shows whether button has sub menus or not
  submenu?: boolean;
  displaySubmenu?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  selected?: boolean;
  id?: number;
}

const ToolbarButtonWrapper = styled.div`
  position: relative;
  margin-bottom: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 8 : 0)}px;
`;

const toolButtonBackground = (theme: IDefaultTheme): Color =>
  config.featureFlag.uiMode !== 'aurora' ? theme.colors.tertiary : theme.colors.whiteBg;

const toolButtonColor = (theme: IDefaultTheme): Color =>
  config.featureFlag.uiMode !== 'aurora' ? theme.colors.secondaryFont : theme.colors.black;

const selectedToolButtonBackground = (theme: IDefaultTheme): Color =>
  config.featureFlag.uiMode !== 'aurora' ? theme.colors.secondaryFont : theme.colors.quaternary;

const ToolbarButtonStyle = styled.div<PropsTheme<IToolProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }: PropsTheme<IToolProps>): Color => toolButtonBackground(theme) || ''};
  color: ${({ theme }: PropsTheme<IToolProps>): Color => toolButtonColor(theme) || ''};
  border: none;
  border-radius: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 3 : 0)}px;
  position: relative;
  width: ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 38 : 32)}px;
  height: 32px;
  margin: 0 ${(): number => (config.featureFlag.uiMode !== UI_MODE.AURORA ? 4 : 1)}px;
  box-sizing: border-box;

  ${({ disabled }: PropsTheme<IToolProps>): string =>
    disabled
      ? `
  opacity: 0.25;
  cursor: not-allowed;
    `
      : ''}
  ${({
    selected, theme
  }: PropsTheme<IToolProps>): string =>
    selected
      ? ` cursor: pointer;
    background-color: ${selectedToolButtonBackground(theme)};
    svg .fill-hover {
      fill: ${theme.colors.whiteBg};
    }
    svg .stroke-hover {
      stroke: ${theme.colors.whiteBg};
    }
    svg .stroke-hover-inv {
      stroke: ${theme.colors.secondaryFont};
    }
  `
      : ''}
  ${({
    submenu, theme
  }: PropsTheme<IToolProps>): string =>
    !submenu
      ? `
  &:hover:enabled {
    cursor: pointer;
    background-color: ${theme.colors.secondaryFont};
    svg .fill-hover {
      fill: ${theme.colors.whiteBg};
    }
    svg .stroke-hover {
      stroke: ${theme.colors.whiteBg};
    }
    svg .stroke-hover-inv {
      stroke: ${theme.colors.secondaryFont};
    }
  }
  `
      : ''}
`;

const ToolbarButton = observer((props: IToolProps): ReactElement => {
  const {
    id, icon, title, submenu, displaySubmenu, children, disabled, selected, onClick, ...rest
  } = props;

  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      if (!disabled) {
        onClick?.(event);
      }
    },
    [onClick, disabled]
  );

  const showTooltip = !disabled && !submenu;
  const tooltipContent = showTooltip ? title : null;
  const tooltipPosition = config.featureFlag.uiMode !== 'aurora' ? 'tooltip-right' : 'tooltip-left';
  const buttonClassName = clsx({
    [tooltipPosition]: showTooltip
  });

  const onMouseOver = (): void => {
    if (!disabled) {
      setShowSubmenu(true);
    }
  };

  const onMouseLeave = (): void => {
    if (!disabled) {
      setShowSubmenu(false);
    }
  };

  const toolbarButtonProps = {
    disabled,
    selected,
    onClick: handleClick,
    className: buttonClassName,
    submenu,
    ...(rest as Attributes)
  };

  return (
    <ToolbarButtonWrapper onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <ToolbarButtonStyle {...toolbarButtonProps} data-tooltip={tooltipContent}>
        <IconMapper name={icon as string} />
      </ToolbarButtonStyle>

      <LyraLayout.SubTool visible={submenu && showSubmenu}>
        {submenu && showSubmenu ? children : <></>}
      </LyraLayout.SubTool>
    </ToolbarButtonWrapper>
  );
});

export default ToolbarButton;
