import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React from 'react';
import {
  LyraModal, LyraButtons, LyraIcon, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import type { ButtonOption } from '@aurorasolar/lyra-ui-kit/lib/components/Buttons/BigButtonIcon';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { useUiStore } from '../../../../stores/useStore';
import type { QuickStartGuideViewModel } from '../../../../stores/UiStore/Modal/ViewModels/QuickStartGuideViewModel/QuickStartGuideViewModel';
import {
  BigButtonContainer,
  BigButtonsContainer,
  ButtonsDivider,
  HalfPanel,
  LyraPanelMessage,
  ModalWrapper
} from './QuickStartGuideModal.style';

const import3dModelButtonOptions: ButtonOption = {
  name: (
    <LyraIcon.Icon
      style={{
        width: '80px',
        height: '80px',
        marginBottom: '20px'
      }}
      name="import-3D"
    />
  ),
  caption: 'Import 3D Model'
};

const startTracingButtonOptions: ButtonOption = {
  name: (
    <LyraIcon.Icon
      style={{
        width: '80px',
        height: '80px',
        marginBottom: '20px'
      }}
      name="trace-roof"
    />
  ),
  caption: 'Trace Roof'
};

export const QuickStartGuideModal = observer((): ReactElement => {
  const { modal } = useUiStore();
  const quickStartGuideViewModel = modal.viewModel as QuickStartGuideViewModel;

  const modalProps = {
    title: 'Rooftop Array',
    showFooter: false,
    onClickCloseButton: (): void => {
      modal.closeModal();
    },
    color: EThemeComponentColor.PURPLE
  };

  return (
    <LyraModal.Modal $show={true} {...modalProps}>
      <ModalWrapper>
        <LyraTypography.Heading align="center" type="h2" fontWeight="normal">
          How are you going to define your roof faces?
        </LyraTypography.Heading>
        <BigButtonsContainer>
          <HalfPanel>
            <BigButtonContainer>
              <LyraButtons.BigButtonIcon
                fullWidth={false}
                withCaption={true}
                onClick={(response: MouseEvent): void => {
                  modal.closeModal();
                  quickStartGuideViewModel.openImport3dModelWizard();
                }}
                options={import3dModelButtonOptions}
              />
            </BigButtonContainer>
            <LyraPanelMessage
              label={'Import a 3D model provided by a third-party vendor like RoofOrders.com or Eagleview.'}
            />
          </HalfPanel>
          <ButtonsDivider />
          <HalfPanel>
            <BigButtonContainer>
              <LyraButtons.BigButtonIcon
                height="90px"
                fullWidth={false}
                withCaption={true}
                onClick={(response: MouseEvent): void => {
                  modal.closeModal();
                  quickStartGuideViewModel.startTracingRoofFace();
                }}
                options={startTracingButtonOptions}
              />
            </BigButtonContainer>
            <LyraPanelMessage label={'Begin by tracing the building outline, then trace individual roof faces.'} />
          </HalfPanel>
        </BigButtonsContainer>
      </ModalWrapper>
    </LyraModal.Modal>
  );
});
