import { Vertex } from '../../domain/graphics/Vertex';
import type {
  Vector2D, Vector3D
} from '../../domain/typings';

export class VerticesMapping {
  convertDomainModelToEntity3d(vertices: Vertex[]): Vector3D[] {
    // removing the last repeated vertex
    const uniqueVertices = vertices.slice(0, -1);
    return uniqueVertices.map(
      ({
        x, y, z
      }: Vertex): Vector3D => ({
        x,
        y,
        z
      })
    );
  }

  convertDomainModelToEntity2d(vertices: Vertex[]): Vector2D[] {
    // removing the last repeated vertex
    const uniqueVertices = vertices.slice(0, -1);
    return uniqueVertices.map(
      ({
        x, y
      }: Vertex): Vector2D => ({
        x,
        y
      })
    );
  }

  convertEntityToDomainModel(vectors: Vector3D[]): Vertex[] {
    const parsedVertices = vectors.map((vec: Vector3D): Vertex => Vertex.fromXyzValuesObject(vec));

    // adding the first vertex as last one in order to close the polygon
    parsedVertices.push(parsedVertices[0]);
    return parsedVertices;
  }
}
