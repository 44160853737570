import isEmpty from 'lodash/isEmpty';
import type { Option } from '@aurorasolar/lyra-ui-kit';
import {
  action, computed, observable, runInAction
} from 'mobx';
import { MathUtils as ThreeMath } from 'three';
import { handleApiError } from '../../../../../utils/helpers';
import type { IRoofFaceSupplementalData } from '../../../../../domain/entities/SiteDesign/RoofFaceSupplementalData';
import { SURFACE_CODE } from '../../../../../ui/containers/RoofFaceProperties/constants';
import { BaseRoofFaceViewModel } from '../BaseRoofFaceViewModel';
import { UpdateRoofFacePropertyKey } from '../../../../ServiceBus/Commands/UpdateRoofFacePropertyCommand';
import { DesignService } from '../../../../../infrastructure/services/api/DesignService';
import type {
  IOption, BaseAttributes
} from '../../../../../domain/models/SiteDesign/IOption';
import type { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import { SupplementalProjectData } from '../../../../../domain/models/SiteDesign/SupplementalProjectData';
import {
  surfaceKeyToLabelMap,
  surfaceMaterialToParentSurfaceKey,
  surfaceKeyToSurfaceMaterial,
  surfaceKeyToSurfaceType
} from './constants';

export class SurfaceRoofFaceViewModel extends BaseRoofFaceViewModel {
  readonly id: string = ThreeMath.generateUUID();
  readonly propUICode: string = SURFACE_CODE;

  @observable
  private currentSurface?: IOption<BaseAttributes>;
  @observable
  private currentLabelSurface?: string;
  @observable
  surfaceTypes: Option[] = observable([]);
  @observable
  private currentMaterial?: IOption<BaseAttributes>;
  @observable
  private currentLabelMaterial?: string;
  @observable
  surfaceMaterials: Option[] = observable([]);

  @observable
  private listOfSurfaces?: IOption<BaseAttributes>[];
  @observable
  private listOfMaterials?: IOption<BaseAttributes>[];

  private readonly designService = new DesignService();

  get property(): UpdateRoofFacePropertyKey {
    return UpdateRoofFacePropertyKey.Surface;
  }

  @action.bound
  clear(): void {
    this.currentLabelSurface = '';
    this.value = '';
  }

  @computed
  override get currentLabel(): string {
    const surface = this.roofFaceProps?.currentSelectedRoofFaces[0]?.surface;
    if (surface && surfaceKeyToLabelMap[surface]) {
      return surfaceKeyToLabelMap[surface];
    }
    return '';
  }

  @computed
  get currentSurfaceType(): string {
    const surface = this.roofFaceProps?.currentSelectedRoofFaces[0]?.surface;
    if (surface && surfaceKeyToSurfaceType[surface]) {
      return surfaceKeyToSurfaceType[surface];
    }
    return '';
  }

  @computed
  get currentSurfaceMaterial(): string {
    const surface = this.roofFaceProps?.currentSelectedRoofFaces[0]?.surface;
    if (surface && surfaceKeyToSurfaceMaterial[surface]) {
      return surfaceKeyToSurfaceMaterial[surface];
    }
    return '';
  }

  @action.bound
  async loadSeletedSurfaceSubTypes(): Promise<void> {
    const surface = this.roofFaceProps?.currentSelectedRoofFaces[0]?.surface;
    if (this.currentSurfaceMaterial) {
      this.loadSurfaceSubTypes(surfaceMaterialToParentSurfaceKey[surface]);
    }
  }

  @action.bound
  async loadSurfaceTypes(): Promise<void> {
    const data = await this.designService
      .getRoofSurfaceTypeOptions(this.roofFace.slope)
      .catch(handleApiError('Failed to fetch surface types data'));

    runInAction((): void => {
      this.listOfSurfaces = data;
      this.surfaceTypes = this.mapToItemOption(data);
    });

    if (!isEmpty(this.onSelectSurfaceType)) {
      this.loadSurfaceSubTypes(this.onSelectSurfaceType?.attributes.name);
    }
  }

  @action.bound
  async loadSurfaceSubTypes(surface: string | undefined): Promise<void> {
    if (surface) {
      const data = await this.designService
        .getRoofSurfaceSubTypeOptions(surface)
        .catch(handleApiError('Failed to fetch surface sub types data'));
      runInAction((): void => {
        this.listOfMaterials = data;
        this.surfaceMaterials = this.mapToItemOption(data);
      });
    }
  }

  @computed
  get onSelectSurfaceType(): IOption<BaseAttributes> | null {
    return this.getCurrentRoofFaceSupplementalData()?.surfaceType ?? null;
  }

  @computed
  get onSelectMaterial(): IOption<BaseAttributes> | null {
    return this.getCurrentRoofFaceSupplementalData()?.surfaceSubType ?? null;
  }

  @computed
  get getNameSurface(): string {
    return this.currentLabelSurface + (this.currentLabelMaterial ? ` / ${this.currentLabelMaterial}` : '');
  }

  @action.bound
  setCurrentSurfaceType(value: string): void {
    const surface: IOption<BaseAttributes> | undefined = this.listOfSurfaces?.find(
      (surfacetype: IOption<BaseAttributes>): boolean => surfacetype.attributes.value === value
    );
    if (surface) {
      this.currentSurface = surface;
      this.currentLabelSurface = surface.attributes.name;
      this.surfaceMaterials = [];
      this.loadSurfaceSubTypes(surface.attributes.name);
      this.changeValue(this.currentSurface.attributes.value);
    }
  }

  @action.bound
  setCurrentMaterial(material: string): void {
    const surfaceMaterial = this.listOfMaterials?.find(
      (surfMaterial: IOption<BaseAttributes>): boolean => surfMaterial.attributes.value === material
    );
    if (surfaceMaterial) {
      this.currentMaterial = surfaceMaterial;
      this.currentLabelMaterial = surfaceMaterial.attributes.name;
      this.changeValue(this.currentMaterial.attributes.value.trim());
    }
  }

  override saveProperty(): void {
    if (this.currentSurface) {
      this.saveSurfaceType(this.currentSurface);
    }
    if (this.currentMaterial) {
      this.saveSurfaceSubType(this.currentMaterial);
    }
    super.saveProperty();
  }

  override changeValue(value: RoofFace[keyof RoofFace]): void {
    super.changeValue(value);
  }

  private saveSurfaceType(surfaceType: IOption<BaseAttributes>): void {
    const project = this.getDomain().project;
    if (!project.supplementalData) {
      project.supplementalData = new SupplementalProjectData();
    }
    if (!project.supplementalData.roofFacesSupplementalData) {
      project.supplementalData.roofFacesSupplementalData = [];
    }
    const currentRoofFaceData: IRoofFaceSupplementalData | undefined = this.getCurrentRoofFaceSupplementalData();
    if (currentRoofFaceData) {
      currentRoofFaceData.surfaceType = surfaceType;
      currentRoofFaceData.surfaceSubType = undefined;
    } else {
      project.supplementalData.roofFacesSupplementalData.push({
        serverId: this.roofFaceProps.currentSelectedRoofFaces[0].serverId,
        surfaceType
      });
    }
  }

  private saveSurfaceSubType(surfaceSubType: IOption<BaseAttributes>): void {
    const currentRoofFaceData: IRoofFaceSupplementalData | undefined = this.getCurrentRoofFaceSupplementalData();
    if (currentRoofFaceData) {
      currentRoofFaceData.surfaceSubType = surfaceSubType;
    }
  }

  private mapToItemOption(options: IOption<BaseAttributes>[]): Option[] {
    return options.map(
      (option: IOption<BaseAttributes>): Option => ({
        name: option.attributes.name,
        value: option.attributes.value
      })
    );
  }
}
