import React, { BaseProps, ReactElement } from 'react';
import { LeftIcon } from '../LeftIcon';
import { SiteStructureItemContainer } from '../styles';
import { ColorsFactory } from '../ColorsFactory';

export type RoofStoryProps = BaseProps & {
  id?: string;
  level?: number;
};

const RoofStory = (props: RoofStoryProps): ReactElement => {
  const { id, className = '', styles = {}, level = 1 } = props;
  const iconName = 'icon-level-' + level.toString().replace('.', '-');

  // Fallback to default color
  // in case no such level found.
  const bgColor = ColorsFactory[level] || ColorsFactory[1];

  return (
    <SiteStructureItemContainer key={id} className={className} styles={styles}>
      <LeftIcon icon={iconName} bgColor={bgColor} />
      {`Level ${level}`}
    </SiteStructureItemContainer>
  );
};

export { RoofStory };
