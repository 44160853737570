import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import useUiStore from '../../../stores/useStore';
import { wizardRegistry } from './registry';

const WizardModal = observer((): ReactElement => {
  const { wizard } = useUiStore();
  // Modal to be rendered
  const ModalUI = wizard.hasSteps && wizardRegistry.get(wizard.currentViewModel.propCodeUI);

  return <>{ModalUI && <ModalUI />}</>;
});

export default WizardModal;
