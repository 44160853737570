import type {
  Color, PropsTheme
} from '@aurorasolar/lyra-ui-kit';
import {
  LyraFontFamily, LyraIcon
} from '@aurorasolar/lyra-ui-kit';
import type { PropsWithTheme } from '@aurorasolar/lyra-ui-kit/lib/components/Button';
import styled from 'styled-components';
import config, { UI_MODE } from '../../../../config/config';

const { H4 } = LyraFontFamily.FontFamily;

type Props = PropsWithTheme & {
  active?: boolean;
};

type IconProps = {
  /**
   * Name prefixed with '$' so that styled-components do not pass it down to a React component.
   * @see https://styled-components.com/docs/api#transient-props
   * @see https://aurorasolar.atlassian.net/browse/LYRA-5168
   */
  $active: boolean;
};

export const EquipmentPanelContainer = styled.div`
  width: 240px;
  border-radius: 3px;
  border: solid 1px ${({ theme }: PropsWithTheme): Color => theme.colors.gray2};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.newGray2};
  position: absolute;
  top: 72px;
  right: 5px;
  padding: 10px;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scrollbar-width: none;
  max-height: calc(100% - 100px);
`;

export const SiteEquipmentFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.white};
  border-top: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grayColor6};
`;

export const LocationHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }: Props): Color =>
    config.featureFlag.uiMode === UI_MODE.AURORA ? theme.colors.white : theme.colors.primary};
  margin-bottom: ${(): number => (config.featureFlag.uiMode === UI_MODE.AURORA ? 0 : 5)}px;
  padding: ${(): number => (config.featureFlag.uiMode === UI_MODE.AURORA ? 10 : 5)}px 0;
`;

export const LocationText = styled.span`
  font-size: ${(): number => (config.featureFlag.uiMode === UI_MODE.AURORA ? 14 : 12)}px;
  font-weight: bold;
  color: ${({ theme }: PropsWithTheme): Color =>
    config.featureFlag.uiMode === UI_MODE.AURORA ? theme.colors.black : theme.colors.highlight};
  text-indent: 12px;
`;

export const InverterItemWrapper = styled.div`
  border-radius: 4px;
  border: solid 2px ${({ theme }: PropsWithTheme): Color => theme.colors.newGray3};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  padding: 0px 15px 0px 12px;
  flex-grow: 1;
  overflow-y: auto;
`;

export const AuroraInverterItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  border: none;
  padding: 0px 15px 0px 12px;
  input[type='checkbox'] {
    & ~ label {
      left: -7px;
    }
    &:checked ~ label {
      &::before {
        background-color: ${({ theme }: PropsTheme): Color => theme.colors.black};
        opacity: 1;
      }
      &::after {
        background-color: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
        top: 1px;
      }
    }
  }
`;

export const InverterItem = styled.div`
  border-bottom: ${({ theme }: PropsWithTheme): Color =>
    theme.auroraMode ? 'none' : `solid 1px ${theme.colors.newGray3}`};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  &:last-child {
    border-bottom: none;
  }
`;

export const IconView = styled.div`
  position: relative;
`;

export const MarkerIcon = styled(LyraIcon.Icon)<IconProps>`
  width: 35px;
  height: 39px;
  path:last-child {
    fill: ${({ $active }: IconProps): string => ($active ? '#52b8b8' : '#9e9e9e')};
  }
`;

export const TextView = styled.div`
  margin-left: 8px;
`;

export const InverterDescription = styled(H4)`
  font-size: 13px !important;
  margin-top: 5px;
`;

export const InverterTitle = styled(H4)`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: -0.46px;
  text-align: center;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }: PropsWithTheme): Color => theme.colors.whiteBg};
`;

export const ButtonView = styled.div`
  margin-top: 15px;
  display: flex;
`;

export const StatusButton = styled.div<Props>`
  width: 67px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 4px;
  border: ${({
    active, theme
  }: Props): string =>
    active ? `2px solid ${theme.colors.main1}` : `1px solid ${theme.colors.tertiary}`};
  background-color: ${({ theme }: PropsWithTheme): Color => theme.colors.highlight};
  font-size: 13px;
  box-shadow: ${({ active }: Props): string => (active ? 'rgba(0, 0, 0, 0.4) 0 2px 4px 0' : 'none')};
  padding: ${({ active }: Props): string => (active ? '0' : '1px')};
  cursor: pointer;
  &:hover {
    border: solid 2px ${({ theme }: PropsWithTheme): Color => theme.colors.main1};
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px 0;
    padding: 0;
  }
`;

export const StatusToggleContainer = styled.div`
  & > div {
    display: flex;
    flex-direction: row-reverse;

    & > label {
      margin-left: 8px;
      font-size: 12px;
    }
  }
`;

export const TodoItemWrapper = styled.div`
  margin-top: 24px;
`;

export const TodoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TodoIcon = styled(LyraIcon.Icon)``;

export const TodoText = styled(H4)`
  margin-left: 5px;
`;
