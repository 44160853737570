import {
  observer, useObserver
} from 'mobx-react-lite';
import type {
  BaseProps, ReactElement
} from 'react';
import * as React from 'react';
import useStore from '../../../stores/useStore';
import Counter from '../../../ui/components/Counter';
import { ZOOM_LIMIT } from '../../../stores/EditorStore/constants';

const ZoomControls = observer((props: BaseProps): ReactElement => {
  const { editor } = useStore();

  return useObserver(
    (): ReactElement => (
      <Counter
        label='Zoom'
        min={ZOOM_LIMIT.lower}
        max={ZOOM_LIMIT.upper}
        value={Number(editor.currentZoom.toFixed(2))}
        plus={editor.zoomIn}
        minus={editor.zoomOut}
      />
    )
  );
});

export default ZoomControls;
