import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import * as React from 'react';
import useStore from '../../../stores/useStore';
import { modalRegistry } from './registry';

const Modal: React.FC = (): ReactElement => {
  const { modal } = useStore();
  // Modal to be rendered
  const ModalUI = modal.viewModel && modalRegistry.get(modal.viewModel.propCodeUI);
  return <>{ModalUI && <ModalUI />}</>;
};

export default observer(Modal);
