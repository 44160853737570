import styled, { css, CSSProp, PropsTheme, withTheme } from 'styled-components';
import { Color } from '../../typings';
import { EThemeComponentColor } from '../../components/Theme/EThemeComponentColor';
import { OptionProps } from '../../components/List';

type StyleProps = PropsTheme & {
  isSelected?: boolean;
  columns?: number;
  gutter?: number;
  selectedOptionColor?: number;
};

const buttonsGap: number = 4;

function getWidth(columns: number): number {
  return 100 / columns;
}
const updateBorderStyleOption = ({ selectedOptionColor, theme }: PropsTheme<OptionProps>): Color => {
  return (selectedOptionColor === (EThemeComponentColor.PURPLE || 'purple')
    ? theme.colors.mainColor2
    : theme.colors.mainColor1);
};

const selectedMixin = css`
  border: 1px solid ${(props: PropsTheme): Color => updateBorderStyleOption(props)};
  outline: 1px solid ${(props: PropsTheme): Color => updateBorderStyleOption(props)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;

const nonSelectedMixin = css`
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.grey4};
`;

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${buttonsGap}px;
  width: 100%;
  margin: 5px 1px;
`;

export const Card = styled.button<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  flex: 1 1 ${({ columns = 1 }: StyleProps): string => `calc(${getWidth(columns)}% - ${buttonsGap}px)`};
  max-width: ${({ columns = 1 }: StyleProps): string => `calc(${getWidth(columns)}% - ${buttonsGap}px)`};
  outline: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.secondary};
  padding: ${({ gutter = 4 }: StyleProps): number => gutter}px;
  ${({ isSelected }: StyleProps): CSSProp => isSelected ? selectedMixin : nonSelectedMixin}
  padding: 8px 4px;
  background: ${({ theme }: PropsTheme): Color => theme.colors.whiteBg};
  font-size: 13px;
  cursor: pointer;

  &:hover {
    ${selectedMixin};
  }
  
  &:focus {
    ${selectedMixin};
  }
  
  &.custom-input {
    background: ${({ theme }: PropsTheme): Color => theme.colors.secondary};
    border: none;
    outline: none;
    padding: 0;
    &:hover {
      border: 0;
      box-shadow: none;
    }
  }
`;
