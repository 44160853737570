import type {
  BaseProps, ReactElement
} from 'react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ROOF_FACE_SLOPE_FRACTION_DIGITS } from '../../../domain/models/Constants';
import {
  AngleGrades, ButtonWrapper, CustomUnits
} from './styles';

type Props = BaseProps & {
  numerator: number;
  denominator: number;
  angle: number;
  onClick?: (value: number) => void;
  selected?: boolean;
};

function SlopeButton(props: Props): ReactElement {
  const {
    angle, className, denominator, onClick, numerator, selected
  } = props;

  const containerClassName = clsx(className, {
    active: selected
  });

  const onButtonClick = useCallback(() => {
    onClick?.(angle);
  }, [angle, onClick]);

  return (
    <ButtonWrapper className={containerClassName} onClick={onButtonClick}>
      <CustomUnits>
        {numerator}/{denominator}
      </CustomUnits>

      <AngleGrades>{angle.toFixed(ROOF_FACE_SLOPE_FRACTION_DIGITS)}°</AngleGrades>
    </ButtonWrapper>
  );
}

export default SlopeButton;
