import {
  action, computed, observable
} from 'mobx';
import type { IAddressData } from '../../../../../domain/models/SiteDesign/Address';
import { Address } from '../../../../../domain/models/SiteDesign/Address';
import type { IPersonParticipant } from '../../../../../domain/models/SiteDesign/Participant';
import type { Coords } from '../../../../../domain/typings';
import { BaseImageryProvider } from '../../../../../domain/typings';
import type EditorStore from '../../../../EditorStore/EditorStore';
import type { RoofProtrusionStore } from '../../../Properties/RoofProtrusion/RoofProtrusionStore';
import type { WorkspaceStore } from '../../../WorkspaceStore';
import type { IBaseViewModelDependencies } from '../../BaseViewModel';
import { BaseViewModel } from '../../BaseViewModel';
import type { InstallerOption } from '../../../../../infrastructure/services/api/DesignService';
import { notify } from '../../../../../utils/helpers';
import {
  ERROR, CONFIRMATION
} from '../../../../../domain/models/Constants';
import config from '../../../../../config/config';
import type { IAdditionalProjectData } from '../../../../../domain/models/SiteDesign/Project';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type {
  ICompanyInstallerCreationRequest,
  ICompanyInstallerData,
  ILicenseData
} from '../../../../../domain/models/Installer';
import {
  emptyLicense, isLicenseValid
} from '../../../../../domain/models/Installer';
import {
  type IKeyboardBehaviourHandler, KeyboardBehaviour
} from '../../../../../domain/behaviour/KeyboardBehaviour';

const DEFAULT_MAP_ZOOM_LEVEL: number = 20;

interface IUpsertInstallerAndCreateProjectViewModelDependencies extends IBaseViewModelDependencies {
  editor: EditorStore;
  internalReferenceId?: string;
  customer?: IPersonParticipant;
  initialStep?: number;
  startupMode: boolean;
}

interface ILoadProjectDependencies {
  editor: EditorStore;
  roofProtrusion: RoofProtrusionStore;
  workspace: WorkspaceStore;
}

export class UpsertInstallerAndCreateProjectViewModel extends BaseViewModel implements IKeyboardBehaviourHandler {
  readonly propCodeUI = 'upsert_installer_and_create_project_modal';
  override readonly editor: EditorStore;
  /**
   * Project address as displayed in the UI field. Changes as user types it in.
   */
  @observable
  projectAddressString: string = '';
  /**
   * Project address split into individual components.
   * Set when user chooses a suggestion from autocompleted, cleared when user types in the field.
   */
  @observable
  private projectAddress?: Address;

  @observable
  additionalProjectData: IAdditionalProjectData = {
    internalReferenceId: '',
    customer: {
      firstName: '',
      lastName: ''
    }
  };
  @observable
  coordinates: Coords = {} as Coords;
  @observable
  zoomLevel: number = DEFAULT_MAP_ZOOM_LEVEL;
  @observable
  step: number = 1;
  @observable
  licenses: ILicenseData[] = [emptyLicense()];
  @observable
  website: string = '';
  @observable
  phoneNumber: string = '';
  @observable
  installerAddress: IAddressData = {
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: ''
  };
  @observable
  companyName: string = '';
  @observable
  isLoading: boolean = true;
  @observable
  logoUploadingInProgress: boolean = false;
  @observable
  isLogoPresent: boolean = false;
  @observable
  checkingLogo: boolean = false;

  private currentInstallerId!: string;
  private currentInstallerData!: ICompanyInstallerData;

  @observable
  logoUrl!: string;
  // We only need to pass preferences when updating installer, so it's just empty for now:
  currentInstallerPreferences?: {};

  /**
   * A flag indicating whether we are in the startup wizard mode (in contrast to editing installer data)
   */
  @observable
  startupMode: boolean = false;
  @observable
  onlyProjectCreationOnStartupMode: boolean = false;

  @observable
  installerSavingInProgress: boolean = false;
  private loadProjectDependencies: ILoadProjectDependencies | null = null;

  private onProjectCreationSuccess!: () => void;
  private onProjectCreationFail!: () => void;
  projectCreationCompletionPromise: Promise<void>;

  constructor(dependencies: IUpsertInstallerAndCreateProjectViewModelDependencies) {
    super(dependencies);
    this.editor = dependencies.editor;
    KeyboardBehaviour.addKeyboardEvents(this);

    this.startupMode = dependencies.startupMode;

    this.loadInstallers();

    this.projectCreationCompletionPromise = new Promise((resolve, reject): void => {
      this.onProjectCreationSuccess = resolve;
      this.onProjectCreationFail = reject;
    });
  }

  @computed
  get isAddressValid(): boolean {
    return !!this.projectAddress?.isValid;
  }

  @action.bound
  async deleteLogo(): Promise<void> {
    await this.documentsService.deleteLogo(this.currentInstallerId);
    this.isLogoPresent = false;
  }

  @action.bound
  async uploadLogo(file: File): Promise<void> {
    this.logoUploadingInProgress = true;
    try {
      await this.documentsService.uploadLogo(this.currentInstallerId, file);
      this.isLogoPresent = true;
      notify('Logo has been successfully uploaded and set', CONFIRMATION);
    } catch (error) {
      notify('Logo uploading failed', CONFIRMATION);
      console.error('Logo uploading failed', error);
    } finally {
      this.logoUploadingInProgress = false;
    }
  }

  @action.bound
  private checkWhetherLogoExists(installer: ICompanyInstallerData): Promise<void> {
    this.checkingLogo = true;
    return this.documentsService
      .checkLogo(installer.account, installer.id)
      .then((): void => {
        this.isLogoPresent = true;
      })
      .catch((): void => {
        this.isLogoPresent = false;
      })
      .finally((): void => {
        this.checkingLogo = false;
      });
  }

  @action.bound
  loadInstallers(): void {
    this.designService.loadInstallers().then(this.setValuesFromInstallerResponse);
  }

  @action.bound
  private setValuesFromInstallerResponse(installers: InstallerOption[]): void {
    if (!installers.length) {
      // No installers exist for account
      this.step = 1;
      this.onlyProjectCreationOnStartupMode = false;
    } else {
      // Installer already exists
      this.step = this.startupMode ? 4 : 1;
      this.onlyProjectCreationOnStartupMode = true;
      this.designService.getInstaller(installers[0].id).then(this.setCurrentInstallerData);
    }
    this.isLoading = false;
  }

  @action.bound
  private setCurrentInstallerData(installer: ICompanyInstallerData): void {
    this.currentInstallerId = installer.id;
    this.currentInstallerData = installer;
    this.logoUrl = `${config.api.documents}/accounts/${installer.account}/installers/${installer.id}/logo`;

    this.companyName = installer.name;
    this.installerAddress.addressOne = installer.address?.addressOne ?? '';
    this.installerAddress.addressTwo = installer.address?.addressTwo ?? '';
    this.installerAddress.city = installer.address?.city ?? '';
    this.installerAddress.postalCode = installer.address?.postalCode ?? '';
    this.installerAddress.province = installer.address?.province ?? '';
    this.phoneNumber = installer.phoneNumber ?? '';
    this.licenses = installer.licenses ?? [emptyLicense()];

    // Setting logo info here after we've set currentInstallerData:
    this.checkWhetherLogoExists(this.currentInstallerData);
  }

  @action.bound
  handlePreviousStep(): void {
    if (this.step > 1) {
      this.step--;
    }
  }

  setLoadProjectDependencies(dependencies: ILoadProjectDependencies): void {
    this.loadProjectDependencies = dependencies;
  }

  @action.bound
  async handleNextStepInCreateProjectWizard(dependencies: {
    editor: EditorStore;
    roofProtrusion: RoofProtrusionStore;
    workspace: WorkspaceStore;
    internalReferenceId?: string;
    customer?: IPersonParticipant;
  }): Promise<void> {
    switch (this.step) {
    case 1:
      this.step = 2;
      return;
    case 2:
      const success = !this.currentInstallerId ? await this.createInstaller() : await this.updateInstaller();
      if (success) {
        this.step = 3;
        this.checkWhetherLogoExists(this.currentInstallerData);
      }
      return;
    case 3:
      this.step = 4;
      return;
    case 4:
      this.createProject(dependencies);
      return;
    default:
    }
  }

  @action.bound
  async saveInstallerOutsideOfCreateProjectWizard(): Promise<void> {
    const success = await this.updateInstaller();
    if (success) {
      this.closeModal();
      notify('Installer information has been successfully saved', CONFIRMATION);
    }
  }

  private async createInstaller(): Promise<boolean> {
    const data: ICompanyInstallerCreationRequest = {
      type: 'LICENSED_CONTRACTOR',
      name: this.companyName,
      address: new Address(this.installerAddress).toData(),
      phoneNumber: this.phoneNumber || undefined,
      licenses: this.licenses
    };
    try {
      await this.designService.createInstaller(data).then(this.setCurrentInstallerData);
      return true;
    } catch (error) {
      console.error('Installer creation failed', error);
      notify('Installer creation failed', ERROR);
      return false;
    }
  }

  private async updateInstaller(): Promise<boolean> {
    this.installerSavingInProgress = true;

    const data: ICompanyInstallerData = {
      ...this.currentInstallerData
    };
    data.name = this.companyName;
    data.address = new Address(this.installerAddress).toData();
    data.phoneNumber = this.phoneNumber || undefined;
    data.licenses = this.licenses;
    try {
      await this.designService.updateInstaller(this.currentInstallerId, data).then(this.setCurrentInstallerData);
      return true;
    } catch (error) {
      console.error('Installer update failed', error);
      notify('Installer update failed', ERROR);
      return false;
    } finally {
      this.installerSavingInProgress = false;
    }
  }

  @action.bound
  private createProject(dependencies: ILoadProjectDependencies): void {
    this.domain.createProject(
      this.projectAddress!,
      this.coordinates,
      {
        provider: BaseImageryProvider.GOOGLE_MAPS,
        zoomLevel: this.zoomLevel
      },
      this.currentInstallerId,
      this.additionalProjectData,
      dependencies
    );
    this.closeModal();
    this.onProjectCreationSuccess();
  }

  @computed
  get isValid(): boolean {
    switch (this.step) {
    case 1:
      return this.isFirstStepValid();
    case 2:
      return this.isFirstStepValid() && this.isSecondStepValid();
    case 3:
      return true;
    case 4:
      return !!(
        this.isAddressValid
          && this.coordinates?.latitude
          && this.coordinates?.longitude
          && this.additionalProjectData.customer!.lastName
      );
    default:
      return false;
    }
  }

  private isFirstStepValid(): boolean {
    return Boolean(
      this.companyName.trim() && this.licenses.every((license: ILicenseData): boolean => isLicenseValid(license))
    );
  }

  private isSecondStepValid(): boolean {
    return new Address(this.installerAddress).isValid;
  }

  @action.bound
  setZoom(newZoom: number): void {
    this.zoomLevel = newZoom;
  }

  @action.bound
  setLocation(location: Coords): void {
    if (!location) {
      return;
    }
    this.coordinates = location;
  }

  @action.bound
  setProjectAddressString(address: string): void {
    this.projectAddressString = address;
    this.projectAddress = undefined;
  }

  @action.bound
  setInternalReferenceId(id: string): void {
    this.additionalProjectData.internalReferenceId = id.trim();
  }

  @action.bound
  setCustomerFirstName(firstName: string): void {
    this.additionalProjectData.customer!.firstName = firstName.trim();
  }

  @action.bound
  setCustomerLastName(lastName: string): void {
    this.additionalProjectData.customer!.lastName = lastName.trim();
  }

  @action.bound
  setCompanyName(name: string): void {
    this.companyName = name;
  }

  @action.bound
  setInstallerAddress1(value: string): void {
    this.installerAddress.addressOne = value.trim();
  }

  @action.bound
  setInstallerAddress2(value: string): void {
    this.installerAddress.addressTwo = value.trim();
  }

  @action.bound
  setInstallerCity(value: string): void {
    this.installerAddress.city = value.trim();
  }

  @action.bound
  setInstallerState(value: string): void {
    this.installerAddress.province = value.trim();
  }

  @action.bound
  setInstallerPostalCode(value: string): void {
    this.installerAddress.postalCode = value.trim();
  }

  @action.bound
  setPhoneNumber(value: string): void {
    this.phoneNumber = value;
  }

  @action.bound
  setWebsite(value: string): void {
    this.website = value;
  }

  @action.bound
  addLicense(): void {
    this.licenses.push(emptyLicense());
  }

  @action.bound
  setLicenseNumber(index: number, licenseNumber: string): void {
    this.licenses[index].number = licenseNumber;
  }

  @action.bound
  setLicenseClass(index: number, licenseClass: string): void {
    this.licenses[index].classification = licenseClass;
  }

  @action.bound
  setLicenseState(index: number, state: string): void {
    this.licenses[index].issuingAuthority = state;
  }

  @action.bound
  removeLicense(index: number): void {
    this.licenses.splice(index, 1);
  }

  /**
   * Function that parse google response in address domain model
   * @param originalAddress Address with the total string typed by the user
   * @param addressComponents parts of the adress according to google maps
   */
  @action.bound
  mapAddress(originalAddress: string, addressComponents: google.maps.GeocoderAddressComponent[]): void {
    this.projectAddressString = originalAddress;
    this.projectAddress = Address.fromGoogleMapsAddressComponents(addressComponents);
  }

  dispose(): void {
    KeyboardBehaviour.removeKeyboardEvents(this);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    /** Not implemented yet */
  };

  onKeyUp = (event: KeyboardEvent): void => {
    if (this.isValid && event.key === KeyboardListener.KEY_ENTER) {
      if (this.loadProjectDependencies) {
        this.createProject(this.loadProjectDependencies);
      }
      this.dispose();
    }
  };
}
