import {
  LyraLoader, LyraModal, LyraSummary, LyraTabs
} from '@aurorasolar/lyra-ui-kit';
import { EThemeComponentColor } from '@aurorasolar/lyra-ui-kit/lib/components/Theme/EThemeComponentColor';
import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, {
  useCallback, useEffect, useState
} from 'react';
import type { PermitPackageViewModel } from '../../../../stores/UiStore/Modal/ViewModels/PermitPackageModal/PermitPackageViewModel';
import { useUiStore } from '../../../../stores/useStore';
import ImagesAndDocuments from './ImagesAndDocuments/ImagesAndDocuments';
import EngineerOfRecord from './EngineerOfRecord/EngineerOfRecord';
import Options from './Options/Options';
import Settings from './Settings/Settings';
import {
  ModalWrapper, PreviewWrapper, TabsWrapper
} from './styles';
import DirectDownloadFallback from './DirectDownloadFallback/DirectDownloadFallback';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getTabsContent = (isInstallerALicensedContractor = false) => {
  const labels = [
    {
      index: 0,
      name: 'Options',
      image: 'tool-wrench'
    },
    {
      index: 1,
      name: 'Images and Documents',
      image: 'tool-images'
    },
    {
      index: !isInstallerALicensedContractor ? 2 : 3,
      name: 'Advanced Settings',
      image: 'tool-settings'
    }
  ];

  const content = [
    <Options key="options-permit-package" />,
    <ImagesAndDocuments key="gallery-permit-package" />,
    <Settings key="settings-permit-package" />
  ];

  if (isInstallerALicensedContractor) {
    labels.splice(2, 0, {
      index: 2,
      name: 'Engineer of Record',
      image: 'engineer-of-record'
    });

    content.splice(2, 0, <EngineerOfRecord key="engineer-of-record" />);
  }

  return {
    labels,
    content
  };
};

const PermitPackageModal = (): ReactElement => {
  const { modal } = useUiStore();
  const viewModel = modal.viewModel as PermitPackageViewModel;
  const [currentTab, setCurrentTab] = useState(0);
  const isInstallerALicensedContractor = viewModel.isInstallerALicensedContractor;

  // Depending on the flag value, there will be different amount of tabs in the UI
  const tabs = getTabsContent(isInstallerALicensedContractor);

  const onHandleClickTab = useCallback((indexTab: number): void => {
    setCurrentTab(indexTab);
  }, []);

  useEffect((): void => {
    viewModel.getPermitPackageGenerationOptions();
  }, [viewModel]);

  const modalProps = {
    title: 'Permit Package',
    leftButtonLabel: viewModel.leftButtonLabel,
    rightButtonLabel: viewModel.actionButtonLabel,
    rightButtonDisabled: viewModel.actionButtonDisabled,
    $show: true,
    width: '100%',
    onClickCloseButton: (): void => viewModel.closeModal(),
    onClickLeftButton: (): void => viewModel.closeModal(),
    onClickRightButton: (): Promise<void> => viewModel.proceed()
  };

  return (
    <LyraModal.Modal {...modalProps}>
      <ModalWrapper>
        <TabsWrapper>
          {viewModel.documentGenerationOptions ? (
            <LyraTabs.CustomTabs
              activeThemeColor={EThemeComponentColor.BLUE}
              inactiveThemeColor={EThemeComponentColor.GRAY_500}
              labels={tabs.labels}
              value={currentTab}
              contents={tabs.content}
              onClick={onHandleClickTab}
            />
          ) : (
            <LyraLoader.Loader text="Loading..." />
          )}
        </TabsWrapper>
        <PreviewWrapper>
          <LyraSummary.Summary mode={viewModel.summaryComponentMode} data={viewModel.summaryComponentData} />
        </PreviewWrapper>
      </ModalWrapper>
      <DirectDownloadFallback />
    </LyraModal.Modal>
  );
};

export default observer(PermitPackageModal);
