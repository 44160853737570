import type { Object3D } from 'three';
import type {
  AnyConstructor, Mixin
} from '../../utils/Mixin';
import EWorkspace from '../typings/EWorkspace';
import { getLyraModelByMesh } from '../sceneObjectsWithLyraModelsHelpers';
import type { Drawable } from './Drawable';

export const WorkspaceTagged = <T extends AnyConstructor<Drawable>>(DraggableBase: T) => {
  abstract class MixinClass extends DraggableBase {
    abstract tag: EWorkspace;
  }
  return MixinClass;
};

export type WorkspaceTagged = Mixin<typeof WorkspaceTagged>;

/*
 * Guard to verify if the object is part of design Workspace
 *
 */
export function isDesignWorkspaceObject(target: unknown): target is WorkspaceTagged {
  return getLyraModelByMesh<WorkspaceTagged>(target as Object3D).tag === EWorkspace.DESIGN;
}
