import type { Object3D } from 'three';
import { KeyboardListener } from '../../../../../utils/KeyboardListener';
import type { Building } from '../../../../../domain/models/SiteDesign/Building';
import { RoofFace } from '../../../../../domain/models/SiteDesign/RoofFace';
import type { IDrawableStructureFactory } from '../../../../../domain/models/StructureFactory';
import type { Color } from '../../../../../domain/typings';
import { ECursor } from '../../../../../domain/typings';
import type DomainStore from '../../../../DomainStore/DomainStore';
import type SmartGuideStore from '../../../SmartGuidesStore/SmartGuidesStore';
import type { WizardStore } from '../../../Wizard/Wizard';
import type {
  IPointerDownControlEvent,
  IPointerHoveringControlEvent,
  IPointerLeftControlEvent
} from '../../../../EditorStore/Controls/ControlEvents';
import { HoverControl } from '../../../../EditorStore/Controls/HoverControl';
import { SelectionControl } from '../../../../EditorStore/Controls/SelectionControl';
import type { PropertiesStore } from '../../../Properties/Properties';
import type { IAddRoofFaceCommandDependencies } from '../../../../ServiceBus/Commands/AddRoofFaceCommand';
import type { ServiceBus } from '../../../../ServiceBus/ServiceBus';
import { canvasConfig } from '../../../../../config/canvasConfig';
import type { IWorkspace } from '../../../WorkspaceStore/types';
import type {
  IBaseToolDependencies, IHandleClicksTool, IHandleHoverTool, IHandleMoveTool
} from '../../Tool';
import {
  PANNING_TOOL_ID,
  PARCEL_BOUNDARY_TOOL_ID,
  ROOF_TRACING_TOOL_ID,
  SELECT_TOOL_ID,
  TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID
} from '../constants';
import { TraceToolBase } from '../TraceToolBase';
import { PropsPanelUICodes } from '../../../Properties/propertiesStoreConstants';
import { SceneObjectType } from '../../../../../domain/models/Constants';
import type { RoofFacePropertiesStore } from '../../../Properties/RoofProperties/RoofFacePropertiesStore';
import { getLyraModelByMesh } from '../../../../../domain/sceneObjectsWithLyraModelsHelpers';
import type { Unzoomable } from '../../../../../domain/mixins/Unzoomable';

export interface ITraceIndividualRoofFaceToolDependencies extends IBaseToolDependencies {
  readonly properties: PropertiesStore;
  readonly smartGuides: SmartGuideStore;
  readonly wizard: WizardStore;
  readonly domain: DomainStore;
  readonly roofFaceProps: RoofFacePropertiesStore;
  readonly serviceBus: ServiceBus;
  readonly drawableObjectsFactory: IDrawableStructureFactory;
  readonly currentWorkspace: IWorkspace;
}

export class TraceIndividualRoofFaceTool
  extends TraceToolBase
  implements IHandleClicksTool, IHandleHoverTool, IHandleMoveTool {
  static readonly toolWhitelist: string[] = [
    PANNING_TOOL_ID,
    SELECT_TOOL_ID,
    TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID,
    PARCEL_BOUNDARY_TOOL_ID,
    ROOF_TRACING_TOOL_ID
  ];
  override readonly id: string = TRACE_INDIVIDUAL_ROOFFACE_TOOL_ID;
  override readonly icon: string = 'trace-roof';
  override readonly title: string = 'Trace individual roof';
  override readonly description: string = this.title;
  override testId: string = 'TraceIndividualRoofFace';

  override showSubmenu: boolean = false;
  override smartGuides: SmartGuideStore;
  override currentWorkspace: IWorkspace;
  private hoveringControl?: HoverControl;
  private readonly roofFaceProps: RoofFacePropertiesStore;

  constructor(dependencies: ITraceIndividualRoofFaceToolDependencies) {
    super(dependencies);

    this.cursor = ECursor.FIRST_TRACE;
    this.properties = dependencies.properties;
    this.wizard = dependencies.wizard;
    this.currentWorkspace = dependencies.currentWorkspace;
    this.domain = dependencies.domain;

    this.smartGuides = dependencies.smartGuides;
    this.drawableObjectsFactory = dependencies.drawableObjectsFactory;
    this.roofFaceProps = dependencies.roofFaceProps;
  }

  whenSelected(): void {
    // when create this in editor
    this.wipObject = this.drawableObjectsFactory.create<RoofFace>(RoofFace, {
      color: canvasConfig.wipRoofFaceColor
    });

    this.initPreviewLine();
    this.properties.setPropertyPanel(PropsPanelUICodes.HintPanelTraceIndividualRoofFace);
    this.setupHovering();

    this.hidePreviewLine();
    this.editor.baseMouseControl.addEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.addEventListener('PointerDown', this.onMouseDown);
    this.editor.baseMouseControl.addEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.addEventListener('PointerLeft', this.onMouseLeave);
    KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
  }

  whenDeselected(): void {
    this.editor.removeObject(this.wipObject!.mesh);
    this.removePreviewLine();
    this.removeEventListeners();
    this.properties.setPropertyPanel();
    this.hoveringControl!.dispose();
  }

  dispose(): void {
    this.removeEventListeners();
  }

  override onMouseDown = (event: IPointerDownControlEvent): void => {
    super.onMouseDown(event);
    this.disableTools();
  };

  override onMouseLeave = (event: IPointerLeftControlEvent): void => {
    this.hidePreviewLine();
    this.smartGuides.resetGuides();
  };

  onObjectHoverIn = ({ hoverObject: hoverEvent }: IPointerHoveringControlEvent): void => {
    if (hoverEvent === undefined) {
      return;
    }

    if (hoverEvent.type === 'Vertex' && hoverEvent.mesh.userData.index === 0) {
      this.updateCursor(ECursor.TRACE);
      const { scaleFactor } = this.editor;
      (hoverEvent as Unzoomable).unzoom(scaleFactor * 2);
    }
  };

  onObjectHoverOut = ({ hoverObject: hoverEvent }: IPointerHoveringControlEvent): void => {
    if (hoverEvent === undefined) {
      return;
    }

    if (hoverEvent.type === 'Vertex' && hoverEvent.mesh.userData.index === 0) {
      this.updateCursor();
      const { scaleFactor } = this.editor;
      (hoverEvent as Unzoomable).unzoom(scaleFactor);
    }
  };

  async finishWipPolygon(finishData: { name: string; color: Color; level: number; building: Building }): Promise<void> {
    const newRoofFace: RoofFace = this.drawableObjectsFactory.create<RoofFace>(RoofFace, {
      color: canvasConfig.wipRoofFaceColor
    });

    newRoofFace.setVertices(this.wipObject!.boundary.vertices);

    const dependencies: IAddRoofFaceCommandDependencies = {
      domain: this.domain,
      newRoofFace: newRoofFace,
      color: finishData.color,
      name: finishData.name,
      level: finishData.level,
      building: finishData.building,
      templateRoofFace: this.roofFaceProps.lastInteractedRoofFace
    };
    this.serviceBus.send('add_roof_face', dependencies);

    this.editor.addOrUpdateObject(newRoofFace.mesh);
    this.editor.removeObject(this.wipObject!.mesh);
    this.removePreviewLine();

    this.hoveringControl!.setTargetObjects([newRoofFace]);
    // hide smart guides displayed
    this.smartGuides.resetGuides();
    this.enableTools();
    await this.toolbar.activateSelectionToolInProjectWorkspace();

    SelectionControl.getInstance(this.editor, this.editor.viewport, this.editor.activeCamera).setSelectedObjects([
      newRoofFace
    ]);
  }

  isRoofFace(target: Object3D): boolean {
    return getLyraModelByMesh<RoofFace>(target).type === SceneObjectType.RoofFace;
  }

  private setupHovering(): void {
    this.hoveringControl = new HoverControl(this.editor, this.editor.viewport, this.editor.activeCamera!, [
      this.wipObject!
    ]);
    this.hoveringControl.addEventListener('HoverInObject', this.onObjectHoverIn);
    this.hoveringControl.addEventListener('HoverOutObject', this.onObjectHoverOut);
  }

  private removeEventListeners(): void {
    this.editor.baseMouseControl.removeEventListener('PointerUp', this.onMouseUp);
    this.editor.baseMouseControl.removeEventListener('PointerMove', this.onMouseMove);
    this.editor.baseMouseControl.removeEventListener('PointerLeft', this.onMouseLeave);
    this.editor.baseMouseControl.removeEventListener('PointerDown', this.onMouseDown);
    this.hoveringControl!.removeEventListener('HoverInObject', this.onObjectHoverIn);
    this.hoveringControl!.removeEventListener('HoverOutObject', this.onObjectHoverOut);
    KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
  }

  private disableTools(): void {
    this.toolbar.whitelistTools(TraceIndividualRoofFaceTool.toolWhitelist);
  }
}
