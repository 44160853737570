import {
  LyraFormElementsDesignTool, LyraIcon, LyraMessageBox, LyraTypography
} from '@aurorasolar/lyra-ui-kit';
import { observer } from 'mobx-react-lite';
import type {
  ReactElement, FC
} from 'react';
import React, {
  useCallback, useRef, useState, useMemo
} from 'react';
import debounce from 'lodash/debounce';
import { Link } from '@aurorasolar/lyra-ui-kit/lib/components/FontFamily/Fonts';
import type { Coords } from '../../../../../domain/typings';
import useStore from '../../../../../stores/useStore';
import type { UpsertInstallerAndCreateProjectViewModel } from '../../../../../stores/UiStore/Modal/ViewModels/CreateProject/UpsertInstallerAndCreateProjectViewModel';
import Field from '../../../../components/Field';
import type {
  PreviewGoogleMapChangeParams,
  PreviewGoogleMapOptions,
  PreviewGoogleMapProps
} from '../PreviewGoogleMap/PreviewGoogleMap';
import PreviewGoogleMap, {
  DEFAULT_MAP_ZOOM_LEVEL,
  MAX_MAP_ZOOM_LEVEL,
  MIN_MAP_ZOOM_LEVEL
} from '../PreviewGoogleMap/PreviewGoogleMap';
import {
  useGeocoding, usePlaces, useGoogleMap
} from '../../../../../utils/maps';
import {
  CreateProjectContentWrapper,
  CreateProjectLeftContentSide,
  CreateProjectRightContentSide,
  CreateProjectContentSideSeparator,
  CreateProjectNameWrapper,
  CreateProjectFieldRowWrapper,
  CenteredTitle,
  EmptyMapView
} from './../styles';

const mapOptions: PreviewGoogleMapOptions = {
  mapVisible: true,
  minZoom: MIN_MAP_ZOOM_LEVEL,
  maxZoom: MAX_MAP_ZOOM_LEVEL
};

export const CreateProjectStep: FC = observer((): ReactElement => {
  // Setting stores used in the component
  const { modal } = useStore();
  const addressRef = useRef({} as HTMLInputElement);

  // Setting view model used by the component
  const [upsertInstallerAndCreateProjectViewModel] = useState<UpsertInstallerAndCreateProjectViewModel>(
    modal.viewModel as UpsertInstallerAndCreateProjectViewModel
  );

  // Instance of google maps variables using custom hooks
  const googleMap = useGoogleMap();
  // Instance of geocoder
  const geocoder = useGeocoding(googleMap);

  const [autocompleteSelectedCoords, setAutocompleteSelectedCoords] = useState<Coords>();

  const onPlaceChanged = (autocomplete: google.maps.places.Autocomplete): void => {
    const place = autocomplete.getPlace();

    const addressComponents = place.address_components;

    if (addressComponents && place.geometry?.location) {
      upsertInstallerAndCreateProjectViewModel.mapAddress(addressRef.current.value, addressComponents);

      const latLong: Coords = {
        latitude: place.geometry?.location.lat() ?? 0,
        longitude: place.geometry?.location.lng() ?? 0
      };
      setAutocompleteSelectedCoords(latLong);
      // Call location callback to set latitude and longitude
      upsertInstallerAndCreateProjectViewModel.setLocation(latLong);
      upsertInstallerAndCreateProjectViewModel.setZoom(DEFAULT_MAP_ZOOM_LEVEL);
    }
  };

  // Call custom hook to use places and call the callback onPlaceChanged
  usePlaces(googleMap, addressRef.current, onPlaceChanged, {
    componentRestrictions: {
      country: ['us', 'pr']
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeMap = useCallback(
    debounce((updatedCoordinates: PreviewGoogleMapChangeParams): void => {
      const latLong: Coords = {
        latitude: updatedCoordinates.latitude,
        longitude: updatedCoordinates.longitude
      };
      // Call location callback to set latitude and longitude
      upsertInstallerAndCreateProjectViewModel.setLocation(latLong);
      upsertInstallerAndCreateProjectViewModel.setZoom(updatedCoordinates.zoom);
    }, 500),
    [geocoder]
  );

  const PreviewGoogleMapParams: PreviewGoogleMapProps = useMemo(
    (): PreviewGoogleMapProps => ({
      coordinates: {
        lat: autocompleteSelectedCoords?.latitude || 0,
        lng: autocompleteSelectedCoords?.longitude || 0,
        zoom: DEFAULT_MAP_ZOOM_LEVEL
      },
      options: mapOptions,
      onChange: handleChangeMap
    }),
    [autocompleteSelectedCoords, handleChangeMap]
  );

  return (
    <>
      <CenteredTitle>
        <LyraTypography.Heading type="h2" fontWeight="300">
          Create a Project
        </LyraTypography.Heading>
      </CenteredTitle>
      <CreateProjectContentWrapper>
        <CreateProjectLeftContentSide>
          <CreateProjectFieldRowWrapper>
            <Field className="mr-xxs" label="PROJECT ADDRESS" testid="create-project-address-container">
              <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                inputRef={addressRef}
                value={upsertInstallerAndCreateProjectViewModel.projectAddressString}
                onChange={upsertInstallerAndCreateProjectViewModel.setProjectAddressString}
              />
              {upsertInstallerAndCreateProjectViewModel.projectAddressString
                && !upsertInstallerAndCreateProjectViewModel.isAddressValid && (
                <div
                  style={{
                    color: 'red',
                    textTransform: 'capitalize'
                  }}
                >
                    Address Invalid.
                </div>
              )}
            </Field>
          </CreateProjectFieldRowWrapper>
          <CreateProjectFieldRowWrapper>
            <Field className="mr-xxs" label="INTERNAL REFERENCE ID">
              <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                onChange={upsertInstallerAndCreateProjectViewModel.setInternalReferenceId}
                placeholder={'Project identifier internal to your company (optional)'}
              />
            </Field>
          </CreateProjectFieldRowWrapper>
          <CreateProjectFieldRowWrapper>
            <CreateProjectNameWrapper>
              <Field className="mr-xxs" label="CUSTOMER FIRST NAME">
                <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                  onChange={upsertInstallerAndCreateProjectViewModel.setCustomerFirstName}
                  placeholder={'Optional'}
                />
              </Field>
              <Field className="mr-xxs" label="CUSTOMER LAST NAME" testid="create-project-customer-last-name">
                <LyraFormElementsDesignTool.FormElementsDesignTool.TextField.TextField
                  onChange={upsertInstallerAndCreateProjectViewModel.setCustomerLastName}
                />
              </Field>
            </CreateProjectNameWrapper>
          </CreateProjectFieldRowWrapper>
        </CreateProjectLeftContentSide>
        <CreateProjectContentSideSeparator />
        <CreateProjectRightContentSide>
          {autocompleteSelectedCoords ? (
            <>
              <PreviewGoogleMap {...PreviewGoogleMapParams} />
              <LyraMessageBox.MessageBox icon="info" className="messageInfo">
                Click and drag to center the map over the property. Zoom to the property boundaries using the scroll
                wheel or <code>+</code> and <code>-</code> keys.
                <b>
                  {' '}
                  Map boundaries cannot be changed after creating the project so be sure the image is positioned to
                  include all the property boundaries.
                </b>
              </LyraMessageBox.MessageBox>
            </>
          ) : (
            <EmptyMapView>
              <LyraIcon.Icon
                name="icon-address"
                style={{
                  width: 56,
                  height: 78
                }}
              />
              <Link
                className="mt-xxs"
                style={{
                  fontSize: 11
                }}
              >
                Enter an address to see a satellite preview here
              </Link>
            </EmptyMapView>
          )}
        </CreateProjectRightContentSide>
      </CreateProjectContentWrapper>
    </>
  );
});
