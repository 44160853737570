import {
  BufferGeometry, Line, MeshBasicMaterial, Vector2
} from 'three';
import { canvasConfig } from '../../config/canvasConfig';

import { Unzoomable } from '../mixins/Unzoomable';
import { Drawable } from '../mixins/Drawable';
import { LayerCanvas } from './LayerCanvas';

const SQUARE_SIZE = 20;

// Creating mesh for representing the square angle
// when it's right angle(a.k.a 90 degrees)
const squareGeo = new BufferGeometry().setFromPoints([
  new Vector2(SQUARE_SIZE, 0),
  new Vector2(SQUARE_SIZE, SQUARE_SIZE),
  new Vector2(0, SQUARE_SIZE)
]);

const squareMaterial = new MeshBasicMaterial({
  transparent: true,
  depthTest: true,
  depthWrite: false,
  ...LayerCanvas.CLOSEST,
  color: canvasConfig.liveAngleSnapColor
});

const MixedClass = Unzoomable(Drawable(class SimpleClass {}));

export class SquareRightAngle extends MixedClass {
  constructor() {
    super();
    const line = new Line(squareGeo, squareMaterial);
    this.mesh.add(line);
  }

  unzoom(factor: number): void {
    this.mesh.scale.set(factor * this.mapZoomFactor, factor * this.mapZoomFactor, factor * this.mapZoomFactor);
  }
}
