import type { Object3D } from 'three';
import type { Drawable } from './mixins/Drawable';
import type { SceneObjectType } from './models/Constants';

/**
 * TODO:
 *   * all return types should be optional
 */

export function getParentLyraModelBySceneObjectType<T extends Drawable>(
  object: Drawable,
  type: SceneObjectType
): T | undefined {
  let parent: Object3D | undefined = object.mesh;
  do {
    // In the process of looking for a parent the variable turns from Object3D to NewDrawable
    if (getLyraModelByOptionalMesh(parent)?.type === type || parent?.type === type) {
      return parent as unknown as T;
    }
    parent = getParentLyraModelByMeshOrLyraModel(parent);
  } while (parent);

  return undefined;
}

export function getParentLyraModelByMesh<T = Drawable>(mesh: Object3D, level: number = 1): T {
  return mesh.parent && level > 1
    ? getParentLyraModelByMesh<T>(mesh.parent, level - 1)
    : ((mesh.parent?.userData.lyraModel ?? mesh.parent) as T);
}
export function getParentLyraModelByMeshOrLyraModel<T = Drawable>(
  meshOrModel: Object3D | Drawable | undefined
): T | undefined {
  if (!meshOrModel) {
    return undefined;
  }
  const meshParent = (meshOrModel as Drawable).mesh?.parent ?? (meshOrModel as Object3D).parent;
  if (meshParent) {
    return getLyraModelByMesh(meshParent);
  }
}

export function getLyraModelByMesh<T = Drawable>(mesh: Object3D): T {
  if (mesh.userData?.lyraModel) {
    return mesh.userData.lyraModel;
  }
  return mesh as unknown as T;
}

export function getLyraModelByOptionalMesh<T = Drawable>(mesh: Object3D | null | undefined): T | undefined {
  if (!mesh) {
    return undefined;
  }

  return mesh.userData?.lyraModel;
}
