import type { Texture } from 'three';
import { TextureLoader } from 'three';

type ProgressCallback = (progress: ProgressEvent) => void;

function load(url: string, onProgress?: ProgressCallback): Promise<Texture> {
  const loader: TextureLoader = new TextureLoader();

  return new Promise<Texture>((resolve: (texture: Texture) => void, reject: (error: unknown) => void): void => {
    loader.load(url, resolve, onProgress, reject);
  });
}

export { load };
