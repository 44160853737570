import type { PropsTheme } from 'styled-components';
import styled, { withTheme } from 'styled-components';
import type { Color } from '../../../domain/typings';
import Flexbox from '../Layout/Flexbox';
import {
  COMPASS_HEIGHT, COMPASS_WIDTH, GRADIENT_COLOR_1, GRADIENT_COLOR_2, NEEDLE_RADIUS
} from './constants';
import {
  getCardinalPointStyles, getRingPipeStyles
} from './helpers';

export const CompassStyle = styled(Flexbox)`
  min-height: 250px;
  position: relative;
  width: 100%;
`;

export const InfoText = styled.div`
  font-size: 13px;
  margin: 5px 0px 55px;
  background-color: rgb(221, 232, 232);
  padding: 7px;
  line-height: 1.5;
  border-radius: 6px;
`;

export const DegreeRingStyle = styled.div`
  height: ${COMPASS_HEIGHT}px;
  position: relative;
  width: ${COMPASS_WIDTH}px;
`;

export const Ring = styled.div`
  background: white;
  border: solid 1.1px ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
  border-radius: 100%;
  box-sizing: border-box;
  height: ${COMPASS_HEIGHT}px;
  position: absolute;
  width: ${COMPASS_WIDTH}px;

  & > span {
    background-color: ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
    display: block;
    height: 9px;
    left: 68px;
    opacity: 0.5;
    position: absolute;
    top: 65px;
    width: 2px;

    ${getRingPipeStyles()}
  }
`;

export const InnerRing = styled.div`
  align-items: center;
  background-image: radial-gradient(circle at 50% 50%, ${GRADIENT_COLOR_1}, ${GRADIENT_COLOR_2});
  border: solid 1.1px ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  height: 106px;
  justify-content: center;
  left: 16px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 16px;
  width: 106px;

  & > span:first-child {
    font-size: 24px;
    font-weight: bold;
    padding-left: 7px;
  }
`;

export const Needle = styled.div`
  border-radius: 100%;
  height: 138px;
  position: absolute;
  width: 138px;
  &::before {
    background-color: ${GRADIENT_COLOR_2};
    border: 3px solid ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
    border-radius: 100%;
    box-sizing: border-box;
    cursor: pointer;
    content: '';
    display: block;
    height: 23px;
    left: calc(50% - ${NEEDLE_RADIUS});
    position: absolute;
    top: calc(50% - ${NEEDLE_RADIUS});
    transform: translate(0px, -62px);
    transition: 0.1s all ease;
    width: 23px;
  }
`;

export const CardinalPoints = styled.div`
  left: 47%;
  position: absolute;
  top: 46%;

  & > span {
    position: absolute;

    ${getCardinalPointStyles()}
  }
`;

export const SnapPoint = styled.div`
  border: 1px solid ${({ theme }: PropsTheme): Color => theme.colors.secondaryFont};
  border-radius: 100%;
  height: 5px;
  left: calc(50% - 3px);
  position: absolute;
  top: calc(50% - 3px);
  width: 5px;
`;

export const ArrowButtonsStyle = styled.div<PropsTheme>`
  margin-top: 60px;
  width: 180px;

  & > div {
    margin-bottom: 10px;
    text-align: center;

    & > span {
      border: 2px solid ${({ theme }: PropsTheme): Color => theme.colors.quaternary};
      border-radius: 3px;
      cursor: pointer;
      display: inline-block;
      height: 15px;
      margin: 0 5px;
      padding: 3px 5px;
      width: 13px;

      &:active {
        border: 2px solid ${({ theme }: PropsTheme): Color => theme.colors.primary};
      }

      &:first-child {
        transform: scaleX(-1);
      }
    }
  }

  & > p {
    font-size: 10px;
    margin-top: 5px;
    text-align: center;
  }
`;
