import { useContext } from 'react';
import context from './context';
import type Store from './Store';
import type { UiStore } from './UiStore/UiStore';

function useStore(): Store {
  return useContext(context) as Store;
}

export function useUiStore(): UiStore {
  return useStore().uiStore;
}

export default useStore;
