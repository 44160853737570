import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TextLayout, TextLayoutProps } from './styles';

const HeadingText = styled(TextLayout)`
${({ type }: TextLayoutProps): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined => type}`
;

export type HeadingProps = TextLayoutProps;
export const Heading = (props: HeadingProps): ReactElement => {
  const { children, type, key, ...rest } = props;

  return (
    <HeadingText
      {...rest}
      type={type}
      key={key}
    >
      {children}
    </HeadingText>
  );
};
