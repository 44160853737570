import {
  action, computed
} from 'mobx';
import type { IKeyboardBehaviourHandler } from '../../../domain/behaviour/KeyboardBehaviour';
import type { IDisposable } from '../../../domain/typings';
import { ECursor } from '../../../domain/typings';
import type EditorStore from '../../EditorStore/EditorStore';
import type {
  IControlDragging,
  IControlSelectionChange,
  IPointerDblClickControlEvent,
  IPointerDownControlEvent,
  IPointerHoveringControlEvent,
  IPointerLeftControlEvent,
  IPointerMoveControlEvent,
  IPointerUpControlEvent
} from '../../EditorStore/Controls/ControlEvents';
import { EMouseButtons } from '../../EditorStore/Controls/ControlEvents';
import type { ModalStore } from '../Modal/Modal';
import type { ServiceBus } from '../../ServiceBus/ServiceBus';
import { getUiStore } from '../../RootStoreInversion';
import type { ToolbarStore } from './Toolbar';

export interface IHandleClicksTool {
  onMouseDown(event: IPointerDownControlEvent): void;
  onMouseUp(event: IPointerUpControlEvent): void;
  onMouseDblClick(event: IPointerDblClickControlEvent): void;
}

export interface IHandleHoverTool {
  onObjectHoverIn(event: IPointerHoveringControlEvent): void;
  onObjectHoverOut(event: IPointerHoveringControlEvent): void;
}

export interface IHandleMoveTool {
  onMouseMove(event: IPointerMoveControlEvent): void;
  onMouseLeave(event: IPointerLeftControlEvent): void;
}

interface IHandleTouchTool {
  onTouchStart(event: IPointerDownControlEvent): void;
  onTouchEnd(event: IPointerUpControlEvent): void;
}

interface IHandleTouchMoveTool {
  onTouchMove(event: IPointerMoveControlEvent): void;
  onMouseLeave(event: IPointerLeftControlEvent): void;
}

export interface IHandleSelectionTool {
  onSelectionChange(event: IControlSelectionChange): void;
}

export interface IHandleDragTool {
  onDrag(event: IControlDragging): void;
  onDragStart(event: IControlDragging): void;
  onDragEnd(event: IControlDragging): void;
}
export interface IBaseToolDependencies {
  editor: EditorStore;
  toolbar: ToolbarStore;
  serviceBus: ServiceBus;
}

type IHandleEventsTool = IKeyboardBehaviourHandler & IHandleTouchTool & IHandleMoveTool & IHandleClicksTool;

export abstract class BaseTool implements IDisposable, IHandleEventsTool {
  abstract id: string;
  abstract icon: string;
  abstract title: string;
  abstract description: string;
  readonly testId: string | undefined;
  readonly onClick?: () => void;

  cursor: ECursor;
  protected readonly editor: EditorStore;
  protected readonly toolbar: ToolbarStore;

  protected readonly modal: ModalStore;
  protected readonly serviceBus: ServiceBus;

  constructor(dependencies: IBaseToolDependencies) {
    this.cursor = ECursor.DEFAULT;
    this.editor = dependencies.editor;
    this.toolbar = dependencies.toolbar;
    this.modal = getUiStore().modal;

    this.serviceBus = dependencies.serviceBus;
  }

  abstract whenSelected(): void;
  abstract whenDeselected(): void;
  abstract dispose(): void;

  @action.bound
  isSelected(): boolean {
    return this.toolbar.selectedTool !== undefined && this.toolbar.selectedTool.id === this.id;
  }

  @computed
  get isDisabled(): boolean {
    return this.toolbar.isToolDisabled(this.id);
  }

  updateCursor(cursor: ECursor = this.cursor, cleanHistory: boolean = false): void {
    this.editor.updateCursor(cursor, cleanHistory);
  }

  onKeyDown(event: KeyboardEvent): void {
    /** event not implemented yet */
  }
  onKeyUp(event: KeyboardEvent): void {
    /** event not implemented yet */
  }
  onTouchStart(event: IPointerDownControlEvent): void {
    /** event not implemented yet */
  }
  onTouchEnd(event: IPointerUpControlEvent): void {
    /** event not implemented yet */
  }
  onMouseMove(event: IPointerMoveControlEvent): void {
    /** event not implemented yet */
  }
  onMouseLeave(event: IPointerLeftControlEvent): void {
    /** event not implemented yet */
  }
  onMouseDown(event: IPointerDownControlEvent): void {
    /** event not implemented yet */
  }
  onMouseUp(event: IPointerUpControlEvent): void {
    /** event not implemented yet */
  }
  onMouseDblClick = (event: IPointerDblClickControlEvent): void => {
    /** event not implemented yet */
  };

  protected isValidUpEvent({
    delta, pointerEnd, button
  }: IPointerUpControlEvent): boolean {
    return (
      delta !== undefined
      && pointerEnd !== undefined
      && button !== undefined
      && button === EMouseButtons.LEFT
      && delta.x <= 0.01
      && delta.y <= 0.01
    );
  }
}
