import type {
  BaseProps, MouseEvent, ReactElement
} from 'react';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import {
  FULL_ANGLE, HALF_PI
} from '../../../domain/models/Constants';
import { useEmptyChildren } from '../../../utils/hooks';
import {
  positionFromAngle, getVectorAngle, degreesToRadians, radiansToDegrees
} from '../../../utils/math';
import { getElementFromRef as getElement } from '../../../utils/helpers';
import ArrowButtons from './ArrowButtons';
import { CARDINAL_ABBREV } from './constants';
import { getCardinalAbbrev } from './helpers';
import {
  CardinalPoints, CompassStyle, DegreeRingStyle, InfoText, InnerRing, Needle, Ring, SnapPoint
} from './styles';

type Props = BaseProps & {
  angle?: number; // in degrees
  snaps?: number[];
  onChange?: (newDegree: number, quadrantBearing: string) => void;
};

function renderSnapPoints(snaps: number[]): ReactElement[] {
  return snaps.map((degree: number, index: number): ReactElement => {
    const angle = degreesToRadians(degree) - HALF_PI;
    const {
      x, y
    } = positionFromAngle(angle, 60);
    const translate = `translate(${x}px, ${y}px)`;

    return (
      <SnapPoint
        key={index}
        style={{
          transform: translate
        }}
      />
    );
  });
}

function Compass(props: Props): ReactElement {
  const {
    angle = 0, snaps = [], onChange
  } = props;

  const [degree, setDegree] = useState(Number(angle));
  const [cardinalAbbrev, setCardinalAbbrev] = useState(CARDINAL_ABBREV[0]);
  const [mousePress, setMousePress] = useState(false);
  const compassRef = useRef({} as HTMLDivElement);

  const changeDegree = useCallback(
    (newDegree: number): void => {
      newDegree = newDegree % 360;
      if (newDegree < 0) {
        newDegree += 360;
      }
      const cardinalDirLetter = getCardinalAbbrev(newDegree);

      setDegree(newDegree);
      setCardinalAbbrev(cardinalDirLetter);

      onChange?.(newDegree, cardinalDirLetter);
    },
    [onChange]
  );

  useEffect((): void => {
    requestAnimationFrame((): void => {
      const degreeToSetTo = !degree ? 0 : degree;
      changeDegree(degreeToSetTo);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAngle = useCallback(
    (event: MouseEvent): void => {
      const element = getElement<HTMLDivElement>(compassRef);
      const dimension = element.getBoundingClientRect() as DOMRect;
      const mousePos = {
        x: event.clientX,
        y: event.clientY
      };
      const dist = {
        x: dimension.x + dimension.width / 2 - mousePos.x,
        y: dimension.y + dimension.height / 2 - mousePos.y
      };
      const rotate = getVectorAngle(dist, HALF_PI);
      let newDegree = Math.floor(radiansToDegrees(rotate));
      // Array with the nearest point,
      // returns the Azimuth point degree if is close,
      // if not just return 0
      const nearestPoint = snaps.map((point: number): number => {
        const degreesToSnap = 10;
        // Absolute value of the azimuth snap angle
        // b/c of the counter clockwise flip rotation
        // after the Y coords change
        const pointDegree = FULL_ANGLE - Math.abs(point);
        const closeSnap = Math.abs(pointDegree - newDegree);
        if (closeSnap <= degreesToSnap) {
          return pointDegree;
        } else {
          return 0;
        }
      });

      // We find the Azimuth degree if we're near the point from the Array
      const snapPoint = nearestPoint.find((data: number): boolean => {
        // (earch number of the array it's the nearest snap for that vertex)
        // Retuns in the array
        // the number that's greater than 0, 0 == no near snap, if there's
        // a close spot it will return that number on degree
        return data > 1;
      });

      // Snap the point
      if (snapPoint !== undefined) {
        newDegree = snapPoint;
      } else {
        newDegree = Math.floor(radiansToDegrees(rotate));
      }

      changeDegree(newDegree);
    },
    [changeDegree, snaps]
  );
  const onMouseMove = useCallback(
    (event: MouseEvent): void => {
      if (mousePress) {
        onChangeAngle(event);
      }
    },
    [mousePress, onChangeAngle]
  );
  const onMouseDown = useCallback(
    (event: MouseEvent): void => {
      setMousePress(true);
      onChangeAngle(event);
    },
    [onChangeAngle]
  );
  const onMouseUp = useCallback((event: MouseEvent): void => {
    setMousePress(false);
  }, []);
  const onArrowLeft = useCallback((): void => {
    const newAngle = (degree - 1 + FULL_ANGLE) % FULL_ANGLE;

    changeDegree(newAngle);
  }, [degree, changeDegree]);
  const onArrowRight = useCallback((): void => {
    const newAngle = (degree + 1) % FULL_ANGLE;

    changeDegree(newAngle);
  }, [degree, changeDegree]);

  return (
    <CompassStyle dir="column" justify="center" align="center">
      <InfoText>Click and drag on the compass below to change direction.</InfoText>
      <DegreeRingStyle onMouseOut={onMouseUp}>
        <Ring>
          {useEmptyChildren(8)}
          {snaps.length ? renderSnapPoints(snaps) : <></>}
        </Ring>
        <InnerRing>
          <span>{degree % 1 > 0 ? degree.toFixed(2) : degree}°</span>
          <span>{cardinalAbbrev}</span>
        </InnerRing>
        <Needle
          style={{
            transform: `rotateZ(${degree}deg)`
          }}
          ref={compassRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        />
        <CardinalPoints>{useEmptyChildren(CARDINAL_ABBREV.length)}</CardinalPoints>
      </DegreeRingStyle>
      <ArrowButtons caption="You can also use your keyboard arrows" onLeft={onArrowLeft} onRight={onArrowRight} />
    </CompassStyle>
  );
}

export default Compass;
