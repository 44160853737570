/* tslint:disable:no-any */
import React, { ReactElement, useRef, BaseProps } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';

import { Row } from './styles';

export type DraggableRowProps = BaseProps & {
  index: number;
  id: string | number;
  width?: string;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd?: () => void;
  onClick?: (event: MouseEvent) => void;
};

interface IDragItem {
  index: number;
  id: string | number;
  type?: string;
}

interface ICollectedDropProps {
  handlerId: Identifier | null;
}

interface ICollectedDragProps {
  isDragging: boolean;
}

const DraggableRow = (props: DraggableRowProps): ReactElement => {
  const { children, index, id, onDrag, onDragEnd, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<IDragItem, void, ICollectedDropProps>({
    accept: 'card',
    collect: (monitor: DropTargetMonitor<IDragItem, void>): ICollectedDropProps => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: IDragItem, monitor: DropTargetMonitor<IDragItem, void>): void => {
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (!ref.current || dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onDrag?.(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (): void => onDragEnd?.()
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: (): IDragItem => ({ id, index }),
    collect: (monitor: DragSourceMonitor<IDragItem>): ICollectedDragProps => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const rowProps = {
    ...rest,
    ref,
    isDragging,
    'data-handler-id': handlerId
  };

  return (
    <Row {...rowProps} content={[]}>
      {children}
    </Row>
  );
};

export { DraggableRow };
