import { observer } from 'mobx-react-lite';
import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { ElectricalDesignStage } from '../../../../domain/stages/DesignStages/ElectricalDesignStage';
import { sectionTitle } from '../../SystemDesign/SectionTitle';
import {
  Quantity, QuantityValue
} from '../CommonElectricalDesignRightMenuCards.styles';
import {
  ElectricalDesignCard,
  DcOptimizerDescription,
  DcOptimizerDescriptionName,
  DcOptimizerRow,
  DcOptimizerWrapper
} from './style';

interface IDcOptimizerProps {
  readonly electricalDesignStage: ElectricalDesignStage;
  readonly editable?: boolean;
}

const DcOptimizer: React.FC<IDcOptimizerProps> = (props: IDcOptimizerProps): ReactElement => {
  const {
    electricalDesignStage, editable
  } = props;
  const handleEditClick = useCallback((): void => {
    electricalDesignStage.editInverterAndDcOptimizer();
  }, [electricalDesignStage]);

  const design = electricalDesignStage.design;

  const dcOptimizerModel = design.supplementalData.optimizerInfo?.model;
  const dcOptimizerCount = design.system.equipment.optimizers?.instances.length ?? 0;

  const electricalDesignProps = {
    editable,
    title: sectionTitle('dc optimizer'),
    onEditClick: handleEditClick
  };

  return (
    <ElectricalDesignCard {...electricalDesignProps}>
      {design.system.equipment.hasDcOptimizerDefinition && (
        <DcOptimizerWrapper>
          <DcOptimizerRow>
            <Quantity>
              <QuantityValue>{dcOptimizerCount}</QuantityValue>
            </Quantity>
            <DcOptimizerDescription>
              <DcOptimizerDescriptionName>{dcOptimizerModel}</DcOptimizerDescriptionName>
            </DcOptimizerDescription>
          </DcOptimizerRow>
        </DcOptimizerWrapper>
      )}
    </ElectricalDesignCard>
  );
};

export default observer(DcOptimizer);
