import type EditorStore from '../../stores/EditorStore/EditorStore';
import { DragControl } from '../../stores/EditorStore/Controls/DragControl';
import type SmartGuidesStore from '../../stores/UiStore/SmartGuidesStore/SmartGuidesStore';
import type { IHandleDragTool } from '../../stores/UiStore/ToolbarStore/Tool';
import type { Draggable } from '../mixins/Draggable';
import type { Selectable } from '../mixins/Selectable';

export class DragBehaviour {
  protected dragControl: DragControl;

  constructor(editor: EditorStore, guidelines: SmartGuidesStore) {
    this.dragControl = DragControl.getInstance(editor, editor.viewport, editor.activeCamera, guidelines);
  }

  setSnapIgnoreServerIds(newServerIdsToIgnore: string[]): void {
    this.dragControl.snapIgnoreServerIds = newServerIdsToIgnore;
  }

  addDragEvents(instance: IHandleDragTool): void {
    this.dragControl.activate();
    this.dragControl.addEventListener('drag', instance.onDrag);
    this.dragControl.addEventListener('dragstart', instance.onDragStart);
    this.dragControl.addEventListener('dragend', instance.onDragEnd);
  }

  removeDragEvents(instance: IHandleDragTool, recursive: boolean = false): void {
    this.dragControl.deactivate();
    this.dragControl.removeEventListener('drag', instance.onDrag);
    this.dragControl.removeEventListener('dragstart', instance.onDragStart);
    this.dragControl.removeEventListener('dragend', instance.onDragEnd);

    this.setTargetObjects(undefined, recursive);
  }

  setTargetObjects(objects: Selectable[] | undefined, recursiveRaycast: boolean = true): void {
    return this.dragControl.setTargetObjects(objects, recursiveRaycast);
  }

  setMultiSelectedObject(objects: Draggable[]) {
    this.dragControl.setAdditionalSelectedObjects(objects);
  }

  filterAndUpdateTargetObjects(filterFn: (object: Selectable) => boolean): void {
    this.dragControl.filterAndUpdateTargetObjects(filterFn);
  }
}
